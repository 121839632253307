import {
  PostCenterTestRequestStatistics,
  CenterDetail,
  GetCenterStatistic,
} from 'services/vrat/cms'
import { history } from 'umi';

export default {
  namespace: 'vratCenterDetail',
  state: {
    listCenterStatistics: [],
    SearchField: null,
    TestStartTime: null,
    TestEndTime: null,
    pageIndex: 1,
    pageSize: 12,
    total: 0,
    centerId: null,
    centerInfo: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *fetchStatistics({ payload }, { call, put, select, take }) {
      const { centerId } = payload
      const { SearchField, TestStartTime, TestEndTime, pageIndex, pageSize } = yield select(
        state => state.vratCenterDetail
      )
      var res = yield call(
        PostCenterTestRequestStatistics,
        { pageIndex, pageSize, centerId },
        { SearchField, TestStartTime, TestEndTime }
      )
      const { data, response } = res
      if (response.ok) {
        const { Items, TotalCount } = data
        yield put({
          type: 'updateState',
          payload: { listCenterStatistics: Items, total: TotalCount, centerId },
        })
        yield put({ type: 'CenterDetail', payload: centerId })
      }
    },

    *downloadCenterStatistic(_, { take, put, call, select }) {
      const { centerId, SearchField, TestStartTime, TestEndTime } = yield select(
        state => state.vratCenterDetail
      )
      yield call(GetCenterStatistic, centerId, { SearchField, TestStartTime, TestEndTime })
    },

    *CenterDetail({ payload }, { take, put, call, select }) {
      const res = yield call(CenterDetail, payload)
      const { data, response } = res
      if (response.ok) {
        yield put({ type: 'updateState', payload: { centerInfo: data } })
      }
    },

    *filterAndFetch({ payload: filter }, { take, put, call, select }) {
      const { centerId } = yield select(state => state.vratCenterDetail)
      yield put({ type: 'updateState', payload: filter })
      yield put({ type: 'fetchStatistics', payload: { centerId } })
    },

    *returnBack({ payload: query }, { call, put, select }) {
      history.goBack()
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/vratCenterDetail') {
    //       dispatch({ type: 'fetchStatistics', payload: location.query })
    //     }
    //   })
    // },
  },
}
