import React from 'react'
import { connect } from 'umi'
import { Row, Col, Divider } from 'antd'
import styles from './Report.less'
import { useIntl } from 'umi'
import CharacteristicChart from 'components/VratChartComponents/CharacteristicChart'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const MotionCharacteristics = ({ dispatch, data, showNewReport }) => {

  const intl = useIntl()

  const hyperactivityIndex = data ? (100 - data.Hyperactivity * 100).toFixed(1) : 0
  const impulsivityIndex = data ? (100 - data.Impulsicity * 100).toFixed(1) : 0
  const smallMovementIndex = data ? (100 - data.SmallMovement * 100).toFixed(1) : 0
  const areaIndex = data ? (100 - data.Area * 100).toFixed(1) : 0

  const CharacteristicHyperactivityChartProps = {
    data: hyperactivityIndex,
    title: intl.formatMessage({ id: 'report.motionCharacter.hyperactivity' }),
  }

  const CharacteristicAreaChartProps = {
    data: areaIndex,
    title: intl.formatMessage({ id: 'report.motionCharacter.motionArea' }),
  }

  const descTextE = [
    {
      title: intl.formatMessage({ id: 'report.motionCharacter.hyperactivity' }),
      desc: intl.formatMessage({ id: 'report.motionCharacter.hyperactivity.desc' }),
    },
    {
      title: 'Impulsivity Index',
      desc:
        'Suddenness of movements. If there are more rapid changes, percentile will be closer to the red area.',
    },
    {
      title: 'Small-movement Index',
      desc:
        'Movements within 1cm. During the test, the higher the proportion of small movements, the closer to the rea area.',
    },
    {
      title: intl.formatMessage({ id: 'report.motionCharacter.motionArea' }),
      desc: intl.formatMessage({ id: 'report.motionCharacter.motionArea.desc' }),
    },
  ]

  return (
    <div id="characteristic" className={styles.page_div}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.motionCharacter.title' })}</p>
      </h2>
      <Row type="flex" justify="center" style={{ marginTop: 100 }}>
        <Col style={{ height: 120 }}>
          <CharacteristicChart {...CharacteristicHyperactivityChartProps} />
        </Col>
        <Col style={{ height: 120 }}>
          <CharacteristicChart {...CharacteristicAreaChartProps} />
        </Col>
        <Col span={24} style={{ marginTop: 35, textAlign: 'center' }}>
          <h2 className={styles.weightFont}>
            {intl.formatMessage({ id: 'report.motionCharacter.chart.title' })}
          </h2>
        </Col>
        <Col span={20} style={{ marginLeft: 48 }}>
          <Divider style={{ width: 750 }} type="horizontal" className={styles.divider} />
        </Col>
      </Row>

      <Row type="flex" justify="center" style={{ padding: 30 }}>
        <Col span={7}>
          <p className={styles.weightFont}>{descTextE[0].title}</p>
          <p style={{ color: '#333' }}>{descTextE[0].desc}</p>
        </Col>
        <Col span={1} offset={1} style={{ marginTop: -30 }}>
          <Divider style={{ height: 130, width: 1 }} type="vertical" className={styles.divider} />
        </Col>
        <Col span={7}>
          <p className={styles.weightFont}>{descTextE[3].title}</p>
          <p style={{ color: '#333' }}>{descTextE[3].desc}</p>
        </Col>
        <Col span={1} style={{ display: 'none' }}>
          <Divider style={{ height: 150, width: 1 }} type="vertical" className={styles.divider} />
        </Col>
        <Col span={7} style={{ display: 'none' }}>
          <p className={styles.weightFont}>{descTextE[2].title}</p>
          <p style={{ color: '#333' }}>{descTextE[2].desc}</p>
        </Col>
      </Row>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '10' : '10'}</p>
    </div>
  )
}

export default connect()(MotionCharacteristics)
