import { Current, SetLanguage } from 'services/language'
import { getLocale, setLocale, useIntl } from 'umi'
import usermanger from 'utils/auth'

const LocalToServer = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es'
}

const ServerToLocal = {
  en: 'en-US',
  'zh-Hans': 'zh-CN',
  es: 'es-ES'
}


const GlobalModel = {
  namespace: 'global',

  state: {
  },

  effects: {

    *init({ payload }, { call, put, select }) {
      yield put({ type: 'refreshLanguage' })
      yield put({ type: 'initOidc' })
    },

    *initOidc({ payload }, { call, put, select }) {
      if (!usermanger.manager) {
        yield usermanger.init()
      }
    },

    *refreshLanguage({ payload }, { call, put, select }) {
      const res = yield call(Current)
      if (res.response.ok) {
        const { name } = res.data
        if (ServerToLocal[name] !== getLocale()) {
          setLocale(ServerToLocal[name])
        }
      }
    },

    *changeLang({ payload: key }, { call, put, take, select }) {
      const res = yield call(SetLanguage, { culture: LocalToServer[key] })
      if (res.response.ok) {
        setLocale(key)
      }
    },

  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  subscriptions: {

  },
}

export default GlobalModel;