import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
} from 'services/rocketService/Prepare'

import {
  UpdateCourseItem,
  EditCourseItemSortOrder,
  DeleteCourseItem,
} from 'services/rocketService/Course'

export default {
  namespace: 'courseDetail',
  state: {
    modalVisible: false,
    courseInfo: null,
    content: null,
    selectItem: null,
    sortOrderModal: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage({ payload }, { call, put, take, select }) {
      const res = yield call(GetPrepareEdit, payload.id, 'course')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { courseInfo: res.data },
        })
      }
    },

    *onAdd(_, { call, put, take, select }) {
      const res = yield call(GetPrepareList, { MaxResultCount: 5000 }, 'courseItem')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { content: res.data, modalVisible: true },
        })
      }
    },

    *addCourseItem({ payload }, { call, put, take, select }) {
      const { courseInfo } = yield select(state => state.courseDetail)
      const res = yield call(UpdateCourseItem, {
        courseId: courseInfo.id,
        detailIds: payload,
      })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { content: null, modalVisible: false },
        })
        yield put({ type: 'loadPage', payload: { id: courseInfo.id } })
      }
    },

    *changeSortOrder({ payload }, { call, put, take, select }) {
      const { courseInfo, selectItem } = yield select(state => state.courseDetail)
      const res = yield call(EditCourseItemSortOrder, {
        courseId: courseInfo.id,
        detailId: selectItem.id,
        newSortOrder: payload,
      })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { sortOrderModal: false },
        })
        yield put({ type: 'loadPage', payload: { id: courseInfo.id } })
      }
    },

    *deleteItem({ payload }, { call, put, take, select }) {
      const { courseInfo } = yield select(state => state.courseDetail)
      const res = yield call(DeleteCourseItem, { courseId: courseInfo.id, detailIds: [payload] })
      if (res.response.ok) {
        yield put({ type: 'loadPage', payload: { id: courseInfo.id } })
      }
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/courseDetail') {
    //       dispatch({ type: 'loadPage', payload: location.query })
    //     }
    //   })
    // },
  },
}
