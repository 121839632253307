import React, { useEffect } from 'react'
import moment from 'moment'
import DocumentTitle from 'react-document-title'
import { tableHeadTitle as tableHeadTitleFunc, 
    tableContentHeadTitle as tableContentHeadTitleFunc, 
    table2Content1Title as table2Content1TitleFunc, 
    groupTableContentHeadTitle as groupTableContentHeadTitleFunc, 
    oneTomoreTable2Content2Title as oneTomoreTable2Content2TitleFunc, 
    oneTomoreRable2Content2Title as oneTomoreRable2Content2TitleFunc, 
    table2Content2 as table2Content2Func, 
    table2Content2Title as table2Content2TitleFunc
} from './config'
import triangleImg from './images/triangle.png'
import pentagonalImg from './images/pentagonal.png'
import circularImg from './images/circular.png'
import diamondImg from './images/diamond.png'
import rectangleImg from './images/rectangle.png'
import feedtriangleImg from './images/feedtriangle.png'
import feedpentagonalImg from './images/feedpentagonal.png'
import feedcircularImg from './images/feedcircular.png'
import feeddiamondImg from './images/feeddiamond.png'
import feedrectangleImg from './images/feedrectangle.png'
import feedbodyImg from './images/feedbody.png';
import feedcenterImg from './images/feedcenter.png';
import feedhandEyeImg from './images/feedhandEye.png';
import feedpowerImg from './images/feedpower.png';
import feedrightLeftImg from './images/feedrightLeft.png';
import bodyImg from './images/body.png';
import centerImg from './images/center.png';
import handEyeImg from './images/handEye.png';
import powerImg from './images/power.png';
import rightLeftImg from './images/rightLeft.png';
import { connect, useIntl } from 'umi'
import { Button, Spin } from 'antd'
import { convertTimeToSecond } from 'cognitiveleap-core-us/utils/utils'
import { convertDuration } from 'utils/utils'
import Comments from './comments'
import styles from './index.less'

const HfsTrainingRecord = ({ dispatch, hfsTrainingRecord, loading, location }) => {
    const intl = useIntl()
    const tableHeadTitle = tableHeadTitleFunc()
    const tableContentHeadTitle = tableContentHeadTitleFunc()
    const table2Content1Title = table2Content1TitleFunc()
    const groupTableContentHeadTitle = groupTableContentHeadTitleFunc()
    const oneTomoreTable2Content2Title = oneTomoreTable2Content2TitleFunc()
    const oneTomoreRable2Content2Title = oneTomoreRable2Content2TitleFunc()
    const table2Content2 = table2Content2Func()
    const table2Content2Title = table2Content2TitleFunc()

    const { type = 'download', from = 'oneToone' } = location.query

    useEffect(() => {
        dispatch({ type: 'hfsTrainingRecord/fetch', payload: location.query })
    }, [])

    const { subjectInfo, planData, guardianInfo, musicInfo, num, trainerInfo, childInfo } = hfsTrainingRecord
    const { feedbackProperties: childFeedbackProperties, feedbacks: childFeedbacks } = childInfo || {}
    const { feedbacks: guardianFeedbacks, hasDone: guardianHasDone } = guardianInfo || {}
    const { isApp = false, coachName, num: myNum, userOfflineTrainingPlan } = planData || {}
    const { currentNum } = userOfflineTrainingPlan || {}
    const { numProgress } = musicInfo || {}
    const showEmptyDataIndex = (data, item, index) => {
        switch (item.propertyName) {
            case "index":
                return data.length + index + 1
        }
    }

    // 身体协调性 核心力量 左右交叉 手眼协调 平衡
    const isFiveType = (type) => {
        if(type === 'physicalStrength' || type === 'leftRightCross' || type === 'balance' || type === 'eyeHandCoordination' || type === 'core') {
            return true
        }
        return false
    }

    const getImage = (configData) => {
        const { imageType, imageCount, isFeed } = configData || {}
        const tempItemCount = parseInt(imageCount)
        if (tempItemCount <= 0) return ''
        const arrayData = new Array(tempItemCount).fill(0)
        let url = ''
        let width = isFiveType(imageType) ? 18 : 11        
        switch (imageType) {
            case "triangle":
                url = isFeed ? feedtriangleImg : triangleImg;
                break
            case "pentagonal":
                url = isFeed ? feedpentagonalImg : pentagonalImg;
                break
            case "circular":
                url = isFeed ? feedcircularImg : circularImg
                break
            case "diamond":
                url = isFeed ? feeddiamondImg : diamondImg
                break
            case "rectangle":
                url = isFeed ? feedrectangleImg : rectangleImg
                break
            case "physicalStrength":
                url = isFeed ? feedbodyImg : bodyImg
                break
            case "leftRightCross":
                url = isFeed ? feedrightLeftImg : rightLeftImg
                break
            case "balance":
                url = isFeed ? feedcenterImg : centerImg
                break
            case "eyeHandCoordination":
                url = isFeed ? feedhandEyeImg : handEyeImg
                break
            case "core":
                url = isFeed ? feedpowerImg : powerImg
                break
        }
        return <React.Fragment>
            {
                arrayData.map((item, index) => {
                    return <img src={url} key={index} width={width} style={{ marginRight: '2px' }} />
                })
            }
        </React.Fragment>
    }

    const showData = (data, configData, index) => {
        const { propertyName } = configData || {}
        let item = ''
        let dataRes = ''
        switch (propertyName) {
            case "index":
                return index + 1
            case "planname":
                const planname1 = data && data.trainingProgram && `${data.trainingProgram.title || ""} ${data.trainingProgram.difficulty || ""}`
                const planname2 = data.feedbacks && data.feedbacks.length > 0 ? data.feedbacks[0].feedbackProperties.find(item => item.name === "name") : ''
                return planname2 ? `${planname1}-${planname2["value"]}` : planname1
            case "plantarget":
                return data && data.trainingProgram && data.trainingProgram.targets && Array.isArray(data.trainingProgram.targets) && data.trainingProgram.targets.map((item, index) => data.trainingProgram.targets.length - 1 === index ? item.displayName : `${item.displayName}、`)
            case "firstRound":
                item = data.feedbacks && data.feedbacks.length > 0 ? data.feedbacks[0].feedbackProperties.find(item => (item.name === "firstRound") || (item.name === "continuousAttention")) : ''
                dataRes = item ? item.value : ''
                return data.isStaticPlaybook ? '------' : dataRes
            case "secondRound":
                item = data.feedbacks && data.feedbacks.length > 0 ? data.feedbacks[0].feedbackProperties.find(item => (item.name === "secondRound") || (item.name === "preBehaviour")) : ''
                dataRes = item ? item.value : ''
                return data.isStaticPlaybook ? '------' : dataRes
            case "thirdRound":
                item = data.feedbacks && data.feedbacks.length > 0 ? data.feedbacks[0].feedbackProperties.find(item => (item.name === "thirdRound") || (item.name === "bestPerformance")) : ''
                dataRes = item ? item.value : ''
                return data.isStaticPlaybook ? '------' : dataRes
            case "difficultySelection":
                if (data.hasDone === false) {
                    return <span></span>
                } else {
                    if (data.feedbacks && data.feedbacks.length > 0) {
                        item = data.feedbacks[0].feedbackProperties.find(item => item.name === "difficultySelection")
                        dataRes = item ? parseInt(item.value) : '0'
                        const isFeed = item && item.dataType === "mock" ? false : true
                        return getImage({ imageType: 'triangle', imageCount: dataRes, isFeed });
                    } else {
                        return getImage({ imageType: 'triangle', imageCount: 5, isFeed: false })
                    }
                }
            case "likingRating":
                if (data.hasDone === false) {
                    return <span></span>
                } else {
                    if (data.feedbacks && data.feedbacks.length > 0) {
                        item = data.feedbacks[0].feedbackProperties.find(item => item.name === "likingRating")
                        dataRes = item ? parseInt(item.value) : '0'
                        const isFeed = item && item.dataType === "mock" ? false : true
                        return getImage({ imageType: 'pentagonal', imageCount: dataRes, isFeed });
                    } else {
                        return getImage({ imageType: 'pentagonal', imageCount: 5, isFeed: false })
                    }
                }
            case "notes":
            case "notes2":
                item = data.feedbacks && data.feedbacks.length > 0 ? data.feedbacks[0].feedbackProperties.find(item => item.name === "notes2" || item.name === 'notes') : ''
                dataRes = item ? item.value : data.hasDone === false ?  intl.formatMessage({ id: 'Incompleted' }) : ''
                return dataRes
        }
    }

    const showTable2Data = (configData) => {
        const { propertyName, name } = configData || {}
        switch (propertyName) {
            case "text":
                return name;
            case "image":
                return getImage(configData)
        }
    }

    const getFeedbackValue = (feedbackItem, name) => {
        const { value = 5 } = feedbackItem || {}
        switch (name) { 
            case "physicalStrength":
                return getImage({ imageType: 'physicalStrength', imageCount: value, isFeed: feedbackItem !== null });
            case "core":
                return getImage({ imageType: 'core', imageCount: value, isFeed: feedbackItem !== null });
            case "leftRightCross":
                return getImage({ imageType: 'leftRightCross', imageCount: value, isFeed: feedbackItem !== null });
            case "eyeHandCoordination":
                return getImage({ imageType: 'eyeHandCoordination', imageCount: value, isFeed: feedbackItem !== null });
            case "balance":
                return getImage({ imageType: 'balance', imageCount: value, isFeed: feedbackItem !== null });
            default: 
                return name + ": " + value
        }
    }

    const planItemsData = planData && planData.planItems || []
    let nonePlaybookData = planData && planData.nonePlaybook || []

    // 给静态活动加上标志
    nonePlaybookData = nonePlaybookData.map(item => {
        return {
            ...item,
            isStaticPlaybook: true
        }
    })

    const data = planItemsData.concat(nonePlaybookData)

    tableHeadTitle[0].name = `${intl.formatMessage({ id: 'studentName' })}：${subjectInfo && subjectInfo.name || ''}`
    tableHeadTitle[1].name = `${intl.formatMessage({ id: 'session' })}：${planData && planData.num || ''}`
    const musicData = from === 'oneToone' ? 
        planData && planData.iLs && planData.iLs.tracks && planData.iLs.tracks.length > 0 ? planData.iLs.tracks.map(item => planData.iLs.courseCode + item.code) : '' :
        planData && planData.iLs && planData.iLs.length > 0 ? planData.iLs.map(item => item) : ''
    tableHeadTitle[2].name = `${intl.formatMessage({ id: 'music' })}： ${musicData}`

    // 获取聆听时长
    let currentMusicInfo = from === 'oneToone' ? numProgress && numProgress.find(item => item.num === parseInt(num)) || null : numProgress && numProgress[0] || null
    let webProgress = 0

    if (currentMusicInfo) {
        if (from === 'oneToone') {
            if (isApp) {
                currentMusicInfo.appCodeProgress.forEach(item => {
                    webProgress += convertTimeToSecond(item.progress)
                })
            } else {
                currentMusicInfo.webCodeProgress.forEach(item => {
                    webProgress += convertTimeToSecond(item.progress)
                })
            }
        } else {
            currentMusicInfo.appCodeProgress.forEach(item => {
                webProgress += convertTimeToSecond(item.progress)
            })
            currentMusicInfo.webCodeProgress.forEach(item => {
                webProgress += convertTimeToSecond(item.progress)
            })
        }
    }

    tableHeadTitle[4].name = `${intl.formatMessage({ id: 'playingDurationMins' })}：${webProgress > 0 ? convertDuration(webProgress) : ''}`

    // 训练师名字
    let trainerName = myNum === currentNum ? (trainerInfo && trainerInfo.name || '') : coachName
    tableHeadTitle[7].name = trainerName ? `${intl.formatMessage({ id: 'trainer' })}：${trainerName}` : `${intl.formatMessage({ id: 'trainer' })}：`

    // 显示整体反馈内容
    if (guardianInfo) {
        if (guardianHasDone === true) {
            if (guardianFeedbacks.length === 0) {
                guardianInfo.feedbackProperties.forEach(item => {
                    switch (item.name) {
                        case "obeyOrder":
                            if(from === 'oneToone') {
                                table2Content1Title[3].imageCount = item.extraProperties && item.extraProperties.options && item.extraProperties.options.length
                            } else {
                                oneTomoreTable2Content2Title[1].imageCount = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.options && item.programFeedbackProperty.extraProperties.options.length
                            }
                            break
                        case "attention":
                            if(from === 'oneToone') {
                                table2Content1Title[5].imageCount = item.extraProperties && item.extraProperties.options && item.extraProperties.options.length
                            } else {
                                oneTomoreTable2Content2Title[3].imageCount = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.options && item.programFeedbackProperty.extraProperties.options.length
                            }
                            break
                        case "imHy":
                            if(from === 'oneToone') {
                                table2Content1Title[7].imageCount = item.extraProperties && item.extraProperties.options && item.extraProperties.options.length
                            } else {
                                oneTomoreTable2Content2Title[5].imageCount = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.options && item.programFeedbackProperty.extraProperties.options.length
                            }
                            break
                    }
                })
            } else {
                guardianFeedbacks[0].feedbackProperties.forEach(item => {
                    switch (item.name) {
                        case "volume":
                            tableHeadTitle[3].name = `${intl.formatMessage({ id: 'volumeOnly' })}：${item.value ? item.value + '%' : ''}`
                            break
                        case "TrainingDurationMinute":
                            tableHeadTitle[5].name = `${intl.formatMessage({ id: 'estimatedTrainingDuration' })}：${item.value ? item.value : ''}`
                            break
                        case "courseTime":
                            tableHeadTitle[6].name = `${intl.formatMessage({ id: 'trainingDate' })}：${item.value ? moment(item.value).format('YYYY/MM/DD') : ''}`
                            break
                        case "obeyOrder":
                            table2Content1Title[3].imageCount = item.value ? parseInt(item.value) : 0
                            table2Content1Title[3].isFeed = true
                            break
                        case "attention":
                            table2Content1Title[5].imageCount = item.value ? parseInt(item.value) : 0
                            table2Content1Title[5].isFeed = true
                            break
                        case "imHy":
                            table2Content1Title[7].imageCount = item.value ? parseInt(item.value) : 0
                            table2Content1Title[7].isFeed = true
                            break
                        case "behaviour":
                            table2Content2[0].content = item.value
                            break
                        case "improvement":
                            table2Content2[1].content = item.value
                            break
                        case "needImprove":
                            table2Content2[2].content = item.value
                            break
                        case "parentsFeedback":
                            table2Content2[3].content = item.value
                            break
                    }
                })
            }
        }
        if (guardianHasDone === false) {
            const { endTime, doneTime } = guardianInfo
            const tempTime = endTime || doneTime
            tableHeadTitle[6].name = `${intl.formatMessage({ id: 'trainingDate' })}：${tempTime ? moment(tempTime).format('YYYY/MM/DD') : ''}`
        }
    }

    if(childFeedbacks && childFeedbacks.length > 0){
        if(childFeedbacks && childFeedbacks.length > 0) {
            childFeedbacks[0].feedbackProperties.forEach((feedbackItem) => {
                const { value, name } = feedbackItem
                switch(name){
                    case "volume":
                        tableHeadTitle[3].name = `${intl.formatMessage({ id: 'volumeOnly' })}：${value ? value + '%' : ''}`
                        break
                    case "obeyOrder":
                        oneTomoreTable2Content2Title[1].imageCount = value ? parseInt(value) : 0
                        oneTomoreTable2Content2Title[1].isFeed = true
                        break
                    case "attention":
                        oneTomoreTable2Content2Title[3].imageCount = value ? parseInt(value) : 0
                        oneTomoreTable2Content2Title[3].isFeed = true
                        break
                    case "imHy":
                        oneTomoreTable2Content2Title[5].imageCount = value ? parseInt(value) : 0
                        oneTomoreTable2Content2Title[5].isFeed = true
                        break
                }
            })
        } else {
            childFeedbackProperties && childFeedbackProperties.length > 0 && childFeedbackProperties.map((item, index) => {
                const { name } = item
                switch(name) {
                    case "obeyOrder":
                        oneTomoreTable2Content2Title[1].imageCount = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.options && item.programFeedbackProperty.extraProperties.options.length
                        break
                    case "attention":
                        oneTomoreTable2Content2Title[3].imageCount = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.options && item.programFeedbackProperty.extraProperties.options.length
                        break
                    case "imHy":
                        oneTomoreTable2Content2Title[5].imageCount = item.programFeedbackProperty && item.programFeedbackProperty.extraProperties && item.programFeedbackProperty.extraProperties.options && item.programFeedbackProperty.extraProperties.options.length
                        break
                }
            })
        }
    }

    const renderBottomData = (options, data) => {
        const { propertyName }  = options
        let { displayName , value = '', id } = data
        if(childFeedbacks && childFeedbacks.length > 0){
            const feedbackItem = childFeedbacks && childFeedbacks.length > 0 ? childFeedbacks[0].feedbackProperties.find((feedback) => feedback.programFeedbackPropertyId === id) : null
            if(feedbackItem) {
                const { value: editValue } = feedbackItem
                value = editValue
            }
        }
        switch (propertyName) {
            case "title":
                return displayName
            case "content":
                return value
            default:
                return propertyName + ": " + value
        }
    }

    const commentProps = {
        intl,
        feedrectangleImg,
        feeddiamondImg,
        feedcircularImg,
        feedpentagonalImg,
        feedtriangleImg
    }

    return (
        <DocumentTitle title={intl.formatMessage({ id: 'HFSTrainingRecord' })}>
            <Spin spinning={loading.models.hfsTrainingRecord}>
                <div className={styles.hfsTrainingRecord} id="hfsTrainingRecord">
                    <div className={styles.hfsTrainingRecordTitle}>{intl.formatMessage({ id: 'HFSTrainingRecord' })}</div>
                    <div className={styles.hfsTrainingRecordHeader}>
                        {
                            tableHeadTitle.map((item, index) => {
                                return <div className={styles.hfsTrainingRecordSubHeader} key={'tableHeadTitle' + index} style={item.style}>{item.name}</div>
                            })
                        }
                    </div>
                    {from === 'oneToone' && <>
                        <div className={styles.hfsTrainingRecordContentHeader}>
                            {
                                tableContentHeadTitle.map((item, index) => {
                                    return <div className={styles.hfsTrainingRecordContentSubHeader} key={'tableContentHeadTitle' + index} style={item.style}>{item.name}</div>
                                })
                            }
                        </div>
                        <div className={styles.hfsTrainingRecordContent}>
                            {
                                data.map((item, index) => {
                                    return (
                                        <div className={styles.hfsTrainingRecordContentItem} key={'content' + index}>
                                            {
                                                tableContentHeadTitle.map((contentItem, contentIndex) => {
                                                    return <div className={styles.hfsTrainingRecordContentItemProperty} key={'contentitem' + contentIndex} style={contentItem.style}>{showData(item, contentItem, index)}</div>
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                data.length < 8 &&
                                new Array(8).fill(0).slice(0, 8 - data.length).map((item, index) => {
                                    return (
                                        <div className={styles.hfsTrainingRecordContentItem} key={'emptycontent' + index}>
                                            {
                                                tableContentHeadTitle.map((contentItem, contentIndex) => {
                                                    return (
                                                        <div className={styles.hfsTrainingRecordContentItemProperty} key={'emptycontentitem' + contentIndex} style={contentItem.style}>
                                                            {showEmptyDataIndex(data, contentItem, index)}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>}
                    {from === 'oneTomore' && <>
                         <div className={styles.hfsTrainingRecordContentHeader}>
                            {
                                groupTableContentHeadTitle.map((item, index) => {
                                    return <div className={styles.hfsTrainingRecordContentSubHeader} key={'groupTableContentHeadTitle' + index} style={item.style}>{item.name}</div>
                                })
                            }
                        </div>
                        <div className={styles.hfsTrainingRecordContent}>
                            {
                                data.map((item, index) => {
                                    return (
                                        <div className={styles.hfsTrainingRecordContentItem} key={'oneTomoreContent' + index}>
                                            {
                                                groupTableContentHeadTitle.map((contentItem, contentIndex) => {
                                                    return <div className={styles.hfsTrainingRecordContentItemProperty} key={'oneTomoreContentItem' + contentIndex} style={contentItem.style}>{showData(item, contentItem, index)}</div>
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                data.length < 6 &&
                                new Array(6).fill(0).slice(0, 6 - data.length).map((item, index) => {
                                    return (
                                        <div className={styles.hfsTrainingRecordContentItem} key={'groupEmptycontent' + index}>
                                            {
                                                groupTableContentHeadTitle.map((contentItem, contentIndex) => {
                                                    return (
                                                        <div className={styles.hfsTrainingRecordContentItemProperty} key={'groupEmptycontentitem' + contentIndex} style={contentItem.style}>
                                                            {showEmptyDataIndex(data, contentItem, index)}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>}
                    {/* <div className={styles.hfsTrainingRecord2Title}>观察到的课堂表现</div> */}
                    {
                        from === 'oneToone' && <>
                            <div className={styles.table2Content1}>
                                {
                                    table2Content1Title.map((item, index) => {
                                        return (
                                            <div className={styles.table2Content1Item} key={'table2Content1Title' + index} style={item.style}>{showTable2Data(item)}</div>
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.table2Content2}>
                                {
                                    table2Content2.map((item, index) => {
                                        return (
                                            <div className={styles.table2Content2Item} key={'table2Content2' + index}>
                                                {
                                                    table2Content2Title.map((contentItem, contentIndex) => {
                                                        return <div className={styles.table2Content2ItemProperty} style={contentItem.style} key={'table2Content2item' + contentIndex}>{item[contentItem.propertyName]}</div>
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                         </>
                    }
                    {
                        from === 'oneTomore' && <>
                            <div className={styles.hfsTrainingRecordTitleTwo}>{intl.formatMessage({ id: 'personalFeedback' })}</div>
                            {/* <div className={styles.table2Content1}>
                                {
                                    childFeedbackProperties && childFeedbackProperties.length > 0 && childFeedbackProperties.map((item, index) => {
                                        const { name } = item
                                        return isFiveType(name) ? (
                                            <div className={styles.table2Content1Item} key={'oneTomorefiveTable2Content1Title' + index} style={{ width: `calc(100% / 5)` }}>{item.displayName}</div>
                                        ) : null
                                    })
                                }
                            </div>
                            <div className={styles.table2Content1}>
                                {
                                    childFeedbackProperties && childFeedbackProperties.length > 0 && childFeedbackProperties.map((item, index) => {
                                        const { id, name } = item
                                        const feedbackItem = childFeedbacks && childFeedbacks.length > 0 ? childFeedbacks[0].feedbackProperties.find((feedback) => feedback.programFeedbackPropertyId === id) : null
                                        return isFiveType(name) ? (
                                            <div className={styles.table2Content1Item} key={'oneTomoreTable2Content1Value' + index} style={{ width: `calc(100% / 5)` }}>{getFeedbackValue(feedbackItem, name)}</div>
                                        ) : null
                                    })
                                }
                            </div> */}
                            <div className={styles.table2Content1}>
                                {
                                    oneTomoreTable2Content2Title.map((item, index) => {
                                        return (
                                            <div className={styles.table2Content1Item} key={'oneTomoreTable2Content2Title' + index} style={item.style}>{showTable2Data(item)}</div>
                                        )
                                    })
                                }
                            </div>
                            <div className={styles.table2Content2}>
                                {
                                    childFeedbackProperties && childFeedbackProperties.length > 0 && childFeedbackProperties.map((item, index) => {
                                        const { name } = item 
                                        return name === 'overallBehaviour' || name === 'trainAtHome' ? <div className={styles.table2Content2Item} key={'childfeedbackProperties' + index}>
                                            {
                                                oneTomoreRable2Content2Title.map((contentItem, contentIndex) => {
                                                    return <div className={styles.table2Content2ItemProperty} style={contentItem.style} key={'oneTomoreRable2Content2Title' + contentIndex}>{renderBottomData(contentItem, item)}</div>
                                                })
                                            }
                                        </div> : null
                                    })
                                }
                            </div>
                        </>
                    }
                    <div className={styles.finallyContent}>
                        {
                            from === 'oneToone' ? <>
                                <div style={{ fontWeight: 'bold' }}>
                                    {intl.formatMessage({ id: 'note' })}
                                </div> 
                                <Comments {...commentProps}/>
                            </> : <>
                                <span className={styles.finallyContentTitle}>{intl.formatMessage({ id: 'NoteLevelDifficulty' })}1-5<img src={feedtriangleImg} width='10' />{intl.formatMessage({ id: 'indicates' })}： </span><span>{intl.formatMessage({ id: 'extremelyEasyDifficult' })}</span>
                                <span className={styles.finallyContentTitle}> {intl.formatMessage({ id: 'levelOfLikeability' })}1-5<img src={feedpentagonalImg} width='10' />{intl.formatMessage({ id: 'indicates' })}：</span><span>{intl.formatMessage({ id: 'extremelyDislikeLike' })}</span>
                                <span className={styles.finallyContentTitle}> {intl.formatMessage({ id: 'attention' })}1-5<img src={feedrectangleImg} width='10' />{intl.formatMessage({ id: 'indicates' })}：</span><span>{intl.formatMessage({ id: 'extremelyInattention' })}</span>
                                <span className={styles.finallyContentTitle}> {intl.formatMessage({ id: 'followInstructions' })}1-5<img src={feedcircularImg} width='10' />{intl.formatMessage({ id: 'indicates' })}：</span><span>{intl.formatMessage({ id: 'veryUncooperative' })}</span>
                                <span className={styles.finallyContentTitle}> {intl.formatMessage({ id: 'hyperactivity_impulsivity' })}1-5<img src={feeddiamondImg} width='10' />{intl.formatMessage({ id: 'indicates' })}：</span><span>{intl.formatMessage({ id: 'highImpulsive' })}</span>
                                {/* {
                                    from === 'oneTomore' && <>
                                        <span className={styles.finallyContentTitle}> {intl.formatMessage({ id: 'coordination_core_balance' })}1-5<img src={feedpowerImg} width='12' />{intl.formatMessage({ id: 'allIndicate' })}：</span><span>{intl.formatMessage({ id: 'needImprove' })}</span>
                                    </>
                                } */}
                            </>
                        }
                    </div>
                </div>
                {type !== 'download' && <div className={styles.downlaod}>
                    <Button onClick={() => dispatch({ type: 'hfsTrainingRecord/downloadPdf' })} id='downlaodButton'>{intl.formatMessage({ id: 'printTimetable' })}</Button>
                </div>}
            </Spin>
        </DocumentTitle>
    )
}

export default connect(({ loading, hfsTrainingRecord }) => ({ loading, hfsTrainingRecord }))(HfsTrainingRecord)