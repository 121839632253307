import {
  GetUserAssessmentListApi,
  GetAssessmentDetail,
  GetGetRocketAssessment,
  GetAssessmentListQrCodeApi,
  PostSendToGuardianApi,
  PostUserAssessmentsExcel
} from 'services/rocketService/Assessment'
import { isJson } from 'cognitiveleap-core-us/utils/helper'
import { message, Modal } from 'antd'
import * as SurveyPDF from 'survey-pdf'

export default {
  namespace: 'adminquestionnaireList',
  state: {
    childName: '',
    listData: [],
    filters: {},
    filterDate: {
      startTime: '',
      endTime: ''
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    orderBy: null,
    sendToGuardianModalVisible: false,
    selectAssessmentModalVisible: false,
    currentSendToGuardianRecord: null,
    transferList: [],
    assessmentName: '',
    currentTenant: null,
    btnLoading: false
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *changeTenant({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user);
      const { currentTenant } = yield select(state => state.adminquestionnaireList);
      const { canChangeList } = currentUser;
      if (!currentTenant) {
        yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
      } else if (payload) {
        const { id } = payload;
        if (id) {
          const newCurrentTenant = canChangeList.find(item => item.id == id)
          yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
        } else {
          yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
        }
      }
      yield put({ type: 'loadPage' })
    },

    *loadPage(_, { call, put, take, select }) {
      const { childName, filterDate, pagination, orderBy, assessmentName, currentTenant, filters } = yield select(state => state.adminquestionnaireList)
      const { current, pageSize } = pagination
      const { assessmentStatus = [] } = filters
      const parmas = {
        BeginTime: filterDate.startTime,
        EndTime: filterDate.endTime,
        Filter: childName,
        Status: assessmentStatus,
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize,
        Sorting: orderBy == 'ascend' ? 'ASC' : 'DESC',
        AssessmentName: assessmentName,
        TenantId: currentTenant.id
      }
      const res = yield call(GetUserAssessmentListApi, parmas)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            pagination: { ...pagination, ...{ total: res.data.totalCount, current } },
            listData: res.data.items
          }
        })
      }
    },

    *onSearch(_, { call, put, take, select }) {
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      yield put({
        type: 'updateState',
        payload: { pagination }
      })

      yield put({
        type: 'loadPage'
      })
    },

    *clearFilter(_, { call, put, take, select }) {
      const childName = ''
      const assessmentName = ''
      const orderBy = null
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 100,
      }
      const filterDate = {
        startTime: '',
        endTime: ''
      }
      yield put({
        type: 'updateState',
        payload: { childName, assessmentName, orderBy, pagination, filterDate }
      })
      yield put({
        type: 'loadPage'
      })
    },

    *changeTable({ payload: data }, { call, put, take, select }) {
      const { orderBy, pagination } = yield select(state => state.adminquestionnaireList)
      const { pagination: newPagination, sorter, filters = {} } = data

      yield put({
        type: 'updateState',
        payload: { pagination: { ...pagination, ...newPagination }, orderBy: sorter ? (sorter.order || "ascend") : orderBy, filters }
      })

      yield put({
        type: 'loadPage'
      })
    },

    *sendToGuardian({ payload: _ }, { call, put, take, select }) {
      const { currentSendToGuardianRecord } = yield select(state => state.adminquestionnaireList)
      const parmas = {
        userAssessmentIds: [currentSendToGuardianRecord.id],
        send: true
      }
      const res = yield call(PostSendToGuardianApi, parmas)
      if (res.response.ok) {
        message.success('已发送')
        yield put({ type: 'loadPage' })
        yield put({ type: 'updateState', payload: { sendToGuardianModalVisible: false } })
      } else {
        message.error('发送失败，请稍后重试')
      }
    },

    *uploadAssessmentQr({ payload: { key } }, { call, put, take, select }) {
      return yield call(GetAssessmentListQrCodeApi, { assessmentId: key })
    },

    *showAssessmentList({ payload: _ }, { call, put, take, select }) {
      const query = {
        maxResultCount: 1000,
      }
      const res = yield call(GetGetRocketAssessment, query)
      if (res.response.ok) {
        const { items } = res.data
        yield put({ type: 'updateState', payload: { selectAssessmentModalVisible: true, transferList: items } })
      } else {
        const { error } = res.data;
        Modal.warning({
          title: '提示',
          content: error.message,
          okText: '知道了'
        })
      }
    },

    *exportExcel(_, { call, put, take, select }) {
      yield put({ type: 'updateState', payload: { btnLoading: true } })

      const { childName, filterDate, pagination, orderBy, assessmentName, currentTenant, filters } = yield select(state => state.adminquestionnaireList)
      const { current, pageSize } = pagination
      const { assessmentStatus = [] } = filters
      const { id: TenantId, displayName } = currentTenant
      const parmas = {
        BeginTime: filterDate.startTime,
        EndTime: filterDate.endTime,
        Filter: childName,
        Status: assessmentStatus,
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize,
        Sorting: orderBy == 'ascend' ? 'ASC' : 'DESC',
        AssessmentName: assessmentName,
        TenantId
      }
      yield call(PostUserAssessmentsExcel, parmas, displayName + '_')

      yield put({ type: 'updateState', payload: { btnLoading: false } })
    },

    *downloadAssessmentPDF({ payload: id }, { call, put, take, select }) {
      const res = yield call(GetAssessmentDetail, id)
      if (res.response.ok) {
        const userAssessmentInfo = res.data
        const {
          questionnaireAnswer: { questionnaire, answer },
        } = userAssessmentInfo
        const questionnaireJson = isJson(questionnaire.jsonString)
          ? JSON.parse(questionnaire.jsonString)
          : null
        const answerJson = isJson(answer.answerContent) ? JSON.parse(answer.answerContent) : null

        if (questionnaireJson === null || answerJson === null) {
          message.error(`Error Display. UserAssessmentId: ${userAssessmentInfo.id} `)
        } else {
          const surveyPDF = new SurveyPDF.SurveyPDF(questionnaireJson)
          surveyPDF.data = answerJson
          surveyPDF.save()
        }
      }
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/statistic/questionnaireList') {
    //       dispatch({ type: 'changeTenant' })
    //     }
    //   })
    // },
  },
}
