import React from 'react'
import { Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import MyModal from 'components/MyModal'
import { IntlManager } from 'utils/helper'
import { connect } from 'umi'
import styles from './index.less'

const { Option } = Select;
class AssessmentSelectModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            key: undefined
        }
    }

    selectOnchange = (value) => {
        this.setState({ key: [value] })
    }

    showPopTitle = (data) => {
        const intl = IntlManager.MyIntl()

        let title = ''
        const { hasSame, ageMismatch, isNeedInformedConsent, remainingTimes, records, timesSetting } = data
        if (hasSame) title = intl('unfinishedAssessments')
        else if (ageMismatch) title = intl('ageNotMatch')
        else if (isNeedInformedConsent) title = intl('assessmentOnlyWechat')
        if (timesSetting) {
            if (records && records.length === 0) title = intl('TheAmountAssessmentNotSet')
            else if (remainingTimes <= 0) title = intl('The assessments has been used up')
        }
        return title
    }

    render() {
        const intl = IntlManager.MyIntl()

        const { title, visible, loading, onCancel, allList, onOk, assessmentTimesSettingRecords, currentUser } = this.props
        const { changedTenant } = currentUser || {}
        const { timesSetting } = changedTenant || {};
        const { key } = this.state
        const myModalProps = {
            title,
            visible,
            loading,
            onCancel,
            onOk: () => {
                const self = this;
                eventDebounce(function submit() {
                    onOk(key)
                    self.setState({ key: undefined })
                }, 300)
            }
        }

        return (
            <MyModal
                {...myModalProps}
            >
                <div className={styles.assessmentSelectModal}>
                    <div className={styles.assessmentSelectModalTop}>
                        <div>{intl('selectAssessment')}：</div>
                        <Select onChange={this.selectOnchange} style={{ width: 300 }} placeholder={intl('pleaseSelect')} value={key} virtual={false}>
                            {
                                allList && Array.isArray(allList) && allList.length > 0 && allList.map((item, index) => {
                                    // 不设置tag，直接使用
                                    // 设置tag 找到对应的records为空数组说明没配置则禁用
                                    // 找到对应的次数配置，小于等于0则禁用
                                    const { hasSame, ageMismatch, isNeedInformedConsent, tagIds } = item
                                    const selectItem = assessmentTimesSettingRecords.find(record => {
                                        const { tag: { id } } = record || {}
                                        return tagIds.find(tag => tag === id)
                                    })
                                    const { remainingTimes, records } = selectItem || {}
                                    const showDisable = tagIds.length > 0 ? 
                                        selectItem ? 
                                            (remainingTimes <= 0 || (records && records.length === 0)) : 
                                            false : 
                                        false
                                    if (ageMismatch || hasSame || isNeedInformedConsent || (timesSetting ? showDisable : false)) {
                                        return (
                                            <Option value={item.id} disabled={true} key={index}>
                                                <Tooltip
                                                    placement="top"
                                                    title={this.showPopTitle({ ...item, remainingTimes, records, timesSetting })}
                                                >
                                                    <span className={styles.assessmentSelectModalTopTitle}>{item.title}</span>
                                                </Tooltip>
                                            </Option>
                                        )
                                    } else {
                                        return <Option value={item.id} key={index}>{item.title}</Option>
                                    }
                                })
                            }
                        </Select>
                    </div>
                    {/* <div className={styles.assessmentSelectModalBottom}>
                        <InfoCircleOutlined />
                        <span className={styles.assessmentSelectModalBottomRight}>如儿童当前有未完成的测评，则此测评不可选</span>
                    </div> */}
                </div>
            </MyModal>
        )
    }
}

export default connect(({ user }) => ({ currentUser: user.currentUser }))(AssessmentSelectModal)
