import React, { useEffect } from 'react'
import { Card, Button, Drawer, Empty, Spin, Tabs } from 'antd'
import { connect, history, useIntl } from 'umi'
import AccountSetting from 'components/AccountSetting'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'

const title = (intl) => {
    return {
        'editResumes': intl.formatMessage({ id: 'editCV' }),
        'editAmount': intl.formatMessage({ id: 'editRemainingTimes' })
    }
}

const { TabPane } = Tabs;

const GroupDetail = ({ adminGroupDetail, dispatch, loading, location }) => {
    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'adminGroupDetail/query', payload: location.query })
    }, [])

    const { userInfo, modalVisible, content, modalType, trainerDetail, questionData, drawerVisible, tabListData, tabActiveKey } = adminGroupDetail
    const { tenants } = userInfo || {}
    const { questionnaireAndAnswer } = questionData || {}
    const { answer, questionnaire } = questionnaireAndAnswer || {}
    const { answerContent, creationTime } = answer || {}
    const { jsonString, correctRate } = questionnaire || {}

    let questionArr = [], correntRateAndResult = null
    if (questionData) {
        correntRateAndResult = getCorrentRateAndResult(jsonString, answerContent, correctRate)
        questionArr = correntRateAndResult && correntRateAndResult.questionArr
    }


    const userAllTenant = tenants && tenants.length > 0 ? tenants.map(item => {
        return {
            displayName: item.tenant && item.tenant.displayName,
            roles: [item.role],
            state: item.tenant && item.tenant.state,
            id: item.tenant && item.tenant.id,
        }
    }) : []

    const accountSettingProps = {
        trainerDetail,
        userInfo,
        userAllTenant,
        canEditResumes: true,
        isShowLookFeedback: true,
        editAmount() {
            dispatch({ type: 'adminGroupDetail/edit', payload: { modalType: 'editAmount' } })
        },
        editResumes() {
            dispatch({ type: 'adminGroupDetail/edit', payload: { modalType: 'editResumes' } })
        },
        lookTrainingFeedback(record) {
            dispatch({ type: 'adminGroupDetail/trainingFeedback', payload: record })
        },
        reloadPage() {
            dispatch({ type: 'adminGroupDetail/query', payload: location.query })
        }
    }
    const modalProps = {
        visible: modalVisible,
        loading: loading.models.adminGroupDetail,
        content,
        title: title(intl)[modalType],
        onSubmitText: modalType === 'validPassword' ? intl.formatMessage({ id: 'verifyPassword' }) : '',
        onOk: (values) => {
            dispatch({ type: 'adminGroupDetail/onSubmit', payload: { values, intl } })
        },
        onCancel() {
            dispatch({ type: 'adminGroupDetail/onCancel' })
        }
    }

    const goBack = () => {
        history.goBack()
    }
    const { name } = userInfo || {}
    const cardTitle = (
        <div>
            <Button onClick={goBack}>{intl.formatMessage({ id: 'back' })}</Button>
            <b style={{ marginLeft: '10px', fontSize: '20px' }}>
                {intl.formatMessage({ id: 'WhoAccountSettings' }, { name })}
            </b>
        </div>
    )

    const showSurveyProps = {
        contents: questionArr,
        choice: answerContent ? JSON.parse(JSON.stringify(answerContent)) : {},
        showIcon: false
    }

    return (
        <Card bordered={false} title={cardTitle}>
            <Spin spinning={loading.effects['adminGroupDetail/loadPage']}>
                <AccountSetting {...accountSettingProps} />
            </Spin>
            <MagicFormModal {...modalProps} />
            <Drawer
                title={intl.formatMessage({ id: 'trainfeedback' })}
                width={700}
                open={drawerVisible}
                bodyStyle={{ padding: "20px" }}
                onClose={() => dispatch({ type: 'adminGroupDetail/updateState', payload: { drawerVisible: false } })}
            >
                {tabListData &&
                    <Tabs
                        activeKey={tabActiveKey}
                        type="card"
                        onChange={(id) => {
                            dispatch({ type: 'adminGroupDetail/changeTab', payload: { id } })
                        }}>
                        {tabListData.map(item => {
                            return (<TabPane tab={item.displayName} key={item.id}>
                                <Spin spinning={loading.effects['adminGroupDetail/changeTab'] === undefined ? loading.effects['adminGroupDetail/trainingFeedback'] : loading.effects['adminGroupDetail/changeTab']}>
                                    {
                                        questionData ?
                                            <ShowSurvey {...showSurveyProps} />
                                            :
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    }
                                </Spin>
                            </TabPane>)
                        })}
                    </Tabs>
                }
            </Drawer>
        </Card>
    )
}

export default connect(({ adminGroupDetail, loading }) => ({ adminGroupDetail, loading }))(GroupDetail)