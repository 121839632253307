import React from 'react';

import { Swiper } from 'swiper/swiper-react';

// Import Swiper styles
import 'swiper/swiper.css';
import 'swiper/modules/navigation.css';
import 'swiper/modules/pagination.css';

// import required modules
import { Navigation, Pagination, Mousewheel } from 'swiper/modules';

const CustomSwiper = ({ children, onSlideChange }) => {
  return (
    <Swiper
      cssMode={true}
      navigation={true}
      pagination={true}
      mousewheel={true}
      modules={[Navigation, Pagination, Mousewheel]}
      onSlideChange={onSlideChange}
      style={{ paddingBottom: '40px' }}
    >
      {children}
    </Swiper>
  );
};

export default React.memo(CustomSwiper);
