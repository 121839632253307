import React from 'react'
import { connect } from 'umi'
import { Spin, Table, Button, Tabs, Drawer, Divider } from 'antd'
import moment from 'moment'
import { useIntl } from 'umi'
import AdminClockInRecord from 'components/ClockInRecord';
import TrainingStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/TrainingStatistics';
import BrainLineChart from 'cognitiveleap-core-us/components/Charts/ILSChart/BrainLineChart'
import CourseStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/CourseStatistics';
import RankingList from 'cognitiveleap-core-us/components/Charts/ILSChart/RankingList';
import FMA from 'cognitiveleap-core-us/components/Charts/ILSChart/MFA';
import BrainRecord from 'cognitiveleap-core-us/components/Charts/GroupChart/BrainRecord';
import MusicRecord from 'components/MusicRecord';
import Loading from 'components/Loading'
import style from './index.less';
import { history } from 'umi';
const { TabPane } = Tabs;

const OfflinePlan = ({ adminSubjectOfflinePlan, adminClockInRecord, loading, dispatch }) => {
  const intl = useIntl();

  const { planInfo, pagination } = adminSubjectOfflinePlan;
  const {
    isShowIlsRecord,
    ilstrainerRecord,
    ilsparentRecord,
    ilsappRecord,
    pagination: recordPagination,
    ilsActiveKey,
    shouldGetData,
    currentTablePlan,
    brainRecord,
    musicRecord
  } = adminClockInRecord;
  if (planInfo == null) return <Spin />

  const { items, totalCount } = planInfo;
  const isLoading = loading.effects['adminSubjectOfflinePlan/loadList'];
  const isRecordLoading = loading.effects['adminClockInRecord/getRecord'];
  const brainIsLoading = loading.effects['adminClockInRecord/getBrainRecord']
  const musicRecordIsLoading = loading.effects['adminClockInRecord/getMusicRecord']

  const getStatus = (status) => {
    switch (status) {
      case 'None':
        return intl.formatMessage({ id: 'notRealStart' })
      case 'Created':
        return intl.formatMessage({ id: 'created' })
      case 'Started':
        return intl.formatMessage({ id: 'ongoing' })
      case 'Ended':
        return intl.formatMessage({ id: 'finished' })
      case 'Feedbacked':
        return intl.formatMessage({ id: 'doneFeek' })
      default:
        return ''
    }
  }

  const formatTime = (time) => {
    return <span>{time ? moment(time).local().format('YYYY-MM-DD HH:mm') : ''}</span>
  }

  const goToPlanDetail = (record) => {
    const { id } = record
    history.push({
      pathname: '/system/manage/userOfflinePlanDetail',
      query: {
        id
      }
    })
  }

  const columns = [
    {
      title: '创建时间',
      dataIndex: 'creationTime',
      key: 'creationTime',
      render: time => formatTime(time),
    },
    {
      title: '训练计划名称',
      dataIndex: 'displayName',
      key: 'displayName'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: status => <span>{getStatus(status)}</span>,
    },
    {
      title: '当次课',
      dataIndex: 'currentNum',
      key: 'currentNum'
    },
    {
      title: '总课数',
      dataIndex: 'totalNum',
      key: 'totalNum'
    },
    {
      title: '前测状态',
      dataIndex: 'allAssessmentReady',
      key: 'allAssessmentReady',
      render: text => text,
    },
    {
      title: '正式开启时间',
      dataIndex: 'start',
      key: 'start',
      render: time => formatTime(time),
    },
    {
      title: '结束时间',
      dataIndex: 'end',
      key: 'end',
      render: time => formatTime(time),
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (text, record) => {
        return (
          <div>
            {
              record.start &&
              <div style={{ display: 'inline' }}>
                <a
                  onClick={() => {
                    dispatch({ type: 'adminClockInRecord/showChartRecord', payload: record })
                  }}
                >图表和反馈记录</a>
                <Divider type='vertical' />
              </div>
            }
            <a onClick={() => goToPlanDetail(record)}>计划详情</a>
            <Divider type='vertical' />
            {record.status === 'Ended' &&
              <a
                key="startPlan"
                onClick={()=>{ dispatch({ type: 'adminSubjectOfflinePlan/reStart', payload: { intl, id: record.id } });}}
              >
                {intl.formatMessage({ id: "startPlan" })}
              </a>
            }
          </div>
        )
      },
    }
  ]

  const dataSource = items.map(item => {
    let allAssessmentReady = '无前测';
    const { assessmentStatus = [] } = item
    if (assessmentStatus.length > 0) {
      const firstData = assessmentStatus.filter((item) => item.assessmentType == 'PreAssessment')
      if (firstData.length > 0) {
        const { userAssessment, isFinished } = firstData[0] || {};
        if (userAssessment) {
          const { status, needPay } = userAssessment || {};
          if (status === 'ReportedReady') {
            allAssessmentReady = '已完成';
            if (!needPay) {
              allAssessmentReady = '已获取报告';
            }
          }
        } else {
          allAssessmentReady = isFinished ? '已完成' : '未完成';
        }
      }
    }
    return {
      ...item,
      allAssessmentReady
    }
  })

  const lookIlsPlan = () => {
    dispatch({
      type: 'adminClockInRecord/updateState',
      payload: {
        isShowIlsRecord: false
      }
    })
  }

  const onChange = (current) => {
    dispatch({
      type: 'adminSubjectOfflinePlan/changePage',
      payload: {
        pagination: {
          currentPageSize: pagination.currentPageSize,
          currentPageIndex: current
        }
      }
    })
  }

  const getRecord = (key) => {
    const currentKey = key || ilsActiveKey;
    if (key != 'chart') {
      dispatch({
        type: 'adminClockInRecord/getRecord',
        payload: {
          id: currentKey == 'ilsapp' ? currentTablePlan && currentTablePlan.onlinePlanId : currentTablePlan && currentTablePlan.id,
          type: currentKey,
          current: ++recordPagination[currentKey].current,
          ilsType: key
        }
      })
    } else {
      dispatch({
        type: 'adminClockInRecord/updateState',
        payload: {
          shouldGetData: true
        }
      })
    }
  }

  const ilsparentPropsData = {
    dataSource: ilsparentRecord,
    loadData: getRecord,
    isLoading: isRecordLoading
  }

  const ilstrainerPropsData = {
    dataSource: ilstrainerRecord,
    loadData: getRecord,
    isLoading: isRecordLoading
  }

  const ilsappPropsData = {
    dataSource: ilsappRecord,
    loadData: getRecord,
    isLoading: isRecordLoading
  }

  const callback = () => {
    dispatch({
      type: 'adminClockInRecord/updateState',
      payload: {
        shouldGetData: false
      }
    })
  }

  const userOfflineTrainingPlanProps = {
    UserOfflineTrainingPlanId: currentTablePlan && currentTablePlan.id,
    shouldGetData,
    Loading,
    callback
  }

  const userTrainingPlanProps = {
    UserTrainingPlanId: currentTablePlan && currentTablePlan.onlinePlanId,
    shouldGetData,
    Loading
  }

  return (
    <div>
      {
        items.length === 0 ? '暂无开启训练' :
          <div className={style.offlinePlanTop}>
            <Table
              columns={columns}
              dataSource={dataSource}
              loading={isLoading}
              rowKey='id'
              pagination={{
                total: totalCount,
                pageSize: pagination.currentPageSize,
                current: pagination.currentPageIndex,
                onChange
              }}
            />
            <Drawer
              open={isShowIlsRecord}
              width={700}
              onClose={lookIlsPlan}
              title={<b>{`${currentTablePlan && currentTablePlan.displayName}  图表和反馈记录`}</b>}
            >
              <Tabs type="card"
                onChange={(key) => {
                  dispatch({
                    type: 'adminClockInRecord/updateState',
                    payload: {
                      ilsActiveKey: key
                    }
                  })
                  switch (key) {
                    case 'brain':
                      dispatch({ type: 'adminClockInRecord/getBrainRecord', payload: { id: currentTablePlan && currentTablePlan.id } });
                      break;
                    case 'music':
                      dispatch({ type: 'adminClockInRecord/getMusicRecord', payload: currentTablePlan })
                      break
                    default:
                      getRecord(key);
                  }
                }}
                activeKey={ilsActiveKey}
              >
                <TabPane tab='图表' key="chart">
                  <div style={{ width: '80%', margin: 'auto' }}>
                    <TrainingStatistics {...userOfflineTrainingPlanProps} />
                    {
                      currentTablePlan && currentTablePlan.audioType == 'BrainAudio' &&
                      <BrainLineChart {...userOfflineTrainingPlanProps} />
                    }
                    <CourseStatistics {...userOfflineTrainingPlanProps} />
                    {/* <RankingList {...userOfflineTrainingPlanProps} />
                    <FMA {...userTrainingPlanProps} /> */}
                  </div>
                </TabPane>
                <TabPane tab="训练师反馈" key="ilstrainer">
                  <AdminClockInRecord {...ilstrainerPropsData} />
                </TabPane>
                <TabPane tab="课时整体反馈" key="ilsparent">
                  <AdminClockInRecord {...ilsparentPropsData} />
                </TabPane>
                <TabPane tab="家长反馈" key="ilsapp">
                  <AdminClockInRecord {...ilsappPropsData} />
                </TabPane>
                {
                  currentTablePlan && currentTablePlan.audioType == 'BrainAudio' &&
                  <TabPane tab='优脑音乐收听情况' key="brain">
                    <BrainRecord brainRecord={brainRecord} isLoading={brainIsLoading} />
                  </TabPane>
                }
                {
                  currentTablePlan && currentTablePlan.audioType == 'SenseAudio' &&
                  <TabPane tab='音乐反馈' key="music">
                    <MusicRecord musicRecord={musicRecord} isLoading={musicRecordIsLoading} />
                  </TabPane>
                }
              </Tabs>
            </Drawer>
          </div>
      }
    </div>

  )
}

export default connect(({ adminSubjectOfflinePlan, adminSubjectDetail, loading, adminClockInRecord }) => ({
  adminSubjectOfflinePlan,
  adminSubjectDetail,
  loading,
  adminClockInRecord
}))(OfflinePlan)
