import React from 'react'
import { Modal, Form, Input, Radio, Select, Button } from 'antd'
import styles from './NewLocation.less'
import { useIntl } from 'umi'

const FormItem = Form.Item
const Option = Select.Option

const NewLocationModal = ({
  currentLocation,
  visible,
  onCancel,
  loading,
  onOk
}) => {
  const intl = useIntl();

  const onSubmit = (values) => {
    const postData = { ...currentLocation, ...values }
    onOk(postData)
  }

  const onQuit = () => {
    onCancel()
  }

  const prefixSelector = (
    <FormItem
      name={'CountryCode'}
      initialValue={currentLocation ? currentLocation.CountryCode : "86"}
      style={{ marginBottom: '0' }}
    >
      <Select style={{ width: 130 }}>
        <Option value="86">{intl.formatMessage({ id: 'china' })}(86)</Option>
        <Option value="972">{intl.formatMessage({ id: 'israel' })}(972)</Option>
        <Option value="52">{intl.formatMessage({ id: 'mexico' })}(52)</Option>
        <Option value="1">{intl.formatMessage({ id: 'unitedState' })}(1)</Option>
      </Select>
    </FormItem>
  )

  const showTitle = currentLocation ? intl.formatMessage({ id: 'editLocation' }) : intl.formatMessage({ id: 'newLocation' })

  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      open={visible}
      title={<span className={styles.addTestFormModalTitle}>{showTitle}</span>}
      okText={<span className={styles.addTestFormModalOK}>{intl.formatMessage({ id: 'submit' })}</span>}
      cancelText={<span className={styles.addTestFormModalCancel}>{intl.formatMessage({ id: 'cancel' })}</span>}
      onCancel={onQuit}
      className={styles.newSbjModal}
      zIndex={102}
      footer={null}
    >
      <Form
        layout="vertical"
        className={styles.addTesteeForm}
        onFinish={onSubmit}
      >
        <FormItem
          className={styles.addTestFormItem}
          label={intl.formatMessage({ id: 'email' })}
          name={'email'}
          initialValue={currentLocation ? currentLocation.Email : null}
        >
          <Input />
        </FormItem>

        <FormItem
          className={styles.addTestFormItem}
          label={intl.formatMessage({ id: 'phone' })}
          name={'Phone'}
          initialValue={currentLocation ? currentLocation.Phone : null}
        >
          <Input addonBefore={prefixSelector} />
        </FormItem>

        <FormItem
          className={styles.addTestFormItem}
          label={intl.formatMessage({ id: 'locationAddress' })}
          name={'Location'}
          initialValue={currentLocation ? currentLocation.Location : null}
        >
          <Input />
        </FormItem>

        <FormItem
          className={styles.addTestFormItem}
          label={intl.formatMessage({ id: 'locationName' })}
          name={'Name'}
          initialValue={currentLocation ? currentLocation.Name : null}
          rules={[{ required: true, message: `${intl.formatMessage({ id: 'locationName' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
        >
          <Input />
        </FormItem>
        <FormItem
          className={styles.addTestFormItem}
          label={intl.formatMessage({ id: 'locationStatus' })}
          name={'IsEnabled'}
          initialValue={currentLocation ? currentLocation.IsEnabled : false}
        >
          <Radio.Group>
            <Radio value={true}>{intl.formatMessage({ id: 'active' })}</Radio>
            <Radio value={false}>{intl.formatMessage({ id: 'inActive' })}</Radio>
          </Radio.Group>
        </FormItem>
        <div className={styles.footer}>
          <Button className={styles.footerLeft} onClick={onQuit}>取消</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default NewLocationModal
