import React from 'react'
import { connect } from 'umi'
import { Tabs, Spin } from 'antd'
import DietaryAnalysisChart from 'cognitiveleap-core-us/components/Charts/DietaryAnalysisChart';
import DietaryStructureBarChart from 'cognitiveleap-core-us/components/Charts/DietaryStructureBarChart';
import AdminClockInRecord  from 'components/ClockInRecord';
import AdminDataTop from 'components/DataTop';
import Loading from 'components/Loading'
import style from './index.less';
const { TabPane } = Tabs;

const Nutrition = ({ adminSubjectNutrition, adminSubjectDetail, adminClockInRecord, loading, dispatch }) => {
  const { planInfo } = adminSubjectNutrition;
  if (planInfo == null) return <Spin />;
  const isLoading = loading.effects['adminClockInRecord/getRecord'];
  const { activePlans, lastestTrainingPlan } = planInfo;
  const { subjectId } = adminSubjectDetail;
  const { nutritionRecord, pagination } = adminClockInRecord;

  const loadData = () => {
    lastestTrainingPlan && lastestTrainingPlan.id && 
      dispatch({ 
        type: 'adminClockInRecord/getRecord', 
        payload: { 
          id: lastestTrainingPlan && lastestTrainingPlan.id, 
          type: 'nutrition',
          current: ++pagination.nutrition.current
        }});
  }

  const propsData = {
    dataSource: nutritionRecord,
    loadData,
    isLoading,
  }

  const chartProps = {
    subjectId,
    from: 'pc',
    Loading
  }

  return (
    <div>      {activePlans.length === 0 ? '暂无开启训练' : 
    ///TODO 呈现用户数据
        <div>
          <AdminDataTop {...{lastestTrainingPlan,type:'admin'}} />
          <Tabs defaultActiveKey="chart" type="card" onChange={()=>{!nutritionRecord && loadData()}}>
            <TabPane tab="图表" key="chart">
              <center>
                <div className={style.chart}>
                  <DietaryAnalysisChart {...chartProps} />
                  <DietaryStructureBarChart {...chartProps} />
                </div>
              </center>
            </TabPane>
            <TabPane tab="打卡记录" key="record">
              <AdminClockInRecord { ...propsData } />
            </TabPane>
          </Tabs>
        </div>
    }</div>
  )
}

export default connect(({ adminSubjectNutrition, adminSubjectDetail, adminClockInRecord, loading }) => ({
  adminSubjectNutrition,
  adminSubjectDetail,
  adminClockInRecord,
  loading,
}))(Nutrition)
