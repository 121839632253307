
import { GetUserCourseAnalysis, DownloadUserCourseExcelApi } from 'services/rocketService/Analysis' 
import { message } from 'antd'
import download from 'cognitiveleap-core-us/utils/download'
import moment from 'moment'
import { IntlManager } from 'utils/helper';

export default {
  namespace: 'userClassAnalysis',
  state: {
    userClassAnalysisTablePagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    searchOption: {
      userNickName: '',
      courseName: '',
      openingTime: {
        startTime: '',
        endTime: ''
      }
    },
    courseAnalysisData: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {

    *initPage({ payload: _ }, { call, put, select }) {
      yield put({ type: 'getUserCourseAnalysis' })
    },

    *getUserCourseAnalysis({ payload: _ }, { call, put, select }) {
        const { userClassAnalysisTablePagination, searchOption } = yield select(state => state.userClassAnalysis)
        const { userNickName, courseName, openingTime } = searchOption
        const { pageSize, current }= userClassAnalysisTablePagination
        const params = {
          UserName: userNickName,
          CourseName: courseName,
          Start: openingTime.startTime,
          End: openingTime.endTime,
          SkipCount: (current - 1) * pageSize,
          MaxResultCount: pageSize
        }
        const res = yield call(GetUserCourseAnalysis, params)
        if (res.response.ok) {
          const { userCourse } = res.data
          const { totalCount } = userCourse || {}
          const newPagination = {
            ...userClassAnalysisTablePagination,
            total: totalCount
          }
          yield put({
            type: 'updateState',
            payload: {
              courseAnalysisData:res.data,
              userClassAnalysisTablePagination: newPagination
            }
          })
        }
    },

    *changeTable({ payload: data }, { call, put, select }){
      const { userClassAnalysisTablePagination: currentPagination,} = yield select(state => state.userClassAnalysis)
      const { pagination, filters= {} } = data
      yield put({
        type: 'updateState',
        payload: { 
          userClassAnalysisTablePagination: { ...currentPagination, ...pagination, },
          filters,
        },
      })
      yield put({ type: 'getUserCourseAnalysis' })
    },

    *onClear({ payload: data }, { call, put, select }){
      const searchOption = {
        userNickName: '',
        courseName: '',
        openingTime: {
          startTime: '',
          endTime: ''
        }
      }
      yield put({ type: 'updateState', payload: { searchOption } })
      yield put({ type: 'onSearch' })
    },

    *onSearch({ payload }, { call, put, select }){
      const userClassAnalysisTablePagination = {
        current: 1,
        pageSize: 10,
        total: 0
      }
      yield put({ type: 'updateState', payload: { userClassAnalysisTablePagination } })
      yield put({ type: 'getUserCourseAnalysis' })
    },

    *downloadExcel({ payload }, { call, put, select }){
      const formatMessage = IntlManager.intl.formatMessage
      const { searchOption } = yield select(state => state.userClassAnalysis)
      const { userNickName, courseName, openingTime } = searchOption
      const params = {
        UserName: userNickName,
        CourseName: courseName,
        Start: openingTime.startTime,
        End: openingTime.endTime
      }
      const hide = message.loading(formatMessage({ id: 'exxportListLoading' }), 0);
      const res = yield call(DownloadUserCourseExcelApi, params);
      if (res.response.ok) {
        const { response } = res;
        response.blob().then(blob => {
          download(blob, `课程分析+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
        })
      }
      setTimeout(hide, 0)
    }
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/analysis/userClass') {
    //       dispatch({ type: 'initPage' })
    //     }
    //   })
    // },
  },
}
