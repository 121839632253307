import React from 'react';
import styles from './index.less';
import { useIntl } from 'umi';
import { Row, Space } from 'antd';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import MoveLegend from 'components/NewVratChartComponents/MoveLegend';
import MovementPathMotionType from 'components/NewVratChartComponents/MovementPathMotionType';
import MovementPathBlocks from 'components/NewVratChartComponents/MovementPathBlocks';
import BottomPageNum from './components/BottomPageNum';
import headIcon from 'assets/vratReportImg/head_small_icon.png';
import handIcon from 'assets/vratReportImg/hand_small_icon.png';

const showHeadText = (headOutRanges, intl) => {
  const textArr = headOutRanges.map((item) => {
    const { Block } = item || {};
    if (Block === 1) {
      return intl.formatMessage({ id: 'FirstBlock' });
    } else if (Block === 2) {
      return intl.formatMessage({ id: 'TwoBlock' });
    } else if (Block === 3) {
      return intl.formatMessage({ id: 'ThreeBlock' });
    } else if (Block === 4) {
      return intl.formatMessage({ id: 'FourBlock' });
    }
  }).filter(item => item);

  const length = textArr.length;

  if (length === 0) {
    return intl.formatMessage({ id: 'MovementPath.Remained' });
  } else if (length === 4) {
    return intl.formatMessage({ id: 'MovementPath.Exceeded' });
  } else if (length === 3) {
    return intl.formatMessage({ id: 'MovementPath.SomeExceeded' }, { result: `${textArr[0]}, ${textArr[1]}, and ${textArr[2]}` });
  } else if (length === 1) {
    return intl.formatMessage({ id: 'MovementPath.SomeExceeded' }, { result: `${textArr[0]}` });
  } else {
    return intl.formatMessage({ id: 'MovementPath.SomeExceeded' }, { result: `${textArr.join(
      ' and ',
    )}` });
  }
};

const MovementArea = ({ data }) => {
  const intl = useIntl();

  //type 0->3 blockNum 1-4
  const GetByType = (list, type, blockNum) => {
    const result = list.filter((item) => {
      return item.MotionType === type;
    });

    const res = result.filter((item) => {
      return item.Block === blockNum;
    });
    return res;
  };

  const { Points = [], OutRanges = [] } = data || {};

  // 头部超出数据
  const headOutRanges = OutRanges.filter((item) => item.MotionType === 0);

  // Head block 1~4
  const showPointsHeadBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 0, 1),
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 0, 2),
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 0, 3),
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 0, 4),
        },
      },
    },
    motionIcon: headIcon,
    motionType: intl.formatMessage({ id: 'MovementPath.Head' }),
  };

  // Hand block 1~4
  const showPointsHandBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 1, 1),
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 1, 2),
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 1, 3),
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 1, 4),
        },
      },
    },
    motionIcon: handIcon,
    motionType: intl.formatMessage({ id: 'MovementPath.Hand' }),
  };

  const block1Props = {
    styles,
    number: 1,
  };
  const block2Props = {
    styles,
    number: 2,
  };
  const block3Props = {
    styles,
    number: 3,
  };
  const block4Props = {
    styles,
    number: 4,
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.movementArea}>
        <HeaderLine color="#3DBD7D" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'MovementPath' })}</div>
          <div className={styles.titleDes}>
            <div className={styles.desBold}>
              {intl.formatMessage({ id: 'MovementPath.DesBold' })}
            </div>

            <div>
              {intl.formatMessage({ id: 'MovementPath.FirstDes' })}{' '}
              <span className={styles.blueLines}>{intl.formatMessage({ id: 'MovementPath.BlueLine' })}</span>
              {intl.formatMessage({ id: 'MovementPath.TwoDes' })}
              <span className={styles.blueLines}>{intl.formatMessage({ id: 'MovementPath.BlueLine' })}</span>
              {intl.formatMessage({ id: 'MovementPath.ThreeDes' })}
              <span className={styles.greenLines}>{intl.formatMessage({ id: 'MovementPath.GreenLine' })} </span>
              {intl.formatMessage({ id: 'MovementPath.FourDes' })}
            </div>
          </div>
          <div className={styles.MovementPathContent}>
            <MovementPathMotionType {...showPointsHeadBlocksProps} />
            <MovementPathMotionType {...showPointsHandBlocksProps} />
          </div>
          <Row type="flex" justify="center">
            <div style={{ width: '126px' }}>
              <MovementPathBlocks {...block1Props} />
            </div>
            <div style={{ width: '126px' }}>
              <MovementPathBlocks {...block2Props} />
            </div>
            <div style={{ width: '126px' }}>
              <MovementPathBlocks {...block3Props} />
            </div>
            <div style={{ width: '126px' }}>
              <MovementPathBlocks {...block4Props} />
            </div>
          </Row>
          <div className={styles.echartLegend}>
            <MoveLegend />
          </div>
          <div className={styles.moveBottomDes}>
            {intl.formatMessage({ id: 'MovementPath.BottomDes' })}
            <div className={styles.bottomBold}>
              {showHeadText(headOutRanges, intl)}
            </div>
          </div>
        </div>
        <BottomPageNum num={16} />
        <BottomDes color="#3DBD7D" />
      </div>
    </div>
  );
};

export default MovementArea;
