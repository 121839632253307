import getRequest from 'utils/request'

const BrainMusicApi = '/api/rocketAdmin/brainMusic'
const PostImportMusicApi = '/api/rocketAdmin/brainMusic/importMusicSection/{brainMusicId}'

export async function PostBrainMusic(data) {
    return getRequest()(BrainMusicApi, {
        method: 'POST',
        data,
    })
}

export async function GetBrainMusic(data) {
    return getRequest()(BrainMusicApi, {
        method: 'GET',
        params: data
    })
}

export async function PutBrainMusic(data) {
    return getRequest()(BrainMusicApi, {
        method: 'PUT',
        data
    })
}

export async function DelBrainMusic(data) {
    return getRequest()(BrainMusicApi, {
        method: 'DELETE',
        params: data
    })
}

export async function PostImportMusic(data) {
    return getRequest()(PostImportMusicApi.replace('{brainMusicId}', data.brainMusicId), {
        method: 'POST',
        data
    })
}
