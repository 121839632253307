import ReactEcharts from 'echarts-for-react'
import { useIntl } from 'umi'

const PathLengthChart = ({ styles }) => {
  const intl = useIntl();
  const option = {
    color: ['#36abd3', '#A8DCBF'],
    legend: {
      data: [
        {
          name: intl.formatMessage({ id: 'report.pathLength.chart.legendU' }),
          icon: 'roundRect',
        },
        {
          name: intl.formatMessage({ id: 'report.pathLength.chart.legendM' }),
          icon: 'roundRect',
        },
      ],
      type: 'scroll',
      orient: 'vertical',
      icon: 'roundRect',
      selectedMode: false,
    },
    xAxis: {
      type: 'value',
      show: false,
    },
    yAxis: {
      show: false,
      type: 'value',
    },
    series: [
      {
        name: intl.formatMessage({ id: 'report.pathLength.chart.legendU' }),
        data: [],
        type: 'line',
      },
      {
        name: intl.formatMessage({ id: 'report.pathLength.chart.legendM' }),
        data: [],
        type: 'line',
      },
    ],
  }

  return (
    <ReactEcharts
      option={option}
      className={styles.pathlengthRegend}
      style={{ height: '50px', width: '200px' }}
    />
  )
}

export default PathLengthChart
