import { Card, List, Pagination } from 'antd'
import styles from '../detail.less'

const ProjectCards = ({ list, pagination, onPageChange, onGoToDetail }) => {
  const paginationProps = {
    ...pagination,
    onChange: onPageChange,
  }

  const onClickCard = (e, courseNum, id) => {
    e.stopPropagation()
    onGoToDetail(courseNum, id)
  }

  return (
    <div className={styles.coursewareWrap}>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={list}
        renderItem={item => {
          const { courseNum, courseWares, courseWareIds } = item
          return (
            <List.Item>
              <Card
                hoverable
                title={`第${courseNum}课`}
                type="inner"
                onClick={() => onGoToDetail(courseNum)}
              >
                <div className={styles.courseScroll}>
                  {courseWares.map(courseItem => {
                    const { id, title } = courseItem
                    return (
                      <div key={id} className={styles.courseName}>
                        <a onClick={e => onClickCard(e, courseNum, id)}>{title}</a>
                      </div>
                    )
                  })}
                </div>
              </Card>
            </List.Item>
          )
        }}
      />
      <Pagination {...paginationProps} />
    </div>
  )
}

export default ProjectCards
