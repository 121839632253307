import { message } from 'antd'
import moment from 'moment'
import { GetCertificateList, PostStopCertificate, PutTrainerCertificate, GetTrainerTrainingFeedback, DeleteCert, PostQuestionList } from 'services/trainingSystem'
import config from 'utils/config'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
const { baseURL, uploadCredentials } = config

let contentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: '证书编号',
            FormKey: 'credentialsCode',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
            },
            Description: '',
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: 'Maple',
                        Id: 'Maple',
                    },
                    {
                        Value: 'Emma',
                        Id: 'Emma',
                    },
                ],
                Required: true,
            },
            ShowTitle: '培训师',
            FormKey: 'trainerName',
            Description: null,
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: 'Maple',
                        Id: 'Maple',
                    },
                    {
                        Value: 'Emma',
                        Id: 'Emma',
                    },
                ],
                Required: true,
            },
            ShowTitle: '认证师',
            FormKey: 'certifier',
            Description: null,
        },
        // {
        //     EditorType: 'Input',
        //     ShowTitle: '认证师',
        //     FormKey: 'certifier',
        //     AdditionalData: null,
        //     Value: null,
        //     Setting: {
        //         Required: true,
        //     },
        //     Description: '',
        // },
        {
            EditorType: 'DatePicker',
            ShowTitle: '考核通过时间',
            FormKey: 'passTime',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
                DateType: 'date',
            },
            Description: null,
        },
        {
            EditorType: 'RangePicker',
            ShowTitle: '有效期',
            FormKey: 'startEndTime',
            AdditionalData: null,
            Value: null,
            Setting: {
                showTime: false,
                Required: true,
                format: 'YYYY/MM/DD'
            },
            Description: null,
        },
        {
            EditorType: 'Upload',
            Value: null,
            Setting: {
                UploadSetting: {
                    isSingle: true,
                    uploadType: 'image'
                },
                Action: baseURL + uploadCredentials,
                Header: getAuthHeader(),
                Required: true,
            },
            ShowTitle: '上传证书',
            FormKey: 'credential',
        }
    ]
}

export default {
    namespace: 'medalList',
    state: {
        list: [],
        userName: '',
        name: '',
        code: '',
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        content: contentMenu,
        tabActiveKey: '',
        tabListData: null,
        modalVisible: false,
        selectItem: null,
        questionData: null,
        drawerVisible: false
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query({ payload }, { call, put, take, select }) {
            const { pagination, userName, name, code } = yield select(state => state.medalList)
            const { current, pageSize } = pagination
            const params = {
                UserName: userName,
                Name: name,
                Code: code,
                SkipCount: (current - 1) * pageSize,
                MaxResultCount: pageSize
            }
            const res = yield call(GetCertificateList, params)
            if (res.response.ok) {
                const { totalCount, items } = res.data || {}
                yield put({ type: 'updateState', payload: { list: items, pagination: { ...pagination, total: totalCount } } })
            }
        },

        *changeTable({ payload: data }, { call, put, select }) {
            const { pagination } = data
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'query' })
        },

        *onClear({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { pagination, userName: '', name: '', code: '' } })
            yield put({ type: 'query' })
        },

        *stopCertificate({ payload }, { call, put, select }) {
            const { id } = payload || {}
            const params = { certificateId: id }
            const res = yield call(PostStopCertificate, params)
            if (res.response.ok) {
                message.success('已取消认证')
                yield put({ type: 'query' })
            }
        },

        *deleteCert({ payload }, { call, put, select }) {
            const { id } = payload || {}
            const params = { certificateId: id }
            const res = yield call(DeleteCert, params)
            if (res.response.ok) {
                message.success('删除成功')
                yield put({ type: 'query' })
            }
        },

        *showSelectItem({ payload }, { call, put, select }) {
            const { startTime, endTime } = payload || {}
            let emptyContent = contentMenu
            const editUserInfoForm = matchDynamicForm(emptyContent, {
                ...payload,
                startEndTime: [moment(startTime), moment(endTime)]
            })
            yield put({
                type: 'updateState',
                payload: {
                    modalVisible: true,
                    content: editUserInfoForm,
                    selectItem: payload
                }
            })
        },

        *onsubmit({ payload }, { call, put, select }) {
            const { selectItem } = yield select(state => state.medalList)
            const { startEndTime } = payload
            const params = {
                ...selectItem,
                ...payload,
                startTime: startEndTime[0],
                endTime: startEndTime[1],
            }
            const res = yield call(PutTrainerCertificate, params)
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { modalVisible: false } })
                yield put({ type: 'query' })
            } else {
                const { error } = res.data
                const { message: errorInfo } = error || {}
                errorInfo && message.error(errorInfo)
            }
        },

        *changeTab({ payload }, { call, put, select }) {
            const { id } = payload;
            yield put({ type: 'updateState', payload: { tabActiveKey: id } })
            const { selectItem: { trainerId, trainingModuleId } } = yield select(state => state.medalList)
            const GetFeedbackRes = yield call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId, QuestionnaireId: id })
            if (GetFeedbackRes.data) {
                const GetFeedbackResData = GetFeedbackRes.data
                yield put({ type: 'updateState', payload: { questionData: GetFeedbackResData } })
            } else {
                yield put({ type: 'updateState', payload: { questionData: null } })
            }
        },

        *trainingFeedback({ payload }, { call, put, select }) {
            const { trainingModule: { questionnaires }, trainerId, trainingModuleId, questionnaireId: queryId } = payload || {}
            if (questionnaires && questionnaires.length > 0) {
                const questionnaireId = queryId || questionnaires[0]
                yield put({ type: 'updateState', payload: { tabActiveKey: questionnaireId } })
                const PostQuestionRes = yield call(PostQuestionList, { QuestionnaireIds: questionnaires })
                if (PostQuestionRes.response.ok) {
                    const questionListInfo = PostQuestionRes.data
                    const showTabList = questionnaires.map(id => {
                        return questionListInfo.find(item => item.id === id)
                    })
                    yield put({ type: 'updateState', payload: { tabListData: showTabList, selectItem: payload, drawerVisible: true } })
                    const GetFeedbackRes = yield call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId, QuestionnaireId: questionnaireId })
                    if (GetFeedbackRes.data) {
                        const GetFeedbackResData = GetFeedbackRes.data
                        yield put({ type: 'updateState', payload: { questionData: GetFeedbackResData } })
                    } else {
                        yield put({ type: 'updateState', payload: { questionData: null } })
                    }
                } else {
                    const { error } = PostQuestionRes.data
                    const { message: errorInfo } = error || {}
                    errorInfo && message.error(errorInfo)
                }
            } else {
                message.error("暂无培训反馈")
            }
        },
    },
    subscriptions: {
        // setupHistory({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/manage/medal') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
