import React from 'react';
import styles from './index.less';
import qrCode from 'assets/newVratReportImg/qrCode.png';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import BottomPageNum from './components/BottomPageNum';
import { useIntl, getLocale } from 'umi';
import sign from 'assets/newVratReportImg/sign.png';

const getNumStyle = (num) => {
  const data = Math.round(num);
  let color = '#48C969';
  if (data >= 0 && data <= 33) {
    color = '#ED3F24';
  } else if (data >= 34 && data <= 66) {
    color = '#FEBD10';
  }
  return <span style={{ color }}>{data}</span>;
};

const tableStyle = {
  'zh-CN': {
    fontSize: '13px',
    lineHeight: '13px'
  },
  'en-US': {
    fontSize: '12px',
    lineHeight: '13px'
  },
  'es-ES': {
    fontSize: '12px',
    lineHeight: '12px'
  }
}

const signContentStyle = {
  'zh-CN': {
    fontSize: '14px',
  },
  'en-US': {
    fontSize: '14px',
  },
  'es-ES': {
    fontSize: '12px',
  }
}

const signContentTitleStyle = {
  'zh-CN': {
    minWidth: '70px',
  },
  'en-US': {
    minWidth: '120px',
  },
  'es-ES': {
    minWidth: '170px',
  }
}

const lineHeightList = {
  'zh-CN': '26px',
  'en-US': '20px',
  'es-ES': '14px'
}

const fontSizeList = {
  'zh-CN': '18px',
  'en-US': '16px',
  'es-ES': '16px'
}

const trWidthList = {
  first: {
    'zh-CN': '140px',
    'en-US': '151px',
    'es-ES': '140px'
  },
  second: {
    'zh-CN': '500px',
    'en-US': '500px',
    'es-ES': '400px'
  },
  third: {
    'zh-CN': '160px',
    'en-US': '95px',
    'es-ES': '100px'
  },
  fourth: {
    'zh-CN': '100px',
    'en-US': '80px',
    'es-ES': '85px'
  },
  firstTd: {
    'zh-CN': '110px',
    'en-US': '100px',
    'es-ES': '110px'
  }
}

const tdExplanationStyle = {
  textAlign: 'left',
  fontFamily: 'RobotoRegular',
  fontWeight: 'normal',
  lineHeight: lineHeightList[getLocale()]
};

const trExplanationStyle = (index) => {
  return {
    fontSize: fontSizeList[getLocale()],
    width: trWidthList[index][getLocale()],
    wordBreak: 'break-all'
  }
}

const numStyle = { fontSize: '16px' };

const FullScore = ({
  dataSource,
  isScreenReport,
  screeningCPTFlags = {},
  screeningMotionFlags = {}
}) => {
  const intl = useIntl();

  const getFlagStyle = (key) => {
    const flagData = {
      ...screeningCPTFlags,
      ...screeningMotionFlags
    }

    const { NotValid = 1 } = flagData || {};

    if (NotValid === 1) {
      return <span style={{ color: 'gray' }}>-</span>
    }

    return flagData[key] ? <img src={sign}/> : null
  }

  const showTextList = [
    {
      title: intl.formatMessage({ id: 'FullScore.FlagExplanation' }),
      des: intl.formatMessage({ id: 'FullScore.FlagExplanationDes' }),
    },
    {
      title: intl.formatMessage({ id: 'FullScore.Disclaimer' }),
      des: intl.formatMessage({ id: 'FullScore.DisclaimerDes' }),
    }
  ];

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.fullScoreResult}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.summaryTitle}>
            {intl.formatMessage({ id: 'FullScore.title' })}
          </div>
          <table style={tableStyle[getLocale()]}>
            <tr
              style={{
                backgroundColor: '#00C0D7',
                color: 'white',
                height: '65px',
              }}
            >
              <th></th>
              <th
                style={{
                  lineHeight: '20px',
                  ...trExplanationStyle('first')
                }}
              >
                {intl.formatMessage({ id: 'FullScore.parameter' })}
              </th>
              <th
                style={{
                  lineHeight: '20px',
                  ...trExplanationStyle('second')
                }}
              >
                {intl.formatMessage({ id: 'FullScore.explanation' })}
              </th>
              <th
                style={{
                  lineHeight: '20px',
                  ...trExplanationStyle('third'),
                  wordBreak: 'normal'
                }}
              >
                {intl.formatMessage({ id: 'FullScore.percentile' })}
              </th>
              {
                isScreenReport && (
                  <th
                    style={{
                      lineHeight: '20px',
                      ...trExplanationStyle('fourth'),
                      wordBreak: 'normal'
                    }}
                  >
                    {intl.formatMessage({ id: 'FullScore.indicator' })}
                  </th>
                )
              }
            </tr>
            <tr>
              <td style={{ lineHeight: '20px', ...trExplanationStyle('firstTd') }} rowSpan={2}>
                {intl.formatMessage({ id: 'FullScore.overall' })}
              </td>
              <td style={{ textAlign: 'left' }}>
                {intl.formatMessage({ id: 'FullScore.attention' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.distractions' })}
              </td>
              <td style={numStyle}>{getNumStyle(dataSource['API'])}</td>
              {
                isScreenReport && (
                  <td style={{...numStyle, color: 'gray'}}>-</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left', lineHeight: '20px' }}>
                {intl.formatMessage({ id: 'FullScore.motion' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.hyperactivity'})}
              </td>
              <td style={numStyle}>{getNumStyle(dataSource['MPI'])}</td>
              {
                isScreenReport && (
                  <td style={{...numStyle, color: 'gray'}}>-</td>
              )}
            </tr>
            <tr>
              <td
                rowSpan={7}
                style={{
                  backgroundColor: '#effdfc',
                  lineHeight: '20px',
                  ...trExplanationStyle('firstTd')
                }}
              >
                {intl.formatMessage({ id: 'FullScore.scores' })}
              </td>
              <td style={{ textAlign: 'left' }}>
                {intl.formatMessage({ id: 'FullScore.focused' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.due' })}
              </td>
              <td style={numStyle}>
                {getNumStyle(dataSource['FocusedAttention'])}
              </td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('OmissionErrors')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left', lineHeight: '20px' }}>
                {intl.formatMessage({ id: 'FullScore.self' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.errors' })}
              </td>
              <td style={numStyle}>{getNumStyle(dataSource['SelfControl'])}</td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('CommissionErrors')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left', lineHeight: '20px' }}>
                {intl.formatMessage({ id: 'FullScore.accuracy' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.answers' })}
              </td>
              <td style={numStyle}>{getNumStyle(dataSource['Accuracy'])}</td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('Accuracy')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left', lineHeight: '20px' }}>
                {intl.formatMessage({ id: 'FullScore.discriminability' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.discriminabilitys' })}
              </td>
              <td style={numStyle}>{getNumStyle(dataSource['Discernment'])}</td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('Dprime')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left', lineHeight: '20px' }}>
                {intl.formatMessage({ id: 'FullScore.speed' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.stimuli' })}
              </td>
              <td style={numStyle}>{getNumStyle(dataSource['MentalSpeed'])}</td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('Latency')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left', lineHeight: '20px' }}>
                {intl.formatMessage({ id: 'FullScore.stability' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.processing' })}
              </td>
              <td style={numStyle}>
                {getNumStyle(dataSource['MentalStability'])}
              </td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('Variability')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left', lineHeight: '20px' }}>
                {intl.formatMessage({ id: 'FullScore.endurance' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.enduranceScore' })}
              </td>
              <td style={numStyle}>
                {getNumStyle(dataSource['MentalEndurance'])}
              </td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('Continuity')}</td>
              )}
            </tr>
            <tr>
              <td
                rowSpan={4}
                style={{
                  backgroundColor: 'white',
                  lineHeight: '20px',
                  ...trExplanationStyle('firstTd')
                }}
              >
                {intl.formatMessage({ id: 'FullScore.motor' })}
              </td>
              <td style={{ textAlign: 'left' }}>
                {intl.formatMessage({ id: 'FullScore.activityIndex' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.restlessness' })}
              </td>
              <td style={numStyle}>{getNumStyle(dataSource['FidgetIndex'])}</td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('TemporalScaling')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>
                {intl.formatMessage({ id: 'FullScore.areaIndex' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.movements' })}
              </td>
              <td style={numStyle}>
                {getNumStyle(dataSource['MotionAreaIndex'])}
              </td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('Area')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>
                {intl.formatMessage({ id: 'FullScore.direction' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.tracking' })}
              </td>
              <td style={numStyle}>
                {getNumStyle(dataSource['DirectionTracking'])}
              </td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('TimeAtBoard')}</td>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>
                {intl.formatMessage({ id: 'FullScore.distance' })}
              </td>
              <td style={tdExplanationStyle}>
                {intl.formatMessage({ id: 'FullScore.position' })}
              </td>
              <td style={numStyle}>
                {getNumStyle(dataSource['MovementDistance'])}
              </td>
              {
                isScreenReport && (
                  <td style={numStyle}>{getFlagStyle('TotalDisplacement')}</td>
              )}
            </tr>
            {/* <tr>
              <td style={{ textAlign: 'left' }}>
                Movement Path - How much movement was made away from the center
              </td>
              <td>{getNumStyle(area)}</td>
            </tr> */}
          </table>
          {/* 二维码图片 */}
          {/* <div className={styles.bottomImg}>
            <div className={styles.imgItem}>
              <img src={qrCode} />
              <div className={styles.imgTitle}>
                Learn more about your child's score
              </div>
            </div>

            <div className={styles.imgItem}>
              <img src={qrCode} />
              <div className={styles.imgTitle}>Connect with a professional</div>
            </div>

            <div className={styles.imgItem}>
              <img src={qrCode} />
              <div className={styles.imgTitle}>
                Learn more about Emotion and Cognitive Training
              </div>
            </div>
          </div> */}
          <div className={styles.bottomDes}>
            <span className={styles.low}>
              {intl.formatMessage({ id: 'FullScore.low' })}
            </span>
            <span className={styles.average}>
              {intl.formatMessage({ id: 'FullScore.average' })}
            </span>
            <span className={styles.high}>
              {intl.formatMessage({ id: 'FullScore.high' })}
            </span>
          </div>
          {
            isScreenReport && (
              <div className={styles.signContent} style={signContentStyle[getLocale()]}>
                {
                  showTextList.map(item => {
                    const { title, des } = item || {};
                    return (
                      <div className={styles.signItem} key={title}>
                        <div className={styles.signTitle} style={signContentTitleStyle[getLocale()]}>{title}</div>
                        <div className={styles.signDes}>{des}</div>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        </div>
        <BottomPageNum num={4} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default FullScore;
