import pages from 'utils/pages'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { GetAreaList, PostTenantData, PutTenantData, PrepareForEdit, PostSendEmail, PutTenantState } from 'services/tenant'
import { message } from 'antd'
const { areaList } = pages

const contentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: 'name',
            FormKey: 'name',
            AdditionalData: null,
            Value: '',
            Setting: {
                Required: true,
            },
            Description: null,
        },
        {
            EditorType: 'Input',
            ShowTitle: 'displayName',
            FormKey: 'displayName',
            AdditionalData: null,
            Value: '',
            Setting: {
                Required: false,
            },
            Description: null,
        },
        {
            EditorType: 'Input',
            ShowTitle: 'adminEmail',
            FormKey: 'adminEmail',
            AdditionalData: null,
            Value: '',
            Setting: {
                Required: true,
                customValidator: (rule, value, callback) => {
                    if (!/^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/.test(value)) {
                      callback('请输入有效邮箱')
                    } else {
                      callback()
                    }
                }
            },
            Description: null,
        },
        {
            EditorType: 'Input',
            ShowTitle: 'adminPhone',
            FormKey: 'adminPhone',
            AdditionalData: null,
            Value: '',
            Setting: {
                Required: false,
            },
            Description: null,
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: 'Effective',
                        Id: 'Effective',
                    },
                    {
                        Value: 'Invalid',
                        Id: 'Invalid',
                    },
                ],
                Required: true,
            },
            ShowTitle: 'state',
            FormKey: 'state',
            Description: null,
        },
    ],
}

export default {
    namespace: 'areaList',

    state: {
        list: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        modalVisible: false,
        content: null,
        modalType: 'edit',
        selectItem: null,
    },

    effects: {
        *query(_, { take, put, call, select }) {
            const { pagination } = yield select(state => state.areaList)
            let query = { skipCount: 0, maxResultCount: 10 }

            const currentPageIndex = pagination.current
            const currentPageSize = pagination.pageSize
            query = {
                ...query,
                skipCount: (currentPageIndex - 1) * currentPageSize,
                maxResultCount: currentPageSize,
            }

            const res = yield call(GetAreaList, query)
            if (res.response.ok) {
                const { items, totalCount } = res.data
                const newPagination = {
                    current: currentPageIndex,
                    pageSize: currentPageSize,
                    total: totalCount,
                }
                yield put({
                    type: 'updateState',
                    payload: { pagination: newPagination, list: items },
                })
            }
        },

        *onAdd(_, { call, put, select }) {
            let emptyContent = null
            emptyContent = contentMenu
            yield put({
                type: 'updateState',
                payload: { modalType: 'add', content: emptyContent, modalVisible: true },
            })
        },

        *onSubmitModal({ payload: data }, { take, put, call, select }) {
            const { modalType, selectItem } = yield select(state => state.areaList)
            let res
            if (modalType === 'edit') {
                res = yield call(PutTenantData, { ...selectItem, ...data })
            } else {
                res = yield call(PostTenantData, data, 'Area')
            }
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { modalVisible: false } })
                yield put({ type: 'query' })
            }
            else {
                const { error } = res.data
                message.error(error.message)
            }
        },

        *onEdit({ payload: id }, { call, put, select }) {
            let emptyContent = null
            const res = yield call(PrepareForEdit, { id, type: 'Area' })
            console.log('编辑', res);
            if (res.response.ok) {
                emptyContent = contentMenu
                const { tenant } = res.data
                const editUserInfoForm = matchDynamicForm(emptyContent, res.data.tenant)
                yield put({
                    type: 'updateState',
                    payload: {
                        content: editUserInfoForm,
                        modalType: 'edit',
                        modalVisible: true,
                        selectItem: res.data.tenant,
                    },
                })
            }
        },

        *onSendEmail({ payload: id }, { call, put, select }) {
            const res = yield call(PostSendEmail, id)
            if (res.response.ok) {
                message.success('发送邮件成功')
                yield put({ type: 'query' })
            } else {
                message.error('发送邮件失败')
            }
        },

        *changeTable({ payload: data }, { call, put, select }) {
            const { pagination } = data
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'query' })
        },
    },

    reducers: {

        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === areaList) {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
