import {
  GetAssessmentGrantTenantsList,
  PutAssessmentBatchTenantsGrant,
  GetOfflineTrainingPlanGrantTenantsList,
  PutOfflineTrainingPlanGrantTenantsList,
} from 'services/rocketService/Assessment';

export default {
  namespace: 'authorCenter',
  state: {
    id: '',
    title: '',
    type: '',
    selectModalVisible: false,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    data: [],
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *query({ payload }, { take, put, call, select }) {
      const { title, id, type } = payload;
      yield put({ type: 'updateState', payload: { title, type, id } });

      yield put({ type: 'queryList' });
    },

    *queryList({ payload }, { take, put, call, select }) {
      const { id, type } = yield select((state) => state.authorCenter);

      // 获取已授权的数据
      const params = {
        Id: id,
        SkipCount: 0,
        MaxResultCount: 999,
      };
      const res = yield call(
        type === 'offlinePlan'
          ? GetOfflineTrainingPlanGrantTenantsList
          : GetAssessmentGrantTenantsList,
        params,
      );

      if (res.response.ok) {
        const { items = [], totalCount = 0 } = res.data || {};
        yield put({
          type: 'updateState',
          payload: {
            data: items,
            pagination: { current: 1, pageSize: totalCount, total: totalCount },
          },
        });
      }
    },

    *submit({ payload }, { take, put, call, select }) {
      const { id, type } = yield select((state) => state.authorCenter);
      const params = {
        tenantIds: payload,
        resourceKey: id,
      };

      const res = yield call(
        type === 'offlinePlan'
          ? PutOfflineTrainingPlanGrantTenantsList
          : PutAssessmentBatchTenantsGrant,
        params,
      );

      if (res.response.ok) {
        yield put({ type: 'queryList', payload: { id } });
      }

      yield put({
        type: 'updateState',
        payload: {
          selectModalVisible: false,
        },
      });
    },

    *delete({ payload }, { take, put, call, select }) {
      const { id, type } = yield select((state) => state.authorCenter);
      console.log('删除', payload);
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //     return history.listen(location => {
    //         if (location.pathname === '/system/manage/authorCenter') {
    //             dispatch({ type: 'query', payload: location.query })
    //         }
    //     })
    // },
  },
};
