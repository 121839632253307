import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Spin, message } from 'antd';
import { history, connect } from 'umi';
import { GetChildListDetail, PostRestart } from 'services/group';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import ChartsDrawer from 'components/ClassGroupModal/ChartsDrawer';
import { useIntl } from 'umi'
const leftTempSupport = {
  userName: {
    showText: '儿童姓名',
    showType: 'Text',
    render: (text, record) => {
      return (
        <a
          onClick={() => {
            history.push({
              pathname: '/system/manage/subjectDetail',
              query: {
                id: record.id,
              },
            });
          }}
        >
          {text}
        </a>
      );
    },
  },
  gender: {
    showText: '性别',
    showType: 'Text',
    render: (text, record) => {
      if (text === 'Female') {
        return '女';
      } else if (text === 'Male') {
        return '男';
      } else {
        return '其他';
      }
    },
  },
  ageStage: {
    showText: '阶段',
    showType: 'Text',
    render: (text, record) => {
      if (text === 'PreSchool') {
        return '学前';
      } else if (text === 'GradeSchool') {
        return '学龄';
      } else if (text === 'TeenSchool') {
        return '青少年';
      } else if (text === 'GradeOrTeenSchool') {
        return '学龄/青少年';
      }
      return '其他';
    },
  },
  birthday: {
    showText: '出生日期',
    showType: 'Time',
  },
  creationTime: {
    showText: '最近入班时间',
    showType: 'Time',
  },
};

const rightListSupport = {
  name: {
    showText: '训练计划名称',
    showType: 'Text',
  },
  currentNum: {
    showText: '进度',
    showType: 'Text',
  },
  status: {
    showText: '状态',
    showType: 'Text',
    render: (text, record) => {
      if (text === 'Started') {
        return '进行中';
      } else if (text === 'Created') {
        return '已创建';
      } else {
        return '已结束';
      }
    },
  },
  startTime: {
    showText: '开启时间',
    showType: 'Time',
  },
  endTime: {
    showText: '结束时间',
    showType: 'Time',
  },
};

const { TabPane } = Tabs;

const ExpandedTable = ({ allData, currentUser, getListData }) => {
  const intl = useIntl();
  const [groupLinkUsers, setGroupLinkUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { groupTrainingPlans = [], id } = allData;

  const currentRecord = useRef(null);

  const initData = async () => {
    setLoading(true);
    const res = await GetChildListDetail({
      groupId: id,
    });

    if (res.response.ok) {
      setGroupLinkUsers(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [allData]);

  const leftTableProps = {
    listData: groupLinkUsers.map((item) => {
      const { subjectInfoReturnModel = {} } = item;
      const { gender } = subjectInfoReturnModel;
      return {
        ...item,
        gender,
      };
    }),
    listSupport: leftTempSupport,
    Actions: [
      {
        showText: '详情',
        itemRender(record) {
          return (
            <a
              onClick={() => {
                history.push({
                  pathname: '/system/manage/subjectDetail',
                  query: {
                    id: record.id,
                  },
                });
              }}
            >
              详情
            </a>
          );
        },
      },
    ],
  };

  const rightTableProps = {
    listData: groupTrainingPlans.map((item) => {
      const { trainingPlanV2 } = item;
      const { displayName: name } = trainingPlanV2;
      return {
        ...item,
        name,
      };
    }),
    listSupport: rightListSupport,
    Actions: [
      {
        showText: '图表和反馈记录',
        itemRender(record) {
          return (
            <a
              onClick={() => {
                currentRecord.current = record;
                setVisible(true);
              }}
            >
              图表和反馈记录
            </a>
          );
        },
      },
      {
        showText: '训练总览',
        itemRender(record) {
          const { groupId, id, name, creatorId } = record;
          return (
            <a
              onClick={() => {
                history.push({
                  pathname: '/system/manage/detail/groupOfflinePlanDetail',
                  query: {
                    id,
                    displayName: encodeURIComponent(name),
                    groupId,
                    creatorId
                  },
                });
              }}
            >
              训练总览
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'startPlan' }),
        itemRender(record) {
          const { status, id } = record;
          return (
            status === 'Ended' && (
              <a
                key="startPlan"
                onClick={() => {
                  PostRestart({ groupTrainingPlanId: id })
                    .then((res) => {
                      const { ok } = res.response || {}
                      const { error } = res.data || {}
                      if(ok) {
                        message.success(intl.formatMessage({ id: 'successStart' }));
                        getListData();
                      } else {
                        message.error(error.message);
                      }
                    })
                }}
              >
                {intl.formatMessage({ id: 'startPlan' })}
              </a>
            )
          );
        },
      },
    ],
  };

  const chartDrawerProps = {
    visible,
    onClose: () => {
      setVisible(false);
    },
    currentNum: currentRecord?.current?.currentNum,
    currentTablePlan:
      currentRecord.current && currentRecord.current.trainingPlanV2,
    GroupTrainingPlanId: currentRecord.current && currentRecord.current.id,
  };

  return (
    <>
      <Tabs defaultActiveKey="1" type="card" size="small">
        <TabPane tab="儿童" key="1">
          <Spin spinning={loading}>
            <MagicTable {...leftTableProps} />
          </Spin>
        </TabPane>
        <TabPane tab="训练计划" key="2">
          <MagicTable {...rightTableProps} />
        </TabPane>
      </Tabs>
      {visible && <ChartsDrawer {...chartDrawerProps} />}
    </>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  ExpandedTable,
);
