import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { connect } from 'umi';
import ReactEcharts from 'echarts-for-react';
import { InfoCircleOutlined } from '@ant-design/icons';
import DocumentTitle from 'react-document-title';
import { TinyArea, TinyColumn, Donut } from '@ant-design/charts';
import { Col, Row, Tooltip, DatePicker, Card, message } from 'antd';
import { ChartCard, Field } from './Charts';
import { getTimeDistance } from 'utils/utils';
import { toMoney, getMonths } from 'cognitiveleap-core-us/utils/utils';

import {
  GetILSTotalData,
  GetVRATTotalData,
  GetFasData,
  GetVRATAnalysis, // VRAT报告数
  GetILSAnalysis, // 耗课数
  GetOneToMoreTrainingAnalsis, // 一对多耗课数
  PostOneToMoreTrainingStatistics,
  GetHFSAnalysis, // HFS 耗费
} from 'services/rocketService/Analysis';
import { GetContractList } from 'services/contract';

import { history, useIntl } from 'umi';

import styles from './index.less';

const { RangePicker } = DatePicker;

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: {
    marginBottom: 24,
  },
};

const DataPanel = ({ loading, visitData, currentUser }) => {
  const intl = useIntl();

  const { auth } = currentUser || {};
  const { grantedPolicies } = auth || {};
  const hasCourseQuantityPermission =
    grantedPolicies['PFE.Overview.StatisticalOverview.CourseQuantity']; // 耗课数
  const hasVratReportQuantityPermission =
    grantedPolicies['PFE.Overview.StatisticalOverview.VratReportQuantity']; // vCAT报告数
  const hasFasReportQuantityPermission =
    grantedPolicies['PFE.Overview.StatisticalOverview.FasReportQuantity']; // FAS报告数
  const hasCumulativeConsumptionPermission =
    grantedPolicies['PFE.Overview.StatisticalOverview.CumulativeConsumption']; // 今日消费额(¥)
  const hasDataTrendPermission =
    grantedPolicies['PFE.Overview.StatisticalOverview.DataTrend']; // 数据趋势
  const hasHfsConsumptionProfilePermission =
    grantedPolicies['PFE.Overview.StatisticalOverview.HfsConsumptionProfile']; // HFS消费概况

  const getMonth = () => {
    const currentMonth = moment().month() + 1;
    return {
      currentMonth,
      monthDays: moment().daysInMonth(),
    };
  };

  const [classNum, setClassNum] = useState({});
  const [vratNum, setVratNum] = useState({});
  const [consumeNum, setConsumeNum] = useState({});
  const [fasNum, setFasNum] = useState({});
  // const [childNum, setChildNum] = useState({});

  const [optionInfo, setOptionInfo] = useState({
    xData: [],
  });
  const [hfsOptionInfo, setHfsOptionInfo] = useState({
    xData: [],
  });

  const [salesData, setSalesData] = useState([]);
  const [salesHFSData, setSalesHFSData] = useState([]);

  const [isEmpty, setIsEmpty] = useState(false);
  const [isHFSEmpty, setIsHFSEmpty] = useState(false);

  const rangePickerValue = useRef(getTimeDistance('month'));
  const rangeHFSPickerValue = useRef(getTimeDistance('month'));

  const [consumptionData, setConsumptionData] = useState({});

  const getClassNum = async () => {
    const res = await Promise.all([
      GetILSTotalData(),
      PostOneToMoreTrainingStatistics({
        GroupTypes: [],
      }),
    ]);

    if (res.every(item => item.response.ok)) {
      const oneToOneData = res[0]?.data || {};
      const oneToMoreData = res[1]?.data || {};

      const { courseQuantity, courseQuantityToday } = oneToOneData;
      const { courseQuantity: oneToMoreCourseQuantity = 0, courseQuantityToday: oneToMoreCourseQuantityToday = 0 } = oneToMoreData;
      setClassNum({
        ...oneToOneData,
        courseQuantity: courseQuantity + oneToMoreCourseQuantity,
        courseQuantityToday: courseQuantityToday + oneToMoreCourseQuantityToday
      });
    } else {
      const { error } = res[0].data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getVratNum = async () => {
    const res = await GetVRATTotalData();
    if (res.response.ok) {
      setVratNum(res.data);
    } else {
      const { error } = res.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getConsumeNum = async () => {
    const res = await GetContractList();
    if (res.response.ok) {
      setConsumeNum(res.data);
    } else {
      const { error } = res.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getFasNum = async () => {
    const res = await GetFasData();
    if (res.response.ok) {
      setFasNum(res.data);
    } else {
      const { error } = res.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  // const getChildNum = async () => {
  //   const res = await GetChildrenTotalData();
  //   if (res.response.ok) {
  //     setChildNum(res.data);
  //   } else {
  //     const { error } = res.data || {
  //       error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
  //     };
  //     message.error(error.message);
  //   }
  // };

  const initData = () => {
    const { currentMonth, monthDays } = getMonth();
    let newListData = [];
    for (let index = 1; index <= monthDays; index++) {
      newListData.push(`${currentMonth}/${index}`);
    }
    setOptionInfo({
      xData: newListData,
      xName: `${intl.formatMessage({ id: 'date' })}`,
      yName: `${intl.formatMessage({ id: 'quantity' })}`,
    });

    setHfsOptionInfo({
      xData: newListData,
      xName: `${intl.formatMessage({ id: 'date' })}`,
      yName: `${intl.formatMessage({ id: 'amount' })}($)`,
    });
  };

  const setMonthSalesData = async () => {
    // 默认展示月
    const params = {
      Type: 'Month',
      Year: parseInt(moment().year()),
      Month: parseInt(moment().month() + 1),
    };
    const res = await Promise.all([
      GetILSAnalysis(params),
      GetVRATAnalysis(params),
      GetOneToMoreTrainingAnalsis(params),
    ]);
    initData();
    setIsEmpty(true);
    if (res.every((item) => item.response.ok)) {
      const classNum = res[0].data.trend;
      const vratNum = res[1].data.Trend;
      const oneToMoreClassNum = res[2].data.trend;
      if (classNum.length !== 0 || vratNum.length !== 0 || oneToMoreClassNum.length !== 0) {
        setIsEmpty(false);
        findSetSalesData(res);
      }
    }
  };

  const getNewData = (data) => {
    let { typeChart } = data;
    // 一对一，一对二统称归为一对多数据
    typeChart = typeChart.map(item => {
      let { type } = item || {};

      const lowerCaseType = type.toLowerCase();

      if (lowerCaseType.includes('oneto')) {
        if (lowerCaseType.includes('offline')) {
          type = 'OneToMoreOfflineCourse'
        } else if (lowerCaseType.includes('online')) {
          type = 'OneToMoreOnlineCourse'
        }
      }

      return {
        ...item,
        type: getCName(type)
      }
    })

    typeChart = typeChart.reduce((pre, cur) => {
      const { consumption, type } = cur;

      const index = pre.findIndex((item => item.type === type))
      if (index > -1) {
        pre[index].consumption += consumption
      } else {
        pre[pre.length] = cur
      }

      return pre;
    }, [])

    return { ...data, typeChart };
  };

  const setMonthHFSData = async () => {
    // HFS数据默认展示月
    const params = {
      Type: 'Month',
      Year: parseInt(moment().year()),
      Month: parseInt(moment().month() + 1),
    };
    const res = await GetHFSAnalysis(params);
    initData();
    setIsHFSEmpty(true);
    if (res.response.ok) {
      const newData = getNewData(res.data);
      setConsumptionData(newData);
      if (res.data.trend.length !== 0) {
        setIsHFSEmpty(false);
        findSetHFSData(res);
      }
    } else {
      const { error } = res.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getData = () => {
    hasCourseQuantityPermission && getClassNum();
    hasVratReportQuantityPermission && getVratNum();
    hasCumulativeConsumptionPermission && getConsumeNum();
    // getChildNum();
    hasFasReportQuantityPermission && getFasNum();
    setMonthSalesData();
    hasHfsConsumptionProfilePermission && setMonthHFSData();
  };

  useEffect(() => {
    getData();
  }, []);

  const getCName = (type) => {
    if (type === 'Assessment') {
      return `${intl.formatMessage({ id: 'assessment' })}`;
    } else if (type === 'Course') {
      return `${intl.formatMessage({ id: 'course' })}`;
    } else if (type === 'OnlinePlanCourse') {
      return `${intl.formatMessage({ id: 'OnlinePlan' })}`;
    } else if (type === 'OfflinePlanCourse') {
      return `${intl.formatMessage({ id: 'OfflinePlan' })}`;
    } else if (type === 'OneToMoreOfflineCourse') {
      return `${intl.formatMessage({ id: 'oneToMoreOfflineCourse' })}`;
    } else if (type === 'OneToMoreOnlineCourse') {
      return `${intl.formatMessage({ id: 'oneToMoreOnlineCourse' })}`;
    } else {
      return type;
    }
  };

  const findSetSalesData = (res, type = 'day', dataList = []) => {
    setSalesData([]);
    const oneTooneClassName = res[0].data.trend; // 一对一耗课数
    const vratNum = res[1].data.Trend; // Vrat
    const oneToMoreClassNum = res[2].data.trend; // 一对多耗课数

    const classNum = [
      ...oneTooneClassName,
      ...oneToMoreClassNum
    ].reduce((pre, cur) => {
      const { day, month, courseQuantity } = cur;
      let index;

      if (type === 'day') {
        index = pre.findIndex((item => moment(item.day).isSame(moment(day))));
      } else {
        index = pre.findIndex((item => item.month === month));
      }

      if (index > -1) {
        pre[index].courseQuantity += courseQuantity
      } else {
        pre[pre.length] = cur
      }

      return pre;
    }, []);


    if (classNum.length === 0 && vratNum.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
      const currentMonth = moment().month() + 1;
      let newListData = [
        {
          name: `${intl.formatMessage({ id: 'usedSessions' })}`,
          type: 'line',
          data: [],
        },
        {
          name: `${intl.formatMessage({ id: 'VRATNum' })}`,
          type: 'line',
          data: [],
        },
      ];

      // 匹配数据
      if (type === 'year') {
        for (let index = 1; index <= currentMonth; index++) {
          const classCurrentItem = classNum.find(
            (item) => index == (item.month || item.Month),
          );
          const vratCurrentItem = vratNum.find(
            (item) => index == (item.month || item.Month),
          );

          classCurrentItem
            ? newListData[0].data.push(classCurrentItem['courseQuantity'] || 0)
            : newListData[0].data.push(0);

          vratCurrentItem
            ? newListData[1].data.push(vratCurrentItem['Num'] || 0)
            : newListData[1].data.push(0);
        }
      } else {
        // 匹配数据
        const startTime = rangePickerValue.current[0]
          .format('MM/DD')
          .split('/');

        const startMonth = parseInt(startTime[0]);

        const isCurrentMonth = startMonth == currentMonth;

        if (isCurrentMonth) {
          for (let index = 1; index <= moment().date(); index++) {
            const classCurrentItem = classNum.find(
              (item) => index == moment(item.day || item.Day).date(),
            );
            const vratCurrentItem = vratNum.find(
              (item) => index == moment(item.day || item.Day).date(),
            );

            classCurrentItem
              ? newListData[0].data.push(
                  classCurrentItem['courseQuantity'] || 0,
                )
              : newListData[0].data.push(0);

            vratCurrentItem
              ? newListData[1].data.push(vratCurrentItem['Num'] || 0)
              : newListData[1].data.push(0);
          }
        } else {
          dataList.forEach((index) => {
            // 转换匹配数据格式
            const newData = `${index.split('/')[0]}/${index.split('/')[1]}/${
              index.split('/')[2]
            }`;

            const classCurrentItem = classNum.find(
              (item) =>
                newData == moment(item.day || item.Day).format('YYYY/MM/DD'),
            );
            const vratCurrentItem = vratNum.find(
              (item) =>
                newData == moment(item.day || item.Day).format('YYYY/MM/DD'),
            );

            classCurrentItem
              ? newListData[0].data.push(
                  classCurrentItem['courseQuantity'] || 0,
                )
              : newListData[0].data.push(0);

            vratCurrentItem
              ? newListData[1].data.push(vratCurrentItem['Num'] || 0)
              : newListData[1].data.push(0);
          });
        }
      }

      if (!hasCourseQuantityPermission) {
        newListData.splice(0, 1);
      }

      if (!hasVratReportQuantityPermission) {
        newListData.splice(1, 1);
      }

      setSalesData(newListData);
    }
  };

  const findSetHFSData = (res, type = 'day', dataList = []) => {
    setSalesHFSData([]);
    const consumptionNum = res.data.trend;
    if (consumptionNum.length === 0) {
      setIsHFSEmpty(true);
    } else {
      setIsHFSEmpty(false);

      const currentMonth = moment().month() + 1;
      let newListData = [
        {
          name: `${intl.formatMessage({ id: 'spending' })}`,
          type: 'line',
          data: [],
        },
      ];

      // 匹配数据
      if (type === 'year') {
        for (let index = 1; index <= currentMonth; index++) {
          const tempItem = consumptionNum.find(
            (item) => index == (item.month || item.Month),
          );

          tempItem
            ? newListData[0].data.push(tempItem['consumption'] || 0)
            : newListData[0].data.push(0);
        }
      } else {
        // 匹配数据

        const startTime = rangeHFSPickerValue.current[0]
          .format('MM/DD')
          .split('/');

        const startMonth = parseInt(startTime[0]);
        const isCurrentMonth = startMonth == currentMonth;

        if (isCurrentMonth) {
          for (let index = 1; index <= moment().date(); index++) {
            const tempItem = consumptionNum.find(
              (item) => index == moment(item.day || item.Day).date(),
            );

            tempItem
              ? newListData[0].data.push(tempItem['consumption'] || 0)
              : newListData[0].data.push(0);
          }
        } else {
          dataList.forEach((index) => {
            // 转换匹配数据格式
            const newData = `${index.split('/')[0]}/${index.split('/')[1]}/${
              index.split('/')[2]
            }`;

            const tempItem = consumptionNum.find(
              (item) =>
                newData == moment(item.day || item.Day).format('YYYY/MM/DD'),
            );

            tempItem
              ? newListData[0].data.push(tempItem['consumption'] || 0)
              : newListData[0].data.push(0);
          });
        }
      }

      setSalesHFSData(newListData);
    }
  };

  const setYearSalesData = async () => {
    // 默认展示月
    let infoData = [];
    for (let index = 1; index <= 12; index++) {
      infoData.push(getMonths(index, intl));
    }
    setOptionInfo({
      xData: infoData,
      xName: `${intl.formatMessage({ id: 'month' })}`,
      yName: `${intl.formatMessage({ id: 'quantity' })}`,
    });

    const params = {
      Type: 'Year',
      Year: parseInt(moment().year()),
      Month: parseInt(moment().month() + 1),
    };
    const res = await Promise.all([
      GetILSAnalysis(params),
      GetVRATAnalysis(params),
      GetOneToMoreTrainingAnalsis(params),
    ]);

    if (res.every((item) => item.response.ok)) {
      findSetSalesData(res, 'year');
    }
  };

  const setYearHFSSalesData = async () => {
    // 默认展示月
    let newListData = [];
    for (let index = 1; index <= 12; index++) {
      newListData.push(getMonths(index, intl));
    }
    setHfsOptionInfo({
      xData: newListData,
      xName: `${intl.formatMessage({ id: 'date' })}`,
      yName: `${intl.formatMessage({ id: 'amount' })}($)`,
    });

    const params = {
      Type: 'Year',
      Year: parseInt(moment().year()),
      Month: parseInt(moment().month() + 1),
    };
    const res = await GetHFSAnalysis(params);

    if (res.response.ok) {
      const newData = getNewData(res.data);
      setConsumptionData(newData);
      findSetHFSData(res, 'year');
    }
  };

  const changeData = (value) => {
    let current = moment(value[0]);
    const newListData = [];

    while (moment(current).isSameOrBefore(moment(value[1]), 'day')) {
      newListData.push(moment(current).format('YYYY/MM/DD'));
      current.add(1, 'day');
    }

    return newListData;
  };

  const handleRangePickerChange = async (value) => {
    rangePickerValue.current = value;
    if (value) {
      const newListData = changeData(value);

      const StartTime = moment(value[0].format('YYYY-MM-DD HH:mm:ss'))._i;
      const EndTime = moment(value[1].format('YYYY-MM-DD HH:mm:ss'))._i;

      const params = {
        StartTime,
        EndTime,
      };
      const res = await Promise.all([
        GetILSAnalysis(params),
        GetVRATAnalysis(params),
        GetOneToMoreTrainingAnalsis(params),
      ]);

      if (res.every((item) => item.response.ok)) {
        findSetSalesData(res, 'day', newListData);
      }

      setOptionInfo({
        xData: newListData,
        xName: `${intl.formatMessage({ id: 'date' })}`,
        yName: `${intl.formatMessage({ id: 'quantity' })}`,
      });
    }
  };

  const handleRangeHFSPickerChange = async (value) => {
    rangeHFSPickerValue.current = value;
    if (value) {
      const newListData = changeData(value);

      const StartTime = moment(value[0].format('YYYY-MM-DD HH:mm:ss'))._i;
      const EndTime = moment(value[1].format('YYYY-MM-DD HH:mm:ss'))._i;

      const params = {
        StartTime,
        EndTime,
      };
      const res = await GetHFSAnalysis(params);

      if (res.response.ok) {
        const newData = getNewData(res.data);
        setConsumptionData(newData);
        findSetHFSData(res, 'day', newListData);
      }

      setHfsOptionInfo({
        xData: newListData,
        xName: `${intl.formatMessage({ id: 'date' })}`,
        yName: `${intl.formatMessage({ id: 'amount' })}`,
      });
    }
  };

  const selectDate = (type) => {
    rangePickerValue.current = getTimeDistance(type);
    if (type === 'year') {
      setYearSalesData();
    } else {
      setMonthSalesData();
    }
  };

  const selectHFSDate = (type) => {
    rangeHFSPickerValue.current = getTimeDistance(type);
    if (type === 'year') {
      setYearHFSSalesData();
    } else {
      setMonthHFSData();
    }
  };

  const isActive = (type) => {
    if (!rangePickerValue.current) {
      return '';
    }

    const value = getTimeDistance(type);

    if (!value) {
      return '';
    }

    if (!rangePickerValue.current[0] || !rangePickerValue.current[1]) {
      return '';
    }

    if (
      rangePickerValue.current[0].isSame(value[0], 'day') &&
      rangePickerValue.current[1].isSame(value[1], 'day')
    ) {
      return styles.currentDate;
    }

    return '';
  };

  const isHFSActive = (type) => {
    if (!rangeHFSPickerValue.current) {
      return '';
    }

    const value = getTimeDistance(type);

    if (!value) {
      return '';
    }

    if (!rangeHFSPickerValue.current[0] || !rangeHFSPickerValue.current[1]) {
      return '';
    }

    if (
      rangeHFSPickerValue.current[0].isSame(value[0], 'day') &&
      rangeHFSPickerValue.current[1].isSame(value[1], 'day')
    ) {
      return styles.currentDate;
    }

    return '';
  };

  const config = {
    legend: {
      data: [`${intl.formatMessage({ id: 'usedSessions' })}`, `${intl.formatMessage({ id: 'VRATNum' })}`],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      name: optionInfo.xName,
      type: 'category',
      boundaryGap: false,
      data: optionInfo.xData.map((str) => {
        if (str.length > 5) {
          const newStr =
            str.split('/')[1] +
            '/' +
            str.split('/')[2] +
            '\n' +
            str.split('/')[0];
          return newStr;
        }
        return str;
      }),
    },
    yAxis: {
      name: optionInfo.yName,
      type: 'value',
    },
    tooltip: {
      trigger: 'axis',
    },
    series: !isEmpty ? salesData : [],
  };

  const hfsConfig = {
    title: {
      text: `${intl.formatMessage({ id: 'HFSSpending' })}`,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      name: hfsOptionInfo.xName,
      type: 'category',
      boundaryGap: false,
      data: hfsOptionInfo.xData.map((str) => {
        if (str.length > 5) {
          const newStr =
            str.split('/')[1] +
            '/' +
            str.split('/')[2] +
            '\n' +
            str.split('/')[0];
          return newStr;
        }
        return str;
      }),
    },
    yAxis: {
      name: hfsOptionInfo.yName,
      type: 'value',
    },
    tooltip: {
      trigger: 'axis',
    },
    series: !isHFSEmpty ? salesHFSData : [],
  };

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'statisticalOverview' })}>
      <div className={styles.dataPanel}>
        <div className={styles.panelTop}>
          <Row gutter={24}>
            {hasCourseQuantityPermission && <Col {...topColResponsiveProps}>
              <ChartCard
                bordered={false}
                loading={loading}
                title={intl.formatMessage({ id: 'todaySession' })}
                action={
                  <Tooltip title={intl.formatMessage({ id: 'sessionsCompleted' })}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                total={classNum.courseQuantityToday}
                footer={
                  <Field
                    label={intl.formatMessage({ id: 'totalSession' })}
                    value={classNum.courseQuantity}
                  />
                }
                contentHeight={46}
              >
                <TinyArea
                  color="#975FE4"
                  xField="x"
                  height={46}
                  forceFit
                  yField="y"
                  smooth
                  data={visitData}
                />
              </ChartCard>
            </Col>}

            {hasVratReportQuantityPermission && <Col {...topColResponsiveProps}>
              <ChartCard
                bordered={false}
                loading={loading}
                title={intl.formatMessage({ id: 'TodayVRATNum' })}
                action={
                  <Tooltip title={intl.formatMessage({ id: 'VRATReportsNum' })}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                total={vratNum.AddedToday}
                footer={
                  <Field
                    label={intl.formatMessage({ id: 'TotalVRATNum' })}
                    value={vratNum.Total}
                  />
                }
                contentHeight={46}
              >
                <TinyArea
                  color="#975FE4"
                  xField="x"
                  height={46}
                  forceFit
                  yField="y"
                  smooth
                  data={visitData}
                />
              </ChartCard>
            </Col>}

            {hasFasReportQuantityPermission && <Col {...topColResponsiveProps}>
              <ChartCard
                bordered={false}
                loading={loading}
                title={intl.formatMessage({ id: 'todayFASNum' })}
                action={
                  <Tooltip title={intl.formatMessage({ id: 'FASQuestionnaires' })}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                total={fasNum.addedToday}
                footer={
                  <Field
                    label={intl.formatMessage({ id: 'totalFASNum' })}
                    value={fasNum.total}
                  />
                }
                contentHeight={46}
              >
                <TinyArea
                  color="#975FE4"
                  xField="x"
                  height={46}
                  forceFit
                  yField="y"
                  smooth
                  data={visitData}
                />
              </ChartCard>
            </Col>}

            {hasCumulativeConsumptionPermission && <Col {...topColResponsiveProps}>
              <ChartCard
                bordered={false}
                loading={loading}
                title={`${intl.formatMessage({ id: 'todaySpending' })}($)`}
                action={
                  <Tooltip title={intl.formatMessage({ id: 'HFSDes' })}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                total={toMoney(consumeNum.cumulativeConsumptionToday || 0)}
                footer={
                  <Field
                    label={`${intl.formatMessage({ id: 'totalSpending' })}($)`}
                    value={toMoney(consumeNum.cumulativeConsumption || 0)}
                  />
                }
                contentHeight={46}
              >
                <TinyColumn
                  xField="x"
                  height={46}
                  forceFit
                  yField="y"
                  data={visitData}
                />
              </ChartCard>
            </Col>}
            {/* <Col {...topColResponsiveProps}>
              <ChartCard
                bordered={false}
                loading={loading}
                title="今日授权儿童数"
                action={
                  <Tooltip title="中心新增的已授权的儿童数，同一儿童多次授权计为1次">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
                total={toMoney(childNum.addedToday || 0)}
                footer={
                  <Field
                    label="累计授权儿童数"
                    value={toMoney(childNum.cumulativeAuthorization || 0)}
                  />
                }
                contentHeight={46}
              >
                <TinyArea
                  color="#975FE4"
                  xField="x"
                  height={46}
                  forceFit
                  yField="y"
                  smooth
                  data={visitData}
                />
              </ChartCard>
            </Col> */}
          </Row>
        </div>

        {hasDataTrendPermission && <div className={styles.panelMid}>
          <Card
            bordered={false}
            title={intl.formatMessage({ id: 'sessions_VRAT' })}
            extra={
              <div className={styles.salesExtraWrap}>
                <div className={styles.salesExtra}>
                  <a
                    className={isActive('month')}
                    onClick={() => selectDate('month')}
                  >
                    {intl.formatMessage({ id: 'thisMonth' })}
                  </a>
                  <a
                    className={isActive('year')}
                    onClick={() => selectDate('year')}
                  >
                    {intl.formatMessage({ id: 'thisYear' })}
                  </a>
                </div>
                <RangePicker
                  value={rangePickerValue.current}
                  onChange={handleRangePickerChange}
                  style={{
                    width: 256,
                  }}
                />
                <div
                  className={styles.more}
                  onClick={() =>
                    history.push(
                      hasHfsConsumptionProfilePermission ?
                      '/system/center/menu/analysis/iLs' :
                      '/system/center/menu/analysis/vrat'
                    )
                  }
                >
                  {intl.formatMessage({ id: 'more' })}
                </div>
              </div>
            }
            bodyStyle={{
              padding: 0,
            }}
          >
            <div className={styles.salesCard}>
              <div className={styles.salesBar}>
                <ReactEcharts
                  option={config}
                  notMerge={true}
                  lazyUpdate={false}
                  style={{ height: '450px', width: '100%', margin: '0 auto' }}
                />
                {isEmpty && (
                  <div className={styles.commonAnalysisLineChartIsEmpty}>
                    {intl.formatMessage({ id: 'emptyData' })}
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>}

        {hasHfsConsumptionProfilePermission && <div className={styles.panelMid}>
          <Card
            bordered={false}
            title={intl.formatMessage({ id: 'HFSOverview' })}
            extra={
              <div className={styles.salesExtraWrap}>
                <div className={styles.salesExtra}>
                  <a
                    className={isHFSActive('month')}
                    onClick={() => selectHFSDate('month')}
                  >
                    {intl.formatMessage({ id: 'thisMonth' })}
                  </a>
                  <a
                    className={isHFSActive('year')}
                    onClick={() => selectHFSDate('year')}
                  >
                    {intl.formatMessage({ id: 'thisYear' })}
                  </a>
                </div>
                <RangePicker
                  value={rangeHFSPickerValue.current}
                  onChange={handleRangeHFSPickerChange}
                  style={{
                    width: 256,
                  }}
                />
                <div
                  className={styles.more}
                  onClick={() =>
                    history.push('/system/center/menu/hfs/costOverview')
                  }
                >
                  {intl.formatMessage({ id: 'more' })}
                </div>
              </div>
            }
            bodyStyle={{
              padding: 0,
            }}
          >
            <div className={styles.salesCard}>
              <div className={styles.salesBarHFS}>
                <div>
                  <ReactEcharts
                    option={hfsConfig}
                    notMerge={true}
                    lazyUpdate={false}
                    style={{ height: '450px', width: window.innerWidth >= 1600 ? '55vw' : '75vw' }}
                  />
                  {isHFSEmpty && (
                    <div className={styles.commonAnalysisLineChartIsEmptyHFS}>
                      {intl.formatMessage({ id: 'emptyData' })}
                    </div>
                  )}
                </div>
                <div className={styles.donutAnaly}>
                  {JSON.stringify(consumptionData) !== '{}' && (
                    <>
                      <Donut
                        forceFit
                        title={{
                          visible: true,
                          text: `${intl.formatMessage({ id: 'HFSProportion' })}`,
                        }}
                        height={450}
                        radius={0.7}
                        padding="auto"
                        angleField="consumption"
                        colorField="type"
                        data={
                          consumptionData.typeChart.length == 0
                            ? [{ consumption: 0, type: `${intl.formatMessage({ id: 'emptyData' })}` }]
                            : consumptionData.typeChart
                        }
                        legend={{
                          visible: false,
                        }}
                        label={{
                          visible: true,
                          type: 'spider',
                          formatter: (text, item) => {
                            const total = consumptionData.typeChart.reduce(
                              (prev, cur) => {
                                return (
                                  parseInt(prev) + parseInt(cur.consumption)
                                );
                              },
                              0,
                            );
                            if (total === 0) {
                              return `${getCName(item._origin.type)}`;
                            } else {
                              return `${item._origin.type}: ${Math.round(
                                (item._origin.consumption / total) * 100,
                              )}%`;
                            }
                          },
                        }}
                        statistic={{
                          totalLabel: `${intl.formatMessage({ id: 'spending' })}`,
                        }}
                      />
                      <div className={styles.hsfDes}>
                        {intl.formatMessage({ id: 'HFSBalance' })}：
                        <span>${toMoney(consumptionData.balance)}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>}
      </div>
    </DocumentTitle>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(DataPanel);
