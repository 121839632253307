import { Descriptions, Row, Col } from 'antd'
import { useIntl } from 'umi'
import ReportRichText from 'components/ReportRichText/ReportRichText'

const TemplateBasicInfo = ({ templateInfo }) => {
  const intl = useIntl()
  return (
    <div>
      {templateInfo == null ? (
        <div></div>
      ) : (
        <div style={{ marginTop: 0 }}>
          <Descriptions title="" column={1}>
            <Descriptions.Item label={intl.formatMessage({ id: 'numberOrder' })}>{templateInfo.code}</Descriptions.Item>
          </Descriptions>
          <Row>
            <Col>
              <div>{intl.formatMessage({ id: 'trainingIntroduction' })}:</div>
            </Col>
            <Col>
              <ReportRichText content={templateInfo.introduction} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div>{intl.formatMessage({ id: 'TrainingInstructions' })}:</div>
            </Col>
            <Col>
              <ReportRichText content={templateInfo.notice}/>
            </Col>
          </Row>
          <Descriptions title="" column={1}>
            <Descriptions.Item label={intl.formatMessage({ id: 'coverImage' })}>
              {
                <a href={templateInfo.image != null ? templateInfo.image.url : null} target="_Blank">
                  <img
                    style={{ width: 100, height: 100, borderRadius: 10 }}
                    src={templateInfo.image != null ? templateInfo.image.url : null}
                  />
                </a>
              }
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: 'trainingGoals' })}>
              {templateInfo.targets.map((item, index) => {
                return <span key={index}>{item.displayName} </span>
              })}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: 'feedbackTrainingPlan' })}>
              {templateInfo.feedbackProperties.map((item, index) => {
                return <span key={index}>{item.displayName}</span>
              })}
            </Descriptions.Item>
            <Descriptions.Item label={intl.formatMessage({ id: 'feedback_session' })}>
              {templateInfo.courseFeedbackProperties.map((item, index) => {
                return <span key={index}>{item.displayName}</span>
              })}
            </Descriptions.Item>
            <Descriptions.Item label="币种">{templateInfo.currencyType}</Descriptions.Item>
            <Descriptions.Item label="价格">{`$${templateInfo.price}`}</Descriptions.Item>
            <Descriptions.Item label="文件">
              {templateInfo.files != null
                ? templateInfo.files.map((item, index) => {
                    return (
                      <a key={index} href={item.url} target="_Blank">
                        {item.fileName}
                      </a>
                    )
                  })
                : null}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </div>
  )
}

export default TemplateBasicInfo
