import React from 'react'
import { connect } from 'umi'
import { Tabs, Spin } from 'antd'
import SleepLineChart from 'cognitiveleap-core-us/components/Charts/SleepLineChart';
import PositivePieChart from 'cognitiveleap-core-us/components/Charts/PositivePieChart';
import PositiveBarChart from 'cognitiveleap-core-us/components/Charts/PositiveBarChart';
import NegativePieChart from 'cognitiveleap-core-us/components/Charts/NegativePieChart';
import NegativeBarChart from 'cognitiveleap-core-us/components/Charts/NegativeBarChart';
import AdminClockInRecord  from 'components/ClockInRecord';
import AdminDataTop from 'components/DataTop';
import Loading from 'components/Loading'
import style from './index.less';

const { TabPane } = Tabs;

const SleepPlan = ({ adminSubjectSleep, adminSubjectDetail, adminClockInRecord, loading, dispatch }) => {
  const { planInfo } = adminSubjectSleep
  if (planInfo == null) return <Spin />
  const { subjectId } = adminSubjectDetail;
  const { sleepRecord, pagination } = adminClockInRecord;
  const isLoading = loading.effects['adminClockInRecord/getRecord'];
  const { activePlans, lastestTrainingPlan } = planInfo;

  const loadData = () => {
    lastestTrainingPlan && lastestTrainingPlan.id && 
      dispatch({ type: 'adminClockInRecord/getRecord', payload: { 
        id: lastestTrainingPlan.id, 
        type: 'sleep',
        current: ++pagination.sleep.current
      }});
  }

  const propsData = {
    dataSource: sleepRecord,
    loadData,
    isLoading
  }

  const chartProps = {
    subjectId,
    from: 'pc',
    Loading
  }

  return (
    <div>
      {
        activePlans.length === 0 ? '暂无开启训练' : 
        <div>
          <AdminDataTop {...{lastestTrainingPlan,type:'admin'}} />
          <Tabs defaultActiveKey="chart" type="card" onChange={()=>{!sleepRecord && loadData()}}>
            <TabPane tab="图表" key="chart">
              <center>
                <div className={style.chart}>
                  <SleepLineChart {...chartProps} />
                  <PositivePieChart {...chartProps} />
                  <PositiveBarChart {...chartProps} />
                  <NegativePieChart {...chartProps} />
                  <NegativeBarChart {...chartProps} />
                </div>
              </center>
            </TabPane>
            <TabPane tab="打卡记录" key="record">
              <AdminClockInRecord { ...propsData } />
            </TabPane>
          </Tabs>
        </div>
      }
    </div>
  )
}

export default connect(({ adminSubjectSleep, adminSubjectDetail, adminClockInRecord, loading }) => ({
  adminSubjectSleep,
  adminSubjectDetail,
  adminClockInRecord,
  loading,
}))(SleepPlan)
