import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const roundImg =
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAG6ADAAQAAAABAAAAGwAAAAD2D1T5AAACa0lEQVRIDb2Wv4saQRTHZzc2h4IxYrOWdodgoVYWWgaFUzjSprBIefVhEyIoV6cJ5H8I6BWSUhsrIQhyZ2HAalHE8wTlAko27yMLWaImJrp5MLuzs28+3/n9RlN/sEwm41ssFi/F7cKyrHN5G5KCkqaSTE3T7uR96/P5PjebzYXk95q274+IPF8ul9cicCXpLBAIqGg0qkKhkPL7/Wo+n6vJZKJ6vZ6azWZKRJ8kvfd6vTci+riLu1MskUhcivNHqfwim82qQqGgYrEYwC2GNER1u11Vq9VUo9FQ8v0gTm86nc6nLWdngThqIvQ2Ho9/LxaL1mAwkKLDDX/qUR+Ok72Vt4WscrlsrVarw1UcntSjvghaewXlxyUtwvEUZgvSQ6bkp7EYpHDKEPxrj35tIBx4cOGjpvNg1bEYSqWS8ng8FB1tcODBhQ9QZx9Jq65yuZyKRCJHizgB8FjN8NHR2bDycZbP551+J8uzbeCjwzBesGHZR24YXPjo6KJ6zsmwa8OeQhwufHTomcER5KbZfAOxIGedm2bzg4hNOVTdNJs/Rczk9HbTbL6pywTeESZkAl3RgwsfHXp2SzwiTLhhcOGjoxNhRfWJeOSGwYWPzoafTCZvJP11/JIh+q0R3+DCR4hhVIRyqfVQrVbVer2m6GiDAw8ufIDPeAyHw2+GYXwdjUavZOVo6XSa4qOsUqmoVqtlyRC+brfbX4BtxMiYpnkfDodVv9/PjMdjlUqllK5vOs7vg40eIVSv15mrd3IX+bCzsnTZ1TvI9nVJmmGH8s3tijhH+DnF7WqnGN2WYPd/7o3OMSbCnupG/ANV7tdC477QSAAAAABJRU5ErkJggg==';
const squareImg =
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAIAAAACtmMCAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAG6ADAAQAAAABAAAAGwAAAAD2D1T5AAAAZ0lEQVRIDe2VsQ3AMAzDkqIf+Scf5Z/yU9WiQ6BR8ShvGkQYXDQjYrTeDRqgLdz13U/MzPNHqwrM6xxEBBNJiBjtURRHNXskIWK0R1Ec1eyRhIix3+O7rphEDKP40lYDB2m2bP+GHQ8HpBQJdtmvigAAAABJRU5ErkJggg==';
const triangleImg =
  'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAG6ADAAQAAAABAAAAGwAAAAD2D1T5AAABJUlEQVRIDa3WPxJDUBDH8ZVCpXUKMyqFTnIEN3CTuITzJOnULiRv3/hlgvdnFwo7TNbXpxKiA0dVVf2BNbppl0zoPs/zk6d2Vx0zgX6JYC6X8aGKLaqmKAoyukarU8XMu/d5ntMwDMSTr/kkPcQxqLquoyzLiKdWJ46Zt7eqtm0thKdWJ4r9q9I0tTGeWp0otlXZmjlpddGYS4WYVheN+VQIanTBWEiFmEYXjMVUCEp13phEhZhU541JVQhKdM6YRoWYROeMaVUIxnS72BEVYjHdLnZUhWBIt4qdUSEW0q1iZ1UI+nS/2BUqxHy6X+wqFYIunY1dqULMpYNs9RXGwtm51SWL6lWWJdV1ffb5u/1xHGmaJkqS5MGxN/9x2f3q4hsm9vkC86evWUhObeMAAAAASUVORK5CYII=';

const LineWithInterfereChart = ({
  Behaviour,
  Motion,
  Distractor,
  showLegend = false,
  min,
  max,
}) => {
  const intl = useIntl();
  let title;
  let initLineData;

  if (min === 0) {
    title = intl.formatMessage({ id: 'MentalEnduranceTwo.block1' });
    initLineData = [
      {
        value: [0, 1600],
        symbol: 'none',
      },
      {
        value: [3.25, 1600],
        symbol: 'none',
      },
    ];
  } else if (min === 3.25) {
    title = intl.formatMessage({ id: 'MentalEnduranceTwo.block2' });
    initLineData = [
      {
        value: [3.25, 1600],
        symbol: 'none',
      },
      {
        value: [6.5, 1600],
        symbol: 'none',
      },
    ];
  } else if (min === 6.5) {
    title = intl.formatMessage({ id: 'MentalEnduranceTwo.block3' });
    initLineData = [
      {
        value: [6.5, 1600],
        symbol: 'none',
      },
      {
        value: [9.75, 1600],
        symbol: 'none',
      },
    ];
  } else if (min === 9.75) {
    title = intl.formatMessage({ id: 'MentalEnduranceTwo.block4' });
    initLineData = [
      {
        value: [9.75, 1600],
        symbol: 'none',
      },
      {
        value: [13, 1600],
        symbol: 'none',
      },
    ];
  }

  const color = ['#3DBD7D', '#FFCE3D', '#F36E65', '#36abd3', '#333333'];
  const series = [
    {
      name: intl.formatMessage({ id: 'RealtimePerformanceTwo.answer' }),
      type: 'scatter',
      data: [],
    },
    {
      name: intl.formatMessage({ id: 'RealtimePerformanceTwo.err' }),
      type: 'scatter',
      data: [],
    },
    {
      name: intl.formatMessage({ id: 'RealtimePerformanceTwo.commission' }),
      type: 'scatter',
      data: [],
    },
    {
      name: intl.formatMessage({ id: 'RealtimePerformanceTwo.amplitude' }),
      type: 'line',
      yAxisIndex: 1,
      showSymbol: false,
      hoverAnimation: false,
      data: [],
    },
    {
      type: 'line',
      hoverAnimation: false,
      lineStyle: {
        width: 1,
        color: '#333333',
      },
      data: [],
      markLine: {
        data: [],
        symbol: 'none',
        silent: true,
        lineStyle: {
          type: 'solid',
          color: '#cccccc',
        },
        label: {
          show: false,
          padding: [6, 6, 6, 6],
        },
      },
      zlevel: 1,
    },
  ];

  const style = {
    height: showLegend ? '230px' : '180px',
    width: '780px',
  };

  const option = {
    color,

    title: {
      text: title,
      textStyle: {
        fontSize: 13,
        fontWeight: 'bold',
      },
      bottom: 0,
      left: '74',
    },

    grid: {
      left: '10%',
      // right: '12%',
      width: '80%',
      top: showLegend ? '25%' : '4%',
      bottom: showLegend ? '20%' : '25%',
    },

    xAxis: {
      name: intl.formatMessage({ id: 'Chats.Time.Minute' }),
      type: 'value',
      nameLocation: 'center',
      nameGap: 30,
      boundaryGap: false,
      axisLine: { onZero: false },
      min,
      max,
      interval: 0.25,
      splitLine: {
        show: false,
      },
      nameTextStyle: {
        fontSize: 13,
        fontWeight: 'bold',
        color: 'black',
      },
      axisLabel: {
        formatter: function (value, index) {
          return value !== 0 && value % 1 === 0 ? value : '';
        },
      },
    },

    yAxis: [
      {
        // name: intl.formatMessage({ id: 'report.realTime.chart.yAxisl' }),
        nameLocation: 'center',
        min: 0,
        max: 1600,
        interval: 400,
        nameGap: 40,
        position: 'left',
        type: 'value',
        splitLine: {
          show: false,
        },
      },
      {
        name: intl.formatMessage({ id: 'Charts.MotionIndex' }),
        nameLocation: 'center',
        nameGap: 25,
        type: 'value',
        position: 'right',
        min: 0,
        max: 8,
        interval: 2,
        nameRotate: 270,
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        nameTextStyle: {
          fontSize: 13,
          fontWeight: 'bold',
          color: 'black',
        },
      },
    ],

    legend: showLegend
      ? {
          left: 50,
          selectedMode: false
        }
      : null,

    series,
  };

  Behaviour.forEach((item) => {
    switch (item.Type) {
      case 2:
        option.series[0].data.push([item.Minute, item.ReactionTime]);
        break;
      case 1:
        option.series[1].data.push([item.Minute, 0]);
        break;
      case 0:
        option.series[2].data.push([item.Minute, item.ReactionTime]);
        break;
      default:
        option.series[0].data.push([item.Minute, item.ReactionTime]);
        break;
    }
  });

  const showMotionIndex = Motion.map((item) => {
    return [item.Minute, item.MotionIndex];
  });

  const distractorIndex = Distractor.map((item) => {
    const { Minute, Type } = item;
    // Audio = 0
    // Visual = 1;
    // Mixed = 2;
    let symbolImg = squareImg;
    if (Type == 1) {
      symbolImg = roundImg;
    } else if (Type == 2) {
      symbolImg = triangleImg;
    }
    return {
      value: [Minute, 1600],
      symbol: symbolImg,
      symbolSize: 12,
    };
  });

  option.series[3].data = showMotionIndex;
  option.series[4].data = [
    initLineData[0],
    ...distractorIndex,
    initLineData[1],
  ];
  option.series[4].markLine.data = Distractor.map((item) => {
    const { Minute } = item || {};
    return {
      xAxis: Minute,
    };
  });

  return (
    <ReactEcharts option={option} style={style} opts={{ renderer: 'svg' }} />
  );
};

export default LineWithInterfereChart;
