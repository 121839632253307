import {
  PostTrainingProgram,
  GetTrainingProgramList,
  GetTrainingProgram,
  PutTrainingProgram,
  PrepareForEdit,
  TrainingProgramDetail,
  DeleteTrainingProgram,
} from 'services/rocketService/TrainingProgram'
import { onPreviewVideo } from "utils/utils"
import { GetReminderTemplate } from 'services/rocketService/Reminder'
import { PutTrainingProgramVideo, GetVideoUrl } from 'services/video'
import {
  matchDynamicForm,
  addOptionsToProperties,
  addDescriptionToProperties,
} from 'cognitiveleap-core-us/utils/utils'

import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import coreConfig from 'cognitiveleap-core-us/utils/config'
import { message } from 'antd'
const { baseURL, uploadTrainingProgramUrl, uploadImagesUrl, uploadTrainingProgramMediasVod } = config
const { videoSeparator } = coreConfig
const uploadUrl = uploadImagesUrl
const trainingProgramUrl = uploadTrainingProgramUrl

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show] || '', Id: item.id.toString() }
  })
}

const content = {
  Properties: [
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: '线上',
            Id: 'Online',
          },
          {
            Value: '线下',
            Id: 'OffLine',
          },
        ],
        Required: true,
      },
      ShowTitle: '线上/线下',
      FormKey: 'type',
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '标题',
      FormKey: 'title',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '训练项目编号',
      FormKey: 'code',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '副标题',
      FormKey: 'subTitle',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: '无',
            Id: 'Null',
          },
          {
            Value: '一人',
            Id: 'One',
          },
          {
            Value: '两人',
            Id: 'Two',
          },
          {
            Value: '多人',
            Id: 'Many',
          },
        ],
        Required: false,
      },
      ShowTitle: '活动类型(人数)',
      FormKey: 'personType',
      Description: null,
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'L1',
            Id: 'lv1',
          },
          {
            Value: 'L2',
            Id: 'lv2',
          },
          {
            Value: 'L3',
            Id: 'lv3',
          },
          {
            Value: 'L4',
            Id: 'lv4',
          },
          {
            Value: 'L5',
            Id: 'lv5',
          },
          {
            Value: 'L6',
            Id: 'lv6',
          },
          {
            Value: 'L7',
            Id: 'lv7',
          },
          {
            Value: 'L8',
            Id: 'lv8',
          },
          {
            Value: 'L9',
            Id: 'lv9',
          },
          {
            Value: 'L10',
            Id: 'lv10',
          },
          {
            Value: 'L11',
            Id: 'lv11',
          },
          {
            Value: 'L12',
            Id: 'lv12',
          },
          {
            Value: 'L13',
            Id: 'lv13',
          },
          {
            Value: 'L14',
            Id: 'lv14',
          },
          {
            Value: 'L15',
            Id: 'lv15',
          },
          {
            Value: 'L16',
            Id: 'lv16',
          },
          {
            Value: 'L17',
            Id: 'lv17',
          },
          {
            Value: 'L18',
            Id: 'lv18',
          },
          {
            Value: 'L19',
            Id: 'lv19',
          },
          {
            Value: 'L20',
            Id: 'lv20',
          },
        ],
        Required: false,
      },
      ShowTitle: 'iLs难度',
      FormKey: 'difficulty',
      Description: null,
    },
    {
      EditorType: 'SingleWithSearch',
      ShowTitle: '难度2.0',
      FormKey: 'difficulty2',
      AdditionalData: null,
      Value: null,
      Setting: {
        DropdownOptions: [],
      },
      Description: null,
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: '动作概要',
      FormKey: 'content',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: '孩子完成活动可能出现的困难',
      FormKey: 'possibleDifficulties',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: '团体带课建议',
      FormKey: 'suggestionsOnGroupTeaching',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '是否是注册动作',
      FormKey: 'isRegisterAction',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '是否全填写才算完成',
      FormKey: 'punchAllToFinish',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: '难度增加',
      FormKey: 'difficultyIncrease',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '活动反馈',
      FormKey: 'feedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'extra',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（始）',
      FormKey: 'ageStart',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（末）',
      FormKey: 'ageEnd',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '训练器材',
      FormKey: 'materials',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '名称',
              FormKey: 'trainingMaterialId',
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: '描述',
              FormKey: 'materialDescription',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'NumberInput',
              ShowTitle: '数量',
              FormKey: 'requiredCount',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: false,
                NumberSetting: {
                  min: 0,
                  step: 1,
                  precision: 0,
                },
              },
              Description: null,
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '训练目标',
      FormKey: 'targets',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '训练类别',
      FormKey: 'categoryIds',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '标签',
      FormKey: 'tagIds',
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '建议时长(单位：分钟)',
      FormKey: 'suggestedTimeLength',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          uploadType: 'video',
          onPreview(file) {
            onPreviewVideo(file)
          }
        },
        Action: baseURL + uploadTrainingProgramMediasVod,
        Header: getAuthHeader(),
      },
      ShowTitle: '视频',
      FormKey: 'videos',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          uploadType: 'image',
        },
        Action: baseURL + trainingProgramUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: '视频封面图片',
      FormKey: 'images',
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: false,
      },
      ShowTitle: '添加提醒',
      FormKey: 'reminderTemplateId',
      Description: null,
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: '训练次数和注意事项',
      FormKey: 'precautions',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
  ],
}

const updateVidContent = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'vid',
      FormKey: 'vid',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    }
  ]
}

export default {
  namespace: 'trainingProgram',
  state: {
    list: [],
    listSupport: {
      title: {
        showText: '标题',
        showType: 'Text',
      },
      // subTitle: {
      //   showText: '副标题',
      //   showType: 'Text',
      // },
      // level: {
      //   showText: '难度',
      //   showType: 'Text',
      // },
      // primaryTargetDisplayName: {
      //   showText: '训练目标',
      //   showType: 'Text',
      // },
      fileName: {
        showText: '视频文件名称',
        showType: 'Text',
      },
      coverImg: {
        showText: '视频封面图',
        showType: 'Picture',
      },
      code: {
        showText: 'code',
        showType: 'Text',
      },
      type: {
        showText: '类型',
        showType: 'Text',
        // filter: {
        //   hasFilter: true,
        //   filterOptions: [
        //     { text: 'Online', value: 'Online' },
        //     { text: 'Offline', value: 'Offline' },
        //   ],
        // },
      },
    },
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
    avaliableFeedbackProperties: [],
    modalVisible: false,
    content: null,
    modalType: 'add',
    selectItem: null,
    searchInput: '',
    updateVidContent: updateVidContent,
    updateVidVisible: false
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, searchInput } = yield select(state => state.trainingProgram)
      let query = { skipCount: 0, maxResultCount: 10, filter: searchInput }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetTrainingProgramList, query)
      const { response, data } = res
      if (response.ok) {
        const { items, totalCount } = data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.trainingProgram)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'query' })
    },

    *clearAllFilterAndSorter(_, { call, put, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 12,
        total: 0,
      }
      const payload = { pagination: defaultPagination, searchInput: '' }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'query' })
    },

    *onAddOrEdit({ payload: data }, { take, put, call, select }) {
      const isAdd = data === null
      const res = yield call(PrepareForEdit, { ...data })
      if (res.response.ok) {
        const {
          availableCategorys,
          availableTags,
          availableTargets,
          avaliableMaterials,
          avaliableFeedbackProperties,
          avaliableReminderTemplate,
          difficulties,
          trainingProgram,
        } = res.data

        let emptyContent = content

        const categoryOptions = listToOptions(availableCategorys, 'name')
        const tagOptions = listToOptions(availableTags, 'displayName')
        const targetOptions = listToOptions(availableTargets, 'displayName')
        const materialOptions = listToOptions(avaliableMaterials, 'displayName')
        const propertyOptions = listToOptions(avaliableFeedbackProperties, 'displayName')
        const reminderOptions = listToOptions(avaliableReminderTemplate, 'title')

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['feedbackProperties', 'feedbackPropertyId'],
          propertyOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['materials', 'trainingMaterialId'],
          materialOptions
        )
        emptyContent = addOptionsToProperties(emptyContent, ['targets'], targetOptions)
        emptyContent = addOptionsToProperties(emptyContent, ['categoryIds'], categoryOptions)
        emptyContent = addOptionsToProperties(emptyContent, ['tagIds'], tagOptions)
        emptyContent = addOptionsToProperties(emptyContent, ['difficulty2'], difficulties.map(item => {
          return {
            Value: item.replace('lv', 'L'),
            Id: item
          }
        }))
        emptyContent = addOptionsToProperties(emptyContent, ['reminderTemplateId'], reminderOptions)

        const {
          agePeriod,
          feedbackProperties,
          materials,
          suggestedTimeLength,
          targets,
        } = trainingProgram
        const { start, end } = agePeriod || {}

        const feedbacks = feedbackProperties.map(item => {
          const { extraProperties } = item
          const extra = JSON.stringify(extraProperties)
          return {
            ...item,
            feedbackPropertyId: item.id,
            extra,
          }
        })
        const form = matchDynamicForm(emptyContent, {
          ...trainingProgram,
          ageStart: start || 0,
          ageEnd: end || 0,
          feedbackProperties: feedbacks,
          materials: materials.map(item => {
            return { ...item, trainingMaterialId: item.id }
          }),
          targets: targets.map(item => item.id),
          suggestedTimeLength: suggestedTimeLength / 60,
        })

        yield put({
          type: 'updateState',
          payload: {
            avaliableFeedbackProperties,
            modalType: isAdd ? 'add' : 'edit',
            content: form,
            modalVisible: true,
            selectItem: isAdd ? null : trainingProgram,
          },
        })
      }
    },

    *onDelete({ payload: data }, { take, put, call, select }) {
      const res = yield call(DeleteTrainingProgram, data)
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.trainingProgram)
      let res
      if (modalType === 'edit') {
        res = yield call(PutTrainingProgram, { ...selectItem, ...data }, selectItem.id)
      } else {
        res = yield call(PostTrainingProgram, data)
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      } else {
        const { error } = res.data
        if (error) {
          message.error(error.message)
        }
      }
    },

    *updateVid({ payload }, { take, put, call, select }) {
      const { videos } = payload || {}
      const id = videos && videos[0] && videos[0].id

      const content = {
        Properties: [
          {
            ...updateVidContent.Properties[0],
            Value: id && id.includes(videoSeparator) ? id.split(videoSeparator)[0] : null
          }
        ]
      }

      content.Properties[0] = {
        ...content.Properties[0],
        Value: id && id.includes(videoSeparator) ? id.split(videoSeparator)[0] : null
      }
      yield put({
        type: 'updateState',
        payload: {
          updateVidVisible: true,
          selectVidItem: payload,
          updateVidContent: content
        }
      })
    },

    *onsubmitVid({ payload }, { take, put, call, select }) {
      const { vid } = payload
      const { selectVidItem } = yield select(state => state.trainingProgram)
      const { id } = selectVidItem || {}
      const signResult = yield call(GetVideoUrl, { vid })
      if (signResult.response.ok) {
        const signResultData = signResult.data
        const { title } = signResultData || {}

        const putCourseRes = yield call(PutTrainingProgramVideo, {
          vid: `${vid}${videoSeparator}${title}`,
          id
        })
        if (putCourseRes.response.ok) {
          message.success("更新成功")
          yield put({
            type: 'updateState',
            payload: {
              updateVidVisible: false,
            }
          })
          yield put({ type: 'query' })
        } else {
          message.error('更新失败')
        }
      } else {
        const { error } = signResult.data
        const { message: errorInfo } = error || {}
        errorInfo && message.error(errorInfo)
      }
    }

  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/trainingProgram') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
