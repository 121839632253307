import { message } from 'antd'
import { GetSchoolList, AddSchool, updateSchoolInfo, deleteSchool } from 'services/school'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'

const contentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: '学校名称',
            FormKey: 'schoolName',
            AdditionalData: null,
            Value: '',
            Setting: {
                Required: true,
                customValidator: (rule, value, callback) => {
                    if (value && value.length >= 2 && value.length <= 80) {
                        callback()
                    } else {
                        callback('学校名称长度应在2-80个字符之间')
                    }
                }
            },
            Description: '学校全称，2-80个字符',
        },
        {
            EditorType: 'Input',
            ShowTitle: 'code',
            FormKey: 'code',
            AdditionalData: null,
            Value: '',
            Setting: {
                Required: true,
            },
            Description: '唯一标识',
        },
    ]
}

export default {
    namespace: 'schoolList',
    state: {
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        modalType: 'edit',
        selectItem: null,
        modalVisible: false,
        filterSchoolName: '',
        schoolListData: []
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query({ payload }, { call, put, select }) {
            const { filterSchoolName, pagination } = yield select(state => state.schoolList)
            const { current, pageSize } = pagination
            const parmas = {
                Name: filterSchoolName,
                SkipCount: (current - 1) * pageSize,
                MaxResultCount: pageSize
            }
            const res = yield call(GetSchoolList, parmas)
            if (res.response.ok) {
                const { items, totalCount } = res.data
                yield put({ type: 'updateState', payload: { schoolListData: items, pagination: { ...pagination, total: totalCount } } })
            }
        },

        *onAdd(_, { call, put, select }) {
            yield put({
                type: 'updateState',
                payload: { modalType: 'add', content: contentMenu, modalVisible: true },
            })
        },

        *onSubmitModal({ payload: data }, { take, put, call, select }) {
            const { modalType, selectItem } = yield select(state => state.schoolList)
            const { schoolName, code } = data
            let res
            if (modalType === 'edit') {
                const params = { ...selectItem, ...data, name: data.schoolName }
                const res = yield call(updateSchoolInfo, params)
                if (res.response.ok) {
                    message.success('编辑成功')
                    yield put({ type: 'updateState', payload: { modalVisible: false } })
                    yield put({ type: 'query' })
                }
                else {
                    const { error } = res.data || {}
                    message.error(error.message)
                }
            } else {
                const parmas = {
                    name: schoolName,
                    code
                }
                const res = yield call(AddSchool, parmas)
                if (res.response.ok) {
                    message.success('添加成功')
                    yield put({ type: 'updateState', payload: { modalVisible: false } })
                    yield put({ type: 'query' })
                }
                else {
                    const { error } = res.data || {}
                    message.error(error.message)
                }
            }
        },


        *onEdit({ payload }, { call, put, select }) {
            let emptyContent = {...contentMenu}
            emptyContent.Properties[1].Setting.Disabled = true
            const editUserInfoForm = matchDynamicForm(emptyContent, {
                ...payload,
                schoolName: payload.name
            })
            yield put({
                type: 'updateState',
                payload: {
                    content: editUserInfoForm,
                    modalType: 'edit',
                    modalVisible: true,
                    selectItem: payload,
                }
            })
        },

        *changeTable({ payload }, { call, put, select }) {
            const { pagination } = yield select(state => state.schoolList)
            const { pagination: newPagination } = payload
            yield put({ type: 'updateState', payload: { pagination: { ...pagination, ...newPagination } } })
            yield put({ type: 'query' })
        },

        *onSearch({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'query' })
        },

        *onClear({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { pagination, filterSchoolName: '' } })
            yield put({ type: 'query' })
        },

        *onDelete({ payload }, { call, put, select }) {
            const res = yield call(deleteSchool, payload)
            if (res.response.ok) {
                message.success('刪除成功')
                yield put({ type: 'query' })
            }
            else {
                const { error } = res.data || {}
                message.error(error.message)
            }
        }
    },
    subscriptions: {
        // setupHistory({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/manage/school') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
