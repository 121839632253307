import React, { useEffect } from 'react'
import { connect } from 'umi'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { history } from 'umi';
import { Button, Modal, Input } from 'antd'
import StatusModal from 'components/StatusModal'
import SortOrderModal from 'components/SortOrderModal'

const confirm = Modal.confirm
const { Search } = Input

const initialStatusValue = {
  'Unpublished': 'Unpublish',
  'Public': 'Public',
  'Hidden': 'Hide',
  'Expired': 'Expire',
  'Trashed': 'Trash'
}

const Course = ({ dispatch, course }) => {

  useEffect(() => {
    dispatch({ type: 'course/query' })
  }, [])

  const {
    list,
    listSupport,
    pagination,
    modalType,
    content,
    modalVisible,
    statusVisible,
    sortOrderModal,
    selectItem,
    searchInput,
    filters
  } = course

  const validator = value => {
    return true
  }

  const modalTypeToTitle = type => {
    switch (type) {
      case 'add':
        return '新建课程'
      case 'edit':
        return '编辑课程'
      default:
        return ''
    }
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'course/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      switch (modalType) {
        case 'add':
        case 'edit':
          if (!validator(value)) {
            return
          } else {
            dispatch({ type: 'course/onSubmitModal', payload: value })
          }
          break
        default:
      }
    },
    content,
  }

  const statusProps = {
    visible: statusVisible,
    initialValue: initialStatusValue[selectItem && selectItem.status],
    description: 'Public和Hide提交后,isVIP状态不可编辑',
    onCancel() {
      dispatch({ type: 'course/updateState', payload: { statusVisible: false } })
    },
    onOk(data) {
      dispatch({ type: 'course/changeStatus', payload: data.status })
    },
  }

  const tableProps = {
    listData: list,
    listSupport,
    pagination,
    filters,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'course/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '中心',
        onClick(record) {
          history.push({
            pathname: '/system/manage/authorCenter',
            query: {
              id: record.id,
              type: 'curriculum',
              title: record.title
            },
          })
        }
      },
      {
        showText: '小节',
        onClick(record) {
          history.push({
            pathname: '/system/manage/courseDetail',
            query: {
              id: record.id,
            },
          })
        },
      },
      {
        showText: '信息',
        onClick(record) {
          dispatch({ type: 'course/onEdit', payload: record })
        },
      },
      {
        showText: '排序',
        onClick(record) {
          dispatch({
            type: 'course/updateState',
            payload: { selectItem: record, sortOrderModal: true },
          })
        },
      },
      {
        showText: '切换发布态',
        onClick(record) {
          dispatch({
            type: 'course/updateState',
            payload: { statusVisible: true, selectItem: record },
          })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'course/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const sortModalProps = {
    visible: sortOrderModal,
    onCancel() {
      dispatch({ type: 'course/updateState', payload: { sortOrderModal: false } })
    },
    onOk(data) {
      dispatch({ type: 'course/changeSortOrder', payload: data.sortOrder })
    },
    currentValue: selectItem && selectItem.sortOrder,
  }

  const onAddBtnClick = () => {
    dispatch({ type: 'course/onAdd' })
  }

  return (
    <div>
      <h1>课程</h1>
      <Button onClick={onAddBtnClick}>新建课程</Button>
      <Search
        placeholder="课程名称"
        onSearch={value =>
          dispatch({ type: 'course/changeTable', payload: { searchInput: value } })
        }
        onChange={e =>
          dispatch({ type: 'course/updateState', payload: { searchInput: e.target.value } })
        }
        style={{ width: 200 }}
        value={searchInput}
      />
      <Button
        onClick={() => {
          const params = {
            searchInput: '',
            filters: {},
            pagination: {
              current: 1,
              pageSize: 10,
              total: 0,
            }
          }
          dispatch({ type: 'course/changeTable', payload: params })
        }}
      >
        Clear
      </Button>
      <br />
      <br />
      <MagicFormModal {...modalProps} />
      <StatusModal {...statusProps} />
      <SortOrderModal {...sortModalProps} />
      <MagicTable {...tableProps} />
    </div>
  )
}
export default connect(({ course }) => ({ course }))(Course)
