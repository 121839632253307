import { GetCase, PostAdminTestExcel } from 'services/vrat/cms';

export default {
  namespace: 'cmsCaseList',

  state: {
    caseList: [],
    pageIndex: 1,
    pageSize: 12,
    total: 0,
    searchField: null,
    Status: [],
    OrderBy: null,
    StartTime: null,
    EndTime: null,
    StartTimeGenerate: null,
    EndTimeGenerate: null,
    loading: false,
    btnLoading: false,
  },

  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetch(_, { call, put, select, take }) {
      yield put({
        type: 'save',
        payload: { loading: true },
      });
      const {
        pageIndex,
        pageSize,
        searchField,
        StartTime,
        EndTime,
        StartTimeGenerate,
        EndTimeGenerate,
        OrderBy,
        Status,
      } = yield select((state) => state.cmsCaseList);
      const res = yield call(
        GetCase,
        { pageIndex, pageSize, searchField },
        { StartTime, EndTime, OrderBy, Status, StartTimeGenerate, EndTimeGenerate, },
      );
      const { data, response } = res;
      if (response.ok) {
        const { TotalCount, data: caseList } = data;
        yield put({
          type: 'save',
          payload: { caseList, total: TotalCount, loading: false },
        });
      }
    },

    *filterFetch({ payload }, { call, put, select, take }) {
      yield put({ type: 'save', payload: {
        pageIndex: 1,
        pageSize: 12,
        ...payload,
      } });
      yield put({ type: 'fetch' });
    },

    *exportExcel({ payload }, { call, put, select }) {
      yield put({
        type: 'save',
        payload: { btnLoading: true },
      });
      const { OrderBy, searchField, StartTime, EndTime, Status, StartTimeGenerate, EndTimeGenerate, } = yield select(
        (state) => state.cmsCaseList,
      );
      yield call(
        PostAdminTestExcel,
        { searchField },
        {
          StartTime,
          EndTime,
          StartTimeGenerate,
          EndTimeGenerate,
          OrderBy,
          Status,
          TestingCenterId: null,
          SubjectId: null,
        },
      );
      yield put({
        type: 'save',
        payload: { btnLoading: false },
      });
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(({ pathname, query }) => {
    //     if (pathname === '/system/manage/statistic/vratCaseList') {
    //       dispatch({ type: 'fetch' })
    //     }
    //   })
    // },
  },
};
