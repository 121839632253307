
export default {
    namespace: 'hfsOrderDetail',
    state: {
    },
    reducers: {
      updateState(state, { payload }) {
        return {
          ...state,
          ...payload,
        }
      },
    },
    effects: {
      *query(_, { call, put, take, select }) {
      }
    },
    subscriptions: {
      // setupHistory({ dispatch, history }) {
      //   return history.listen(location => {
      //     if (location.pathname === '/system/center/detail/hfsOrderDetail') {
      //       dispatch({ type: 'query' })
      //     }
      //   })
      // },
    },
  }
  