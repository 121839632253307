import { Modal, Button, Avatar, Divider, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment'
import { useIntl } from 'umi'
import subjectDefault from 'assets/subject_default.png'
import styles from '../center.less'

const CheckInModal = ({ loading, content, visible, onCancel, onOk }) => {
  const intl = useIntl();
  if (content == null) {
    return <div></div>
  }
  const { name, birthDay, localedGender, picture } = content

  const onQuit = () => {
    onCancel()
  }

  return (
    <Modal
      maskClosable={true}
      open={visible}
      onCancel={onQuit}
      width={600}
      title={intl.formatMessage({ id: 'checkIn' })}
      closeIcon={<CloseCircleOutlined />}
      footer={null}
    >
      <div style={{ textAlign: 'center' }}>
        <p className={styles.checkInTitle}>{intl.formatMessage({ id: 'checkInPeopleList' })}：</p>
        <div style={{ paddingBottom: 20 }}>
          <Avatar shape="square" size={48} src={picture !== undefined ? picture : subjectDefault} />
          <span style={{ margin: '0 26px 0 16px' }}>{name}</span>
          <span className={styles.checkInColor}>{localedGender}</span>
          <Divider type="vertical" />
          <span className={styles.checkInColor}>{moment(birthDay).format('MM/DD/YYYY')}</span>
        </div>
      </div>
      <Row
        style={{ textAlign: 'center', marginTop: 60 }}
        gutter={20}
        type="flex"
        justify="space-around"
      >
        <Col span={12}>
          <Button onClick={onCancel} style={{ width: '90%' }}>
            {intl.formatMessage({ id: 'cancel' })}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            onClick={() => onOk(content.id)}
            loading={loading}
            style={{ width: '90%' }}
          >
            {intl.formatMessage({ id: 'sureCheckIn' })}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default CheckInModal
