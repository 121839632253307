import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { useIntl } from 'umi'

const LocationList = ({ locationList, onEdit }) => {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: 'creationTime' }),
      dataIndex: 'CreationTime',
      render: text => <span>{moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''}</span>
    },
    {
      title: intl.formatMessage({ id: 'email' }),
      dataIndex: 'Email',
    },
    {
      title: intl.formatMessage({ id: 'phone' }),
      dataIndex: 'Phone',
    },
    {
      title: intl.formatMessage({ id: 'locationAddress' }),
      dataIndex: 'Location',
    },
    {
      title: intl.formatMessage({ id: 'locationName' }),
      dataIndex: 'Name',
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'IsEnabled',
      render: text => <span>{text ? intl.formatMessage({ id: 'active' }) : intl.formatMessage({ id: 'inActive' })}</span>,
    },
    {
      title: intl.formatMessage({ id: 'action' }),
      dataIndex: 'Actions',
      render: (text, record, index) => <a onClick={() => onEdit(record.Id)}>{intl.formatMessage({ id: 'edit' })}</a>,
    },
  ]

  return <Table rowKey={record => record.Id} columns={columns} dataSource={locationList} pagination={false} />
}

export default LocationList
