import React from 'react';
import { Modal, message } from 'antd'
import styles from './index.less'
import copy from 'copy-to-clipboard'
import { useIntl } from 'umi'
import { DownloadOutlined } from '@ant-design/icons';

const TrainingCode = ({ visible, url, changedTenant, downloadCenterQr, onCancel }) => {
    const intl = useIntl()

    return (
        <Modal
            open={visible}
            footer={null}
            closable={true}
            width={300}
            className={styles.centerQrModal}
            onCancel={onCancel}
        >
            <div style={{marginBottom: '5px'}}>{intl.formatMessage({ id: 'WechatScan' })}</div>
            <div>{intl.formatMessage({ id: 'apply' })} <span style={{fontSize: '20px', fontWeight: 550}}>{intl.formatMessage({ id: 'HFSCoach' })}</span> {intl.formatMessage({ id: 'certifiedTraining' })}</div>
            <img src={url} width={200} />
            <div style={{marginBottom: '5px'}}>{changedTenant&&changedTenant.displayName}</div>
            <div
                className={styles.downloadCenterQr}
                onClick={()=>{downloadCenterQr()}}
            >
                {intl.formatMessage({ id: 'saveImage' })}&nbsp;<DownloadOutlined />
            </div>
        </Modal>
    )
}

export default TrainingCode