import { Button } from 'antd';
import { connect, useIntl } from 'umi';

import BrainModal from './BrainModal';

import styles from '../detail.less';

const StepOne = ({
  subjectOfflinePlan,
  subjectDetail,
  loading,
  dispatch,
  onNextStep,
}) => {
  const intl = useIntl();

  const { brainModalVisible, brainType, currentType, newPlanLoading } =
    subjectOfflinePlan;

  const { subjectInfo } = subjectDetail;

  const updateData = (type, value) => {
    dispatch({
      type: 'subjectOfflinePlan/updateState',
      payload: {
        [type]: value,
      },
    });
  };

  const brainModalProps = {
    visible: brainModalVisible,
    type: brainType,
    currentType,
    spinning: loading.effects['subjectOfflinePlan/beforeCreate'],
    handleCancel: () => {
      updateData('brainModalVisible', false);
    },
    onChange: (e) => {
      updateData('currentType', e.target.value);
    },
    nextStep: async (e) => {
      updateData('brainModalVisible', false);
      const postData = {
        subjectId: subjectInfo.id,
        planCode: currentType,
      };
      onNextStep(postData);
    },
  };

  const onNewPlan = () => {
    dispatch({
      type: 'subjectOfflinePlan/beforeCreate',
      payload: { intl },
    });
  };

  return (
    <div className={styles.tabContent}>
      <div className={styles.stepOne}>
        <div className={styles.stepOneText}>
          <div>{intl.formatMessage({ id: 'No ongoing plans detected' })}</div>
        </div>
        <Button onClick={onNewPlan} type="primary" loading={newPlanLoading}>
          {intl.formatMessage({ id: 'StartCustomize' })}
        </Button>
      </div>
      <BrainModal {...brainModalProps} />
    </div>
  );
};

export default connect(({ subjectOfflinePlan, subjectDetail, loading }) => ({
  subjectOfflinePlan,
  subjectDetail,
  loading,
}))(StepOne);
