import React, { useEffect, useState } from 'react';
import { Card, Input, Space, Button, DatePicker, Row, Col } from 'antd';
import { connect, useIntl } from 'umi';
import moment from 'moment';

import {
  GetQuestionAnalysis,
  DownQuestionAnalysis,
} from 'services/rocketService/Analysis';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import { showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import { getFormatDate } from 'utils/utils';
import download from 'cognitiveleap-core-us/utils/download';

const { Search } = Input;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const listSupport = {
  displayName: {
    showText: '中心名字',
    showType: 'Text',
    fixed: 'left',
  },
  totalCount: {
    showText: '总问卷报告数',
    showType: 'Text',
  },
  todayNewAddCount: {
    showText: '今日新增报告数',
    showType: 'Text',
  },
  fasTotalCount: {
    showText: 'FAS问卷报告数（总）',
    showType: 'Text',
  },
  fasTodayNewAddCount: {
    showText: 'FAS问卷报告数（今日新增）',
    showType: 'Text',
  },
};

const QuestionAnalysis = ({ currentUser }) => {
  const intl = useIntl()
  const { canChangeList } = currentUser;

  const [dataSource, setDataSource] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: '全部',
  });
  const [filterDate, setFilterDate] = useState({
    startTime: '',
    endTime: '',
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
  });
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = await GetQuestionAnalysis({
      MaxResultCount: pagination.pageSize,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      Filter: keyword,
      TenantId: currentTenant.id,
      StartTime: filterDate.startTime,
      EndTime: filterDate.endTime,
    });

    if (res.response.ok) {
      const { items, totalCount } = res.data;

      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      showErrorMsg(res, intl);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [
    filterDate,
    keyword,
    pagination.pageSize,
    pagination.current,
    currentTenant,
  ]);

  const changedTenantList = (id) => {
    if (id) {
      const newCurrentTenant = canChangeList.find((item) => item.id == id);
      setCurrentTenant({ ...newCurrentTenant });
    } else {
      setCurrentTenant({ id: '', displayName: '全部' });
    }
  };

  const tableProps = {
    listData: dataSource.map((item) => {
      const {
        tenant: { displayName },
      } = item;
      return {
        displayName,
        ...item,
      };
    }),
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
  };

  const onChangeDate = (value) => {
    const startTime = value && value[0] ? getFormatDate(value[0], true) : '';
    const endTime = value && value[1] ? getFormatDate(value[1], false) : '';
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    setFilterDate({
      startTime,
      endTime,
    });
  };

  const buttonClick = async () => {
    setLoading({
      ...loading,
      buttonLoading: true,
    });

    const res = await DownQuestionAnalysis({
      MaxResultCount: pagination.pageSize,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      Filter: keyword,
      TenantId: currentTenant.id,
      StartTime: filterDate.startTime,
      EndTime: filterDate.endTime,
    });

    if (res.response.ok) {
      const { response } = res;
      response.blob().then((blob) => {
        download(
          blob,
          `问卷分析+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`,
        );
      });
    } else {
      showErrorMsg(res, intl);
    }

    setLoading({
      ...loading,
      buttonLoading: false,
    });
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>问卷分析</b>}
      bordered={false}
      extra={
        <AnalysisTenantSelect
          showAll={true}
          currentTenant={currentTenant}
          onChange={changedTenantList}
        />
      }
    >
      <Space>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Search
              placeholder="中心名称"
              onSearch={(value) => {
                setPagination({
                  current: 1,
                  pageSize: 10,
                  total: 0,
                });
                setKeyword(value);
              }}
              style={{ width: 200 }}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <RangePicker
              onChange={onChangeDate}
              placeholder={['开始日期', '结束日期']}
              disabledDate={(current) => {
                return current && current > moment();
              }}
              value={[
                filterDate.startTime
                  ? moment(filterDate.startTime, dateFormat)
                  : null,
                filterDate.endTime ? moment(filterDate.endTime, dateFormat) : null,
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Button loading={loading.buttonLoading} onClick={buttonClick}>
              下载列表
            </Button>
          </Col>
        </Row>
      </Space>
      <p />
      <MagicTable {...tableProps} />
    </Card>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(QuestionAnalysis);
