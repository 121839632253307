import React, { useState, useEffect } from 'react';
import {
  Button,
  Divider,
  Space,
  Spin,
  Drawer,
  Modal,
  message,
  Dropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect, useIntl } from 'umi';
import moment from 'moment';

import {
  GetDiagnosticInformationList,
  GetChiefComplaintList,
  PostDiagnosticInformation,
  PostChiefComplaint,
  PutQuestionnaire,
  GetQuestionnaire,
} from 'services/trainingSystem';

import Question from 'components/Question';
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey';
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils';

import styles from '../detail.less';

const formatType = 'YYYY/MM/DD HH:mm';

const StepFour = ({ activePlan, prePage, nextPage, subjectDetail }) => {
  const intl = useIntl();

  const { assessmentStatus = [] } = activePlan || {};

  const { subjectInfo } = subjectDetail;
  const { id: subjectId } = subjectInfo || {};

  const [loading, setLoading] = useState({
    pageLoading: false, // 页面加载loading
    drawerLoading: false, // 抽屉加载loading
    surveyLoading: false,
  });
  const [visitData, setVisitData] = useState({});
  const [type, setType] = useState('');
  const [operate, setOperate] = useState('');
  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [questionnaireData, setQuestionnaireData] = useState({});

  const { diagnosticInfo = [], chiefComplaint = [] } = visitData || {};
  const showData = type === 'chiefComplaint' ? chiefComplaint : diagnosticInfo;

  const initData = async () => {
    setLoading({
      ...loading,
      pageLoading: true,
    });

    const params = {
      UserId: subjectId,
    };

    const resList = await Promise.all([
      GetDiagnosticInformationList(params),
      GetChiefComplaintList(params),
    ]);

    if (resList.every((item) => item.response.ok)) {
      const [diagnosticInformationList, chiefComplaintList] = resList;
      const {
        items: { items: diagnosticInfoData },
      } = diagnosticInformationList.data;
      const {
        items: { items: chiefComplaintData },
      } = chiefComplaintList.data;
      visitData.diagnosticInfo = diagnosticInfoData;
      visitData.chiefComplaint = chiefComplaintData;
      setVisitData(visitData);
    }

    setLoading({
      ...loading,
      pageLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const addQuestion = async (option) => {
    setType(option);
    setLoading({
      ...loading,
      pageLoading: true,
    });
    const params = {
      userId: subjectId,
    };
    const res =
      option == 'diagnosticInformation'
        ? await PostDiagnosticInformation(params)
        : await PostChiefComplaint(params);

    if (res.response.ok) {
      setQuestionnaireData(res.data);
      setDrawerVisible(true);
    }

    setLoading({
      ...loading,
      pageLoading: false,
    });
  };

  let _questionArr = [];
  const { questionnaireAndAnswer, id: userQuestionnaireId } =
    questionnaireData || {};
  const { questionnaire, answer } = questionnaireAndAnswer || {};
  const { answerContent } = answer || {};
  const { jsonString, correctRate } = questionnaire || {};
  if (operate === 'show') {
    const { questionArr = [] } =
      getCorrentRateAndResult(jsonString, answerContent, correctRate) || {};
    _questionArr = questionArr;
  }

  const submitQuestion = async (payload) => {
    const { answerContent, isComplete, finishedPage } = payload || {};
    const params = {
      userQuestionnaireId,
      answerContent,
      isComplete,
      finishedPage,
    };
    const submitQuestionRes = await PutQuestionnaire(params);

    if (submitQuestionRes.response?.ok) {
      if (isComplete) {
        message.success(intl.formatMessage({ id: 'success' }));
        initData();
        setSubmitData({});
        setDrawerVisible(false);
      }
    }
  };

  const onValueChange = ({
    answerContent,
    isComplete,
    finishedPage,
    needSubmit,
  }) => {
    setSubmitData({ answerContent, isComplete, finishedPage });
    if (needSubmit) {
      onComplete({ answerContent, isComplete, finishedPage });
    }
  };

  const onComplete = ({ answerContent, isComplete, finishedPage }) => {
    if (isComplete) {
      Modal.confirm({
        title: (
          <div style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'submitDes' })}
          </div>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          submitQuestion({ answerContent, isComplete, finishedPage });
        },
      });
    } else {
      submitQuestion({ answerContent, isComplete, finishedPage });
    }
  };

  const questionProps = {
    content: jsonString || '',
    answerContent: (submitData && submitData.answerContent) ?? '',
    answer,
    onComplete,
    onValueChange,
    time: 5,
  };

  const showSurveyProps = {
    contents: _questionArr,
    choice: answer && answer.answerContent ? answer.answerContent : null,
    showIcon: false,
  };

  const showDrawerList = (option) => {
    setType(option);
    setOperate('show');
    setDrawerVisible(true);

    getDataByIndex(0, option);
  };

  const getDataByIndex = async (index, option) => {
    setCurrentAssessmentIndex(index);
    setLoading({
      ...loading,
      surveyLoading: true,
    });

    const _data = option === 'chiefComplaint' ? chiefComplaint : diagnosticInfo;

    const { id } = _data[index] || {};
    const res = await GetQuestionnaire({ id });

    if (res.response.ok) {
      setQuestionnaireData(res.data);
    }

    setLoading({
      ...loading,
      surveyLoading: false,
    });
  };

  const items = showData.map((item, index) => {
    const { id, finishTime } = item || {};
    return {
      key: id,
      label: <a>{moment(finishTime).format(formatType)}</a>,
      onClick: () => getDataByIndex(index, type),
    };
  });

  return (
    <div className={styles.tabContent}>
      <div className={styles.stepFour}>
        <div className={styles.stepFourTitle}>
          {intl.formatMessage({ id: 'Before starting the plan' })}【
          <a
            onClick={() =>
              window.open(`/system/archives?subjectId=${subjectId}`)
            }
          >
            {intl.formatMessage({ id: 'Child Profile' })}
          </a>
          】{intl.formatMessage({ id: 'Completion of the following' })}
        </div>
        <Spin spinning={loading.pageLoading}>
          <div className={styles.stepFourText}>
            <Space>
              <div className={styles.stepFourTextTitle}>
                1. 【{intl.formatMessage({ id: 'diagnosticInformation' })}】
              </div>
              {!diagnosticInfo.length ? (
                <a onClick={() => addQuestion('diagnosticInformation')}>
                  {intl.formatMessage({ id: 'fillOut' })}
                </a>
              ) : (
                <Space size={2} split={<Divider type="vertical" />}>
                  <a onClick={() => showDrawerList('diagnosticInformation')}>
                    {intl.formatMessage({ id: 'filled in' })}
                  </a>
                  <a onClick={() => addQuestion('diagnosticInformation')}>
                    {intl.formatMessage({ id: 'Add New' })}
                  </a>
                </Space>
              )}
            </Space>
            <Space>
              <div className={styles.stepFourTextTitle}>
                2. 【{intl.formatMessage({ id: 'childStrengthsChallenges' })}】
              </div>
              {!chiefComplaint.length ? (
                <a onClick={() => addQuestion('chiefComplaint')}>
                  {intl.formatMessage({ id: 'fillOut' })}
                </a>
              ) : (
                <Space size={2} split={<Divider type="vertical" />}>
                  <a onClick={() => showDrawerList('chiefComplaint')}>
                    {intl.formatMessage({ id: 'filled in' })}
                  </a>
                  <a onClick={() => addQuestion('chiefComplaint')}>
                    {intl.formatMessage({ id: 'Add New' })}
                  </a>
                </Space>
              )}
            </Space>
          </div>
        </Spin>
        <Space size={'large'}>
          {Boolean(assessmentStatus.length) && (
            <Button onClick={prePage}>
              {intl.formatMessage({ id: 'previous' })}
            </Button>
          )}
          <Button
            disabled={!diagnosticInfo.length || !chiefComplaint.length}
            type="primary"
            onClick={nextPage}
          >
            {intl.formatMessage({ id: 'next' })}
          </Button>
        </Space>
      </div>
      <Drawer
        title={intl.formatMessage({
          id:
            type == 'diagnosticInformation'
              ? 'diagnosticInformation'
              : 'childStrengthsChallenges',
        })}
        open={drawerVisible}
        width={700}
        onClose={() => {
          onComplete(submitData);
          setDrawerVisible(false);
          setOperate('');
          initData();
        }}
        extra={
          operate === 'show' && (
            <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {moment(showData[currentAssessmentIndex].finishTime).format(
                    formatType,
                  )}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          )
        }
      >
        {operate === 'show' ? (
          <Spin spinning={loading.surveyLoading}>
            <ShowSurvey {...showSurveyProps} />
          </Spin>
        ) : (
          drawerVisible && <Question {...questionProps} />
        )}
      </Drawer>
    </div>
  );
};

export default connect(({ subjectDetail }) => ({
  subjectDetail,
}))(StepFour);
