import React from 'react';
import process from 'assets/vratReportImg/process.png';
import styles from './index.less';

const MyProcess = (props) => {
  const { percent = 50 } = props;

  let one = (304 - 26 - 14 - 54) / 100;
  let left = percent * one + 38;

  return (
    <div className={styles.panel}>
      <span className={styles.num}>0</span>
      <div className={styles.pool} style={{ marginLeft: `${left}px` }}></div>
      <img src={process} />
      <span className={styles.num}>100</span>
    </div>
  );
};

export default MyProcess;
