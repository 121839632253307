import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal, message } from 'antd'
import { checkFeedbackProperty } from 'utils/utils'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { history } from 'umi';

const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑训练计划',
  add: '新建训练计划',
  remark: '编辑备注'
}

const listSupport = {
  displayName: {
    showText: '显示名称',
    showType: 'Text',
  },
  totalDays: {
    showText: 'totalDays',
    showType: 'Text',
  },
  price: {
    showText: 'price',
    showType: 'Text',
  },
  userPlanCount: {
    showText: 'userPlanCount',
    showType: 'Text',
  },
  // ageStart: {
  //   showText: 'ageStart',
  //   showType: 'Text',
  // },
  // ageEnd: {
  //   showText: 'ageEnd',
  //   showType: 'Text',
  // },
  description: {
    showText: '备注',
    showType: 'Remark',
  }
}

const TrainingPlan = ({ adminTrainingPlan, dispatch, loading }) => {
  const tableLoading = loading.effects['adminTrainingPlan/query']

  useEffect(()=>{
    dispatch({ type: 'adminTrainingPlan/query' })
  }, [])

  const {
    modalType,
    content,
    modalVisible,
    list,
    pagination,
    availableFeedbackProperties
  } = adminTrainingPlan

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'adminTrainingPlan/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      if (modalType === 'remark') {
        dispatch({
          type: 'adminTrainingPlan/onSubmitModal',
          payload: value,
        })
      } else {
        const { feedbackProperties, ageStart, ageEnd } = value
        const allReady = feedbackProperties.every(item => {
          const { extra, feedbackPropertyId } = item
          const feedbackProperty = availableFeedbackProperties.find(propertyItem => {
            return (propertyItem.id === feedbackPropertyId)
          })
          const {type, extraProperties} = feedbackProperty
          return checkFeedbackProperty(type, extra, extraProperties)
        })
  
        if (allReady) {
          const feedbacks = feedbackProperties.map(item => {
            const { extra, } = item
            const extraProperties = JSON.parse(extra)
            return {
              ...item,
              extraProperties,
            }
          })
          dispatch({
            type: 'adminTrainingPlan/onSubmitModal',
            payload: {
              ...value,
              feedbackProperties: feedbacks,
              agePeriod: { start: ageStart, end: ageEnd },
            },
          })
        }
      }
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    loading: tableLoading,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'adminTrainingPlan/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '详情',
        onClick(record) {
          history.push({
            pathname: '/system/manage/planDetail',
            query: {
              templateId: record.id,
            },
          })
        },
      },
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'adminTrainingPlan/onEdit', payload: record.id })
        },
      },
      {
        showText: '编辑备注',
        onClick(record) {
          dispatch({ type: 'adminTrainingPlan/onEditRemark', payload: record })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'adminTrainingPlan/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'adminTrainingPlan/onAdd' })
  }

  return (
    <div>
      <h1>训练计划</h1>
      <Button onClick={onAddClick}>新建训练计划</Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ loading, adminTrainingPlan }) => ({ loading, adminTrainingPlan }))(TrainingPlan)
