import { useState } from 'react'
import { Modal, Button, Radio } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { PrepareEditUser } from 'services/userManager'
import styles from './index.less'
import { IntlManager } from 'utils/helper'

const ApplyModal = ({ visible, selectItem, onhandleOK, onhandleCancel }) => {
    const intl = IntlManager.MyIntl()

    const [auditResult, setAuditResult] = useState("UnReviewed")
    const [userRoles, setUserRoles] = useState([])
    const [role, setRole] = useState("Coach")

    const { trainer } = selectItem || {}
    const { email } = trainer || {}

    const onOk = () => {
        const params = {
            auditResult,
            role
        }
        onhandleOK(params)
    }

    const onCancel = () => {
        onhandleCancel()
    }

    return (
        <Modal
            title={<b style={{ fontSize: '17px' }}>{intl('reviewTrainingApplications')}</b>}
            open={visible}
            onOk={onOk}
            onCancel={onCancel}
            footer={null}
        >
            <div className={styles.applyModal}>
                <p className={styles.applyModalTitle}>{intl('resultOfTrainingEmail', { email: email })}</p>
                <div className={styles.applyModalButton}>
                    <div
                        className={styles.applyModalButtonItem}
                        style={{
                            background: auditResult === "NotPassed" ? "#FF6600" : 'white',
                            color: auditResult === "NotPassed" ? "white" : '#000',
                            border: auditResult === "NotPassed" ? "1px solid rgba(0,0,0,0)" : '1px solid #ccc',
                        }}
                        onClick={() => setAuditResult("NotPassed")}
                    >
                        <CloseOutlined /> {intl('decline')}
                    </div>
                    <div
                        className={styles.applyModalButtonItem}
                        style={{
                            background: auditResult === "Passed" ? "#1890FF" : 'white',
                            color: auditResult === "Passed" ? "white" : '#000',
                            border: auditResult === "Passed" ? "1px solid rgba(0,0,0,0)" : '1px solid #ccc',
                        }}
                        onClick={async () => {
                            const res = await PrepareEditUser({ type: 'Center' })
                            if (res.response.ok) {
                                const Roles = res.data.roles.filter(r => r.name !== 'CenterAdmin')
                                setUserRoles(Roles)
                            } else {
                                const data = res.data
                                const { error } = data || {}
                                error && message.error(error.message)
                            }

                            setAuditResult("Passed")
                        }}
                    >
                        <CheckOutlined /> {intl('pass')}
                    </div>
                </div>
                {
                    auditResult === "Passed" &&
                    <div className={styles.applyModalRole}>
                        <span><span style={{ color: 'red' }}>*</span> {intl('useRole')} </span>
                        <Radio.Group onChange={(e) => setRole(e.target.value)} value={role}>
                            {userRoles.map(item => {
                                return (
                                    <Radio key={item.id} value={item.name}>{item.displayName}</Radio>
                                )
                            })}
                        </Radio.Group>
                    </div>
                }
                <div className={styles.applyModalBottom}>
                    <Button style={{ width: '100px' }} onClick={() => onCancel()}>{intl("cancel")}</Button>
                    <Button type="primary" style={{ width: '100px', marginLeft: '10px' }} onClick={() => onOk()}>{intl("submitOther")}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default ApplyModal