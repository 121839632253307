import React from 'react';
import styles from '../index.less';

const ContentLine = ({ color = 'rgba(0, 192, 215, 0.1)', content }) => {
  return (
    <div className={styles.contentLine} style={{ backgroundColor: color }}>
      <div className={styles.lineContent}>{content}</div>
    </div>
  );
};

export default ContentLine;
