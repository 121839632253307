import React, { useEffect } from 'react';
import { connect, useIntl, history } from 'umi';
import { Card, Input, DatePicker, Button } from 'antd';
import { getFormatDate, getAssessmentStatus, downloadQr } from 'utils/utils';
import AssessmentSelectModal from 'components/SelectModal';
import moment from 'moment';
import styles from './index.less';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import SendToGuardian from './SendToGuardian';
import { getToken, getTenantId } from 'cognitiveleap-core-us/utils/auth';
import config from 'utils/config';
const { mobileUrl } = config;
const { Search } = Input;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const QuestionnaireList = ({
  adminquestionnaireList,
  dispatch,
  loading,
  currentUser,
}) => {
  const intl = useIntl();

  const {
    childName,
    filterDate,
    pagination,
    orderBy,
    filters,
    listData,
    sendToGuardianModalVisible,
    selectAssessmentModalVisible,
    transferList,
    assessmentName,
    currentTenant,
  } = adminquestionnaireList;
  const { current, changedTenant } = currentUser;

  useEffect(() => {
    dispatch({ type: 'adminquestionnaireList/changeTenant' });
  }, []);

  const listSupport = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
      },
      sorter: {
        hasSorter: true,
      },
    },
    assessmentName: {
      showText: '测试名称',
      showType: 'Text',
    },
    childName: {
      showText: '儿童',
      showType: 'Text',
      render: (text, data) => {
        const { userId } = data;
        return (
          <a
            onClick={() =>
              history.push({
                pathname: '/system/manage/subjectDetail',
                query: {
                  id: userId,
                },
              })
            }
          >
            {text}
          </a>
        );
      },
    },
    gender: {
      showText: intl.formatMessage({ id: 'gender' }),
      showType: 'Text',
    },
    school: {
      showText: '学校',
      showType: 'Text',
    },
    age: {
      showText: '年龄',
      showType: 'Text',
    },
    // guardianName: {
    //     showText: '家长',
    //     showType: 'Text',
    //     render: (text, data) => {
    //         const { userInfo } = data || {}
    //         const { guardianId } = userInfo || {}
    //         return (
    //             <a
    //                 onClick={() => history.push({
    //                     pathname: '/system/manage/userDetail',
    //                     query: {
    //                         id: guardianId
    //                     }
    //                 })}>
    //                 {text}
    //             </a>
    //         )
    //     }
    // },
    // phoneNumber: {
    //     showText: '手机号',
    //     showType: 'Text',
    // },
    assessmentStatus: {
      showText: '状态',
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: [
          {
            text: getAssessmentStatus('None', intl),
            value: 'None',
          },
          {
            text: getAssessmentStatus('Started', intl),
            value: 'Started',
          },
          {
            text: getAssessmentStatus('Finished', intl),
            value: 'Finished',
          },
          {
            text: getAssessmentStatus('ReportedReady', intl),
            value: 'ReportedReady',
          },
          {
            text: getAssessmentStatus('Closed', intl),
            value: 'Closed',
          },
        ],
      },
    },
  };

  const onSearch = () => {
    dispatch({
      type: 'adminquestionnaireList/onSearch',
    });
  };

  const onChangeChildName = (name) => {
    dispatch({
      type: 'adminquestionnaireList/updateState',
      payload: { childName: name },
    });
  };

  const onChangeAssessmentName = (name) => {
    dispatch({
      type: 'adminquestionnaireList/updateState',
      payload: { assessmentName: name },
    });
  };

  const onChangeDate = async (value) => {
    const startTime = value && value[0] ? getFormatDate(value[0], true) : '';
    const endTime = value && value[1] ? getFormatDate(value[1], false) : '';
    await dispatch({
      type: 'adminquestionnaireList/updateState',
      payload: { filterDate: { startTime, endTime } },
    });
    onSearch();
  };

  const clearFilter = () => {
    dispatch({
      type: 'adminquestionnaireList/clearFilter',
    });
  };

  const assessmentActions = [
    {
      showText: '查看',
      itemRender(record) {
        const { status } = record;
        return status === 'ReportedReady' ? (
          <span key="view">
            <a
              onClick={() => {
                const reportUrl = `${mobileUrl}/m/rocketReport?to=${
                  record.id
                }&t=${getToken()}&h=${getTenantId()}`;
                window.open(reportUrl, '_blank');
              }}
            >
              查看
            </a>
          </span>
        ) : null;
      },
    },
    // {
    //     showText: '下载',
    //     itemRender(record) {
    //         const { status } = record
    //         return status === 'ReportedReady' ? (
    //             <span key="downloadPDF">
    //                 <a

    //                 >
    //                     下载
    //           </a>
    //             </span>
    //         ) : null
    //     },
    // },
    // {
    //     showText: '发送给家长',
    //     itemRender(record) {
    //         const { status, confirmType } = record
    //         return status === 'ReportedReady' ? (
    //             <span key="view">
    //                 {
    //                     confirmType == 'Public' ?
    //                         <span style={{color: 'grey'}}>已发送</span>
    //                         :
    //                         <a
    //                             onClick={() => {
    //                                 dispatch({
    //                                     type: 'adminquestionnaireList/updateState',
    //                                     payload: {
    //                                         sendToGuardianModalVisible: true,
    //                                         currentSendToGuardianRecord: record
    //                                     }
    //                                 })
    //                             }}
    //                         >
    //                             发送给家长
    //                         </a>

    //                 }
    //             </span>
    //         ) : null
    //     }
    // }
  ];

  const newListData = listData.map((item) => {
    const { assessment, userInfo, status } = item;
    const { gender, monthAge, phoneNumber, guardianName, school } =
      userInfo || {};
    const year = parseInt(monthAge / 12);
    const month = parseInt(monthAge % 12);
    const age =
      year + month == 0
        ? '0岁'
        : `${year > 0 ? `${year}岁` : ''}${month > 0 ? `${month}个月` : ''}`;
    return {
      ...item,
      assessmentName: assessment.title,
      childName: userInfo.name,
      age,
      assessmentStatus: getAssessmentStatus(status, intl),
      gender,
      phoneNumber,
      guardianName,
      school,
    };
  });

  const tableProps = {
    loading: loading.models.adminquestionnaireList,
    listData: newListData,
    listSupport,
    pagination,
    filters,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination, filters };
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi };
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt };
      if (Object.keys(sorter).length !== 0) data = { ...data, sorter: sorter };
      dispatch({ type: 'adminquestionnaireList/changeTable', payload: data });
    },
    scroll: true,
    sorter: {
      columnKey: 'creationTime',
      order: orderBy,
    },
    Actions: assessmentActions,
    actionTitle: '报告',
  };

  const sendToGuardianProps = {
    visible: sendToGuardianModalVisible,
    loading: loading.effects['adminquestionnaireList/sendToGuardian'],
    onCancel: () => {
      dispatch({
        type: 'adminquestionnaireList/updateState',
        payload: {
          sendToGuardianModalVisible: false,
        },
      });
    },
    onOk: () => {
      dispatch({
        type: 'adminquestionnaireList/sendToGuardian',
      });
    },
  };

  const selectAssessmentModalProps = {
    defaultSelectKeys: [],
    title: '添加测评',
    loading: loading.effects['adminquestionnaireList/uploadAssessmentQr'],
    allList: transferList,
    selectModalVisible: selectAssessmentModalVisible,
    onOk(keys) {
      dispatch({
        type: 'adminquestionnaireList/updateState',
        payload: { selectAssessmentModalVisible: false },
      });
      keys.forEach((item) => {
        dispatch({
          type: 'adminquestionnaireList/uploadAssessmentQr',
          payload: { key: item },
        }).then((res) => {
          const currentAssessment = transferList.filter(
            (listItem) => item == listItem.id,
          );
          const title = currentAssessment[0].title;
          downloadQr({
            type: 'base64',
            data: res.data,
            title,
            subTitle: '扫一扫做测评',
            description: changedTenant.displayName,
            downloadName: `${
              changedTenant.displayName
            }_${title}_二维码_${moment().format('YYYYMMDD')}`,
          });
        });
      });
    },
    onCancel() {
      dispatch({
        type: 'adminquestionnaireList/updateState',
        payload: { selectAssessmentModalVisible: false },
      });
    },
  };

  return (
    <Card
      title={<b>问卷测评列表</b>}
      bordered={false}
      className={styles.questionnaireList}
      extra={
        <AnalysisTenantSelect
          showAll={true}
          currentTenant={currentTenant}
          onChange={(e) => {
            dispatch({
              type: 'adminquestionnaireList/changeTenant',
              payload: { id: e },
            });
          }}
        />
      }
    >
      <div className={styles.questionnaireListSearch}>
        <div className={styles.questionnaireListSearchLeft}>
          <Search
            placeholder="儿童姓名/学校"
            value={childName}
            onChange={(e) => onChangeChildName(e.target.value)}
            onSearch={(value) => onSearch(value)}
            style={{ height: 32, maxWidth: 220 }}
            className={styles.questionnaireListSearchItem}
          />
          <Search
            placeholder="测试名称"
            value={assessmentName}
            onChange={(e) => onChangeAssessmentName(e.target.value)}
            onSearch={(value) => onSearch(value)}
            style={{ height: 32, maxWidth: 220 }}
            className={styles.questionnaireListSearchItem}
          />
          <RangePicker
            onChange={(date) => onChangeDate(date)}
            placeholder={['开始日期', '结束日期']}
            disabledDate={(current) => {
              return current && current > moment();
            }}
            value={[
              filterDate.startTime
                ? moment(filterDate.startTime, dateFormat)
                : null,
              filterDate.endTime
                ? moment(filterDate.endTime, dateFormat)
                : null,
            ]}
            className={styles.questionnaireListSearchItem}
          />
          <Button onClick={clearFilter}>取消筛选</Button>
        </div>
        {/*
                    <div>
                        <Button type='primary' onClick={() => {
                            dispatch({
                                type: 'adminquestionnaireList/showAssessmentList'
                            })
                            }}>
                            测评二维码
                        </Button>
                    </div>
                */}
      </div>

      <MagicTable {...tableProps} />
      <SendToGuardian {...sendToGuardianProps} />
      <AssessmentSelectModal {...selectAssessmentModalProps} />
    </Card>
  );
};

export default connect(({ adminquestionnaireList, loading, user }) => ({
  adminquestionnaireList,
  loading,
  currentUser: user.currentUser,
}))(QuestionnaireList);
