import { useState, useEffect, useRef } from 'react';
import { PrefixZero } from 'utils/utils'
import moment from 'moment'

export default function useTimer(data, startTime, showWarn, showFinishWarn, changTimeStyle) {
    const { duration } = data
    const deadLine = moment(startTime).add(duration, 'minutes')
    const deadLineTime = deadLine.diff(moment(), 'seconds')

    const [currentTime, setTime] = useState(null)

    const timerID = useRef()

    const getTime = (durationTime) => {
        return `${PrefixZero(durationTime.hours(), 2)} : ${PrefixZero(durationTime.minutes(), 2)} : ${PrefixZero(durationTime.seconds(), 2)}`
    }

    const isFinish = (deadLineTime) => deadLineTime < 0

    useEffect(() => {
        JSON.stringify(data) !== '{}' && !isFinish(deadLineTime) && (timerID.current = setInterval(() => {
            const durationTime = moment.duration(deadLine.diff(moment(), 'seconds'), 'seconds')
            if (!isFinish(durationTime)) {
                const arriveTime = getTime(durationTime)
                if (arriveTime === '00 : 01 : 00') {
                    changTimeStyle()
                }
                if (arriveTime === '00 : 03 : 00') {
                    showWarn()
                }
                setTime(() => arriveTime)
            } else {
                showFinishWarn()
                clearInterval(timerID.current)
            }
        }, 1000))
    }, [data])

    useEffect(() => {
        return () => {
            clearInterval(timerID.current)
        }
    }, [])

    return currentTime;
}