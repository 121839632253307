import React from 'react'
import { connect } from 'umi'
import { Row, Col, Badge } from 'antd'
import styles from './Report.less'
import { useIntl, getLocale } from 'umi'
import RoseChart from 'components/VratChartComponents/RoseChart'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const bgImgList = {
  'zh-CN': styles.vrClassroomImgCN,
  'en-US': styles.vrClassroomImg,
  'es-ES': styles.vrClassroomImgES
}

const DirectionTracking = ({ dispatch, data, showNewReport }) => {
  const showData = data ? data.Time : []

  const intl = useIntl()
  const RoseChartProps = {
    data: showData,
  }
  const minutes = data ? data.AttentionTimeOnWhiteboard.toFixed(3) : ''
  const percent = data ? `${(data.Percentile * 100).toFixed(2)}%` : '0%'

  function getColor(percent) {
    if (percent <= 33) {
      return '#42BF80'
    } else if (percent > 33 && percent <= 67) {
      return '#FFD352'
    } else if (percent > 67) {
      return '#F3766E'
    }
  }

  const showColor = data ? getColor(100 - data.Percentile * 100) : '#F3766E'

  return (
    <div id="direction" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.directionTracking.title' })}</p>
      </h2>
      <Row type="flex" justify="space-around" style={{ marginTop: 80 }}>
        <Col span={12} className={bgImgList[getLocale()]}>
          <RoseChart {...RoseChartProps} />
          <h2 className={`${styles.brainPic} ${styles.weightFont}`}>
            {intl.formatMessage({ id: 'report.directionTracking.chart.title' })}
          </h2>
        </Col>
        <Col span={10}>
          <div>
            <p style={{ fontSize: 20, fontWeight: 700, color: '#333', marginBottom: 20 }}>
              <span>{intl.formatMessage({ id: 'report.directionTracking.p1.desc1' })}</span>
              <span style={{ fontSize: 28, color: showColor }}>{minutes}</span>
              <span>{intl.formatMessage({ id: 'report.directionTracking.p1.desc2' })}</span>
              <span style={{ fontSize: 28, color: showColor }}>{percent}</span>
              <span>{intl.formatMessage({ id: 'report.directionTracking.p1.desc3' })}</span>
            </p>
            <p style={{ color: '#333' }}>
              <Badge status="default" />
              {intl.formatMessage({ id: 'report.directionTracking.p2' })}
            </p>
            <p style={{ color: '#333' }}>
              <Badge status="default" />
              {intl.formatMessage({ id: 'report.directionTracking.p3' })}
            </p>
            <p style={{ color: '#333' }}>
              <Badge status="default" />
              {intl.formatMessage({ id: 'report.directionTracking.p4' })}
            </p>
          </div>
        </Col>
      </Row>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '9' : '9'}</p>
    </div>
  )
}

export default connect()(DirectionTracking)
