import { useEffect } from 'react';
import { history } from 'umi';
import { Button, Modal, Table, Divider } from 'antd'
import { connect } from 'umi'
import SortOrderModal from 'components/SortOrderModal'
import { CaretLeftOutlined } from '@ant-design/icons'
import SelectModal from './SelectModal'

const { confirm } = Modal

const CourseDetail = ({ dispatch, courseDetail, location }) => {
  const { modalVisible, courseInfo, content, selectItem, sortOrderModal } = courseDetail

  const { title = '', contents = [] } = courseInfo || {}

  useEffect(() => {
    dispatch({ type: 'courseDetail/loadPage', payload: location.query })
  }, [])

  const onAddBtnClick = () => {
    dispatch({ type: 'courseDetail/onAdd' })
  }

  const onChangeSortOrder = selectItem => {
    dispatch({
      type: 'courseDetail/updateState',
      payload: { selectItem, sortOrderModal: true },
    })
  }

  const onDelete = id => {
    confirm({
      title: 'Are you sure to remove this course item from current course?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch({ type: 'courseDetail/deleteItem', payload: id })
      },
    })
  }

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
    },
    {
      title: 'contentType',
      dataIndex: 'contentType',
    },
    {
      title: 'experience',
      dataIndex: 'experience',
      render: experience => <span>{experience ? 'true' : 'false'}</span>,
    },
    {
      title: 'avaliable',
      dataIndex: 'avaliable',
      render: avaliable => <span>{avaliable ? 'true' : 'false'}</span>,
    },
    {
      title: 'accessCount',
      dataIndex: 'accessCount',
    },
    {
      title: 'sortOrder',
      dataIndex: 'sortOrder',
    },
    {
      title: 'Action',
      render: (text, record) => (
        <span>
          <a onClick={() => onChangeSortOrder(record)}>Change SortOrder</a>
          <Divider type="vertical" />
          <a onClick={() => onDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ]

  const onBack = () => {
    history.goBack()
  }

  const selectModalProps = {
    defaultSelectKeys: contents.map(item => item.id),
    allList: content ? content.items : [],
    selectModalVisible: modalVisible,
    onOk(keys) {
      dispatch({ type: 'courseDetail/addCourseItem', payload: keys })
    },
    onCancel() {
      dispatch({ type: 'courseDetail/updateState', payload: { modalVisible: false } })
    },
  }

  const sortOrderProps = {
    visible: sortOrderModal,
    onCancel() {
      dispatch({ type: 'courseDetail/updateState', payload: { sortOrderModal: false } })
    },
    onOk(data) {
      dispatch({ type: 'courseDetail/changeSortOrder', payload: data.sortOrder })
    },
    currentValue: selectItem && selectItem.sortOrder,
  }

  return (
    <div>
      <h1>{title}</h1>
      <Button icon={<CaretLeftOutlined />} onClick={onBack}>
        Back
      </Button>
      <Divider type="vertical" />
      <Button onClick={onAddBtnClick}>编辑课程小节</Button>
      <Divider />
      <Table dataSource={contents} columns={columns} rowKey={'id'} />
      <SortOrderModal {...sortOrderProps} />
      <SelectModal {...selectModalProps} />
    </div>
  )
}

export default connect(({ courseDetail }) => ({ courseDetail }))(CourseDetail)
