import React from 'react';
import { Space } from 'antd';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import round from 'assets/newVratReportImg/round.png';
import square from 'assets/newVratReportImg/square.png';
import triangle from 'assets/newVratReportImg/triangle.png';
import LineWithInterfereChart from 'components/NewVratChartComponents/LineWithInterfereChart';
import BottomPageNum from './components/BottomPageNum';
import { useIntl } from 'umi';
const RealtimePerformanceTwo = ({ data }) => {
  const intl = useIntl();
  const { Motion = [], Behaviour = [], Distractor = [] } = data || {};

  const MotionOne = [];
  const MotionTwo = [];
  const MotionThree = [];
  const MotionFour = [];

  const BehaviourOne = [];
  const BehaviourTwo = [];
  const BehaviourThree = [];
  const BehaviourFour = [];

  const DistractorOne = [];
  const DistractorTwo = [];
  const DistractorThree = [];
  const DistractorFour = [];

  Motion.forEach((item) => {
    const { Minute } = item || {};
    if (Minute >= 0 && Minute <= 3.25) {
      MotionOne.push(item);
    }
    if (Minute > 3.24 && Minute <= 6.5) {
      MotionTwo.push(item);
    }
    if (Minute > 6.49 && Minute <= 9.75) {
      MotionThree.push(item);
    }
    if (Minute > 9.74 && Minute <= 13) {
      MotionFour.push(item);
    }
  });

  Behaviour.forEach((item) => {
    const { Minute } = item || {};
    if (Minute >= 0 && Minute <= 3.25) {
      BehaviourOne.push(item);
    } else if (Minute >= 3.25 && Minute <= 6.5) {
      BehaviourTwo.push(item);
    } else if (Minute >= 6.5 && Minute <= 9.75) {
      BehaviourThree.push(item);
    } else if (Minute >= 9.75 && Minute <= 13) {
      BehaviourFour.push(item);
    }
  });

  Distractor.forEach((item) => {
    const { Minute } = item || {};
    if (Minute >= 0 && Minute <= 3.25) {
      DistractorOne.push(item);
    } else if (Minute >= 3.25 && Minute <= 6.5) {
      DistractorTwo.push(item);
    } else if (Minute >= 6.5 && Minute <= 9.75) {
      DistractorThree.push(item);
    } else if (Minute >= 9.75 && Minute <= 13) {
      DistractorFour.push(item);
    }
  });

  const BlockOneProps = {
    Motion: MotionOne,
    Behaviour: BehaviourOne,
    Distractor: DistractorOne,
    showLegend: true,
    min: 0,
    max: 3.25,
  };

  const BlockTwoProps = {
    Motion: MotionTwo,
    Behaviour: BehaviourTwo,
    Distractor: DistractorTwo,
    min: 3.25,
    max: 6.5,
  };

  const BlockThreeProps = {
    Motion: MotionThree,
    Behaviour: BehaviourThree,
    Distractor: DistractorThree,
    min: 6.5,
    max: 9.75,
  };

  const BlockFourProps = {
    Motion: MotionFour,
    Behaviour: BehaviourFour,
    Distractor: DistractorFour,
    min: 9.75,
    max: 13,
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.realtimePerformanceTwo}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.title}>{intl.formatMessage({ id: 'RealtimePerformanceTwo.title' })}</div>
          <Space className={styles.titleDes} align="center" size="middle">
            <Space align="center">
              <img src={round} />
              {intl.formatMessage({ id: 'RealtimePerformanceTwo.visual' })}
            </Space>
            <Space align="center">
              <img src={square} />
              {intl.formatMessage({ id: 'RealtimePerformanceTwo.auditory' })}
            </Space>
            <Space align="center">
              <img src={triangle} />
              {intl.formatMessage({ id: 'RealtimePerformanceTwo.mixed' })}
            </Space>
          </Space>
          <div className={styles.chartContent}>
            <LineWithInterfereChart {...BlockOneProps} />
            <LineWithInterfereChart {...BlockTwoProps} />
            <LineWithInterfereChart {...BlockThreeProps} />
            <LineWithInterfereChart {...BlockFourProps} />
          </div>
        </div>
        <BottomPageNum num={13} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default RealtimePerformanceTwo;
