import { connect, useIntl } from 'umi'
import { useEffect } from 'react'
import { Button, Table, Row, Col } from 'antd'
import QuestionnaireModal from './QuestionnaireModal'
import moment from 'moment'
import PaginationCom from 'components/Pagination/Pagination'
import DocumentTitle from 'react-document-title'

const QuesionnaireList = ({ questionList, dispatch }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'questionList/fetch' })
  }, [])

  const { list, pageIndex, total, pageSize, modalVisible, currentQuesionnaire } = questionList

  const onEdit = record => {
    dispatch({ type: 'questionList/clickEdit', payload: record.Id })
  }

  const showLanguage = text => {
    switch (text) {
      case 'en':
        return intl.formatMessage({ id: 'english' })
      default:
        return intl.formatMessage({ id: 'chinese' })
    }
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'creationTime' }),
      dataIndex: 'CreationTime',
      render: text => (
        <span>{moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'displayName' }),
      dataIndex: 'DisplayName',
    },
    {
      title: intl.formatMessage({ id: 'language' }),
      dataIndex: 'Language',
      render: (text, record) => <span>{showLanguage(text)}</span>,
    },
    {
      title: intl.formatMessage({ id: 'type' }),
      dataIndex: 'Type',
    },
    {
      title: intl.formatMessage({ id: 'action' }),
      dataIndex: 'action',
      render: (text, record, index) => (
        <a href="#!" onClick={() => onEdit(record)}>
          {intl.formatMessage({ id: 'edit' })}
        </a>
      ),
    },
  ]

  const questionModalProps = {
    visible: modalVisible,
    initData: currentQuesionnaire,
    onCancel() {
      dispatch({
        type: 'questionList/updateState',
        payload: { modalVisible: false, currentQuesionnaire: null },
      })
    },
    onOk(value) {
      dispatch({ type: 'questionList/addQuesionnaire', payload: value })
    },
  }

  const onShowModal = () => {
    dispatch({
      type: 'questionList/updateState',
      payload: { modalVisible: true, currentQuesionnaire: null },
    })
  }

  const onPageChange = (pageIndex, pageSize) => {
    dispatch({ type: 'questionList/filterAndFetch', payload: { pageIndex, pageSize } })
  }

  const paginationComProps = { onPageChange, pageIndex, pageSize, total }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'questionnaireList' })}>
      <div>
        <div>
          <span>{intl.formatMessage({ id: 'questionnaireList' })}</span>
        </div>
        <Row type="flex" justify="end" style={{ marginBottom: 20, marginTop: -20 }}>
          <Col>
            <Button type="primary" onClick={onShowModal}>
              {intl.formatMessage({ id: 'newQuestionnaire' })}
            </Button>
          </Col>
        </Row>
        <div>
          <Table
            rowKey={record => record.Id}
            columns={columns}
            dataSource={list}
            pagination={false}
          />
          <div style={{ marginTop: 30 }}>
            <PaginationCom {...paginationComProps} />
          </div>
        </div>
        <QuestionnaireModal {...questionModalProps} />
      </div>
    </DocumentTitle>
  )
}

export default connect(({ questionList }) => ({ questionList }))(QuesionnaireList)
