import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import BottomPageNum from './components/BottomPageNum';
import HeaderLine from './components/HeaderLine';
import { useIntl } from 'umi';
const WelcomLetters = () => {
  const intl = useIntl();
  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.welcomLetters}>
        <HeaderLine color="#ea4c89" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'WelcomeLetters.title' })}</div>
          <div className={styles.centerDes}>
            {intl.formatMessage({ id: 'WelcomeLetters.parents' })} <p />
            {intl.formatMessage({ id: 'WelcomeLetters.attention' })} <p />
            {intl.formatMessage({ id: 'WelcomeLetters.indicators' })} <p />
            {intl.formatMessage({ id: 'WelcomeLetters.discriminability' })}
            <p />
            {intl.formatMessage({ id: 'WelcomeLetters.information' })}
            <p />
            {intl.formatMessage({ id: 'WelcomeLetters.scores' })}
            <p />
            {intl.formatMessage({ id: 'WelcomeLetters.believe' })}
            <p />
            {intl.formatMessage({ id: 'WelcomeLetters.regards' })}
            <p />
            {intl.formatMessage({ id: 'WelcomeLetters.team' })}
          </div>
        </div>
        <BottomPageNum num={2} />
        <BottomDes color="#ea4c89" />
      </div>
    </div>
  );
};

export default WelcomLetters;
