import React from 'react';
import { useIntl } from 'umi';

import { Empty } from 'antd';

import abilityRemember from './imgs/abilityRemember.png';
import bigMove from './imgs/bigMove.png';
import concentration from './imgs/concentration.png';
import fineMotor from './imgs/fineMotor.png';
import organizationalPlanning from './imgs/organizationalPlanning.png';
import sensoryProcessing from './imgs/sensoryProcessing.png';
import socialize from './imgs/socialize.png';
import stateOfMind from './imgs/stateOfMind.png';
import timeAwareness from './imgs/timeAwareness.png';
import auditoryLanguage from './imgs/auditoryLanguage.png';
import hyperactiveAndImpulsive from './imgs/hyperactiveAndImpulsive.png';

import styles from './index.less';

const SportItemList = ({ recommendDifficulty, reportData = {} }) => {
  const intl = useIntl();

  const {
    attention,
    emotion,
    fineMotorSkills,
    grossMotorSkills,
    hyperactivityOrImpulsivity,
    memory,
    organizingOrPlanning,
    perception,
    socialSkills,
    timePerception,
    verbalComprehension,
    //大运动
    GML,
    GMH,
    //精细动作
    FMH,
    FML,
    //感知觉
    PL,
    PH,
    //情绪
    EH,
    EL,
    //社交
    SH,
    SL,
    //专注力
    ACH,
    ACL,
    //多动/冲动
    OIH,
    OIL,
    //组织性/条理性/计划
    POH,
    POL,
    //时间知觉
    CTH,
    CTL,
    //记忆力
    MH,
    ML,
    //口语理解
    CSLH,
    CSLL,
  } = reportData || {};

  if (Object.keys(reportData).length === 0) return;

  const itemList = [
    {
      icon: bigMove,
      title: intl.formatMessage({ id: 'GrossMotorSkills' }),
      content: intl.formatMessage({ id: 'FAS2Des1' }),
      value: grossMotorSkills,
      lowValue: GML,
      hightValue: GMH,
    },
    {
      icon: fineMotor,
      title: intl.formatMessage({ id: 'FineMotorSkills' }),
      content: intl.formatMessage({ id: 'FAS2Des2' }),
      value: fineMotorSkills,
      lowValue: FML,
      hightValue: FMH,
    },
    {
      icon: sensoryProcessing,
      title: intl.formatMessage({ id: 'Perception' }),
      content: intl.formatMessage({ id: 'FAS2Des3' }),
      value: perception,
      lowValue: PL,
      hightValue: PH,
    },
    {
      icon: stateOfMind,
      title: intl.formatMessage({ id: 'Emotion' }),
      content: intl.formatMessage({ id: 'FAS2Des4' }),
      value: emotion,
      lowValue: EL,
      hightValue: EH,
    },
    {
      icon: socialize,
      title: intl.formatMessage({ id: 'Social' }),
      content: intl.formatMessage({ id: 'FAS2Des5' }),
      value: socialSkills,
      lowValue: SL,
      hightValue: SH,
    },
    {
      icon: concentration,
      title: intl.formatMessage({ id: 'Attention' }),
      content: intl.formatMessage({ id: 'FAS2Des6' }),
      value: attention,
      lowValue: ACL,
      hightValue: ACH,
    },
    {
      icon: hyperactiveAndImpulsive,
      title: intl.formatMessage({ id: 'hyperactivity_impulsivity' }),
      content: intl.formatMessage({ id: 'FAS2Des7' }),
      value: hyperactivityOrImpulsivity,
      lowValue: OIL,
      hightValue: OIH,
    },
    {
      icon: timeAwareness,
      title: intl.formatMessage({ id: 'TimePerception' }),
      content: intl.formatMessage({ id: 'FAS2Des8' }),
      value: timePerception,
      lowValue: CTL,
      hightValue: CTH,
    },
    {
      icon: organizationalPlanning,
      title: intl.formatMessage({ id: 'OrganizingPlanning' }),
      content: intl.formatMessage({ id: 'FAS2Des9' }),
      value: organizingOrPlanning,
      lowValue: POL,
      hightValue: POH,
    },
    {
      icon: abilityRemember,
      title: intl.formatMessage({ id: 'Memory' }),
      content: intl.formatMessage({ id: 'FAS2Des11' }),
      value: memory,
      lowValue: ML,
      hightValue: MH,
    },
    {
      icon: auditoryLanguage,
      title: intl.formatMessage({ id: 'auditoryLanguage' }),
      content: intl.formatMessage({ id: 'FAS2Des10' }),
      value: verbalComprehension,
      lowValue: CSLL,
      hightValue: CSLH,
    },
  ]
    .map((item) => {
      const { value, lowValue, hightValue } = item;
      if (value > hightValue) {
        return {
          ...item,
          level: 2,
        };
      } else if (value >= lowValue && value <= hightValue) {
        return {
          ...item,
          level: 1,
        };
      }
      return null;
    })
    .filter((item) => item)
    .sort((a, b) => b.level - a.level)
    .slice(0, 3);

  return (
    <div className={styles.sportPlanContent}>
      <div className={styles.sportPlanContentTop}>
        <div>
          {intl.formatMessage({ id: 'Initial difficulty' })}：
          {recommendDifficulty}
        </div>
        <div>{intl.formatMessage({ id: 'Training Focus' })}</div>
      </div>
      <div className={styles.sportPlanContentBottom}>
        {itemList.length ? (
          itemList.map((item, index) => {
            const { icon, title, content } = item;
            return (
              <div className={styles.sportItemTotal} key={title}>
                <div className={styles.sportItem}>
                  <img src={icon} alt="" />
                  <div>
                    <div className={styles.sportItemTitle}>{title}</div>
                    <div className={styles.sportItemContent}>{content}</div>
                  </div>
                </div>
                {index < itemList.length - 1 && (
                  <hr
                    style={{
                      border: 'none',
                      display: 'block',
                      height: '1px',
                      width: '732px',
                      backgroundColor: '#E8E8E8',
                    }}
                  />
                )}
              </div>
            );
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
};

export default SportItemList;
