import React, { useEffect, useState } from 'react';
import { Input, Button, Row, Col } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import AddTrainModal from 'components/UserConfigurationModal/AddTrainModal';
import { useIntl, history, connect } from 'umi';

import { GetClassHoursSettingRecordsList } from 'services/userConfiguration';

const { Search } = Input;

const listSupport = (intl, getRemainingTimesStyle) => {
  return {
    name: {
      showText: intl.formatMessage({ id: 'subjectName' }),
      showType: 'Text',
      render: (text, record) => {
        const { subject } = record || {};
        const { id } = subject || {};
        return (
          <a
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: '/system/center/detail/subjectDetail',
                query: {
                  id,
                },
              });
            }}
          >
            {text}
          </a>
        );
      },
    },
    remainingTimes: {
      showText: intl.formatMessage({ id: 'Remaining Sessions' }),
      showType: 'Text',
      render: (text) => {
        return <div style={getRemainingTimesStyle(text)}>{text}</div>;
      },
    },
    usedTimes: {
      showText: intl.formatMessage({ id: 'Used Sessions' }),
      showType: 'Text',
    },
    totalTimes: {
      showText: intl.formatMessage({ id: 'Total Sessions' }),
      showType: 'Text',
    },
    lastSubmitTime: {
      showText: intl.formatMessage({ id: 'LastSubmissionTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
      },
    },
  };
};

const TrainList = ({ currentUser, getRemainingTimesStyle }) => {
  const intl = useIntl();

  const { auth } = currentUser || {}
  const { grantedPolicies } = auth || {}

  const haveAddPermission = grantedPolicies['RocketSystem.ClassHours.Create'];

  const [listData, setListData] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetClassHoursSettingRecordsList({
      Filter: keyword,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { items, totalCount } = res.data || {};
      setListData(items);

      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [keyword, pagination.pageSize, pagination.current]);

  const expandOptions = (record, index, indent, expanded) => {
    const columns = {
      classHours: {
        showText: intl.formatMessage({ id: 'Training Sessions' }),
        showType: 'Text',
      },
      remark: {
        showText: intl.formatMessage({ id: 'comments' }),
        showType: 'Text',
      },
      creationTime: {
        showText: intl.formatMessage({ id: 'submissionTime' }),
        showType: 'Time',
        addtional: {
          format: 'YYYY/MM/DD HH:mm',
        },
      },
    };
    if (expanded) {
      const { records: listData } = record || {};

      const props = {
        listData,
        listSupport: columns,
      };
      return <MagicTable {...props} />;
    }
  };

  const tableProps = {
    listData: listData.map((item) => {
      const { subject } = item || {};
      const { name } = subject || {};
      return {
        name,
        ...item,
      };
    }),
    listSupport: listSupport(intl, getRemainingTimesStyle),
    loading: loading.tableLoading,
    pagination,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: expandOptions,
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    onTableChange(page) {
      const { current, pageSize } = page;

      expandedRowKeys.length > 0 && setExpandedRowKeys([]);

      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: haveAddPermission ? [
       {
        showText: intl.formatMessage({ id: 'Add Sessions' }),
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                optionNum('edit', record);
              }}
            >
              {intl.formatMessage({ id: 'Add Sessions' })}
            </a>
          );
        },
      },
    ] : null,
  };

  const optionNum = (type, record) => {
    if (type === 'edit') {
      setCurrentItem(record);
    } else {
      currentItem && setCurrentItem(null);
    }

    setVisible(true);
  };

  const modalProps = {
    visible,
    currentItem,
    onCancel: () => {
      setVisible(false);
    },
    reloadTable: initData,
    getRemainingTimesStyle,
  };

  return (
    <div>
      <Row type="flex" justify="space-between">
        <Col>
          <Search
            placeholder={intl.formatMessage({ id: 'subjectName' })}
            onSearch={(value) => {
              setKeyword(value);
            }}
            style={{ width: 200 }}
            allowClear={true}
          />
        </Col>
        <Col>
          {
            haveAddPermission && <Button onClick={() => optionNum('add')} type="primary">
              {intl.formatMessage({ id: 'Add Sessions' })}
            </Button>
          }
        </Col>
      </Row>
      <p />
      <MagicTable {...tableProps} />
      {visible && <AddTrainModal {...modalProps} />}
    </div>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(TrainList);
