import React, { useEffect } from 'react'
import { connect, useIntl } from 'umi'
import { Card, Drawer, Modal, Button, Tabs, Spin } from 'antd'
import AccountSetting from 'components/AccountSetting'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import Question from 'components/Question'
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'

const { TabPane } = Tabs;

const Settings = ({ currentUser, settings, loading, dispatch }) => {
    const intl = useIntl()
    const {
        modalVisible,
        content,
        modalType,
        userInfo,
        trainerDetail,
        drawerVisible,
        questionData,
        tabActiveKey,
        submitData,
        currentAssmessmentIsFinshed,
        logout,
        isAdmin,
        tabListData,
    } = settings

    const title = {
        'editInfo': intl.formatMessage({ id: 'editProfile' }),
        'validPassword': intl.formatMessage({ id: 'changeEmail' }),
        'changeEmail': intl.formatMessage({ id: 'changeEmail' }),
        'changePassword': intl.formatMessage({ id: 'changePassword' }),
        'editResumes': intl.formatMessage({ id: 'editCV' }),
        'editTelphone': intl.formatMessage({ id: 'changPhoneNum' })
    }

    useEffect(() => {
        dispatch({ type: 'settings/query' })
    }, [])

    const { staff, userAllTenant } = currentUser || {}
    const { questionnaireAndAnswer, finishTime } = questionData || {}
    const { questionnaire, answer } = questionnaireAndAnswer || {}
    const { answerContent: AnswerContent } = answer || {}
    const { jsonString, correctRate } = questionnaire || {}
    const { answerContent } = submitData || {}

    let questionArr = [], correntRateAndResult = null
    if (questionData) {
        correntRateAndResult = getCorrentRateAndResult(jsonString, AnswerContent, correctRate)
        questionArr = correntRateAndResult && correntRateAndResult.questionArr
    }

    const modalProps = {
        visible: modalVisible,
        loading: loading.models.settings,
        content,
        title: title[modalType],
        onSubmitText: modalType === 'validPassword' ? intl.formatMessage({ id: 'verifyPassword' }) : '',
        onOk: (values) => {
            dispatch({ type: 'settings/onSubmit', payload: { values, intl } })
        },
        onCancel() {
            dispatch({ type: 'settings/onCancel', payload: { intl } })
        }
    }

    const accountSettingProps = {
        trainerDetail,
        userInfo: userInfo || staff || {},
        userAllTenant,
        isAdmin,
        canEditResumes: true,
        isShowFeedback: true,
        isShowDownload: true,
        editAccountInfo() {
            dispatch({ type: 'settings/edit', payload: { modalType: 'editInfo', intl } })
        },
        editEmail() {
            dispatch({ type: 'settings/edit', payload: { modalType: 'validPassword', intl } })
        },
        editPassword() {
            dispatch({ type: 'settings/edit', payload: { modalType: 'changePassword', intl } })
        },
        editResumes() {
            dispatch({ type: 'settings/edit', payload: { modalType: 'editResumes', intl } })
        },
        editTelphone() {
            dispatch({ type: 'settings/edit', payload: { modalType: 'editTelphone', intl } })
        },
        downloadCertificate(record) {
            dispatch({ type: 'settings/downloadCertificate', payload: record })
        },
        trainingFeedback(record) {
            dispatch({ type: 'settings/trainingFeedback', payload: record })
        }
    }

    const onComplete = ({ answerContent, isComplete, finishedPage }) => {
        if (isComplete) {
            Modal.confirm({
                title: <div style={{ color: 'red' }}>{intl.formatMessage({ id: 'submitDes' })}</div>,
                okText: intl.formatMessage({ id: 'submit' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk: () => {
                    dispatch({ type: 'settings/submit', payload: { answerContent, isComplete, finishedPage, intl } })
                }
            })
        } else {
            dispatch({ type: 'settings/submit', payload: { answerContent, isComplete, finishedPage, intl } })
        }
    }

    const onValueChange = ({ answerContent, isComplete, finishedPage, needSubmit }) => {
        dispatch({ type: 'settings/updateState', payload: { submitData: { answerContent, isComplete, finishedPage } } })
    }

    const questionProps = {
        content: jsonString || '',
        answerContent: answerContent || '',
        answer,
        onComplete: onComplete,
        onValueChange: onValueChange
    }

    const onClose = () => {
        if (!currentAssmessmentIsFinshed && submitData) {
            onComplete(submitData)
        }
        dispatch({ type: 'settings/updateState', payload: { drawerVisible: false } })
    }

    const showSurveyProps = {
        contents: questionArr,
        choice: AnswerContent ? JSON.parse(JSON.stringify(AnswerContent)) : {},
        showIcon: false,
        showAll: false,
    }

    // 测试环境才会有注销功能
    const extra = () => {
        if (process.env.showLogoutBtn) {
            return <Button onClick={() => dispatch({ type: 'settings/updateState', payload: { logout: true } })}>{intl.formatMessage({ id: 'logoutUser' })}</Button>
        } else {
            return <span></span>
        }
    }

    const handleCancel = () => {
        dispatch({ type: 'settings/updateState', payload: { logout: false } })
    }

    const handleOk = () => {
        dispatch({ type: 'settings/userLogout' })
    }

    return (
        <Card title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'profileSetting' })}</b>} extra={!isAdmin ? extra() : <></>} bordered={false}>
            <AccountSetting {...accountSettingProps} />
            <MagicFormModal {...modalProps} />
            <Drawer
                title={intl.formatMessage({ id: 'trainfeedback' })}
                width={700}
                open={drawerVisible}
                onClose={onClose}
                bodyStyle={{ padding: '20px' }}
            >
                {
                    tabListData &&
                    <Tabs
                        //defaultActiveKey={tabListData[0].id}
                        activeKey={tabActiveKey}
                        type="card"
                        onChange={(id) => {
                            dispatch({ type: 'settings/changeTab', payload: { id } })
                        }}>
                        {tabListData.map(item => {
                            return (<TabPane tab={item.displayName} key={item.id}>
                                <Spin spinning={loading.effects['settings/changeTab'] === undefined ? loading.effects['settings/trainingFeedback'] : loading.effects['settings/changeTab']}>
                                    {
                                        finishTime ?
                                            <ShowSurvey {...showSurveyProps} />
                                            :
                                            <Question {...questionProps} />
                                    }
                                </Spin>
                            </TabPane>)
                        })}
                    </Tabs>
                }
            </Drawer>
            <Modal
                title="用户注销"
                open={logout}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>注销后将不能登录系统</p>
            </Modal>
        </Card>
    )
}

export default connect(({ user, settings, loading }) => ({ currentUser: user.currentUser, settings, loading }))(Settings)