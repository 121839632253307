import React, { useEffect } from 'react'
import { connect, useIntl, history } from 'umi'
import { Spin, Form, Input, Button } from 'antd'
import { SHOWTYPE } from './models/updateEmail'
import styles from './index.less'
import ActivateSuccess from './ActivateSuccess'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const Activate = ({
  updateEmail,
  loading,
  dispatch,
  location
}) => {
  const intl = useIntl();
  useEffect(() => {
    dispatch({ type: 'updateEmail/check', payload: location.query })
  }, [])

  const { showType } = updateEmail

  const onSubmit = values => {
    dispatch({ type: 'updateEmail/activateUser', payload: values })
  }

  const showTypeProps = {
    onComplete() {
      history.replace('/')
    },
    showType,
  }

  const showPage = type => {
    switch (type) {
      case SHOWTYPE.Idle:
        return (
          <div style={{ paddingTop: 100, textAlign: 'center' }}>
            <Spin />
          </div>
        )
      case SHOWTYPE.NewUser:
        return (
          <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <h1 style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'setPassword' })}</h1>
                <Form
                  className={styles.form}
                  onFinish={onSubmit}
                  {...layout}
                >
                  <Form.Item
                    label={'旧密码'}
                    name='oldPassword'
                    rules={[{ required: true, message: `旧密码 ${intl.formatMessage({ id: 'isRequired' })}` }]}
                  >
                    <Input.Password placeholder={'oldPassword'} />
                  </Form.Item>
                  <Form.Item
                    label={'新密码'}
                    name='password'
                    rules={[{ required: true, message: `新密码 ${intl.formatMessage({ id: 'isRequired' })}` }]}
                  >
                    <Input.Password placeholder={'password'} />
                  </Form.Item>
                  <Form.Item
                    label={'再次输入新密码'}
                    name='confirmPassword'
                    rules={[{ required: true, message: `再次输入新密码 ${intl.formatMessage({ id: 'isRequired' })}` }]}
                  >
                    <Input.Password placeholder={'confirmPassword'} />
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'center' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={onSubmit}
                      className={styles.btn}
                    >
                      确认并激活
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        )
      default:
        return <ActivateSuccess {...showTypeProps} />
    }
  }

  return <div>{showPage(showType)}</div>
}

export default connect(({ updateEmail }) => ({ updateEmail }))(Activate)
