import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderContent from './components/HeaderContent';
import CharacteristicChart from 'components/NewVratChartComponents/CharacteristicChart';
import BottomPageNum from './components/BottomPageNum';
import { useIntl } from 'umi';

const MotorAttention = ({ data }) => {
  const intl = useIntl();
  const hyperactivityIndex = data ? Math.round(data.Hyperactivity * 100) : 0;
  const areaIndex = data ? Math.round((data.Area * 100).toFixed(1)) : 0;

  const CharacteristicHyperactivityChartProps = {
    data: hyperactivityIndex,
    title: intl.formatMessage({ id: 'MotorAttention.activityIndex' }),
  };

  const CharacteristicAreaChartProps = {
    data: areaIndex,
    title: intl.formatMessage({ id: 'MotorAttention.motionArea' }),
  };

  const headerContentProps = {
    color: '#3DBD7D',
    title: intl.formatMessage({ id: 'MotorAttention.title' }),
    content: (
      <div className={styles.motorAttentionContent}>
        <div>{intl.formatMessage({ id: 'MotorAttention.measures' })}</div>
        <div className={styles.motorAttentionBottom}>
          <div>
            {intl.formatMessage(
              { id: 'MotorAttention.activity' },
              { hyperactivityIndex },
            )}
          </div>
          <div className={styles.motorRange}>
            {intl.formatMessage({ id: 'MotorAttention.motion' }, { areaIndex })}
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.motorAttention}>
        <HeaderContent {...headerContentProps} />
        <div className={styles.centerContent}>
          <CharacteristicChart {...CharacteristicHyperactivityChartProps} />
          <div className={styles.bottomDes}>
            <div>{intl.formatMessage({ id: 'MotorAttention.represents' })}</div>
            <div className={styles.source}>
              {intl.formatMessage(
                { id: 'MotorAttention.hyperactivityIndex' },
                { hyperactivityIndex },
              )}
            </div>
            {/* <div>
              This means that you spent an average amount of time fidgeting
              during the assessment.
            </div> */}
          </div>
          <CharacteristicChart {...CharacteristicAreaChartProps} />
          <div className={styles.bottomDes}>
            <div>{intl.formatMessage({ id: 'MotorAttention.movements' })}</div>
            <div className={styles.source}>
              {intl.formatMessage(
                { id: 'MotorAttention.areaIndex' },
                { areaIndex },
              )}
            </div>
            {/* <div>
              This means that your movement area was large during the
              assessment.
            </div> */}
          </div>
        </div>
        <BottomPageNum num={14} />
        <BottomDes color="#3DBD7D" />
      </div>
    </div>
  );
};

export default MotorAttention;
