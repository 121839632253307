import React, { useEffect } from 'react';
import { connect, history, useIntl } from 'umi';
import { Card, Drawer, Spin, Tabs, Dropdown, Menu, Button, Modal, Input } from 'antd';
import { PlusOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import Question from 'components/Question';
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils';
import { getScore } from 'utils/utils';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey';
import moment from 'moment';
import styles from './index.less';

const { TabPane } = Tabs;
const { Search } = Input;

const listSupport = (intl) => {
  return {
    name: {
      showText: intl.formatMessage({ id: 'name' }),
      showType: 'Text',
    },
    email: {
      showText: intl.formatMessage({ id: 'email' }),
      showType: 'Text',
    },
    supervisorName: {
      showText: intl.formatMessage({ id: 'Supervisor' }),
      showType: 'Text',
    },
    phone: {
      showText: intl.formatMessage({ id: 'phone' }),
      showType: 'Text',
    },
  };
};

const SupervisorList = ({ currentUser, dispatch, loading, supervisorList }) => {
  const intl = useIntl();

  const { hostRoles = [] } = currentUser || {};

  const isSupervisor = hostRoles.find(item => item.name === "supervisor");

  useEffect(() => {
    dispatch({ type: 'supervisorList/fetch' });
  }, []);

  const {
    pagination,
    supervisorData,
    supervisionTabActiveKey,
    questionnaireListData,
    eidtSupervisionVisible,
    supervisionQuestionDetail,
    currentTrainerSummaryItem,
    currentSupervisorItem,
    supervisorQuestion,
    supervisorSubmitData,
    showSupervisionVisible,
    supervisorIsFinshed,
    content,
    sendInviterEmailVisible,
  } = supervisorList;

  const { items } = questionnaireListData || {};
  const { items: subItems } = items || {};

  const currentId =
    supervisionTabActiveKey === 'TrainerSummary'
      ? currentTrainerSummaryItem?.id
      : currentSupervisorItem?.id;
  const currentQuestion = subItems
    ? subItems.find((item) => item.id === currentId)
    : null;

  // 填写问卷
  const { questionnaireAndAnswer: supervisorQuestionnaireAndAnswer } =
    supervisorQuestion || {};
  const { questionnaire: supervisorQuestionnaire, answer: supervisorAnswer } =
    supervisorQuestionnaireAndAnswer || {};
  const { jsonString: supervisorJsonString } = supervisorQuestionnaire || {};
  const { answerContent: supervisorAnswerContent } = supervisorSubmitData || {};

  // 展示问卷
  const {
    questionnaireAndAnswer: supervisionDetaiQuestionnaireAndAnswerl,
    fillerUser,
  } = supervisionQuestionDetail || {};
  const {
    answer: supervisionDetaiAnswer,
    questionnaire: supervisionDetaiQuestionnaire,
  } = supervisionDetaiQuestionnaireAndAnswerl || {};
  const {
    jsonString: supervisionDetaiJsonString,
    correctRate: supervisionDetaiCorrectRate,
  } = supervisionDetaiQuestionnaire || {};
  const { answerContent: supervisionDetaiAnswerContent } =
    supervisionDetaiAnswer || {};
  const { name } = fillerUser || {};
  let questionArr = [],
    correntRateAndResult = null;
  if (supervisionQuestionDetail) {
    correntRateAndResult = getCorrentRateAndResult(
      supervisionDetaiJsonString,
      supervisionDetaiAnswerContent,
      supervisionDetaiCorrectRate,
    );
    questionArr = correntRateAndResult && correntRateAndResult.questionArr;
  } else {
    questionArr = [];
  }

  const onChangSupervisionTabs = (value) => {
    dispatch({ type: 'supervisorList/onChangSupervisionTabs', payload: value });
  };

  // 见习督导跟踪表
  const supervisorOnComplete = ({
    answerContent,
    isComplete,
    finishedPage,
  }) => {
    if (isComplete) {
      Modal.confirm({
        title: (
          <div style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'submitDes' })}
          </div>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          dispatch({
            type: 'supervisorList/updateState',
            payload: { supervisorIsFinshed: true },
          });
          dispatch({
            type: 'supervisorList/supervisorSubmit',
            payload: { answerContent, isComplete, finishedPage, intl },
          });
        },
      });
      return;
    } else {
      dispatch({
        type: 'supervisorList/supervisorSubmit',
        payload: { answerContent, isComplete, finishedPage, intl },
      });
    }
  };

  const supervisorValueChange = ({
    answerContent,
    isComplete,
    finishedPage,
    needSubmit,
  }) => {
    dispatch({
      type: 'supervisorList/updateState',
      payload: {
        supervisorSubmitData: { answerContent, isComplete, finishedPage },
      },
    });
    if (needSubmit) {
      supervisorOnComplete({ answerContent, isComplete, finishedPage });
    }
  };

  const supervisorOnClose = () => {
    if (!supervisorIsFinshed && supervisorSubmitData) {
      supervisorOnComplete(supervisorSubmitData);
    }
    dispatch({ type: 'supervisorList/onCloseEidtSupervision' });
  };

  const supervisorQuestionProps = {
    content: supervisorJsonString ? supervisorJsonString : '',
    answerContent: supervisorAnswerContent || '',
    answer: supervisorAnswer,
    onComplete: supervisorOnComplete,
    onValueChange: supervisorValueChange,
  };

  const tableProps = {
    loading: loading,
    listSupport: listSupport(intl),
    pagination,
    listData: supervisorData
      ? supervisorData.items?.map((item) => {
          const { countryCode, phoneNumber } = item || {};
          return {
            ...item,
            phone: `${countryCode}${phoneNumber}`,
          };
        })
      : [],
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination };
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi };
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt };
      dispatch({ type: 'supervisorList/changeTable', payload: data });
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'detail' }),
        itemRender(record) {
          return (
            <a
              key={record.key}
              onClick={() => {
                history.push({
                  pathname: '/system/manage/trainingStaffDetail',
                  query: {
                    id: record.id,
                  },
                });
              }}
            >
              {intl.formatMessage({ id: 'detail' })}
            </a>
          );
        },
      },
      // {
      //   showText: intl.formatMessage({ id: 'supervisionReport' }),
      //   itemRender(record) {
      //     return (
      //       <a
      //         key={record.key}
      //         onClick={() => {
      //           dispatch({
      //             type: 'supervisorList/showSupervision',
      //             payload: { record },
      //           });
      //         }}
      //       >
      //         {intl.formatMessage({ id: 'supervisionReport' })}
      //       </a>
      //     );
      //   },
      // },
    ],
  };

  const showSurveyProps = {
    contents: questionArr,
    choice: supervisionDetaiAnswerContent
      ? JSON.parse(JSON.stringify(supervisionDetaiAnswerContent))
      : {},
    showIcon: false,
  };

  const menuList =
    subItems && subItems.length > 0 ? (
      <Menu>
        {subItems.map((item) => {
          return (
            <Menu.Item
              key={item.id}
              onClick={() =>
                dispatch({
                  type: 'supervisorList/clickMenuItem',
                  payload: item,
                })
              }
            >
              {moment(item.finishTime).format('YYYY/MM/DD HH:mm')}
            </Menu.Item>
          );
        })}
      </Menu>
    ) : null;

  const getExtraAction = (
    <Button
      type="primary"
      onClick={() => dispatch({ type: 'supervisorList/onSendInviterEmail' })}
    >
      {intl.formatMessage({ id: 'InviteEmployee' })}
    </Button>
  );

  const modalProps = {
    loading,
    content,
    title: intl.formatMessage({ id: 'email' }),
    visible: sendInviterEmailVisible,
    onCancel() {
      dispatch({
        type: 'supervisorList/updateState',
        payload: { sendInviterEmailVisible: false },
      });
    },
    onOk(value) {
      dispatch({ type: 'supervisorList/submitSendEmail', payload: value });
    },
  };

  const searchProps = {
    allowClear: true,
    placeholder: `${intl.formatMessage({ id: 'search' })}${intl.formatMessage({id: 'name' })}`,
    style: { width: 300 },
    onSearch(value) {
      dispatch({ type: 'supervisorList/updateState', payload: {
        filter: value,
        pagination: {
          current: 1,
          pageSize: 12,
          total: 0,
        }
      } })
      dispatch({ type: 'supervisorList/fetch', payload: value })
    },
  }

  return (
    <Card
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({ id: 'trainSupervison' })}
        </b>
      }
      bordered={false}
      extra={isSupervisor && getExtraAction}
    >
      <Search {...searchProps} />
      <p />

      <MagicTable {...tableProps} />
      <MagicFormModal {...modalProps} />

      {/* 展示见习督导记录 */}
      <Drawer
        title={
          <b style={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'supervisionReport' })}
          </b>
        }
        width="700"
        open={showSupervisionVisible}
        onClose={() =>
          dispatch({
            type: 'supervisorList/updateState',
            payload: { showSupervisionVisible: false },
          })
        }
      >
        <Spin spinning={loading}>
          <Tabs
            activeKey={supervisionTabActiveKey}
            onChange={onChangSupervisionTabs}
          >
            <TabPane
              tab={intl.formatMessage({ id: 'supervisorRecords' })}
              key="Supervisor"
            ></TabPane>
            <TabPane
              tab={intl.formatMessage({ id: 'coachRecords' })}
              key="TrainerSummary"
            ></TabPane>
          </Tabs>
          <div className={styles.supervisionEditBtn}>
            <div className={styles.supervisionEditBtnLeft}>
              {supervisionTabActiveKey === 'Supervisor' &&
                subItems &&
                subItems.length > 0 && (
                  <span>
                    <span>
                      {intl.formatMessage({ id: 'score' })}
                      {getScore(correntRateAndResult, supervisionDetaiAnswer)}
                    </span>
                    <span style={{ marginLeft: '20px' }}>{name}</span>
                  </span>
                )}
            </div>
            <div className={styles.supervisionEditBtnRight}>
              {supervisionTabActiveKey === 'Supervisor' &&
                currentSupervisorItem !== null && (
                  <div style={{ marginRight: '20px' }}>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() =>
                        dispatch({ type: 'supervisorList/editSupervision' })
                      }
                    >
                      {intl.formatMessage({ id: 'edit' })}
                    </Button>
                  </div>
                )}
              {supervisionTabActiveKey === 'Supervisor' && (
                <div style={{ marginRight: '20px' }}>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() =>
                      dispatch({ type: 'supervisorList/addSupervision' })
                    }
                  >
                    {intl.formatMessage({ id: 'supervisorRecords' })}
                  </Button>
                </div>
              )}
              {subItems && subItems.length > 0 && (
                <div style={{ marginRight: '20px' }}>
                  <Dropdown overlay={menuList}>
                    <a>
                      {currentQuestion
                        ? moment(currentQuestion.finishTime)
                            .local()
                            .format('YYYY/MM/DD HH:mm')
                        : ''}
                    </a>
                  </Dropdown>
                  <DownOutlined style={{ marginLeft: '5px' }} />
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: '25px' }}>
            <ShowSurvey {...showSurveyProps} />
          </div>
        </Spin>
      </Drawer>

      {/* 填写见习督导跟踪表 */}
      {supervisorQuestion && !loading && (
        <Drawer
          title={
            <b style={{ fontSize: '20px' }}>
              {intl.formatMessage({ id: 'supervisorRecords' })}
            </b>
          }
          width="700"
          open={eidtSupervisionVisible}
          onClose={supervisorOnClose}
        >
          <Question {...supervisorQuestionProps} />
        </Drawer>
      )}
    </Card>
  );
};

export default connect(({ user, supervisorList, loading }) => ({
  currentUser: user.currentUser,
  supervisorList,
  loading: loading.models['supervisorList'],
}))(SupervisorList);
