import React from 'react';
import { Spin } from 'antd';
import { useIntl } from 'umi';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import RecommendationsLine from './components/RecommendationsLine';
import BottomPageNum from './components/BottomPageNum';
import { CloseCircleFilled } from '@ant-design/icons';

const showList = (intl) => [
  {
    title: intl.formatMessage({ id: 'Interpretation.ReactionTime' }),
    content: intl.formatMessage({ id: 'Interpretation.ReactionTime.Des' }),
  },
  {
    title: intl.formatMessage({ id: 'Interpretation.Continuous' }),
    content: intl.formatMessage({ id: 'Interpretation.Continuous.Des' }),
  },
];

const InterpretationThree = ({ tenantInfo, qrCode, qrLoading, qrCodeStatus }) => {
  const intl = useIntl();

  const { vcatqrCode } = tenantInfo || {};

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.interpretationThree}>
        <HeaderLine color="#EE4FA4" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'Interpretation' })}</div>

          {showList(intl).map((item) => {
            const { content, title } = item || {};
            return (
              <div key={title} style={{ marginBottom: '150px' }}>
                <RecommendationsLine
                  title={title}
                  content={content}
                  height="68px"
                />
              </div>
            );
          })}

          {
            vcatqrCode && (
              <div className={styles.threeBottomContent}>
                <div className={styles.bottomTitle}>
                  {intl.formatMessage({ id: 'Interpretation.ScanQrDes' })}
                </div>
                <Spin spinning={qrLoading}>
                  <div className={styles.interpretationQrContent}>
                    {qrCode && <img src={qrCode} />}
                    {qrCodeStatus === 'failed' && (
                      <div className={styles.interpretationQrFailedText}>
                        <CloseCircleFilled style={{ fontSize: '35px', color: '#D12323' }} />
                        <div className={styles.failedTitle}>{intl.formatMessage({ id: 'Interpretation.ScanQr.Failed' })}</div>
                        <div className={styles.failedDes}>
                          {intl.formatMessage({ id: 'Interpretation.ScanQr.Failed.Des' })}
                        </div>
                      </div>
                    )}
                  </div>
                </Spin>
              </div>
            )
          }
        </div>

        <BottomPageNum num={20} />
        <BottomDes color="#EE4FA4" />
      </div>
    </div>
  );
};

export default InterpretationThree;
