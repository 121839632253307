import React, { Fragment, useEffect, useState } from 'react'
import { connect, history, useIntl } from 'umi'
import { Button, Spin, Row, Col, Card, Modal } from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'
import CenterInfo from './CenterInfo'
import ResourseMenu from './ResourseMenu'
import GrantList from './GrantList'
import GrantSelectModal from './GrantSelectModal'
import LocationModal from './LocationModal'
import LocationList from './LocationList'
import HfsContract from './HfsContract'
import TrainingCourses from './TrainingCourses'
import TheraLeap from './TheraLeap'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import SelectModal from 'components/SelectModal'
import ExpandedTable  from './ExpandedTable'
import { eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import { genderLocale } from 'utils/utils'
import moment from 'moment'

const dateFormat = 'YYYY/MM/DD'
const commonMenuList = ['Database', 'Course', 'Assessment', 'TrainingPlanLibrary', 'OfflineTrainingPlan', 'VRAT_Assessment']
// const agentMenuList = ['TheraLeap', 'VRAT_Assessment', 'VRAT_Amount']

const CenterDetail = ({ centerDetail, loading, dispatch, location, currentUser }) => {
  const { adminRoles: [{name} = {}] = [], auth, } = currentUser
  const { grantedPolicies } = auth || {}

  const {
    centerInfo,
    vratCenterInfo,
    currentTab,
    oneToMoreTableData,
    showList,
    modalVisible,
    currentLocation,
    newLocationVisible,
    newVRATAmountModalVisible,
    VRATAmountModalContent,
    VRATAmountTablepagination,
    VRATAmountList,
    VRATAmount,
    schoolModalVisible,
    schoolListPagination,
    schoolList,
    grantTenantSchool,
    hfsContractLocked,
    canSendCode,
    hfsModalEditOrAddType,
    contractTableList,
    hfsModalType,
    hfsModalVisible,
    hfsModalContent,
    editRemarkContent,
    hfsReminderModalContent,
    reminderValueModalContent,
    contractPagination,
    hfsContractTime,
    expandedRowKeys,
    selectHfsModalVisible,
    selectHfsItem,
    statisticsHfsData,
    hfsCodeTime,
    visible,
    remiderVisible,
    remarkVisible,
    trainingCoursesTabs,
    trainingCoursesList,
    trainingCoursesCurrentTab,
    trainingCoursesInfo,
    trainingCoursesEditModalVisible,
    trainingCoursesModalContent,
    trainingCoursesModalType,
    trainingCoursesDrawerVisible,
    theraLeapListData,
    templateModalVisible,
    vratModalType,
    addNewEmployeeModalVisible,
    confirmModalVisible,
    contentMenu,
    roleName,
    filters,
    filter,
    roleNameOptions,
    list,
    pagination
  } = centerDetail

  const intl = useIntl()

  const [expandedRowKey, setExpandedRowKeys] = useState([]);

  const hasUserWritePermission = grantedPolicies['Business.TenantUserManagement.Update'] === true

  useEffect(() => {
    dispatch({ type: 'centerDetail/loadPage', payload: location.query })

    return () => {
      dispatch({ type: 'centerDetail/updateState', payload: {
        currentTab: 'TrainingMaterials',
        centerInfo: null,
      }})
    }
  }, [])

  if (centerInfo === null) return <Spin />
  const centerInfoProps = {
    centerInfo,
  }

  const listSupport = {
    name: {
      showText: intl.formatMessage({ id: 'name' }),
      showType: 'Text',
      render(text, record, index) {
        const { modelKeys, name } = record || {}
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{name}</span>
            {/* {
              modelKeys.map(item => {
                const qualificationItem = qualificationList.find(qualificationItem => qualificationItem.name === item)
                return (
                  <img src={qualificationItem && qualificationItem.issuedStyle && qualificationItem.issuedStyle.url} style={{ width: '70px', marginLeft: '10px' }} />
                )
              })
            } */}
          </div>
        )
      }
    },
    gender: {
      showText: intl.formatMessage({ id: 'gender' }),
      showType: 'Text',
      render(text, record, index) {
        return genderLocale(text, intl)
      }
    },
    userRoles: {
      showText: intl.formatMessage({ id: 'role' }),
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: roleNameOptions.map(item => {
          const { name, displayName } = item || {};
          return {
            text: displayName,
            value: name
          }
        }),
      },
    },
    email: {
      showText: intl.formatMessage({ id: 'email' }),
      showType: 'Text',
    },
    remark: {
      showText: intl.formatMessage({ id: 'comments' }),
      showType: 'Remark',
    },
    isActive: {
      showText: intl.formatMessage({ id: 'isActive' }),
      showType: 'Bool',
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
    }
  }
  const showActive = (isActive, isSend) => {
    if (isActive && isSend) return null
    if (isSend) return intl.formatMessage({ id: 'resend' })
    else {
      return intl.formatMessage({ id: 'sendActivationEmail' })
    }
  }
  const EmployeeTableProps = {
    loading: loading.models.centerDetail,
    listData: list.map(item => {
      const { roles, state } = item
      const roleNames = roles.map(roleItem => roleItem.displayName)
      return {
        ...item,
        userRoles: roleNames.join(' '),
        status: state === 'Effective' ? intl.formatMessage({ id: 'effective' }) : intl.formatMessage({ id: 'invalid' })
      }
    }),
    listSupport,
    pagination,
    filters,
    onTableChange(pagi, filt = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'centerDetail/changeTable', payload: data })
    },
    Actions: [
      hasUserWritePermission ? {
        showText: intl.formatMessage({ id: 'sendInvitationEmail' }),
        itemRender(record) {
          const { isSend, isActive } = record
          const showWord = showActive(isActive, isSend)
          return showWord == null ? null : (
            <span key="sendEmail">
              <a
                onClick={() => {
                  dispatch({ type: 'centerDetail/onSendEmail', payload: { id: record.id, intl } })
                }}
              >
                {showWord}
              </a>
            </span>
          )
        },
      } : null,
      hasUserWritePermission ? {
        showText: intl.formatMessage({ id: 'delete' }),
        itemRender(record) {
          const { isActive, id } = record
          return isActive ? null : (
            <span key="delete">
              <a
                onClick={() => {
                  dispatch({ type: 'centerDetail/onDelete', payload: { userId: id, intl, ...location.query } })
                }}
              >
                {intl.formatMessage({ id: 'delete' })}
              </a>
            </span>
          )
        },
      } : null,
    ],
  }

  const VRATAmountTableListSupport = {
    CreationTime: {
      showText:  intl.formatMessage({ id: 'creatTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm:ss'
      }
    },
    TestAmount: {
      showText:  intl.formatMessage({ id: 'Test quantity' }),
      showType: 'text'
    },
    LastAmount: {
      showText:  intl.formatMessage({ id: 'availableTest' }),
      showType: 'text'
    },
    UsedAmount: {
      showText:  intl.formatMessage({ id: 'consumptionTest' }),
      showType: 'text'
    },
    Validity: {
      showText:  intl.formatMessage({ id: 'Valid Until/Start' }),
      showType: 'text'
    },
    BillingMethod: {
      showText:  intl.formatMessage({ id: 'Billing method' }),
      showType: 'text'
    },
    MonthlyIncrTestAmount:{
      showText:  intl.formatMessage({ id: 'MonthAccount' }),
      showType: 'text',
      render: (_, record, index) => {
        return (
          <span>
            {record.MonthlyIncrTestAmount ? record.MonthlyIncrTestAmount : '-'}
          </span>
        );
      },
    },
    Status: {
      showText:  intl.formatMessage({ id: 'available' }),
      showType: 'Bool'
    },
    Remark: {
      showText:  intl.formatMessage({ id: 'comments' }),
      showType: 'text'
    }
  }

  const schoolListSupport = {
    name: {
      showText:  intl.formatMessage({ id: 'School Name' }),
      showType: 'text'
    },
  }

  const menuProps = {
    currentUser,
    currentTab,
    centerInfo,
    onChangeTab(e) {
      dispatch({ type: 'centerDetail/changeTab', payload: e.key })
    },
  }

  const locationListProps = {
    locationList: vratCenterInfo.TestLocations,
    onEdit(id) {
      dispatch({ type: 'centerDetail/onEditLocation', payload: id })
    },
  }

  const locationModalProps = {
    loading: loading.effects['centerDetail/addLocation'],
    currentLocation,
    visible: newLocationVisible,
    onCancel() {
      dispatch({ type: 'centerDetail/updateState', payload: { newLocationVisible: false } })
    },
    onOk(data) {
      dispatch({ type: 'centerDetail/addLocation', payload: data })
    },
  }

  const grantListProps = {
    currentTab,
    showList,
    vratCenterInfo,
    isLoading: loading.models.centerDetail
  }

  const grantTenantSchoolAllList = grantTenantSchool.map(item => {
    return {
      ...item.school,
      ...item,
      title: item.school.name
    }
  })

  const selectSchoolModal = {
    title:  intl.formatMessage({ id: 'Choose school' }),
    selectModalVisible: schoolModalVisible,
    targetKeys: grantTenantSchoolAllList.filter(item => item.isGrant).map(item => item.id),
    allList: grantTenantSchoolAllList,
    loading: loading.effects['centerDetail/onSelectSchoolOk'],
    onOk: (data) => {
      dispatch({ type: 'centerDetail/onSelectSchoolOk', payload: data })
    },
    onCancel: () => {
      dispatch({ type: 'centerDetail/updateState', payload: { schoolModalVisible: false } })
    }
  }

  const schoolTableProps = {
    loading: loading.effects['centerDetail/getSchoolList'],
    listData: schoolList,
    listSupport: schoolListSupport,
    pagination: schoolListPagination,
    scroll: true,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { schoolListPagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'centerDetail/changeSchoolTable', payload: data })
    },
  }

  const getSelectModalGrantedListKeys = () => {
    switch (currentTab) {
      case 'Course':
        return showList.filter(item => item.isGranted && item.resource && item.resource.type === currentTab).map(item => item.resource.id)
      case 'Database':
        return showList.filter(item => item.isGranted && item.resource && item.resource.type === 'TrainingMaterials').map(item => item.resource.id)
      case 'VRAT_Assessment':
        return vratCenterInfo.AllowQuestionnaires.map(item => item.QuestionnaireId)
      case 'TrainingPlanLibrary':
        return showList.filter(item => item.isGrant).map(item => item.resource.id)
      case 'TrainingCourses':
        return trainingCoursesTabs.map(item => item.trainingModuleId)
      default:
        return showList.filter(item => item.isGranted).map(item => item.resource.id)
    }
  }

  const isLawful = (status, isVip) => {
    if (status === 'Public') {
      if (isVip) {
        return true
      }
      return false
    }
    if (status === 'Hidden') {
      return true
    }
    return false
  }

  const getSelectModalAllList = () => {
    switch (currentTab) {
      case 'Course':
        return showList.filter(item => item.resource && item.resource.type === currentTab && isLawful(item.resource.status, item.resource.isVip))
      case 'Database':
        return showList.filter(item => item.resource && item.resource.type === 'TrainingMaterials')
      case 'VRAT_Assessment':
        return vratCenterInfo.AllQuestionnaires
      case 'TrainingCourses':
        return trainingCoursesList
      default:
        return showList
    }
  }

  const selectModalProps = {
    currentTab,
    defaultSelectKeys: getSelectModalGrantedListKeys(),
    allList: getSelectModalAllList(),
    selectModalVisible: modalVisible,
    onOk(keys) {
      dispatch({ type: 'centerDetail/editGrantList', payload: keys })
    },
    onCancel() {
      dispatch({ type: 'centerDetail/updateState', payload: { modalVisible: false } })
    },
  }

  const hfsContractProps = {
    visible,
    hfsContractLocked,
    canSendCode,
    hfsModalEditOrAddType,
    contractTableList,
    hfsModalType,
    hfsModalVisible,
    vratCenterInfo,
    oneToMoreTableData,
    hfsModalContent,
    hfsReminderModalContent,
    loading: loading.models.centerDetail,
    contractPagination,
    hfsContractTime,
    expandedRowKeys,
    selectHfsModalVisible,
    selectHfsItem,
    statisticsHfsData,
    hfsCodeTime,
    onSendCode(values) {
      dispatch({ type: 'centerDetail/sendCode', payload: values })
    },
    getCode() {
      dispatch({ type: 'centerDetail/getCode' })
    },
    onHfsCancel() {
      dispatch({ type: 'centerDetail/cancelHfs' })
    },
    onHfsReminderCancel() {
      dispatch({
        type: 'centerDetail/updateState',
        payload: {
          visible: false,
        }
      })
    },
    onHfsReminderOk(values) {
      dispatch({ type: 'centerDetail/submitHfsReminder', payload: { values } })
    },
    onHfsOk(values, type, option) {
      let title = ''
      switch (type) {
        case "contract":
          // const { amount: contractAmount } = values
          title = option === 'add' ?  intl.formatMessage({ id: 'new contract' }) : intl.formatMessage({ id: 'modify contract' });
          // title = option === 'add' ? `确定为${centerInfo && centerInfo.displayName}充值￥${contractAmount}吗？` : "确定修改合同信息吗？";
          break
        case "recharge":
        case "rechargeExpired":
          const { money: rechargeMoney, recharge: rechargeStyle } = values
          title = `确定为${centerInfo && centerInfo.displayName}${rechargeStyle === "recharge" ? '充值' : '扣款'}$${rechargeMoney}吗？如已在有效期内，则提交后立即到账`
          break
      }
      if (title) {
        Modal.confirm({
          title,
          okText:  intl.formatMessage({ id: 'sureConfirm' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          onOk() {
            dispatch({ type: 'centerDetail/submitHfs', payload: { values, type } })
          }
        });
        return
      }
      dispatch({ type: 'centerDetail/submitHfs', payload: { values, type } })
    },
    editHfs(record, type) {
      dispatch({ type: 'centerDetail/editHfs', payload: { record, type } })
    },
    editHfsReminder() {
      dispatch({ type: 'centerDetail/editHfsReminder' })
    },
    deleteHfs(record, type) {
      dispatch({ type: 'centerDetail/deleteHfs', payload: { record, type } })
    },
    newHfs(record, type) {
      dispatch({ type: 'centerDetail/newHfs', payload: { record, type, intl } })
    },
    stopHfs(record, type) {
      dispatch({ type: 'centerDetail/stopHfs', payload: { record, type } })
    },
    onExpandedRowsChange(data) {
      dispatch({ type: 'centerDetail/updateState', payload: { expandedRowKeys: data } })
    },
    onchangeHfsPagination(value) {
      dispatch({ type: 'centerDetail/onchangeHfsPagination', payload: value })
    },
    onExpand(expanded, record) {
      dispatch({ type: 'centerDetail/onHfsTableExpand', payload: { expanded, record } })
    },
    reloadData(type, record) {
      dispatch({ type: 'centerDetail/reloadData', payload: { record, type } })
    },
    startHfs(record, type) {
      dispatch({ type: 'centerDetail/startHfs', payload: { record, type } })
    }
  }

  const trainingCoursesProps = {
    tabs: trainingCoursesTabs,
    visible: trainingCoursesEditModalVisible,
    loading: loading.models.centerDetail,
    info: trainingCoursesInfo,
    currentTab: trainingCoursesCurrentTab,
    content: trainingCoursesModalContent,
    modalType: trainingCoursesModalType,
    drawerVisible: trainingCoursesDrawerVisible,
    onAddSelectModule: () => dispatch({ type: 'centerDetail/updateState', payload: { modalVisible: true } }),
    changeTabs(value) {
      dispatch({ type: 'centerDetail/changeTrainingCoursesCurrentTab', payload: value })
    },
    changeOpenState(value) {
      dispatch({ type: 'centerDetail/trainingCoursesChangeOpenState', payload: value })
    },
    changePriceState: async (value) => {
      await dispatch({ type: 'centerDetail/updateState', payload: { trainingCoursesModalType: 'changePriceStatus' } })
      dispatch({ type: 'centerDetail/onTrainingCoursesOk', payload: value })
    },
    editModal({ type }) {
      dispatch({ type: 'centerDetail/editTrainingCoursesModal', payload: type })
    },
    onModalCancel() {
      dispatch({ type: 'centerDetail/updateState', payload: { trainingCoursesEditModalVisible: false } })
    },
    onModalOk(values) {
      Modal.confirm({
        title: <span style={{ color: 'red' }}> {intl.formatMessage({ id: 'submitDes' })}</span>,
        okText: intl.formatMessage({ id: 'sureConfirm' }),
        cancelText:  intl.formatMessage({ id: 'cancel' }),
        onOk() {
          eventDebounce(() => { dispatch({ type: 'centerDetail/onTrainingCoursesOk', payload: values }) }, 300)
        }
      })
    },
    showRecord() {
      dispatch({ type: 'centerDetail/showTrainingCoursesDrawerRecord' })
    },
    closeDrawer() {
      dispatch({ type: 'centerDetail/updateState', payload: { trainingCoursesDrawerVisible: false } })
    }
  }

  const onAddLocation = () => {
    dispatch({
      type: 'centerDetail/updateState',
      payload: { newLocationVisible: true, currentLocation: null },
    })
  }

  const VRATAmountTableProps = {
    loading: loading.effects['centerDetail/getVRATAmountList'],
    listData: VRATAmountList.map(item => {
      const { StartTime, EndTime, BillingMethod, LastAmount, TestAmount } = item;
      return {
        ...item,
        Validity: BillingMethod ==='Monthly' ? `${moment(StartTime).local().format(dateFormat)}`: `${moment(StartTime).local().format(dateFormat)} - ${moment(EndTime).local().format(dateFormat)}`,
        BillingMethod: {
          "Number": intl.formatMessage({ id: 'Add by quantity' }),
          "Time": intl.formatMessage({ id: 'unlimited quantity' }),
          "Monthly": intl.formatMessage({ id: 'Monthly' })
        }[BillingMethod],
        originBillingMethod: BillingMethod,
        LastAmount: LastAmount < 0 ? intl.formatMessage({ id: 'unlimited' }) : LastAmount,
        TestAmount: TestAmount < 0 ? intl.formatMessage({ id: 'unlimited' }) : TestAmount
      }
    }),
    listSupport: VRATAmountTableListSupport,
    pagination: VRATAmountTablepagination,
    scroll: true,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { VRATAmountTablepagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'centerDetail/changeVRATAmountTable', payload: data })
    },

    expandable() {
       return {
        expandedRowKey,
        rowExpandable(record) {
          const { originBillingMethod } = record || {};
          return originBillingMethod === 'Monthly';
        },
        expandedRowRender: (record, index, indent, expanded) => {
          return <ExpandedTable  allData={record} expanded={expanded}/>
        },
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      }
    },
    Actions: [
      {
        showText: '编辑备注',
        itemRender(record) {
          return <a onClick={(e) => {
            e.stopPropagation()
            dispatch({
              type: 'centerDetail/vartRemarkEdit',
              payload: { record, intl },
            })
          }}> {intl.formatMessage({ id: 'Edit Notes' })}</a>
        },
      },
      {
        showText: '禁用',
        itemRender(record) {
          const { Status } = record
          return Status && <a onClick={() => {
            dispatch({
              type: 'centerDetail/forbidVratAmount',
              payload: { record },
            })
          }}>{intl.formatMessage({ id: 'Disable' })}</a>
        },
      }
    ]
  }

  const theraLeapProps = {
    centerInfo,
    theraLeapListData,
    loading: loading.effects['centerDetail/getTheraLeapList'],
    templateModalVisible,
    editTemplate: (type) => {
      dispatch({
        type: 'centerDetail/updateState',
        payload: { templateModalVisible: type === 'add' ? true : false },
      })
    },
    deleteTemplete: (record) => {
      dispatch({
        type: 'centerDetail/deleteTemplete',
        payload: { record },
      })
    },
    reloadTable: () => {
      dispatch({
        type: 'centerDetail/getTheraLeapList',
      })
    }
  }

  // 点击添加新员工
  const addNewEmployee = () => {
    dispatch({ type: 'centerDetail/addNewEmployee', payload: { type: 'add', intl } });
  };

  const showTabContent = (currentTab) => {
    switch (currentTab) {
      case 'VRAT_Amount':
        const { LastAmount, UsedAmount, LatestExpirationTime, VratReminderAmount = 0 } = VRATAmount
        return (
          <div>
            <Button
              onClick={() => {
                dispatch({
                  type: 'centerDetail/newVRATAmount', payload: { intl }
                })
              }}
            >
              {intl.formatMessage({ id: 'New test volume' })}
            </Button>
            <div style={{ margin: '10px' }}>
              {/* {`可用测试量：${LastAmount < 0 ? '不限' : LastAmount} | 消耗测试量：${UsedAmount} | 当前有效期至：${moment(LatestExpirationTime).format('YYYY-MM-DD')} | 提醒值：${VratReminderAmount}`} &nbsp; */}
              {intl.formatMessage({ id: 'availableTest' })}:{LastAmount < 0 ?  intl.formatMessage({ id: 'unlimited' }): LastAmount} | {intl.formatMessage({ id: 'consumptionTest' })}: {UsedAmount} | {intl.formatMessage({ id: 'Current validity period' })} {moment(LatestExpirationTime).format('YYYY-MM-DD')} | {intl.formatMessage({ id: 'Reminder value' })}{VratReminderAmount}&nbsp;
              <span style={{ cursor: 'pointer', color: '#40a9ff' }} onClick={() => { dispatch({ type: 'centerDetail/editRemiderValue', payload: { intl } }) }}>{intl.formatMessage({ id: 'edit' })}</span>
            </div>
            <MagicTable {...VRATAmountTableProps} />
          </div>
        )
      case 'School':
        return (
          <div>
            <Button
              onClick={() => {
                dispatch({
                  type: 'centerDetail/updateState',
                  payload: {
                    schoolModalVisible: true
                  }
                })
              }}
            >
              {intl.formatMessage({ id: 'Edit Authorization' })}
            </Button>
            <MagicTable {...schoolTableProps} />
            <SelectModal {...selectSchoolModal} />
          </div>
        )
      case "HFS_Contract":
        return <HfsContract {...hfsContractProps} />
      case "TrainingCourses":
        return <TrainingCourses {...trainingCoursesProps} />
      case "TheraLeap":
        return <TheraLeap {...theraLeapProps} />
      case "TeamManagement":
        return (
          <div>
            <Button
              onClick={addNewEmployee}
            >
              {intl.formatMessage({ id: 'addNewEmployee' })}
            </Button>
            <MagicFormModal {...addNewEmployeeProps} />
            <MagicTable {...EmployeeTableProps} />
          </div>
        )

      default:
        return <div />
    }
  }

  const modalProps = {
    title: intl.formatMessage({ id: 'New test volume' }),
    loading: loading.effects['centerDetail/onSubmitVRATAmountModal'],
    visible: newVRATAmountModalVisible,
    onCancel() {
      dispatch({ type: 'centerDetail/updateState', payload: { newVRATAmountModalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'centerDetail/onSubmitVRATAmountModal', payload: value })
    },
    content: VRATAmountModalContent,
  }

  const addNewEmployeeProps = {
    loading: loading.models.group,
    title: intl.formatMessage({ id: 'addNewEmployee' }),
    visible: addNewEmployeeModalVisible,
    onCancel() {
      dispatch({
        type: 'centerDetail/updateState',
        payload: { addNewEmployeeModalVisible: false },
      });
    },
    onOk: (value) => {
      const { email = [], roleName: roleNameValue } = value
      const { Value } = roleName.find(({ Id }) => Id === roleNameValue) || {}
      Modal.confirm({
        okText: intl.formatMessage({ id: 'confirm' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        width: 700,
        icon: null,
        onOk() {
          dispatch({
            type: 'centerDetail/onSubmitModal',
            payload: { data: { ...value, ...location.query }, intl },
            callback: (result) => {
              Modal.error({
                title: 'Error',
                width: 700,
                content: <div>
                  {
                    result
                      .filter(({ message }) => message)
                      .map(({ message }) => <div>{message}</div>)
                  }
                </div>
              })
            }
          });

        },
        content: <div>
          <div style={{display: 'flex', justifyContent: 'center',marginBottom: '15px'}}><b>{intl.formatMessage({ id: 'Confirm to' })}</b></div>
          <div style={{textAlign: 'center'}}>
            {email.map(({ label }, index) => <><span>{label};</span>{index % 2 === 1 ? <br /> : <br />}</>)}
          </div>
          <div style={{display: 'flex', justifyContent: 'center',marginTop: '15px'}}>{intl.formatMessage({ id: 'add a total of' }, { length: email.length })}&nbsp;<b>{Value}</b>&nbsp;?</div>
        </div>,
      });
    },
    content: contentMenu,
  }

  const reminderValueProps = {
    visible: remiderVisible,
    title: intl.formatMessage({ id: 'reminder values' }),
    onCancel: () => {
      dispatch({
        type: 'centerDetail/updateState',
        payload: {
          remiderVisible: false,
        }
      })
    },
    onOk: (values) => {
      dispatch({ type: 'centerDetail/submitReminderValue', payload: values })
    },
    content: reminderValueModalContent,
  }

  const remarkProps = {
    visible: remarkVisible,
    title: intl.formatMessage({ id: 'reminder values' }),
    onCancel: () => {
      dispatch({
        type: 'centerDetail/updateState',
        payload: {
          remarkVisible: false,
        }
      })
    },
    onOk: (values) => {
      dispatch({ type: 'centerDetail/submitRemark', payload: values })
    },
    content: editRemarkContent,
  }

  const goBack = () => {
    dispatch({ type: 'centerDetail/clearHfsTimer' })
    history.goBack()
  }

  return (
    <div>
      <Row>
        <Col span={4}>
          <Button icon={<CaretLeftOutlined />} onClick={() => goBack()}>
            {intl.formatMessage({ id: 'back' })}
          </Button>
        </Col>
        <Col span={6}>
          <h1>{intl.formatMessage({ id: 'cms.statistics.centerDetail.title' })}</h1>
        </Col>
      </Row>

      <CenterInfo {...centerInfoProps} />

      <ResourseMenu {...menuProps} />

      {
        commonMenuList.includes(currentTab) ?
          <Fragment>
            <Button
              onClick={() =>
                dispatch({ type: 'centerDetail/updateState', payload: { modalVisible: true } })
              }
            >
              {intl.formatMessage({ id: 'Edit Authorization'})}
            </Button>
            <GrantList {...grantListProps} />
            <Card>
              <Row type="flex" justify="space-between">
                <Col>
                  <h3>{intl.formatMessage({ id: 'locationInformation' })}</h3>
                </Col>
                <Col>
                  <Button onClick={onAddLocation} type="primary">
                    {intl.formatMessage({ id: 'newLocation' })}
                  </Button>
                </Col>
              </Row>
              <br />
              <LocationList {...locationListProps} />
            </Card>
          </Fragment>
          :
          <Fragment>{showTabContent(currentTab)}</Fragment>
      }

      {modalVisible && <GrantSelectModal {...selectModalProps} />}
      <LocationModal {...locationModalProps} />
      <MagicFormModal {...modalProps} />
      <MagicFormModal {...reminderValueProps} />
      <MagicFormModal {...remarkProps} />
    </div>
  )
}

export default connect(({ centerDetail, loading, user }) => ({ centerDetail, loading, currentUser: user.currentUser }))(CenterDetail)
