import React from 'react';
import { MicroAppWithMemoHistory, connect, getLocale } from 'umi';

import { getToken } from 'cognitiveleap-core-us/utils/auth';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es'
};

const CustomMicroApp = ({ url, appProps = {}, subjectDetail }) => {
  const { subjectInfo } = subjectDetail || {};
  const { id } = subjectInfo || {};
  const initUrl =
    url +
    (url.includes('?') ? '&' : '?') +
    `t=${getToken()}&u=${id}&culture=${LangOptions[getLocale()]}`;

  return (
    <MicroAppWithMemoHistory
      name="pdtMobile"
      url={initUrl}
      isWeb={'yes'}
      {...appProps}
    />
  );
};

export default React.memo(
  connect(({ subjectDetail }) => ({
    subjectDetail,
  }))(CustomMicroApp),
);
