import React from 'react';
import NumberAnimate from 'cognitiveleap-core-us/components/Animate/Number';
import styles from './index.less';

const Field = ({ label, value, ...rest }) => (
  <div className={styles.field} {...rest}>
    <span className={styles.label}>{label}</span>
    <span className={styles.number}>
      <NumberAnimate>{value}</NumberAnimate>
    </span>
  </div>
);

export default Field;
