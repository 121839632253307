import { GetUserList, GetSubjectList, PostUserHiddenContent } from 'services/userManager'

export default {
  namespace: 'adminUserlist',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    searchField: null,
    currentTenant: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage(_, { call, put, take, select }) {
      const { pagination, searchField, currentTenant } = yield select(state => state.adminUserlist)
      let query = { skipCount: 0, maxResultCount: 10, filter: searchField }
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        tenantId: currentTenant?.id
      }
      const res = yield call(GetUserList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTenant({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user);
      const { currentTenant } = yield select(state => state.adminUserlist);
      const { canChangeList } = currentUser;
      if (!currentTenant) {
        yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
      } else if (payload) {
        const { id } = payload;
        if (id) {
          const newCurrentTenant = canChangeList.find(item => item.id == id)
          yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
        } else {
          yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
        }
      }
      yield put({ type: 'loadPage' });
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: currentPagination } = yield select(state => state.adminUserlist)
      const { pagination } = data
      yield put({
        type: 'updateState',
        payload: { pagination: { ...currentPagination, ...pagination } },
      })
      yield put({ type: 'loadPage' })
    },

    *filterFetch({ payload }, { call, put, select, take }) {
      const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      yield put({ type: 'updateState', payload: { pagination: defaultPagination, ...payload } })
      yield put({ type: 'loadPage' })
    },

    *clearAllFilterAndSorter(_, { call, put, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      const payload = { pagination: defaultPagination, searchField: null }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'loadPage' })
    },

    *levelup({ payload: id }, { call, put, select, take }) {
      const res = yield call(PostUserHiddenContent,{ids:[id]})
      if(res.response.ok){
        yield put({ type: 'loadPage' })
      }
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/user/user') {
    //       dispatch({ type: 'loadPage' })
    //     }
    //   })
    // },
  },
}
