import React, { useEffect } from 'react'
import { connect } from 'umi'
import MyVideo from "components/MyVideo"

const ShowVideo = ({ showVideo, dispatch, location }) => {

    useEffect(() => {
        dispatch({ type: 'showVideo/updateState', payload: location.query })
    }, [])

    const { id, url, coverUrl } = showVideo

    const videoProps = {
        id,
        url,
        coverUrl,
        width: "100%",
        height: "calc(100vh - 60px)"
    }

    return (
        <div style={{ padding: '30px' }}>
            {
                url && <MyVideo {...videoProps} />
            }
        </div>
    )
}

export default connect(({ showVideo }) => ({ showVideo }))(ShowVideo)