import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Row, Col, Button, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑标签',
  add: '新建标签',
}

const Tag = ({ tag, dispatch }) => {

  useEffect(() => {
    dispatch({ type: 'tag/query' })
  }, [])

  const { modalType, content, modalVisible, list, listSupport, pagination, selectItem } = tag

  const { hasLanguageFallback } = selectItem || {}

  const modalTypeToTitle = (modalType, hasLanguageFallback) => {
    const showLangFallback = hasLanguageFallback ? '（尚未翻译）' : ''
    return modalTitleMenu[modalType] + showLangFallback
  }

  const modalProps = {
    title: modalTypeToTitle(modalType, hasLanguageFallback),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'tag/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'tag/onSubmitModal', payload: value })
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'tag/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'tag/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'tag/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'tag/onAdd' })
  }

  return (
    <div>
      <h1>标签</h1>
      <Button onClick={onAddClick}>新建标签</Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ tag }) => ({ tag }))(Tag)
