export default {
  namespace: 'scenario',
  state: {
    list: [
      {
        createDateTime: '2019-01-09 14:00',
        founder: 'Lily',
        scenarioName: 'Morning(6:00-10:00)',
        startTime: '6:00',
        endTime: '10:00',
      },
    ],
    listSupport: {
      createDateTime: {
        showText: '创建日期',
        showType: 'Text',
      },
      founder: {
        showText: '创建人',
        showType: 'Text',
      },
      scenarioName: {
        showText: '场景名',
        showType: 'Text',
      },
      scenarioIcon: {
        showText: '场景Icon',
        showType: 'Text',
      },
      startTime: {
        showText: '起始时间',
        showType: 'Text',
      },
      endTime: {
        showText: '结束时间',
        showType: 'Text',
      },
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    content: null,
    modalType: 'add',
    modalVisible: false,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *query({ payload: queryData }, { take, put, call, select }) {
      let { currentUser } = yield select(state => state.user)
      if (!currentUser) {
        yield put({ type: 'user/getUserInfo', payload: { from: window.location } })
        yield take('user/getUserInfo/@@end')
      }
    },

    *onAddScenario({ payload: id }, { take, put, call, select }) {
      let addScenario = {
        Properties: [
          {
            EditorType: 'Input',
            ShowTitle: '场景名',
            FormKey: 'scenarioName',
            AdditionalData: null,
            Value: null,
            Setting: {},
            Description: null,
          },
          {
            EditorType: 'Upload',
            Value: null,
            Setting: {
              UploadSetting: {
                uploadType: 'image',
              },
            },
            ShowTitle: '场景Icon',
            FormKey: 'scenarioIcon',
            Description: '只需要一张',
          },
          {
            EditorType: 'Input',
            ShowTitle: '起始时间',
            FormKey: 'startTime',
            AdditionalData: null,
            Value: null,
            Setting: {},
            Description: null,
          },
          {
            EditorType: 'Input',
            ShowTitle: '起始时间',
            FormKey: 'endTime',
            AdditionalData: null,
            Value: null,
            Setting: {},
            Description: null,
          },
        ],
      }
      yield put({
        type: 'updateState',
        payload: { modalType: 'edit', content: addScenario, modalVisible: true },
      })
    },

    *onEditScenario({ payload: id }, { take, put, call, select }) {
      let editScenario = {
        Properties: [
          {
            EditorType: 'Input',
            ShowTitle: '创建日期',
            FormKey: 'createDateTime',
            AdditionalData: null,
            Value: '2019-01-09 14:00',
            Setting: { Disabled: true },
            Description: null,
          },
          {
            EditorType: 'Input',
            ShowTitle: '创建人',
            FormKey: 'founder',
            AdditionalData: null,
            Value: 'Lily',
            Setting: { Disabled: true },
            Description: null,
          },
          {
            EditorType: 'Input',
            ShowTitle: '场景名',
            FormKey: 'scenarioName',
            AdditionalData: null,
            Value: 'Morning(6:00-10:00)',
            Setting: {},
            Description: null,
          },
          {
            EditorType: 'Upload',
            Value: null,
            Setting: {
              UploadSetting: {
                uploadType: 'image',
              },
            },
            ShowTitle: '场景Icon',
            FormKey: 'scenarioIcon',
            Description: '只需要一张',
          },
          {
            EditorType: 'Input',
            ShowTitle: '起始时间',
            FormKey: 'startTime',
            AdditionalData: null,
            Value: '6:00',
            Setting: {},
            Description: null,
          },
          {
            EditorType: 'Input',
            ShowTitle: '起始时间',
            FormKey: 'endTime',
            AdditionalData: null,
            Value: '10:00',
            Setting: {},
            Description: null,
          },
        ],
      }
      yield put({
        type: 'updateState',
        payload: { modalType: 'edit', content: editScenario, modalVisible: true },
      })
    },

    *onSubmitModal(_, { take, put, call, select }) {
      const { modalType } = yield select(state => state.scenario)
      if (modalType === 'edit') {
        console.log('edit')
      } else {
        console.log('add')
      }

      yield put({ type: 'query' })
      yield put({ type: 'updateState', payload: { modalVisible: false } })
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/scenario') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
