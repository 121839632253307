import React, { useState, useEffect } from 'react';
import { Card, Button, Space, message, Input, Transfer, Modal } from 'antd';
import { history } from 'umi';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

import { GetTenantList, PostTenantsInfo } from 'services/tenant';

import { PostAddTenant, GetGrantTenant } from 'services/roles';

const { Search } = Input;

const listSupport = {
  creationTime: {
    showText: 'creationTime',
    showType: 'Time',
  },
  displayName: {
    showText: 'displayName',
    showType: 'Text',
  },
  enterprise: {
    showText: 'enterprise',
    showType: 'Text',
  },
  vratStatus: {
    showText: 'vratStatus',
    showType: 'Bool',
  },
  rocketStatus: {
    showText: 'rocketStatus',
    showType: 'Bool',
  },
  state: {
    showText: 'state',
    showType: 'Text',
  },
  institutionType: {
    showText: 'institutionType',
    showType: 'Text',
  },
  chargingMethod: {
    showText: 'chargingMethod',
    showType: 'Text',
  },
  isActive: {
    showText: 'isActive',
    showType: 'Bool',
  },
  isSend: {
    showText: 'isSend',
    showType: 'Bool',
  }
}

const CenterListDetail = ({ location }) => {
  const { id } = location.query;

  const [listData, setListData] = useState([]);
  const [trainListData, setTrainListData] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState({
    btnLoading: false,
    tableLoading: false,
    modelLoading: false,
  });
  const [searchData, setSearchData] = useState(null);
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    // 请求list数据
    const grantRes = await GetGrantTenant({ id });
    if (grantRes.response.ok) {
      const { grantTenants } = grantRes.data;
      if (grantTenants.length > 0) {
        setTenantList(grantTenants);
        // 有中心数据
        const { pageSize, current } = pagination
        const res = await PostTenantsInfo({
          tenantIds: grantTenants,
          type: 'Center',
          filter: searchData,
          skipCount: (current - 1) * pageSize,
          maxResultCount: pageSize
        });
        if (res.response.ok) {
          const list = res.data.items.map(item => {
            const { projectStatus, parentTenant } = item
            const vratStatus = projectStatus.some(project => {
              const { name, status } = project
              return name === 'Project.Vrat' && status
            })
            const rocketStatus = projectStatus.some(project => {
              const { name, status } = project
              return name === 'Project.Rocket' && status
            })
      
            const enterprise = parentTenant && parentTenant.displayName;
      
            return {
              ...item,
              vratStatus,
              rocketStatus,
              enterprise
            }
          })
          setListData(list);
          setPagination({
            ...pagination,
            total: res.data.totalCount
          })
        }
      }
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    getData();
  }, [searchData, pagination.current, pagination.pageSize]);

  const props = {
    listData: listData,
    listSupport: listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
  };

  const addTenant = async () => {
    setLoading({
      ...loading,
      btnLoading: true,
    })
    const res = await GetTenantList({
      type: 'Center',
      // State: 'Effective',
      MaxResultCount: 999,
    });
    if (res.response.ok) {
      const newData = res.data.items.map((item) => {
        return { ...item, key: item.id };
      });
      const selectData = [];
      if (tenantList.length > 0) {
        newData.forEach((item) => {
          tenantList.forEach((newItem) => {
            if (item.id === newItem) {
              selectData.push(newItem);
            }
          });
        });
        setSelected(selectData);
      }
      setTrainListData(newData);
    }
    setVisible(true);
    setLoading({
      ...loading,
      btnLoading: false,
    })
  };

  return (
    <Card
      title={
        <Space>
          <Button
            onClick={() => {
              history.goBack();
            }}
          >
            返回
          </Button>
          <b style={{ fontSize: '20px' }}>中心列表</b>
        </Space>
      }
      extra={
        <Button type="primary" onClick={addTenant} loading={loading.btnLoading}>
          添加管理中心
        </Button>
      }
      bordered={false}
    >
      <div>
        <Search
          placeholder="请输入中心名"
          allowClear
          onSearch={(value) => {
            setPagination({
              current: 1,
              pageSize: 10,
              total: 0,
            });
            setSearchData(value);
          }}
          style={{ width: 200 }}
        />
      </div>
      <br />
      <MagicTable {...props} />
      <Modal
        width={800}
        open={visible}
        title={'添加管理中心'}
        onOk={async () => {
          const res = await PostAddTenant({
            contentAdminId: id,
            tenantIds: selected,
          });
          if (res.response.ok) {
            // 添加成功了，应该刷新页面
            getData();
          }
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Transfer
          listStyle={{
            width: 360,
            height: 400,
          }}
          dataSource={trainListData}
          showSearch
          targetKeys={selected}
          onChange={(value) => {
            setSelected(value);
          }}
          render={(item) => item.displayName}
        />
      </Modal>
    </Card>
  );
};

export default CenterListDetail;
