import React, { useEffect } from 'react';
import { connect, useIntl } from 'umi'

import ShowDataAnalysis from 'components/ShowDataAnalysis'
import CardHead from 'components/CardHead';
import { Card, Spin } from 'antd'
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart'
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import { getAnalysisSubTitle, getAnalysisData } from 'utils/analysis'
import { vratKeyIndicators, vratLegend } from '../config'
import style from './index.less';

const VARTAnalysis = ({ adminVratAnalysis, loading, dispatch }) => {

    const intl = useIntl()

    const {
        keyIndicators,
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        analysisData,
        datePickerIsOpen,
        currentTenant
    } = adminVratAnalysis;

    useEffect(() => {
        dispatch({ type: 'adminVratAnalysis/changeTenant' })
    }, [])

    if (analysisData == null || keyIndicators == null) {
        return <Spin />
    }

    const { Trend } = analysisData;

    const isLoadingAnalysis = loading.effects['adminVratAnalysis/loadAnalysisData'] || loading.effects['adminVratAnalysis/loadKeyIndicators'];

    vratKeyIndicators(intl).forEach(item => {
        if (item.hasOwnProperty('subTitle')) {
            const objData = keyIndicators[item.field]
            if (objData) {
                item.data = getAnalysisData(item.dataField, objData[item.dataField], intl)
                if (objData[item.dataField]) {
                    item.subTitle = getAnalysisSubTitle(item.subTitleField, objData[item.subTitleField], intl)
                } else {
                    item.subTitle = ''
                }
            } else {
                item.data = 0
                item.subTitle = ''
            }
        } else {
            item.data = keyIndicators[item.field]
        }
    });

    const getVratKeyIndicators = () => {
        return vratKeyIndicators(intl)
          .map((item) => {
            if (item.hasOwnProperty('subTitle')) {
              const objData = keyIndicators[item.field];
              if (objData) {
                item.data = getAnalysisData(
                  item.dataField,
                  objData[item.dataField],
                  intl
                );
                if (objData[item.dataField]) {
                  item.subTitle = getAnalysisSubTitle(
                    item.subTitleField,
                    objData[item.subTitleField],
                    intl
                  );
                } else {
                  item.subTitle = '';
                }
              } else {
                item.data = 0;
                item.subTitle = '';
              }
            } else {
              item.data = keyIndicators[item.field];
            }
            return { ...item };
          })
          .map((item) => {
            if (item.hasOwnProperty('subTitle')) {
              const objData = keyIndicators[item.field];
              if (objData) {
                item.data = getAnalysisData(
                  item.dataField,
                  objData[item.dataField],
                  intl
                );
                if (objData[item.dataField]) {
                  item.subTitle = getAnalysisSubTitle(
                    item.subTitleField,
                    objData[item.subTitleField],
                    intl
                  );
                } else {
                  item.subTitle = '';
                }
              } else {
                item.data = 0;
                item.subTitle = '';
              }
            } else {
              item.data = keyIndicators[item.field];
            }
            return { ...item };
          });
      };

    const chartProps = {
        legendData: vratLegend(intl),
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        dataSource: Trend
    };

    const changeTag = (tag, checked) => {
        dispatch({
            type: 'adminVratAnalysis/changeTag',
            payload: {
                key: tag.key
            }
        })
    }

    const handleOpenChange = () => {
        dispatch({
            type: 'adminVratAnalysis/updateState',
            payload: {
                datePickerIsOpen: !datePickerIsOpen
            }
        })
    }

    const handlePanelChange = (time) => {
        dispatch({
            type: 'adminVratAnalysis/handlePanelChange',
            payload: {
                time
            }
        })
    }

    const analysisChoiceDateProps = {
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        datePickerIsOpen,
        changeTag,
        handleOpenChange,
        handlePanelChange
    }
    return (
        <Card
            title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'VRATAnalysis' })}</b>}
            bordered={false}
            extra={
                <AnalysisTenantSelect
                    showAll={true}
                    currentTenant={currentTenant}
                    onChange={(e) => {
                        dispatch({ type: 'adminVratAnalysis/changeTenant', payload: { id: e } })
                    }}
                />
            }
        >
            <div className={style.vratAnalysis}>

                <div className={style.keyIndicators}>
                    <CardHead title={intl.formatMessage({ id: 'keyIndicators' })} />
                    <ShowDataAnalysis keyIndicators={getVratKeyIndicators()} />
                </div>
                <div className={style.trendAnalysis}>
                    <CardHead title={intl.formatMessage({ id: 'trendAnalysis' })} />
                    <AnalysisChoiceDate {...analysisChoiceDateProps} />
                    <div className={style.trendAnalysisContent}>
                        <Spin
                            spinning={isLoadingAnalysis}
                        >
                            <CommonAnalysisLineChart {...chartProps} />
                        </Spin>
                    </div>
                </div>

            </div>
        </Card>
    )
}

export default connect(({ adminVratAnalysis, loading }) => ({ adminVratAnalysis, loading }))(VARTAnalysis);