import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderContent from './components/HeaderContent';
import BottomPageNum from './components/BottomPageNum';
import RadarChart from 'components/NewVratChartComponents/RadarChart';
import { useIntl } from 'umi';
const AttentionScores = ({ data }) => {
  const intl = useIntl();
  const {
    Attention = 0,
    SelfControl = 0,
    Reactivity = 0,
    ReactionStability = 0,
    Sensitivity = 0,
    ContinuousAttention = 0,
  } = data || {};

  const RadarChartProps = {
    data: {
      Attention,
      SelfControl,
      Reactivity,
      ReactionStability,
      Sensitivity,
      ContinuousAttention,
    },
  };

  const DescProps = [
    {
      leftTitleE: intl.formatMessage({ id: 'AttentionScores.omission' }),
      rightDescE: intl.formatMessage({ id: 'AttentionScores.focused' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'AttentionScores.self' }),
      rightDescE: intl.formatMessage({ id: 'AttentionScores.commission' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'AttentionScores.speed' }),
      rightDescE: intl.formatMessage({ id: 'AttentionScores.reactionScore' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'AttentionScores.stability' }),
      rightDescE: intl.formatMessage({ id: 'AttentionScores.reaction' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'AttentionScores.discriminability' }),
      rightDescE: intl.formatMessage({ id: 'AttentionScores.prime' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'AttentionScores.mental' }),
      rightDescE: intl.formatMessage({ id: 'AttentionScores.score' }),
    },
  ];

  const headerContentProps = {
    color: '#00C0D8',
    title: intl.formatMessage({ id: 'AttentionScores.title' }),
    content: (
      <div className={styles.attentionScoresContent}>
        <span>
          {intl.formatMessage({ id: 'AttentionScores.graphs' })}
          <br />
          {intl.formatMessage({ id: 'AttentionScores.higher' })}
        </span>
        <span className={styles.redZone}>{intl.formatMessage({ id: 'AttentionScores.red' })}</span>
        <span>{intl.formatMessage({ id: 'AttentionScores.means' })}</span>
      </div>
    ),
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.attentionScores}>
        <HeaderContent {...headerContentProps} />
        <div className={styles.centerContent}>
          <RadarChart {...RadarChartProps} />
          {/* <div className={styles.title}>{intl.formatMessage({ id: 'AttentionScores.reference' })}</div> */}
          <div className={styles.desList}>
            {DescProps.map((item) => {
              const { leftTitleE, rightDescE } = item || {};
              return (
                <div className={styles.descItem} key={leftTitleE}>
                  <span className={styles.leftTitle}>{leftTitleE}</span>
                  <span className={styles.rightDes}>{rightDescE}</span>
                </div>
              );
            })}
          </div>
        </div>
        <BottomPageNum num={7} />
        <BottomDes color="#00C0D8" />
      </div>
    </div>
  );
};

export default AttentionScores;
