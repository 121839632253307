import { GetSleepRecord } from 'services/rocketService/TrainingPlan';
import { GetBrainRecord, GetMusicRecord } from 'services/rocketService/UserOfflinePlan'

export default {
  namespace: 'adminClockInRecord',

  state: {
    sleepRecord: null,
    nutritionRecord: null,
    exerciseRecord: null,
    ilstrainerRecord: null,
    ilsparentRecord: null,
    ilsappRecord: null,
    pagination: {
      sleep: {
        current: 0,
        pageSize: 10,
      },
      nutrition: {
        current: 0,
        pageSize: 10,
      },
      exercise: {
        current: 0,
        pageSize: 10,
      },
      ilstrainer: {
        current: 0,
        pageSize: 10,
      },
      ilsparent: {
        current: 0,
        pageSize: 10,
      },
      ilsapp: {
        current: 0,
        pageSize: 10,
      }
    },
    isShowExerciseRecord: false,
    isShowIlsRecord: false,
    currentExerciseId: '',
    currentIlsId: '',
    currentonlinePlanId: '',
    ilsActiveKey: 'chart',
    shouldGetData: false,
    audioType: '',
    brainRecord: null,
    musicRecord: null
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {

    *getRecord({ payload: { id, type, current } }, { all, call, put, take, select }) {
      const { subjectId } = yield select(state => state.adminSubjectDetail);

      const { pagination, sleepRecord, nutritionRecord, exerciseRecord, ilstrainerRecord, ilsparentRecord, ilsappRecord } = yield select(state => state.adminClockInRecord);

      const planId = (type == 'ilstrainer' || type == 'ilsparent') ? 'UserOfflineTrainingPlanId' : 'UserTrainingPlanId';
      const parmasData = {
        SubjectId: subjectId,
        [planId]: id,
        SkipCount: (current - 1) * (pagination[type].pageSize),
        MaxResultCount: pagination[type].pageSize
      };

      (type == 'ilstrainer' || type == 'ilsparent') && (parmasData.ForGuardian = (type == 'ilsparent'));

      const res = yield call(GetSleepRecord, parmasData);

      if (res.response.ok) {
        const totalCount = res.data.totalCount;
        const newPagination = Object.assign({}, pagination, { [type]: { current, pageSize: pagination[type].pageSize } });
        switch (type) {
          case 'sleep':
            yield put({
              type: 'updateState', payload: {
                sleepRecord: sleepRecord && totalCount ? { items: sleepRecord.items.concat(res.data.items), totalCount } : res.data,
                isShowExerciseRecord: false,
                pagination: newPagination
              }
            });
            break
          case 'nutrition':
            yield put({
              type: 'updateState', payload: {
                nutritionRecord: nutritionRecord && totalCount ? { items: nutritionRecord.items.concat(res.data.items), totalCount } : res.data,
                isShowExerciseRecord: false,
                pagination: newPagination
              }
            });
            break
          case 'exercise':
            yield put({
              type: 'updateState', payload: {
                exerciseRecord: exerciseRecord && totalCount ? { items: exerciseRecord.items.concat(res.data.items), totalCount } : res.data,
                pagination: newPagination,
                isShowExerciseRecord: true,
                currentExerciseId: id
              }
            });
            break;
          case 'ilstrainer':
            yield put({
              type: 'updateState', payload: {
                ilstrainerRecord: ilstrainerRecord && totalCount ? { items: ilstrainerRecord.items.concat(res.data.items), totalCount } : res.data,
                pagination: newPagination,
                isShowExerciseRecord: false,
              }
            });
            break;
          case 'ilsparent':
            yield put({
              type: 'updateState', payload: {
                ilsparentRecord: ilsparentRecord && totalCount ? { items: ilsparentRecord.items.concat(res.data.items), totalCount } : res.data,
                pagination: newPagination,
                isShowExerciseRecord: false,
              }
            });
            break;
          case 'ilsapp':
            yield put({
              type: 'updateState', payload: {
                ilsappRecord: ilsappRecord && totalCount ? { items: ilsappRecord.items.concat(res.data.items), totalCount } : res.data,
                pagination: newPagination,
                isShowExerciseRecord: false,
              }
            });
            break;
        }
      }
    },

    *resetGetRecord({ payload: { id, type } }, { all, call, put, take, select }) {
      const { pagination } = yield select(state => state.adminClockInRecord);
      const payloadData = {
        exerciseRecord: null,
        pagination: Object.assign({}, pagination, { exercise: { current: 1, pageSize: 10 } }),
        isShowExerciseRecord: true
      };

      yield put({ type: 'updateState', payload: payloadData })
      yield put({ type: 'getRecord', payload: { id, type, current: 1 } })
    },

    *getBrainRecord({ payload: { id } }, { call, put, select }) {
      const res = yield call(GetBrainRecord, { UserOfflineTrainingPlanId: id });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            brainRecord: res.data,
          },
        })
      }
    },

    *showChartRecord({ payload }, { call, put, select }) {
      const { pagination } = yield select(state => state.adminClockInRecord)
      yield put({
        type: 'updateState',
        payload: {
          isShowIlsRecord: true,
          currentTablePlan: payload,
          ilsActiveKey: 'chart',
          shouldGetData: true,
          ilsparentRecord: null,
          ilstrainerRecord: null,
          ilsappRecord: null,
          brainRecord: null,
          musicRecord: null,
          pagination: Object.assign(
            {},
            pagination,
            {
              ilstrainer: {
                current: 0,
                pageSize: 10,
              },
              ilsparent: {
                current: 0,
                pageSize: 10,
              },
              ilsapp: {
                current: 0,
                pageSize: 10,
              }
            }
          )
        }
      })
    },

    *getMusicRecord({ payload }, { call, put, select }) {
      const { id } = payload
      const res = yield call(GetMusicRecord, id)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            musicRecord: res.data
          }
        })
      }
    }
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
      return () => { }
    },
  },
}
