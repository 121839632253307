import { matchDynamicForm, addOptionsToProperties, deepCopy } from 'cognitiveleap-core-us/utils/utils'
import {
    PostPrepareTrainingModule,
    PostTrainingModule,
    GetTrainingModule,
    PutTrainingModule,
    DeleteTrainingModule,
    PutSortOrderMove,
    PostRelatingClass,
    GetTrainingModuleQrCode,
    PutTrainingModuleInUse
} from 'services/trainingSystem'
import { GetExamList } from 'services/exam'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
const { baseURL, uploadTrainingMedias } = config

// /api/rocketAdmin/File/TrainingMedias
import { message, Modal } from 'antd'
import { getDvaApp } from 'umi'
import { downloadQr } from 'utils/utils'
import { DownloadOutlined } from '@ant-design/icons';
import styles from '../index.less'

let contentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: 'name',
            FormKey: 'name',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
            },
            Description: '',
        },
        {
            EditorType: 'Input',
            ShowTitle: 'displayName',
            FormKey: 'displayName',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
            },
            Description: '要是没写这里的话，那可能别人就不知道这个是啥',
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [],
                Required: false,
            },
            ShowTitle: '关联勋章',
            FormKey: 'qualificationMedalIds',
            Description: null,
        },
        {
            EditorType: 'Bool',
            Value: null,
            Setting: {
                Required: false,
            },
            ShowTitle: '实操培训',
            FormKey: 'hasTrainingAppointment',
            Description: '若选择无。该培训模块无第二步实操培训流程',
        },
        {
            EditorType: 'ContentSelector',
            Value: null,
            Setting: {
                SelectorOptions: [],
                Required: false,
            },
            ShowTitle: '培训反馈问卷',
            FormKey: 'questionnaires',
            Description: null,
        },
        {
            EditorType: 'SingleWithSearch',
            ShowTitle: '笔试问卷',
            FormKey: 'examinationId',
            AdditionalData: null,
            Value: null,
            Setting: {
                DropdownOptions:[],
                Required: false,
            },
            Description: '若不绑定笔试，则该培训模块无笔试流程',
        },
        {
            EditorType: 'SingleWithSearch',
            Value: null,
            Setting: {
                DropdownOptions: [],
                Required: false,
            },
            ShowTitle: '口试问卷',
            FormKey: 'oralQuestionnaireId',
            Description: '若不绑定口试问卷，则该培训模块无实操考核流程',
        },
        {
            EditorType: 'SingleWithSearch',
            Value: null,
            Setting: {
                DropdownOptions: [],
                Required: false,
            },
            ShowTitle: '实操问卷',
            FormKey: 'practicalQuestionnaireId',
            Description: '若不绑定实操问卷，则该培训模块无实操考核流程',
        },
        {
            EditorType: 'NumberInput',
            ShowTitle: '默认督导次数',
            FormKey: 'supervisionFrequency',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: false,
                NumberSetting: {
                    min: 0,
                    max: 8,
                    precision: 0,
                },
            },
            Description: '若填0，则该培训模块无督导流程',
        },
        {
            EditorType: 'Upload',
            Value: null,
            Setting: {
                UploadSetting: {
                    uploadType: 'image',
                    isSingle: true,
                },
                Action: baseURL + uploadTrainingMedias,
                Header: getAuthHeader(),
            },
            ShowTitle: 'cover',
            FormKey: 'cover',
        },
        {
            EditorType: 'Input',
            ShowTitle: '学习提醒',
            FormKey: 'learningReminder',
            AdditionalData: null,
            Value: null,
            Setting: {},
            Description: '若有内容填写，显示在Learning流程。',
        },
        // {
        //     EditorType: 'NumberInput',
        //     ShowTitle: '自助缴费单价',
        //     FormKey: 'price',
        //     AdditionalData: null,
        //     Value: null,
        //     Setting: {
        //         NumberSetting: {
        //             min: 0,
        //             step: 1,
        //             precision: 2,
        //         },
        //     },
        //     Description: null,
        // },
    ]
}

const examContentMenu = {
    Properties: [
        {
            EditorType: 'SingleWithSearch',
            ShowTitle: '关联考试',
            FormKey: 'examination',
            AdditionalData: null,
            Value: null,
            Setting: {
                DropdownOptions: [],
                Required: true,
            },
            Description: '',
        }
    ]
}

const listToOptions = (list, showAttributeArr) => {
    return list.map(item => {
        const Value = showAttributeArr.map(attribute => `${item[attribute]} `)
        return { Value, Id: item.id.toString() }
    })
}

export default {
    namespace: 'trainingModule',
    state: {
        list: [],
        modalVisible: false,
        examVisible: false,
        modalType: 'edit',
        content: null,
        selectItem: null,
        sortOrderModal: false,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query(_, { take, put, call, select }) {
            const { pagination } = yield select(state => state.trainingModule)
            const { current, pageSize } = pagination
            const res = yield call(GetTrainingModule, { SkipCount: (current - 1) * pageSize, MaxResultCount: pageSize })
            if (res.response.ok) {
                const { totalCount, items } = res.data
                yield put({
                    type: 'updateState',
                    payload: {
                        pagination: {
                            ...pagination,
                            total: totalCount
                        },
                        list: items
                    }
                })
            }
        },

        *changeTable({ payload: data }, { call, put, select }) {
            const { pagination } = data
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'query' })
        },

        *onRelation({ payload }, { call, put, select }) {
            const res = yield call(GetExamList, { pageSize: 500 })
            let { selectItem } = payload
            yield put({
                type: 'updateState',
                payload: { selectItem }
            })
            const { examination } = selectItem || {}
            let emptyContent = examContentMenu
            if (res.response.ok) {
                // 有问题的写法
                // emptyContent.Properties[0].Setting.DropdownOptions = res.data.items.map(item => {
                //     return {
                //         Value: item.name,
                //         Id: item.id
                //     }
                // })

                // 没问题的写法
                const examOptions = listToOptions(res.data.items, ['name'])
                emptyContent = addOptionsToProperties(
                    emptyContent,
                    ['examination'],
                    examOptions
                )
                if (examination) {
                    // 已经有关联考试数据
                    selectItem = {
                        ...selectItem,
                        examination: examination.id,
                    }
                    emptyContent = matchDynamicForm(emptyContent, { ...selectItem })
                }
            }
            yield put({
                type: 'updateState',
                payload: { modalType: 'add', content: emptyContent, examVisible: true },
            })
        },

        *weixinExam({ payload }, { take, put, call, select }) {
            const { selectItem } = payload
            yield put({
                type: 'updateState',
                payload: { selectItem }
            })
            const { id, examination: { name } } = selectItem
            const res = yield call(GetTrainingModuleQrCode, { TrainingModuleId: id })
            if (res.response.ok) {
                Modal.info({
                    icon: null,
                    width: 350,
                    content: <div className={styles.qrCode}>
                        <div className={styles.qrCodeName}>{name}</div>
                        <div className={styles.qrCodeDes}>扫一扫参加考试</div>
                        <img className={styles.qrCodeImg} src={`data:image/png;base64,${res.data}`} />
                        <div
                            className={styles.downloadCenterQr}
                            onClick={() => {
                                downloadQr({
                                    type: 'base64',
                                    data: res.data,
                                    title: name,
                                    subTitle: '扫一扫参加考试',
                                    downloadName: name + '考试二维码',
                                    imgType: 'examCode'
                                })
                            }}
                        >
                            保存图片<DownloadOutlined />
                        </div>
                    </div>,
                    onOk() {
                        console.log('OK');
                    }
                });
            } else {
                const { error } = res.data || { error: "获取二维码失败" }
                message.error(error && error.message)
            }
        },

        *onAdd(_, { take, put, call, select }) {
            const resList = yield [call(PostPrepareTrainingModule, { id: '' }), call(GetExamList, { pageSize: 500 })]
            let content = deepCopy(contentMenu)
            if (resList.every(item => item.response.ok)) {
                const { qualificationMedals, availableQuestionnaires } = resList[0].data || {}
                const { items = [] } = resList[1].data || {}

                const examOptions = listToOptions(items, ['name'])
                const availableData = listToOptions(availableQuestionnaires, ['displayName'])

                content.Properties[2].Setting.DropdownOptions = listToOptions(qualificationMedals, ['name'])
                content.Properties[4].Setting.SelectorOptions = availableData
                content.Properties[5].Setting.DropdownOptions = examOptions
                content.Properties[6].Setting.DropdownOptions = availableData
                content.Properties[7].Setting.DropdownOptions = availableData
            }
            yield put({
                type: 'updateState',
                payload: { modalType: 'add', content, modalVisible: true },
            })
        },

        *onSubmitModal({ payload: data }, { take, put, call, select }) {
            const { questionnaires } = data || {}
            let res = null
            let params = {}
            const { modalType, selectItem } = yield select(state => state.trainingModule)
            if (modalType === 'add') {
                params = {
                    ...data,
                    qualificationMedalIds: data.qualificationMedalIds ? [data.qualificationMedalIds] : [],
                    questionnaires: Array.isArray(questionnaires) ? data.questionnaires : (data.questionnaires ? [data.questionnaires] : null)
                }
                res = yield call(PostTrainingModule, params)
            } else {
                params = {
                    ...selectItem,
                    ...data,
                    qualificationMedalIds: data.qualificationMedalIds ? [data.qualificationMedalIds] : [],
                    questionnaires: Array.isArray(questionnaires) ? data.questionnaires : (data.questionnaires ? [data.questionnaires] : null)
                }
                res = yield call(PutTrainingModule, params)
            }
            if (res && res.response.ok) {
                message.success('提交成功')
                yield put({ type: 'query' })
                yield put({ type: 'updateState', payload: { modalVisible: false } })
            }
        },

        *onExamSubmitModal({ payload: data }, { call, put, select }) {
            const { selectItem } = yield select(state => state.trainingModule)
            const { id } = selectItem || {}
            const params = {
                id,
                examinationId: data
            }
            const res = yield call(PostRelatingClass, params)
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { examVisible: false } })
                yield put({ type: 'query' })
            } else {
                message.error("关联失败")
            }
        },

        *onEdit({ payload }, { call, put, select }) {
            const resList = yield [call(PostPrepareTrainingModule, { id: payload.id  }), call(GetExamList, { pageSize: 500 })]

            if (resList.every(item => item.response.ok)) {
                const { qualificationMedals, availableQuestionnaires } = resList[0].data
                const { items = [] } = resList[1].data || {}

                payload.qualificationMedalIds = payload.qualificationMedals && payload.qualificationMedals.length > 0 ? payload.qualificationMedals[0].id : ''
                let content = deepCopy(contentMenu)

                const examOptions = listToOptions(items, ['name'])
                const availableData = listToOptions(availableQuestionnaires, ['displayName'])

                content.Properties[2].Setting.DropdownOptions = listToOptions(qualificationMedals, ['name'])
                content.Properties[4].Setting.SelectorOptions = availableData
                content.Properties[5].Setting.DropdownOptions = examOptions
                content.Properties[6].Setting.DropdownOptions = availableData
                content.Properties[7].Setting.DropdownOptions = availableData

                const editUserInfoForm = matchDynamicForm(content, payload)
                yield put({
                    type: 'updateState',
                    payload: {
                        content: editUserInfoForm,
                        modalType: 'edit',
                        modalVisible: true,
                        selectItem: payload
                    },
                })
            }

        },

        *changeSortOrder({ payload }, { call, put, select }) {
            const { selectItem } = yield select(state => state.trainingModule)
            const params = {
                id: selectItem && selectItem.id,
                newSortOrder: payload
            }
            const res = yield call(PutSortOrderMove, params)
            if (res.response.ok) {
                yield put({ type: 'query' })
                yield put({ type: 'updateState', payload: { sortOrderModal: false } })
            }
        },

        *onDelete({ payload }, { call, put, select }) {
            const res = yield call(DeleteTrainingModule, { id: payload.id })
            if (res.response.ok) {
                message.success('删除成功')
                yield put({ type: 'query' })
            }
        },

        *onChangeStatus({ payload }, { call, put, select }) {
            const { id, inUse } = payload || {};
            const res = yield call(PutTrainingModuleInUse, { id, inUse: !inUse })
            if (res.response.ok) {
                message.success(inUse ? '停用成功' : '启用成功')
                yield put({ type: 'query' })
            }
        }
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/content/course/trainingModule') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
