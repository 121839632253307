import { GetApplyList } from "services/applyTraining"
import { GetTrainingApplication } from 'services/trainingSystem'

export default {
    namespace: 'applyReview',

    state: {
        currentTenant: null,
        Filter: '',
        AuditResult: '',
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
        // 列表数据
        listData: null,
        drawerVisible: false,
        // 培训申请表数据
        questionData: null,
    },

    effects: {
        *query({ payload }, { take, put, call, select }) {
            const { currentUser } = yield select(state => state.user);
            const { currentTenant } = yield select(state => state.applyReview);
            const { canChangeList } = currentUser;
            if (!currentTenant) {
                yield put({ type: 'updateState', payload: { currentTenant: { id: "" } } })
            } else if (payload) {
                const { id } = payload;
                if (id) {
                    const newCurrentTenant = canChangeList.find(item => item.id == id)
                    yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
                } else {
                    yield put({ type: 'updateState', payload: { currentTenant: canChangeList[0] } })
                }
            }
            yield put({ type: 'getApplyList' })
        },

        *getApplyList({ payload }, { take, put, call, select }) {
            const { currentTenant, Filter, AuditResult, pagination } = yield select(state => state.applyReview)
            const { id } = currentTenant || {}
            const { current, pageSize } = pagination || {}
            const params = {
                TenantId: id,
                AuditResult,
                Filter,
                SkipCount: (current - 1) * pageSize,
                MaxResultCount: pageSize
            }
            const getListRes = yield call(GetApplyList, params)
            if (getListRes.response.ok) {
                const { pagedResult } = getListRes.data || {}
                const { items, totalCount } = pagedResult || {}
                const newPagination = { ...pagination, total: totalCount }
                yield put({
                    type: 'updateState',
                    payload: {
                        listData: getListRes.data,
                        pagination: newPagination
                    }
                })
            }
        },

        *onClear({ payload }, { take, put, call, select }){
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0
            }
            yield put({
                type: 'updateState',
                payload: {
                    pagination,
                    Filter: '',
                    AuditResult: '',
                }
            })
            yield put({
                type: 'getApplyList'
            })
        },

        *changeTable({ payload }, { call, put, select }) {
            const { pagination } = yield select(state => state.applyReview)
            const { pagination: newPagination } = payload || {}
            yield put({
                type: 'updateState',
                payload: {
                    pagination: { ...pagination, ...newPagination }
                }
            })
            yield put({
                type: 'getApplyList'
            })
        },

        *onSearch({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0
            }
            yield put({
                type: 'updateState',
                payload: {
                    pagination
                }
            })
            yield put({
                type: 'getApplyList'
            })
        },

        *getQuestion({ payload }, { call, put, select }) {
            const { trainer, tenantId } = payload || {}
            const { id } = trainer || {}
            const getQuestionRes = yield call(GetTrainingApplication, { TrainerId: id, TenantId: tenantId })
            if (getQuestionRes.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: {
                        questionData: getQuestionRes.data,
                        drawerVisible: true
                    }
                })
            }
        },
    },

    reducers: {

        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === "/system/manage/manage/applyReview") {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
