import React from 'react'
import { connect, useIntl } from 'umi'
import { Spin, List, Avatar, Button } from 'antd'
import CenterClockInRecord  from 'components/ClockInRecord';
import moment from 'moment';
import styles from '../detail.less'

const ExerciseInfo = ({ subjectExercise, loading, centerClockInRecord, dispatch, subjectDetail }) => {

  const { planInfo, pagination: exercisePagination } = subjectExercise;
  const { subjectId, subjectInfo: { isBind, isLogout } } = subjectDetail;
  const intl = useIntl()
  if (planInfo == null) return <Spin className={styles.spin} />

  const isExerciseLoding = loading.effects['subjectExercise/loadList'];
  const isRecordLoading = loading.effects['centerClockInRecord/getRecord'];
  const { exerciseRecord, isShowExerciseRecord, pagination: recordPagination, currentExerciseId } = centerClockInRecord;
  const { items, totalCount } = planInfo

  const getRecord = (id) => {
    const currentId = id || currentExerciseId;
    dispatch({
      type: currentId != currentExerciseId ? 'centerClockInRecord/resetGetRecord' : 'centerClockInRecord/getRecord',
      payload: {
        id: currentId,
        type: 'exercise',
        current: ++recordPagination.exercise.current
      }
    })
  }

  const addMore = () => {
    dispatch({
      type: 'subjectExercise/loadList',
      payload: {
        current: ++exercisePagination.current,
        type: 'load'
      }
    })
  }

  const lookExercisePlan = () => {
    dispatch({
      type : 'centerClockInRecord/updateState',
      payload : {
        isShowExerciseRecord: false
      }
    })
  }

  const propsData = {
    dataSource: exerciseRecord,
    loadData:getRecord,
    isLoading: isRecordLoading
  }

  return (
    <div className={styles.tabContent}>
      {
        !isLogout && isBind ? 
          <div>
            {
              items.length === 0 ? 
                intl.formatMessage({ id: 'noOpenTraining' }) 
                : 
                <Spin spinning={isExerciseLoding}>
                  {
                    isShowExerciseRecord ? 
                      <div>
                        <Button
                          onClick={()=>{lookExercisePlan()}}
                          style={{marginBottom: '20px'}}
                        >
                          { intl.formatMessage({ id: 'back' }) }
                        </Button>
                        <CenterClockInRecord { ...propsData } />
                      </div>
                      :
                      <div>
                        <List
                          itemLayout="horizontal"
                          dataSource={items}
                          renderItem={item => (
                            <List.Item
                              actions={[item.start && <a onClick={()=>{getRecord(item.id)}}>{intl.formatMessage({ id: 'viewRecord' })}</a>]}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar 
                                    shape="square" 
                                    size={50} 
                                    src={item && item.image && item.image.url} 
                                  />
                                }
                                title={<b>{item.displayName}</b>}
                                description={ item.start ? `${moment(item.start).format('YYYY/MM/DD')} ~ ${moment(item.end).format('YYYY/MM/DD')}` : intl.formatMessage({ id: 'notOpen' })}
                              />
                            </List.Item>
                          )}
                        />
                        <div style={{textAlign: 'center'}}>
                                {
                                  totalCount > items.length ?
                                    <Button onClick={ () => { addMore() } }>{intl.formatMessage({ id: 'loadMore' })}</Button>
                                    :
                                    <p>{intl.formatMessage({ id: 'bottomLine' })}</p>
                                }
                        </div>
                      </div>
                  }
              </Spin>
            }
          </div>
          :
          <span>{intl.formatMessage({ id: 'childCannotViewed' }, { status: intl.formatMessage({ id: isLogout ? 'cancelled' : 'noLongerAuthorized' })})}</span>
      }
      
    </div>
  )
}

export default connect(({ 
  subjectExercise, 
  loading,
  centerClockInRecord,
  subjectDetail
}) => ({ 
  subjectExercise, 
  loading,
  centerClockInRecord,
  subjectDetail
}))(ExerciseInfo)
