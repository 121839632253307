import React from 'react';
import { Button } from 'antd-mobile';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './index.less';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 12, color: 'black' }} spin />
);

const ChangeEcharts = ({
  week,
  recentWeek,
  onChangeWeek,
  loading = { rightLoading: false, leftLoading: false },
  children,
}) => {
  return (
    <div className={styles.changePanel}>
      <Button
        disabled={week <= 1 || week === undefined}
        onClick={() => onChangeWeek('left')}
        loading={loading.leftLoading}
        loadingicon={<Spin indicator={antIcon} />}
        style={{
          marginRight: '-110px',
          width: '28px',
          height: '44px',
          borderRadius: '38px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '18px',
          color: '#546173',
          zIndex: '100',
        }}
      >
        {'<'}
      </Button>
      {children}
      <Button
        disabled={recentWeek == week || recentWeek == undefined}
        loading={loading.rightLoading}
        loadingicon={<Spin indicator={antIcon} />}
        onClick={() => onChangeWeek('right')}
        style={{
          marginLeft: '-110px',
          width: '28px',
          height: '44px',
          borderRadius: '38px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '18px',
          color: '#546173',
          zIndex: '100',
        }}
      >
        {'>'}
      </Button>
    </div>
  );
};

export default ChangeEcharts;
