import React, { useEffect } from 'react'
import { connect, history, useIntl } from 'umi'
import { Button, Modal, Input, Row, Tooltip } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import tip from './imgs/tip.png'
import ChangeEmailModal from './ChangeEmailModal'
import style from './index.less'

const { Search } = Input

const confirm = Modal.confirm


const getListSupport = (name, intl) => {
  const listSupport = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
    },
    displayName: {
      showText: intl.formatMessage({ id: 'Display Name' }),
      showType: 'Text',
    },
    enterprise: {
      showText: intl.formatMessage({ id: 'Enterprise' }),
      showType: 'Text',
    },
    contentAdminSimples: {
      showText: intl.formatMessage({ id: 'SubAdmin' }),
      render: (text) => {
        if (text.length) {
          return (
            <Tooltip title={text.map(item => item.name).join(', ')}>
              <span>{text[0].name}</span>
            </Tooltip>
          )
        }
      }
    },
    vratStatus: {
      showText: intl.formatMessage({ id: 'vCAT Status' }),
      showType: 'Bool',
    },
    rocketStatus: {
      showText: intl.formatMessage({ id: 'Rocket Status' }),
      showType: 'Bool',
    },
    PDTStatus: {
      showText: intl.formatMessage({ id: 'PDT Status' }),
      showType: 'Bool',
    },
    state: {
      showText: intl.formatMessage({ id: 'State' }),
      showType: 'Text',
    },
    institutionType: {
      showText: intl.formatMessage({ id: 'Institution Type' }),
      showType: 'Text',
    },
    chargingMethod: {
      showText: intl.formatMessage({ id: 'chargingMethod' }),
      showType: 'Text',
    },
    isActive: {
      showText: intl.formatMessage({ id: 'Is Active' }),
      showType: 'Bool',
    },
    isSend: {
      showText: intl.formatMessage({ id: 'Is Send' }),
      showType: 'Bool',
    }
  }
  if (name === 'agent') {
    delete listSupport.chargingMethod;
    delete listSupport.rocketStatus;
  }
  return listSupport
}

const CenterList = ({ centerList, dispatch, loading, currentUser }) => {
  const { adminRoles: [{name} = {}] = [] } = currentUser || {}
  const intl = useIntl();

  const modalTitleMenu = {
    edit: intl.formatMessage({ id: 'Edit Center' }),
    add: intl.formatMessage({ id: 'Add Center' }),
  }

  useEffect(() => {
    dispatch({ type: 'centerList/query' })
  }, [])

  const { modalType, content, modalVisible, list, pagination, Filter, ContentAdminName, showChangeEmailModal, currentChangeEmailRecord, changeEmailContent, titleName, selectItem={} } = centerList || {}
  const { contentAdminSimples=[] } = selectItem || {}

  // 判断是否展示提示信息
  const filterContentAdminSimples = contentAdminSimples
  .filter(({ roleNames }) => roleNames.includes('agent'))
  .map(({name}) => name)

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'centerList/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'centerList/onSubmitModal', payload: value })
    },
    otherTopContent: name !== 'agent' && modalType==="edit" && filterContentAdminSimples.length > 0? <div style={{paddingLeft: '60px', marginBottom: '25px'}}> <img src={tip} style={{ width: '30px', height: '30px'}}  alt='' ></img>代理商子管理员{filterContentAdminSimples.join()}在管理此中心，不建议编辑</div> : null,
    content,
  }

  // const changeEmailModalProps = {
  //   title: '修改邮箱',
  //   onOkText: '确认并发送',
  //   visible: showChangeEmailModal,
  //   currentChangeEmailRecord,
  //   loading: loading.effects['centerList/changeCenterEmail'],
  //   onOk: (email) => {
  //     dispatch({ type: 'centerList/changeCenterEmail', payload: email })
  //   },
  //   onCancel: () => {
  //     dispatch({ type: 'centerList/updateState', payload: { showChangeEmailModal: false } })
  //   },
  // }

  const changeEmailModalProps = {
    title:  intl.formatMessage({ id: 'Change Email' }),
    visible: showChangeEmailModal,
    content: changeEmailContent,
    loading: loading.effects['centerList/changeCenterEmail'],
    onOk: (value) => {
      dispatch({ type: 'centerList/changeCenterEmail', payload: value })
    },
    onCancel: () => {
      dispatch({ type: 'centerList/updateState', payload: { showChangeEmailModal: false } })
    },
  }

  const showActive = (isActive, isSend) => {
    if (isActive && isSend) return null
    if (isSend) return  intl.formatMessage({ id: 'resend' })
    else {
      return intl.formatMessage({ id: 'Send An Invitation Email' })
    }
  }

  const TableProps = {
    listData: list.map(item => {
      const { projectStatus, parentTenant } = item
      const vratStatus = projectStatus.some(project => {
        const { name, status } = project
        return name === 'Project.Vrat' && status
      })
      const rocketStatus = projectStatus.some(project => {
        const { name, status } = project
        return name === 'Project.Rocket' && status
      })
      const PDTStatus = projectStatus.some(project => {
        const { name, status } = project
        return name === 'Project.PDT' && status
      })

      const enterprise = parentTenant && parentTenant.displayName;

      return {
        ...item,
        vratStatus,
        rocketStatus,
        enterprise,
        PDTStatus
      }
    }),
    listSupport: getListSupport(name, intl),
    pagination,
    loading: loading.effects['centerList/query'],
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'centerList/changeTable', payload: data })
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'Authorize' }),
        onClick(record) {
          history.push({
            pathname: '/system/manage/centerDetail',
            query: {
              id: record.id,
            },
          })
        },
      },
      {
        showText: '编辑',
        itemRender(record) {
          const { isActive } = record;
          return (
            <a
              onClick={() => {
                dispatch({ type: 'centerList/onEdit', payload: record.id })
              }}
            >
              {intl.formatMessage({ id: 'edit' })}
            </a>
          )
        }
      },
      {
        showText: '删除',
        itemRender(record) {
          const { isActive = false, displayName } = record || {}

          return !isActive ? <a onClick={() => {
            confirm({
              title: <span style={{ color: 'red' }}>
                {intl.formatMessage({ id: 'DelDisplayName' }, {displayName})}
              </span>,
              content: '',
              okText: intl.formatMessage({ id: 'Delete' }),
              okType: 'danger',
              cancelText: intl.formatMessage({ id: 'cancel' }),
              onOk() {
                dispatch({ type: 'centerList/onDelete', payload: record.id })
              },
            })
          }}>
          {intl.formatMessage({ id: 'delete' })}
          </a> : null
        }
      },
      {
        showText: '发送激活邮件',
        itemRender(record) {
          const { isSend, isActive } = record
          const showWord = showActive(isActive, isSend)
          return showWord == null ? null : (
            <span key="sendEmail">
              <a
                onClick={() => {
                  dispatch({ type: 'centerList/onSendEmail', payload: record.id })
                }}
              >
                {showWord}
              </a>
            </span>
          )
        },
      },
      {
        showText: '修改邮箱',
        itemRender(record) {
          const { isSend, isActive } = record
          return isActive ? (
            <span key="changeEmail">
              <a
                onClick={() => {
                  dispatch({ type: 'centerList/changeEmail', payload: record })
                }}
              >
                 {intl.formatMessage({ id: 'Change Email' })}
              </a>
            </span>
          )
            :
            null
        },
      }
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'centerList/onAdd' })
  }

  const changeSearchField = (value) => {
    dispatch({ type: 'centerList/updateState', payload: { Filter: value.target.value } })
  }

  const onClear = () => {
    dispatch({ type: 'centerList/clearAllFilterAndSorter' })
  }

  return (
    <div>
      <h1>{intl.formatMessage({ id: 'Manage Centers' })}</h1>
      <div className={style.search}>
        <div>
          <Search
            placeholder={intl.formatMessage({ id: 'Please enter center/enterprise' })}
            value={Filter}
            onSearch={value => dispatch({ type: 'centerList/filterFetch', payload: { Filter: value } })}
            onChange={changeSearchField}
            style={{ width: 200 }}
          />
          <Search
            placeholder={intl.formatMessage({ id: 'Please enter subAdmin' })}
            value={ContentAdminName}
            onSearch={value => dispatch({ type: 'centerList/filterFetch', payload: { ContentAdminName: value } })}
            onChange={(value) => {
              dispatch({ type: 'centerList/updateState', payload: { ContentAdminName: value.target.value } })
            }}
            style={{ width: 200, marginLeft: '20px' }}
          />
          <Button
            className={style.searchTop}
            onClick={onClear}
          >
            {intl.formatMessage({ id: 'clear' })}
          </Button>
        </div>
        <div>
          <Button onClick={onAddClick} type='primary'>{intl.formatMessage({ id: 'Add Center' })}</Button>
        </div>
      </div>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
      <MagicFormModal {...changeEmailModalProps} />
      {/* <ChangeEmailModal {...changeEmailModalProps} /> */}
    </div>
  )
}
export default connect(({ centerList, loading, user }) => ({ centerList, loading, currentUser: user.currentUser }))(CenterList)
