import { Modal, Button } from 'antd';
import newTrainIMG from 'assets/new_train.png'
import { IntlManager } from 'utils/helper'
import styles from './index.less'


const NewTrainModuleModal = ({
    visible,
    oncancel,
    onok
}) => {
    const intl = IntlManager.MyIntl()

    return (
        <Modal
            open={visible}
            title=""
            footer={null}
            width={600}
            onCancel={() => oncancel()}
        >
            <img src={newTrainIMG} className={styles.img} />
            <div className={styles.desc}>{intl('newlyArrangedTraining')}</div>
            <div className={styles.newTrainBtn}>
                {/* <Button
                    style={{ width: '230px' }}
                    onClick={() => oncancel()}
                >
                    暂不
                </Button> */}
                <div>
                    <Button
                        type="primary"
                        style={{ width: '230px', marginLeft: '10px' }}
                        onClick={() => onok()}
                    >
                        {intl('viewTheTraining')}
                    </Button>
                    <div className={styles.stopNo} onClick={() => oncancel()}>{intl('notForNow')}</div>
                </div>
            </div>
        </Modal>
    )
}

export default NewTrainModuleModal