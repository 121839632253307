import { GetUserCourseAnalysis, DownloadUserCourseExcelApi } from 'services/rocketService/Analysis'
import { message } from 'antd'
import download from 'cognitiveleap-core-us/utils/download'
import moment from 'moment'

export default {
  namespace: 'adminUserClassAnalysis',
  state: {
    currentTenant: null,
    userClassAnalysisTablePagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    searchOption: {
      userNickName: '',
      courseName: '',
      openingTime: {
        startTime: '',
        endTime: ''
      }
    }
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {


    *initPage({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user);
      const { currentTenant } = yield select(state => state.adminUserClassAnalysis);
      const { canChangeList } = currentUser;
      if (!currentTenant) {
        yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' }} })
      } else if (payload) {
        const { id } = payload;
        if (id) {
          const newCurrentTenant = canChangeList.find(item => item.id == id)
          yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
        } else {
          yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' }} })
        }
      }

      yield put({ type: 'getUserCourseAnalysis' })
    },

    *getUserCourseAnalysis({ payload }, { call, put, select }) {
      const { userClassAnalysisTablePagination, searchOption, currentTenant } = yield select(state => state.adminUserClassAnalysis)
      const { userNickName, courseName, openingTime } = searchOption
      const { pageSize, current } = userClassAnalysisTablePagination
      const { id } = currentTenant
      const params = {
        UserName: userNickName,
        CourseName: courseName,
        Start: openingTime.startTime,
        End: openingTime.endTime,
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize,
        TenantId: id
      }
      const res = yield call(GetUserCourseAnalysis, params)
      if (res.response.ok) {
        const { userCourse } = res.data
        const { totalCount } = userCourse || {}
        const newPagination = {
          ...userClassAnalysisTablePagination,
          total: totalCount
        }
        yield put({
          type: 'updateState',
          payload: {
            courseAnalysisData: res.data,
            userClassAnalysisTablePagination: newPagination
          }
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { userClassAnalysisTablePagination: currentPagination, } = yield select(state => state.adminUserClassAnalysis)
      const { pagination, filters = {} } = data
      yield put({
        type: 'updateState',
        payload: {
          userClassAnalysisTablePagination: { ...currentPagination, ...pagination, },
          filters,
        },
      })
      yield put({ type: 'getUserCourseAnalysis' })
    },

    *onClear({ payload: data }, { call, put, select }) {
      const searchOption = {
        userNickName: '',
        courseName: '',
        openingTime: {
          startTime: '',
          endTime: ''
        }
      }
      yield put({ type: 'updateState', payload: { searchOption } })
      yield put({ type: 'onSearch' })
    },

    *onSearch({ payload }, { call, put, select }) {
      const userClassAnalysisTablePagination = {
        current: 1,
        pageSize: 10,
        total: 0
      }
      yield put({ type: 'updateState', payload: { userClassAnalysisTablePagination } })
      yield put({ type: 'getUserCourseAnalysis' })
    },

    *changeTenant({ payload }, { call, put, select }){
      yield put({ type: 'updateState', payload: { currentTenant: payload } })
      yield put({ type: 'onClear' })
    },

    *downloadExcel({ payload }, { call, put, select }){
      const { searchOption, currentTenant } = yield select(state => state.adminUserClassAnalysis)
      const { userNickName, courseName, openingTime } = searchOption
      const params = {
        UserName: userNickName,
        CourseName: courseName,
        Start: openingTime.startTime,
        End: openingTime.endTime,
        TenantId: currentTenant.id
      }
      const hide = message.loading('导出列表中,请稍等...', 0);
      const res = yield call(DownloadUserCourseExcelApi, params);
      if (res.response.ok) {
        const { response } = res;
        response.blob().then(blob => {
          download(blob, `课程分析+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
        })
      }
      setTimeout(hide, 0)
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/analysis/userClass') {
    //       dispatch({ type: 'initPage' })
    //     }
    //   })
    // },
  },
}
