import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { GetApps, AddApp, UpdateApp, PrepareForEdit } from 'services/rocketService/AppInfo'

const contentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'displayName' }),
        FormKey: 'displayName',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
        },
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: null,
        Setting: {
          DropdownOptions: [
            {
              Value: 'None',
              Id: 'None',
            },
            {
              Value: 'IOS',
              Id: 'IOS',
            },
            {
              Value: 'ANDROID',
              Id: 'ANDROID',
            },
          ],
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'platform' }),
        FormKey: 'platform',
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'version' }),
        FormKey: 'version',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
        },
        Description: intl.formatMessage({ id: 'versionFormat' }),
      },
      {
        EditorType: 'Input',
        ShowTitle: "最低允许版本",
        FormKey: 'minimalSupportVersion',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
        },
        Description: intl.formatMessage({ id: 'versionFormat' }),
      },
      {
        EditorType: 'Dropdown',
        Value: null,
        Setting: {
          DropdownOptions: [
            {
              Value: 'InternalTest',
              Id: 'InternalTest',
            },
            {
              Value: 'Test',
              Id: 'Test',
            },
            {
              Value: 'Released',
              Id: 'Released',
            },
          ],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'status' }),
        FormKey: 'status',
        Description: null,
      },
      {
        EditorType: 'Bool',
        ShowTitle: intl.formatMessage({ id: 'available' }),
        FormKey: 'available',
        AdditionalData: null,
        Value: null,
        Setting: {},
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'termsOfService' }),
        FormKey: 'termsOfService',
        AdditionalData: null,
        Value: null,
        Setting: {},
        Description: '',
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'privacyPolicy' }),
        FormKey: 'privacyPolicy',
        AdditionalData: null,
        Value: null,
        Setting: {},
        Description: '',
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'region' }),
        FormKey: 'region',
        AdditionalData: null,
        Value: null,
        Setting: {},
        Description: '',
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'downloadLink' }),
        FormKey: 'downloadLink',
        AdditionalData: null,
        Value: null,
        Setting: {},
        Description: '',
      },
    ]
  }
}

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

export default {
  namespace: 'rocketVersion',
  state: {
    list: [],
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const res = yield call(GetApps, { Name: 'rocket' })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { list: res.data },
        })
      }
    },

    *onEdit({ payload: { record: selectItem, intl } }, { call, put, select }) {
      let emptyContent = JSON.parse(JSON.stringify(contentMenu(intl)))
      const editUserInfoForm = matchDynamicForm(emptyContent, selectItem)
      yield put({
        type: 'updateState',
        payload: {
          content: editUserInfoForm,
          modalType: 'edit',
          modalVisible: true,
          selectItem,
        },
      })
    },

    *onAdd({ payload: { intl } }, { call, put, select }) {
      let emptyContent = contentMenu(intl)
      const res = yield call(PrepareForEdit, {})
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { modalType: 'add', content: emptyContent, modalVisible: true },
        })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.rocketVersion)
      let res
      if (modalType === 'edit') {
        res = yield call(UpdateApp, { ...selectItem, ...data })
      } else {
        res = yield call(AddApp, data)
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/application/rocketapp') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
