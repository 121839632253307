import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
} from 'services/rocketService/Prepare'

import { EditCategorySortOrder, PublishStatus } from 'services/rocketService/CourseCategory'

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '分类名',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'displayName',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: '要是没写这里的话，那可能别人就不知道这个是啥',
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Unpublished',
            Id: 'Unpublished',
          },
          {
            Value: 'Hidden',
            Id: 'Hidden',
          },
          {
            Value: 'Public',
            Id: 'Public',
          },
          {
            Value: 'Expired',
            Id: 'Expired',
          },
          {
            Value: 'Trashed',
            Id: 'Trashed',
          },
        ],
        Required: false,
      },
      ShowTitle: 'status',
      FormKey: 'status',
      Description: null,
    },
  ],
}

const listSupport = {
  name: {
    showText: 'name',
    showType: 'Text',
  },
  displayName: {
    showText: 'displayName',
    showType: 'Text',
  },
  sortOrder: {
    showText: '排序号',
    showType: 'Text',
  },
  status: {
    showText: 'status',
    showType: 'Text',
  },
  courseCount: {
    showText: 'courseCount',
    showType: 'Text',
  },
}

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

export default {
  namespace: 'courseCategory',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    sortOrderModal: false,
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.courseCategory)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'courseCategory')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      let emptyContent = null
      const res = yield call(GetPrepareEdit, id, 'courseCategory')
      if (res.response.ok) {
        emptyContent = contentMenu
        const editUserInfoForm = matchDynamicForm(emptyContent, res.data)
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: res.data,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'courseCategory')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      let emptyContent = null
      emptyContent = contentMenu
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: emptyContent, modalVisible: true },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.courseCategory)
      let res
      if (modalType === 'edit') {
        res = yield call(
          PutPrepareData,
          { ...selectItem, ...data },
          selectItem.id,
          'courseCategory'
        )
      } else {
        res = yield call(PostPrepareData, data, 'courseCategory')
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },

    *changeSortOrder({ payload }, { call, put, take, select }) {
      const { selectItem } = yield select(state => state.courseCategory)
      const res = yield call(EditCategorySortOrder, selectItem.id, payload)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { sortOrderModal: false },
        })
        yield put({ type: 'query' })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/course/category') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
