import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Tree,
  Button,
  Spin,
  Tooltip,
  Divider,
  Timeline,
  Input,
  Empty,
} from 'antd';
import ShowPdf from 'components/ShowPdf';
import { history, connect, useIntl } from 'umi';
import styles from './index.less';
import localForage from 'localforage';
import storageKeys from 'utils/storageKeys';
import MyVideo from 'components/MyVideo';
import fullScreen from 'assets/fullscreen.png';
import { eventDebounce } from 'cognitiveleap-core-us/utils/utils';
import ReportRichText from 'components/ReportRichText/ReportRichText';

const { Search } = Input;

const treeItemOnExpand = (expandedKeys, key) => {
  if (expandedKeys.includes(key)) {
    const index = expandedKeys.indexOf(key);
    expandedKeys.splice(index, 1);
    return Array.from(new Set(expandedKeys));
  } else {
    return expandedKeys.concat(key);
  }
};

const getShowStyle = (title, searchValue) => {
  if (searchValue && title.includes(searchValue)) {
    var contentArray = title.split(searchValue);
    const joinText = contentArray.join(
      "<span style='background-color: #FFF717' >" + searchValue + '</span>',
    );

    return <span dangerouslySetInnerHTML={{ __html: joinText }}></span>;
  }

  return <span>{title}</span>;
};

const generateCourseTree = ({
  searchValue,
  courseItem,
  selectedKeys,
  expandedKeys,
  onSelect,
  onExpand,
}) => {
  const {
    contents,
    title,
    subTitle,
    steps,
    stepIndex,
    id: courseId,
  } = courseItem;
  let treeData = [];

  //如果没有编辑章节
  if (steps.length === 0) {
    treeData = [
      {
        selectable: false,
        title: (
          <div
            onClick={() =>
              onExpand(
                treeItemOnExpand(expandedKeys, JSON.stringify({ courseId })),
              )
            }
          >
            {getShowStyle(title, searchValue)}
          </div>
        ),
        key: JSON.stringify({ courseId }),
        children: contents.map((contentItem) => {
          const { name, id: contentId } = contentItem;
          return {
            title: (
              <span>{getShowStyle(name || '', searchValue)}</span>
              //   <Tooltip placement="topLeft" title={name}>
              //     <span>
              //       {name?.length > 20
              //         ? name.substring(0, 20) + '...'
              //         : name || ''}
              //     </span>
              //   </Tooltip>
            ),
            key: JSON.stringify({
              courseId,
              contentId,
              fatherKey: '',
            }),
          };
        }),
      },
    ];
  }

  //TODO 处理有章节的情况
  else {
    treeData = [
      {
        selectable: false,
        title: (
          <div
            onClick={() =>
              onExpand(
                treeItemOnExpand(expandedKeys, JSON.stringify({ courseId })),
              )
            }
          >
            {getShowStyle(title, searchValue)}
          </div>
        ),
        key: JSON.stringify({ courseId }),
        children: stepIndex.map((stepItem) => {
          const { contentId, title } = stepItem;
          return {
            title: (
              <div
                onClick={() => {
                  onExpand(
                    treeItemOnExpand(
                      expandedKeys,
                      JSON.stringify({ contentId }),
                    ),
                  );
                }}
              >
                {getShowStyle(title, searchValue)}
              </div>
            ),
            selectable: false,
            key: JSON.stringify({ contentId }),
            children:
              contentId.length &&
              contentId.map((contentIdItem) => {
                const title = contents.find(
                  (contentsItem) => contentIdItem === contentsItem.id,
                )['name'];
                return {
                  title: (
                    <span>{getShowStyle(title, searchValue)}</span>
                    // <Tooltip placement="topLeft" title={title}>
                    //   <span>
                    //     {title.length > 20
                    //       ? title.substring(0, 20) + '...'
                    //       : title}
                    //   </span>
                    // </Tooltip>
                  ),
                  key: JSON.stringify({
                    courseId,
                    contentId: contents.find(
                      (contentsItem) => contentIdItem === contentsItem.id,
                    )['id'],
                    fatherKey: contentId,
                  }),
                };
              }),
          };
        }),
      },
    ];
  }

  return (
    <Tree
      defaultExpandAll={false}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
      onExpand={onExpand}
      treeData={treeData}
      className={styles.tree}
    />
  );
};

const TrainingMaterial = ({
  trainingMaterial,
  dispatch,
  loading,
  location,
}) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'trainingMaterial/pageLoading', payload: location.query });

    return () => {
      dispatch({ type: 'trainingMaterial/clear' });
    };
  }, []);

  const {
    searchValue,
    courseList,
    selectedKeys,
    expandedKeys,
    currentPdfInfo,
  } = trainingMaterial;
  const isLoading = loading.models.trainingMaterial;
  const { courseId = null, contentId = null } =
    selectedKeys.length > 0 ? JSON.parse(selectedKeys[0]) : {};
  const currentCourseContent =
    courseId && courseList.find((item) => item.id === courseId);
  const currentContent =
    contentId &&
    currentCourseContent.contents.find((item) => item.id === contentId);
  const currentChapterContent =
    currentCourseContent &&
    currentContent &&
    currentCourseContent.stepIndex.length > 0 &&
    currentCourseContent.stepIndex.find((item) =>
      item.contentId.includes(currentContent.id),
    );
  const { resourceList } = currentContent || {};

  const onSelect = async (selectedKeys, info) => {
    if (selectedKeys.length > 0) {
      dispatch({
        type: 'trainingMaterial/onselectKeys',
        payload: { selectedKeys },
      });
      const { contentId, courseId, fatherKey } =
        selectedKeys && selectedKeys.length > 0
          ? JSON.parse(selectedKeys[0])
          : {};
      await localForage.setItem(storageKeys.trainingMaterialCache, {
        courseId,
        contentId,
        fatherKey,
      });
    }
  };
  const onExpand = (clickExpandedKeys, expanded) => {
    dispatch({
      type: 'trainingMaterial/updateState',
      payload: { expandedKeys: clickExpandedKeys, autoExpandParent: false },
    });
  };

  const getRealItem = (item, type) => {
    const { url, fileName, id, coverUrl } = item || {};
    switch (type) {
      case 'Richtext':
        return (
          <div>
            <div className={styles.img}>
              <img src={item.url} />
            </div>
            <Divider style={{ width: '100%' }} />
          </div>
        );
      case 'Audio':
        return <audio controls src={item.url}></audio>;
      case 'Video':
        const videoProps = {
          id,
          url,
          coverUrl,
          width: '100%',
          height: '700px',
        };
        return (
          <div style={{ width: 'calc(100% - 200px)' }}>
            <MyVideo {...videoProps}></MyVideo>
          </div>
        );
      case 'Doc':
        return (
          <a
            onClick={() => {
              localStorage.setItem('reviewfileUrl', item.url);
              window.open('/system/reviewfile', '_blank');
            }}
          >
            {item.fileName}
          </a>
        );
    }
  };

  const getContent = () => {
    return (
      currentContent &&
      currentContent.resourceList &&
      currentContent.resourceList.map((itemResource) => {
        return (
          <div key={itemResource.id} className={styles.content}>
            {getRealItem(itemResource, currentContent.contentType)}
          </div>
        );
      })
    );
  };

  const getDocContent = () => {
    const isPdf = /.pdf$/.test(currentPdfInfo && currentPdfInfo.url);
    const showPdfProps = {
      url: currentPdfInfo && currentPdfInfo.url,
      scale: 0.8,
      isShowLeftRight: true,
      isPdf,
    };
    return (
      <div className={styles.trainingDetailContentDoc}>
        <div className={styles.trainingDetailContentDocTop}>
          {resourceList && resourceList.length > 0 ? (
            resourceList.length === 1 ? (
              <span></span>
              // <a className={styles.trainingDetailContentDocTopOne}>
              //   {resourceList[0] && resourceList[0].fileName}
              // </a>
            ) : (
              <Timeline>
                {resourceList.map((item) => {
                  const { url, fileName, id } = item || {};
                  const color =
                    currentPdfInfo && currentPdfInfo.id === id
                      ? '#1890FF'
                      : 'gray';
                  const changeTimelineItem = () => {
                    if (currentPdfInfo && currentPdfInfo.id !== id) {
                      dispatch({
                        type: 'trainingMaterial/getCurrentPdfInfo',
                        payload: { resourceList, id },
                      });
                    }
                  };
                  return (
                    <Timeline.Item color={color} onClick={changeTimelineItem}>
                      <a style={{ color }}>{fileName}</a>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            )
          ) : (
            <span></span>
          )}
        </div>
        <div className={styles.trainingDetailContentDocBottom}>
          {currentPdfInfo && (
            <React.Fragment>
              <ShowPdf {...showPdfProps} />
              <img
                src={fullScreen}
                className={styles.fullScreen}
                onClick={() => {
                  if (isPdf) {
                    localStorage.setItem(
                      'reviewfileUrl',
                      currentPdfInfo && currentPdfInfo.url,
                    );
                    window.open('/system/reviewfile', '_blank');
                  }
                }}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const onSearchValue = (e) => {
    const { value } = e.target;
    if (!value) {
      const expandedKeys =
        selectedKeys && selectedKeys.length > 0
          ? [
              JSON.stringify({
                courseId: JSON.parse(selectedKeys[0]).courseId,
              }),
              JSON.stringify({
                contentId: JSON.parse(selectedKeys[0]).fatherKey,
              }),
            ]
          : [];
      dispatch({
        type: 'trainingMaterial/updateState',
        payload: {
          searchValue: '',
          autoExpandParent: false,
          expandedKeys,
        },
      });
      return;
    }
    let newExpandedKeys = courseList
      .map((item) => {
        const { id, title, stepIndex, contents } = item;
        if (
          title.includes(value) ||
          contents.some((content) => content.name.includes(value)) ||
          stepIndex.some((stepItem) => stepItem.title.includes(value))
        ) {
          return JSON.stringify({ courseId: id });
        }
      })
      .filter((item) => item);

    newExpandedKeys.forEach((item) => {
      const { courseId } = JSON.parse(item);
      const findData = courseList.find((course) => course.id === courseId);
      if (findData) {
        const { stepIndex = [], contents = [] } = findData;
        stepIndex.forEach((step) => {
          const { contentId = [] } = step;
          contentId.forEach((content) => {
            const findContent = contents.find((contentItem) => {
              const { id, name } = contentItem || {};
              return id === content && name.includes(value);
            });

            if (
              findContent &&
              !newExpandedKeys.includes(JSON.stringify({ contentId }))
            ) {
              newExpandedKeys.push(JSON.stringify({ contentId }));
            }
          });
        });
      }
    });

    dispatch({
      type: 'trainingMaterial/updateState',
      payload: {
        searchValue: value,
        autoExpandParent: true,
        expandedKeys: newExpandedKeys,
      },
    });
  };

  return (
    <div className={styles.trainingMaterial}>
      <div className={styles.trainingMaterialTop}>
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          {intl.formatMessage({ id: 'back' })}
        </Button>
        <p className={styles.trainingMaterialTopTitle}>
          {intl.formatMessage({ id: 'database' })}
        </p>
      </div>
      <Spin spinning={isLoading}>
        <Row className={styles.trainingMaterialContent}>
          <Col span={17}>
            {currentCourseContent && (
              <div className={styles.oneTitle}>
                {currentCourseContent.title}
              </div>
            )}
            {currentChapterContent && (
              <div className={styles.twoTitle}>
                {currentChapterContent.title}
              </div>
            )}
            {currentContent && (
              <div className={styles.threeTitle}>{currentContent.name}</div>
            )}
            {selectedKeys.length > 0 ? (
              <>
                {currentContent && currentContent.contentType === 'Doc'
                  ? getDocContent()
                  : getContent()}
                <p />
                <div style={{ maxWidth: 'calc(100% - 200px)', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
                  {currentContent.detail &&
                    (currentContent.detail.includes('<p') ? (
                      <ReportRichText content={currentContent.detail} />
                    ) : (
                      <div style={{ whiteSpace: 'pre-line' }}>
                        {currentContent.detail}
                      </div>
                    ))}
                </div>
              </>
            ) : (
              intl.formatMessage({ id: 'noCourses' })
            )}
          </Col>
          <Col span={7}>
            <div className={styles.treeOut}>
              <Search
                allowClear
                style={{
                  marginBottom: 8,
                }}
                placeholder={intl.formatMessage({ id: 'search' })}
                onChange={(e) => {
                  eventDebounce(() => onSearchValue(e), 666);
                }}
              />
              {searchValue && expandedKeys.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                courseList.map((item) => {
                  return (
                    <div key={item.id}>
                      {generateCourseTree({
                        searchValue,
                        courseItem: item,
                        expandedKeys,
                        selectedKeys: selectedKeys,
                        onSelect: onSelect,
                        onExpand,
                      })}
                    </div>
                  );
                })
              )}
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default connect(({ trainingMaterial, loading }) => ({
  trainingMaterial,
  loading,
}))(TrainingMaterial);
