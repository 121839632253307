import React, { useState } from 'react';
import { connect, useIntl } from 'umi';
import { Space, Button, Menu, Dropdown } from 'antd';
import CustomMicroApp from 'components/CustomMicroApp';
import { DownOutlined } from '@ant-design/icons';
import { getActiveWeekdays, getEchartWeekAssigned } from 'utils/utils';
import ShowCardBorder from './components/ShowCardBorder';

import moment from 'moment';

import styles from './index.less'

const ParentEmotion = ({ subjectDetail }) => {
    const intl = useIntl();
    const [week, setWeek] = useState(null);

    const { subjectInfo } = subjectDetail;
    const { pdtModules, guardians, id } = subjectInfo;
    const currentModule = pdtModules.find(item => item.pdtModule === 'ParentEmotion');
    const { appStartTime, stopTime } = currentModule || {};
    const parentId = guardians[0].id;

    const { weekAssigned, currentWeek, startDay, endDay } = getEchartWeekAssigned(appStartTime, stopTime, week);
    const handleMenuClick = (e) => {
        const { key } = e;
        setWeek(key);
    };

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={getActiveWeekdays(weekAssigned)}
        />
    );

    const props = {
        url: '/parentemotion',
        appProps: {
            parentId,
            week,
            changeWeek: (value) => {
                setWeek(value);
            }
        }
    };

    return <div className={styles.parentEmotionPanel}>
        <div className={styles.EmotionTitle}>{intl.formatMessage({ id: 'ParentEmotion' })}</div>
        {/* <Space>
            <span>{intl.formatMessage({ id: 'Week' })}:&nbsp;&nbsp;</span>
            <Dropdown overlay={menu}>
                <Button>
                    <Space>
                        {currentWeek}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
            {startDay.format('MM/DD/YYYY')} -
            {endDay.format('MM/DD/YYYY')}
        </Space> */}
        <ShowCardBorder>
            <CustomMicroApp {...props} />
        </ShowCardBorder>
    </div>
};

export default connect(({
    subjectDetail,
}) => ({
    subjectDetail,
}))(ParentEmotion);