import React, { useEffect, useState } from 'react';
// import { PostPayResult } from 'services/exam';
import { Table } from 'antd';

const ExpandedTable = (props) => {
  const { data, columns, codeList } = props;

  const [currentData, setCurrentData] = useState(data);
  const [loading, setLoading] = useState(false);

  const getPayRes = async () => {
    setLoading(true);
    let newData = currentData;
    setCurrentData(newData);
    // const res = await PostPayResult({ codes: codeList });
    // if (res.response.ok) {
    //   const payInfo = res.data.data;
    //   let newData = currentData;
    //   if (currentData.length > 1 && payInfo.length > 0) {
    //     let temp = newData.slice(1, currentData.length);
    //     let tempData = [];
    //     temp.forEach((item) => {
    //       let tempItem = item;
    //       payInfo.forEach((itemTemp) => {
    //         if (item.id === itemTemp.code && itemTemp.status !== 'NotPay') {
    //           const { price, status } = itemTemp;
    //           tempItem = {
    //             ...item,
    //             price,
    //             payStatus: status,
    //           };
    //         }
    //       });
    //       tempData.push(tempItem);
    //     });
    //     tempData.unshift(currentData[0]);
    //     setCurrentData(tempData);
    //   } else {
    //     setCurrentData(newData);
    //   }
    // }
    setLoading(false);
  };

  useEffect(() => {
    getPayRes();
  }, []);

  return (
    <Table
      columns={columns}
      loading={loading}
      rowKey={(record) => record.id + ''}
      dataSource={currentData}
      pagination={false}
    />
  );
};

export default ExpandedTable;
