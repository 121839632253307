import React, { useEffect } from 'react';
import { connect, history, useIntl } from 'umi'
import { Card, Input, Button, Spin, Pagination, Row, Col } from 'antd';
import storageKeys from 'utils/storageKeys'
import localForage from 'localforage'
import style from './index.less';
import TrainerDatabaseItem from './components/TrainerDatabaseItem';
const { Search } = Input

const TrainerDatabase = ({ trainerDatabase, dispatch, loading }) => {
    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'trainerDatabase/getTrainerDatabaseList' })
    }, [])

    const { trainerDatabaseInfo, pagination, Filter } = trainerDatabase;
    const isLoading = loading.models.trainerDatabase;

    if (!trainerDatabaseInfo) {
        return <Spin />;
    }

    const onSearch = (type, value) => {
        let parmas = null;
        switch (type) {
            case 'pageSize':
                parmas = {
                    pagination: { current: 1, pageSize: value },
                    title: Filter
                };
                break;
            case 'pageNo':
                parmas = {
                    pagination: { current: value, pageSize: pagination.pageSize },
                    title: Filter
                };
                break;
            case 'inputTitle':
                parmas = {
                    pagination: { current: 1, pageSize: 10 },
                    title: value
                };
                break;
            case 'reset':
                parmas = {
                    pagination: { current: 1, pageSize: 10 },
                    title: ''
                };
                break;
        }
        dispatch({
            type: 'trainerDatabase/onSearch',
            payload: parmas
        })
    }

    const onShowSizeChange = (current, pageSize) => {
        onSearch('pageSize', pageSize);
    }

    const onChange = (pageNo) => {
        onSearch('pageNo', pageNo);
    }

    const onInputSearch = (value) => {
        onSearch('inputTitle', value);
    }

    const goDetail = async (item) => {
        //先添加courseid进去
        await localForage.setItem(storageKeys.trainingMaterialCache, { courseId: item.id, contentId: null })
        history.push({
            pathname: '/system/center/detail/trainingMaterial'
        })
    }

    const onInputValueChange = e => {
        dispatch({
            type: 'trainerDatabase/updateState',
            payload: {
                Filter: e.target.value
            }
        })
    }

    const totalShowText = intl.formatMessage({ id: 'total' })
    const itemsShowText = intl.formatMessage({ id: 'items' })

    return (
        <Card
            title={<h1 className={style.pageName}>{intl.formatMessage({ id: 'database' })}</h1>}
            bordered={false}
            className={style.trainerDatabase}
        >
            <div className={style.trainerDatabaseSearch}>
                <Search
                    style={{ width: 250 }}
                    value={Filter}
                    placeholder={intl.formatMessage({ id: 'pleaseEnterKeys' })}
                    onSearch={value => onInputSearch(value)}
                    onChange={onInputValueChange}
                />
                <Button
                    className={style.trainerDatabaseSearchButton}
                    disabled={isLoading}
                    onClick={() => { onSearch('reset') }}
                >
                    {intl.formatMessage({ id: 'reset' })}
                </Button>
            </div>
            <Spin spinning={isLoading}>
                <div className={style.trainerDatabaseContent}>
                    {
                        trainerDatabaseInfo.items.map(item => {
                            return <TrainerDatabaseItem key={item.id} data={item} goDetail={goDetail} />
                        })
                    }
                </div>
            </Spin>
            <Pagination
                showQuickJumper
                current={pagination.current}
                total={trainerDatabaseInfo.totalCount}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onChange}
                pageSizeOptions={['10', '15', '20', '25', '30']}
                showTotal={total => totalShowText + ` ${total} ` + itemsShowText}
                disabled={isLoading}
                pageSize={pagination.pageSize}
            />
        </Card>
    )
}

export default connect(({ trainerDatabase, loading }) => ({ trainerDatabase, loading }))(TrainerDatabase)
