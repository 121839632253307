import React, { useEffect } from 'react'
import moment from 'moment'
import { Button, Form, Row, Col, Input, Select, Radio, Table, Divider, Modal, message, Spin } from 'antd'
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './addUser.less'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { history, useIntl, connect, getLocale } from 'umi';
import CountDownBtn from './CountDownBtn'
import ConfirmModal from './ConfirmModal'
import { matchDynamicForm, isEffectualPhoneNumber } from 'cognitiveleap-core-us/utils/utils'
import { genderLocale, handLocale, getMobilePrefix } from 'utils/utils'
import FormItem from 'antd/lib/form/FormItem';
const Option = Select.Option
const confirm = Modal.confirm
const formRef = React.createRef()

const AddUser = ({
  addUser,
  currentUser,
  dispatch,
  loading,
}) => {

  const intl = useIntl()
  const isZh = getLocale() === 'zh-CN'
  const mobilePrefix = getMobilePrefix(intl)
  useEffect(() => {
    dispatch({ type: 'addUser/getGuardianRole', payload: { intl } })
  }, [])

  const { addUser: isLoading } = loading.models
  const initLoading = loading.effects['addUser/getGuardianRole']
  const { changedTenant: { institutionType } } = currentUser || {}
  const {
    canSendCode,
    subjectList,
    guardianInfo,
    confirmModalVisible,
    content,
    modalVisible,
    coaches,
    selectItem,
    selectIndex,
    avaliableGuardianRoles,
    isSendCode,
    countryCode,
    btnDisable,
  } = addUser

  if (initLoading) {
    return <Spin size="large" style={{ minHeight: '300px', width: '100vw', backgroundColor: 'white', lineHeight: '300px' }} />;
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: intl.formatMessage({ id: 'birthday' }),
      dataIndex: 'birthDay',
      key: 'birthDay',
      render: text => <span>{moment(text).format('YYYY-MM-DD')}</span>,
    },
    {
      title: intl.formatMessage({ id: 'gender' }),
      dataIndex: 'genderLocale',
      // filters: [
      //   { text: intl.formatMessage({ id: 'unknown' }), value: 'Unknown' },
      //   { text: intl.formatMessage({ id: 'male' }), value: 'Male' },
      //   { text: intl.formatMessage({ id: 'female' }), value: 'Female' },
      //   { text: intl.formatMessage({ id: 'other' }), value: 'Others' },
      // ],
      key: 'gender',
    },
    {
      title: intl.formatMessage({ id: 'relationship' }),
      // filters: avaliableGuardianRoles.map(item => {
      //   return {
      //     text: item.displayName,
      //     value: item.name,
      //   }
      // }),
      dataIndex: 'relationshipLocale',
      key: 'guardianRole',
    },
    {
      title: intl.formatMessage({ id: 'trainer' }),
      dataIndex: 'coachNames',
      key: 'coachNames'
    },
    {
      title: intl.formatMessage({ id: 'dominantHand' }),
      dataIndex: 'handLocale',
      // filters: [
      //   { text: intl.formatMessage({ id: 'left' }), value: 'LeftHand' },
      //   { text: intl.formatMessage({ id: 'right' }), value: 'Right' },
      // ],
      key: 'dominantHand',
    },
    {
      title: intl.formatMessage({ id: 'action' }),
      key: 'action',
      render: (text, record, index) => (
        <span>
          <a
            onClick={() =>
              dispatch({
                type: 'addUser/updateState',
                payload: { modalVisible: true, selectItem: record, selectIndex: index },
              })
            }
          >
            {intl.formatMessage({ id: 'edit' })}
          </a>
          <Divider type="vertical" />
          <a
            onClick={() =>
              confirm({
                title: intl.formatMessage({ id: 'guardian.areYouSureToDeleteSubject' }),
                content: intl.formatMessage({ id: 'guardian.canBeModified' }),
                okText: intl.formatMessage({ id: 'submit' }),
                okType: 'danger',
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk() {
                  dispatch({ type: 'addUser/onDelete', payload: index })
                },
              })
            }
          >
            {intl.formatMessage({ id: 'delete' })}
          </a>
        </span>
      ),
    },
  ]

  const newList = institutionType === 'Medical' ? [
    {
      title: intl.formatMessage({ id: 'clinicNumber' }),
      dataIndex: 'outpatientNumber',
      key: 'outpatientNumber'
    },
    ...columns
  ] : columns

  //  切换区域Select触发回调,只验证手机号
  const handleChange = () => {
    formRef.current.validateFields(['phone']);
  }
  const prefixSelector = (
    <FormItem
      name={'countryCode'}
      initialValue={countryCode ?? (isZh ? '+86' : '+1')}
      style={{ marginBottom: 0 }}
    >
      <Select style={{ width: 150 }} onChange={handleChange}>
        {
          mobilePrefix.map((item, key) => {
            const { prefix, countryName } = item
            return <Option value={prefix} key={key}>{`${countryName}(${prefix})`}</Option>
          })
        }
      </Select>
    </FormItem>
  )

  const modalProps = {
    title:
      selectItem === null
        ? intl.formatMessage({ id: 'newSubject' })
        : intl.formatMessage({ id: 'editSubject' }),
    visible: modalVisible,
    loading: loading.effects['addUser/checkSubject'] || loading.effects['addUser/addOrEditSubject'],
    onCancel() {
      dispatch({ type: 'addUser/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      const { guardianRole, coachIds } = value
      const relationshipLocale = avaliableGuardianRoles.find(item => {
        return item.name === guardianRole
      }).displayName
      const coachNamesList = coachIds && Array.isArray(coachIds) && coachIds.length > 0 ? coachIds.map(item => coaches.find(coacheItem => coacheItem.id === item).name) : []
      dispatch({ type: 'addUser/checkSubject', payload: { ...value, relationshipLocale, coachNames: coachNamesList.join('；'), intl } })
    },
    content: matchDynamicForm(content, selectItem),
  }

  const countDownBtnProps = {
    intl,
    onComplete() {
      dispatch({ type: 'addUser/updateState', payload: { canSendCode: true } })
    },
  }

  const onSendCode = async () => {
    const values = await formRef.current.getFieldValue();
    if (values && values.phone) {
      dispatch({ type: 'addUser/updateState', payload: { canSendCode: false, isSendCode: true } })
      dispatch({ type: 'addUser/sendCode', payload: { values, intl } })
    } else {
      message.error(intl.formatMessage({ id: 'inputPhoneNum' }))
    }
  }

  const tableProps = {
    columns: newList,
    dataSource: subjectList.map(item => {
      const { gender, dominantHand, coachIds } = item
      const coachNamesList = coachIds && Array.isArray(coachIds) && coachIds.length > 0 ? coachIds.map(item => coaches.find(coacheItem => coacheItem.id === item).name) : []
      const coachNames = coachNamesList.length > 2 ? coachNamesList.slice(0, 2).join('；') + '...' : coachNamesList.join('；')
      return {
        ...item,
        genderLocale: genderLocale(gender, intl),
        handLocale: handLocale(dominantHand, intl),
        coachNames
      }
    }),
    pagination: false,
    rowKey: 'name',
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const onSubmit = (values) => {
    // if (!isSendCode) {
    //   message.warning(intl.formatMessage({ id: 'sendCodeFirst' }))
    //   return
    // }
    if (subjectList.length > 0) {
      dispatch({
        type: 'addUser/updateState',
        payload: { confirmModalVisible: true, guardianInfo: { ...values, phoneNumber: values.phone } },
      })
    }
    else {
      message.error(intl.formatMessage({ id: 'lessOneChild' }))
    }
  }

  const confirmModalProps = {
    guardianInfo,
    subjectList,
    visible: confirmModalVisible,
    onCancel() {
      dispatch({ type: 'addUser/updateState', payload: { confirmModalVisible: false } })
    },
    onOk() {
      dispatch({ type: 'addUser/onSubmit', payload: { intl } })
    },
  }

  const onCancel = () => {
    history.replace('/system/center/menu/user/user')
  }

  const onAdd = () => {
    dispatch({
      type: 'addUser/updateState',
      payload: { modalVisible: true, selectIndex: null, selectItem: null },
    })
  }

  return (
    <div>
      <div className={styles.top}>
        <Button onClick={() => history.goBack()}>{intl.formatMessage({ id: 'back' })}</Button>
        <span className={styles.topTitle}>{intl.formatMessage({ id: 'addGuardian' })}</span>
      </div>
      {/* <h1 className={styles.bigTitle}>{intl.formatMessage({ id: 'addGuardian' })}</h1> */}
      <Form
        ref={formRef}
        onFinish={onSubmit}
        {...formItemLayout}
      >
        <div className={styles.wrap}>
          {/* 填写家长信息 */}
          <div className={`${styles.item} ${styles.itemMargin}`}>
            <h3 className={styles.itemTitle}>{intl.formatMessage({ id: 'guardian.information' })}</h3>
            <Divider style={{ margin: '16px 0 32px 0' }} />
            {/* 手机号 */}
            <Form.Item
              label={intl.formatMessage({ id: 'phone' })}
              name={'phone'}
              rules={[
                {
                  required: true, message: `${intl.formatMessage({ id: 'phone' })} ${intl.formatMessage({ id: 'isRequired' })}` 
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // 拿到当前的区号
                    const countryCode = getFieldValue('countryCode');
                    // 判断手机号格式是否正确 更新btnDisabled状态 并在表单给出提示
                    if (isEffectualPhoneNumber(countryCode, value)) {
                      dispatch({
                        type: 'addUser/updateState',
                        payload: {
                          btnDisable: false,
                        },
                      });
                      return Promise.resolve();
                    } else {
                      dispatch({
                        type: 'addUser/updateState',
                        payload: {
                          btnDisable: true,
                        },
                      });
                      return Promise.reject(new Error(intl.formatMessage({id: 'telMessage'})));
                    }
                  },
                }),
              ]}
            >
              <Row>
                <Col span={12}>
                  <Input
                    addonBefore={prefixSelector}
                    placeholder={intl.formatMessage({ id: 'pleaseEnter' })}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'name' })}
              name={'name'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({ id: 'name' })} ${intl.formatMessage({ id: 'isRequired' })}`,
                  min: 2,
                  max: 30,
                },
              ]}
            >
              <Row>
                <Col span={12}>
                  <Input placeholder={intl.formatMessage({ id: 'pleaseEnter' })} />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'gender' })}
              name={'gender'}
              initialValue={'Male'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({ id: 'gender' })} ${intl.formatMessage({ id: 'isRequired' })}`,
                }
              ]}
            >
              <Radio.Group>
                <Radio value="Male">{intl.formatMessage({ id: 'male' })}</Radio>
                <Radio value="Female">{intl.formatMessage({ id: 'female' })}</Radio>
                {/* <Radio value="Others">{intl.formatMessage({ id: 'other' })}</Radio> */}
              </Radio.Group>
            </Form.Item>
          </div>

          {/* 添加儿童 */}
          <div className={`${styles.item} ${styles.itemMargin}`}>
            <Row type="flex" justify="start" align="middle" gutter={20} className={styles.itemTitle}>
              <Col>
                <Button type="primary" onClick={onAdd} icon={<PlusOutlined />}>
                  {intl.formatMessage({ id: 'newSubject' })}
                </Button>
              </Col>
              <Col>
                <span className={styles.desc}>{intl.formatMessage({ id: 'guardian.atLeast1' })}</span>
              </Col>
            </Row>
            <Divider style={{ margin: '16px 0 32px 0' }} />
            <div style={{ padding: '0 32px' }}>
              <Table {...tableProps} />
            </div>
          </div>

          {/* 填写短信验证码 */}
          {/* <div className={`${styles.item} ${styles.itemMargin}`}>
            <h3 className={styles.itemTitle}>{intl.formatMessage({ id: 'enterSMSCode' })}</h3>
            <Divider style={{ margin: '16px 0 32px 0' }} />
            <Form.Item
              label={intl.formatMessage({ id: 'smsCode' })}
              extra={intl.formatMessage({ id: 'guardian.sendCodeTips' })}
              name={'verificationCode'}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({ id: 'smsCode' })} ${intl.formatMessage({ id: 'isRequired' })}`
                }
              ]}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Input placeholder={intl.formatMessage({ id: 'pleaseEnter' })} />
                </Col>
                <Col span={8}>
                  {canSendCode ? (
                    <Button onClick={onSendCode} disabled={btnDisable}>
                      {intl.formatMessage({ id: 'guardian.sendCode' })}
                    </Button>
                  ) : (
                    <CountDownBtn {...countDownBtnProps} />
                  )}
                </Col>
              </Row>
            </Form.Item>
          </div>
          <Row style={{ backgroundColor: 'white' }} justify="center" type="flex">
            <Col>
              <InfoCircleOutlined />
            </Col>
            <Col style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              <p>
                {intl.formatMessage({ id: 'securityCodeTips1' })}
                <span style={{ color: '#FF6600' }}>
                  {intl.formatMessage({ id: 'securityCodeTips2' })}
                </span>
                {intl.formatMessage({ id: 'securityCodeTips3' })}
              </p>
              <p>{intl.formatMessage({ id: 'securityCodeTips4' })}</p>
              <p>{intl.formatMessage({ id: 'securityCodeTips5' })}</p>
              <p>{intl.formatMessage({ id: 'securityCodeTips6' })}</p>
            </Col>
          </Row> */}
        </div>

        <Row style={{ backgroundColor: 'white', paddingTop: 10 }} justify="center" type="flex">
          <Col>
            <span>{intl.formatMessage({ id: 'submitTipAddUser' })}</span>
          </Col>
        </Row>

        {/* 按钮 */}
        <div className={styles.submit}>
          <Button size="large" onClick={onCancel} className={styles.btn}>
            {intl.formatMessage({ id: 'backLowercase' })}
          </Button>
          <Button
            size="large"
            type="primary"
            className={styles.submitBtn}
            loading={isLoading}
            htmlType="submit"
          >
            {intl.formatMessage({ id: 'submit' })}
          </Button>
        </div>
      </Form>
      <ConfirmModal {...confirmModalProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}

export default connect(({ addUser, user, loading }) => ({ addUser, currentUser: user.currentUser, loading }))(AddUser)
