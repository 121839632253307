import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Space, message, Select } from 'antd';
import { history } from 'umi';

import { getAdminName } from 'utils/utils';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

import {
  PostReSend,
  PostAddRole,
  PostContentAdmin,
  PostActiveAccount,
  PostEditRole,
  GetContentAdminList,
  PostFreeze,
} from 'services/roles';

const { Search } = Input;
const { Option } = Select;

const listSupport = {
  creationTime: {
    showText: '创建时间',
    showType: 'Time',
  },
  name: {
    showText: '姓名',
    showType: 'Text',
  },
  roleNames: {
    showText: '管理员类型',
    showType: 'Text',
    render: (text, _) => {
      return text.map((item, index) => {
        const temp = index === text.length - 1 ? '' : '，';
        return getAdminName(item) + '管理' + temp;
      });
    },
  },
  email: {
    showText: '邮箱',
    showType: 'Text',
  },
  phoneNumber: {
    showText: '手机号',
    showType: 'Text',
  },
  state: {
    showText: '状态',
    showType: 'Text',
    render: (text, _) => (text === 'Active' ? '有效' : '冻结'),
  },
};

const contentMenu = {
  Properties: [
    // {
    //   EditorType: 'Input',
    //   ShowTitle: '姓名',
    //   FormKey: 'name',
    //   AdditionalData: null,
    //   Value: null,
    //   Setting: {
    //     Required: true,
    //   },
    //   Description: '',
    // },
    {
      EditorType: 'CheckboxSelector',
      ShowTitle: '角色类型',
      FormKey: 'roleNames',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        DropdownOptions: [
          {
            Value: '运营管理',
            Id: 'operationAdmin',
          },
          {
            Value: '内容管理',
            Id: 'editor',
          },
          {
            Value: '财务管理',
            Id: 'finance',
          },
          {
            Value: '销售管理',
            Id: 'sales',
          },
          {
            Value: '医学支持管理',
            Id: 'medicalSupport',
          },
          {
            Value: '培训管理',
            Id: 'training',
          },
          {
            Value: '代理商',
            Id: 'agent'
          }
        ],
      },
      Description: '',
    },
    {
      EditorType: 'Input',
      ShowTitle: '邮箱',
      FormKey: 'email',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        Rules: [
          {
            pattern:
              /^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/,
            message: '请输入有效邮箱',
          },
        ],
      },
      Description: '',
    },
    // {
    //   EditorType: 'Input',
    //   ShowTitle: '手机号',
    //   FormKey: 'phoneNumber',
    //   AdditionalData: null,
    //   Value: null,
    //   Setting: {
    //     Required: false,
    //   },
    //   Description: '',
    // },
    // {
    //   EditorType: 'Dropdown',
    //   Value: 'Effective',
    //   Setting: {
    //     DropdownOptions: [
    //       {
    //         Value: '有效',
    //         Id: 'Effective',
    //       },
    //       {
    //         Value: '冻结',
    //         Id: 'Invalid',
    //       },
    //     ],
    //     Required: true,
    //   },
    //   ShowTitle: '状态',
    //   FormKey: 'state',
    //   Description: null,
    // },
  ],
};

const AdminList = () => {
  const [listData, setListData] = useState([]);
  const [currentContent, setCurrentContent] = useState(contentMenu);
  const [currentItem, setCurrentItem] = useState({});
  const [loading, setLoading] = useState({
    tableLoading: false,
    modelLoading: false,
  });
  const [visible, setVisible] = useState(false);
  const [searchData, setSearchData] = useState({
    RoleName: null,
    UserName: null,
  });
  const [option, setOptions] = useState('add');
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const { current, pageSize } = pagination;
    const { RoleName, UserName } = searchData;
    var params = {
      SkipCount: (current - 1) * pageSize,
      MaxResultCount: pageSize,
      RoleName,
      UserName,
    };
    // 删除空的数据
    for (var key in params) {
      if (params[key] === null || params[key] === '') {
        delete params[key];
      }
    }
    const res = await GetContentAdminList(params);
    if (res.response.ok) {
      const { items, totalCount } = res.data;
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { message: msg } = res.data.error || { message: '获取失败' };
      message.error(msg);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    getData();
  }, [searchData, pagination.current]);

  const props = {
    listData: listData,
    listSupport: listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: '发送邀请邮件',
        itemRender(record) {
          const { isSend } = record;
          return (
            <a
              onClick={async () => {
                const { id } = record;
                const res = await PostReSend({ id });
                if (res.response.ok) {
                  message.success('发送成功');
                } else {
                  const { error } = res.data;
                  const { message: errorInfo } = error || {
                    message: '发送失败',
                  };
                  message.error(errorInfo);
                }
              }}
            >
              {isSend ? '重新发送' : '发送邀请邮件'}
            </a>
          );
        },
      },
      {
        showText: '编辑角色',
        itemRender(record) {
          return (
            <a
              onClick={() => {
                const { roleNames, email, name } = record;
                setCurrentItem(record);
                let emptyContent = JSON.parse(JSON.stringify(contentMenu));
                // emptyContent.Properties[0].Value = name;
                // emptyContent.Properties[0].Setting.Disabled = true;
                emptyContent.Properties[0].Value = roleNames;
                // emptyContent.Properties[0].Description = '只能新增，不能删除';
                emptyContent.Properties[1].Value = email;
                emptyContent.Properties[1].Setting.Disabled = true;
                setCurrentContent(emptyContent);
                setOptions('edit');
                setVisible(true);
              }}
            >
              编辑角色
            </a>
          );
        },
      },
      {
        showText: '中心列表',
        itemRender(record) {
          return (
            <a
              onClick={() => {
                const { id } = record;
                history.push({
                  pathname: '/system/manage/adminListDetail',
                  query: {
                    id
                  },
                });
              }}
            >
              中心列表
            </a>
          );
        },
      },
      {
        showText: '冻结账号',
        itemRender(record) {
          const { state, id } = record || {};
          const isActive = state === 'Active';
          return (
            <a
              onClick={async () => {
                const res = await PostFreeze({
                  contentAdminId: id,
                  userOperate: isActive ? 'Freeze' : 'Unfreeze',
                });

                if (res.response.ok) {
                  message.success('操作成功');
                  getData();
                } else {
                  const { error } = res.data || {};
                  message.error(error.message);
                }
              }}
            >
              {isActive ? '冻结账号' : '解冻账号'}
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    title: option == 'add' ? '添加管理员' : '编辑角色类型',
    visible,
    content: currentContent,
    onCancel() {
      setVisible(false);
    },
    loading: loading.modelLoading,
    async onOk(value) {
      setLoading({
        ...loading,
        modelLoading: true,
      });
      let res;
      if (option === 'add') {
        res = await PostAddRole({ ...value });
      } else {
        const { roleNames } = value;
        const { id: contentAdminId, roleNames: currentRoleNames } = currentItem;
        // const newRoleNames = [];
        // roleNames.forEach((item) => {
        //   if (!currentRoleNames.includes(item)) {
        //     newRoleNames.push(item);
        //   }
        // });
        // if (newRoleNames.length === 0) {
        //   message.error('请添加新的角色');
        //   setLoading({
        //     ...loading,
        //     modelLoading: false,
        //   });
        //   return;
        // }
        res = await PostEditRole({
          contentAdminId,
          roleNames,
        });
      }
      if (res.response.ok) {
        message.success(option === 'add' ? '添加成功' : '编辑成功');
        getData();
      } else {
        const { message: msg } = res.data.error || { message: '添加失败' };
        message.error(msg);
      }
      setVisible(false);
      setLoading({
        ...loading,
        modelLoading: false,
      });
    },
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>管理员列表</b>}
      extra={
        <Button
          type="primary"
          onClick={() => {
            setCurrentContent(contentMenu);
            setOptions('add');
            setVisible(true);
          }}
        >
          添加管理员
        </Button>
      }
      bordered={false}
    >
      <div>
        <Space>
          <Search
            placeholder="请输入管理员姓名"
            allowClear
            onSearch={(value) => {
              setPagination({
                current: 1,
                pageSize: 10,
                total: 0,
              });
              setSearchData({
                ...searchData,
                UserName: value,
              });
            }}
            style={{ width: 200 }}
          />
          <Select
            placeholder={'全部'}
            style={{ width: 200 }}
            onChange={(value) => {
              setPagination({
                current: 1,
                pageSize: 10,
                total: 0,
              });
              setSearchData({
                ...searchData,
                RoleName: value,
              });
            }}
          >
            <Option value="">全部</Option>
            <Option value="operationAdmin">运营管理</Option>
            <Option value="editor">内容管理</Option>
            <Option value="finance">财务管理</Option>
            <Option value="sales">销售管理</Option>
            <Option value="medicalSupport">医学支持管理</Option>
            <Option value="training">培训管理</Option>
            <Option value="agent">代理商</Option>
          </Select>
        </Space>
      </div>
      <br />
      <MagicTable {...props} />
      <MagicFormModal {...modalProps} />
    </Card>
  );
};

export default AdminList;
