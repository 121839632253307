import React, { useEffect } from 'react'
import { connect, useIntl } from 'umi'
import moment from 'moment'
import { Spin, Modal, Table } from 'antd'
import DocumentTitle from 'react-document-title'
import styles from '../center.less'

const ProductList = ({ productList, dispatch, loading }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'productList/fetch' })
  }, [])

  const { products, viewModalVisible, currentRecord } = productList
  const { productList: isLoading } = loading.models
  let statusIndex = 0;
  const onDownloadClick = url => {
    dispatch({ type: 'productList/download', payload: url })
  }

  const onViewChangeLog = record => {
    dispatch({
      type: 'productList/updateState',
      payload: {
        viewModalVisible: true,
        currentRecord: record,
      },
    })
  }

  const statusShow = status => {
    switch (status) {
      case 'UnReleased':
        return <span>{intl.formatMessage({ id: 'unReleased' })}</span>
      case 'Released':
        return <span>{intl.formatMessage({ id: 'released' })}</span>
      case 'Archive':
        return <span>{intl.formatMessage({ id: 'archive' })}</span>
      default:
        return null
    }
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'productName' }),
      dataIndex: 'Software',
      key: 'Software',
    },
    {
      title: intl.formatMessage({ id: 'version' }),
      dataIndex: 'AppVersion',
      key: 'AppVersion',
    },
    {
      title: intl.formatMessage({ id: 'releaseDate' }),
      dataIndex: 'ReleaseTime',
      key: 'ReleaseTime',
      render: text => (
        <span>{moment(text).isValid() ? moment.utc(text).format('MM/DD/YYYY') : ''}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: 'changeLog' }),
      dataIndex: 'ChangeLog',
      key: 'ChangeLog',
      render: (text, record) => (
        <a onClick={() => onViewChangeLog(record)}>{intl.formatMessage({ id: 'see' })}</a>
      ),
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'Status',
      key: 'Status',
      render: text => statusShow(text),
    },
    {
      title: intl.formatMessage({ id: 'action' }),
      dataIndex: 'Actions',
      key: 'Actions',
      render: (text, record) => (
        <span>
          <a onClick={() => onDownloadClick(record.DownloadLink)}>
            {intl.formatMessage({ id: 'download' })}
          </a>
        </span>
      ),
    },
  ]

  const viewModalProps = {
    title: intl.formatMessage({ id: 'viewChangeLog' }),
    visible: viewModalVisible,
    onCancel() {
      dispatch({ type: 'productList/updateState', payload: { viewModalVisible: false } })
    },
    footer: null,
  }

  const logContent = currentRecord == null ? '' : currentRecord.ChangeLog
  const showChangeLog = logContent => {
    if (logContent === null) {
      return intl.formatMessage({ id: 'emptyData' })
    }
    else {
      return logContent.split('\n').map((item, index) => {
        return <p key={item + index}>{item}</p>
      })
    }
  }

  const isNotShowStatus = products.every(item => item.Status == "UnReleased");
  if (isNotShowStatus) {
    statusIndex = columns.findIndex(item => item.dataIndex == 'Status');
    columns.splice(statusIndex, 1);
  }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'productList.title' })}>
      <React.Fragment>
        <h1 className={styles.pageName}>{intl.formatMessage({ id: 'productList.title' })}</h1>
        <Spin spinning={isLoading}>
          <div className={styles.table}>
            <Table
              rowKey={record => record.Id}
              columns={columns}
              dataSource={products}
              pagination={false}
            />
          </div>
        </Spin>
        <Modal {...viewModalProps}>{showChangeLog(logContent)}</Modal>
      </React.Fragment>
    </DocumentTitle>
  )
}

export default connect(({ productList, loading }) => ({ productList, loading }))(ProductList)
