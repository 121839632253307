import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
} from 'services/rocketService/Prepare'
import { message } from 'antd'


import { PublishStatus, EditSortOrder, PostPostRocketAssessmentList } from 'services/rocketService/Assessment'

import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
const { baseURL, uploadImagesUrl } = config
const uploadUrl = uploadImagesUrl

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '英文名',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '标题',
      FormKey: 'title',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '副标题',
      FormKey: 'subTitle',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: false,
      },
      ShowTitle: '问卷',
      FormKey: 'questionnaireId',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '标签',
      FormKey: 'tagIds',
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: '富文本',
      FormKey: 'content',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: '页头图片',
      FormKey: 'headerImageUrl',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: '首页推荐测试左侧图片',
      FormKey: 'imageUrl',
    },
    {
      EditorType: 'Bool',
      ShowTitle: '是否为专业测评',
      FormKey: 'isPro',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '是否需要付费',
      FormKey: 'isVip',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '价钱',
      FormKey: 'price',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 2,
        },
      },
      Description: "单位:'元'",
    },
    {
      EditorType: 'Bool',
      ShowTitle: '是否需要确认儿童信息',
      FormKey: 'isInfoConfirmNeeded',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '关联证书勋章',
      Description: '关联后，颁发证书才可正常使用',
      FormKey: 'qualificationMedalIds',
    },
    {
      EditorType: 'Bool',
      ShowTitle: 'needConfirm',
      FormKey: 'needConfirm',
      AdditionalData: null,
      Value: true,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '知情同意书',
      FormKey: 'isNeedInformedConsent',
      AdditionalData: null,
      Value: true,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '月龄（始）',
      FormKey: 'start',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: -1,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '月龄（末）',
      FormKey: 'end',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: -1,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: 'showFrontCover',
      FormKey: 'showFrontCover',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: '中文',
            Id: 'zh-Hans',
          },
          {
            Value: '英文',
            Id: 'en',
          },
        ],
        Required: false,
      },
      ShowTitle: '显示语言',
      FormKey: 'language',
      Description: null,
    }
  ],
}

const listSupport = {
  name: {
    showText: '英文名',
    showType: 'Text',
  },
  title: {
    showText: '标题',
    showType: 'Text',
  },
  price: {
    showText: '价钱',
    showType: 'Text',
  },
  hasLanguageFallback: {
    showText: '未翻译版本？',
    showType: 'Bool',
  },
  status: {
    showText: 'status',
    showType: 'Text',
    filter: {
      hasFilter: true,
      filterOptions: [
        { text: 'Public', value: 'Public' },
        { text: 'Hidden', value: 'Hidden' },
        { text: 'Unpublished', value: 'Unpublished' },
        { text: 'Expired', value: 'Expired' },
        { text: 'Trashed', value: 'Trashed' },
      ],
    }
  },
  sortOrder: {
    showText: 'sortOrder',
    showType: 'Text',
  },
}

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

const defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
}

export default {
  namespace: 'assessment',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
    statusVisible: false,
    sortOrderModal: false,
    filters: {},
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, filters } = yield select(state => state.assessment)
      const { status=[] } = filters || {}
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        contentStatuses: status || []
      }

      const res = yield call(PostPostRocketAssessmentList, query )
      const { data, response } = res
      if (response.ok) {
        const { items, totalCount } = data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination, filters={} } = data

      const isActiveFilter = Object.keys(filters).length > 0
      const currentPagination = isActiveFilter ? defaultPagination : pagination

      if (isActiveFilter) {
        yield put({ type: 'updateState', payload: { filters } })
      }

      yield put({ type: 'updateState', payload: { pagination: currentPagination } })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      const res = yield call(PrepareForEdit, { id }, 'assessment')
      const { data, response } = res
      if (response.ok) {
        const { assessment, availableQuestionnaires, availableTags, qualificationMedals } = data
        const { agePeriod } = assessment
        const questionnaireOptions = listToOptions(availableQuestionnaires, 'displayName')
        const medalOptions = listToOptions(qualificationMedals, 'displayName')
        const tagOptions = listToOptions(availableTags, 'displayName')
        let emptyContent = { ...contentMenu }
        emptyContent.Properties[3].Setting.DropdownOptions = questionnaireOptions
        emptyContent.Properties[4].Setting.SelectorOptions = tagOptions
        emptyContent.Properties[12].Setting.SelectorOptions = medalOptions
        const insertValue = {
          ...assessment,
          start: agePeriod.start ? agePeriod.start : -1,
          end: agePeriod.end ? agePeriod.end : -1,
        }

        const editUserInfoForm = matchDynamicForm(emptyContent, insertValue)
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: assessment,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'assessment')
      const { data, response } = res
      if (response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      const res = yield call(PrepareForEdit, {}, 'assessment')
      const { data, response } = res
      if (response.ok) {
        const { availableQuestionnaires, availableTags } = data
        const questionnaireOptions = listToOptions(availableQuestionnaires, 'displayName')
        const tagOptions = listToOptions(availableTags, 'displayName')
        let emptyContent = { ...contentMenu }
        emptyContent.Properties[3].Setting.DropdownOptions = questionnaireOptions
        emptyContent.Properties[4].Setting.SelectorOptions = tagOptions
        yield put({
          type: 'updateState',
          payload: { modalType: 'add', content: emptyContent, modalVisible: true },
        })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.assessment)
      const { qualificationMedalIds, needConfirm, start, end } = data
      if (qualificationMedalIds.length > 0 && !needConfirm) {
        message.error("添加关联证书勋章需要needConfirm开启", 3)
        return
      }
      const tempData = {
        ...data,
        agePeriod: {
          start: start >= 0 ? start : null,
          end: end >= 0 ? end : null
        }
      }
      let res
      if (modalType === 'edit') {
        res = yield call(PutPrepareData, { ...selectItem, ...tempData }, selectItem.id, 'assessment')
      } else {
        res = yield call(PostPrepareData, tempData, 'assessment')
      }
      const { response } = res
      if (response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },

    *changeStatus({ payload: data }, { take, put, call, select }) {
      const { selectItem } = yield select(state => state.assessment)
      const res = yield call(PublishStatus, { id: selectItem.id, action: data })
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { statusVisible: false } })
      }
    },

    *changeSortOrder({ payload: data }, { take, put, call, select }) {
      const { selectItem } = yield select(state => state.assessment)
      const res = yield call(EditSortOrder, selectItem.id, data)
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { sortOrderModal: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/assessment/qa/assessment') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
