import React, { useState, useEffect } from 'react';
import {
  GetCoachConfigRecord,
  GetCoachConfigDetail,
} from 'services/coachTrainingConfig';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { Card, Divider, Space, message } from 'antd';
import { useIntl, connect } from 'umi';

const listSupport = (intl) => {
  return {
    changeTimes: {
      showText: intl.formatMessage({ id: 'Training Sessions' }),
      showType: 'Text',
      render: (text, record) => {
        const { recharge } = record || {};
        return recharge ? `+${text}` : `-${Math.abs(text)}`;
      },
    },
    remark: {
      showText: intl.formatMessage({ id: 'comments' }),
      showType: 'Text',
    },
    creationTime: {
      showText: intl.formatMessage({ id: 'LastSubmissionTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm',
      },
    },
  };
};

const Configuration = ({ currentUser }) => {
  const { id: userId } = currentUser || {};

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [listData, setListData] = useState([]);

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { current, pageSize } = pagination || {};
  const { usedTimes = 0, totalTimes = 0 } = configuration || {};
  const freeTimes = totalTimes - usedTimes;

  const changePageRequest = async (id) => {
    setLoading(true);

    const { id: configurationId } = configuration || {};

    const res = await GetCoachConfigRecord({
      CoachTrainingConfigId: configurationId || id,
      SkipCount: (current - 1) * pageSize,
      MaxResultCount: pageSize,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount || 0,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  const initData = async () => {
    if (configuration) {
      changePageRequest();
    } else {
      const res = await GetCoachConfigDetail({ userId });

      if (res.response.ok) {
        const { id } = res.data || {};
        setConfiguration(res.data);
        changePageRequest(id);
      } else {
        const {
          data: { error },
        } = res;
        message.error(error.message);
      }
    }
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize]);

  const TableProps = {
    loading,
    listData,
    listSupport: listSupport(intl),
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
  };

  return (
    <Card
      title={
        <Space direction="vertical">
          <b style={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'Personal Configuration' })}
          </b>
          <div>{intl.formatMessage({ id: 'Here is the configuration' })}</div>
        </Space>
      }
      bordered={false}
    >
      <Space split={<Divider type="vertical" />} size={6}>
        <span>
          {intl.formatMessage({ id: 'Remaining Sessions' })}:{' '}
          <span style={{ color: freeTimes <= 3 && 'red' }}>{freeTimes}</span>
        </span>
        <span>
          {intl.formatMessage({ id: 'Used Sessions' })}: {usedTimes}
        </span>
        <span>
          {intl.formatMessage({ id: 'Total Sessions' })}: {totalTimes}
        </span>
      </Space>
      <p />
      <MagicTable {...TableProps} />
    </Card>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  Configuration,
);
