import pages from 'utils/pages'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  GetTenantList,
  PostTenantData,
  PutTenantData,
  PrepareForEdit,
  PostSendEmail,
  PostChangeEmail,
  DeleteTenantInfo
} from 'services/tenant'
import {
  PostSendCode,
} from 'services/account'
import { message } from 'antd'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import { getDvaApp } from 'umi'
import { deepCopy } from 'cognitiveleap-core-us/utils/utils';
import { IntlManager } from 'utils/helper';
const { baseURL, uploadTenantPic, uploadTenantLogo } = config
const { centerList } = pages

const emailReg = /^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}
const contentMenu = () => {
  const formatMessage = IntlManager.intl.formatMessage
  return {
     Properties: [
    {
      EditorType: 'Input',
      ShowTitle: formatMessage({ id: 'Name' }),
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
        customValidator: (rule, value, callback) => {
          if (!/^[A-Za-z]+$/.test(value)) {
            callback(formatMessage({ id: 'Limiters' }))
          } else {
            callback()
          }
        }
      }
    },
    {
      EditorType: 'Input',
      ShowTitle: formatMessage({ id: 'Display Name' }),
      FormKey: 'displayName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: false,
      },
      ShowTitle: formatMessage({ id: 'Enterprise' }),
      FormKey: 'parentId',
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          uploadType: 'image',
          isSingle: true,
        },
        Action: baseURL + uploadTenantPic,
        Header: getAuthHeader(),
        Required: true,
      },
      ShowTitle: formatMessage({ id: 'Cover Image' }),
      FormKey: 'picture',
      Description: formatMessage({ id: 'Used for app' }),
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          uploadType: 'image',
          isSingle: true,
        },
        Action: baseURL + uploadTenantLogo,
        Header: getAuthHeader(),
        Required: false,
      },
      ShowTitle: formatMessage({ id: 'Logo' }),
      FormKey: 'logo',
      Description:  formatMessage({ id: 'brand promotion' }),
    },
    {
      EditorType: 'Input',
      ShowTitle: formatMessage({ id: 'Address' }),
      FormKey: 'address',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: formatMessage({ id: 'Admin Email' }),
      FormKey: 'adminEmail',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
        Disabled: false,
        customValidator: (rule, value, callback) => {
          if (!/^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/.test(value)) {
            callback(formatMessage({ id: 'valid email address' }))
          } else {
            callback()
          }
        }
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: formatMessage({ id: 'Admin Phone' }),
      FormKey: 'adminPhone',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: formatMessage({ id: 'Consultation methods' }),
      FormKey: 'consultation',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description:  formatMessage({ id: 'Can fill in address, etc' })
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Effective',
            Id: 'Effective',
          },
          {
            Value: 'Invalid',
            Id: 'Invalid',
          },
        ],
        Required: true,
      },
      ShowTitle: formatMessage({ id: 'State' }),
      FormKey: 'state',
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          // {
          //   Value: 'Medical',
          //   Id: 'Medical',
          // },
          {
            Value:formatMessage({ id: 'Education' }),
            Id: 'Education',
          },
          // {
          //   Value: 'Education_CHN',
          //   Id: 'Education_CHN',
          // },
          {
            Value: formatMessage({ id: 'Education_US' }),
            Id: 'Education_US',
          },
        ],
        Required: true,
      },
      ShowTitle: formatMessage({ id: 'Institution Type' }),
      FormKey: 'institutionType',
      Description:  formatMessage({ id: 'Select the US version' })
    },
    {
        EditorType: 'CheckboxSelector',
        Value: null,
        Setting: {
            DropdownOptions: [
                {
                    Value: formatMessage({ id: 'Parent Edition' }),
                    Id: 'ParentEdition'
                },
                // {
                //     Value: formatMessage({ id: 'Educator Edition' }),
                //     Id: 'EducationEdition'
                // },
                // {
                //   Value: formatMessage({ id: 'Clinician Edition' }),
                //   Id: 'ClinicianEdition'
                // },
                {
                  Value: formatMessage({ id: 'Parent Screen Edition' }),
                  Id: 'ParentScreenEdition'
                }
            ],
            Required: false,
        },
        ShowTitle: formatMessage({ id: 'vCAT Report Type' }),
        FormKey: 'reportKinds',
        Description: formatMessage({ id: 'Please select' }),
    },
    {
      EditorType: 'Bool',
      ShowTitle: formatMessage({ id: 'vCAT QR code' }),
      FormKey: 'vcatqrCode',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: formatMessage({ id: 'vCAT report' }),
    },
    {
      EditorType: 'EditableList',
      ShowTitle: formatMessage({ id: 'Project Status' }),
      FormKey: 'projectStatus',
      AdditionalData: null,
      Value: null,
      Setting: {
        Fixed: true,
        ListElement: {
          Properties: [
            {
              EditorType: 'Input',
              ShowTitle: formatMessage({ id: 'Name' }),
              FormKey: 'name',
              AdditionalData: null,
              Value: '',
              Setting: {
                Required: false,
                Disabled: true,
              },
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: formatMessage({ id: 'Display Name' }),
              FormKey: 'displayName',
              AdditionalData: null,
              Value: '',
              Setting: {
                Required: false,
                Disabled: true,
              },
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: formatMessage({ id: 'status' }),
              FormKey: 'status',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
          ],
        },
      },
      Description: formatMessage({ id: 'Database is a database' }),
    },
    {
      EditorType: 'Dropdown',
      Value: '',
      Setting: {
        DropdownOptions: [
          {
            Value:formatMessage({ id: 'Month' }),
            Id: 'Month',
          },
          {
            Value: formatMessage({ id: 'Recharge' }),
            Id: 'Contract',
          },
        ],
        Required: true,
      },
      ShowTitle: formatMessage({ id: 'HFS Method' }),
      FormKey: 'chargingMethod',
      Description: formatMessage({ id: 'Counting and settlement' }),
    },
    {
      EditorType: 'Bool',
      ShowTitle: formatMessage({ id: 'Is Show Cl Logo' }),
      FormKey: 'isShowClLogo',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: formatMessage({ id: 'Whether the CL Logo' }),
    },
    {
      EditorType: 'Bool',
      ShowTitle: formatMessage({ id: 'Is Show Center Logo' }),
      FormKey: 'isShowCenterLogo',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: formatMessage({ id: 'Whether the Center Logo' }),
    },
    {
      EditorType: 'Bool',
      ShowTitle: formatMessage({ id: 'timesSetting' }),
      FormKey: 'timesSetting',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description:formatMessage({ id: 'Frequency limit' }),
    },
    {
      EditorType: 'Bool',
      ShowTitle:formatMessage({ id: 'Training courses' }),
      FormKey: 'trainingTimesReminder',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: formatMessage({ id: 'Training hours' }),
    },
    {
      EditorType: 'Bool',
      ShowTitle: formatMessage({ id: 'Class class' }),
      FormKey: 'groupTimesReminder',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: formatMessage({ id: 'Accumulated class hours' }),
    }
  ]}
}

let emailContentMenu =  () => {
  const formatMessage = IntlManager.intl.formatMessage
  return { Properties: [
      {
        EditorType: 'Input',
        ShowTitle: formatMessage({ id: 'newEmail' }),
        FormKey: 'newEmail',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          onChange(e) {
            getDvaApp()._store.dispatch({
              type: 'centerList/updateState',
              payload: {
                newEmail: e.target.value
              }
            })
          }
        },
        Description: null,
      },
      {
        EditorType: 'SendCodeInput',
        ShowTitle: formatMessage({ id: 'Verification Code' }),
        FormKey: 'code',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          canSendCode: true,
          onSendCode() {
            getDvaApp()._store.dispatch({
              type: 'centerList/onSendCode',
            })
          },
          onComplete() {
            let content = deepCopy(emailContentMenu)
            content.Properties[1].Setting.canSendCode = true
            getDvaApp()._store.dispatch({
              type: 'centerList/updateState',
              payload: {
                changeEmailContent: content
              }
            })
          }
        },
        Description: null,
      }
  ]}
}

const getContenMenu = (contentMenu, name) => {
const formatMessage = IntlManager.intl.formatMessage
  if(name === 'agent') {
    contentMenu.Properties[2].Setting.IsVisible = false
    contentMenu.Properties[8].Setting.IsVisible = false
    contentMenu.Properties[14].Setting.IsVisible = false
    contentMenu.Properties[17].Setting.IsVisible = false
    contentMenu.Properties[18].Setting.IsVisible = false
    contentMenu.Properties[19].Setting.IsVisible = false
    contentMenu.Properties[15].Description = contentMenu.Properties[15].Description.replace('FAS报告、', '')
    contentMenu.Properties[16].Description = contentMenu.Properties[16].Description.replace('FAS报告、', '')
    contentMenu.Properties[13].Description = formatMessage({ id: 'Perform on/off operations' })
  }
  return contentMenu
}

export default {
  namespace: 'centerList',

  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    newEmail: '',
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
    Filter: null,
    ContentAdminName: null,
    showChangeEmailModal: false,
    currentChangeEmailRecord: null,
    changeEmailContent: null,
    changeEmailIsSendCode: false,
    titleName: '',
  },

  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, Filter, ContentAdminName } = yield select(state => state.centerList)
      let query = { skipCount: 0, maxResultCount: 10, Type: 'Center' }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        Filter,
        ContentAdminName
      }

      const res = yield call(GetTenantList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *changeEmail({ payload }, { call, put, select }) {
      let content = { ...emailContentMenu() }

      yield put({ type: 'updateState', payload: { showChangeEmailModal: true, currentChangeEmailRecord: payload, changeEmailContent: content } })
    },

    *onSendCode({ payload }, { call, put, select }) {
      const { newEmail, currentChangeEmailRecord } = yield select(state => state.centerList)
      const { id } = currentChangeEmailRecord || {}
      if (newEmail) {
        if (emailReg.test(newEmail)) {
          let res = yield call(PostSendCode, { email: newEmail, tenantId: id })
          if (res.response.ok) {
            message.success('发送成功')
            let content = deepCopy(emailContentMenu())
            content.Properties[1].Setting.canSendCode = false
            yield put({ type: 'updateState', payload: { changeEmailContent: content, changeEmailIsSendCode: true } })
          } else {
            const { error } = res && res.data || {}
            let { message: errorInfo } = error || {}
            errorInfo && message.error(errorInfo)
          }
        } else {
          message.error('请输入正确的邮箱')
        }
      } else {
        message.error('请输入邮箱')
      }
    },

    *filterFetch({ payload }, { call, put, select, take }) {
      const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      yield put({ type: 'updateState', payload: { pagination: defaultPagination, ...payload } })
      yield put({ type: 'query' })
    },

    *clearAllFilterAndSorter(_, { call, put, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      const payload = { pagination: defaultPagination, Filter: null, ContentAdminName: null }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      const { currentUser: { adminRoles: [{ name } = {}] = [] } = {} } = yield select(state => state.user)
      const res = yield call(PrepareForEdit, { id, type: 'Center' })
      if (res.response.ok) {
        const { parentTenant, tenant } = res.data
        const { parentTenant: currentParent, chargingMethod, state, institutionType, isActive = false, contentAdminSimples:[{name: names} = {}] = []} = tenant
        const orgOptions = listToOptions(parentTenant, 'displayName')
        let emptyContent = getContenMenu({ ...contentMenu() }, name)
        emptyContent.Properties[2].Setting.DropdownOptions = orgOptions
        emptyContent.Properties[6].Setting.Disabled = isActive
        if (institutionType === 'Medical_Public') {
          emptyContent.Properties[11].Setting.IsVisible = true
          emptyContent.Properties[13].Setting.IsVisible = true
        }
        if (state === "Effective") {
          emptyContent.Properties[14].Setting.Disabled = chargingMethod === "Contract"
        } else {
          emptyContent.Properties[14].Setting.Disabled = false
        }
        const editUserInfoForm = matchDynamicForm(emptyContent, {
          ...tenant,
          parentId: currentParent ? currentParent.id : null,
        })
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: res.data.tenant,
            titleName: names
          },
        })
      } else {
        // console.log(res)
      }
    },

    *onSendEmail({ payload: id }, { call, put, select }) {
      const res = yield call(PostSendEmail, id)
      if (res.response.ok) {
        message.success('发送邮件成功')
        yield put({ type: 'query' })
      } else {
        message.error('发送邮件失败')
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeleteTenantInfo, id)
      if (res.response.ok) {
        message.success('已删除')
        yield put({ type: 'query' })
      } else {
        message.error('删除失败')
      }
    },

    *onAdd(_, { call, put, select }) {
      const res = yield call(PrepareForEdit, { type: 'Center' })
      const { currentUser: { adminRoles: [{ name } = {}] = [] } = {} } = yield select(state => state.user)
      if (res.response.ok) {
        const { parentTenant, tenant } = res.data
        const orgOptions = listToOptions(parentTenant, 'displayName')
        let emptyContent = getContenMenu({ ...contentMenu() }, name)
        emptyContent.Properties[2].Setting.DropdownOptions = orgOptions
        emptyContent.Properties[6].Setting.Disabled = false
        emptyContent.Properties[14].Setting.Disabled = false
        const addForm = matchDynamicForm(emptyContent, { ...tenant })
        yield put({
          type: 'updateState',
          payload: { modalType: 'add', content: addForm, modalVisible: true },
        })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.centerList)
      let res
      if (modalType === 'edit') {
        res = yield call(PutTenantData, { ...selectItem, ...data })
      } else {
        res = yield call(PostTenantData, data, 'Center')
      }
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { modalVisible: false } })
        yield put({ type: 'query' })
      } else {
        const { error } = res.data
        message.error(error.message)
      }
    },

    *changeCenterEmail({ payload }, { take, put, call, select }) {
      const { currentChangeEmailRecord } = yield select(state => state.centerList)
      const { id } = currentChangeEmailRecord || {}
      const { newEmail, code } = payload
      const parmas = {
        tenantId: id,
        email: newEmail,
        code
      }
      const res = yield call(PostChangeEmail, parmas)
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { showChangeEmailModal: false } })
        yield put({ type: 'query' })
      } else {
        const { error } = res.data || {}
        message.error(error && error.message)
      }
    }
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === centerList) {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
