import { Card, List, Pagination, Divider } from 'antd'

const ProjectCards = ({ list, pagination, onEdit, onPageChange, onSort, onCopy }) => {
  const paginationProps = {
    ...pagination,
    onChange: onPageChange,
  }

  const getShowRepeatWord = (dayNum, repeatToFinish)=>{
    if(repeatToFinish===null) return `第${dayNum + 1}天`
    if(repeatToFinish===true) return '每天重复'
    if(repeatToFinish===false) return '一次性'
  }

  return (
    <div>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={list}
        renderItem={item => {
          const { dayNum, programs, programId, repeatToFinish} = item
          return (
            <List.Item>
              <Card
                title={getShowRepeatWord(dayNum,repeatToFinish)}
                extra={
                  <span>
                    <a
                      onClick={() => {
                        onEdit(dayNum, programId, repeatToFinish)
                      }}
                    >
                      Edit
                    </a>
                    <Divider type="vertical" />
                    <a onClick={() => {
                      onSort(dayNum, programs, repeatToFinish)
                    }}>Sort</a>
                    <Divider type="vertical" />
                    <a onClick={()=>{
                      onCopy(dayNum, programId, repeatToFinish)
                    }}>Copy</a>
                  </span>
                }
              >
                {programs.map(programItem => {
                  const { id, title } = programItem
                  return <div key={id}>{title}</div>
                })}
              </Card>
            </List.Item>
          )
        }}
      />
      <Pagination {...paginationProps} />
    </div>
  )
}

export default ProjectCards
