import { message } from 'antd'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { AddOrUpdateB, PrepareForEdit, GetBApi, Release } from 'services/rocketService/BInfo'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
const { baseURL, uploadBAttachment } = config

const listToOptions = (list, show) => {
    return list.map(item => {
        return { Value: item[show], Id: item.id.toString() }
    })
}

let contentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: '版本号',
            FormKey: 'version',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
            },
            Description: '请按照a.b.c 格式填写',
        },
        {
            EditorType: 'RichEditorPro',
            ShowTitle: '摘要',
            FormKey: 'summary',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
            },
            Description: null,
        },
        {
            EditorType: 'Upload',
            Value: null,
            Setting: {
                UploadSetting: {
                    // isSingle: true,
                    uploadType: 'pdf'
                },
                Action: baseURL + uploadBAttachment,
                Header: getAuthHeader(),
                Required: false,
            },
            ShowTitle: '附件',
            FormKey: 'attachment',
            Description: '支持格式PDF',
        },
        {
            EditorType: 'Dropdown',
            Value: true,
            Setting: {
                DropdownOptions: [
                    {
                        Value: '是',
                        Id: true,
                    },
                    {
                        Value: '否',
                        Id: false,
                    },
                ],
                Required: true,
            },
            ShowTitle: '发邮件',
            FormKey: 'send',
            Description: null,
        },
        {
            EditorType: 'Input',
            ShowTitle: '邮件标题',
            FormKey: 'subject',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
                customValidator: (rule, value, callback) => {
                    if(value && value.length > 200) {
                        callback('邮件标题长度应小于200')
                    } else {
                        callback()
                    }
                }
            },
            Description: '最多200个字符',
        },
        {
            EditorType: 'ContentSelector',
            Value: null,
            Setting: {
                SelectorOptions: [],
                Required: false,
            },
            ShowTitle: '发布到中心',
            FormKey: 'tenantIds',
            Description: null,
        },
    ],
}

export default {
    namespace: 'bVersion',
    state: {
        list: [],
        modalVisible: false,
        content: null,
        modalType: 'edit',
        selectItem: null,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        }
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query(_, { take, put, call, select }) {
            const { pagination } = yield select(state => state.bVersion)
            const { pageSize, current } = pagination

            const params = {
                MaxResultCount: pageSize,
                SkipCount: (current - 1) * pageSize
            }

            const res = yield call(GetBApi, params, 'get')

            if (res.response.ok) {
                const { totalCount, items } = res.data
                const newPagination = { ...pagination, total: totalCount }
                yield put({
                    type: 'updateState',
                    payload: { list: items, pagination: newPagination },
                })
            }
        },

        *onEdit({ payload: selectItem }, { call, put, select }) {
            let emptyContent = {...contentMenu}
            const { id } = selectItem
            const res = yield call(PrepareForEdit, { id })
            if (res.response.ok) {
                const { allTenant, webVersion } = res.data
                const tagOptions = listToOptions(allTenant, 'displayName')
                emptyContent.Properties[5].Setting.SelectorOptions = tagOptions
                const editUserInfoForm = matchDynamicForm(emptyContent, webVersion)
                yield put({
                    type: 'updateState',
                    payload: {
                        content: editUserInfoForm,
                        modalType: 'edit',
                        modalVisible: true,
                        selectItem,
                    },
                })
            }
        },

        *onAdd(_, { call, put, select }) {
            let emptyContent = contentMenu
            const res = yield call(PrepareForEdit, { id: null })
            const { data, response } = res
            if (response.ok) {
                const { allTenant } = data
                const tagOptions = listToOptions(allTenant, 'displayName')
                emptyContent.Properties[5].Setting.SelectorOptions = tagOptions
                yield put({
                    type: 'updateState',
                    payload: { modalType: 'add', content: emptyContent, modalVisible: true, selectItem: null },
                })
            }
        },

        *onSubmitModal({ payload: data }, { take, put, call, select }) {
            const { modalType, selectItem } = yield select(state => state.bVersion)
            const id = modalType == 'add' ? null : selectItem.id
            const res = yield call(AddOrUpdateB, { ...data, id }, modalType)
            if (res.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: { modalVisible: false },
                })
                yield put({
                    type: 'query'
                })
            } else {
                const { error } = res.data
                message.error(error.message)
            }
        },

        *changeTable({ payload: data }, { call, put, select }) {
            const { pagination } = data
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'query' })
        },

        *release({ payload: data }, { take, put, call, select }) {
            const { id } = data
            const res = yield call(Release, id)
            if (res.response.ok) {
                message.success('发布成功！')
                yield put({ type: 'query' })
            } else {
                const { error } = res.data
                message.error(error.message)
            }
        }
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/application/b') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
