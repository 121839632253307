import React, { useState, useEffect } from 'react';
import { connect, useIntl } from 'umi'
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Menu, message } from 'antd';
import { PostChildEmotionList } from 'services/pdt';
import { getActiveWeekdays } from 'utils/utils';
import ChildEmotionEcharts from './components/ChildEmotionEcharts';
import moment from 'moment';

import styles from './index.less';

const Emotion = ({ subjectDetail }) => {
    const intl = useIntl()

    const { subjectInfo } = subjectDetail;
    const { id } = subjectInfo;

    const [week, setWeek] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [emotionData, setEmotionData] = useState([]);

    // todo
    let weekAssigned = 1;

    const initData = async () => {
        const res = await PostChildEmotionList({ childId: id })

        if (res.response.ok) {
            setEmotionData(res.data);
        } else {
            if (res.data === '') {
                // console.log('no data');
                setIsEmpty(true);
            } else {
                const { msg } = res.data;
                message.error(msg)
            }
        }
    }

    // 获取最大是哪周
    const sortData = emotionData.sort((a, b) => {
        return b.weekIndex - a.weekIndex
    })

    if (sortData.length) {
        const { weekIndex } = sortData[0];
        weekAssigned = weekIndex
    }

    const currentWeek = week || weekAssigned;

    useEffect(() => {
        initData();
    }, [])

    const handleMenuClick = (e) => {
        const { key } = e;
        setWeek(key);
    };

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={getActiveWeekdays(weekAssigned)}
        />
    );

    const props = {
        data: emotionData.filter(item => item.weekIndex == currentWeek),
    }

    return <div className={styles.emotionPanel}>
        <div className={styles.emotionTitle}>{intl.formatMessage({ id: 'Emotion' })}</div>
        <div className={styles.emotionWeekSelect}>
            <span>{intl.formatMessage({ id: 'Week' })}:&nbsp;&nbsp;</span>
            <Dropdown overlay={menu}>
                <Button>
                    <Space>
                        {currentWeek}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
            &nbsp;
            {/* {moment().format('MM/DD/YYYY')} - {moment().format('MM/DD/YYYY')} */}
        </div>
        <ChildEmotionEcharts {...props} />
    </div>
};

export default connect(({
    subjectDetail,
}) => ({
    subjectDetail,
}))(Emotion);