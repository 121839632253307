import React from 'react';
import { Modal, Radio, Button, Spin, Tooltip } from 'antd'
import styles from '../detail.less'
import { IntlManager } from 'utils/helper'

const planType = {
  'BrainAudio': '哈氏优脑音智训练',
  'SenseAudio': '哈氏智能精准干预计划'
}


const BrainModal = ({ visible, onChange, currentlist, type, handleCancel, nextStep, currentType, spinning }) => {
  const { planKeys } = type || {}
  const intl = IntlManager.MyIntl()

  return (
    <Modal
      title={<b>{intl('scheduleTrainingPlan')}</b>}
      open={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Spin spinning={spinning}>
        <div style={{ marginBottom: '15px', fontWeight: '600' }}>{intl('pleaseSelect')}</div>
        <Radio.Group onChange={onChange} value={currentType}>
          {/* {
                type && type.audioTypes && type.audioTypes.length > 0 &&
                <div>
                  {
                    type.audioTypes.map((item, index) => {
                      return (
                        <Radio key={index} className={styles.radioStyle} value={item}>
                          {planType[item]}
                        </Radio>
                      )
                    })
                  }
                </div>    
              } */}
          {
            planKeys && planKeys.map((item, index) => {
              const { code, displayName, notCreateReason } = item || {}
              {/* const findRes = currentlist.find(current => {
                const { userOfflineTrainingPlan } = current || {};
                const { displayName: currentDisplayName } = userOfflineTrainingPlan || {};
                return displayName === currentDisplayName
              }) */}
              const isDisabled = notCreateReason
              return (
                  <Radio key={index} disabled={isDisabled} className={styles.radioStyle} value={code}>
                      {
                        notCreateReason ? (
                          <Tooltip title={() => {
                            if(notCreateReason === 'RocketSystem:UserPlanWithSameNameExistStarted') {
                              return intl('There is already an active one for this project')
                            }
                            if(notCreateReason === 'RocketSystem:UserPlanWithSameNameExistCreated') {
                              return intl('has already been created')
                            }
                          }}>
                            {displayName}
                          </Tooltip>
                        ) : (
                          <span>{displayName}</span>
                        )
                      }
                  </Radio>
              )
            })
          }
        </Radio.Group>
        <div style={{ textAlign: 'center', marginTop: '30px' }}>
          <Button
            type="primary"
            style={{ width: '200px' }}
            onClick={(e) => { nextStep(e) }}
          >{intl('next')}</Button>
        </div>
      </Spin>
    </Modal>
  )
}

export default BrainModal;
