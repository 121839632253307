import React from 'react';
import { Modal, message } from 'antd'
import styles from './index.less'
import { useIntl } from 'umi'
import copy from 'copy-to-clipboard'

const WeixinQr = ({ visible, url, changedTenant: current, onCancel }) => {
    const intl = useIntl()
    return (
        <Modal
            open={visible}
            footer={null}
            closable={true}
            width={300}
            className={styles.centerQrModal}
            onCancel={onCancel}
        >
            <div><h2>{intl.formatMessage({ id: 'WechatScan' })}</h2></div>
            <div className={styles.centerName}>{current && current.displayName}</div>
            <img src={url} width={200} />
        </Modal>
    )
}

export default WeixinQr