export default {
  namespace: 'trainingBlock',
  state: {
    list: [
      {
        createdDateTime: '2019-01-09 14:00',
        title: '推球专栏',
        founder: 'Lilian',
      },
    ],
    listSupport: {
      createdDateTime: {
        showText: '创建时间',
        showType: 'Text',
      },
      title: {
        showText: '标题',
        showType: 'Text',
      },
      founder: {
        showText: '创建人',
        showType: 'Text',
      },
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    modalVisible: false,
    checkModalVisible: false,
    content: null,
    modalType: 'add',
    editId: null,
    selectItem: null,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *query({ payload: queryData }, { take, put, call, select }) {},

    *onAddTrainingBlock(_, { take, put, call, select }) {
      let AddTrainingBlock = {
        Properties: [
          {
            EditorType: 'Input',
            ShowTitle: '标题',
            FormKey: 'title',
            AdditionalData: null,
            Value: null,
            Setting: {},
            Description: null,
          },
        ],
      }

      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: AddTrainingBlock, modalVisible: true },
      })
    },

    *onEditTrainingBlock(_, { take, put, call, select }) {
      let editTrainingBlock = {
        Properties: [
          {
            EditorType: 'Input',
            ShowTitle: '标题',
            FormKey: 'title',
            AdditionalData: null,
            Value: '推球专栏',
            Setting: {},
            Description: null,
          },
        ],
      }
      yield put({
        type: 'updateState',
        payload: { modalType: 'edit', content: editTrainingBlock, modalVisible: true },
      })
    },

    *onCheckTrainingActivity(_, { take, put, call, select }) {
      let checkTrainingActivity = {
        Properties: [
          {
            EditorType: 'Input',
            ShowTitle: '创建时间',
            FormKey: 'createdDateTime',
            AdditionalData: null,
            Value: '2019-01-09 14: 00',
            Setting: {},
            Description: null,
          },
          {
            EditorType: 'Input',
            ShowTitle: '标题',
            FormKey: 'title',
            AdditionalData: null,
            Value: '推球专栏',
            Setting: {},
            Description: null,
          },
          {
            EditorType: 'Input',
            ShowTitle: '难度',
            FormKey: 'level',
            AdditionalData: null,
            Value: '5',
            Setting: {},
            Description: null,
          },
          {
            EditorType: 'Dropdown',
            ShowTitle: '活动类型',
            FormKey: 'type',
            AdditionalData: null,
            Value: '1',
            Setting: {
              DropdownOptions: [
                {
                  Value: '单人独立完成',
                  Id: '1',
                },
                {
                  Value: '双人合作完成',
                  Id: '2',
                },
                {
                  Value: '三人或以上小组训练',
                  Id: '3',
                },
              ],
            },
            Description: null,
          },
          {
            EditorType: 'Dropdown',
            ShowTitle: '主能力',
            FormKey: 'mainAbility',
            AdditionalData: null,
            Value: '1',
            Setting: {
              DropdownOptions: [
                {
                  Value: '平衡感',
                  Id: '1',
                },
                {
                  Value: '肌肉力量',
                  Id: '2',
                },
                {
                  Value: '听觉专注力',
                  Id: '3',
                },
              ],
            },
            Description: null,
          },
        ],
      }

      yield put({
        type: 'updateState',
        payload: { modalType: 'check', content: checkTrainingActivity, checkModalVisible: true },
      })
    },

    *onDelete(_, { take, put, call, select }) {
      // 专栏内没有内容时才能删除,可能需要加入判断
      console.log('delete')
    },

    *onSubmitModal(_, { take, put, call, select }) {
      const { modalType, editId } = yield select(state => state.trainingBlock)
      if (modalType === 'edit') {
        console.log('edit')
      } else {
        console.log('add')
      }

      yield put({ type: 'query' })
      yield put({ type: 'updateState', payload: { modalVisible: false } })
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/trainingBlock') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
