import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal, Input } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { convertDuration } from 'utils/utils'
const confirm = Modal.confirm
const Search = Input.Search

const modalTitleMenu = {
  edit: '编辑训练音轨',
  add: '新建训练音轨',
}

const Track = ({ track, dispatch }) => {

  useEffect(() => {
    dispatch({ type: 'track/query' })
  }, [])

  const { modalType, content, modalVisible, list, listSupport, pagination, searchInput } = track

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'track/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'track/onSubmitModal', payload: value })
    },
    content,
  }

  const TableProps = {
    listData: list.map(item => {
      return {
        ...item,
        isUpload: item.track ? '是':'否'
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'track/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '更新时长',
        itemRender(record) {
          const { track } = record
          return track ? (
            <a
              onClick={() => {
                const { url } = track
                const audio = new Audio(url)
                audio.load()
                audio.oncanplay = ()=>{
                  dispatch({ type: 'track/updateTrackDuration', payload: {
                    id: record.id,
                    duration: convertDuration(audio.duration)
                  } })
                }
              }}
            >
              更新时长
            </a>
          )
            :
            null
        }
      },
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'track/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'track/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'track/onAdd' })
  }

  return (
    <div>
      <h1>训练音轨</h1>
      <Button onClick={onAddClick}>新建训练音轨</Button>
      <Search
        placeholder="音轨编号"
        onSearch={value => {
          dispatch({ type: 'track/changeTable', payload: { searchInput: value }  })
        }}
        onChange={e =>
          dispatch({
            type: 'track/updateState',
            payload: { searchInput: e.target.value },
          })
        }
        style={{ width: 200 }}
        value={searchInput}
      />
      <Button
        onClick={() =>
          dispatch({ type: 'track/clearAllFilterAndSorter' })
        }
      >
        Clear
      </Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ track }) => ({ track }))(Track)
