import React, { useEffect } from 'react'
import { connect, useIntl } from 'umi'
import { Button, Modal, Input } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import styles from './index.less'

const confirm = Modal.confirm
const { Search } = Input

const modalTitleMenu = {
  edit: '编辑训练器材',
  add: '新建训练器材',
}

const Material = ({ material, dispatch }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'material/query' })
  }, [])

  const { modalType, content, modalVisible, list, listSupport, pagination, filter } = material

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'material/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'material/onSubmitModal', payload: value })
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'material/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'material/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'material/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'material/onAdd' })
  }

  return (
    <div>
      <h1>训练器材</h1>
      <div className={styles.search}>
        <div>
          <Search
            placeholder="请输入名称"
            value={filter}
            onSearch={value => dispatch({ type: 'material/filterFetch', payload: { filter: value } })}
            onChange={(value) => { dispatch({ type: 'material/updateState', payload: { filter: value.target.value } }) }}
            style={{ width: 200 }}
          />
          <Button
            className={styles.searchTop}
            onClick={() => dispatch({ type: 'material/onClear' })}
          >
            {intl.formatMessage({ id: 'clear' })}
          </Button>
        </div>
        <Button type='primary' onClick={onAddClick}>新建训练器材</Button>
      </div>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ material }) => ({ material }))(Material)
