import React, { useEffect } from 'react'
import { connect, useIntl } from 'umi'
import { Card, Divider, Table, Tabs, Pagination } from 'antd';
import { showContractStatus } from 'utils/utils'
import { toMoney } from 'cognitiveleap-core-us/utils/utils'
import styles from './index.less'
import moment from 'moment'

const { TabPane } = Tabs;

const contractColumns = (intl) => [
    { title: intl.formatMessage({ id: 'LastSubmissionTime' }), dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
    { title: intl.formatMessage({ id: 'validity' }), dataIndex: 'validity', key: 'validity', render: text => <span>{text}</span> },
    // { title: intl.formatMessage({ id: 'ReminderThreshold' }), dataIndex: 'reminderAmount', key: 'reminderAmount', render: text => <span>${text}</span> },
    { title: intl.formatMessage({ id: 'AddCreditCharge' }), dataIndex: 'recharge', key: 'recharge', render: text => <span>+${text}</span> },
    { title: intl.formatMessage({ id: 'Consumption' }), dataIndex: 'consumeAmount', key: 'consumeAmount' },
    { title: intl.formatMessage({ id: 'DeactivationTime' }), dataIndex: 'stopTime', key: 'stopTime', render: text => <span>{text ? moment(text).local().format('YYYY/MM/DD HH:mm') : ''}</span> },
    { title: intl.formatMessage({ id: 'status' }), dataIndex: 'showStatus', key: 'showStatus' },
]

const rechargeColumns = (intl) => [
    { title: intl.formatMessage({ id: 'addTime' }), dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
    { title: intl.formatMessage({ id: 'recharge_Charge' }), dataIndex: 'money', key: 'money', render: text => <span>{text > 0 ? `+$${text}` : `-$${Math.abs(text)}`}</span> },
    { title: intl.formatMessage({ id: 'comments' }), dataIndex: 'remark', key: 'remark' }
]

const evaluationColumns = (intl) => [
    { title: intl.formatMessage({ id: 'addTime' }), dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
    { title: intl.formatMessage({ id: 'testName' }), dataIndex: 'resourceName', key: 'resourceName' },
    { title: intl.formatMessage({ id: 'UnitPrice' }), dataIndex: 'price', key: 'price' },
    // { title: '次数', dataIndex: 'input2', key: 'input2' }
]

const trainColumns = (intl) => [
    { title: intl.formatMessage({ id: 'addTime' }), dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
    { title: intl.formatMessage({ id: 'TrainingName' }), dataIndex: 'resourceName', key: 'resourceName' },
    { title: intl.formatMessage({ id: 'type' }), dataIndex: 'type', key: 'type' },
    { title: intl.formatMessage({ id: 'UnitPrice' }), dataIndex: 'price', key: 'price' },
]

const curriculumColumns = (intl) => [
    { title: intl.formatMessage({ id: 'addTime' }), dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
    { title: intl.formatMessage({ id: 'courseName' }), dataIndex: 'resourceName', key: 'resourceName' },
    { title: intl.formatMessage({ id: 'UnitPrice' }), dataIndex: 'price', key: 'price' },
    // { title: '次数', dataIndex: 'input2', key: 'input2' }
]

const Cost = ({ hfsCost, loading, dispatch }) => {

    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'hfsCost/query' })
    }, [])

    const { hfsCostData, expandedRowKeys, pagination } = hfsCost
    const { items, balance, notActive, cumulativeConsumption, cumulativeRecharge, assConsumption, planConsumption, courseConsumption, reminderAmount, oneToManyConsumption } = hfsCostData || {}
    const isLoading = loading.models.hfsCost

    const onExpandedRowsChange = (data) => {
        dispatch({ type: 'hfsCost/updateState', payload: { expandedRowKeys: data } })
    }

    const onExpand = (expanded, record) => {
        dispatch({ type: 'hfsCost/onExpand', payload: { expanded, record } })
    }

    const showcontractColumns = contractColumns(intl)
    return (
        <Card
            bordered={false}
            className={styles.cost}
            title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'HFSFeeOverview' })}</b>}
        >
            <div className={styles.costTop}>
                <div className={styles.costTopItem}>
                    <span className={balance <= reminderAmount ? styles.costTopItemBalance : ''}>{intl.formatMessage({ id: 'Balance' })}：${toMoney(balance)}</span>
                    <Divider type='vertical' />
                    <span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}：${toMoney(cumulativeConsumption)}</span>
                    <Divider type='vertical' />
                    <span>{intl.formatMessage({ id: 'Recharge' })}：${toMoney(cumulativeRecharge)}{notActive > 0 && `（${intl.formatMessage({ id: 'NotActive' })}$${toMoney(notActive)}）`}</span>
                </div>
                <div className={styles.costTopItem}>
                    <span>{intl.formatMessage({ id: 'assessment' })}：</span>
                    <span>{intl.formatMessage({ id: 'ConsumptionThisMonth' })}：${assConsumption && assConsumption.monthConsumption}</span>
                    <Divider type='vertical' />
                    <span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}：${assConsumption && assConsumption.cumulativeConsumption}</span>
                </div>
                <div className={styles.costTopItem}>
                    <span>{intl.formatMessage({ id: 'exercise' })}：</span>
                    <span>{intl.formatMessage({ id: 'ConsumptionThisMonth' })}：${planConsumption && planConsumption.monthConsumption}</span>
                    <Divider type='vertical' />
                    <span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}：${planConsumption && planConsumption.cumulativeConsumption}</span>
                </div>
                <div className={styles.costTopItem}>
                    <span>{intl.formatMessage({ id: 'class' })}：</span>
                    <span>{intl.formatMessage({ id: 'ConsumptionThisMonth' })}：${oneToManyConsumption && oneToManyConsumption.monthConsumption}</span>
                    <Divider type='vertical' />
                    <span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}：${oneToManyConsumption && oneToManyConsumption.cumulativeConsumption}</span>
                </div>
                <div className={styles.costTopItem}>
                    <span>{intl.formatMessage({ id: 'course' })}：</span>
                    <span>{intl.formatMessage({ id: 'ConsumptionThisMonth' })}：${courseConsumption && courseConsumption.monthConsumption}</span>
                    <Divider type='vertical' />
                    <span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}：${courseConsumption && courseConsumption.cumulativeConsumption}</span>
                </div>
            </div>
            {/* <div className={styles.costTop}>
                <table border="1" style={{ margin: '0 auto', textAlign: 'center', borderColor: '#c2c2c2' }}>
                    <tr>
                        <td style={{ padding: '0.3rem' }}><span className={balance <= reminderAmount ? styles.costTopItemBalance : ''}>{intl.formatMessage({ id: 'Balance' })}</span></td>
                        <td>${toMoney(balance)}</td>
                        <td><span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}</span></td>
                        <td>${toMoney(cumulativeConsumption)}</td>
                        <td><span>{intl.formatMessage({ id: 'Recharge' })}</span></td>
                        <td>${toMoney(cumulativeRecharge)}</td>
                        {
                            notActive > 0 && <td><span>${intl.formatMessage({ id: 'NotActive' })}</span></td>
                        }
                        {
                            notActive > 0 && <td><span>${toMoney(notActive)}</span></td>
                        }
                    </tr>
                    <tr>
                        <td><span>{intl.formatMessage({ id: 'assessment' })}</span></td>
                        <td></td>
                        <td><span>{intl.formatMessage({ id: 'ConsumptionThisMonth' })}</span></td>
                        <td>${assConsumption && assConsumption.monthConsumption}</td>
                        <td><span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}</span></td>
                        <td>${assConsumption && assConsumption.cumulativeConsumption}</td>
                    </tr>
                    <tr>
                        <td><span>{intl.formatMessage({ id: 'exercise' })}</span></td>
                        <td></td>
                        <td><span>{intl.formatMessage({ id: 'ConsumptionThisMonth' })}</span></td>
                        <td>${planConsumption && planConsumption.monthConsumption}</td>
                        <td><span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}</span></td>
                        <td><span>${planConsumption && planConsumption.cumulativeConsumption}</span></td>
                    </tr>
                    <tr>
                        <td><span>{intl.formatMessage({ id: 'course' })}</span></td>
                        <td></td>
                        <td><span>{intl.formatMessage({ id: 'ConsumptionThisMonth' })}</span></td>
                        <td>${courseConsumption && courseConsumption.monthConsumption}</td>
                        <td><span>{intl.formatMessage({ id: 'AccumulativeConsumption' })}</span></td>
                        <td><span>${courseConsumption && courseConsumption.cumulativeConsumption}</span></td>
                    </tr>
                </table>
            </div> */}
            <div className={styles.costTable}>
                <Table
                    columns={showcontractColumns}
                    dataSource={items && items.items ? items.items.map(item => {
                        return {
                            ...item,
                            key: item.id,
                            validity: `${moment(item.startTime).local().format('YYYY/MM/DD')}-${moment(item.endTime).local().format('YYYY/MM/DD')}`,
                            showStatus: showContractStatus(item.state, intl),
                            recharge: item.amount + item.consumeAmount
                        }
                    }) : []}
                    expandedRowKeys={expandedRowKeys}
                    onExpandedRowsChange={onExpandedRowsChange}
                    onExpand={(expanded, record) => onExpand(expanded, record)}
                    pagination={false}
                    loading={isLoading}
                    expandable={{
                        expandedRowRender: (record, index) => {
                            return (
                                <Tabs
                                    type="card"
                                >
                                    <TabPane tab={intl.formatMessage({ id: 'Recharge' })} key="recharge">
                                        <Table
                                            columns={rechargeColumns(intl)}
                                            dataSource={record && record.subData && record.subData.records ? record.subData.records.map((item, index) => { return { ...item, key: index } }) : []}
                                            pagination={false}
                                        />
                                    </TabPane>
                                    <TabPane tab={intl.formatMessage({ id: 'assessment' })} key="evaluation">
                                        <Table
                                            columns={evaluationColumns(intl)}
                                            dataSource={record && record.subData && record.subData.resources ? record.subData.resources.filter(item => item.type === 'Assessment').map((item, index) => { return { ...item, key: index, state: record.subData.state } }) : []}
                                            pagination={false}
                                        />
                                    </TabPane>
                                    <TabPane tab={intl.formatMessage({ id: 'exercise' })} key="train">
                                        <Table
                                            columns={trainColumns(intl)}
                                            dataSource={record && record.subData && record.subData.resources ? record.subData.resources.filter(item => item.type === 'OnlinePlanCourse' || item.type === 'OfflinePlanCourse').map((item, index) => { return { ...item, key: index, state: record.subData.state, type: intl.formatMessage({ id: item.type === 'OnlinePlanCourse' ? 'onlineSession' : 'offlineSession' }) } }) : []}
                                            pagination={false}
                                        />
                                    </TabPane>
                                    <TabPane tab={intl.formatMessage({ id: 'course' })} key="curriculum">
                                        <Table
                                            columns={curriculumColumns(intl)}
                                            dataSource={record && record.subData && record.subData.resources ? record.subData.resources.filter(item => item.type === 'Course').map((item, index) => { return { ...item, key: index, state: record.subData.state } }) : []}
                                            pagination={false}
                                        />
                                    </TabPane>
                                    <TabPane tab={intl.formatMessage({ id: 'class' })} key="class">
                                        <Table
                                            columns={trainColumns(intl)}
                                            dataSource={record && record.subData && record.subData.resources ? record.subData.resources.filter(item => item.type.includes('OneTo')).map((item, index) => {
                                                return { ...item, key: index, type: intl.formatMessage({ id: item.type }) }
                                            }) : []}
                                            pagination={false}
                                        />
                                    </TabPane>
                                </Tabs>
                            )
                        }
                    }}
                />
                <div className={styles.costTablePagination}>
                    <Pagination
                        {...pagination}
                        onChange={value => dispatch({ type: 'hfsCost/changePagination', payload: value })}
                    />
                </div>
            </div>
        </Card>
    )
}

export default connect(({ hfsCost, loading }) => ({ hfsCost, loading }))(Cost)