import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
} from 'services/rocketService/Prepare'

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '属性名(type)',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '显示名称',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '描述',
      FormKey: 'description',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '类型',
      FormKey: 'tagType',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
  ],
}

const listSupport = {
  displayName: {
    showText: '显示名称',
    showType: 'Text',
  },
  name: {
    showText: '属性名(英文)',
    showType: 'Text',
  },
  description: {
    showText: '描述',
    showType: 'Text',
  },
  tagType: {
    showText: '类型',
    showType: 'Text',
  },
  hasLanguageFallback: {
    showText: '未翻译版本？',
    showType: 'Bool',
  },
}

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

export default {
  namespace: 'tag',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.tag)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'tag')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      let emptyContent = null,
        insertValue = null
      const res = yield call(GetPrepareEdit, id, 'tag')
      if (res.response.ok) {
        emptyContent = contentMenu
        insertValue = res.data
        const editUserInfoForm = matchDynamicForm(emptyContent, insertValue)
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: insertValue,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'tag')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      let emptyContent = null
      emptyContent = contentMenu
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: emptyContent, modalVisible: true },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.tag)
      let res
      if (modalType === 'edit') {
        res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'tag')
      } else {
        res = yield call(PostPrepareData, data, 'tag')
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/tag') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
