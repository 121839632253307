import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { findQuestionErrorList } from 'utils/utils'
import { Modal } from 'antd'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
} from 'services/rocketService/Prepare'

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '英文名',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '显示名称',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '语言',
      FormKey: 'language',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'JSON字符串',
      FormKey: 'jsonString',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '类型',
      FormKey: 'type',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '总页数',
      FormKey: 'totalPage',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '问卷题目总个数',
      FormKey: 'totalQuestionCount',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '通过率(%)',
      FormKey: 'correctRate',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          max: 100,
          step: 1,
          precision: 0,
        },
      },
      Description: '请输入1-100的整数',
    }
  ],
}

const listSupport = {
  displayName: {
    showText: '显示名称',
    showType: 'Text',
  },
  name: {
    showText: '英文名',
    showType: 'Text',
  },
}

export default {
  namespace: 'adminQuestionnaire',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    keyword: "",
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    editId: '',
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, keyword } = yield select(state => state.adminQuestionnaire)
      let query = { skipCount: 0, maxResultCount: 10, Filter: keyword }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'questionnaire')
      const { data, response } = res
      if (response.ok) {
        const { items, totalCount } = data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      let emptyContent = null,
        insertValue = null
      const res = yield call(GetPrepareEdit, id, 'questionnaire')
      const { data, response } = res
      if (response.ok) {
        emptyContent = contentMenu
        insertValue = data
        const editUserInfoForm = matchDynamicForm(emptyContent, insertValue)
        yield put({
          type: 'updateState',
          payload: { content: editUserInfoForm, modalType: 'edit', modalVisible: true, editId: id },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'questionnaire')
      const { response } = res
      if (response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      let emptyContent = null
      emptyContent = contentMenu
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: emptyContent, modalVisible: true },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { jsonString } = data
      const errList = findQuestionErrorList(jsonString)

      if (errList.length > 0) {
        Modal.error({
          title: '须完善多语言部分',
          width: '700px',
          content: (
            <div>
              <div style={{ margin: "20px 0 20px 0", fontSize: "18px", color: "red" }}>
                正确格式示例：{'"title": { "default" :"32432", "zh-cn":"你好", "en": "Hello" }'}
              </div>
              <div>
                <div style={{ marginBottom: "10px", fontSize: "18px", color: "red" }}>待修改列表：</div>
                {errList.map((item, index) => {
                  return <div key={index}>{index + 1}: {item}</div>
                })}
              </div>
            </div>
          ),
        });

        // return
      }

      const { modalType, editId } = yield select(state => state.adminQuestionnaire)
      let res
      if (modalType === 'edit') {
        res = yield call(PutPrepareData, { ...data, id: editId }, editId, 'questionnaire')
      } else {
        res = yield call(PostPrepareData, data, 'questionnaire')
      }
      const { response } = res
      if (response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/assessment/qa/questionnaire') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
