import {
  GetSleepOfflinePlan
} from 'services/rocketService/TrainingPlan'

export default {
  namespace: 'adminSubjectExercise',

  state: {
    planInfo: null,
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *loadList({ payload: { current = 1, type } }, { all, call, put, take, select }) {
      const { subjectInfo } = yield select(state => state.adminSubjectDetail);
      const { pagination, planInfo } = yield select(state => state.adminSubjectExercise);
      const res = yield call(GetSleepOfflinePlan, { 
          SubjectId: subjectInfo.id, 
          Type: 'Online',
          SkipCount: ( current - 1 ) * ( pagination.pageSize ),
          MaxResultCount: pagination.pageSize
      })

      if (res.response.ok) {
        const totalCount = res.data.totalCount;
        const newPagination = Object.assign({}, pagination, { current, pageSize: pagination.pageSize });
        yield put({
          type: 'updateState',
          payload: { 
            planInfo: planInfo && type == 'load'? { items: planInfo.items.concat(res.data.items), totalCount } : res.data,
            pagination: newPagination
          },
        })
      }
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
      return () => { }
    },
  },
}
