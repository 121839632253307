import React from 'react'
import WysiwygEditor from 'cognitiveleap-core-us/components/MagicForm/WysiwygEditorPro'
// import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { connect } from 'umi'

class EditReport extends React.Component {
  render() {
    return (
      <div>
        <h1>编辑问卷</h1>
        <WysiwygEditor/>
      </div>
    )
  }
}
export default connect(({ editReport }) => ({ editReport }))(EditReport)
