import { List, Card } from 'antd'
import { ExclamationCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'umi'

const DataValidateList = ({ pageType, dataValidation = [] }) => {
  const intl = useIntl()
  if (dataValidation.length === 0) return null

  const LevelToIcon = level => {
    switch (level) {
      case 'Warning':
        return <ExclamationCircleOutlined style={{ fontSize: 16, color: '#ffbf00' }} />
      case 'Accept':
        return <CheckCircleOutlined style={{ fontSize: 16, color: '#00a854' }} />
      case 'Error':
        return <CloseCircleOutlined style={{ fontSize: 16, color: '#f04134' }} />
      default:
        return <CloseCircleOutlined style={{ fontSize: 16, color: '#f04134' }} />
    }
  }

  return (
    <Card style={{ marginTop: 18 }}>
      <h3 style={{ fontSize: 18 }}>{intl.formatMessage({ id: 'dataValidation' })}</h3>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={dataValidation}
        renderItem={item => (
          <List.Item>
            <Card title={<h3 style={{ fontSize: 14 }}>{item.DataType}</h3>}>
              <span>{LevelToIcon(item.Level)}</span>
              {pageType === 'admin' ? (
                <span style={{ marginLeft: 8, fontSize: 14 }}>{item.Message}</span>
              ) : null}
            </Card>
          </List.Item>
        )}
      />
    </Card>
  )
}

export default DataValidateList
