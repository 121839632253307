import React, { useEffect } from 'react'
import { connect, useIntl } from 'umi'
import { Button, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'


const CenterListEnterprise = ({ centerListEnterprise, dispatch }) => {

  const intl = useIntl()

  useEffect(() => {
    dispatch({ type: 'centerListEnterprise/query' })
  }, [])

  const listSupport = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
    },
    displayName: {
      showText: intl.formatMessage({ id: 'centerName' }),
      showType: 'Text',
    },
    chargingMethod: {
      showText: intl.formatMessage({ id: 'chargingMethod' }),
      showType: 'Text',
      render: (text) => {
        if (text === 'Month') {
          return '人工按月'
        } else if (text === 'Contract') {
          return '合同充值'
        } else {
          return '未知'
        }
      }
    },
    // vratStatus:{
    //   showText: 'vratStatus',
    //   showType: 'Bool',
    // },
    // rocketStatus:{
    //   showText: 'rocketStatus',
    //   showType: 'Bool',
    // },
    showStatus: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
    },
    isActive: {
      showText: intl.formatMessage({ id: 'isActive' }),
      showType: 'Bool',
    },
  }

  const { list, pagination } = centerListEnterprise

  const TableProps = {
    listData: list.map(item => {
      const { projectStatus, state } = item
      const vratStatus = projectStatus.some(project => {
        const { name, status } = project
        return name === 'Project.Vrat' && status
      })
      const rocketStatus = projectStatus.some(project => {
        const { name, status } = project
        return name === 'Project.Rocket' && status
      })
      return {
        ...item,
        showStatus: state === 'Effective' ? intl.formatMessage({ id: 'effective' }) : intl.formatMessage({ id: 'inActive' }),
        vratStatus,
        rocketStatus
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'centerListEnterprise/changeTable', payload: data })
    },
  }

  return (
    <div>
      <h1>中心列表</h1>
      <MagicTable {...TableProps} />
    </div>
  )
}

export default connect(({ centerListEnterprise }) => ({ centerListEnterprise }))(CenterListEnterprise)
