import React, { useEffect } from 'react'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import { Card, Drawer, Button, Spin, Collapse, Progress, Tag, Divider, Empty, Space, message } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { resolveSurveyData, getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import PersonalCenterCard from 'components/PersonalCenterCard'
import { connect, history, useIntl, getLocale } from 'umi'
import styles from './index.less'
import lockImgUrl from 'assets/icon_locked.png'
import iconBookImgUrl from 'assets/icon_book.png'
import iconPassGrayImgUrl from 'assets/icon_pass_gray.png'
import passImgUrl from 'assets/pass.png'
import unpassImgUrl from 'assets/unpass.png'
import passEnImgUrl from 'assets/passEn.png'
import unpassEnImgUrl from 'assets/unpassEn.png'
import iconCheckCircle from 'assets/icon_Check-Circle.png'
import iconWarning from 'assets/icon_Warning.png'

const { Panel } = Collapse;

const TrainingStaffDetail = ({ trainingStaffDetail, dispatch, loading, location }) => {

    const intl = useIntl()
    const isZh = getLocale() === 'zh-CN' ? true : false;

    useEffect(() => {
        dispatch({ type: 'trainingStaffDetail/updateState', payload: { moduleList: [], tabkey: '' } })
        dispatch({ type: 'trainingStaffDetail/query', payload: location.query })
        return () => {
            dispatch({ type: 'trainingStaffDetail/clear' })
        }
    }, [])

    const { drawerVisible,
        moduleList,
        tabkey,
        questionData,
        userInfo,
        moduleDetail,
        // medalInfo,
        itemDrawerVisible,
        activeCollapseKey,
        activeContent,
        activeClassInfo,
        showQuestionAnswerData,
        correntRateAndResult,
        showAnswerVisible,
        trainerDetail
    } = trainingStaffDetail
    const { name } = userInfo || {}
    const { finishTime, questionnaireAndAnswer } = questionData || {}
    const { questionnaire, answer } = questionnaireAndAnswer || {}
    const { answerContent } = answer || {}
    const { jsonString, correctRate } = questionnaire || {}

    // const { course } = activeClassInfo || {}
    const { stepIndex = [], contents = [], title, passCount, length } = JSON.parse(JSON.stringify(activeClassInfo)) || {}
    const { id: activeId } = activeContent || {}
    const { data, currentContent } = showQuestionAnswerData || {}
    const { questionnaireAndAnswer: activeQuestionnaireAndAnswer } = data || {}
    const { answer: activeAnswer, questionnaire: activeQuestionnaire } = activeQuestionnaireAndAnswer || {}
    const { questionArr, correntRate, isPass: correntRateAndResultIspass, correntCount } = correntRateAndResult || {}

    // 给stepIndex添加真正得content
    stepIndex.forEach((item, index) => {
        item.content = []
        item.contentId.forEach(id => {
            const content = contents.find(item => item.id === id)
            if (content) item.content.push(content)
        })
    })

    const getAnswerDom = () => {
        const { questionArr = [], } = getCorrentRateAndResult(jsonString, answerContent, correctRate) || {};
        const showSurveyProps = {
            contents: questionArr,
            choice: answer && answer.answerContent ? answer.answerContent : null,
            showIcon: false
        }
        return <ShowSurvey {...showSurveyProps} />
    }

    const personalCenterCardProps = {
        userInfo,
        tabkey,
        tabList: moduleList,
        detail: moduleDetail,
        isFininshed: finishTime,
        loading: loading.models.trainingStaffDetail,
        roleType: 'centerAdmin',
        // medalInfo,
        trainerDetail,
        onClickItem(item) {
            dispatch({ type: 'trainingStaffDetail/onClickItem', payload: item })
        },
        onChangeTabs(key) {
            dispatch({ type: 'trainingStaffDetail/onChangeTabs', payload: key })
            dispatch({ type: 'trainingStaffDetail/getModuleDetail', payload: key })
        },
        showTrainingApplication() {
            if (questionnaireAndAnswer) {
                dispatch({ type: 'trainingStaffDetail/updateState', payload: { drawerVisible: true } })
            } else {
                message.warning('培训申请表未填写')
            }
        },
    }

    const onChangeCollapse = (value) => {
        dispatch({ type: 'trainingStaffDetail/updateState', payload: { activeCollapseKey: value } })
    }

    const renderContent = (item) => {
        const { name, timeConsuming, userCourseDetail, id, isLocked } = item || {}
        const { isFinish, correctRate, isPass, times } = userCourseDetail || {}
        return (
            <div className={styles.renderContent} key={id}>
                <div className={styles.contentTitle}>
                    {
                        !(isFinish && isPass) && activeId && activeId === id && <Tag color="#108ee9">{intl.formatMessage({ id: 'learning' })}</Tag>
                    }
                    <span className={styles.contentTitleContent}>{name}</span>
                </div>
                <div className={styles.renderContentDetail}>
                    {
                        isLocked ?
                            <div className={styles.renderContentDetailLocked}><img src={lockImgUrl} /></div>
                            :
                            <div className={styles.renderContentDetailUnLocked}>
                                <div className={styles.left}>
                                    <img src={iconBookImgUrl} /><span>{intl.formatMessage({ id: isFinish ? 'studyCompleted' : 'unfinished' })}</span>
                                </div>
                                <div className={styles.right}>
                                    {
                                        correctRate !== -1 && userCourseDetail &&
                                        <div className={styles.rightContent}>
                                            <img src={iconPassGrayImgUrl} />
                                            <span>{intl.formatMessage({ id: isPass ? 'pass' : 'failed' })}</span>
                                            <Divider type="vertical" />
                                            <span>{intl.formatMessage({ id: 'accuracyRate' })} {correctRate && `${correctRate}%` || '0%'}</span>
                                            <Divider type="vertical" />
                                            <span>{intl.formatMessage({ id: 'allTimesWereSubmitted' }, { num: times || 0 })}</span>
                                            {
                                                times &&
                                                <React.Fragment>
                                                    <Divider type="vertical" />
                                                    <a onClick={() => dispatch({ type: 'trainingStaffDetail/createQuestionnaire', payload: item })}>{intl.formatMessage({ id: 'viewResult' })}</a>
                                                </React.Fragment>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                    }
                </div>
                <div className={styles.renderContentIcon}>
                    {
                        isFinish && isPass
                            ? <img src={iconCheckCircle} />
                            :
                            activeId && activeId === id ?
                                <img src={iconWarning} />
                                :
                                <span></span>
                    }
                </div>
            </div>
        )
    }

    const showSurveyProps = {
        contents: questionArr,
        choice: activeAnswer && activeAnswer.answerContent ? JSON.parse(JSON.stringify(activeAnswer && activeAnswer.answerContent)) : {}
    }

    return (
        <>
            <div className={styles.trainingStaffDetail}>
                <div className={styles.trainingStaffDetailTitle}>
                    <Button onClick={() => history.goBack()}>{intl.formatMessage({ id: 'back' })}</Button>
                    <b className={styles.title}>{intl.formatMessage({ id: 'xTrainingDetails' }, { name: name || '' })}</b>
                </div>
                <Card
                    bordered={false}
                    className={styles.trainingStaffDetailCard}
                >
                    <div className={styles.trainingStaffDetailContent}>
                        <PersonalCenterCard
                            {...personalCenterCardProps}
                        />
                    </div>
                    <Drawer
                        title={
                            <React.Fragment>
                                {drawerVisible && <b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'trainingApplicationForm' })}</b>}
                                {itemDrawerVisible && (
                                    <div>
                                        <b style={{ fontSize: '20px' }}>{title}</b>
                                        {
                                            contents && contents.length > 0 &&
                                            <div style={{ width: '400px', marginTop: '15px' }}>
                                                <Progress
                                                    percent={length > 0 ? (passCount / length) * 100 : 0}
                                                    format={(percent) => `${passCount}/${length}`}
                                                    strokeColor={length > 0 && passCount === length ? '#3C84FF' : '#F96B4B'}
                                                />
                                            </div>
                                        }
                                    </div>
                                )}
                            </React.Fragment>
                        }
                        open={drawerVisible || itemDrawerVisible}
                        width={700}
                        onClose={() => dispatch({ type: 'trainingStaffDetail/updateState', payload: { drawerVisible: false, itemDrawerVisible: false } })}
                    >
                        {
                            drawerVisible && getAnswerDom()
                        }
                        {
                            itemDrawerVisible &&
                            <React.Fragment>
                                {
                                    stepIndex && stepIndex.length > 0 ?
                                        <Collapse activeKey={activeCollapseKey} onChange={onChangeCollapse}>
                                            {
                                                stepIndex.map(item => {
                                                    const { title, content, index } = item || {}
                                                    return (
                                                        <Panel header={title} key={index}>
                                                            {
                                                                content && content.map(item => {
                                                                    return renderContent(item)
                                                                })
                                                            }
                                                        </Panel>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                        :
                                        contents && contents.length > 0
                                            ?
                                            <div>
                                                {
                                                    contents && contents.map(item => {
                                                        return renderContent(item)
                                                    })
                                                }
                                            </div>
                                            :
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </React.Fragment>
                        }
                    </Drawer>
                    <Drawer
                        open={showAnswerVisible}
                        width={700}
                        onClose={() => dispatch({ type: 'trainingStaffDetail/updateState', payload: { showAnswerVisible: false } })}
                        title={
                            <div className={styles.drawerTitle}>
                                <div><b style={{ fontSize: '20px' }}>{activeQuestionnaire && activeQuestionnaire.displayName}</b></div>
                                <Space split={<Divider type="vertical" />}>
                                    <span>{intl.formatMessage({ id: 'totalQuestions' }, { num: questionArr && questionArr.length || 0 })}</span>
                                    <span>{intl.formatMessage({ id: 'correctQuestions' }, { num:  correntCount || 0})}</span>
                                    <span>{intl.formatMessage({ id: 'worryQuestions' }, { num: (questionArr && questionArr.length - correntCount) || 0 })}</span>
                                    <span>{intl.formatMessage({ id: 'allTimesWereSubmitted' }, { num: currentContent && currentContent.userCourseDetail && currentContent && currentContent.userCourseDetail.times || 0 })}</span>
                                </Space>
                                <div className={isZh ? styles.ispassImg : styles.ispassEnImg}>
                                    {
                                        correntRateAndResultIspass ?
                                            <React.Fragment>
                                                <img src={isZh ? passImgUrl : passEnImgUrl} />
                                                <div className={styles.passRote}>{`${intl.formatMessage({ id: 'accuracyRate' })}${correntRate}%`}</div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <img src={isZh ? unpassImgUrl : unpassEnImgUrl} />
                                                <div className={styles.unpassRote}>{`${intl.formatMessage({ id: 'accuracyRate' })}${correntRate}%`}</div>
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                        }
                    >
                        {showAnswerVisible && <ShowSurvey {...showSurveyProps} />}
                    </Drawer>
                </Card>
            </div>
        </>
    )
}

export default connect(({ trainingStaffDetail, loading }) => ({ trainingStaffDetail, loading }))(TrainingStaffDetail)