import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import {
  PostPrepareData,
  GetPrepareList,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
  PutTrainingPlanTemplate
} from 'services/rocketService/Prepare'

const { baseURL, uploadImagesUrl } = config

const uploadUrl = uploadImagesUrl

let contentMenu = {
  Properties: [
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
        Required: true,
      },
      ShowTitle: 'image',
      FormKey: 'image',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'displayName',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: 'isVip',
      FormKey: 'isVip',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: 'price',
      FormKey: 'price',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 2,
        },
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: 'RMB',
      Setting: {
        DropdownOptions: [
          {
            Value: 'RMB',
            Id: 'RMB',
          },
        ],
        Required: false,
      },
      ShowTitle: 'currencyType',
      FormKey: 'currencyType',
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: 'termOfService',
      FormKey: 'termOfService',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: 'html',
      FormKey: 'html',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'h5',
      FormKey: 'h5',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: '如果用WebView，WebView地址',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: 'totalDays',
      FormKey: 'totalDays',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 1,
          step: 1,
          precision: 0,
        },
      },
      Description: '总天数',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: 'postAssMin',
      FormKey: 'postAssMin',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: '开启后测至少天数',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（始）',
      FormKey: 'ageStart',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（末）',
      FormKey: 'ageEnd',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: 'feedbackProperties',
      FormKey: 'feedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'extra',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '前测',
      FormKey: 'preAssessmentIds',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '后测',
      FormKey: 'postAssessmentIds',
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: 'picture',
      FormKey: 'picture',
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Online',
            Id: 'Online',
          },
          {
            Value: 'OffLine',
            Id: 'OffLine',
          },
          {
            Value: 'Sleep',
            Id: 'Sleep',
          },
          {
            Value: 'Nutrition',
            Id: 'Nutrition',
          },
          {
            Value: 'OneToManyOnline',
            Id: 'OneToManyOnline',
          },
        ],
        Required: false,
      },
      ShowTitle: 'type',
      FormKey: 'type',
      Description: null,
    },
  ],
}

const remarkContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'description',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
  ],
}

const listToOptions = (list, showAttributeArr) => {
  return list.map(item => {
    const Value = showAttributeArr.map(attribute => `${item[attribute]} `)
    return { Value, Id: item.id.toString() }
  })
}

const showDescription = availableTypes => {
  let showWords = ''
  availableTypes.forEach(typeItem => {
    const { constraints } = typeItem
    let showAvailableConstraints = ''
    constraints.forEach(item => {
      showAvailableConstraints += item.name + ';'
    })
    showWords += 'Type: ' + typeItem.name + ' AvailableKeys: ' + showAvailableConstraints + '\r'
  })
  return showWords
}

export default {
  namespace: 'adminTrainingPlan',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    availableFeedbackProperties: [],
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.adminTrainingPlan)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'trainingPlan')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      const res = yield call(PrepareForEdit, { id }, 'trainingPlan')
      if (res.response.ok) {
        const {
          availableAssessments,
          availableFeedbackProperties,
          trainingPlanTemplate,
        } = res.data
        let emptyContent = {...contentMenu}
        emptyContent.Properties[12].Setting.ListElement.Properties[0].Setting.DropdownOptions = listToOptions(
          availableFeedbackProperties,
          ['displayName', 'name', 'type']
        )
        emptyContent.Properties[13].Setting.SelectorOptions = listToOptions(
          availableAssessments,
          ['name']
        )
        emptyContent.Properties[14].Setting.SelectorOptions = listToOptions(
          availableAssessments,
          ['name']
        )
        const {
          agePeriod,
          feedbackProperties,
          postAssessments,
          preAssessments,
        } = trainingPlanTemplate
        const { start, end } = agePeriod || {}

        const feedbacks = feedbackProperties.map(item => {
          const { extraProperties } = item
          const extra = JSON.stringify(extraProperties)
          return {
            ...item,
            feedbackPropertyId: item.id,
            extra,
          }
        })

        const editUserInfoForm = matchDynamicForm(emptyContent, {
          ...trainingPlanTemplate,
          ageStart: start || 0,
          ageEnd: end || 0,
          feedbackProperties: feedbacks,
          preAssessmentIds: preAssessments.map(item => item.id),
          postAssessmentIds: postAssessments.map(item => item.id),
        })
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: trainingPlanTemplate,
            availableFeedbackProperties,
          },
        })
      }
    },

    *onEditRemark({ payload }, { call, put, select }) {
      const { description } = payload
      const showForm = matchDynamicForm(remarkContentMenu, { description })
      yield put({
        type: 'updateState',
        payload: {
          content: showForm,
          modalType: 'remark',
          modalVisible: true,
          selectItem: payload,
        },
      })
    },


    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'trainingPlan')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      const res = yield call(PrepareForEdit, {}, 'trainingPlan')
      if (res.response.ok) {
        const { availableAssessments, availableFeedbackProperties,} = res.data
        let emptyContent = {...contentMenu}
        emptyContent.Properties[12].Setting.ListElement.Properties[0].Setting.DropdownOptions = listToOptions(
          availableFeedbackProperties,
          ['displayName', 'name', 'type']
        )
        emptyContent.Properties[13].Setting.SelectorOptions = listToOptions(
          availableAssessments,
          ['name']
        )
        emptyContent.Properties[14].Setting.SelectorOptions = listToOptions(
          availableAssessments,
          ['name']
        )
        yield put({
          type: 'updateState',
          payload: {
            modalType: 'add',
            content: emptyContent,
            modalVisible: true,
            availableFeedbackProperties,
          },
        })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.adminTrainingPlan)
      let res
      if (modalType === 'remark') {
        res = yield call(PutTrainingPlanTemplate, { id: selectItem.id, ...data })
      } else {
        if (modalType === 'edit') {
          res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'trainingPlan')
        } else {
          res = yield call(PostPrepareData, data, 'trainingPlan')
        }
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/trainingPlan') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
