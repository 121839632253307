import React, { useEffect, useState } from 'react'
import { connect, useIntl, getLocale } from 'umi'
import { Anchor, Layout, Spin, Input, BackTop } from 'antd'
import LocaleSelection from 'cognitiveleap-core-us/components/LocaleSelection'
import { showMenu, hideMenu, ContextMenu } from 'utils/customMenu.js';
import styles from './Report.less'
import BasicInfo from './BasicInfo'
import Overview from './Overview'
import AttentionIndex from './AttentionIndex'
import SixDimension from './SixDimension'
import ContinuousAttention from './ContinuousAttention'
import MotionIndex from './MotionIndex'
import ReportDes from './ReportDes'
import MovementPath from './MovementPath'
import PathLength from './PathLength'
import DirectionTracking from './DirectionTracking'
import MotionCharacteristics from './MotionCharacteristics'
import RealTimePerformance from './RealTimePerformance'
import TotalRes from './TotalRes'
import ScanQrToPay from './ScanQrToPay'

import overviewImg from 'assets/overviewDes.svg'
import attentionSkillImg from 'assets/attentionSkillDes.svg'
import SendReportModal from '../../../components/SendReportModal'
const { Link } = Anchor
const { Content } = Layout

const LinkList = [
  {
    key: 'basic',
    title: 'Basic Info',
    chineseTitle: '基本信息',
  },
  {
    key: 'overview',
    title: 'Overview',
    chineseTitle: '总体表现',
    children: [
      {
        key: 'realTime',
        title: 'Real Time Performance',
        chineseTitle: '实时表现',
      },
    ],
  },
  {
    key: 'attentionIndex',
    title: 'Attention Index',
    chineseTitle: '注意力指数',
    children: [
      {
        key: 'attentionSkill',
        title: 'Attention Skills',
        chineseTitle: '注意力维度',
      },
      {
        key: 'continuous',
        title: 'Continuous Attention',
        chineseTitle: '持续注意力',
      },
    ],
  },
  {
    key: 'motionIndex',
    title: 'Motion Index',
    chineseTitle: '动作指数',
    children: [
      {
        key: 'movement',
        title: 'Movement Path',
        chineseTitle: '路径图',
      },
      {
        key: 'path',
        title: 'Path Length',
        chineseTitle: '路径长度',
      },
      {
        key: 'direction',
        title: 'Direction Tracking',
        chineseTitle: '注视方向追踪',
      },
      {
        key: 'characteristic',
        title: 'Motion Characteristics',
        chineseTitle: '动作特点',
      },
    ],
  },
  {
    key: 'summary',
    title: 'Summary',
    chineseTitle: '测评总结',
  },
]

const Report = ({ report2, dispatch, location, loading }) => {

const { suspension, isAdmin } = location.query || {};
useEffect(() => {
  dispatch({ type: 'report2/fetch', payload: location.query });
  // 初始先禁用右键菜单功能，等页面加载完毕会重写右键菜单功能
  if (suspension) {
    document.addEventListener('contextmenu', (event) => event.preventDefault());
    // 禁用浏览器快捷键打印功能
    window.addEventListener('keydown', function (e) {
      if (e.ctrlKey && (e.key === 'p' || e.key === 'P')) {
        e.preventDefault();
        dispatch({
          type: 'report2/downloadReport',
        })
      }
    });
  }
}, []);

  const intl = useIntl()

  //递归获取边栏
  const getLinks = linkList => {
    return linkList.map(item => {
      const title = (
        <div>
          <div className={styles.anchorlink}>{item.title}</div>
          <div className={styles.anchorlink}>{item.chineseTitle}</div>
        </div>
      )

      if (item.children) {
        return (
          <Link key={item.key} href={`#${item.key}`} title={title}>
            {getLinks(item.children)}
          </Link>
        )
      }

      return <Link key={item.key} href={`#${item.key}`} title={title} />
    })
  }


  const {
    reportVersion,
    basicInfo,
    lineWithIcon,
    continuousAttention,
    directionTracking,
    motionCharacteristics,
    movementPath,
    overView,
    pathLength,
    sixDimension,
    tenantInfo,
    sendReportModalVisible,
    qrCodeData
  } = report2

  const {
    basicLoading,
    overViewLoading,
    lineWithIconLoading,
    sixDimensionLoading,
    continuousAttentionLoading,
    movementPathLoading,
    pathLengthLoading,
    directionTrackingLoading,
    motionCharacteristicsLoading,
    qrCodeLoading
  } = report2

  const { institutionType, showBuyQrCode } = tenantInfo || {}

  // // 美服；系统语言：中文（英文版保持不变）
  const showNewReport = institutionType === 'Education_US' && getLocale() === 'zh-CN'

  const attention = sixDimension ? sixDimension.Attention : 0
  const selfControl = sixDimension ? sixDimension.SelfControl : 0
  const reactivity = sixDimension ? sixDimension.Reactivity : 0
  const reactionStability = sixDimension ? sixDimension.ReactionStability : 0
  const sensitivity = sixDimension ? sixDimension.Sensitivity : 0
  const continuous_Attention = sixDimension ? sixDimension.ContinuousAttention : 0

  const headPathLength = directionTracking ? directionTracking.Percentile : 0

  const hyperactivity = motionCharacteristics ? motionCharacteristics.Hyperactivity : 0

  const Links = getLinks(LinkList)

  const basicInfoProps = {
    data: basicInfo,
    reportVersion,
    tenantInfo,
    showNewReport
  }

  const summaryProps = {
    data: {
      Attention: attention,
      SelfControl: selfControl,
      Reactivity: reactivity,
      ReactionStability: reactionStability,
      ContinuousAttention: continuous_Attention,
      Sensitivity: sensitivity,
      HeadPathLength: headPathLength,
      Hyperactivity: hyperactivity,
    },
  }

  const isComplete = !(
    basicLoading ||
    overViewLoading ||
    lineWithIconLoading ||
    sixDimensionLoading ||
    continuousAttentionLoading ||
    movementPathLoading ||
    pathLengthLoading ||
    directionTrackingLoading ||
    motionCharacteristicsLoading ||
    qrCodeLoading
  )

  const isSendReportLoading = loading.effects['report2/sendReport']
  // (
  // 				<Sider width={260} className={styles.sider}>
  // 				<Anchor className={styles.anchor} offsetTop={50}>
  // 					{Links}
  // 				</Anchor>
  // 			</Sider>
  // 	)

  //temp hide summary page
  // (<Summary {...summaryProps}/>)

// 发送报告
const SendReportModalProps = {
  visible: sendReportModalVisible,
  loading: isSendReportLoading,
  onCancel() {
    dispatch({ type: 'report2/updateState', payload: { sendReportModalVisible: false } })
  },
  onOk(data) {
    dispatch({ type: 'report2/sendReport', payload: data })
  },
}


const onSendReport = () => {
  dispatch({ type: 'report2/updateState', payload: { sendReportModalVisible: true } })
}

  // 下载pdf
  const downloadPdf = () => {
    dispatch({
      type: 'report2/downloadReport',
    })
  }

  const initCustomMenu = () => {
    const contextMenu = ContextMenu({
      menus: [
        {
          name: intl.formatMessage({ id: 'downLoadReport' }),
          onClick: () => downloadPdf(),
        },
        isAdmin === 'false' ? {
          name: intl.formatMessage({ id: 'sendReport' }),
          onClick: () => onSendReport(),
        } : null,
        {
          name: intl.formatMessage({ id: 'selectLanguage' }),
          children: [
            {
              name: '简体中文',
              active: getLocale() === 'zh-CN',
              onClick: () => {
                dispatch({ type: 'global/changeLang', payload: 'zh-CN' });
              },
              className: getLocale() === 'zh-CN' ? 'chineseStyle' : '_',
            },
            {
              name: 'English',
              active: getLocale() === 'en-US',
              onClick: () => {
                dispatch({ type: 'global/changeLang', payload: 'en-US' });
              },
              className: getLocale() === 'en-US' ? 'englishStyle' : '_',
            },
            {
              name: 'Español',
              active: getLocale() === 'es-ES',
              onClick: () => {
                dispatch({ type: 'global/changeLang', payload: 'es-ES' });
              },
              className: getLocale() === 'es-ES' ? 'englishStyle' : '_',
            },
          ],
        },
        {
          name: intl.formatMessage({ id: 'backTop' }),
          onClick: () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
        },
      ].filter((item) => item),
    });
    document.addEventListener('contextmenu', (event) =>
      showMenu(event, contextMenu),
    );
    document.addEventListener('click', (event) => hideMenu(event, contextMenu));
  };

  useEffect (() => {
    if(suspension) {
      initCustomMenu()
    }
  }, [])

  return (
    <Layout className={styles.reportwrapper}>
      {suspension && (
        <>
          <div className={styles.suspension}>
            <ul>
              <li className={styles.downLoadStyle} onClick={downloadPdf}></li>
             { isAdmin === 'false' ? <li
                className={styles.sendReportStyle}
                onClick={onSendReport}
              ></li> : null}
              <li className={styles.selectStyle} id="select">
                <LocaleSelection
                  changeLanguage={(key) =>
                    dispatch({ type: 'global/changeLang', payload: key })
                  }
                  dropDownProps={{
                    getPopupContainer: () => {
                      return document.getElementById('select');
                    },
                    overlayStyle: { width: 80 },
                  }}
                  styles={{width: '64px', height: '64px'}}
                />
              </li>
            </ul>
          </div>
          <BackTop visibilityHeight={900}>
            <div className={styles.backUpStyle}></div>
          </BackTop>
        </>
      )}
      <SendReportModal {...SendReportModalProps} />
      <Content className={styles.content}>
        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={basicLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <BasicInfo {...basicInfoProps} />
          </div>
        </Spin>

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={overViewLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <Overview data={overView} />
          </div>
        </Spin>

        {/* {showNewReport && <div className={styles.pageWrapper}>
          <ReportDes title='总体表现分析' imgUrl={overviewImg} pageNumber={3} />
        </div>} */}

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={lineWithIconLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <RealTimePerformance
              data={lineWithIcon}
              showNewReport={showNewReport}
            />
          </div>
        </Spin>

        {/* <div className={styles.pageWrapper}>
            <AttentionIndex />
          </div> */}

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={sixDimensionLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <SixDimension data={sixDimension} showNewReport={showNewReport} />
          </div>
        </Spin>

        {/* {showNewReport && <div className={styles.pageWrapper}>
          <ReportDes title='注意力维度分析' imgUrl={attentionSkillImg} pageNumber={5} />
        </div>} */}

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={continuousAttentionLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <ContinuousAttention
              data={continuousAttention}
              showNewReport={showNewReport}
            />
          </div>
        </Spin>

        <div className={styles.pageWrapper}>
          <MotionIndex showNewReport={showNewReport} />
        </div>

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={movementPathLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <MovementPath data={movementPath} showNewReport={showNewReport} />
          </div>
        </Spin>

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={pathLengthLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <PathLength data={pathLength} showNewReport={showNewReport} />
          </div>
        </Spin>

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={directionTrackingLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <DirectionTracking
              data={directionTracking}
              showNewReport={showNewReport}
            />
          </div>
        </Spin>

        <Spin
          size="large"
          tip={`${intl.formatMessage({ id: 'loading' })}...`}
          spinning={motionCharacteristicsLoading}
          className={styles.spinnerWrapperText}
        >
          <div className={styles.pageWrapper}>
            <MotionCharacteristics
              data={motionCharacteristics}
              showNewReport={showNewReport}
            />
          </div>
        </Spin>

        {showNewReport && (
          <Spin
            size="large"
            tip={`${intl.formatMessage({ id: 'loading' })}...`}
            spinning={sixDimensionLoading || overViewLoading}
            className={styles.spinnerWrapperText}
          >
            <div className={styles.pageWrapper}>
              <TotalRes overView={overView} sixDimension={sixDimension} />
            </div>
          </Spin>
        )}

        {showBuyQrCode && (
          <Spin
            size="large"
            tip={`${intl.formatMessage({ id: 'loading' })}...`}
            spinning={qrCodeLoading}
            className={styles.spinnerWrapperText}
          >
            <div className={styles.pageWrapper}>
              <ScanQrToPay qrCodeData={qrCodeData} />
            </div>
          </Spin>
        )}

        {/* give feedback to the back end */}
        {isComplete ? <div id={'report ready'} key={'report ready'} /> : null}
      </Content>
    </Layout>
  );
}

export default connect(({ report2, loading }) => ({ report2, loading }))(Report)