import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑企业',
  add: '新建企业',
}

const listSupport = {
  creationTime: {
    showText: 'creationTime',
    showType: 'Time',
  },
  displayName: {
    showText: 'displayName',
    showType: 'Text',
  },
  adminEmail: {
    showText: 'adminEmail',
    showType: 'Text',
  },
  childCount: {
    showText: 'centerCount',
    showType: 'Text',
  },
  region: {
    showText: 'region',
    showType: 'Text',
  },
  state: {
    showText: 'state',
    showType: 'Text',
  },
  isActive: {
    showText: 'isActive',
    showType: 'Bool',
  },
  isSend: {
    showText: 'isSend',
    showType: 'Bool',
  },
}

const OrgList = ({ orgList, dispatch }) => {

  useEffect(() => {
    dispatch({ type: 'orgList/query' })
  }, [])

  const { modalType, content, modalVisible, list, pagination } = orgList

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'orgList/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'orgList/onSubmitModal', payload: value })
    },
    content,
  }

  const showActive = (isActive, isSend) => {
    if (isActive && isSend) return null
    if (isSend) return '重新发送'
    else {
      return '发送激活邮件'
    }
  }

  const TableProps = {
    listData: list.map(item => {
      return {
        ...item,
        region: item.parentTenant&&item.parentTenant.displayName
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'orgList/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          //console.log(record)
          dispatch({ type: 'orgList/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              // dispatch({ type: 'orgList/onDelete', payload: record.id })
            },
          })
        },
      },
      {
        showText: '发送激活邮件',
        itemRender(record) {
          const { isSend, isActive } = record
          const showWord = showActive(isActive, isSend)
          return showWord == null ? null : (
            <span key="sendEmail">
              <a
                onClick={() => {
                  dispatch({ type: 'orgList/onSendEmail', payload: record.id })
                }}
              >
                {showWord}
              </a>
            </span>
          )
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'orgList/onAdd' })
  }

  return (
    <div>
      <h1>企业管理</h1>
      <Button onClick={onAddClick}>新建企业</Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ orgList }) => ({ orgList }))(OrgList)
