import React from 'react';
import { useIntl } from 'umi';
import styles from './Report.less';
import PoweredBy from 'components/VratChartComponents/poweredBy';

const ScanQrToPay = ({ qrCodeData }) => {
  const intl = useIntl();
  const blob = new Blob([qrCodeData], { type: 'image/png' });
  const url = URL.createObjectURL(blob);

  return (
    <div className={`${styles.page_div} ${styles.payBg}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>
          {intl.formatMessage({ id: 'report.scanQrTips' })}
          <p />
          {intl.formatMessage({ id: 'report.scanQrTips2' })}
        </p>
      </h2>
      <div className={styles.ScanQrToPayContent}>
        <div className={styles.imgBox}>
          {qrCodeData && url && <img src={url} />}
        </div>
        <div className={styles.box}></div>
      </div>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>11</p>
    </div>
  );
};

export default ScanQrToPay;
