import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
  PutTrackDuration
} from 'services/rocketService/Prepare'

import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import { message } from 'antd'
const { baseURL, uploadTrackAudio } = config

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '音轨编号',
      FormKey: 'trackCode',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
        EditorType: 'TimePicker',
        ShowTitle: 'duration',
        FormKey: 'duration',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: null,
      },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          uploadType: 'audio',
          isSingle: true,
        },
        Action: baseURL + uploadTrackAudio,
        Header: getAuthHeader(),
      },
      ShowTitle: '音轨',
      FormKey: 'track',
    },
  ],
}

const listSupport = {
  trackCode: {
    showText: '音轨编号',
    showType: 'Text',
  },
  duration: {
    showText: '时长',
    showType: 'Text',
  },
  isUpload: {
    showText: '是否已上传',
    showType: 'Text',
  }
}

export default {
  namespace: 'track',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
    searchInput: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, searchInput } = yield select(state => state.track)
      let query = { skipCount: 0, maxResultCount: 10, Filter: searchInput }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'track')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.track)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      let emptyContent = null,
        insertValue = null
      const res = yield call(GetPrepareEdit, id, 'track')
      if (res.response.ok) {
        emptyContent = contentMenu
        insertValue = res.data
        const editUserInfoForm = matchDynamicForm(emptyContent, insertValue)
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: res.data,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'track')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      let emptyContent = null
      emptyContent = contentMenu
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: emptyContent, modalVisible: true },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.track)
      let res
      if (modalType === 'edit') {
        res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'track')
      } else {
        res = yield call(PostPrepareData, data, 'track')
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },

    *updateTrackDuration({ payload: data }, { take, put, call, select }) {
      const res = yield call(PutTrackDuration, data)
      if(res.response.ok){
        message.success('更新成功')
        yield put({ type: 'query' })
      }
    },

    *clearAllFilterAndSorter(_, { call, put, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      const payload = { pagination: defaultPagination, searchInput: '' }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'query' })
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/programPieces/track') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
