import { connect } from 'umi'
import { getBlobData } from 'cognitiveleap-core-us/utils/utils'
import ShowPdf from 'components/ShowPdf'
import style from './index.less';
import React from 'react';
class Reviewfile extends React.Component {
    constructor(props) {
        super(props)
        const url = localStorage.getItem('reviewfileUrl')
        const isPdf = /.pdf$/.test(url)
        this.outDiv = React.createRef()
        let initState = {}
        if (isPdf) {
            initState = {
                url,
                resultUrl: url,
                isPdf,
            }
        } else {
            initState = {
                url,
                resultUrl: url,
                isPdf
            }
        }
        this.state = initState
    }

    componentDidMount() {
        // const { isPdf, url } = this.state
        // const that = this;
        // if (!isPdf) {
        //     getBlobData(url, (data) => {
        //         that.setState({
        //             resultUrl: data
        //         })
        //     })
        // }

        if (this.outDiv) this.outDiv.current.addEventListener('contextmenu', (e) => e.preventDefault())
    }

    render() {
        const { resultUrl, isPdf, url } = this.state;
        const showPdfProps = {
            url,
            scale: 1.3,
            isPdf: true,
            isShowLeftRight: true
        }
        return (
            <div className={isPdf ? style.outDivPdf : style.outDiv} ref={this.outDiv}>
                {
                    isPdf ?
                        <ShowPdf {...showPdfProps} />
                        :
                        <embed src={resultUrl} className={style.preivewfile} />
                }
            </div>
        )
    }
}

export default Reviewfile;