import React from 'react';
import { Modal, message } from 'antd'
import styles from './index.less'
import copy from 'copy-to-clipboard'
import { useIntl } from 'umi'
import { DownloadOutlined } from '@ant-design/icons';

const CenterQr = ({ visible, url, changedTenant: current, downloadCenterQr, onCancel }) => {

    const intl = useIntl()

    return (
        <Modal
            open={visible}
            footer={null}
            closable={true}
            width={300}
            className={styles.centerQrModal}
            onCancel={onCancel}
        >
            <div className={styles.centerName}>{current && current.displayName}</div>
            <img src={url} width={200} />
            <div
                onContextMenu={(e) => {
                    e.preventDefault();
                    copy(current && current.qrNum) && message.success(intl.formatMessage({ id: 'copyed' }))
                }}>
                {intl.formatMessage({ id: 'centerCode' })}：{current && current.qrNum}
            </div>
            <div
                className={styles.downloadCenterQr}
                onClick={() => { downloadCenterQr() }}
            >
                {intl.formatMessage({ id: 'saveImage' })}&nbsp;<DownloadOutlined />
            </div>
        </Modal>
    )
}

export default CenterQr