import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { connect, useIntl } from 'umi';
import moment from 'moment';
import { RedoOutlined } from '@ant-design/icons';

import config from 'utils/config';
const { mobileUrl } = config;
import AssessmentQrCode from 'components/AssessmentQrCode';
import {
  getToken,
  getTenantId,
  getUserTenantId,
} from 'cognitiveleap-core-us/utils/auth';
import { PostCustomPlan } from 'services/rocketService/Assessment';

import styles from '../detail.less';

const StepTwo = ({
  subjectAssessment,
  dispatch,
  currentUser,
  reloadPage,
  activePlan,
  loading,
}) => {
  const intl = useIntl();

  const [customBtnLoading, setCustomBtnLoading] = useState(false);

  const {
    assessmentQrUrlModalVisible,
    assessmentQrUrl,
    assessmentQrData
  } = subjectAssessment;

  const {
    assessmentStatus = [],
    displayName,
    id: userOfflineTrainingPlanId,
  } = activePlan || {};
  const preAssessmentData = assessmentStatus.filter(
    (item) => item.assessmentType == 'PreAssessment',
  );

  if (preAssessmentData.length == 0) return null;

  const { userAssessment, assessment, isFinished, userAssessmentId } =
    preAssessmentData[0] || {};
  const { status, needPay, creationTime } = userAssessment || {};
  const { title, id: assessmentId } = assessment || {};

  const record = {
    ...activePlan,
    assessmentId,
    assessmentName: title,
    id: userAssessmentId,
  };

  const canClick = status == 'ReportedReady' && !needPay && isFinished;

  const editAssessment = (type) => {
    let tempRecord = { ...record };
    if (type == 'again') {
      delete tempRecord.id;
    }
    dispatch({
      type: 'subjectAssessment/getAssessmentQrCode',
      payload: {
        record: tempRecord,
        intl,
      },
    });
  };

  const qrCodeProps = {
    url: assessmentQrUrl,
    currentUser,
    visible: assessmentQrUrlModalVisible,
    assessmentInfo: assessmentQrData,
    onCancel: () => {
      reloadPage();
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          assessmentQrUrlModalVisible: false,
        },
      });
    },
    downloadAssessmentQR: () => {
      dispatch({
        type: 'subjectAssessment/downloadAssessmentQRPhoto',
        payload: { intl },
      });
    },
  };

  const showOptions = () => {
    if (status == 'ReportedReady') {
      if (needPay) {
        // 获取报告
        return (
          <a
            onClick={() => {
              dispatch({
                type: 'subjectAssessment/getAssessmentReport',
                payload: { record, intl, customReload: reloadPage },
              });
            }}
          >
            {intl.formatMessage({ id: 'getReport' })}
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              const reportUrl = `${mobileUrl}/m/rocketReport?to=${userAssessmentId}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`;
              window.open(reportUrl, '_blank');
            }}
          >
            {intl.formatMessage({ id: 'lookReport' })}
          </a>
          // <Space size={6} align="start">
          //   <a
          //     onClick={() => {
          //       const reportUrl = `${mobileUrl}/m/rocketReport?to=${userAssessmentId}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`;
          //       window.open(reportUrl, '_blank');
          //     }}
          //   >
          //     {intl.formatMessage({ id: 'lookReport' })}
          //   </a>
          //   <a onClick={() => editAssessment('again')}>{intl.formatMessage({ id: 'Test Again' })}</a>
          // </Space>
        );
      }
    }
    return (
      <Space size={6} align="start">
        <a onClick={editAssessment}>
          {intl.formatMessage({
            id: status === 'None' ? 'Fill In' : 'Continue Filling',
          })}
        </a>
        <RedoOutlined onClick={reloadPage} spin={loading} />
      </Space>
    );
  };

  const clickCustomPlan = async () => {
    setCustomBtnLoading(true);
    const res = await PostCustomPlan({
      userOfflineTrainingPlanId,
    });

    if (res.response.ok) {
      reloadPage();
    }
    setCustomBtnLoading(false);
  };

  return (
    <div className={styles.tabContent}>
      <div className={styles.stepTwo}>
        <div className={styles.stepTwoText}>
          {displayName} {intl.formatMessage({ id: 'Customizing plan' })}
        </div>
        <Space className={styles.stepTwoText} size={6} align="start">
          {intl.formatMessage({ id: 'Pre-assessment' })}：
          <div className={styles.stepTwoDes}>
            {title}
            <div className={styles.time}>
              {intl.formatMessage({ id: 'classCreateTime' })}：
              {moment(creationTime).format('MM-DD-YYYY HH:mm')}
            </div>
          </div>
          {showOptions()}
        </Space>
        <Button
          type="primary"
          loading={customBtnLoading}
          disabled={!canClick}
          onClick={clickCustomPlan}
        >
          {intl.formatMessage({ id: 'Customize HFS Training Plan' })}
        </Button>
      </div>
      <AssessmentQrCode {...qrCodeProps} />
    </div>
  );
};

export default connect(({ subjectAssessment, user }) => ({
  subjectAssessment,
  currentUser: user.currentUser,
}))(StepTwo);
