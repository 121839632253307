import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const LineWithPointsChart = ({ dataStruct }) => {
  const { Behaviour, Motion } = dataStruct;

  const intl = useIntl();

  const color = ['#3DBD7D', '#FFCE3D', '#F36E65', '#36abd3'];
  const series = [
    {
      name: intl.formatMessage({ id: 'RealtimePerformance.answer' }),
      type: 'scatter',
      data: [],
    },
    {
      name: intl.formatMessage({ id: 'RealtimePerformance.err' }),
      type: 'scatter',
      data: [],
    },
    {
      name: intl.formatMessage({ id: 'RealtimePerformance.commission' }),
      type: 'scatter',
      data: [],
    },
    {
      name: intl.formatMessage({ id: 'RealtimePerformance.amplitude' }),
      type: 'line',
      yAxisIndex: 1,
      showSymbol: false,
      hoverAnimation: false,
      data: [],
    },
  ];

  const style = {
    height: '400px',
    width: '750px',
  };

  const option = {
    color,

    grid: {
      left: '12%',
      right: '12%',
    },

    xAxis: {
      name: intl.formatMessage({ id: 'Charts.Minutes' }),
      type: 'value',
      nameLocation: 'center',
      nameGap: 30,
      boundaryGap: false,
      axisLine: { onZero: false },
      min: 0,
      max: 13,
      splitNumber: 13,
    },

    yAxis: [
      {
        name: intl.formatMessage({ id: 'Charts.Reaction.Time' }),
        nameLocation: 'center',
        min: 0,
        max: 1600,
        interval: 400,
        nameGap: 40,
        position: 'left',
        type: 'value',
      },
      {
        name: intl.formatMessage({ id: 'Charts.MotionIndex' }),
        nameLocation: 'center',
        nameGap: 25,
        type: 'value',
        position: 'right',
        min: 0,
        max: 8,
        interval: 2,
        nameRotate: 270,
      },
    ],

    legend: {
      top: 'bottom',
    },

    series,
  };

  Behaviour.forEach((item) => {
    switch (item.Type) {
      case 2:
        option.series[0].data.push([item.Minute, item.ReactionTime]);
        break;
      case 1:
        option.series[1].data.push([item.Minute, 0]);
        break;
      case 0:
        option.series[2].data.push([item.Minute, item.ReactionTime]);
        break;
      default:
        option.series[0].data.push([item.Minute, item.ReactionTime]);
        break;
    }
  });

  const showMotionIndex = Motion.map((item) => {
    return [item.Minute, item.MotionIndex];
  });

  option.series[3].data = showMotionIndex;
  return (
    <ReactEcharts option={option} style={style} opts={{ renderer: 'svg' }} />
  );
};

export default LineWithPointsChart;
