import { message } from 'antd'
import {
    GetTrainerModuleList,
    GetTrainerModuleDetail,
    GetTrainingApplication,
    GetProfile,
    PostIssueCertificate,
    GetQuestionnaire,
    GetQualification,
    GetTrainingModule,
    GetTrainerDetail,
    PostSupervisorSummary,
    GetSupervisorSSummary,
    GetTrainerSummaryList,
    PutSupervisorSummary,
    GetTrainCenterList,
    GetCourseList,
    GetTrainingDetail
} from 'services/trainingSystem'
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import config from 'utils/config'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
const { baseURL, uploadCredentials } = config

let contentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: '证书编号',
            FormKey: 'credentialsCode',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
            },
            Description: '',
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: 'Maple',
                        Id: 'Maple',
                    },
                    {
                        Value: 'Emma',
                        Id: 'Emma',
                    },
                ],
                Required: true,
            },
            ShowTitle: '培训师',
            FormKey: 'trainerName',
            Description: null,
        },
        // {
        //     EditorType: 'Input',
        //     ShowTitle: '培训师',
        //     FormKey: 'trainerName',
        //     AdditionalData: null,
        //     Value: null,
        //     Setting: {
        //         Required: true,
        //     },
        //     Description: '',
        // },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: 'Maple',
                        Id: 'Maple',
                    },
                    {
                        Value: 'Emma',
                        Id: 'Emma',
                    },
                ],
                Required: true,
            },
            ShowTitle: '认证师',
            FormKey: 'certifier',
            Description: null,
        },
        // {
        //     EditorType: 'Input',
        //     ShowTitle: '认证师',
        //     FormKey: 'certifier',
        //     AdditionalData: null,
        //     Value: null,
        //     Setting: {
        //         Required: true,
        //     },
        //     Description: '',
        // },
        {
            EditorType: 'DatePicker',
            ShowTitle: '考核通过时间',
            FormKey: 'passTime',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
                DateType: 'date',
            },
            Description: null,
        },
        {
            EditorType: 'RangePicker',
            ShowTitle: '有效期',
            FormKey: 'startEndTime',
            AdditionalData: null,
            Value: null,
            Setting: {
                showTime: false,
                Required: true,
                format: 'YYYY/MM/DD'
            },
            Description: null,
        },
        {
            EditorType: 'Upload',
            Value: null,
            Setting: {
                UploadSetting: {
                    isSingle: true,
                    uploadType: 'image'
                },
                Action: baseURL + uploadCredentials,
                Header: getAuthHeader(),
                Required: true,
            },
            ShowTitle: '上传证书',
            FormKey: 'credential',
        }
    ]
}

export default {
    namespace: 'admintrainingStaffDetail',
    state: {
        userId: '',
        userInfo: null,
        drawerVisible: false,
        itemDrawerVisible: false,
        moduleList: [],
        tabkey: '',
        questionData: null,
        awardInfo: null,
        content: contentMenu,
        awardQualificationsVisible: false,
        moduleDetail: null,
        activeContent: null,
        activeCollapseKey: '',
        activeClassInfo: null,
        showQuestionAnswerData: null,
        // medalInfo: null,
        showAnswerVisible: false,
        awardModuleList: [],
        trainerDetail: null,
        showSupervisionVisible: false,
        supervisionTabActiveKey: "Supervisor",
        eidtSupervisionVisible: false,
        supervisorQuestion: null,
        currentSupervisorItem: null,
        currentTrainerSummaryItem: null,
        supervisionQuestionDetail: null,
        supervisorIsFinshed: false,
        supervisorSubmitData: null
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query({ payload }, { call, put, select }) {
            const { id } = payload
            yield put({ type: 'updateState', payload: { userId: id } })
            const { currentUser } = yield select(state => state.user)
            const { adminRoles = [] } = currentUser || {}
            const canEditIsses = adminRoles.length > 0 && adminRoles.some(role => role.name === 'admin' || role.name === 'editor')

            yield put({ type: 'getUserInfo' })
            yield put.resolve({ type: 'getTrainerDetail' })
            yield put({ type: 'getQuestionData' })
            yield put({ type: 'getModuleList' })
            // yield put({ type: 'getQualification' })
            if (canEditIsses) 
              yield put({ type: 'getAwardModuleList' })
        },

        *getTrainerDetail({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.admintrainingStaffDetail)
            const res = yield call(GetTrainerDetail, { trainerId: userId })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { trainerDetail: res.data } })
            }
        },

        *getUserInfo({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.admintrainingStaffDetail)
            const res = yield call(GetProfile, { id: userId })
            yield put({ type: 'updateState', payload: { userInfo: null } })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { userInfo: res.data } })
            }
        },

        *getModuleList({ payload }, { call, put, select }) {
            const { tabkey, userId } = yield select(state => state.admintrainingStaffDetail)
            // const res = yield call(GetTrainerModuleList, { TrainerId: userId })
            const res = yield call(GetTrainCenterList, { TrainerId: userId })
            if (res.response.ok) {
                if (res.data && res.data.length > 0) {
                    const data = res.data
                    const newTabkey = tabkey || (data && data[0] && data[0].id || '')
                    yield put({ type: 'updateState', payload: { moduleList: data, tabkey: newTabkey } })
                    yield put({ type: 'getModuleDetail' })
                }
            }
        },

        *getAwardModuleList({ payload }, { call, put, select }) {
            const res = yield call(GetTrainingModule, { SkipCount: 0, MaxResultCount: 1000 })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { awardModuleList: res.data.items } })
            }
        },

        *getModuleDetail({ payload }, { call, put, select }) {
            const { tabkey: stateKey, moduleList, trainerDetail, userId } = yield select(state => state.admintrainingStaffDetail)
            // const params = { TrainerTrainingModuleId: tabkey, TrainerId: userId }
            // const res = yield call(GetTrainerModuleDetail, params)
            const tabkey = payload || stateKey
            const currentModule = moduleList.find(item => item.id === tabkey)
            const { trainingModuleId } = currentModule
            const res = yield call(GetCourseList, { TrainingModuleId: trainingModuleId, TrainerTrainingId: tabkey })
            if (res.response.ok) {
                const { id, issued, startDate, endDate, status, trainingOrder, trainingModule, lastUserExaminationPaperId, trainingModuleId, tenant } = currentModule
                const { trainerCredentialses = [] } = trainerDetail || {}
                const currentTrainer = trainerCredentialses.filter(item => item.trainingModuleId === trainingModuleId)
                const newData = {
                    issued,
                    startDate,
                    endDate,
                    status,
                    trainerId: userId,
                    trainingOrder,
                    trainingModule: {
                        ...trainingModule,
                        courses: res.data,
                    },
                    tenant,
                    id,
                    trainer: {
                        trainerCredentialses: currentTrainer
                    },
                    lastUserExaminationPaperId
                }
                yield put({ type: 'updateState', payload: { moduleDetail: newData } })
                // yield put({ type: 'updateState', payload: { moduleDetail: res.data } })
            }
        },

        *getQuestionData({ payload }, { call, put, select }) {
            const { userId, userInfo } = yield select(state => state.admintrainingStaffDetail)
            const { tenantId } = userInfo || {};
            const res = yield call(GetTrainingApplication, { TrainerId: userId, TenantId: tenantId })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { questionData: res.data } })
            }
        },

        *onChangeTabs({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.admintrainingStaffDetail)
            yield put({ type: 'updateState', payload: { tabkey: payload } })
            // yield put({ type: 'query', payload: { id: userId } })
        },

        *awardQualifications({ payload: data }, { call, put, select }) {
            const { awardInfo, userId, moduleList } = yield select(state => state.admintrainingStaffDetail)
            const { credentialsCode, trainerName, certifier, passTime, startEndTime, credential } = data
            const trainerTrainingModuleItem = moduleList.find(item => item.trainingModuleId === (awardInfo && awardInfo.id))
            const params = {
                trainerId: userId,
                trainerTrainingModuleId: trainerTrainingModuleItem ? trainerTrainingModuleItem.id : '',
                trainingModuleId: awardInfo && awardInfo.id,
                credentialsCode,
                trainerName,
                certifier,
                passTime,
                startTime: startEndTime[0],
                endTime: startEndTime[1],
                credential
            }
            const res = yield call(PostIssueCertificate, params)
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { awardQualificationsVisible: false } })
                yield put({ type: 'query', payload: { id: userId } })
            } else {
                const { error } = res.data
                const { message: errorInfo } = error || {}
                errorInfo && message.error(errorInfo)
            }
        },

        *onClickItem({ payload }, { call, put, select }) {
            const { courseId } = payload || {}
            const { moduleDetail } = JSON.parse(JSON.stringify(yield select(state => state.admintrainingStaffDetail)))
            const { id: moduleId } = moduleDetail || {}
            const res = yield call(GetTrainingDetail, { CourseId: courseId, TrainerTrainingId: moduleId })
            if(res.response.ok){
                const course = res.data

                let { stepIndex, contents } = course || {}
                let newActiveContent = null
                let currentIndex = 0
                let defaultStep = null
                // 判断返回值是否存在userCourseDetail属性值，如果都不存在，默认打开第一个
                const hasUserCourseDetail = contents.find(item => item.userCourseDetail)
                const hasUserCourseDetailData = contents.filter(item => item.userCourseDetail)
                const hasnotUserCourseDetailData = contents.filter(item => !item.userCourseDetail)
                // 如果存在hasUserCourseDetail，就找isFinish和isPass其中有一个不为true的值就是默认第一个，如果都为true，则第一个没有hasUserCourseDetail就是默认第一个
                if (hasUserCourseDetail) {
                    const eithorData = hasUserCourseDetailData.find(item => item.userCourseDetail.isFinish && !item.userCourseDetail.isPass)
                    if (eithorData) {
                        newActiveContent = eithorData
                    } else {
                        newActiveContent = (hasnotUserCourseDetailData && hasnotUserCourseDetailData[0]) || (hasUserCourseDetailData && hasUserCourseDetailData[hasUserCourseDetailData.length - 1])
                    }
                } else {
                    newActiveContent = contents && contents[0] || null
                }
                currentIndex = contents.findIndex(item => newActiveContent.id === item.id)
                // 标记是否上锁
                contents && contents.forEach((item, index) => {
                    if (index === currentIndex) {
                        item.isLocked = false
                    } else if (!item.userCourseDetail) {
                        item.isLocked = true
                    }
                })
                // step后端没有ID,为了正常切换，我加了index作为切换得id,这样就可以打开当前得章节
                defaultStep = stepIndex.find((item, index) => {
                    item.index = index
                    return item.contentId.includes(newActiveContent && newActiveContent.id)
                })

                yield put({
                    type: 'updateState',
                    payload: {
                        activeContent: newActiveContent,
                        activeCollapseKey: String(defaultStep && defaultStep.index),
                        itemDrawerVisible: true,
                        activeClassInfo: course
                    }
                })
            }
        },

        *createQuestionnaire({ payload }, { take, put, call, select }) {
            const { userCourseDetail } = payload || {}
            const { userQuestionnaireId } = userCourseDetail || {}
            const params = {
                id: userQuestionnaireId
            }
            const res = yield call(GetQuestionnaire, params)
            if (res.response.ok) {
                const data = res.data
                const { questionnaireAndAnswer } = data || {}
                const { answer, questionnaire } = questionnaireAndAnswer || {}
                const { answerContent } = answer || {}
                const { correctRate, jsonString } = questionnaire || {}
                yield put({
                    type: 'updateState',
                    payload: {
                        showQuestionAnswerData: {
                            data,
                            currentContent: payload
                        },
                        correntRateAndResult: getCorrentRateAndResult(jsonString, answerContent, correctRate),
                        showAnswerVisible: true
                    }
                })
            } else {
                const { error } = res.data
                const { message: errorInfo } = error || {}
                errorInfo && message.error(errorInfo)
            }
        },

        // *getQualification({ payload }, { call, put, select }) {
        //     const res = yield call(GetQualification, { SkipCount: 0, MaxResultCount: 1000 })
        //     if (res.response.ok) {
        //         yield put({ type: 'updateState', payload: { medalInfo: res.data.items } })
        //     }
        // },

        // 获取问卷内容
        *getQuestionDetail({ payload }, { call, put, select }) {
            const { supervisionTabActiveKey } = yield select(state => state.admintrainingStaffDetail)
            const questionDetailRes = yield call(GetQuestionnaire, { id: payload.id })
            if (questionDetailRes.response.ok) {
                const currentIdName = supervisionTabActiveKey === "TrainerSummary" ? "currentTrainerSummaryItem" : "currentSupervisorItem"
                yield put({
                    type: "updateState",
                    payload: {
                        supervisionQuestionDetail: questionDetailRes.data,
                        [currentIdName]: payload
                    }
                })
            }
        },

        // 获取训练师小结
        *getTrainerSummary({ payload }, { call, put, select }) {
            const { userId, currentTrainerSummaryItem } = yield select(state => state.admintrainingStaffDetail)
            const trainerSummaryListRes = yield call(GetTrainerSummaryList, { UserId: userId })
            if (trainerSummaryListRes.response.ok) {
                const data = trainerSummaryListRes.data
                const { items } = data || {}
                const { items: subItems } = items || {}
                const item = currentTrainerSummaryItem ? currentTrainerSummaryItem : (subItems && subItems.length > 0 ? subItems[0] : null)
                yield put({
                    type: "updateState",
                    payload: {
                        questionnaireListData: data
                    }
                })
                if (item !== null) {
                    yield put({
                        type: "getQuestionDetail",
                        payload: item
                    })
                }
            }
        },

        // 获取督导师跟踪表
        *getSupervisor({ payload }, { call, put, select }) {
            const { userId, currentSupervisorItem } = yield select(state => state.admintrainingStaffDetail)
            const supervisorListRes = yield call(GetSupervisorSSummary, { UserId: userId })
            if (supervisorListRes.response.ok) {
                const data = supervisorListRes.data
                const { items } = data || {}
                const { items: subItems } = items || {}
                const item = currentSupervisorItem ? currentSupervisorItem : (subItems && subItems.length > 0 ? subItems[0] : null)
                yield put({
                    type: "updateState",
                    payload: {
                        questionnaireListData: data
                    }
                })
                if (item !== null) {
                    yield put({
                        type: "getQuestionDetail",
                        payload: item
                    })
                }
            }
        },

        // 根据当前Tab Type获取不同的数据
        *getDataByDiffType({ payload }, { call, put, select }) {
            const { supervisionTabActiveKey } = yield select(state => state.admintrainingStaffDetail)
            if (supervisionTabActiveKey === "TrainerSummary") {
                yield put({ type: "getTrainerSummary" })
            } else if (supervisionTabActiveKey === "Supervisor") {
                yield put({ type: "getSupervisor" })
            }
        },

        // 展示见习督导记录
        *showSupervision({ payload }, { call, put, select }) {
            yield put({
                type: "updateState",
                payload: {
                    showSupervisionVisible: true,
                    supervisionQuestionDetail: null,
                    currentSupervisorItem: null,
                    currentTrainerSummaryItem: null
                }
            })
            yield put({ type: 'getDataByDiffType' })
        },

        // 切换见习督导记录Tab
        *onChangSupervisionTabs({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { supervisionTabActiveKey: payload, questionnaireListData: null, supervisionQuestionDetail: null } })
            yield put({ type: 'getDataByDiffType' })
        },

        *editSupervision({ payload }, { call, put, select }) {
            const { currentSupervisorItem } = yield select(state => state.admintrainingStaffDetail)
            const questionDetailRes = yield call(GetQuestionnaire, { id: currentSupervisorItem.id })
            if (questionDetailRes.response.ok) {
                yield put({ type: "updateState", payload: { supervisorQuestion: questionDetailRes.data, eidtSupervisionVisible: true } })
            }
        },

        // 展示编辑见习督导小结
        *addSupervision({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.admintrainingStaffDetail)
            const supervisionRes = yield call(PostSupervisorSummary, { userId })
            if (supervisionRes.response.ok) {
                yield put({ type: "updateState", payload: { supervisorQuestion: supervisionRes.data, eidtSupervisionVisible: true } })
            }
        },

        // 关闭编辑见习督导跟踪表
        *onCloseEidtSupervision({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { eidtSupervisionVisible: false, supervisorQuestion: null } })
        },

        // 提交见习督导跟踪表
        *supervisorSubmit({ payload }, { call, put, select }) {
            const { supervisorQuestion } = yield select(state => state.admintrainingStaffDetail)
            const { id } = supervisorQuestion || {}
            const { answerContent, isComplete, finishedPage } = payload
            const params = {
                updateQuestionnaireDto: {
                    userQuestionnaireId: id,
                    answerContent,
                    isComplete,
                    finishedPage
                }
            }
            const res = yield call(PutSupervisorSummary, params)
            if (res.response.ok) {
                if (isComplete) {
                    message.success('提交成功')
                    yield put({ type: 'updateState', payload: { supervisorSubmitData: null, eidtSupervisionVisible: false, currentSupervisorItem: null } })
                    yield put({ type: 'getDataByDiffType' })
                }
            }
        },

        // 切换menuList
        *clickMenuItem({ payload }, { call, put, select }) {
            // const { id } = payload || {}
            yield put({
                type: 'getQuestionDetail',
                payload
            })
        },

        *clear({ payload }, { call, put, select }) {
            yield put({ type: "updateState", payload: { userInfo: null } })
        },
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(async location => {
        //         if (location.pathname === '/system/manage/trainingStaffDetail') {
        //             await dispatch({ type: 'updateState', payload: { moduleList: [], tabkey: '' } })
        //             dispatch({ type: 'query', payload: location.query })
        //         }
        //     })
        // },
    },
}
