import { Modal, Button } from 'antd';
import newTrainIMG from 'assets/new_train2.png'
import { IntlManager } from 'utils/helper'
import styles from './index.less'

const NewTrainModuleModal2 = ({
    visible,
    oncancel,
    onok
}) => {
    const intl = IntlManager.MyIntl()

    return (
        <Modal
            open={visible}
            title=""
            footer={null}
            width={600}
            onCancel={() => oncancel()}
        >
            <img src={newTrainIMG} className={styles.img} />
            <div className={styles.desc}>{intl('goTrainingCenter')}</div>
            <div className={styles.newTrainBtn}>
                <Button
                    type="primary"
                    style={{ width: '230px', marginLeft: '10px' }}
                    onClick={() => onok()}
                >
                    {intl('ok')}
                </Button>
            </div>
        </Modal>
    )
}

export default NewTrainModuleModal2