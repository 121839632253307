import { Table, Divider } from 'antd'
import styles from '../../detail.less'
import { IntlManager } from 'utils/helper'

const intl = IntlManager.MyIntl()

const CoursewareContent = trainingCourseInfo => {
  const info = info => {
    return (
      <div>
        {/* 课件名称 */}
        <div className={styles.betweenItem}>
          <Divider type="vertical" className={styles.dividerColor} />
          <span className={styles.infoTitle}>{intl('coursewareName')} : </span>
          <span>{info.title}</span>
        </div>
        {/* 课件编号 */}
        <div className={styles.betweenItem}>
          <Divider type="vertical" className={styles.dividerColor} />
          <span className={styles.infoTitle}>{intl('coursewareNumber')} : </span>
          <span>{info.code}</span>
        </div>

        {/* 预计训练时长(分钟) */}
        <div className={styles.betweenItem}>
          <Divider type="vertical" className={styles.dividerColor} />
          <span className={styles.infoTitle}>{intl('estimatedTrainingDuration')} : </span>
          <span>{info.expectTime}</span>
        </div>

        {/* 适合年龄 */}
        <div className={styles.betweenItem}>
          <Divider type="vertical" className={styles.dividerColor} />
          <span className={styles.infoTitle}>{intl('suitableAge')} : </span>
          <span>{`${info.agePeriod != null ? info.agePeriod.start : ''}-${
            info.agePeriod != null ? info.agePeriod.end : ''
          }`}</span>
        </div>

        {/* 适合人数 */}
        <div className={styles.betweenItem}>
          <Divider type="vertical" className={styles.dividerColor} />
          <span className={styles.infoTitle}>{intl('suitableNumberPeople')} : </span>
          <span>{`${info.least}-${info.maximum}`}</span>
        </div>

        {/* 训练目标 */}
        <div className={styles.betweenItem}>
          <Divider type="vertical" className={styles.dividerColor} />
          <span className={styles.infoTitle}>{intl('trainingGoals')} : </span>
          {info.trainingTarget.map(item => {
            return <span key={item.id}>{item.displayName}</span>
          })}
        </div>
      </div>
    )
  }

  const materials = materials => {
    const columns = [
      {
        title: intl('orderNumber'),
        dataIndex: 'number',
      },
      {
        title: intl('trainingMaterialsName'),
        dataIndex: 'materialName',
      },
      {
        title: intl('image'),
        dataIndex: 'image',
        render: text => (
          <img alt="img" style={{ width: 60, height: 60, borderRadius: 10 }} src={text} />
        ),
      },
    ]

    let data = []
    materials.forEach((item, index) => {
      data.push({
        key: index,
        number: index + 1,
        materialName: item.displayName,
        image: item.image.url,
      })
    })
    return (
      <div>
        <div className={styles.betweenItem}>
          <Divider type="vertical" className={styles.dividerColor} />
          <span className={styles.infoTitle}>{intl('trainingMaterials')} : </span>
        </div>
        <div style={{ padding: '5px 20px' }}>
          <Table columns={columns} dataSource={data} bordered pagination={false} />
        </div>
      </div>
    )
  }

  return (
    <div>
      {trainingCourseInfo == null ? (
        <div></div>
      ) : (
        <div>
          <h2 className={styles.category}>{intl('introduction')}</h2>
          {info(trainingCourseInfo)}
          {materials(trainingCourseInfo.materials)}
        </div>
      )}
    </div>
  )
}

export default CoursewareContent
