import React, { useEffect, useState } from 'react';
import { message, Input, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

import {
  PostBrainMusic,
  GetBrainMusic,
  PutBrainMusic,
  DelBrainMusic,
  PostImportMusic,
} from 'services/brainMusic';
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';
import { cleanObject } from 'cognitiveleap-core-us/utils/utils';
import config from 'utils/config';
const { baseURL } = config;

import styles from './index.less';

const { Search } = Input;

const contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'Code',
      FormKey: 'code',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: '',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'DisplayName',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: '2-50个字符，不可重名',
    },
  ],
};

const BrainMusic = () => {
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [currentContent, setCurrentContent] = useState(contentMenu);

  const [loading, setLoading] = useState({
    tableLoading: true,
    modelLoading: true,
  });

  const [listData, setListData] = useState([]);
  const [type, setType] = useState('add');

  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState({
    musicVisible: false,
    excelVisible: false,
  });
  const [currentRecord, setCurrentRecord] = useState({});

  const getListData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const params = {
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
      filter: keyword,
    };
    const res = await GetBrainMusic(cleanObject(params));
    if (res.response.ok) {
      setPagination({
        ...pagination,
        total: res.data.totalCount,
      });
      setListData(res.data.items);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }
    setLoading({ ...loading, tableLoading: false });
  };

  useEffect(() => {
    getListData();
  }, [pagination.current, pagination.pageSize, keyword]);

  const contentExcelMenu = {
    Properties: [
      {
        EditorType: 'Upload',
        ShowTitle: '音乐小节',
        FormKey: 'brainMusicId',
        Value: null,
        Setting: {
          UploadSetting: {
            isSingle: true,
            uploadType: 'xlsx',
          },
          Action:
            baseURL +
            '/api/rocketAdmin/brainMusic/importMusicSection/' +
            currentRecord.id,
          Header: getAuthHeader(),
          Required: true,
        },
        Description: '上传xls，xlsx文件',
      },
    ],
  };

  const listSupport = {
    code: {
      showText: 'code',
      showType: 'Text',
    },
    displayName: {
      showText: 'displayName',
      showType: 'Text',
    },
    audioTracks: {
      showText: '音乐小节Excel',
      showType: 'Text',
      render: (_, record) => {
        return record.audioTracks.length > 0 ? '有' : '无';
      },
    },
  };

  const tableProps = {
    listData: listData,
    listSupport: listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: '编辑',
        itemRender(record) {
          return (
            <a
              onClick={() => {
                let content = JSON.parse(JSON.stringify(contentMenu));
                content.Properties[0].Value = record.code;
                content.Properties[0].Setting = {
                  ...content.Properties[0].Setting,
                  Disabled: true,
                };
                content.Properties[1].Value = record.displayName;
                setCurrentContent(content);
                setType('update');
                setCurrentRecord(record);
                setVisible({
                  ...visible,
                  musicVisible: true,
                });
              }}
            >
              编辑
            </a>
          );
        },
      },
      {
        showText: '音乐小节Excel',
        itemRender(record) {
          if (record.audioTracks && record.audioTracks.length > 0) {
            return null;
          }
          return (
            <a
              onClick={() => {
                setCurrentRecord(record);
                setVisible({ ...visible, excelVisible: true });
              }}
            >
              音乐小节Excel
            </a>
          );
        },
      },
      {
        showText: '删除',
        itemRender(record) {
          return (
            <a
              onClick={() => {
                Modal.confirm({
                  title: (
                    <span className={styles.changeRedColor}>
                      确定删除此音乐模块吗？
                    </span>
                  ),
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <span className={styles.changeRedColor}>
                      删除后将影响使用此音乐模块的用户
                    </span>
                  ),
                  okText: '删除',
                  cancelText: '取消',
                  onOk: async () => {
                    const { id: Id } = record;
                    const res = await DelBrainMusic({ Id });
                    if (res.response.ok) {
                      message.success('删除成功');
                      getListData();
                    } else {
                      const { error } = res.data || {
                        error: { message: '删除失败' },
                      };
                      message.error(error.message);
                    }
                  },
                });
              }}
            >
              删除
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    title: '音乐模块',
    visible: visible.musicVisible,
    content: currentContent,
    onCancel() {
      setVisible({
        ...visible,
        musicVisible: false,
      });
    },
    loading: !loading.modelLoading,
    async onOk(value) {
      let res;
      if (type === 'add') {
        res = await PostBrainMusic(value);
      } else {
        const { displayName } = value;
        res = await PutBrainMusic({
          displayName,
          id: currentRecord.id,
        });
      }
      if (res.response.ok) {
        getListData();
        setVisible({
          ...visible,
          musicVisible: false,
        });
        message.success(type === 'add' ? '添加成功' : '修改成功');
      }
      setLoading({
        ...loading,
        modelLoading: false,
      });
    },
  };

  const excelModalProps = {
    title: `${currentRecord.displayName}音乐小节Excel`,
    visible: visible.excelVisible,
    content: contentExcelMenu,
    onCancel() {
      setVisible({
        ...visible,
        excelVisible: false,
      });
    },
    loading: !loading.modelLoading,
    async onOk(value) {
      getListData();
      setVisible({
        ...visible,
        excelVisible: false,
      });
    },
  };

  return (
    <>
      <h1>优脑音乐模块</h1>
      <div className={styles.headerTool}>
        <Search
          placeholder="code/displayName"
          className={styles.search}
          onSearch={(value) => {
            setPagination({
              ...pagination,
              current: 1,
              pageSize: 10,
            });
            setKeyword(value);
          }}
        />
        <Button
          type="primary"
          onClick={() => {
            setType('add');
            setCurrentContent(contentMenu);
            setVisible({
              ...visible,
              musicVisible: true,
            });
          }}
        >
          添加音乐模块
        </Button>
      </div>
      <MagicTable {...tableProps} />
      <MagicFormModal {...modalProps} />
      <MagicFormModal {...excelModalProps} />
    </>
  );
};

export default BrainMusic;
