import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const CPTChart = ({ data }) => {
  let YMax = 3;
  const intl = useIntl();

  const option = {
    color: ['#6ECE9E', '#FFDA6E', '#F6928C'],
    legend: {
      x: 'center',
      top: '5%',
      data: [intl.formatMessage({ id: 'MentalEndurance.correct' }), intl.formatMessage({ id: 'MentalEndurance.omission' }), intl.formatMessage({ id: 'MentalEndurance.commission' })],
    },
    grid: [
      {
        left: '15%',
        right: '15%',
        top: '20%',
        height: '35%',
      },
      {
        left: '15%',
        right: '15%',
        top: '55%',
        height: '35%',
      },
    ],

    xAxis: [
      {
        show: false,
        type: 'category',
        axisTick: { show: false },
        data: ['1', '2', '3', '4'],
      },
      {
        name: intl.formatMessage({ id: 'Charts.Blocks' }),
        gridIndex: 1,
        type: 'category',
        axisTick: { show: true },
        data: ['1', '2', '3', '4'],
        position: 'top',
        axisLabel: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        name: intl.formatMessage({ id: 'Charts.Times' }),
        min: 0,
        max: 15,
        splitNumber: 3,
        type: 'value',
      },
      {
        gridIndex: 1,
        type: 'value',
        minInterval: 1,
        inverse: true,
        max: YMax,
      },
    ],
    series: [
      {
        name: intl.formatMessage({ id: 'MentalEndurance.correct' }),
        type: 'bar',
        barWidth: '60%',
        data: [],
        silent: true,
        label: {
          show: true,
          position: 'top',
          formatter: 'right',
          color: '#000',
        },
      },
      {
        name: intl.formatMessage({ id: 'MentalEndurance.omission' }),
        type: 'bar',
        barGap: 0,
        barWidth: '30%',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: [],
        silent: true,
        label: {
          show: true,
          position: 'bottom',
          formatter: 'right',
          color: '#000',
        },
      },
      {
        name: intl.formatMessage({ id: 'MentalEndurance.commission' }),
        type: 'bar',
        barWidth: '30%',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: [],
        silent: true,
        label: {
          show: true,
          position: 'bottom',
          formatter: 'right',
          color: '#000',
        },
      },
    ],
  };

  for (let i = 1; i <= 4; i++) {
    const selectBlock = data.find((item) => {
      return item.Block === i;
    });
    if (selectBlock !== undefined) {
      option.series[0].data.push(selectBlock.CorrectAnswer);
      option.series[1].data.push(selectBlock.OmissionError);
      option.series[2].data.push(selectBlock.CommissionError);

      option.series[0].label.formatter = selectBlock.CorrectAnswer;
      option.series[1].label.formatter = selectBlock.OmissionError;
      option.series[2].label.formatter = selectBlock.CommissionError;

      if (selectBlock.OmissionError > YMax) YMax = selectBlock.OmissionError;

      if (selectBlock.CommissionError > YMax)
        YMax = selectBlock.CommissionError;
    }
  }

  option.yAxis[1].max = YMax;

  // {Block: 1, CorrectAnswer: 13, OmissionError: 0, CommissionError: 69, CaseId: 234}
  return (
    <ReactEcharts
      option={option}
      style={{ height: '600px', width: '600px' }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default CPTChart;
