import DocumentTitle from 'react-document-title'
import { useEffect } from 'react'
import { connect, useIntl } from 'umi'
import moment from 'moment'
import { DatePicker, Button, Row, Col, Divider, Spin } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import ShowDataAnalysis from 'components/ShowDataAnalysis'
import { vratKeyIndicators } from './config'
import { getAnalysisSubTitle, getAnalysisData } from 'utils/analysis'
import styles from '../../center.less'
import myStyles from './index.less'
import { getFormatDate } from 'utils/utils'

const { RangePicker } = DatePicker

const IndicatorsAnalysis = ({ dispatch, statistics, loading }) => {

  const intl = useIntl()

  useEffect(() => {
    dispatch({ type: 'statistics/fetchStatistics' })
    dispatch({ type: 'statistics/getTestResourceAmount' })
  }, [])

  const { statistics: isStatisticsLoading } = loading.models
  const {
    listCenterStatistics,
    SearchField,
    TestStartTime,
    TestEndTime,
    ReportStartTime,
    ReportEndTime,
    pageIndex,
    pageSize,
    total,
    VRATAmount
  } = statistics

  if (VRATAmount === null) {
    return <Spin />
  }

  const centerNameShowText = intl.formatMessage({ id: 'centerName' })
  // const locationNameShowText = intl.formatMessage({ id: 'locationName' })
  const firstTestTimeShowText = intl.formatMessage({ id: 'firstTestTime' })
  const lastTestTimeShowText = intl.formatMessage({ id: 'lastTestTime' })
  const subjectCountShowText = intl.formatMessage({ id: 'subjectCount' })
  const reportReadyCountShowText = intl.formatMessage({ id: 'reportReadyCount' })

  const listSupportCenterStatistics = {
    centerName: {
      showText: centerNameShowText,
      showType: 'Text',
    },
    // locationName: {
    //   showText: locationNameShowText,
    //   showType: 'Text',
    // },
    FirstTestTime: {
      showText: firstTestTimeShowText,
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD',
      },
    },
    LastTestTime: {
      showText: lastTestTimeShowText,
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD',
      },
    },
    SubjectCount: {
      showText: subjectCountShowText,
      showType: 'Text',
    },
    // RealTestCount: {
    //   showText: intl.formatMessage({id: 'realTestCount'}),
    //   showType: 'Text',
    // },
    ReportAcquiredCount: {
      showText: reportReadyCountShowText,
      showType: 'Text',
    },
    // ReportAcquiredCount5_13:: {
    //   showText: intl.formatMessage({ id: 'reportReadyCount5_13' }),
    //   showType: 'Text',
    // },
    // TrailTestCount: {
    //   showText: intl.formatMessage({id: 'trailTestCount'}),
    //   showType: 'Text',
    // },
  }

  const showData = listCenterStatistics.map(item => {
    const { IsTotal, TestingCenter, TestLocation } = item
    const showstatisticsTotal = intl.formatMessage({ id: 'statisticsTotal' })
    const centerName = IsTotal
      ? showstatisticsTotal
      : TestingCenter
        ? TestingCenter.DisplayName
        : ''
    // const locationName = IsTotal
    //   ? showstatisticsTotal
    //   : TestLocation
    //     ? TestLocation.Name
    //     : ''
    return {
      centerName,
      // locationName,
      ...item,
    }
  })

  // vratKeyIndicators().forEach(item => {
  //   if (item.hasOwnProperty('subTitle')) {
  //     item.data = getAnalysisData(item.dataField, VRATAmount[item.dataField])
  //     if (VRATAmount[item.dataField]) {
  //       item.subTitle = getAnalysisSubTitle(item.subTitleField, VRATAmount[item.subTitleField])
  //     }
  //   } else {
  //     item.data = VRATAmount[item.field]
  //   }
  // })

  const showVratKeyIndicators = vratKeyIndicators(intl).map(item => {
    if (item.hasOwnProperty('subTitle')) {
      const showSubTitle = VRATAmount[item.dataField] ? getAnalysisSubTitle(item.subTitleField, VRATAmount[item.subTitleField], intl) : item.subTitle
      return {
        ...item,
        data: getAnalysisData(item.dataField, VRATAmount[item.dataField], intl),
        subTitle: showSubTitle
      }
    } else {
      return {
        ...item,
        data: VRATAmount[item.field]
      }
    }
  })

  const TableProps = {
    loading: isStatisticsLoading,
    listData: showData,
    listSupport: listSupportCenterStatistics,
    pagination: { current: pageIndex, pageSize, total },
    onTableChange(pagi = {}, filt = {}) {
      const { current, pageSize } = pagi
      // let data = { pagination, filter }
      // if (Object.keys(pagi).length !== 0)
      //   data = { ...data, pagination: pagi }
      // if (Object.keys(filt).length !== 0)
      //   data = { ...data, filter: filt }
      dispatch({ type: 'statistics/filterAndFetch', payload: { pageIndex: current, pageSize } })
    },
  }

  const onTimePicker = value => {
    let TestStartTime = null
    let TestEndTime = null
    if (value && value.length > 0) {
      TestStartTime = getFormatDate(value[0], true)
      TestEndTime = getFormatDate(value[1], false)
    } else {
      TestStartTime = null
      TestEndTime = null
    }
    dispatch({ type: 'statistics/filterAndFetch', payload: { TestStartTime, TestEndTime } })
  }

  const onReportTimePicker = value => {
    let ReportStartTime = null
    let ReportEndTime = null
    if (value && value.length > 0) {
      ReportStartTime = getFormatDate(value[0], true)
      ReportEndTime = getFormatDate(value[1], false)
    } else {
      ReportStartTime = null
      ReportEndTime = null
    }
    dispatch({ type: 'statistics/filterAndFetch', payload: { ReportStartTime, ReportEndTime } })
  }

  const ShowRangeTime = [
    moment(TestStartTime).isValid() ? moment(TestStartTime) : null,
    moment(TestEndTime).isValid() ? moment(TestEndTime) : null,
  ]

  const ShowReportRangeTime = [
    moment(ReportStartTime).isValid() ? moment(ReportStartTime) : null,
    moment(ReportEndTime).isValid() ? moment(ReportEndTime) : null,
  ]

  const onClear = () => {
    dispatch({
      type: 'statistics/filterAndFetch',
      payload: { TestStartTime: null, TestEndTime: null, ReportStartTime: null, ReportEndTime: null, SearchField: null },
    })
  }

  const onDownload = () => {
    dispatch({ type: 'statistics/downloadStatistic' })
  }

  return (
    <div>
      <div className={myStyles.dataAnalysis}>
        <ShowDataAnalysis keyIndicators={showVratKeyIndicators} />
      </div>
      <div className={styles.table}>
        <Row type="flex" justify="start" gutter={10} style={{ marginBottom: 20 }}>
          <Col>
            {/* <span style={{marginRight: '10px'}}>测试时间</span> */}
            <RangePicker onChange={onTimePicker} value={ShowRangeTime} />
          </Col>
          {/* <Col>
            <span style={{marginRight: '10px'}}>报告生成时间</span>
            <RangePicker onChange={onReportTimePicker} value={ShowReportRangeTime} />
          </Col> */}
          <Col>
            <Button onClick={onClear}>{intl.formatMessage({ id: 'clear' })}</Button>
          </Col>
          <Col>
            <Button onClick={onDownload}>{intl.formatMessage({ id: 'downloadAllStatistic' })}</Button>
          </Col>
        </Row>
        <MagicTable {...TableProps} />
      </div>
    </div>
  )
}

export default connect(({ statistics, loading }) => ({ statistics, loading }))(IndicatorsAnalysis)
