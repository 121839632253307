import pages from 'utils/pages'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { GetTenantList, GetRegionList } from 'services/tenant'
import { message } from 'antd'
const { centerListArea } = pages

export default {
  namespace: 'centerListArea',

  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    Filter: '',
    filters: null,
    regionList: []
  },

  effects: {
    *getRegionList(_, { take, put, call, select }){
      const res = yield call(GetRegionList);
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { regionList: res.data.enterprises } })
      }
    },

    *query(_, { take, put, call, select }) {
      const { pagination, Filter, filters } = yield select(state => state.centerListArea)
      let query = { skipCount: 0, maxResultCount: 10, Type: 'Center' }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        Filter,
        ParentNameFilter: filters && filters.region && filters.region[0] || ''
      }
      const res = yield call(GetTenantList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination, filters } = data
      yield put({ type: 'updateState', payload: { pagination, filters } })
      yield put({ type: 'query' })
    },

    *filterFetch({ payload }, { call, put, select, take }) {
      const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      yield put({ type: 'updateState', payload: { pagination: defaultPagination, ...payload } })
      yield put({ type: 'query' })
    },

    *clear(_, { call, put, select, take }) {
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }

      yield put({
        type: 'updateState',
        payload: {
          pagination,
          Filter: '',
          filters: null
        }
      })

      yield put({ type: 'query' })
    }
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === centerListArea) {
    //       dispatch({ type: 'query' })
    //       dispatch({ type: 'getRegionList' })
    //     }
    //   })
    // },
  },
}
