import React from 'react'
import { Modal, Transfer } from 'antd'

export default class SelectModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      targetKeys: this.props.defaultSelectKeys,
      selectedKeys: [],
    }
  }

  handleOk = targetKeys => {
    this.props.onOk(targetKeys)
  }

  handleCancel = () => {
    this.setState({ selectedKeys: [], targetKeys: [] })
    this.props.onCancel()
  }

  // 写的不对
  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props.defaultSelectKeys !== prevProps.defaultSelectKeys) {
  //     this.setState({ targetKeys: this.props.defaultSelectKeys })
  //   }
  // }

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] })
  }

  render() {
    const { allList, selectModalVisible, currentTab } = this.props
    const { targetKeys, selectedKeys } = this.state

    const renderType = (item, type) => {
      switch (type) {
        case 'TrainingPlanLibrary':
        case 'OfflineTrainingPlan':
          return item.resource.displayName
        case 'TrainingCourses':
          return item.displayName
        case 'VRAT_Assessment':
          return item.DisplayName
        case 'Assessment':
          return item.resource.title + '-' + item.resource.name
        default:
          return item.resource.title
      }
    }

    const showData = allList.map((item, index) => {
      let itemData = null;
      switch (currentTab) {
        case 'TrainingPlanLibrary':
        case 'OfflineTrainingPlan':
          itemData = {
            ...item,
            key: item.resource.id,
            title: item.resource.displayName,
          };
          break;
        case 'VRAT_Assessment':
          itemData = {
            ...item,
            key: item.Id,
            title: item.DisplayName,
          };
          break;
        case 'TrainingCourses':
          itemData = {
            ...item,
            key: item.id,
            title: item.displayName,
            disabled: targetKeys.includes(item.id) || !item.inUse,
          };
          break;
        case 'Assessment':
          itemData = {
            ...item,
            key: item.resource.id,
            title: item.resource.title + '-' + item.resource.name,
          };
          break;
        default:
          itemData = {
            ...item,
            key: item.resource.id,
            title: item.resource.title,
          };
      }
      return itemData;
    })

    const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

    return (
      <Modal
        width={800}
        destroyOnClose={true}
        title={currentTab}
        open={selectModalVisible}
        onOk={() => this.handleOk(targetKeys)}
        onCancel={() => this.handleCancel()}
      >
        <Transfer
          listStyle={{
            width: 350,
            height: 400,
          }}
          dataSource={showData}
          titles={['Source', 'Target']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={this.handleChange}
          onSelectChange={this.handleSelectChange}
          render={item => renderType(item, currentTab)}
          filterOption={filterOption}
          showSearch
        />
      </Modal>
    )
  }
}
