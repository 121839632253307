import React, { useEffect } from 'react';
import { connect, useIntl } from 'umi'
import ShowDataAnalysis from 'components/ShowDataAnalysis'
import CardHead from 'components/CardHead';
import { Card, Spin } from 'antd'
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart'
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate'
import { getAnalysisSubTitle, getAnalysisData } from 'utils/analysis'
import { vratKeyIndicators, vratLegend } from '../config'
import style from './index.less';

const StatisticalData = ({ vratAnalysis, loading, dispatch }) => {

    const intl = useIntl()
    useEffect(() => {
        dispatch({ type: 'vratAnalysis/loadPage' })
        dispatch({ type: 'vratAnalysis/loadAnalysisData' })
    }, [])

    const { 
        keyIndicators,
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        analysisData,
        datePickerIsOpen
     } = vratAnalysis;

    if ( analysisData == null || keyIndicators == null) {
        return <Spin />
    }

    const { Trend } = analysisData;

    const isLoadingAnalysis = loading.effects['vratAnalysis/loadAnalysisData'];

    const showVratKeyIndicators = vratKeyIndicators(intl).map(item=>{
        if (item.hasOwnProperty('subTitle')) {
            const objData = keyIndicators[item.field]
            return {
                ...item,
                data: objData!==undefined? getAnalysisData(item.dataField, objData[item.dataField], intl): 0,
                subTitle: objData!==undefined?getAnalysisSubTitle(item.subTitleField, objData[item.subTitleField], intl): ''
            }

            // if(objData){
            //     item.data = getAnalysisData(item.dataField, objData[item.dataField])
            //     if (objData[item.dataField]) {
            //         item.subTitle = getAnalysisSubTitle(item.subTitleField, objData[item.subTitleField])
            //     }
            // } else {
            //     item.data = 0
            //     item.subTitle = ''
            // }
        } else {
            return {
                ...item,
                data: keyIndicators[item.field]
            }
        }
    })

    const chartProps = {
        legendData: vratLegend(intl),
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        dataSource: Trend
    };

    const changeTag = (tag, checked) => {
        dispatch({
            type: 'vratAnalysis/changeTag',
            payload: {
                key: tag.key
            }
        })
    }

    const handleOpenChange = () => {
        dispatch({
            type: 'vratAnalysis/updateState',
            payload: {
                datePickerIsOpen: !datePickerIsOpen
            }
        })
    }

    const handlePanelChange = (time) => {
        dispatch({
            type: 'vratAnalysis/handlePanelChange',
            payload: {
                time
            }
        })
    }

    const analysisChoiceDateProps = {
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        datePickerIsOpen,
        changeTag,
        handleOpenChange,
        handlePanelChange
    }
    return (
        <div className={style.vratAnalysis}>
            <div className={style.keyIndicators}>
                <CardHead title={intl.formatMessage({ id: 'keyIndicators' })}  />
                <ShowDataAnalysis keyIndicators={showVratKeyIndicators} />
            </div>
            <Spin
                spinning={isLoadingAnalysis}
            >
                <div className={style.trendAnalysis}>
                    <CardHead title={intl.formatMessage({ id: 'trendAnalysis' })}  />
                    <AnalysisChoiceDate {...analysisChoiceDateProps} />
                    <div className={style.trendAnalysisContent}>
                        <CommonAnalysisLineChart {...chartProps} />
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default connect(({ vratAnalysis, loading }) => ({ vratAnalysis, loading }))(StatisticalData);