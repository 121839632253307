import React from 'react'
import { connect } from 'dva'
import { Row, Spin } from 'antd'
import MyProcess from 'components/MyProcess'
import headIcon from 'assets/vratReportImg/blackHeader.png'
import handIcon from 'assets/vratReportImg/blackHand.png'
import upIcon from 'assets/vratReportImg/upIcon.png'
import downIcon from 'assets/vratReportImg/downIcon.png'
import vrClassroomImgCN from 'assets/vratReportImg/VR_classroom_bg_cn.png'
import MovementPathMotionType from 'components/VratChartComponents/MovementPathMotionType'
import MovementPathBlocks from 'components/VratChartComponents/MovementPathBlocks'
import RoseChart from 'components/VratChartComponents/RoseChart'
import { useIntl } from 'umi'
import styles from './index.less'

const TwoPage = ({ sixDimension, sixDimensionLoading, pathLength, pathLengthLoading, motionCharacteristics, motionCharacteristicsLoading, directionTracking, directionTrackingLoading, movementPath, movementPathLoading }) => {

  const intl = useIntl()
  // 注意力和动作分维度分析
  const Attention = sixDimension ? sixDimension.Attention * 100 : 0
  const SelfControl = sixDimension ? sixDimension.SelfControl * 100 : 0
  const Reactivity = sixDimension ? sixDimension.Reactivity * 100 : 0
  const ReactionStability = sixDimension ? sixDimension.ReactionStability * 100 : 0
  const Sensitivity = sixDimension ? sixDimension.Sensitivity * 100 : 0
  const ContinuousAttention = sixDimension ? sixDimension.ContinuousAttention * 100 : 0

  const PathLength = pathLength ? pathLength.PathLength : []
  const Summaries = pathLength ? pathLength.Summarys : []

  // 头部路径
  const headPath = PathLength.length > 0 && PathLength.filter(item => {
    return !item.IsMedian && item.MotionType === 0
  })

  // 手部路径
  const handPath = PathLength.length > 0 && PathLength.filter(item => {
    return !item.IsMedian && item.MotionType === 1
  })

  // 头部路径中位数
  const headMedian = PathLength.length > 0 && PathLength.filter(item => {
    return item.IsMedian && item.MotionType === 0
  })
  // 手部路径中位数
  const handMedian = PathLength.length > 0 && PathLength.filter(item => {
    return item.IsMedian && item.MotionType === 1
  })

  const PercentileToString = percentile => {
    if (percentile === undefined) return ''
    else return `${(percentile * 100).toFixed(0)}`
  }
  // type 0->3 ['Head','Hand','Chest','Leg']
  const FindByType = (list, type) => {
    const result = list.find(item => {
      return item.MotionType === type
    })
    return result !== undefined ? result : {}
  }

  const percent0 = PercentileToString(1 - (FindByType(Summaries, 0).Percentile || 0))
  const percent1 = PercentileToString(1 - (FindByType(Summaries, 1).Percentile || 0))

  const hyperactivityIndex = motionCharacteristics ? (100 - motionCharacteristics.Hyperactivity * 100).toFixed(0) : 0
  const areaIndex = motionCharacteristics ? (100 - motionCharacteristics.Area * 100).toFixed(0) : 0

  const percent = directionTracking ? `${(directionTracking.Percentile * 100).toFixed(0)}` : '0'

  //type 0->3 blockNum 1-4
  const GetByType = (list, type, blockNum) => {
    const result = list.filter(item => {
      return item.MotionType === type
    })

    const res = result.filter(item => {
      return item.Block === blockNum
    })
    return res
  }

  const Points = movementPath ? movementPath.Points : []

  // Head block 1~4
  const showPointsHeadBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 0, 1),
          type: "medical"
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 0, 2),
          type: "medical"
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 0, 3),
          type: "medical"
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 0, 4),
          type: "medical"
        },
      },
    },
    motionIcon: headIcon,
    type: "medical",
    motionType: intl.formatMessage({ id: 'report.movementPath.chart.head' }),
  }
  // Hand block 1~4
  const showPointsHandBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 1, 1),
          type: "medical"
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 1, 2),
          type: "medical"
        },

      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 1, 3),
          type: "medical"
        },

      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 1, 4),
          type: "medical"
        },

      },
    },
    motionIcon: handIcon,
    type: "medical",
    motionType: intl.formatMessage({ id: 'report.movementPath.chart.hand' }),
  }

  const block1Props = {
    styles,
    number: 1,
  }
  const block2Props = {
    styles,
    number: 2,
  }
  const block3Props = {
    styles,
    number: 3,
  }
  const block4Props = {
    styles,
    number: 4,
  }

  const showData = directionTracking ? directionTracking.Time : []

  const RoseChartProps = {
    data: showData,
    type: 'medical'
  }

  return (
    <div id="basic" className={`${styles.page_div}`}>
      <Spin
        spinning={sixDimensionLoading && motionCharacteristicsLoading && pathLengthLoading && directionTrackingLoading}
        className={styles.spinnerWrapperText}
      >
        <div className={styles.headerTextFirst}>三、注意力和动作分维度分析</div>
        <div className={styles.headerTextBody}>
          <table border="1" align="center" valign="middle">
            <tbody>
              <tr align="center" className={styles.twoTrStyle}>
                <td className={styles.firstLine}></td>
                <td className={styles.secondLine}>维度项</td>
                <td className={styles.secondLine}>人群百分比 (%)</td>
                <td className={styles.lastLine}></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td rowSpan="6" className={styles.firstLine}>注意力维度</td>
                <td><b>目标专注力</b></td>
                <td>{Attention.toFixed(0)}</td>
                <td><MyProcess percent={Attention} /></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>行为自控力</b></td>
                <td>{SelfControl.toFixed(0)}</td>
                <td><MyProcess percent={SelfControl} /></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>反应力</b></td>
                <td>{Reactivity.toFixed(0)}</td>
                <td><MyProcess percent={Reactivity} /></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>反应稳定性</b></td>
                <td>{ReactionStability.toFixed(0)}</td>
                <td><MyProcess percent={ReactionStability} /></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>分辨力</b></td>
                <td>{Sensitivity.toFixed(0)}</td>
                <td><MyProcess percent={Sensitivity} /></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>持续注意力</b></td>
                <td>{ContinuousAttention.toFixed(0)}</td>
                <td><MyProcess percent={ContinuousAttention} /></td>
              </tr>

              <tr align="center" className={styles.twoTrStyle}>
                <td rowSpan="5" className={styles.firstLine}>动作维度</td>
                <td><b>头部路径长度</b></td>
                <td>{percent0}</td>
                <td><MyProcess percent={percent0} /></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>手部路径长度</b></td>
                <td>{percent1}</td>
                <td><MyProcess percent={percent1} /></td>
              </tr>
              {/* <tr align="center" className={styles.twoTrStyle}>
                <td><b>头部稳定</b></td>
                <td>{percent.toFixed(0)}</td>
                <td><MyProcess percent={percent} /></td>
              </tr> */}
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>多动指数</b></td>
                <td>{hyperactivityIndex}</td>
                <td><MyProcess percent={hyperactivityIndex} /></td>
              </tr>
              <tr align="center" className={styles.twoTrStyle}>
                <td><b>动作范围指数</b></td>
                <td>{areaIndex}</td>
                <td><MyProcess percent={areaIndex} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Spin>
      <Spin
        spinning={movementPathLoading}
        className={styles.spinnerWrapperText}>
        <div className={styles.headerTextFirst}>四、动作行为表现</div>
        <div className={styles.scale}>
          <div style={{ marginTop: 90, position: 'relative' }}>
            <span className={styles.movementPathText}>
              {intl.formatMessage({ id: 'report.movementPath.chart.yAxis' })}
            </span>
            <MovementPathMotionType {...showPointsHeadBlocksProps} />
            <MovementPathMotionType {...showPointsHandBlocksProps} />
          </div>
          <Row type="flex" justify="center">
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block1Props} />
            </div>
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block2Props} />
            </div>
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block3Props} />
            </div>
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block4Props} />
            </div>
          </Row>
          <h3 style={{ textAlign: 'center', marginTop: -10 }} className={styles.weightFont}>
            {intl.formatMessage({ id: 'report.movementPath.chart.title' })}
          </h3>
        </div>
        <div className={styles.scaleLook}>
          <div className={styles.scaleLookImg}>
            <img src={vrClassroomImgCN} />
          </div>
          <RoseChart {...RoseChartProps} />
          <h2 className={`${styles.brainPic} ${styles.weightFont}`}>
            {intl.formatMessage({ id: 'report.directionTracking.chart.title' })}
          </h2>
        </div>
        <div className={styles.lastTable}>
          <table border="1" align="center" valign="middle">
            <tbody>
              <tr align="center" className={styles.lastTableTr}>
                <td className={styles.lastTableTitle}>运动路径长度(um*)</td>
                <td className={styles.lastTableContent}>区间1</td>
                <td className={styles.lastTableContent}>区间2</td>
                <td className={styles.lastTableContent}>区间3</td>
                <td className={styles.lastTableContent}>区间4</td>
                <td className={styles.lastTableContent}>总计</td>
              </tr>
              <tr align="center" className={styles.lastTableTr}>
                <td><b>头部路径长度</b></td>
                {(headPath && headMedian) && headPath.slice(1, headPath.length).map((item, index) => {
                  if (item.Value > headMedian[index + 1].Value) {
                    return <td key={index}>{item.Value.toFixed(1)}<img src={upIcon} /></td>
                  } else {
                    return <td key={index}>{item.Value.toFixed(1)}<img src={downIcon} /></td>
                  }
                })}
                <td>{headPath && headPath.length > 0 && headPath[0].Value.toFixed(1)}
                  {(headPath && headMedian && headPath.length > 0 && headMedian.length > 0) && headPath[0].Value > headMedian[0].Value
                    ? <img src={upIcon} />
                    : headPath && headPath.length !== 0 && <img src={downIcon} />}
                </td>
                {headPath && headPath.length === 0 && <><td></td><td></td><td></td><td></td></>}
              </tr>
              <tr align="center" className={styles.lastTableTr}>
                <td><b>中位数(50%)</b></td>
                {headMedian && headMedian.slice(1, headMedian.length).map((item, index) => {
                  return (<td key={index}>{item.Value.toFixed(1)}</td>);
                })}
                <td>{headMedian && headMedian.length > 0 && headMedian[0].Value.toFixed(1)}</td>
                {headMedian && headMedian.length === 0 && <><td></td><td></td><td></td><td></td></>}
              </tr>
              <tr align="center" className={styles.lastTableTr}>
                <td><b>手部路径长度</b></td>
                {(handPath && handMedian) && handPath.slice(1, handPath.length).map((item, index) => {
                  if (item.Value > handMedian[index + 1].Value) {
                    return <td key={index}>{item.Value.toFixed(1)}<img src={upIcon} /></td>
                  } else {
                    return <td key={index}>{item.Value.toFixed(1)}<img src={downIcon} /></td>
                  }
                })}
                <td>{handPath && handPath.length > 0 && handPath[0].Value.toFixed(1)}
                  {(handPath && handMedian && handPath.length > 0 && handMedian.length > 0) && handPath[0].Value > handMedian[0].Value
                    ? <img src={upIcon} />
                    : handPath && handPath.length !== 0 && <img src={downIcon} />}
                </td>
                {handPath && handPath.length === 0 && <><td></td><td></td><td></td><td></td></>}
              </tr>
              <tr align="center" className={styles.lastTableTr}>
                <td><b>中位数(50%)</b></td>
                {handMedian && handMedian.slice(1, handMedian.length).map((item, index) => {
                  return (<td key={index}>{item.Value.toFixed(1)}</td>);
                })}
                {handMedian.length === 0 && <><td></td><td></td><td></td><td></td></>}
                <td>{handMedian && handMedian.length > 0 && handMedian[0].Value.toFixed(1)}</td>
              </tr>
            </tbody>
          </table>
          <div className={styles.tableDescription}>*路径长度单位：1um 表示虚拟教室中的1米。</div>
        </div>
      </Spin>
      <span className={styles.page_number}>2</span>
    </div>
  )
}

export default connect()(TwoPage)
