import React from 'react';
import ShowCardBorder from './ShowCardBorder';
import { useIntl } from 'umi';
import CustomMicroApp from 'components/CustomMicroApp';

import styles from './index.less';

const CbtOverview = ({ cbtData, isEmpty }) => {
  const intl = useIntl();

  const props = {
    url: '/cbtHome',
    appProps: {
      cbtData,
    },
  };

  return (
    <div className={styles.cbtpanel}>
      <div className={styles.cbtContent}>
        <ShowCardBorder
          title={intl.formatMessage({ id: 'CBTSessionsCompleted' })}
          footer={intl.formatMessage({ id: 'Week' })}
        >
          {isEmpty ? (
            <div>{intl.formatMessage({ id: 'emptyData' })}</div>
          ) : (
            <CustomMicroApp {...props} />
          )}
        </ShowCardBorder>
      </div>
    </div>
  );
};

export default CbtOverview;
