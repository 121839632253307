import React, { useEffect } from 'react';
import { connect } from 'umi'
import ShowDataAnalysis from 'components/ShowDataAnalysis'
import CardHead from 'components/CardHead';
import { Card, Spin } from 'antd'
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart'
import CommonPieTable from 'cognitiveleap-core-us/components/Charts/Common/CommonPieTable'
import { guardianLegend, guardianKeyIndicators, tableColumns, guardianGengerDataSource } from '../config';
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate'
import { getProportionData } from 'cognitiveleap-core-us/utils/utils';
import { useIntl } from 'umi'
import style from './index.less';

const GuardianAnalysis = ({ guardianAnalysis, loading, dispatch }) => {
    const intl = useIntl()
    const { 
        currentTag,
        datePickerIsOpen,
        dateMonthPickerData,
        dateYearPickerData,
        analysisData,
        keyIndicators
     } = guardianAnalysis;

     useEffect(() => {
        dispatch({ type: 'guardianAnalysis/loadKeyIndicators' })
        dispatch({ type: 'guardianAnalysis/loadAnalysisData' }) 
     }, [])

     if ( analysisData == null || keyIndicators == null) {
        return <Spin />
    }
    const { genderChart, trend } = analysisData;

    const isLoadingAnalysis = loading.effects['guardianAnalysis/loadAnalysisData'];

    const chartProps = {
        legendData: guardianLegend(intl),
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        dataSource: trend
    };

    const genderTotalNum = genderChart.length> 0 && genderChart.map(item => item.num).reduce((prev, cur) => {
        return prev + cur
    });

    const showGuardianGengerDataSource = guardianGengerDataSource(intl).map(item=>{
        const data = genderChart.find( genderItem => genderItem.gender == item.gender );
        return {
            ...item,
            number:data!==undefined?data.num:0,
            proportion: data!==undefined?getProportionData(data.num, genderTotalNum): '0%'
        }
    })

    const showGuardianKeyIndicators = guardianKeyIndicators(intl).map(item=>{
        return {
            ...item,
            data: keyIndicators[item.field]
        }
    })

    const genderProps = {
        title: intl.formatMessage({ id: 'genderDistribution' }),
        color: ['#69C0FF', '#FFADD2', '#D9F7BE', '#E8E8E8'],
        totalNum: genderTotalNum,
        columns: tableColumns(intl),
        dataSource: showGuardianGengerDataSource,
        legendData: [intl.formatMessage({ id: 'male' }), intl.formatMessage({ id: 'female' }), intl.formatMessage({ id: 'unknown' }), intl.formatMessage({ id: 'other' })]
    }

    const changeTag = (tag, checked) => {
        dispatch({
            type: 'guardianAnalysis/changeTag',
            payload: {
                key: tag.key
            }
        })
    }

    const handleOpenChange = () => {
        dispatch({
            type: 'guardianAnalysis/updateState',
            payload: {
                datePickerIsOpen: !datePickerIsOpen
            }
        })
    }

    const handlePanelChange = (time) => {
        dispatch({
            type: 'guardianAnalysis/handlePanelChange',
            payload: {
                time
            }
        })
    }

    const analysisChoiceDateProps = {
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        datePickerIsOpen,
        changeTag,
        handleOpenChange,
        handlePanelChange
    }
    return (
    <Card title={<b style={{fontSize: '20px'}}>{intl.formatMessage({ id: 'parentUserAnalysis' })}</b>} bordered={false}>
            <div className={style.guardianAnalysis}>
                <div className={style.keyIndicators}>
                    <CardHead title={intl.formatMessage({ id: 'keyIndicators' })}  />
                    <ShowDataAnalysis keyIndicators={showGuardianKeyIndicators} />
                </div>
                <Spin
                    spinning={isLoadingAnalysis}
                >
                    <div className={style.trendAnalysis}>
                        <CardHead title={intl.formatMessage({ id: 'trendAnalysis'})}  />
                        <AnalysisChoiceDate {...analysisChoiceDateProps} />
                        <div className={style.trendAnalysisContent}>
                            <CommonAnalysisLineChart {...chartProps} />
                            <div className={style.trendAnalysisContentChart}><CommonPieTable {...genderProps} /></div>
                        </div>
                    </div>
                </Spin>
            </div>
        </Card>
    )
}

export default connect(({ guardianAnalysis, loading }) => ({ guardianAnalysis, loading }))(GuardianAnalysis);