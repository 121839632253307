import { GetTrainerDatabaseList } from 'services/trainerDatabase'

export default {
    namespace: 'trainerDatabase',
    state:{
        trainerDatabaseInfo: null,
        pagination: {
          current: 1,
          pageSize: 10,
        },
        Filter: ''
    },
    reducers: {
        updateState(state, { payload }) {
          return {
            ...state,
            ...payload,
          }
        },
    },
    effects: {
        *getTrainerDatabaseList( { payload }, { all, call, put, select }){
            const { pagination, Filter } = yield select(state => state.trainerDatabase);
            const res = yield call(GetTrainerDatabaseList,{
                Type: 'TrainingMaterials',
                // Type: 'Course',
                SkipCount: ( pagination.current - 1 ) * ( pagination.pageSize ),
                MaxResultCount: pagination.pageSize,
                Filter,
                IncludeContent: true
            });
            if (res.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: { trainerDatabaseInfo:  res.data},
                })
            }
        },
        *onSearch({ payload: {pagination, title} }, { all, call, put, select }){
          yield put({
            type: 'updateState',
            payload: { 
              pagination: pagination,
              Filter: title
            },
          });
          yield put({type: 'getTrainerDatabaseList'});
        }
    },
    subscriptions: {
        // setupHistory({ dispatch, history }) {
        //   return history.listen(location => {
        //     if (location.pathname === '/system/center/menu/trainerDatabase') {
        //       dispatch({ type: 'getTrainerDatabaseList' })
        //     }
        //   })
        // },
    },
}