import React from 'react'
import { CanvasConfetti } from 'utils/confetti'
import { SHOWTYPE } from './models/activate'
import { Result } from 'antd'

export default class Clock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countDown: 5,
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  tick() {
    if (this.state.countDown > 1) {
      this.setState({
        countDown: this.state.countDown - 1,
      })
    } else {
      const { onComplete } = this.props
      if (onComplete) onComplete()
    }
  }

  getShowText(type, intl) {
    switch (type) {
      case SHOWTYPE.ErrorLink:
        return intl.formatMessage({ id: 'activeFailMsg' })
      case SHOWTYPE.ExistUser:
        return intl.formatMessage({ id: 'accountExist' })
      case SHOWTYPE.UserAlreadyActive:
        CanvasConfetti.playSchoolPride();
        return intl.formatMessage({ id: 'accountActived' })
      case SHOWTYPE.UnknownError:
      default:
        return intl.formatMessage({ id: 'accountActiveFail' })
    }
  }

  showStatusIcon(type) {
    switch (type) {
      case SHOWTYPE.ErrorLink:
        return 'warning'
      case SHOWTYPE.ExistUser:
      case SHOWTYPE.UserAlreadyActive:
        return 'success'
      case SHOWTYPE.UnknownError:
      default:
        return 'warning'
    }
  }

  render() {
    const { showType, intl } = this.props
    const { countDown } = this.state
    const showText = this.getShowText(showType, intl).replace('replacement', countDown)
    return (
      <div style={{ marginTop: 100, padding: '0 10% 0 10%' }}>
        <Result title={showText} status={this.showStatusIcon(showType)} />
      </div>
    )
  }
}
