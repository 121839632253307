import { message } from 'antd';
import { matchDynamicForm, deepCopy } from 'cognitiveleap-core-us/utils/utils';
import { onPreviewVideo } from 'utils/utils';
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
  PostCourseContentDetail,
} from 'services/rocketService/Prepare';
import { PutCourseConetentDetail, GetVideoUrl } from 'services/video';
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';
import config from 'utils/config';
import coreConfig from 'cognitiveleap-core-us/utils/config';
const {
  baseURL,
  uploadCourseMedias,
  uploadTrainingMedias,
  uploadCourseMediasVod,
} = config;
const { videoSeparator } = coreConfig;
const uploadUrl = uploadCourseMedias;

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'name',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
          onPreview(file) {
            onPreviewVideo(file);
          },
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: 'resource',
      FormKey: 'resource',
    },
    {
      EditorType: 'Upload',
      Value: [],
      Setting: {
        UploadSetting: {
          isSingle: false,
          onPreview(file) {
            onPreviewVideo(file);
          },
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: 'resourceList',
      FormKey: 'resourceList',
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: 'detail',
      FormKey: 'detail',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: '无',
            Id: 'Null',
          },
          {
            Value: '音频',
            Id: 'Audio',
          },
          {
            Value: '视频',
            Id: 'Video',
          },
          {
            Value: '富文本',
            Id: 'Richtext',
          },
          {
            Value: '文件',
            Id: 'Doc',
          },
        ],
        Required: true,
      },
      ShowTitle: 'contentType',
      FormKey: 'contentType',
      Description: null,
    },
    {
      EditorType: 'TimePicker',
      ShowTitle: 'duration',
      FormKey: 'duration',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: 'experience',
      FormKey: 'experience',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '页数',
      FormKey: 'pages',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '预计学习时长(分钟)：',
      FormKey: 'timeConsuming',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: '请输入正整数',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: false,
        },
        Action: baseURL + uploadTrainingMedias,
        Header: getAuthHeader(),
      },
      ShowTitle: '附件',
      FormKey: 'attachments',
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: false,
      },
      ShowTitle: '问卷',
      FormKey: 'questionnaires',
      Description: null,
    },
  ],
};

const updateVidContent = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'vid',
      FormKey: 'vid',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
  ],
};

const listSupport = {
  name: {
    showText: '名字',
    showType: 'Text',
  },
  contentType: {
    showText: '类型',
    showType: 'Text',
  },
  duration: {
    showText: 'duration',
    showType: 'Text',
  },
  experience: {
    showText: '可试听',
    showType: 'Bool',
  },
  pages: {
    showText: '页数',
    showType: 'Text',
  },
};

export default {
  namespace: 'courseItem',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    searchInput: '',
    listSupport: {},
    modalVisible: false,
    updateVidVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
    updateVidContent: updateVidContent,
    selectVidItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, searchInput } = yield select(
        (state) => state.courseItem,
      );
      let query = { skipCount: 0, maxResultCount: 10, filter: searchInput };

      const currentPageIndex = pagination.current;
      const currentPageSize = pagination.pageSize;
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      };

      const res = yield call(GetPrepareList, query, 'courseItem');
      if (res.response.ok) {
        const { items, totalCount } = res.data;
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        };
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        });
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      yield put({ type: 'updateState', payload: data });
      yield put({ type: 'query' });
    },

    *onEdit({ payload: id }, { call, put, select }) {
      const res = yield call(PostCourseContentDetail, { id });
      if (res.response.ok) {
        const { contentsDetail, availableQuestionnaires } = res.data || {};
        const { resource, detail, questionnaires, contentType } =
          contentsDetail || {};
        let emptyContent = deepCopy(contentMenu);
        // 如果是视频就要改变上传路径
        if (contentType === 'Video') {
          emptyContent.Properties[1].Setting.UploadSetting.uploadType = 'video';
          emptyContent.Properties[2].Setting.UploadSetting.uploadType = 'video';

          emptyContent.Properties[1].Setting.Action =
            baseURL + uploadCourseMediasVod;
          emptyContent.Properties[2].Setting.Action =
            baseURL + uploadCourseMediasVod;
        } else {
          emptyContent.Properties[1].Setting.UploadSetting.uploadType = '';
          emptyContent.Properties[2].Setting.UploadSetting.uploadType = '';

          emptyContent.Properties[1].Setting.Action = baseURL + uploadUrl;
          emptyContent.Properties[2].Setting.Action = baseURL + uploadUrl;
        }
        emptyContent.Properties[10].Setting.DropdownOptions =
          availableQuestionnaires.map((item) => {
            return {
              Value: item.displayName,
              Id: item.id,
            };
          });
        const insertValue = {
          ...contentsDetail,
          video: resource,
          draftContent: detail,
          questionnaires:
            questionnaires && questionnaires.length > 0
              ? questionnaires[0]
              : '',
        };
        const editUserInfoForm = matchDynamicForm(emptyContent, insertValue);
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: contentsDetail,
          },
        });
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'courseItem');
      if (res.response.ok) {
        yield put({ type: 'query' });
      } else {
        const { message: msg } = res.data.error || { message: '删除小节失败' };
        message.error(msg);
      }
    },

    *onAdd(_, { call, put, select }) {
      const res = yield call(PostCourseContentDetail, { });

      if (res.response.ok) {
        const { availableQuestionnaires = [] } = res.data || {};
        let emptyContent = deepCopy(contentMenu);

        emptyContent.Properties[10].Setting.DropdownOptions =
          availableQuestionnaires.map((item) => {
            return {
              Value: item.displayName,
              Id: item.id,
            };
          });

        yield put({
          type: 'updateState',
          payload: { modalType: 'add', content: emptyContent, modalVisible: true },
        });
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(
        (state) => state.courseItem,
      );
      data.questionnaires =
        data.questionnaires && data.questionnaires.length > 0
          ? [data.questionnaires]
          : [];
      let res;
      if (modalType === 'edit') {
        res = yield call(
          PutPrepareData,
          { ...selectItem, ...data },
          selectItem.id,
          'courseItem',
        );
      } else {
        res = yield call(PostPrepareData, data, 'courseItem');
      }
      if (res.response.ok) {
        yield put({ type: 'query' });
        yield put({ type: 'updateState', payload: { modalVisible: false } });
      }
    },

    *updateVid({ payload }, { take, put, call, select }) {
      const { resourceList } = payload;
      let id = null;
      if (resourceList && resourceList.length > 0) {
        id = resourceList[0].id;
      }
      const content = { ...updateVidContent };
      content.Properties[0].Value =
        id && id.includes(videoSeparator) ? id.split(videoSeparator)[0] : null;
      yield put({
        type: 'updateState',
        payload: {
          updateVidVisible: true,
          selectVidItem: payload,
          updateVidContent: content,
        },
      });
    },

    *onsubmitVid({ payload }, { take, put, call, select }) {
      const { vid } = payload;
      const { selectVidItem } = yield select((state) => state.courseItem);
      const signResult = yield call(GetVideoUrl, { vid });
      if (signResult.response.ok) {
        const signResultData = signResult.data;
        const { title } = signResultData || {};

        const putCourseRes = yield call(PutCourseConetentDetail, {
          vid: `${vid}${videoSeparator}${title}`,
          id: selectVidItem && selectVidItem.id,
        });
        if (putCourseRes.response.ok) {
          message.success('更新成功');
          yield put({
            type: 'updateState',
            payload: {
              updateVidVisible: false,
            },
          });
          yield put({ type: 'query' });
        } else {
          message.error('更新失败');
        }
      } else {
        const { error } = signResult.data;
        const { message: errorInfo } = error || {};
        errorInfo && message.error(errorInfo);
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/course/item') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
};
