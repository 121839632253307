import { useEffect } from 'react';
import { history } from 'umi';
import { Button, Modal, Table, Divider, Switch } from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'
import { connect } from 'umi'
import SortOrderModal from 'components/SortOrderModal'
import SelectModal from './SelectModal'

const { confirm } = Modal

const CourseCategoryDetail = ({ dispatch, courseCategoryDetail, location }) => {
  const {
    modalVisible,
    courseCategoryInfo,
    content,
    selectItem,
    sortOrderModal,
    list,
    type
  } = courseCategoryDetail

  const { name = '', displayName = '' } = courseCategoryInfo || {}
  
  const onAddBtnClick = () => {
    dispatch({ type: 'courseCategoryDetail/onAdd' })
  }

  const onChangeSortOrder = selectItem => {
    dispatch({
      type: 'courseCategoryDetail/updateState',
      payload: { selectItem, sortOrderModal: true },
    })
  }

  const onDelete = id => {
    confirm({
      title: 'Are you sure to remove this course from current category?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch({ type: 'courseCategoryDetail/deleteItem', payload: id })
      },
    })
  }

  useEffect(() => {
    dispatch({ type: 'courseCategoryDetail/updateState', payload: { courseCategoryId: location.query.id, type: location.query.type, list: [] } })
    dispatch({ type: 'courseCategoryDetail/loadPage' })
  }, [])

  const getColumnsByType = () => {
    let columns = []
    switch(type){
      case "course":
        columns = [
          {
            title: 'title',
            dataIndex: 'title',
          },
          {
            title: 'subTitle',
            dataIndex: 'subTitle',
          },
          {
            title: 'length',
            dataIndex: 'length',
          },
          {
            title: 'recommended',
            dataIndex: 'recommended',
            render: recommended => <span>{recommended ? 'true' : 'false'}</span>,
          },
          {
            title: 'isVip',
            dataIndex: 'isVip',
            render: isVip => <span>{isVip ? 'true' : 'false'}</span>,
          },
          {
            title: 'status',
            dataIndex: 'status',
          },
          {
            title: 'sortOrder',
            dataIndex: 'sortOrder',
          },
          {
            title: 'Action',
            render: (text, record) => (
              <span>
                <a onClick={() => onChangeSortOrder(record)}>Change SortOrder</a>
                <Divider type="vertical" />
                <a onClick={() => onDelete(record.id)}>Delete</a>
              </span>
            ),
          },
        ]
        break
      case "trainingModule":
        columns = [
          {
            title: 'title',
            dataIndex: 'title',
          },
          {
            title: 'subTitle',
            dataIndex: 'subTitle',
          },
          {
            title: 'length',
            dataIndex: 'length',
          },
          {
            title: 'sortOrder',
            dataIndex: 'sortOrder',
          },
          {
            title: 'Action',
            render: (text, record) => (
              <span>
                <a onClick={() => onChangeSortOrder(record)}>Change SortOrder</a>
                <Divider type="vertical" />
                <a onClick={() => onDelete(record.id)}>Delete</a>
              </span>
            ),
          },
        ]
        break
    }
    return columns
  }

  const getDataByType = () => {
    let data = []
    switch(type){
      case "course":
        data = list;
        break
      case "trainingModule":
        data = list.map(item => {
          const { course, courseId, moduleId, sortOrder } = item
          return { ...course, courseId, moduleId, sortOrder }
        })
    }
    return data
  }

  const getName = () => {
    switch(type){
      case "course":
        return name;
      case "trainingModule":
        return displayName
    }
  }

  const onBack = () => {
    history.goBack()
  }

  const selectModalProps = {
    defaultSelectKeys: getDataByType().map(item => item.id),
    allList: content ? content.items : [],
    selectModalVisible: modalVisible,
    onOk(keys) {
      dispatch({ type: 'courseCategoryDetail/addCourse', payload: keys })
    },
    onCancel() {
      dispatch({ type: 'courseCategoryDetail/updateState', payload: { modalVisible: false } })
    },
  }

  const sortOrderProps = {
    visible: sortOrderModal,
    onCancel() {
      dispatch({ type: 'courseCategoryDetail/updateState', payload: { sortOrderModal: false } })
    },
    onOk(data) {
      dispatch({ type: 'courseCategoryDetail/changeSortOrder', payload: data.sortOrder })
    },
    currentValue: selectItem && selectItem.sortOrder,
  }

  return (
    <div>
      <h1>{getName()}</h1>
      <Button icon={<CaretLeftOutlined />} onClick={onBack}>
        Back
      </Button>
      <Divider type="vertical" />
      <Button onClick={onAddBtnClick}>添加进分类</Button>
      <Divider />
      <Table dataSource={getDataByType()} columns={getColumnsByType()} rowKey={'id'} />
      <SortOrderModal {...sortOrderProps} />
      <SelectModal {...selectModalProps}/>
    </div>
  )
}

export default connect(({ courseCategoryDetail }) => ({ courseCategoryDetail }))(
  CourseCategoryDetail
)
