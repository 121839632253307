import React from 'react';
import { useIntl } from 'umi';
import moment from 'moment';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

const listSupport = (intl) => {
  return {
    order: {
      showText: intl.formatMessage({ id: 'OrderPdt' }),
      showType: 'Text',
    },
    title: {
      showText: intl.formatMessage({ id: 'SessionName' }),
      showType: 'Text',
    },
    type: {
      showText: intl.formatMessage({ id: 'type' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({ id: text.toLowerCase() });
      },
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({ id: text.toLowerCase() });
      },
    },
    pushedAt: {
      showText: intl.formatMessage({ id: 'AssignedDate' }),
      showType: 'Time',
    },
    time: {
      showText: intl.formatMessage({ id: 'CompletionTime' }),
      showType: 'Time',
      render: (text, record) => {
        const { status } = record || {};
        return status === 'completed'
          ? moment(text).local().format('YYYY-MM-DD')
          : '-';
      },
    },
  };
};

const CbtWeekly = ({ cbtData, currentWeek }) => {
  const intl = useIntl();

  const listData = cbtData
    .filter((item) => {
      const { cbt } = item;
      const { weekIndex } = cbt;
      return weekIndex == currentWeek;
    })
    .sort((a, b) => b.cbt.dayIndex - a.cbt.dayIndex);

  const tableProps = {
    listData: listData.map((item) => {
      const { cbt, updatedAt } = item;
      const { weekIndex, dayIndex, type, title } = cbt;
      const order = intl.formatMessage(
        { id: 'whickWeekOrDay' },
        { week: weekIndex, day: dayIndex },
      );
      return {
        order,
        type,
        title,
        time: updatedAt,
        ...item,
      };
    }),
    listSupport: listSupport(intl),
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <MagicTable {...tableProps} />
    </div>
  );
};

export default CbtWeekly;
