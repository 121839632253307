import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import ScoresTitle from './components/ScoresTitle';
import BottomPageNum from './components/BottomPageNum';
import { useIntl } from 'umi';

const getLevelText = (value, intl) => {
  if (value >= 0 && value < 33) {
    return intl.formatMessage({ id: 'MuchBelowAverage' });
  } else if (value >= 33 && value < 50) {
    return intl.formatMessage({ id: 'BelowAverage' });
  } else if (value >= 50 && value < 66) {
    return intl.formatMessage({ id: 'AboveAverage' });
  } else if (value >= 66 && value <= 100) {
    return intl.formatMessage({ id: 'MuchAboveAverage' });
  }
};

const FirstScoresDes = ({ data }) => {
  const intl = useIntl();
  const {
    Attention = 0,
    SelfControl = 0,
    Reactivity = 0,
  } = data || {};

  const _Attention = Math.round(Attention * 100);
  const _Reactivity = Math.round(Reactivity * 100);
  const _SelfControl = Math.round(SelfControl * 100);

  const firstDesProps = {
    num: '01',
    title: intl.formatMessage({ id: 'FirstScoresDes.attention' }),
    firstDes: intl.formatMessage({ id: 'FirstScoresDes.refers' }),
    secondDes: intl.formatMessage(
      { id: 'FirstScoresDes.focused' },
      { attention: _Attention, _Attention: getLevelText(_Attention, intl) },
    ),
  };

  const twoDesProps = {
    num: '02',
    title: intl.formatMessage({ id: 'TwoScoresDes.self' }),
    firstDes: intl.formatMessage({ id: 'TwoScoresDes.impulse' }),
    secondDes: intl.formatMessage(
      { id: 'TwoScoresDes.scored' },
      { selfControl: _SelfControl, _SelfControl: getLevelText(_SelfControl, intl) },
    ),
  };

  const threeDesProps = {
    num: '03',
    title: intl.formatMessage({ id: 'TwoScoresDes.speed' }),
    firstDes: intl.formatMessage({ id: 'TwoScoresDes.represents' }),
    secondDes: intl.formatMessage(
      { id: 'TwoScoresDes.secondDes' },
      {
        reactivity: _Reactivity,
        _Reactivity: getLevelText(_Reactivity, intl),
      },
    ),
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.firstScoresDes}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.scoresTitle}>
            {intl.formatMessage({ id: 'FirstScoresDes.scoresTitle' })}
          </div>
          <div className={styles.titleDes}>
            {intl.formatMessage({ id: 'FirstScoresDes.titleDes' })}
          </div>
          <ScoresTitle {...firstDesProps} />
          <ScoresTitle {...twoDesProps} />
          <ScoresTitle {...threeDesProps} />
        </div>
        <BottomPageNum num={8} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default FirstScoresDes;
