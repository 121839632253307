import React from 'react';
import CustomMicroApp from 'components/CustomMicroApp';

import styles from './index.less';

const SleepDailyRecord = ({ week }) => {
  const url = week ? `/sleepWeeklyRecord?week=${week}` : `/sleepWeeklyRecord`;

  const props = {
    url,
  };

  return (
    <div className={styles.dailyRecordPanel}>
      <CustomMicroApp {...props} />
    </div>
  );
};

export default SleepDailyRecord;
