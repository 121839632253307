import React from 'react'
import { useIntl } from 'umi'
import { Row, Col, Badge } from 'antd'
import styles from './Report.less'
import RadarChart from 'components/VratChartComponents/RadarChart'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const SixDimensions = ({ data, showNewReport }) => {
  const Attention = data ? data.Attention : 0
  const SelfControl = data ? data.SelfControl : 0
  const Reactivity = data ? data.Reactivity : 0
  const ReactionStability = data ? data.ReactionStability : 0
  const Sensitivity = data ? data.Sensitivity : 0
  const ContinuousAttention = data ? data.ContinuousAttention : 0

  const intl = useIntl()
  const RadarChartProps = {
    data: {
      Attention,
      SelfControl,
      Reactivity,
      ReactionStability,
      Sensitivity,
      ContinuousAttention,
    },
  }

  const DescProps = [
    {
      leftTitleE: intl.formatMessage({ id: 'report.attentionSkill.chart.attention' }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.attention' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'report.attentionSkill.chart.self' }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.self' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'report.attentionSkill.chart.reactivity' }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.reactivity' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'report.attentionSkill.chart.reactSta' }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.reactSta' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'report.attentionSkill.chart.sensitivity' }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.sensitivity' }),
    },
    {
      leftTitleE: intl.formatMessage({ id: 'report.attentionSkill.chart.continuous' }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.continuous' }),
    },
  ]

  const renderRightDesc = DescProps => {
    var arr = []
    DescProps.map((item, index) => {
      return arr.push(
        <Row type="flex" justify="space-around" style={{ marginBottom: 15 }} key={index}>
          <Col span={1} push={1}>
            <Badge status="processing" />
          </Col>
          <Col span={3}>
            <div>
              <p style={{ color: '#333', fontWeight: 700 }}>{item.leftTitleE}</p>
            </div>
          </Col>
          <Col span={16}>
            <div>
              <p style={{ color: '#333' }}>{item.rightDescE}</p>
            </div>
          </Col>
        </Row>
      )
    })
    return arr
  }

  return (
    <div id="attentionSkill" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.attentionSkill.title' })}</p>
      </h2>
      <Row
        type="flex"
        justify="space-around"
        className={styles.marginTop}
        style={{ marginTop: 90 }}
      >
        <Col span={9}>
          <RadarChart {...RadarChartProps} />
          <h2 className={`${styles.brainPic} ${styles.weightFont}`} style={{ marginLeft: 65 }}>
            {intl.formatMessage({ id: 'report.attentionSkill.chart.title' })}
          </h2>
        </Col>
        <Col span={12}>{renderRightDesc(DescProps)}</Col>
      </Row>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '4' : '4'}</p>
    </div>
  )
}

export default SixDimensions
