import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const PathLengthChart = () => {
  const intl = useIntl();
  const option = {
    color: ['#36abd3', '#A8DCBF'],
    legend: {
      data: [
        {
          name: intl.formatMessage({ id: 'Charts.You' }),
          icon: 'roundRect',
        },
        {
          name: intl.formatMessage({ id: 'Charts.Median' }),
          icon: 'roundRect',
        },
      ],
      type: 'scroll',
      icon: 'roundRect',
      selectedMode: false,
    },
    xAxis: {
      type: 'value',
      show: false,
    },
    yAxis: {
      show: false,
      type: 'value',
    },
    series: [
      {
        name: intl.formatMessage({ id: 'Charts.You' }),
        data: [],
        type: 'line',
      },
      {
        name: intl.formatMessage({ id: 'Charts.Median' }),
        data: [],
        type: 'line',
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: '50px', width: '200px' }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default PathLengthChart;
