import ReactEcharts from 'echarts-for-react';

const MotionChart = ({ data }) => {
  let baseColor = 'green';
  let userColor = '#36abd3';

  const option = {
    grid: {
      top: '10%',
      left: '10%',
      right: '10%',
      bottom: '10%',
    },
    xAxis: {
      show: false,
      type: 'value',
      min: -0.4,
      max: 0.4,
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#777',
        },
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      show: false,
      type: 'value',
      min: -0.4,
      max: 0.4,
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#777',
        },
      },
      axisLabel: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },

    series: [
      {
        id: 'base',
        type: 'line',
        data: [
          [-0.2, -0.2],
          [-0.2, 0.2],
          [0.2, 0.2],
          [0.2, -0.2],
          [-0.2, -0.2],
        ],
        lineStyle: {
          normal: {
            color: baseColor,
            width: 1.3,
            type: 'dashed',
          },
        },
        showSymbol: false,
        hoverAnimation: false,
      },
      {
        id: 'user',
        type: 'line',
        smooth: true,
        data: [],
        symbolSize: 2,
        symbol: "circle",
        itemStyle: {
          color: userColor,
        },
        // showSymbol: false,
        hoverAnimation: false,
      },
    ],
  };

  const showData = data.Position.map((item) => {
    return [item.Position.PosX, item.Position.PosZ];
  });

  option.series[1].data = showData;

  return (
    <ReactEcharts
      option={option}
      style={{ height: 125, width: 125 }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default MotionChart;
