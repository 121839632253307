import React, { useContext } from 'react';
import { MyContext } from '../context';
import styles from '../index.less';
import { useIntl }  from 'umi';
const BottomDes = ({ color }) => {
  const intl = useIntl();
  const { Name = '', isScreenReport } = useContext(MyContext) || {};

  return (
    <div className={styles.bottomContent}>
      <div className={styles.bottomTitle}>{Name}</div>
      <div className={styles.bottomTitleTwo} style={{ color: color }}>
      {intl.formatMessage({ id: isScreenReport ? 'ScreeBottomDes.EDITION' : 'BottomDes.EDITION' })}
      </div>
    </div>
  );
};

export default BottomDes;
