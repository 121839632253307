import { GetTrainerModuleDetail, FinishCourse, GetQuestionnaire, PutUpdateQuestionnaire, GetTrainingDetail } from 'services/trainingSystem'
import { message } from 'antd'
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'

export default {
  namespace: 'trainingDetail',
  state: {
    courseId: '',
    moduleId: '',
    preCourseId: '',
    preModuleId: '',
    courseDetailId: '',
    courseDetail: null,
    currentContentKey: '',
    activeContent: null,
    activeCollapseKey: [],
    visible: false,
    questionnaireDetail: null,
    submitData: null,
    currentAssmessmentIsFinshed: false,
    showSurveyVisible: false,
    correntRateAndResult: null,
    isStudyingContent: null,
    currentPdfInfo: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *pageLoading({ payload }, { take, put, call, select }) {
      const { moduleId, courseId, isReset, openNew, goNext } = payload || {}
      if (isReset) yield put.resolve({ type: 'updateState', payload: { activeContent: null } })
      const { activeContent, activeCollapseKey } = yield select(state => state.trainingDetail)
      // const res = yield call(GetTrainerModuleDetail, { TrainerTrainingModuleId: moduleId })
      const res = yield call(GetTrainingDetail, { CourseId: courseId, TrainerTrainingId: moduleId })
      let defaultStep = null
      if (res.response.ok) {
        // const { trainingModule } = res.data
        // const { courses } = trainingModule || {}
        // const currentCourseDetail = courses && courses.length > 0 ? courses.find(item => item.courseId === courseId) : null
        // const { course } = currentCourseDetail || {}
        // let { stepIndex, contents } = course || {}
        let { stepIndex, contents } = res.data || {}
        let newActiveContent = null
        let currentIndex = 0
        let isStudyingContent = null
        const getActiveContent = (contents) => {
          // 判断返回值是否存在userCourseDetail属性值，如果都不存在，默认打开第一个
          const hasUserCourseDetail = contents.find(item => item.userCourseDetail)
          const hasUserCourseDetailData = contents.filter(item => item.userCourseDetail)
          const hasnotUserCourseDetailData = contents.filter(item => !item.userCourseDetail)
          // 如果存在hasUserCourseDetail，就找isFinish和isPass其中有一个不为true的值就是默认第一个，如果都为true，则第一个没有hasUserCourseDetail就是默认第一个
          if (hasUserCourseDetail) {
            const eithorData = hasUserCourseDetailData.find(item => item.userCourseDetail.isFinish && !item.userCourseDetail.isPass)
            if (eithorData) {
              return newActiveContent = eithorData
            } else {
              return newActiveContent = (hasnotUserCourseDetailData && hasnotUserCourseDetailData[0]) || (hasUserCourseDetailData && hasUserCourseDetailData[hasUserCourseDetailData.length - 1])
            }
          } else {
            return newActiveContent = contents && contents[0] || null
          }
        }


        if (activeContent) {
          const { id } = activeContent || {}
          newActiveContent = contents.find(item => item.id === id)
        } else {
          newActiveContent = getActiveContent(contents)
        }
        currentIndex = contents.findIndex(item => newActiveContent.id === item.id)
        // 标记是否上锁
        contents && contents.forEach((item, index) => {
          if (index === currentIndex) {
            item.isLocked = false
          } else if (!item.userCourseDetail) {
            if (index === currentIndex + 1) {
              if (currentIndex <= contents.length - 1) {
                if (newActiveContent && newActiveContent.userCourseDetail &&
                  newActiveContent.userCourseDetail.isFinish &&
                  newActiveContent.userCourseDetail.isPass
                ) {
                  item.isLocked = false
                } else {
                  item.isLocked = true
                }
              }
            } else {
              item.isLocked = true
            }
          }
        })

        // step后端没有ID,为了正常切换，我加了index作为切换得id,这样就可以打开当前得章节
        if (openNew) {
          defaultStep = stepIndex.find((item, index) => {
            item.index = index
            return item.contentId.includes(newActiveContent && newActiveContent.id)
          })
        }

        if (newActiveContent) {
          yield put({
            type: 'updateState',
            payload: {
              courseDetail: res.data,
              moduleId,
              courseId,
              activeContent: goNext ? getActiveContent(contents) : newActiveContent,
              isStudyingContent: getActiveContent(contents),
              activeCollapseKey: defaultStep ? Array.from(new Set(activeCollapseKey.concat(String(defaultStep && defaultStep.index)))) : activeCollapseKey
            }
          })
        }

        // 后来添加的需求，需要在内容里面显示PDF
        if (newActiveContent && newActiveContent.contentType === 'Doc') {
          yield put({ type: 'getCurrentPdfInfo', payload: { resourceList: newActiveContent && newActiveContent.resourceList } })
        }
      }
    },

    *getCurrentPdfInfo({ payload }, { take, put, call, select }) {
      const { resourceList, id } = payload
      if (!id) {
        yield put({ type: 'updateState', payload: { currentPdfInfo: resourceList && resourceList.length > 0 ? resourceList[0] : null } })
      } else {
        const item = resourceList.find(item => item.id === id)
        yield put({ type: 'updateState', payload: { currentPdfInfo: item } })
      }
    },

    *asignStudyed({ payload: { intl } }, { take, put, call, select }) {
      const { courseId, moduleId, activeContent } = yield select(state => state.trainingDetail)
      const { questionnaires } = activeContent || {}
      const params = {
        trainerTrainingId: moduleId,
        courseId,
        courseDetailId: activeContent && activeContent.id
      }
      const res = yield call(FinishCourse, params)
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'studyCompleted' }))
        yield put.resolve({ type: 'pageLoading', payload: { moduleId, courseId, goNext: questionnaires && questionnaires.length === 0 } })
        if (questionnaires && questionnaires.length > 0) {
          yield put({ type: 'createQuestionnaire', payload: { type: 'create' } })
        }
      } else {
        const { error } = res.data
        const { message: errorInfo } = error || {}
        errorInfo && message.error(errorInfo)
      }
    },

    *changeActiveCollapseKey({ payload }, { take, put, call, select }) {
      yield put({ type: 'updateState', payload: { activeCollapseKey: payload } })
    },

    *changeContent({ payload }, { take, put, call, select }) {
      yield put({ type: 'updateState', payload: { activeContent: payload } })
      if (payload && payload.contentType === 'Doc') {
        yield put({ type: 'getCurrentPdfInfo', payload: { resourceList: payload && payload.resourceList } })
      }
    },

    *createQuestionnaire({ payload }, { take, put, call, select }) {
      const { type } = payload
      const { activeContent, courseId, courseDetail, moduleId } = yield select(state => state.trainingDetail)
      const { userCourseDetail, questionnaires } = activeContent || {}
      const { userQuestionnaireId } = userCourseDetail || {}
      const params = {
        id: userQuestionnaireId
      }
      const res = yield call(GetQuestionnaire, params)
      if (res.response.ok) {
        const data = res.data
        const { questionnaireAndAnswer } = data || {}
        const { answer, questionnaire } = questionnaireAndAnswer || {}
        const { answerContent } = answer || {}
        const { correctRate, jsonString } = questionnaire || {}
        yield put({
          type: 'updateState',
          payload: {
            questionnaireDetail: data,
            visible: type === 'create',
            showSurveyVisible: type === 'show',
            currentAssmessmentIsFinshed: false,
            correntRateAndResult: type === 'show' ? getCorrentRateAndResult(jsonString, answerContent, correctRate) : null
          }
        })
        // 是否自动进入下一小节
        // yield put({ type: 'pageLoading', payload: { moduleId, courseId, goNext: true } })
      } else {
        const { error } = res.data
        const { message: errorInfo } = error || {}
        errorInfo && message.error(errorInfo)
      }
    },

    *onComplete({ payload }, { take, put, call, select }) {
      const { activeContent, moduleId, courseId, questionnaireDetail } = yield select(state => state.trainingDetail)
      const { id, questionnaires } = activeContent || {}
      const { answerContent, isComplete, finishedPage, intl } = payload
      const { questionnaireAndAnswer, id: userQuestionnaireId } = questionnaireDetail || {}
      const { questionnaire } = questionnaireAndAnswer || {}
      const { jsonString, correctRate: passRate } = questionnaire || {}
      const { questionArr, correntRate, isPass, correntCount } = getCorrentRateAndResult(jsonString, answerContent, passRate) || {}
      let params = {}
      if (isComplete) {
        params = {
          trainerTrainingId: moduleId,
          courseId,
          courseDetailId: id,
          userQuestionnaireId: userQuestionnaireId,
          isPass,
          correctRate: correntRate,
          updateQuestionnaire: {
            userQuestionnaireId: userQuestionnaireId,
            answerContent: answerContent,
            isComplete: true,
            finishedPage: 0
          },
          correctNumber: correntCount
        }
        const res = yield call(PutUpdateQuestionnaire, params)
        if (res.response.ok) {
          message.success(intl.formatMessage({ id: 'submitted' }))
          yield put.resolve({ type: 'updateState', payload: { currentAssmessmentIsFinshed: true, visible: false, submitData: null } })
          yield put.resolve({ type: 'pageLoading', payload: { moduleId, courseId } })
          yield put.resolve({ type: 'createQuestionnaire', payload: { type: 'show' } })
        } else {
          const { error } = res.data
          const { message: errorInfo } = error || {}
          errorInfo && message.error(errorInfo)
        }
      }
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(async location => {
    //     if (location.pathname === '/system/center/detail/trainingDetail') {
    //       const { courseId, moduleId } = location.query || {}
    //       await dispatch({ type: 'updateState', payload: { preCourseId: courseId, preModuleId: moduleId, activeCollapseKey: [] } })
    //       dispatch({ type: 'pageLoading', payload: { ...location.query, isReset: true, openNew: true } })
    //     }
    //   })
    // },
  },
}
