import { GetPrepareList, GetPrepareEdit } from 'services/rocketService/Prepare'
import { GetTrainingModuleDetail, PutTrainingModuleCourse, PutCourseOrder, DeleteTrainingModuleCourse } from 'services/trainingSystem'
import {
  EditCourseSortOrder,
  CourseDelete,
  UpdateCategory,
} from 'services/rocketService/CourseCategory'

export default {
  namespace: 'courseCategoryDetail',
  state: {
    modalVisible: false,
    courseCategoryInfo: null,
    list: [],
    courseCategoryId: null,
    content: null,
    selectItem: null,
    sortOrderModal: false,
    type: ''
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage(_, { call, put, take, select }) {
      const { type } = yield select(state => state.courseCategoryDetail)
      switch (type) {
        case "course":
          yield put({ type: 'loadInfo' })
          yield put({ type: 'loadList' })
          break
        case "trainingModule":
          yield put({ type: 'loadList' })
          break
      }

    },

    *loadInfo(_, { call, put, take, select }) {
      const { courseCategoryId } = yield select(state => state.courseCategoryDetail)
      const res = yield call(GetPrepareEdit, courseCategoryId, 'courseCategory')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { courseCategoryInfo: res.data },
        })
      }
    },

    *loadList(_, { call, put, take, select }) {
      const { courseCategoryId, type } = yield select(state => state.courseCategoryDetail)
      let res = null
      switch (type) {
        case "course":
          res = yield call(GetPrepareList, { CategoryId: courseCategoryId, MaxResultCount: 5000 }, 'course')
          if (res && res.response.ok) {
            const { items, totalCount } = res.data
            yield put({
              type: 'updateState',
              payload: { list: items },
            })
          }
          break
        case "trainingModule":
          res = yield call(GetTrainingModuleDetail, { id: courseCategoryId })
          if (res && res.response.ok) {
            const { courses } = res.data
            yield put({
              type: 'updateState',
              payload: {
                courseCategoryInfo: res.data,
                list: courses
              }
            })
          }
          break
      }
    },

    *onAdd(_, { call, put, take, select }) {
      const { type } = yield select(state => state.courseCategoryDetail)
      const res = yield call(GetPrepareList, { MaxResultCount: 5000 }, 'course')
      if (res.response.ok) {
        let data = null
        switch (type) {
          case "course":
            data = res.data && res.data.items && res.data.items.length > 0 ? res.data.items.filter(item => item.type !== "TrainingCourse") : []
            data = { totalCount: data ? data.length : 0, items: data }
            break
          case "trainingModule":
            data = res.data && res.data.items && res.data.items.length > 0 ? res.data.items.filter(item => item.type === "TrainingCourse") : []
            data = { totalCount: data ? data.length : 0, items: data }
            break
        }
        yield put({
          type: 'updateState',
          payload: { content: data, modalVisible: true },
        })
      }
    },

    *addCourse({ payload }, { call, put, take, select }) {
      const { courseCategoryId, type } = yield select(state => state.courseCategoryDetail)
      let res = null
      switch (type) {
        case "course":
          res = yield call(UpdateCategory, { categoryId: courseCategoryId, courseIds: payload })
          break
        case "trainingModule":
          res = yield call(PutTrainingModuleCourse, { id: courseCategoryId, courseIds: payload })
          break
      }

      if (res && res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { content: null, modalVisible: false },
        })
        yield put({ type: 'loadList' })
      }
    },

    *changeSortOrder({ payload }, { call, put, take, select }) {
      const { courseCategoryId, selectItem, type } = yield select(state => state.courseCategoryDetail)
      let res = null
      switch (type) {
        case "course":
          res = yield call(EditCourseSortOrder, { categoryId: courseCategoryId, courseId: selectItem.id, newSortOrder: payload })
          break
        case "trainingModule":
          res = yield call(PutCourseOrder, { trainingModuleId: courseCategoryId, courseId: selectItem.id, newSortOrder: payload })
          break
      }
      if (res && res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { sortOrderModal: false },
        })
        yield put({ type: 'loadList' })
      }
    },

    *deleteItem({ payload }, { call, put, take, select }) {
      const { courseCategoryId, type } = yield select(state => state.courseCategoryDetail)
      let res = null
      switch (type) {
        case "course":
          res = yield call(CourseDelete, { categoryId: courseCategoryId, courseIds: [payload] })
          break
        case "trainingModule":
          res = yield call(DeleteTrainingModuleCourse, { TrainingModuleId: courseCategoryId, courseId: payload })
          break
      }
      if (res && res.response.ok) {
        yield put({ type: 'loadList' })
      }
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/courseCategoryDetail') {
    //       dispatch({ type: 'updateState', payload: { courseCategoryId: location.query.id, type: location.query.type, list: [] } })
    //       dispatch({ type: 'loadPage' })
    //     }
    //   })
    // },
  },
}
