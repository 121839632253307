import React, {useEffect} from 'react'
import { connect } from 'umi'
import { Input, Select, Button, Popconfirm } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import styles from './index.less'

const { Search } = Input;

const modalTypeToTitle = {
    edit: '编辑学校',
    add: '新建学校',
}

const SchoolList = ({ loading, dispatch, schoolList }) => {

    useEffect(() => {
        dispatch({ type: 'schoolList/query' })
    }, [])

    const listSupport = {
        name: {
            showText: '学校名称',
            showType: 'Text',
        },
        code: {
            showText: 'code',
            showType: 'Text',
        },
        creationTime: {
            showText: '创建时间',
            showType: 'Time',
            addtional: {
                format: 'YYYY/MM/DD HH:mm',
            },
        }
    }

    const { pagination, modalType, modalVisible, content, schoolListData, filterSchoolName } = schoolList

    const TableProps = {
        listData: schoolListData,
        listSupport,
        pagination,
        loading: loading.models.schoolList,
        Actions: [
            {
                showText: '编辑',
                onClick(record) {
                    dispatch({ type: 'schoolList/onEdit', payload: record })
                },
            },
            {
                showText: '删除',
                itemRender: (record) => {
                    return (
                        <Popconfirm
                            title="确定要删除此学校吗？"
                            placement="topRight"
                            okText="确定"
                            cancelText="取消"
                            onConfirm={() => dispatch({ type: 'schoolList/onDelete', payload: record.id })}
                        >
                            <a href="#">删除</a>
                        </Popconfirm>
                    )
                }
            }
        ],
        onTableChange(pagi, fil = {}, sort = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) {
                data = { ...data, pagination: pagi }
            }
            dispatch({ type: 'schoolList/changeTable', payload: data })
        }
    }

    const modalProps = {
        title: modalTypeToTitle[modalType],
        visible: modalVisible,
        onCancel() {
            dispatch({ type: 'schoolList/updateState', payload: { modalVisible: false } })
        },
        onOk(value) {
            dispatch({ type: 'schoolList/onSubmitModal', payload: value })
        },
        loading: loading.effects['schoolList/onSubmitModal'],
        content,
    }

    const onAddClick = () => {
        dispatch({ type: 'schoolList/onAdd' })
    }

    return (
        <div className={styles.schoolList}>
            <div>学校管理</div>
            <div className={styles.schoolListTop}>
                <div className={styles.schoolListTopLeft}>
                    <Search
                        placeholder="学校名称"
                        onSearch={value => dispatch({ type: 'schoolList/onSearch' })}
                        value={filterSchoolName}
                        style={{ height: 32, width: 220 }}
                        onChange={e => dispatch({ type: 'schoolList/updateState', payload: { filterSchoolName: e.target.value } })}
                    />
                    <div className={styles.schoolListTopLeftClear}>
                        <Button onClick={()=>{dispatch({ type: 'schoolList/onClear' })}}>取消筛选</Button>
                    </div>
                </div>
                <Button type='primary' onClick={onAddClick}>新建学校</Button>
            </div>
            <MagicTable {...TableProps} />
            <MagicFormModal {...modalProps} />
        </div>
    )
}

export default connect(({ loading, schoolList }) => ({ loading, schoolList }))(SchoolList)