import React, { useEffect, useState } from 'react';
import { useIntl, history } from 'umi';
import { message, Modal } from 'antd';
import Thoughts from 'components/CoreLibraryCom/thoughts';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { GetWeekOfDays } from 'services/adhd';

const listSupport = (intl) => {
  return {
    sortOrder: {
      showText: intl.formatMessage({ id: 'Day Order' }),
      showType: 'Text',
      render: (text) => {
        return `${intl.formatMessage(
          { id: 'Day' },
          { day: parseInt(text) + 1 },
        )} `;
      },
    },
    title: {
      showText: intl.formatMessage({ id: 'Session Title' }),
      showType: 'Text',
    },
    contentType: {
      showText: intl.formatMessage({ id: 'Type' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({id: text})
      }
    },
  };
};

const ExpandedTable = ({ allData, selectTemplate, isAdmin,isEnterpriseAdmin, isAreaAdmin }) => {
  const intl = useIntl();

  const { id, sortOrder, title } = allData || {};
  const { id: TemplateId } = selectTemplate || {};

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [listData, setListData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({});

  const initData = async () => {
    setLoading(true);

    const res = await GetWeekOfDays({
      WeekThemeId: id,
      SkipCount: 0,
      MaxResultCount: 20,
    });

    if (res.response.ok) {
      const { items = [] } = res.data || {};
      setListData(items);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const tableProps = {
    listData,
    listSupport: listSupport(intl),
    loading,
    Actions: [
      {
        showText: intl.formatMessage({ id: 'core-Details' }),
        itemRender(record) {
          const { id: DailyTopicId, sortOrder: daySortOrder } = record || {};
          return isAdmin || isEnterpriseAdmin || isAreaAdmin? null : (
            <a
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: '/system/center/detail/coreSessionDetail',
                  query: {
                    DailyTopicId,
                    weekSortOrder: sortOrder,
                    title,
                    daySortOrder,
                    TemplateId,
                    WeekThemeId: id,
                  },
                });
              }}
            >
              {intl.formatMessage({ id: 'core-Details' })}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'Thoughts' }),
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                setModalVisible(true);
                setCurrentRecord(record);
              }}
            >
              {intl.formatMessage({ id: 'Thoughts' })}
            </a>
          );
        },
      },
    ],
  };

  const { id: DailyTopicId, sortOrder: daySortOrder } = currentRecord || {};

  const thoughtsProps = {
    TemplateId,
    WeekThemeId: id,
    DailyTopicId,
    ShowTitle: false,
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <MagicTable {...tableProps} />
      <Modal
        title={`${intl.formatMessage(
          { id: 'core-weekDay' },
          { week: parseInt(sortOrder) + 1, day: parseInt(daySortOrder) + 1 },
        )} `}
        open={modalVisible}
        onCancel={handleCancel}
        width={800}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
      >
        {Object.keys(currentRecord).length > 0 && (
          <Thoughts {...thoughtsProps} />
        )}
      </Modal>
    </>
  );
};

export default React.memo(ExpandedTable);
