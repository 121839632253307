import { message } from 'antd'
import { GetProductList } from 'services/vrat/testCenter'
import { IntlManager } from 'utils/helper'

export default {
  namespace: 'productList',

  state: {
    products: [],
    viewModalVisible: false,
    currentRecord: null,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *fetch({ payload }, { call, put, select, take }) {
      const res = yield call(GetProductList)
      const { data, response } = res
      if (response.ok) {
        const products = data
        yield put({ type: 'updateState', payload: { products } })
      }
    },

    download({ payload }, { call, put }) {
      const formatMessage = IntlManager.intl.formatMessage
      if (payload !== null) {
        var win = window.open(payload, '_blank')
        win.focus()
      } else {
        message.error(formatMessage({ id: 'invalidUrl' }))
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/manage/vratProduct') {
    //       dispatch({ type: 'fetch' })
    //     }
    //   })
    // },
  },
}
