import { Tooltip, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const listSupport = {
  trainerName: {
    showText: '训练师',
    showType: 'Text',
    fixed: 'left',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  city: {
    showText: '城市',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  tenantDisplayName: {
    showText: '机构',
    showType: 'Remark',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  institutionType: {
    showText: '机构类型',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  tenantUserRoles: {
    showText: '角色',
    showType: 'Remark',
    render: (text) => {
      return (
        <div style={{ minWidth: '100px' }}>
          {text.map((item) => item.displayName).join(', ')}
        </div>
      );
    },
  },
  college: {
    showText: '学校',
    showType: 'Remark',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  qualification: {
    showText: '学历',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  major: {
    showText: '专业',
    showType: 'Remark',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  jobTitle: {
    showText: '岗位类型',
    showType: 'Remark',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  yearWorkWithChildren: {
    showText: '从业年限',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  moduleCoursePassProgress: {
    showText: '课程学习进度',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  supervisorSSummaryCount: {
    showText: '督导培训进度',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  hasEndCourseSubject: {
    showText: '是否开展训练',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '90px' }}>{text ? '是' : '否'}</div>,
  },
  userState: {
    showText: '账号情况',
    showType: 'Text',
    render: (text) => (
      <div style={{ minWidth: '80px' }}>
        {text === 'Effective' ? '有效' : '无效'}
      </div>
    ),
  },
  professionalDevelopment: {
    showText: '这个培训对您的专业发展有多大帮助？',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '130px' }}>{text}</div>,
  },
  professionalExpertise: {
    showText: '这个培训提高了我的专业知识',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  recommendProgram: {
    showText: '我愿意被把这个培训推荐给别人',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneTOnePlanCourseQuantity: {
    showText:
    <Space size={3}>
      总训练课时数
      <Tooltip title="这里的课时数字段都包括督导（即试用）课时数在内">
       <InfoCircleOutlined style={{ color: 'gray' }}/>
      </Tooltip>
    </Space>,
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneTOnePlanCourseEndLastTime: {
    showText: '最近结课时间(训练)',
    showType: 'Time',
    render: (text) => <div style={{ minWidth: '100px' }}>{text && moment(text).local().format('YYYY-MM-DD HH:mm')}</div>
  },
  oneToOneOnlineCourseQuantity: {
    showText: '线上训练课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToOneOfflineCourseQuantity: {
    showText: '线下训练课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToOneOnlineCourseQuantity: {
    showText: '近1年线上训练课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToOneOfflineCourseQuantity: {
    showText: '近1年线下训练课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToOneCourseSubjectQuantity: {
    showText: '总训练儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToOneOnlineCourseSubjectQuantity: {
    showText: '线上训练儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToOneOfflineCourseSubjectQuantity: {
    showText: '线下训练儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToOneOnlineCourseSubjectQuantity: {
    showText: '近1年线上训练儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToOneOfflineCourseSubjectQuantity: {
    showText: '近1年线下训练儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToManyPlanCourseQuantity: {
    showText: '总班级课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToManyOnlineCourseQuantity: {
    showText: '线上班级课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToManyOfflineCourseQuantity: {
    showText: '线下班级课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToManyOnlineCourseQuantity: {
    showText: '近1年线上班级课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToManyOfflineCourseQuantity: {
    showText: '近1年线下班级课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToManyCourseSubjectQuantity: {
    showText: '总班级儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToManyOnlineCourseSubjectQuantity: {
    showText: '线上班级儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  oneToManyOfflineCourseSubjectQuantity: {
    showText: '线下班级儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToManyOnlineCourseSubjectQuantity: {
    showText: '近1年线上班级儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOneToManyOfflineCourseSubjectQuantity: {
    showText: '近1年线下班级儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  planCourseQuantity: {
    showText: '总课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  onlineCourseQuantity: {
    showText: '线上课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  offlineCourseQuantity: {
    showText: '线下课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  lastYearOnlineCourseQuantity: {
    showText: '近1年线上课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOfflineCourseQuantity: {
    showText: '近1年线下课时数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  courseSubjectQuantity: {
    showText: '总儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  onlineCourseSubjectQuantity: {
    showText: '线上儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  offlineCourseSubjectQuantity: {
    showText: '线下儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '80px' }}>{text}</div>,
  },
  lastYearOnlineCourseSubjectQuantity: {
    showText: '近1年线上儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
  lastYearOfflineCourseSubjectQuantity: {
    showText: '近1年线下儿童数',
    showType: 'Text',
    render: (text) => <div style={{ minWidth: '100px' }}>{text}</div>,
  },
};

export { listSupport };
