import { GetPlanItemDetail, GetGroupCourseDetail, PutUpdatePlayBookList } from 'services/rocketService/UserOfflinePlan'
import { GetTrainingProgramList } from 'services/rocketService/TrainingProgram'
import { message } from 'antd'

export default {
  namespace: 'userOfflineCourseDetail',
  state: {
    userPlanId: null,
    courseInfo: null,
    from: null, // 判断如果是班级课时详情请求什么的跟之前的不一样，复用这个页面
    pagination: {
      current: 1,
      pageSize: 1,
      total: 10,
    },
    selectModalVisible: false,
    drawerVisible: false,
    playBookList: [],
    activeNum: 1,
    planType: "SenseAudio"
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *pageLoading({ payload }, { call, put, select }) {
      const { pagination } = yield select(state => state.userOfflineCourseDetail)
      const { num, userPlanId, currentNum, planType, from = '' } = payload
      yield put({
        type: 'updateState',
        payload: {
          pagination: { ...pagination, current: Number(num), },
          userPlanId,
          activeNum: currentNum,
          planType,
          from
        },
      })
      if (from == '') {
        yield put({ type: 'fetch' })
      } else {
        yield put({ type: 'groupFetch' })
      }
    },

    *fetch(_, { take, put, call, select }) {
      const { pagination, userPlanId } = yield select(state => state.userOfflineCourseDetail)
      const res = yield call(GetPlanItemDetail, {
        UserOfflinePlanId: userPlanId,
        num: pagination.current,
      })
      if (res.response.ok) {
        const courseInfo = res.data
        const total = courseInfo.userOfflineTrainingPlan != null ? courseInfo.userOfflineTrainingPlan.currentNum : 0
        yield put({
          type: 'updateState', payload: {
            courseInfo,
            pagination: { ...pagination, total },
          }
        })
      }
    },

    *groupFetch(_, { call, put, take, select }) {
      const { pagination, userPlanId, activeNum } = yield select(state => state.userOfflineCourseDetail)
      const res = yield call(GetGroupCourseDetail, {
        GroupTrainingPlanId: userPlanId,
        CourseNum: pagination.current,
      })
      if (res.response.ok) {
        const { courseItems, files } = res.data
        const tempData = {
          nonePlaybook: [],
          isApp: false,
          userOfflineTrainingPlan: {
            files
          },
          planItems: courseItems.map(item => {
            return {
              ...item,
              trainingProgramDetail: item.trainingProgram
            }
          }),
          ...res.data
        }
        yield put({
          type: 'updateState', payload: {
            courseInfo: tempData,
            pagination: { ...pagination, total: activeNum },
          }
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage, from } = yield select(state => state.userOfflineCourseDetail)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: from === '' ? 'fetch' : 'groupFetch' })
    },

    *onSelectPlayBook(_, { call, put, select }) {
      const res = yield call(GetTrainingProgramList, {
        maxResultCount: 100,
        NonePlaybook: true
      })
      if (res.response.ok) {
        const { totalCount, items } = res.data
        yield put({ type: 'updateState', payload: { playBookList: items, selectModalVisible: true } })
      }
    },

    *onShowPlayBook(_, { call, put, select }) {
      const res = yield call(GetTrainingProgramList, {
        maxResultCount: 100,
        NonePlaybook: true
      })
      if (res.response.ok) {
        const { totalCount, items } = res.data
        yield put({ type: 'updateState', payload: { playBookList: items, drawerVisible: true } })
      }
    },

    *updatePlaybookList({ payload: data }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { selectModalVisible: false } })
      const res = yield call(PutUpdatePlayBookList, data)
      if (res.response.ok) {
        yield put({ type: 'fetch' })
      }
      else {
        message.error('选取失败')
      }
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/detail/userOfflineCourseDetail') {
    //       dispatch({ type: 'pageLoading', payload: location.query })
    //     }
    //   })
    // },
  },
}
