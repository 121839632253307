import React, {useState} from 'react';
import { Modal, Checkbox, Button} from 'antd';
import SubjectInfoCard from 'components/SubjectInfoCard';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useIntl } from 'umi';
import { CloseCircleOutlined } from '@ant-design/icons';
import styles from '../center.less'


const SubjectInfoCheckInModal = ({
    subjectInfo,
    visible,
    onCancel,
    onOk,
    loading
}) => {
    const intl = useIntl();
    const [disable, setDisable] = useState(true);

    const onSubmit = () => {
        onOk(subjectInfo)
    }

    const onQuit = () => {
        onCancel()
    }

    const handleOnchange = () => {
        setDisable(!disable);
      };
    
      const okStyle = {
        backgroundColor: '#FF3B30 ',
        color: '#fff',
        border: 'none',
        marginLeft: '30px',
      };
    
      const cancelStyle = {
        backgroundColor: '#fff ',
        color: '#262626',
        border: '1px solid #ccc',
        marginLeft: '30px',
      };
    
      const disableStyle = {
        backgroundColor: "#ff00008f",
        border: 'none',
        color: "#fff",
        marginLeft: '30px',
      }

    return (
        <Modal
            maskClosable={false}
            open={visible}
            title={<span style={{fontSize: '14px'}}><b>{intl.formatMessage({ id: 'sureInfoAgain' })}</b></span>}
            onCancel={onQuit}

            confirmLoading={loading}
            zIndex={1000}
            footer={null}
            afterClose={() => {
                setDisable(true)
            }}
            destroyOnClose={true}
            closeIcon={<CloseCircleOutlined />}
        >
            {/* <div style={{ color: '#F96B4B', marginLeft: '15px' }}>{intl.formatMessage({ id: 'submitTip' })}</div> */}
            <SubjectInfoCard subjectInfo={subjectInfo} />
            <div
                className={styles.tips}
            >
                 <ExclamationCircleFilled style={{fontSize: '28px', color:'#faad14'}}/>&nbsp;&nbsp;&nbsp;<span>{intl.formatMessage({ id: 'subTip' })}</span>
            </div>
            {/* <div className={styles.modalTips}>{intl.formatMessage({ id: 'subTipInfo' })}</div> */}
            <Checkbox  style={{marginLeft: '100px'}} onChange={handleOnchange}>
                {intl.formatMessage({ id: 'risk' })}
            </Checkbox>
            <Button
                disabled={disable}
                style={disable ? disableStyle : okStyle}
                onClick={onSubmit}
                loading={loading}
            >
                {intl.formatMessage({ id: 'Confirm Add' })}
            </Button>
            <Button style={cancelStyle} onClick={onQuit}>
                {intl.formatMessage({ id: 'return' })}
            </Button>
            
        </Modal>
    )
}

export default SubjectInfoCheckInModal
