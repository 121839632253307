import { history, connect } from 'umi';
import { Button, Modal, Row, Col, Tabs } from 'antd'
import { useEffect } from 'react'
import { CaretLeftOutlined } from '@ant-design/icons'
import TemplateBasicInfo from './TemplateBasicInfo'
import ProjectCards from './ProjectCards'
import SelectModal from './SelectModal'
import SortOrderModal from './SortModal'
import CopyToMultiplePlacesModal from 'components/MultipleSelection/courseWare'

const { confirm } = Modal

const { TabPane } = Tabs

const OfflinePlanDetail = ({ dispatch, adminOfflinePlanDetail, location}) => {

  useEffect(() => {
    dispatch({ type: 'adminOfflinePlanDetail/pageLoading', payload: location.query })
  }, [])

  const {
    list,
    pagination,
    showType,
    templateInfo,
    selectModalVisible,
    sortModalVisible,
    copyModalVisible,
    trainingProgramList,
    selectItem,
  } = adminOfflinePlanDetail

  const onTabChange = key => {
    dispatch({ type: 'adminOfflinePlanDetail/tabChange', payload: key })
  }

  const onBack = () => {
    history.goBack()
  }

  const basicInfoProps = {
    templateInfo,
  }

  const selectModalProps = {
    defaultSelectKeys: selectItem ? selectItem.courseWareIds : [],
    trainingProgramList,
    selectModalVisible,
    onOk(keys) {
      dispatch({
        type: 'adminOfflinePlanDetail/onSubmitSelect',
        payload: { courseWareId: keys },
      })
    },
    onCancel() {
      dispatch({ type: 'adminOfflinePlanDetail/updateState', payload: { selectModalVisible: false } })
    },
  }

  const sortModalProps = {
    programs: selectItem ? selectItem.programs : [],
    sortModalVisible,
    onOk(courseWares) {
      const courseWareId = courseWares.map(item => item.id)
      dispatch({
        type: 'adminOfflinePlanDetail/onSubmitSelect',
        payload: { courseWareId },
      })
    },
    onCancel() {
      dispatch({ type: 'adminOfflinePlanDetail/updateState', payload: { sortModalVisible: false } })
    },
  }

  const copyModalProps = {
    courseNum: selectItem ? selectItem.courseNum : 1,
    totalCount: pagination.total,
    visible: copyModalVisible,
    onOk(courseNum) {
      dispatch({
        type: 'adminOfflinePlanDetail/onSubmitCopy',
        payload: courseNum,
      })
    },
    onCancel() {
      dispatch({ type: 'adminOfflinePlanDetail/updateState', payload: { copyModalVisible: false } })
    },
  }

  const projectCardsProps = {
    list,
    pagination,
    onEdit(courseNum, courseWareIds) {
      dispatch({ type: 'adminOfflinePlanDetail/onEdit', payload: { courseNum, courseWareIds, programs: [] } })
    },
    onSort(courseNum, programs) {
      dispatch({
        type: 'adminOfflinePlanDetail/updateState',
        payload: { sortModalVisible: true, selectItem: { courseNum, programs, courseWareIds: [] } },
      })
    },
    onCopy(courseNum, courseWareIds){
      dispatch({
        type: 'adminOfflinePlanDetail/onCopy',
        payload: { courseNum, courseWareIds, programs: [] },
      })
    },
    onPageChange(page, pageSize) {
      const pagination = {
        current: page,
        pageSize,
      }
      dispatch({ type: 'adminOfflinePlanDetail/changeTable', payload: { pagination } })
    },
  }

  return (
    <div>
      <Row>
        <Col span={4}>
          <Button icon={<CaretLeftOutlined />} onClick={onBack}>
            Back
          </Button>
        </Col>
        <Col span={6}>
          <h1>详情页</h1>
        </Col>
      </Row>
      <Tabs activeKey={showType} onChange={onTabChange}>
        <TabPane tab="基本介绍" key="basic" />
        <TabPane tab="课件设置" key="project" />
      </Tabs>
      {showType === 'basic' ? (
        <TemplateBasicInfo {...basicInfoProps} />
      ) : (
        <ProjectCards {...projectCardsProps} />
      )}
      <SelectModal {...selectModalProps} />
      <SortOrderModal {...sortModalProps} />
      <CopyToMultiplePlacesModal {...copyModalProps}/>
    </div>
  )
}

export default connect(({ adminOfflinePlanDetail }) => ({ adminOfflinePlanDetail }))(OfflinePlanDetail)
