import React from 'react';
import { useIntl } from 'umi';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import BottomPageNum from './components/BottomPageNum';
import RecommendationsLine from './components/RecommendationsLine';

const showList = (intl) => [
  {
    title: intl.formatMessage({ id: 'Interpretation.Api' }),
    content: intl.formatMessage({ id: 'Interpretation.Api.Des' }),
  },
  {
    title: intl.formatMessage({ id: 'Interpretation.Mpi' }),
    content: intl.formatMessage({ id: 'Interpretation.Mpi.Des' }),
  },
  {
    title: intl.formatMessage({ id: 'Interpretation.OmissionError' }),
    content: intl.formatMessage({ id: 'Interpretation.OmissionError.Des' }),
  },
];

const InterpretationOne = () => {
  const intl = useIntl();

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.interpretationOne}>
        <HeaderLine color="#EE4FA4" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'Interpretation' })}</div>

          {showList(intl).map((item) => {
            const { content, title } = item || {};
            return (
              <div key={title} style={{ marginBottom: '150px' }}>
                <RecommendationsLine
                  title={title}
                  content={content}
                  height="68px"
                />
              </div>
            );
          })}
        </div>
        <BottomPageNum num={18} />
        <BottomDes color="#EE4FA4" />
      </div>
    </div>
  );
};

export default InterpretationOne;
