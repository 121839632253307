import React, { useEffect } from 'react'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import AutoGenerationCertModal from 'components/AutoGenerationCertModal'
import { connect, history } from 'umi'
import { Button, Modal, Input, Tabs, Drawer, Empty, Spin } from 'antd'
import { downloadLongRangeImg, getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import moment from 'moment'
import styles from './index.less'

const { Search } = Input
const { TabPane } = Tabs;

const listSupport = {
    creationTime: {
        showText: '颁发时间',
        showType: 'Time',
    },
    username: {
        showText: '员工姓名',
        showType: 'Text',
        render(text, record, index) {
            const { username, trainerId } = record
            return <a onClick={() => history.push({
                pathname: '/system/manage/groupDetail',
                query: {
                    id: trainerId
                }
            })}>{username}</a>
        }
    },
    name: {
        showText: '勋章类型',
        showType: 'Text',
    },
    credentialsCode: {
        showText: '证书编号',
        showType: 'Text',
    },
    date: {
        showText: '有效期',
        showType: 'Text',
    },
    // certifier: {
    //     showText: '认证师',
    //     showType: 'Text',
    // },
    supervisorName: {
        showText: '督导师',
        showType: 'Text',
    },
    state: {
        showText: '是否有效',
        showType: 'Text',
    }
}

const MedalList = ({ medalList, dispatch, loading }) => {
    const {
        list,
        pagination,
        userName,
        code,
        name,
        content,
        modalVisible,
        questionData,
        drawerVisible,
        tabActiveKey,
        tabListData,
        selectItem
    } = medalList

    useEffect(() => {
        dispatch({ type: 'medalList/query' })
    }, [])

    const { questionnaireAndAnswer } = questionData || {}
    const { answer, questionnaire } = questionnaireAndAnswer || {}
    const { answerContent } = answer || {}
    const { jsonString, correctRate } = questionnaire || {}

    let questionArr = [], correntRateAndResult = null
    if (questionData) {
        correntRateAndResult = getCorrentRateAndResult(jsonString, answerContent, correctRate)
        questionArr = correntRateAndResult && correntRateAndResult.questionArr
    }

    const TableProps = {
        listData: list.map(item => {
            return {
                ...item,
                date: `${moment(item.startTime).format('YYYY/MM/DD')}~${moment(item.endTime).format('YYYY/MM/DD')}`,
                state: item.status && moment().isSameOrAfter(moment(item.startTime), 'day') && moment().isSameOrBefore(moment(item.endTime), 'day') ? '有效' : '无效'
            }
        }),
        listSupport,
        loading: loading.effects['medalList/query'],
        pagination,
        onTableChange(pagi, fil = {}, sort = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) {
                data = { ...data, pagination: pagi }
            }
            dispatch({ type: 'medalList/changeTable', payload: data })
        },
        Actions: [
            {
                showText: '取消认证',
                itemRender(record) {
                    const { status } = record
                    return status ? (
                        <a
                            onClick={() => {
                                const { username, name } = record || {}
                                Modal.confirm({
                                    title: <span style={{ color: 'red' }}>{`确定取消${username}的${name}认证吗？`}</span>,
                                    okText: '确定',
                                    cancelText: '取消',
                                    onOk: () => {
                                        dispatch({ type: 'medalList/stopCertificate', payload: record })
                                    },
                                    onCancel() {
                                    },
                                });
                            }}
                        >
                            取消认证
                        </a>
                    ) : null
                }
            },
            {
                showText: '下载证书',
                itemRender(record) {
                    const { credential } = record || {}
                    const { url } = credential || {}
                    return url ? <a onClick={() => downloadLongRangeImg(credential)}>下载证书</a> : null
                }
            },
            {
                showText: '编辑证书',
                itemRender(record) {
                    const { status } = record
                    return status ?
                        (
                            <a onClick={() => { dispatch({ type: 'medalList/showSelectItem', payload: record }) }}>
                                编辑证书
                            </a>
                        )
                        :
                        null
                }
            },
            {
                showText: '删除证书',
                itemRender(record) {
                    const { status } = record
                    return status ?
                        (
                            <a onClick={() => {
                                Modal.confirm({
                                    title: '确定删除证书吗？',
                                    okText: '删除证书',
                                    onOk: () => {
                                        dispatch({ type: 'medalList/deleteCert', payload: record })
                                    }
                                })
                            }}>
                                删除证书
                            </a>
                        )
                        :
                        null
                }
            },
            {
                showText: '查看培训反馈',
                itemRender(record) {
                    return <a onClick={() => { dispatch({ type: 'medalList/trainingFeedback', payload: record }) }}>查看培训反馈</a>
                }
            }
        ]
    }

    const modalProps = {
        title: '编辑证书',
        visible: modalVisible,
        content,
        onCancel() {
            dispatch({ type: 'medalList/updateState', payload: { modalVisible: false } })
        },
        onOk(value) {
            dispatch({ type: 'medalList/onsubmit', payload: value })
        }
    }

    const autoGenerationCertModalProps = {
        type: 'edit',
        visible: modalVisible,
        selectItem,
        trainingModuleId: selectItem?.trainingModuleId,
        onCloseModal: () => {
            dispatch({ type: 'medalList/updateState', payload: { modalVisible: false } })
            dispatch({ type: 'medalList/query' })
        }
    }

    const showSurveyProps = {
        contents: questionArr,
        choice: answerContent ? JSON.parse(JSON.stringify(answerContent)) : {},
        showIcon: false
    }

    return (
        <div>
            <div>证书管理</div>
            <div>
                <Search
                    placeholder="员工姓名"
                    value={userName}
                    onSearch={value => dispatch({ type: 'medalList/query' })}
                    onChange={e => dispatch({ type: 'medalList/updateState', payload: { userName: e.target.value } })}
                    style={{ width: 200 }}
                />
                <Search
                    placeholder="勋章类型"
                    value={name}
                    onSearch={value => dispatch({ type: 'medalList/query' })}
                    onChange={e => dispatch({ type: 'medalList/updateState', payload: { name: e.target.value } })}
                    style={{ width: 200 }}
                />
                <Search
                    placeholder="证书编号"
                    value={code}
                    onSearch={value => dispatch({ type: 'medalList/query' })}
                    onChange={e => dispatch({ type: 'medalList/updateState', payload: { code: e.target.value } })}
                    style={{ width: 200 }}
                />
                <Button onClick={() => { dispatch({ type: 'medalList/onClear' }) }}>取消筛选</Button>
                <Button type="primary" onClick={() => dispatch({ type: 'user/showFeedbackQRAndCode' })} className={styles.topLeft}>培训反馈码</Button>
            </div>
            <MagicTable {...TableProps} />
            {/* <MagicFormModal {...modalProps} /> */}
            {modalVisible && <AutoGenerationCertModal {...autoGenerationCertModalProps} />}
            <Drawer
                title="培训反馈"
                width={700}
                open={drawerVisible}
                bodyStyle={{ padding: "20px" }}
                onClose={() => dispatch({ type: 'medalList/updateState', payload: { drawerVisible: false } })}
            >
                {tabListData &&
                    <Tabs
                        activeKey={tabActiveKey}
                        type="card"
                        onChange={(id) => {
                            dispatch({ type: 'medalList/changeTab', payload: { id } })
                        }}>
                        {tabListData.map(item => {
                            return (<TabPane tab={item.displayName} key={item.id}>
                                <Spin spinning={loading.effects['medalList/changeTab'] === undefined ? loading.effects['medalList/trainingFeedback'] : loading.effects['medalList/changeTab']}>
                                    {
                                        questionData ?
                                            <ShowSurvey {...showSurveyProps} />
                                            :
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    }
                                </Spin>
                            </TabPane>)
                        })}
                    </Tabs>
                }
            </Drawer>
        </div>
    )
}

export default connect(({ medalList, loading }) => ({ medalList, loading }))(MedalList)