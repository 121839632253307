import ReactEcharts from 'echarts-for-react'
import { useIntl } from 'umi'

const PathLengthChart = ({ data, motionText }) => {
  const intl = useIntl();
  const baseData = data.filter(item => {
    return item.IsMedian
  })

  const userData = data.filter(item => {
    return !item.IsMedian
  })

  const option = {
    title: {
      text: motionText + intl.formatMessage({ id: 'report.pathLength.chart.subtitle' }),
      left: 'center',
      bottom: '1%',
      textStyle: {
        fontSize: 14,
        color: '#333',
        fontWeight: 'bold',
      },
      bottom: -5,
    },
    color: ['#36abd3', '#A8DCBF'],
    // color: ['#00CCCC', '#A8DCBF'],
    // legend: {
    // 	data: ['Head Movement - 0.1', 'Percentile - 10%']
    // },
    grid: {
      left: '19%',
      right: '16%',
      height: '50%',
    },
    xAxis: {
      name: intl.formatMessage({ id: 'report.pathLength.chart.xAxis' }),
      type: 'category',
      data: ['1', '2', '3', '4'],
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      name: intl.formatMessage({ id: 'report.pathLength.chart.yAxis' }),
      type: 'value',
    },

    series: [
      {
        // name: 'Head Movement - 0.1',
        name: intl.formatMessage({ id: 'report.pathLength.chart.legendU' }),
        type: 'line',
        data: [],
        hoverAnimation: false,
        symbolSize: 3,
        itemStyle: {
          normal: {
            borderWidth: 3,
            borderColor: '#1a9bfc',
            // borderColor: '#03ADAD',
          },
        },
      },
      {
        // name: 'Percentile - 10%',
        name: intl.formatMessage({ id: 'report.pathLength.chart.legendM' }),
        type: 'line',
        data: [],
        hoverAnimation: false,
        symbolSize: 3,
        itemStyle: {
          normal: {
            borderWidth: 3,
            borderColor: '#a2d9ba',
          },
        },
      },
    ],
  }

  for (let i = 1; i <= 4; i++) {
    const selectUser = userData.find(item => {
      return item.Block === i
    })

    const selectBase = baseData.find(item => {
      return item.Block === i
    })

    if (selectUser !== undefined) {
      option.series[0].data.push(selectUser.Value)
    }

    if (selectBase !== undefined) {
      option.series[1].data.push(selectBase.Value)
    }
  }

  return (
    <ReactEcharts option={option} style={{ height: '230px', width: '390px', margin: 'auto' }} />
  )
}

export default PathLengthChart
