import { GetInfos, AddInfo, UpdateQuestionnaire, GetById } from 'services/vrat/questionnaire'

export default {
  namespace: 'questionList',

  state: {
    list: [],
    pageIndex: 1,
    total: 0,
    pageSize: 12,
    modalVisible: false,
    currentQuesionnaire: null,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *fetch(_, { call, put, select, take }) {
      const { pageIndex, pageSize } = yield select(state => state.questionList)
      const res = yield call(GetInfos, { pageIndex, pageSize })
      const { data, response } = res
      if (response.ok) {
        yield put({ type: 'updateState', payload: { list: data.data, total: data.TotalCount } })
      }
    },

    *clickEdit({ payload: id }, { take, put, call, select }) {
      const res = yield call(GetById, id)
      const { response, data } = res
      if (response.ok) {
        yield put({
          type: 'updateState',
          payload: { currentQuesionnaire: data, modalVisible: true },
        })
      }
    },

    *filterAndFetch({ payload: filter }, { take, put, call, select }) {
      yield put({ type: 'updateState', payload: filter })
      yield put({ type: 'fetch' })
    },

    *addQuesionnaire({ payload }, { call, put, select, take }) {
      const { currentQuesionnaire } = yield select(state => state.questionList)
      //edit
      let res
      if (currentQuesionnaire) {
        res = yield call(UpdateQuestionnaire, currentQuesionnaire.Id, {
          ...currentQuesionnaire,
          ...payload,
        })
      } else {
        res = yield call(AddInfo, payload)
      }

      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { modalVisible: false } })
        yield put({ type: 'fetch' })
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/assessment/vrat') {
    //       dispatch({ type: 'fetch' })
    //     }
    //   })
    // },
  },
}
