import { useState, useEffect } from 'react';
import { Card, Button, message, Space, Modal } from 'antd';
import {
  CaretLeftOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import DaysListModal from './DaysListModal';
import SortOrderModal from 'components/SortOrderModal';
import { history } from 'umi';

const confirm = Modal.confirm;

import {
  GetWeekOfDays,
  DeleteWeekOfDays,
  PutWeekOfDaysOrder,
} from 'services/adhd';

const listSupport = {
  title: {
    showText: 'dayTitle',
    showType: 'Text',
  },
  contentType: {
    showText: 'contentType',
    showType: 'Text',
  },
  isMark: {
    showText: 'isMark',
    showType: 'Text',
    render: (text) => (text ? 'Yes' : 'No'),
  },
  pdf: {
    showText: 'PDF',
    showType: 'Text',
    render: (text) => (Object.keys(text || {}).length > 0 ? 'Yes' : 'No'),
  },
  sortOrder: {
    showText: 'sortOrder',
    showType: 'Text',
  },
};

const WeekThemeDays = ({ location }) => {
  const { id, title } = location.query || {};

  const [dataSource, setDataSource] = useState([]);
  const [modalVisible, setModalVisible] = useState({
    addDaysModal: false,
    sortOrderModal: false,
  });
  const [currentItem, setCurrentItem] = useState({});
  const [loading, setLoading] = useState({
    tableLoading: false,
    modalLoading: false,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetWeekOfDays({
      WeekThemeId: id,
      SkipCount: 0,
      MaxResultCount: 20,
    });

    if (res.response.ok) {
      const { items = [] } = res.data || {};
      setDataSource(items);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const tableProps = {
    listData: dataSource,
    listSupport,
    loading: loading.tableLoading,
    Actions: [
      {
        showText: 'Order',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                setCurrentItem(record);
                setModalVisible({
                  ...modalVisible,
                  sortOrderModal: true,
                });
              }}
            >
              Order
            </a>
          );
        },
      },
      {
        showText: 'Delete',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                confirm({
                  title: 'Are you sure to remove from this week?',
                  icon: <ExclamationCircleOutlined />,
                  content: null,
                  okText: 'Remove',
                  onOk: async () => {
                    const { id: recordId } = record || {};
                    const res = await DeleteWeekOfDays({
                      weekThemeId: id,
                      dailyTopicId: recordId,
                    });
                    initData();
                    if (res.response.ok) {
                      initData();
                      message.success('Delete successfully');
                    } else {
                      const { error } = res.data;
                      message.error(error.message);
                    }
                  },
                });
              }}
            >
              Delete
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    weekThemeId: id,
    visible: modalVisible.addDaysModal,
    selectData: dataSource,
    onClose: () => {
      setModalVisible({
        ...modalVisible,
        addDaysModal: false,
      });
    },
    reloadTable: initData,
  };

  const sortModalProps = {
    visible: modalVisible.sortOrderModal,
    onCancel() {
      setModalVisible({
        ...modalVisible,
        sortOrderModal: false,
      });
    },
    onOk: async (value) => {
      const { sortOrder } = value || {};

      const res = await PutWeekOfDaysOrder({
        weekThemeId: id,
        dailyTopicId: currentItem?.id,
        newSortOrder: sortOrder,
      });

      if (res.response.ok) {
        message.success('success');
        initData();
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      setModalVisible({
        ...modalVisible,
        sortOrderModal: false,
      });
    },
    currentValue: currentItem?.sortOrder,
  };

  return (
    <Card
      title={
        <Space>
          <Button icon={<CaretLeftOutlined />} onClick={() => history.goBack()}>
            Back
          </Button>
          <b style={{ fontSize: '20px' }}>{title}</b>
        </Space>
      }
      bordered={false}
      extra={
        <Button
          type="primary"
          onClick={() => {
            setModalVisible({
              ...modalVisible,
              addDaysModal: true,
            });
          }}
        >
          Add Days
        </Button>
      }
    >
      <MagicTable {...tableProps} />
      {modalVisible.addDaysModal && <DaysListModal {...modalProps} />}
      <SortOrderModal {...sortModalProps} />
    </Card>
  );
};

export default WeekThemeDays;
