import React from 'react'

const EmailSetting = () => {
  return (
    <div>
        <h1>邮件设置</h1>
      </div>
      )
}
export default EmailSetting
