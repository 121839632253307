import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
  GetPrepareEdit,
  UpdateOfflineRules,
} from 'services/rocketService/Prepare'

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

export default {
  namespace: 'coursewareDetail',
  state: {
    templateInfo: null,
    list: [],
    pagination: {
      current: 1,
      pageSize: 1,
      total: 0,
    },
    trainingCourseInfo: null,
    selectKeys: ['introduce'],
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *pageLoading({ payload }, { take, put, call, select }) {
      const { pagination } = yield select(state => state.coursewareDetail)
      const { templateId, page, cid } = payload
      const query = {
        skipCount: parseInt(page) - 1,
        maxResultCount: 1,
        templateId,
      }
      if (cid === undefined) {
        const res = yield [
          call(GetPrepareEdit, templateId, 'offlinePlan'),
          call(GetPrepareList, query, 'offlineRules'),
        ]
        if (res[0].response.ok && res[1].response.ok) {
          const templateInfo = res[0].data
          const { items, totalCount } = res[1].data
          const trainingCourselist = items.length > 0 ? items[0].courseWares : []
          yield put({
            type: 'updateState',
            payload: {
              templateInfo,
              pagination: { ...pagination, current: parseInt(page), total: totalCount },
              list: trainingCourselist,
            },
          })
          if (trainingCourselist.length > 0) {
            yield put({ type: 'getCoursewareInfo', payload: trainingCourselist[0] })
          } else {
            yield put({
              type: 'updateState',
              payload: { trainingCourseInfo: null },
            })
          }
        }
      } else {
        const res = yield [
          call(GetPrepareEdit, templateId, 'offlinePlan'),
          call(GetPrepareList, query, 'offlineRules'),
          call(GetPrepareEdit, cid, 'trainingCourse'),
        ]
        if (res[0].response.ok && res[1].response.ok && res[2].response.ok) {
          const templateInfo = res[0].data
          const { items, totalCount } = res[1].data
          const trainingCourselist = items.length > 0 ? items[0].courseWares : []
          yield put({
            type: 'updateState',
            payload: {
              templateInfo,
              pagination: { ...pagination, current: parseInt(page), total: totalCount },
              list: trainingCourselist,
              trainingCourseInfo: res[2].data,
              selectKeys: ['introduce'],
            },
          })
        }
      }
    },

    *queryList(_, { take, put, call, select }) {
      const { pagination, templateInfo } = yield select(state => state.coursewareDetail)
      const query = {
        skipCount: pagination.current - 1,
        maxResultCount: 1,
        templateId: templateInfo.id,
      }
      const res = yield call(GetPrepareList, query, 'offlineRules')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const trainingCourselist = items.length > 0 ? items[0].courseWares : []
        yield put({
          type: 'updateState',
          payload: { pagination: { ...pagination, total: totalCount }, list: trainingCourselist },
        })

        if (trainingCourselist.length > 0) {
          yield put({ type: 'getCoursewareInfo', payload: trainingCourselist[0] })
        } else {
          yield put({
            type: 'updateState',
            payload: { trainingCourseInfo: null },
          })
        }
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.coursewareDetail)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'queryList' })
    },

    *getCoursewareInfo({ payload }, { call, put, take, select }) {
      // const { list } = yield select(state => state.coursewareDetail)
      // const selectTrainingCourse = list.find(item=>item.id===payload.id)
      // yield put({
      //   type: 'updateState',
      //   payload: { trainingCourseInfo: selectTrainingCourse },
      // })
      const res = yield call(GetPrepareEdit, payload.id, 'trainingCourse')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { trainingCourseInfo: res.data, selectKeys: ['introduce'] },
        })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/detail/coursewareDetail') {
    //       dispatch({ type: 'pageLoading', payload: location.query })
    //     }
    //   })
    // },
  },
}
