import React from 'react';
import ShowCardBorder from './ShowCardBorder';
import ChangeEcharts from 'components/ChangeEcharts';
import { useIntl } from 'umi';
import ReactECharts from 'echarts-for-react';

// const xLables = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu'];
const xLables = ['Day1', 'Day2', 'Day3', 'Day4', 'Day5'];

const ExerciseOverview = ({
  data,
  isEmpty,
  changeWeek,
  currentWeek,
  weekAssigned,
}) => {
  const intl = useIntl();
  const finishData = [];
  const caughtData = [];

  data
    .sort((a, b) => a.exercise.dayIndex - b.exercise.dayIndex)
    .forEach((item) => {
      const { exercise, status, progress } = item;
      const { videoLink } = exercise;
      const { exercise: videoExercise } = JSON.parse(videoLink);

      const totalLength = videoExercise.length;

      const completedLength =
        progress.length === 0
          ? 0
          : progress.includes(',')
          ? progress.split(',').length
          : 1;

      if (completedLength > 0) {
        if (completedLength === 7) {
          finishData.push({
            value: completedLength,
            itemStyle: {
              borderRadius: [12, 12, 0, 0],
            },
          });
        } else {
          finishData.push(completedLength);
        }
        caughtData.push(totalLength - completedLength);
      } else {
        finishData.push(0);
        caughtData.push(totalLength);
      }
      // if (status === 'completed') {
      //   finishData.push(completedLength);
      //   caughtData.push(totalLength - completedLength);
      // } else {
      //   caughtData.push(totalLength);
      // }
    });

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      show: false,
      height: '70%',
      top: '15%',
    },
    xAxis: {
      type: 'category',
      data: xLables,
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    series: [
      {
        name: 'Completed',
        data: finishData,
        type: 'bar',
        barWidth: 24,
        stack: 'total',
        itemStyle: {
          color: '#2b9e84',
        },
      },
      {
        name: 'CaughtUp',
        data: caughtData,
        type: 'bar',
        barWidth: 24,
        stack: 'total',
        itemStyle: {
          borderRadius: [12, 12, 0, 0],
          color: '#d7d7d7',
        },
      },
    ],
  };

  const changeProps = {
    week: currentWeek,
    recentWeek: weekAssigned,
    onChangeWeek: (type) => {
      const tempWeek = parseInt(currentWeek);
      const res = type === 'right' ? tempWeek + 1 : tempWeek - 1;

      changeWeek(res);
    },
  };

  return (
    <ShowCardBorder>
      {isEmpty ? (
        <div>{intl.formatMessage({ id: 'emptyData' })}</div>
      ) : (
        <ChangeEcharts {...changeProps}>
          <ReactECharts
            option={options}
            style={{ height: '300px', width: '750px' }}
            notMerge={true}
            opts={{ renderer: 'svg' }}
          />
        </ChangeEcharts>
      )}
    </ShowCardBorder>
  );
};

export default ExerciseOverview;
