export default {
  namespace: 'editReport',
  state: {},
  reducer: {},
  effects: {
    *loadPage({ payload }, { call, put, take, select }) {},
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/assessment/qa/editReport') {
    //     }
    //   })
    // },
  },
}
