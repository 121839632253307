import React, { useEffect, useState } from 'react';
import { connect, history, useIntl } from 'umi'
import moment from 'moment';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const dateFormat = 'YYYY/MM/DD';

const ExpandedTable = ({centerDetail, loading, dispatch, location, allData, expanded }) => {
  const intl = useIntl()
  const {monthList = {}, editMonthRemarkContent, remarkMonthVisible} = centerDetail || {}
  const { Id } = allData || {};

  const listSupport = {
    Period: {
      showText: intl.formatMessage({ id: 'subscription periods' }),
      showType: 'Text',
    },
    TestAmount: {
      showText: intl.formatMessage({ id: 'Test volume (surplus)' }),
      showType: 'Text',
      render: (_, record, index) => {
        return (
          <span>
            {record.TestAmount}({record.RemainingTestAmount})
          </span>
        );
      },
    },
    LastAmount: {
      showText: intl.formatMessage({ id: 'availableTest' }),
      showType: 'Text',
    },
    UsedAmount: {
      showText: intl.formatMessage({ id: 'consumptionTest' }),
      showType: 'Text',
    },
    Validity: {
      showText: intl.formatMessage({ id: 'availablePeriod' }),
      showType: 'Text',
      render: (_, record, index) => {
        return (
          <span>
            {moment(record.StartTime).local().format(dateFormat)} -{' '}
            {moment(record.EndTime).local().format(dateFormat)}
          </span>
        );
      },
    },
    RemainingAmount: {
      showText: intl.formatMessage({ id: 'Balance month' }),
      showType: 'Text',
      render: (_, record, index) => {
        return (
          <span style={{ color: record.RemainingAmount >= 0 ? '' : 'red' }}>
            {record.RemainingAmount}
          </span>
        );
      },
    },
    Remark: {
      showText: intl.formatMessage({ id: 'Comments' }),
      showType: 'Text',
    },
  };

  const remarkProps = {
    visible: remarkMonthVisible,
    title: intl.formatMessage({ id: 'reminder values' }),
    onCancel: () => {
      dispatch({
        type: 'centerDetail/updateState',
        payload: {
          remarkMonthVisible: false,
        }
      })
    },
    onOk: (values) => {
      dispatch({ type: 'centerDetail/submitMonthRemark', payload: values })
    },
    content: editMonthRemarkContent,
  }
  useEffect(() => {
    if(expanded) {
      dispatch({ type: 'centerDetail/getMonthChildrenList', payload: { Id } })
    }
  }, [expanded]);

  const tableProps = {
    loading: loading.effects['centerDetail/getMonthChildrenList'],
    listData: monthList[Id] || [],
    listSupport,
    Actions: [
      {
        showText: '编辑备注',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation()
                dispatch({
                  type: 'centerDetail/monthRemarkEdit',
                  payload: { record, intl },
                })
              }}
            >
             {intl.formatMessage({ id: 'Edit Notes' })}
            </a>
          );
        },
      },
    ],
  };
  return (
    <>
      <MagicTable {...tableProps} />
      <MagicFormModal {...remarkProps} />
    </>
  );
};

export default connect(({ centerDetail, loading }) => ({ centerDetail, loading }))(ExpandedTable)

