import React from 'react';
import CustomMicroApp from 'components/CustomMicroApp';
import ShowCardBorder from './ShowCardBorder';
import styles from './index.less';

const SleepAnalysic = ({ week, onChangeWeek }) => {
  const url = week ? `/sleepWeeklyAnalyse?week=${week}` : `/sleepWeeklyAnalyse`;

  const props = {
    url,
    appProps: {
      changeWeek: onChangeWeek,
    },
  };

  return (
    <div className={styles.SleepAnalysic}>
      <ShowCardBorder>
        <CustomMicroApp {...props} />
      </ShowCardBorder>
    </div>
  );
};

export default React.memo(SleepAnalysic);
