import React from 'react';
import moment from 'moment';
import {
  Tabs,
  Button,
  Switch,
  Space,
  Divider,
  Drawer,
  Spin,
  Modal,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import styles from './NewLocation.less';
const { TabPane } = Tabs;

const listSupport = {
  creationTime: {
    showText: '提交时间',
    showType: 'Time',
    addtional: {
      // format: 'YYYY-MM-DD HH:mm'
      format: 'YYYY/MM/DD',
    },
  },
  numberOfPlaces: {
    showText: '培训名额数',
    showType: 'text',
  },
  remark: {
    showText: '备注',
    showType: 'Remark',
  },
};

const drawerListSupport = {
  creationTime: {
    showText: '提交时间',
    showType: 'Time',
    addtional: {
      format: 'YYYY-MM-DD HH:mm',
    },
  },
  option: {
    showText: '操作',
    showType: 'text',
  },
  optionPerson: {
    showText: '操作人',
    showType: 'text',
  },
};

const modalTitleObj = {
  courseValidity: '编辑课程有效期',
  addTrainingPlaces: '添加名额',
  modulePrice: '编辑自助缴费单价',
};

const TrainingCourses = ({
  tabs,
  loading,
  currentTab,
  info,
  visible,
  content,
  modalType,
  changeTabs,
  changeOpenState,
  editModal,
  onModalCancel,
  onModalOk,
  drawerVisible,
  showRecord,
  closeDrawer,
  changePriceState,
  onAddSelectModule
}) => {
  const {
    status,
    usedNumber,
    validDate,
    numberOfPlaces,
    trainingModuleResources,
    lastStartTime,
    price,
    canUsePrice,
  } = info || {};

  const currentTabItem = tabs.find((item) => item.name === currentTab);

  const tableProps = {
    listSupport,
    listData: trainingModuleResources || [],
  };

  const modalProps = {
    loading,
    visible,
    content,
    title: modalTitleObj[modalType],
    onCancel: onModalCancel,
    onOk: onModalOk,
  };

  const drawerTableProps = {
    loading,
    listSupport: drawerListSupport,
    listData: [],
  };

  const onChangeOpenState = (value) => {
    Modal.confirm({
      title: `确定为此中心${value ? '开启' : '关闭'}${currentTabItem.displayName}培训吗?`,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        changeOpenState(value);
      },
    });
  };

  const onChangePriceState = (value) => {
    Modal.confirm({
      title: `确定为此中心${value ? '开启' : '关闭'}${currentTab}自助缴费吗?`,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        changePriceState(value);
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.trainingCourses}>
        <Tabs
          tabBarExtraContent={{
            left: (
              <Button
                style={{ marginLeft: '20px' }}
                icon={<PlusOutlined />}
                onClick={onAddSelectModule}
              ></Button>
            ),
          }}
          activeKey={currentTab}
          onChange={changeTabs}
          type="card"
        >
          {tabs.map((item) => {
            return <TabPane tab={item.displayName} key={item.name}></TabPane>;
          })}
        </Tabs>
        <div className={styles.trainingCoursesContent}>
          <div className={styles.trainingCoursesContentTop}>
            <div className={styles.trainingCoursesContentTopItem}>
              <div className={styles.trainingCoursesContentTopItemLeft}>
                {status ? (
                  <React.Fragment>
                    <span>
                      开启时间：
                      {moment(lastStartTime).format('YYYY/MM/DD HH:mm')}
                    </span>
                    {/* <span className={styles.trainingCoursesContentTopItemLeftRecord} onClick={showRecord}><a>历史记录</a></span> */}
                  </React.Fragment>
                ) : (
                  <span>未开启</span>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Switch
                  onChange={(value) => onChangeOpenState(value)}
                  checked={status}
                />
              </div>
            </div>
            <div className={styles.trainingCoursesContentTopItem}>
              <div className={styles.trainingCoursesContentTopItemLeft}>
                <span>课程有效期：{validDate}天</span>
                <span
                  className={styles.trainingCoursesContentTopItemLeftEdit}
                  onClick={() => editModal({ type: 'courseValidity' })}
                >
                  <a>编辑</a>
                </span>
              </div>
            </div>
            {/* <div className={styles.trainingCoursesContentTopItem}>
                <div className={styles.trainingCoursesContentTopItemLeft}>
                    <span>自助缴费单价：￥{price}</span>
                    <span className={styles.trainingCoursesContentTopItemLeftEdit} onClick={() => editModal({ type: 'modulePrice' })}><a>编辑</a></span>
                    <Switch onChange={(value) => onChangePriceState(value)} checked={canUsePrice} size={"small"} />
                </div>
            </div> */}
            <div className={styles.trainingCoursesContentTopItem}>
              <div className={styles.trainingCoursesContentTopItemLeft}>
                <span>培训名额数：</span>
                <Space split={<Divider type="vertical" />}>
                  <span>可用 {numberOfPlaces} 名</span>
                  <span>累计已用 {usedNumber} 名</span>
                  <span>累计发放 {numberOfPlaces + usedNumber} 名</span>
                </Space>
              </div>
            </div>
          </div>
          <div className={styles.trainingPlaces}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => editModal({ type: 'addTrainingPlaces' })}
            >
              培训名额
            </Button>
          </div>
          <MagicTable {...tableProps} />
          <MagicFormModal {...modalProps} />
          <Drawer
            width={700}
            open={drawerVisible}
            onClose={closeDrawer}
            title={<b>{`${currentTab}开启/停用历史记录`}</b>}
          >
            <MagicTable {...drawerTableProps} />
          </Drawer>
        </div>
      </div>
    </Spin>
  );
};

export default TrainingCourses;
