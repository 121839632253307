import React, { useEffect } from 'react';
import { Table, Button, Divider, Spin } from 'antd';
import { getSurveyDataByType } from 'cognitiveleap-core-us/utils/utils';
import { connect, useIntl, getLocale } from 'umi';
import moment from 'moment';
import styles from './index.less';

const SPLIT_PAGE = 4;

const Compare = ({ compare, loading, dispatch, location }) => {
  const intl = useIntl();
  const tableLoading = loading.effects['compare/loadPage'];
  // zh-cn 为中文，default 为英文

  const language = getLocale() === 'zh-CN' ? 'zh-cn' : 'en';

  const { showAnswerList, questionnare, type } = compare;

  useEffect(() => {
    dispatch({ type: 'compare/loadPage', payload: location.query });
  }, []);

  const headerTitle = () => {
    switch (type) {
      case 'mfa':
        return intl.formatMessage({ id: 'FASBasicCompetency' });
      case 'mfatwo':
        return 'FAS2.0哈氏基础能力测评统计图';
      case 'snap':
        return intl.formatMessage({ id: 'childrenAttention' });
      case 'parent':
        return intl.formatMessage({ id: 'parentInterviews' });
      case 'chiefComlaint':
        return intl.formatMessage({ id: 'childStrengthsChallenges' });
      default:
        return '';
    }
  };

  const getFormatDate = (date) => {
    return moment(date).format('YYYY/MM/DD');
  };

  const getColumns = (showAnswerList) => {
    return [
      {
        title: intl.formatMessage({ id: 'question' }),
        dataIndex: 'question',
        key: 'question',
        width: type === 'parent' || type === 'chiefComlaint' ? '10%' : '20%',
        className: `${styles.questionTitle}`,
      },
      ...showAnswerList.map((item) => {
        const { finishTime } = item;
        const formatDate = getFormatDate(finishTime);
        return {
          title: formatDate,
          dataIndex: finishTime,
          key: finishTime,
          width: '20%',
          render: (text) => (
            <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
              {text}
            </div>
          )
        };
      }),
    ];
  };

  const getDataSource = (questionnare, showAnswerList) => {
    return questionnare.map((questionnareItem, index) => {
      const { name, title } = questionnareItem;
      const showTitle = title
        ? Object.prototype.toString.call(title) === '[object Object]'
          ? title.hasOwnProperty(language)
            ? title[language]
            : title['default']
          : title
        : '';
      const answerObj = {
        key: showTitle + index,
        question: showTitle,
      };
      showAnswerList.forEach((answerItem) => {
        const { finishTime, answer } = answerItem;
        answerObj[finishTime] = getSurveyDataByType(answer, questionnareItem);
      });
      return answerObj;
    });
  };

  const showData = getDataSource(questionnare, showAnswerList);

  const columns = getColumns(showAnswerList);

  const splitNum = Math.ceil((columns.length - 1) / SPLIT_PAGE);

  const downloadPdf = () => {
    document.getElementById('downlaodButton').style.display = 'none';
    window.print();
    document.getElementById('downlaodButton').style.display = 'block';
  };

  return (
    <Spin spinning={tableLoading}>
      <div className={styles.total}>
        <div className={styles.headerTitle}>
          {headerTitle()} - {intl.formatMessage({ id: 'detailsComparison' })}
        </div>
        <div className={styles.panel}>
        {
          Array(splitNum).fill(0).map((_, index) => {
            const tempColumn = [
              columns[0],
              ...columns.slice(index * SPLIT_PAGE + 1, (index + 1) * SPLIT_PAGE + 1)
            ]
            return (
              <div key={index}>
                {index > 0 && <Divider />}
                <Table
                  key={index}
                  bordered="true"
                  // loading={tableLoading}
                  size="small"
                  columns={tempColumn}
                  dataSource={showData}
                  pagination={false}
                />
                {(index + 1) < splitNum && <div style={{ pageBreakAfter: 'always' }}></div>}
              </div>
            )
          })
        }
        </div>
        <div className={styles.downlaod}>
          <Button onClick={() => downloadPdf()} id="downlaodButton">
            {intl.formatMessage({ id: 'printDetails' })}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default connect(({ compare, loading }) => ({ compare, loading }))(
  Compare,
);
