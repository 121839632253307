import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
} from 'services/rocketService/Prepare'

import {
  PostTrainingProgram,
  GetTrainingProgramList,
  GetTrainingProgram,
  PutTrainingProgram,
  PrepareForEdit,
  TrainingProgramDetail,
  DeleteTrainingProgram,
} from 'services/rocketService/TrainingProgram'

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show] || '', Id: item.id.toString() }
  })
}

const content = {
  Properties: [
    {
      EditorType: 'RichEditorPro',
      ShowTitle: '模块详情说明 富文本',
      FormKey: 'modelDetails',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '训练项目',
      FormKey: 'trainingProgramIds',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '标签(五步循环法)',
      FormKey: 'tagIds',
      Description: null,
    },
  ],
}

const showTag =(item)=>{
  if(item.tagType==null){
    return item.displayName
  }else{
    return `${item.displayName}--${item.tagType||''}`
  }
}

export default {
  namespace: 'trainingCourseDetail',
  state: {
    trainingCourseInfo: null,
    categorySelectModal: false,
    categoryList: [],
    selectCategoryId: null,

    modalVisible: false,
    content: null,
    selectItem: null,

    sortModalVisible: false,
    sortParams: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage({ payload }, { call, put, take, select }) {
      const res = yield call(GetPrepareEdit, payload.id, 'trainingCourse')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { trainingCourseInfo: res.data },
        })
      }
    },

    *addCategory(_, { call, put, take, select }) {
      const res = yield call(GetPrepareList, { MaxResultCount: 1000 }, 'category')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            categoryList: res.data.items,
            categorySelectModal: true,
          },
        })
      }
    },

    *showAddOrEditContent({ payload }, { call, put, take, select }) {
      const { categoryId: selectCategoryId, selectItem } = payload
      const res = yield [
        call(GetTrainingProgramList, { CategoryId: selectCategoryId, MaxResultCount: 1000 }),
        call(GetPrepareList, { MaxResultCount: 1000 }, 'tag'),
      ]
      if ((res[0].response.ok, res[1].response.ok)) {
        const programOptions = listToOptions(res[0].data.items, 'title')
        const tagOptions = res[1].data.items.map(item => {
          return { Value: showTag(item), Id: item.id }
        })
        const emptyContent = {...content}
        emptyContent.Properties[1].Setting.SelectorOptions = programOptions
        emptyContent.Properties[2].Setting.SelectorOptions = tagOptions
        const showForm =
          selectItem === null
            ? emptyContent
            : matchDynamicForm(emptyContent, {
                ...selectItem.data,
              })

        yield put({
          type: 'updateState',
          payload: {
            categorySelectModal: false,
            modalVisible: true,
            content: showForm,
            selectCategoryId,
            selectItem,
          },
        })
      }
    },

    *updateCategories({ payload }, { call, put, take, select }) {
      const { trainingCourseInfo } = yield select(state => state.trainingCourseDetail)
      const postData = { ...trainingCourseInfo, categories: payload }
      const res = yield call(PutPrepareData, postData, trainingCourseInfo.id, 'trainingCourse')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            selectCategoryId: '',
            selectItem: null,
            modalVisible: false,
            sortModalVisible: false,
          },
        })
        yield put({ type: 'loadPage', payload: { id: trainingCourseInfo.id } })
      }
    },

    *onSubmit({ payload }, { call, put, take, select }) {
      const { selectCategoryId, trainingCourseInfo, selectItem } = yield select(
        state => state.trainingCourseDetail
      )
      const currentCategories = trainingCourseInfo.categories.slice(
        0,
        trainingCourseInfo.categories.length
      )
      const payloadCategory = { ...payload, trainingCategoryId: selectCategoryId }
      const newCategories =
        selectItem === null
          ? currentCategories.concat([payloadCategory])
          : currentCategories.map((item, index) => {
              return index === selectItem.index ? payloadCategory : item
            })
      yield put({ type: 'updateCategories', payload: newCategories })
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/trainingCourseDetail') {
    //       dispatch({ type: 'loadPage', payload: location.query })
    //     }
    //   })
    // },
  },
}
