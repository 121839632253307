import { useState, useEffect } from 'react';
import { message } from 'antd';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils';

import { GetTagDetail, PostTagList, PutTag } from 'services/adhd';

const modalTypeToTitle = {
  edit: 'Edit Tag',
  add: 'Add Tag',
};

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'name',
      FormKey: 'name',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        Disabled: false,
      },
      Description: 'Up to 100 characters (including Chinese and English)',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'displayName',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: 'Up to 100 characters (including Chinese and English)',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'type',
      FormKey: 'tagType',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: 'Key Words, Disorder, Program Goal',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'description',
      FormKey: 'description',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: 'Instructions for use.',
    },
  ],
};

const TagModal = ({
  modalVisible,
  currentItem,
  modalType,
  onClose,
  reloadTable,
}) => {
  const isEdit = modalType === 'edit' && Object.keys(currentItem).length > 0;
  const { id } = currentItem || {};

  const [currentContent, setCurrentContent] = useState(contentMenu);
  const [loading, setLoading] = useState({
    initLoading: false,
    modalLoading: false,
  });

  const initData = async () => {
    let showForm = contentMenu;

    if (isEdit) {
      const res = await GetTagDetail({ id });

      if (res.response.ok) {
        showForm.Properties[0].Setting.Disabled = true;

        showForm = matchDynamicForm(contentMenu, {
          ...res?.data,
        });
      } else {
        const { error } = res.data;
        message.error(error.message);
      }
    } else {
      showForm.Properties[0].Setting.Disabled = false;
    }

    setCurrentContent(showForm);

    setLoading({
      ...loading,
      initLoading: true,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const modalProps = {
    loading: loading.modalLoading,
    title: modalTypeToTitle[modalType],
    visible: modalVisible,
    onCancel() {
      onClose();
    },
    onOk: async (value) => {
      setLoading({
        ...loading,
        modalLoading: true,
      });

      const res = isEdit
        ? await PutTag({
            id,
            ...value,
          })
        : await PostTagList(value);

      if (res.response.ok) {
        onClose();
        reloadTable();
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      setLoading({
        ...loading,
        modalLoading: false,
      });
    },
    content: currentContent,
  };

  return loading.initLoading && <MagicFormModal {...modalProps} />;
};

export default TagModal;
