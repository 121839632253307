import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal, message, Input } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import {checkFeedbackProperty} from 'utils/utils'
const Search = Input.Search
const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑反馈属性',
  add: '新建反馈属性',
}

const FeedbackProperty = ({ feedbackProperty, dispatch }) => {
  const {
    modalType,
    content,
    modalVisible,
    list,
    listSupport,
    pagination,
    searchInput
  } = feedbackProperty

  useEffect(() => {
    dispatch({ type: 'feedbackProperty/query' })
  }, [])

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  // const stringToList = str => {
  //   const parts = str.split(':')
  //   if (parts.length === 2) {
  //     return parts
  //   } else return []
  // }

  // const checkProperty = (type, extraProperties) => {
  //   const currentType = availableTypes.find(item => {
  //     return item.name === type
  //   })
  //   const { constraints } = currentType
  //   return Object.keys(extraProperties).every(item => {
  //     return constraints.some(constraintItem => {
  //       return constraintItem.name === item
  //     })
  //   })
  // }

  // const showError = type => {
  //   const currentType = availableTypes.find(item => {
  //     return (item.name = type)
  //   })
  //   message.error('Include not avaliable property keys')
  // }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'feedbackProperty/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      const { extra, type } = value
      if(checkFeedbackProperty(type,extra)){
        const extraProperties = JSON.parse(extra)
        dispatch({
          type: 'feedbackProperty/onSubmitModal',
          payload: { ...value, extraProperties },
        })
      }

      // let extraProperties = {}
      // if (extra !== null) {
      //   extra.split(';').forEach(item => {
      //     const listStr = stringToList(item)
      //     if (listStr.length === 2) {
      //       extraProperties[listStr[0].trim()] = listStr[1].trim()
      //     }
      //   })
      // }
      // if (checkProperty(type, extraProperties)) {
      //   dispatch({
      //     type: 'feedbackProperty/onSubmitModal',
      //     payload: { ...value, extraProperties },
      //   })
      // } else {
      //   showError(type)
      // }
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'feedbackProperty/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'feedbackProperty/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'feedbackProperty/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'feedbackProperty/onAdd' })
  }

  return (
    <div>
      <h1>打卡反馈属性</h1>
      <Button onClick={onAddClick}>新建反馈属性</Button>
      <Search
        placeholder="训练项目名称"
        onSearch={value =>
          dispatch({ type: 'feedbackProperty/changeTable', payload: { searchInput: value } })
        }
        onChange={e =>
          dispatch({
            type: 'feedbackProperty/updateState',
            payload: { searchInput: e.target.value },
          })
        }
        style={{ width: 200 }}
        value={searchInput}
      />
      <Button
        onClick={() =>
          dispatch({ type: 'feedbackProperty/clearAllFilterAndSorter' })
        }
      >
        Clear
      </Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ feedbackProperty }) => ({ feedbackProperty }))(FeedbackProperty)
