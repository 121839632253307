import ReactEcharts from 'echarts-for-react';

const ProbChart = ({ data }) => {
  const option = {
    series: [
      {
        type: 'gauge',
        center: ['50%', '50%'],
        radius: '100%',
        axisLine: {
          lineStyle: {
            width: 22,
            color: [
              [0.333, '#F36E65'],
              [0.667, '#FFCE3D'],
              [1, '#3DBD7D'],
            ],
          },
        },
        pointer: {
          width: 10,
          length: 80,
          itemStyle: {
            color: 'auto',
          },
        },
        axisTick: {
          distance: -30,
          length: 8,
          lineStyle: {
            color: '#fff',
            width: 0,
          },
        },
        splitLine: {
          distance: -30,
          length: 30,
          lineStyle: {
            color: '#fff',
            width: 2,
          },
        },
        axisLabel: {
          color: 'auto',
          distance: 35,
          fontSize: 15,
        },
        detail: {
          valueAnimation: true,
          color: 'auto',
          fontSize: 40,
        },

        data: [],
      },
    ],
  };

  option.series[0].data.push({ value: data });

  return (
    <ReactEcharts
      option={option}
      style={{ height: '266px', width: '266px' }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default ProbChart;
