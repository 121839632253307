import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Space, Image, message, Spin, Tabs} from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { history, useIntl } from 'umi';
import DeviceModal from 'components/DeviceModal';
import PicoImg from 'assets/picoImg.png';
import { GetDeviceDetail, GetDeviceUseageDetail} from 'services/device';
const { TabPane } = Tabs;
const DeviceDetail = ({ location }) => {
  const intl = useIntl();
  const { registerDeviceId } = location.query || {};
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState('pass');
  const [loading, setLoading] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [dataSource, setDataSource] = useState({});
  const [filter, setFilter] = useState([]);
  const showStatus =  {
    WaitForRegistration: intl.formatMessage({ id: 'PendingRegistration' }),
    Reviewing: intl.formatMessage({ id: 'UnderReview' }),
    Registered: intl.formatMessage({ id: 'Registered' }),
  };

  // 设备注册记录
  const listSupport = {
    OperationDescription: {
      showText: intl.formatMessage({ id: 'Operation Record' }),
      showType: 'Text',
    },
    OperationSource: {
      showText: intl.formatMessage({ id: 'Operation Source' }),
      showType: 'Text',
    },
    Email: {
      showText: intl.formatMessage({ id: 'user' }),
      showType: 'Text',
    },
    TenantName: {
      showText: intl.formatMessage({ id: 'center' }),
      showType: 'Text',
    },
    RegisterTime: {
      showText: intl.formatMessage({ id: 'Operation Time' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm:ss',
      },
    },
    Remark: {
      showText: intl.formatMessage({ id: 'comments' }),
      showType: 'Text',
    },
  };

  // 设备使用记录
  const useageList = {
    CaseId: {
      showText: intl.formatMessage({ id: 'caseId' }),
      showType: 'Text',
    },
    DeviceIP: {
      showText: intl.formatMessage({ id: 'Device IP' }),
      showType: 'Text',
    },
    IPGeoLocation: {
      showText: intl.formatMessage({ id: 'IP Home' }),
      showType: 'Text',
    },
    TenantName: {
      showText: intl.formatMessage({ id: 'center' }),
      showType: 'Text',
    },
    ReportStatusType: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: [
          {
            text: intl.formatMessage({ id: 'untested' }),
            value: 'UnTested',
          },
          {
            text: intl.formatMessage({ id: 'testing' }),
            value: 'Testing'
          },
          {
            text: intl.formatMessage({ id: 'tested' }),
            value: 'Tested'
          },
          {
            text: intl.formatMessage({ id: 'reported' }),
            value: 'ReportReady',
          },
          {
            text: intl.formatMessage({ id: 'reportGenerating' }),
            value: 'ReportGenerating',
          },
          {
            text: intl.formatMessage({ id: 'failure' }),
            value: 'Failed',
          },
        ]
      }
    },
    TestTime: {
      showText: intl.formatMessage({ id: 'Date created times' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm:ss',
      },
    },
  
  }
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
    // 翻页数据
    const [usePagination, setUsePagination] = useState({
      current: 1,
      pageSize: 5,
      total: 0,
    });

  const {
    DeviceName,
    DeviceDetailDtos = [],
    DeviceSN,
    Status,
    TenantName,
  } = infoData || {};

  const { Records = [] } = dataSource || {}
  // 初始化设备注册记录
  const initData = async () => {
    setLoading(true);
    const res = await GetDeviceDetail({ registerDeviceId });

    if (res.response.ok) {
      const { DeviceDetailDtos = [] } = res.data || {};
      setPagination({
        ...pagination,
        total: DeviceDetailDtos.length,
      });
      setInfoData(res.data);
    } else {
      const {
        data: { Error },
      } = res;
      message.error(Error?.Message);
    }
    setLoading(false);
  };

  //初始化设备使用记录
  const initUseageData = async () => {
    setLoading(true);
    const params = {
      ReportStatus: filter,
      RegisterDeviceId: registerDeviceId,
    }
    const res = await GetDeviceUseageDetail(params);
    const { response, data } = res || {};

    if(response.ok) {
      const { Records = [] } = data || {};
      setUsePagination({
        ...usePagination,
        current: 1,
        total: Records.length,
      });
      setDataSource(data);
    } else {
      const { data: { Error } } = res || {};
      message.error(Error?.Message || '数据初始化失败');
    }
    setLoading(false);
  };

  const option = (type) => {
    setVisible(true);
    setType(type);
  };

  const startIndex = (pagination.current - 1) * pagination.pageSize;
  const endIndex = startIndex + pagination.pageSize;
  const tableProps = {
    listData: DeviceDetailDtos.slice(startIndex, endIndex),
    listSupport,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
  };
  const useStartIndex = (usePagination.current - 1) * usePagination.pageSize;
  const useEndIndex = useStartIndex + usePagination.pageSize;
  const deviceUseageProps  = {
    listData: Records
      .filter(({ ReportStatusType }) => filter.length === 0 || filter.includes(ReportStatusType))
      .slice(useStartIndex, useEndIndex),
    listSupport: useageList,
    pagination: usePagination,
    filters: {
      ReportStatusType: filter,
    },
    onTableChange(page, filt = {}) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setUsePagination({
          ...usePagination,
          current,
          pageSize,
        });
      };

      if (Object.keys(filt).length > 0) {
        const { ReportStatusType } = filt;
        setFilter(ReportStatusType);
        setUsePagination({
          ...usePagination,
          current: 1,
          total: Records.filter(({ ReportStatusType: rst }) => ReportStatusType.length === 0 || ReportStatusType.includes(rst)).length,
        });
      };
    },
  }

  useEffect(() => {
    initData();
    initUseageData();
  }, []);

  const modalProps = {
    visible,
    currentState: {
      type,
      record: infoData,
    },
    reloadPage: initData,
    onCloseModal: () => {
      setVisible(false);
    },
  };

  return (
    <Card
      title={<Button onClick={() => history.goBack()}>BACK</Button>}
      bordered={false}
    >
      <Spin spinning={loading}>
        <Row>
          <Col flex={22}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: 'flex' }}
            >
              <h3>
                <strong>{intl.formatMessage({ id: 'Device Information' })}</strong>
              </h3>
              <div>{intl.formatMessage({ id: 'DeviceSN' })}：{DeviceSN}</div>
              <div>{intl.formatMessage({ id: 'DeviceName' })}：{DeviceName}</div>
              <div>{intl.formatMessage({ id: 'center' })}：{TenantName}</div>
              <div>{intl.formatMessage({ id: 'current state' })}：{showStatus[Status]}</div>
              {Status !== 'WaitForRegistration' && (
                <div>
                  {intl.formatMessage({ id: 'Action' })}：
                  {Status === 'Reviewing' ? (
                    <Space>
                      <a onClick={() => option('pass')}>{intl.formatMessage({ id: 'pass' })}</a>
                      <a onClick={() => option('reject')}>{intl.formatMessage({ id: 'Reject' })}</a>
                    </Space>
                  ) : (
                    <a onClick={() => option('logout')}>{intl.formatMessage({ id: 'Log Off' })}</a>
                  )}
                </div>
              )}
            </Space>
          </Col>
          <Col flex={2}>
            <Image width={420} src={PicoImg} preview={false} />
          </Col>
        </Row>
        <p />
        <Tabs defaultActiveKey='1'>
          <TabPane tab={intl.formatMessage({ id: 'Device Registration Record' })} key="1">
            <MagicTable {...tableProps} />
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: 'Device Usage Records' })} key="2">
            <MagicTable {...deviceUseageProps} />
          </TabPane>
        </Tabs>
        {visible && <DeviceModal {...modalProps} />}
      </Spin>
    </Card>
  );
};

export default DeviceDetail;
