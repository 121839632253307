import { message } from 'antd'
import { GetAdminStaffList, } from 'services/trainingSystem'
import { 
    TrainerToSupervisor, 
    GetSupervisedList,
    DeleteSupervisor
} from 'services/supervisor'

export default {
    namespace: 'SuperviseList',

    state: {
        changeStaffModalVisible: false,
        staffList: [],
        dataSource: [],
        staffId: '',
        supervisorData: null,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        Filter: '',
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    effects: {
        *initPage({ payload }, { call, put, take, select }) {
            const { id } = payload || {}
            yield put({ type: 'updateState', payload: { staffId: id } })
            yield put({ type: 'getSupervisorById' })
        },

        *getSupervisorById({ payload }, { call, put, take, select }) {
            const { staffId, pagination, Filter } = yield select(state => state.SuperviseList)
            const { current, pageSize } = pagination
            const params = {
                Filter,
                SupervisorId: staffId,
                MaxResultCount: pageSize,
                SkipCount: (current - 1) * pageSize
            }
            const getSupervisorRes = yield call(GetSupervisedList, params)
            if (getSupervisorRes.response.ok) {
                const { items, totalCount } = getSupervisorRes.data
                yield put({
                    type: 'updateState',
                    payload: {
                        supervisorData: getSupervisorRes.data,
                        pagination: {
                            current,
                            pageSize,
                            total: totalCount,
                        }
                    }
                })
                //yield put({ type: 'getAllStaff', payload })
            }
        },

        *getAllStaff({ payload }, { call, put, take, select }) {
            const { supervisorData, staffId } = yield select(state => state.SuperviseList)
            const canNotChoiceId = supervisorData?.items?.map(item => item.id).concat(staffId)
            const params = {
                MaxResultCount: 10000,
                SkipCount: 0
            }
            const getStaffListRes = yield call(GetAdminStaffList, params)
            if (getStaffListRes.response.ok) {
                const { items } = getStaffListRes.data || {}
                const dataSource = items.map(item => {
                    const { id, name, email } = item || {}
                    const title = name || email
                    return {
                        ...item,
                        title,
                        description: title,
                        disabled: canNotChoiceId.includes(id)
                    }
                })
                yield put({
                    type: 'updateState',
                    payload: {
                        staffList: items,
                        changeStaffModalVisible: true,
                        dataSource
                    }
                })
            }
            else{
                message.error('获取员工信息失败')
            }
        },

        *addStaff({ payload }, { call, put, take, select }) {
            const { staffId } = yield select(state => state.SuperviseList)
            const params = {
                supervisorId: staffId,
                trainerIds: payload
            }
            const addStaffRes = yield call(TrainerToSupervisor, params)
            if (addStaffRes.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: {
                        changeStaffModalVisible: false
                    }
                })
                yield put({ type: 'getSupervisorById' })
                message.success("添加成功")
            }else{
                message.error("添加失败")
            }
        },

        *changeTable({ payload }, { call, put, take, select }) {
            const { pagination } = yield select(state => state.SuperviseList)
            const { pagination: newPagination } = payload||{}
            const { current } = newPagination||{}
            yield put({ type: 'updateState', payload: { pagination: { ...pagination, current } } })
            yield put({ type: 'getSupervisorById' })
        },

        *onclear({ payload }, { call, put, take, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({
                type: 'updateState',
                payload: {
                    pagination,
                    Filter: ''
                }
            })
            yield put({ type: 'getSupervisorById' })
        },

        *onSearch({ payload }, { call, put, take, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'getSupervisorById' })
        },

        *deleteSupervisor({ payload }, { call, put, take, select }){
            const { staffId } = yield select(state => state.SuperviseList)
            const { id } = payload
            const params = {
                SupervisorId: staffId,
                TrainerIds: id?[id]:[]
            }
            const deleteSupervisorRes = yield call(DeleteSupervisor, params)
            if(deleteSupervisorRes.response.ok){
                message.success("删除成功")
                yield put({ type: 'getSupervisorById' })
            }
        }
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(({ pathname, query }) => {
        //         if (pathname === '/system/manage/superviseList') {
        //             dispatch({ type: 'initPage', payload: query })
        //         }
        //     })
        // },
    },
}
