import { AdminGetUserDetailInfo } from 'services/userManager'
import { PostCourseToUser, GetUserGrantCourse, CancelCourseToUser } from 'services/trainerDatabase'
import { GetAppUserQuestionnarie } from 'services/trainingSystem'
import { getFormatDate } from 'cognitiveleap-core-us/utils/utils'
import { message } from 'antd'

const content = {
    Properties: [
        {
            EditorType: 'SingleWithSearch',
            Value: null,
            Setting: {
                DropdownOptions: [],
                Required: true,
            },
            ShowTitle: '课程',
            FormKey: 'courseIds',
            Description: null,
        },
        {
            EditorType: 'DatePicker',
            Value: null,
            Setting: {
                Required: false,
                DateType: 'date'
            },
            ShowTitle: '开始日期',
            FormKey: 'startDate'
        },
        {
            EditorType: 'DatePicker',
            Value: null,
            Setting: {
                Required: false,
                DateType: 'date'
            },
            ShowTitle: '结束日期',
            FormKey: 'endDate',
            Description: '从开始日期的00:00到结束日期的24:00；如不填，则为“不限期”'
        }
    ]
}

export default {
    namespace: 'adminUserDetail',

    state: {
        userInfo: null,
        tabActiveKey: 'class',
        selectModalVisible: false,
        userClassTablePagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        userGrantCourse: null,
        userCourseList: [],
        content: null,
        drawerVisible: false,
        selectCourse: null,
        collapseActiveKey: []
    },

    effects: {
        *initPage({ payload }, { call, put, take, select }) {
            yield put({ type: 'getUserDetailInfo', payload })
            yield put({ type: 'getUserClass', payload })
        },

        *getUserDetailInfo({ payload }, { call, put, take, select }) {
            const res = yield call(AdminGetUserDetailInfo, payload.id)
            yield put({
                type: 'updateState',
                payload: { userInfo: res.data },
            })
        },

        *getUserClass({ payload }, { call, put, take, select }) {
            const { id } = payload
            const { userClassTablePagination } = yield select(state => state.adminUserDetail)
            const parmas = {
                userId: id,
                SkipCount: 0,
                MaxResultCount: 10000
            }
            const res = yield call(GetUserGrantCourse, parmas)
            if (res.response.ok) {
                let { courseGrants, courses } = res.data
                const newPagination = {
                    ...userClassTablePagination,
                    total: courseGrants.totalCount
                }

                yield put({
                    type: 'updateState',
                    payload: {
                        userGrantCourse: res.data,
                        userClassTablePagination: newPagination
                    }
                })

                yield put({
                    type: 'changeUserClassTable',
                    payload: {
                        pagination: newPagination
                    }
                })
            }
        },

        *openCourses({ payload }, { call, put, take, select }) {
            const emptyContent = {...content} 
            let courseGrantIds = []
            let contentCourse = []
            const { userGrantCourse } = yield select(state => state.adminUserDetail)
            const { courseGrants, courses } = userGrantCourse
            const courseGrantItems = courseGrants.items.filter(item => !item.isDeleted && item.status !== 'Expired')
            courseGrantIds = courseGrantItems.length > 0 ? courseGrantItems.map(item => item.course.id) : []
            contentCourse = courses.filter(item => !courseGrantIds.includes(item.id))
            emptyContent.Properties[0].Setting.DropdownOptions = contentCourse.map(item => {
                return {
                    Value: item.title,
                    Id: item.id
                }
            })
            yield put({
                type: 'updateState',
                payload: {
                    selectModalVisible: true,
                    content: emptyContent
                }
            })
        },

        *postCourseToUser({ payload }, { call, put, take, select }) {
            const { userInfo } = yield select(state => state.adminUserDetail)
            const { courseIds, startDate, endDate } = payload
            const { id } = userInfo || {}
            const params = {
                userId: id,
                courseIds: [courseIds],
                startTime: startDate ? getFormatDate(startDate, true) : null,
                endTime: endDate ? getFormatDate(endDate, false) : null
            }
            const res = yield call(PostCourseToUser, params)
            if (res.response.ok) {
                message.success('开通课程成功')
            } else {
                const { data: { error } } = res;
                if (error && error.message) {
                    message.warning(error.message);
                }
            }
            yield put({ type: 'getUserClass', payload: { id } })
            yield put({ type: 'updateState', payload: { selectModalVisible: false } })
        },

        *changeUserClassTable({ payload: data }, { call, put, take, select }) {
            const { userClassTablePagination: currentPagination, userGrantCourse } = yield select(state => state.adminUserDetail)
            const { pagination } = data
            const { courseGrants } = userGrantCourse || {}
            const { items } = courseGrants || {}
            const newUserClassTablePagination = { ...currentPagination, ...pagination, }
            const pageSize = newUserClassTablePagination.pageSize
            const start = (newUserClassTablePagination.current - 1) * pageSize
            const end = newUserClassTablePagination.current * pageSize
            const userCourseList = items.slice(start, end)
            yield put({
                type: 'updateState',
                payload: {
                    userClassTablePagination: newUserClassTablePagination,
                    userCourseList
                },
            })
        },

        *cancelCourseToUser({ payload }, { call, put, take, select }) {
            const { userInfo } = yield select(state => state.adminUserDetail)
            const { id } = userInfo || {}
            const parmas = {
                userId: id,
                courseIds: [payload.id]
            }
            const res = yield call(CancelCourseToUser, parmas)
            if (res.response.ok) {
                yield put({ type: 'getUserClass', payload: { id } })
                message.success('已停止授权')
            }
        },

        *showTestResult({ payload }, { call, put, take, select }) {
            const courseItem = payload
            const { course } = courseItem
            const { contents } = course
            if (contents && contents.length > 0) {
                const content = contents[0]
                yield put({ type: 'updateState', payload: { selectCourse: payload, drawerVisible: true, collapseActiveKey: content && content.id } })
                yield put({ type: 'getAppUserQuestionnarie', payload: content })
            }
        },

        *getAppUserQuestionnarie({ payload }, { call, put, take, select }) {
            const { id, questionnaires } = payload
            const { selectCourse, userInfo } = yield select(state => state.adminUserDetail)
            const { course } = selectCourse
            const { contents } = course || {}
            const params = {
                UserId: userInfo && userInfo.id,
                CourseId: course && course.id,
                CourseDetailId: id,
                QuestionnaireId: questionnaires && questionnaires[0]
            }
            let currentContent = contents.find(item => item.id === id)
            const res = yield call(GetAppUserQuestionnarie, params)
            if (res.response.ok) {
                if (currentContent) currentContent.myquestionnaire = res.data
            } else {
                if (currentContent) currentContent.myquestionnaire = null
            }
        }
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(({ pathname, query }) => {
        //         if (pathname === '/system/manage/userDetail') {
        //             dispatch({ type: 'initPage', payload: query })
        //         }
        //     })
        // },
    }
}