import { history } from 'umi';
import {
  TestRequestsPaged,
  GetCaseCode,
  PostCenterTestExcel,
  GetRequestInfoById
} from 'services/vrat/testCenter'
import { message, Modal } from 'antd';
import { GetLocationList } from 'services/location'
import { addOptionsToProperties, createSuperLabel } from 'cognitiveleap-core-us/utils/utils'
import { AddVratTest, UpdateTestRequest, regenerationReport, GetReport } from 'services/vrat'
import { encryptAES } from 'utils/utils';
import { GetPDFReport } from 'services/vrat/chart'
import { countDown } from 'utils/utils';
import { IntlManager } from 'utils/helper'
import copy from 'copy-to-clipboard'

import { CheckCircleOutlined } from '@ant-design/icons';
const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show] || '', Id: item.id.toString() }
  })
}

const orderToBackEnd = {
  ascend: '',
  descend: 'DESC',
}

let testListInterval;

export const gradeList = () => {
  const formatMessage = IntlManager.intl.formatMessage
  return [
    {
      key: 'Before School',
      value: formatMessage({ id: 'beforeSchool' }),
    },
    {
      key: '1',
      value: '1',
    },
    {
      key: '2',
      value: '2',
    },
    {
      key: '3',
      value: '3',
    },
    {
      key: '4',
      value: '4',
    },
    {
      key: '5',
      value: '5',
    },
    {
      key: '6',
      value: '6',
    },
    {
      key: '7',
      value: '7',
    },
    {
      key: '8',
      value: '8',
    },
    {
      key: '9',
      value: '9',
    },
    {
      key: '10',
      value: '10',
    },
    {
      key: '11',
      value: '11',
    },
    {
      key: '12',
      value: '12',
    },
    {
      key: 'Not Attending School',
      value: formatMessage({ id: 'other' }),
    },
  ]
}


const contentMenu = () => {
  const formatMessage = IntlManager.intl.formatMessage

  return {
    Properties: [
      // {
      //   EditorType: 'Dropdown',
      //   ShowTitle: formatMessage({ id: 'location' }),
      //   FormKey: 'testLocationId',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     DropdownOptions: [],
      //     Required: true,
      //   },
      //   Description: null,
      // },
      {
        EditorType: 'Dropdown',
        ShowTitle: formatMessage({ id: 'grade' }),
        FormKey: 'grade',
        AdditionalData: null,
        Value: null,
        Setting: {
          DropdownOptions: gradeList().map(item => {
            return { Value: item.value, Id: item.key }
          }),
          Required: true,
        },
        Description: null,
      },
    ],
  }
}

export default {
  namespace: 'testList',
  state: {
    currectRecord: null,
    requestList: [],
    pageIndex: 1,
    total: 0,
    pageSize: 12,
    searchField: null,
    StartTime: null,
    StartTimeGenerate: null,
    EndTime: null,
    EndTimeGenerate: null,
    Status: [],
    Gender: [],
    OrderBySorter: null,
    isLoading: false,
    content: null,
    modalVisible: false,
    btnLoading: false,
    getCodeLoading: false
  },

  reducers: {
    save(state, { payload: data }) {
      return { ...state, ...data }
    },

    changeSearch(state, { payload: searchField }) {
      return { ...state, searchField }
    },
  },

  effects: {
    // effects内实现setInterval：https://github.com/dvajs/dva/issues/252
    // 开启定时器
    *startInterval(_, { fork, cancelled, call, select, put }) {
      // 定义一个生成器函数，用于处理定时任务
      testListInterval = yield fork(function *() {
        // 停止条件：task取消
        while (!(yield cancelled())) {
          const delay = timeout => {
            return new Promise((resolve) => {
              setTimeout(resolve, timeout)
            });
          };
          yield call(delay, 15000);
          const { requestList } = yield select(state => state.testList);
          // 获取需要请求详情的id列表
          const FilterTestRequestIds = requestList
            .filter(item => item.Status !== 'ReportReady' && item.Status !== 'Failed')
            .map(item => item.Id);
          // 执行根据id列表获取详情接口
          if (!FilterTestRequestIds.length) {
            break;
          }
          const params = {
            pageIndex: 1,
            pageSize: 12,
          }
          const { data = {} } = yield call(TestRequestsPaged, params, { FilterTestRequestIds }) || {};
          const { data: newItemList = [] } = data;
          // 将状态没有发生改变的数据过滤掉
          const changedArray = newItemList.filter(newItem => {
            const oldItem = requestList.find(oldItem => oldItem.Id === newItem.Id);
            return oldItem.Status !== newItem.Status;
          });
          // 如果有数据状态发声变化，更新到表格数据中
          if (changedArray.length) {
            yield put({
              type: 'save',
              payload: {
                requestList: requestList.map(item => {
                  const newItem = changedArray.find(newItem => newItem.Id === item.Id)
                  return newItem || item;
                }),
              },
            });
          }
        }
      });
    },
    // 停止计时器
    *stopInterval(_, { cancel }) {
      if (testListInterval) {
        // 停止轮询task
        yield cancel(testListInterval);
      }
    },
    *fetch({ payload: queryData }, { call, put, take, select }) {
      yield put({
        type: 'save',
        payload: {
          isLoading: true,
        },
      })

      const {
        pageIndex,
        pageSize,
        searchField,
        StartTime,
        StartTimeGenerate,
        EndTime,
        EndTimeGenerate,
        Status,
        Gender,
        OrderBySorter,
      } = yield select(state => state.testList)
      let query = {
        pageIndex: queryData.pageIndex || pageIndex,
        pageSize: queryData.pageSize || pageSize,
      }
      let interval;
      const queryStartTime = queryData.StartTime !== undefined ? queryData.StartTime : StartTime;
      const queryEndTime = queryData.EndTime !== undefined ? queryData.EndTime : EndTime;
      const reportQueryStartTime = queryData.StartTimeGenerate !== undefined ? queryData.StartTimeGenerate : StartTimeGenerate
      const reportQueryEndTime = queryData.EndTimeGenerate !== undefined ? queryData.EndTimeGenerate : EndTimeGenerate
      if (queryData.searchField || searchField)
        query = { ...query, searchField: queryData.searchField || searchField }

      let bodyData = {
        Status: queryData.Status || Status,
        Gender: queryData.Gender || Gender,
      }

      bodyData = { ...bodyData, StartTime: queryStartTime || '', StartTimeGenerate: reportQueryStartTime || '' }

      bodyData = { ...bodyData, EndTime: queryEndTime || '', EndTimeGenerate: reportQueryEndTime || '' }

      let orderby = queryData.OrderBySorter || OrderBySorter
      if (orderby) {
        var orderByValue = orderby.PropertyName + ' ' + orderToBackEnd[orderby.IsDESC]
        bodyData = { ...bodyData, OrderBy: orderByValue }
      }

      const res = yield call(TestRequestsPaged, query, bodyData)
      const { data, response } = res
      if (response.ok) {
        yield put({
          type: 'save',
          payload: {
            requestList: data.data,
            total: data.TotalCount,
            isLoading: false,
            ...query,
            ...bodyData,
            OrderBySorter: orderby,
            StartTime: queryStartTime || null,
            EndTime: queryEndTime || null,
            StartTimeGenerate: reportQueryStartTime || null,
            EndTimeGenerate: reportQueryEndTime || null
          },
        });
        yield put({ type: 'stopInterval' });
        yield put({ type: 'startInterval' });
      } else {
        yield put({
          type: 'save',
          payload: {
            isLoading: false,
          },
        })
      }
    },
    viewReport2({ payload: data }, { put, call }) {
      const url = '/report?caseId=' + encodeURIComponent(encryptAES(data.Case.Id)) + '&suspension=true'
      const id = data.Case.Id
      createSuperLabel(url, id)
      // window.open(url, '_blank')
    },

    *getReport({ payload: data }, { put, call }) {
      const { record } = data
     const res =  yield call(GetReport, {
        caseId: record.Case.Id,
      })
      if (res.response.ok) {
        yield put({ type: 'fetch', payload: {} })
      } else {
        const { data: { Error } } = res;
        message.error(Error.Message);
      }
    },


    *medicalReportView({ payload: { record: data, institutionType} }, { call, put, select }) {
      const url = institutionType === 'Medical_Public' ? '/system/medicalPublicVratReport?caseId=': '/system/medicalVratReport?caseId='
      const id = data.Case.Id
      createSuperLabel(url + data.Case.Id, id)
      // window.open(url, '_blank')
    },

    *medicalChnReportView({ payload: data }, { call, put, select }) {
      const url = '/system/medicalChnVratReport?caseId=' + data.Case.Id
      var win = window.open(url, '_blank')
    },

    *ParentEditionReportView({ payload: { record, model } }, { call, put, select }) {
      let url = '/parentreport?caseId=' + record.Case.Id + '&reId=' + record.Case.Id + '&suspension=true'
      if (model === 'screen') {
        url += '&model=' + model
      }
      var win = window.open(url, '_blank')
    },

    *exportExcel({ payload: { currentUser } }, { put, call, select }) {
      const { id: SubjectId, changedTenant: { id: TestingCenterId } } = currentUser
      yield put({ type: 'save', payload: { btnLoading: true } })
      const {
        searchField,
        StartTime,
        EndTime,
        Status,
        OrderBySorter,
        StartTimeGenerate,
        EndTimeGenerate
      } = yield select(state => state.testList)

      yield call(PostCenterTestExcel, { searchField }, {
        StartTime,
        EndTime,
        StartTimeGenerate,
        EndTimeGenerate,
        Status,
        OrderBy: OrderBySorter,
        TestingCenterId,
        SubjectId,
      })

      yield put({ type: 'save', payload: { btnLoading: false } })
    },

    *downloadReport({ payload: { record, intl } }, { call, put, select }) {
      const hide = message.loading(intl.formatMessage({ id: 'downloading' }), 0);
      yield call(GetPDFReport, record.Case.Id, record.Case.TargetReportVersion)
      setTimeout(hide, 0)
    },

    *clearAllFilterAndSorter({ payload }, { call, put, select }) {
      const pageSize = yield select(state => state.testList.pageSize)
      const defaultState = {
        pageIndex: 1,
        searchField: null,
        StartTime: null,
        EndTime: null,
        StartTimeGenerate: null,
        EndTimeGenerate: null,
        Status: [],
        OrderBy: null,
        Gender: [],
        OrderBySorter: null,
      }
      yield put({ type: 'save', payload: { pageSize, ...defaultState } })
      yield put({ type: 'fetch', payload: {} })
    },

    *goToTestDetail({ payload: id }, { take, put, call, select }) {
      history.push({
        pathname: '/system/center/detail/vratTestDetail',
        query: {
          id,
        },
      })
    },

    *regenerate({ payload }, { take, put, call, select }) {
      const { Subject, TestLocation, Case } = payload
      const { Id } = Subject
      // const data = {
      //   testLocationId: TestLocation.Id,
      //   grade: Case.Grade
      // }
      const res = yield call(AddVratTest, Id, {})
      if (res.response.ok) {
        yield put({
          type: 'save',
          payload: {
            pageIndex: 1
          }
        })
        yield put({ type: 'fetch', payload: {} })
      } else {
        const { data: { Error } } = res;
        message.warning(Error.Message);
      }
    },

    *addTest({ payload: record }, { call, put, take, select }) {
      const query = { maxResultCount: 500, Enabled: true }
      const { Case, TestLocation } = record
      const res = yield call(GetLocationList, query)
      if (res.response.ok) {
        const { items } = res.data
        const locationOptions = listToOptions(items, 'name')
        let emptyContent = addOptionsToProperties(
          contentMenu(),
          ['testLocationId'],
          locationOptions
        )

        // emptyContent.Properties[0].Value = `${TestLocation.Id}`
        // emptyContent.Properties[1].Value = `${Case.Grade}`
        emptyContent.Properties[0].Value = `${Case.Grade}`

        yield put({
          type: 'save',
          payload: {
            content: emptyContent,
            modalVisible: true,
            currentRecord: record
          },
        })
      } else {
        const { data: { error } } = res;
        message.warning(error.message);
      }
    },

    *submitAddTest({ payload: { value, intl } }, { take, put, call, select }) {
      const { grade: Grade, testLocationId: TestLocationId } = value;
      const { currentRecord } = yield select(state => state.testList)
      const res = yield UpdateTestRequest({ id: currentRecord.Id }, {
        Grade,
        TestLocationId,
      })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({ type: 'fetch', payload: {} })
      }
      yield put({
        type: 'save', payload: {
          modalVisible: false,
        }
      })
    },
    // 重新生成报告
    *regenerationReport({ payload: data }, { put, call }) {
      const { record,intl } = data || {}
      const res =  yield call(regenerationReport,{CaseIds:[record.Case.Id]}
      )
      if (res.response.ok) {
        Modal.success({
          title: <b>{intl.formatMessage({ id: 'Successful retry' })}</b>,
          content: intl.formatMessage({ id: 'line up' })
        })
        yield put({ type: 'fetch', payload: {} })
      } else {
        const { data: { Error } } = res || {};
        message.error(Error.Message);
      }
    },

    *getCode({ payload }, { take, put, call, select }) {
      const { getCodeLoading } = yield select(state => state.testList)

      if (getCodeLoading) return

      yield put({ type: 'save', payload: { getCodeLoading: true } })

      const { record, type, intl } = payload
      const res = yield call(GetCaseCode, { testRequestId: record.Id })
      const { data, response } = res
      if (response.ok) {
        const { Code, Image } = data
        if (type === 'copy') {
          Modal.confirm({
            title: <div>
              {intl.formatMessage({ id: 'testCode' })}：{Code}
            </div>,
            okText: intl.formatMessage({ id: 'copy' }),
            icon: <CheckCircleOutlined style={{ color: '#52C41A' }} />,
            cancelText: intl.formatMessage({ id: 'close' }),
            content: null,
            onOk() {
              if (copy(Code)) message.success(intl.formatMessage({ id: 'copyed' }))
              else message.error(intl.formatMessage({ id: 'failure' }))
            },
          });
        } else {
          countDown(Image, Code, intl)
        }
      } else {
        const { data: { Error } } = res;
        message.warning(Error.Message);
      }

      yield put({ type: 'save', payload: { getCodeLoading: false } })
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/statistic/vratList') {
    //       dispatch({ type: 'fetch', payload: {} })
    //     }
    //   })
    // },
  },
}
