import { history } from 'umi';
import { GetById, GetAllInfoByAnswerId, UpdateOrNewAnswer } from 'services/vrat/questionnaire'

export default {
  namespace: 'questionnaire',
  state: {
    CaseId: null,
    questionnaireInfo: null,
    answer: null,
    answerId: null,
    editable: false,
    GuardianId: null,
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *fetch({ payload: query }, { call, put, select, take }) {
      const { ci, gi, ie, qi, ai } = query
      if (ai) {
        const { data, response } = yield call(GetAllInfoByAnswerId, ai)
        if (response.ok)
          yield put({
            type: 'save',
            payload: {
              answerId: ai,
              answer: data.AnswerContent,
              questionnaireInfo: data.Questionnaire,
              editable: ie === '1',
            },
          })
      } else {
        const { data, response } = yield call(GetById, qi)
        if (response.ok) {
          yield put({
            type: 'save',
            payload: {
              questionnaireInfo: data,
              CaseId: ci,
              GuardianId: gi ? gi : null,
              editable: ie === '1',
            },
          })
        }
      }
    },

    *submitAnswer({ payload: data }, { call, put, select }) {
      const { answer, answerContent } = data
      const { answerId, CaseId, GuardianId, questionnaireInfo } = yield select(
        state => state.questionnaire
      )
      const postValue = {
        AnswerId: answerId,
        QuestionnaireId: questionnaireInfo.Id,
        CaseId,
        GuardianId,
        AnswerContent: answerContent,
        QuestionnaireVersion: questionnaireInfo.Version
        // FinishedProgress: finishProgress,
      }
      const { response } = yield call(UpdateOrNewAnswer, postValue)
      if (response.ok) {
        history.goBack()
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/questionnaire') {
    //       dispatch({ type: 'fetch', payload: location.query })
    //     }
    //   })
    // },
  },
}
