import { GetSupervisedList, PostSendInviterEmail } from 'services/supervisor';
import { message } from 'antd';
import {
  GetSupervisorSSummary,
  GetTrainerSummaryList,
  PostSupervisorSummary,
  GetQuestionnaire,
  PutSupervisorSummary,
} from 'services/trainingSystem';
import { IntlManager } from 'utils/helper';

const contentMenu = () => {
  const formatMessage = IntlManager.intl.formatMessage;
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: formatMessage({ id: 'email' }),
        FormKey: 'email',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          Rules: [
            {
              pattern:
                /^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/,
              message: '请输入有效邮箱',
            },
          ],
        },
        Description: null,
      },
    ],
  };
};

export default {
  namespace: 'supervisorList',

  state: {
    supervisorData: null,
    selectItem: null,
    showSupervisionVisible: false,
    sendInviterEmailVisible: false,
    supervisionQuestionDetail: null,
    currentSupervisorItem: null,
    currentTrainerSummaryItem: null,
    questionnaireListData: null,
    supervisionTabActiveKey: 'Supervisor',
    supervisorQuestion: null,
    supervisorIsFinshed: false,
    supervisorSubmitData: null,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    content: null,
    filter: ''
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: {
    *fetch({ payload }, { call, put, take, select }) {
      const { pagination, filter } = yield select((state) => state.supervisorList);
      const { current, pageSize } = pagination;
      const params = {
        MaxResultCount: pageSize,
        SkipCount: (current - 1) * pageSize,
        Filter: payload || filter
      };
      const getSupervisorRes = yield call(GetSupervisedList, params);
      if (getSupervisorRes.response.ok) {
        const { items, totalCount } = getSupervisorRes.data || {};
        yield put({
          type: 'updateState',
          payload: {
            supervisorData: getSupervisorRes.data,
            pagination: {
              current,
              pageSize,
              total: totalCount,
            },
          },
        });
      }
    },

    *changeTable({ payload }, { call, put, take, select }) {
      const { pagination } = yield select((state) => state.supervisorList);
      const { pagination: newPagination } = payload;
      const { current } = newPagination || {};
      yield put({
        type: 'updateState',
        payload: { pagination: { ...pagination, current } },
      });
      yield put({ type: 'fetch' });
    },

    *onSendInviterEmail({ payload }, { call, put, take, select }) {
      yield put({
        type: 'updateState',
        payload: {
          sendInviterEmailVisible: true,
          content: { ...contentMenu() },
        },
      });
    },

    *submitSendEmail({ payload }, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()

      const res = yield call(PostSendInviterEmail, payload);

      if (res.response.ok) {
        message.success(intl('success'));
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      yield put({
        type: 'updateState',
        payload: {
          sendInviterEmailVisible: false,
        },
      });
    },

    // 根据当前Tab Type获取不同的数据
    *getDataByDiffType({ payload }, { call, put, select }) {
      const { supervisionTabActiveKey } = yield select(
        (state) => state.supervisorList,
      );
      if (supervisionTabActiveKey === 'TrainerSummary') {
        yield put({ type: 'getTrainerSummary' });
      } else if (supervisionTabActiveKey === 'Supervisor') {
        yield put({ type: 'getSupervisor' });
      }
    },

    // 获取问卷内容
    *getQuestionDetail({ payload }, { call, put, select }) {
      const { supervisionTabActiveKey } = yield select(
        (state) => state.supervisorList,
      );
      const questionDetailRes = yield call(GetQuestionnaire, {
        id: payload.id,
      });
      if (questionDetailRes.response.ok) {
        const currentIdName =
          supervisionTabActiveKey === 'TrainerSummary'
            ? 'currentTrainerSummaryItem'
            : 'currentSupervisorItem';
        yield put({
          type: 'updateState',
          payload: {
            supervisionQuestionDetail: questionDetailRes.data,
            [currentIdName]: payload,
          },
        });
      }
    },

    // 获取训练师小结
    *getTrainerSummary({ payload }, { call, put, select }) {
      const { selectItem, currentTrainerSummaryItem } = yield select(
        (state) => state.supervisorList,
      );
      const { id } = selectItem || {};
      const trainerSummaryListRes = yield call(GetTrainerSummaryList, {
        UserId: id,
      });
      if (trainerSummaryListRes.response.ok) {
        const data = trainerSummaryListRes.data;
        const { items } = data || {};
        const { items: subItems } = items || {};
        const item = currentTrainerSummaryItem
          ? currentTrainerSummaryItem
          : subItems && subItems.length > 0
          ? subItems[0]
          : null;
        yield put({
          type: 'updateState',
          payload: {
            questionnaireListData: data,
          },
        });
        if (item !== null) {
          yield put({
            type: 'getQuestionDetail',
            payload: item,
          });
        }
      }
    },

    // 获取督导师跟踪表
    *getSupervisor({ payload }, { call, put, select }) {
      const { selectItem, currentSupervisorItem } = yield select(
        (state) => state.supervisorList,
      );
      const { id } = selectItem || {};
      const supervisorListRes = yield call(GetSupervisorSSummary, {
        UserId: id,
      });
      if (supervisorListRes.response.ok) {
        const data = supervisorListRes.data;
        const { items } = data || {};
        const { items: subItems } = items || {};
        const item = currentSupervisorItem
          ? currentSupervisorItem
          : subItems && subItems.length > 0
          ? subItems[0]
          : null;
        yield put({
          type: 'updateState',
          payload: {
            questionnaireListData: data,
          },
        });
        if (item !== null) {
          yield put({
            type: 'getQuestionDetail',
            payload: item,
          });
        }
      }
    },

    // 切换见习督导记录Tab
    *onChangSupervisionTabs({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          supervisionTabActiveKey: payload,
          questionnaireListData: null,
          supervisionQuestionDetail: null,
        },
      });
      yield put({ type: 'getDataByDiffType' });
    },

    // 关闭编辑见习督导跟踪表
    *onCloseEidtSupervision({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: { eidtSupervisionVisible: false, supervisorQuestion: null },
      });
    },

    *showSupervision({ payload }, { call, put, take, select }) {
      const { record } = payload;
      yield put({
        type: 'updateState',
        payload: {
          selectItem: record,
          showSupervisionVisible: true,
          supervisionQuestionDetail: null,
          currentSupervisorItem: null,
          currentTrainerSummaryItem: null,
        },
      });
      yield put({ type: 'getDataByDiffType' });
    },

    // 展示见习督导跟踪表
    *addSupervision({ payload }, { call, put, select }) {
      const { selectItem } = yield select((state) => state.supervisorList);
      const { id } = selectItem || {};
      const supervisionRes = yield call(PostSupervisorSummary, { userId: id });
      if (supervisionRes.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            supervisorQuestion: supervisionRes.data,
            eidtSupervisionVisible: true,
          },
        });
      }
    },

    *editSupervision({ payload }, { call, put, select }) {
      const { selectItem, currentSupervisorItem } = yield select(
        (state) => state.supervisorList,
      );
      const questionDetailRes = yield call(GetQuestionnaire, {
        id: currentSupervisorItem.id,
      });
      //const supervisionRes = yield call(PostSupervisorSummary, { userId: id })
      if (questionDetailRes.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            supervisorQuestion: questionDetailRes.data,
            eidtSupervisionVisible: true,
          },
        });
      }
    },

    // 提交见习督导跟踪表
    *supervisorSubmit({ payload }, { call, put, select }) {
      const { supervisorQuestion } = yield select(
        (state) => state.supervisorList,
      );
      const { id } = supervisorQuestion || {};
      const { answerContent, isComplete, finishedPage, intl } = payload;
      const params = {
        updateQuestionnaireDto: {
          userQuestionnaireId: id,
          answerContent,
          isComplete,
          finishedPage,
        },
      };
      const res = yield call(PutSupervisorSummary, params);
      if (res.response.ok) {
        if (isComplete) {
          message.success(intl.formatMessage({ id: 'submitted' }));
          yield put({
            type: 'updateState',
            payload: {
              supervisorSubmitData: null,
              eidtSupervisionVisible: false,
              currentSupervisorItem: null,
            },
          });
          yield put({ type: 'getDataByDiffType' });
        }
      }
    },

    // 切换menuList
    *clickMenuItem({ payload }, { call, put, select }) {
      yield put({
        type: 'getQuestionDetail',
        payload,
      });
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //     return history.listen(location => {
    //         if (location.pathname === '/system/center/menu/manage/trainerSupervised') {
    //             dispatch({ type: 'fetch' })
    //         }
    //     })
    // },
  },
};
