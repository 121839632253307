import React from 'react';
import { useIntl, getLocale } from 'umi';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import RoseChart from 'components/NewVratChartComponents/RoseChart';
import BottomPageNum from './components/BottomPageNum';

const bgImgList = {
  'zh-CN': styles.vrClassroomImgCN,
  'en-US': styles.vrClassroomImg,
  'es-ES': styles.vrClassroomImgES
}

const DirectionTracking = ({ data }) => {
  const intl = useIntl();

  const showData = data ? data.Time : [];
  const RoseChartProps = {
    data: showData,
  };

  const minutes = data
    ? `${((data.AttentionTimeOnWhiteboard / 13) * 100).toFixed(2)}%`
    : '';
  const percent = data ? `${(data.Percentile * 100).toFixed(2)}%` : '0%';

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.directionTracking}>
        <HeaderLine color="#3DBD7D" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'DirectionTracking' })}</div>
          <div className={styles.titleDes}>
            <div>
              {intl.formatMessage({ id: 'DirectionTracking.TitleDes' })}
            </div>
            <div className={styles.twoDes}>
              {intl.formatMessage({ id: 'DirectionTracking.FirstDes' })}{' '}
              <span className={styles.blueLines}>{intl.formatMessage({ id: 'DirectionTracking.BlueLine' })}</span>
              {intl.formatMessage({ id: 'DirectionTracking.TwoDes' })}
              <div>
                {intl.formatMessage({ id: 'DirectionTracking.ThreeDes' })}{' '}
                <span className={styles.blueLines}>{intl.formatMessage({ id: 'DirectionTracking.BlueLine' })}</span>
                {intl.formatMessage({ id: 'DirectionTracking.FourDes' })}
              </div>
            </div>
          </div>
          <div className={bgImgList[getLocale()]}>
            <RoseChart {...RoseChartProps} />
          </div>
          <div className={styles.whereLook}>{intl.formatMessage({ id: 'DirectionTracking.WhereLooking' })}</div>
          <div className={styles.directionBottomContent}>
            {intl.formatMessage({ id: 'DirectionTracking.BottomDes' })}
            <div className={styles.bottomResult}>
              {intl.formatMessage({ id: 'DirectionTracking.Bottom.FirstResult' }, { minutes })}
            </div>
            <div>
              {intl.formatMessage({ id: 'DirectionTracking.Bottom.TwoResult' }, { percent })}
            </div>
          </div>
        </div>
        <BottomPageNum num={15} />
        <BottomDes color="#3DBD7D" />
      </div>
    </div>
  );
};

export default DirectionTracking;
