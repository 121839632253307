import React from 'react'
import styles from './Report.less'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const totalList = [
    {
        title: '专注力',
        color: '#c5e6f9'
    },
    {
        title: '行为控制力',
        color: '#f9e5e4'
    },
    {
        title: '情绪管理',
        color: '#fff7e1'
    },
    {
        title: '反应力',
        color: '#d7efe1'
    },
    {
        title: '动作行为表现',
        color: '#d7ddef'
    }
]

const TotalRes = ({ overView, sixDimension }) => {

    const roundList = [];
    const { AttentionPerformanceIndex = 0 } = overView || {};
    const {
        Attention = 0,
        SelfControl = 0,
        Reactivity = 0,
        ReactionStability = 0,
        Sensitivity = 0,
        ContinuousAttention = 0
    } = sixDimension || {}

    const sixData = [
        { value: Attention, tag: 'Attention' }, // 专注力圈
        { value: ContinuousAttention, tag: 'Attention' }, // 专注力圈
        { value: Sensitivity, tag: 'Attention' }, // 专注力圈
        { value: SelfControl, tag: 'SelfControl' }, // 行为自控圈 情绪管理圈
        { value: ReactionStability, tag: 'ReactionStability' }, // 情绪管理圈
        { value: Reactivity, tag: 'Reactivity' } // 反应力圈
    ];

    sixData.forEach(item => {
        const { value, tag } = item;
        if (value < 0.5) {
            switch (tag) {
                case 'Attention':
                    if (!roundList.some(item => item.title === '专注力')) {
                        roundList.push(totalList[0]);
                    }
                    break;
                case 'SelfControl':
                    if (!roundList.some(item => item.title === '行为控制力')) {
                        roundList.push(totalList[1]);
                    }
                    if (!roundList.some(item => item.title === '情绪管理')) {
                        roundList.push(totalList[2]);
                    }
                    break;
                case 'ReactionStability':
                    if (!roundList.some(item => item.title === '情绪管理')) {
                        roundList.push(totalList[2]);
                    }
                    break;
                case 'Reactivity':
                    if (!roundList.some(item => item.title === '动作行为表现')) {
                        roundList.push(totalList[3]);
                    }
                    break;
                default:
                    break;
            }
        }
    })

    // 没有小于0.5的情况
    if (roundList.length === 0) {
        const res = sixData.filter(item => item.value >= 0.5 && item.value < 0.8).sort((a, b) => a.value - b.value);
        if (res && res.length > 0) {
            // 显示最小的
            const { tag } = res[0]
            switch (tag) {
                case 'Attention':
                    roundList.push(totalList[0]);
                    break;
                case 'SelfControl':
                    roundList.push(totalList[1], totalList[2]);
                    break;
                case 'ReactionStability':
                    roundList.push(totalList[2]);
                    break;
                case 'Reactivity':
                    roundList.push(totalList[3]);
                    break;
                default:
                    break;
            }
        }
    }

    if (AttentionPerformanceIndex <= 0.66) {
        roundList.push(totalList[4])
    }

    return (
        <div className={styles.page_div}>
            <h2 className={styles.page_title}>
                <p className={styles.eTitle}>总结</p>
            </h2>
            <div className={styles.totalResContent}>
                <div>根据测试结果，{roundList.length === 0 ? '我们初步认为孩子的注意力和动作行为表现均表现良好。' : '我们初步认为孩子在以下方面可以通过训练得到能力的提升：'}</div>
                <p />
                {roundList.length > 0 ? <div className={styles.roundList}>
                    {
                        roundList.map((item, index) => {
                            const { title, color } = item
                            return <div className={styles.roundItem} key={index} style={{ backgroundColor: color }}>
                                {title}
                            </div>
                        })
                    }
                </div> : <p />}
                <div>HFS专注力训练系统基于哈佛大学专注力权威专家哈洛韦尔博士的“儿童发展优势理论”。六大维度的优脑训练，遵循大脑神经可塑性原理，利用骨传导技术和可询证科学原理的专业处理优脑音乐，结合多模态感官强化激发的有氧技巧运动，自下而上、循序渐进地提升孩子大脑的各项执行功能，增强孩子的专注力、学习力和情绪管理能力。专注力训练系统同时辅以家长养育课程，助力家长不断挖掘孩子的优势潜能，让其绽放独有天赋，快乐自信地成长。</div>
                <div className={styles.tipDes}>本报告不作为任何和医学诊断治疗相关的参考用途</div>
            </div>
            <PoweredBy styles={styles} />
            <p className={styles.page_number}>11</p>
        </div >
    )
}

export default TotalRes