import { Divider, Badge } from 'antd'
import ReportRichText from 'components/ReportRichText/ReportRichText'
import SpinComponent from 'components/Spin'
import { useIntl } from 'umi'
import styles from '../detail.less'

const TemplateBasicInfo = ({ templateInfo }) => {
  const intl = useIntl()

  return (
    <div>
      {templateInfo == null ? (
        <div className={styles.place}>
          <SpinComponent />
        </div>
      ) : (
        <div>
          <div className={styles.content}>
            {/* 编号 */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'numberOrder' })} : </span>
              <span>{templateInfo.code}</span>
            </div>

            {/* 训练目标 */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'trainingGoals' })} : </span>
              {templateInfo.targets.map((item, index) => {
                return <span key={index}>{item.displayName} </span>
              })}
            </div>

            {/* 适合年龄 */}
            {/* <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>适合年龄 : </span>
              {templateInfo.agePeriod !== null ? (
                <span>
                  {`${templateInfo.agePeriod.start}-${templateInfo.agePeriod.end} ${useIntl().formatMessage({
                    id: 'y',
                  })}`}{' '}
                </span>
              ) : (
                ''
              )}
            </div> */}

            {/* 课程次数 */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'numberOfSessions' })} : </span>
              <span>{templateInfo.totalCourseNum} </span>
            </div>

            {/* 反馈（训练项目） */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'feedbackTrainingPlan' })} : </span>
            </div>
            <div className={styles.liItem}>
              {templateInfo.feedbackProperties.map((item, index) => {
                return (
                  <div key={index}>
                    <Badge status="default" color={'rgba(0, 0, 0, 0.65)'} text={item.displayName} />
                  </div>
                )
              })}
            </div>

            {/* 反馈（次课） */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'feedback_session' })} : </span>
            </div>
            <div className={styles.liItem}>
              {templateInfo.courseFeedbackProperties.map((item, index) => {
                return (
                  <div key={index}>
                    <Badge status="default" color={'rgba(0, 0, 0, 0.65)'} text={item.displayName} />
                  </div>
                )
              })}
            </div>

            {/* 币种 */}
            {/* <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>币种 : </span>
              <span>{templateInfo.currencyType}</span>
            </div> */}

            {/* 价格 */}
            {/* <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>价格 : </span>
              <span>{`$${templateInfo.price}`}</span>
            </div> */}

            {/* 文件 */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'file' })} : </span>
            </div>
            <div style={{ paddingLeft: '20px' }}>
              {templateInfo.files != null
                ? templateInfo.files.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item.url}
                        target="_Blank"
                        rel="noopener noreferrer"
                        className={styles.betweenItem}
                      >
                        {item.fileName}
                      </a>
                    )
                  })
                : null}
            </div>
          </div>
          <div className={styles.contentBottom}>
            {/* 训练介绍 */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'trainingIntroduction' })} : </span>
            </div>
            <div className={styles.richText}>
              <ReportRichText content={templateInfo.introduction} />
            </div>

            {/* 训练须知 */}
            <div className={styles.betweenItem}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'TrainingInstructions' })} : </span>
            </div>
            <div className={styles.richText}>
              <ReportRichText content={templateInfo.notice} />
            </div>
          </div>

          {/* 封面图 不需要 */}
          {/* <Descriptions title="" column={1}>
            <Descriptions.Item label="封面图">
              {
                <a
                  href={templateInfo.image != null ? templateInfo.image.url : null}
                  target="_Blank"
                >
                  <img
                    style={{ width: 100, height: 100, borderRadius: 10 }}
                    src={templateInfo.image != null ? templateInfo.image.url : null}
                  />
                </a>
              }
            </Descriptions.Item>
          </Descriptions> */}
        </div>
      )}
    </div>
  )
}

export default TemplateBasicInfo
