import React from 'react';
import styles from '../index.less';

const HeaderContent = ({ color, title, content }) => {
  return (
    <div className={styles.headerContent} style={{ backgroundColor: color }}>
      <div className={styles.headerTitle}>{title}</div>
      {content}
    </div>
  );
};

export default HeaderContent;
