import React, { useState, useEffect } from 'react';
import { message, Spin, Dropdown, Button, Space, Menu } from 'antd';
import moment from 'moment';
import { useIntl } from 'umi';
import { getActiveWeekdays } from 'utils/utils';
import { DownOutlined } from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

import { GetPdtLearningAnalysis } from 'services/pdt';

const listSupport = (intl) => {
  return {
    order: {
      showText: intl.formatMessage({ id: 'OrderPdt' }),
      showType: 'Text',
    },
    sessionName: {
      showText: intl.formatMessage({ id: 'SessionName' }),
      showType: 'Text',
    },
    type: {
      showText: intl.formatMessage({ id: 'type' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({ id: text.toLowerCase() });
      },
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({ id: text.toLowerCase() });
      },
    },
    pushedAt: {
      showText: intl.formatMessage({ id: 'AssignedDate' }),
      showType: 'Time',
    },
    completedTime: {
      showText: intl.formatMessage({ id: 'CompletionTime' }),
      showType: 'Time',
      render: (text, record) => {
        const { status } = record || {};
        return status === 'COMPLETED'
          ? moment(text).local().format('YYYY-MM-DD')
          : '-';
      },
    },
  };
};

const ParentLearningWeekly = ({ subjectId }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [isRender, setIsRender] = useState(true);
  const [listData, setListData] = useState({});
  const [week, setWeek] = useState(null);

  const initData = async () => {
    setLoading(true);
    const params = {
      subjectId,
      week,
    };
    const res = await GetPdtLearningAnalysis(params);
    if (res.response.ok) {
      res.data.data && setListData(res.data.data);
    } else {
      const { msg } = res.data;
      message.error(msg);
    }
    setIsRender(false);
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [week]);

  if (Object.keys(listData).length === 0 && isRender) {
    return <Spin />;
  }

  const {
    subjectSubsectionDtoList = [],
    weekEnd,
    weekStart,
    weekAssigned,
  } = listData;

  const handleMenuClick = (e) => {
    const { key } = e;
    setWeek(key);
  };

  const menu = (
    <Menu onClick={handleMenuClick} items={getActiveWeekdays(weekAssigned)} />
  );

  const tableProps = {
    listData: subjectSubsectionDtoList.map((item) => {
      const { subsectionDto } = item;
      const { week, day, name, type } = subsectionDto || {};
      const order = intl.formatMessage({ id: 'whickWeekOrDay' }, { week, day });
      return {
        ...item,
        sessionName: name,
        type,
        order,
      };
    }),
    loading,
    listSupport: listSupport(intl),
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {Object.keys(listData).length === 0 ? (
        <div>{intl.formatMessage({ id: 'emptyData' })}</div>
      ) : (
        <>
          <div>
            <span>Week:&nbsp;&nbsp;</span>
            <Space>
              <Dropdown overlay={menu}>
                <Button>
                  <Space>
                    {week || weekAssigned}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
              {moment(weekStart).format('MM/DD/YYYY')} -
              {moment(weekEnd).format('MM/DD/YYYY')}
            </Space>
          </div>
          <p />
          <MagicTable {...tableProps} />
        </>
      )}
    </div>
  );
};

export default ParentLearningWeekly;
