import React, { useState } from 'react'
import { connect } from 'umi'
import { Layout } from 'antd'
import { CopyrightOutlined } from '@ant-design/icons';
import Menu from 'cognitiveleap-core-us/components/SiderMenu'
import { getMenuList } from './utils'
import Footer from 'cognitiveleap-core-us/components/Footer'
import { useIntl } from 'umi'
import moment from "moment"
import styles from './Layout.less'
const { Content, Sider } = Layout

const SystemLayout = ({ location, dispatch, currentUser, children }) => {
  const intl = useIntl();

  const [collapsed, setCollapsed] = useState(document.body.clientWidth < 576);
  const { auth, current, changedTenant, adminRoles } = currentUser
  const { grantedPolicies } = auth
  const FooterProps = {
    links: [
      {
        key: 'HomePage',
        title: intl.formatMessage({ id: 'home' }),
        href: '/',
        blankTarget: false,
      },
      {
        key: 'Company',
        title: intl.formatMessage({ id: 'company' }),
        href: 'https://www.cognitiveleap.com/',
        blankTarget: true,
      },
    ],

    copyright: (
      <React.Fragment>
        Copyright <CopyrightOutlined /> 2017-{moment().format("YYYY")} Cognitive Leap Solutions Inc.
      </React.Fragment>
    ),
  }

  // Footer是自定义的，不是Ant的Footer
  return (
    <Layout>
      <Menu menuData={getMenuList(changedTenant, grantedPolicies, adminRoles)} location={location} changeCollapsed={(value) => setCollapsed(value)} />
      <Layout>
        <Content style={{
          background: "#fff",
          padding: 24,
          marginLeft: collapsed ? '60px' : '200px',
          transition: 'all 0.1s',
          minHeight: "90vh"
        }}>{children}</Content>
        <span className={styles.layoutFooter} >
          <Footer {...FooterProps} />
        </span>
      </Layout>
    </Layout>
  )
}

export default connect(({ user }) => ({ currentUser: user.currentUser }))(SystemLayout)
