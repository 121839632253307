import getRequest from 'utils/request'


const GetOrPostOrPut = '/api/rocketAdmin/appInformation'
const PostForEdit = '/api/rocketAdmin/appInformation/prepareForEdit'

export async function GetApps(data) {
  return getRequest()(GetOrPostOrPut, {
    method: 'GET',
    params: data,
  })
}

export async function AddApp(data) {
  return getRequest()(GetOrPostOrPut, {
    method: 'POST',
    data,
  })
}

export async function UpdateApp(data) {
  return getRequest()(GetOrPostOrPut, {
    method: 'PUT',
    data,
  })
}

export async function PrepareForEdit(data) {
  return getRequest()(PostForEdit, {
    method: 'POST',
    data,
  })
}
