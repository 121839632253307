import React from 'react'
import moment from 'moment'
import { getDvaApp, getLocale } from 'umi'
import { matchDynamicForm, downloadLongRangeImg, isEffectualPhoneNumber, eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import config from 'utils/config'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import {
  PutUserInfo,
  PostCheckPassword,
  PostSendCode,
  PutUserEmail,
  PostChangePassword,
  PostLogoutUserApplication,
  GetProfile,
  ChangePasswordUs,
  PostSendPhoneCode,
  ChangePhoneNumberUs,
  PostSendEmail,
  PutChangeEmailByCode,
  PostCheckEmailPassword,
  PutProfile
} from 'services/account'
import {
  PostUploadUserResumes,
  GetTrainerDetail,
  GetTrainerTrainingFeedback,
  PostTrainerTrainingFeedback,
  PutQuestionnaire,
  PostSendCheckNumberCode,
  PostQuestionList
} from 'services/trainingSystem'
import { getMobilePrefix } from 'utils/utils'
import { message } from 'antd'
const { baseURL, uploadUserPicUrl, uploadUserResumes } = config
const uploadUrl = uploadUserPicUrl
const emailReg = /^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/

const accountPictureUrl = 'https://rocketsystem.oss-cn-shanghai.aliyuncs.com/User/Picture/';
const isZh = getLocale() === 'zh-CN';

const editInfoContent = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'name' }),
        FormKey: 'name',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
        },
        Description: null,
      },
      // {
      //   EditorType: 'Dropdown',
      //   Value: 'Number',
      //   Setting: {
      //     Required: true,
      //     DropdownOptions: mobilePrefix.map(item => {
      //       const { prefix, countryName } = item
      //       return {
      //         Value: countryName,
      //         Id: prefix
      //       }
      //     }),
      //     onchange(value) {
      //       getDvaApp()._store.dispatch({
      //         type: 'settings/onchangeInfo',
      //         payload: {
      //           type: 'countryCode',
      //           value
      //         }
      //       })
      //     }
      //   },
      //   ShowTitle: '电话区号',
      //   FormKey: 'countryCode',
      //   Description: null,
      // },
      // {
      //   EditorType: 'SendCodeInput',
      //   ShowTitle: '手机号',
      //   FormKey: 'phoneNumber',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     Required: true,
      //     canSendCode: true,
      //     btnDisabled: true,
      //     onSendCode() {
      //       getDvaApp()._store.dispatch({ type: 'settings/onSendCodeIntel' })
      //     },
      //     onComplete() {
      //       editInfoContent.Properties[2].Setting.canSendCode = true
      //       getDvaApp()._store.dispatch({
      //         type: 'settings/updateState',
      //         payload: {
      //           content: editInfoContent
      //         }
      //       })
      //     },
      //     onChange(e) {
      //       getDvaApp()._store.dispatch({
      //         type: 'settings/onchangeInfo',
      //         payload: {
      //           type: 'phoneNumber',
      //           value: e.target.value
      //         }
      //       })
      //     }
      //   },
      //   Description: null,
      // },
      // {
      //   EditorType: 'Input',
      //   ShowTitle: '验证码',
      //   FormKey: 'code',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     Required: false,
      //   },
      //   Description: null,
      // },
      {
        EditorType: 'Dropdown',
        Value: 'Number',
        Setting: {
          Required: false,
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'male' }),
              Id: 'Male',
            },
            {
              Value: intl.formatMessage({ id: 'female' }),
              Id: 'Female',
            },
            // {
            //   Value: intl.formatMessage({ id: 'other' }),
            //   Id: 'Others',
            // }
          ],
        },
        ShowTitle: intl.formatMessage({ id: 'gender' }),
        FormKey: 'gender',
        Description: null,
      },
      {
        EditorType: 'Upload',
        Value: null,
        Setting: {
          UploadSetting: {
            isSingle: true,
            uploadType: 'image',
            cropIMG: true
          },
          Action: baseURL + uploadUrl,
          Header: getAuthHeader(),
        },
        ShowTitle: intl.formatMessage({ id: 'profilePhoto' }),
        FormKey: 'picture',
      },
      // {
      //   EditorType: 'Upload',
      //   Value: null,
      //   Setting: {
      //     UploadSetting: {
      //       // isSingle: true,
      //       uploadType: 'pdf'
      //     },
      //     Action: baseURL + uploadUserResumes,
      //     Header: getAuthHeader(),
      //     Required: false,
      //   },
      //   ShowTitle: '个人简历',
      //   FormKey: 'resumes'
      // },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'birthday' }),
        FormKey: 'birthday',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
          DateType: 'date',
          DisabledDate: (current) => {
            return current && current > moment()
          }
        },
      }
    ]
  }
}

const validPasswordContent = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'email' }),
        FormKey: 'email',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
          Disabled: true
        },
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'password' }),
        FormKey: 'password',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          isPassword: true
        },
        Description: null,
      }
    ]
  }
}

const changeEmailContent = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: "newEmail" }),
        FormKey: 'newEmail',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          onChange(e) {
            getDvaApp()._store.dispatch({
              type: 'settings/updateState',
              payload: {
                newEmail: e.target.value
              }
            })
          }
        },
        Description: null,
      },
      {
        EditorType: 'SendCodeInput',
        ShowTitle: intl.formatMessage({ id: "smsCode" }),
        FormKey: 'code',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          canSendCode: true,
          onSendCode() {
            getDvaApp()._store.dispatch({
              type: 'settings/onSendCode',
              payload: { intl }
            })
          },
          onComplete() {
            const content = changeEmailContent(intl)
            content.Properties[1].Setting.canSendCode = true
            getDvaApp()._store.dispatch({
              type: 'settings/updateState',
              payload: {
                content
              }
            })
          }
        },
        Description: null,
      }
    ]
  }
}

const changePasswordContent = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'currentPassword' }),
        FormKey: 'oldPassword',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
          isPassword: true
        },
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'newPassword' }),
        FormKey: 'newPassword',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
          isPassword: true,
          Rules: [{
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,12}$/,
            message: intl.formatMessage({ id: 'passwordRule' })
          }]

        },
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'confirmPassword' }),
        FormKey: 'confirmPassword',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
          isPassword: true
        },
        Description: null,
      }
    ]
  }
}

const editResumesContent = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Upload',
        Value: null,
        Setting: {
          UploadSetting: {
            // isSingle: true,
            uploadType: 'pdf'
          },
          Action: baseURL + uploadUserResumes,
          Header: getAuthHeader(),
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'cv' }),
        FormKey: 'resumes',
        Description: intl.formatMessage({ id: 'availablePDFFormat' }),
      }
    ]
  }
}

const editTelphoneContent = (intl) => {
  const mobilePrefix = getMobilePrefix(intl)
  return {
    Properties: [
      {
        EditorType: 'Dropdown',
        Value: isZh ? '+86' : '+1',
        Setting: {
          Required: true,
          DropdownOptions: mobilePrefix.map(item => {
            const { prefix, countryName } = item
            return {
              Value: countryName,
              Id: prefix
            }
          }),
          onchange(value) {
            getDvaApp()._store.dispatch({
              type: 'settings/onchangeInfo',
              payload: {
                type: 'countryCode',
                value,
                intl,
              }
            })
          }
        },
        ShowTitle: intl.formatMessage({ id: 'countryCode' }),
        FormKey: 'countryCode',
        Description: null,
      },
      {
        EditorType: 'SendCodeInput',
        ShowTitle: intl.formatMessage({ id: 'phone' }),
        FormKey: 'phoneNumber',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          canSendCode: true,
          btnDisabled: true,
          onSendCode() {
            getDvaApp()._store.dispatch({ type: 'settings/onSendCodeIntel', payload: { intl } })
          },
          onComplete() {
            const content = editTelphoneContent(intl)
            content.Properties[1].Setting.canSendCode = true
            content.Properties[1].Setting.btnDisabled = false
            getDvaApp()._store.dispatch({
              type: 'settings/updateState',
              payload: {
                content
              }
            })
          },
          onChange(e) {
            getDvaApp()._store.dispatch({
              type: 'settings/onchangeInfo',
              payload: {
                type: 'phoneNumber',
                value: e.target.value,
                intl,
              }
            })
          },
          customValidator: (_, value) => {
            const { newCountryCode, userInfo } =
            getDvaApp()._store.getState().settings;
            const { currentUser } = getDvaApp()._store.getState().user;
            const { staff } = currentUser || {};
            const { phoneNumber } = userInfo || staff || {};
            if (
              value !== phoneNumber &&
              isEffectualPhoneNumber(newCountryCode, value)
            ) {
              return Promise.resolve();
            } else if (value === phoneNumber) {
              // 手机号与历史相同错误提示
              return Promise.reject(
                new Error(intl.formatMessage({ id: 'telMessage' }))
              );
            } else {
              // 手机号错误格式错误提示
              return Promise.reject(
                new Error(intl.formatMessage({ id: 'telMessage' }))
              );
            }
          },
        },
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'smsCode' }),
        FormKey: 'code',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: null,
      },
    ]
  }
}

export default {
  namespace: 'settings',
  state: {
    modalVisible: false,
    content: null,
    modalType: '',
    newEmail: '',
    userInfo: null,
    tabActiveKey: '',
    trainerDetail: null,
    drawerVisible: false,
    tabListData: null,
    isAdmin: false, // 是否为机构端，机构端和center端个人设置页不同，api也不同
    questionData: null,
    submitData: null,
    currentAssmessmentIsFinshed: false,
    selectItem: null,
    needDownload: false,
    newTel: '',
    newCountryCode: '',
    changeEmailIsSendCode: false,
    logout: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user)
      const { adminRoles = [], current } = currentUser || {}
      if (adminRoles.length > 0 && current === null) {
        // 为管理端
        yield put({
          type: 'updateState',
          payload: {
            isAdmin: true
          }
        })
        yield put({ type: 'getProfilesDetail' })
      } else {
        yield put({ type: 'getTrainerDetail' })
      }
    },

    *getProfilesDetail({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user)
      const { staff, id } = currentUser || {}
      const res = yield call(GetProfile, { userId: id || staff && staff.id })
      if (res.response.ok) {
        const { picture } = res.data
        const fileName = picture && picture.split('_')[1]
        const extension = fileName && '.' + fileName.split('.')[1]
        const userInfo = {
          ...res.data,
          picture: picture ? {
            id: picture,
            fileName,
            extension,
            url: `${accountPictureUrl}${res.data.picture}`
          } : null,
          phoneNumber: res.data.phone
        }
        yield put({
          type: 'updateState', payload: {
            userInfo
          }
        })
      }
    },

    *getTrainerDetail({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user)
      const { staff, id } = currentUser || {}
      const res = yield call(GetTrainerDetail, { TrainerId: id || staff && staff.id })
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { trainerDetail: res.data } })
      }
    },

    *edit({ payload }, { call, put, select }) {
      const { modalType, intl } = payload
      let editFormContent = null, emptyContent = null;
      const { currentUser } = yield select(state => state.user)
      const { userInfo } = yield select(state => state.settings)
      const { staff } = currentUser || {}
      const { phoneNumber, countryCode } = userInfo || staff
      switch (modalType) {
        case "editInfo":
          emptyContent = editInfoContent(intl)
          emptyContent.Properties[2].Setting.btnDisabled = true
          editFormContent = matchDynamicForm(emptyContent, userInfo || staff)
          break
        case "validPassword":
          emptyContent = validPasswordContent(intl)
          editFormContent = matchDynamicForm(emptyContent, userInfo || staff)
          break
        case "changePassword":
          editFormContent = changePasswordContent(intl)
          break
        case "editResumes":
          emptyContent = editResumesContent(intl)
          editFormContent = matchDynamicForm(emptyContent, userInfo || staff)
          break
        case "editTelphone":
          editFormContent = editTelphoneContent(intl)
          // emptyContent = editTelphoneContent(intl)
          // editFormContent = matchDynamicForm(emptyContent, userInfo || staff)
          break
      }
      yield put({
        type: 'updateState',
        payload: {
          modalVisible: true,
          content: editFormContent,
          modalType,
          newTel: phoneNumber,
          newCountryCode: countryCode
        }
      })
    },

    *onSubmit({ payload }, { call, put, select }) {
      const { modalType, changeEmailIsSendCode, userInfo, isAdmin } = yield select(state => state.settings)
      const { currentUser } = yield select(state => state.user)
      const { staff, id } = currentUser || {}
      const { picture } = staff || {}
      const { intl, values } = payload
      let res = null
      let params = null
      switch (modalType) {
        case "editInfo":
          res = isAdmin ? yield call(PutProfile, { ...values, userId: id || staff.id }) : yield call(PutUserInfo, { ...staff, ...values, id: staff.id })
          break
        case "editTelphone":
          res = isAdmin ? yield call(ChangePhoneNumberUs, { userId: id || staff.id }, { phoneNumber: values.countryCode + values.phoneNumber, token: values.code }) : yield call(PutUserInfo, { ...staff, ...values, id: staff.id, picture: picture && picture.id })
          break
        case "validPassword":
          res = isAdmin ? yield call(PostCheckEmailPassword, { userId: id || staff.id, password: values.password }) : yield call(PostCheckPassword, { password: values.password })
          break
        case "changeEmail":
          if (!changeEmailIsSendCode) {
            message.error(intl.formatMessage({ id: 'sendCodeFirst' }))
            return
          }
          res = isAdmin ? yield call(PutChangeEmailByCode, { code: values.code, email: values.newEmail, userId: id || staff.id }) : yield call(PutUserEmail, { code: values.code, email: values.newEmail })
          break
        case "changePassword":
          const { newPassword, confirmPassword } = values
          if (newPassword !== confirmPassword) {
            message.error(intl.formatMessage({ id: 'twoPasswordDiff' }))
            return
          } else {
            isAdmin ? res = yield call(ChangePasswordUs, { userId: id || staff.id }, values) : res = yield call(PostChangePassword, values)
          }
          break
        case "editResumes":
          params = {
            resumes: values.resumes,
            userId: staff.id
          }
          res = yield call(PostUploadUserResumes, params)
          break
      }
      if (res && res.response.ok) {
        switch (modalType) {
          case "editInfo":
          case "editTelphone":
            message.success(intl.formatMessage({ id: 'success' }))
            const { picture } = res.data
            let userInfoData;
            if (isAdmin) {
              const fileName = picture && picture.split('_')[1]
              const extension = fileName && '.' + fileName.split('.')[1]
              userInfoData = {
                ...res.data,
                picture: picture ? {
                  id: picture,
                  fileName,
                  extension,
                  url: `${accountPictureUrl}${picture}`
                } : null,
                phoneNumber: res.data.phone
              }
            } else {
              userInfoData = res.data
            }

            yield put.resolve({ type: 'user/getUserInfo' })
            yield put({ type: 'updateState', payload: { modalVisible: false, userInfo: userInfoData } })
            break
          case "validPassword":
            if (!res.data.isPass) {
              message.error(intl.formatMessage({ id: 'passwordError' }))
              return
            }
            yield put({ type: 'updateState', payload: { content: changeEmailContent(intl), modalType: 'changeEmail' } })
            break
          case "changeEmail":
            message.success(intl.formatMessage({ id: 'success' }))
            yield put({ type: 'user/getUserInfo' })
            yield put({ type: 'updateState', payload: { modalVisible: false } })
            break
          case "changePassword":
            message.success(intl.formatMessage({ id: 'success' }))
            yield put({ type: 'updateState', payload: { modalVisible: false } })
            yield put({ type: 'user/logout' })
            break
          case "editResumes":
            message.success(intl.formatMessage({ id: 'success' }))
            yield put({ type: 'updateState', payload: { modalVisible: false, userInfo: res.data } })
        }
      } else {
        const { error } = res && res.data || {}
        const { message: errorInfo } = error || {}
        errorInfo && message.error(errorInfo)
      }
    },

    *onSendCode({ payload }, { call, put, select }) {
      const { newEmail, isAdmin } = yield select(state => state.settings)
      const { currentUser } = yield select(state => state.user)
      const { staff, id } = currentUser || {}
      const { intl } = payload
      if (newEmail) {
        if (emailReg.test(newEmail)) {
          let res = isAdmin ? yield call(PostSendEmail, { email: newEmail, userId: id || staff.id }) : yield call(PostSendCode, { email: newEmail })
          if (res.response.ok) {
            message.success(intl.formatMessage({ id: 'success' }))
            const content = changeEmailContent(intl)
            content.Properties[1].Setting.canSendCode = false
            yield put({ type: 'updateState', payload: { content, changeEmailIsSendCode: true } })
          } else {
            const { error } = res && res.data || {}
            let { message: errorInfo, code } = error || {}
            if (code === "Business:EmailInOtherTenant") errorInfo = intl.formatMessage({ id: 'emailUserd' })
            errorInfo && message.error(errorInfo)
          }
        } else {
          message.error(intl.formatMessage({ id: 'enterRightEmail' }))
        }
      } else {
        message.error(intl.formatMessage({ id: 'enterEmail' }))
      }
    },

    *onCancel({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { modalVisible: false, newEmail: '', changeEmailIsSendCode: false } })
    },

    *downloadCertificate({ payload }, { call, put, select }) {
      const { trainerTrainingModuleId, credential, trainingModule, trainerId, trainingModuleId } = payload || {}
      const { questionnaires } = trainingModule || {}
      yield put({ type: 'updateState', payload: { currentAssmessmentIsFinshed: false, selectItem: payload, needDownload: true } })
      if (questionnaires && questionnaires.length > 0) {
        const GetFeedBacksRes = yield [...questionnaires.map(id => {
          return call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId, QuestionnaireId: id })
        })]
        //const 
        if (GetFeedBacksRes.every(item => item.response?.ok)) {
          const unFinishedQuestionIndex = GetFeedBacksRes.findIndex(item => {
            return item.data === '' || item.data.finishTime === null
          })
          if (unFinishedQuestionIndex !== -1) {
            message.warning("请先完成培训反馈")
            yield put({ type: 'trainingFeedback', payload: { trainingModule, trainerId, trainingModuleId, questionnaireId: questionnaires[unFinishedQuestionIndex] } })
          }
          else {
            downloadLongRangeImg(credential)
          }
        }

        else {
          message.warning("部分培训反馈信息获取失败，请刷新后重试")
        }


        // const GetFeedbackRes = yield call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId })
        // if (GetFeedbackRes.response.ok) {
        //   const GetFeedbackResData = GetFeedbackRes.data
        //   if (!GetFeedbackResData) {
        //     const PostFeedbackRes = yield call(PostTrainerTrainingFeedback, { trainingModuleId })
        //     if (PostFeedbackRes.response.ok) {
        //       const PostFeedbackResData = PostFeedbackRes.data
        //       message.warning("请先完成培训反馈")
        //       yield put({ type: 'updateState', payload: { questionData: PostFeedbackResData, drawerVisible: true } })
        //     }
        //   } else {
        //     const GetFeedbackResData = GetFeedbackRes.data
        //     const { finishTime } = GetFeedbackResData || {}
        //     if (finishTime) {
        //       downloadLongRangeImg(credential)
        //     } else {
        //       yield put({ type: 'updateState', payload: { questionData: GetFeedbackResData, drawerVisible: true } })
        //     }
        //   }
        // }
      } else {
        downloadLongRangeImg(credential)
      }
    },

    *submit({ payload }, { call, put, select }) {
      const { questionData, selectItem, needDownload } = yield select(state => state.settings)
      const { id } = questionData || {}
      const { answerContent, isComplete, finishedPage, intl } = payload
      const { credential } = selectItem || {}
      const params = {
        userQuestionnaireId: id,
        answerContent,
        isComplete,
        finishedPage
      }
      const res = yield call(PutQuestionnaire, params)
      if (res.response.ok) {
        if (isComplete) {
          message.success(intl.formatMessage({ id: 'submitted' }))
          if (needDownload) {
            downloadLongRangeImg(credential)
          }
          yield put({ type: 'updateState', payload: { submitData: null, drawerVisible: false, currentAssmessmentIsFinshed: true } })
        }
      }
    },

    *changeTab({ payload }, { call, put, select }) {
      const { id } = payload;
      yield put({ type: 'updateState', payload: { tabActiveKey: id } })
      const { selectItem: { trainerId, trainingModuleId }, trainerDetail: { trainerCredentialses } } = yield select(state => state.settings)
      const GetFeedbackRes = yield call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId, QuestionnaireId: id })
      if (!GetFeedbackRes.data) {
        const PostFeedbackRes = yield call(PostTrainerTrainingFeedback, { trainingModuleId, questionnaireId: id })
        if (PostFeedbackRes.response.ok) {
          const PostFeedbackResData = PostFeedbackRes.data
          yield put({ type: 'updateState', payload: { questionData: PostFeedbackResData } })
        } else {
          const { error } = PostFeedbackRes.data
          const { message: errorInfo } = error || {}
          errorInfo && message.error(errorInfo)
        }
      } else {
        const GetFeedbackResData = GetFeedbackRes.data
        yield put({ type: 'updateState', payload: { questionData: GetFeedbackResData } })
      }
    },

    *trainingFeedback({ payload }, { call, put, select }) {
      const { selectItem } = yield select(state => state.settings)
      const { trainingModule: { questionnaires }, trainerId, trainingModuleId, questionnaireId: queryId } = payload || {}
      //如果两次打开的是同一个module就不用重复调用请求了
      // if(selectItem&&selectItem.trainingModuleId === trainingModuleId){
      //   yield put({ type: 'updateState', payload: { drawerVisible: true } })
      // }
      // else{
      if (questionnaires && questionnaires.length > 0) {
        const questionnaireId = queryId || questionnaires[0]
        yield put({ type: 'updateState', payload: { tabActiveKey: questionnaireId } })
        const PostQuestionRes = yield call(PostQuestionList, { QuestionnaireIds: questionnaires })
        if (PostQuestionRes.response.ok) {
          const questionListInfo = PostQuestionRes.data
          const showTabList = questionnaires.map(id => {
            return questionListInfo.find(item => item.id === id)
          })
          yield put({ type: 'updateState', payload: { tabListData: showTabList, selectItem: payload, needDownload: false } })
          const GetFeedbackRes = yield call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId, QuestionnaireId: questionnaireId })
          if (!GetFeedbackRes.data) {
            const PostFeedbackRes = yield call(PostTrainerTrainingFeedback, { trainingModuleId, questionnaireId: questionnaireId })
            if (PostFeedbackRes.response.ok) {
              const PostFeedbackResData = PostFeedbackRes.data
              yield put({ type: 'updateState', payload: { questionData: PostFeedbackResData, drawerVisible: true } })
            } else {
              const { error } = PostFeedbackRes.data
              const { message: errorInfo } = error || {}
              errorInfo && message.error(errorInfo)
            }
          } else {
            const GetFeedbackResData = GetFeedbackRes.data
            yield put({ type: 'updateState', payload: { questionData: GetFeedbackResData, drawerVisible: true } })
          }
        } else {
          const { error } = GetFeedbackRes.data
          const { message: errorInfo } = error || {}
          errorInfo && message.error(errorInfo)
        }
      }
      // }

    },

    // 在改变电话区号或者手机号的时候，如果用户输入来输入去的结果和老值相等，发送验证码按钮就为disabled
    *onchangeInfo({ payload }, { call, put, select }) {
      const { value, type, intl } = payload;
      const { currentUser } = yield select((state) => state.user);
      const { userInfo, newTel, newCountryCode } = yield select(
        (state) => state.settings
      );
      const { staff } = currentUser || {};
      const { phoneNumber, countryCode } = userInfo || staff;
      if (type === 'phoneNumber') {
        const content = editTelphoneContent(intl);
        if (
          value !== phoneNumber &&
          isEffectualPhoneNumber(newCountryCode, value)
        ) {
          content.Properties[1].Setting.btnDisabled = false;
        } else {
          content.Properties[1].Setting.btnDisabled = true;
        }
        yield put({ type: 'updateState', payload: { content, newTel: value } });
      } else if (type === 'countryCode') {
        window.myFormModalRef.current.validateFields(['phoneNumber']);
        const content = editTelphoneContent(intl);
        if (newTel !== phoneNumber && isEffectualPhoneNumber(value, newTel)) {
          content.Properties[1].Setting.btnDisabled = false;
        } else {
          content.Properties[1].Setting.btnDisabled = true;
        }
        yield put({
          type: 'updateState',
          payload: { content, newCountryCode: value },
        });
      }
    },

    *onSendCodeIntel({ payload }, { call, put, select }) {
      const { intl } = payload
      const { newTel, newCountryCode, isAdmin } = yield select(state => state.settings)
      const { currentUser } = yield select(state => state.user)
      const { staff, id } = currentUser || {}
      const sendRes = isAdmin ? yield call(PostSendPhoneCode, { userId: id || staff.id }, { phoneNumber: newCountryCode + newTel }) : yield call(PostSendCheckNumberCode, { phone: newTel, countryCode: newCountryCode })
      if (sendRes.response.ok) {
        message.success(intl.formatMessage({ id: 'codeSendSuccess' }))
        const content = editTelphoneContent(intl)
        content.Properties[1].Setting.canSendCode = false
        yield put({ type: 'updateState', payload: { content } })
      } else {
        const { error } = sendRes.data
        let { message: errorInfo, code } = error || {}
        if (code === "InvalidPhone") errorInfo = intl.formatMessage({ id: 'phoneNumberIncorrect' })
        errorInfo && message.error(errorInfo)
      }
    },

    *userLogout({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { logout: false } })
      const logoutRes = yield call(PostLogoutUserApplication)
      if (logoutRes.response.ok) {
        // 注销成功
        message.success('注销成功')
        yield put({ type: 'user/logout' })
      } else {
        const { error } = logoutRes.data
        let { message: errorInfo } = error || {}
        errorInfo && message.error(errorInfo)
      }
    },

  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/account/settings') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
