import React from 'react'
import { Modal, Transfer } from 'antd'

export default class SelectModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            targetKeys: props.targetKeys || [],
            selectedKeys: props.selectedKeys || [],
        }
    }

    handleCancel = () => {
        this.props.onCancel()
    }

    handleOk = targetKeys => {
        this.props.onOk(targetKeys)
    }

    handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        const { handleSelectChange } = this.props
        handleSelectChange([...sourceSelectedKeys, ...targetSelectedKeys])
    }

    handleChange = targetKeys => {
        const { handleChange } = this.props
        handleChange(targetKeys)
    }

    static getDerivedStateFromProps(props, state){
        if(props.targetKeys!==state.targetKeys||props.selectedKeys!==state.selectedKeys){
            return {
                targetKeys: props.targetKeys,
                selectedKeys: props.selectedKeys
            }
        }
        return null
    }

    // componentWillReceiveProps(props) {
    //     this.setState({
    //         targetKeys: props.targetKeys,
    //         selectedKeys: props.selectedKeys
    //     })
    // }

    render() {
        const { selectModalVisible, allList, title, loading } = this.props
        const { targetKeys, selectedKeys } = this.state
        const showData = allList.map(item => {
            return {
                ...item,
                key: item.id,
            }
        })

        return (
            <Modal
                width={800}
                destroyOnClose={true}
                title={title}
                open={selectModalVisible}
                onOk={() => this.handleOk(targetKeys)}
                onCancel={this.handleCancel}
                confirmLoading={loading}
            >
                <Transfer
                    listStyle={{
                        width: 350,
                        height: 400,
                    }}
                    showSearch
                    dataSource={showData}
                    titles={['Source', 'Target']}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onChange={this.handleChange}
                    onSelectChange={this.handleSelectChange}
                    filterOption={(inputValue, option) => option.title && option.title.indexOf(inputValue) > -1}
                    render={item => item.title}
                />
            </Modal>
        )
    }
}
