import React from 'react'
import { Modal, Form, Select, Button } from 'antd'
import styles from './TestDetail.less'
import { useIntl } from 'umi'
const FormItem = Form.Item
const SelectOption = Select.Option

const AddCommentModal = ({
  tempList,
  title,
  visible,
  onCancel,
  onOk
}) => {
  const intl = useIntl()
  const templateOptions = tempList.map(item => {
    return (
      <SelectOption key={item.Id} value={item.Id}>
        {item.DisplayName}
      </SelectOption>
    )
  })

  const onSubmit = (values) => {
    onOk(values)
  }

  const onQuit = () => {
    onCancel()
  }

  return (
    <Modal
      maskClosable={false}
      open={visible}
      title={title}
      okText={intl.formatMessage({ id: 'submit' })}
      cancelText={intl.formatMessage({ id: 'cancel' })}
      onCancel={onQuit}
      zIndex={102}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
      >
        <FormItem
          label={intl.formatMessage({ id: 'commentTemplate' })}
          name={'QuestionnaireId'}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: 'commentTemplate' })} ${intl.formatMessage({
                id: 'isRequired',
              })}`,
            },
          ]}

        >
          <Select>{templateOptions}</Select>
        </FormItem>
        <div className={styles.footer}>
          <Button className={styles.footerLeft} onClick={onQuit}>{intl.formatMessage({ id: 'cancel' })}</Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: 'submit' })}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default AddCommentModal
