import React from 'react';
import { Row, Col } from 'antd';
import MotionChart from './MotionChart';

const MovementPathMotionType = ({ data, motionIcon, motionType, styles }) => {
  const {
    MotionTypeBlock1,
    MotionTypeBlock2,
    MotionTypeBlock3,
    MotionTypeBlock4,
  } = data;
  let style = { color: '#3CA9E5', fontWeight: 700 };
  let colStyle = {
    marginTop: 45,
    marginRight: 30,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '143px'
  };
  return (
    <div className={styles.marginBottom}>
      <Row type="flex" justify="center">
        <Col span={1} style={colStyle}>
          <img src={motionIcon} alt={motionIcon} />
          <p style={style}>{motionType}</p>
        </Col>
        <div className={styles.movementPathMotionTypeContent}>
          <div className={styles.borderRightNone}>
            <MotionChart {...MotionTypeBlock1} />
          </div>
          <div className={styles.borderRightNone}>
            <MotionChart {...MotionTypeBlock2} />
          </div>
          <div className={styles.borderRightNone}>
            <MotionChart {...MotionTypeBlock3} />
          </div>
          <div className={styles.border1px}>
            <MotionChart {...MotionTypeBlock4} />
          </div>
        </div>
      </Row>
    </div>
  );
};

export default MovementPathMotionType;
