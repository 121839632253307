import React from 'react'
import { connect } from 'umi'
import { Spin, Tabs } from 'antd';
import SleepLineChart from 'cognitiveleap-core-us/components/Charts/SleepLineChart';
import PositivePieChart from 'cognitiveleap-core-us/components/Charts/PositivePieChart';
import PositiveBarChart from 'cognitiveleap-core-us/components/Charts/PositiveBarChart';
import NegativePieChart from 'cognitiveleap-core-us/components/Charts/NegativePieChart';
import NegativeBarChart from 'cognitiveleap-core-us/components/Charts/NegativeBarChart';
import CenterClockInRecord from 'components/ClockInRecord';
import CenterDataTop from 'components/DataTop';
import Loading from 'components/Loading'
import { useIntl } from 'umi'
import styles from '../detail.less'

const { TabPane } = Tabs;

const SleepInfo = ({ subjectSleep, subjectDetail, centerClockInRecord, loading, dispatch }) => {
  const { planInfo } = subjectSleep;
  const intl = useIntl()
  if (planInfo == null) return <Spin className={styles.spin} />

  const { activePlans, lastestTrainingPlan } = planInfo;
  const { sleepRecord, pagination } = centerClockInRecord;
  const isLoading = loading.effects['centerClockInRecord/getRecord'];
  const { subjectId, subjectInfo: { isBind, isLogout } } = subjectDetail;

  const loadData = () => {
    lastestTrainingPlan && lastestTrainingPlan.id &&
      dispatch({
        type: 'centerClockInRecord/getRecord', payload: {
          id: lastestTrainingPlan.id,
          type: 'sleep',
          current: ++pagination.sleep.current
        }
      });
  }

  const propsData = {
    dataSource: sleepRecord,
    loadData,
    isLoading
  }

  const chartProps = {
    subjectId,
    from: 'pc',
    Loading
  }

  return (
    <div className={styles.tabContent}>
      {
        !isLogout && isBind ?
          <div>
            {
              activePlans.length == 0 ? intl.formatMessage({ id: 'noOpenTraining' }) :
                <div>
                  <CenterDataTop {...{ lastestTrainingPlan, type: 'center' }} />
                  <Tabs defaultActiveKey="chart" type="card" onChange={() => { !sleepRecord && loadData() }}>
                    <TabPane tab={intl.formatMessage({ id: 'chart' })} key="chart">
                      <center>
                        <div className={styles.chart}>
                          <SleepLineChart {...chartProps} />
                          <PositivePieChart {...chartProps} />
                          <PositiveBarChart {...chartProps} />
                          <NegativePieChart {...chartProps} />
                          <NegativeBarChart {...chartProps} />
                        </div>
                      </center>
                    </TabPane>
                    <TabPane tab={intl.formatMessage({ id: 'checkInRecords' })} key="record">
                      <CenterClockInRecord {...propsData} />
                    </TabPane>
                  </Tabs>
                </div>
            }
          </div>
          :
          <span>{intl.formatMessage({ id: 'childCannotViewed' }, { status: intl.formatMessage({ id: isLogout ? 'cancelled' : 'noLongerAuthorized' })})}</span>
      }
    </div>
  )
}

export default connect(({
  subjectSleep,
  subjectDetail,
  loading,
  centerClockInRecord
}) => ({
  subjectSleep,
  subjectDetail,
  loading,
  centerClockInRecord
}))(SleepInfo)
