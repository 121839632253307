import React, { useEffect, useState, useRef } from 'react';
import { message, Progress, Modal, Button, Spin } from 'antd';
import Questionnaire from 'components/Question';
import moment from 'moment';
import { PostStartExamPaper, PutExamPaper, GetExamResult } from 'services/exam';
import {
  FileTextOutlined,
  FieldTimeOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { history } from 'umi';
import useTimer from './components/timer';
import { IntlManager } from 'utils/helper'
import styles from './index.less';


const ExamPanelDetail = (props) => {
  const intl = IntlManager.MyIntl()

  const { trainerTrainingModuleId } = props.location.query;

  const [currentData, setCurrentData] = useState({});
  // 自动提交
  const [myAnswerContent, setAnswerContent] = useState('');
  // 用于比较是否有更改
  const [currentAnswer, setCurrentAnswer] = useState({});
  const [status, setStatus] = useState('DOING');
  const [startTime, setStartTime] = useState('');
  const [showTop, setShowTop] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [changeTime, setChangeTime] = useState(false);
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);

  const finishId = useRef();
  const finishAnswer = useRef();

  const showWarn = () => {
    message.warn(intl('minutesLeftInExam'), 3);
  };

  const showFinishWarn = async () => {
    const params = {
      answerContent: finishAnswer.current,
      id: finishId.current,
      end: true,
    };
    const res = await PutExamPaper(params);
    if (res.response.ok) {
      Modal.warning({
        title: intl('theExamEnds'),
        content: intl('automaticallySubmitted'),
        onOk() {
          history.replace({
            pathname: '/system/center/detail/examResult',
            query: {
              examId: finishId.current,
            },
          });
        },
      });
    } else {
      message.error(IntlManager.intl.formatMessage({ id: 'failedSubmitAnswer'}));
    }
  };

  const changTimeStyle = () => {
    setChangeTime(true);
  };

  const current = useTimer(
    currentData,
    startTime,
    showWarn,
    showFinishWarn,
    changTimeStyle,
  );

  const getData = async () => {
    const params = {
      id: trainerTrainingModuleId,
    };
    const res = await GetExamResult(params);
    if (res.response.ok) {
      const { status } = res.data.data;
      if (status === 'NOT_STARTED') {
        Modal.info({
          title: intl('startTheExam'),
          content: <div>{intl('clickStartTest')}</div>,
          okText: intl('startTheExam'),
          cancelText: '',
          async onOk() {
            const paperRes = await PostStartExamPaper(params);
            if (paperRes.response.ok) {
              getData();
            } else {
              const { msg } = paperRes.data || { msg: intl("failedStartExam ") };
              message.error(msg);
              setTimeout(() => {
                history.goBack();
              }, 1500);
            }
          },
        });
      } else {
        const {
          examinationPaper,
          examinationPaperAnswer,
          id,
          status,
          startTime,
        } = res.data.data;
        if (status !== 'END' || status !== 'NOT_STARTED') {
          setId(id);
          finishId.current = id;
          setStartTime(startTime);
          setCurrentData(examinationPaper);
          if (examinationPaperAnswer && examinationPaperAnswer.answerContent) {
            setAnswerContent(examinationPaperAnswer.answerContent);
            finishAnswer.current = examinationPaperAnswer.answerContent;
          }
          if (status === 'DOING') {
            const deadLine = moment(startTime).add(
              examinationPaper.duration,
              'minutes',
            );
            const deadLineTime = deadLine.diff(moment(), 'seconds');
            if (deadLineTime < 0) {
              console.log('超时自动提交了');
              showFinishWarn();
            }
          }
        } else {
          setStatus(status);
        }
      }
    } else {
      const { msg } = res.data || { msg: intl('getExamInformation ') };
      setErrorMessage(msg);
      message.error(msg);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const QuestionnaireProps = {
    content: currentData.jsonString,
    answerContent: myAnswerContent,
    loopCompleteTime: 15000,
    async onComplete(data) {
      let res;
      const { answerContent, isComplete } = data;
      setCurrentAnswer(answerContent);
      if (answerContent !== currentAnswer) {
        const params = {
          answerContent,
          id,
          end: false,
        };
        res = await PutExamPaper(params);
      }
      if (isComplete) {
        if (
          Object.keys(JSON.parse(answerContent.split('\\').join(''))).length !==
          currentData.totalQuestionCount
        ) {
          Modal.confirm({
            title: '',
            icon: <ExclamationCircleOutlined />,
            content: <div>{intl('sureSubmitQuestionnaire')}</div>,
            okText: intl("submit"),
            cancelText: intl('cancel'),
            onOk: async () => {
              const params = {
                answerContent,
                id,
                end: true,
              };
              res = await PutExamPaper(params);
              if (res.response.ok) {
                history.replace({
                  pathname: '/system/center/detail/examResult',
                  query: {
                    examId: id,
                  },
                });
              } else {
                message.error(IntlManager.intl.formatMessage({ id: 'failedSubmitAnswer'}));
              }
            },
          });
        } else {
          const params = {
            answerContent,
            id,
            end: true,
          };
          res = await PutExamPaper(params);
          if (res.response.ok) {
            history.replace({
              pathname: '/system/center/detail/examResult',
              query: {
                examId: id,
              },
            });
          } else {
            message.error(IntlManager.intl.formatMessage({ id: 'failedSubmitAnswer'}));
          }
        }
      }
    },
    onValueChange(data) {
      setAnswerContent(data.answerContent);
      finishAnswer.current = data.answerContent;
    },
  };

  window.onbeforeunload = (event) => {
    if (event) {
      event.returnValue = intl('closePrompt');
    }
  };

  document.addEventListener('scroll', function (event) {
    var _scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (_scrollTop > 25) {
      setShowTop(true);
    }
  });

  return (
    <div className={styles.panel}>
      <Spin tip={errorMessage} spinning={errorMessage !== null}>
        {status === 'DOING' ? (
          <>
            <div
              className={
                !showTop
                  ? styles.panelTop
                  : `${styles.panelTop} ${styles.panelTopFix}`
              }
            >
              <div className={styles.topTitle}>{currentData.name}</div>
              <div className={styles.topIcon}>
                <div className={styles.icon}>
                  <FieldTimeOutlined
                    style={{ fontSize: '25px', color: '#009dd9' }}
                  />
                  <div
                    className={
                      !changeTime
                        ? `${styles.description}`
                        : `${styles.description} ${styles.breath}`
                    }
                  >
                    {current !== null ? current : '00 : 00 : 00'}
                  </div>
                </div>
                <div className={styles.icon}>
                  <FileTextOutlined
                    style={{ fontSize: '25px', color: '#009dd9' }}
                  />
                  <div className={styles.description}>
                    {intl('totalScore')}{currentData.fraction}
                  </div>
                </div>
              </div>
              <div className={styles.process}>
                <Progress
                  strokeLinecap="square"
                  percent={Math.round(
                    (myAnswerContent &&
                      Object.keys(
                        JSON.parse(myAnswerContent.split('\\').join('')),
                      ).length / currentData.totalQuestionCount) * 100,
                  )}
                />
              </div>
              <div>
                <Button
                  className={styles.backBtn}
                  onClick={() => {
                    Modal.confirm({
                      title: intl('confirmExitExam'),
                      icon: <ExclamationCircleOutlined />,
                      content: (
                        <div>
                          {intl('answersWillSubmitted')}
                        </div>
                      ),
                      okText: intl("submit"),
                      cancelText: intl("cancel"),
                      onOk: () => {
                        history.goBack();
                      },
                    });
                  }}
                >
                  {intl("back")}
                </Button>
              </div>
            </div>
            <div
              className={
                !showTop
                  ? styles.panelBody
                  : `${styles.panelBody} ${styles.panelBodyFix}`
              }
            >
              {!loading && JSON.stringify(currentData) !== '{}' && (
                <Questionnaire {...QuestionnaireProps} />
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.finishPanel}>{intl('examHasEnded')}</div>
          </>
        )}
      </Spin>
    </div>
  );
};

export default ExamPanelDetail;
