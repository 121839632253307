import React from 'react'
import { history } from 'umi';
import { connect } from 'umi'
import { Button } from 'antd'
import HfsOrderInfo from 'components/HfsOrderInfo'
import styles from './index.less'

const HfsOrderDetail = ({ adminhfsOrderDetail, loading }) => {

    return (
        <div className={styles.orderDetail}>
            <div className={styles.orderDetailTop}>
                <Button onClick={()=> history.goBack()}>返回</Button>
                <span className={styles.orderDetailTopDes}>订单详情</span>
            </div>
            <HfsOrderInfo />
        </div> 
    )
}

export default connect(({ adminhfsOrderDetail, loading }) => ({ adminhfsOrderDetail, loading }))(HfsOrderDetail)