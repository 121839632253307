import React from 'react'

import { Table, Spin } from 'antd'

const GrantList = ({ currentTab, showList, vratCenterInfo, isLoading }) => {
  const courseColumns = [
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'subTitle',
      dataIndex: 'subTitle',
      key: 'subTitle',
    },
    {
      title: '课时数',
      dataIndex: 'length',
      key: 'length',
    },
  ]

  const TrainingMaterialsColumns = [
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'subTitle',
      dataIndex: 'subTitle',
      key: 'subTitle',
    },
  ]

  const assessmentColumns = [
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => {
        const { title, name } = record || {};
        return title + '-' + name;
      }
    },
    {
      title: 'subTitle',
      dataIndex: 'subTitle',
      key: 'subTitle',
    },
  ]

  const offlinePlanColumns = [
    {
      title: 'code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'displayName',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'totalCourseNum',
      dataIndex: 'totalCourseNum',
      key: 'totalCourseNum',
    },
  ]

  const vratColumns = [
    {
      title: 'DisplayName',
      dataIndex: 'DisplayName',
      key: 'DisplayName',
    },
    {
      title: 'TotalPage',
      dataIndex: 'TotalPage',
      key: 'TotalPage',
    },
  ]

  const trainingPlanLibraryColumns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'displayName',
      dataIndex: 'displayName',
      key: 'displayName',
    }
  ]

  const showColumns = () => {
    switch (currentTab) {
      case 'Course':
        return courseColumns
      case 'Database':
        return TrainingMaterialsColumns
      case 'Assessment':
        return assessmentColumns
      case 'OfflineTrainingPlan':
        return offlinePlanColumns
      case 'VRAT_Assessment':
        return vratColumns
      case 'TrainingPlanLibrary':
        return trainingPlanLibraryColumns
      default:
        return []
    }
  }

  const getGrantedList = () => {
    switch (currentTab) {
      case 'Course':
        return showList
          .filter((item) => item.isGranted && item.resource && item.resource.type === currentTab)
          .map((item) => item.resource)
      case 'Database':
        return showList
          .filter((item) => item.isGranted && item.resource && item.resource.type === 'TrainingMaterials')
          .map((item) => item.resource)
      case 'TrainingPlanLibrary':
        return showList.filter((item) => item.isGrant).map((item) => item.resource)
      default:
        return showList.filter((item) => item.isGranted).map((item) => item.resource)
    }
  }

  let showAllowQuestionnaires = []
  vratCenterInfo.AllowQuestionnaires.forEach((item) => {
    showAllowQuestionnaires.push(
      vratCenterInfo.AllQuestionnaires.find((allitem) => allitem.Id === item.QuestionnaireId)
    )
  })

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={showColumns()}
        dataSource={currentTab === 'VRAT_Assessment' ? showAllowQuestionnaires : getGrantedList()}
        rowKey={currentTab === 'VRAT_Assessment' ? 'Id' : 'id'}
      />
    </Spin>
  )
}

export default GrantList
