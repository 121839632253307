import React from 'react'
import { SHOWTYPE } from './models/updateEmail'
import { Result } from 'antd'

export default class Clock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countDown: 5,
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  tick() {
    if (this.state.countDown > 1) {
      this.setState({
        countDown: this.state.countDown - 1,
      })
    } else {
      const { onComplete } = this.props
      if (onComplete) onComplete()
    }
  }

  getShowText(type) {
    switch (type) {
      case SHOWTYPE.ErrorLink:
        return '激活链接错误或者失效，{seconds}秒之后自动返回登录页'
      case SHOWTYPE.ExistUser:
      case SHOWTYPE.UserAlreadyActive:
        return '该账户已激活，{seconds}秒之后自动返回登录页'
      case SHOWTYPE.UnknownError:
      default:
        return '激活失败，{seconds}秒之后自动返回登录页'
    }
  }

  showStatusIcon(type) {
    switch (type) {
      case SHOWTYPE.ErrorLink:
        return 'warning'
      case SHOWTYPE.ExistUser:
      case SHOWTYPE.UserAlreadyActive:
        return 'success'
      case SHOWTYPE.UnknownError:
      default:
        return 'warning'
    }
  }

  render() {
    const { showType } = this.props
    const { countDown } = this.state
    const showText = this.getShowText(showType).replace('{seconds}', countDown)
    return (
      <div style={{ marginTop: 100 }}>
        <Result title={showText} status={this.showStatusIcon(showType)} />
      </div>
    )
  }
}
