import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
  PostList
} from 'services/rocketService/Prepare'
import { PublishStatus,EditSortOrder } from 'services/rocketService/Course'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import { message } from 'antd'
import config from 'utils/config'
const { baseURL, uploadImagesUrl } = config

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

const listSupport = {
  title: {
    showText: 'title',
    showType: 'Text',
  },
  subTitle: {
    showText: 'subTitle',
    showType: 'Text',
  },
  sortOrder:{
    showText: 'sortOrder',
    showType: 'Text',
  },
  type: {
    showText: 'type',
    showType: 'Text',
    filter: {
      hasFilter: true,
      filterOptions: [
        { text: 'Course', value: 'Course' },
        { text: 'TrainingMaterials', value: 'TrainingMaterials' },
        { text: 'TrainingCourse', value: 'TrainingCourse' },
      ],
      filterMultiple: false,
    }
  },
  recommended: {
    showText: 'recommended',
    showType: 'Bool',
  },
  new: {
    showText: 'new',
    showType: 'Bool',
  },
  isVip: {
    showText: 'isVip',
    showType: 'Bool',
  },
  price: {
    showText: 'price',
    showType: 'Text',
  },
  length: {
    showText: '课时数',
    showType: 'Text',
  },
  accessCount: {
    showText: '播放量',
    showType: 'Text',
  },
  status: {
    showText: 'status',
    showType: 'Text',
    filter: {
      hasFilter: true,
      filterOptions: [
        { text: 'Public', value: 'Public' },
        { text: 'Hidden', value: 'Hidden' },
        { text: 'Unpublished', value: 'Unpublished' },
        { text: 'Expired', value: 'Expired' },
        { text: 'Trashed', value: 'Trashed' },
      ],
    }
  },
}

export default {
  namespace: 'course',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
    filters: {},
    searchInput: '',
    statusVisible: false,
    sortOrderModal: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, searchInput, filters, } = yield select(state => state.course)
      const { type = [], status = [] } = filters || {}
      let query = { 
        skipCount: 0, 
        maxResultCount: 10, 
        type: (type || [])[0] || '',
        filter: searchInput  || '',
        ContentStatuses: status || []
      }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      const res = yield call(PostList, {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      })
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      yield put({ type: 'updateState', payload: data })
      yield put({ type: 'query' })
    },

    *onAdd(_, { call, put, select }) {
      const res = yield call(PrepareForEdit, {}, 'course')
      if (res.response.ok) {
        const { availableTags, availableTeachers } = res.data
        const tagOptions = listToOptions(availableTags, 'name')
        const teacherOptions = listToOptions(availableTeachers, 'name')
        const contentMenu = {
          Properties: [
            {
              EditorType: 'Dropdown',
              Value: null,
              Setting: {
                DropdownOptions: [
                  {
                    Value: 'Course',
                    Id: 'Course',
                  },
                  {
                    Value: 'TrainingMaterials',
                    Id: 'TrainingMaterials',
                  },
                  {
                    Value: 'TrainingCourse',
                    Id: 'TrainingCourse',
                  },
                ],
                Required: false,
              },
              ShowTitle: 'type',
              FormKey: 'type',
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'Title',
              FormKey: 'title',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'SubTitle',
              FormKey: 'subTitle',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Upload',
              Value: null,
              Setting: {
                UploadSetting: {
                  uploadType: 'image',
                  isSingle: true,
                },
                Action: baseURL + uploadImagesUrl,
                Header: getAuthHeader(),
              },
              ShowTitle: 'ImageUrl',
              FormKey: 'imageUrl',
            },
            {
              EditorType: 'Upload',
              Value: null,
              Setting: {
                UploadSetting: {
                  uploadType: 'image',
                  isSingle: true,
                },
                Action: baseURL + uploadImagesUrl,
                Header: getAuthHeader(),
              },
              ShowTitle: 'HeaderImageUrl',
              FormKey: 'headerImageUrl',
            },
            // {
            //   EditorType: 'RichEditorPro',
            //   ShowTitle: '富文本',
            //   FormKey: 'draftContent',
            //   AdditionalData: null,
            //   Value: null,
            //   Setting: {},
            //   Description: null,
            // },
            {
              EditorType: 'Bool',
              ShowTitle: 'recommended',
              FormKey: 'recommended',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: 'new',
              FormKey: 'new',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: 'isVip',
              FormKey: 'isVip',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'NumberInput',
              ShowTitle: 'price',
              FormKey: 'price',
              AdditionalData: null,
              Value: null,
              Setting: {
                NumberSetting: {
                  min: 0,
                  step: 1,
                  precision: 2,
                },
              },
            },
            {
              EditorType: 'Dropdown',
              Value: null,
              Setting: {
                DropdownOptions: [
                  {
                    Value: 'RMB',
                    Id: 'RMB',
                  },
                ],
                Required: false,
              },
              ShowTitle: 'currencyType',
              FormKey: 'currencyType',
              Description: null,
            },
            {
              EditorType: 'EditableList',
              ShowTitle: 'steps',
              FormKey: 'steps',
              AdditionalData: null,
              Value: null,
              Setting: {
                ListElement: {
                  Properties: [
                    {
                      EditorType: 'Input',
                      ShowTitle: 'title',
                      FormKey: 'title',
                      AdditionalData: null,
                      Value: null,
                      Setting: {
                        Required: false,
                      },
                      Description: null,
                    },
                    {
                      EditorType: 'NumberInput',
                      ShowTitle: 'count',
                      FormKey: 'count',
                      AdditionalData: null,
                      Value: null,
                      Setting: {
                        NumberSetting: {
                          min: 0,
                          step: 1,
                        },
                      },
                    },
                  ],
                },
              },
              Description: null,
            },
            {
              EditorType: 'EditableList',
              ShowTitle: 'courseModules',
              FormKey: 'courseModules',
              AdditionalData: null,
              Value: null,
              Setting: {
                ListElement: {
                  Properties: [
                    {
                      EditorType: 'Input',
                      ShowTitle: 'title',
                      FormKey: 'title',
                      AdditionalData: null,
                      Value: null,
                      Setting: {
                        Required: false,
                      },
                      Description: null,
                    },
                    {
                      EditorType: 'RichEditorPro',
                      ShowTitle: 'detail',
                      FormKey: 'detail',
                      AdditionalData: null,
                      Value: null,
                      Setting: {},
                      Description: null,
                    },
                  ],
                },
              },
              Description: null,
            },
            {
              EditorType: 'ContentSelector',
              Value: null,
              Setting: {
                SelectorOptions: tagOptions,
                Required: false,
              },
              ShowTitle: 'tagIds',
              FormKey: 'tagIds',
              Description: null,
            },
            {
              EditorType: 'ContentSelector',
              Value: null,
              Setting: {
                SelectorOptions: teacherOptions,
                Required: false,
              },
              ShowTitle: 'teacherIds',
              FormKey: 'teacherIds',
              Description: null,
            },
          ],
        }
        yield put({
          type: 'updateState',
          payload: { modalType: 'add', content: contentMenu, modalVisible: true },
        })
      }
    },

    *onEdit({ payload }, { call, put, select }) {
      const { id, status } = payload
      const res = yield call(PrepareForEdit, { id }, 'course')
      if (res.response.ok) {
        const { availableTags, availableTeachers, course } = res.data
        const {
          title,
          subTitle,
          imageUrl,
          headerImageUrl,
          recommended,
          new: isNew,
          isVip,
          price,
          currencyType,
          courseModules,
          tagIds,
          teacherIds,
          steps,
          type
        } = course
        const tagOptions = listToOptions(availableTags, 'name')
        const teacherOptions = listToOptions(availableTeachers, 'name')

        const contentMenu = {
          Properties: [
            {
              EditorType: 'Dropdown',
              Value: type,
              Setting: {
                DropdownOptions: [
                  {
                    Value: 'Course',
                    Id: 'Course',
                  },
                  {
                    Value: 'TrainingMaterials',
                    Id: 'TrainingMaterials',
                  },
                  {
                    Value: 'TrainingCourse',
                    Id: 'TrainingCourse',
                  }
                ],
                Required: false,
              },
              ShowTitle: 'type',
              FormKey: 'type',
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'Title',
              FormKey: 'title',
              AdditionalData: null,
              Value: title,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'SubTitle',
              FormKey: 'subTitle',
              AdditionalData: null,
              Value: subTitle,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Upload',
              Value: imageUrl,
              Setting: {
                UploadSetting: {
                  uploadType: 'image',
                  isSingle: true,
                },
                Action: baseURL + uploadImagesUrl,
                Header: getAuthHeader(),
              },
              ShowTitle: 'imageUrl',
              FormKey: 'imageUrl',
            },
            {
              EditorType: 'Upload',
              Value: headerImageUrl,
              Setting: {
                UploadSetting: {
                  isSingle: true,
                  uploadType: 'image',
                },
                Action: baseURL + uploadImagesUrl,
                Header: getAuthHeader(),
              },
              ShowTitle: 'headerImageUrl',
              FormKey: 'headerImageUrl',
            },
            {
              EditorType: 'Bool',
              ShowTitle: 'recommended',
              FormKey: 'recommended',
              AdditionalData: null,
              Value: recommended,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: 'new',
              FormKey: 'new',
              AdditionalData: null,
              Value: isNew,
              Setting: {
                Required: false,
              },
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: 'isVip',
              FormKey: 'isVip',
              AdditionalData: null,
              Value: isVip,
              Setting: {
                Required: false,
                Disabled: ["Hidden", "Public"].includes(status)
              },
              Description: null,
            },
            {
              EditorType: 'NumberInput',
              ShowTitle: 'price',
              FormKey: 'price',
              AdditionalData: null,
              Value: price,
              Setting: {
                NumberSetting: {
                  min: 0,
                  step: 1,
                  precision: 2,
                },
              },
            },
            {
              EditorType: 'Dropdown',
              Value: currencyType,
              Setting: {
                DropdownOptions: [
                  {
                    Value: 'RMB',
                    Id: 'RMB',
                  },
                ],
                Required: false,
              },
              ShowTitle: 'currencyType',
              FormKey: 'currencyType',
              Description: null,
            },
            {
              EditorType: 'EditableList',
              ShowTitle: 'steps',
              FormKey: 'steps',
              AdditionalData: null,
              Value: steps,
              Setting: {
                ListElement: {
                  Properties: [
                    {
                      EditorType: 'Input',
                      ShowTitle: 'title',
                      FormKey: 'title',
                      AdditionalData: null,
                      Value: null,
                      Setting: {
                        Required: false,
                      },
                      Description: null,
                    },
                    {
                      EditorType: 'NumberInput',
                      ShowTitle: 'count',
                      FormKey: 'count',
                      AdditionalData: null,
                      Value: null,
                      Setting: {
                        NumberSetting: {
                          min: 0,
                          step: 1,
                        },
                      },
                    },
                  ],
                },
              },
              Description: null,
            },
            {
              EditorType: 'EditableList',
              ShowTitle: 'courseModules',
              FormKey: 'courseModules',
              AdditionalData: null,
              Value: courseModules,
              Setting: {
                ListElement: {
                  Properties: [
                    {
                      EditorType: 'Input',
                      ShowTitle: 'title',
                      FormKey: 'title',
                      AdditionalData: null,
                      Value: null,
                      Setting: {
                        Required: false,
                      },
                      Description: null,
                    },
                    {
                      EditorType: 'RichEditorPro',
                      ShowTitle: 'detail',
                      FormKey: 'detail',
                      AdditionalData: null,
                      Value: null,
                      Setting: {},
                      Description: null,
                    },
                  ],
                },
              },
              Description: null,
            },
            {
              EditorType: 'ContentSelector',
              Value: tagIds,
              Setting: {
                SelectorOptions: tagOptions,
                Required: false,
              },
              ShowTitle: 'tagIds',
              FormKey: 'tagIds',
              Description: null,
            },
            {
              EditorType: 'ContentSelector',
              Value: teacherIds,
              Setting: {
                SelectorOptions: teacherOptions,
                Required: false,
              },
              ShowTitle: 'teacherIds',
              FormKey: 'teacherIds',
              Description: null,
            },
          ],
        }
        yield put({
          type: 'updateState',
          payload: {
            content: contentMenu,
            modalType: 'edit',
            modalVisible: true,
            selectItem: course,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'course')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.course)
      let res
      if (modalType === 'edit') {
        res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'course')
      } else {
        res = yield call(PostPrepareData, data, 'course')
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      } else {
        const { error } = res.data
        const { message: errorInfo } = error || {}
        message.error(errorInfo)
      }
    },

    *changeSortOrder({ payload }, { call, put, take, select }) {
      const { selectItem } = yield select(state => state.course)
      const res = yield call(EditSortOrder, selectItem.id, payload)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { sortOrderModal: false },
        })
        yield put({ type: 'query' })
      }
    },

    *changeStatus({ payload: data }, { take, put, call, select }) {
      const { selectItem } = yield select(state => state.course)
      const res = yield call(PublishStatus, { id: selectItem.id, action: data })
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { statusVisible: false } })
      } else {
        const { error } = res.data
        const { message: errorInfo } = error || {}
        message.error(errorInfo)
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/course/course') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
