import React, { useEffect, useState } from 'react';
import { Card, Input, Button, Space, message } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import DeviceModal from 'components/DeviceModal';
import { history, useIntl, connect } from 'umi';
import download from 'cognitiveleap-core-us/utils/download';
import moment from 'moment';

import {
  PostAdminAllDevices,
  PostCenterAllDevices,
  PostExportExcel,
} from 'services/device';

const { Search } = Input;

const showStatus = (intl) => {
  return {
    WaitForRegistration: intl.formatMessage({ id: 'PendingRegistration' }),
    Reviewing: intl.formatMessage({ id: 'UnderReview' }),
    Registered: intl.formatMessage({ id: 'Registered' }),
  };
};

const listSupport = (intl, isAdmin) => {
  return {
    DeviceSN: {
      showText: intl.formatMessage({ id: 'DeviceSN' }),
      showType: 'Text',
    },
    DeviceName: {
      showText: intl.formatMessage({ id: 'DeviceName' }),
      showType: 'Text',
    },
    TenantName: {
      showText: intl.formatMessage({ id: 'center' }),
      showType: isAdmin ? 'Text' : 'Hidden',
    },
    Status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: [
          {
            text: intl.formatMessage({ id: 'PendingRegistration' }),
            value: 'WaitForRegistration',
          },
          {
            text: intl.formatMessage({ id: 'UnderReview' }),
            value: 'Reviewing',
          },
          {
            text: intl.formatMessage({ id: 'Registered' }),
            value: 'Registered',
          },
        ],
      },
      render: (text) => showStatus(intl)[text] || text,
    },
    RegisterTime: {
      showText: intl.formatMessage({ id: 'TimeOfRegistration' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm:ss',
      },
    },
  };
};

const DeviceList = ({ currentUser }) => {
  const { adminRoles, current } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;

  const intl = useIntl();

  const [listData, setListData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [visible, setVisible] = useState(false);
  const [TenantName, setTenantName] = useState('');
  const [DeviceSN, setDeviceSN] = useState('');
  const [currentState, setCurrentState] = useState({
    type: 'pass',
    record: {},
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
  });

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const { current, pageSize } = pagination;

    const props = {
      PageIndex: current,
      PageSize: pageSize,
      DeviceStatuses: filter,
      DeviceSN,
      TenantName,
    };

    const res = isAdmin
      ? await PostAdminAllDevices(props)
      : await PostCenterAllDevices(props);

    if (res.response.ok) {
      const { TotalCount, data = [] } = res.data || {};
      setListData(data);
      setPagination({
        ...pagination,
        total: TotalCount,
      });
    } else {
      const {
        data: { Error },
      } = res;
      message.error(Error?.Message);
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [filter, pagination.current, pagination.pageSize, DeviceSN, TenantName]);

  const option = (type, record) => {
    setVisible(true);
    setCurrentState({
      type,
      record,
    });
  };

  const tableProps = {
    listData,
    listSupport: listSupport(intl, isAdmin),
    pagination,
    loading: loading.tableLoading,
    filters: {
      Status: filter,
    },
    onTableChange(page, filt) {
      if (filt !== undefined) {
        const { Status } = filt;
        setFilter(Status);
      }

      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: isAdmin
      ? [
          {
            showText: intl.formatMessage({ id: 'detail' }),
            onClick(record) {
              history.push({
                pathname: '/system/manage/deviceDetail',
                query: {
                  registerDeviceId: record?.RegisterDeviceId,
                },
              });
            },
          },
          {
            showText: '通过',
            itemRender(record) {
              const { Status } = record;
              return Status === 'Reviewing' ? (
                <a onClick={() => option('pass', record)}>{intl.formatMessage({ id: 'pass' })}</a>
              ) : null;
            },
          },
          {
            showText: '驳回',
            itemRender(record) {
              const { Status } = record;
              return Status === 'Reviewing' ? (
                <a onClick={() => option('reject', record)}>{intl.formatMessage({ id: 'Reject' })}</a>
              ) : null;
            },
          },
          {
            showText: '注销',
            itemRender(record) {
              const { Status } = record;
              return Status === 'Registered' ? (
                <a onClick={() => option('logout', record)}>{intl.formatMessage({ id: 'Log Off' })}</a>
              ) : null;
            },
          },
        ]
      : null,
  };

  const modalProps = {
    visible,
    currentState,
    reloadPage: initData,
    onCloseModal: () => {
      setVisible(false);
    },
  };

  const exportExcel = async () => {
    setLoading({
      ...loading,
      buttonLoading: true,
    });

    const res = await PostExportExcel({
      PageIndex: 1,
      PageSize: 999,
      DeviceStatuses: filter,
      DeviceSN,
      TenantName,
    });

    if (res.response.ok) {
      const { response } = res;
      response.blob().then((blob) => {
        download(
          blob,
          `${intl.formatMessage({ id: 'Device Management List' })}+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`,
        );
      });
    } else {
      const {
        data: { Error },
      } = res;
      message.error(Error?.Message);
    }

    setLoading({
      ...loading,
      buttonLoading: false,
    });
  };

  return (
    <Card
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({ id: 'DeviceManagement' })}
        </b>
      }
      bordered={false}
      extra={
        isAdmin && (
          <Button
            type="primary"
            onClick={exportExcel}
            loading={loading.buttonLoading}
          >
          {intl.formatMessage({ id: 'Export' })}
          </Button>
        )
      }
    >
      <Space>
        <Search
          placeholder={intl.formatMessage({ id: 'EnterDeviceSN' })}
          style={{ width: 200 }}
          onSearch={(value) => setDeviceSN(value)}
        />
        {isAdmin && (
          <Search
            placeholder={intl.formatMessage({ id: 'EnterCenterName' })}
            style={{ width: 200 }}
            onSearch={(value) => setTenantName(value)}
          />
        )}
      </Space>
      <p />
      <MagicTable {...tableProps} />
      {visible && <DeviceModal {...modalProps} />}
    </Card>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(DeviceList);
