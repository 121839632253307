import getRequest from 'utils/request'

const EditBannerOrder = '/api/rocketAdmin/banner/move/{bannerId}'
const PutPublishStatus = '/api/rocketAdmin/banner/contentStatus'

export async function PublishStatus(data) {
  return getRequest()(PutPublishStatus, {
    method: 'PUT',
    data,
  })
}

export async function EditSortOrder(bannerId, newSortOrder) {
  return getRequest()(EditBannerOrder.replace('{bannerId}', bannerId), {
    method: 'PUT',
    params: { newSortOrder },
  })
}
