import React from 'react'
import { connect } from 'umi'
import { Popover, Badge } from 'antd'
import { BellOutlined } from '@ant-design/icons';
import { getNotificationCount } from 'utils/utils'
import Notification from 'components/Notification'
import iconMessageURL from 'assets/icon_message.png'
import styles from './index.less'

class NotificationPopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        getNotificationCount()
    }

    onChangeNotification = (visible) => {
        const { dispatch } = this.props
        if (visible) {
            getNotificationCount();
            dispatch({
                type: 'notification/load',
            })
        }
        dispatch({
            type: 'notification/updateState',
            payload: { visible }
        })
    }

    render() {
        const { notification } = this.props || {};
        const { visible: notificationVisible, unreadUserCount, unreadSystemCount } = notification || {};

        const allNotRead = unreadUserCount + unreadSystemCount

        return (
            <Popover
                open={notificationVisible}
                placement='bottom'
                content={
                    <Notification className={styles.notification} />
                }
                trigger="click"
                onOpenChange={this.onChangeNotification}
                className={styles.handleFixed}
                // getPopupContainer={() => document.querySelector('Header')}
            >
                <Badge count={allNotRead} overflowCount={99}>
                    <img src={iconMessageURL} className={styles.notificationIcon} />
                </Badge>
            </Popover>
        )
    }
}

export default connect(({ notification }) => ({ notification }))(NotificationPopover)