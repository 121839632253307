import React, { useState, useEffect } from 'react';
import { useIntl, useLocation } from 'umi';
import { Steps, message, Modal, Spin } from 'antd';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';

import {
  PostFindUserOfflinePlanList,
  CreateUserOfflinePlan,
} from 'services/rocketService/UserOfflinePlan';

import FinishPlanList from './FinishPlanList';

const OfflinePlanInfo = ({ location }) => {
  const intl = useIntl();

  const { id } = useLocation()?.query || {};

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activePlan, setActivePlan] = useState({});

  const { status, assessmentStatus = [] } = activePlan || {};

  const initData = async () => {
    setLoading(true);
    const res = await PostFindUserOfflinePlanList({
      maxResultCount: 1,
      skipCount: 0,
      status: ['Created', 'Started'],
      subjectId: id,
    });

    if (res.response.ok) {
      const { items = [] } = res.data;
      // 有已经安排的计划
      if (items.length) {
        const data = items[0];
        setActivePlan(data);

        const { assessmentStatus = [], status, customizedPlan } = data || {};
        if (status === 'Started') {
          // 计划进行中
          setCurrent(4);
        } else {
          const preAssessmentData = assessmentStatus.filter(
            (item) => item.assessmentType == 'PreAssessment',
          );
          if (preAssessmentData.length) {
            const { userAssessment, isFinished } = preAssessmentData[0] || {};
            const { status, needPay } = userAssessment || {};
            if (
              isFinished &&
              status === 'ReportedReady' &&
              !needPay &&
              customizedPlan
            ) {
              // 前测已经完成并获取报告并点击定制方案，跳转生成个性化方案步骤
              setCurrent(2);
            } else {
              // 未完成，跳转前测步骤
              setCurrent(1);
            }
          } else {
            // 无需做前测，没有前测和个性化方案步骤，跳转家长访谈步骤
            setCurrent(3);
          }
        }
      } else {
        setActivePlan({});
        setCurrent(0);
      }
    } else {
      const { data: { error } } = res;
      message.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  if (!Object.keys(activePlan).length && loading) {
    return (
      <div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin />
      </div>
    )
  }

  const getStepStatus = (step) => {
    if (Object.keys(activePlan).length) {
      if (current >= step) {
        return Boolean(assessmentStatus.length);
      }
      return null;
    }
    return null;
  };

  const items = [
    {
      title: (
        <span
          style={{ color: getStepStatus(0) ? '#1890FF' : 'rgba(0,0,0,0.45)' }}
        >
          {intl.formatMessage({ id: 'Arrange Plan' })}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{ color: getStepStatus(1) ? '#1890FF' : 'rgba(0,0,0,0.45)' }}
        >
          {intl.formatMessage({ id: 'pre_assessment' })}
        </span>
      ),
      icon: !getStepStatus(1) && getStepStatus(1) !== null && (
        <div
          style={{
            width: '33px',
            height: '33px',
            backgroundColor: '#f0f0f0',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <hr style={{ width: '20px' }} />
        </div>
      ),
    },
    {
      title: (
        <span
          style={{ color: getStepStatus(2) ? '#1890FF' : 'rgba(0,0,0,0.45)' }}
        >
          {intl.formatMessage({ id: 'Generate Personalized Plan' })}
        </span>
      ),
      icon: !getStepStatus(2) && getStepStatus(2) !== null && (
        <div
          style={{
            width: '33px',
            height: '33px',
            backgroundColor: '#f0f0f0',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <hr style={{ width: '20px' }} />
        </div>
      ),
    },
    {
      title: (
        <span
          style={{ color: getStepStatus(3) ? '#1890FF' : 'rgba(0,0,0,0.45)' }}
        >
          {intl.formatMessage({ id: 'parentInterviews' })}
        </span>
      ),
    },
    {
      title: (
        <span
          style={{ color: getStepStatus(4) ? '#1890FF' : 'rgba(0,0,0,0.45)' }}
        >
          {intl.formatMessage({ id: 'startPlan2' })}
        </span>
      ),
    },
  ];

  const stepOneProps = {
    onNextStep: async (postData) => {
      const res = await CreateUserOfflinePlan(postData);
      if (res.response.ok) {
        initData();
      } else {
        const { error } = res.data;
        const { code } = error || {};
        if (code === 'SubjectNotFound') {
          message.error(intl.formatMessage({ id: 'subjectNotFound' }));
        } else {
          Modal.warning({
            title: intl.formatMessage({ id: 'reminder' }),
            content: error.message,
            okText: intl.formatMessage({ id: 'know' }),
          });
        }
      }
    },
  };

  const stepTwoProps = {
    reloadPage: initData,
    activePlan,
    loading,
  };

  const stepThreeProps = {
    reloadPage: initData,
    activePlan,
    nextPage: () => {
      setCurrent(current + 1);
    },
  };

  const stepFourProps = {
    activePlan,
    prePage: () => {
      setCurrent(current - 1);
    },
    nextPage: () => {
      setCurrent(current + 1);
    },
  };

  const stepFiveProps = {
    activePlan,
    reloadPage: initData,
  };

  return (
    <>
      <br />
      {status !== 'Started' && (
        <Steps current={current} labelPlacement="vertical" items={items} />
      )}
      {current === 0 && <StepOne {...stepOneProps} />}
      {current === 1 && <StepTwo {...stepTwoProps} />}
      {current === 2 && <StepThree {...stepThreeProps} />}
      {current === 3 && <StepFour {...stepFourProps} />}
      {(current === 4 || status === 'Started') && (
        <StepFive {...stepFiveProps} />
      )}
      <div>
        <FinishPlanList />
      </div>
    </>
  );
};

export default OfflinePlanInfo;
