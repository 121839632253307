import React from 'react';
import styles from '../index.less';

const RecommendationsLine = ({
  color = '#3DBD7D',
  title,
  content,
  height = '47px',
}) => {
  return (
    <div className={styles.recommendationsLine}>
      <div
        className={styles.recommendationsLineBlock}
        style={{ backgroundColor: color, height }}
      ></div>
      <div>
        <span className={styles.recommendationsLineTitle}>
          {title ? `${title}: ` : ''}
        </span>
        {content}
      </div>
    </div>
  );
};

export default RecommendationsLine;
