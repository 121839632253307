import { history } from 'umi';
import { useEffect } from 'react';
import { Button, Modal, Table, Divider, Row, Col, Tabs } from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'
import { connect } from 'umi'
import TemplateBasicInfo from './TemplateBasicInfo'
import ProjectCards from './ProjectCards'
import SelectModal from './SelectModal'
import SortOrderModal from './SortModal'
import CopyToMultiplePlacesModal from 'components/MultipleSelection/trainingProgram'

const { confirm } = Modal

const { TabPane } = Tabs

const PlanDetail = ({ dispatch, planDetail, location }) => {

  useEffect(() => {
    dispatch({ type: 'planDetail/pageLoading', payload: location.query })
  }, [])

  const {
    list,
    pagination,
    showType,
    templateInfo,
    selectModalVisible,
    sortModalVisible,
    copyModalVisible,
    trainingProgramList,
    selectItem
  } = planDetail

  const onTabChange = key => {
    dispatch({ type: 'planDetail/tabChange', payload: key })
  }

  const onBack = () => {
    history.goBack()
  }

  const basicInfoProps = {
    templateInfo,
  }

  const selectModalProps = {
    defaultSelectKeys: selectItem ? selectItem.programId : [],
    trainingProgramList,
    selectModalVisible,
    onOk(keys) {
      dispatch({
        type: 'planDetail/onSubmitSelect',
        payload: { programId: keys },
      })
    },
    onCancel() {
      dispatch({ type: 'planDetail/updateState', payload: { selectModalVisible: false } })
    },
  }

  const sortModalProps = {
    programs: selectItem ? selectItem.programs : [],
    sortModalVisible,
    onOk(programs) {
      const programId = programs.map(item => item.id)
      dispatch({
        type: 'planDetail/onSubmitSelect',
        payload: { programId },
      })
    },
    onCancel() {
      dispatch({ type: 'planDetail/updateState', payload: { sortModalVisible: false } })
    },
  }

  const copyModalProps = {
    dayNum: selectItem ? selectItem.dayNum : 0,
    visible: copyModalVisible,
    totalCount: pagination.total,
    onOk(dayNum) {
      dispatch({
        type: 'planDetail/onSubmitCopy',
        payload: dayNum,
      })
    },
    onCancel() {
      dispatch({ type: 'planDetail/updateState', payload: { copyModalVisible: false } })
    },
  }

  const projectCardsProps = {
    list,
    pagination,
    onEdit(dayNum, programId, repeatToFinish) {
      dispatch({ type: 'planDetail/onEdit', payload: { dayNum, programId, repeatToFinish, programs: [] } })
    },
    onSort(dayNum, programs, repeatToFinish) {
      dispatch({
        type: 'planDetail/updateState',
        payload: { sortModalVisible: true, selectItem: {repeatToFinish, dayNum, programs, programId: [] } },
      })
    },
    onCopy(dayNum, programId, repeatToFinish){
      dispatch({
        type: 'planDetail/onCopy', payload: {repeatToFinish, dayNum, programId, programs: [] }
      })
    },
    onPageChange(page, pageSize) {
      const pagination = {
        current: page,
        pageSize,
      }
      dispatch({ type: 'planDetail/changeTable', payload: { pagination } })
    },
  }

  return (
    <div>
      <Row>
        <Col span={4}>
          <Button icon={<CaretLeftOutlined />} onClick={onBack}>
            Back
          </Button>
        </Col>
        <Col span={6}>
          <h1>详情页</h1>
        </Col>
      </Row>
      <Tabs activeKey={showType} onChange={onTabChange}>
        <TabPane tab="基本介绍" key="basic" />
        <TabPane tab="项目设置" key="project" />
      </Tabs>
      {showType === 'basic' ? (
        <TemplateBasicInfo {...basicInfoProps} />
      ) : (
        <ProjectCards {...projectCardsProps} />
      )}
      <SelectModal {...selectModalProps} />
      <SortOrderModal {...sortModalProps} />
      <CopyToMultiplePlacesModal {...copyModalProps}/>
    </div>
  )
}

export default connect(({ planDetail }) => ({ planDetail }))(PlanDetail)
