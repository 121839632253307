import React from 'react'
import Footer from 'cognitiveleap-core-us/components/Footer'
import { Layout } from 'antd'
import { CopyrightOutlined } from '@ant-design/icons';
import { useIntl } from 'umi'
import moment from "moment"
const { Content } = Layout

const DetailLayout = ({ children }) => {
  const intl = useIntl();

  const FooterProps = {
    links: [
      {
        key: 'HomePage',
        title: intl.formatMessage({ id: 'home' }),
        href: '/',
        blankTarget: false,
      },
      {
        key: 'Company',
        title: intl.formatMessage({ id: 'company' }),
        href: 'https://www.cognitiveleap.com/',
        blankTarget: true,
      },
    ],

    copyright: (
      <React.Fragment>
        Copyright <CopyrightOutlined /> 2017-{moment().format("YYYY")} Cognitive Leap Solutions Inc.
      </React.Fragment>
    ),
  }

  return (
    <Layout>
      <Content style={{ background: '#fff' }}>
        {children}
      </Content>
      <Footer {...FooterProps} />
    </Layout>
  )
}

export default DetailLayout
