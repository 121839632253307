import { history, connect } from 'umi';
import { useEffect } from 'react'
import DocumentTitle from 'react-document-title'
import { Button, Divider, Tabs } from 'antd'
import TemplateBasicInfo from './TemplateBasicInfo'
import ProjectCards from './ProjectCards'
import styles from '../detail.less'

const { TabPane } = Tabs

const OfflinePlanDetail = ({ dispatch, loading, offlinePlanDetail, location }) => {

  useEffect(() => {
    dispatch({ type: 'offlinePlanDetail/clear' })
    dispatch({ type: 'offlinePlanDetail/pageLoading', payload: location.query })
  }, [])

  const { list, pagination, showType, templateInfo } = offlinePlanDetail

  const onTabChange = key => {
    dispatch({ type: 'offlinePlanDetail/tabChange', payload: key })
  }

  const onBack = () => {
    history.goBack()
  }

  const basicInfoProps = {
    templateInfo,
  }

  const projectCardsProps = {
    list,
    pagination,
    onEdit(courseNum, courseWareIds) {
      dispatch({
        type: 'offlinePlanDetail/onEdit',
        payload: { courseNum, courseWareIds, programs: [] },
      })
    },
    onSort(courseNum, programs) {
      dispatch({
        type: 'offlinePlanDetail/updateState',
        payload: { sortModalVisible: true, selectItem: { courseNum, programs, courseWareIds: [] } },
      })
    },
    onCopy(courseNum, courseWareIds) {
      dispatch({
        type: 'offlinePlanDetail/onCopy',
        payload: { courseNum, courseWareIds, programs: [] },
      })
    },
    onPageChange(page, pageSize) {
      const pagination = {
        current: page,
        pageSize,
      }
      dispatch({ type: 'offlinePlanDetail/changeTable', payload: { pagination } })
    },
    onGoToDetail(courseNum, id) {
      const query = {
        templateId: templateInfo.id,
        page: courseNum,
      }
      history.push({
        pathname: '/system/center/detail/coursewareDetail',
        query: id === undefined ? query : { ...query, cid: id },
      })
    },
  }

  return (
    <DocumentTitle title="训练计划详情">
      <div>
        <div className={styles.top}>
          <Button onClick={onBack}>Back</Button>
          <span className={styles.topTitle}>
            {templateInfo != null ? templateInfo.displayName : ''}
          </span>
        </div>
        <Divider className={styles.dividerStyle} />
        <Tabs
          activeKey={showType}
          onChange={onTabChange}
          tabBarStyle={{ backgroundColor: 'white', marginBottom: 0, paddingLeft: 30 }}
        >
          <TabPane tab="基本介绍" key="basic" />
          <TabPane tab="课件" key="project" />
        </Tabs>
        <div className={styles.mainOld}>
          {showType === 'basic' ? (
            <TemplateBasicInfo {...basicInfoProps} />
          ) : (
            <ProjectCards {...projectCardsProps} />
          )}
        </div>
      </div>
    </DocumentTitle>
  )
}

export default connect(({ offlinePlanDetail, loading }) => ({ offlinePlanDetail, loading }))(
  OfflinePlanDetail
)
