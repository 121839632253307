import React, { useState, useEffect } from 'react';
import { Timeline, Row, Col, Badge, Popover, message } from 'antd';
import { PostNutritionRecord } from 'services/pdt';
import { useIntl } from 'umi';
import { getFormatDate } from 'utils/utils';
import moment from 'moment';

import styles from './index.less';

// 1 green, 2 yellow, 3 red
const COLOR_ALL = ['#52bca6', '#f8c072', '#f95d65'];
const MealList = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];

const ShowSugerText = ['Suger unknow', 'No suger', 'With suger'];
const ShowAdditiveText = ['Additives unknow', 'No additives', 'With additives'];
const ShowCoffeeText = ['Unknow Coffee', 'No Coffee', 'Has Coffee'];

const getStatus = (type, value, intl) => {
  const tempValue = parseInt(value) + 1;

  let showText;

  if (type === 'suger') {
    showText = intl.formatMessage({ id: ShowSugerText[tempValue] });
  } else if (type === 'additives') {
    showText = intl.formatMessage({ id: ShowAdditiveText[tempValue] });
  } else if (type === 'coffee') {
    showText = ShowCoffeeText[tempValue];
  }

  return showText;
};

const MealComponent = React.memo((data) => {
  const intl = useIntl();
  const {
    mealIndex,
    dishFoodDetailsDTOs,
    color: mealColor,
    hasAdditive,
    // hasCaffeine,
    hasSugar,
  } = data;
  return (
    <div className={styles.MealComponentPanel}>
      <div className={styles.MealComponentTop}>
        <span className={styles.MealComponentTitle}>
          {intl.formatMessage({ id: MealList[parseInt(mealIndex)] })}
        </span>
        <Badge color={COLOR_ALL[parseInt(mealColor) - 1]} />
      </div>

      <div className={styles.MealList}>
        {dishFoodDetailsDTOs.map((item, index) => {
          const { dishName, listFoodDTOS = [], color: dishColor } = item;
          return (
            <Popover
              key={index}
              style={{ width: 700, borderRadius: '10px' }}
              content={
                <>
                  {listFoodDTOS && listFoodDTOS.map((foodDTOS) => {
                    const {
                      foodId,
                      color: foodColor,
                      servings,
                      measurement,
                      name,
                    } = foodDTOS;
                    return (
                      <div key={foodId} className={styles.MealComponentContent}>
                        <div className={styles.ContentTop}>
                          <Badge color={COLOR_ALL[parseInt(foodColor) - 1]} />
                          <span className={styles.ContentTitle}>{name}</span>
                        </div>
                        <div className={styles.ContentList}>
                          <div>
                            {servings}&nbsp;
                            {intl.formatMessage({
                              id: servings == 1 ? 'serving' : 'servings',
                            })}
                            &nbsp; ({measurement})
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className={styles.sugerOrAdd}>
                    <div>{getStatus('suger', hasSugar, intl)}</div>
                    <div>{getStatus('additives', hasAdditive, intl)}</div>
                    {/* {hasCaffeine && (
                      <div>{getStatus('coffee', hasCaffeine)}</div>
                    )} */}
                  </div>
                </>
              }
              title={null}
              trigger="hover"
              placement="topLeft"
            >
              <div className={styles.mealItem}>
                <Badge
                  color={COLOR_ALL[parseInt(dishColor - 1)]}
                  text={
                    <span style={{ color: COLOR_ALL[parseInt(dishColor - 1)] }}>
                      {dishName}
                    </span>
                  }
                />
              </div>
            </Popover>
          );
        })}
      </div>
    </div>
  );
});

const ShowComponent = React.memo((data) => {
  const { createTime, webMealDetailDTOs } = data;
  return (
    <div className={styles.ShowComponentPanel}>
      <div className={styles.componentTitle}>
        {moment(createTime).format('YYYY/MM/DD')}
      </div>
      <div className={styles.mealsList}>
        <Row className={styles.NutritionPdtDes}>
          {webMealDetailDTOs
            .sort((a, b) => a.mealIndex - b.mealIndex)
            .map((item) => {
              const { mealIndex } = item;
              return (
                <Col span={6} key={mealIndex}>
                  <MealComponent {...item} />
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
});

const NutritionPdfDailyRecording = ({ id, week, startDay, endDay }) => {
  const intl = useIntl();

  const [recordData, SetRecordData] = useState([]);

  const initData = async () => {
    const res = await PostNutritionRecord(
      {
        userId: id,
      },
      {
        startDate: getFormatDate(startDay),
        endDate: getFormatDate(endDay),
      },
    );

    if (res.response.ok) {
      SetRecordData(res.data.data);
    } else {
      const { msg } = res.data;
      message.error(msg);
    }
  };

  useEffect(() => {
    initData();
  }, [week]);

  return (
    <div className={styles.NutritionRecordingPanel}>
      {recordData.length > 0 ? (
        <Timeline>
          {recordData.map((recordData, index) => {
            return (
              <Timeline.Item key={index}>
                <ShowComponent {...recordData} />
              </Timeline.Item>
            );
          })}
        </Timeline>
      ) : (
        <div>{intl.formatMessage({ id: 'emptyData' })}</div>
      )}
    </div>
  );
};

export default NutritionPdfDailyRecording;
