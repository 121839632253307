import { useIntl } from 'umi'

const PoweredBy = ({ styles }) => {
  const intl = useIntl();
  return (
    <div className={styles.powered_by}>
      <p>{intl.formatMessage({ id: 'poweredBy' })}</p>
    </div>
  )
}

export default PoweredBy
