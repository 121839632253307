import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
  GetPrepareEdit,
  UpdateOfflineRules,
} from 'services/rocketService/Prepare'

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

export default {
  namespace: 'offlinePlanDetail',
  state: {
    templateInfo: null,
    templateId: null,
    list: [],
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
    //'basic'/'project'
    showType: 'basic',
    selectModalVisible: false,
    sortModalVisible: false,
    copyModalVisible: false,
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },

    clear(state) {
      const defaultPagination = {
        current: 1,
        pageSize: 12,
        total: 0
      }
      const payload = { pagination: defaultPagination, list: [], templateInfo: null, showType: 'basic' }
      return {
        ...state,
        ...payload,
      }
    }
  },
  effects: {
    *pageLoading({ payload }, { take, put, call, select }) {
      const templateId = payload.templateId || null
      yield put({
        type: 'updateState',
        payload: { templateId },
      })
      const { showType } = yield select(state => state.offlinePlanDetail)
      if (showType === 'basic') {
        yield put({ type: 'queryInfo' })
      } else {
        yield put({ type: 'queryList' })
      }
    },

    *tabChange({ payload }, { take, put, call, select }) {
      yield put({
        type: 'updateState',
        payload: { showType: payload },
      })
      if (payload === 'basic') {
        yield put({ type: 'queryInfo' })
      } else if (payload === 'project') {
        yield put({ type: 'queryList' })
      }
    },

    *queryInfo(_, { take, put, call, select }) {
      const { templateId } = yield select(state => state.offlinePlanDetail)
      const res = yield call(GetPrepareEdit, templateId, 'offlinePlan')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { templateInfo: res.data },
        })
      }
    },

    *queryList(_, { take, put, call, select }) {
      const { pagination, templateId } = yield select(state => state.offlinePlanDetail)
      let query = { skipCount: 0, maxResultCount: 10, templateId }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'offlineRules')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'queryList' })
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/detail/offlinePlanDetail') {
    //       dispatch({ type: 'clear' })
    //       dispatch({ type: 'pageLoading', payload: location.query })
    //     }
    //   })
    // },
  },
}
