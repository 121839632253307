import { PostCenterStatistics, GetStatistic } from 'services/vrat/testCenter'
import {
  GetTestResourceAmount
} from 'services/vrat'

export default {
  namespace: 'statistics',
  state: {
    listCenterStatistics: [],
    SearchField: null,
    TestStartTime: null,
    TestEndTime: null,
    ReportStartTime: null,
    ReportEndTime: null,
    pageIndex: 1,
    pageSize: 12,
    total: 0,
    VRATAmount: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *getTestResourceAmount({ payload }, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser
      const { id } = current
      const res = yield call(GetTestResourceAmount, { tenantId: id })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            VRATAmount: res.data
          }
        })
      }
    },

    *fetchStatistics(_, { call, put, select, take }) {
      const { SearchField, TestStartTime, TestEndTime, pageIndex, pageSize, ReportStartTime, ReportEndTime } = yield select(
        state => state.statistics
      )
      var res = yield call(
        PostCenterStatistics,
        { pageIndex, pageSize },
        { SearchField, TestStartTime, TestEndTime, ReportStartTime, ReportEndTime }
      )
      const { data, response } = res
      if (response.ok) {
        const { Items, TotalCount } = data
        yield put({
          type: 'updateState',
          payload: { listCenterStatistics: Items, total: TotalCount },
        })
      }
    },

    *downloadStatistic(_, { take, put, call, select }) {
      const { SearchField, TestStartTime, TestEndTime } = yield select(state => state.statistics)
      yield call(GetStatistic, { SearchField, TestStartTime, TestEndTime })
    },

    *updateTimePicker({ payload: filter }, { take, put, call, select }) {
      yield put({ type: 'updateState', payload: { filter } })
      yield put({ type: 'fetchStatistics' })
    },

    *filterAndFetch({ payload: filter }, { take, put, call, select }) {
      yield put({ type: 'updateState', payload: filter })
      yield put({ type: 'fetchStatistics' })
    },

    *clearAllFilterAndSorter(_, { take, put, call, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
      const payload = { pagination: defaultPagination, filter: null }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'fetchStatistics' })
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/statistic/vratStatistic') {
    //       dispatch({ type: 'fetchStatistics' })
    //       dispatch({ type: 'getTestResourceAmount' })
    //     }
    //   })
    // },
  },
}
