import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
  GetPrepareEdit,
  UpdateOfflineRules,
} from 'services/rocketService/Prepare'

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

export default {
  namespace: 'adminOfflinePlanDetail',
  state: {
    templateInfo: null,
    templateId: null,
    list: [],
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
    trainingProgramList: [],
    //'basic'/'project'
    showType: 'basic',
    selectModalVisible: false,
    sortModalVisible: false,
    copyModalVisible: false,
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *pageLoading({ payload }, { take, put, call, select }) {
      const templateId = payload.templateId || null
      yield put({
        type: 'updateState',
        payload: { templateId },
      })
      const { showType } = yield select(state => state.adminOfflinePlanDetail)
      if (showType === 'basic') {
        yield put({ type: 'queryInfo' })
      } else {
        yield put({ type: 'queryList' })
      }
    },

    *tabChange({ payload }, { take, put, call, select }) {
      yield put({
        type: 'updateState',
        payload: { showType: payload },
      })
      if (payload === 'basic') {
        yield put({ type: 'queryInfo' })
      } else if (payload === 'project') {
        yield put({ type: 'queryList' })
      }
    },

    *queryInfo(_, { take, put, call, select }) {
      const { templateId } = yield select(state => state.adminOfflinePlanDetail)
      const res = yield call(GetPrepareEdit, templateId, 'offlinePlan')
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { templateInfo: res.data },
        })
      }
    },

    *queryList(_, { take, put, call, select }) {
      const { pagination, templateId } = yield select(state => state.adminOfflinePlanDetail)
      let query = { skipCount: 0, maxResultCount: 10, templateId }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'offlineRules')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'queryList' })
    },

    *onEdit({ payload: data }, { call, put, select }) {
      const res = yield call(
        GetPrepareList,
        { SkipCount: 0, MaxResultCount: 1000 },
        'trainingCourse'
      )
      if (res.response.ok) {
        const { totalCount, items } = res.data
        yield put({
          type: 'updateState',
          payload: {
            selectModalVisible: true,
            trainingProgramList: items,
            selectItem: data,
          },
        })
      }
    },

    *onCopy({ payload: data }, { call, put, select }) {
      const res = yield call(
        GetPrepareList,
        { SkipCount: 0, MaxResultCount: 1000 },
        'trainingCourse'
      )
      if (res.response.ok) {
        const { totalCount, items } = res.data
        yield put({
          type: 'updateState',
          payload: {
            copyModalVisible: true,
            trainingProgramList: items,
            selectItem: data,
          },
        })
      }
    },

    *onSubmitSelect({ payload: data }, { call, put, select }) {
      const { selectItem, templateId } = yield select(state => state.adminOfflinePlanDetail)
      const putData = {
        offlineTrainingPlanId: templateId,
        trainingPlanRules: [
          {
            courseNum: selectItem.courseNum + 1,
            courseWareId: data.courseWareId,
          },
        ],
      }
      const res = yield call(UpdateOfflineRules, putData)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            sortModalVisible: false,
            selectModalVisible: false,
            trainingProgramList: [],
            selectItem: null,
          },
        })
        yield put({ type: 'queryList' })
      }
    },

    *onSubmitCopy({ payload: data }, { call, put, select }) {
      const { selectItem, templateId } = yield select(state => state.adminOfflinePlanDetail)
      let putData = {
        offlineTrainingPlanId: templateId,
        trainingPlanRules: [],
      }
      const courseWareId = selectItem.courseWareIds.map(item => item.id)
      data.courseNum.forEach(course => {
        putData.trainingPlanRules.push({
          courseNum: parseInt(course) + 1,
          courseWareId: courseWareId,
        })
      })
      const res = yield call(UpdateOfflineRules, putData)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            sortModalVisible: false,
            selectModalVisible: false,
            copyModalVisible: false,
            trainingProgramList: [],
            selectItem: null,
          },
        })
        yield put({ type: 'queryList' })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/offlinePlanDetail') {
    //       dispatch({ type: 'pageLoading', payload: location.query })
    //     }
    //   })
    // },
  },
}
