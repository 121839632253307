import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { message } from 'antd'
import { getDvaApp } from 'umi';
import moment from 'moment'
import {
  GetTenantInfo,
  GetTenantGrant,
  UpdateTenantGrant,
  PutRemider,
  PutRemiderValue,
  GetTrainingPlanV2,
  PostTrainingPlanV2
} from 'services/tenant'
import { getGrantSchool, grantSchool } from 'services/school'
import {
  PostTestResource,
  GetTestResource,
  GetTestResourceAmount,
  ForbidVratAmount,
  GetMonthChildren
} from 'services/vrat'
import { PrepareEditUser, PostUser, PostUserList, PostSendEmail, DelAdminUser } from 'services/userManager'
import {
  NewTenantContract,
  GetContractList,
  StopContract,
  DeleteContract,
  PostRecharge,
  PostSendMessage,
  PostCheckCode,
  EditTenantContract,
  GetSubDataById,
  EditEvaluation,
  GetAssessmentResource,
  PostAssessmentResource,
  DeleteAssessmentResource,
  GetCourseResource,
  GetTrainResResource,
  PostCourseResource,
  PostPlanV2Resource,
  PostTrainResourceApi,
  PostStartHfs,
  PutRemark,
  PostTrainingPlanV2Pre,
  GetPlanResource,
  PutPlanV2Resource,
  PutHfsContract
} from 'services/contract'
import { CenterDetail, AllowQuesionnaire } from 'services/vrat/cms'
import {
  PostCreateOrUpdateLocation,
  GetLocations,
  GetLocationDetail,
} from 'services/vrat/testLocation'
import { GetTemplateOfCenters, DeleteTemplateOfCenter } from 'services/adhd';
import {
  GetTrainingModule,
  GetSelectTenantTrainingModule,
  PostSelectTenantTrainingModule,
  GetTrainingCoursesByModule,
  PostTrainingModuleResource,
  PutTenantTrainingModule,
  PostStartTenantTrainingModule,
  PostEndTenantTrainingModule
} from 'services/trainingSystem'
import { hfsContractTime, hfsCodeTime } from 'utils/config'
import { deepCopy, addOptionsToProperties } from 'cognitiveleap-core-us/utils/utils';
import { GetAdminStaffList } from 'services/trainingSystem'

const contentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Dropdown',
        Value: 'Number',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'Add by quantity' }),
              Id: 'Number',
            },
            {
              Value: intl.formatMessage({ id: 'unlimited quantity' }),
              Id: 'Time',
            },
            {
              Value: intl.formatMessage({ id: 'Monthly' }),
              Id: 'Monthly',
            }
          ],
          onchange: (value) => {
            getDvaApp()._store.dispatch({
              type: 'centerDetail/onchangeVRATMountType',
              payload: {
                type: value,
                intl
              }
            })
          },
          Required: true,
        },
        ShowTitle:  intl.formatMessage({ id: 'Billing method' }),
        FormKey: 'BillingMethod',
        Description: null,
      },
      {
        EditorType: 'RangePicker',
        Value: null,
        Setting: {
          IsVisible: true,
          Required: true,
          format: 'YYYY/MM/DD',
          showTime: false,
          DateType: '',
          disabledDate: (current) => {
            return current && current < moment().add(-1, 'd')
          }
        },
        ShowTitle: intl.formatMessage({ id: 'availablePeriod' }),
        FormKey: 'validity',
        Description: intl.formatMessage({ id: 'dateDes' })
      },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'Valid Until/Start' }),
        FormKey: 'validitys',
        AdditionalData: null,
        Value: null,
        Setting: {
            IsVisible: false,
            Required: true,
            DateType: 'date',
            DisabledDate: (current) => {
              return current && current < moment().add(-1, 'd')
            }
        },
        Description: null,
    },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'vCAT_Amount' }),
        FormKey: 'TestAmount',
        AdditionalData: null,
        Value: 1,
        Setting: {
          Required: true,
          Disabled: false,
          NumberSetting: {
            min: 1,
            step: 1
          }
        },
        Description: intl.formatMessage({ id: 'less than' }),
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'Comments' }),
        FormKey: 'Remark',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: null,
      },
    ]
  }

}

const defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
}

const AddContentMenu = (intl) => {

  return {
    Properties: [
      {
        EditorType: 'EmailSelect',
        ShowTitle: intl.formatMessage({ id: 'email' }),
        FormKey: 'email',
        AdditionalData: null,
        Value: null,
        Description: intl.formatMessage({ id: 'Add up to members' }),
        Setting: {
          Required: true,
          // Rules: [
          //   {
          //     pattern:
          //       /^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/,
          //     message: '请输入有效邮箱',
          //   },
          // ],
          DropdownOptions: []
        },
      },
      {
        EditorType: 'Dropdown',
        ShowTitle: intl.formatMessage({ id: 'roleName' }),
        FormKey: 'roleName',
        AdditionalData: null,
        Value: null,
        Setting: {
          // Disabled: true,
          DropdownOptions: [],
          Required: true,
        },
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'comments' }),
        FormKey: 'remark',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: intl.formatMessage({ id: 'Notes will be added' }),
      }
    ],
  }
}

const editRemarkContentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'Comments' }),
        FormKey: 'remark',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
        },
        Description: null,
      },
    ]
  }
}

const hfsReminderModalContentMenu = {
  Properties: [
    {
      EditorType: 'NumberInput',
      ShowTitle: '提醒临界金额($)',
      FormKey: 'HfsReminderAmount',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    },
  ]
}

const reminderValueModalContentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'Reminder value' }),
        FormKey: 'vratReminderAmount',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          NumberSetting: {
            min: 0,
            step: 1
          },
        },
        Description: null,
      },
    ]
  }

}

const contractContentMenu = (intl) =>  {
  return {
    Properties: [
      {
        EditorType: 'RangePicker',
        Value: null,
        Setting: {
          Required: true,
          format: 'YYYY/MM/DD',
          showTime: false
        },
        ShowTitle: intl.formatMessage({ id: 'availablePeriod' }),
        FormKey: 'validity',
        Description:  intl.formatMessage({ id: 'dateDes' })
      },
      // {
      //   EditorType: 'NumberInput',
      //   ShowTitle: '提醒临界金额($)',
      //   FormKey: 'reminderAmount',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     Required: true,
      //     NumberSetting: {
      //       min: 0,
      //       step: 1
      //     },
      //   },
      //   Description: null,
      // },
      // {
      //   EditorType: 'NumberInput',
      //   ShowTitle: '充值($)',
      //   FormKey: 'amount',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     Required: true,
      //     Disabled: false,
      //     NumberSetting: {
      //       min: 0,
      //       step: 1
      //     },
      //   },
      //   Description: null,
      // },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'Comments' }),
        FormKey: 'remark',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: null,
      },
    ]
  }

}

const rechargeContentMenu = {
  Properties: [
    {
      EditorType: 'Dropdown',
      Value: 'recharge',
      Setting: {
        DropdownOptions: [
          {
            Value: '充值',
            Id: 'recharge',
          },
          {
            Value: '扣款',
            Id: 'deduction',
          },
        ],
        Required: true,
      },
      ShowTitle: '充值/扣款',
      FormKey: 'recharge',
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '邮箱提醒中心管理员',
      FormKey: 'noticeTenantEmail',
      AdditionalData: null,
      Value: true,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '邮箱提醒Shion',
      FormKey: 'noticeAdminEmail',
      AdditionalData: null,
      Value: true,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '金额($)',
      FormKey: 'money',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'remark',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    }
  ]
}

const rechargeExpiredContentMenu = {
  Properties: [
    {
      EditorType: 'Dropdown',
      Value: 'deduction',
      Setting: {
        DropdownOptions: [
          {
            Value: '扣款',
            Id: 'deduction',
          },
        ],
        Required: true,
      },
      ShowTitle: '扣款',
      FormKey: 'recharge',
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '邮箱提醒中心管理员',
      FormKey: 'noticeTenantEmail',
      AdditionalData: null,
      Value: true,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '邮箱提醒Shion',
      FormKey: 'noticeAdminEmail',
      AdditionalData: null,
      Value: true,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '金额($)',
      FormKey: 'money',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'remark',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    }
  ]
}

const evaluationContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '名称',
      FormKey: 'resourceName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
        Disabled: true
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '单价($)',
      FormKey: 'price',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    }
  ]
}

const oneToMoreContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '名称',
      FormKey: 'resourceName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
        Disabled: true
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '线上单价($)',
      FormKey: 'priceup',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '线下单价($)',
      FormKey: 'pricedown',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    }
  ]
}

const trainContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '名称',
      FormKey: 'resourceName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
        Disabled: true
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '单价($)',
      FormKey: 'price',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    }
  ]
}

const remarkContentMenu = {
  Properties: [
    {
      EditorType: 'NumberInput',
      ShowTitle: '单价($)',
      FormKey: 'money',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'remark',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false
      },
      Description: null,
    }
  ]
}

const curriculumContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '名称',
      FormKey: 'resourceName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
        Disabled: true
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '单价($)',
      FormKey: 'price',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    }
  ]
}

const courseValidityContentMenu = {
  Properties: [
    {
      EditorType: 'NumberInput',
      ShowTitle: '课程有效期(天)',
      FormKey: 'validDate',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    }
  ]
}

const addTrainingPlacesContentMenu = {
  Properties: [
    {
      EditorType: 'NumberInput',
      ShowTitle: '培训名额',
      FormKey: 'numberOfPlaces',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1
        },
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'remark',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false
      },
      Description: null,
    }
  ],

}

const modulePriceContentMenu = {
  Properties: [
    {
      EditorType: 'NumberInput',
      ShowTitle: '自助缴费单价($)',
      FormKey: 'price',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 2
        },
      },
      Description: null
    }
  ]
}

export default {
  namespace: 'centerDetail',
  state: {
    centerInfo: null,
    currentTab: 'Database',
    showList: [],
    modalVisible: false,
    vratCenterInfo: null,
    currentLocation: null,
    newLocationVisible: false,
    newVRATAmountModalVisible: false,
    VRATAmountModalContent: null,
    editRemarkContent: null,
    editMonthRemarkContent: null,
    VRATAmountTablepagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    VRATAmountList: [],
    VRATAmount: { LastAmount: 0, UsedAmount: 0 },
    schoolModalVisible: false,
    schoolListPagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    schoolList: [],
    grantTenantSchool: [],
    hfsContractLocked: false,  //hfsContract是否解锁
    canSendCode: true,   //hfsContract是否可以发送验证码
    hfsModalEditOrAddType: 'edit',   //合同弹框类型(编辑/添加)
    selectHfsItem: null,  // 当前被编辑的记录
    hfsModalType: '',  // HFS弹框类型
    hfsModalContent: null,  // HFS弹框内容
    hfsReminderModalContent: null, // 临界金额弹框内容
    reminderValueModalContent: null, // 提醒值弹窗内容
    hfsModalVisible: false,  // HFS弹框是否展示
    contractPagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showTotal: (total) => `总共 ${total} 条`
    },
    hfsContractTime: hfsContractTime,
    contractTimer: null,
    contractTableList: [],
    selectHfsModalVisible: false,
    expandedRowKeys: [],
    statisticsHfsData: null,
    hfsCodeTime: hfsCodeTime,   // 获取验证码倒计时
    hfsCodeTimer: null,
    trainingCoursesTabs: [],
    trainingCoursesList: [],
    trainingCoursesCurrentTab: '',
    trainingCoursesInfo: null,
    trainingCoursesEditModalVisible: false,
    trainingCoursesModalContent: null,
    trainingCoursesModalType: '',
    trainingCoursesDrawerVisible: false,
    visible: false, // 修改临界金额的弹窗
    remiderVisible: false, // 修改提醒值弹窗
    remarkMonthVisible: false,// 修改月订阅提醒值弹窗
    remarkVisible: false, // 修改备注弹窗
    oneToMoreTableData: [], // 一对多的表格数据
    theraLeapListData: [],
    templateModalVisible: false,
    monthList: {},
    addNewEmployeeModalVisible: false,
    confirmModalVisible: false,
    staffList: [],
    activeStatus: '',
    filters: {},
    roleNameOptions: [],
    list: [],
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage({ payload }, { call, put, take, select }) {
      const { currentTab } = yield select(state => state.centerDetail)
      const { currentUser } = yield select(state => state.user)
      const { adminRoles: [{name} = {}] = [] } = currentUser || {}
      const res = yield [call(GetTenantInfo, payload.id), call(CenterDetail, payload.id)]
      let newCurrentTab = currentTab
      if (res[0].response.ok && res[1].response.ok) {
        const centerInfo = res[0].data
        const { projectStatus, chargingMethod } = centerInfo
        if (projectStatus && Array.isArray(projectStatus)) {
          const vratStatusInfo = projectStatus.find(item => item.name === "Project.Vrat")
          const rocketStatusInfo = projectStatus.find(item => item.name === "Project.Rocket")
          const vratStatus = vratStatusInfo && vratStatusInfo.status
          const rocketStatus = rocketStatusInfo && rocketStatusInfo.status
          if ((!vratStatus && currentTab === 'VRAT_Amount') || (!(rocketStatus && chargingMethod === 'Contract') && currentTab === 'HFS_Contract')) {
            newCurrentTab = 'Database'
          }
          // 子代理商需求
          if(name === 'agent') {
            newCurrentTab = 'TheraLeap'
          }
        }
        yield put({
          type: 'updateState',
          payload: {
            centerInfo,
            vratCenterInfo: res[1].data,
            currentTab: newCurrentTab
          },
        })
        yield put({
          type: 'changeTab',
          payload: newCurrentTab
        })
      }
    },

    *changeTab({ payload }, { call, put, take, select }) {
      if (payload !== 'HFS_Contract') {
        yield put.resolve({ type: 'clearHfsTimer' })
      }
      yield put({
        type: 'updateState',
        payload: { currentTab: payload, showList: [], contractTableList: [] },
      })
      switch (payload) {
        case 'Database':
        case 'Course':
        case 'Assessment':
        case 'OfflineTrainingPlan':
          yield put({ type: 'loadList' })
          break
        case 'VRAT_Amount':
          yield put({ type: 'getVRATAmountList' })
          yield put({ type: 'getTestResourceAmount' })
          break
        case 'School':
          yield put({ type: 'getSchoolList' })
          break
        case 'TrainingPlanLibrary':
          yield put({ type: 'getTrainingPlanLibraryList' })
          break
        case 'HFS_Contract':
          const { contractTimer, hfsCodeTimer, hfsContractLocked } = yield select(state => state.centerDetail)
          const time = localStorage.getItem('hfsContractTime')
          const localHfsCodeTime = localStorage.getItem('hfsCodeTime')
          const diffTime = moment(time).diff(moment(), 'seconds')
          const diffHfsCodeTime = moment(localHfsCodeTime).diff(moment(), 'seconds')
          yield put({ type: 'updateState', payload: { expandedRowKeys: [] } })
          if (diffTime > 1) {
            if (!contractTimer) yield put({ type: 'createContractTimer' })
            if (hfsCodeTimer) clearInterval(hfsCodeTimer)
            yield put({ type: 'updateState', payload: { hfsContractTime: diffTime, hfsCodeTimer: null, canSendCode: true, hfsCodeTime } })
            yield put({ type: 'getContractList' })
          } else {
            if (diffHfsCodeTime > 1) {
              yield put({ type: 'updateState', payload: { canSendCode: false, hfsCodeTime: diffHfsCodeTime } })
              if (!hfsCodeTimer) yield put({ type: 'createHfsCodeTimer' })
            } else {
              yield put({ type: 'updateState', payload: { canSendCode: true, hfsCodeTime } })
            }
          }
          break
        case 'TrainingCourses':
          yield put({ type: 'getTrainingCoursesList' })
          break
        case "TheraLeap":
          yield put({ type: 'getTheraLeapList' })
          break
        case 'TeamManagement':
          yield put({ type: 'getList' })
          break
      }
    },

    *getTheraLeapList(_, { call, put, take, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const { id: TenantId } = centerInfo

      const theraLeapRes = yield call(GetTemplateOfCenters, { TenantId, SkipCount: 0, MaxResultCount: 999 })
      if (theraLeapRes.response.ok) {
        const { items } = theraLeapRes.data
        yield put({
          type: 'updateState',
          payload: {
            theraLeapListData: items,
          }
        })
      }
    },

    *deleteTemplete({ payload: { record } }, { call, put, take, select }) {
      const { id } = record || {};
      const { centerInfo } = yield select(state => state.centerDetail)
      const { id: tenantId } = centerInfo

      const deleteRes = yield call(DeleteTemplateOfCenter, { tenantId, templateId: id })
      if (deleteRes.response.ok) {
        yield put({ type: 'getTheraLeapList' })
      }
    },

    *getTrainingCoursesList(_, { call, put, take, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const { id: tenantId } = centerInfo
      const resList = yield [call(GetSelectTenantTrainingModule, { tenantId }), call(GetTrainingModule, { SkipCount: 0, MaxResultCount: 999 })]

      if (resList.every(item => item.response.ok)) {
        const items = resList[0].data.map(item => {
          const { trainingModule } = item || {}
          return {
            ...item,
            ...trainingModule
          }
        })
        const allTrainingCoursesList = resList[1].data.items;

        yield put({
          type: 'updateState',
          payload: {
            trainingCoursesTabs: items,
            trainingCoursesList: allTrainingCoursesList,
            trainingCoursesCurrentTab: items.length > 0 ? items[0] && items[0].name : ''
          }
        })
        yield put({ type: 'getTrainingCoursesByModule' })
      }
    },

    *clearHfsTimer({ payload }, { call, put, take, select }) {
      const { contractTimer, hfsCodeTimer } = yield select(state => state.centerDetail)
      clearInterval(contractTimer)
      clearInterval(hfsCodeTimer)
      yield put({ type: 'updateState', payload: { contractTimer: null, hfsCodeTimer: null } })
    },

    *onEditLocation({ payload: id }, { call, put, select }) {
      const res = yield call(GetLocationDetail, { id })
      const { data, response } = res
      if (response.ok) {
        yield put({
          type: 'updateState',
          payload: { currentLocation: data, newLocationVisible: true },
        })
      }
    },

    *addLocation({ payload: data }, { call, put, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const postData = { ...data, centerId: centerInfo.id }
      const res = yield call(PostCreateOrUpdateLocation, postData)
      const { data: resData, response } = res
      if (response.ok) {
        yield put({
          type: 'updateState',
          payload: { currentLocation: null, newLocationVisible: false },
        })
        yield put({ type: 'loadPage', payload: { id: centerInfo.id } })
      } else {
        if (resData.Email) {
          message.error(resData.Email[0])
        }
      }
    },

    *loadList(_, { call, put, take, select }) {
      const { currentTab, centerInfo } = yield select(state => state.centerDetail)
      let type = currentTab;
      switch (currentTab) {
        case "Course":
        case "Database":
          type = 'Course'
          break
      }
      if (currentTab === 'TrainingPlanLibrary') {
        yield put({ type: 'getTrainingPlanLibraryList' })
      } else {
        const res = yield call(GetTenantGrant, centerInfo.id, type)

        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: { showList: res.data },
          })
        }
      }
    },

    *getTrainingPlanLibraryList(_, { call, put, take, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const { id: TenantId } = centerInfo
      const res = yield call(GetTrainingPlanV2, { TenantId })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { showList: res.data.grantTenantResources },
        })
      }
    },

    *editGrantList({ payload }, { call, put, take, select }) {
      const { currentTab, centerInfo, showList } = yield select(state => state.centerDetail)

      if (currentTab === 'VRAT_Assessment') {
        const res = yield call(AllowQuesionnaire, {
          QuestionnaireIds: payload,
          CenterId: centerInfo.id,
        })
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: { modalVisible: false },
          })
          yield put({ type: 'loadPage', payload: { id: centerInfo.id } })
        }
      } else if (currentTab == 'TrainingCourses') {
        const res = yield call(PostSelectTenantTrainingModule, {
          moduleIds: payload,
          tenantId: centerInfo.id,
        })
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: { modalVisible: false },
          })
          yield put({ type: 'getTrainingCoursesList' })
        }
      } else {
        let res
        let type = currentTab;
        let resourceKeys = payload
        switch (currentTab) {
          case "Course":
            const hasGrantedCourse = showList.filter(item => item.isGranted && item.resource.type !== currentTab)
            const hasGrantedCourseKeys = hasGrantedCourse.map(item => item.resource.id)
            resourceKeys = resourceKeys.concat(hasGrantedCourseKeys)
            type = 'Course'
            break
          case "Database":
            const hasGrantedDatabase = showList.filter(item => item.isGranted && item.resource.type !== 'TrainingMaterials')
            const hasGrantedDatabaseKeys = hasGrantedDatabase.map(item => item.resource.id)
            resourceKeys = resourceKeys.concat(hasGrantedDatabaseKeys)
            type = 'Course'
            break
        }
        if (currentTab === 'TrainingPlanLibrary') {
          res = yield call(PostTrainingPlanV2, {
            grantList: resourceKeys,
            tenantId: centerInfo.id
          })
        } else {
          res = yield call(UpdateTenantGrant, type, {
            tenantId: centerInfo.id,
            resourceKeys,
          })
        }

        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: { modalVisible: false },
          })
          yield put({ type: 'loadList' })
        }
      }
    },

    *newVRATAmount({ payload }, { call, put, take, select }) {
      const { intl } = payload || {}
      let emptyContent = { ...contentMenu(intl) }
      emptyContent.Properties[3].Setting.Disabled = false
      yield put({
        type: 'updateState',
        payload: { VRATAmountModalContent: emptyContent, newVRATAmountModalVisible: true },
      })
    },

    *getTestResourceAmount({ payload }, { call, put, take, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const { id } = centerInfo
      const res = yield call(GetTestResourceAmount, { tenantId: id })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            VRATAmount: res.data
          }
        })
      }
    },

    *getMonthChildrenList({ payload }, {call,put, take, select }) {
      const { monthList } = yield select(state => state.centerDetail)
      const { Id } = payload || {}
      const params = {
        SkipCount: 0,
        MaxResultCount: 1000,
        TestResourceId: Id,
      };
      const res = yield call(GetMonthChildren, params) ;
      if (res.response.ok) {
        const { Items } = res.data || {};
        yield put({
          type: 'updateState',
          payload: {
            monthList: { ...monthList, [Id]: Items }
          }
        })
      } else {
        const { error } = res.data;
        message.error(error.message);
      }
    },

    *getVRATAmountList({ payload }, { call, put, take, select }) {
      const { VRATAmountTablepagination, centerInfo } = yield select(state => state.centerDetail)
      const { id } = centerInfo
      const { pageSize, current } = VRATAmountTablepagination
      const params = {
        MaxResultCount: pageSize,
        SkipCount: (current - 1) * pageSize,
        TenantId: id
      }
      const res = yield call(GetTestResource, params)
      if (res.response.ok) {
        const { TotalCount, Items } = res.data
        yield put({
          type: 'updateState',
          payload: {
            VRATAmountTablepagination: { ...VRATAmountTablepagination, total: TotalCount },
            VRATAmountList: Items
          }
        })
      }
    },

    *onSubmitVRATAmountModal({ payload }, { call, put, take, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const params = {
        Remark: payload.Remark,
        BillingMethod: payload.BillingMethod,
        StartTime:  payload.BillingMethod !== 'Monthly' ? payload.validity[0] :  moment(payload.validitys).startOf('day').format(),
        EndTime: payload.BillingMethod !== 'Monthly'? payload.validity[1] : moment(payload.validitys).startOf('day').format(),
        TestAmount: payload.TestAmount,
        TenantId: centerInfo && centerInfo.id
      }

      const res = yield call(PostTestResource, params)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { newVRATAmountModalVisible: false }
        })
        yield put({ type: 'getVRATAmountList' })
        yield put({ type: 'getTestResourceAmount' })
      } else {
          const { Error, error } = res.data || {};
          message.error(Error.Message || error.message );
      }
    },

    *onchangeVRATMountType({ payload }, { call, put, take, select }) {
      const { VRATAmountModalContent } = yield select(state => state.centerDetail);
      const { type, intl } = payload || {}
      let content = deepCopy(VRATAmountModalContent)
      content.Properties[3].Setting.Disabled = type === "Time"
      if (type === 'Monthly') {
        content.Properties[1].Setting.IsVisible = false
        content.Properties[2].Setting.IsVisible = true
        content.Properties[3].Description = intl.formatMessage({ id: 'less than monthly' })
      } else {
        content.Properties[1].Setting.IsVisible = true
        content.Properties[2].Setting.IsVisible = false
        content.Properties[3].Description = intl.formatMessage({ id: 'less than' })
      }
      yield put({
        type: 'updateState',
        payload: { VRATAmountModalContent: content },
      })
    },

    *changeVRATAmountTable({ payload: data }, { call, put, take, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { VRATAmountTablepagination: pagination } })
      yield put({ type: 'getVRATAmountList' })
    },

    *forbidVratAmount({ payload: { record } }, { call, put, take, select }) {
      const { Id } = record
      const parmas = {
        resourceId: Id
      }
      const res = yield call(ForbidVratAmount, parmas)
      if (res.response.ok) {
        yield put({ type: 'getVRATAmountList' })
        yield put({ type: 'getTestResourceAmount' })
      }
    },

    *onSelectSchoolOk({ payload }, { call, put, take, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const params = {
        tenantId: centerInfo.id,
        grantList: payload
      }
      const res = yield call(grantSchool, params)
      if (res.response.ok) {
        message.success('授权成功')
        yield put({
          type: 'updateState',
          payload: {
            schoolModalVisible: false
          }
        })
        yield put({ type: 'getSchoolList' })
      } else {
        message.success('授权失败')
      }

    },

    *getSchoolList({ payload }, { call, put, take, select }) {
      const { centerInfo } = yield select(state => state.centerDetail)
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 0
      }
      const res = yield call(getGrantSchool, { TenantId: centerInfo.id })
      if (res.response.ok) {
        const { grantTenantSchool } = res.data || {}
        const schoolList = grantTenantSchool.filter(item => item.isGrant)
        const newSchoolListPagination = { ...pagination, total: schoolList.length }
        yield put({ type: 'updateState', payload: { grantTenantSchool, schoolListPagination: newSchoolListPagination } })
        yield put({
          type: 'changeSchoolTable',
          payload: {
            pagination: newSchoolListPagination
          }
        })
      }
    },

    *changeSchoolTable({ payload: data }, { call, put, take, select }) {
      const { schoolListPagination, grantTenantSchool } = yield select(state => state.centerDetail)
      const { pagination } = data
      const newSchoolListPagination = { ...schoolListPagination, ...pagination }
      const pageSize = newSchoolListPagination.pageSize
      const start = (newSchoolListPagination.current - 1) * pageSize
      const end = newSchoolListPagination.current * pageSize
      let grantSchoolListData = []
      grantTenantSchool.forEach(item => {
        if (item.isGrant) {
          grantSchoolListData.push(item.school)
        }
      })
      const schoolList = grantSchoolListData.slice(start, end)
      yield put({
        type: 'updateState',
        payload: {
          schoolListPagination: newSchoolListPagination,
          schoolList
        },
      })
    },

    *createContractTimer({ payload }, { call, put, take, select }) {
      // 倒计时timer
      const contractTimer = setInterval(() => {
        const { hfsContractTime: time } = getDvaApp()._store.getState().centerDetail
        if (time > 1) {
          getDvaApp()._store.dispatch({
            type: 'centerDetail/updateState',
            payload: {
              hfsContractTime: time - 1
            }
          })
        } else {
          getDvaApp()._store.dispatch({ type: 'centerDetail/onTimerComplete' })
        }
      }, 1000)
      yield put({ type: 'updateState', payload: { hfsContractLocked: true, contractTimer } })
    },

    *createHfsCodeTimer({ payload }, { call, put, take, select }) {
      const hfsCodeTimer = setInterval(() => {
        const { hfsCodeTime: time } = getDvaApp()._store.getState().centerDetail
        if (time > 1) {
          getDvaApp()._store.dispatch({
            type: 'centerDetail/updateState',
            payload: {
              hfsCodeTime: time - 1,
              canSendCode: false
            }
          })
        } else {
          getDvaApp()._store.dispatch({ type: 'centerDetail/onHfsCodeComplete' })
        }
      }, 1000)
      yield put({ type: 'updateState', payload: { hfsCodeTimer } })
    },

    // HFS充值需求 
    *sendCode({ payload }, { call, put, take, select }) {
      const { hfsCodeTimer } = yield select(state => state.centerDetail)
      const { code } = payload
      const res = yield call(PostCheckCode, { code })
      if (res.response.ok) {
        const { value } = res.data
        localStorage.setItem('x-authenticate', value)
        localStorage.setItem('hfsContractTime', moment().add(hfsContractTime, 'seconds'))
        if (hfsCodeTimer) clearInterval(hfsCodeTimer)
        yield put({ type: 'updateState', payload: { hfsCodeTimer: null, canSendCode: true, hfsCodeTime } })
        yield put({ type: 'getContractList' })
        yield put({ type: 'createContractTimer' })
      } else {
        const { error } = res.data || {}
        const { message: errorInfo } = error || {}
        message.error(errorInfo || '验证码错误')
      }
    },

    *getCode({ payload }, { call, put, take, select }) {
      const res = yield call(PostSendMessage)
      if (res.response.ok) {
        message.success('验证码发送成功')
        localStorage.setItem('hfsCodeTime', moment().add(hfsCodeTime, 'seconds'))
        yield put({ type: 'createHfsCodeTimer' })
      } else {
        const { error } = res.data || {}
        const { message: errorInfo } = error || {}
        message.error(errorInfo || '验证码发送失败')
      }
    },

    *onTimerComplete({ payload }, { call, put, take, select }) {
      const { contractTimer } = yield select(state => state.centerDetail)
      clearInterval(contractTimer)
      localStorage.setItem('hfsContractTime', '')
      yield put({ type: 'updateState', payload: { hfsContractLocked: false, hfsContractTime, contractTimer: null, canSendCode: true } })
      yield put({ type: 'cancelHfs' })
    },

    *onHfsCodeComplete({ payload }, { call, put, take, select }) {
      const { hfsCodeTimer } = yield select(state => state.centerDetail)
      clearInterval(hfsCodeTimer)
      localStorage.setItem('hfsCodeTime', '')
      yield put({ type: 'updateState', payload: { canSendCode: true, hfsCodeTime: hfsCodeTime, hfsCodeTimer: null } })
    },

    *getContractList({ payload }, { call, put, take, select }) {
      const { centerInfo, contractPagination, contractTableList, expandedRowKeys } = yield select(state => state.centerDetail)
      const { current, pageSize } = contractPagination
      const params = {
        TenantId: centerInfo.id,
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize
      }
      const res = yield call(GetContractList, params)
      if (res.response.ok) {
        const { items: { items, totalCount } } = res.data
        const newContractPagination = { ...contractPagination, total: totalCount }
        const firstId = items.length > 0 ? items[0].id : ''
        if (firstId) {
          yield put({ type: 'getSubDataById', payload: { id: firstId } })
        }
        yield put({
          type: 'updateState',
          payload: {
            contractPagination: newContractPagination,
            contractTableList: items.map(item => {
              const oldDataItem = contractTableList.find(oldItem => oldItem.id === item.id)
              if (oldDataItem && oldDataItem.subData) {
                item.subData = oldDataItem.subData
              }
              return item
            }),
            expandedRowKeys: firstId ? expandedRowKeys.concat(firstId) : expandedRowKeys,
            statisticsHfsData: res.data
          }
        })
      } else {
        const { error, code } = res.data || {}
        const { message: errorInfo } = error || {}
        if (code === '412') {
          yield put({ type: 'hasNoHfsPermission' })
        } else {
          message.error(errorInfo || '获取合同列表失败')
        }
      }
    },

    *getSubDataById({ payload }, { call, put, take, select }) {
      const { contractTableList } = deepCopy(yield select(state => state.centerDetail))
      const res = yield call(GetSubDataById, payload)
      if (res.response.ok) {
        const subData = res.data
        contractTableList.forEach(item => {
          if (item && item.id === subData.id) {
            item.subData = subData
          }
        })
        yield put({ type: 'updateState', payload: { contractTableList } })
      }
    },

    *newHfs({ payload: { record, type, intl } }, { call, put, take, select }) {
      const { contractTableList } = deepCopy(yield select(state => state.centerDetail))
      const { id } = record || {}
      let emptyContent = null
      let hfsModalType = ''
      let modalVisibleName = ''
      let params = {}
      switch (type) {
        case "contract":
          emptyContent = contractContentMenu(intl)
          hfsModalType = 'contract'
          modalVisibleName = 'hfsModalVisible'
          break
        case "recharge":
          const { state } = record || {}
          // 已结束的只可以扣款不可以充值
          if (state === 'Expired') {
            emptyContent = rechargeExpiredContentMenu
            hfsModalType = 'rechargeExpired'
          } else {
            emptyContent = rechargeContentMenu
            hfsModalType = 'recharge'
          }
          modalVisibleName = 'hfsModalVisible'
          break
        case "evaluation":
          hfsModalType = 'evaluation'
          modalVisibleName = 'selectHfsModalVisible'
          params = {
            contractId: id,
            type: 'Assessment'
          }
          const evaluationRes = yield call(GetAssessmentResource, params)
          if (evaluationRes.response.ok) {
            contractTableList.forEach(item => {
              if (item && item.id === id) {
                item.selectModalData = evaluationRes.data
                record.selectModalData = evaluationRes.data
              }
            })
          }
          break
        case "train":
          hfsModalType = 'train'
          modalVisibleName = 'selectHfsModalVisible'
          params = {
            contractId: id,
            type: 'OfflinePlanCourse'
          }
          const trainRes = yield call(GetTrainResResource, params)
          if (trainRes.response.ok) {
            contractTableList.forEach(item => {
              if (item && item.id === id) {
                item.selectModalData = trainRes.data
                record.selectModalData = trainRes.data
              }
            })
          }
          break
        case "curriculum":
          hfsModalType = 'curriculum'
          modalVisibleName = 'selectHfsModalVisible'
          params = {
            contractId: id,
            type: 'Course'
          }
          const curriculumRes = yield call(GetCourseResource, params)
          if (curriculumRes.response.ok) {
            contractTableList.forEach(item => {
              if (item && item.id === id) {
                item.selectModalData = curriculumRes.data
                record.selectModalData = curriculumRes.data
              }
            })
          }
          break
        case "oneToMore":
          hfsModalType = 'oneToMore'
          modalVisibleName = 'selectHfsModalVisible'
          params = {
            contractId: id,
            type: 'TrainingPlanV2'
          }
          const onToMoreRes = yield call(PostTrainingPlanV2Pre, params)
          if (onToMoreRes.response.ok) {
            contractTableList.forEach(item => {
              if (item && item.id === id) {
                item.selectModalData = onToMoreRes.data
                record.selectModalData = onToMoreRes.data
              }
            })
          }
          break
      }
      yield put({
        type: 'updateState',
        payload: {
          [modalVisibleName]: true,
          contractTableList,
          hfsModalContent: emptyContent,
          selectHfsItem: record,
          hfsModalEditOrAddType: 'add',
          hfsModalType
        }
      })
    },

    *cancelHfs({ payload }, { call, put, take, select }) {
      yield put({
        type: 'updateState',
        payload: {
          hfsModalVisible: false,
          selectHfsModalVisible: false,
          selectHfsItem: null
        }
      })
    },

    *submitHfs({ payload: { values, type } }, { call, put, take, select }) {
      const { selectHfsItem, centerInfo, hfsModalEditOrAddType } = yield select(state => state.centerDetail)
      let parmas = {}
      let errorMessage = null
      let isSuccess = true
      switch (type) {
        case "contract":
          const { amount: contractAmount, remark: contractRemark, reminderAmount: contractReminderAmount, validity: contractValidity } = values
          const { id: contractId } = selectHfsItem || {}
          parmas = {
            tenantId: centerInfo && centerInfo.id,
            startTime: contractValidity && contractValidity[0] ? contractValidity[0] : null,
            endTime: contractValidity && contractValidity[1] ? contractValidity[1] : null,
            amount: contractAmount,
            remark: contractRemark,
            reminderAmount: contractReminderAmount,
            id: contractId || null
          }
          const contractRes = yield call(hfsModalEditOrAddType === 'add' ? NewTenantContract : EditTenantContract, parmas)
          if (contractRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getContractList' })
          } else {
            isSuccess = false
            errorMessage = contractRes.data
          }
          break;
        case "recharge":
        case "rechargeExpired":
          const { money: rechargeMoney, recharge, remark: rechargeRemark, noticeAdminEmail, noticeTenantEmail } = values
          const { id: rechargeId } = selectHfsItem || {}
          parmas = {
            isRecharge: recharge === 'recharge',
            remark: rechargeRemark,
            money: rechargeMoney,
            contractId: rechargeId,
            noticeAdminEmail,
            noticeTenantEmail
          }
          const rechargeRes = yield call(hfsModalEditOrAddType === 'add' ? PostRecharge : '', parmas)
          if (rechargeRes.response.ok) {
            isSuccess = true
            yield put.resolve({ type: 'getSubDataById', payload: { id: rechargeId } })
            yield put.resolve({ type: 'getContractList' })
          } else {
            isSuccess = false
            errorMessage = rechargeRes.data
          }
          break
        case "evaluation":
          const { price: evaluationMoney, resourceName: evaluationName, resourcesIds: evaluationResourcesIds } = values || {}
          const { id: evaluationId, contractId: evaluationContractId } = selectHfsItem || {}
          if (hfsModalEditOrAddType === 'add') {
            parmas = {
              resourcesIds: evaluationResourcesIds,
              contractId: evaluationId,
              tenantId: centerInfo && centerInfo.id
            }
          } else {
            parmas = {
              contractId: evaluationContractId,
              price: evaluationMoney,
              id: evaluationId,
            }
          }
          const evaluationRes = yield call(hfsModalEditOrAddType === 'add' ? PostAssessmentResource : EditEvaluation, parmas)
          if (evaluationRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getSubDataById', payload: { id: hfsModalEditOrAddType === 'add' ? evaluationId : evaluationContractId } })
          } else {
            isSuccess = false
            errorMessage = evaluationRes.data
          }
          break
        case "train":
          const { price: trainMoney, resourceName: trainName, resourcesIds: trainResourcesIds } = values || {}
          const { id: trainId, contractId: trainContractId } = selectHfsItem || {}
          if (hfsModalEditOrAddType === 'add') {
            parmas = {
              resourcesIds: trainResourcesIds,
              contractId: trainId,
              tenantId: centerInfo && centerInfo.id
            }
          } else {
            parmas = {
              contractId: trainContractId,
              price: trainMoney,
              id: trainId,
            }
          }
          const trainRes = yield call(hfsModalEditOrAddType === 'add' ? PostTrainResourceApi : EditEvaluation, parmas)
          if (trainRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getSubDataById', payload: { id: hfsModalEditOrAddType === 'add' ? trainId : trainContractId } })
          } else {
            isSuccess = false
            errorMessage = trainRes.data
          }
          break
        case "curriculum":
          const { price: curriculumMoney, resourcesIds: curriculumResourcesIds } = values || {}
          const { id: curriculumId, contractId: curriculumContractId } = selectHfsItem || {}
          if (hfsModalEditOrAddType === 'add') {
            parmas = {
              resourcesIds: curriculumResourcesIds,
              contractId: curriculumId,
              tenantId: centerInfo && centerInfo.id
            }
          } else {
            parmas = {
              contractId: curriculumContractId,
              price: curriculumMoney,
              id: curriculumId,
            }
          }
          const curriculumRes = yield call(hfsModalEditOrAddType === 'add' ? PostCourseResource : EditEvaluation, parmas)
          if (curriculumRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getSubDataById', payload: { id: hfsModalEditOrAddType === 'add' ? curriculumId : curriculumContractId } })
          } else {
            isSuccess = false
            errorMessage = curriculumRes.data
          }
          break
        case "oneToMore":
          const { id: oneToMoreId, contractId: oneToMoreIdContractId } = selectHfsItem || {}
          if (hfsModalEditOrAddType === 'add') {
            const { resourcesIds } = values || {}
            parmas = {
              resourcesIds,
              contractId: oneToMoreId,
              tenantId: centerInfo && centerInfo.id
            }
          } else {
            const { pricedown, priceup } = values
            const { webPrice: { resourceType: webResourceType }, appPrice: { resourceType: appResourceType }, resourceId } = selectHfsItem
            parmas = {
              resourceId,
              contractId: oneToMoreIdContractId,
              prices: [
                {
                  price: priceup,
                  resourceType: appResourceType,
                  platform: "App"
                },
                {
                  price: pricedown,
                  resourceType: webResourceType,
                  platform: "Web"
                }
              ]
            }
          }
          const oneToMoreRes = yield call(hfsModalEditOrAddType === 'add' ? PostPlanV2Resource : PutPlanV2Resource, parmas)
          if (oneToMoreRes.response.ok) {
            isSuccess = true
            yield put({ type: 'reloadData', payload: { type: 'oneToMore', record: { id: hfsModalEditOrAddType === 'add' ? oneToMoreId : oneToMoreIdContractId } } })
          } else {
            isSuccess = false
            errorMessage = oneToMoreRes.data
          }
          break
        case "remark":
          const { id: recordId, tenantContractId: remarkContractId } = selectHfsItem || {}
          const params = {
            ...values,
            recordId,
            contractId: remarkContractId
          }
          const remarkRes = yield call(PutHfsContract, params)
          if (remarkRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getSubDataById', payload: { id: hfsModalEditOrAddType === 'add' ? recordId : remarkContractId } })
          } else {
            isSuccess = false
            errorMessage = remarkRes.data
          }
          break
        default:
          console.log('HFS提交按钮确认')
      }

      if (isSuccess) {
        message.success('提交成功')
        yield put({ type: 'cancelHfs' })
      } else {
        const { error, code } = errorMessage || {}
        const { message: errorInfo } = error || {}
        if (code === '412') {
          yield put({ type: 'hasNoHfsPermission' })
        } else {
          message.error(errorInfo || '提交失败')
        }
      }
    },

    *submitHfsReminder({ payload }, { call, put, take, select }) {
      const { vratCenterInfo: { Id }, centerInfo } = JSON.parse(JSON.stringify(yield select(state => state.centerDetail)))
      const { values: { HfsReminderAmount } } = payload
      const res = yield call(PutRemider, {
        hfsReminderAmount: HfsReminderAmount,
        id: Id
      })
      if (res.response.ok) {
        message.success("修改成功")
        yield put({ type: 'loadPage', payload: { id: centerInfo.id } })
      }
      yield put({
        type: 'updateState',
        payload: {
          visible: false,
        }
      })
    },

    *editHfsReminder({ payload }, { call, put, take, select }) {
      const { vratCenterInfo: { HfsReminderAmount } } = JSON.parse(JSON.stringify(yield select(state => state.centerDetail)))
      let emptyContent = hfsReminderModalContentMenu
      emptyContent.Properties[0].Value = HfsReminderAmount
      yield put({
        type: 'updateState',
        payload: {
          visible: true,
          hfsReminderModalContent: emptyContent
        }
      })
    },

    *editRemiderValue({ payload }, { call, put, take, select }) {
      const { intl } = payload || {}
      const { VRATAmount: { VratReminderAmount } } = JSON.parse(JSON.stringify(yield select(state => state.centerDetail)))
      let emptyContent = reminderValueModalContentMenu(intl)
      emptyContent.Properties[0].Value = VratReminderAmount
      yield put({
        type: 'updateState',
        payload: {
          remiderVisible: true,
          reminderValueModalContent: emptyContent
        }
      })
    },

    * monthRemarkEdit({ payload }, { call, put, take, select }) {
      const { record, intl } = payload || {}
      const { Remark } = record || {}
      let emptyContent = deepCopy(editRemarkContentMenu(intl))
      emptyContent.Properties[0].Value = Remark
      yield put({
        type: 'updateState',
        payload: {
          remarkMonthVisible: true,
          editMonthRemarkContent: emptyContent,
          selectHfsItem: record
        }
      })
    },
    *vartRemarkEdit({ payload }, { call, put, take, select }) {
      const { record, intl } = payload || {}
      const { Remark } = record
      let emptyContent = deepCopy(editRemarkContentMenu(intl))
      emptyContent.Properties[0].Value = Remark
      yield put({
        type: 'updateState',
        payload: {
          remarkVisible: true,
          editRemarkContent: emptyContent,
          selectHfsItem: record
        }
      })
    },

    *submitMonthRemark({ payload }, { call, put, take, select }) {
    const { selectHfsItem: { Id, ParentId }, centerInfo } = JSON.parse(JSON.stringify(yield select(state => state.centerDetail)))
    const { remark } = payload
    // 编辑备注
    const res = yield call(PutRemark, {
      Remark: remark,
      Id
    })
    if (res.response.ok) {
      message.success("修改成功")
      yield put({ type: 'getMonthChildrenList', payload: { Id: ParentId } })
    }
    yield put({
      type: 'updateState',
      payload: {
        remarkMonthVisible: false,
      }
    })
  },
    *submitRemark({ payload }, { call, put, take, select }) {
      const { selectHfsItem: { Id }, centerInfo } = JSON.parse(JSON.stringify(yield select(state => state.centerDetail)))
      const { remark } = payload
      // 编辑备注
      const res = yield call(PutRemark, {
        Remark: remark,
        Id
      })
      if (res.response.ok) {
        message.success("修改成功")
        yield put({ type: 'loadPage', payload: { id: centerInfo.id } })
      }
      yield put({
        type: 'updateState',
        payload: {
          remarkVisible: false,
        }
      })
    },

    *submitReminderValue({ payload }, { call, put, take, select }) {
      const { vratCenterInfo: { Id }, centerInfo } = JSON.parse(JSON.stringify(yield select(state => state.centerDetail)))
      const res = yield call(PutRemiderValue, {
        ...payload,
        id: Id
      })
      if (res.response.ok) {
        message.success("修改成功")
        yield put({ type: 'loadPage', payload: { id: centerInfo.id } })
      }
      yield put({
        type: 'updateState',
        payload: {
          remiderVisible: false,
        }
      })
    },

    *editHfs({ payload: { record, type, intl } }, { call, put, take, select }) {
      // 弹框里面的数据
      let editUserInfoForm = null
      // HFS弹框类型
      let hfsModalType = ''

      let emptyContent
      switch (type) {
        case "contract":
          hfsModalType = 'contract'
          emptyContent = { ...contractContentMenu(intl) }
          // emptyContent.Properties[2].Setting.Disabled = true
          editUserInfoForm = matchDynamicForm(emptyContent, {
            ...record,
            validity: [moment(record.startTime), moment(record.endTime)]
          })
          break
        case "evaluation":
          hfsModalType = 'evaluation'
          emptyContent = { ...evaluationContentMenu }
          editUserInfoForm = matchDynamicForm(emptyContent, record)
          break
        case "train":
          hfsModalType = 'train'
          emptyContent = { ...trainContentMenu }
          editUserInfoForm = matchDynamicForm(emptyContent, record)
          break
        case "curriculum":
          hfsModalType = 'curriculum'
          emptyContent = { ...curriculumContentMenu }
          editUserInfoForm = matchDynamicForm(emptyContent, record)
          break
        case "oneToMore":
          hfsModalType = 'oneToMore'
          emptyContent = { ...oneToMoreContentMenu }
          const { resourceName, appPrice: { price: priceUp }, webPrice: { price: priceDown } } = record
          emptyContent.Properties[0].Value = resourceName
          emptyContent.Properties[1].Value = priceUp
          emptyContent.Properties[2].Value = priceDown
          editUserInfoForm = emptyContent
          break;
        case "remark":
          hfsModalType = 'remark'
          emptyContent = { ...remarkContentMenu }
          editUserInfoForm = matchDynamicForm(emptyContent, record)
          break
        default:
          editUserInfoForm = null
      }

      yield put({
        type: 'updateState',
        payload: {
          hfsModalVisible: true,
          hfsModalContent: editUserInfoForm,
          selectHfsItem: record,
          hfsModalEditOrAddType: 'edit',
          hfsModalType
        }
      })
    },

    *deleteHfs({ payload: { record, type } }, { call, put, take, select }) {
      let parmas = {}
      let errorMessage = null
      let isSuccess = true
      switch (type) {
        case "contract":
          parmas = {
            id: record.id
          }
          const contractRes = yield call(DeleteContract, parmas)
          if (contractRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getContractList' })
          } else {
            isSuccess = false
            errorMessage = contractRes.data
          }
          break
        case "evaluation":
          const { resourceId: evaluationResourceId, contractId: evaluationContractId } = record || {}
          parmas = {
            resourceId: evaluationResourceId,
            contractId: evaluationContractId
          }
          const evaluationRes = yield call(DeleteAssessmentResource, parmas)
          if (evaluationRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getSubDataById', payload: { id: evaluationContractId } })
          } else {
            isSuccess = false
            errorMessage = evaluationRes.data
          }
          break
        case "train":
          const { resourceId: trainResourceId, contractId: trainContractId } = record || {}
          parmas = {
            resourceId: trainResourceId,
            contractId: trainContractId
          }
          const trainRes = yield call(DeleteAssessmentResource, parmas)
          if (trainRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getSubDataById', payload: { id: trainContractId } })
          } else {
            isSuccess = false
            errorMessage = trainRes.data
          }
          break
        case "onetomore":
          const { resourceId: oneToMoreResourceId, contractId: oneToMoreContractId } = record || {}
          parmas = {
            resourceId: oneToMoreResourceId,
            contractId: oneToMoreContractId
          }
          const oneToMoreRes = yield call(DeleteAssessmentResource, parmas)
          if (oneToMoreRes.response.ok) {
            isSuccess = true
            yield put({ type: 'reloadData', payload: { type: 'oneToMore', record: { id: oneToMoreContractId } } })
          } else {
            isSuccess = false
            errorMessage = oneToMoreRes.data
          }
          break
        case "curriculum":
          const { resourceId: curriculumResourceId, contractId: curriculumContractId } = record || {}
          parmas = {
            resourceId: curriculumResourceId,
            contractId: curriculumContractId
          }
          const curriculumRes = yield call(DeleteAssessmentResource, parmas)
          if (curriculumRes.response.ok) {
            isSuccess = true
            yield put({ type: 'getSubDataById', payload: { id: curriculumContractId } })
          } else {
            isSuccess = false
            errorMessage = curriculumRes.data
          }
          break
        default:
          console.log('删除操作')
      }

      if (isSuccess) {
        message.success('已删除')
      } else {
        const { error, code } = errorMessage || {}
        const { message: errorInfo } = error || {}
        if (code === '412') {
          yield put({ type: 'hasNoHfsPermission' })
        } else {
          message.error(errorInfo || '删除失败')
        }
      }
    },

    *stopHfs({ payload: { record, type } }, { call, put, take, select }) {
      let parmas = {}
      let errorMessage = null
      let isSuccess = true
      switch (type) {
        case "contract":
          parmas = {
            id: record.id
          }
          const res = yield call(StopContract, parmas)
          if (res.response.ok) {
            isSuccess = true
          } else {
            isSuccess = false
            errorMessage = res.data
          }
          break;
      }
      if (isSuccess) {
        message.success('已停用')
        yield put({ type: 'getContractList' })
      } else {
        const { error, code } = errorMessage || {}
        const { message: errorInfo } = error || {}
        if (code === '412') {
          yield put({ type: 'hasNoHfsPermission' })
        } else {
          message.error(errorInfo || '停用失败')
        }
      }
    },

    *onchangeHfsPagination({ payload }, { call, put, take, select }) {
      const { contractPagination } = yield select(state => state.centerDetail)
      yield put({ type: 'updateState', payload: { contractPagination: { ...contractPagination, current: payload } } })
      yield put({ type: 'getContractList' })
    },

    *onHfsTableExpand({ payload: { expanded, record } }, { call, put, take, select }) {
      const { id } = record || {}
      if (expanded) {
        yield put({ type: 'getSubDataById', payload: { id } })
      }
    },

    *hasNoHfsPermission({ payload }, { call, put, take, select }) {
      const { contractTimer, hfsCodeTimer } = yield select(state => state.centerDetail)
      localStorage.setItem('hfsContractTime', '')
      localStorage.setItem('hfsCodeTime', '')
      clearInterval(contractTimer)
      clearInterval(hfsCodeTimer)
      yield put({ type: 'updateState', payload: { canSendCode: true, hfsContractLocked: false, contractTimer: null, hfsCodeTimer: null, hfsContractTime } })
      yield put({ type: 'cancelHfs' })
    },

    *startHfs({ payload }, { call, put, take, select }) {
      const { record, type } = payload
      let params = {}
      let errorMessage = null
      let isSuccess = true
      switch (type) {
        case "contract":
          params = {
            id: record && record.id
          }
          const res = yield call(PostStartHfs, params)
          if (res.response.ok) {
            isSuccess = true
          } else {
            isSuccess = false
            errorMessage = res.data
          }
      }
      if (isSuccess) {
        message.success('开启成功')
        yield put({ type: 'getContractList' })
      } else {
        const { error, code } = errorMessage || {}
        const { message: errorInfo } = error || {}
        if (code === '412') {
          yield put({ type: 'hasNoHfsPermission' })
        } else {
          message.error(errorInfo || '开启失败')
        }
      }
    },

    *reloadData({ payload }, { call, put, take, select }) {
      const { record, type } = payload
      const { id } = record;
      switch (type) {
        case "oneToMore":
          const res = yield call(GetPlanResource, { id })
          if (res.response.ok) {
            yield put({ type: 'updateState', payload: { oneToMoreTableData: res.data } })
          } else {
            const { error } = res.data || {};
            message.error(error.message);
          }
          break;
        default:
          break;
      }
    },

    // TrainingCourses
    *trainingCoursesChangeOpenState({ payload }, { call, put, take, select }) {
      const { trainingCoursesCurrentTab, trainingCoursesTabs, centerInfo } = yield select(state => state.centerDetail)
      const currentTabInfo = trainingCoursesTabs.find(item => item.name === trainingCoursesCurrentTab)
      const params = {
        moduleId: currentTabInfo.id,
        tenantId: centerInfo.id
      }
      const res = yield call(payload ? PostStartTenantTrainingModule : PostEndTenantTrainingModule, params)
      if (res.response.ok) {
        yield put({ type: 'getTrainingCoursesByModule' })
      }
    },

    *changeTrainingCoursesCurrentTab({ payload }, { call, put, take, select }) {
      yield put({ type: 'updateState', payload: { trainingCoursesCurrentTab: payload } })
      yield put({ type: 'getTrainingCoursesByModule' })
    },

    *editTrainingCoursesModal({ payload }, { call, put, take, select }) {
      let content = null
      let type = ''
      const { trainingCoursesInfo } = yield select(state => state.centerDetail)
      const { validDate, price } = trainingCoursesInfo || {}
      switch (payload) {
        case "courseValidity":
          content = { ...courseValidityContentMenu }
          content.Properties[0].Value = validDate
          type = "courseValidity"
          break
        case "addTrainingPlaces":
          content = { ...addTrainingPlacesContentMenu }
          type = "addTrainingPlaces"
          break
        case "modulePrice":
          content = { ...modulePriceContentMenu }
          content.Properties[0].Value = price
          type = "modulePrice"
          break
      }
      yield put({ type: 'updateState', payload: { trainingCoursesEditModalVisible: true, trainingCoursesModalContent: content, trainingCoursesModalType: type } })
    },

    *onTrainingCoursesOk({ payload }, { call, put, take, select }) {
      let res = null
      let params = {}
      const { trainingCoursesModalType, trainingCoursesCurrentTab, trainingCoursesTabs, centerInfo, trainingCoursesInfo } = yield select(state => state.centerDetail)
      const currentTabInfo = trainingCoursesTabs.find(item => item.name === trainingCoursesCurrentTab)
      switch (trainingCoursesModalType) {
        case "courseValidity":
          params = {
            moduleId: currentTabInfo.id,
            tenantId: centerInfo.id,
            validDate: payload.validDate,
            price: trainingCoursesInfo && trainingCoursesInfo.price,
            canUsePrice: trainingCoursesInfo && trainingCoursesInfo.canUsePrice
          }
          res = yield call(PutTenantTrainingModule, params)
          break
        case "addTrainingPlaces":
          params = {
            moduleId: currentTabInfo.id,
            tenantId: centerInfo.id,
            numberOfPlaces: payload.numberOfPlaces,
            remark: payload.remark
          }
          res = yield call(PostTrainingModuleResource, params)
          break
        case "modulePrice":
          params = {
            moduleId: currentTabInfo.id,
            tenantId: centerInfo.id,
            validDate: trainingCoursesInfo && trainingCoursesInfo.validDate,
            price: payload.price,
            canUsePrice: trainingCoursesInfo && trainingCoursesInfo.canUsePrice
          }
          res = yield call(PutTenantTrainingModule, params)
          break
        case "changePriceStatus":
          params = {
            moduleId: currentTabInfo.id,
            tenantId: centerInfo.id,
            validDate: trainingCoursesInfo && trainingCoursesInfo.validDate,
            price: trainingCoursesInfo && trainingCoursesInfo.price,
            canUsePrice: payload
          }
          res = yield call(PutTenantTrainingModule, params)
          break
      }
      if (res && res.response.ok) {
        message.success('提交成功')
        yield put({ type: 'getTrainingCoursesByModule' })
        yield put({ type: 'updateState', payload: { trainingCoursesEditModalVisible: false } })
      } else {
        const { error } = res.data
        const { message: messageInfo } = error || {}
        messageInfo && message.error(messageInfo)
      }
    },

    *showTrainingCoursesDrawerRecord({ payload }, { call, put, take, select }) {
      yield put({ type: 'updateState', payload: { trainingCoursesDrawerVisible: true } })
    },

    *getTrainingCoursesByModule({ payload }, { call, put, take, select }) {
      const { centerInfo, trainingCoursesCurrentTab, trainingCoursesTabs } = yield select(state => state.centerDetail)
      const currentTabInfo = trainingCoursesTabs.find(item => item.name === trainingCoursesCurrentTab) || {}
      if (Object.keys(currentTabInfo).length) {
        const res = yield call(GetTrainingCoursesByModule, { ModuleId: currentTabInfo?.id, TenantId: centerInfo.id })
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              trainingCoursesInfo: res.data
            }
          })
        }
      }
    },

    *addNewEmployee({ payload }, {call, put, take, select, all }) {
      const { intl, type } = payload || {}
      const formContent = AddContentMenu(intl);
      const params = {type: 'Center'}
      const data = {
        MaxResultCount: 10000,
        SkipCount: 0
      }
      try {
        const [res, getStaffListRes] = yield all([
          call(PrepareEditUser, params),
          call(GetAdminStaffList, data)
        ])

        if(res.response.ok) {
          const { roles, user } = res.data
          let roleName = roles.map(item => {
            const { name, displayName, id } = item
            return {
              Value: displayName,
              Id: name,
            }
          })

          let contentMenu;
          let content;
          roleName = roleName && roleName.filter(item => item.Id !== "CenterAdmin")
          content = deepCopy(formContent)
          // content.Properties[1].Setting.Disabled = false;
          contentMenu = addOptionsToProperties(content, ['roleName'], roleName)
          yield put({
            type: 'updateState',
            payload: {
              addNewEmployeeModalVisible: true,
              roleName,
              contentMenu
            }
          })
        }
        else{
          message.error('获取角色名信息失败')
        }
        if (getStaffListRes.response.ok) {
          let content;
          const { items } = getStaffListRes.data || {}
          const { contentMenu } = yield select(state => state.centerDetail)
          // contentMenu 取第一次更改后的contentMenu
          content = deepCopy(contentMenu)
          content.Properties[0].Setting.DropdownOptions = items.map(item=> {
            const { name, email, id } = item
            return {
              label: `${name}(${email})`,
              value: email,
              key: id
            }
          });
          yield put({
            type: 'updateState',
            payload: {
              // changeStaffModalVisible: true,
              contentMenu: content
            }
          })
        }
        else{
          message.error('获取员工信息失败')
        }
      } catch(error) {
        console.log(error,'error')
      }
    },

    *onSubmitModal(
      { payload: { data, intl }, callback },
      { take, put, call, select, all }
    ) {
      const { email, roleName, remark, id: tenantId } = data;
      let res = yield all(
        email.map(({ value }) => {
          return call(PostUser, { email: value, roleName, remark, tenantId });
        })
      );

      const result = res.map(
        (
          { data: { error: { code, message } = {} }, response: { ok } },
          index
        ) => {
          return { ok, code, message, email: email[index] };
        }
      );
      // 展示的message数组
      let resultMessage = [];
      // 过滤已添加的邮箱
      const emailInTenant = result.filter(
        ({ code }) => code === 'Business:EmailInTenant'
      );
      if (emailInTenant.length) {
        resultMessage.push({
          message: '员工添加失败，请检查员工是否已存在于当前中心，尝试重新添加',
        });
        emailInTenant.map(({ email }) => {
          resultMessage.push({ message: email.value });
        });
      }
      // 过滤失败的邮箱
      const emailFail = result.filter(({ code }) => code === null);
      if (emailFail.length) {
        resultMessage.push({ message: '你的请求无效' });
        emailFail.map(({ email }) => {
          resultMessage.push({ message: email.value });
        });
      }
      yield put({
        type: 'updateState',
        payload: { addNewEmployeeModalVisible: false },
      });
      if (result.every(({ ok }) => ok)) {
        message.success(intl.formatMessage({ id: 'mailSentSuccessfully' }));
        yield put({ type: 'getList' });
      } else {
        callback(resultMessage);
        yield put({ type: 'getList' });
      }
    },

    *getList({ payload }, { call, put, take, select, all }) {
      const { pagination, filter, centerInfo, activeStatus, filters } = yield select(state => state.centerDetail)
      const { userRoles } = filters || {}
      let query = { skipCount: 0, maxResultCount: 10, }
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        filter: filter,
        state: activeStatus,
        roles: userRoles || [],
        tenantIds: [centerInfo.id] || []
      }
      const [res, editUserRes] = yield all([
        call(PostUserList, query),
        call(PrepareEditUser, { type: 'Center' })
      ])
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
      if (editUserRes.response.ok) {
        const { roles = [] } = editUserRes.data || {};
        yield put({ type: 'updateState', payload: { roleNameOptions: roles } })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination, filters = {} } = data || {}

      const isActiveFilter = Object.keys(filters).length > 0
      const currentPagination = isActiveFilter ? defaultPagination : pagination

      if (isActiveFilter) {
        yield put({ type: 'updateState', payload: { filters } })
      }

      yield put({ type: 'updateState', payload: { pagination: currentPagination } })
      yield put({ type: 'getList' })
    },

    *onSendEmail({ payload: { intl, id } }, { call, put, select }) {
      const res = yield call(PostSendEmail, id)
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'mailSentSuccessfully' }))
        yield put({ type: 'getList' })
      } else {
        message.error(intl.formatMessage({ id: 'failedToSendMail' }))
      }
    },

    *onDelete({ payload: { userId, intl, id } }, { call, put, select }) {
      const res = yield call(DelAdminUser, { userId, tenantId: id })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({ type: 'getList' })
      } else {
        message.success(intl.formatMessage({ id: 'error' }))
      }
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/centerDetail') {
    //       dispatch({ type: 'loadPage', payload: location.query })
    //     }
    //   })
    // },
  },
}
