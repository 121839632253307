import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Radio, Button, Dropdown, Space, Menu, Progress, message } from 'antd';
import CbtOverview from './components/CbtOverview';
import CbtWeekly from './components/CbtWeekly';
import { connect, useIntl } from 'umi';
import { getActiveWeekdays } from 'utils/utils';

import { PostCbtList } from 'services/pdt'

import styles from './index.less';

const CBT = ({ subjectDetail }) => {
  const intl = useIntl();

  const { subjectInfo } = subjectDetail;
  const { id } = subjectInfo;

  const [mode, setMode] = useState('overView');
  const [cbtData, setCbtData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [week, setWeek] = useState(null);
  let weekAssigned = 1;

  const handleMenuClick = (e) => {
    const { key } = e;
    setWeek(key);
  };

  // 获取最大是哪周
  const sortData = cbtData.sort((a, b) => {
    return b.cbt.weekIndex - a.cbt.weekIndex
  })

  if (sortData.length) {
    const { cbt } = sortData[0];
    const { weekIndex } = cbt;
    weekAssigned = weekIndex
  }

  const currentWeek = week || weekAssigned

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={getActiveWeekdays(weekAssigned)}
    />
  );

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const initData = async () => {
    const res = await PostCbtList(
      {
        childId: id,
        status: ['completed', 'caughtUp', 'active'],
      }
    );

    if (res.response.ok) {
      setCbtData(res.data);
    } else {
      if (res.data === '') {
        // console.log('no data');
        setIsEmpty(true);
      } else {
        const { msg } = res.data;
        message.error(msg)
      }
    }
  };

  useEffect(() => {
    initData()
  }, [])

  const overViewProps = {
    cbtData,
    isEmpty
  }

  const weeklyProps = {
    cbtData,
    currentWeek
  }

  const completedNum = cbtData?.filter(item => item.status === 'completed').length

  return (
    <div className={styles.cbtpanel}>
      <div className={styles.titleGroup}>
        <div className={styles.progressTitle}>{intl.formatMessage({ id: 'CBTClass' })}</div>
        <div className={styles.progressPanel}>
          <div className={styles.progressNum}>
            <div className={styles.progressCurrentNum}>{completedNum}</div>
            <div>/60</div>
          </div>
          <Progress
            percent={completedNum / 60 * 100}
            showInfo={false}
            strokeWidth={20}
            strokeColor={'#6eb1b6'}
            style={{ width: '180px' }}
          />
        </div>
      </div>
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{ marginBottom: 8 }}
      >
        <Radio.Button value="overView">{intl.formatMessage({ id: 'Overview' })}</Radio.Button>
        <Radio.Button value="weekly">{intl.formatMessage({ id: 'Weekly' })}</Radio.Button>
      </Radio.Group>

      {mode === 'weekly' && (
        <div className={styles.cbtRightSelect}>
          <span>{intl.formatMessage({ id: 'Week' })}:&nbsp;&nbsp;</span>
          <Dropdown overlay={menu}>
            <Button>
              <Space>
                {currentWeek}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      )}
      {mode === 'overView' ? <CbtOverview {...overViewProps} /> : <CbtWeekly {...weeklyProps} />}
    </div>
  );
};

export default connect(({
  subjectDetail,
}) => ({
  subjectDetail,
}))(CBT);
