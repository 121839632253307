import React, { memo, useEffect } from 'react';
import { connect } from 'umi'
import ShowDataAnalysis from 'components/ShowDataAnalysis'
import CardHead from 'components/CardHead';
import { Card, Spin } from 'antd'
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart'
import CommonPieTable from 'cognitiveleap-core-us/components/Charts/Common/CommonPieTable'
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate'
import style from './index.less';
import { 
    childrenAgeDataSource, 
    childrenGengerDataSource, 
    tableColumns, 
    childrenKeyIndicators,
    childrenLegend,
    childrenAgeTableColums
 } from  '../config';
import { getProportionData } from 'cognitiveleap-core-us/utils/utils';
import { useIntl } from 'umi'

const ChildrenAnalysis = ({ childrenAnalysis, loading, dispatch }) => {
    const { 
        keyIndicators,
        currentTag,
        datePickerIsOpen,
        dateMonthPickerData,
        dateYearPickerData,
        analysisData
     } = childrenAnalysis;

     const intl = useIntl()
     useEffect(() => {
        dispatch({ type: 'childrenAnalysis/loadKeyIndicators' })
        dispatch({ type: 'childrenAnalysis/loadAnalysisData' }) 
     }, [])

    if ( analysisData == null || keyIndicators == null) {
        return <Spin />
    }

    const { ageDistributionChart, genderChart, trend } = analysisData;
    const isLoadingAnalysis = loading.effects['childrenAnalysis/loadAnalysisData'];

    const chartProps = {
        legendData: childrenLegend(intl),
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        dataSource: trend
    };

    const ageTotalNum = ageDistributionChart.length> 0 && ageDistributionChart.map(item => item.num).reduce((prev, cur) => {
        return prev + cur
    });

    const genderTotalNum = genderChart.length> 0 && genderChart.map(item => item.num).reduce((prev, cur) => {
        return prev + cur
    });

    const showChildrenKeyIndicators = childrenKeyIndicators(intl).map(item=>{
        return {
            ...item,
            data: keyIndicators[item.field]
        }
    })
    const getChildrenKeyIndicators = () => {
        return childrenKeyIndicators(intl)
            .map(item => ({
                ...item,
                data: keyIndicators[item.field]
            }))
            .map(item => {
                const data = ageDistributionChart.find(ageItem => ageItem.age == item.age);
                if (data) {
                    return {
                        ...item,
                        number: data.num,
                        proportion: getProportionData(data.num, ageTotalNum),
                    }
                } else {
                    return {
                        ...item,
                        number: 0,
                        proportion: '0%',
                    }
                }
            })
    }


    const showChildrenAgeDataSource = childrenAgeDataSource(intl).map(item=>{
        const data = ageDistributionChart.find( ageItem => ageItem.age == item.age );
        return {
            ...item,
            number: data!==undefined?data.num:0,
            proportion: data!==undefined?getProportionData(data.num, ageTotalNum):'0%'
        }
    })

    const showChildrenGengerDataSource = childrenGengerDataSource(intl).map(item=>{
        const data = genderChart.find( genderItem => genderItem.gender == item.gender )
        return {
            ...item,
            number: data!==undefined?data.num:0,
            proportion: data!==undefined?getProportionData(data.num, genderTotalNum):'0%'
        }
    })

    const ageProps = {
        title: intl.formatMessage({ id: 'ageDistribution' }),
        color: ['#FFADD2', '#FFD591', '#D9F7BE', '#91D5FF', '#BA8FFA'],
        totalNum: ageTotalNum,
        columns: childrenAgeTableColums(intl),
        dataSource: showChildrenAgeDataSource,
        legendData: ['0-3', '3-5.5', '5.5-13', '13-18', '>=18']
    }

    const genderProps = {
        title: intl.formatMessage({ id: 'genderDistribution' }),
        color: ['#69C0FF', '#FFADD2', '#D9F7BE', '#E8E8E8'],
        totalNum: genderTotalNum,
        columns: tableColumns(intl),
        dataSource: showChildrenGengerDataSource,
        legendData: [intl.formatMessage({ id: 'male' }), intl.formatMessage({ id: 'female' }), intl.formatMessage({ id: 'unknown' }), intl.formatMessage({ id: 'other' })]
    }

    const changeTag = (tag, checked) => {
        dispatch({
            type: 'childrenAnalysis/changeTag',
            payload: {
                key: tag.key
            }
        })
    }

    const handleOpenChange = () => {
        dispatch({
            type: 'childrenAnalysis/updateState',
            payload: {
                datePickerIsOpen: !datePickerIsOpen
            }
        })
    }

    const handlePanelChange = (time) => {
        dispatch({
            type: 'childrenAnalysis/handlePanelChange',
            payload: {
                time
            }
        })
    }

    const analysisChoiceDateProps = {
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        datePickerIsOpen,
        changeTag,
        handleOpenChange,
        handlePanelChange
    }
    return (
    <Card title={<b style={{fontSize: '20px'}}>{intl.formatMessage({ id: 'childUserAnalysis' })}</b>} bordered={false}>
            <div className={style.childrenAnalysis}>
                <div className={style.keyIndicators}>
                    <CardHead title={intl.formatMessage({ id: 'keyIndicators' })}  />
                    <ShowDataAnalysis keyIndicators={getChildrenKeyIndicators()} />
                </div>
                <Spin spinning={isLoadingAnalysis}>
                    <div className={style.trendAnalysis}>
                        <CardHead title={intl.formatMessage({ id: 'trendAnalysis' })}  />
                        <AnalysisChoiceDate {...analysisChoiceDateProps} />
                        <div className={style.trendAnalysisContent}>
                            <CommonAnalysisLineChart {...chartProps} />
                            <div className={style.trendAnalysisContentChart}><CommonPieTable {...ageProps} /></div>
                            <div className={style.trendAnalysisContentChart}><CommonPieTable {...genderProps} /></div>
                        </div>
                    </div>
                </Spin>
            </div>
        </Card>
    )
}

export default connect(({ childrenAnalysis, loading }) => ({ childrenAnalysis, loading }))(ChildrenAnalysis);