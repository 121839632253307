import { GetPrepareList } from 'services/rocketService/Prepare'

export default {
  namespace: 'offlinePlan',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 6,
      total: 0,
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.offlinePlan)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'offlinePlan')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/content/offlinePlan') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
