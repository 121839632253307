import React, { useState, useEffect } from 'react';
import { Radio, Progress, message, Spin } from 'antd';
import { connect, useIntl } from 'umi';
import ParentLearningOverview from './components/ParentLearningOverview';
import ParentLearningWeekly from './components/ParentLearningWeekly';

import { GetPdtProgress } from 'services/pdt'

import styles from './index.less';

const ParentLearning = ({ subjectDetail }) => {
    const intl = useIntl()

    const { subjectInfo: { id } } = subjectDetail;

    const [mode, setMode] = useState('overView');
    const [progressData, setProgressData] = useState({})

    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    const init = async () => {
        const res = await GetPdtProgress({ subjectId: id })
        if (res.response.ok) {
            setProgressData(res.data.data)
        } else {
            const { msg } = res.data;
            message.error(msg);
        }
    }

    useEffect(() => {
        init();
    }, [])

    if (Object.keys(progressData).length === 0) {
        return <Spin className={styles.spin} />
    }

    const weeklyProps = {
        subjectId: id
    }

    const { completedNum, totalNum } = progressData


    return (
        <div className={styles.ParentLearningpanel}>
            <div className={styles.titlePanel}>
                <div className={styles.progressTitle}>{intl.formatMessage({ id: 'ParentLearning' })}</div>
                <div className={styles.progressPanel}>
                    <div className={styles.progressNum}>
                        <div className={styles.progressCurrentNum}>{completedNum}</div>
                        <div>/{totalNum}</div>
                    </div>
                    <Progress
                        percent={completedNum / totalNum * 100}
                        showInfo={false}
                        strokeWidth={20}
                        strokeColor={'#6eb1b6'}
                        style={{ width: '180px' }}
                    />
                </div>
            </div>
            <div className={styles.ParentLearningSelectGroup}>
                <Radio.Group
                    onChange={handleModeChange}
                    value={mode}
                    style={{ marginBottom: 8 }}
                >
                    <Radio.Button value="overView">{intl.formatMessage({ id: 'Overview' })}</Radio.Button>
                    <Radio.Button value="weekly">{intl.formatMessage({ id: 'Weekly' })}</Radio.Button>
                </Radio.Group>
            </div>
            {mode === 'overView' ? <ParentLearningOverview /> : <ParentLearningWeekly {...weeklyProps} />}
        </div>
    );
};

export default connect(({
    subjectDetail
}) => ({
    subjectDetail
}))(ParentLearning);
