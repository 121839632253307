import React from 'react';
import styles from '../index.less';

const ScoresTitle = ({ num, title, firstDes, secondDes }) => {
  return (
    <div className={styles.scoresTitle}>
      <div className={styles.topTitle}>
        <div className={styles.num}>{num}.</div>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.desContent}>
        <div className={styles.firstDes}>{firstDes}</div>
        <div className={styles.secondDes}>{secondDes}</div>
      </div>
    </div>
  );
};

export default ScoresTitle;
