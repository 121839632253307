import { message, Modal, Row } from 'antd'
import { IntlManager } from 'utils/helper'
import copy from 'copy-to-clipboard'
import { CheckCircleOutlined } from '@ant-design/icons';

import {
  GetRequestInfoById,
  CheckConsentForm,
  TestTypeChange,
  GetCaseCode,
  SendReportEmail,
  PutNormalData
} from 'services/vrat/testCenter'
import { CaseDetail } from 'services/vrat/cms'
import { GetAllDocTemplate } from 'services/vrat/questionnaire'
import { GetPDFReport } from 'services/vrat/chart'
import { countDown } from 'utils/utils';
import { GetReport } from 'services/vrat'

export default {
  namespace: 'testDetail',

  state: {
    caseDetailInfo: null,
    addCommentsModalVisible: false,
    sendReportModalVisible: false,
    pageType: 'center',
    checkBoxState: false,
    commentTemplate: [],
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *fetch({ payload }, { call, put, select, take }) {
      const { id, pageType } = payload
      const data =
        pageType === 'center'
          ? yield call(GetRequestInfoById, { testRequestId: id })
          : yield call(CaseDetail, id)
      const { data: requestInfoData, response: requestInfoResponse } = data
      if (requestInfoResponse.ok) {
        yield put({
          type: 'save',
          payload: {
            pageType,
            caseDetailInfo: requestInfoData,
            checkBoxState: requestInfoData.NormalData
          },
        })
      }
    },

    *signConsentForm({ payload }, { call, put, select }) {
      const { caseDetailInfo } = yield select(state => state.testDetail)
      const res = yield call(CheckConsentForm, caseDetailInfo.Id)
      if (res.response.ok) {
        const newCaseDetailInfo = { ...caseDetailInfo, IsConsentFormSigned: true }
        yield put({ type: 'save', payload: { caseDetailInfo: newCaseDetailInfo } })
      }
    },
    *getReport({ payload: { caseId } }, { put, call, select }) {
      const { caseDetailInfo } = yield select(state => state.testDetail)
     const res =  yield call(GetReport, {
        caseId: caseId,
      })
      if (res.response.ok) {
        if (location.pathname === '/system/center/detail/vratTestDetail') {
          yield put({ type: 'fetch',payload: { id: caseDetailInfo.Id, pageType: 'center' } })
        }
        if (location.pathname === '/system/manage/vratCaseDetail') {
          yield put({ type: 'fetch',payload: { id: caseDetailInfo.Id, pageType: 'admin' } })
        }
      } else {
        const { data: { Error } } = res;
        message.error(Error.Message);
      }
    },

    *checkBoxData({ payload: { value } }, { call, put, select }) {
      const { caseDetailInfo } = yield select(state => state.testDetail)
      yield put({ type: 'save', payload: { checkBoxState: value } })
      const res = yield call(PutNormalData, { testRequestId: caseDetailInfo.Id, isSelect: value })

      if (!res.response.ok) {
        yield put({ type: 'save', payload: { checkBoxState: !value } })

        const { error } = res.data || { error: { message: "数据获取失败" } }
        message.error(error.message)
      }
    },

    *sendReport({ payload }, { call, put, select }) {
      const formatMessage = IntlManager.intl.formatMessage
      const { caseDetailInfo } = yield select(state => state.testDetail)
      const res = yield call(SendReportEmail, { CaseId: caseDetailInfo.Case.Id, ...payload })
      if (res.response.ok) {
        yield put({ type: 'save', payload: { sendReportModalVisible: false } })
        message.success(formatMessage({ id: 'sendReportSuccessfully' }))
      }
    },

    *SubmitTestType({ payload }, { call, put, select }) {
      const formatMessage = IntlManager.intl.formatMessage
      const { caseDetailInfo } = yield select(state => state.testDetail)
      const postData = {
        caseId: caseDetailInfo.Case.Id,
        testType: payload,
      }
      const res = yield call(TestTypeChange, postData)
      if (res.response.ok) {
        message.success(formatMessage({ id: 'updateSuccessfully' }))
        const newCaseInfo = {
          ...caseDetailInfo,
          Case: { ...caseDetailInfo.Case, TestType: payload },
        }
        yield put({ type: 'save', payload: { caseDetailInfo: newCaseInfo } })
      }
    },

    *onAddCommentModal(_, { call, put, select }) {
      const template = yield call(GetAllDocTemplate)
      const { data, response } = template
      if (response.ok) {
        yield put({
          type: 'save',
          payload: {
            commentTemplate: data,
            addCommentsModalVisible: true,
          },
        })
      }
    },

    viewReport({ payload: data }, { put, call, select }) {
      const url = global.report + '?caseId=' + data.caseId
      var win = window.open(url, '_blank')
      win.focus()
    },

    *downloadReport({ payload: { caseId, intl } }, { call, put, select }) {
      const { caseDetailInfo } = yield select(state => state.testDetail)
      const { Case } = caseDetailInfo
      const hide = message.loading(intl.formatMessage({ id: 'downloading' }), 0);
      yield call(GetPDFReport, caseId, Case.TargetReportVersion)
      setTimeout(hide, 0)
    },

    *getCode({ payload: { type, intl } }, { call, put, select }) {
      const { caseDetailInfo } = yield select(state => state.testDetail)
      const res = yield call(GetCaseCode, { testRequestId: caseDetailInfo.Id })
      const { data, response } = res
      if (response.ok) {
        const { Code, Image } = data
        if (type === 'copy') {
          Modal.confirm({
            title: <div>
              {intl.formatMessage({ id: 'testCode' })}：{Code}
            </div>,
            icon: <CheckCircleOutlined style={{ color: '#52C41A' }} />,
            okText: intl.formatMessage({ id: 'copy' }),
            content: null,
            cancelText: intl.formatMessage({ id: 'close' }),
            onOk() {
              if (copy(Code)) message.success(intl.formatMessage({ id: 'copyed' }))
              else message.error(intl.formatMessage({ id: 'failure' }))
            },
          });
        } else {
          countDown(Image, Code, intl)
        }
      } else {
        const { data: { Error } } = res;
        message.warning(Error.Message);
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/detail/vratTestDetail') {
    //       dispatch({ type: 'fetch', payload: { ...location.query, pageType: 'center' } })
    //     }
    //     if (location.pathname === '/system/manage/vratCaseDetail') {
    //       dispatch({ type: 'fetch', payload: { ...location.query, pageType: 'admin' } })
    //     }
    //   })
    // },
  },
}
