import React from 'react'
import { CloseOutlined } from '@ant-design/icons';
import { connect } from 'umi'
import styles from './index.less'

class FirstSystemNotification extends React.Component {
    componentDidMount() {
        const { dispatch } = this.props
        if (dispatch) {
            dispatch({
                type: 'notification/getFirstSystemNotification'
            })
        }

    }
    onClickFirstSystemNotification = (data) => {
        const { dispatch } = this.props
        const { extraProperties, isRead } = data
        const { Icon } = extraProperties
        const subType = Icon || 'System'
        switch (subType) {
            case 'VratIcon':
                const url = '/system/center/menu/analysis/vrat?tab=Indicators'
                if (!isRead) {
                    dispatch({
                        type: 'notification/signToRead',
                        payload: { data }
                    })
                }
                window.open(url, '_blank')
                break;
            case 'System':
                dispatch({ type: 'notification/updateState', payload: { modalVisible: true, releaseInfo: data } })
                break
        }
    }

    hideFirstSystemNotification = () => {
        const { dispatch } = this.props
        dispatch({ type: 'notification/updateState', payload: { showFirstSystemNotification: false } })
    }

    render() {
        const { firstSystemNotification } = this.props
        const { items } = firstSystemNotification || {}
        return (
            <React.Fragment>
                {
                    items && Array.isArray(items) && items.length > 0 &&
                    <div className={styles.showFirstSystemNotification}>
                        <span onClick={() => this.onClickFirstSystemNotification(items[0])}>{items[0].title}</span>
                        <span className={styles.showFirstSystemNotificationClosed} onClick={this.hideFirstSystemNotification}><CloseOutlined /></span>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default connect(({
    notification: {
        firstSystemNotification
    }
}) => ({
    firstSystemNotification
}))(FirstSystemNotification)