import { useEffect, useRef, useState } from 'react';
import { connect, useIntl, getLocale } from 'umi';
import { Layout, Spin, BackTop, Space } from 'antd';
import { MyContext } from './context';
import { showMenu, hideMenu, ContextMenu } from 'utils/customMenu.js';
import LocaleSelection from 'cognitiveleap-core-us/components/LocaleSelection'
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import CoverPage from './CoverPage';
import WelcomLetters from './WelcomLetters';
import ChildInfo from './ChildInfo';
import FullScore from './FullScore';
import TableOfContents from './TableOfContents';
import OverallResults from './OverallResults';
import AttentionScores from './AttentionScores';
import FirstScoresDes from './FirstScoresDes';
import TwoScoresDes from './TwoScoresDes';
import MentalEndurance from './MentalEndurance';
import MentalEnduranceTwo from './MentalEnduranceTwo';
import RealtimePerformance from './RealtimePerformance';
import RealtimePerformanceTwo from './RealtimePerformanceTwo';
import MotorAttention from './MotorAttention';
import DirectionTracking from './DirectionTracking';
import MovementArea from './MovementArea';
import MovementDistance from './MovementDistance';
// import SummaryOfResult from './SummaryOfResult';
// import SummaryOfResultTwo from './SummaryOfResultTwo';
// import SummaryOfResultNull from './SummaryOfResultNull';
import InterpretationOne from './InterpretationOne';
import InterpretationTwo from './InterpretationTwo';
import InterpretationThree from './InterpretationThree';
import RecommendationOne from './RecommendationOne';
import RecommendationTwo from './RecommendationTwo';
import RecommendationThree from './RecommendationThree';
import RecommendationFour from './RecommendationFour';

import printIcon from 'assets/newVratReportImg/printIcon.png';
import toUpIcon from 'assets/newVratReportImg/toUpIcon.png';
import disablePrintIcon from 'assets/newVratReportImg/disablePrintIcon.png';

import styles from './index.less';

const { Content } = Layout;

const momentLangList = {
  'zh-CN': 'zh-cn',
  'en-US': 'en-us',
  'es-ES': 'es-es'
}

const ReportNew = ({ parentReport, dispatch, location }) => {
  const intl = useIntl();

  // type=download 隐藏打印按钮
  // content=recommendation 显示recommendation PDF
  // model=screen 为筛查版家长报告
  const { type, content, suspension, model = 'parent' } = location.query || {};

  moment.locale(momentLangList[getLocale()]); // 默认的语言环境为英语
  const isScreenReport = model === 'screen';

  const componentRef = useRef();

  // const [printType, setPrintType] = useState('A4');

  const {
    reportVersion,
    basicInfo,
    lineWithIcon,
    continuousAttention,
    directionTracking,
    motionCharacteristics,
    movementPath,
    overView,
    pathLength,
    sixDimension,
    tenantInfo,
    staticQr,
    qrCodeStatus,
    screeningCPTFlags,
    screeningMotionFlags
  } = parentReport;

  const {
    basicLoading,
    overViewLoading,
    lineWithIconLoading,
    sixDimensionLoading,
    continuousAttentionLoading,
    movementPathLoading,
    pathLengthLoading,
    directionTrackingLoading,
    motionCharacteristicsLoading,
    staticQrLoading,
    interpretationQrLoading,
    screeningCPTFlagLoading,
    screeningMotionFlagLoading
  } = parentReport;

  const isComplete = !(
    basicLoading ||
    overViewLoading ||
    lineWithIconLoading ||
    sixDimensionLoading ||
    continuousAttentionLoading ||
    movementPathLoading ||
    pathLengthLoading ||
    directionTrackingLoading ||
    motionCharacteristicsLoading ||
    isScreenReport && (screeningCPTFlagLoading || screeningMotionFlagLoading)
  );

  const ChildInfoProps = {
    data: basicInfo,
    reportVersion,
  };

  const { Subject, Case, LearnMoreImage = '' } = basicInfo || {};
  const { Name: RealName = '' } = Subject || {};
  const { Id: CaseId } = Case || {};

  let Name;
  if (RealName[0] === '*' && RealName[RealName.length - 1] === '*') {
    // *风清扬* -> *风清扬*
    Name = RealName;
  }
  if (RealName.includes(' ')) {
    // Jennifer Wang -> J. W.
    Name =
      RealName.split(' ')
        .map((item) => {
          return item[0]?.toUpperCase() ?? '';
        })
        .join('. ') + '.';
  } else if (RealName.length === 1 && RegExp("[A-Za-z]{1}").test(RealName)) {
    // T -> T.
    Name = RealName + '.';
  } else {
    // 王树林 -> '王**'
    Name = RealName[0] + '**';
  }

  const initCustomMenu = () => {
    const contextMenu = ContextMenu({
      menus: [
        {
          name: intl.formatMessage({ id: 'downLoadReport' }),
          onClick: () => downLoadPdt(),
        },
        {
          name: intl.formatMessage({ id: 'selectLanguage' }),
          children: [
            {
              name: '简体中文',
              active: getLocale() === 'zh-CN',
              onClick: () => {
                dispatch({ type: 'global/changeLang' , payload: 'zh-CN' })
              },
              className: getLocale() === 'zh-CN' ? 'chineseStyle' : '_',
            },
            {
              name: 'English',
              active: getLocale() === 'en-US',
              onClick: () => {
                dispatch({ type: 'global/changeLang' , payload: 'en-US' })
              },
              className: getLocale() === 'en-US' ? 'englishStyle' : '_',
            },
            {
              name: 'Español',
              active: getLocale() === 'es-ES',
              onClick: () => {
                dispatch({ type: 'global/changeLang' , payload: 'es-ES' })
              },
              className: getLocale() === 'es-ES' ? 'englishStyle' : '_',
            }
          ]
        },
        {
          name: intl.formatMessage({ id: 'backTop' }),
          onClick: () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
        },
      ].filter(item => item),
    });
    document.addEventListener('contextmenu', (event) =>
      showMenu(event, contextMenu),
    );
    document.addEventListener('click', (event) => hideMenu(event, contextMenu));
  };

  useEffect (() => {
    if(suspension) {
      initCustomMenu()
    }
  }, [])
  useEffect(() => {
    dispatch({ type: 'parentReport/fetch', payload: location.query });
    // 初始先禁用右键菜单功能，等页面加载完毕会重写右键菜单功能
    if (suspension) {
      document.addEventListener('contextmenu', (event) => event.preventDefault());
      // 禁用浏览器快捷键打印功能
      window.addEventListener('keydown', function (e) {
        if (e.ctrlKey && (e.key === 'p' || e.key === 'P')) {
          e.preventDefault();
          dispatch({
            type: 'parentReport/downLoadPdt',
            payload: location.query
          })
        }
      });
    }
  }, []);


  // 数据汇总数据
  // Overall Index
  const {
    CptNoWeight,
    MotionNoWeight,
    AttentionPerformanceIndex = 0,
    MotionPerformanceIndex = 0,
  } = overView || {};
  const { Accuracy = 0 } = CptNoWeight || {};
  const { Area = 0 } = MotionNoWeight || {};
  const accuracy = (Accuracy * 100).toFixed(1);
  const area = (Area * 100).toFixed(1);
  const attentionPerformance = (AttentionPerformanceIndex * 100).toFixed(1);
  const motionPerformance = (MotionPerformanceIndex * 100).toFixed(1);

  // Motor Attention Scores
  const hyperactivityIndex = motionCharacteristics
    ? (motionCharacteristics.Hyperactivity * 100).toFixed(1)
    : 0;
  const areaIndex = motionCharacteristics
    ? (motionCharacteristics.Area * 100).toFixed(1)
    : 0;

  const Summaries = pathLength ? pathLength.Summarys : [];

  const FindByType = (list, type) => {
    const result = list.find((item) => {
      return item.MotionType === type;
    });
    return result !== undefined ? result : {};
  };

  const PercentileToString = (percentile) => {
    if (percentile === undefined) return 0;
    else return (percentile * 100).toFixed(1);
  };

  const headPercent = PercentileToString(
    FindByType(Summaries, 0).Percentile || 0,
  );

  const {
    Attention = 0,
    SelfControl = 0,
    Reactivity = 0,
    ReactionStability = 0,
    Sensitivity = 0,
    ContinuousAttention = 0,
  } = sixDimension || {};

  const _Attention = Attention * 100;
  const _ContinuousAttention = ContinuousAttention * 100;
  const _Sensitivity = Sensitivity * 100;
  const _ReactionStability = ReactionStability * 100;
  const _Reactivity = Reactivity * 100;
  const _SelfControl = SelfControl * 100;

  const percent = directionTracking ? directionTracking.Percentile * 100 : 0;

  // 总数据
  const summaryDataSource = {
    API: attentionPerformance,
    MPI: motionPerformance,
    FocusedAttention: _Attention,
    SelfControl: _SelfControl,
    Accuracy: accuracy,
    Discernment: _Sensitivity,
    MentalSpeed: _Reactivity,
    MentalStability: _ReactionStability,
    MentalEndurance: _ContinuousAttention,
    FidgetIndex: hyperactivityIndex,
    MotionAreaIndex: areaIndex,
    DirectionTracking: percent,
    MovementDistance: headPercent,
    // MovementPath: area,
  };

  // 小于33的数据，用于SummaryOfResult页面
  const lessSummaryDataSource = Object.keys(summaryDataSource)
    .filter((key) => {
      const value = Math.round(summaryDataSource[key]);
      return value >= 0 && value <= 33;
    })
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: summaryDataSource[key],
      });
    }, {});

  const lessRecommendationOneData = [
    'API',
    'FocusedAttention',
    'Accuracy',
    'MentalSpeed',
    'MentalStability',
  ].filter((key) => {
    const value = Math.round(summaryDataSource[key]);
    return value >= 0 && value <= 33;
  });

  const lessRecommendationTwoData = [
    'API',
    'SelfControl',
    'Discernment',
  ].filter((key) => {
    const value = Math.round(summaryDataSource[key]);
    return value >= 0 && value <= 33;
  });

  const lessRecommendationThreeData = [
    'MPI',
    'MovementDistance',
    'MotionAreaIndex',
    'FidgetIndex',
    'DirectionTracking',
  ].filter((key) => {
    const value = Math.round(summaryDataSource[key]);
    return value >= 0 && value <= 33;
  });

  // const getPrintStyle = () => {
  //   switch (printType) {
  //     case 'A4':
  //       return `
  //         @media print {
  //           @page {
  //             size: A4 portrait;
  //             margin: 0;
  //             padding: 0;
  //           }
  //         }`;
  //     case 'Letter':
  //       return `
  //       @media print {
  //         @page {
  //           size: letter;
  //           margin: 0;
  //           padding: 0;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div {
  //             min-height: 1056px;
  //             min-width: 814px;
  //             max-height: 1056px;
  //             max-width: 814px;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div
  //         .antd-pro-pages-common-parent-report-index-fullScoreResult
  //         .antd-pro-pages-common-parent-report-index-centerContent
  //         .antd-pro-pages-common-parent-report-index-summaryTitle {
  //           margin-bottom: 20px;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div
  //         .antd-pro-pages-common-parent-report-index-realtimePerformance
  //         .antd-pro-pages-common-parent-report-index-centerContent
  //         .antd-pro-pages-common-parent-report-index-titleDes {
  //           margin-top: 20px;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div
  //         .antd-pro-pages-common-parent-report-index-firstScoresDes
  //         .antd-pro-pages-common-parent-report-index-centerContent {
  //           margin-top: 20px;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div
  //         .antd-pro-pages-common-parent-report-index-realtimePerformance
  //         .antd-pro-pages-common-parent-report-index-centerContent
  //         .antd-pro-pages-common-parent-report-index-numDes {
  //           margin-top: 20px;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div
  //         .antd-pro-pages-common-parent-report-index-realtimePerformanceTwo
  //         .antd-pro-pages-common-parent-report-index-centerContent
  //         .antd-pro-pages-common-parent-report-index-title {
  //           margin-top: 25px;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div
  //         .antd-pro-pages-common-parent-report-index-realtimePerformanceTwo
  //         .antd-pro-pages-common-parent-report-index-centerContent
  //         .antd-pro-pages-common-parent-report-index-titleDes {
  //           margin-top: 8px;
  //         }

  //         .antd-pro-pages-common-parent-report-index-reportwrapper
  //         .antd-pro-pages-common-parent-report-index-content
  //         .antd-pro-pages-common-parent-report-index-pageWrapper
  //         .antd-pro-pages-common-parent-report-index-page_div
  //         .antd-pro-pages-common-parent-report-index-interpretationThree
  //         .antd-pro-pages-common-parent-report-index-centerContent
  //         .antd-pro-pages-common-parent-report-index-threeBottomContent {
  //           margin-top: 40px;
  //         }
  //       }`;
  //     default:
  //       return ``;
  //   }
  // };

  // setPrintType('A4');
  // setPrintType('Letter');

  const canPrint = !interpretationQrLoading && isComplete;

  if (canPrint) {
    initCustomMenu();
  }

  const downLoadPdt = () => {
    canPrint && dispatch({ type: 'parentReport/downLoadPdt', payload: location.query });
  }

  return (
    <div>
      {/* <style>{getPrintStyle()}</style> */}
      {/* <BackTop visibilityHeight={1000}>
        <div className={styles.ToUpStyle}>
          <img src={toUpIcon} />
        </div>
      </BackTop> */}
      {suspension && (
        <>
          <div className={styles.suspension}>
            <ul>
              <li className={styles.downLoadStyle} onClick={downLoadPdt}></li>
              {/* {!isAdmin ? <li
                className={styles.sendReportStyle}
                onClick={onSendReport}
              ></li> : null
              } */}
              <li className={styles.selectStyle} id="select">
                <LocaleSelection
                  changeLanguage={(key) =>
                    dispatch({ type: 'global/changeLang', payload: key })
                  }
                  dropDownProps={{
                    getPopupContainer: () => {
                      return document.getElementById('select');
                    },
                    overlayStyle: { width: 80 },
                  }}
                  styles={{width: '64px', height: '64px'}}
                />
              </li>
            </ul>
          </div>
          <BackTop visibilityHeight={900}>
            <div className={styles.backUpStyle}></div>
          </BackTop>
        </>
      )}
      {/* <ReactToPrint
        documentTitle={`vCAT Report for parents - Case${CaseId}`}
        trigger={() => (
          <div
            className={styles.IconStyle}
            style={{ display: type === 'download' && 'none' }}
          >
            <img src={canPrint ? printIcon : disablePrintIcon} />
          </div>
        )}
        content={() => componentRef.current}
        onBeforePrint={() => {
          return new Promise((resolve, reject) => {
            canPrint ? resolve() : reject();
          });
        }}
      /> */}
      {/* <div
        className={styles.IconStyle}
        style={{ display: type === 'download' && 'none' }}
        onClick={downLoadPdt}
      >
        <img src={canPrint ? printIcon : disablePrintIcon} />
      </div> */}
      <MyContext.Provider
        value={{
          Name,
          isScreenReport
        }}
      >
        <Layout ref={componentRef} className={styles.reportwrapper}>
          {content === 'recommendation' ? (
            <Content className={styles.content}>
              <div className={styles.pageWrapper}>
                <RecommendationOne />
              </div>

              <div className={styles.pageWrapper}>
                <RecommendationTwo />
              </div>

              <div className={styles.pageWrapper}>
                <RecommendationThree />
              </div>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={staticQrLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <RecommendationFour qrCode={staticQr} />
                </div>
              </Spin>

              {/* 后端下载匹配字段 */}
              {!staticQrLoading ? (
                <div id={'report ready'} key={'report ready'} />
              ) : null}
            </Content>
          ) : (
            <Content className={styles.content}>
              <Spin
                size="large"
                tip={`Loading...`}
                spinning={basicLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <CoverPage data={tenantInfo} isScreenReport={isScreenReport}/>
                </div>
              </Spin>

              <div className={styles.pageWrapper}>
                <WelcomLetters />
              </div>

              {/* 小于33的数据空时隐藏此页面 */}
              {/* {Object.keys(lessSummaryDataSource).length > 0 && (
            <Spin
              size="large"
              tip={`Loading...`}
              spinning={!isComplete}
              className={styles.spinnerWrapperText}
            >
              <div className={styles.pageWrapper}>
                <SummaryOfResult dataSource={lessSummaryDataSource}/>
              </div>
            </Spin>
          )}

          <Spin
            size="large"
            tip={`Loading...`}
            spinning={!isComplete}
            className={styles.spinnerWrapperText}
          >
            <div className={styles.pageWrapper}>
              {Object.keys(lessSummaryDataSource).length > 0 ? (
                <SummaryOfResultTwo dataSource={lessSummaryDataSource}/>
              ) : (
                <SummaryOfResultNull/>
              )}
            </div>
          </Spin> */}

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={basicLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <ChildInfo {...ChildInfoProps} userName={Name} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={!isComplete}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <FullScore
                    dataSource={summaryDataSource}
                    isScreenReport={isScreenReport}
                    screeningMotionFlags={screeningMotionFlags}
                    screeningCPTFlags={screeningCPTFlags}
                  />
                </div>
              </Spin>

              <div className={styles.pageWrapper}>
                <TableOfContents />
              </div>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={overViewLoading || basicLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <OverallResults data={overView} basicInfo={basicInfo} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={sixDimensionLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <AttentionScores data={sixDimension} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={sixDimensionLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <FirstScoresDes data={sixDimension} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={sixDimensionLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <TwoScoresDes data={sixDimension} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={continuousAttentionLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <MentalEndurance data={continuousAttention} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={continuousAttentionLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <MentalEnduranceTwo data={continuousAttention} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={lineWithIconLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <RealtimePerformance data={lineWithIcon} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={lineWithIconLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <RealtimePerformanceTwo data={lineWithIcon} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={motionCharacteristicsLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <MotorAttention data={motionCharacteristics} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={directionTrackingLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <DirectionTracking data={directionTracking} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={movementPathLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <MovementArea data={movementPath} />
                </div>
              </Spin>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={pathLengthLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <MovementDistance data={pathLength} />
                </div>
              </Spin>

              <div className={styles.pageWrapper}>
                <InterpretationOne />
              </div>

              <div className={styles.pageWrapper}>
                <InterpretationTwo />
              </div>

              <Spin
                size="large"
                tip={`Loading...`}
                spinning={basicLoading}
                className={styles.spinnerWrapperText}
              >
                <div className={styles.pageWrapper}>
                  <InterpretationThree tenantInfo={tenantInfo} qrCode={LearnMoreImage} qrLoading={interpretationQrLoading} qrCodeStatus={qrCodeStatus} />
                </div>
              </Spin>

              {/* {lessRecommendationOneData.length > 0 && (
            <div className={styles.pageWrapper}>
              <RecommendationOne />
            </div>
          )}

          {lessRecommendationTwoData.length > 0 && (
            <div className={styles.pageWrapper}>
              <RecommendationTwo />
            </div>
          )}

          {lessRecommendationThreeData.length > 0 && (
            <div className={styles.pageWrapper}>
              <RecommendationThree />
            </div>
          )}

          {(lessRecommendationOneData.length > 0 ||
            lessRecommendationTwoData.length > 0 ||
            lessRecommendationThreeData.length > 0) && (
            <div className={styles.pageWrapper}>
              <RecommendationFour />
            </div>
          )} */}

              {/* 后端下载匹配字段 */}
              {isComplete ? (
                <div id={'report ready'} key={'report ready'} />
              ) : null}
            </Content>
          )}
        </Layout>
      </MyContext.Provider>
    </div>
  );
};

export default connect(({ parentReport }) => ({ parentReport }))(ReportNew);
