import React from 'react'

import { Menu } from 'antd'

import styles from './NewLocation.less'

const ResourseMenu = ({ currentTab, onChangeTab, centerInfo, currentUser }) => {
  const { adminRoles: [{name} = {}] = [] } = currentUser || {}
  let menuList = ['Database', 'TrainingCourses', 'Course', 'Assessment', 'TrainingPlanLibrary', 'OfflineTrainingPlan', 'TheraLeap', 'VRAT_Assessment', 'School']
  const { projectStatus, chargingMethod } = centerInfo || {}
  if (projectStatus && Array.isArray(projectStatus)) {
    const vratStatusInfo = projectStatus.find(item => item.name === "Project.Vrat")
    const rocketStatusInfo = projectStatus.find(item => item.name === "Project.Rocket")
    if (vratStatusInfo && vratStatusInfo.status) {
      menuList.splice(menuList.length, 0, 'VRAT_Amount')
    }
    if (rocketStatusInfo && rocketStatusInfo.status && chargingMethod === "Contract") {
      menuList.splice(menuList.length, 0, 'HFS_Contract')
    }
    menuList.push('TeamManagement')
  }
  if (name === 'agent') {
    menuList = ['TheraLeap', 'VRAT_Assessment', 'VRAT_Amount'];
  }
  return (
    <Menu
      onClick={onChangeTab}
      selectedKeys={[currentTab]}
      mode="horizontal"
      className={styles.menu}
    >
      {menuList.map(item => {
        return <Menu.Item key={item}>{item}</Menu.Item>
      })}
    </Menu>
  )
}

export default ResourseMenu
