import { connect, useIntl, history } from 'umi'
import { useEffect } from 'react'
import styles from './userList.less'
import centerCss from '../center.less'
import DocumentTitle from 'react-document-title'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { genderLocale, getRandomName } from 'utils/utils'
import React, { useState } from 'react'
import CustomNameInput from 'components/CustomNameInput/CustomNameInput'
import { addOptionsToProperties } from 'cognitiveleap-core-us/utils/utils'
import { Input, Row, Col, Button, Divider } from 'antd'
import moment from 'moment'
import ConfirmModal from 'components/ConfirmChildInfoModal'
const { Search } = Input

const UserList = ({ dispatch, userlist, currentUser, loading, location }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'userlist/loadPage', payload: location.query })
  }, [])

  const { auth, changedTenant } = currentUser

  const institutionType = changedTenant ? changedTenant.institutionType : 'other'
  const { grantedPolicies } = auth

  const hasSubjectWritePermission = grantedPolicies['Business.SubjectManagement.Write'] === true
  const hasAssignCoachPermission = grantedPolicies['Business.SubjectManagement.UserCoach.Assign'] === true

  const { list, pagination, coaches, searchField, modalVisible, avaliableGuardianRoles, confirmModalVisible,
    addSubjectInfo, } = userlist

  const contentMenu = {
    Properties: [
      {
        EditorType: 'CustomRender',
        ShowTitle: intl.formatMessage({ id: 'name' }),
        FormKey: 'name',
        AdditionalData: null,
        Value: '',
        Setting: {
          Required: true,
          render: () => <CustomNameInput />
        },
        Description: null,
      },
      // {
      //   EditorType: 'Input',
      //   ShowTitle: formatMessage({ id: 'name' }),
      //   FormKey: 'name',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     Required: true,
      //   },
      //   Description: null,
      // },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'birthday' }),
        FormKey: 'birthDay',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          DateType: 'date',
          format: 'MM/DD/YYYY',
          DisabledDate: (current) => {
            return current && current > moment()
          }
        },
        Description: intl.formatMessage({ id: 'pleaseCheckCarefully' }),
      },
      {
        EditorType: 'Dropdown',
        Value: 'Male',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'male' }),
              Id: 'Male',
            },
            {
              Value: intl.formatMessage({ id: 'female' }),
              Id: 'Female',
            },
            // {
            //   Value: intl.formatMessage({ id: 'other' }),
            //   Id: 'Others',
            // },
          ],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'gender' }),
        FormKey: 'gender',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: 'Right',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'left' }),
              Id: 'LeftHand',
            },
            {
              Value: intl.formatMessage({ id: 'right' }),
              Id: 'Right',
            },
          ],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'dominantHand' }),
        FormKey: 'dominantHand',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: 'Mother',
        Setting: {
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'relationship' }),
        FormKey: 'guardianRole',
        Description: <div style={{ marginTop: '16px', color: '#F96B4B' }}>{intl.formatMessage({ id: 'submitTip' })}</div>,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'locatedCity' }),
        FormKey: 'city',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false
        },
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: '',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'kindergarten' }),
              Id: '0',
            },
            {
              Value: '1',
              Id: '1',
            },
            {
              Value: '2',
              Id: '2',
            },
            {
              Value: '3',
              Id: '3',
            },
            {
              Value: '4',
              Id: '4',
            },
            {
              Value: '5',
              Id: '5',
            },
            {
              Value: '6',
              Id: '6',
            },
            {
              Value: '7',
              Id: '7',
            },
            {
              Value: '8',
              Id: '8',
            },
            {
              Value: '9',
              Id: '9',
            },
            {
              Value: '10',
              Id: '10',
            },
            {
              Value: '11',
              Id: '11',
            },
            {
              Value: '12',
              Id: '12',
            },
            {
              Value: intl.formatMessage({ id: 'other' }),
              Id: '13',
            },
          ],
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'grade' }),
        FormKey: 'gradeName',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: null,
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'public' }),
              Id: 'Public',
            },
            {
              Value: intl.formatMessage({ id: 'private' }),
              Id: 'Private',
            },
            {
              Value: intl.formatMessage({ id: 'international' }),
              Id: 'International',
            },
            {
              Value: intl.formatMessage({ id: 'notAttendingSchool' }),
              Id: 'NotGoingToSchool',
            },
          ],
          // Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'schoolNature' }),
        FormKey: 'schoolNature',
        Description: null,
      }
      // {
      //   EditorType: 'Input',
      //   ShowTitle: intl.formatMessage({ id: 'name' }),
      //   FormKey: 'name',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     Required: true,
      //   },
      //   Description: null,
      // }
    ],
  }

  const roleOptions = avaliableGuardianRoles.map(item => ({
    Value: item.displayName,
    Id: item.name,
  }))

  if (institutionType === 'Medical') {
    contentMenu.Properties.unshift({
      EditorType: 'Input',
      Value: null,
      AdditionalData: null,
      Setting: {
        Required: false,
      },
      ShowTitle: intl.formatMessage({ id: 'clinicNumber' }),
      FormKey: 'outpatientNumber',
      Description: null,
    })

    contentMenu.Properties.splice(1, 1, {
      EditorType: 'Input',
      ShowTitle: intl.formatMessage({ id: 'name' }),
      FormKey: 'name',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: null,
    })
  }

  if (hasAssignCoachPermission) contentMenu.Properties.push(
    // {
    //   EditorType: 'Dropdown',
    //   Value: null,
    //   Setting: {
    //     DropdownOptions: coaches.map(item => ({
    //       Value: item.name,
    //       Id: item.id,
    //     })),
    //     Required: false,
    //   },
    //   ShowTitle: intl.formatMessage({ id: 'assignTrainers' }),
    //   FormKey: 'coachId',
    //   Description: null,
    // }
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        DropdownOptions: coaches.map((item) => {
          const { id, name, email, isActive, state, roles } = item || {};
          const { displayName } = roles[0] || {};

          const showName = name || intl.formatMessage({ id: 'No Name' });

          if (!isActive) {
            return {
              Value: showName + ' - ' + displayName + ' - ' + email,
              Id: id,
              ShowLabel: showName,
              DisableToolTip: intl.formatMessage({ id: 'AccountNotActivated' }),
            };
          }
          if (state !== 'Effective') {
            return {
              Value: showName + ' - ' + displayName + ' - ' + email,
              Id: id,
              ShowLabel: showName,
              DisableToolTip: intl.formatMessage({ id: 'Invalid Account' }),
            };
          }
          return {
            Value: showName + ' - ' + displayName + ' - ' + email,
            Id: id,
            ShowLabel: showName,
          };
        }),
        Required: false,
        Props: {
          mode: 'multiple',
          optionLabelProp: 'label',
        },
      },
      ShowTitle: intl.formatMessage({ id: 'assignTrainers' }),
      FormKey: 'coachIds',
      Description: null,
    }
  )

  const modalProps = {
    loading: loading.models.userlist,
    title: intl.formatMessage({ id: 'addSubject' }),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'userlist/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      const { guardianRole, coachIds } = value
      const relationshipLocale = avaliableGuardianRoles.find(item => {
        return item.name === guardianRole
      }).displayName
      const coachNames = coachIds && Array.isArray(coachIds) && coachIds.length > 0 ? coachIds.map(item => coaches.find(coacheItem => coacheItem.id === item).name).join('；') : ''
      dispatch({ type: 'userlist/checkSubject', payload: { ...value, relationshipLocale, coachNames } })
    },
    content: addOptionsToProperties(contentMenu, ['guardianRole'], roleOptions),
  }

  const listSupport = {
    showName: {
      showText: intl.formatMessage({ id: 'name' }),
      showType: 'Text',
    },
    localedGender: {
      showText: intl.formatMessage({ id: 'gender' }),
      showType: 'Text',
    },
    phone: {
      showText: intl.formatMessage({ id: 'phone' }),
      showType: 'Text',
    },
    email: {
      showText: intl.formatMessage({ id: 'email' }),
      showType: 'Text',
    },
    picture: {
      showText: intl.formatMessage({ id: 'photo' }),
      showType: 'Image',
    },
    subjectCount: {
      showText: intl.formatMessage({ id: 'subjectCount' }),
      showType: 'Text',
    },
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
      },
    },
    // isBind: {
    //   showText: 'isBind',
    //   showType: 'Bool',
    // },
    // emailConfirmed: {
    //   showText: 'emailConfirmed',
    //   showType: 'Bool',
    // },
    // phoneConfirmed: {
    //   showText: 'phoneConfirmed',
    //   showType: 'Bool',
    // },
    // userStatus: {
    //   showText: '用户状态',
    //   showType: 'Status',
    //   addtional: {
    //     statusArray: ['已激活', '已冻结'],
    //   },
    //   filter: {
    //     hasFilter: true,
    //     filterOptions: [{ text: '已激活', value: 'on' }, { text: '已冻结', value: 'off' }],
    //   },
    // },
    // vipStatus: {
    //   showText: '会员状态',
    //   showType: 'Status',
    //   addtional: {
    //     statusArray: ['非会员', '会员', '过期会员'],
    //   },
    //   filter: {
    //     hasFilter: true,
    //     filterOptions: [
    //       { text: '非会员', value: 'None' },
    //       { text: '会员', value: 'Vip' },
    //       { text: '过期会员', value: 'ExpiredVip ' },
    //     ],
    //   },
    // },
  }

  const onClear = () => {
    dispatch({ type: 'userlist/clearAllFilterAndSorter' })
  }

  const confirmModalProps = {
    loading: loading.effects['userlist/addSubject'],
    subjectInfo: addSubjectInfo,
    visible: confirmModalVisible,
    onCancel() {
      dispatch({ type: 'userlist/updateState', payload: { confirmModalVisible: false } })
    },
    onOk() {
      dispatch({ type: 'userlist/addSubject', })
    },
  }

  const searchProps = {
    placeholder: intl.formatMessage({ id: 'searchGuardian' }),
    onSearch(value) {
      dispatch({ type: 'userlist/filterFetch', payload: { searchField: value } })
    },
    onChange(value) {
      dispatch({ type: 'userlist/updateState', payload: { searchField: value.target.value } })
    },
    value: searchField,
  }

  const showList = list.map(item => {
    const { subjects, gender, id } = item
    return {
      ...item,
      localedGender: genderLocale(gender, intl),
    }
  })

  const tableProps = {
    loading: loading.models.userlist,
    listData: showList.map(item => {
      const { nickName, name } = item
      return {
        ...item,
        showName: nickName || name
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'userlist/changeTable', payload: data })
    },
    scroll: true,
    Actions: [
      hasSubjectWritePermission ? {
        showText: intl.formatMessage({ id: 'addSubject' }),
        itemRender(record) {
          const { isLogout } = record
          return !isLogout ? (
            <a
              onClick={() => {
                dispatch({ type: 'userlist/getGuardianRoleAndShowModal', payload: record })
              }}
            >
              {intl.formatMessage({ id: 'addSubject' })}
            </a>
          )
            :
            null
        }
      } : null,
      {
        showText: intl.formatMessage({ id: 'lookDetail' }),
        itemRender(record) {
          return (
            <a
              onClick={
                () => {
                  history.push({
                    pathname: '/system/center/detail/userDetail',
                    query: {
                      id: record.id,
                    }
                  })
                }
              }
            >
              {intl.formatMessage({ id: 'lookDetail' })}
            </a>
          )
        }
      }
    ],
  }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'guardian.guardianList' })}>
      <div className={styles.box}>
        <h1 className={centerCss.pageName}>{intl.formatMessage({ id: 'guardian.guardianList' })}</h1>
        <Divider />
        <div className={styles.table}>
          <Row type="flex" justify="space-between" gutter={16} style={{ marginBottom: 20 }}>
            <Col lg={10} xl={6}>
              <Row gutter={10}>
                <Col span={18}>
                  <Search {...searchProps} />
                </Col>
                <Col span={2}>
                  <Button onClick={onClear}>{intl.formatMessage({ id: 'clear' })}</Button>
                </Col>
              </Row>
            </Col>
            <Col>{
              hasSubjectWritePermission ? <Button
                type="primary"
                onClick={() => {
                  history.push('/system/vratProcess?type=addParent')
                  //window.open('/system/center/addUser', '_blank')
                }}
              >
                {intl.formatMessage({ id: 'addGuardian' })}
              </Button> : null
            }
            </Col>
          </Row>
          <MagicTable {...tableProps} />
          <MagicFormModal {...modalProps} />
          <ConfirmModal {...confirmModalProps} />
        </div>
      </div>
    </DocumentTitle>
  )
}

export default connect(({ userlist, user, loading }) => ({ userlist, currentUser: user.currentUser, loading }))(UserList)
