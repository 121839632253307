import React from 'react'
import { Spin, Form, Input, Button } from 'antd'
import { connect } from 'umi'
import { useIntl } from 'umi'
import styles from './index.less'

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
}

const ChangePassword = ({ changePassword, dispatch }) => {
    const intl = useIntl();

    const onSubmit = (values) => {
        dispatch({ type: 'changePassword/changePassword', payload: values })
    }

    return (
        <div className={styles.password}>
            <div className={styles.title}>修改密码</div>
            <div className={styles.content}>
                <Form
                    onFinish={onSubmit}
                    {...layout}
                >
                    <Form.Item
                        label={'旧密码'}
                        name='oldPassword'
                        rules={[{ required: true, message: `旧密码 ${intl.formatMessage({ id: 'isRequired' })}` }]}
                    >
                        <Input.Password placeholder={'oldPassword'} />
                    </Form.Item>
                    <Form.Item
                        label={'新密码'}
                        name='newPassword'
                        rules={[{ required: true, message: `新密码 ${intl.formatMessage({ id: 'isRequired' })}` }]}
                    >
                        <Input.Password placeholder={'password'} />
                    </Form.Item>
                    <Form.Item
                        label={'再次输入新密码'}
                        name='confirmPassword'
                        rules={[{ required: true, message: `再次输入新密码 ${intl.formatMessage({ id: 'isRequired' })}` }]}
                    >
                        <Input.Password placeholder={'confirmPassword'} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={styles.btn}
                        >
                            确认修改
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default connect(({ changePassword }) => ({ changePassword }))(ChangePassword)