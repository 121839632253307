import React from 'react';
import styles from './index.less';
import { Row, Col } from 'antd';
import { useIntl } from 'umi';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import PathLengthChart from 'components/NewVratChartComponents/PathLengthChart';
import PathLengthChartText from 'components/NewVratChartComponents/PathLengthChartText';
import PathLengthLegend from 'components/NewVratChartComponents/PathLengthLegend';
import BottomPageNum from './components/BottomPageNum';
import headIcon from 'assets/vratReportImg/head_small_icon.png';
import handIcon from 'assets/vratReportImg/hand_small_icon.png';

const MovementDistance = ({ data }) => {
  const intl = useIntl();

  const PathLength = data ? data.PathLength : [];
  const Summaries = data ? data.Summarys : [];

  // type 0->3['Head','Hand','Chest','Leg']
  const GetByType = (list, type) => {
    const result = list.filter((item) => {
      return item.MotionType === type;
    });
    return result;
  };

  // type 0->3 ['Head','Hand','Chest','Leg']
  const FindByType = (list, type) => {
    const result = list.find((item) => {
      return item.MotionType === type;
    });
    return result !== undefined ? result : {};
  };

  const PercentileToString = (percentile) => {
    if (percentile === undefined) return '';
    else return `${(percentile * 100).toFixed(1)}%`;
  };

  const testDataHead = GetByType(PathLength, 0);
  const testDataHand = GetByType(PathLength, 1);

  // 折线图部分
  const PathLengthHeadChartProps = {
    data: testDataHead,
    motionText: intl.formatMessage({ id: 'MovementDistance.HeadMovement' }),
  };

  const PathLengthHandChartProps = {
    data: testDataHand,
    motionText: intl.formatMessage({ id: 'MovementDistance.HandMovement' }),
  };

  function getColor(percent) {
    if (percent <= 33) {
      return '#F3766E';
    } else if (percent > 33 && percent <= 67) {
      return '#FFD352';
    } else if (percent >= 67) {
      return '#42BF80';
    }
  }

  // 折线图下方icon text显示部分
  // 如果百分比在0-33，红色，百分比33-67，黄色，67-100，绿色
  const percent0 = PercentileToString(FindByType(Summaries, 0).Percentile || 0);
  const percent1 = PercentileToString(FindByType(Summaries, 1).Percentile || 0);

  const PathLengthHeadChartTextProps = {
    data: {
      percentInfo: {
        percent: percent0,
        color: getColor(percent0.slice(0, percent0.length - 1)),
      },
    },
    motionIcon: headIcon,
    motion: intl.formatMessage({ id: 'MovementDistance.HeadMovement' }),
  };
  const PathLengthHandChartTextProps = {
    data: {
      percentInfo: {
        percent: percent1,
        color: getColor(percent1.slice(0, percent1.length - 1)),
      },
    },
    motionIcon: handIcon,
    motion: intl.formatMessage({ id: 'MovementDistance.HandMovement' }),
  };

  // 头部超出平均值数据 testDataHead
  const filterData = testDataHead.filter((item) => item.Block !== 0);
  const overMedianData = filterData.reduce((pre, curr) => {
    const { Block, Value } = curr || {};

    const findData =
      filterData.find((item) => item.Block == Block && !item.IsMedian) || {};
    const { Value: findValue } = findData;

    if (findValue > Value) {
      pre.push(findData);
    } else {
    }
    return pre;
  }, []);

  const showHeadText = () => {
    const textArr = overMedianData.map((item) => {
      const { Block } = item || {};
      if (Block === 1) {
        return intl.formatMessage({ id: 'FirstBlock' });
      } else if (Block === 2) {
        return intl.formatMessage({ id: 'TwoBlock' });
      } else if (Block === 3) {
        return intl.formatMessage({ id: 'ThreeBlock' });
      } else if (Block === 4) {
        return intl.formatMessage({ id: 'FourBlock' });
      }
    }).filter(item => item);

    const length = textArr.length;

    if (length === 0) {
      return intl.formatMessage({ id: 'MovementDistance.Remained' });
    } else if (length === 4) {
      return intl.formatMessage({ id: 'MovementDistance.Exceeded' });
    } else if (length === 3) {
      return intl.formatMessage({ id: 'MovementDistance.SomeExceeded' }, { result: `${textArr[0]}, ${textArr[1]}, and ${textArr[2]}` });
    } else if (length === 1) {
      return intl.formatMessage({ id: 'MovementDistance.SomeExceeded' }, { result: `${textArr[0]}` });
    } else {
      return intl.formatMessage({ id: 'MovementDistance.SomeExceeded' }, { result: `${textArr.join(
        ' and ',
      )}` });
    }
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.movementDistance}>
        <HeaderLine color="#3DBD7D" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'MovementDistance' })}</div>
          <div className={styles.titleDes}>
            <div className={styles.desBold}>
              {intl.formatMessage({ id: 'MovementDistance.DesBold' })}
            </div>

            <div>
              {intl.formatMessage({ id: 'MovementDistance.The' })} <span className={styles.blueLines}>{intl.formatMessage({ id: 'MovementDistance.BlueLine' })}</span>
              {intl.formatMessage({ id: 'MovementDistance.FirstDes' })}{' '}
              {intl.formatMessage({ id: 'MovementDistance.The' })}{' '}
              <span className={styles.greenLines}>{intl.formatMessage({ id: 'MovementDistance.GreenLine' })} </span>
              {intl.formatMessage({ id: 'MovementDistance.TwoDes' })}
            </div>
          </div>

          <Row
            style={{ marginTop: 50, width: '100%' }}
          >
            <Col span={6} offset={16}>
              <PathLengthLegend />
            </Col>
          </Row>
          <Row
            type="flex"
            justify="center"
            align="center"
          >
            <Col span={12}>
              <PathLengthChart {...PathLengthHeadChartProps} />
              <PathLengthChartText {...PathLengthHeadChartTextProps} />
            </Col>
            <Col span={12}>
              <PathLengthChart {...PathLengthHandChartProps} />
              <PathLengthChartText {...PathLengthHandChartTextProps} />
            </Col>
          </Row>

          <div className={styles.distanceBottomDes}>
            {intl.formatMessage({ id: 'MovementDistance.BottomDes' })}
            <div className={styles.bottomBold}>{showHeadText()}</div>
          </div>
        </div>
        <BottomPageNum num={17} />
        <BottomDes color="#3DBD7D" />
      </div>
    </div>
  );
};

export default MovementDistance;
