import moment from 'moment'
import { ManOutlined, WomanOutlined, UserOutlined } from '@ant-design/icons';
import { useIntl } from 'umi'

const ChildInfo = ({ childInfo, grade = '' }) => {
  const intl = useIntl()
  const { BirthDay = '', Gender = 0, Name } = childInfo
  const birthday = moment(BirthDay).isValid() ? moment(BirthDay).format('MM-DD-YYYY') : ''
  const genderToIcon = gender => {
    switch (gender) {
      case 'Male':
        return <ManOutlined style={{ fontSize: 18, color: '#3399FF' }} /> 
      case 'Female':
        return <WomanOutlined style={{ fontSize: 18, color: '#FF6699' }} />
      default:
        return <UserOutlined style={{ fontSize: 18 }} />
    }
  }

  const switchGrade = grade => {
    switch (grade) {
      case 'Before School':
        return intl.formatMessage({ id: 'beforeSchool' })
      case 'Not Attending School':
      case '13':
        return intl.formatMessage({ id: 'other' })
      default:
        return grade
    }
  }
  return (
    <div style={{ marginLeft: 12, marginTop: 18 }}>
      <span style={{ fontSize: 20 }}>{Name}</span>
      <span style={{ marginLeft: 8 }}>{genderToIcon(Gender)}</span>
      <span style={{ marginLeft: 8 }}>
        {'( ' + birthday + ' / ' + intl.formatMessage({ id: 'grade' }) + switchGrade(grade) + ' )'}
      </span>
    </div>
  )
}

export default ChildInfo
