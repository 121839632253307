import { message, Modal } from "antd";
import moment from 'moment'
import { GetNotification, PostSignToRead, PostSignAllToRead } from 'services/notification'
import { getNotificationCount } from 'utils/utils'

const removeSomeData = (data) => {
  let init = {};
  let newData = [];

  if (data.length < 1) return data;

  data.forEach(item => {
    !init[item.id] && (init[item.id] = true) && (item.isRead == false) && newData.push(item)
  });

  newData = newData.sort((pre, next) => moment(next.creationTime) - moment(pre.creationTime))
  return newData
}

const notification = {
  namespace: 'notification',

  state: {
    visible: false,
    activeKey: 'User',
    unreadUserCount: 0,
    unreadSystemCount: 0,
    data: {
      User: [],
      System: []
    },
    pagination: {
      User: {
        current: 1,
        pageSize: 30,
        total: 0,
      },
      System: {
        current: 1,
        pageSize: 30,
        total: 0,
      }
    },
    modalVisible: false,
    releaseInfo: null,
    firstSystemNotification: null,
    showFirstSystemNotification: true
  },

  effects: {
    *load(_, { call, put, select }) {
      const { data, pagination, activeKey } = yield select(state => state.notification);
      const { current, pageSize } = pagination[activeKey]

      const SkipCount = (current - 1) * pageSize
      const MaxResultCount = pageSize

      const params = {
        Type: activeKey,
        SkipCount,
        MaxResultCount,
        IsRead: false
      }

      const res = yield call(GetNotification, params)

      if (res.response.ok) {
        const newData = [
          ...data[activeKey],
          ...res.data.items
        ]

        const newPagination = {
          ...pagination,
          [activeKey]: {
            ...pagination[activeKey],
            total: res.data.totalCount
          }
        }

        yield put({
          type: 'updateState',
          payload: {
            data: {
              ...data,
              [activeKey]: removeSomeData(newData)
            },
            pagination: newPagination
          }
        })
      }
    },

    *onTabClick({ payload: { activeKey } }, { call, put, select }) {
      const { pagination } = yield select(state => state.notification);
      const newPagination = {
        ...pagination,
        [activeKey]: {
          ...pagination[activeKey],
          current: 1,
          pageSize: 30,
          total: 0,
        }
      }

      yield put({ type: 'updateState', payload: { activeKey, pagination: newPagination } })
      yield put({ type: 'load' })
    },

    *changePagination({ payload: _ }, { call, put, select }) {
      const { pagination, activeKey } = yield select(state => state.notification);
      const newPagination = {
        ...pagination,
        [activeKey]: {
          ...pagination[activeKey],
          current: pagination[activeKey].current + 1
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          pagination: newPagination
        }
      })

      yield put({ type: 'load' })
    },

    *signToRead({ payload: { data } }, { call, put, select }) {
      const { data: dataSource, activeKey } = yield select(state => state.notification);
      const typeData = JSON.parse(JSON.stringify(dataSource[activeKey]))

      const params = {
        messageIds: [data.id]
      }
      const res = yield call(PostSignToRead, params)

      if (res.response.ok) {
        getNotificationCount()
        yield put({ type: 'notificationCenter/load' })
        yield put({ type: 'getFirstSystemNotification' })
        typeData.forEach(item => {
          if (item.id == data.id) {
            item.isRead = true
          }
        })

        const newData = {
          ...dataSource,
          [activeKey]: removeSomeData(typeData)
        }
        
        yield put({
          type: 'updateState',
          payload: {
            data: newData
          }
        })
      }
    },

    *signAllToHasRead({ payload }, { call, put, select }){
      const { key } = payload || {}
      
      const { data: dataSource, activeKey: notificationActiveKey} = yield select(state => state.notification);

      const activeKey = key || notificationActiveKey 

      const typeData = JSON.parse(JSON.stringify(dataSource[activeKey]))
      
      const res = yield call(PostSignAllToRead, { type: activeKey })

      if (res.response.ok) {
        getNotificationCount()
        yield put({ type: 'notificationCenter/load' })
        yield put({ type: 'getFirstSystemNotification' })
        typeData.forEach(item => {
          item.isRead = true
        })

        const newData = {
          ...dataSource,
          [activeKey]: removeSomeData(typeData)
        }
        
        yield put({
          type: 'updateState',
          payload: {
            data: newData
          }
        })

      }

    },

    *getFirstSystemNotification(_, { call, put, select }){
      const params = {
        Type: 'System',
        SkipCount: 0,
        MaxResultCount: 1,
        IsRead: false,
        StartDate: moment().add(-30, 'd').format()
      }
      const res = yield call(GetNotification, params)
      if(res.response.ok){
        yield put({ type: 'updateState', payload: { firstSystemNotification: res.data } })
      }
    }
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  subscriptions: {

  },
}

export default notification