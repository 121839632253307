import pages from 'utils/pages'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { GetTenantList } from 'services/tenant'
import { message } from 'antd'
const { centerListEnterprise } = pages

export default {
  namespace: 'centerListEnterprise',

  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  },

  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.centerListEnterprise)
      let query = { skipCount: 0, maxResultCount: 10, Type: 'Center' }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetTenantList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === centerListEnterprise) {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
