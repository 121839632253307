import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Divider,
  Card,
  message,
  Empty,
  Button,
  Skeleton,
  Space,
  Row,
  Col,
  Tooltip,
  Spin
} from 'antd';
import { connect, history, useIntl } from 'umi';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { SwiperSlide } from 'swiper/swiper-react';
import NumberAnimate from 'cognitiveleap-core-us/components/Animate/Number';
import AssessmentSelectModal from 'components/SelectModal';
import CustomSwiper from 'components/CustomSwiper';
import TrainingCom from './TrainingCom';
import { downloadQr, getFormatDate, fixedZero, getUserImg } from 'utils/utils';

import firstImg from 'assets/dataPanel/icon1.png';
import threeImg from 'assets/dataPanel/icon3.png';
import fourImg from 'assets/dataPanel/icon4.png';
import fiveImg from 'assets/dataPanel/icon5.png';
import sixImg from 'assets/dataPanel/icon6.png';
import sevenImg from 'assets/dataPanel/icon7.png';
import eightImg from 'assets/dataPanel/icon8.png';
import niceImg from 'assets/dataPanel/icon9.png';
import tenImg from 'assets/dataPanel/icon10.png';
import elevenImg from 'assets/dataPanel/icon11.png';

import brainLogo from 'assets/brainLogo.png';

import {
  GetTrainerDetail,
  GetTrainerModuleList,
  GetTrainingApplication,
  PostModuleCourseCount
} from 'services/trainingSystem';
import {
  GetPlanListOverView
} from 'services/rocketService/UserOfflinePlan'
import {
  GetChildrenTotalData,
  GetILSTotalData,
  GetTotalData,
} from 'services/rocketService/Analysis';
import {
  GetCenterSubjectList,
  PostUserList,
  GetRecordChild,
} from 'services/userManager';
import { GetArrangeCourse } from 'services/arrangeCourse';
import { GetEnoughVratNum } from 'services/vrat';

import styles from './index.less';

const WorkTable = ({ questionnaireList, dispatch, loading, currentUser }) => {
  const intl = useIntl();

  const [planList, setPlanList] = useState([]);
  const [canShow, setCanShow] = useState(true);
  const [hostRoles, setHostRoles] = useState([]);
  const [trainList, setTrainList] = useState([]);
  const [childNum, setChildNum] = useState({});
  const [classNum, setClassNum] = useState({});
  const [childList, setChildList] = useState([]); // 儿童列表
  const [recordChild, setRecordChild] = useState([]); // 最近儿童记录
  const [classDetail, setClassDetail] = useState([]);
  const [classHoursTotal, setClassHoursTotal] = useState({}) //本中心课时数 + 全部课时数 
  const [headerLoading, setHeaderLoading] = useState(true);
  const [trainListLoading, setTrainListLoading] = useState(true);
  const [childListLoading, setChildListLoading] = useState(false);
  const [classListLoading, setClassListLoading] = useState(true);
  const [planListLoading, setPlanListLoading] = useState(true);

  const getShowCardNum = () => {
    const screenWidth = screen.width;

    if (screenWidth <= 1100) {
      return 1;
    }
    if (screenWidth <= 1500) {
      return 2;
    }
    return 3;
  }

  const [planListPagination, setPlanListPagination] = useState({
    current: 1,
    pageSize: getShowCardNum(),
    total: 0
  })

  const [feedbackText, setFeedbackText] = useState(null);

  const { changedTenant, current, userAllTenant, staff, auth } =
    currentUser || {};
  const { id: currentId, roles } = current || {};
  const { grantedPolicies } = auth || {};
  const hfsKey =
    grantedPolicies['RocketSystem.TenantContractManagement'] === true; // HFS费用概况
  const childListKey = grantedPolicies['Business.SubjectManagement'] === true; // 儿童列表
  const vratKey =
    grantedPolicies['VrClassroom.TestRequest.Statistics'] === true; // VRAT数据统计
  const questionListKey =
    grantedPolicies['RocketSystem.UserAssessment'] === true; // 问卷测评列表
  const headKey =
    grantedPolicies['RocketSystem.UserOfflinePlan.Analysis'] === true; // 优脑分析
  const childKey =
    grantedPolicies['Business.SubjectManagement.Analysis'] === true; // 儿童用户分析
  const hasSubjectWritePermission =
    grantedPolicies['Business.SubjectManagement.Write'] === true; // 添加家长
  const coreSessionMenuKey =
    grantedPolicies['Menu.TrainingManagement.CoreSessionLibrary'] === true; // Core Session Library
  const hasAddVRATCasePermission = grantedPolicies['VrClassroom.TestRequest.Create'] === true
  const hasReadPermission =
    grantedPolicies['RocketSystem.UserAssessment'] === true; // 测评二维码
  const hasCalendarPermission = grantedPolicies['Business.Calendar'] === true; // 课程
  const hasAddVratTestPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.AddVratTest'] === true; // 添加vCAT测试
  const hasSubjectListPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.SubjectList'] === true; // 儿童列表
  const hasAddGuardianPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.AddGuardian'] === true; // 添加家长
  const hasAssessmentQRCodePermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.AssessmentQRCode'] === true; // 测评二维码
  const hasUserAssessmentListPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.UserAssessmentList'] === true; // 问卷测评列表
  const hasHFSOrderOverViewPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.HFSOrderOverView'] === true; // HFS费用概况
  const hasCoreSessionLibraryPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.CoreSessionLibrary'] === true; // Core Session Library
  const hasVartStatisticsPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.VartStatistics'] === true; // vCAT数据统计
  const hasIlsTrainingAnalysisPermission = grantedPolicies['PFE.Overview.Workbench.CommonFunction.IlsTrainingAnalysis'] === true; // 优脑训练分析
  const hasTrainingProgressPermission = grantedPolicies['PFE.Overview.Workbench.TrainingProgress'] // 培训进展
  const hasRecentSubjectRecordPermission = grantedPolicies['PFE.Overview.Workbench.RecentSubjectRecord'] // 最近的儿童记录
  const hasTodayCalendarPermission = grantedPolicies['PFE.Overview.Workbench.TodayCalendar'] // 今日课时
  const hasCenterCourseQuantityPermission =
    grantedPolicies['PFE.Overview.Workbench.KeyData.CenterCourseQuantity'] === true; // 本中心课时数
  const hasCenterCumulativeAuthorizationPermission =
    grantedPolicies['PFE.Overview.Workbench.KeyData.CenterCumulativeAuthorization'] === true; // 本中心儿童数
  const hasCourseQuantityTotalPermission =
    grantedPolicies['PFE.Overview.Workbench.KeyData.CourseQuantityTotal'] === true; // 全部课时数
  const hasTotalCumulativeAuthorizationPermission =
    grantedPolicies['PFE.Overview.Workbench.KeyData.CumulativeAuthorizationTotal'] === true; // 全部儿童数
  const { id: TrainerId, name, picture = {} } = staff || {};
  const url = picture && JSON.stringify(picture) !== '{}' ? picture.url : '';
  const currentInfo = userAllTenant.find((item) => item.id === currentId);
  const rolesName = roles ? roles[0].name : [];

  const getChildNum = async () => {
    const childRes = await GetChildrenTotalData(); // 儿童数量
    if (childRes.response.ok) {
      setChildNum(childRes.data);
    } else {
      const { error } = childRes.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getUserNum = async () => {
    const classRes = await GetILSTotalData(); // 课时数量
    if (classRes.response.ok) {
      setClassNum(classRes.data);
    } else {
      const { error } = classRes.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  // 课时数量
  const getTotalUserNum = async () => {
  // "offlineCourseQuantity": 0, 线下代课数
  // "onlineCourseQuantity": 0, 线上代课数
  // "tenantOfflineCourseQuantity": 0, 中心线下代课数
  // "tenantOnlineCourseQuantity": 0, 中心线上代课数
    const res = await GetTotalData();
    if(res.response.ok) {
      setClassHoursTotal(res.data)
    } else {
        const { error } = res.data || { error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` } };
        message.error(error.message);
      }
  }

  const getHeaderData = async () => {
    const res = await GetTrainerDetail({ TrainerId }); // 角色勋章等

    if (res.response.ok) {
      setHeaderLoading(false);
      setHostRoles(
        res.data.trainerCredentialses.filter((item) => {
          return item.trainingModule.qualificationMedals.length > 0;
        }),
      );
    } else {
      const { error } = res.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getTrainListData = async () => {
    const res = await Promise.all([
      await GetTrainerModuleList(), // 培训进展
      await GetTrainingApplication(),
    ]);

    if (res.every((item) => item.response.ok)) {
      let trainRes = res[0]?.data || [];

      setTrainList(trainRes);
      if (!res[1].data.questionnaireAndAnswer) {
        setFeedbackText(`${intl.formatMessage({ id: 'trainingNotEdit' })}`);
      }

      setTrainListLoading(false);

      const learnTrainData = trainRes.filter(item => item.status === 'Learning') || [];

      const courseListRes = await PostModuleCourseCount({
        trainerTrainingModuleIds: learnTrainData.map(item => item?.id)
      })

      if (courseListRes.response.ok) {
        trainRes = trainRes.map(item => {
          const { status, trainingModule, id } = item || {};

          if (status === 'Learning') {
            const currentCourseList = courseListRes?.data?.find((course) => {
              const { trainerTrainingModuleId } = course || {};
              return trainerTrainingModuleId === id;
            }) || [];

            return {
              ...item,
              trainingModule: {
                ...trainingModule,
                courses: currentCourseList
              }
            }
          }

          return item;
        });
      }

      setTrainList(trainRes);
    }
  };

  const getChildListData = async () => {
    const childListRes = await GetCenterSubjectList({
      maxResultCount: 1,
      skipCount: 0,
    });

    if (childListRes.response.ok) {
      setChildList(childListRes.data);
      setChildListLoading(false);
    } else {
      const { error } = childListRes.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getClassListData = async () => {
    let params = {
      startTime: getFormatDate(moment(), true),
      endTime: getFormatDate(moment(), false),
      tenantId: currentId,
    };
    if (rolesName.includes('CenterAdmin') || rolesName.includes('FrontDesk')) {
      const res = await PostUserList({
        IsActive: true,
        State: 'Effective',
        Roles: ['Coach', 'CenterAdmin'],
        MaxResultCount: 1000,
      });
      if (res.response.ok) {
        let coachIds = [];
        const data = res.data.items;
        data.forEach((item) => {
          coachIds.push(item.id);
        });
        params = {
          ...params,
          coachIds,
        };
      }
    }

    const classDetail = await GetArrangeCourse(params);

    if (classDetail.response.ok) {
      const newData = classDetail.data.sort((a, b) => {
        const { startTime: aStartTime } = a;
        const { startTime: bStartTime } = b;
        if (moment(aStartTime).hours() === moment(bStartTime).hours()) {
          return moment(aStartTime).minutes() - moment(bStartTime).minutes();
        }
        return moment(aStartTime).hours() - moment(bStartTime).hours();
      });
      setClassDetail(newData);
      setClassListLoading(false);
    } else {
      const { error } = classDetail.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getRecordChildListData = async () => {
    const res = await GetRecordChild();
    if (res.response.ok) {
      setRecordChild(res.data);
    } else {
      const { error } = res.data || {
        error: { message: `${intl.formatMessage({ id: 'getDataFail' })}` },
      };
      message.error(error.message);
    }
  };

  const getPlanListData = async () => {
    setPlanListLoading(true);

    const { current, pageSize } = planListPagination || {}
    const res = await GetPlanListOverView({
      SkipCount: (current - 1) * pageSize,
      MaxResultCount: pageSize,
    })

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};

      setPlanList(items);
      setPlanListPagination({
        ...planListPagination,
        total: totalCount,
      })
    }

    setPlanListLoading(false);
  }

  const getData = () => {
    getHeaderData();
    childKey && hasCenterCumulativeAuthorizationPermission && getChildNum();
    headKey && hasCenterCourseQuantityPermission && getUserNum();
    headKey && hasCenterCourseQuantityPermission&& hasCourseQuantityTotalPermission && getTotalUserNum();
    hasTrainingProgressPermission && getTrainListData();
    childListKey && hasRecentSubjectRecordPermission && getChildListData();
    childListKey && hasRecentSubjectRecordPermission && getRecordChildListData();
    hasCalendarPermission && hasTodayCalendarPermission && getClassListData();
  };

  useEffect(() => {
    getPlanListData();
  }, [planListPagination.current])

  useEffect(() => {
    // dispatch({ type: 'questionnaireList/loadPage' });
    getData();
  }, []);

  const { selectAssessmentModalVisible, transferList } = questionnaireList;

  const selectAssessmentModalProps = {
    defaultSelectKeys: [],
    title: `${intl.formatMessage({ id: 'addEvaluationQrCode' })}`,
    loading: loading.effects['questionnaireList/uploadAssessmentQr'],
    allList: transferList,
    selectModalVisible: selectAssessmentModalVisible,
    onOk(keys) {
      dispatch({
        type: 'questionnaireList/updateState',
        payload: { selectAssessmentModalVisible: false },
      });
      if (typeof keys !== 'undefined') {
        keys.forEach((item) => {
          dispatch({
            type: 'questionnaireList/uploadAssessmentQr',
            payload: { key: item },
          }).then((res) => {
            const currentAssessment = transferList.filter(
              (listItem) => item == listItem.id,
            );
            const title = currentAssessment[0].title;
            const role =
              currentInfo &&
              currentInfo.roles &&
              currentInfo.roles[0] &&
              currentInfo.roles[0].name;
            downloadQr({
              type: 'base64',
              data: res.data,
              title,
              subTitle: `${intl.formatMessage({ id: 'useWechatSee' })}`,
              description:
                role === 'Coach'
                  ? `${changedTenant.displayName}${name ? '_' + name : ''}`
                  : changedTenant.displayName,
              downloadName: `${
                changedTenant.displayName
              }_${title}_${intl.formatMessage({
                id: 'QRCode',
              })}_${moment().format('YYYYMMDD')}`,
              imgType: 'questionnaire',
            });
          });
        });
      } else {
        message.error(`${intl.formatMessage({ id: 'selectOption' })}`);
        return;
      }
    },
    onCancel() {
      dispatch({
        type: 'questionnaireList/updateState',
        payload: { selectAssessmentModalVisible: false },
      });
    },
  };

  // 是否过期
  const isStatusActive = (endTime) => {
    return moment().isSameOrBefore(moment(endTime));
  };

  // 获取学习状态
  const getStatus = (status, order, courses = {}) => {
    if (status === 'Learning') {
      const { length = 0, passCount = 0 } = courses;
      return `${intl.formatMessage({ id: 'trainingStudy' })} ${passCount}/${length}`;
    } else if (status === 'NotStarted') {
      if (order) {
        if (order.status === 'NotPay') {
          return `${intl.formatMessage({ id: 'trainingNotPay' })}`;
        } else if (order.status === 'Paid') {
          return `${intl.formatMessage({ id: 'trainingNotStart' })}`;
        }
      } else {
        return `${intl.formatMessage({ id: 'trainingNotStart' })}`;
      }
    } else if (status === 'Expired') {
      return `${intl.formatMessage({ id: 'trainingExpired' })}`;
    } else if (status === 'Stopped') {
      return `${intl.formatMessage({ id: 'trainingStop' })}`;
    } else {
      return ''
    }
  };

  // 获取证书状态
  const issued = (data, id) => {
    if (data) {
      data.sort((a, b) => {
        const aTime = a.creationTime;
        const bTime = b.creationTime;
        return moment(aTime) < moment(bTime) ? 1 : -1;
      });
      const newData =
        data && data.length > 0
          ? data.find(
              (item) => item.trainingModule && item.trainingModule.id === id,
            )
          : null;
      if (newData) {
        if (isStatusActive(newData.endTime)) {
          return `${intl.formatMessage({ id: 'qualified' })}`;
        } else {
          return `${intl.formatMessage({ id: 'qualificationExpired' })}`;
        }
      }
    }
    return `${intl.formatMessage({ id: 'notQualified' })}`;
  };

  const addEasyVrat = async () => {
    const res = await GetEnoughVratNum();
    if (res.response.ok) {
      if (res.data) {
        history.push('/system/vratProcess')
      } else {
        message.error(intl.formatMessage({ id: 'addVratError' }))
      }
    } else {
      const { error } = res.data
      message.error(error.message)
    }
  }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'desktop' })}>
      <div className={styles.workTable}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            {/* <div className={styles.leftImg}>
              <img src={url !== '' ? url : userStaff} />
            </div> */}
            {getUserImg(staff, { height: '50px', width: '50px' })}
            <div className={styles.leftInfo}>
              <div className={styles.userName}>
                {intl.formatMessage({ id: 'hey' })}，{name}{' '}
              </div>
              {hostRoles.length === 0 && headerLoading && (
                <Space className={styles.skeletonHeader}>
                  <Skeleton.Button
                    active={true}
                    size={'small'}
                    shape={'default'}
                  />
                  <Skeleton.Button
                    active={true}
                    size={'small'}
                    shape={'default'}
                  />
                </Space>
              )}
              {(!headerLoading || hostRoles.length > 0) && (
                <div className={styles.roles}>
                  {hostRoles.map((role) => {
                    return (
                      isStatusActive(role.endTime) && (
                        <div className={styles.rolesName} key={role.id}>
                          {role.trainingModule.qualificationMedals.map(
                            (item) => {
                              return item.displayName;
                            },
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {!rolesName.includes('FrontDesk') && (
            <div className={styles.headerRight}>
              <Space split={<Divider type="vertical" className={styles.divider} />}>
                {headKey && hasCenterCourseQuantityPermission && (
                  <div className={styles.rightChild}>
                    <div className={styles.name}>
                      {intl.formatMessage({ id: 'centerClassNum' })}{' '}
                      <Tooltip
                        title={intl.formatMessage({ id: 'yourCenterClassNum' })}
                      >
                        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div className={styles.childNum}>
                      <NumberAnimate>
                      {classHoursTotal?.tenantOfflineCourseQuantity + classHoursTotal?.tenantOnlineCourseQuantity || 0}
                      </NumberAnimate>
                    </div>
                  </div>
                )}

                {childListKey && hasCenterCumulativeAuthorizationPermission && (
                  <div className={styles.rightChild}>
                    <div className={styles.name}>
                      {intl.formatMessage({ id: 'centerChildNum' })}{' '}
                      <Tooltip
                        title={intl.formatMessage({ id: 'yourCenterChildNum' })}
                      >
                        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div className={styles.childNum}>
                      <NumberAnimate>{childNum?.cumulativeManageSubject || 0}</NumberAnimate>
                    </div>
                  </div>
                )}

                {userAllTenant.length > 1 && headKey && hasCourseQuantityTotalPermission && (
                  <div className={styles.rightChild}>
                    <div className={styles.name}>
                      {intl.formatMessage({ id: 'allClassNum' })}{' '}
                      <Tooltip
                        title={intl.formatMessage({ id: 'yourAllClassNum' })}
                      >
                        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div className={styles.childNum}>
                      <NumberAnimate>
                        {classHoursTotal?.offlineCourseQuantity + classHoursTotal?.onlineCourseQuantity || 0}
                      </NumberAnimate>
                    </div>
                  </div>
                )}

                {userAllTenant.length > 1 && childKey && hasTotalCumulativeAuthorizationPermission && (
                  <div className={styles.rightChild}>
                    <div className={styles.name}>
                      {intl.formatMessage({ id: 'allChildNum' })}{' '}
                      <Tooltip
                        title={intl.formatMessage({ id: 'yourAllChildNum' })}
                      >
                        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                    <div className={styles.childNum}>
                      <NumberAnimate>
                        {childNum?.totalCumulativeManageSubject || 0}
                      </NumberAnimate>
                    </div>
                  </div>
                )}
              </Space>
            </div>
          )}
        </div>

        {
          Boolean(planListPagination.total) && (
            <Card
              title={intl.formatMessage({ id: 'trainingOverview' })}
              bordered={false}
              className={styles.trainingCard}
              extra={
                <span className={styles.childExtra}>
                  <span
                    onClick={() =>
                      history.push('/system/center/menu/traningManage/trainList')
                    }
                  >
                    {intl.formatMessage({ id: 'more' })}
                  </span>
                </span>
              }
            >
              <Spin spinning={planListLoading}>
                <div className={styles.onGoingPlanNum}>
                  {intl.formatMessage({ id: 'goingPlans' })}: {planListPagination.total}
                </div>
                <CustomSwiper
                  onSlideChange={(swiper) => {
                    const { activeIndex } = swiper || {};
                    if (!planListLoading) {
                      setPlanListPagination({
                        ...planListPagination,
                        current: activeIndex + 1,
                      })
                    }
                  }}
                >
                  {
                    Array(Math.ceil(planListPagination.total / getShowCardNum())).fill(0).map((_, index) => {
                      return (
                        <SwiperSlide
                          key={index}
                          style={{
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '45px',
                            height: '300px',
                        }}>
                          <Space size={'large'}>
                            {
                              planList.map((item) => {
                                let id;

                                const { plan, group } = item || {};
                                if (plan === null) {
                                  id = group?.id;
                                } else {
                                  id = plan?.id;
                                }

                                return (
                                  <TrainingCom key={id} data={item}/>
                                )
                              })
                            }
                          </Space>
                        </SwiperSlide>
                      )
                    }
                  )}
                </CustomSwiper>
              </Spin>
            </Card>
          )
        }

        <Card
          title={intl.formatMessage({ id: 'commonFeatures' })}
          bordered={false}
          className={styles.functionCard}
        >
          <Row gutter={[0, 16]} className={styles.autoChange}>
            {hasAddVRATCasePermission && hasSubjectWritePermission && hasAddVratTestPermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={addEasyVrat}
                >
                  <div className={styles.functionItem}>
                    <img src={tenImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({ id: 'subject.newVRATTest' })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}

            {childListKey && hasSubjectListPermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() =>
                    history.push('/system/center/menu/user/subject')
                  }
                >
                  <div className={styles.functionItem}>
                    <img src={firstImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({ id: 'subjectList' })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}

            {/* <Col xs={8} lg={4}>
              <Card.Grid
                className={styles.gridStyle}
                onClick={() =>
                  history.push('/system/center/menu/personalCenter')
                }
              >
                <div className={styles.functionItem}>
                  <img src={twoImg}></img>
                  <div className={styles.girdName}>培训中心</div>
                </div>
              </Card.Grid>
            </Col> */}
            {hasSubjectWritePermission && hasAddGuardianPermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() => history.push('/system/vratProcess?type=addParent')}
                >
                  <div className={styles.functionItem}>
                    <img src={threeImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({ id: 'addGuardian' })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}
            {hasReadPermission && hasAssessmentQRCodePermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() => {
                    dispatch({
                      type: 'questionnaireList/showAssessmentList',
                    });
                  }}
                >
                  <div className={styles.functionItem}>
                    <img src={fourImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({ id: 'evaluationQrCode' })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}
            {questionListKey && hasUserAssessmentListPermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() =>
                    history.push(
                      '/system/center/menu/statistic/questionnaireList',
                    )
                  }
                >
                  <div className={styles.functionItem}>
                    <img src={fiveImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({
                        id: 'questionnaireEvaluationList',
                      })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}

            {coreSessionMenuKey && hasCoreSessionLibraryPermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() =>
                    history.push('/system/center/menu/traningManage/library')
                  }
                >
                  <div className={styles.functionItem}>
                    <img src={elevenImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({ id: 'Core session Library' })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}

            {vratKey && hasVartStatisticsPermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() =>
                    history.push('/system/center/menu/analysis/vrat')
                  }
                >
                  <div className={styles.functionItem}>
                    <img src={sevenImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({ id: 'VRATAnalysis' })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}

            {/* {childKey && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() =>
                    history.push('/system/center/menu/analysis/children')
                  }
                >
                  <div className={styles.functionItem}>
                    <img src={eightImg}></img>
                    <div className={styles.girdName}>{intl.formatMessage({ id: 'childUserAnalysis' })}</div>
                  </div>
                </Card.Grid>
              </Col>
            )} */}

            {headKey && hasIlsTrainingAnalysisPermission && (
              <Col xs={12} lg={6}>
                <Card.Grid
                  className={styles.gridStyle}
                  onClick={() =>
                    history.push('/system/center/menu/analysis/iLs')
                  }
                >
                  <div className={styles.functionItem}>
                    <img src={niceImg}></img>
                    <div className={styles.girdName}>
                      {intl.formatMessage({ id: 'brainTrainAnalysis' })}
                    </div>
                  </div>
                </Card.Grid>
              </Col>
            )}
          </Row>
        </Card>

        {!trainListLoading && trainList.length !== 0 && hasTrainingProgressPermission && (
          <Card
            title={intl.formatMessage({ id: 'trainingProcess' })}
            bordered={false}
            extra={
              <span className={styles.extra}>
                <span onClick={() => setCanShow(!canShow)}>
                  {canShow
                    ? intl.formatMessage({ id: 'collapse' })
                    : intl.formatMessage({ id: 'unfold' })}
                </span>
                <span
                  onClick={() =>
                    history.push('/system/center/menu/personalCenter')
                  }
                >
                  {intl.formatMessage({ id: 'more' })}
                </span>
              </span>
            }
            className={styles.card}
          >
            {trainListLoading && <Skeleton active />}
            <Row>
              {canShow &&
                trainList.length > 0 &&
                !trainListLoading &&
                trainList.map((item) => {
                  return (
                    <Col xs={24} md={12} lg={8} xl={6} xxl={4} key={item.id}>
                      <Card.Grid
                        className={styles.gridStyle}
                        onClick={() =>
                          history.push({
                            pathname: '/system/center/menu/personalCenter',
                            query: {
                              checkedId: item.id,
                            },
                          })
                        }
                      >
                        <div className={styles.girdImg}>
                          <img
                            src={
                              item.trainingModule.cover
                                ? item.trainingModule.cover.url
                                : brainLogo
                            }
                          ></img>
                          <div className={styles.girdName}>
                            {item.trainingModule.displayName}
                          </div>
                        </div>
                        <div
                          className={
                            item.issued &&
                            issued(
                              item.trainer.trainerCredentialses,
                              item.trainingModule.id,
                            ).includes(
                              `${intl.formatMessage({ id: 'qualified' })}`,
                            )
                              ? styles.gridDesDefault
                              : styles.gridDes
                          }
                        >
                          {item.issued
                            ? issued(
                                item.trainer.trainerCredentialses,
                                item.trainingModule.id,
                              )
                            : `${intl.formatMessage({ id: 'notQualified' })}`}
                        </div>
                        <div
                          className={
                            getStatus(
                              item.status,
                              item.trainingOrder,
                              item.trainingModule.courses,
                            ).includes(
                              `${intl.formatMessage({ id: 'trainingStudy' })}`,
                            )
                              ? styles.gridDesTwoDefault
                              : styles.gridDesTwo
                          }
                        >
                          {feedbackText
                            ? feedbackText
                            : getStatus(
                                item.status,
                                item.trainingOrder,
                                item.trainingModule.courses,
                              )}
                        </div>
                        <div className={styles.gridLeft}>
                          {item.issued &&
                            issued(
                              item.trainer.trainerCredentialses,
                              item.trainingModule.id,
                            ).includes(
                              `${intl.formatMessage({ id: 'qualified' })}`,
                            ) &&
                            item.trainingModule.qualificationMedals.length > 0 &&
                             item.trainingModule.qualificationMedals[0]
                              .issuedStyle && (
                              <img
                                key={item.id}
                                src={
                                  item.trainingModule.qualificationMedals[0]
                                    .issuedStyle.url
                                }
                              />
                            )}
                          {item.issued &&
                            issued(
                              item.trainer.trainerCredentialses,
                              item.trainingModule.id,
                            ).includes(
                              `${intl.formatMessage({
                                id: 'qualificationExpired',
                              })}`,
                            ) &&
                            item.trainingModule.qualificationMedals.length > 0 &&
                            item.trainingModule.qualificationMedals[0]
                              .unissuedStyle && (
                              <img
                                key={item.id}
                                src={
                                  item.trainingModule.qualificationMedals[0]
                                    .unissuedStyle.url
                                }
                              />
                            )}
                        </div>
                      </Card.Grid>
                    </Col>
                  );
                })}
            </Row>
          </Card>
        )}

        {childListKey && hasRecentSubjectRecordPermission && (
          <Card
            title={intl.formatMessage({ id: 'recentChild' })}
            bordered={false}
            extra={
              <span className={styles.childExtra}>
                <span
                  onClick={() =>
                    history.push('/system/center/menu/user/subject')
                  }
                >
                  {intl.formatMessage({ id: 'more' })}
                </span>
              </span>
            }
            className={styles.childCard}
          >
            {childListLoading && <Skeleton active />}
            {!childListLoading && recordChild.length === 0 && (
              <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <Button
                  type="primary"
                  onClick={() => history.push('/system/vratProcess?type=addParent')}
                >
                  {intl.formatMessage({ id: 'goAdd' })}
                </Button>
              </div>
            )}
            {!childListLoading && recordChild.length > 0 && (
              <div className={styles.childList}>
                {recordChild.map((item) => {
                  return (
                    <div
                      className={styles.childItem}
                      key={item.childId}
                      onClick={() =>
                        history.push({
                          pathname: '/system/center/detail/subjectDetail',
                          query: {
                            id: item.childId,
                          },
                        })
                      }
                    >
                      {getUserImg(item, { height: '70px', width: '70px' })}
                      {/* <img src={item.picture ? item.picture : userStaff} /> */}
                      <div className={styles.childName}>{item.childName}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </Card>
        )}

        {hasCalendarPermission && hasTodayCalendarPermission && (
          <Card
            title={intl.formatMessage({ id: 'todayClass' })}
            bordered={false}
            extra={
              <span className={styles.childExtra}>
                <span
                  onClick={() =>
                    history.push('/system/center/detail/classCardDetail')
                  }
                >
                  {intl.formatMessage({ id: 'all' })}
                </span>
              </span>
            }
            className={styles.classCard}
          >
            {classListLoading && <Skeleton active />}
            {!classListLoading && classDetail.length === 0 && (
              <div className={styles.empty}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <Button
                  type="primary"
                  onClick={() =>
                    history.push('/system/center/detail/classCardDetail')
                  }
                >
                  {intl.formatMessage({ id: 'goAdd' })}
                </Button>
              </div>
            )}
            {!classListLoading && classDetail.length > 0 && (
              <div className={styles.classList}>
                <InfiniteScroll
                  dataLength={classDetail.length}
                  hasMore={false}
                  height={240}
                >
                  {classDetail.map((item) => {
                    return (
                      <div
                        key={item.userPlanId}
                        className={
                          isStatusActive(item.startTime)
                            ? `${styles.classItem}`
                            : `${styles.classItem} ${styles.classOpacity}`
                        }
                        onClick={() =>
                          history.push('/system/center/detail/classCardDetail')
                        }
                      >
                        <div className={styles.classTime}>
                          {fixedZero(moment(item.startTime).hours())}:
                          {fixedZero(moment(item.startTime).minutes())}
                        </div>
                        {!rolesName.includes('Coach') && (
                          <>
                            <div className={styles.classTeacherName}>
                              {intl.formatMessage({ id: 'trainer' })}：
                              {item.executor.name}
                            </div>
                            <div className={styles.divider}>|</div>
                          </>
                        )}
                        <div className={styles.classChildName}>
                          {intl.formatMessage({ id: 'subject' })}：
                          {item.students.map((name) => {
                            return name.name;
                          })}
                        </div>
                        <div
                          className={
                            item.calendarCourseType === 'Online'
                              ? styles.classDownTag
                              : styles.classUpTag
                          }
                        >
                          {item.calendarCourseType === 'Online'
                            ? intl.formatMessage({ id: 'online' })
                            : intl.formatMessage({ id: 'offline' })}
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
          </Card>
        )}
        <AssessmentSelectModal {...selectAssessmentModalProps} />
      </div>
    </DocumentTitle>
  );
};

export default connect(({ questionnaireList, loading, user }) => ({
  questionnaireList,
  loading,
  currentUser: user.currentUser,
}))(WorkTable);
