import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Radio, Button, Dropdown, Space, Menu, Progress } from 'antd';
import ExerciseOverview from './components/ExerciseOverview';
import ExerciseWeekly from './components/ExerciseWeekly';
import { connect, useIntl } from 'umi';
import { PostExerciseList } from 'services/pdt';
import { getActiveWeekdays } from 'utils/utils';
import moment from 'moment';

import styles from './index.less';

const ExercisePdt = ({ subjectDetail }) => {
    const intl = useIntl();

    const [mode, setMode] = useState('overView');

    const [week, setWeek] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false)
    const [exerciseData, setExerciseData] = useState([]);

    const { subjectInfo } = subjectDetail;
    const { pdtModules, id } = subjectInfo;
    // const currentModule = pdtModules.find(item => item.pdtModule === 'Exercise');
    // const { startTime, stopTime } = currentModule || {};

    // const diffDay = stopTime ? moment(stopTime).diff(moment(startTime), 'days', true) : moment().diff(moment(startTime), 'days', true);
    // const weekAssigned = Math.ceil(diffDay / 7);
    // const currentWeek = week || weekAssigned;

    // const startDay = moment(startTime).add((currentWeek - 1) * 7, 'days')
    // const endDay = moment(startTime).add(currentWeek * 7, 'days')

    let weekAssigned = 1;

    // 获取最大是哪周
    const sortData = exerciseData.sort((a, b) => {
        return b.exercise.weekIndex - a.exercise.weekIndex
    })

    if (sortData.length) {
        const { exercise } = sortData[0];
        const { weekIndex } = exercise;
        weekAssigned = weekIndex
    }

    const currentWeek = week || weekAssigned

    const handleMenuClick = (e) => {
        const { key } = e;
        setWeek(key);
    };

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={getActiveWeekdays(weekAssigned)}
        />
    );

    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    const initData = async () => {
        const res = await PostExerciseList({
            childId: id,
            status: ['completed', 'caughtUp', 'active'],
        })

        if (res.response.ok) {
            setExerciseData(res.data);
        } else {
            if (res.data === '') {
                setIsEmpty(true);
            } else {
                const { msg } = res.data;
                message.error(msg)
            }
        }
    }

    useEffect(() => {
        initData();
    }, [])

    const overViewProps = {
        data: exerciseData.filter(item => item.exercise.weekIndex == currentWeek),
        isEmpty,
        weekAssigned,
        currentWeek,
        changeWeek: (value) => {
            setWeek(value);
        }
    }

    const props = {
        data: exerciseData.filter(item => item.exercise.weekIndex == currentWeek),
        isEmpty
    }

    const completedNum = exerciseData?.filter(item => item.status === 'completed').length

    return (
        <div className={styles.exericsepanel}>
            <div className={styles.progressPanel}>
                <div className={styles.progressTitle}>{intl.formatMessage({ id: 'Exercise-PDT' })}</div>
                <div className={styles.progressNum}>
                    <div className={styles.progressCurrentNum}>{completedNum}</div>
                    <div>/60</div>
                </div>
                <Progress
                    percent={completedNum / 60 * 100}
                    showInfo={false}
                    strokeWidth={20}
                    strokeColor={'#6eb1b6'}
                    style={{ width: '180px' }}
                />
            </div>
            <Radio.Group
                onChange={handleModeChange}
                value={mode}
                style={{ marginBottom: 8 }}
            >
                <Radio.Button value="overView">{intl.formatMessage({ id: 'Overview' })}</Radio.Button>
                <Radio.Button value="weekly">{intl.formatMessage({ id: 'Weekly' })}</Radio.Button>
            </Radio.Group>
            <div className={styles.exericseRightSelect}>
                <span>{intl.formatMessage({ id: 'Week' })}:&nbsp;&nbsp;</span>
                <Dropdown overlay={menu}>
                    <Button>
                        <Space>
                            {currentWeek}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
                &nbsp;
            </div>
            {mode === 'overView' ?
                <ExerciseOverview {...overViewProps} />
                : <ExerciseWeekly {...props} />}
        </div>
    );
}

export default connect(({
    subjectDetail,
}) => ({
    subjectDetail,
}))(ExercisePdt);