import React, { useEffect } from 'react'
import { connect } from 'dva'
import DocumentTitle from 'react-document-title'
import { Layout } from 'antd'
import FirstPage from './FirstPage'
import TwoPage from './TwoPage'
import ThreePage from './ThreePage'
import styles from './index.less'

const { Content } = Layout

const MedicalVratReport = ({ medicalVratReport, dispatch, location }) => {

    useEffect(() => {
        dispatch({ type: 'medicalVratReport/fetch', payload: location.query })
    }, [])

    const {
        reportVersion,
        basicInfo,
        lineWithIcon,
        continuousAttention,
        directionTracking,
        motionCharacteristics,
        movementPath,
        overView,
        pathLength,
        sixDimension,
        tenantInfo
    } = medicalVratReport

    const {
        basicLoading,
        overViewLoading,
        lineWithIconLoading,
        sixDimensionLoading,
        continuousAttentionLoading,
        movementPathLoading,
        pathLengthLoading,
        directionTrackingLoading,
        motionCharacteristicsLoading,
    } = medicalVratReport

    const firstPageProps = {
        data: basicInfo,
        tenantInfo,
        reportVersion,
        totalShow: overView,
        continuousAttention,
        lineWithIcon,
        basicLoading,
        overViewLoading,
        lineWithIconLoading,
        continuousAttentionLoading,
        sixDimensionLoading,
        pathLengthLoading,
        motionCharacteristicsLoading,
        directionTrackingLoading
    }

    const twoPageProps = {
        sixDimension,
        pathLength,
        motionCharacteristics,
        movementPath,
        directionTracking,
        sixDimensionLoading,
        pathLengthLoading,
        motionCharacteristicsLoading,
        directionTrackingLoading,
        movementPathLoading
    }

    const titleName = basicInfo ? (basicInfo.Subject.Name[0] === '*' && basicInfo.Subject.Name[basicInfo.Subject.Name.length - 1] === '*') ? '' : '-' + basicInfo.Subject.Name : ''
    const titleNum = basicInfo ? basicInfo.Subject.OutpatientNumber ? '-' + basicInfo.Subject.OutpatientNumber : "" : ""

    return (
        <DocumentTitle title={`vCAT基础版${titleName !== '' ? titleName : ''}${titleNum !== '' ? titleNum : ''}`}>
            <Layout className={styles.reportwrapper}>
                <Content className={styles.content}>
                    <div className={styles.pageWrapper}>
                        <FirstPage {...firstPageProps} />
                    </div>

                    <div className={styles.pageWrapper}>
                        <TwoPage {...twoPageProps} />
                    </div>

                    <div className={styles.pageWrapper}>
                        <ThreePage {...firstPageProps} />
                    </div>
                </Content>
            </Layout>
        </DocumentTitle>
    )
}

export default connect(({ medicalVratReport }) => ({ medicalVratReport }))(MedicalVratReport)