import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Space, message, Card, Radio, Checkbox } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { addOptionsToProperties, matchDynamicForm } from 'cognitiveleap-core-us/utils/utils';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { GetExamInfo } from 'services/exam';
import { GetExamList, PostExamList, PutExamList } from 'services/exam';
import styles from './index.less';

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '考试名称',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '参与考试时长',
      FormKey: 'duration',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
        Props: {
          min: 0,
        },
      },
      Description: '分钟',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '通过分数',
      FormKey: 'passingScore',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
        Props: {
          min: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '添加题目',
      FormKey: 'regulations',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [
                  {
                    Value: '单选题',
                    Id: 'radiogroup',
                  },
                  {
                    Value: '多选题',
                    Id: 'checkbox',
                  },
                ],
                Required: true,
              },
              ShowTitle: '题型',
              FormKey: 'questionType',
              Description: null,
            },
            {
              EditorType: 'NumberInput',
              ShowTitle: '数量',
              FormKey: 'quantity',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: true,
                Props: {
                  min: 0,
                },
              },
              Description: null,
            },
            {
              EditorType: 'NumberInput',
              ShowTitle: '分值',
              FormKey: 'fraction',
              AdditionalData: null,
              Value: null,
              Setting: {
                Required: true,
                Props: {
                  min: 0,
                },
              },
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      ShowTitle: '分类',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: true,
      },
      Description: null,
      FormKey: 'classifies',
    },
    {
      EditorType: 'SingleWithSearch',
      ShowTitle: '语言',
      FormKey: 'language',
      AdditionalData: null,
      Value: '',
      Setting: {
        DropdownOptions: [
          {
            Value: 'zh-cn',
            Id: 'zh-cn',
          },
          {
            Value: 'en-us',
            Id: 'en-us',
          },
        ],
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '页数',
      FormKey: 'pages',
      AdditionalData: null,
      Value: 1,
      Setting: {
        Required: false,
        Props: {
          min: 1,
        },
      },
      Description: null,
    },
  ],
};

const Exam = () => {
  // 修改题信息的model的状态
  const [content, setContent] = useState({});

  // 列表数据
  const [listData, setListData] = useState([]);

  // 一些model的控制
  const [modelShow, setModelShow] = useState(false);

  // table loading
  const [loading, setLoading] = useState({
    tableLoading: false,
    modelLoading: false,
  });

  // 判断是否是修改题目
  const [questionID, setQuestionID] = useState('');

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const getListData = async () => {
    setLoading({ ...loading, tableLoading: true });
    const res = await GetExamList({
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
    });
    if (res.response && res.response.ok) {
      setListData(res.data.items);
      setPagination({
        ...pagination,
        total: res.data.totalCount || 0,
      });
    } else {
      message.error(res.data.msg, 3);
    }
    setLoading({ ...loading, tableLoading: false });
  };

  useEffect(() => {
    getListData();
  }, [pagination.current, pagination.pageSize]);

  // 列表colums
  const listSupport = {
    name: {
      showText: '考试名称',
      showType: 'Text',
      width: '30%',
    },
    createTime: {
      showText: '创建时间',
      showType: 'Time',
      width: '30%',
    },
  };

  const TableProps = {
    listData,
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          editQuestion('edit', record.id);
        },
      },
    ],
  };

  const modalProps = {
    title: '编辑考试',
    content,
    visible: modelShow,
    loading: loading.modelLoading,
    onCancel() {
      setModelShow(false);
      setLoading({ ...loading, modelLoading: false });
      setQuestionID('');
    },
    async onOk(value) {
      setLoading({ ...loading, modelLoading: true });
      const {
        name,
        duration,
        language,
        pages,
        classifies,
        regulations,
        passingScore,
      } = value;
      const params = {
        name,
        duration,
        language,
        pages,
        classifyIds: classifies,
        regulations,
        passingScore,
        id: questionID,
      };
      let res;
      if (questionID !== '') {
        // 编辑
        res = await PutExamList(params);
      } else {
        // 添加
        res = await PostExamList(params);
      }
      if (res.response.ok) {
        setModelShow(false);
        setQuestionID('');
        getListData();
      } else {
        const { msg } = res.data || { msg: '修改失败' };
        message.error(msg);
      }
      setLoading({ ...loading, modelLoading: false });
    },
  };

  // 数据格式转换
  const listToOptions = (list, showAttributeArr) => {
    return list.map((item) => {
      const Value = showAttributeArr.map((attribute) => `${item[attribute]} `);
      return { Value, Id: item.id.toString() };
    });
  };

  const editQuestion = async (type = 'add', id) => {
    const examInfo = await GetExamInfo({ id });
    const { examination, classifies } = examInfo.data.data;
    if (examInfo.response && examInfo.response.ok) {
      const questionOptions = listToOptions(classifies, ['name']);
      let emptyContent = contentMenu;
      emptyContent = addOptionsToProperties(
        emptyContent,
        ['classifies'],
        questionOptions,
      );
      let showForm = emptyContent;
      if (type === 'edit') {
        setQuestionID(id);
        let record = {
          ...examination,
          classifies: examination.classifies.map((item) => {
            return item.id;
          }),
        };
        showForm = matchDynamicForm(emptyContent, {
          ...record,
        });
      }
      setContent(showForm);
      setModelShow(true);
    } else {
      message.error('获取分类信息失败');
    }
  };

  return (
    <>
      <Button type="primary" onClick={editQuestion}>
        创建考试
      </Button>
      <div className={styles.panl}>
        <MagicTable {...TableProps} />
      </div>
      <MagicFormModal {...modalProps} />
    </>
  );
};

export default Exam;
