import React, { useState, useEffect } from 'react';
import ExpandedTable from './ExpandedTable';
import AddClassModal from './AddClassModal';
import { getFormatDate } from 'utils/utils';
import { GetCoachConfig, PostCoachConfig } from 'services/coachTrainingConfig';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { Card, Button, Input, DatePicker, Space, message } from 'antd';
import { useIntl, history, connect } from 'umi';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const { RangePicker } = DatePicker;
const { Search } = Input;

const listSupport = (intl) => {
  return {
    userName: {
      showText: intl.formatMessage({ id: 'Coach Name' }),
      showType: 'Text',
      render: (text, record) => {
        const { userId } = record || {};
        return (
          <a
            onClick={() => {
              history.push({
                pathname: '/system/center/detail/groupDetail',
                query: {
                  id: userId,
                },
              });
            }}
          >
            {text}
          </a>
        );
      },
    },
    freeTimes: {
      showText: intl.formatMessage({ id: 'Remaining Sessions' }),
      showType: 'Text',
    },
    usedTimes: {
      showText: intl.formatMessage({ id: 'Used Sessions' }),
      showType: 'Text',
    },
    totalTimes: {
      showText: intl.formatMessage({ id: 'Total Sessions' }),
      showType: 'Text',
    },
    lastSubmitRecordTime: {
      showText: intl.formatMessage({ id: 'LastSubmissionTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm',
      },
    },
  };
};

const CoachConfiguration = ({ currentUser }) => {
  const intl = useIntl();

  const { auth } = currentUser || {}
  const { grantedPolicies } = auth || {}

  const haveAddPermission = grantedPolicies['RocketSystem.CoachTrainingConfig.Create'];

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState({
    tableLoading: false,
    modalLoading: false,
  });
  const [listData, setListData] = useState([]);

  // 搜索数据
  const [searchKey, setSearchKey] = useState('');
  const [filterDate, setFilterDate] = useState({
    startTime: '',
    endTime: '',
  });
  const [currentItem, setCurrentItem] = useState({});

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const { current, pageSize } = pagination || {};
    const { startTime, endTime } = filterDate || {};
    const res = await GetCoachConfig({
      SkipCount: (current - 1) * pageSize,
      MaxResultCount: pageSize,
      StartTime: startTime,
      EndTime: endTime,
      Filter: searchKey,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount || 0,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize, filterDate]);

  const TableProps = {
    loading: loading.tableLoading,
    listData: listData.map(item => {
      const { totalTimes = 0, usedTimes = 0 } = item || {};
      return {
        ...item,
        freeTimes: totalTimes - usedTimes,
      }
    }),
    listSupport: listSupport(intl),
    pagination,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: (record) => (
          <ExpandedTable allData={record} visible={visible} />
        ),
        onExpandedRowsChange: (rows) => {
          // 只允许打开一个ExpendTable
          if (rows.length > 1) {
            setExpandedRowKeys([rows[rows.length - 1]]);
          } else {
            setExpandedRowKeys(rows);
          }
        },
      };
    },
    onTableChange(page) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });

      expandedRowKeys.length > 0 && setExpandedRowKeys([]);
    },
    Actions: haveAddPermission ? [
      {
        showText: intl.formatMessage({ id: 'Add Sessions' }),
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                setCurrentItem(record);
                setVisible(true);
              }}
            >
              {intl.formatMessage({ id: 'Add Sessions' })}
            </a>
          );
        },
      },
    ] : null,
  };

  const submitModal = async (values) => {
    const { userId } = currentItem || {};
    const { remark, customChangeTimes, changeTimes } = values;

    if (changeTimes == 'custom' && customChangeTimes == 0) {
      message.warning(intl.formatMessage({ id: 'Dont enter Zero' }));
      return;
    }

    const res = await PostCoachConfig({
      remark,
      userId,
      changeTimes: customChangeTimes !== 0 ? Math.abs(customChangeTimes) : changeTimes,
      recharge: customChangeTimes >= 0,
    });

    if (res.response.ok) {
      message.success(intl.formatMessage({ id: 'success' }));
      setVisible(false);
      initData();
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }
  };

  const modalProps = {
    visible,
    currentItem,
    loading: loading.modalLoading,
    closeModal: () => {
      setVisible(false);
      setCurrentItem({});
    },
    onOk: submitModal,
  };

  const changeLoadData = (type, value) => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });

    switch (type) {
      case 'keyword':
        initData();
        break;
      case 'date':
        const startTime =
          value && value[0] ? getFormatDate(value[0], true) : '';
        const endTime = value && value[1] ? getFormatDate(value[1], false) : '';
        setFilterDate({
          startTime,
          endTime,
        });
        break;
      case 'clear':
        setFilterDate({
          startTime: '',
          endTime: '',
        });
        setSearchKey('');
        break;
      default:
        break;
    }
  };

  return (
    <Card
      title={
        <Space direction="vertical">
          <b style={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'Coach Configuration' })}
          </b>
          <div>{intl.formatMessage({ id: 'Configure training session' })}</div>
        </Space>
      }
      bordered={false}
    >
      <Space>
        <Space size="10">
          <div>{intl.formatMessage({ id: 'keyword' })}&nbsp;&nbsp;</div>
          <Search
            value={searchKey}
            placeholder={intl.formatMessage({ id: 'Coach Name' })}
            style={{ width: 220 }}
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
            onSearch={(e) => changeLoadData('keyword')}
          />
        </Space>

        <Space size="10">
          <div>{intl.formatMessage({ id: 'submissionTime' })}&nbsp;&nbsp;</div>
          <RangePicker
            onChange={(value) => changeLoadData('date', value)}
            placeholder={[
              intl.formatMessage({ id: 'startDate' }),
              intl.formatMessage({ id: 'endDate' }),
            ]}
            disabledDate={(current) => {
              return current && current > moment();
            }}
            value={[
              filterDate.startTime
                ? moment(filterDate.startTime, dateFormat)
                : null,
              filterDate.endTime ? moment(filterDate.endTime, dateFormat) : null,
            ]}
          />
        </Space>

        <Button onClick={() => changeLoadData('clear')}>
          {intl.formatMessage({ id: 'clear' })}
        </Button>
      </Space>
      <p />
      <MagicTable {...TableProps} />
      {visible && <AddClassModal {...modalProps} />}
    </Card>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(CoachConfiguration);
