import { 
  NextCourse
} from 'services/rocketService/UserOfflinePlan'
import { message, Modal } from 'antd'
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  GetFeedBack,
  PostFeedBack,
  PutFeedBack,
  PostFeedBackTotal,
  PutFeedBackTotal,
  PutChildFeedback,
  PostChildFeedback,
  Done,
  DoneNum,
  StartCourse,
  GetChildFeedback
} from 'services/group';
import { getNextKey, getDisabledValue, getHasDoneValue, getMusicHasDone, isParentalInterviewsNeed } from 'utils/utils'
import { deepCopy } from 'cognitiveleap-core-us/utils/utils'
import { IntlManager } from 'utils/helper'
import { getDvaApp } from 'umi'
import {
  GetGroupCourseList,
  GetParentalInterviewsNeed
} from 'services/group';

export default {
  namespace: 'groupOfflinePlanDetail',
  state: {
    queryData: {},
    planItems: [],
    pagination: {
      current: 1,
      pageSize: 9,
      total: 0,
    },
    selectCourseInfo: null,
    drawerVisible: false,
    CourseNum: 0,
    selectGuardianCourseInfo: null,
    activeKey: [],
    childFeedbackData: [],
    groupLinkUsers: [],
    activeTab: 'class',
    childEdit: false,
    downLoadModalVisible: false,
    duration: 0,
    percent: 0,
    isCurrentNum: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *pageLoading({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState', payload: { queryData: payload }
      })
      yield put({ type: 'loadList', payload })
    },

    *loadList({ payload }, { call, put, select }) {
      const { pagination, queryData: { id: GroupTrainingPlanId, groupId } } = yield select(state => state.groupOfflinePlanDetail)
      let query = {
        SkipCount: 0,
        MaxResultCount: 10,
        GroupTrainingPlanId,
      }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        SkipCount: (currentPageIndex - 1) * currentPageSize,
        MaxResultCount: currentPageSize,
      }
      const res = yield call(GetGroupCourseList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data

        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }

        yield put({ type: 'updateState', payload: { pagination: newPagination, planItems: items } })
      }
    },

    *loadChild(_, { call, put, select }) {
      const { queryData: { id: GroupTrainingPlanId }, CourseNum } = yield select(state => state.groupOfflinePlanDetail)
      const params = {
        GroupTrainingPlanId,
        CourseNum,
      };
      const res = yield call(GetChildFeedback, params);
      if (res.response.ok) {
        // 出席并且没填反馈的儿童
        const data = res.data
        const response = data.find(item => item.hasDone === null && item.attendance === "Attend");
        const { id = '' } = response || {}
        yield put({ type: 'updateState', payload: { 
            childFeedbackData: data,
            activeKey: id !== '' ? [id] : []
          } 
        })
      }
    },
    
    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'loadList' })
    },

    *onFeedback({ payload: data }, { call, put, select }) {
      const { userPlanId, num } = data
      const res = yield call(GetFeedBack, {
        GroupTrainingPlanId: userPlanId,
        CourseNum: num,
      })
      if (res.response.ok) {
        const { groupTrainingPlanCourse, groupTrainingPlan } = res.data;
        const { trainingPlanV2, currentNum } = groupTrainingPlan;
        const { feedbacks, courseItems, num, feedbackProperties, id, hasDone, groupTrainingPlanId, nonePlaybook, minStartTime } =
          groupTrainingPlanCourse;

        const allData = {
          num,
          nonePlaybook,
          planItems: courseItems,
          userOfflineTrainingPlan: trainingPlanV2,
        };

        // 课时整体反馈
        const guardianCourseInfo = {
          id,
          num,
          groupTrainingPlanId,
          feedbacks: feedbacks.length > 0
            ? [
                {
                  creationTime: feedbacks[0].creationTime,
                  feedbackProperties: feedbacks[0].feedbackProperties.map(
                    (item) => {
                      return {
                        ...item,
                        displayName: item.programFeedbackProperty.displayName,
                      };
                    },
                  ),
                },
              ]
            : [],
          feedbackProperties,
          minStartTime,
          userOfflineTrainingPlan: trainingPlanV2,
          hasDone,
        };

        yield put({
          type: 'updateState',
          payload: { drawerVisible: true, activeTab: 'class', selectCourseInfo: allData, selectGuardianCourseInfo: guardianCourseInfo, isCurrentNum: num === currentNum },
        })
        yield put({
          type: 'findActiveKeys',
          payload: { type: 'guardian' }
        })
      }
    },

    *findActiveKeys({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo } = yield select(state => state.groupOfflinePlanDetail)
      const { currentUser } = yield select(state => state.user)
      const { audioRecordPunch, hasDone: guardianHasDone, userOfflineTrainingPlan } = selectGuardianCourseInfo || {}
      const { planItems, nonePlaybook } = selectCourseInfo || {}
      const { auth, changedTenant } = currentUser || {}
      const { grantedPolicies } = auth || {}

      const isBind = true
      const isLogout = false

      const rocketProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Rocket')['status'] : true;
      const hasOfflinePlanWritePermission = grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true

      const musicHasDone = getMusicHasDone({ audioRecordPunch })

      const guardianId = getDisabledValue({ hasDone: guardianHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) === false
        && getHasDoneValue({ hasDone: guardianHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })
        ? userOfflineTrainingPlan.id : null
      const musicId = audioRecordPunch !== null ?
        (getDisabledValue({ hasDone: musicHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) === false
          && getHasDoneValue({ hasDone: musicHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) ? 'music' : null) : null

      const { type, id } = payload
      let activeKey

      switch (type) {
        case 'guardian':
          activeKey = guardianId || getNextKey({ planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })
          break
        case 'music':
          activeKey = getNextKey({ planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) || guardianId
          break
        default:
          if (id)
            activeKey = getNextKey({ id, planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) || guardianId || musicId || getNextKey({ planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })
          else {
            activeKey = guardianId || musicId || getNextKey({ planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })
          }
          break
      }

      yield put({
        type: 'updateState',
        payload: {
          activeKey: activeKey !== null ? [activeKey] : []
        },
      })

    },

    *onChangeCollapse({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo } = JSON.parse(JSON.stringify(yield select(state => state.groupOfflinePlanDetail)))
      const { activeKey, type, hasDone } = payload || {}
      const { audioRecordPunch } = selectGuardianCourseInfo || {}
      let { planItems, nonePlaybook } = selectCourseInfo || {}

      yield put({
        type: 'updateState',
        payload: {
          activeKey
        }
      })

      if (type === 'guardian') {
        // 代表已做
        if (hasDone == true) {
          selectGuardianCourseInfo.isEdit = false
        } else {
          selectGuardianCourseInfo.isEdit = true
        }
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo
          }
        })
      }

      if (type === "activity") {
        let current = null
        planItems = planItems.map(item => {
          if (item.id === (activeKey && activeKey.length && activeKey[activeKey.length - 1])) {
            const { hasDone } = item || {}
            current = item
            if (hasDone == true) {
              item.isEdit = false
            } else {
              item.isEdit = true
            }
          }
          return item
        })
        if (!current) {
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === (activeKey && activeKey.length && activeKey[activeKey.length - 1])) {
              const { hasDone } = item || {}
              current = item
              if (hasDone == true) {
                item.isEdit = false
              } else {
                item.isEdit = true
              }
            }
            return item
          })
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo: {
              ...selectCourseInfo,
              planItems,
              nonePlaybook
            }
          }
        })
      }

      if (type === 'child') {
        yield put({
          type: 'updateState',
          payload: {
            childEdit: false,
          }
        })
      }
    },

    *submitGuardianFeedback({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo: { id, groupTrainingPlanId, num } } = yield select(state => state.groupOfflinePlanDetail)
      const { submitData: { feedbacks }, guardianHasDone } = payload
      const res =  yield call(guardianHasDone == null ? PostFeedBackTotal : PutFeedBackTotal, {
        feedbacks,
        groupTrainingPlanCourseId: id
      });
      if (res.response.ok) {
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: groupTrainingPlanId, num },
        })
      } else {
        const { error } = res.data;
        message.error(error.message);
      }
    },

    *closeDrawer(_, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: { drawerVisible: false },
      })
      yield put({
        type: 'loadList',
      })
    },

    *submitFeedback({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo: { groupTrainingPlanId, num } } = yield select(state => state.groupOfflinePlanDetail)
      const { feedbacks, planItemId, hasDone } = payload
      const res = yield call(hasDone == null ? PostFeedBack : PutFeedBack, {
        feedbacks,
        groupCourseItemId: planItemId,
      })
      if (res.response.ok) {
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: groupTrainingPlanId, num },
        })
      }
    },

    *childSubmitFeedback({ payload }, { call, put, select }) {
      const { childEdit } = yield select(state => state.groupOfflinePlanDetail)
      const { feedbacks, groupUserCourseItemId, hasDone } = payload
      const res = yield call(hasDone == null ? PostChildFeedback : PutChildFeedback, { feedbacks, groupUserCourseItemId})
      if (res.response.ok) {
        yield put({
          type: 'loadChild',
        })
        if(childEdit) {
          yield put({ type: 'updateState', payload: { childEdit: false } })
        }
      }
    },

    *onNotDoItem({ payload: id }, { call, put, select }) {
      const { selectGuardianCourseInfo: { groupTrainingPlanId, num } } = yield select(state => state.groupOfflinePlanDetail)
      const res = yield call(Done, {
        groupCourseItemId: id,
      })
      if (res.response.ok) {
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: groupTrainingPlanId, num },
        })
      } else {
        const { error } = res.data || {};
        message.error(error.message);
      }
    },

    *onGuardianNotDoItem({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo: { id, groupTrainingPlanId, num } } = yield select(state => state.groupOfflinePlanDetail)
      const res = yield call(DoneNum, {
        groupTrainingPlanCourseId: id,
        hasDone: false,
      })
      if (res.response.ok) {
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: groupTrainingPlanId, num },
        })
      } else {
        const { error } = res.data || {};
        message.error(error.message);
      }
    },

    *finishCourse({ payload }, { call, put, select }) {
      const { userPlanId, num } = payload
      const res = yield call(NextCourse, { offlinePlanId: userPlanId, num })
      if (res.response.ok) {
        yield put({
          type: 'loadList',
        })
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *eidtItem({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo } = deepCopy(yield select(state => state.groupOfflinePlanDetail))
      const { userOfflineTrainingPlan } = selectGuardianCourseInfo || {}
      const { data, type } = payload || {}
      let { planItems, nonePlaybook } = selectCourseInfo || {}
      if (type === "guardian") {
        const { id } = userOfflineTrainingPlan || {}
        selectGuardianCourseInfo.isEdit = true
        yield put({
          type: 'updateState',
          payload: {
            activeKey: id
          }
        })
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo
          }
        })
      }

      if (type === "activity") {
        const { hasDone, id } = data || {}
        yield put({
          type: 'updateState',
          payload: {
            activeKey: id
          }
        })
        if (hasDone == true) {
          planItems = planItems.map(item => {
            if (item.id === id) {
              item.isEdit = true
            }
            return item
          })
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === id) {
              item.isEdit = true
            }
            return item
          })
        } else {
          planItems = planItems.map(item => {
            if (item.id === id) {
              item.isEdit = false
            }
            return item
          })
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === id) {
              item.isEdit = false
            }
            return item
          })
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo: {
              ...selectCourseInfo,
              planItems,
              nonePlaybook
            }
          }
        })
      }
      
      if(type === 'child') {
        const { id } = data
        yield put({
          type: 'updateState',
          payload: { 
            childEdit: true,
            activeKey: id
          },
        })
      }
    },

    *startNextCourseImplement({ payload }, { call, put, select }) {
      const { userPlanId, num } = payload
      const intl = IntlManager.MyIntl()

      Modal.confirm({
        title: intl('activateNextSessionDes'),
        icon: <ExclamationCircleOutlined />,
        content: null,
        okText: intl('sureActivate'),
        cancelText: intl('cancel'),
        onOk: async () => {
          const res = await StartCourse({ groupTrainingPlanId: userPlanId, courseNum: num + 1 })
          if (res.response.ok) {
            getDvaApp()._store.dispatch({ type: 'groupOfflinePlanDetail/loadList' })
          } else {
            const { data: { error } } = res;
            message.error(error.message);
          }
        },
      });
    },

    *startNextCourse({ payload }, { call, put, select }) {
      const { userPlanId, num } = payload

      if (isParentalInterviewsNeed(num, 'next')) { 
        const res = call(GetParentalInterviewsNeed, {
          GroupTrainingPlanId: userPlanId,
          Num: num,
        });

        if (res.response.ok) {
          const needData = res.data;
          if (needData.some((item) => item.need)) {
            const childList = needData.map((item) => {
              const { need, subjectName } = item;
              return need ? subjectName : '';
            });
            Modal.warning({
              title: `您尚未完成${childList}在本阶段的家长访谈，请及时进行。`,
              content: null,
              onOk: () => {
                getDvaApp()._store.dispatch({ 
                  type: 'groupOfflinePlanDetail/startNextCourseImplement',
                  payload
                })
              }
            });
          } else {
            yield put({
              type: 'startNextCourseImplement',
              payload
            })
          }
        }
      } else {
        yield put({
          type: 'startNextCourseImplement',
          payload
        })
      }
    }
  }
}
