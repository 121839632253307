import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal, Input } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const { Search } = Input
const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑训练目标',
  add: '新建训练目标',
}

const Target = ({ target, dispatch }) => {

  useEffect(() => {
    dispatch({ type: 'target/query' })
  }, [])

  const { modalType, content, modalVisible, list, listSupport, pagination } = target

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'target/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'target/onSubmitModal', payload: value })
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'target/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'target/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'target/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'target/onAdd' })
  }

  return (
    <div>
      <h1>训练目标</h1>
      <Button onClick={onAddClick}>新建训练目标</Button>
      <Search
        placeholder="请输入训练目标名称"
        allowClear
        style={{ width: 200 }}
        onSearch={(value) => { dispatch({ type: 'target/query', payload: { searchKeyword: value } }) }}
      />
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ target }) => ({ target }))(Target)
