import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const PathLengthChart = ({ data, motionText }) => {
  const intl = useIntl();
  const baseData = data.filter((item) => {
    return item.IsMedian;
  });

  const userData = data.filter((item) => {
    return !item.IsMedian;
  });

  const option = {
    title: {
      text: motionText,
      left: 'center',
      bottom: '8%',
      textStyle: {
        fontSize: 13,
        color: '#333',
        fontWeight: 'bold',
      },
    },
    color: ['#36abd3', '#A8DCBF'],
    grid: {
      left: '20%',
      right: '17%',
      top: '15%',
      bottom: '35%',
      height: '50%',
    },
    xAxis: {
      name: intl.formatMessage({ id: 'Charts.Blocks' }),
      type: 'category',
      data: ['1', '2', '3', '4'],
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      name: intl.formatMessage({ id: 'Charts.PathLength' }),
      type: 'value',
    },

    series: [
      {
        name: intl.formatMessage({ id: 'Charts.You' }),
        type: 'line',
        data: [],
        hoverAnimation: false,
        symbolSize: 3,
        itemStyle: {
          normal: {
            borderWidth: 3,
            borderColor: '#1a9bfc',
          },
        },
      },
      {
        name: intl.formatMessage({ id: 'Charts.Median' }),
        type: 'line',
        data: [],
        hoverAnimation: false,
        symbolSize: 3,
        itemStyle: {
          normal: {
            borderWidth: 3,
            borderColor: '#a2d9ba',
          },
        },
      },
    ],
  };

  for (let i = 1; i <= 4; i++) {
    const selectUser = userData.find((item) => {
      return item.Block === i;
    });

    const selectBase = baseData.find((item) => {
      return item.Block === i;
    });

    if (selectUser !== undefined) {
      option.series[0].data.push(selectUser.Value);
    }

    if (selectBase !== undefined) {
      option.series[1].data.push(selectBase.Value);
    }
  }

  return (
    <ReactEcharts
      option={option}
      style={{ height: '240px', width: '380px' }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default PathLengthChart;
