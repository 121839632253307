import React, { useEffect, useState } from 'react'
import { useIntl, connect, addLocale } from 'umi'
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import { IntlManager } from 'utils/helper';
import  Wrong  from 'components/Wrong'
import * as Sentry from "@sentry/react";
import config from 'utils/config'
const { localesExcelName } = config
import { read, utils } from 'xlsx';

const GlobalLayout = (props) => {
    const client = Sentry.getCurrentHub().getClient();
    const { Replay } = client._integrations || {};

    const { dispatch, children, loading, history } = props;

    const intl = useIntl()
    const [isBuilding, setIsBuilding] = useState(true);

    const initLocales = async () => {
      var localesArray = [];
      const zhLangs = {};
      const enLangs = {};
      const esLangs = {};
      const localesFile = await (
        await fetch(
            `https://rocketsystem.s3.us-west-1.amazonaws.com/Web/Attachment/${localesExcelName}.xlsx`,
            {
                method: 'GET',
                cache: 'no-cache'
            },
        )
      ).arrayBuffer();

      const wb = read(localesFile);

      wb.SheetNames.map((item) => {
        const ws = wb.Sheets[item];
        localesArray = localesArray.concat(
          utils.sheet_to_json(ws, {
            defval: '',
          }),
        );
      });

      localesArray
        .filter((item) => item.key)
        .forEach((item) => {
          const { key, zhCN, enUS, esES } = item;
          zhLangs[key] = zhCN.replace(/\\n/g, '\n');
          enLangs[key] = enUS.replace(/\\n/g, '\n');
          esLangs[key] = esES.replace(/\\n/g, '\n');
        });

      Object.keys(zhLangs).length > 0 &&
        await addLocale('zh-CN', zhLangs, {
          momentLocale: 'zh-cn',
          antd: zhCN,
        });
      Object.keys(enLangs).length > 0 &&
        await addLocale('en-US', enLangs, {
          momentLocale: 'en-us',
          antd: enUS,
        });
      Object.keys(enLangs).length > 0 &&
        await addLocale('es-ES', esLangs, {
          momentLocale: 'es-es',
          antd: esES,
        });
    };

    useEffect(() => {
      const { messages = {} } = intl || {}
      if (Object.keys(messages).length) {
        IntlManager.init(intl)
        setIsBuilding(false)
      }
    }, [intl])

    useEffect(() => {
        // 如有更新，则更新版本
        const CurrentVersion = localStorage.getItem('CurrentVersion')
        if (CurrentVersion === 'old') {
            window.location.reload();
            localStorage.setItem('CurrentVersion', 'new')
        }
    }, [history.location])

    useEffect(() => {
        initLocales()
        if (dispatch) {
            dispatch({
                type: 'global/init',
            })
        }
    }, [])

    return (
      <Sentry.ErrorBoundary
            onError={(error) => {
                setTimeout(() => {
                    Replay?.stop()
                }, 5000);
            }}
            fallback={({ error, componentStack, resetError }) => {
                return (
                    <Wrong />
                )
            }}
        >
            {!isBuilding && children}
        </Sentry.ErrorBoundary>
    )
}

export default Sentry.withProfiler(connect(({ loading }) => ({
  loading: loading.models.global,
}))(GlobalLayout))
