import React from 'react'
import { connect } from 'umi'
import { Row, Col, Badge } from 'antd'
import styles from './Report.less'
import { useIntl } from 'umi'
import CPTChart from 'components/VratChartComponents/CPTChart'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const ContinuousAttention = ({ dispatch, data, showNewReport }) => {
  const showData = data ? data : []

  const intl = useIntl()
  const CPTChartProps = {
    data: showData,
  }

  return (
    <div id="continuous" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.continuous.title' })}</p>
      </h2>
      <Row type="flex" justify="space-around" style={{ marginTop: 60 }}>
        <Col span={10}>
          <CPTChart {...CPTChartProps} />
        </Col>
        <Col span={9}>
          <div>
            <p style={{ fontWeight: '700' }}>{intl.formatMessage({ id: 'report.continuous.notes' })}</p>
            <p>
              <Badge status="default" />
              <span style={{ color: '#333' }}>
                {intl.formatMessage({ id: 'report.continuous.desc1' })}
              </span>
            </p>
            <p>
              <Badge status="default" />
              <span style={{ color: '#333' }}>
                {intl.formatMessage({ id: 'report.continuous.desc2' })}
              </span>
            </p>
            <p>
              <Badge status="default" />
              <span style={{ color: '#333' }}>
                {intl.formatMessage({ id: 'report.continuous.desc3' })}
              </span>
            </p>
            <p>
              <Badge status="default" />
              <span style={{ color: '#333' }}>
                {intl.formatMessage({ id: 'report.continuous.desc4' })}
              </span>
            </p>
          </div>
        </Col>
      </Row>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '5' : '5'}</p>
    </div>
  )
}

export default connect()(ContinuousAttention)
