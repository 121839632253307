import React from 'react'
import styles from './Report.less'
import { Image } from 'antd';
import PoweredBy from 'components/VratChartComponents/poweredBy'

const ReportDes = ({ title, imgUrl, pageNumber }) => {
    return (
        <div className={styles.page_div}>
            <h2 className={styles.page_title}>
                <p className={styles.eTitle}>{title}</p>
            </h2>
            <div style={{ margin: '-30px 30px 140px 30px' }}>
                <Image preview={false} src={imgUrl} />
            </div>
            <PoweredBy styles={styles} />
            <p className={styles.page_number}>{pageNumber}</p>
        </div>
    )
}

export default ReportDes