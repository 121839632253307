import { GetHfsOrderList, PostDownLoadExcel } from 'services/contract'
import { message } from 'antd'
import download from 'cognitiveleap-core-us/utils/download'
import moment from 'moment'

export default {
  namespace: 'hfsOrder',
  state: {
    isOpenUp: false,
    Filter: '',
    Type: '',
    StartDate: '',
    EndDate: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    orderList: []
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { call, put, take, select }) {
      const { Filter, Type, StartDate, EndDate, pagination } = yield select(state => state.hfsOrder)
      const { current, pageSize } = pagination
      const params = {
        Filter,
        Type: Type || '',
        StartDate,
        EndDate,
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize
      }
      const res = yield call(GetHfsOrderList, params)
      if (res.response.ok) {
        const { totalCount, items } = res.data
        yield put({
          type: 'updateState',
          payload: {
            pagination: { ...pagination, total: totalCount },
            orderList: items
          }
        })
      }
    },

    *onClear(_, { call, put, take, select }) {
      yield put({
        type: 'updateState',
        payload: {
          Filter: '',
          Type: '',
          StartDate: '',
          EndDate: '',
          pagination: {
            current: 1,
            pageSize: 10,
            total: 0
          }
        }
      })
      yield put({ type: 'query' })
    },

    *onTableChange({ payload }, { call, put, take, select }) {
      const { pagination } = yield select(state => state.hfsOrder)
      const { pagination: newPagination } = payload
      yield put({ type: 'updateState', payload: { pagination: { ...pagination, ...newPagination } } })
      yield put({ type: 'query' })
    },

    *downloadExcel({ payload: { intl } }, { call, put, take, select }) {
      const { Filter, Type, StartDate, EndDate } = yield select(state => state.hfsOrder)
      const params = {
        Filter,
        Type: Type || '',
        StartDate,
        EndDate
      }
      const hide = message.loading(intl.formatMessage({ id: 'exxportListLoading' }), 0);
      const res = yield call(PostDownLoadExcel, params)
      if (res.response.ok) {
        const { response } = res;
        response.blob().then(blob => {
          download(blob, `${intl.formatMessage({ id: 'OrderList' })}+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
        })
      }
      setTimeout(hide, 0)
    }
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/hfs/order') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
