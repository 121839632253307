
import localForage from 'localforage'
import { getCurrentSubject } from 'utils/utils'
import {
  CenterGetSubjectInfo,
  PostSubjectCheckIn,
  PostSubjectCheckOut,
  GetSubjectCheckInList,
} from 'services/userManager'
import subjectDetailModel from '../../SubjectDetail/models/subjectDetail'
import subjectAssessmentModel from '../../SubjectDetail/models/subjectAssessment'
import subjectVratModel from '../../SubjectDetail/models/subjectVrat'
import offlinePlanModel from '../../SubjectDetail/models/offlinePlan'
import subjectSleepModel from '../../SubjectDetail/models/sleep'
import subjectNutritionModel from '../../SubjectDetail/models/nutrition'
import subjectExerciseModel from '../../SubjectDetail/models/exercise'
import centerClockInRecord from '../../SubjectDetail/models/centerClockInRecord'
import { ENTRY } from '../../SubjectDetail/models/subjectDetail'
import { message } from 'antd'
import {getDvaApp} from 'umi'

export default {
  namespace: 'multiCheck',
  state: {
    subjects: [],
    currentSubjectInfo: null,
    drawerVisible: false,
    modalVisible: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *pageLoading(_, { take, put, call, select }) {
      const res = yield call(GetSubjectCheckInList, { MaxResultCount: 500 })
      if (res.response.ok) {
        console.log(res)
        const checkInSubjects = res.data.items
        if (checkInSubjects.length > 0) {
          let currentSubjectInfo = null
          const checkInIdList = checkInSubjects.map(item => item.subject.id)
          const subjects = checkInSubjects.map(item => item.subject)
          const localCurrentSubjectInfo = yield getCurrentSubject()
          if (
            localCurrentSubjectInfo !== null &&
            checkInIdList.includes(localCurrentSubjectInfo.id)
          ) {
            currentSubjectInfo = subjects.find(item => {
              return item.id === localCurrentSubjectInfo.id
            })
          } else {
            currentSubjectInfo = subjects[0]
          }
          //保存到local
          yield getCurrentSubject(currentSubjectInfo)
          //更新state
          yield put({
            type: 'updateState',
            payload: { subjects, currentSubjectInfo },
          })
          //让detail page刷新
          yield put({
            type: 'subjectDetail/loadPage',
            payload: { id: currentSubjectInfo.id, entry: ENTRY.Drawer },
          })
        } else {
          console.log('heihe')
          //todo only clear subjectlist & currentSubject
          localForage.clear()
          yield put({
            type: 'updateState',
            payload: { subjects: [], currentSubjectInfo: null },
          })
        }
      }
    },

    *checkOutSubject({ payload }, { take, put, call, select }) {
      const res = yield call(PostSubjectCheckOut, payload.id)
      if (res.response.ok) {
        yield put({ type: 'pageLoading' })
      }
    },

    *checkInSubject({ payload:subjectId }, { take, put, call, select }) {
      const res = yield call(PostSubjectCheckIn, subjectId)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { modalVisible: false },
        })
        const {subject} = res.data
        yield getCurrentSubject(subject)
        yield put({ type: 'pageLoading' })
      } else {
        const {error} = res.data
        message.error(error.message)
        yield put({
          type: 'updateState',
          payload: { modalVisible: false },
        })
      }
    },

    *switchSubject({ payload }, { take, put, call, select }) {
      const { subjects } = yield select(state => state.multiCheck)
      const currentSubjectInfo = subjects.find(item => {
        return item.id === payload.id
      })
      yield put({
        type: 'updateState',
        payload: { currentSubjectInfo },
      })
      yield getCurrentSubject(currentSubjectInfo)
      yield put({
        type: 'subjectDetail/loadPage',
        payload: { id: currentSubjectInfo.id, entry: ENTRY.Drawer },
      })
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/detail/multiCheck') {
    //       dispatch({ type: 'pageLoading', payload: location.query })
    //       getDvaApp().replaceModel(subjectDetailModel)
    //       getDvaApp().replaceModel(subjectAssessmentModel)
    //       getDvaApp().replaceModel(subjectVratModel)
    //       getDvaApp().replaceModel(offlinePlanModel)
    //       getDvaApp().replaceModel(subjectSleepModel)
    //       getDvaApp().replaceModel(subjectNutritionModel)
    //       getDvaApp().replaceModel(subjectExerciseModel)
    //       getDvaApp().replaceModel(centerClockInRecord)
    //     }
    //   })
    // },
  },
}
