import {
  matchDynamicForm,
  addOptionsToProperties,
  addDescriptionToProperties,
} from 'cognitiveleap-core-us/utils/utils'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'

import {
  PostPrepareData,
  GetPrepareList,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
  PutDescription,
} from 'services/rocketService/Prepare'

const { baseURL, uploadOfflineTrainingPlanMedias, uploadImagesUrl } = config

const uploadUrl = uploadOfflineTrainingPlanMedias

const contentMenu = {
  Properties: [
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
        },
        Action: baseURL + uploadImagesUrl,
        Header: getAuthHeader(),
        Required: true,
      },
      ShowTitle: '封面图',
      FormKey: 'image',
    },
    {
      EditorType: 'Input',
      ShowTitle: '训练计划名称',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: 'VIP',
      FormKey: 'isVip',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '线下单价(课时)($)',
      FormKey: 'price',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 2,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '线上单价(课时)($)',
      FormKey: 'onlinePrice',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 2,
        },
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: 'RMB',
      Setting: {
        DropdownOptions: [
          {
            Value: 'RMB',
            Id: 'RMB',
          },
        ],
        Required: false,
      },
      ShowTitle: '币种',
      FormKey: 'currencyType',
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: '介绍',
      FormKey: 'introduction',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: '注意事项',
      FormKey: 'notice',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '训练计划编号',
      FormKey: 'code',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '期次',
      FormKey: 'period',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 1,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '次课',
      FormKey: 'totalCourseNum',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: false,
      },
      ShowTitle: '关联计划',
      FormKey: 'onlinePlanId',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '关联证书',
      Description: '关联后，颁发证书才可正常使用。未关联则可直接使用',
      FormKey: 'qualificationMedalIds',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（始）',
      FormKey: 'ageStart',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（末）',
      FormKey: 'ageEnd',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '反馈（训练项目）',
      FormKey: 'feedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: '补充备注',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '反馈（次课）',
      FormKey: 'courseFeedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'extra',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '训练目标',
      FormKey: 'targetIds',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '前测',
      FormKey: 'preAssessmentIds',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '中测',
      FormKey: 'mediumAssessmentIds',
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '后测',
      FormKey: 'postAssessmentIds',
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: false,
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: '静态教具图片',
      FormKey: 'files',
    },
  ],
}

const remarkContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'description',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
  ],
}

const listToOptions = (list, showAttributeArr, joinStr = '') => {
  return list.map(item => {
    const Value = showAttributeArr.map(attribute => `${item[attribute]} `).join(joinStr)
    return { Value, Id: item.id.toString() }
  })
}

export default {
  namespace: 'adminOfflinePlan',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    availableTypes: [],
    avaliableFeedbackProperties: [],
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.adminOfflinePlan)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'offlinePlan')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onAddOrEdit({ payload: id }, { call, put, select }) {
      const isAdd = id === null ? true : false
      const res = yield call(PrepareForEdit, { id }, 'offlinePlan')
      if (res.response.ok) {
        const {
          targets,
          availableFeedbackProperties,
          availableAssessments,
          availableOnlinePlans,
          offlineTrainingPlan,
          qualificationMedals
        } = res.data
        let emptyContent = contentMenu

        const targetOptions = listToOptions(targets, ['displayName'])
        const propertyOptions = listToOptions(availableFeedbackProperties, ['displayName', 'name', 'type'])
        const onlinePlanOptions = listToOptions(availableOnlinePlans, ['displayName'])
        const qualificationOptions = listToOptions(qualificationMedals, ['displayName'])
        const assessmentOptions = listToOptions(availableAssessments, ['title', 'name'], '-')

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['feedbackProperties', 'feedbackPropertyId'],
          propertyOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['onlinePlanId'],
          onlinePlanOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['qualificationMedalIds'],
          qualificationOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['courseFeedbackProperties', 'feedbackPropertyId'],
          propertyOptions
        )

        emptyContent = addOptionsToProperties(emptyContent, ['targetIds'], targetOptions)
        emptyContent = addOptionsToProperties(emptyContent, ['preAssessmentIds'], assessmentOptions)
        emptyContent = addOptionsToProperties(emptyContent, ['mediumAssessmentIds'], assessmentOptions)
        emptyContent = addOptionsToProperties(
          emptyContent,
          ['postAssessmentIds'],
          assessmentOptions
        )
        let showForm = emptyContent

        if (!isAdd) {
          const { agePeriod, feedbackProperties, courseFeedbackProperties } = offlineTrainingPlan
          
          const { start, end } = agePeriod || {}
          const feedbacks = feedbackProperties.map(item => {
            const { extraProperties } = item
            const extra = JSON.stringify(extraProperties)
            return {
              ...item,
              feedbackPropertyId: item.id,
              extra,
            }
          })
          const courseFeedbacks = courseFeedbackProperties.map(item => {
            const { extraProperties } = item
            const extra = JSON.stringify(extraProperties)
            return {
              ...item,
              feedbackPropertyId: item.id,
              extra,
            }
          })

          showForm = matchDynamicForm(emptyContent, {
            ...offlineTrainingPlan,
            ageStart: start || 0,
            ageEnd: end || 0,
            feedbackProperties: feedbacks,
            courseFeedbackProperties: courseFeedbacks,
          })
        }
        yield put({
          type: 'updateState',
          payload: {
            content: showForm,
            modalType: isAdd ? 'add' : 'edit',
            modalVisible: true,
            selectItem: isAdd ? null : offlineTrainingPlan,
            avaliableFeedbackProperties: availableFeedbackProperties,
          },
        })
      }
    },

    *onEditRemark({ payload }, { call, put, select }) {
      const { description } = payload
      const showForm = matchDynamicForm(remarkContentMenu, { description })
      yield put({
        type: 'updateState',
        payload: {
          content: showForm,
          modalType: 'remark',
          modalVisible: true,
          selectItem: payload,
        },
      })
    },

    *onGetList({ payload }, { call, put, select }) {
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'offlinePlan')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.adminOfflinePlan)
      let res
      if (modalType === 'remark') {
        res = yield call(PutDescription, { id: selectItem.id, ...data })
      } else {
        if (modalType === 'edit') {
          res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'offlinePlan')
        } else {
          res = yield call(PostPrepareData, data, 'offlinePlan')
        }
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/offlinePlan') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
