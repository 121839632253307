import React, { useState, useEffect } from 'react';
import { useIntl, connect } from 'umi';
import { Dropdown, Menu, Space } from 'antd';
import {
  getToken,
  getTenantId,
  getUserTenantId,
} from 'cognitiveleap-core-us/utils/auth';
import { DownCircleOutlined } from '@ant-design/icons';
import PlannedProgram from 'components/PlannedProgram/PlannedProgram';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { getAssessmentStatus } from 'utils/utils';
import { GetCustomPlanList } from 'services/rocketService/Assessment';

import moment from 'moment';

import config from 'utils/config';
const { mobileUrl } = config;

import styles from '../detail.less';

const RecommendMusicList = ({ activePlan, currentUser }) => {
  const intl = useIntl();

  const { id: UserOfflineTrainingPlanId, assessmentStatus = [] } =
    activePlan || {};

  const { changedTenant } = currentUser;

  const [open, setOpen] = useState(false);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const initData = async () => {
    setLoading(true);
    const res = await GetCustomPlanList({
      UserOfflineTrainingPlanId,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { totalCount, items = [] } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize, activePlan]);

  const columns = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY HH:mm',
      },
    },
    recommendMusicCourse: {
      showText: intl.formatMessage({ id: 'Recommend Music Module' }),
      showType: 'Text',
      render: (text) => text.toUpperCase(),
    },
    usingMusicCourse: {
      showText: intl.formatMessage({ id: 'Current Music Module' }),
      showType: 'Text',
      render: (text) => text.toUpperCase(),
    },
    continuationMode: {
      showText: intl.formatMessage({ id: 'Renewal Mode' }),
      showType: 'Text',
      render: (text) => {
        switch (text) {
          case 'New':
            return intl.formatMessage({ id: 'New Module' });
          case 'Continue':
            return intl.formatMessage({ id: 'ContinueModule' });
          case 'Reset':
            return intl.formatMessage({ id: 'reset' });
          default:
            return text;
        }
      },
    },
    arrangeableClass: {
      showText: intl.formatMessage({ id: 'Available Session Hours' }),
      showType: 'Text',
    },
    scheduleClass: {
      showText: intl.formatMessage({ id: 'Arranged Sessions' }),
      showType: 'Text',
    },
    remainingClass: {
      showText: intl.formatMessage({ id: 'Remaining Sessions' }),
      showType: 'Text',
    },
    creationName: {
      showText: intl.formatMessage({ id: 'Operator' }),
      showType: 'Text',
    },
  };

  const onClickMenuItem = ({ key }) => {
    const reportUrl = `${mobileUrl}/m/rocketReport?to=${key}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`;
    window.open(reportUrl, '_blank');
  };

  const props = {
    loading,
    listData: listData.map((item) => {
      const { arrangeableClass, scheduleClass } = item;
      return {
        ...item,
        remainingClass: arrangeableClass - scheduleClass,
      };
    }),
    listSupport: columns,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: assessmentStatus.length
      ? [
          {
            showText: intl.formatMessage({ id: 'preAssessmentList' }),
            itemRender(record) {
              const { recommendUserAssessment, recommendUserAssessmentId } =
                record || {};
              const { userAssessment } = recommendUserAssessment || {};
              const { assessment, status, tenantId, needPay, finishTime } =
                userAssessment || {};
              const { title, assessmentType } = assessment;
              const tempStatus =
                status === 'ReportedReady' && !needPay
                  ? 'hasGetReport'
                  : status;

              const menu = (
                <Menu
                  onClick={onClickMenuItem}
                  className={styles.assessmentContent}
                >
                  <Menu.Item
                    key={recommendUserAssessmentId}
                    disabled={tenantId !== (changedTenant && changedTenant.id)}
                  >
                    <span>
                      <a>{title}</a>
                      <span className={styles.showPreAssessmentIsFinish}>
                        {getAssessmentStatus(tempStatus, intl)}
                      </span>
                      {finishTime &&
                        moment(finishTime)
                          .local()
                          .format('YYYY/MM/DD HH:mm:ss')}
                      <span
                        style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.5)' }}
                      >
                        {
                          {
                            PreAssessment: intl.formatMessage({
                              id: 'pre_assessment',
                            }),
                            MediumAssessment: intl.formatMessage({
                              id: 'mid_assessment',
                            }),
                          }[assessmentType]
                        }
                      </span>
                    </span>
                  </Menu.Item>
                </Menu>
              );

              return (
                <span key="assessmentList">
                  <Dropdown overlay={menu}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Space>
                        <span>
                          {intl.formatMessage({ id: 'assessmentDetails' })}
                        </span>
                        <DownCircleOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                </span>
              );
            },
          },
          {
            showText: intl.formatMessage({ id: 'preAssessmentList' }),
            itemRender(record) {
              return (
                <span key="trainingPrograms">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentPlan(record);
                      setOpen(true);
                    }}
                  >
                    <span>{intl.formatMessage({ id: 'Training Plan' })}</span>
                  </a>
                </span>
              );
            },
          },
        ]
      : [],
  };

  const plannedProgramProps = {
    open,
    activePlan: {
      ...currentPlan,
      customizedPlanId: currentPlan.id,
      id: currentPlan.userOfflineTrainingPlanId,
    },
    onClose: () => {
      setOpen(false);
    },
  };
  return (
    <>
      <p />
      <MagicTable {...props} />
      {open && <PlannedProgram {...plannedProgramProps} />}
    </>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(RecommendMusicList);
