
import { getNotificationCount } from 'utils/utils'
import { GetNotification, PostSignToRead } from 'services/notification'
import { IntlManager } from 'utils/helper'

export default {
  namespace: 'notificationCenter',

  state: {
    filterTitle: [],
    pagination: {
      User: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      System: {
        current: 1,
        pageSize: 10,
        total: 0,
      }
    },
    activeKey: 'User',
    isOpen: true,
    data: null,
    modalVisible: false,
    releaseInfo: null
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {

    *init(_, { call, put, select }) {
      const formatMessage = IntlManager.intl.formatMessage
      yield put({
        type: 'updateState', payload: {
          filterTitle: [
            {
              name: formatMessage({ id: 'all' }),
              title: '',
              type: 'all',
              checked: true,
              isShow: true
            },
            {
              name: formatMessage({ id: 'newAuthorization' }),
              title: formatMessage({ id: 'newAuthorization' }),
              checked: false,
              isShow: true
            },
            // {
            //   name: formatMessage({ id: 'trainingCheckIn' }),
            //   title: formatMessage({ id: 'trainingCheckIn' }),
            //   checked: false,
            //   isShow: true
            // },
            // {
            //   name: formatMessage({ id: 'assessmentSubmission' }),
            //   title: formatMessage({ id: 'assessmentSubmission' }),
            //   checked: false,
            //   isShow: true
            // },
            // {
            //   name: formatMessage({ id: 'exerciseActivated' }),
            //   title: formatMessage({ id: 'exerciseActivated' }),
            //   checked: false,
            //   isShow: false
            // },
            // {
            //   name: formatMessage({ id: 'sleepActivated' }),
            //   title: formatMessage({ id: 'sleepActivated' }),
            //   checked: false,
            //   isShow: false
            // },
            // {
            //   name: formatMessage({ id: 'nutritionActivated' }),
            //   title: formatMessage({ id: 'nutritionActivated' }),
            //   checked: false,
            //   isShow: false
            // },
            {
              name: formatMessage({ id: 'deauthorization' }),
              title: formatMessage({ id: 'deauthorization' }),
              checked: false,
              isShow: true
            },
            // {
            //   name: formatMessage({ id: 'Apply for FocusEDTx Cancellation' }),
            //   title: formatMessage({ id: 'Apply for FocusEDTx Cancellation' }),
            //   checked: false,
            //   isShow: false
            // },
          ],
        }
      })

      yield put({ type: 'getCount' });
      yield put({ type: 'load' })
    },

    *getCount(_, { call, put, select }) {
      getNotificationCount();
    },

    *load(_, { call, put, select }) {
      const { filterTitle, activeKey, pagination } = yield select(state => state.notificationCenter);
      const { current, pageSize } = pagination[activeKey]

      const Title = filterTitle.filter(item => item.checked)[0].title;
      const SkipCount = (current - 1) * pageSize
      const MaxResultCount = pageSize

      const params = {
        Type: activeKey,
        Title,
        SkipCount,
        MaxResultCount
      }
      const res = yield call(GetNotification, params);

      if (res.response.ok) {

        const newPagination = {
          ...pagination,
          [activeKey]: {
            ...pagination[activeKey],
            total: res.data.totalCount
          }
        }

        yield put({
          type: 'updateState',
          payload: {
            data: res.data.items,
            pagination: newPagination
          }
        })
      }

    },

    *changeCheckTag({ payload: { filter } }, { call, put, select }) {
      const { filterTitle, pagination } = yield select(state => state.notificationCenter);
      const copyFilterType = JSON.parse(JSON.stringify(filterTitle))
      const newPagination = {
        User: {
          current: 1,
          pageSize: 10,
          total: 0,
        }
      }

      copyFilterType.forEach(item => {
        if (item.title == filter.title) {
          item.checked = true;
        } else {
          item.checked = false
        }
      })

      yield put({
        type: 'updateState', payload: {
          filterTitle: copyFilterType,
          pagination: { ...pagination, ...newPagination }
        }
      })

      yield put({ type: 'load' })
    },

    *touchTab({ payload: { activeKey } }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { activeKey } })
      yield put({ type: 'load' })
    },

    *showMoreFilter({ payload: _ }, { call, put, select }) {
      const { filterTitle } = yield select(state => state.notificationCenter);
      const copyFilterType = JSON.parse(JSON.stringify(filterTitle));

      copyFilterType.forEach(item => {
        item.isShow = true
      })

      yield put({ type: 'updateState', payload: { filterTitle: copyFilterType } })
    },

    *changePagination({ payload: { current, pageSize } }, { call, put, select }) {
      const { pagination, activeKey } = yield select(state => state.notificationCenter);

      const newPagination = {
        ...pagination,
        [activeKey]: {
          ...pagination[activeKey],
          current,
          pageSize
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          pagination: newPagination
        }
      })

      yield put({ type: 'load' })
    },

  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/notification') {
    //       dispatch({ type: 'init' })
    //     }
    //   })
    // },
  },
}
