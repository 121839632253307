import React from 'react'

const OrgRole = () => {
  return (
    <div>
        <h1>企业角色管理</h1>
      </div>
      )
}
export default OrgRole
