import React, { useState, useEffect } from 'react';
import { Radio, Row, Col, Button, Dropdown, Space, Menu, message } from 'antd';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import NutritionPdfAnalysis from './components/NutritionPdtAnalysis';
import NutritionPdtDailyRecording from './components/NutritionPdtDailyRecording';
import { connect, useIntl } from 'umi';
import { PostNutrition } from 'services/pdt';
import { getActiveWeekdays, getEchartWeekAssigned } from 'utils/utils';
import moment from 'moment';

import styles from './index.less';

const NutritionPdt = ({ subjectDetail }) => {
  const intl = useIntl();

  const [mode, setMode] = useState('analysis');

  const [week, setWeek] = useState(null);
  const [numInfo, setNumInfo] = useState({})

  const { subjectInfo } = subjectDetail;
  const { pdtModules, id } = subjectInfo;
  const currentModule = pdtModules.find(item => item.pdtModule === 'Nutrition');
  const { appStartTime, stopTime } = currentModule || {};

  const { weekAssigned, startDay, endDay, currentWeek } = getEchartWeekAssigned(appStartTime, stopTime, week);

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  // const handleMenuClick = (e) => {
  //   const { key } = e;
  //   setWeek(key);
  // };

  const analysisProps = {
    week,
    changeWeek: (value) => {
      setWeek(value);
    }
  }

  const recordProps = {
    id,
    week,
    startDay,
    endDay
  }

  // const menu = (
  //   <Menu
  //     onClick={handleMenuClick}
  //     items={getActiveWeekdays(weekAssigned)}
  //   />
  // );

  const initData = async () => {
    const res = await PostNutrition({ userId: id })
    if (res.response.ok) {
      setNumInfo(res.data.data)
    } else {
      message.error('error')
    }
  }

  useEffect(() => {
    initData()
  }, [])

  const changeWeek = (type) => {
    setWeek(type == 'left' ? currentWeek - 1 : currentWeek + 1);
  }

  return (
    <div className={styles.NutritionPdtPanel}>
      <div className={styles.NutritionTitle}>{intl.formatMessage({ id: 'NutritionPdt' })}</div>
      <Row justify="center" align="middle" className={styles.NutritionPdtDes}>
        <Col span={6}>
          <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{ marginBottom: 8 }}
          >
            <Radio.Button value="analysis">{intl.formatMessage({ id: 'Analysis' })}</Radio.Button>
            <Radio.Button value="recording">{intl.formatMessage({ id: 'DailyRecording' })}</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={6}>{intl.formatMessage({ id: 'startTime' })}: {moment(appStartTime).format('YYYY-MM-DD HH:mm:ss')}</Col>
        <Col span={6}>{intl.formatMessage({ id: 'NumberDailyRecording' })}: {numInfo?.numbersOfRecord}</Col>
        <Col span={6}>{intl.formatMessage({ id: 'ContinuousRecording' }, { day: numInfo?.dayOfContinuousRecord })}</Col>
      </Row>
      {
        mode === 'recording' && <div style={{ marginTop: '15px' }}>
          {/* <span>Week:&nbsp;&nbsp;</span> */}
          {/* <Dropdown overlay={menu}>
            <Button>
              <Space>
                {currentWeek}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          &nbsp;&nbsp; */}
          <Space>
            <Button shape="circle" icon={<LeftOutlined />} disabled={currentWeek == 1} onClick={() => { changeWeek('left') }} />
            {moment(startDay).format('MM/DD/YYYY')} -
            {moment(endDay).format('MM/DD/YYYY')}
            <Button shape="circle" icon={<RightOutlined />} disabled={currentWeek === weekAssigned || weekAssigned == 1} onClick={() => { changeWeek('right') }} />
          </Space>
        </div>
      }
      {mode === 'analysis' ? (
        <NutritionPdfAnalysis {...analysisProps} />
      ) : (
        <div style={{ marginTop: '15px' }}><NutritionPdtDailyRecording {...recordProps} /></div>
      )}
    </div>
  );
};

export default connect(({
  subjectDetail,
}) => ({
  subjectDetail,
}))(NutritionPdt);
