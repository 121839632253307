import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const PathLengthChart = () => {
  const intl = useIntl();
  const option = {
    color: ['#36abd3', 'green'],
    legend: {
      data: [
        {
          name: intl.formatMessage({ id: 'Charts.You' }),
          icon: 'roundRect',
        },
        {
          name: intl.formatMessage({ id: 'Charts.MovementRange' }),
          icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAtCAYAAABRXm6KAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAYKADAAQAAAABAAAALQAAAAAJUMO2AAAEYklEQVR4Ae1cS4gTQRCtzk6y6Wx28Y8oCip+T/7w4OfgTUREUFRUPAiiHhS/CKLsyKoofhDFgwheFLyKIoIiZC8igogIIh4U/K2uCou6O7PZScbXySSZnnyMmkRcq6GYruqqavpVp6e7pzuCfiNJUz6E2XRQvJS5ZVqilBx2bin5YNUv1dagzAgKquRD0CsJfpX2g14NnW0+GrnQIuscmWSXa7ACsmSSh+XKmBlbWrKQ6GUZOYsLCOxB9oQk+UJhWRDrueKhwiUROxw74pJ7AKrvEMFpiOB3zcxE7x9GA7SD+jU5MxkE5BE5jhx6BaYpB4kgcayvve8gCdKG4bxCRhHgI1rXkN/mGbZFKOIMJAYSHp99JChJtymlyZjJI+BsdXojnyPvIZgLavUKFoU7w3En4dzJKyKjDUGq50O2xqdwM9wcPuvjOVsNAqsphd5+CaPHFKjf8EweG2QcD5oXhqAT1Cot2QmFWZ7SBavd2h78yQQdMP8TBEwK4T2ws5maL/eYPT1B7UIAVIlJESgfRW4SorcKfDpowDwjwAgwAjVAAKMN5kNCH4Jq4JddVEYAa6vZaUpvxrRUTXaWh+gMycomXFpLBLC+2gDwt8LnUNCKkPwqb2HZ/AJ0FTS+lpWxr2IEhBB3fdI5ah0wFjQZtD7dlOZfgw+demTTofRzn98xKgBjcoL+SL9avXGqIwJ2yu7yuc8EoLCruZ+++Qo5Ww8E9J3RuFGPOthnZQSsFitOvdlNORWAN5XVubTmCOwD/JwYAUaAEWAE/nsEeC/oL3QB7Dj05ao1Mt8vPc46aPGMKIdMfZ/5HQcDH49f++riX4QPjEZktW/CjaiQ69AR4ADoeDSc4wA0HHK9Qt4L0vFoCIcDDy25ingvKIdEI58m5aehjayW62IEGAFGIIAAL7wCgNSbxeHnXfgUM9oV7h3btTv1AJykFnwqsIiPJNYnDhcpLLvkOzgf6VWwILMOwH7QWGwQnZS98m2MYpvqUzt7jX6IrvWB343p6IPsQixF61CwFzQEB4c6CCelGa7aItBmtg0Trjjl83pajTSZAFiudR4FuZ3Q0TimfgWF2eD4LDj7+wgkKbkM1qM8D2/Q+8+pfPaGTIKc8OLwa/CrPYVphjBc3Obo9Hh+/CECwPKJsdh4iWOJS3DnYotjOk+LXOLg6FG8C1zQ+6gZnVikwII/RgAY5w/CFTvDcWkAfyliRmYUF7KkagTURRdTzqtGX5+GVrCIm/FRKUrtx0/oHo5XP7PJ7sZ7gvc0PMyiHdEJIi1mYo4/H6KNoKFukzvVPmSr25Jlk1G2JFAA8NX7YTdmSbsRBJxpx1c1k77glvuIgCq1Hmsd7iSdz0E5+EGpr9opUkL9e4CGBWQdkG1Q5eXSr8x01pdzUiRPIkK/kv51fbQVnfJToMndED5Rt2ACco2t+hcAq32gFaA5IPUiKf8yUZdvktCoNv3r+mgnRoZHeHwEqed1TDPvUzsuObaDq5B+AINGRoQvBjcJAAAAAElFTkSuQmCC',
        },
      ],
      type: 'scroll',
      icon: 'roundRect',
      selectedMode: false,
    },
    xAxis: {
      type: 'value',
      show: false,
    },
    yAxis: {
      show: false,
      type: 'value',
    },
    series: [
      {
        name: intl.formatMessage({ id: 'Charts.You' }),
        data: [],
        type: 'line',
      },
      {
        name: intl.formatMessage({ id: 'Charts.MovementRange' }),
        data: [],
        type: 'line',
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: '50px', width: '400px' }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default PathLengthChart;
