import confetti from 'canvas-confetti';

const DURATION_TIME = 5;

const CONFETTI_DEFAULTS = {
  colors: ['#5D8C7B', '#F2D091', '#F2A679', '#D9695F', '#8C4646'],
  shapes: ['square'],
  ticks: 500,
};

export const CanvasConfetti = {
  endTime: Date.now() + DURATION_TIME * 1000,
  playSchoolPride: () => {
    let leftConfettiTimer;
    let rightConfettiTimer;
    if (!leftConfettiTimer && !rightConfettiTimer) {
      leftConfettiTimer = window.setTimeout(() => {
        confetti({
          ...CONFETTI_DEFAULTS,
          particleCount: 60,
          angle: 60,
          spread: 100,
          origin: { x: 0 },
        });
      }, 50);
      rightConfettiTimer = window.setTimeout(() => {
        confetti({
          ...CONFETTI_DEFAULTS,
          particleCount: 60,
          angle: 120,
          spread: 100,
          origin: { x: 1 },
        });
      }, 150);
    }

    setTimeout(() => {
      if (window.leftConfettiTimer && window.rightConfettiTimer) {
        window.clearTimeout(leftConfettiTimer);
        window.clearTimeout(rightConfettiTimer);
      }
    }, 8 * 1000);
  },
};
