import { message } from 'antd'
import { GetAdminStaffList, GetQualification, GetTrainingDetailExcel } from 'services/trainingSystem'
import { ToSupervisor, CancelSupervisor } from 'services/supervisor'
import download from 'cognitiveleap-core-us/utils/download'
import moment from 'moment'

export default {
    namespace: 'staffList',
    state: {
        list: [],
        qualificationList: [],
        name: '',
        pagination: {
            current: 1,
            pageSize: 12,
            total: 0,
        }
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query({ payload }, { call, put, select }) {
            yield put({ type: 'getList' })
            // yield put({ type: 'getQualification' })
        },

        *getQualification({ payload }, { call, put, select }) {
            const res = yield call(GetQualification, { SkipCount: 0, MaxResultCount: 1000 })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { qualificationList: res.data.items } })
            }
        },

        *getList({ payload }, { call, put, select }) {
            const { name, pagination } = yield select(state => state.staffList)
            const { current, pageSize } = pagination
            const params = {
                Filter: name,
                SkipCount: (current - 1) * pageSize,
                MaxResultCount: pageSize
            }
            const res = yield call(GetAdminStaffList, params)
            if (res.response.ok) {
                const { items, totalCount } = res.data
                yield put({ type: 'updateState', payload: { list: items, pagination: { ...pagination, total: totalCount } } })
            }
        },

        *onsearch({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 12,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'getList' })
        },

        *exportExcel({ payload }, { call, put, select }) {
            const hide = message.loading('DownLoading...', 0)

            const res = yield call(GetTrainingDetailExcel, {})

            if (res.response.ok) {
                const { response } = res;
                response.blob().then(blob => {
                    download(blob, `带课列表+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
                })
            }

            setTimeout(hide, 0)
        },

        *changeTable({ payload: data }, { call, put, select }) {
            const { pagination: currentPagination } = yield select(state => state.staffList)
            const { pagination } = data
            yield put({
                type: 'updateState',
                payload: { pagination: { ...currentPagination, ...pagination } },
            })
            yield put({ type: 'getList' })
        },

        *onClear({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 12,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { name: '', pagination } })
            yield put({ type: 'getList' })
        },

        *toSupervisor({ payload }, { call, put, select }){
            const { id } = payload||{}
            const toSupervisorRes = yield call(ToSupervisor, {id})
            if(toSupervisorRes.response.ok){
                message.success("设定成功")
                yield put({ type: 'query' })
            }else{
                message.error("设定失败")
            }
        },

        *cancelSupervisor({ payload }, { call, put, select }){
            const { id } = payload||{}
            const toSupervisorRes = yield call(CancelSupervisor, {id})
            if(toSupervisorRes.response.ok){
                message.success("取消成功")
                yield put({ type: 'query' })
            }else{
                message.error("取消失败")
            }
        }
    },
    subscriptions: {
        // setupHistory({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/manage/group') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
