import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
} from 'services/rocketService/Prepare'

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '课件编号',
      FormKey: 'code',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '课件的标题',
      FormKey: 'title',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（始）',
      FormKey: 'ageStart',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '适合月龄（末）',
      FormKey: 'ageEnd',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'TimePicker',
      ShowTitle: '预计训练时间',
      FormKey: 'expectTime',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '最少人数',
      FormKey: 'least',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '最多人数',
      FormKey: 'maximum',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
  ],
}

const listSupport = {
  title: {
    showText: '课件标题',
    showType: 'Text',
  },
  code: {
    showText: '课件编号',
    showType: 'Text',
  },
}

export default {
  namespace: 'trainingCourse',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.trainingCourse)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'trainingCourse')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.trainingCourse)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'query' })
    },

    *onCopy({ payload: data }, { call, put, select }) {
      const res = yield call(GetPrepareEdit, data.id, 'trainingCourse')
      if (res.response.ok) {
        const { title } = res.data
        const postRes = yield call(
          PostPrepareData,
          { ...res.data, title: title + '-副本', code: '' },
          'trainingCourse'
        )
        if (postRes.response.ok) {
          yield put({ type: 'query' })
        }
      }
    },

    *onEdit({ payload: id }, { call, put, select }) {
      let emptyContent = null,
        insertValue = null
      const res = yield call(GetPrepareEdit, id, 'trainingCourse')
      if (res.response.ok) {
        emptyContent = contentMenu
        insertValue = res.data
        const { agePeriod } = insertValue
        const editUserInfoForm = matchDynamicForm(emptyContent, {
          ...insertValue,
          ageStart: agePeriod.start,
          ageEnd: agePeriod.end,
        })
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: res.data,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'trainingCourse')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      let emptyContent = null
      emptyContent = contentMenu
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: emptyContent, modalVisible: true },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.trainingCourse)
      let res
      if (modalType === 'edit') {
        res = yield call(
          PutPrepareData,
          { ...selectItem, ...data },
          selectItem.id,
          'trainingCourse'
        )
      } else {
        res = yield call(PostPrepareData, data, 'trainingCourse')
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/trainingCourse') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
