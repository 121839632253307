import React from 'react'
import { Row, Col } from 'antd'
import { useIntl } from 'umi'
import styles from './Report.less'
import PathLengthChart from 'components/VratChartComponents/PathLengthChart'
import PathLengthChartText from 'components/VratChartComponents/PathLengthChartText'
import PathLengthLegend from 'components/VratChartComponents/PathLengthLegend'
import headIcon from 'assets/vratReportImg/head_small_icon.png'
import handIcon from 'assets/vratReportImg/hand_small_icon.png'
import chestIcon from 'assets/vratReportImg/chest_small_icon.png'
import legIcon from 'assets/vratReportImg/leg_small_icon.png'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const PathLengthComponent = ({ data, showNewReport }) => {
  const intl = useIntl()
  const PathLength = data ? data.PathLength : []
  const Summaries = data ? data.Summarys : []

  // type 0->3['Head','Hand','Chest','Leg']
  const GetByType = (list, type) => {
    const result = list.filter(item => {
      return item.MotionType === type
    })
    return result
  }

  // type 0->3 ['Head','Hand','Chest','Leg']
  const FindByType = (list, type) => {
    const result = list.find(item => {
      return item.MotionType === type
    })
    return result !== undefined ? result : {}
  }

  const PercentileToString = percentile => {
    if (percentile === undefined) return ''
    else return `${(percentile * 100).toFixed(1)}%`
  }

  const testDataHead = GetByType(PathLength, 0)
  const testDataHand = GetByType(PathLength, 1)
  const testDataChest = GetByType(PathLength, 2)
  const testDataLeg = GetByType(PathLength, 3)

  // 折线图部分
  const PathLengthHeadChartProps = {
    data: testDataHead,
    motionText: intl.formatMessage({ id: 'report.pathLength.head' }),
  }

  const PathLengthHandChartProps = {
    data: testDataHand,
    motionText: intl.formatMessage({ id: 'report.pathLength.hand' }),
  }
  const PathLengthChestChartProps = {
    data: testDataChest,
    motionText: 'Chest',
  }
  const PathLengthLegChartProps = {
    data: testDataLeg,
    motionText: 'Leg',
  }

  function getColor(percent) {
    if (percent <= 33) {
      return '#42BF80'
    } else if (percent > 33 && percent <= 67) {
      return '#FFD352'
    } else if (percent >= 67) {
      return '#F3766E'
    }
  }

  // 折线图下方icon text显示部分
  // 如果百分比在0-33，红色，百分比33-67，黄色，67-100，绿色

  const percent0 = PercentileToString(1 - (FindByType(Summaries, 0).Percentile || 0))
  const percent1 = PercentileToString(1 - (FindByType(Summaries, 1).Percentile || 0))
  const percent2 = PercentileToString(1 - (FindByType(Summaries, 2).Percentile || 0))
  const percent3 = PercentileToString(1 - (FindByType(Summaries, 3).Percentile || 0))

  const PathLengthHeadChartTextProps = {
    data: {
      percentInfo: {
        percent: percent0,
        color: getColor(percent0.slice(0, percent0.length - 1)),
      },
    },
    motionIcon: headIcon,
    motion: intl.formatMessage({ id: 'report.pathLength.head' }),
  }
  const PathLengthHandChartTextProps = {
    data: {
      percentInfo: {
        percent: percent1,
        color: getColor(percent1.slice(0, percent1.length - 1)),
      },
    },
    motionIcon: handIcon,
    motion: intl.formatMessage({ id: 'report.pathLength.hand' }),
  }
  const PathLengthChestChartTextProps = {
    data: {
      percentInfo: {
        percent: percent2,
        color: getColor(percent2.slice(0, percent2.length - 1)),
      },
    },
    motionIcon: chestIcon,
    motion: 'Chest',
  }
  const PathLengthLegChartTextProps = {
    data: {
      percentInfo: {
        percent: percent3,
        color: getColor(percent3.slice(0, percent3.length - 1)),
      },
    },
    motionIcon: legIcon,
    motion: 'Leg',
  }

  return (
    <div id="path" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.pathLength.title' })}</p>
      </h2>
      <Row type="flex" justify="center" style={{ marginTop: 150, position: 'relative' }}>
        <Col span={9} className={styles.borderR}>
          <div>
            <PathLengthChart {...PathLengthHeadChartProps} />
            <PathLengthChartText {...PathLengthHeadChartTextProps} />
          </div>
        </Col>
        <Col span={9}>
          <PathLengthChart {...PathLengthHandChartProps} />
          <PathLengthChartText {...PathLengthHandChartTextProps} />
        </Col>
        {/* <Col span={9} className={styles.borderR}>
					<PathLengthChart {...PathLengthChestChartProps} />
					<PathLengthChartText {...PathLengthChestChartTextProps}></PathLengthChartText>
				</Col>
				<Col span={9}>
					<PathLengthChart {...PathLengthLegChartProps} />
					<PathLengthChartText {...PathLengthLegChartTextProps}></PathLengthChartText>
				</Col> */}
        <Col>
          <PathLengthLegend styles={styles} />
        </Col>
      </Row>
      {/* <div style={{marginTop: 20}}>
				<p style={{ marginLeft: '16%' }}>*头部/手部/胸部/腿部动作路径长度，超过的同龄人百分比</p>
				<p style={{ marginLeft: '16%' }}>*Median - 人群中位数</p>
      </div> */}
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '8' : '8'}</p>
    </div>
  )
}

export default PathLengthComponent
