import React from 'react'
import { Drawer, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import {useIntl } from 'umi'
import moment from 'moment'

// 获取重复排课
const getRepetitiveStyle = (periodIntervalNumber, periodIntervalUnit, intl) => {
    if (periodIntervalNumber === 1 && periodIntervalUnit === "Day") {
        return "每天"
    }
    if (periodIntervalNumber === 7 && periodIntervalUnit === "Day") {
        return intl.formatMessage({ id: 'oneWeek' })
    }
    if (periodIntervalNumber === 14 && periodIntervalUnit === "Day") {
        return intl.formatMessage({ id: 'twoWeeks' })
    }
    if (periodIntervalNumber === 1 && periodIntervalUnit === "Month") {
        return intl.formatMessage({ id: 'oneMonth' })
    }
    return intl.formatMessage({ id: 'never' })
}

const listSupport = (intl) => {
    return {
        executorName: {
            showText: intl.formatMessage({ id: 'trainer' }),
            showType: 'Text'
        },
        startCourseTime: {
            showText: (
                <div>
                    <div>{intl.formatMessage({ id: 'startTimefrom' })}</div>
                    <div style={{ color: '#999' }}>{intl.formatMessage({ id: 'durationHour' })}</div>
                </div>
            ),
            showType: 'Text'
        },
        calendarCourseTypeTitle: {
            showText: intl.formatMessage({ id: 'type' }),
            showType: 'Text'
        },
        repeatCourse: {
            showText: intl.formatMessage({ id: 'repeat' }),
            showType: 'Text'
        },
        calendarCount: {
            showText: intl.formatMessage({ id: 'numberOfRepetitions' }),
            showType: 'Text'
        },
        message: {
            showText: intl.formatMessage({ id: 'textReminder' }),
            showType: 'Text'
        },
    }
}

const ArrangeCourse = (props) => {

    const intl = useIntl()

    const { visible, addCourse, onclose, datasource, onEdit, onDelete } = props

    const MagicTableProps = {
        listData: datasource.map(item => {
            const {
                executor,
                startTime,
                calendarCourseType,
                periodIntervalNumber,
                periodIntervalUnit,
                calendarCount,
                executorBeforeHours,
                parentBeforeHours,
            } = item || {}
            const hasExecutorMessage = executorBeforeHours > 0
            const hasParentMessage = parentBeforeHours > 0
            return {
                ...item,
                executorName: executor?.name,
                startCourseTime: moment(startTime).format('YYYY/MM/DD HH:mm'),
                calendarCourseTypeTitle: intl.formatMessage({ id: calendarCourseType === "Offline" ? 'offline' : 'online' }),
                repeatCourse: getRepetitiveStyle(periodIntervalNumber, periodIntervalUnit, intl),
                calendarCount: !periodIntervalNumber?'':calendarCount,
                message: `${hasExecutorMessage ? intl.formatMessage({ id: 'trainer' }): ''}${hasExecutorMessage ? ' , ' : ''}${hasParentMessage ? intl.formatMessage({ id: 'guardian' }): ''}`
            }
        }),
        listSupport: listSupport(intl),
        Actions: [
            {
                showText: intl.formatMessage({ id: 'edit' }),
                itemRender(record) {
                    const { startTime } = record || {}
                    return moment(startTime).isSameOrAfter(moment()) ? (
                        <span key="checkin">
                            <a
                                onClick={() => {
                                    onEdit(record)
                                }}
                            >
                                {intl.formatMessage({ id: 'edit' })}
                            </a>
                        </span>
                    )
                        :
                        null
                },
            },
            {
                showText: intl.formatMessage({ id: 'delete' }),
                itemRender(record) {
                    const { startTime } = record || {}
                    return moment(startTime).isSameOrAfter(moment()) ? (
                        <span key="checkin">
                            <a
                                onClick={() => {
                                    onDelete(record)
                                }}
                            >
                                {intl.formatMessage({ id: 'delete' })}
                            </a>
                        </span>
                    )
                        :
                        null
                },
            }
        ]
    }

    return (
        <Drawer
            open={visible}
            title={
                <div>
                    <b style={{ fontSize: '18px' }}>{intl.formatMessage({ id: 'scheduleSession' })}</b>
                    <div style={{ fontSize: '15px' }}>{intl.formatMessage({ id: 'scheduleASession' })}</div>
                </div>
            }
            width={750}
            onClose={onclose}
        >
            <Button
                icon={<PlusOutlined />}
                onClick={() => addCourse()}
            >
                {intl.formatMessage({ id: 'scheduleSession' })}
            </Button>
            <div style={{ margin: '10px 0' }}>{intl.formatMessage({ id: 'scheduleDes' })}</div>
            <MagicTable {...MagicTableProps} />
        </Drawer>
    )
}

export default ArrangeCourse