import { GetAssessmentDetail } from 'services/rocketService/Assessment'
import { getLocale } from 'umi'

export default {
  namespace: 'questionnaireView',
  state: {
    userAssessmentInfo: null,
    locale: getLocale() === 'zh-CN' ? 'zh-cn' : 'en'
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *fetch({ payload: query }, { call, put, select, take }) {
      const {id} = query
      const res = yield call(GetAssessmentDetail, id)
      if(res.response.ok){
        yield put({
          type: 'updateState',
          payload: {
            userAssessmentInfo: res.data
          },
        })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/questionnaireView') {
    //       dispatch({ type: 'fetch', payload: location.query })
    //     }
    //   })
    // },
  },
}
