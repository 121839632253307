import React from 'react'
import { Modal, Table, Divider } from 'antd'
import { DownOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined, UpOutlined } from '@ant-design/icons';
export default class CourseModal extends React.Component {
  state = {
    programs: [],
  }

  handleOk = programs => {
    this.props.onOk(programs)
  }

  handleCancel = () => {
    this.setState({ programs: [] })
    this.props.onCancel()
  }

  componentDidUpdate(prevProps) {
    const prevIds = prevProps.programs.map(item => item.id)
    const currentIds = this.props.programs.map(item => item.id)
    const isContains = prevIds.every(item => currentIds.includes(item))
    if (isContains && prevIds.length === currentIds.length) {
      return
    } else {
      this.setState({ programs: this.props.programs })
    }
  }

  changeSort(list, current, newIndex) {
    if (current === newIndex) return
    const currentProgram = list[current]
    list.splice(current, 1)
    list.splice(newIndex, 0, currentProgram)
    this.setState({ programs: JSON.parse(JSON.stringify(list)) })
  }

  render() {
    const { sortModalVisible } = this.props
    const { programs } = this.state

    const columns = [
      {
        title: 'title',
        dataIndex: 'title',
      },
      {
        title: 'subTitle',
        dataIndex: 'subTitle',
      },
      {
        title: 'action',
        render: (text, record, index) => {
          if (index === 0) {
            return (
              <span>
                <DownOutlined
                  onClick={() => {
                    this.changeSort(programs, index, index + 1)
                  }}
                />
                <Divider type="vertical" />
                <VerticalAlignBottomOutlined
                  onClick={() => {
                    this.changeSort(programs, index, programs.length - 1)
                  }}
                />
              </span>
            )
          } else if (index === programs.length - 1) {
            return (
              <span>
                <VerticalAlignTopOutlined
                  onClick={() => {
                    this.changeSort(programs, index, 0)
                  }}
                />
                <Divider type="vertical" />
                <UpOutlined
                  onClick={() => {
                    this.changeSort(programs, index, index - 1)
                  }}
                />
              </span>
            )
          } else {
            return (
              <span>
                <VerticalAlignTopOutlined
                  onClick={() => {
                    this.changeSort(programs, index, 0)
                  }}
                />
                <Divider type="vertical" />
                <UpOutlined
                  onClick={() => {
                    this.changeSort(programs, index, index - 1)
                  }}
                />
                <Divider type="vertical" />
                <DownOutlined 
                  onClick={() => {
                    this.changeSort(programs, index, index + 1)
                  }}
                />
                <Divider type="vertical" />
                <VerticalAlignBottomOutlined 
                  onClick={() => {
                    this.changeSort(programs, index, programs.length - 1)
                  }}
                />
              </span>
            )
          }
        },
      },
    ]

    return (
      <Modal
        width={800}
        destroyOnClose={true}
        title={'训练选择'}
        open={sortModalVisible}
        onOk={() => this.handleOk(programs)}
        onCancel={() => this.handleCancel()}
      >
        <Table pagination={false} dataSource={programs} columns={columns} rowKey={'id'} />
      </Modal>
    )
  }
}
