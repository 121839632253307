import React, { useEffect } from 'react'
import { history } from 'umi';
import { connect } from 'umi'
import { Button, Input, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import SortOrderModal from 'components/SortOrderModal'
import styles from './index.less'

const { Search } = Input;

const modalTypeToTitle = {
    "add": '新建培训课程',
    "edit": '编辑培训课程'
}

const TrainingCourses = ({ dispatch, trainingCourses }) => {

    useEffect(() => {
        dispatch({ type: 'trainingCourses/query' })
    }, [])

    const { list, searchInput, modalVisible, content, modalType, sortOrderModal, selectItem, pagination } = trainingCourses

    const listSupport = {
        title: {
            showText: 'title',
            showType: 'Text',
        },
        subTitle: {
            showText: 'subTitle',
            showType: 'Text',
        },
        sortOrder: {
            showText: 'sortOrder',
            showType: 'Text',
        },
        length: {
            showText: '课时数',
            showType: 'Text',
        }
    }

    const tableProps = {
        listData: list,
        listSupport,
        pagination,
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'trainingCourses/changeTable', payload: data })
        },
        Actions: [
            {
                showText: '小节',
                onClick(record) {
                    history.push({
                        pathname: '/system/manage/courseDetail',
                        query: {
                            id: record.id,
                        },
                    })
                },
            },
            {
                showText: '信息',
                onClick(record) {
                    dispatch({ type: 'trainingCourses/onEdit', payload: record })
                },
            },
            {
                showText: '排序',
                onClick(record) {
                    dispatch({
                        type: 'trainingCourses/updateState',
                        payload: { selectItem: record, sortOrderModal: true },
                    })
                },
            },
            {
                showText: '删除',
                onClick(record) {
                    Modal.confirm({
                        title: '你确定要删除该条数据?',
                        content: '删除后将无法恢复！',
                        okText: '确认',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk() {
                            dispatch({ type: 'trainingCourses/onDelete', payload: record.id })
                        },
                    })
                },
            },
        ]
    }

    const modalProps = {
        title: modalTypeToTitle[modalType],
        visible: modalVisible,
        content,
        onCancel() {
            dispatch({ type: 'trainingCourses/updateState', payload: { modalVisible: false } })
        },
        onOk(value) {
            dispatch({ type: 'trainingCourses/onSubmitModal', payload: value })
        }
    }

    const sortModalProps = {
        visible: sortOrderModal,
        currentValue: selectItem && selectItem.sortOrder,
        onCancel() {
            dispatch({ type: 'trainingCourses/updateState', payload: { sortOrderModal: false } })
        },
        onOk(data) {
            dispatch({ type: 'trainingCourses/changeSortOrder', payload: data.sortOrder })
        }
    }

    return (
        <div className={styles.trainingCourses}>
            <div>培训课程</div>
            <div className={styles.trainingCoursesSearch}>
                <Button onClick={() => dispatch({ type: 'trainingCourses/onAdd' })}>新建培训课程</Button>
                <Search
                    placeholder="培训课程名称"
                    value={searchInput}
                    onChange={e =>
                        dispatch({ type: 'trainingCourses/updateState', payload: { searchInput: e.target.value } })
                    }
                    style={{ width: 200, margin: '0 10px' }}
                    onSearch={value => { dispatch({ type: 'trainingCourses/onSearchInput', payload: value }) }}
                />
                <Button onClick={() => dispatch({ type: 'trainingCourses/onClear' })}>clear</Button>
            </div>
            <MagicTable {...tableProps} />
            <MagicFormModal {...modalProps} />
            <SortOrderModal {...sortModalProps} />
        </div>
    )
}

export default connect(({ trainingCourses }) => ({ trainingCourses }))(TrainingCourses)