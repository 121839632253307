import React from 'react';
import styles from '../index.less';

const HeaderLine = ({ color }) => {
  return (
    <div className={styles.headerLine} style={{ backgroundColor: color }}></div>
  );
};

export default HeaderLine;
