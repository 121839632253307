import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal, Table } from 'antd'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import styles from '../../admin.less'
import { useIntl } from 'umi'
const confirm = Modal.confirm

const TrainingPlan = ({ rocketVersion, dispatch }) => {

  const intl = useIntl()

  const modalTitleMenu = {
    edit: intl.formatMessage({ id: 'editVersionInfo' }),
    add: intl.formatMessage({ id: 'addNewVersion' }),
  }

  useEffect(() => {
    dispatch({ type: 'rocketVersion/query' })
  }, [])

  const { modalType, content, modalVisible, list } = rocketVersion

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'displayName' }),
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: intl.formatMessage({ id: 'platform' }),
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: intl.formatMessage({ id: 'version' }),
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: "最低允许版本",
      dataIndex: 'minimalSupportVersion',
      key: 'minimalSupportVersion',
    },
    {
      title: intl.formatMessage({ id: 'action' }),
      key: 'actions',
      render: (text, record) => (
        <span>
          <a
            onClick={() => {
              dispatch({ type: 'rocketVersion/onEdit', payload: { record, intl } })
            }}
          >
            {intl.formatMessage({ id: 'edit' })}
          </a>
        </span>
      ),
    },
  ]

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'rocketVersion/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({
        type: 'rocketVersion/onSubmitModal',
        payload: value,
      })
    },
    content,
  }

  const onAddClick = () => {
    dispatch({ type: 'rocketVersion/onAdd', payload: { intl } })
  }

  return (
    <div>
      <h1 className={styles.pageTitle}>{intl.formatMessage({ id: 'rocketReleaseList' })}</h1>
      <Button onClick={onAddClick}>{intl.formatMessage({ id: 'addNewVersion' })}</Button>
      <Table rowKey={'id'} dataSource={list} columns={columns} className={styles.marginTop} bordered={true} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ rocketVersion }) => ({ rocketVersion }))(TrainingPlan)
