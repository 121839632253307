import { PostGuardianInfo, PutGuardian } from 'services/userManager'
import { PostCourseToUser, GetUserGrantCourse } from 'services/trainerDatabase'
import { GetAppUserQuestionnarie } from 'services/trainingSystem'
import { getFormatDate } from 'cognitiveleap-core-us/utils/utils'
import { IntlManager } from 'utils/helper'
import { getMobilePrefixOtherIntl } from 'utils/utils'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import { message } from 'antd'
import config from 'utils/config'

const { baseURL, uploadUserPicUrl } = config
const uploadUrl = uploadUserPicUrl

const content = intl => {
    return {
        Properties: [
            {
                EditorType: 'SingleWithSearch',
                Value: null,
                Setting: {
                    DropdownOptions: [],
                    Required: true,
                },
                ShowTitle: intl('course'),
                FormKey: 'courseIds',
                Description: null,
            },
            {
                EditorType: 'DatePicker',
                Value: null,
                Setting: {
                    Required: false,
                    DateType: 'date'
                },
                ShowTitle: intl('startTime'),
                FormKey: 'startDate'
            },
            {
                EditorType: 'DatePicker',
                Value: null,
                Setting: {
                    Required: false,
                    DateType: 'date'
                },
                ShowTitle: intl('endTime'),
                FormKey: 'endDate',
                Description: intl('dateDes'),
            },
        ]
    }
}

const editInfoContent = intl => {
    const mobilePrefix = getMobilePrefixOtherIntl(intl)

    return {
        Properties: [
            {
                EditorType: 'Input',
                ShowTitle: intl('Guardian Name'),
                FormKey: 'name',
                AdditionalData: null,
                Value: null,
                Setting: {
                    Required: true,
                },
                Description: null,
            },
            {
                EditorType: 'Dropdown',
                Value: '+1',
                Setting: {
                    Required: true,
                    DropdownOptions: mobilePrefix.map(item => {
                        const { prefix, countryName } = item
                        return {
                            Value: countryName,
                            Id: prefix
                        }
                    }),
                },
                ShowTitle: intl("countryCode"),
                FormKey: 'countryCode',
                Description: null,
            },
            {
                EditorType: 'Input',
                ShowTitle: intl('phone'),
                FormKey: 'phoneNumber',
                AdditionalData: null,
                Value: null,
                Setting: {
                    Required: true,
                },
                Description: null,
            },
            {
                EditorType: 'Dropdown',
                Value: 'Number',
                Setting: {
                    Required: true,
                    DropdownOptions: [
                        {
                            Value: intl('male'),
                            Id: 'Male',
                        },
                        {
                            Value: intl('female'),
                            Id: 'Female',
                        },
                    ],
                },
                ShowTitle: intl('gender'),
                FormKey: 'gender',
                Description: null,
            },
            {
                EditorType: 'Upload',
                Value: null,
                Setting: {
                    UploadSetting: {
                        isSingle: true,
                        uploadType: 'image',
                        cropIMG: true,
                        needRes: true,
                    },
                    Action: baseURL + uploadUrl,
                    Header: getAuthHeader(),
                    Required: false
                },
                ShowTitle: intl('profilePhoto'),
                FormKey: 'picture',
            },
        ]
    }
}

export default {
    namespace: 'userDetail',

    state: {
        userInfo: null,
        from: '',
        tabActiveKey: 'class',
        userClassTablePagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        userGrantCourse: null,
        selectModalVisible: false,
        userCourseList: [],
        content: null,
        editInfoVisible: false,
        drawerVisible: false,
        selectCourse: null,
        collapseActiveKey: []
    },

    effects: {
        *initPage({ payload }, { call, put, take, select }) {
            yield put({ type: 'getGuardianInfo', payload })
            yield put({ type: 'getUserClass', payload })
        },

        *getGuardianInfo({ payload }, { call, put, take, select }) {
            const { id, from } = payload
            const res = yield call(PostGuardianInfo, id)
            yield put({
                type: 'updateState',
                payload: { userInfo: res.data, from },
            })
        },

        *getUserClass({ payload }, { call, put, take, select }) {
            const { id } = payload
            const { userClassTablePagination } = yield select(state => state.userDetail)
            const parmas = {
                userId: id,
                SkipCount: 0,
                MaxResultCount: 10000
            }
            const res = yield call(GetUserGrantCourse, parmas)
            if (res.response.ok) {
                let { courseGrants, courses } = res.data
                const newPagination = {
                    ...userClassTablePagination,
                    total: courseGrants.totalCount
                }

                yield put({
                    type: 'updateState',
                    payload: {
                        userGrantCourse: res.data,
                        userClassTablePagination: newPagination
                    }
                })

                yield put({
                    type: 'changeUserClassTable',
                    payload: {
                        pagination: newPagination
                    }
                })
            }
        },

        *openCourses({ payload }, { call, put, take, select }) {
            const intl = IntlManager.MyIntl()

            const emptyContent = { ...content(intl) }
            let courseGrantIds = []
            let contentCourse = []
            const { userGrantCourse } = yield select(state => state.userDetail)
            const { courseGrants, courses } = userGrantCourse || {}
            const courseGrantItems = courseGrants && courseGrants.items ? courseGrants.items.filter(item => !item.isDeleted && item.status !== 'Expired') : []
            courseGrantIds = courseGrantItems.length > 0 ? courseGrantItems.map(item => item.course.id) : []
            contentCourse = courses ? courses.filter(item => !courseGrantIds.includes(item.id)) : []
            emptyContent.Properties[0].Setting.DropdownOptions = contentCourse.map(item => {
                return {
                    Value: item.title,
                    Id: item.id
                }
            })
            yield put({
                type: 'updateState',
                payload: {
                    selectModalVisible: true,
                    content: emptyContent
                }
            })
        },

        *editInfo({ payload }, { call, put, take, select }) {
            const intl = IntlManager.MyIntl()
            const { userInfo } = yield select(state => state.userDetail)

            const emptyContent = { ...editInfoContent(intl) }

            const { name, picture, countryCode, phone, gender } = userInfo || {}

            // 添加家长有两种方式 app or web
            // 两种添加方式获取回来的数据格式不一致有问题
            let tempCountryCode = countryCode;
            let tempPhone = phone;
            if (phone?.includes('+')) {
                tempCountryCode = '+' + tempCountryCode;
                tempPhone = phone.split(`${tempCountryCode}`)[1]
            }

            emptyContent.Properties[0].Value = name
            emptyContent.Properties[1].Value = tempCountryCode
            emptyContent.Properties[2].Value = tempPhone
            emptyContent.Properties[3].Value = gender
            emptyContent.Properties[4].Value = picture ? {
                id: picture,
                fileName: picture,
                url: picture
            } : null

            yield put({
                type: 'updateState',
                payload: {
                    editInfoVisible: true,
                    content: emptyContent
                }
            })
        },

        *onUpdateUserInfo({ payload: { values, intl } }, { call, put, select }) {
            const { userInfo } = yield select(state => state.userDetail)
            const { id } = userInfo || {}

            const { picture } = values;
            const { url } = picture || {};

            const params = {
                ...values,
                guardianId: id,
                picture: url || picture
            }

            const res = yield call(PutGuardian, params)
            if (res.response.ok) {
                message.success(intl.formatMessage({ id: 'success' }))
                yield put({ type: 'initPage', payload: { id } })
            } else {
                const { data: { error } } = res;
                if (error && error.message) {
                    message.warning(error.message);
                }
            }

            yield put({ type: 'updateState', payload: { editInfoVisible: false } })
        },

        *postCourseToUser({ payload: { data, intl } }, { call, put, take, select }) {
            const { userInfo } = yield select(state => state.userDetail)
            const { courseIds, startDate, endDate } = data
            const { id } = userInfo || {}
            const params = {
                userId: id,
                courseIds: [courseIds],
                startTime: startDate ? getFormatDate(startDate, true) : null,
                endTime: endDate ? getFormatDate(endDate, false) : null
            }
            const res = yield call(PostCourseToUser, params)
            if (res.response.ok) {
                message.success(intl.formatMessage({ id: 'success' }))
            } else {
                const { data: { error } } = res;
                if (error && error.message) {
                    message.warning(error.message);
                }
            }
            yield put({ type: 'getUserClass', payload: { id } })
            yield put({ type: 'updateState', payload: { selectModalVisible: false } })
        },

        *changeUserClassTable({ payload: data }, { call, put, take, select }) {
            const { userClassTablePagination: currentPagination, userGrantCourse } = yield select(state => state.userDetail)
            const { pagination } = data
            const { courseGrants } = userGrantCourse || {}
            const { items } = courseGrants || {}
            const newUserClassTablePagination = { ...currentPagination, ...pagination, }
            const pageSize = newUserClassTablePagination.pageSize
            const start = (newUserClassTablePagination.current - 1) * pageSize
            const end = newUserClassTablePagination.current * pageSize
            const userCourseList = items.slice(start, end)
            yield put({
                type: 'updateState',
                payload: {
                    userClassTablePagination: newUserClassTablePagination,
                    userCourseList
                },
            })
        },

        *showTestResult({ payload }, { call, put, take, select }) {
            const courseItem = payload
            const { course } = courseItem
            const { contents } = course
            if (contents && contents.length > 0) {
                yield put({ type: 'updateState', payload: { selectCourse: payload, drawerVisible: true } })
                yield put({ type: 'getAppUserQuestionnarie' })
            }
        },

        *getAppUserQuestionnarie({ payload }, { call, put, take, select }) {
            const { selectCourse, userInfo } = yield select(state => state.userDetail)
            const { course } = selectCourse
            const { contents } = course || {}
            const newData = []

            const requestList = contents.map(content => {
                const { id, questionnaires } = content
                const params = {
                    UserId: userInfo && userInfo.id,
                    CourseId: course && course.id,
                    CourseDetailId: id,
                    QuestionnaireId: questionnaires && questionnaires.length > 0 && questionnaires[0]
                }
                return call(GetAppUserQuestionnarie, params)
            })

            const resList = yield [...requestList]

            resList.forEach((item, index) => {
                let currentContent = JSON.parse(JSON.stringify(contents[index]))

                if (item.response.ok) {
                    if (currentContent) currentContent.myquestionnaire = item.data
                } else {
                    if (currentContent) currentContent.myquestionnaire = null
                }

                newData.push(currentContent)
            })

            yield put({
                type: 'updateState', payload: {
                    selectCourse: {
                        ...selectCourse,
                        course: {
                            ...course,
                            contents: newData
                        }
                    }
                }
            })
        }
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(({ pathname, query }) => {
        //         if (pathname === '/system/center/detail/userDetail') {
        //             dispatch({ type: 'initPage', payload: query })
        //         }
        //     })
        // },
    }
}