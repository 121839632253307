import React, { useEffect } from 'react'
import { Input, Button, Modal, Transfer, Popconfirm } from 'antd'
import SelectModal from 'components/SelectModal'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { connect } from 'umi'
import { history } from 'umi';
import styles from './index.less'

const { Search } = Input;

const listSupport = {
    name: {
        showText: "员工姓名",
        showType: 'Text',
    },
    email: {
        showText: "邮箱",
        showType: 'Text',
    },
    creationTime:{
        showText: "creationTime",
        showType: 'Time',
    },
    phone: {
        showText: "手机号",
        showType: 'Text',
    }
}

const SuperviseList = ({ SuperviseList, dispatch, loading, location }) => {

    useEffect(() => {
        dispatch({ type: 'SuperviseList/initPage', payload: location.query })
    }, [])

    const isLoading = loading.models.SuperviseList
    const {
        changeStaffModalVisible,
        staffList,
        dataSource,
        pagination,
        supervisorData,
        Filter,
    } = SuperviseList || {}

    const selectModalProps = {
        selectModalVisible: changeStaffModalVisible,
        allList: dataSource.filter(item => !item.disabled),
        onCancel() {
            dispatch({ type: 'SuperviseList/updateState', payload: { changeStaffModalVisible: false } })
        },
        onOk(values) {
            dispatch({ type: 'SuperviseList/addStaff', payload: values })
        }
    }

    const MagicTableProps = {
        loading: isLoading,
        listData: supervisorData ? supervisorData?.items?.map(item => {
            const { countryCode, phoneNumber } = item || {}
            return {
                ...item,
                phone: `${countryCode}${phoneNumber}`
            }
        }) : [],
        listSupport,
        pagination,
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'SuperviseList/changeTable', payload: data })
        },
        Actions: [
            {
                showText: '删除',
                itemRender(record) {
                    return (
                        <Popconfirm
                            placement="top"
                            title={"确定删除吗"}
                            onConfirm={() => dispatch({ type: 'SuperviseList/deleteSupervisor', payload: record })}
                            okText="确认"
                            cancelText="取消"
                        >
                            <a>删除</a>
                        </Popconfirm>
                    )
                },
            }
        ]
    }

    return (
        <div className={styles.superviseList}>
            <div className={styles.superviseListBack}>
                <Button
                    onClick={()=>history.goBack()}
                >
                    返回
                </Button>
                <b className={styles.superviseListBackTitle}>督导列表</b>
            </div>
            <div className={styles.superviseListTop}>
                <div>
                    <Search
                        placeholder="搜索姓名/邮箱"
                        style={{ width: 200 }}
                        value={Filter}
                        onChange={e => dispatch({ type: 'SuperviseList/updateState', payload: { Filter: e.target.value } })}
                        onSearch={() => dispatch({ type: 'SuperviseList/onSearch' })}
                    />
                    <Button
                        className={styles.superviseListTopClear}
                        onClick={() => dispatch({ type: 'SuperviseList/onclear' })}
                    >
                        取消筛选
                    </Button>
                </div>
                <Button
                    onClick={() => dispatch({ type: 'SuperviseList/getAllStaff' })}
                    loading={isLoading}
                >
                    添加督导员工
                </Button>
            </div>

            <div className={styles.superviseListContent}>
                <MagicTable {...MagicTableProps} />
            </div>

            {/* 添加督导员工弹框 */}
            <SelectModal {...selectModalProps} />
        </div>
    )
}

export default connect(({ SuperviseList, dispatch, loading}) => ({ SuperviseList, dispatch, loading }))(SuperviseList)