import { PostAdminStatistics, GetAllStatistic } from 'services/vrat/cms'
import { history } from 'umi';

export default {
  namespace: 'cmsStatistics',
  state: {
    statistic: [],
    pageIndex: 1,
    total: 0,
    pageSize: 12,
    searchField: null,
    TestStartTime: null,
    TestEndTime: null,
    ReportStartTime: null,
    ReportEndTime: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *fetchStatistics(_, { call, put, select, take }) {
      const { pageIndex, pageSize, searchField, TestStartTime, TestEndTime, ReportStartTime, ReportEndTime } = yield select(
        state => state.cmsStatistics
      )
      const res = yield call(PostAdminStatistics, pageIndex, pageSize, {
        searchField,
        TestStartTime,
        TestEndTime,
        ReportStartTime, 
        ReportEndTime
      })
      const { data, response } = res
      if (response.ok) {
        const { Items, TotalCount } = data
        yield put({ type: 'updateState', payload: { statistic: Items, total: TotalCount } })
      }
    },

    *download(_, { call, put, select, take }) {
      const { searchField, TestStartTime, TestEndTime, ReportStartTime, ReportEndTime } = yield select(state => state.cmsStatistics)
      yield call(GetAllStatistic, { searchField, TestStartTime, TestEndTime, ReportStartTime, ReportEndTime })
    },

    *filterFetch({ payload }, { call, put, select, take }) {
      yield put({ type: 'updateState', payload: {
        ...payload,
        pageSize: 12,
        pageIndex: 1,
      } })
      yield put({ type: 'fetchStatistics' })
    },

    goToDetail({ payload }, { call, put, select, take }) {
      const { TestingCenter } = payload
      const { Id: centerId } = TestingCenter
      history.push({
        pathname: '/system/manage/vratCenterDetail',
        query: { centerId },
      })
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname == '/system/manage/statistic/vratStatistic') {
    //       dispatch({ type: 'fetchStatistics' })
    //     }
    //   })
    // },
  },
}
