import React, { useEffect } from 'react';
import { connect, useIntl } from 'umi'
import ShowDataAnalysis from 'components/ShowDataAnalysis'
import CardHead from 'components/CardHead';
import { Card, Spin } from 'antd'
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart'
import { ilsKeyIndicators, ilsLegend } from '../config';
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate';
import style from './index.less';

const iLsAnalysis = ({ iLsAnalysis, loading, dispatch }) => {

    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'iLsAnalysis/initPage' })
    }, [])

    const {
        keyIndicators,
        currentTag,
        datePickerIsOpen,
        dateYearPickerData,
        dateMonthPickerData,
        analysisData,
    } = iLsAnalysis;

    if (analysisData == null || keyIndicators == null) {
        return <Spin />
    }

    const { trend } = analysisData;
    const isLoadingAnalysis = loading.effects['iLsAnalysis/loadAnalysisData'];

    const showIlsKeyIndicators = ilsKeyIndicators(intl).map(item => {
        return {
            ...item,
            data: keyIndicators[item.field]
        }
    })

    const chartProps = {
        legendData: ilsLegend(intl),
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        dataSource: trend
    };

    const changeTag = (tag, checked) => {
        dispatch({
            type: 'iLsAnalysis/changeTag',
            payload: {
                key: tag.key
            }
        })
    }

    const handleOpenChange = () => {
        dispatch({
            type: 'iLsAnalysis/updateState',
            payload: {
                datePickerIsOpen: !datePickerIsOpen
            }
        })
    }

    const handlePanelChange = (time) => {
        dispatch({
            type: 'iLsAnalysis/handlePanelChange',
            payload: {
                time
            }
        })
    }

    const analysisChoiceDateProps = {
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        datePickerIsOpen,
        changeTag,
        handleOpenChange,
        handlePanelChange
    }

    return (
        <Card title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'brainTrainAnalysis' })}</b>} bordered={false}>
            <div className={style.iLsAnalysis}>
                <div className={style.keyIndicators}>
                    <CardHead title={intl.formatMessage({ id: 'keyIndicators' })} />
                    <ShowDataAnalysis keyIndicators={showIlsKeyIndicators} />
                </div>
                <Spin
                    spinning={isLoadingAnalysis}
                >
                    <div className={style.trendAnalysis}>
                        <CardHead title={intl.formatMessage({ id: 'trendAnalysis' })} />
                        <AnalysisChoiceDate {...analysisChoiceDateProps} />
                        <div className={style.trendAnalysisContent}>
                            <CommonAnalysisLineChart {...chartProps} />
                        </div>
                    </div>
                </Spin>
            </div>
        </Card>
    )
}

export default connect(({ iLsAnalysis, loading }) => ({ iLsAnalysis, loading }))(iLsAnalysis);