
import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const confirm = Modal.confirm

const modalTitleMenu = {
    edit: '编辑区域',
    add: '新建区域',
}

const listSupport = {
    creationTime: {
        showText: 'creationTime',
        showType: 'Time',
    },
    displayName: {
        showText: 'displayName',
        showType: 'Text',
    },
    enterpriseCount: {
        showText: 'enterpriseCount',
        showType: 'Text',
    },
    childCount: {
        showText: 'centerCount',
        showType: 'Text',
    },
    state: {
        showText: 'state',
        showType: 'Text',
    },
    isActive: {
        showText: 'isActive',
        showType: 'Bool',
    },
    isSend: {
        showText: 'isSend',
        showType: 'Bool',
    },
}

const AreaList = ({ areaList, dispatch }) => {

    useEffect(() => {
        dispatch({ type: 'areaList/query' })
    }, [])

    const { modalType, content, modalVisible, list, pagination } = areaList

    const modalTypeToTitle = modalType => {
        return modalTitleMenu[modalType]
    }

    const showActive = (isActive, isSend) => {
        if (isActive && isSend) return null
        if (isSend) return '重新发送'
        else {
            return '发送激活邮件'
        }
    }

    const modalProps = {
        title: modalTypeToTitle(modalType),
        visible: modalVisible,
        onCancel() {
            dispatch({ type: 'areaList/updateState', payload: { modalVisible: false } })
        },
        onOk(value) {
            console.log('添加区域', value)
            dispatch({ type: 'areaList/onSubmitModal', payload: value })
        },
        content,
    }

    const TableProps = {
        listData: list,
        listSupport,
        pagination,
        onTableChange(pagi, fil = {}, sort = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) {
                data = { ...data, pagination: pagi }
            }
            dispatch({ type: 'areaList/changeTable', payload: data })
        },
        Actions: [
            {
                showText: '编辑',
                itemRender(record) {
                  const { isActive } = record;
                  return (
                      <a
                        onClick={() => {
                          dispatch({ type: 'areaList/onEdit', payload: record.id })
                        }}
                      >
                        编辑
                    </a>
                  )
                }
              },
            {
                showText: '删除',
                onClick(record) {
                    confirm({
                        title: '你确定要删除该条数据?',
                        content: '删除后将无法恢复！',
                        okText: '确认',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk() {
                            // dispatch({ type: 'areaList/onDelete', payload: record.id })
                        },
                    })
                },
            },
            {
                showText: '发送激活邮件',
                itemRender(record) {
                    const { isSend, isActive } = record
                    const showWord = showActive(isActive, isSend)
                    return showWord == null ? null : (
                        <span key="sendEmail">
                            <a
                                onClick={() => {
                                    dispatch({ type: 'areaList/onSendEmail', payload: record.id })
                                }}
                            >
                                {showWord}
                            </a>
                        </span>
                    )
                },
            },
        ],
    }

    const onAddClick = () => {
        dispatch({ type: 'areaList/onAdd' })
    }

    return (
        <div>
            <h1>区域管理</h1>
            <Button onClick={onAddClick}>新建区域</Button>
            <MagicTable {...TableProps} />
            <MagicFormModal {...modalProps} />
        </div>
    )
}

export default connect(({ areaList }) => ({ areaList }))(AreaList)