import React from 'react';
import { Row, Col, Spin, Button } from 'antd';
import moment from 'moment';
import LineWithIconChart from 'components/VratChartComponents/LineWithIconChart';
import ScoreChart from 'components/VratChartComponents/ScoreChart';
import cogleapLogo1 from 'assets/CL_logo.png'
import cogleapLogo2 from 'assets/cogleap2.png'
import { gradeList } from '../../Center/SubjectList/models/subjectList';
import { countAge } from 'utils/utils';
import { useIntl, getLocale } from 'umi';

import styles from './index.less';

const FirstPage = ({
  data,
  tenantInfo,
  reportVersion,
  totalShow,
  basicLoading,
  overViewLoading,
  lineWithIconLoading,
  lineWithIcon,
  continuousAttentionLoading,
  continuousAttention,
  sixDimensionLoading,
  pathLengthLoading,
  motionCharacteristicsLoading,
  directionTrackingLoading,
}) => {
  // 中心名字
  const { isShowClLogo, isShowCenterLogo } = tenantInfo || {}
  const tenantLogo = tenantInfo && tenantInfo.logo && tenantInfo.logo.url

  // 头部数据
  const intl = useIntl();
  const showData = data ? data : {};
  const { Subject = {}, Case = {}, TestLocation = {}, TestingCenter = {}, UnitType } = showData;
  const grade = gradeList().find((item) => item.key === Case.Grade);
  const { TestDate } = Case || {};

  const { Name } = TestLocation || {}
  const { DisplayName } = TestingCenter || {}

  const displayName = Name || DisplayName

  // 一、总体表现
  const attentionPerformance = totalShow
    ? (totalShow.AttentionPerformanceIndex * 100).toFixed(1)
    : 100;
  const motionPerformance = totalShow
    ? (totalShow.MotionPerformanceIndex * 100).toFixed(1)
    : 100;

  const AttentionIndexProps = {
    data: attentionPerformance,
  };

  const MotionIndexProps = {
    data: motionPerformance,
  };

  // 二、注意力和头部运动得实时表现
  const Motion = lineWithIcon ? lineWithIcon.Motion : [];
  const Behaviour = lineWithIcon ? lineWithIcon.Behaviour : [];

  const LineWithIconChartProps = {
    dataStruct: {
      Motion,
      Behaviour,
      type: 'medicalChn',
    },
  };

  const continuousAttentionShowData = continuousAttention
    ? continuousAttention
    : [];

  const genderToStr = (gender) => {
    switch (gender) {
      case 'Male':
        return intl.formatMessage({ id: 'male' });
      case 'Female':
        return intl.formatMessage({ id: 'female' });
      default:
        return intl.formatMessage({ id: 'other' });
    }
  };

  const getTotal = (type) => {
    let total = 0;
    continuousAttentionShowData &&
      continuousAttentionShowData
        .slice(1, continuousAttentionShowData.length)
        .map((item) => {
          if (type === 'right') {
            return (total += parseInt(item.CorrectAnswer));
          } else if (type === 'miss') {
            return (total += parseInt(item.OmissionError));
          } else if (type === 'error') {
            return (total += parseInt(item.CommissionError));
          }
        });
    return total;
  };

  const locale = getLocale();

  const logoSrc = locale === 'zh-CN' ? cogleapLogo2 : cogleapLogo1

  return (
    <div id="basic" className={`${styles.page_div}`}>
      <div className={styles.downlaod}>
        {!(
          continuousAttentionLoading ||
          lineWithIconLoading ||
          overViewLoading ||
          basicLoading ||
          sixDimensionLoading ||
          pathLengthLoading ||
          motionCharacteristicsLoading ||
          directionTrackingLoading
        ) && (
            <Button
              type="primary"
              onClick={() => {
                document.getElementById('downlaodButton').style.display = 'none';
                window.print();
                document.getElementById('downlaodButton').style.display = 'block';
              }}
              id="downlaodButton"
            >
              打印测试报告
            </Button>
          )}
      </div>
      {/* <hr className={styles.titleLine} /> */}
      {/* <div className={styles.centerName}>
                <div className={styles.name}>{centerName}</div>
            </div> */}
      {/* <img src={cogleapLogo} alt="logo" className={styles.logo} /> */}
      <div className={styles.headerLogo}>
        {
          isShowClLogo && <img src={logoSrc} alt="logo" className={styles.logo} />
        }
        {
          tenantLogo && isShowCenterLogo &&
          <img src={tenantLogo} alt="logo" className={styles.tenantLogo} />
        }
      </div>
      <div className={styles.firstPageTitle}>
        <span className={styles.line}></span>
        <span className={styles.title}>vCAT 虚拟现实注意力测评报告</span>
        <span className={styles.line}></span>
      </div>
      <Spin spinning={basicLoading} className={styles.spinnerWrapperText}>
        {JSON.stringify(Subject) !== '{}' && (
          <div className={styles.info}>
            <Row>
              <Col span={6}>
                姓名：
                {Subject.Name[0] === '*' &&
                  Subject.Name[Subject.Name.length - 1] === '*'
                  ? '**'
                  : Subject.Name}
              </Col>
              <Col span={6}>性别：{genderToStr(Subject.Gender)}</Col>
              <Col span={6}>
                出生日期：
                {Subject.BirthDay
                  ? moment(Subject.BirthDay).local().format('YYYY/MM/DD')
                  : ''}
              </Col>
              <Col span={6}>年级：{grade ? grade.value : Case.Grade}</Col>
              <Col span={6}>
                测评日期：
                {TestDate ? moment(TestDate).local().format('YYYY/MM/DD') : ''}
              </Col>
              <Col span={6}>
                测评年龄：
                {moment(Subject.BirthDay).isValid()
                  ? countAge(Subject.BirthDay, intl, Case.TestDate)
                  : ''}
              </Col>
              {/* <Col span={6}>测评地点：{TestLocation.Name}</Col> */}
              <Col span={6}>测评地点：{displayName}</Col>
              <Col span={6}></Col>
              <Col span={6}>测评编号：{Case.Id}</Col>
              {/* <Col span={6}>
                            测试师：{Subject.CoachName}
                        </Col> */}
              <Col span={6}>测评版本：{UnitType ? Case.AppVersion + ` - ${UnitType}` : Case.AppVersion}</Col>
              <Col span={6}>报告版本：{`V${reportVersion}`}</Col>
            </Row>
          </div>
        )}
      </Spin>
      <Spin spinning={overViewLoading} className={styles.spinnerWrapperText}>
        <div className={styles.headerTextFirst}>一、总体表现</div>
        <div className={styles.scorePanel}>
          <div>
            <div className={styles.overviewChart}>
              <div className={styles.scoreCard}>
                <ScoreChart {...AttentionIndexProps} />
              </div>
              <div className={styles.scoreCardDes}>注意力指数</div>
            </div>
          </div>
          <div>
            <div className={styles.overviewChart}>
              <div className={styles.scoreCard}>
                <ScoreChart {...MotionIndexProps} />
              </div>
              <div className={styles.scoreCardDes}>动作指数</div>
            </div>
          </div>
        </div>
      </Spin>
      <Spin
        spinning={lineWithIconLoading && continuousAttentionLoading}
        className={styles.spinnerWrapperText}
      >
        <div className={styles.headerTextFirst}>
          二、注意力和头部运动的实时表现
        </div>
        <LineWithIconChart {...LineWithIconChartProps} />
        <div className={styles.description}>
          注意力和头部运动的实时表现（13分钟）
        </div>
        <div className={styles.headerTextBody}>
          <div className={styles.right}>
            <span>正确次数：</span>
            <span className={styles.rightResult}>{getTotal('right')}</span>
            <span> / 52</span>
          </div>
          <div className={styles.miss}>
            <span>漏击次数（未集中注意力）：</span>
            <span className={styles.missResult}>{getTotal('miss')}</span>
          </div>
          <div className={styles.error}>
            <span>错击次数(冲动，在不需要应答时扣动手柄）：</span>
            <span className={styles.errorResult}>{getTotal('error')}</span>
          </div>
        </div>
      </Spin>
      {/* <span className={styles.page_number}>1</span> */}
    </div>
  );
};

export default FirstPage;
