import React, { Fragment } from 'react'
import { Modal, Button } from 'antd'
import { IntlManager } from 'utils/helper'
import styles from '../detail.less'

const ArrangeAssessmentModal = ({ visible, subjectInfo, onOk, onCancel, dataSource }) => {
  const intl = IntlManager.MyIntl()

  const { name } = subjectInfo;
  const assessmentsArr = dataSource && dataSource.assessments && dataSource.assessments;
  return (
    <Modal
      title={<b>{intl('scheduleAssessment')}</b>}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <div style={{ fontSize: '16px', textAlign: 'center' }}>
        {
          assessmentsArr && assessmentsArr.length > 0 ?
            intl('sureScheduleFollowing', { name })
            :
            intl('noTestScheduled')
        }
      </div>
      {
        assessmentsArr && assessmentsArr.length > 0 &&
        <div className={styles.arranegAssessmentContent}>
          {
            assessmentsArr.map((item, index) => {
              return <div key={index}>{item.title}</div>
            })
          }
        </div>
      }
      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        {
          assessmentsArr && assessmentsArr.length > 0 ?
            <Fragment>
              <Button style={{ marginRight: '30px', width: '110px' }} onClick={() => { onCancel() }}>{intl('cancel')}</Button>
              <Button type='primary' style={{ width: '110px' }} onClick={() => { onOk() }}>{intl('submit')}</Button>
            </Fragment>
            :
            <Button type='primary' style={{ width: '110px' }} onClick={() => { onCancel() }}>{intl('submit')}</Button>
        }
      </div>
    </Modal>
  )
}

export default ArrangeAssessmentModal