import {
  GetUserAssessmentListApi,
  GetAssessmentDetail,
  GetGetRocketAssessment,
  GetAssessmentListQrCodeApi,
  PostSendToGuardianApi,
  GetAssessmentReportApi,
  PostUserAssessmentsExcel
} from 'services/rocketService/Assessment'
import { GetUserAssessmentTimesSettingRecords } from 'services/userConfiguration'
import { isJson } from 'cognitiveleap-core-us/utils/helper'
import { IntlManager } from 'utils/helper'
import { showRemainingTimesMsg } from 'utils/utils' 
import { message, Modal } from 'antd'
import * as SurveyPDF from 'survey-pdf'


export default {
  namespace: 'questionnaireList',
  state: {
    childName: '',
    listData: [],
    filters: {},
    filterDate: {
      startTime: '',
      endTime: ''
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 100,
    },
    orderBy: null,
    sendToGuardianModalVisible: false,
    selectAssessmentModalVisible: false,
    currentSendToGuardianRecord: null,
    transferList: [],
    assessmentName: '',
    btnLoading: false
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage(_, { call, put, take, select }) {
      const { childName, filterDate, pagination, orderBy, assessmentName, filters } = yield select(state => state.questionnaireList)
      const { current, pageSize } = pagination
      const { assessmentStatus = [] } = filters
      const parmas = {
        BeginTime: filterDate.startTime,
        EndTime: filterDate.endTime,
        Filter: childName,
        Status: assessmentStatus,
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize,
        Sorting: orderBy == 'ascend' ? 'ASC' : 'DESC',
        AssessmentName: assessmentName
      }
      const res = yield call(GetUserAssessmentListApi, parmas)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            pagination: { ...pagination, ...{ total: res.data.totalCount, current } },
            listData: res.data.items
          }
        })
      }
    },

    *onSearch(_, { call, put, take, select }) {
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 100,
      }
      yield put({
        type: 'updateState',
        payload: { pagination }
      })

      yield put({
        type: 'loadPage'
      })
    },

    *clearFilter(_, { call, put, take, select }) {
      const childName = ''
      const assessmentName = ''
      const orderBy = null
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 100,
      }
      const filterDate = {
        startTime: '',
        endTime: ''
      }
      yield put({
        type: 'updateState',
        payload: { childName, assessmentName, orderBy, pagination, filterDate }
      })
      yield put({
        type: 'loadPage'
      })
    },

    *changeTable({ payload: data }, { call, put, take, select }) {
      const { orderBy, pagination } = yield select(state => state.questionnaireList)
      const { pagination: newPagination, sorter, filters = {} } = data

      yield put({
        type: 'updateState',
        payload: { pagination: { ...pagination, ...newPagination }, orderBy: sorter ? (sorter.order || "ascend") : orderBy, filters }
      })

      yield put({
        type: 'loadPage'
      })
    },

    *exportExcel({ payload: { currentUser } }, { call, put, take, select }) {
      const { changedTenant: { id: tenantId, displayName: tenantName } } = currentUser
      yield put({ type: 'updateState', payload: { btnLoading: true } })

      const { childName, filterDate, pagination, orderBy, assessmentName, filters } = yield select(state => state.questionnaireList)
      const { current, pageSize } = pagination
      const { assessmentStatus = [] } = filters
      yield call(PostUserAssessmentsExcel, {
        beginTime: filterDate.startTime,
        endTime: filterDate.endTime,
        filter: childName,
        status: assessmentStatus,
        skipCount: (current - 1) * pageSize,
        maxResultCount: pageSize,
        sorting: orderBy == 'ascend' ? 'ASC' : 'DESC',
        assessmentName: assessmentName,
        tenantId
      }, tenantName + '_')
      yield put({ type: 'updateState', payload: { btnLoading: false } })
    },

    *sendToGuardian({ payload: { intl } }, { call, put, take, select }) {
      const { currentSendToGuardianRecord } = yield select(state => state.questionnaireList)
      const parmas = {
        userAssessmentIds: [currentSendToGuardianRecord.id],
        send: true
      }
      const res = yield call(PostSendToGuardianApi, parmas)
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'hasSend' }))
        yield put({ type: 'loadPage' })
        yield put({ type: 'updateState', payload: { sendToGuardianModalVisible: false } })
      } else {
        const { error } = res.data;
        const { message: info } = error || {}
        message.error(info || '发送失败，请稍后重试')
      }
    },

    *uploadAssessmentQr({ payload: { key } }, { call, put, take, select }) {
      return yield call(GetAssessmentListQrCodeApi, { assessmentId: key })
    },

    *showAssessmentList({ payload: _ }, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()
      const query = {
        maxResultCount: 1000,
      }
      const res = yield call(GetGetRocketAssessment, query)
      if (res.response.ok) {
        const { items } = res.data
        yield put({ type: 'updateState', payload: { selectAssessmentModalVisible: true, transferList: items } })
      } else {
        const { error } = res.data;
        Modal.warning({
          title: intl('reminder'),
          content: error.message,
          okText: intl('know'),
        })
      }
    },

    *downloadAssessmentPDF({ payload: id }, { call, put, take, select }) {
      const res = yield call(GetAssessmentDetail, id)
      if (res.response.ok) {
        const userAssessmentInfo = res.data
        const {
          questionnaireAnswer: { questionnaire, answer },
        } = userAssessmentInfo
        const questionnaireJson = isJson(questionnaire.jsonString)
          ? JSON.parse(questionnaire.jsonString)
          : null
        const answerJson = isJson(answer.answerContent) ? JSON.parse(answer.answerContent) : null

        if (questionnaireJson === null || answerJson === null) {
          message.error(`Error Display. UserAssessmentId: ${userAssessmentInfo.id} `)
        } else {
          const surveyPDF = new SurveyPDF.SurveyPDF(questionnaireJson)
          surveyPDF.data = answerJson
          surveyPDF.save()
        }
      }
    },

    *getAssessmentReport({ payload: { record, intl } }, { call, put, take, select }) {
      const { id, assessment, userId } = record || {}
      const { tagIds = [] } = assessment || {}
      const { currentUser } = yield select(state => state.user)
      const recordRes = yield call(GetUserAssessmentTimesSettingRecords, {
        userId: userId,
      })

      if (recordRes.response.ok) {
        const selectItem = recordRes?.data?.find(record => {
          const { tag: { id } } = record || {}
          return tagIds.find(tag => tag === id)
        })

        const { remainingTimes, records } = selectItem || {}

        const tempRemainingTimes = tagIds.length > 0 ? remainingTimes+1 : 1
        // const tempRemainingTimes = tagIds.length > 0 ? (records && records.length === 0) ? null : remainingTimes : 1

        if (showRemainingTimesMsg(intl, tempRemainingTimes, currentUser, 'assessment')) return

        // console.log('start loading')

        const hide = message.loading(intl.formatMessage({ id: 'reportingWait' }), 0);
        const res = yield call(GetAssessmentReportApi, { userAssessmentId: id })
        setTimeout(hide, 0)
        if (res.response.ok) {
          yield put({ type: 'loadPage' })
        } else {
          const { error } = res.data || {}
          const { message: messageInfo } = error
          message.error(messageInfo)
        }
      } else {
        const { error } = recordRes.data || {}
        const { message: messageInfo } = error
        message.error(messageInfo)
      }
    }
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/statistic/questionnaireList') {
    //       dispatch({ type: 'loadPage' })
    //     }
    //   })
    // },
  },
}
