import React, { useState, useEffect, useRef } from 'react';
import { Modal, message, InputNumber } from 'antd';
import { connect, useIntl, getLocale } from 'umi';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getDurationTime } from 'utils/utils';
import DownLoadModal from '../DownLoadModal';

import { GetDownloadPdf } from 'services/puppeteer';

import styles from './index.less';

const BatchDownloadModal = ({
  isModalVisible,
  subjectDetail,
  currentUser,
  modalInfo,
  totalNum,
  onClose,
}) => {
  const intl = useIntl();

  const durationRef = useRef();

  const { subjectInfo } = subjectDetail;
  const { staff } = currentUser;

  const [duration, setDuration] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loadPageVisible, setLoadPageVisible] = useState(false);
  const [downInfo, setDownInfo] = useState({
    startPage: 1,
    endPage: 1,
  });

  useEffect(() => {
    setDownInfo({
      ...downInfo,
      endPage: totalNum,
    });
  }, [totalNum]);

  const downLoadProps = {
    visible: loadPageVisible,
    percent,
    duration,
  };

  const onModalClose = () => {
    setDownInfo({
      startPage: 1,
      endPage: 1,
    });
    onClose();
  };

  return (
    <>
      <Modal
        title={intl.formatMessage({ id: 'batchDownloadForm' })}
        open={isModalVisible}
        width={600}
        onOk={() => {
          Modal.confirm({
            title: intl.formatMessage({ id: 'sureDownload' }),
            icon: <ExclamationCircleOutlined />,
            content: null,
            okText: intl.formatMessage({ id: 'submit' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk: async () => {
              if (downInfo.endPage - downInfo.startPage > 19) {
                message.warning(intl.formatMessage({ id: 'downloadedTips' }));
                return;
              }
              const { ilsVersion } = modalInfo;
              const pathName =
                ilsVersion === 'V4'
                  ? '/hfsTwoTrainingRecord'
                  : '/hfsTrainingRecord';
              let countDown;
              let totalTime;
              onModalClose();

              const fileBaseName =
                subjectInfo.name + '-' + modalInfo.displayName + '-';

              const origin = window.location.origin;
              const CognitiveleapUser =
                localStorage.getItem('CognitiveleapUser');
              const userTenantId = localStorage.getItem('userTenantId');
              const localTenantId = localStorage.getItem('tenantId');

              if (downInfo.startPage === downInfo.endPage) {
                const url = [
                  origin +
                    pathName +
                    `?subjectId=${subjectInfo.id}&userPlanId=${
                      modalInfo.id
                    }&num=${downInfo.startPage}&trainerId=${
                      staff && staff.id
                    }&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${localTenantId}&culture=${getLocale()}`,
                ];
                totalTime = getDurationTime(url.length);
                durationRef.current = totalTime;
                countDown = setInterval(() => {
                  if (durationRef.current > 0) {
                    durationRef.current--;
                    setPercent(
                      parseInt(
                        ((totalTime - durationRef.current) / totalTime) * 100,
                      ),
                    );
                    setDuration(durationRef.current);
                  }
                }, 1000);
                setLoadPageVisible(true);
                await GetDownloadPdf(
                  { urlList: { ...url }, needPadding: true },
                  intl.formatMessage(
                    { id: 'downLoadWhichFeedback' },
                    { fileBaseName, num: downInfo.startPage },
                  ),
                );
              } else {
                let urls = [];
                for (
                  let num = downInfo.startPage;
                  num <= downInfo.endPage;
                  num++
                ) {
                  const tempUrl =
                    origin +
                    pathName +
                    `?subjectId=${subjectInfo.id}&userPlanId=${
                      modalInfo.id
                    }&num=${num}&trainerId=${
                      staff && staff.id
                    }&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${localTenantId}&culture=${getLocale()}`;
                  urls.push(tempUrl);
                }
                totalTime = getDurationTime(urls.length);
                durationRef.current = totalTime;
                countDown = setInterval(() => {
                  if (durationRef.current > 0) {
                    durationRef.current--;
                    setPercent(
                      parseInt(
                        ((totalTime - durationRef.current) / totalTime) * 100,
                      ),
                    );
                    setDuration(durationRef.current);
                  }
                }, 1000);
                setLoadPageVisible(true);
                await GetDownloadPdf(
                  { urlList: { ...urls }, needPadding: true },
                  intl.formatMessage(
                    { id: 'downLoadWhichToWhichFeedback' },
                    {
                      fileBaseName,
                      startNum: downInfo.startPage,
                      endNum: downInfo.endPage,
                    },
                  ),
                );
              }
              clearInterval(countDown);
              setLoadPageVisible(false);
              setDuration(0);
              setPercent(0);
            },
          });
        }}
        okText={intl.formatMessage({ id: 'download' })}
        onCancel={() => {
          onModalClose();
        }}
      >
        <div>
          <div>
            <span className={styles.planTitle}>{modalInfo.displayName}</span>
            <span className={styles.planCurrentNum}>
              {modalInfo.currentNum}
            </span>
            <span>{`/${modalInfo.totalNum}`}</span>
          </div>
          <div style={{ marginTop: 25 }}>
            <span>
              {intl.formatMessage({ id: 'enterSessionDownload' })} &nbsp;
            </span>
            &nbsp;
            <InputNumber
              min={1}
              value={downInfo.startPage}
              max={downInfo.endPage}
              onChange={(num) => {
                setDownInfo({
                  ...downInfo,
                  startPage: num,
                });
              }}
            />
            <span>&nbsp; - &nbsp;</span>
            &nbsp;
            <InputNumber
              min={downInfo.startPage}
              value={downInfo.endPage}
              max={modalInfo.currentNum}
              onChange={(num) => {
                setDownInfo({
                  ...downInfo,
                  endPage: num,
                });
              }}
            />
          </div>
        </div>
      </Modal>
      <DownLoadModal {...downLoadProps} />
    </>
  );
};

export default connect(({ subjectDetail, user }) => ({
  subjectDetail,
  currentUser: user.currentUser,
}))(BatchDownloadModal);
