import React, { useEffect, useState } from 'react'
import { connect, history } from 'umi'
import { Button, Modal, Input } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const { Search } = Input
const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑问卷',
  add: '新建问卷',
}

const Questionnaire = ({ adminQuestionnaire, dispatch, loading }) => {

  useEffect(() => {
    dispatch({ type: 'adminQuestionnaire/query' })
  }, [])

  const { modalType, content, modalVisible, list, listSupport, pagination } = adminQuestionnaire

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'adminQuestionnaire/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'adminQuestionnaire/onSubmitModal', payload: value })
    },
    content,
  }

  const TableProps = {
    loading: loading.effects['adminQuestionnaire/query'],
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'adminQuestionnaire/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'adminQuestionnaire/onEdit', payload: record.id })
        },
      },
      // {
      //   showText: '编辑Json',
      //   onClick(record) {
      //     history.push({
      //       pathname: '/system/manage/assessment/qa/editQuestionnaire',
      //       query: {
      //         id: record.id,
      //       },
      //     });
      //   },
      // },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'adminQuestionnaire/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'adminQuestionnaire/onAdd' })
  }

  return (
    <div>
      <h1>问卷（Questionnaire）</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: "20px 0 20px 0" }}>
        <Search
          placeholder="搜索问卷名称"
          style={{ width: "300px" }}
          onSearch={(value) => {
            dispatch({ type: 'adminQuestionnaire/updateState', payload: { keyword: value, pagination: { ...pagination, current: 1 } } })
            dispatch({ type: 'adminQuestionnaire/query' })
          }}
        />
        <Button onClick={onAddClick}>新建问卷</Button>
      </div>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ adminQuestionnaire, loading }) => ({ adminQuestionnaire, loading }))(Questionnaire)
