import React from 'react';
import styles from './index.less';

const ShowCardBorder = ({ children, title, footer }) => {
  return (
    <div className={styles.cardPanel}>
      <div className={styles.cardDes}>{title}</div>
      <div className={styles.showCard}>{children}</div>
      <div className={styles.cardDes}>{footer}</div>
    </div>
  );
};

export default ShowCardBorder;
