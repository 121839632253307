import { Modal, Form, Radio, Icon, Button } from 'antd'
import { useIntl } from 'umi'
import styles from '../detail.less'
const FormItem = Form.Item

const SelectPlanTypeModal = ({
  loading,
  value,
  visible,
  onCancel,
  onOk
}) => {
  const onSubmit = (values) => {
    onOk(values)
  }

  const onQuit = () => {
    onCancel()
  }

  return (
    <Modal
      maskClosable={true}
      open={visible}
      onCancel={onQuit}
      title="安排训练计划"
      closeIcon={<Icon type="close-circle" />}
      className={styles.formLabel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
      >
        <FormItem
          label={'请选择适合儿童年龄的的训练计划'}
          name={'iLsPlanType'}
          initialValue={value}
          rules={[
            {
              required: true,
            }
          ]}
        >
          <Radio.Group size="large" style={{ display: 'block', textAlign: 'center' }}>
            <Radio.Button value="PreSchool">学龄前(3-5岁)</Radio.Button>
            <span style={{ padding: '0 10px' }}></span>
            <Radio.Button value="GradeSchool">学龄(6-12岁)</Radio.Button>
          </Radio.Group>
        </FormItem>
      </Form>
      <div style={{ textAlign: 'center', marginTop: 60 }}>
        <Button type="primary" htmlType="submit" style={{ width: 240 }} loading={loading}>
          选好了，安排计划
        </Button>
      </div>
    </Modal>
  )
}

export default SelectPlanTypeModal
