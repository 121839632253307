import { StaffDetail } from 'services/trainingSystem'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { PostUploadUserResumes, GetTrainerDetail, GetTrainerTrainingFeedback, PostQuestionList, PutAmount, GetUserInfo } from 'services/trainingSystem'
import config from 'utils/config'
import { message } from 'antd'
import { IntlManager } from 'utils/helper'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
const { baseURL, uploadUserResumes } = config

const editAmountContent = (intl)=> {
  return {
    Properties: [
      {
        EditorType: 'NumberInput',
        ShowTitle: intl('remainingVRAT'),
        FormKey: 'vratAmount',
        AdditionalData: null,
        Value: null,
        Setting: {
          Props: {
            min: 0,
            step: 1,
          }
        },
        Description: null,
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl('remainingAssessments'),
        FormKey: 'assessmentAmount',
        AdditionalData: null,
        Value: null,
        Setting: {
          Props: {
            min: 0,
            step: 1,
          }
        },
        Description: null,
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl('remainingTrainingSessions'),
        FormKey: 'courseAmount',
        AdditionalData: null,
        Value: null,
        Setting: {
          Props: {
            min: 0,
            step: 1,
          }
        },
        Description: null,
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl('RemainingHFSTraining'),
        FormKey: 'planV2CourseAmount',
        AdditionalData: null,
        Value: null,
        Setting: {
          Props: {
            min: 0,
            step: 1,
          }
        },
        Description: null,
      }
    ]
  }
}


const editResumesContent = (intl)=>{
  return {
    Properties: [
      {
        EditorType: 'Upload',
        Value: null,
        Setting: {
          UploadSetting: {
            // isSingle: true,
            uploadType: 'pdf'
          },
          Action: baseURL + uploadUserResumes,
          Header: getAuthHeader(),
          Required: false,
        },
        ShowTitle: intl('cv'),
        FormKey: 'resumes',
        Description: intl('availablePDFFormat')
      }
    ]
  }
}

export default {
  namespace: 'adminGroupDetail',
  state: {
    userInfo: null,
    modalVisible: false,
    content: null,
    modalType: '',
    questionData: null,
    tabActiveKey: '',
    tabListData: null,
    drawerVisible: false
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query({ payload }, { call, put, select }) {
      yield put({ type: 'loadPage', payload })
      yield put({ type: 'getTrainerDetail', payload })
    },

    *getTrainerDetail({ payload }, { call, put, select }) {
      const { id } = payload || {}
      const res = yield call(GetTrainerDetail, { trainerId: id })
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { trainerDetail: res.data } })
      }
    },

    *loadPage({ payload }, { call, put, select }) {
      const { id, type = "admin" } = payload
      let res
      if (type === "admin") {
        res = yield call(StaffDetail, { id })
      } else {
        res = yield call(GetUserInfo, { id })
      }
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { userInfo: res.data } })
      }
    },

    *edit({ payload }, { call, put, select }) {
      const intl = IntlManager.MyIntl()
      const { modalType } = payload
      let emptyContent = null
      let editFormContent = null
      const { userInfo, trainerDetail } = yield select(state => state.adminGroupDetail)
      switch (modalType) {
        case "editResumes":
          emptyContent = editResumesContent(intl)
          editFormContent = matchDynamicForm(emptyContent, userInfo)
          break
        case "editAmount":
          emptyContent = editAmountContent(intl)
          editFormContent = matchDynamicForm(emptyContent, trainerDetail)
          break
      }
      yield put({
        type: 'updateState',
        payload: {
          modalVisible: true,
          content: editFormContent,
          modalType
        }
      })
    },

    *onSubmit({ payload: { values, intl } }, { call, put, select }) {
      const { modalType, userInfo, trainerDetail } = yield select(state => state.adminGroupDetail)
      let res = null
      let params = null
      switch (modalType) {
        case "editResumes":
          params = {
            resumes: values.resumes,
            userId: userInfo && userInfo.id
          }
          res = yield call(PostUploadUserResumes, params)
          break
        case "editAmount":
          res = yield call(PutAmount, { ...values, trainerId: trainerDetail.id, })
          break
      }
      if (res && res.response.ok) {
        switch (modalType) {
          case "editResumes":
            message.success(intl.formatMessage({ id: 'submitted' }))
            yield put({ type: 'loadPage', payload: { id: userInfo && userInfo.id } })
            yield put({ type: 'updateState', payload: { modalVisible: false } })
            break
          case 'editAmount':
            message.success(intl.formatMessage({ id: 'submitted' }))
            yield put({ type: 'updateState', payload: { modalVisible: false, } })
            yield put({ type: 'getTrainerDetail', payload: { id: userInfo && userInfo.id } })
            break
        }
      } else {
        const { error } = res && res.data || {}
        const { message: errorInfo } = error || {}
        errorInfo && message.error(errorInfo)
      }
    },

    *onCancel({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { modalVisible: false } })
    },

    *changeTab({ payload }, { call, put, select }) {
      const { id } = payload;
      yield put({ type: 'updateState', payload: { tabActiveKey: id } })
      const { selectItem: { trainerId, trainingModuleId } } = yield select(state => state.adminGroupDetail)
      const GetFeedbackRes = yield call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId, QuestionnaireId: id })
      if (GetFeedbackRes.data) {
        const GetFeedbackResData = GetFeedbackRes.data
        yield put({ type: 'updateState', payload: { questionData: GetFeedbackResData } })
      } else {
        yield put({ type: 'updateState', payload: { questionData: null } })
      }
    },

    *trainingFeedback({ payload }, { call, put, select }) {
      const { trainingModule: { questionnaires }, trainerId, trainingModuleId, questionnaireId: queryId } = payload || {}
      if (questionnaires && questionnaires.length > 0) {
        const questionnaireId = queryId || questionnaires[0]
        yield put({ type: 'updateState', payload: { tabActiveKey: questionnaireId } })
        const PostQuestionRes = yield call(PostQuestionList, { QuestionnaireIds: questionnaires })
        if (PostQuestionRes.response.ok) {
          const questionListInfo = PostQuestionRes.data
          const showTabList = questionnaires.map(id => {
            return questionListInfo.find(item => item.id === id)
          })
          yield put({ type: 'updateState', payload: { tabListData: showTabList, selectItem: payload, drawerVisible: true } })
          const GetFeedbackRes = yield call(GetTrainerTrainingFeedback, { TrainerId: trainerId, TrainingModuleId: trainingModuleId, QuestionnaireId: questionnaireId })
          if (GetFeedbackRes.data) {
            const GetFeedbackResData = GetFeedbackRes.data
            yield put({ type: 'updateState', payload: { questionData: GetFeedbackResData } })
          } else {
            yield put({ type: 'updateState', payload: { questionData: null } })
          }
        } else {
          const { error } = PostQuestionRes.data
          const { message: errorInfo } = error || {}
          errorInfo && message.error(errorInfo)
        }
      } else {
        message.error("暂无培训反馈")
      }
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/groupDetail') {
    //       dispatch({ type: 'query', payload: location.query })
    //     }
    //   })
    // },
  },
}
