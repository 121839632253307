import chartRequest, { config } from 'cognitiveleap-core-us/utils/chartRequest.js'
import {message} from 'antd'

const getFinishedQuestionnaireIds = (list)=>{
    return list.filter(item=>{
        return item.finishTime!==null
    }).map(item=>item.id)
}

const mfaTwoKey = ['MFA_Preschool_V2', 'MFA_GradeSchool_V2', 'MFA_Adolescent_V2', 'MFA_Adult_V2']

const apis = {
    chiefComlaint: '/api/rocketAdmin/userQuestionnaire/chiefComplaintList',
    parent: '/api/rocketAdmin/userQuestionnaire/parentalInterviewsList',
    mfa: '/api/rocketAdmin/subjectQuestionnaireStatistic/subjectMfaAnalysis',
    mfatwo: '/api/rocketAdmin/subjectQuestionnaireStatistic/subjectMfaAnalysis',
    snap: '/api/rocketAdmin/subjectQuestionnaireStatistic/subjectSnapAnalysis',
}

const isQuestionnaire = {
    chiefComlaint: true,
    parent: true,
    mfa: false,
    mfatwo: false,
    snap: false,
}

export default {
    namespace: 'compare',
    state: {
      subjectId: '',
      type: 'vrat',
      showAnswerList: [],
      questionnare: [],
    },
    reducers: {
      updateState(state, { payload }) {
        return {
          ...state,
          ...payload,
        }
      },
    },
    effects: {
      *loadPage({ payload }, { take, put, call, select }){
        const{ subjectId, type } = payload
        if(apis[type]!==undefined){
            const assessmentRes = yield chartRequest()(apis[type], {
                method: 'GET',
                params: { UserId: subjectId }
            });
            if (assessmentRes.response && assessmentRes.response.ok) {
                let userQuestionnaireIds = []
                const isQuestion = isQuestionnaire[type];
                if(isQuestion){
                    const {items} = assessmentRes.data.items
                    userQuestionnaireIds = getFinishedQuestionnaireIds(items)
                    const listRes = yield [...userQuestionnaireIds.map(id=>{
                        return chartRequest()('/api/rocketAdmin/userQuestionnaire/{id}'.replace('{id}', id), {
                            method: 'GET',
                        })
                    })]
                    if(listRes.some(item=>item.response&&item.response.ok)){
                        if(!listRes.every(item=>item.response&&item.response.ok)){
                            message.error(`部分问卷数据获取失败`)
                        }
                        const okResDataList = listRes.filter(item=>item.response&&item.response.ok).map(item=>item.data)
                        const lastQuestion = [...okResDataList].shift().questionnaireAndAnswer?.questionnaire?.jsonStringPageAggregated
                        const lastQuestionElements = JSON.parse(lastQuestion)?.pages[0].elements
                        const answers = okResDataList.map(item=>{
                            const {questionnaireAndAnswer, finishTime} = item
                            return {
                                finishTime,
                                answer: JSON.parse(questionnaireAndAnswer.answer?.answerContent)
                            }
                        })

                        yield put({ type: 'updateState', payload: {
                            showAnswerList: answers,
                            questionnare: lastQuestionElements
                        } })

                    }
                    else{
                        //const errorRequestList = listRes.filter(item=>!item.response.ok)
                        message.error(`获取问卷数据失败`)
                    }
                }
                else{
                    if (type == 'mfatwo') {
                      userQuestionnaireIds = assessmentRes.data.questionnaireData.filter(item => mfaTwoKey.includes(item.name)).map(item=>item.userAssessmentId)
                    } else {
                      userQuestionnaireIds = assessmentRes.data.questionnaireData.map(item=>item.userAssessmentId)
                    }
                    const listRes = yield [...userQuestionnaireIds.map(id=>{
                        return chartRequest()('/api/rocketAdmin/userAssessment/{id}'.replace('{id}', id), {
                            method: 'GET',
                        })
                    })]
                    if(listRes.some(item=>item.response&&item.response.ok)){
                        if(!listRes.every(item=>item.response&&item.response.ok)){
                            message.error(`部分问卷数据获取失败`)
                        }
                        const okResDataList = listRes.filter(item=>item.response&&item.response.ok).map(item=>item.data)
                        const lastQuestion = [...okResDataList].shift().questionnaireAnswer?.questionnaire?.jsonStringPageAggregated
                        const lastQuestionElements = JSON.parse(lastQuestion)?.pages[0].elements
                        const answers = okResDataList.map(item=>{
                            const {questionnaireAnswer, finishTime} = item
                            return {
                                finishTime,
                                answer: JSON.parse(questionnaireAnswer.answer?.answerContent)
                            }
                        })
                        yield put({ type: 'updateState', payload: {
                            showAnswerList: answers,
                            questionnare: lastQuestionElements
                        } })
                    }
                    else{
                        //const errorRequestList = listRes.filter(item=>!item.response.ok)
                        message.error(`获取问卷数据失败`)
                    }
                }
            } else {
                const {error: {message: showMessage}} = assessmentRes.data
                message.error(showMessage)
            }
        }
        else{
            message.error('未知类型')
        }
        yield put({ type: 'updateState', payload })
      }
    },
    subscriptions: {
    //   setup({ dispatch, history }) {
    //     return history.listen(location => {
    //       if (location.pathname === '/system/compare') {
    //           dispatch({ type: 'loadPage', payload: location.query })
    //       }
    //     })
    //   },
    },
  }
