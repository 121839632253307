import { useState, useEffect } from 'react';
import { Transfer, Modal, Spin, message } from 'antd';
import { GetDailyTopic, PostManageWeekThemeDays } from 'services/adhd';

const DaysListModal = ({
  weekThemeId,
  visible,
  onClose,
  selectData,
  reloadTable,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectKeys, setSelectKeys] = useState([]);
  const [loading, setLoading] = useState({
    initDataLoading: false,
    modalLoading: false,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      initDataLoading: true,
    });

    const res = await GetDailyTopic({
      SkipCount: 0,
      MaxResultCount: 999,
      Status: 'Public',
    });

    if (res.response.ok) {
      const { items } = res.data;
      setDataSource(items);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    if (selectData.length > 0) setSelectKeys(selectData.map((item) => item.id));

    setLoading({
      ...loading,
      initDataLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Modal
      width={800}
      visible={visible}
      title="Add Days"
      onOk={async () => {
        const res = await PostManageWeekThemeDays({
          weekThemeId,
          dailyTopicIds: selectKeys,
        });

        if (res.response.ok) {
          reloadTable();
          onClose();
        } else {
          const { error } = res.data;
          message.error(error.message);
        }
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <Spin spinning={loading.initDataLoading}>
        <Transfer
          listStyle={{
            width: 360,
            height: 400,
          }}
          dataSource={dataSource.map((item) => {
            return {
              key: item.id,
              ...item,
            };
          })}
          showSearch
          targetKeys={selectKeys}
          onChange={(value) => {
            setSelectKeys(value);
          }}
          render={(item) => item.title}
        />
      </Spin>
    </Modal>
  );
};

export default DaysListModal;
