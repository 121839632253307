import React from 'react';
import { Badge } from 'antd';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderContent from './components/HeaderContent';
import BottomPageNum from './components/BottomPageNum';
import ScoreChart from 'components/NewVratChartComponents/ScoreChart';
import { useIntl } from 'umi';
const getPercentileGroup = (age = 0) => {
  if (age >= 0 && age < 6) {
    return '6-7';
  } else if (age >= 6 && age < 8) {
    return '6-7';
  } else if (age >= 8 && age < 10) {
    return '8-9';
  } else if (age >= 10 && age < 12) {
    return '10-11';
  } else if (age >= 12 && age < 14) {
    return '12-13';
  } else {
    return '12-13';
  }
};

const OverallResults = ({ data, basicInfo }) => {
  const intl = useIntl();
  const { Subject = {}, TestAge = 0 } = basicInfo || {};

  const { Gender } = Subject || {};

  const attentionPerformance = data
    ? (data.AttentionPerformanceIndex * 100).toFixed(1)
    : 0;
  const motionPerformance = data
    ? (data.MotionPerformanceIndex * 100).toFixed(1)
    : 0;

  const AttentionIndexProps = {
    data: attentionPerformance,
  };

  const MotionIndexProps = {
    data: motionPerformance,
  };

  const description = {
    Attention: intl.formatMessage({ id: 'OverallResults.attention' }),
    Motion: intl.formatMessage({ id: 'OverallResults.motion' }),
  };

  const headerContentProps = {
    color: '#FFB500',
    title: intl.formatMessage({ id: 'OverallResults.title' }),
    content: (
      <div className={styles.overviewContent}>
        <div className={styles.overviewDes}>
          {intl.formatMessage({ id: 'OverallResults.performance' })}
        </div>
        <div className={styles.overviewResult}>
          <span>
            {intl.formatMessage({ id: 'OverallResults.attentionIndex'}, {attentionPerformance: parseInt(attentionPerformance)})}
          </span>
          <span className={styles.MotionIndex}>
            {intl.formatMessage({ id: 'OverallResults.motionIndex' }, {motionPerformance: parseInt(motionPerformance)})}
          </span>
        </div>
      </div>
    ),
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.overallResults}>
        <HeaderContent {...headerContentProps} />
        <div className={styles.centerContent}>
          <div className={styles.attentContent}>
            <div className={styles.attentLeft}>
              <ScoreChart {...AttentionIndexProps} />
              <div className={styles.attentDes}>{description.Attention}</div>
            </div>
            <div className={styles.attentRight}>
              <div className={styles.rightTitle}>
              {intl.formatMessage({ id: 'OverallResults.attentionPerformance' })}
              </div>
              <p />
              <div>
                <Badge
                  color="#000000"
                  text={intl.formatMessage({ id: 'OverallResults.mentally13' })}
                />
              </div>
              <div>
                <Badge
                  color="#000000"
                  text={intl.formatMessage({ id: 'OverallResults.remember' })}
                />
              </div>
              <div>
                <Badge
                  color="#000000"
                  text={intl.formatMessage({ id: 'OverallResults.mentally' })}
                />
              </div>
              <p />
              <div className={styles.rightTitle}>
              {intl.formatMessage({ id: 'OverallResults.attentionIs' }, {attentionPerformance: parseInt(attentionPerformance) })}
              </div>
              <p />
              <div>
                {intl.formatMessage({ id: 'OverallResults.attentionSkills' }, {attentionPerformance: parseInt(attentionPerformance), sex: Gender == 'Male' ? intl.formatMessage({ id: 'OverallResults.boys' }) : intl.formatMessage({ id: 'OverallResults.girls' }), age: getPercentileGroup(TestAge)})}
              </div>
            </div>
          </div>
          <div className={styles.motionContent}>
            <div className={styles.motionLeft}>
              <ScoreChart {...MotionIndexProps} />
              <div className={styles.motionDes}>{description.Motion}</div>
            </div>
            <div className={styles.motionRight}>
              <div className={styles.rightTitle}>
                {intl.formatMessage({ id: 'OverallResults.motionPerformance' })}
              </div>
              <p />
              <div>
                <Badge
                  color="#000000"
                  text={intl.formatMessage({ id: 'OverallResults.physically' })}
                />
              </div>
              <div direction="vertical">
                <Badge
                  color="#000000"
                  text={intl.formatMessage({ id: 'OverallResults.maintain' })}
                />
              </div>
              <p />
              <div className={styles.rightTitle}>
                {intl.formatMessage({ id: 'OverallResults.motionIs'}, {motionPerformance: parseInt(motionPerformance)})}
              </div>
              <p />
              <div>
                {intl.formatMessage({ id: 'OverallResults.motorSkills' }, {motionPerformance: parseInt(motionPerformance), sex: Gender == 'Male' ? intl.formatMessage({ id: 'OverallResults.boys' }) : intl.formatMessage({ id: 'OverallResults.girls' }), age: getPercentileGroup(TestAge)})}
              </div>
            </div>
          </div>
        </div>
        <BottomPageNum num={6} />
        <BottomDes color="#FFB500" />
      </div>
    </div>
  );
};

export default OverallResults;
