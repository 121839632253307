import React, { useState, useEffect } from 'react';
import { message, Card, Button, Input } from 'antd';
import { history } from 'umi';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { GetSupervisorList, GetAdminStaffList } from 'services/trainingSystem';
import { ToSupervisor, CancelSupervisor } from 'services/supervisor';

const { Search } = Input;

const listSupport = {
  creationTime: {
    showText: '添加时间',
    showType: 'Time',
  },
  name: {
    showText: '姓名',
    showType: 'Text',
  },
  email: {
    showText: '邮箱',
    showType: 'Text',
  },
  phoneNumber: {
    showText: '手机号',
    showType: 'Text',
  },
};

const contentMenu = {
  Properties: [
    {
      EditorType: 'SingleWithSearch',
      ShowTitle: '员工',
      FormKey: 'userId',
      AdditionalData: null,
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: true,
      },
      Description: '确定添加后，该用户将被授权督导其他员工。',
    },
  ],
};

const SupervisorList = () => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentContent, setCurrentContent] = useState(contentMenu);
  const [dataSource, setDataSource] = useState([]);
  const [keyword, setKeyword] = useState(null);

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading(true);

    const { current, pageSize } = pagination;
    const res = await GetSupervisorList({
      SkipCount: (current - 1) * pageSize,
      MaxResultCount: pageSize,
      Filter: keyword,
    });

    if (res.response.ok) {
      const { items, totalCount } = res.data;
      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      const { message: msg } = error || { message: '获取列表失败' };
      message.error(msg);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize]);

  const tableProps = {
    listData: dataSource,
    listSupport,
    loading,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: '取消督导权限',
        itemRender(record) {
          const { id } = record;
          return (
            <a
              onClick={async () => {
                const toSupervisorRes = await CancelSupervisor({ id });
                if (toSupervisorRes.response.ok) {
                  message.success('取消成功');
                  initData();
                } else {
                  message.error('取消失败');
                }
              }}
            >
              取消督导权限
            </a>
          );
        },
      },
      {
        showText: '督导列表',
        itemRender(record) {
          return (
            <a
              onClick={() => {
                history.push(`/system/manage/superviseList?id=${record.id}`);
              }}
            >
              督导列表
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    title: '添加督导师',
    visible,
    onCancel() {
      setVisible(false);
    },
    onOk: async (value) => {
      const { userId } = value;
      const toSupervisorRes = await ToSupervisor({ id: userId });
      if (toSupervisorRes.response.ok) {
        message.success('设定成功');
        initData();
      }
      setVisible(false);
    },
    content: currentContent,
  };

  // 添加督导师点击事件
  const addSupervisor = async () => {
    setBtnLoading(true);
    const res = await GetAdminStaffList({
      SkipCount: 0,
      MaxResultCount: 999,
    });

    if (res.response.ok) {
      const activeData = res.data.items.filter((item) => item.name !== null);

      const noSupervisorData = activeData.filter((item) => {
        const { hostRoles } = item;
        return (
          hostRoles.length === 0 ||
          hostRoles.find((role) => role.name !== 'supervisor')
        );
      });

      let emptyContent = contentMenu;
      emptyContent.Properties[0].Setting.DropdownOptions = noSupervisorData.map(
        (item) => {
          const { id, name, phoneNumber, email } = item;
          const showText = phoneNumber !== '' ? phoneNumber : email;
          return {
            Value: name + ' - ' + showText,
            Id: id,
          };
        },
      );
      setCurrentContent(emptyContent);
      setVisible(true);
      setBtnLoading(false);
    } else {
      const { error } = res.data;
      const { message: msg } = error || { message: '获取员工列表失败' };
      message.error(msg);
    }
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>督导师列表</b>}
      extra={
        <Button type="primary" loading={btnLoading} onClick={addSupervisor}>
          添加督导师
        </Button>
      }
      bordered={false}
    >
      <Search
        placeholder="搜索姓名/邮箱"
        value={keyword}
        onChange={(e) => {
          setPagination({
            current: 1,
            pageSize: 10,
            total: 0,
          });
          setKeyword(e.target.value);
        }}
        style={{ width: 200, margin: '0 30px 10px 0' }}
        onSearch={initData}
      />
      <MagicTable {...tableProps} />
      <MagicFormModal {...modalProps} />
    </Card>
  );
};

export default SupervisorList;
