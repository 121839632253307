import { useState, useEffect } from 'react';
import { Card, Button, message, Popconfirm, Input, Space } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import DailyTopicModal from './components/DailyTopicModal';

import { GetDailyTopic, PutContentStatus } from 'services/adhd';

const { Search } = Input;

const listSupport = {
  title: {
    showText: 'title',
    showType: 'Text',
  },
  contentType: {
    showText: 'contentType',
    showType: 'Text',
  },
  isMark: {
    showText: 'isMark',
    showType: 'Text',
    render: (text) => (text ? 'Yes' : 'No'),
  },
  pdf: {
    showText: 'PDF',
    showType: 'Text',
    render: (text) => (Object.keys(text || {}).length > 0 ? 'Yes' : 'No'),
  },
  status: {
    showText: 'status',
    showType: 'Text',
  },
};

const DailyTopic = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState({
    tableLoading: false,
    modalLoading: false,
  });
  const [Filter, setFilter] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [currentItem, setCurrentItem] = useState({});
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetDailyTopic({
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
      Filter,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current, Filter]);

  const operate = (type, record) => {
    if (type !== modalType) setModalType(type);
    if (type === 'edit') setCurrentItem(record);
    setModalVisible(true);
  };

  const changeStatus = async (record, action) => {
    const { id } = record || {};
    const res = await PutContentStatus({
      id,
      action,
    });

    if (res.response.ok) {
      message.success('success');
      initData();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }
  };

  const tableProps = {
    listData: dataSource,
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: 'Edit',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                operate('edit', record);
              }}
            >
              Edit
            </a>
          );
        },
      },
      {
        showText: 'Hide',
        itemRender(record) {
          const { status } = record || {};
          const showStatusText = status === 'Public' ? 'Hide' : 'Public';
          return (
            <Popconfirm
              title={`Are you sure to ${showStatusText.toLocaleLowerCase()} it?`}
              onConfirm={() => changeStatus(record, showStatusText)}
            >
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {showStatusText}
              </a>
            </Popconfirm>
          );
        },
      },
    ],
  };

  const modalProps = {
    modalVisible,
    modalType,
    currentItem,
    onClose: () => {
      setModalVisible(false);
      setCurrentItem({});
    },
    reloadTable: initData,
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>Daily Topic</b>}
      bordered={false}
    >
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <div>Keywords</div>
          <Search
            placeholder="Title"
            allowClear
            onSearch={(value) => setFilter(value)}
            style={{ width: 200 }}
          />
        </Space>
        <Button type="primary" onClick={() => operate('add')}>
          Add Daily Topic
        </Button>
      </Space>

      <p />
      <MagicTable {...tableProps} />
      {modalVisible && <DailyTopicModal {...modalProps} />}
    </Card>
  );
};

export default DailyTopic;
