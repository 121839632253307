import { GetOnline } from 'services/account'

export default {
    namespace: 'online',

    state: {
        list: [],
        currentList: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        }
    },

    effects: {
        *query(_, { take, put, call, select }) {
            const { pagination } = yield select(state => state.online)
            const { pageSize, current } = pagination
            const res = yield call(GetOnline)
            if (res.response.ok) {
                const data = res.data
                const currentList = data.length>0?data.slice((current-1)*pageSize, current*pageSize):[]
                yield put({ type: 'updateState', payload: { list: data, currentList, pagination: { ...pagination, total: data.length } } })
            }
        },

        *changeTable({payload}, { take, put, call, select }){
            const { pagination: newPagination } = payload
            const { pagination, list } = yield select(state => state.online)
            const { pageSize, current } = newPagination
            const currentList = list.length>0?list.slice((current-1)*pageSize, current*pageSize):[]
            yield put({ type: 'updateState', payload: { currentList, pagination: { ...pagination, ...newPagination } } })
        }
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/online') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
