import { history } from 'umi';
import { GetUserList, GetSubjectList, AddSubject, GetCenterUserList, PrepareSubject, CheckSubject } from 'services/userManager'
import { message, Modal } from 'antd'
import { IntlManager } from 'utils/helper'
import { getDvaApp } from 'umi';
import { ExclamationCircleFilled } from '@ant-design/icons';

export default {
  namespace: 'userlist',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
    coaches: [],
    modalVisible: false,
    confirmModalVisible: false,
    addSubjectInfo: null,
    avaliableGuardianRoles: [],
    searchField: null,
    selectItem: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage({ payload }, { call, put, take, select }) {
      const { p } = payload || {};
      const { pagination, searchField } = yield select(state => state.userlist)
      let query = { skipCount: 0, maxResultCount: 10 }
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        filter: searchField || p,
      }
      const res = yield call(GetCenterUserList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, searchField: searchField || p },
        })
      }
    },

    *filterFetch({ payload }, { call, put, select, take }) {
      const defaultPagination = {
        current: 1,
        pageSize: 12,
        total: 0,
      }
      yield put({ type: 'updateState', payload: {
        ...payload,
        pagination: defaultPagination
      } })
      yield put({ type: 'loadPage' })
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: currentPagination } = yield select(state => state.userlist)
      const { pagination } = data
      yield put({
        type: 'updateState',
        payload: { pagination: { ...currentPagination, ...pagination } },
      })
      yield put({ type: 'loadPage' })
    },

    *clearAllFilterAndSorter(_, { call, put, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 12,
        total: 0,
      }
      const payload = { pagination: defaultPagination, searchField: null }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'loadPage' })
    },

    *checkSubject({ payload }, { take, put, call, select }) {
      const formatMessage = IntlManager.intl.formatMessage;

      const res = yield call(CheckSubject, payload)
      if (res.response.ok) {
        const { hadSame } = res.data
        if (hadSame) {
          Modal.confirm({
            title: <span style={{color: 'red', fontWeight: 500}} >{formatMessage({ id: 'sameNamePrompt' })}</span>,
            content: <span style={{color: 'red'}}>{formatMessage({ id: 'sureCenterAddSameChild' })}</span>,
            okText:  formatMessage({ id: 'return' }),
            cancelText: formatMessage({ id: 'Confirm Add' }),
            icon: <ExclamationCircleFilled />,
            okButtonProps: {
              style: { background: '#fff',color:'#5a5a5a', borderRadius: '5px', border:'1px solid #ccc' },
            },
            cancelButtonProps: {
              style: { background: 'red', color: '#fff', borderRadius: '5px', border:'1px solid red'},
            },
            onCancel(){
              getDvaApp()._store.dispatch({
                type: 'userlist/updateState',
                payload: {
                  addSubjectInfo: payload,
                  modalVisible: true, 
                  confirmModalVisible: true,
                }
              })
            }
          })
        } else {
          getDvaApp()._store.dispatch({
            type: 'userlist/updateState',
            payload: {
              addSubjectInfo: payload,
              modalVisible: true, 
              confirmModalVisible: true
            }
          })
        }
      }
    },

    *getGuardianRoleAndShowModal({ payload }, { take, put, call, select }) {
      const res = yield call(PrepareSubject)
      if (res.response.ok) {
        const { avaliableGuardianRoles, coaches } = res.data
        yield put({
          type: 'updateState',
          payload: { avaliableGuardianRoles, coaches, modalVisible: true, selectItem: payload },
        })
      }
    },

    *addSubject(_, { call, put, take, select }) {
      const formatMessage = IntlManager.intl.formatMessage;

      const { selectItem, addSubjectInfo } = yield select(state => state.userlist)
      const res = yield call(AddSubject, {
        guardianId: selectItem.id,
        subjects: [addSubjectInfo]
      })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { confirmModalVisible: false, modalVisible: false},
        })
        message.success(formatMessage({ id: 'addSubjectSuccess' }))
        history.replace('/system/center/menu/user/subject')
        // yield put({ type: 'loadPage' })
      }
      else {
        yield put({
          type: 'updateState',
          payload: { confirmModalVisible: false, modalVisible: true },
        })
        const { error } = res.data
        message.error(error.message)
      }
    },

    goToUserDetail({ payload: id }, { call, put, take, select }) {
      console.log(id)
      history.push({
        pathname: '/system/manage/user/user',
        search: '?userId=' + id,
      })
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/user/user') {
    //       dispatch({ type: 'loadPage' })
    //     }
    //   })
    // },
  },
}
