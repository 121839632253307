import React, { useState } from 'react';
import { Radio, Space, Button, Menu, Dropdown } from 'antd';
import moment from 'moment';
import { connect, useIntl } from 'umi';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getActiveWeekdays, getEchartWeekAssigned } from 'utils/utils';

import Analysis from './components/SleepAnalysic';
import DailyRecord from './components/SleepDailyRecord';

import styles from './index.less';

const SleepPdt = ({ subjectDetail }) => {
    const intl = useIntl()

    const [mode, setMode] = useState('analysis');
    const [week, setWeek] = useState(null);

    const { subjectInfo } = subjectDetail;
    const { pdtModules } = subjectInfo;
    const currentModule = pdtModules.find(item => item.pdtModule === 'Sleep');
    const { appStartTime, stopTime } = currentModule || {};

    const { weekAssigned, startDay, endDay, currentWeek } = getEchartWeekAssigned(appStartTime, stopTime, week);

    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    // const handleMenuClick = (e) => {
    //     const { key } = e;
    //     setWeek(key);
    // };

    // const menu = (
    //     <Menu
    //         onClick={handleMenuClick}
    //         items={getActiveWeekdays(weekAssigned)}
    //     />
    // );

    const changeWeek = (type) => {
        setWeek(type == 'left' ? currentWeek - 1 : currentWeek + 1);
    }

    const dailyRecordProps = {
        week
    }

    const analysisProps = {
        week,
        onChangeWeek: (value) => {
            setWeek(value);
        }
    }

    return <div className={styles.SleepPanel}>
        <div className={styles.sleepTitle}>{intl.formatMessage({ id: 'Sleep-PDT' })}</div>
        <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{ marginBottom: 8 }}
        >
            <Radio.Button value="analysis">{intl.formatMessage({ id: 'Analysis' })}</Radio.Button>
            <Radio.Button value="recording">{intl.formatMessage({ id: 'DailyRecording' })}</Radio.Button>
        </Radio.Group>

        {mode === 'recording' && <div className={styles.weekSelect}>
            <Space>
                {/* <Dropdown overlay={menu}>
                    <Button>
                        <Space>
                            {currentWeek}
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown> */}

                <Button shape="circle" icon={<LeftOutlined />} disabled={currentWeek == 1} onClick={() => { changeWeek('left') }} />
                {moment(startDay).format('MM/DD/YYYY')} -
                {moment(endDay).format('MM/DD/YYYY')}
                <Button shape="circle" icon={<RightOutlined />} disabled={currentWeek === weekAssigned || weekAssigned == 1} onClick={() => { changeWeek('right') }} />
            </Space>
        </div>}
        {mode === 'analysis' ? <Analysis {...analysisProps} /> : <DailyRecord {...dailyRecordProps} />}
    </div>
};

export default connect(({
    subjectDetail,
}) => ({
    subjectDetail,
}))(SleepPdt);