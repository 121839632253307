import { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Space, message, Modal } from 'antd';
import { useIntl } from 'umi';
import {
  GetUserContactEmailDetail,
  GetUserContactList,
  PostSendToSupport,
  PostResendToSupport,
} from 'services/contactUs';
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import config from 'utils/config';
const { baseURL, uploadContactEmailImage } = config;

const listSupport = (intl) => {
  return {
    title: {
      showText: intl.formatMessage({ id: 'Title' }),
      showType: 'Text',
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text, record) => {
        return intl.formatMessage({ id: text === 'Sent' ? 'sent' : 'failed' });
      },
    },
    sentTime: {
      showText: intl.formatMessage({ id: 'Sent time' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
    },
    },
  };
};

const emptyContent = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Input',
        Value: null,
        Setting: {
          Required: true,
          Disabled: false,
          Props: {
            placeholder: intl.formatMessage({ id: 'themeSubject' }),
          },
        },
        ShowTitle: intl.formatMessage({ id: 'Title' }),
        FormKey: 'title',
        Description: null,
      },
      {
        EditorType: 'RichEditorPro',
        Value: null,
        Setting: {
          Disabled: false,
          UploadUrl: baseURL + uploadContactEmailImage,
          Height: 480,
          Props: {
            placeholder: intl.formatMessage({
              id: 'Detailed Description Allow image pasting',
            }),
          },
        },
        ShowTitle: intl.formatMessage({ id: 'detail' }),
        FormKey: 'details',
        Description: '',
      },
    ],
  };
};

const ContactUS = () => {
  const intl = useIntl();

  const [listData, setListData] = useState([]);
  const [content, setContent] = useState({});
  const [type, setType] = useState('add');
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
    modalLoading: false,
  });

  const [modalVisible, setModalVisible] = useState(false);

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = await GetUserContactList({
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { totalCount, items } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current]);

  const tableProps = {
    listData,
    listSupport: listSupport(intl),
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'details' }),
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                addOrEdit('edit', record);
              }}
            >
              {intl.formatMessage({ id: 'details' })}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'resend' }),
        itemRender(record) {
          const { id, status } = record || {};
          return status !== 'Sent' ? (
            <a
              onClick={async (e) => {
                e.stopPropagation();
                setLoading({
                  ...loading,
                  tableLoading: true,
                });

                const res = await PostResendToSupport({ id });
                if (res.response.ok) {
                  const { status } = res.data || {};
                  if (status === 'Sent') {
                    message.success(intl.formatMessage({ id: 'sent' }));
                    initData();
                  } else {
                    message.error(
                      intl.formatMessage({
                        id: 'Failed Please try again later',
                      }),
                    );
                  }
                } else {
                  const {
                    data: { error },
                  } = res;
                  message.error(error.message);
                }

                setLoading({
                  ...loading,
                  tableLoading: false,
                });
              }}
            >
              {intl.formatMessage({ id: 'resend' })}
            </a>
          ) : null;
        },
      },
    ],
  };

  const modalProps = {
    title: intl.formatMessage({ id: 'New Message' }),
    visible: modalVisible,
    loading: loading.modalLoading,
    onCancel() {
      setModalVisible(false);
    },
    onSubmitText: intl.formatMessage({ id: 'send' }),
    onOk: (value) => {
      if (type === 'add') {
        Modal.confirm({
          title: intl.formatMessage({ id: 'sureSendMes' }),
          icon: <ExclamationCircleOutlined />,
          content: null,
          okText: intl.formatMessage({ id: 'sure' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          onOk: async () => {
            setLoading({
              ...loading,
              modalLoading: true,
            });

            const res = await PostSendToSupport(value);
            if (res.response.ok) {
              const { status } = res.data || {};
              if (status === 'Sent') {
                message.success(intl.formatMessage({ id: 'sent' }));
                setModalVisible(false);
                initData();
              } else {
                message.error(
                  intl.formatMessage({ id: 'Failed Please try again later' }),
                );
              }
            } else {
              const {
                data: { error },
              } = res;
              message.error(error.message);
            }

            setLoading({
              ...loading,
              modalLoading: false,
            });
          }
        });
      } else {
        setModalVisible(false);
      }
    },
    content,
  };

  const addOrEdit = (type, record = {}) => {
    let showForm = emptyContent(intl);

    if (type === 'edit') {
      showForm = matchDynamicForm(emptyContent(intl), {
        ...record,
      });

      showForm.Properties[0].Setting.Disabled = true;
      showForm.Properties[1].Setting.Disabled = true;
    }
    setType(type);
    setContent(showForm);
    setModalVisible(true);
  };

  return (
    <Card
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({ id: 'Contact Us' })}
        </b>
      }
      bordered={false}
    >
      <Space direction="vertical">
        <Button type="primary" onClick={() => addOrEdit('add')}>
          {intl.formatMessage({ id: 'New Message' })}
        </Button>
        <span>{intl.formatMessage({ id: 'suggestions and questions' })}</span>
      </Space>

      <p />
      <MagicTable {...tableProps} />
      <MagicFormModal {...modalProps} />
    </Card>
  );
};

export default ContactUS;
