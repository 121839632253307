import React, { useEffect, useState } from 'react';
import { message, Input, Card } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import ExpandedTable from './components/ExpandedTable';
import { GetExamListData, PutExamPaperAgain } from 'services/exam';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import { changeStorageStatus } from 'cognitiveleap-core-us/utils/utils';
import { history, connect } from 'umi';
import moment from 'moment';

const { Search } = Input;

let contentMenu = {
  Properties: [
    // {
    //   EditorType: 'NumberInput',
    //   ShowTitle: '剩余补考次数',
    //   FormKey: 'addTimes',
    //   AdditionalData: null,
    //   Value: null,
    //   Setting: {
    //     Required: true,
    //     Props: {
    //       min: 0,
    //     },
    //   },
    //   Description: '',
    // },
    {
      EditorType: 'NumberInput',
      ShowTitle: '补考通过分数',
      FormKey: 'passingScore',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        Props: {
          min: 0,
        },
      },
      Description: '',
    },
    // {
    //   EditorType: 'NumberInput',
    //   ShowTitle: '补考费用',
    //   FormKey: 'price',
    //   AdditionalData: null,
    //   Value: null,
    //   Setting: {
    //     Required: true,
    //     Props: {
    //       min: 0,
    //     },
    //   },
    //   Description: '',
    // },
  ],
};

const ExamResultPanel = ({ currentUser }) => {
  const { canChangeList, hostRoles = [] } = currentUser;
  const isSupervisor = hostRoles.find(item => item.name === "supervisor");

  const status = JSON.parse(sessionStorage.getItem("_STATUS_ExamList") || "{}");
  const { current = 1, flag = false } = status;

  const [examList, setExamList] = useState([]);
  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: '全部',
  });
  const [keyword, setKeyword] = useState('');
  const [currentContent, setCurrentContent] = useState(contentMenu);
  const [filter, setFilter] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectItem, setSelectItem] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: flag ? current : 1,
    pageSize: 10,
    total: 0,
  });

  const [loading, setLoading] = useState({
    tableLoading: false,
    modelLoading: false,
  });

  const getExamList = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetExamListData({
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      filter: keyword,
      status: filter,
      tenantId: currentTenant.id,
    });

    if (res.response.ok) {
      setExamList(res.data.items);
      setPagination({
        ...pagination,
        total: res.data.totalCount,
      });
    } else {
      message.error('获取考试列表失败');
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    getExamList();
    return () => {
      // 组件卸载时设置 flag
      changeStorageStatus("_STATUS_ExamList", "flag", false);
    }
  }, [pagination.current, pagination.pageSize, keyword, filter, currentTenant]);

  const examListSupport = {
    userName: {
      showText: '参考学员',
      showType: 'Text',
      render: (text, record) => {
        return (
          <a
            key={record.id}
            onClick={() =>
              history.push(
                `/system/manage/trainingStaffDetail?id=${record.userId}`,
              )
            }
          >
            {text}
          </a>
        );
      },
    },
    examinationName: {
      showText: '考试名称',
      showType: 'Text',
    },
    examinationNums: {
      showText: '已有考试次数',
      showType: 'Text',
    },
    // totalPrice: {
    //   showText: '累计实付金额($)',
    //   showType: 'Text',
    // },
    status: {
      showText: '考试状态（最新）',
      showType: 'Text',
      render: (text, record) => {
        if (text === 'PASSED') {
          return '通过';
        } else if (text === 'FAILED') {
          return '未通过';
        } else if (text === 'DOING') {
          return '考试中';
        } else if (text === 'NOT_STARTED') {
          return '未参考';
        } else {
          return '-';
        }
      },
      filter: {
        hasFilter: true,
        filterOptions: [
          {
            text: '通过',
            value: 'PASSED',
          },
          {
            text: '未通过',
            value: 'FAILED',
          },
          {
            text: '未参考',
            value: 'NOT_STARTED',
          },
        ],
      },
    },
    showEndTime: {
      showText: '考试提交时间（最新）',
      showType: 'Text',
    },
    surplusTimes: {
      showText: '剩余补考次数',
      showType: 'Text',
    },
    // agaginPrice: {
    //   showText: '补考金额',
    //   showType: 'Text',
    // },
  };

  const modalProps = {
    title: '编辑补考相关',
    visible,
    content: currentContent,
    onCancel() {
      setVisible(false);
    },
    loading: loading.modelLoading,
    async onOk(value) {
      setLoading({
        ...loading,
        modelLoading: true,
      });
      const { passingScore, addTimes } = value;
      const params = {
        times: 1,
        userExaminationPaperId: selectItem.items[0].id,
        // price,
        passingScore,
      };
      const res = await PutExamPaperAgain(params);
      if (res.response.ok) {
        getExamList();
        setVisible(false);
        message.success('编辑成功');
      }
      setLoading({
        ...loading,
        modelLoading: false,
      });
    },
  };

  const expandOptions = (record, index, indent, expanded) => {
    const columns = [
      {
        key: 'null',
        width: '4%',
      },
      {
        title: '第几次考试',
        key: 'id',
        align: 'left',
        width: '5%',
        render: (_, record, index) => {
          return index + 1;
        },
      },
      // {
      //   title: '本次应付金额($)',
      //   key: 'price',
      //   align: 'left',
      //   width: '5%',
      //   render: (_, record, index) => {
      //     return record.price ? record.price : 0;
      //   },
      // },
      // {
      //   title: '本次实付金额($)',
      //   key: 'price',
      //   align: 'left',
      //   width: '5%',
      //   render: (_, record, index) => {
      //     return record.payStatus
      //       ? record.payStatus !== 'NotPay'
      //         ? record.price
      //         : 0
      //       : 0;
      //   },
      // },
      {
        title: '是否通过',
        key: 'status',
        align: 'left',
        width: '5%',
        render: (_, record, index) => {
          const { status } = record;
          if (status === 'PASSED') {
            return '通过';
          } else if (status === 'FAILED') {
            return '未通过';
          } else if (status === 'DOING') {
            return '考试中';
          } else if (status === 'NOT_STARTED') {
            return '未参考';
          } else {
            return '-';
          }
        },
      },
      {
        title: '本次提交时间',
        key: 'endTime',
        align: 'left',
        width: '5%',
        render: (_, record, index) => {
          if (record.endTime) {
            return moment(record.endTime).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        },
      },
      {
        title: '本次考试成绩',
        key: 'action',
        align: 'left',
        width: '5%',
        render: (_, record, index) => {
          const { endTime, status } = record;
          if (endTime && (status === 'FAILED' || status === 'PASSED')) {
            const { id } = record;
            return (
              <a
                key={id}
                onClick={() => {
                  sessionStorage.setItem('_STATUS_ExamList', JSON.stringify({ current: pagination.current }))
                  history.push(`/system/manage/detail/examResult?examId=${id}`)
                }}
              >
                查看
              </a>
            );
          } else {
            return null;
          }
        },
      },
    ];
    if (expanded) {
      const data = record.items;
      let codeList = [];
      data.forEach((item, index) => {
        if (index >= 1) {
          codeList.push(item.id);
        }
      });

      const props = {
        columns,
        data,
        codeList,
      };
      return <ExpandedTable {...props} />;
    }
  };

  const changedTenantList = (id) => {
    if (id) {
      const newCurrentTenant = canChangeList.find((item) => item.id == id);
      setCurrentTenant({ ...newCurrentTenant });
    } else {
      setCurrentTenant({ id: '', displayName: '全部' });
    }
  };

  const examTableProps = {
    listData: examList.map((item) => {
      const { endTime, items } = item || {};
      return {
        ...item,
        showEndTime: !endTime
          ? '-'
          : moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
        // agaginPrice: items[0].makeUpExamination
        //   ? items[0].makeUpExamination.price
        //   : '-',
        surplusTimes: items[0].makeUpExamination
          ? items[0].makeUpExamination.times
          : '-',
      };
    }),
    listSupport: examListSupport,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: expandOptions,
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    loading: loading.tableLoading,
    filters: {
      status: filter,
    },
    pagination,
    onTableChange(page, filters) {
      if (filters !== undefined) {
        const { status } = filters;
        setFilter(status);
      }

      setExpandedRowKeys([]);

      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: '编辑补考',
        itemRender(record) {
          const { examinationId, status } = record;
          if (status !== 'PASSED') {
            return (
              <a
                key={examinationId}
                onClick={() => {
                  let againPrice = 0;
                  let againTime = 0;
                  let passScore = 0;
                  let emptyContent = contentMenu;
                  if (record.items[0].makeUpExamination) {
                    const { times, passingScore } =
                      record.items[0].makeUpExamination;
                    againTime = times;
                    // againPrice = price;
                    passScore = passingScore;
                  } else {
                    passScore = record.items[0].examinationPaper.passingScore;
                  }
                  // emptyContent.Properties[0].Value = againTime;
                  emptyContent.Properties[0].Value = passScore;
                  // emptyContent.Properties[2].Value = againPrice;
                  setCurrentContent(emptyContent);
                  setSelectItem(record);
                  setVisible(true);
                }}
              >
                编辑补考
              </a>
            );
          } else {
            return null;
          }
        },
      },
    ],
  };

  return (
    <Card
      title={'考试列表'}
      extra={
        !isSupervisor && (
          <AnalysisTenantSelect
            showAll={true}
            currentTenant={currentTenant}
            onChange={changedTenantList}
          />
        )
      }
      bordered={false}
    >
      <div>
        <Search
          placeholder="搜索姓名/考试名称"
          style={{ width: '260px' }}
          onSearch={(value) => {
            setPagination({
              ...pagination,
              current: 1,
              pageSize: 10,
            });
            setKeyword(value);
          }}
        />
      </div>
      <MagicTable {...examTableProps} />
      <MagicFormModal {...modalProps} />
    </Card>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(ExamResultPanel);
