import React from 'react'
import { Modal, Transfer } from 'antd'

export default class SelectModal extends React.Component {
  state = {
    targetKeys: this.props.defaultSelectKeys,
    selectedKeys: [],
  }

  handleOk = targetKeys => {
    this.props.onOk(targetKeys)
  }

  handleCancel = () => {
    this.setState({ selectedKeys: [] })
    this.props.onCancel()
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.defaultSelectKeys !== prevProps.defaultSelectKeys) {
      this.setState({ targetKeys: this.props.defaultSelectKeys })
    }
  }

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys })
  }

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] })
  }

  render() {
    const { allList, selectModalVisible } = this.props
    const { targetKeys, selectedKeys } = this.state

    const showData = allList.map(item => {
      return {
        ...item,
        key: item.id,
      }
    })

    return (
      <Modal
        width={800}
        destroyOnClose={true}
        title={'课程小节选择'}
        open={selectModalVisible}
        onOk={() => this.handleOk(targetKeys)}
        onCancel={() => this.handleCancel()}
      >
        <Transfer
          listStyle={{
            width: 350,
            height: 400,
          }}
          dataSource={showData}
          titles={['Source', 'Target']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={this.handleChange}
          onSelectChange={this.handleSelectChange}
          render={item => item.name}
          showSearch={true}
        />
      </Modal>
    )
  }
}
