
export default {
  namespace: 'archives',
  state: {
    subjectId: null,
    from: 'center',
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *loadPage({ payload }, { take, put, call, select }){
        yield put({ type: 'updateState', payload })
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/archives') {
    //         dispatch({ type: 'loadPage', payload: location.query })
    //     }
    //   })
    // },
  },
}
