import React from 'react'
import { connect } from 'umi'
import { Spin, Tabs } from 'antd';
import DietaryAnalysisChart from 'cognitiveleap-core-us/components/Charts/DietaryAnalysisChart';
import DietaryStructureBarChart from 'cognitiveleap-core-us/components/Charts/DietaryStructureBarChart';
import CenterClockInRecord  from 'components/ClockInRecord';
import CenterDataTop from 'components/DataTop';
import Loading from 'components/Loading'
import { useIntl } from 'umi'
import styles from '../detail.less'

const { TabPane } = Tabs;

const NutritionInfo = ({ subjectNutrition, subjectDetail, centerClockInRecord, loading, dispatch }) => {
  const { planInfo } = subjectNutrition
  const intl = useIntl()
  if (planInfo == null) return <Spin className={styles.spin} />;

  const { activePlans, lastestTrainingPlan } = planInfo;
  const { subjectId, subjectInfo: { isBind, isLogout } } = subjectDetail;
  const { nutritionRecord, pagination } = centerClockInRecord;
  const isLoading = loading.effects['centerClockInRecord/getRecord'];

  const loadData = () => {
    lastestTrainingPlan && lastestTrainingPlan.id && 
      dispatch({ 
        type: 'centerClockInRecord/getRecord', 
        payload: { 
          id: lastestTrainingPlan && lastestTrainingPlan.id, 
          type: 'nutrition',
          current: ++pagination.nutrition.current
        }});
  }

  const propsData = {
    dataSource: nutritionRecord,
    loadData,
    isLoading,
  }
  
  const chartProps = {
    subjectId,
    from: 'pc',
    Loading
  }
  return (
    <div className={styles.tabContent}>
      {
        !isLogout && isBind ? 
          <div>
            { 
              activePlans.length === 0 ? intl.formatMessage({ id: 'noOpenTraining' }) : 
                <div>
                  <CenterDataTop {...{lastestTrainingPlan, type:'center'}} />
                  <Tabs defaultActiveKey="chart" type="card" onChange={()=>{!nutritionRecord && loadData()}}>
                    <TabPane tab={intl.formatMessage({ id: 'figure' })} key="chart">
                      <center>
                        <div className={styles.chart}>
                          <DietaryAnalysisChart {...chartProps} />
                          <DietaryStructureBarChart {...chartProps} />
                        </div>
                      </center>
                    </TabPane>
                    <TabPane tab={intl.formatMessage({ id: 'checkInRecords' })} key="record">
                      <CenterClockInRecord { ...propsData } />
                    </TabPane>
                  </Tabs>
                </div>
            }
          </div>
          :
          <span>{intl.formatMessage({ id: 'childCannotViewed' }, { status: intl.formatMessage({ id: isLogout ? 'cancelled' : 'noLongerAuthorized' })})}</span>
      }
      
    </div>
  )
}

export default connect(({ 
  subjectNutrition, 
  loading,
  subjectDetail,
  centerClockInRecord
}) => ({ 
  subjectNutrition, 
  loading,
  subjectDetail,
  centerClockInRecord
}))(NutritionInfo)
