import usermanger from 'utils/auth'
import { logIn, logOut } from 'cognitiveleap-core-us/utils/auth'
import { history } from 'umi'

export default {
  namespace: 'callback',

  state: {},

  reducers: {},

  effects: {
    *check({ payload }, { call, put, take, select }) {
      const mgr = usermanger.manager ? usermanger.manager : yield usermanger.init()
      mgr
        .signinRedirectCallback()
        .then(user => {
          if (user) {
            console.log(user)
            const { access_token, state } = user
            const { returnUrl = '/' } = state || {}
            logIn(access_token)
            history.replace(`${decodeURIComponent(returnUrl)}`)
            // window.location.href = decodeURIComponent(returnUrl)
          } else {
            console.log('user not login')
            history.replace('/')
            // window.location.href = '/'
          }
        })
        .catch(e => {
          console.log(e)
          logOut()
          history.replace('/')
          // window.location.href = '/'
        })
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen(({ pathname, query }) => {
        if (pathname === '/callback') {
          dispatch({ type: 'check' })
        }
      })
    },
  },
}
