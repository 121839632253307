import React from 'react';
import { Space } from 'antd';
import { useIntl } from 'umi';

const PathLengthChartText = ({ motionIcon, data }) => {
  const { percentInfo } = data;
  const intl = useIntl();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
      }}
    >
      <Space size="10" align="center">
        <img
          src={motionIcon}
          alt={motionIcon}
          style={{
            width: 35,
            height: 35,
            marginRight: 15,
            verticalAlign: 'top',
          }}
        />
        <div
          style={{
            color: '#333',
            fontSize: 16,
            fontWeight: 700,
            verticalAlign: 'sub',
          }}
        >
          {intl.formatMessage({ id: 'MovementDistance.Percentile' })} -
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: 700,
            color: percentInfo.color,
            verticalAlign: 'sub',
            marginLeft: '10px',
          }}
        >
          {percentInfo.percent}
        </div>
      </Space>
    </div>
  );
};
export default PathLengthChartText;
