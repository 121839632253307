import { Drawer, Table } from 'antd'
import { IntlManager } from 'utils/helper'


const PlaybookDrawer = ({ list, visible, onClose }) => {
  const intl = IntlManager.MyIntl()

  const columns = [
    {
      title: intl('numberOrder'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: intl('activityName'),
      dataIndex: 'title',
      key: 'title',
    },
    // {
    //   title: 'suggestedTimeLength',
    //   dataIndex: 'suggestedTimeLength',
    //   key: 'suggestedTimeLength',
    // },
    // {
    //   title: 'isRegisterAction',
    //   dataIndex: 'isRegistered',
    //   key: 'isRegistered',
    // },
    {
      title: intl('coverImage'),
      dataIndex: 'images',
      key: 'images',
      render: images => {
        if (images.length > 0) {
          //仅显示第一个
          const showImg = images[0].url
          return (
            <a href={showImg} rel="noopener noreferrer" target="_blank">
              <img style={{ width: 50, height: 50, marginRight: 5 }} src={showImg} alt={'img'} />
            </a>
          )
        } else {
          return null
        }
      },
    },
    {
      title: intl('detailsDescription'),
      dataIndex: 'content',
      key: 'content',
    },
  ]
  
  const showData = list.map(item => {
    const { isRegisterAction, content } = item
    return {
      ...item,
      isRegistered: intl(isRegisterAction ? 'yes' : 'no'),
      content: <div dangerouslySetInnerHTML={{__html:content}}></div>
    }
  })

  return (
    <Drawer
      width={800}
      title={intl('recommendedActivitiesList')}
      closable={false}
      onClose={onClose}
      open={visible}
    >
      <Table dataSource={showData} columns={columns} rowKey={'id'} />
    </Drawer>
  )
}

export default PlaybookDrawer
