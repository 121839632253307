import React, { useEffect, useState } from 'react'
import { connect, history, useIntl } from 'umi'
import { Spin, Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons';
import styles from './QuestionnaireForm.less'
import QuestionnaireForm from 'components/Question'

const QuestionnairePage = ({ dispatch, questionnaire, loading, location }) => {

  const intl = useIntl()
  const { questionnaireInfo, answer, editable } = questionnaire

  const [current, SetCurrent] = useState(answer);

  useEffect(() => {
    dispatch({ type: 'questionnaire/fetch', payload: location.query })
    return () => {
      dispatch({
        type: 'questionnaire/save',
        payload: { answerId: null, questionnaireInfo: null, answer: null },
      })
    }
  }, [])

  const handleComplete = () => {
    dispatch({ type: 'questionnaire/submitAnswer', payload: current || { answerContent: answer } })
  }

  const onBackClick = () => {
    dispatch({
      type: 'questionnaire/save',
      payload: { answerId: null, questionnaireInfo: null, answer: null },
    })
    history.goBack()
  }

  const { JsonString: questions, DisplayName } = questionnaireInfo || {}

  const QuestionnaireProps = {
    content: questions,
    answerContent: answer,
    editable,
    onComplete(data) {
      dispatch({ type: 'questionnaire/submitAnswer', payload: data })
    },
    onValueChange(data) {
      SetCurrent(data);
    }
  }

  const showBackBtn = editable ? intl.formatMessage({ id: 'saveAndBack' }) : intl.formatMessage({ id: 'back' })

  return (
    <div className={`${styles.surveyjs} ${styles.shadow}`}>
      <div className={styles.questionnaire}>
        <Button className={styles.gobackbutton} onClick={onBackClick}>
          <LeftOutlined />
          <a>{intl.formatMessage({ id: 'back' })}</a>
        </Button>
        {editable ? (
          <Button className={styles.gobackbutton} onClick={handleComplete}>
            <LeftOutlined />
            <a>{showBackBtn}</a>
          </Button>
        ) : null}
        <span className={styles.title}>{DisplayName}</span>
        <hr />
        <div>
          {questions === null || loading.models.questionnaire ? (
            <Spin />
          ) : (
            <QuestionnaireForm
              {...QuestionnaireProps}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(({ questionnaire, loading }) => ({ questionnaire, loading }))(QuestionnairePage)
