import React from 'react'
import styles from './Report.less'
import { useIntl } from 'umi'
import { Row, Col } from 'antd'
import MovementPathMotionType from 'components/VratChartComponents/MovementPathMotionType'
import MovementPathBlocks from 'components/VratChartComponents/MovementPathBlocks'
import headIcon from 'assets/vratReportImg/head_small_icon.png'
import handIcon from 'assets/vratReportImg/hand_small_icon.png'
import chestIcon from 'assets/vratReportImg/chest_small_icon.png'
import legIcon from 'assets/vratReportImg/leg_small_icon.png'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const MovementPath = ({ data, showNewReport }) => {
  const intl = useIntl()
  //type 0->3 blockNum 1-4
  const GetByType = (list, type, blockNum) => {
    const result = list.filter(item => {
      return item.MotionType === type
    })

    const res = result.filter(item => {
      return item.Block === blockNum
    })
    return res
  }

  const Points = data ? data.Points : []

  // Head block 1~4
  const showPointsHeadBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 0, 1),
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 0, 2),
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 0, 3),
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 0, 4),
        },
      },
    },
    motionIcon: headIcon,
    motionType: intl.formatMessage({ id: 'report.movementPath.chart.head' }),
  }
  // Hand block 1~4
  const showPointsHandBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 1, 1),
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 1, 2),
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 1, 3),
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 1, 4),
        },
      },
    },
    motionIcon: handIcon,
    motionType: intl.formatMessage({ id: 'report.movementPath.chart.hand' }),
  }
  // Chest block 1~4
  const showPointsChestBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 2, 1),
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 2, 2),
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 2, 3),
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 2, 4),
        },
      },
    },
    motionIcon: chestIcon,
    motionType: 'Chest',
  }
  // Leg block 1~4
  const showPointsLegBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 3, 1),
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 3, 2),
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 3, 3),
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 3, 4),
        },
      },
    },
    motionIcon: legIcon,
    motionType: 'Leg',
  }

  const block1Props = {
    styles,
    number: 1,
  }
  const block2Props = {
    styles,
    number: 2,
  }
  const block3Props = {
    styles,
    number: 3,
  }
  const block4Props = {
    styles,
    number: 4,
  }

  return (
    <div id="movement" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.movementPath.title' })}</p>
      </h2>
      <div style={{ marginTop: 80, position: 'relative' }}>
        <span className={styles.movementPathText}>
          {intl.formatMessage({ id: 'report.movementPath.chart.yAxis' })}
        </span>
        <MovementPathMotionType {...showPointsHeadBlocksProps} />
        <MovementPathMotionType {...showPointsHandBlocksProps} />
      </div>
      <Row type="flex" justify="center">
        <div style={{ width: '175px' }}>
          <MovementPathBlocks {...block1Props} />
        </div>
        <div style={{ width: '175px' }}>
          <MovementPathBlocks {...block2Props} />
        </div>
        <div style={{ width: '175px' }}>
          <MovementPathBlocks {...block3Props} />
        </div>
        <div style={{ width: '175px' }}>
          <MovementPathBlocks {...block4Props} />
        </div>
      </Row>

      <h3 style={{ textAlign: 'center', marginTop: -10 }} className={styles.weightFont}>
        {intl.formatMessage({ id: 'report.movementPath.chart.title' })}
      </h3>

      {
        /*
          <div style={{ textAlign: 'center', marginTop: '20px'}}>
            <span className={styles.circle}></span>
            {intl.formatMessage({ id: 'report.movementPath.chart.des' })}
          </div>
        */
      }
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '7' : '7'}</p>
    </div>
  )
}

export default MovementPath
