import React, { useEffect, useState } from 'react';
import { Spin, Tag } from 'antd';
import moment from 'moment';

import CardHead from 'components/CardHead';
import ShowDataAnalysis from 'components/ShowDataAnalysis';
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate';
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart';
import {
  PostOneToMoreTrainingStatistics,
  GetOneToMoreTrainingAnalsis,
} from 'services/rocketService/Analysis';
import { getGroupTag } from 'utils/utils';
import { showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import { useIntl } from 'umi'
import { oneToMoreKeyIndicators, ilsLegend } from '../config';
import styles from './index.less';

const { CheckableTag } = Tag;

const tagsData = [
  'All',
  'OneToOne',
  'OneToTwo',
  'OneToThree',
  'OneToFour',
  'OneToFive',
  'OneToSix',
  'OneToMany'
];

const StatisticalData = ({ currentTenantId }) => {
  const intl = useIntl()
  const [statisticsData, setStatisticsData] = useState({});
  const [analsis, setAnalsis] = useState({});
  const [selectedTags, setSelectedTags] = useState(['All']);
  const [currentTag, setCurrentTag] = useState('Month');
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [analsisLoading, setAnalsisLoading] = useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [dateMonthPickerData, setDateMonthPickerData] = useState(moment());
  const [dateYearPickerData, setDateYearPickerData] = useState(moment());

  const initData = async () => {
    setStatisticsLoading(true);
    const res = await PostOneToMoreTrainingStatistics({
      TenantId: currentTenantId,
      GroupTypes: selectedTags.includes('All') ? [] : selectedTags,
    });

    if (res.response.ok) {
      setStatisticsData(res.data);
    } else {
      showErrorMsg(res, intl);
    }
    setStatisticsLoading(false);
  };

  const initAnalysisData = async () => {
    setAnalsisLoading(true);
    const res = await GetOneToMoreTrainingAnalsis({
      Type: currentTag,
      Year: parseInt(moment(dateYearPickerData).format('YYYY')),
      Month: parseInt(moment(dateMonthPickerData).format('MM')),
      TenantId: currentTenantId,
    });

    if (res.response.ok) {
      const { trend } = res.data;
      setAnalsis(trend);
    } else {
      showErrorMsg(res, intl);
    }
    setAnalsisLoading(false);
  };

  useEffect(() => {
    initData();
  }, [currentTenantId, selectedTags]);

  useEffect(() => {
    initAnalysisData();
  }, [
    dateMonthPickerData,
    dateYearPickerData,
    currentTenantId,
    currentTag,
    selectedTags,
  ]);

  if (
    JSON.stringify(statisticsData) == '{}' ||
    JSON.stringify(analsis) == '{}'
  ) {
    return <Spin />;
  }

  oneToMoreKeyIndicators(intl).forEach((item) => {
    item.data = statisticsData[item.field];
  });

  const analysisChoiceDateProps = {
    currentTag,
    dateMonthPickerData,
    dateYearPickerData,
    datePickerIsOpen,
    changeTag: (tag) => {
      const { key } = tag;
      setCurrentTag(key);
    },
    handleOpenChange: () => {
      setDatePickerIsOpen(!datePickerIsOpen);
    },
    handlePanelChange: (time) => {
      setDateMonthPickerData(
        {
          Year: dateMonthPickerData,
          Month: moment(time),
        }[currentTag],
      );
      setDateYearPickerData(
        {
          Year: moment(time),
          Month: dateYearPickerData,
        }[currentTag],
      );

      setDatePickerIsOpen(!datePickerIsOpen);
    },
  };

  const chartProps = {
    legendData: ilsLegend(intl),
    currentTag,
    dateMonthPickerData,
    dateYearPickerData,
    dataSource: analsis,
  };

  const handleChange = (tag, checked) => {
    if (!checked && selectedTags.length === 1) return;

    if (checked && tag === 'All') {
      setSelectedTags(['All']);
    } else {
      const tempData = selectedTags;
      const index = selectedTags.indexOf('All');
      if (index !== -1) {
        tempData.splice(index, 1);
      }
      const nextSelectedTags = checked
        ? [...tempData, tag]
        : tempData.filter((t) => t !== tag);
      setSelectedTags(nextSelectedTags);
    }
  };

  const getExtra = (
    <>
      <span style={{ marginRight: 8 }}>类型: </span>
      {tagsData.map((tag) => (
        <CheckableTag
          key={tag}
          checked={selectedTags.indexOf(tag) > -1}
          onChange={(checked) => handleChange(tag, checked)}
        >
          {getGroupTag(tag, intl)}
        </CheckableTag>
      ))}
    </>
  );

  return (
    <div className={styles.vratAnalysis}>
      <div className={styles.keyIndicators}>
        <CardHead title={intl.formatMessage({ id: 'keyIndicators' })} extra={getExtra} />
        <Spin spinning={statisticsLoading}>
          <ShowDataAnalysis keyIndicators={oneToMoreKeyIndicators(intl)} />
        </Spin>
      </div>
      <div className={styles.trendAnalysis}>
        <CardHead title={intl.formatMessage({ id: 'trendAnalysis' })} />
        <AnalysisChoiceDate {...analysisChoiceDateProps} />
        <div className={styles.trendAnalysisContent}>
          <Spin spinning={analsisLoading}>
            <CommonAnalysisLineChart {...chartProps} />
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default StatisticalData;
