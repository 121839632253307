import React, { useEffect, useState } from 'react';
import { useIntl } from 'umi';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

import {
  PostCbtList,
  PostExerciseList,
  PostNutrition,
  GetPdtProgress,
  GetSleepAnslysis,
  PostChildEmotionList,
  GetParentEmotionFeedbackTimes,
} from 'services/pdt';

const ExpandedTable = ({ allData }) => {
  const intl = useIntl();

  const { id, pdtModules = [] } = allData || {};

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const hasCbt = pdtModules.some((module) => module.pdtModule === 'Cbt');
  const hasEmotion = pdtModules.some(
    (module) => module.pdtModule === 'Emotion',
  );
  const hasSleep = pdtModules.some((module) => module.pdtModule === 'Sleep');
  const hasExercise = pdtModules.some(
    (module) => module.pdtModule === 'Exercise',
  );
  const hasNutrition = pdtModules.some(
    (module) => module.pdtModule === 'Nutrition',
  );
  const hasParentEmotion = pdtModules.some(
    (module) => module.pdtModule === 'ParentEmotion',
  );
  const hasParentLearning = pdtModules.some(
    (module) => module.pdtModule === 'ParentLearning',
  );

  const listSupport = (intl) => {
    return {
      CBTClass: {
        showText: intl.formatMessage({ id: 'CBTClass' }),
        showType: hasCbt ? 'Text' : 'Hidden',
      },
      Completed: {
        showText: intl.formatMessage({ id: 'cbtCompleted' }),
        showType: hasCbt ? 'Text' : 'Hidden',
        render: (text) => {
          return text + '%';
        },
      },
      EmotionPdt: {
        showText: intl.formatMessage({ id: 'Emotion' }),
        showType: hasEmotion ? 'Text' : 'Hidden',
      },
      LearingPdt: {
        showText: intl.formatMessage({ id: 'ParentLearning' }),
        showType: hasParentLearning ? 'Text' : 'Hidden',
      },
      ParentEmotion: {
        showText: intl.formatMessage({ id: 'ParentEmotion' }),
        showType: hasParentEmotion ? 'Text' : 'Hidden',
      },
      ExercisePdt: {
        showText: intl.formatMessage({ id: 'ExercisePdt' }),
        showType: hasExercise ? 'Text' : 'Hidden',
      },
      SleepPdt: {
        showText: intl.formatMessage({ id: 'SleepPdt' }),
        showType: hasSleep ? 'Text' : 'Hidden',
      },
      NutritionPdt: {
        showText: intl.formatMessage({ id: 'NutritionPdt' }),
        showType: hasNutrition ? 'Text' : 'Hidden',
      },
    };
  };

  const initData = async () => {
    setLoading(true);
    const resList = await Promise.all([
      hasCbt &&
        PostCbtList({
          childId: id,
          status: ['completed', 'caughtUp', 'active'],
        }),
      hasExercise &&
        PostExerciseList({
          childId: id,
          status: ['completed', 'caughtUp', 'active'],
        }),
      hasNutrition && PostNutrition({ userId: id }),
      hasParentLearning && GetPdtProgress({ subjectId: id }),
      hasSleep && GetSleepAnslysis({ subjectId: id }),
      hasEmotion && PostChildEmotionList({ childId: id }),
      hasParentEmotion && GetParentEmotionFeedbackTimes({ subjectId: id }),
    ]);

    // Cbt Data
    const cbtData = resList[0]?.data || [];
    const cbtCompletedNum = cbtData?.filter(
      (item) => item.status === 'completed',
    ).length;

    // Exercise Data
    const exerciseData = resList[1]?.data || [];

    const exerciseCompletedNum = exerciseData?.filter(
      (item) => item.status === 'completed',
    ).length;

    // Nutrition Data
    const nutritionData = resList[2]?.data?.data;
    const { numbersOfRecord } = nutritionData || {};

    // Learn Data
    const learnData = resList[3]?.data?.data;
    const { completedNum = 0, totalNum = 60 } = learnData || {};

    // Sleep Data
    const sleepData = resList[4]?.data?.data;
    const { checkInTimes } = sleepData || {};

    // Emotion Data
    const emotionData = resList[5]?.data || [];

    // ParentEmotion Data
    const parentEmotionData = resList[6]?.data || [];

    setListData([
      {
        SleepPdt: checkInTimes,
        ParentEmotion:
          parentEmotionData instanceof Array
            ? parentEmotionData.length
            : parentEmotionData,
        EmotionPdt: emotionData?.length,
        LearingPdt: completedNum + '/' + totalNum,
        CBTClass: cbtCompletedNum + '/' + 60,
        Completed: ((cbtCompletedNum / 60) * 100).toFixed(0), // 使用Cbt进度
        ExercisePdt: exerciseCompletedNum + '/' + 60,
        NutritionPdt: numbersOfRecord,
      },
    ]);

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const tableProps = {
    listData,
    listSupport: listSupport(intl),
    loading,
  };

  return <MagicTable {...tableProps} />;
};

export default React.memo(ExpandedTable);
