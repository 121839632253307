import moment from 'moment'
import { message } from "antd"
import { GetArrangeCourse, GetPlanDetailInfo, GetTeanntCourseCoseList } from 'services/arrangeCourse'
import { changeTenant, changeUserTenant } from 'cognitiveleap-core-us/utils/auth'
import { getFormatDate } from 'utils/utils'

const getTimeSpan = (interval) => {
    switch (interval) {
        case '0':
            return null;
        case '1':
            return {
                periodIntervalUnit: 'Day',
                periodIntervalNumber: 1
            }
        case '2':
            return {
                periodIntervalUnit: 'Day',
                periodIntervalNumber: 7
            }
        case '3':
            return {
                periodIntervalUnit: 'Day',
                periodIntervalNumber: 14
            }
        case '4':
            return {
                periodIntervalUnit: 'Month',
                periodIntervalNumber: 1
            }
    }
}


let contentMenu = {
    Properties: [
        {
            EditorType: 'DatePicker',
            ShowTitle: '开始上课时间',
            FormKey: 'startTime',
            AdditionalData: null,
            Value: null,
            Setting: {
                format: 'YYYY-MM-DD HH:mm',
                minuteStep: 30,
                Required: true,
                DateType: 'time',
                disabledHours: [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23]
            },
            Description: '上课时长为1小时',
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: '线下',
                        Id: 'Offline',
                    },
                    {
                        Value: '线上',
                        Id: 'Online',
                    },
                ],
                Required: true,
            },
            ShowTitle: '方式',
            FormKey: 'courseType',
            Description: null,
        },
        {
            EditorType: 'Dropdown',
            Value: '1',
            Setting: {
                IsVisible: true,
                DropdownOptions: [
                    {
                        Value: '仅编辑本次排课',
                        Id: '0',
                    },
                    {
                        Value: '编辑本次及后续排课',
                        Id: '1',
                    }
                ],
                onchange(value) {
                    /**
                     * 如果设置为0
                     *      重复排课 消失
                     *      重复次数 消失
                     */
                    if (value == 0) {
                        const content = {...contentMenu}
                        content.Properties[6].Setting.IsVisible = false
                        content.Properties[7].Setting.IsVisible = false

                        getDvaApp()._store.dispatch({
                            type: 'classCardAdmin/updateState',
                            payload: {
                                content
                            }
                        })
                    } else {
                        const content = {...contentMenu}
                        content.Properties[6].Setting.IsVisible = true

                        getDvaApp()._store.dispatch({
                            type: 'classCardAdmin/updateState',
                            payload: {
                                content
                            }
                        })
                    }
                },
                Required: true,
            },
            ShowTitle: '编辑生效',
            FormKey: 'editTakeEffect',
            Description: null,
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                IsVisible: true,
                DropdownOptions: [
                    {
                        Value: '不重复',
                        Id: '0',
                    },
                    {
                        Value: '每天',
                        Id: '1',
                    },
                    {
                        Value: '每周',
                        Id: '2',
                    },
                    {
                        Value: '每两周',
                        Id: '3',
                    },
                    {
                        Value: '每月',
                        Id: '4',
                    },
                ],
                onchange(value) {
                    /* 如果value为0设置
                            重复次数为0并且disabled
                    */
                    if (value == 0) {
                        const content = {...contentMenu}
                        content.Properties[7].Setting.IsVisible = false
                        getDvaApp()._store.dispatch({
                            type: 'classCardAdmin/updateState',
                            payload: {
                                content,
                            }
                        })
                    } else {
                        getDvaApp()._store.dispatch({
                            type: 'classCardAdmin/updateState',
                            payload: {
                                content: contentMenu
                            }
                        })
                    }
                },
                Required: true,
            },
            ShowTitle: '重复排课',
            FormKey: 'interval',
            Description: null,
        },
        {
            EditorType: 'NumberInput',
            ShowTitle: '重复次数',
            FormKey: 'courseCount',
            AdditionalData: null,
            Value: 1,
            Setting: {
                IsVisible: true,
                NumberSetting: {
                    min: 1,
                    step: 1,
                },
                Required: true,
            },
            Description: '请输入大于0 的整数',
        },
        {
            EditorType: 'CheckboxSelector',
            Value: ['parentSMS', 'trainerSMS'],
            Setting: {
                DropdownOptions: [
                    {
                        Value: '训练师',
                        Id: 'trainerSMS'
                    },
                    {
                        Value: '家长',
                        Id: 'parentSMS'
                    }
                ],
                Required: false,
            },
            ShowTitle: '短信提醒',
            FormKey: 'SMSReminder',
            Description: "约提前24小时发送短信提醒与系统内通知。",
        }
    ]
}

export default {
    namespace: 'classCardAdmin',

    state: {
        currentDate: moment(),
        // type为本人时的开始时间与结束时间
        startDate: moment().add(-1, 'days'),
        endDate: moment().add(5, 'days'),
        // type为本人时的选择日期
        selfChangeDate: moment(),
        content: null,
        editArrangeVisible: false,
        isShowHoverInfo: false,
        currentSelectTenantId: null,
        currentSelectTrainerId: null,
        hoverDataInfo: null,
        top: 0,
        left: 0,
        showDrawer: false,
        // 当前类型被安排的课程数据
        courseData: null,
        // 当前数据
        data: [],
        // 当前选择的日历
        selectCalendar: null,
        // 本人好课数
        oneselfCourseNumData: null,
        // 原始数据
        preData: [],
        // 是否显示过去可是
        isShowPreData: false,
        // 他人的ID
        coachIds: ""
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    effects: {
        *query({ payload }, { call, put, select }) {
            let queryRes = null
            yield put({ type: 'updateState', payload: { isShowHoverInfo: false } })
            const { startDate, endDate, coachIds} = yield select(state => state.classCardAdmin)
            const tableHeadDOM = document.getElementsByClassName("ant-table-thead")[0]
            tableHeadDOM && (tableHeadDOM.style.top = "0")

            const params = {
                startTime: getFormatDate(startDate, true),
                endTime: getFormatDate(endDate, false),
                coachIds
            }

            queryRes = yield call(GetArrangeCourse, params)

            // 获取本人耗课数
            yield put({
                type: 'getTenantCourseList'
            })

            if (queryRes && queryRes.response.ok) {
                yield put({
                    type: 'updateStateData',
                    payload: queryRes.data
                })
                yield put({
                    type: 'updateState',
                    payload: {
                        preData: queryRes.data
                    }
                })
            }
        },

        *getTenantCourseList({ payload }, { call, put, select }) {
            const getTenantListRes = yield call(GetTeanntCourseCoseList)
            if (getTenantListRes.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: {
                        oneselfCourseNumData: getTenantListRes.data
                    }
                })
            }
        },

        *onClosed({ payload }, { call, put, select }) {
            yield put({
                type: "updateState",
                payload: {
                    isShowHoverInfo: false
                }
            })
        },

        *showCourses({ payload }, { call, put, select }) {
            yield put({ type: "updateState", payload: { showDrawer: true } })
        },

        *changeDate({ payload }, { call, put, select }) {
            let { startDate, endDate } = yield select(state => state.classCardAdmin)
            if (moment(payload).isSameOrAfter(startDate) && moment(payload).isSameOrBefore(endDate)) {
                yield put({
                    type: 'updateState',
                    payload: {
                        selfChangeDate: payload
                    }
                })
                return;
            } else {
                startDate = moment(payload).add(-1, 'days');
                endDate = moment(payload).add(5, 'days');
                yield put({
                    type: 'updateState',
                    payload: {
                        startDate,
                        endDate,
                        selfChangeDate: payload
                    }
                })
                yield put({
                    type: 'query'
                })
            }

        },

        *transDate({ payload }, { call, put, select }) {
            let { startDate, endDate, selfChangeDate } = yield select(state => state.classCardAdmin)
            if (payload === "add") {
                startDate = moment(endDate).add(1, 'days');
                endDate = moment(startDate).add(6, 'days');
            } else {
                endDate = moment(startDate).add(-1, 'days');
                startDate = moment(endDate).add(-6, 'days');
            }
            selfChangeDate = moment(startDate).add(1, 'days');
            yield put({
                type: "updateState",
                payload: {
                    startDate,
                    endDate,
                    selfChangeDate,
                }
            })
            yield put({
                type: "query"
            })

        },

        *changeDateToToday({ payload }, { call, put, select }) {
            yield put({
                type: 'updateState',
                payload: {
                    startDate: moment().add(-1, 'days'),
                    endDate: moment().add(5, 'days'),
                    selfChangeDate: moment()
                }
            })
            yield put({
                type: 'query'
            })
        },

        *getPlanDetailInfo({ payload }, { call, put, select }) {
            const { top, left, dataInfo, selectCalendar } = payload
            const { tenantInfo } = selectCalendar || {}
            const { id: tenantId } = tenantInfo || {}
            const { item } = dataInfo || {}
            const { userPlanId, currentIndex, isRealItem } = item || {}
            if (isRealItem) {
                yield put({
                    type: 'updateState',
                    payload: {
                        hoverDataInfo: item,
                        isShowHoverInfo: true,
                        top,
                        left,
                        selectCalendar
                    }
                })
            } else {
                const getPlanDetailInfoRes = yield call(GetPlanDetailInfo, { id: userPlanId, index: currentIndex }, { 't-select': tenantId })
                if (getPlanDetailInfoRes.response.ok) {
                    yield put({
                        type: 'updateState',
                        payload: {
                            hoverDataInfo: getPlanDetailInfoRes.data,
                            isShowHoverInfo: true,
                            top,
                            left,
                            selectCalendar
                        }
                    })
                } else {
                    const data = getPlanDetailInfoRes.data
                    const { error } = data || {}
                    error && message.error(error.message)
                }
            }
        },

        *updateStateData({ payload }, { call, put, select }) {
            const { data, preData, isShowPreData } = yield select(state => state.classCardAdmin)
            const filterData = payload || data
            const newData = filterData.filter(item => {
                const { isRealItem, startTime } = item || {}
                if (isRealItem) return true
                else {
                    const endTime = moment(startTime).add(1, 'hours')
                    if (endTime.isBefore(moment())) return false
                    return true
                }
            })
            let resData = isShowPreData ? preData : newData

            // 判断是否是整点
            resData = resData.map(item => {
                let { startTime } = item || {}
                const minutes = moment(startTime).get('minutes')
                if (minutes !== 0 && minutes !== 30) {
                    startTime = moment(startTime).set('minutes', 0)
                    return {
                        ...item,
                        startTime,
                        endTime: moment(startTime).add("1", "hours")
                    }
                }
                return item
            })

            yield put({
                type: 'updateState',
                payload: {
                    currentDate: moment(),
                    data: resData
                }
            })
        },

        *changeRoute({ payload }, { call, put, select }) {
            const { selectCalendar } = yield select(state => state.classCardAdmin)
            const { tenantInfo } = selectCalendar || {}
            const { id } = tenantInfo || {}
            const { url } = payload || {}
            changeTenant("")
            changeUserTenant(id)
            yield put({
                type: 'user/updateState',
                payload: {
                    currentUser: null,
                },
            })
            window.location.href = url
            window.location.reload()
        },

        *showPreData({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { isShowPreData: payload } })
            yield put({ type: 'updateStateData' })
        }
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(async ({ pathname, query }) => {
        //         if (pathname === '/system/manage/classCardDetail') {
        //             await dispatch({ type: 'updateState', payload: { content: null, coachIds: query.id } })
        //             dispatch({ type: "query" })
        //         }
        //     })
        // },
    },
}
