import { message } from 'antd'
import {
    GetTenantTrainerModuleList,
    GetTrainerList,
    PostExportPostTrainerList,
    PostEndTenantTrainingModule,
    PostNewTrainerTrainingModule,
    PostStopOrStart,
    PostIssueCertificate,
    PostSupervisorSummary,
    PutSupervisorSummary,
    GetQuestionnaire,
    GetSupervisorSSummary,
    GetTrainerSummaryList,
    PutTrainerRemark,
    PostEndDate
} from 'services/trainingSystem'
import config from 'utils/config'
import moment from 'moment'
import { getAuthHeader, matchDynamicForm } from 'cognitiveleap-core-us/utils/auth'
import download from 'cognitiveleap-core-us/utils/download'
const { baseURL, uploadCredentials } = config

const defaultPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
}

let contentMenu = {
    Properties: [
        {
            EditorType: 'DatePicker',
            ShowTitle: '续期到',
            FormKey: 'endDate',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
                DateType: 'date',
                DisabledDate: (current) => {
                    return current && moment(current).isBefore(moment(), 'day')
                }
            },
            Description: <span style={{ color: '#fb6322' }}>续期到的日期将更新为新的结束学习日期</span>,
        }
    ]
}

let awardContentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: '证书编号',
            FormKey: 'credentialsCode',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
            },
            Description: '',
        },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: 'Maple',
                        Id: 'Maple',
                    },
                    {
                        Value: 'Emma',
                        Id: 'Emma',
                    },
                ],
                Required: true,
            },
            ShowTitle: '培训师',
            FormKey: 'trainerName',
            Description: null,
        },
        // {
        //     EditorType: 'Input',
        //     ShowTitle: '培训师',
        //     FormKey: 'trainerName',
        //     AdditionalData: null,
        //     Value: null,
        //     Setting: {
        //         Required: true,
        //     },
        //     Description: '',
        // },
        {
            EditorType: 'Dropdown',
            Value: null,
            Setting: {
                DropdownOptions: [
                    {
                        Value: 'Maple',
                        Id: 'Maple',
                    },
                    {
                        Value: 'Emma',
                        Id: 'Emma',
                    },
                ],
                Required: true,
            },
            ShowTitle: '认证师',
            FormKey: 'certifier',
            Description: null,
        },
        // {
        //     EditorType: 'Input',
        //     ShowTitle: '认证师',
        //     FormKey: 'certifier',
        //     AdditionalData: null,
        //     Value: null,
        //     Setting: {
        //         Required: true,
        //     },
        //     Description: '',
        // },
        {
            EditorType: 'DatePicker',
            ShowTitle: '考核通过时间',
            FormKey: 'passTime',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: true,
                DateType: 'date',
            },
            Description: null,
        },
        {
            EditorType: 'RangePicker',
            ShowTitle: '有效期',
            FormKey: 'startEndTime',
            AdditionalData: null,
            Value: null,
            Setting: {
                showTime: false,
                Required: true,
                format: 'YYYY/MM/DD'
            },
            Description: null,
        },
        {
            EditorType: 'Upload',
            Value: null,
            Setting: {
                UploadSetting: {
                    isSingle: true,
                    uploadType: 'image'
                },
                Action: baseURL + uploadCredentials,
                Header: getAuthHeader(),
                Required: true,
            },
            ShowTitle: '上传证书',
            FormKey: 'credential',
        }
    ]
}

let remarkContentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            Value: null,
            Setting: {
                Required: true,
            },
            ShowTitle: '备注',
            FormKey: 'remark',
            Description: null,
        },
    ]
}


export default {
    namespace: 'trainingStaffList',
    state: {
        list: [],
        tabsData: [],
        currentTenant: null,
        name: '',
        role: '',
        learningState: '',
        qualifications: '',
        activeKey: '',
        selectItem: null,
        renewalModalVisible: false,
        remarkModalVisible: false,
        autoGenerationCertModalVisible: false,
        content: null,
        type: '',
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
        showSupervisionVisible: false,
        supervisionTabActiveKey: 'Supervisor',
        eidtSupervisionVisible: false,
        assesDrawerVisible: false,
        supervisorQuestion: null,
        supervisorIsFinshed: false,
        supervisorSubmitData: null,
        currentTrainerSummaryId: '',
        currentSupervisorId: '',
        questionnaireListData: null,
        TrainerFrom: "",
        TrainingPayWay: "",
        remarkContent: null,
        currentItem: null,
        learningStatus: [],
        issuedStatus: []
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *changeTenant({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { activeKey: '' } })
            const { currentUser } = yield select(state => state.user);
            const { currentTenant } = yield select(state => state.trainingStaffList);
            const { canChangeList } = currentUser;
            if (canChangeList.length === 0) {
                return;
            }
            if (!currentTenant) {
                yield put({ type: 'updateState', payload: { currentTenant: canChangeList[0] } })
            } else if (payload) {
                const { id } = payload;
                if (id) {
                    const newCurrentTenant = canChangeList.find(item => item.id == id)
                    yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
                } else {
                    yield put({ type: 'updateState', payload: { currentTenant: canChangeList[0] } })
                }
            }
            yield put({ type: 'getTenantTrainerModuleList' })
        },

        *getTenantTrainerModuleList({ payload }, { call, put, select }) {
            const { currentTenant, activeKey } = yield select(state => state.trainingStaffList)
            const { id } = currentTenant || {}
            const res = yield call(GetTenantTrainerModuleList, { TenantId: id })
            if (res.response.ok) {
                const newActiveKey = activeKey || (res.data && res.data.length > 0 ? res.data[0].trainingModule && res.data[0].trainingModule.id : '')
                yield put({ type: 'updateState', payload: { activeKey: newActiveKey, tabsData: res.data } })
                if (res.data && res.data.length > 0) {
                    yield put({ type: 'getTrainerList' })
                }
            }
        },

        *getTrainerList({ payload }, { call, put, select }) {
            const { activeKey, name, learningState, qualifications, pagination, currentTenant, TrainerFrom, TrainingPayWay, learningStatus, issuedStatus } = yield select(state => state.trainingStaffList)
            const { current, pageSize } = pagination
            const params = {
                TenantId: currentTenant && currentTenant.id,
                Name: name,
                // Issued: qualifications,
                // Status: learningState,
                TrainingModuleId: activeKey,
                SkipCount: (current - 1) * pageSize,
                MaxResultCount: pageSize,
                TrainerFrom,
                TrainingPayWay,
                TrainerTrainingModuleStatuses: learningStatus,
                CertificateStatuses: issuedStatus
            }
            const res = yield call(GetTrainerList, params)
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { list: res.data.items, pagination: { ...pagination, total: res.data.totalCount } } })
            }
        },

        *onsearch({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({ type: 'updateState', payload: { pagination } })
            yield put({ type: 'getTenantTrainerModuleList' })
        },

        *changeTable({ payload: data }, { call, put, select }) {
            const { pagination, filters = {} } = data

            const isActiveFilter = Object.keys(filters).length > 0
            const currentPagination = isActiveFilter ? defaultPagination : pagination

            if (isActiveFilter) {
                const { issuedStatus = [], learningState = [] } = filters
                yield put({
                    type: 'updateState',
                    payload: {
                        issuedStatus,
                        learningStatus: learningState
                    }
                })
            }

            yield put({
                type: 'updateState',
                payload: {
                    pagination: currentPagination
                },
            })
            yield put({ type: 'getTenantTrainerModuleList' })
        },

        *editRemark({ payload }, { call, put, select }) {
            const { remark } = payload || {};
            var content = { ...remarkContentMenu }

            content.Properties[0].Value = remark

            yield put({ type: 'updateState', payload: { remarkModalVisible: true, remarkContent: content, currentItem: payload } })
        },


        *editRemarkSubmit({ payload }, { call, put, select }) {
            const { currentItem } = yield select(state => state.trainingStaffList);
            const { id } = currentItem || {};

            const res = yield call(PutTrainerRemark, { ...payload }, { id });

            if (res.response.ok) {
                yield put({ type: 'getTenantTrainerModuleList' })
            } else {
                const { error } = res.data
                const { message: msg } = error || {}
                message.error(msg);
            }

            yield put({ type: 'updateState', payload: { remarkModalVisible: false } })
        },

        *onclear({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
            }
            yield put({
                type: 'updateState',
                payload: {
                    pagination,
                    name: '',
                    learningState: '',
                    qualifications: '',
                    TrainerFrom: '',
                    TrainingPayWay: '',
                    issuedStatus: [],
                    learningStatus: []
                }
            })
            yield put({ type: 'getTenantTrainerModuleList' })
        },

        *exportExcel({ payload }, { call, put, select }) {
            const hide = message.loading('导出列表中，请稍等...', 0);

            const { activeKey, name, currentTenant, TrainerFrom, TrainingPayWay, learningStatus, issuedStatus, tabsData } = yield select(state => state.trainingStaffList)

            const { id, displayName } = currentTenant || {}

            const currentData = tabsData.find(item => item.trainingModule.id === activeKey)
            const { trainingModule } = currentData || {}
            const { displayName: moduleDisplayName } = trainingModule || {}

            const params = {
                TenantId: id,
                Name: name,
                TrainingModuleId: activeKey,
                TrainerFrom,
                TrainingPayWay,
                TrainerTrainingModuleStatuses: learningStatus,
                CertificateStatuses: issuedStatus
            }

            const res = yield call(PostExportPostTrainerList, params);
            if (res.response.ok) {
                const { response } = res;
                response.blob().then(blob => {
                download(blob, `培训人员管理-${displayName}-${moduleDisplayName} ${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
                })
            }

            setTimeout(hide, 0)
        },

        *showSelectItem({ payload }, { call, put, select }) {
            const { record, type } = payload
            switch (type) {
                case "renewal":
                    yield put({ type: 'updateState', payload: { content: contentMenu, selectItem: record, renewalModalVisible: true, type } })
                    break
                case "issue":
                    yield put({ type: 'updateState', payload: { content: awardContentMenu, selectItem: record, autoGenerationCertModalVisible: true, type } })
                    break
                case "reissue":
                    // matchDynamicForm(awardContentMenu, record)
                    yield put({ type: 'updateState', payload: { content: awardContentMenu, selectItem: record, autoGenerationCertModalVisible: true, type } })
                    break
            }

        },

        *postStopOrStart({ payload }, { call, put, select }) {
            const res = yield call(PostStopOrStart, { trainerTrainingModuleId: payload.id })
            if (res.response.ok) {
                yield put({ type: 'getTenantTrainerModuleList' })
            }
        },

        *onSubmit({ payload }, { call, put, select }) {
            const { tabsData, activeKey, selectItem } = yield select(state => state.trainingStaffList)
            const { id, trainerId } = selectItem || {}
            const { record, type } = payload
            const { certifier, credentialsCode, passTime, startEndTime, trainerName, endDate, credential } = record || {}
            let res = null
            let params = null
            switch (type) {
                case "renewal":
                    params = { trainerTrainingModuleId: id, endDate }
                    res = yield call(PostEndDate, params);
                    break
                case "issue":
                case "reissue":
                    params = {
                        trainerId,
                        trainerTrainingModuleId: id,
                        trainingModuleId: activeKey,
                        credentialsCode,
                        trainerName,
                        certifier,
                        passTime,
                        startTime: startEndTime[0],
                        endTime: startEndTime[1],
                        credential
                    }
                    res = yield call(PostIssueCertificate, params)
                    break
            }
            if (res && res.response.ok) {
                switch (type) {
                    case "renewal":
                        message.success('续期成功')
                        break
                    case "issue":
                    case "reissue":
                        message.success('颁发成功')
                        break
                }
                yield put({ type: 'updateState', payload: { renewalModalVisible: false, autoGenerationCertModalVisible: false } })
                yield put({ type: 'getTenantTrainerModuleList' })
            } else {
                const { error } = res.data
                const { message: messageInfo } = error || {}
                messageInfo && message.error(messageInfo)
            }
        },

        // 获取问卷内容
        *getQuestionDetail({ payload }, { call, put, select }) {
            const { supervisionTabActiveKey } = yield select(state => state.trainingStaffList)
            const questionDetailRes = yield call(GetQuestionnaire, { id: payload })
            if (questionDetailRes.response.ok) {
                const currentIdName = supervisionTabActiveKey === "TrainerSummary" ? "currentTrainerSummaryId" : "currentSupervisorId"
                yield put({
                    type: "updateState",
                    payload: {
                        supervisionQuestionDetail: questionDetailRes.data,
                        [currentIdName]: payload
                    }
                })
            }
        },

        // 获取训练师小结
        *getTrainerSummary({ payload }, { call, put, select }) {
            const { selectItem, currentTrainerSummaryId } = yield select(state => state.trainingStaffList)
            const { trainerId } = selectItem || {}
            const trainerSummaryListRes = yield call(GetTrainerSummaryList, { UserId: trainerId })
            if (trainerSummaryListRes.response.ok) {
                const data = trainerSummaryListRes.data
                const { items } = data || {}
                const { items: subItems } = items || {}
                const id = currentTrainerSummaryId ? currentTrainerSummaryId : (subItems && subItems.length > 0 ? subItems[0].id : "")
                yield put({
                    type: "updateState",
                    payload: {
                        questionnaireListData: data
                    }
                })
                if (id) {
                    yield put({
                        type: "getQuestionDetail",
                        payload: id
                    })
                }
            }
        },

        // 获取督导师跟踪表
        *getSupervisor({ payload }, { call, put, select }) {
            const { selectItem, currentSupervisorId } = yield select(state => state.trainingStaffList)
            const { trainerId } = selectItem || {}
            const supervisorListRes = yield call(GetSupervisorSSummary, { UserId: trainerId })
            if (supervisorListRes.response.ok) {
                const data = supervisorListRes.data
                const { items } = data || {}
                const { items: subItems } = items || {}
                const id = currentSupervisorId ? currentSupervisorId : (subItems && subItems.length > 0 ? subItems[0].id : "")
                yield put({
                    type: "updateState",
                    payload: {
                        questionnaireListData: data
                    }
                })
                if (id) {
                    yield put({
                        type: "getQuestionDetail",
                        payload: id
                    })
                }
            }
        },

        // 获取督导回顾小节
        *getSupervisionReview({ payload }, { call, put, select }) {
            const { selectItem, currentSupervisionReviewId } = yield select(state => state.trainingStaffList)
            const { trainerId } = selectItem || {}
            const supervisorListRes = yield call(GetSupervisorReviewList, { UserId: trainerId })
            if (supervisorListRes.response.ok) {
                const data = supervisorListRes.data
                const { items } = data || {}
                const { items: subItems } = items || {}
                const id = subItems && subItems.length > 0 ? subItems[0].id : null
                yield put({
                    type: "updateState",
                        payload: {
                        questionnaireListData: data
                    }
                })
                if (id) {
                    yield put({
                        type: "getQuestionDetail",
                        payload: id
                    })
                }
            }
        },

        // 根据当前Tab Type获取不同的数据
        *getDataByDiffType({ payload }, { call, put, select }) {
            const { supervisionTabActiveKey } = yield select(state => state.trainingStaffList)
            if (supervisionTabActiveKey === "TrainerSummary") {
                yield put({ type: "getTrainerSummary" })
            } else if (supervisionTabActiveKey === "Supervisor") {
                yield put({ type: "getSupervisor" })
            }
        },

        // 展示见习督导记录
        *showSupervision({ payload }, { call, put, select }) {
            const { record } = payload
            yield put({
                type: "updateState",
                payload: {
                    selectItem: record,
                    showSupervisionVisible: true,
                    supervisionQuestionDetail: null,
                    currentSupervisorId: '',
                    currentTrainerSummaryId: ''
                }
            })
            yield put({ type: 'getDataByDiffType' })
        },

        // 切换见习督导记录Tab
        *onChangSupervisionTabs({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { supervisionTabActiveKey: payload, questionnaireListData: null, supervisionQuestionDetail: null } })
            yield put({ type: 'getDataByDiffType' })
        },

        // 展示见习督导跟踪表
        *eidtSupervision({ payload }, { call, put, select }) {
            const { selectItem } = yield select(state => state.trainingStaffList)
            const { trainerId } = selectItem || {}
            const supervisionRes = yield call(PostSupervisorSummary, { userId: trainerId })
            if (supervisionRes.response.ok) {
                yield put({ type: "updateState", payload: { supervisorQuestion: supervisionRes.data, eidtSupervisionVisible: true } })
            }
        },

        // 关闭编辑见习督导跟踪表
        *onCloseEidtSupervision({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { eidtSupervisionVisible: false } })
        },

        // 提交见习督导跟踪表
        *supervisorSubmit({ payload }, { call, put, select }) {
            const { supervisorQuestion } = yield select(state => state.trainingStaffList)
            const { id } = supervisorQuestion || {}
            const { answerContent, isComplete, finishedPage } = payload
            const params = {
                updateQuestionnaireDto: {
                    userQuestionnaireId: id,
                    answerContent,
                    isComplete,
                    finishedPage
                }
            }
            const res = yield call(PutSupervisorSummary, params)
            if (res.response.ok) {
                if (isComplete) {
                    message.success('提交成功')
                    yield put({ type: 'updateState', payload: { supervisorSubmitData: null, eidtSupervisionVisible: false, currentSupervisorId: '' } })
                    yield put({ type: 'getDataByDiffType' })
                }
            }
        },

        // 切换menuList
        *clickMenuItem({ payload }, { call, put, select }) {
            const { id } = payload || {}
            yield put({
                type: 'getQuestionDetail',
                payload: id
            })
        },

    },
    subscriptions: {
        // setupHistory({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/manage/trainingStaff') {
        //             dispatch({ type: 'changeTenant' })
        //         }
        //     })
        // },
    },
}
