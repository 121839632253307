import React from 'react'
import { Divider } from 'antd'
import ReportRichText from 'components/ReportRichText/ReportRichText'
import { IntlManager } from 'utils/helper'
import styles from '../../detail.less'


const CategoryContent = categoryInfo => {
  const { tags, modelDetails, trainingCategory } = categoryInfo
  const intl = IntlManager.MyIntl()

  return (
    <React.Fragment>
      <h2 className={styles.category}>{trainingCategory.name}</h2>
      {/* 五步循环法 */}
      <div className={styles.betweenItem}>
        <Divider type="vertical" className={styles.dividerColor} />
        <span className={styles.fiveSteps}>{intl('five_stepCycle')} : </span>
        {tags.map((value, index) => {
          return (
            <span key={index} className={styles.fiveSteps}>{` ${value.displayName}${
              index === tags.length - 1 ? '' : ' ,'
            }`}</span>
          )
        })}
      </div>

      {/* 详情说明 */}
      <div className={styles.betweenItem}>
        <Divider type="vertical" className={styles.dividerColor} />
        <span className={styles.infoTitle}>{intl('detailsDescription')} : </span>
      </div>
      <div className={styles.richText}>
        <ReportRichText content={modelDetails} />
      </div>
    </React.Fragment>
  )
}

export default CategoryContent
