import React from 'react'
import { Modal, Button } from 'antd'

const SendToGuardian = ({ visible, onCancel, onOk, loading }) => {
    return (
        <Modal
            title={<b>发送报告给家长</b>}
            open={visible}
            footer={null}
            onCancel={onCancel}
        >
            <div style={{ textAlign: 'center' }}>
                <div style={{fontWeight: 600}}>确定发送此报告给儿童的家长吗？</div>
                <div style={{ marginTop: '5px' }}>家长可在APP查看报告</div>
                <div style={{ marginTop: '30px' }}>
                    <Button onClick={onCancel} style={{ marginRight: '20px' }}>取消</Button>
                    <Button type="primary" onClick={onOk} loading={loading}>确定</Button>
                </div>
            </div>
        </Modal>
    )
}

export default SendToGuardian