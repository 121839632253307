import React, { useEffect } from 'react';
import { connect } from 'dva'
import { Card, Spin } from 'antd'
import { getProportionData } from 'cognitiveleap-core-us/utils/utils';
import { useIntl } from 'umi'
import style from './index.less';
import ShowDataAnalysis from 'components/ShowDataAnalysis'
import CardHead from 'components/CardHead';
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart'
import CommonPieTable from 'cognitiveleap-core-us/components/Charts/Common/CommonPieTable'
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import {
    childrenAgeDataSource,
    childrenGengerDataSource,
    tableColumns,
    childrenKeyIndicators,
    childrenLegend,
    childrenAgeTableColums
} from '../config';

const ChildrenAnalysis = ({ adminChildrenAnalysis, loading, dispatch }) => {

    const intl = useIntl()
    useEffect(() => {
        dispatch({ type: 'adminChildrenAnalysis/changeTenant' })
    }, [])

    const {
        keyIndicators,
        currentTag,
        datePickerIsOpen,
        dateMonthPickerData,
        dateYearPickerData,
        analysisData,
        currentTenant
    } = adminChildrenAnalysis;
    if (analysisData == null || keyIndicators == null) {
        return <Spin />
    }

    const { ageDistributionChart, genderChart, trend } = analysisData;
    const isLoadingAnalysis = loading.effects['adminChildrenAnalysis/loadAnalysisData'] || loading.effects['adminChildrenAnalysis/loadKeyIndicators'];

    const chartProps = {
        legendData: childrenLegend(intl),
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        dataSource: trend
    };

    const ageTotalNum = ageDistributionChart.length > 0 && ageDistributionChart.map(item => item.num).reduce((prev, cur) => {
        return prev + cur
    });

    const genderTotalNum = genderChart.length > 0 && genderChart.map(item => item.num).reduce((prev, cur) => {
        return prev + cur
    });

    const getChildrenKeyIndicators = () => {
        return childrenKeyIndicators(intl)
            .map(item => ({
                ...item,
                data: keyIndicators[item.field]
            }))
            .map(item => {
                const data = ageDistributionChart.find(ageItem => ageItem.age == item.age);
                if (data) {
                    return {
                        ...item,
                        number: data.num,
                        proportion: getProportionData(data.num, ageTotalNum),
                    }
                } else {
                    return {
                        ...item,
                        number: 0,
                        proportion: '0%',
                    }
                }
            })
    }

    childrenAgeDataSource(intl).forEach(item => {
        const data = ageDistributionChart.find(ageItem => ageItem.age == item.age);
        if (data) {
            item.number = data.num;
            item.proportion = getProportionData(data.num, ageTotalNum);
        } else {
            item.number = 0;
            item.proportion = '0%';
        }
    })

    childrenGengerDataSource(intl).forEach(item => {
        const data = genderChart.find(genderItem => genderItem.gender == item.gender);
        if (data) {
            item.number = data.num;
            item.proportion = getProportionData(data.num, genderTotalNum);
        } else {
            item.number = 0;
            item.proportion = '0%';
        }
    })

    const showChildrenAgeDataSource = childrenAgeDataSource(intl).map(item=>{
        const data = ageDistributionChart.find( ageItem => ageItem.age == item.age );
        return {
            ...item,
            number: data!==undefined?data.num:0,
            proportion: data!==undefined?getProportionData(data.num, ageTotalNum):'0%'
        }
    })

    const showChildrenGengerDataSource = childrenGengerDataSource(intl).map(item=>{
        const data = genderChart.find( genderItem => genderItem.gender == item.gender )
        return {
            ...item,
            number: data!==undefined?data.num:0,
            proportion: data!==undefined?getProportionData(data.num, genderTotalNum):'0%'
        }
    })

    const ageProps = {
        title: intl.formatMessage({ id: 'ageDistribution' }),
        color: ['#FFADD2', '#FFD591', '#D9F7BE', '#91D5FF', '#BA8FFA'],
        totalNum: ageTotalNum,
        columns: childrenAgeTableColums(intl),
        dataSource: showChildrenAgeDataSource,
        legendData: ['0-3', '3-5.5', '5.5-13', '13-18', '>=18']
    }

    const genderProps = {
        title: intl.formatMessage({ id: 'genderDistribution' }),
        color: ['#69C0FF', '#FFADD2', '#D9F7BE', '#E8E8E8'],
        totalNum: genderTotalNum,
        columns: tableColumns(intl),
        dataSource: showChildrenGengerDataSource,
        legendData: [intl.formatMessage({ id: 'male' }), intl.formatMessage({ id: 'female' }), intl.formatMessage({ id: 'unknown' }), intl.formatMessage({ id: 'other' })]
    }

    const changeTag = (tag, checked) => {
        dispatch({
            type: 'adminChildrenAnalysis/changeTag',
            payload: {
                key: tag.key
            }
        })
    }

    const handleOpenChange = () => {
        dispatch({
            type: 'adminChildrenAnalysis/updateState',
            payload: {
                datePickerIsOpen: !datePickerIsOpen
            }
        })
    }

    const handlePanelChange = (time) => {
        dispatch({
            type: 'adminChildrenAnalysis/handlePanelChange',
            payload: {
                time
            }
        })
    }

    const analysisChoiceDateProps = {
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        datePickerIsOpen,
        changeTag,
        handleOpenChange,
        handlePanelChange
    }

    return (
        <Card
            title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'childUserAnalysis' })}</b>}
            extra={
                <AnalysisTenantSelect
                    showAll={true}
                    currentTenant={currentTenant}
                    onChange={(e) => {
                        dispatch({ type: 'adminChildrenAnalysis/changeTenant', payload: { id: e } })
                    }}
                />
            }
            bordered={false}
        >
            <div className={style.childrenAnalysis}>

                <div className={style.keyIndicators}>
                    <CardHead title={intl.formatMessage({ id: 'keyIndicators' })}  />
                    <ShowDataAnalysis keyIndicators={getChildrenKeyIndicators()} />
                </div>
                <div className={style.trendAnalysis}>
                    <CardHead title={intl.formatMessage({ id: 'trendAnalysis' })}  />
                    <AnalysisChoiceDate {...analysisChoiceDateProps} />
                    <div className={style.trendAnalysisContent}>
                        <Spin spinning={isLoadingAnalysis}>
                            <CommonAnalysisLineChart {...chartProps} />
                        </Spin>
                        <div className={style.trendAnalysisContentChart}><CommonPieTable {...ageProps} /></div>
                        <div className={style.trendAnalysisContentChart}><CommonPieTable {...genderProps} /></div>
                    </div>
                </div>

            </div>
        </Card>
    )
}

export default connect(({ adminChildrenAnalysis, loading }) => ({ adminChildrenAnalysis, loading }))(ChildrenAnalysis);