import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Card, Tag, Icon, Row, Col, Modal, message, Spin } from 'antd'
import { history } from 'umi';
import styles from './index.less'
import CourseCard from 'components/CourseCard'
import activityImg from 'assets/icon_fill.png';
import PaginationCom from 'components/Pagination/Pagination'

const UserOfflinePlanDetail = ({ adminuserOfflinePlanDetail, dispatch, location, loading, currentUser }) => {


    const { adminRoles, current } = currentUser
    const isAdmin = adminRoles.length > 0 && current === null
    useEffect(() => {
        dispatch({ type: 'adminuserOfflinePlanDetail/pageLoading', payload: location.query })
    }, [])

    let currentNum = 0
    let totalNum = 0
    const { planItems, pagination } = adminuserOfflinePlanDetail

    if (planItems.length > 0) {
        const { userOfflineTrainingPlan } = planItems[0]
        currentNum = userOfflineTrainingPlan != null ? userOfflineTrainingPlan.currentNum : 0
        totalNum = userOfflineTrainingPlan != null ? userOfflineTrainingPlan.totalNum : 0
    }

    const paginationComProps = {
        onPageChange: (page, pageSize) => {
            const _pagination = {
                ...pagination,
                current: page,
                pageSize,
            }
            dispatch({ type: 'adminuserOfflinePlanDetail/changeTable', payload: { pagination: _pagination } })
        },
        ...pagination,
    }

    return (
        <Spin spinning={loading.effects['adminuserOfflinePlanDetail/loadList']}>
            <div className={styles.userOfflinePlanDetail}>
                <div className={styles.top}>
                    <Button onClick={() => history.goBack()}>返回</Button>
                </div>
                <div className={styles.content}>
                    <Card
                        title={<b>{`进度: ${currentNum}/${totalNum}`}</b>}
                        bordered={false}
                        className={styles.contentCard}
                    >
                        <div className={styles.contentCardGrid}>
                            {
                                planItems.length > 0 &&
                                planItems.map((item, index) => {
                                    const { num, userOfflineTrainingPlan, iLs, endTime } = item
                                    const courseCardProps = {
                                        isFixed: true,
                                        isShowApp: true,
                                        isAdmin,
                                        allData: item,
                                        isCurrent: num === userOfflineTrainingPlan.currentNum,
                                        hasOfflinePlanWritePermission: false,
                                        from: 'userOfflinePlanDetail',
                                        hasOfflinePlanReadPermission: false,
                                        endTime,
                                        onSwitchCourseType(data) {
                                            if(endTime!==null){
                                                const {isApp} = data
                                                Modal.confirm({
                                                    title: `是否要将该课程切换为${isApp?'线上':'线下'}？`,
                                                    content: <a onClick = {()=>{
                                                        const postData = {...data, notice: true}
                                                        dispatch({ type: 'adminuserOfflinePlanDetail/switchCourseType', payload: postData })
                                                        Modal.destroyAll()
                                                    }}>如果需要发送消息的话请点击这里</a>,
                                                    okText: '是',
                                                    cancelText: '否',
                                                    onOk() {
                                                        dispatch({ type: 'adminuserOfflinePlanDetail/switchCourseType', payload: data })
                                                    }
                                                })
                                            }
                                            else{
                                                message.error('只能编辑已经完成的课程')
                                            }
                                        }
                                    }
                                    return (
                                        <CourseCard {...courseCardProps} key={index} />
                                    )
                                })
                            }
                        </div>
                    </Card>
                </div>
                <div style={{ marginLeft: '16px' }}>
                    <PaginationCom {...paginationComProps} />
                </div>
            </div>
        </Spin>
    )
}

export default connect(({user, adminuserOfflinePlanDetail, loading }) => ({ currentUser: user.currentUser, adminuserOfflinePlanDetail, loading }))(UserOfflinePlanDetail)