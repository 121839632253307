import React, { useEffect } from 'react'
import { Card, Button } from 'antd'
import { connect, history } from 'umi'
import { IntlManager } from 'utils/helper';
import AccountSetting from 'components/AccountSetting'
import styles from './index.less'

const GroupDetail = ({ groupDetail, dispatch, location }) => {
    const intl = IntlManager.MyIntl()

    useEffect(() => {
        dispatch({ type: 'groupDetail/query', payload: location.query })
    }, [])

    const { userInfo, trainerDetail } = groupDetail
    const accountSettingProps = {
        trainerDetail,
        userInfo,
        canEditResumes: false
    }
    const goBack = () => {
        history.goBack()
    }
    const { name } = userInfo || {}
    const title = (
        <div>
            <Button onClick={goBack}>{intl('back')}</Button>
            <b style={{ marginLeft: '10px', fontSize: '20px' }}>
                {intl('WhoAccountSettings', { name })}
            </b>
        </div>
    )
    return (
        <Card bordered={false} title={title}>
            <div className={styles.accountSetting}>
                <AccountSetting {...accountSettingProps} />
            </div>
        </Card>
    )
}

export default connect(({ groupDetail }) => ({ groupDetail }))(GroupDetail)