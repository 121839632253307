import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import BottomPageNum from './components/BottomPageNum';
import HeaderLine from './components/HeaderLine';
import { useIntl } from 'umi';

const TableOfContents = () => {
  const intl = useIntl();

  const contentList = [
    {
      current: '3',
      content: intl.formatMessage({ id: 'TableOfContents.information' }),
    },
    {
      current: '4',
      content: intl.formatMessage({ id: 'TableOfContents.summary' }),
    },
    {
      current: '6',
      content: intl.formatMessage({ id: 'TableOfContents.overall' }),
    },
    {
      current: '7',
      content: intl.formatMessage({ id: 'TableOfContents.cognitive' }),
    },
    {
      current: '10',
      content: intl.formatMessage({ id: 'TableOfContents.mental' }),
    },
    {
      current: '12',
      content: intl.formatMessage({ id: 'TableOfContents.real' }),
    },
    {
      current: '14',
      content: intl.formatMessage({ id: 'TableOfContents.motor' }),
    },
    {
      current: '15',
      content: intl.formatMessage({ id: 'TableOfContents.tracking' }),
    },
    {
      current: '16',
      content: intl.formatMessage({ id: 'TableOfContents.movement' }),
    },
    {
      current: '17',
      content: intl.formatMessage({ id: 'TableOfContents.distance' }),
    },
    {
      current: '18',
      content: intl.formatMessage({ id: 'TableOfContents.interpretation' }),
    },
  ];

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.tableOfContents}>
        <HeaderLine color="#48C969" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'TableOfContents.centerTitle' })}</div>
          <div className={styles.showContentList}>
            {contentList.map((item) => {
              const { current, content } = item || {};
              return (
                <div key={current} className={styles.contentItem}>
                  <div className={styles.itemCurrent}>{current}</div>
                  <div className={styles.connectHr}></div>
                  <div className={styles.itemContent}>{content}</div>
                </div>
              );
            })}
          </div>
        </div>
        <BottomPageNum num={5} />
        <BottomDes color="#48C969" />
      </div>
    </div>
  );
};

export default TableOfContents;
