import React, { useEffect } from 'react'
import moment from 'moment'
import { Card, Drawer, Button, Modal, Spin, Collapse, Progress, Tag, Divider, Empty, Space, Tabs, Dropdown, Menu, message } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined, PlusOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import { resolveSurveyData, getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import PersonalCenterCard from 'components/PersonalCenterCard'
import Question from 'components/Question'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import { getScore } from 'utils/utils'
import { connect, history } from 'umi'
import styles from './index.less'
import lockImgUrl from 'assets/icon_locked.png'
import iconBookImgUrl from 'assets/icon_book.png'
import iconPassGrayImgUrl from 'assets/icon_pass_gray.png'
import passImgUrl from 'assets/pass.png'
import unpassImgUrl from 'assets/unpass.png'
import iconCheckCircle from 'assets/icon_Check-Circle.png'
import iconWarning from 'assets/icon_Warning.png'

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { CheckableTag } = Tag;

const TrainingStaffDetail = ({ admintrainingStaffDetail, dispatch, loading, location }) => {

    useEffect(() => {
        dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { moduleList: [], tabkey: '' } })
        dispatch({ type: 'admintrainingStaffDetail/query', payload: location.query })

        return () => {
            dispatch({ type: 'admintrainingStaffDetail/clear' })
        }
    }, [])

    const { drawerVisible,
        moduleList,
        tabkey,
        questionData,
        userInfo,
        moduleDetail,
        content,
        awardInfo,
        awardQualificationsVisible,
        itemDrawerVisible,
        activeCollapseKey,
        activeContent,
        activeClassInfo,
        showQuestionAnswerData,
        correntRateAndResult,
        showAnswerVisible,
        // medalInfo,
        awardModuleList,
        trainerDetail,
        showSupervisionVisible,
        supervisionTabActiveKey,
        eidtSupervisionVisible,
        supervisorQuestion,
        questionnaireListData,
        supervisionQuestionDetail,
        currentTrainerSummaryItem,
        currentSupervisorItem,
        supervisorSubmitData,
        supervisorIsFinshed
    } = admintrainingStaffDetail
    const { name } = userInfo || {}
    const { finishTime, questionnaireAndAnswer } = questionData || {}
    const { questionnaire, answer } = questionnaireAndAnswer || {}
    const { answerContent } = answer || {}
    const { jsonString, correctRate } = questionnaire || {}

    // const { course } = activeClassInfo || {}
    const { stepIndex = [], contents = [], title, passCount, length } = JSON.parse(JSON.stringify(activeClassInfo)) || {}
    const { id: activeId } = activeContent || {}
    const { data, currentContent } = showQuestionAnswerData || {}
    const { questionnaireAndAnswer: activeQuestionnaireAndAnswer } = data || {}
    const { answer: activeAnswer, questionnaire: activeQuestionnaire } = activeQuestionnaireAndAnswer || {}
    const { questionArr, correntRate, isPass: correntRateAndResultIspass, correntCount } = correntRateAndResult || {}

    // 给stepIndex添加真正得content
    stepIndex.forEach((item, index) => {
        item.content = []
        item.contentId.forEach(id => {
            const content = contents.find(item => item.id === id)
            if (content) item.content.push(content)
        })
    })

    const getAnswerDom = () => {
        const { questionArr = [], } = getCorrentRateAndResult(jsonString, answerContent, correctRate) || {}
        const showSurveyProps = {
            contents: questionArr,
            choice: answer && answer.answerContent ? answer.answerContent : null,
            showIcon: false
        }
        return <ShowSurvey {...showSurveyProps} />
    }

    const { items } = questionnaireListData || {}
    const { items: subItems } = items || {}

    const currentId = supervisionTabActiveKey === "TrainerSummary" ? currentTrainerSummaryItem?.id : currentSupervisorItem?.id
    const currentQuestion = subItems ? subItems.find(item => item.id === currentId) : null

    // 填写问卷
    const { questionnaireAndAnswer: supervisorQuestionnaireAndAnswer } = supervisorQuestion || {}
    const { questionnaire: supervisorQuestionnaire, answer: supervisorAnswer } = supervisorQuestionnaireAndAnswer || {}
    const { jsonString: supervisorJsonString } = supervisorQuestionnaire || {}
    const { answerContent: supervisorAnswerContent } = supervisorSubmitData || {}

    // 展示问卷
    const { questionnaireAndAnswer: supervisionDetaiQuestionnaireAndAnswerl } = supervisionQuestionDetail || {}
    const { answer: supervisionDetaiAnswer, questionnaire: supervisionDetaiQuestionnaire } = supervisionDetaiQuestionnaireAndAnswerl || {}
    const { jsonString: supervisionDetaiJsonString, correctRate: supervisionDetaiCorrectRate } = supervisionDetaiQuestionnaire || {}
    const { answerContent: supervisionDetaiAnswerContent } = supervisionDetaiAnswer || {}

    let supervisionQuestionArr = [], supervisionCorrentRateAndResult = null
    if (supervisionQuestionDetail) {
        supervisionCorrentRateAndResult = getCorrentRateAndResult(supervisionDetaiJsonString, supervisionDetaiAnswerContent, supervisionDetaiCorrectRate)
        supervisionQuestionArr = supervisionCorrentRateAndResult && supervisionCorrentRateAndResult.questionArr
    } else {
        supervisionQuestionArr = []
    }

    const personalCenterCardProps = {
        userInfo,
        tabkey,
        awardModuleList,
        tabList: moduleList,
        detail: moduleDetail,
        loading: loading.models.admintrainingStaffDetail,
        isFininshed: finishTime,
        roleType: 'admin',
        trainerDetail,
        onClickItem(item) {
            dispatch({ type: 'admintrainingStaffDetail/onClickItem', payload: item })
        },
        onChangeTabs(key) {
            dispatch({ type: 'admintrainingStaffDetail/onChangeTabs', payload: key })
            dispatch({ type: 'admintrainingStaffDetail/getModuleDetail', payload: key })
        },
        showTrainingApplication() {
            if (questionnaireAndAnswer) {
                dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { drawerVisible: true } })
            } else {
                message.warning('培训申请表未填写')
            }
        },
        awardQualifications(data) {
            dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { awardInfo: data, awardQualificationsVisible: true } })
        },
        showSupervision() {
            dispatch({ type: 'admintrainingStaffDetail/showSupervision' })
        }
    }

    const modalProps = {
        title: `颁发${awardInfo && awardInfo.displayName}证书`,
        visible: awardQualificationsVisible,
        content,
        onCancel() {
            dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { awardQualificationsVisible: false } })
        },
        onOk(value) {
            Modal.confirm({
                title: <span style={{ color: 'red' }}>确定为{name || ''}颁发{awardInfo && awardInfo.displayName}证书吗？</span>,
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    dispatch({ type: 'admintrainingStaffDetail/awardQualifications', payload: value })
                }
            })
        }
    }

    const onChangeCollapse = (value) => {
        dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { activeCollapseKey: value } })
    }

    const renderContent = (item) => {
        const { name, timeConsuming, userCourseDetail, id, isLocked } = item || {}
        const { isFinish, correctRate, isPass, times } = userCourseDetail || {}
        return (
            <div className={styles.renderContent} key={id}>
                <div className={styles.contentTitle}>
                    {
                        !(isFinish && isPass) && activeId && activeId === id && <Tag color="#108ee9">正在学</Tag>
                    }
                    <span className={styles.contentTitleContent}>{name}</span>
                </div>
                <div className={styles.renderContentDetail}>
                    {
                        isLocked ?
                            <React.Fragment>
                                <div className={styles.renderContentDetailLocked}><img src={lockImgUrl} /></div>
                            </React.Fragment>
                            :
                            <div className={styles.renderContentDetailUnLocked}>
                                <div className={styles.left}>
                                    <img src={iconBookImgUrl} /><span>{isFinish ? '已学完' : '未学完'}</span>
                                </div>
                                <div className={styles.right}>
                                    {
                                        correctRate !== -1 && userCourseDetail &&
                                        <div>
                                            <img src={iconPassGrayImgUrl} />
                                            <span>{isPass ? '已通过' : '未通过'}</span>
                                            <Divider type="vertical" />
                                            <span>正确率 {correctRate && `${correctRate}%` || '0%'}</span>
                                            <Divider type="vertical" />
                                            <span>共提交 {times || 0} 次</span>
                                            {
                                                times > 0 &&
                                                <React.Fragment>
                                                    <Divider type="vertical" />
                                                    <a onClick={() => dispatch({ type: 'admintrainingStaffDetail/createQuestionnaire', payload: item })}>看结果</a>
                                                </React.Fragment>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                    }
                </div>
                <div className={styles.renderContentIcon}>
                    {
                        isFinish && isPass ?
                            <img src={iconCheckCircle} />
                            :
                            activeId && activeId === id ?
                                <img src={iconWarning} />
                                :
                                <span></span>
                    }
                </div>
            </div>
        )
    }

    const showSurveyProps = {
        contents: questionArr,
        choice: activeAnswer && activeAnswer.answerContent ? JSON.parse(JSON.stringify(activeAnswer && activeAnswer.answerContent)) : {}
    }

    const onChangSupervisionTabs = (value) => {
        dispatch({ type: 'admintrainingStaffDetail/onChangSupervisionTabs', payload: value })
    }

    // 见习督导跟踪表
    const supervisorOnComplete = ({ answerContent, isComplete, finishedPage }) => {
        if (isComplete) {
            Modal.confirm({
                title: <div style={{ color: 'red' }}>确定提交吗？</div>,
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { supervisorIsFinshed: true } })
                    dispatch({ type: 'admintrainingStaffDetail/supervisorSubmit', payload: { answerContent, isComplete, finishedPage } })
                }
            })
            return
        } else {
            dispatch({ type: 'admintrainingStaffDetail/supervisorSubmit', payload: { answerContent, isComplete, finishedPage } })
        }
    }

    const supervisorValueChange = ({ answerContent, isComplete, finishedPage, needSubmit }) => {
        dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { supervisorSubmitData: { answerContent, isComplete, finishedPage } } })
        if (needSubmit) {
            supervisorOnComplete({ answerContent, isComplete, finishedPage })
        }
    }

    const supervisorOnClose = () => {
        if (!supervisorIsFinshed && supervisorSubmitData) {
            supervisorOnComplete(supervisorSubmitData)
        }
        dispatch({ type: 'admintrainingStaffDetail/onCloseEidtSupervision' })
    }

    const supervisorQuestionProps = {
        content: supervisorJsonString ? supervisorJsonString : '',
        answerContent: supervisorAnswerContent || '',
        answer: supervisorAnswer,
        onComplete: supervisorOnComplete,
        onValueChange: supervisorValueChange
    }

    const showSupervisorSurveyProps = {
        contents: supervisionQuestionArr,
        choice: supervisionDetaiAnswerContent ? JSON.parse(JSON.stringify(supervisionDetaiAnswerContent)) : {},
        showIcon: false
    }

    const menuList = subItems && subItems.length > 0 ? (
        <Menu>
            {
                subItems.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={() => dispatch({ type: 'admintrainingStaffDetail/clickMenuItem', payload: item })}>{moment(item.finishTime).format("YYYY/MM/DD HH:mm")}</Menu.Item>
                    )
                })
            }
        </Menu>
    ) : null

    return (
        // <Spin spinning={loading.models.admintrainingStaffDetail}>
        <div className={styles.trainingStaffDetail}>
            <Card
                bordered={false}
                title={
                    <div className={styles.trainingStaffDetailTitle}>
                        <Button onClick={() => history.goBack()}>返回</Button>
                        <b className={styles.title}>{`${name || ''}的培训详情`}</b>
                    </div>
                }
            >
                <div className={styles.trainingStaffDetailContent}>
                    <PersonalCenterCard
                        {...personalCenterCardProps}
                    />
                </div>
                <MagicFormModal {...modalProps} />
                <Drawer
                    title={
                        <React.Fragment>
                            {drawerVisible && <b style={{ fontSize: '20px' }}>培训申请表</b>}
                            {itemDrawerVisible && (
                                <div>
                                    <b style={{ fontSize: '20px' }}>{title}</b>
                                    {
                                        contents && contents.length > 0 &&
                                        <div style={{ width: '400px', marginTop: '15px' }}>
                                            <Progress
                                                percent={length > 0 ? (passCount / length) * 100 : 0}
                                                format={(percent) => `${passCount}/${length}`}
                                                strokeColor={length > 0 && passCount === length ? '#3C84FF' : '#F96B4B'}
                                            />
                                        </div>
                                    }
                                </div>
                            )}
                        </React.Fragment>
                    }
                    open={drawerVisible || itemDrawerVisible}
                    width={700}
                    onClose={() => dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { drawerVisible: false, itemDrawerVisible: false } })}
                >
                    {
                        drawerVisible && getAnswerDom()
                    }
                    {
                        itemDrawerVisible &&
                        <React.Fragment>
                            {
                                stepIndex && stepIndex.length > 0 ?
                                    <Collapse activeKey={activeCollapseKey} onChange={onChangeCollapse}>
                                        {
                                            stepIndex.map(item => {
                                                const { title, content, index } = item || {}
                                                return (
                                                    <Panel header={title} key={index}>
                                                        {
                                                            content && content.map(item => {
                                                                return renderContent(item)
                                                            })
                                                        }
                                                    </Panel>
                                                )
                                            })
                                        }
                                    </Collapse>
                                    :
                                    contents && contents.length > 0
                                        ?
                                        <div>
                                            {
                                                contents && contents.map(item => {
                                                    return renderContent(item)
                                                })
                                            }
                                        </div>
                                        :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }
                        </React.Fragment>
                    }
                </Drawer>
                <Drawer
                    open={showAnswerVisible}
                    width={700}
                    onClose={() => dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { showAnswerVisible: false } })}
                    title={
                        <div className={styles.drawerTitle}>
                            <div><b style={{ fontSize: '20px' }}>{activeQuestionnaire && activeQuestionnaire.displayName}</b></div>
                            <Space split={<Divider type="vertical" />}>
                                <span>{`共 ${questionArr && questionArr.length || 0} 题`}</span>
                                <span>{`正确 ${correntCount || 0} 题`}</span>
                                <span>{`错误 ${(questionArr && questionArr.length - correntCount) || 0} 题`}</span>
                                <span>{`共提交 ${currentContent && currentContent.userCourseDetail && currentContent && currentContent.userCourseDetail.times || 0} 次`}</span>
                            </Space>
                            <div className={styles.ispassImg}>
                                {
                                    correntRateAndResultIspass ?
                                        <React.Fragment>
                                            <img src={passImgUrl} />
                                            <div className={styles.passRote}>{`正确率${correntRate}%`}</div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <img src={unpassImgUrl} />
                                            <div className={styles.unpassRote}>{`正确率${correntRate}%`}</div>
                                        </React.Fragment>
                                }
                            </div>
                        </div>
                    }
                >
                    {showAnswerVisible && <ShowSurvey {...showSurveyProps} />}
                </Drawer>

                {/* 展示见习督导记录 */}
                <Drawer
                    title={<b style={{ fontSize: '20px' }}>见习督导小结</b>}
                    width={700}
                    open={showSupervisionVisible}
                    onClose={() => dispatch({ type: 'admintrainingStaffDetail/updateState', payload: { showSupervisionVisible: false } })}
                >
                    <Spin spinning={loading.models.admintrainingStaffDetail}>
                        <Tabs activeKey={supervisionTabActiveKey} onChange={onChangSupervisionTabs}>
                            <TabPane tab="督导师跟踪表" key="Supervisor"></TabPane>
                            <TabPane tab="训练师小结" key="TrainerSummary"></TabPane>
                        </Tabs>
                        <div className={styles.supervisionEditBtn}>
                            <div className={styles.supervisionEditBtnLeft}>
                                {
                                    supervisionTabActiveKey === "Supervisor" && subItems && subItems.length > 0 &&
                                    <span>分数：{getScore(supervisionCorrentRateAndResult, supervisionDetaiAnswer)}</span>
                                }
                            </div>
                            <div className={styles.supervisionEditBtnRight}>
                                {/* admin仅查看见习督导跟踪表 */}
                                {/* {
                                        supervisionTabActiveKey === "Supervisor" &&currentSupervisorItem!==null&&
                                        <div style={{ marginRight: "20px" }}>
                                            <Button icon={<EditOutlined />} onClick={() => dispatch({ type: 'admintrainingStaffDetail/editSupervision' })}>编辑</Button>
                                        </div>
                                    }
                                    {
                                        supervisionTabActiveKey === "Supervisor" &&
                                        <div style={{ marginRight: "20px" }}>
                                            <Button icon={<PlusOutlined />} onClick={() => dispatch({ type: 'admintrainingStaffDetail/addSupervision' })}>见习督导跟踪表</Button>
                                        </div>
                                    } */}
                                {
                                    subItems && subItems.length > 0 &&
                                    <div style={{ marginRight: "20px" }}>
                                        <Dropdown overlay={menuList}>
                                            <a>{currentQuestion ? moment(currentQuestion.finishTime).local().format("YYYY/MM/DD HH:mm") : ''}</a>
                                        </Dropdown>
                                        <DownOutlined style={{ marginLeft: "5px" }} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: '25px' }}>
                            <ShowSurvey {...showSupervisorSurveyProps} />
                        </div>
                    </Spin>
                </Drawer>

                {/* 填写见习督导小结 */}
                {
                    supervisorQuestion && <Drawer
                        title={<b style={{ fontSize: '20px' }}>见习督导跟踪表</b>}
                        width={700}
                        open={eidtSupervisionVisible}
                        onClose={supervisorOnClose}
                    >
                        <Question {...supervisorQuestionProps} />
                    </Drawer>
                }

            </Card>
        </div>
        // </Spin>
    )
}

export default connect(({ admintrainingStaffDetail, loading }) => ({ admintrainingStaffDetail, loading }))(TrainingStaffDetail)