import React from 'react'
import { Row, Col, Spin } from 'antd'
import styles from './index.less'

const ThreePage = ({ totalShow, overViewLoading }) => {
    // 五、测评总结
    const attentionPerformance = totalShow ? (totalShow.AttentionPerformanceIndex * 100) : 100
    const motionPerformance = totalShow ? (totalShow.MotionPerformanceIndex * 100) : 100

    // 总结文字
    const getDes = (type, value) => {
        if (type === 'attention') {
            switch (true) {
                case (value > 0 && value <= 33):
                    return '在测评中容易被干扰，注意力无法集中在任务上，漏击或错击次数较多。'
                case (value > 33 && value <= 67):
                    return '在测评中较容易被干扰，注意力无法长时间集中在任务上，较容易出现漏击和错击。'
                case (value > 67 && value <= 100):
                    return '在测评中能够持续专注在任务上，正确击中次数多，反应速度快。'
                default:
                    return ''
            }
        } else if (type === 'motion') {
            switch (true) {
                case (value > 0 && value <= 33):
                    return '动作多，动作范围幅度大。'
                case (value > 33 && value <= 67):
                    return '偶尔有动作，动作范围较小。'
                case (value > 67 && value <= 100):
                    return '动作稳定，基本不动。'
                default:
                    return ''
            }
        }
    }

    return (
        <div className={`${styles.page_div}`}>
            <Spin
                spinning={overViewLoading}
                className={styles.spinnerWrapperText}
            >
                <div className={styles.headerTextThere}>五、测评总结</div>
                <div className={styles.headerTextBody}>
                    <table border="1" align="center" valign="middle">
                        <tbody>
                            <tr align="center" className={styles.trStyle}>
                                <td className={styles.tdTitle}>维度</td>
                                <td className={styles.tdTitle}>人群百分比</td>
                                <td className={styles.tdTitle}>测评表现</td>
                            </tr>
                            <tr align="center" className={styles.trStyle}>
                                <td className={styles.tdTitle}>注意力指数</td>
                                <td>{attentionPerformance.toFixed(0)}</td>
                                <td className={styles.testDes}>
                                    <div>注意力表现超过{attentionPerformance.toFixed(0)}%的同年龄段、同性别群体。</div>
                                    <div>{getDes('attention', attentionPerformance)}</div>
                                </td>
                            </tr>
                            <tr align="center" className={styles.trStyle}>
                                <td className={styles.tdTitle}>动作指数</td>
                                <td>{motionPerformance.toFixed(0)}</td>
                                <td className={styles.testDes}>
                                    <div>动作行为表现超过{motionPerformance.toFixed(0)}%的同年龄段、同性别群体。</div>
                                    <div>{getDes('motion', motionPerformance)}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.tableDescription}>注：报告仅反映儿童在本次13分钟测评中的表现，不能作为医疗诊断证明，仅供临床医生参考。</div>
            </Spin>
            <div className={styles.dockerMsg}>医生意见：</div>
            <div className={styles.docterSigned}>
                <table border="0" align="center" valign="middle">
                    <tbody>
                        <tr align="left" className={styles.trTwoStyle}>
                            <td className={styles.tdTitleSign} colSpan="2">
                                <div className={styles.sign}>测评师签名：____________________ <span className={styles.signRight}>医生签名：____________________</span></div>
                            </td>
                        </tr>

                        <tr align="left" className={styles.trTwoStyle}>
                            <td className={styles.tdTitleSign} colSpan="2">
                                <div className={styles.sign}>日&emsp;&emsp;&emsp;期：____________________ <span className={styles.dateRight}>日&emsp;&emsp;期：____________________</span></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span className={styles.page_number}>3</span>
        </div>
    )
}

export default ThreePage
