import React, { useEffect } from 'react'
import { Space } from 'antd'
import html2canvas from "html2canvas";
import { Spin, Button } from 'antd'
import { connect, useIntl } from 'umi'
import * as Survey from 'survey-react'
import * as SurveyPDF from "survey-pdf";
import { isJson } from 'cognitiveleap-core-us/utils/helper'
import styles from './index.less'

let clickTime = 0
const QuestionnaireView = ({ questionnaireView, dispatch, location }) => {
  const intl = useIntl()

  useEffect(() => {
    dispatch({ type: 'questionnaireView/fetch', payload: location.query })
  }, [])

  const { userAssessmentInfo, locale } = questionnaireView
  const { assessment } = userAssessmentInfo || {}
  const { title } = assessment || {}
  if (userAssessmentInfo === null) return <Spin />
  const {
    questionnaireAnswer: { questionnaire, answer },
  } = userAssessmentInfo

  const questionnaireJson = isJson(questionnaire.jsonString)
    ? JSON.parse(questionnaire.jsonString)
    : null

  // console.log(questionnaireJson)

  const aggregatedQuestionnaireJson = isJson(questionnaire.jsonStringPageAggregated)
    ? JSON.parse(questionnaire.jsonStringPageAggregated)
    : null

  // console.log(aggregatedQuestionnaireJson)

  const answerJson = isJson(answer.answerContent) ? JSON.parse(answer.answerContent) : null

  if (aggregatedQuestionnaireJson === null || answerJson === null)
    return <div>{`Error Display. UserAssessmentId: ${userAssessmentInfo.id} `}</div>

  const model = new Survey.Model(aggregatedQuestionnaireJson)
  model.mode = 'display'
  model.data = answerJson
  model.locale = locale
  const savePDF = () => {
    var surveyPDF = new SurveyPDF.SurveyPDF(aggregatedQuestionnaireJson);
    surveyPDF.data = answerJson;
    surveyPDF.save();
  }

  const print = () => {
    // const printcontent = document.getElementById("printcontent");
    // html2canvas(printcontent, {
    //   dpi: window.devicePixelRatio * 3,
    //   scale: 3,
    // }).then(canvas => {
    //   const imgUrl = canvas.toDataURL();
    //   // 获取截图base64 
    //   console.log(imgUrl);
    //   const oA = document.createElement("a");
    //   oA.href = imgUrl
    //   oA.download = title
    //   document.body.appendChild(oA)
    //   oA.click();
    //   oA.remove();
    // });

    // 修改radio样式
    if (clickTime === 0) {
      const fieldset = document.getElementsByClassName('sv_qcbc');
      if (fieldset && fieldset.length > 0) {
        for (let i = 0; i < fieldset.length; i++) {
          let radio = fieldset[i];
          const span = document.createElement('span');
          span.style = "margin: 3.9px 0; display: inline-block;"
          const parentNode = radio.parentNode;
          span.innerHTML = radio.innerHTML;
          parentNode.appendChild(span);
          radio.style = "display: none";
        }
      }
      clickTime++
    }
    document.getElementById('changeLocale').style.display = 'none'
    window.print()
    document.getElementById('changeLocale').style.display = 'block'
  }

  const changeLocale = () => {
    const tempLocale = locale === 'zh-cn' ? 'en' : 'zh-cn'
    model.locale = tempLocale;
    dispatch({ type: 'questionnaireView/updateState', payload: {
      locale: tempLocale
    }})
    model.render();
  }

  return (
    <div className={styles.questionnaireView}>
      <div className={styles.questionnaireViewTop}>
        <div className={styles.title}>{title}</div>
        <Space align="center">
            <div
              onClick={changeLocale}
              id="changeLocale"
            >
              <span className={locale === 'zh-cn' ? styles.iscurrent : ''}>中</span>
              <span>&nbsp;/&nbsp;</span>
              <span className={locale === 'en' ? styles.iscurrent : ''}>EN</span>
            </div>
           <Button onClick={() => print()} type="primary" className={styles.questionnaireViewTopBtn}>{intl.formatMessage({ id: 'exportPDF' })}</Button>
        </Space>
      </div>
      <div id='printcontent'>
        <Survey.Survey model={model} />
      </div>
    </div>
  )
}

export default connect(({ questionnaireView }) => ({ questionnaireView }))(QuestionnaireView)
