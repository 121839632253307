import React from 'react';
import { useIntl } from 'umi';
import moment from 'moment';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

const listSupport = (intl) => {
  return {
    order: {
      showText: intl.formatMessage({ id: 'OrderPdt' }),
      showType: 'Text',
    },
    title: {
      showText: intl.formatMessage({ id: 'SessionName' }),
      showType: 'Text',
    },
    type: {
      showText: intl.formatMessage({ id: 'type' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({ id: text.toLowerCase() });
      },
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text) => {
        return intl.formatMessage({ id: text.toLowerCase() });
      },
    },
    pushedAt: {
      showText: intl.formatMessage({ id: 'AssignedDate' }),
      showType: 'Time',
    },
    time: {
      showText: intl.formatMessage({ id: 'CompletionTime' }),
      showType: 'Time',
      render: (text, record) => {
        const { progress } = record || {};
        return progress.split(',').length == 7
          ? moment(text).local().format('YYYY-MM-DD')
          : '-';
      },
    },
  };
};

const ExerciseWeekly = ({ data }) => {
  const intl = useIntl();

  const tableProps = {
    listData: data
      .sort((a, b) => b.exercise.dayIndex - a.exercise.dayIndex)
      .map((item) => {
        const { exercise, updatedAt } = item;
        const { weekIndex, dayIndex, type, title } = exercise;
        const order = intl.formatMessage(
          { id: 'whickWeekOrDay' },
          { week: weekIndex, day: dayIndex },
        );
        return {
          order,
          type,
          title,
          time: updatedAt,
          ...item,
        };
      }),
    listSupport: listSupport(intl),
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <MagicTable {...tableProps} />
    </div>
  );
};

export default ExerciseWeekly;
