import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal, Input } from 'antd'
import { useIntl } from 'umi'

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'

const { Search } = Input;

const Area = ({ centerListArea, dispatch, loading }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'centerListArea/query' })
    dispatch({ type: 'centerListArea/getRegionList' })
  }, [])

  const { list, pagination, Filter, regionList } = centerListArea

  const filterRegionOptions = regionList.map(item => {
    return {
      text: item,
      value: item
    }
  })

  const listSupport = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
    },
    region: {
      showText: '所属企业',
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: filterRegionOptions,
        filterMultiple: false,
      }
    },
    displayName: {
      showText: intl.formatMessage({ id: 'centerName' }),
      showType: 'Text',
    },
    // vratStatus:{
    //   showText: 'vratStatus',
    //   showType: 'Bool',
    // },
    // rocketStatus:{
    //   showText: 'rocketStatus',
    //   showType: 'Bool',
    // },
    showStatus: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
    },
    isActive: {
      showText: intl.formatMessage({ id: 'isActive' }),
      showType: 'Bool',
    },
  }

  const TableProps = {
    loading: loading.effects['centerListArea/query'],
    listData: list.map(item => {
      const { projectStatus, state } = item
      const vratStatus = projectStatus.some(project => {
        const { name, status } = project
        return name === 'Project.Vrat' && status
      })
      const rocketStatus = projectStatus.some(project => {
        const { name, status } = project
        return name === 'Project.Rocket' && status
      })
      return {
        ...item,
        showStatus: state === 'Effective' ? intl.formatMessage({ id: 'effective' }) : intl.formatMessage({ id: 'inActive' }),
        vratStatus,
        rocketStatus,
        region: item.parentTenant && item.parentTenant.displayName
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, filt = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'centerListArea/changeTable', payload: data })
    },
  }

  const onSearchChange = (e) => {
    dispatch({ type: 'centerListArea/updateState', payload: { Filter: e.target.value } })
  }

  const onClear = () => {
    dispatch({ type: 'centerListArea/clear' })
  }

  return (
    <div>
      <h1>中心列表</h1>
      <Search
        placeholder="中心名称"
        value={Filter}
        onChange={onSearchChange}
        onSearch={value => dispatch({ type: 'centerListArea/filterFetch', payload: { Filter: value } })}
        style={{ width: 200, marginBottom: '10px' }}
      />
      <Button onClick={onClear} style={{ marginLeft: '10px' }}>{intl.formatMessage({ id: 'clear' })}</Button>
      <MagicTable {...TableProps} />
    </div>
  )
}

export default connect(({ centerListArea, loading }) => ({ centerListArea, loading }))(Area)
