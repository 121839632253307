import React, { useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import { connect } from 'umi';
import SelectModal from 'components/SelectModal';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { history } from 'umi';
import styles from './index.less';

const deleteText = (
  <div style={{ maxWidth: '200px' }}>
    <span>确定删除吗？</span>
    <span style={{ color: 'red' }}>删除后此中心将不可使用此条目</span>
  </div>
);

const listSupport = {
  creationTime: {
    showText: '添加时间',
    showType: 'Time',
    addtional: {
      format: 'YYYY-MM-DD HH:mm:ss',
    },
  },
  displayName: {
    showText: '中心名称',
    showType: 'text',
  },
  state: {
    showText: 'isActive',
    showType: 'text',
    render: (text) => {
      return text === 'Effective' ? '有效' : '无效';
    },
  },
};

const AuthorCenter = ({
  currentUser,
  authorCenter,
  loading,
  dispatch,
  location,
}) => {
    const tableLoading = loading.effects['authorCenter/queryList'];

  useEffect(() => {
    dispatch({ type: 'authorCenter/query', payload: location.query });
  }, []);

  const { canChangeList } = currentUser;
  const { title, selectModalVisible, data, pagination } = authorCenter;

  const tableProps = {
    listData: data,
    listSupport,
    pagination,
    loading: tableLoading,
    // Actions: [
    //     {
    //         showText: '删除',
    //         itemRender(record) {
    //             return (
    //                 <Popconfirm
    //                     placement="topRight"
    //                     title={deleteText}
    //                     onConfirm={()=>dispatch({ type: 'authorCenter/delete', payload: record })}
    //                 >
    //                     <a>删除</a>

    //                 </Popconfirm>
    //             )
    //         }
    //     }
    // ]
  };

  const selectModalProps = {
    title: '选择中心',
    selectModalVisible,
    allList: canChangeList.map((item) => {
      return {
        ...item,
        title: item.displayName,
      };
    }),
    targetKeys: data.map(item => item.id),
    onOk(values) {
      dispatch({ type: 'authorCenter/submit', payload: values });
    },
    onCancel() {
      dispatch({
        type: 'authorCenter/updateState',
        payload: { selectModalVisible: false },
      });
    },
  };

  return (
    <div className={styles.authorCenter}>
      <div className={styles.authorCenterTitle}>{title}</div>
      <div className={styles.authorCenterBack}>
        <Button icon={<CaretLeftOutlined />} onClick={() => history.goBack()}>
          返回
        </Button>
        <Button
          onClick={() =>
            dispatch({
              type: 'authorCenter/updateState',
              payload: { selectModalVisible: true },
            })
          }
        >
          编辑授权中心
        </Button>
      </div>
      {selectModalVisible && <SelectModal {...selectModalProps} />}
      <MagicTable {...tableProps} />
    </div>
  );
};

export default connect(({ user, authorCenter, loading }) => ({
  currentUser: user.currentUser,
  authorCenter,
  loading,
}))(AuthorCenter);
