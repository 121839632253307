
import React from 'react';
import style from './index.less';
import { useIntl } from 'umi';
import defaultImg from 'assets/default_trainerDatabase.png';

const TrainerDatabaseItem = ({ data, goDetail }) => {
    const intl = useIntl();

    const { imageUrl, title, subTitle, length } = data;

    return (
        <div className={style.trainerDatabaseItem} onClick={() => { goDetail(data) }}>
            <div className={style.trainerDatabaseItemImg}>
                <img src={imageUrl ? imageUrl.url : defaultImg} />
            </div>
            <div className={style.title}>{title}</div>
            <div className={style.subTitle}>
                <div>{subTitle}</div>
                <div>{intl.formatMessage({ id: 'totalNumber' }, { num: length })}</div>
            </div>
        </div>
    )
}

export default TrainerDatabaseItem;