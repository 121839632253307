import React, { useState, useEffect } from 'react';
import { connect, useIntl, history } from 'umi';
import { Row, Col, Button, Spin, message } from 'antd';
import Thoughts from 'components/CoreLibraryCom/thoughts';
import MyVideo from 'components/MyVideo';
import { GetDailyTopicDetail } from 'services/adhd';

import styles from './index.less';

const screenWidth = window.screen.width * 0.7;
const currentHeight = (screenWidth / 16) * 9;

const getContent = (sessionDetail) => {
  const { contentType, resource } = sessionDetail || {};

  switch (contentType) {
    case 'Video':
      const { id, url, coverUrl } = resource || {};
      const videoProps = {
        id,
        url,
        coverUrl,
        width: `${screenWidth}px`,
        height: `${currentHeight}px`,
        customConfig: {
          autoplay: true,
        },
      };
      return <MyVideo {...videoProps}></MyVideo>;
    case 'Activity':
      const { url: activeUrl, title, id: activeId } = sessionDetail || {};

      return (
        <iframe
          src={activeUrl}
          title={title}
          id={activeId}
          width={screenWidth}
          height={currentHeight}
          frameBorder="no"
          border="0"
          scrolling="no"
        />
      );
    default:
      return <div>{contentType}</div>;
  }
};

const CoreSessionDetail = ({ location }) => {
  const {
    DailyTopicId,
    weekSortOrder,
    title,
    daySortOrder,
    TemplateId,
    WeekThemeId,
  } = location.query || {};

  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [sessionDetail, setSessionDetail] = useState({});

  const { title: dayTitle, adhdTags = [] } = sessionDetail || {};

  const initData = async () => {
    setLoading(true);

    const res = await GetDailyTopicDetail({
      id: DailyTopicId,
    });

    if (res.response.ok) {
      setSessionDetail(res.data || {});
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const thoughtsProps = {
    daySortOrder,
    weekSortOrder,
    TemplateId,
    WeekThemeId,
    DailyTopicId,
  };

  return (
    <div className={styles.coreSessionDetail}>
      <div className={styles.coreSessionDetailTop}>
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          {intl.formatMessage({ id: 'back' })}
        </Button>
        <p className={styles.coreSessionDetailTitle}>
          {intl.formatMessage(
            { id: 'core-week' },
            { week: parseInt(weekSortOrder) + 1 },
          )}&nbsp;&nbsp;&nbsp;
          {title}
        </p>
      </div>
      <Spin spinning={loading}>
        <div className={styles.coreSessionDetailContent}>
          <Row>
            <Col span={24}>
              <div className={styles.oneTitle}>
                {intl.formatMessage(
                  { id: 'Day' },
                  { day: parseInt(daySortOrder) + 1 },
                )}&nbsp;&nbsp;&nbsp;
                {dayTitle}
              </div>
              {adhdTags.length > 0 && (
                <div className={styles.twoTitle}>
                  {adhdTags
                    .map((item) => {
                      const { displayName } = item || {};
                      return displayName;
                    })
                    ?.join(', ')}
                </div>
              )}
              <div className={styles.line}></div>
              <div className={styles.content}>
                {Object.keys(sessionDetail).length > 0 &&
                  getContent(sessionDetail)}
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Thoughts {...thoughtsProps} />
        </div>
      </Spin>
    </div>
  );
};

export default CoreSessionDetail;
