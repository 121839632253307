import React from 'react';
import { useIntl, getLocale } from 'umi';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import BottomPageNum from './components/BottomPageNum';
import HeaderLine from './components/HeaderLine';
import { countAge } from 'utils/utils'
import moment from 'moment';

const isZh = getLocale() === 'zh-CN';

const getPercentileGroup = (age = 0, intl) => {
  if (age >= 6 && age < 8) {
    return intl.formatMessage({ id: 'ChildInfo.yrs' }, { num: '6-7' });
  } else if (age >= 8 && age < 10) {
    return intl.formatMessage({ id: 'ChildInfo.yrs' }, { num: '8-9' });
  } else if (age >= 10 && age < 12) {
    return intl.formatMessage({ id: 'ChildInfo.yrs' }, { num: '10-11' });
  } else if (age >= 12 && age < 14) {
    return intl.formatMessage({ id: 'ChildInfo.yrs' }, { num: '12-13' });
  }
};

const genderToStr = (gender, intl) => {
  switch (gender) {
    case 'Male':
      return intl.formatMessage({ id: 'male' });
    case 'Female':
      return intl.formatMessage({ id: 'female' })
    default:
      return intl.formatMessage({ id: 'other' })
  }
};

const ChildInfo = ({ data, reportVersion, userName }) => {
  const intl = useIntl();

  const {
    Subject = {},
    Case = {},
    TestLocation = {},
    TestingCenter = {},
    UnitType = '',
    TestAge = 0,
  } = data || {};

  const { BirthDay, Gender } = Subject || {};
  const { TestDate, Id, AppVersion = '' } = Case || {};

  const { Name } = TestLocation || {};
  const { DisplayName } = TestingCenter || {};

  const TestLocationName = Name || DisplayName;
  const infoList = [
    {
      title: intl.formatMessage({ id: 'ChildInfo.clientName' }),
      key: 'Name',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.birth' }),
      key: 'BirthDay',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.age' }),
      key: 'TestAge',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.gender' }),
      key: 'Gender',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.percentile' }),
      key: 'PercentileGroup',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.date' }),
      key: 'TestDate',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.center' }),
      key: 'TestLocationName',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.id' }),
      key: 'TestId',
    },
    {
      title: intl.formatMessage({ id: 'ChildInfo.version' }),
      key: 'TestVersion',
    },
    {
      title:intl.formatMessage({ id: 'ChildInfo.report' }),
      key: 'ReportVersion',
    },
  ];
  const dataSource = {
    BirthDay: BirthDay ? moment(BirthDay).local().format('MM/DD/YYYY') : '',
    Name: userName,
    Gender: genderToStr(Gender, intl),
    TestId: Id,
    TestLocationName,
    TestDate: TestDate ? moment(TestDate).local().format('MM/DD/YYYY') : '',
    TestAge: countAge(BirthDay, intl, TestDate),
    PercentileGroup:
      (TestAge >= 0 && TestAge < 6) || TestAge >= 14
        ? intl.formatMessage({ id: 'TextNA' })
        : getPercentileGroup(TestAge, intl) + `, ${genderToStr(Gender, intl)}`,
    TestVersion: UnitType ? AppVersion + ` - ${UnitType}` : AppVersion,
    ReportVersion: `V${reportVersion}`,
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.childInfo}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}> {intl.formatMessage({ id: 'ChildInfo.centerTitle' })}</div>
          <div className={styles.showInfoList}>
            {infoList.map((item) => {
              const { key, title } = item || {};
              return (
                <div key={key} className={styles.infoItem}>
                  <div className={styles.infoTitle}>{title}:</div>
                  <div className={styles.infoValue}>
                    {dataSource[key] || ''}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <BottomPageNum num={3} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default ChildInfo;
