import { Collapse, Table, Divider, Modal} from 'antd'

const { Panel } = Collapse;
const { confirm } = Modal

const CategoryCollapose = ({ categories, onEdit, onDelete, onSort }) => {

  const getExtra = (index) => (
    <span>
    <a
      onClick={event => {
        event.stopPropagation();
        onEdit(index)
      }}
    >
      Edit
    </a>
    <Divider type="vertical" />
    <a
      onClick={event => {
        event.stopPropagation();
        onSort(index)
      }}
    >
      Sort
    </a>
    <Divider type="vertical" />
    <a
      onClick={event => {
        event.stopPropagation();
        confirm({
          title: 'Are you sure DELETE this category?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            onDelete(index)
          },
        });
      }}
    >
      Delete
    </a>
    </span>
  );

  return <div>
    <Collapse bordered={false}>
      {categories.map((categoryItem,index)=>{
        const {trainingCategory, trainingPrograms, id} = categoryItem
        const column = [
          {
            title: 'title',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: 'subTitle',
            dataIndex: 'subTitle',
            key: 'subTitle',
          },
          {
            title: 'Action',
            key: 'Action',
            render: (text,record,internalIndex)=><a onClick={event => {
              event.stopPropagation();
              onSort(index, internalIndex)
            }}>Sort</a>
          }
        ]
        return (
          <Panel header={trainingCategory.name} key = {id} extra = {getExtra(index)}>
            <Table columns={column} dataSource={trainingPrograms} rowKey = {'id'} pagination = {false}/>
          </Panel>
        )
      })}
    </Collapse>
  </div>
}

export default CategoryCollapose
