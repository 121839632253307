import { GetApplyList, PostReview } from 'services/applyTraining'
import { GetTrainingApplication } from 'services/trainingSystem'
import { history } from 'umi';
import { message } from 'antd'

export default {
    namespace: 'applyExamine',
    state: {
        Filter: '',
        AuditResult: '',
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
        // 列表数据
        listData: null,
        // 培训申请表数据
        questionData: null,
        // 培训申请表右侧显示
        drawerVisible: false,
        // 培训申请弹框展示
        applyModalVisible: false,
        // 当前审核的内容
        selectItem: null,
        // 选中的培训人员
        selectedRowKeys: [],
        // 批量审核弹框
        BatchApplyVisible: false
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query({ payload }, { call, put, select }) {
            const { Filter, AuditResult, pagination } = yield select(state => state.applyExamine)
            const { current, pageSize } = pagination || {}
            const params = {
                AuditResult,
                Filter,
                SkipCount: (current - 1) * pageSize,
                MaxResultCount: pageSize
            }
            const getListRes = yield call(GetApplyList, params)
            if (getListRes.response.ok) {
                const { pagedResult } = getListRes.data || {}
                const { items, totalCount } = pagedResult || {}
                const newPagination = { ...pagination, total: totalCount }
                yield put({
                    type: 'updateState',
                    payload: {
                        listData: getListRes.data,
                        pagination: newPagination
                    }
                })
            }
        },

        *changeTable({ payload }, { call, put, select }) {
            const { pagination } = yield select(state => state.applyExamine)
            const { pagination: newPagination } = payload || {}
            yield put({
                type: 'updateState',
                payload: {
                    pagination: { ...pagination, ...newPagination }
                }
            })
            yield put({
                type: 'query'
            })
        },

        *onSearch({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0
            }
            yield put({
                type: 'updateState',
                payload: {
                    pagination
                }
            })
            yield put({
                type: 'query'
            })
        },

        *onClear({ payload }, { call, put, select }) {
            const pagination = {
                current: 1,
                pageSize: 10,
                total: 0
            }
            yield put({
                type: 'updateState',
                payload: {
                    pagination,
                    Filter: '',
                    AuditResult: '',
                }
            })
            yield put({
                type: 'query'
            })
        },

        *getQuestion({ payload }, { call, put, select }) {
            const { trainer } = payload || {}
            const { id } = trainer || {}
            const getQuestionRes = yield call(GetTrainingApplication, { TrainerId: id })
            if (getQuestionRes.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: {
                        questionData: getQuestionRes.data,
                        drawerVisible: true
                    }
                })
            }
        },

        *examine({ payload }, { call, put, select }) {
            yield put({
                type: 'updateState',
                payload: {
                    applyModalVisible: true,
                    selectItem: payload
                }
            })
        },

        *submitExamine({ payload: { value, intl } }, { call, put, select }) {
            const { selectItem } = yield select(state => state.applyExamine)
            const { id } = selectItem || {}
            const { auditResult, role } = value || {}
            const params = {
                applyIds: [id],
                isPass: auditResult === "Passed",
                roleName: auditResult === "Passed" ? role : "wangshulinissb"
            }
            const submitRes = yield call(PostReview, params)
            if (submitRes.response.ok) {
                if (auditResult === "Passed") {
                    message.success(intl.formatMessage({ id: 'resultsSubmitted' }), 3)
                    yield put({ type: 'updateState', payload: { applyModalVisible: false, selectedRowKeys: [] } })
                    setTimeout(() => {
                        history.push('/system/center/menu/manage/trainingStaff')
                    }, 3000)
                } else {
                    message.success(intl.formatMessage({ id: 'resultHasSent' }))
                    yield put({ type: 'updateState', payload: { applyModalVisible: false, selectedRowKeys: [] } })
                    yield put({ type: 'query' })
                }
            }
        },

        // 批量通过
        *batchPass({ payload: { intl } }, { call, put, select }) {
            const { selectedRowKeys } = yield select(state => state.applyExamine)
            if (selectedRowKeys.length === 0) {
                message.error(intl.formatMessage({ id: 'selectTheTrainee' }))
                return
            }
            yield put({
                type: 'updateState',
                payload: {
                    BatchApplyVisible: true
                }
            })
        },

        *submitBatchApply({ payload }, { call, put, select }) {
            const { selectedRowKeys } = yield select(state => state.applyExamine)
            const { isPass, roleName, intl } = payload
            const params = {
                applyIds: selectedRowKeys,
                isPass,
                roleName
            }
            const submitRes = yield call(PostReview, params)
            if (submitRes.response.ok) {
                if (isPass) {
                    message.success(intl.formatMessage({ id: 'resultsSubmitted' }), 3)
                    yield put({ type: 'updateState', payload: { BatchApplyVisible: false, selectedRowKeys: [] } })
                    setTimeout(() => {
                        history.push('/system/center/menu/manage/trainingStaff')
                    }, 3000)
                } else {
                    message.success(intl.formatMessage({ id: 'resultHasSent' }))
                    yield put({ type: 'updateState', payload: { BatchApplyVisible: false, selectedRowKeys: [] } })
                    yield put({ type: 'query' })
                }
            }
        }

    },
    subscriptions: {
        // setupHistory({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/center/menu/manage/applyExamine') {
        //             dispatch({
        //                 type: 'query'
        //             })
        //         }
        //     })
        // },
    },
}
