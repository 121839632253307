import React, { useEffect } from 'react'
import { connect } from 'umi'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { Input, Button, Row, Col, Modal } from 'antd'
const { Search } = Input

const confirm = Modal.confirm

const Reminder = ({ dispatch, reminder, loading }) => {

  useEffect(() => {
    dispatch({ type: 'reminder/query' })
  }, [])

  const {
    list,
    listSupport,
    pagination,
    modalType,
    content,
    modalVisible,
    checkModalVisible,
  } = reminder

  const modalTypeToTitle = type => {
    switch (type) {
      case 'add':
        return '添加提醒'
      case 'edit':
        return '编辑提醒'
      default:
        return ''
    }
  }

  const onAddBtnClick = () => {
    dispatch({ type: 'reminder/onAddReminder' })
  }

  const tableProps = {
    loading: loading.models.reminder,
    listData: list,
    listSupport,
    pagination,
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'reminder/onEditReminder', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'reminder/onDeleteReminder', payload: record.id })
            },
          })
        },
      },
      // {
      //   showText: '提醒详情页',
      //   onClick(record) {
      //     dispatch({ type: 'reminder/goToReminderDetail', payload: record.id })
      //   },
      // },
    ],
  }

  const modalProps = {
    loading: loading.models.reminder,
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'reminder/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      switch (modalType) {
        case 'add':
        case 'edit':
          dispatch({ type: 'reminder/onSubmitModal', payload: value })
          break
        default:
      }
    },
    content,
  }

  const searchProps = {
    placeholder: '搜索提醒',
  }

  return (
    <div>
      <h1>提醒</h1>
      <Row gutter={16} style={{ marginTop: 20, marginBottom: 20 }}>
        <Col span={4}>
          <Search {...searchProps} />
        </Col>
        <Col span={4}>
          <Button onClick={onAddBtnClick}>添加提醒</Button>
        </Col>
      </Row>
      <MagicFormModal {...modalProps} />
      <MagicTable {...tableProps} />
    </div>
  )
}

export default connect(({ reminder, loading }) => ({ reminder, loading }))(Reminder)
