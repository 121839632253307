import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import ScoresTitle from './components/ScoresTitle';
import BottomPageNum from './components/BottomPageNum';
import { useIntl } from 'umi';

const getLevelText = (value, intl) => {
  if (value >= 0 && value < 33) {
    return intl.formatMessage({ id: 'MuchBelowAverage' });
  } else if (value >= 33 && value < 50) {
    return intl.formatMessage({ id: 'BelowAverage' });
  } else if (value >= 50 && value < 66) {
    return intl.formatMessage({ id: 'AboveAverage' });
  } else if (value >= 66 && value <= 100) {
    return intl.formatMessage({ id: 'MuchAboveAverage' });
  }
};

const TwoScoresDes = ({ data }) => {
  const intl = useIntl();
  const {
    Sensitivity = 0,
    ContinuousAttention = 0,
    ReactionStability = 0
  } = data || {};

  const _ContinuousAttention = Math.round(ContinuousAttention * 100);
  const _Sensitivity = Math.round(Sensitivity * 100);
  const _ReactionStability = Math.round(ReactionStability * 100);

  const firstDesProps = {
    num: '04',
    title: intl.formatMessage({ id: 'TwoScoresDes.stability' }),
    firstDes: intl.formatMessage({ id: 'TwoScoresDes.consistently' }),
    secondDes: intl.formatMessage(
      { id: 'TwoScoresDes.processing' },
      { reactionStability: _ReactionStability, _ReactionStability: getLevelText(_ReactionStability, intl) },
    ),
  };

  const twoDesProps = {
    num: '05',
    title: intl.formatMessage({ id: 'FirstScoresDes.discriminability' }),
    firstDes: intl.formatMessage({ id: 'FirstScoresDes.discriminate' }),
    secondDes: intl.formatMessage(
      { id: 'FirstScoresDes.gender' },
      { sensitivity: _Sensitivity, _Sensitivity: getLevelText(_Sensitivity, intl) },
    ),
  };

  const threeDesProps = {
    num: '06',
    title: intl.formatMessage({ id: 'FirstScoresDes.mental' }),
    firstDes: intl.formatMessage({ id: 'FirstScoresDes.represents' }),
    secondDes: intl.formatMessage(
      { id: 'FirstScoresDes.endurance' },
      {
        continuousAttention: _ContinuousAttention,
        _ContinuousAttention: getLevelText(_ContinuousAttention, intl),
      },
    ),
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.twoScoresDes}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.scoresTitle}>
            {intl.formatMessage({ id: 'TwoScoresDes.title' })}
          </div>
          <ScoresTitle {...firstDesProps} />
          <ScoresTitle {...twoDesProps} />
          <ScoresTitle {...threeDesProps} />
        </div>
        <BottomPageNum num={9} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default TwoScoresDes;
