import React from 'react'
import MyModal from 'components/MyModal'
import { Input } from 'antd'
import styles from './index.less';
class ChangeEmailModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            errorMessage: ''
        }
    }

    onChangeEmail = (e) => {
        this.setState({ email: e.target.value })
    }

    onCancel = () => {
        const { onCancel } = this.props
        onCancel();
        this.setState({ email: '', errorMessage: '' })
    }

    onOk = () => {
        const { email } = this.state
        const { currentChangeEmailRecord, onOk } = this.props
        const { adminEmail } = currentChangeEmailRecord || {}
        let errorMessage = ''
        if (email === adminEmail) {
            errorMessage = '新邮箱账号不能与原邮箱账号一致'
        }
        if (errorMessage) {
            this.setState({ errorMessage })
        } else {
            onOk(email)
            this.setState({ email: '', errorMessage: '' })
        }
    }

    render() {
        const { title, visible, loading, onOk, onCancel, onOkText } = this.props
        const { errorMessage, email } = this.state
        const propsData = {
            title,
            visible,
            loading,
            onCancel: this.onCancel,
            onOkText,
            onOk: this.onOk
        }
        return (
            <MyModal {...propsData}>
                <div className={styles.changeEmailContent}>
                    <span>新邮箱：</span>
                    <Input value={email} onChange={this.onChangeEmail} className={styles.changeEmailContentInput} />
                </div>
                {errorMessage && <div className={styles.emailErrorMessage}>{errorMessage}</div>}
            </MyModal>
        )
    }
}

export default ChangeEmailModal