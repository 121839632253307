import React from 'react'
import styles from './Report.less'
import { useIntl } from 'umi'
import { Row, Col } from 'antd'
import ScoreChart from 'components/VratChartComponents/ScoreChart'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const Overview = ({ data }) => {
  const attentionPerformance = data ? (data.AttentionPerformanceIndex * 100).toFixed(1) : 100
  const motionPerformance = data ? (data.MotionPerformanceIndex * 100).toFixed(1) : 100

  const intl = useIntl()
  const AttentionIndexProps = {
    data: attentionPerformance,
  }

  const MotionIndexProps = {
    data: motionPerformance,
  }

  const description = {
    Attention: intl.formatMessage({ id: 'report.overview.api' }),
    Motion: intl.formatMessage({ id: 'report.overview.mpi' }),
  }

  return (
    <div id="overview" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.overview.title' })}</p>
      </h2>
      <Row type="flex" justify="space-around" style={{ marginTop: -30 }}>
        <Col span={12} className={styles.overviewChart}>
          <ScoreChart {...AttentionIndexProps} />
          <h2 className={styles.overviewDesc}>{description.Attention}</h2>
          <div className={styles.textPadding}>
            <p style={{ color: '#333' }}>{intl.formatMessage({ id: 'report.overview.api.desc1' })}</p>
            <p style={{ color: '#333' }}>{intl.formatMessage({ id: 'report.overview.api.desc2' })}</p>
            <p style={{ color: '#333', fontWeight: '600' }}>
              {intl.formatMessage({ id: 'report.overview.api.desc3_1' })}
              <span>{parseInt(AttentionIndexProps && AttentionIndexProps.data || 0)}</span>
              {intl.formatMessage({ id: 'report.overview.api.desc3_2' })}
              <span>{parseInt(AttentionIndexProps && AttentionIndexProps.data || 0) + '%'}</span>
              {intl.formatMessage({ id: 'report.overview.api.desc3_3' })}
            </p>
          </div>
        </Col>
        <Col span={12} className={styles.overviewChart}>
          <ScoreChart {...MotionIndexProps} />
          <h2 className={styles.overviewDesc}>{description.Motion}</h2>
          <div className={styles.textPadding}>
            <p style={{ color: '#333' }}>{intl.formatMessage({ id: 'report.overview.mpi.desc1' })}</p>
            <p style={{ color: '#333' }}>{intl.formatMessage({ id: 'report.overview.mpi.desc2' })}</p>
            <p style={{ color: '#333', fontWeight: '600' }}>
              {intl.formatMessage({ id: 'report.overview.mpi.desc3_1' })}
              <span>{parseInt(MotionIndexProps && MotionIndexProps.data || 0)}</span>
              {intl.formatMessage({ id: 'report.overview.mpi.desc3_2' })}
              <span>{parseInt(MotionIndexProps && MotionIndexProps.data || 0) + '%'}</span>
              {intl.formatMessage({ id: 'report.overview.mpi.desc3_3' })}
            </p>
          </div>
        </Col>
      </Row>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>2</p>
    </div>
  )
}

export default Overview
