import { connect, useIntl } from 'umi'
import moment from 'moment'
import { useEffect } from 'react'
import DocumentTitle from 'react-document-title'
import { Table, Button, Input, DatePicker, Row, Col } from 'antd'
import PaginationCom from 'components/Pagination/Pagination'
import { getFormatDate } from 'utils/utils'

const { RangePicker } = DatePicker
const Search = Input.Search

const Statistics = ({ cmsStatistics, dispatch, loading }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'cmsStatistics/fetchStatistics' })
  }, [])

  const {
    statistic = [],
    pageIndex,
    total,
    pageSize,
    searchField,
    TestStartTime,
    TestEndTime,
    ReportStartTime,
    ReportEndTime
  } = cmsStatistics || {}

  const columns = [
    {
      title: intl.formatMessage({ id: 'centerName' }),
      dataIndex: 'CenterName',
    },
    {
      title: intl.formatMessage({ id: 'locationCount' }),
      dataIndex: 'TestLocationCount',
    },
    {
      title: intl.formatMessage({ id: 'firstTestTime' }),
      dataIndex: 'FirstTime',
    },
    {
      title: intl.formatMessage({ id: 'lastTestTime' }),
      dataIndex: 'LastTime',
    },
    {
      title: intl.formatMessage({ id: 'subjectCount' }),
      dataIndex: 'SubjectCount',
    },
    {
      title: intl.formatMessage({ id: 'reportReadyCount' }),
      dataIndex: 'ReportAcquiredCount',
    },
    {
      title: intl.formatMessage({ id: 'Available Test(s)' }),
      dataIndex: 'LastAmount',
      render: (text) => text < 0 ? '不限' : text
    },
    {
      title: intl.formatMessage({ id: 'Valid Until' }),
      dataIndex: 'LatestExpirationTime',
      render: (text) => text && moment(text).format('YYYY/MM/DD')
    },
    // {
    //   title: intl.formatMessage({ id: 'reportReadyCount5_13' }),
    //   dataIndex: 'ReportAcquiredCount5_13:',
    // },
    {
      title: intl.formatMessage({ id: 'action' }),
      render: (text, record) => (
        <a onClick={() => onGoDetail(record)}>
          {intl.formatMessage({ id: 'detail' })}
        </a>
      ),
    },
  ]

  const showData = statistic.map(item => {
    const { FirstTestTime, LastTestTime, TestingCenter } = item
    return {
      FirstTime: moment(FirstTestTime).isValid() ? moment(FirstTestTime).format('MM/DD/YYYY') : '',
      LastTime: moment(LastTestTime).isValid() ? moment(LastTestTime).format('MM/DD/YYYY') : '',
      CenterName: TestingCenter && TestingCenter.DisplayName,
      ...item,
    }
  })

  const onTimePicker = dates => {
    let TestStartTime, TestEndTime
    if (dates && dates.length > 0) {
      TestStartTime = getFormatDate(dates[0], true)
      TestEndTime = getFormatDate(dates[1], false)
    } else {
      TestStartTime = null
      TestEndTime = null
    }
    dispatch({ type: 'cmsStatistics/filterFetch', payload: { TestStartTime, TestEndTime } })
  }

  const onReportTimePicker = value => {
    let ReportStartTime = null
    let ReportEndTime = null
    if (value && value.length > 0) {
      ReportStartTime = getFormatDate(value[0], true)
      ReportEndTime = getFormatDate(value[1], false)
    } else {
      ReportStartTime = null
      ReportEndTime = null
    }
    dispatch({ type: 'cmsStatistics/filterFetch', payload: { ReportStartTime, ReportEndTime } })
  }

  const showRangeTime = [
    moment(TestStartTime).isValid() ? moment(TestStartTime) : null,
    moment(TestEndTime).isValid() ? moment(TestEndTime) : null,
  ]

  const ShowReportRangeTime = [
    moment(ReportStartTime).isValid() ? moment(ReportStartTime) : null,
    moment(ReportEndTime).isValid() ? moment(ReportEndTime) : null,
  ]

  const searchProps = {
    placeholder: intl.formatMessage({ id: 'cms.statistics.searchForCenterName' }),
    style: { width: 300 },
    onSearch(value) {
      dispatch({ type: 'cmsStatistics/filterFetch', payload: { searchField: value } })
    },
    onChange(value) {
      dispatch({ type: 'cmsStatistics/updateState', payload: { searchField: value.target.value } })
    },
    value: searchField,
  }

  const onClear = () => {
    dispatch({
      type: 'cmsStatistics/filterFetch',
      payload: { searchField: null, TestStartTime: null, TestEndTime: null, ReportStartTime: null, ReportEndTime: null },
    })
  }

  const onPageChange = (pageIndex, pageSize) => {
    dispatch({ type: 'cmsStatistics/filterFetch', payload: { pageIndex, pageSize } })
  }

  const onGoDetail = record => {
    dispatch({ type: 'cmsStatistics/goToDetail', payload: record })
  }

  const onDownload = () => {
    dispatch({ type: 'cmsStatistics/download' })
  }

  const paginationComProps = { onPageChange, pageIndex, pageSize, total }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'cms.statistics.title.adminStatistics' })}>
      <div>
        <div>
          <span>
            {intl.formatMessage({ id: 'cms.statistics.title.adminStatistics' })}
          </span>
        </div>

        <Row type="flex" justify="start" gutter={10} style={{ marginBottom: 20 }}>
          <Col>
            <Search {...searchProps} />
          </Col>
          <Col>
            <span style={{ marginRight: '10px' }}>{intl.formatMessage({ id: 'Date created times' })}</span>
            <RangePicker onChange={onTimePicker} value={showRangeTime} />
          </Col>
          <Col>
            <span style={{ marginRight: '10px' }}>{intl.formatMessage({ id: 'reportReadyCountTime' })}</span>
            <RangePicker onChange={onReportTimePicker} value={ShowReportRangeTime} />
          </Col>
          <Col>
            <Button onClick={onClear}>{intl.formatMessage({ id: 'clear' })}</Button>
          </Col>
          <Col>
            <Button onClick={onDownload}>{intl.formatMessage({ id: 'downloadAllStatistic' })}</Button>
          </Col>
        </Row>

        <div>
          <Table
            rowKey={record => record?.TestingCenter?.Id}
            columns={columns}
            dataSource={showData}
            pagination={false}
            loading={loading.models.cmsStatistics}
          />
          <div style={{ marginTop: 30 }}>
            <PaginationCom {...paginationComProps} />
          </div>
        </div>
      </div>
    </DocumentTitle>
  )
}

export default connect(({ cmsStatistics, loading }) => ({ cmsStatistics, loading }))(Statistics)
