import React from 'react';
import { Modal } from 'antd'
import styles from './index.less'
import { useIntl } from 'umi'
import { DownloadOutlined } from '@ant-design/icons';

const LookReportQr = ({ visible, url, changedTenant: current, downloadCenterQr, onCancel }) => {
    const intl = useIntl()
    return (
        <Modal
            open={visible}
            footer={null}
            closable={true}
            width={300}
            className={styles.centerQrModal}
            onCancel={onCancel}
        >
            <div><h2>{intl.formatMessage({ id: 'WechatScan' })}</h2></div>
            <div>{intl.formatMessage({ id: 'viewReport' })}</div>
            <img src={url} width={200} />
            <div className={styles.centerName}>{current && current.displayName}</div>
            <div
                className={styles.downloadCenterQr}
                onClick={() => { downloadCenterQr() }}
            >
                {intl.formatMessage({ id: 'saveImage' })}&nbsp;<DownloadOutlined />
            </div>
        </Modal>
    )
}

export default LookReportQr