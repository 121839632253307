import React from 'react';
import { Layout } from 'antd';
import { useIntl, connect, history } from 'umi';
import { CopyrightOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Content } = Layout;
import Footer from 'cognitiveleap-core-us/components/Footer';
import defaultUserStaffUrl from 'assets/user_staff.png';

import styles from './index.less';

const ChoseRoles = ({ user: { currentUser }, dispatch }) => {
  const intl = useIntl();

  const { name, picture, staff } = currentUser;
  const { name: staffName, picture: staffPicture } = staff || {};

  const FooterProps = {
    links: [
      {
        key: 'HomePage',
        title: intl.formatMessage({ id: 'home' }),
        href: '/',
        blankTarget: false,
      },
      {
        key: 'Company',
        title: intl.formatMessage({ id: 'company' }),
        href: 'https://www.cognitiveleap.com/',
        blankTarget: true,
      },
    ],

    copyright: (
      <React.Fragment>
        Copyright <CopyrightOutlined /> 2017-{moment().format('YYYY')} Cognitive
        Leap Solutions Inc.
      </React.Fragment>
    ),
  };

  return (
    <Layout>
      <Content
        style={{
          background: '#f0f2f5',
          margin: 0,
          minHeight: '80vh',
        }}
      >
        <div className={styles.panel}>
          <div className={styles.panelTop}>
            <img src={(staffPicture && staffPicture.url) || defaultUserStaffUrl} />
            <div className={styles.name}>{intl.formatMessage({ id: 'hey' })}，{name || staffName}</div>
          </div>
          <div className={styles.panelBottom}>
            <div className={styles.enterDes}>{intl.formatMessage({ id: 'clickEnter' })}</div>
            <div className={styles.btnList}>
              <div
                className={styles.btnItem}
                onClick={() => {
                  //   跳转到三个人的管理员端
                  dispatch({
                    type: 'user/changeRoles',
                    payload: 'Management',
                  });
                }}
              >
                {intl.formatMessage({ id: 'cogleap' })}
              </div>
              <div
                className={styles.btnItem}
                onClick={() => {
                  //   机构端
                  dispatch({
                    type: 'user/changeRoles',
                    payload: 'Institutional',
                  });
                }}
              >
                {intl.formatMessage({ id: 'centerSide' })}
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Footer {...FooterProps} />
    </Layout>
  );
};

export default connect(({ user }) => ({
  user,
}))(ChoseRoles);
