import { connect, history, useIntl } from 'umi'
import { useEffect } from 'react';
import { Modal, Button, Row, Col, Spin, Input,message, BackTop } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import styles from './TestDetail.less'
import ChildInfo from './ChildInfo'
import TestingCenterInfo from './TestingCenterInfo'
// import InstitutionTypeCom from 'components/InstitutionTypeCom';
// import GuardianList from './GuardianList'
import DoctorComments from './DoctorComments'
import AddCommentsModal from './AddCommentsModal'
import DataValidationList from './DataValidateList'
import SendReportModal from '../../../components/SendReportModal'
import { createSuperLabel } from 'cognitiveleap-core-us/utils/utils'
import LocaleSelection from 'cognitiveleap-core-us/components/LocaleSelection'
import { showMenu, hideMenu, ContextMenu } from 'utils/customMenu.js';
import { encryptAES } from 'utils/utils';

const confirm = Modal.confirm

const TestDetail = ({ testDetail, currentUser, dispatch, loading, location }) => {
  const { suspension } = location.query || {};
    const intl = useIntl()
  useEffect(() => {
    if (location.pathname === '/system/center/detail/vratTestDetail') {
      dispatch({ type: 'testDetail/fetch', payload: { ...location.query, pageType: 'center' } })
    }
    if (location.pathname === '/system/manage/vratCaseDetail') {
      dispatch({ type: 'testDetail/fetch', payload: { ...location.query, pageType: 'admin' } })
    }
      // 初始先禁用右键菜单功能，等页面加载完毕会重写右键菜单功能
    if (suspension) {
      document.addEventListener('contextmenu', (event) => event.preventDefault());
      // 禁用浏览器快捷键打印功能
      window.addEventListener('keydown', function (e) {
        if (e.ctrlKey && (e.key === 'p' || e.key === 'P')) {
          e.preventDefault();
          dispatch({
            type: 'testDetail/downloadReport',
            payload: {
              caseId: Case.Id,
              intl
            },
          })
        }
      });
    }
  }, [])

  const {
    commentTemplate,
    addCommentsModalVisible,
    sendReportModalVisible,
    caseDetailInfo,
    pageType,
    checkBoxState,
  } = testDetail

  const { auth, changedTenant, adminRoles, current } = currentUser || {}
  const [{name} = {}] = adminRoles || []
  const isAdmin = adminRoles.length > 0 && current === null
  const { grantedPolicies } = auth
  const vratProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Vrat')['status'] : true;
  const institutionType = changedTenant && changedTenant.institutionType
  const hasQRCodePermission = grantedPolicies['VrClassroom.TestRequest.Start'] === true
  const hasTestSummaryPermission = grantedPolicies['Questionnaire.Questions.GetList'] === true
  const hasTestSummaryWritePermission = grantedPolicies['VrClassroom.DoctorCommentTemplete'] === true
  const hasTestSummaryViewPermission = grantedPolicies['VrClassroom.Center'] === true
  const isPageReady = !loading.effects['testDetail/fetch'] && caseDetailInfo !== null
  const isSendReportLoading = loading.effects['testDetail/sendReport']
  const isLoadingReportLoading = loading.effects['testDetail/downloadReport']
  const { roles = [] } = current || {}
  const isEnterpriseAdmin = roles.some(item => item.name === 'EnterpriseAdmin')
  const isAreaAdmin = roles.some(item => item.name === 'AreaAdmin')

  const {
    Case = {},
    Guardians = [],
    IsConsentFormSigned,
    TestLocation,
    Subject = {},
    TestingCenter,
    Status,
    OrderStatus,
    DeviceIP
  } = caseDetailInfo || {}

  const onShowModal = () => {
    dispatch({ type: 'testDetail/onAddCommentModal' })
  }

  const onConcentFormCheck = (e) => {
    dispatch({ type: 'testDetail/checkBoxData', payload: { value: e.target.checked } })
    // confirm({
    //   title: intl.formatMessage({ id: 'consentFormCheckedMsg' }),
    //   onOk() {
    //     dispatch({ type: 'testDetail/signConsentForm' })
    //   },
    // })
  }

  const ChildInfoProps = {
    childInfo: Subject,
    grade: Case.Grade,
  }

  const TestingCenterProps = {
    pageType,
    checkBoxState,
    institutionType,
    isBind: Subject && Subject.IsBind,
    isLogout: Subject && Subject.IsLogout,
    hasQRCodePermission,
    isLoading: isLoadingReportLoading,
    isEnterpriseAdmin,
    isAreaAdmin,
    testingCenterInfo: { TestingCenter: TestingCenter || {}, TestLocation: TestLocation || {}, OrderStatus },
    caseInfo: { ...Case, IsConsentFormSigned, Status, DeviceIP },
    onShowQRCode() {
      dispatch({ type: 'testDetail/getCode', payload: { type: 'show', intl } })
    },
    onCopyCode() {
      dispatch({ type: 'testDetail/getCode', payload: { type: 'copy', intl } })
    },
    onViewReport() {
      const url = '/report?caseId=' + encodeURIComponent(encryptAES(Case.Id))  + '&suspension=true' + '&isAdmin=' + isAdmin
      createSuperLabel(url, Case.Id)
      // var win = window.open(url, '_blank')
      // win.focus()
    },
    onMedicalReportView() {
      const url = '/system/medicalVratReport?caseId=' + Case.Id
      var win = window.open(url, '_blank')
      win.focus()
    },
    onMedicalChnReportView() {
      const url = '/system/medicalChnVratReport?caseId=' + Case.Id
      var win = window.open(url, '_blank')
      win.focus()
    },
    onParentEditionReport(model) {
      let url = '/parentreport?caseId=' + Case.Id + '&reId=' + Case.Id + '&suspension=true'
      if (model === 'screen') {
        url += '&model=' + model
      }
      var win = window.open(url, '_blank')
      win.focus()
    },
    onEducationEditionReport () {
      message.info('暂未完成')
      return
    },
    onClinicianEditionReport() {
      message.info('暂未完成')
      return
    },
    onSendReport() {
      dispatch({ type: 'testDetail/save', payload: { sendReportModalVisible: true } })
    },
    onConcentFormCheck,
    onDownload() {
      dispatch({
        type: 'testDetail/downloadReport',
        payload: {
          caseId: Case.Id,
          intl
        },
      })
    },
    getReport() {
      dispatch({
        type: 'testDetail/getReport',
        payload: {
          caseId: Case.Id,
        },
      })
    },
    onChangeTestType(e) {
      dispatch({
        type: 'testDetail/SubmitTestType',
        payload: e.target.value,
      })
    },
  }

  const onSendReport = ()=> {
    dispatch({ type: 'testDetail/save', payload: { sendReportModalVisible: true } })
  }
  const downloadPdf = () => {
    dispatch({
      type: 'testDetail/downloadReport',
      payload: {
        caseId: Case.Id,
        intl
      },
    })
  }
  const initCustomMenu = () => {
    const contextMenu = ContextMenu({
      menus: [
        {
          name: intl.formatMessage({ id: 'downLoadReport' }),
          onClick: () => downloadPdf(),
        },
        {
          name: intl.formatMessage({ id: 'sendReport' }),
          onClick: () =>onSendReport(),
        },
        {
          name: intl.formatMessage({ id: 'selectLanguage' }),
          children: [
            {
              name: '简体中文',
              active: getLocale() === 'zh-CN',
              onClick: () => {
                dispatch({ type: 'global/changeLang' , payload: 'zh-CN' })
              },
              className: getLocale() === 'zh-CN' ? 'chineseStyle' : '_',
            },
            {
              name: 'English',
              active: getLocale() === 'en-US',
              onClick: () => {
                dispatch({ type: 'global/changeLang' , payload: 'en-US' })
              },
              className: getLocale() === 'en-US' ? 'englishStyle' : '_',
            },
            {
              name: 'Español',
              active: getLocale() === 'es-ES',
              onClick: () => {
                dispatch({ type: 'global/changeLang' , payload: 'es-ES' })
              },
              className: getLocale() === 'es-ES' ? 'englishStyle' : '_',
            }
          ]
        },
        {
          name: intl.formatMessage({ id: 'backTop' }),
          onClick: () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
        },
      ],
    });
    document.addEventListener('contextmenu', (event) =>
      showMenu(event, contextMenu),
    );
    document.addEventListener('click', (event) => hideMenu(event, contextMenu));
  };

  useEffect (() => {
    if(suspension) {
      initCustomMenu()
    }
  }, [])
  const DataValidationListProps = {
    pageType,
    dataValidation: Case.DataValidation,
  }

  const GuardianListProps = {
    guardianList: Guardians,
    vratProjectStatus,
    isBind: Subject && Subject.IsBind,
    isLogout: Subject && Subject.IsLogout,
    onViewQuestion(data) {
      const { AnswerId, GuardianId, QuestionnaireId } = data
      if (AnswerId) {
        history.push({
          pathname: '/system/questionnaire',
          query: {
            ai: AnswerId,
            ie: '1',
          },
        })
      } else {
        history.push({
          pathname: '/system/questionnaire',
          query: {
            qi: QuestionnaireId,
            ie: '1',
            ci: Case.Id,
            gi: GuardianId,
          },
        })
      }
    },
  }

  const SendReportModalProps = {
    visible: sendReportModalVisible,
    loading: isSendReportLoading,
    onCancel() {
      dispatch({ type: 'testDetail/save', payload: { sendReportModalVisible: false } })
    },
    onOk(data) {
      dispatch({ type: 'testDetail/sendReport', payload: data })
    },
  }

  const DoctorCommentsProps = {
    pageType,
    name,
    hasWritePermission: hasTestSummaryWritePermission,
    hasTestSummaryViewPermission,
    vratProjectStatus,
    isBind: Subject && Subject.IsBind,
    isLogout: Subject && Subject.IsLogout,
    commentsList: Case.Comments || [],
    onShowCommentModal: onShowModal,
    onEditComment(id, isView) {
      history.push({
        pathname: '/system/questionnaire',
        query: {
          ai: id,
          ie: isView ? '0' : '1',
        },
      })
    },
  }

  const AddCommentsModalProps = {
    title: intl.formatMessage({ id: 'addComments' }),
    tempList: commentTemplate,
    visible: addCommentsModalVisible,
    onCancel() {
      dispatch({ type: 'testDetail/save', payload: { addCommentsModalVisible: false } })
    },
    onOk(data) {
      dispatch({ type: 'testDetail/save', payload: { addCommentsModalVisible: false } })
      const { QuestionnaireId } = data
      history.push({
        pathname: '/system/questionnaire',
        query: {
          qi: QuestionnaireId,
          ie: '1',
          ci: Case.Id,
        },
      })
    },
  }

  const onBack = () => {
    history.goBack()
  }

  return (
    <div className={styles.dashboardContentWrapper}>
      <Row type="flex" justify="start" gutter={20}>
        {suspension && (
          <>
            <div className={styles.suspension}>
              <ul>
                <li
                  className={styles.downLoadStyle}
                  onClick={downloadPdf}
                >
                </li>
                <li
                  className={styles.sendReportStyle}
                  onClick={onSendReport}
                >
                </li>
                <li className={styles.selectStyle} id="select">
                  <LocaleSelection
                    changeLanguage={(key) =>
                      dispatch({ type: 'global/changeLang', payload: key })
                    }
                    dropDownProps={{
                      getPopupContainer: () => {
                        return document.getElementById('select');
                      },
                      overlayStyle: { width: 80 },
                    }}
                    styles={{ width: '64px', height: '64px' }}
                  />
                </li>
              </ul>
            </div>
            <BackTop visibilityHeight={900}>
              <div className={styles.backUpStyle}></div>
            </BackTop>
          </>
        )}
        <Col>
          <Button onClick={() => onBack()}>
            <LeftOutlined />
            {intl.formatMessage({ id: 'back' })}
          </Button>
        </Col>
        <Col>
          <h2>{intl.formatMessage({ id: 'testDetail' })}</h2>
        </Col>
      </Row>
      <Spin spinning={!isPageReady}>
        <ChildInfo {...ChildInfoProps} />
        <TestingCenterInfo {...TestingCenterProps} />
        <DataValidationList {...DataValidationListProps} />
        <SendReportModal {...SendReportModalProps} />
        {/* <InstitutionTypeCom>
          {pageType === 'admin' ? null : <GuardianList {...GuardianListProps} />}
        </InstitutionTypeCom> */}
        {hasTestSummaryPermission ? (
          <DoctorComments {...DoctorCommentsProps} />
        ) : null}
        <AddCommentsModal {...AddCommentsModalProps} />
      </Spin>
    </div>
  );
}

export default connect(({ testDetail, user, loading }) => ({ testDetail, currentUser: user.currentUser, loading }))(TestDetail)
