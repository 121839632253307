import React from 'react'
import QRCode from 'qrcode.react'

function QRCodeComponent({ codeUrl }) {
  if (codeUrl === undefined) {
    return null
  }
  return (
    <div style={{ margin: '0 auto' }}>
      <QRCode value={codeUrl} level={'H'} includeMargin />
    </div>
  )
}
export default QRCodeComponent
