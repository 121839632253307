import { GetChildrenTotalData, GetChildrenAnalysis, GetILSTotalData } from 'services/rocketService/Analysis'
//import { changeTenant } from 'cognitiveleap-core-us/utils/auth'
import moment from 'moment'
import { formatConfig } from '../../config';


export default {
  namespace: 'adminChildrenAnalysis',
  state: {
    currentTag: 'Month',
    datePickerIsOpen: false,
    dateMonthPickerData: moment(),
    dateYearPickerData: moment(),
    analysisData: null,
    keyIndicators: null,
    currentTenant: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *changeTenant({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user);
      const { currentTenant } = yield select(state => state.adminChildrenAnalysis);
      const { canChangeList } = currentUser;
      if (!currentTenant) {
        yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
      } else if (payload) {
        const { id } = payload;
        if (id) {
          const newCurrentTenant = canChangeList.find(item => item.id == id)
          yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
        } else {
          yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
        }
      }
      yield put({ type: 'loadKeyIndicators' });
      yield put({ type: 'loadAnalysisData' })
    },

    *loadKeyIndicators(_, { call, put, select }) {
      const { currentTenant } = yield select(state => state.adminChildrenAnalysis)
      const resList = yield [call(GetChildrenTotalData, { TenantId: currentTenant.id }), call(GetILSTotalData, { TenantId: currentTenant.id })];
      if (resList.every(item => item.response.ok)) {
        const firstRes = resList[0].data
        const twoRes = resList[1].data
        const { enabledOfflineCourseSubjectTotal } = twoRes
        const tempData = {
          ...firstRes,
          enabledOfflineCourseSubjectTotal
        }
        yield put({
          type: 'updateState',
          payload: {
            keyIndicators: tempData,
          }
        })
      }
    },

    *loadAnalysisData(_, { call, put, select }) {
      const { currentTag, dateMonthPickerData, dateYearPickerData, currentTenant } = yield select(state => state.adminChildrenAnalysis);
      const date = {
        "Year": dateYearPickerData,
        "Month": dateMonthPickerData
      }[currentTag];

      const res = yield call(GetChildrenAnalysis, {
        Type: currentTag,
        Year: parseInt(moment(date).format(formatConfig[currentTag].format)),
        Month: parseInt(moment(dateMonthPickerData).format('MM')),
        TenantId: currentTenant.id
      });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            analysisData: res.data
          }
        })
      }
    },

    *handlePanelChange({ payload: { time } }, { call, put, select }) {
      const { datePickerIsOpen, currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.adminChildrenAnalysis);
      yield put({
        type: 'updateState',
        payload: {
          dateMonthPickerData: {
            "Year": dateMonthPickerData,
            "Month": moment(time)
          }[currentTag],
          dateYearPickerData: {
            "Year": moment(time),
            "Month": dateYearPickerData
          }[currentTag],
          datePickerIsOpen: !datePickerIsOpen
        }
      })
      yield put({ type: 'loadAnalysisData' });
    },

    *changeTag({ payload: { key } }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          currentTag: key
        }
      })
      yield put({ type: 'loadAnalysisData' });
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/analysis/children') {
    //       dispatch({ type: 'changeTenant' })
    //     }
    //   })
    // },
  },
}
