
import React, { Fragment } from 'react'
import { connect, history, useIntl } from 'umi'
import { Tabs, Badge, Spin, Tooltip } from 'antd'
import { CheckOutlined } from '@ant-design/icons';
import styles from './index.less'
import InfiniteScroll from 'react-infinite-scroll-component';
import { formatNotificationData, getNotificationCount } from 'utils/utils'
import NotificationContent from 'components/NotificationContent'
const { TabPane } = Tabs;

const Notification = ({ className, notification, dispatch, loading }) => {
    const intl = useIntl()

    const { activeKey, unreadUserCount: UserCount, unreadSystemCount: SystemCount, data, modalVisible, releaseInfo } = notification
    const isLoading = loading.effects['notification/load']

    const tabOption = {
        User: <Badge count={UserCount} offset={[12, 0]}>{intl.formatMessage({ id: 'userMessage' })}</Badge>,
        System: <Badge count={SystemCount} offset={[12, 0]}>{intl.formatMessage({ id: 'systemNotification' })}</Badge>
    }

    const enterNotificationPage = () => {
        dispatch({
            type: 'notification/updateState',
            payload: { visible: false }
        })
        history.push('/system/center/menu/notification/center')
    }

    const handleInfiniteOnLoad = () => {
        if (!isLoading) {
            dispatch({
                type: 'notification/changePagination'
            })
        }
    }


    const onTabClick = (activeKey) => {
        getNotificationCount();
        dispatch({
            type: 'notification/onTabClick',
            payload: { activeKey }
        })
    }

    const checkNotEmpty = (activeKey) => {
        if (activeKey === 'User') return UserCount !== 0
        else return SystemCount !== 0
    }

    const signAllToHasRead = () => {
        if (checkNotEmpty(activeKey)) {
            dispatch({ type: 'notification/signAllToHasRead' })
        }
    }

    const resultData = formatNotificationData(data[activeKey])

    const notificationConentProps = {
        activeKey,
        datasource: resultData,
        isShowHasRead: false,
        type: 'unread',
        showTimeLocation: 'bottom',
        signToRead: (data) => {
            dispatch({
                type: 'notification/signToRead',
                payload: { data }
            })
        },
        showReleaseModal: (data) => {
            dispatch({
                type: 'notification/updateState',
                payload: { visible: false }
            })
            dispatch({
                type: 'notification/updateState',
                payload: { modalVisible: true, releaseInfo: data }
            })
        }
    }

    return (
        <div className={className}>
            <div className={styles.notification}>
                <Spin
                    spinning={isLoading}
                >
                    <Tabs
                        activeKey={activeKey}
                        onTabClick={key => onTabClick(key)}
                        tabBarExtraContent={
                            <div
                                className={styles.notificationTabsIcon}
                                onClick={() => signAllToHasRead()}
                            >
                                <Tooltip
                                    title={intl.formatMessage({ id: 'allMarkedAsRead' })}
                                    placement="bottomLeft"
                                >
                                    <CheckOutlined />
                                </Tooltip>
                            </div>
                        }
                    >
                        <TabPane
                            tab={tabOption.User}
                            key="User"
                            className={styles.notificationContent}
                        >
                            <InfiniteScroll
                                dataLength={data[activeKey].length}
                                hasMore={data[activeKey] ? UserCount > data[activeKey].length : false}
                                height={400}
                            >
                                <NotificationContent {...notificationConentProps} />
                            </InfiniteScroll>
                        </TabPane>
                        <TabPane
                            tab={tabOption.System}
                            key="System"
                            className={styles.notificationContent}
                        >
                            <InfiniteScroll
                                dataLength={data[activeKey].length}
                                hasMore={data[activeKey] ? SystemCount > data[activeKey].length : false}
                                height={400}
                            >
                                <NotificationContent {...notificationConentProps} />
                            </InfiniteScroll>
                        </TabPane>
                    </Tabs>
                </Spin>
                <div className={styles.enterNotification} onClick={() => enterNotificationPage()}>{intl.formatMessage({ id: 'enterMessage' })}</div>
            </div>
        </div>
    )
}

export default connect(({ notification, loading }) => ({ notification, loading }))(Notification)