import {
    GetGetItemsGrouped,
    PostSwitchCourseType
  } from 'services/rocketService/UserOfflinePlan'
import { message } from 'antd'
  
  export default {
    namespace: 'adminuserOfflinePlanDetail',
    state: {
      planItems: [],
      pagination: {
        current: 1,
        pageSize: 9,
        total: 0,
      }
    },
    reducers: {
      updateState(state, { payload }) {
        return {
          ...state,
          ...payload,
        }
      },
    },
    effects: {
      *pageLoading({ payload }, { call, put, select }) {
        yield put({ type: 'updateState', payload: { UserOfflinePlanId: payload.id } })
        yield put({ type: 'loadList' })
      },
  
      *loadList(_, { call, put, select }) {
        const { pagination, UserOfflinePlanId } = yield select(state => state.adminuserOfflinePlanDetail)
        let query = {
          skipCount: 0,
          maxResultCount: 10,
          UserOfflinePlanId,
          currentOnly: false,
        }
  
        const currentPageIndex = pagination.current
        const currentPageSize = pagination.pageSize
        query = {
          ...query,
          skipCount: (currentPageIndex - 1) * currentPageSize,
          maxResultCount: currentPageSize,
        }
        const res = yield call(GetGetItemsGrouped, query)
        if (res.response.ok) {
          const { items, totalCount } = res.data
          const newPagination = {
            current: currentPageIndex,
            pageSize: currentPageSize,
            total: totalCount,
          }
          yield put({ type: 'updateState', payload: { pagination: newPagination, planItems: items } })
        }
      },

      *changeTable({ payload: data }, { call, put, select }) {
        const { pagination } = data
        yield put({ type: 'updateState', payload: { pagination } })
        yield put({ type: 'loadList' })
      },

      *switchCourseType({ payload: data }, { call, put, select }) {
        const res = yield call(PostSwitchCourseType, data)
        if (res.response.ok) {
          message.success('切换成功');
          yield put({ type: 'loadList' })
        }
        else{
          const { data: { error } } = res;
          message.error(error.message);
        }
      }
    },
    
    subscriptions: {
      // setup({ dispatch, history }) {
      //   return history.listen(location => {
      //     if (location.pathname === '/system/manage/userOfflinePlanDetail') {
      //       dispatch({ type: 'pageLoading', payload: location.query })
      //     }
      //   })
      // },
    },
  }
  