import pages from 'utils/pages'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { GetTenantList,PostTenantData,PutTenantData,PrepareForEdit,PostSendEmail,PutTenantState } from 'services/tenant'
import {message} from 'antd'
const { orgList } = pages

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item.id.toString() }
  })
}

const contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'name',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'displayName',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'adminEmail',
      FormKey: 'adminEmail',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
        customValidator: (rule, value, callback) => {
          if (!/^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/.test(value)) {
            callback('请输入有效邮箱')
          } else {
            callback()
          }
        }
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'adminPhone',
      FormKey: 'adminPhone',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: false,
      },
      ShowTitle: 'parentId',
      FormKey: 'parentId',
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Effective',
            Id: 'Effective',
          },
          {
            Value: 'Invalid',
            Id: 'Invalid',
          },
        ],
        Required: true,
      },
      ShowTitle: 'state',
      FormKey: 'state',
      Description: null,
    },
  ],
}

export default {
  namespace: 'orgList',

  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
  },

  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.orgList)
      let query = { skipCount: 0, maxResultCount: 10, Type: 'Enterprise' }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetTenantList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onEdit({ payload: id }, { call, put, select }) {
      const res = yield call(PrepareForEdit, {id, type: 'Enterprise'})
      if (res.response.ok) {
        const { parentTenant, tenant } = res.data
        const { parentTenant: currentParent } = tenant
        const orgOptions = listToOptions(parentTenant, 'displayName')
        let emptyContent = {...contentMenu}
        emptyContent.Properties[4].Setting.DropdownOptions = orgOptions
        const editUserInfoForm = matchDynamicForm(emptyContent, {
          ...tenant,
          parentId: currentParent ? currentParent.id : null,
        })
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: res.data.tenant,
          },
        })
      }
    },

    *onSendEmail({ payload: id }, { call, put, select }) {
      const res = yield call(PostSendEmail, id)
      if (res.response.ok) {
        message.success('发送邮件成功')
        yield put({ type: 'query' })
      }else{
        message.error('发送邮件失败')
      }
    },

    // *onDelete({ payload: id }, { call, put, select }) {
    //   console.log(id)
    //   // const res = yield call(DeletePrepareData, id, 'teacher')
    //   // if (res.response.ok) {
    //   //   yield put({ type: 'query' })
    //   // }
    // },

    *onAdd(_, { call, put, select }) {
      const res = yield call(PrepareForEdit, { type: 'Enterprise' })
      if (res.response.ok) {
        const { parentTenant, tenant } = res.data
        const orgOptions = listToOptions(parentTenant, 'displayName')
        let emptyContent = {...contentMenu}
        emptyContent.Properties[4].Setting.DropdownOptions = orgOptions
        const addForm = matchDynamicForm(emptyContent, { ...tenant })
        yield put({
          type: 'updateState',
          payload: { modalType: 'add', content: addForm, modalVisible: true },
        })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.orgList)
      let res
      if (modalType === 'edit') {
        res = yield call(PutTenantData, { ...selectItem, ...data })
      } else {
        res = yield call(PostTenantData, data, 'Enterprise')
      }
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { modalVisible: false } })
        yield put({ type: 'query' })
      }
      else{
        const {error} = res.data
        message.error(error.message)
      }
    },


  },

  reducers: {

    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === orgList) {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
