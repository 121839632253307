
import React from 'react'
import { Input, Select, Button, DatePicker } from 'antd'
import { useIntl } from 'umi'
import style from './index.less'
import moment from 'moment'

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD';

const SearchComponent = ({ onChange, onSearch, searchOption, isOpenUp, changeState, onClear }) => {
    const intl = useIntl();
    const { userNickName, planName, planType, status, createTime, realStartTime, endTime, courseTime } = searchOption;

    return (
        <div className={style.ilsSearch}>
            <div className={style.ilsSearchTop}>
                <div className={style.ilsSearchItem}>
                    <span className={style.ilsSearchItemLabel}>{intl.formatMessage({ id: 'creatTime' })}</span>
                    <RangePicker
                        onChange={date => onChange('realStartTime', date)}
                        placeholder={[intl.formatMessage({ id: 'startDate' }), intl.formatMessage({ id: 'endDate' })]}
                        disabledDate={(current) => {
                            return current && current > moment()
                        }}
                        value={[
                            realStartTime.startTime ? moment(realStartTime.startTime, dateFormat) : null,
                            realStartTime.endTime ? moment(realStartTime.endTime, dateFormat) : null
                        ]}
                    />
                </div>
                <div className={style.ilsSearchItem}>
                    <Search
                        placeholder={`${intl.formatMessage({ id: 'name' })}`}
                        value={userNickName}
                        onChange={e => onChange('userNickName', e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, minWidth: 220 }}
                    />
                </div>
                <div className={style.ilsSearchItem}>
                    <Search
                        placeholder={`${intl.formatMessage({ id: 'trainingPlanName' })}`}
                        value={planName}
                        onChange={e => onChange('planName', e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, minWidth: 220 }}
                    />
                </div>
                <div className={style.clear}><Button onClick={() => onClear()}>{intl.formatMessage({ id: 'clear' })}</Button></div>
                <div className={style.openUp}>
                    {
                        isOpenUp ?
                            <a onClick={() => { changeState('isOpenUp', false) }}>{intl.formatMessage({ id: 'putItAway' })}</a>
                            :
                            <a onClick={() => { changeState('isOpenUp', true) }}>{intl.formatMessage({ id: 'open' })}</a>
                    }
                </div>
            </div>
            {
                isOpenUp ?
                    <div>
                        <div className={style.ilsSearchCenter}>
                            <div className={style.ilsSearchItem}>
                                <span className={style.ilsSearchItemLabel}>{intl.formatMessage({ id: 'usedSessionsDuration' })}</span>
                                <RangePicker
                                    onChange={date => onChange('courseTime', date)}
                                    placeholder={[intl.formatMessage({ id: 'startDate' }), intl.formatMessage({ id: 'endDate' })]}
                                    disabledDate={(current) => {
                                        return current && current > moment()
                                    }}
                                    value={[
                                        courseTime.startTime ? moment(courseTime.startTime, dateFormat) : null,
                                        courseTime.endTime ? moment(courseTime.endTime, dateFormat) : null
                                    ]}
                                />
                            </div>
                            <div className={style.ilsSearchItem}>
                                <span className={style.ilsSearchItemLabel}>{intl.formatMessage({ id: 'status' })}</span>
                                <Select
                                    allowClear={true}
                                    style={{ minWidth: 220 }}
                                    onChange={value => onChange('status', value)}
                                    value={status || undefined}
                                    placeholder={intl.formatMessage({ id: 'all' })}
                                >
                                    <Option value="Created">{intl.formatMessage({ id: 'created' })}</Option>
                                    <Option value="Started">{intl.formatMessage({ id: 'ongoing' })}</Option>
                                    <Option value="Ended">{intl.formatMessage({ id: 'finished' })}</Option>
                                </Select>
                            </div>
                            <div className={style.ilsSearchItem}>
                                <span className={style.ilsSearchItemLabel}>{intl.formatMessage({ id: 'trainingProgramType' })}</span>
                                <Select
                                    allowClear={true}
                                    style={{ minWidth: 220 }}
                                    onChange={value => onChange('planType', value)}
                                    value={planType || undefined}
                                    placeholder={intl.formatMessage({ id: 'all' })}
                                >
                                    <Option value="SenseAudio">{intl.formatMessage({ id: 'multiSensoryTraining' })}</Option>
                                    <Option value="BrainAudio">{intl.formatMessage({ id: 'HallowellFocusTraining' })}</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default SearchComponent