import { Modal, Form, Radio, Button } from 'antd'
import styles from './index.less'
const FormItem = Form.Item

const CategorySelectModal = ({
  list,
  visible,
  onCancel,
  onOk
}) => {
  const onSubmit = (values) => {
    onOk(values)
  }

  const onQuit = () => {
    onCancel()
  }

  return (
    <Modal
      title={'请先选择一个训练类别'}
      open={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onQuit}
      footer={null}
    >
      <Form
        layout="horizontal"
        onFinish={onSubmit}
      >
        <FormItem
          label={'训练类别'}
          name={'categoryId'}
          initialValue={null}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            {list.map(item => {
              return (
                <Radio key={item.id} value={item.id}>
                  {item.name}
                </Radio>
              )
            })}
          </Radio.Group>
        </FormItem>
        <div className={styles.footer}>
          <Button className={styles.footerLeft} onClick={onQuit}>取消</Button>
          <Button type="primary" htmlType="submit">
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CategorySelectModal
