import { Row, Col, List, Card } from 'antd'
import moment from 'moment'
import { useIntl } from 'umi'
const ListItem = List.Item

const DoctorComments = ({ pageType, hasWritePermission, hasTestSummaryViewPermission, commentsList, onShowCommentModal, onEditComment, vratProjectStatus, isBind, isLogout,name }) => {
  const intl = useIntl()
  const ListProps = {
    itemLayout: 'horizontal',
    dataSource: commentsList,
    renderItem(item) {
      const { Questionnaire, UpdateTime, Id, DoctorName } = item
      const { DisplayName } = Questionnaire
      const showTime =
        moment
          .utc(UpdateTime)
          .local()
          .format('MM/DD/YYYY HH:mm') + ` ${intl.formatMessage({ id: 'edited' })}`

      const actions = []
      if (hasWritePermission && name !== 'admin') actions.push(
        <a onClick={() => onEditComment(Id, false)}>
          {intl.formatMessage({ id: 'edit' })}
        </a>
      )
      if (hasTestSummaryViewPermission || name === 'agent') actions.push(
        <a onClick={() => onEditComment(Id, true)}>
          {intl.formatMessage({ id: 'see' })}
        </a>
      )
      return (
        <ListItem
          key={Id}
          actions={actions}
        >
          <ListItem.Meta title={DisplayName} />
          <div>{showTime}</div>
        </ListItem>
      )
    },
    locale: {
      emptyText: intl.formatMessage({ id: 'noContent' }),
    },
  }

  return (
    <Card style={{ marginTop: 18 }}>
      <Row type="flex" align="bottom" style={{ marginRight: 24 }}>
        <Col span={20}>
          <h3 style={{ fontSize: 18 }}>{intl.formatMessage({ id: 'testSummary' })}</h3>
        </Col>
        {hasWritePermission && vratProjectStatus && isBind && !isLogout && name !== 'admin'? (
          <Col span={4}>
            <div>
              <a style={{ float: 'right' }} onClick={onShowCommentModal}>
                {intl.formatMessage({ id: 'add'})}
              </a>
            </div>
          </Col>
        ) : null}
      </Row>

      <div style={{ marginLeft: 16, marginRight: 16 }}>
        <List {...ListProps} />
      </div>
    </Card>
  )
}

export default DoctorComments
