import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Popconfirm } from 'antd'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import styles from './index.less'

const modalTypeToTitle = {
    edit: '编辑B端版本',
    add: '添加B端版本',
}

const BVersion = ({ bVersion, dispatch, loading }) => {

    useEffect(() => {
        dispatch({ type: 'bVersion/query' })
    }, [])

    const { modalType, modalVisible, content, list, pagination, selectItem } = bVersion

    const onAddClick = () => {
        dispatch({ type: 'bVersion/onAdd' })
    }

    const listSupport = {
        version: {
            showText: '版本号',
            showType: 'Text',
        },
        summary: {
            showText: '摘要',
            showType: 'html',
        },
        tenantCount: {
            showText: '中心数量',
            showType: 'Text',
        },
        send: {
            showText: '发邮件',
            showType: 'Bool',
        },
        subject: {
            showText: '邮件标题',
            showType: 'Text',
        },
        status: {
            showText: '发布状态',
            showType: 'Bool',
            addtional: {
                boolArray: ['未发布', '已发布']
            }
        },
        releaseTime: {
            showText: '发布时间',
            showType: 'Time',
            addtional: {
                format: 'YYYY-MM-DD HH:mm'
            }
        }
    }

    const modalProps = {
        okDisabled: selectItem && selectItem.status,
        loading: loading.effects['bVersion/onSubmitModal'],
        title: modalTypeToTitle[modalType],
        visible: modalVisible,
        onCancel() {
            dispatch({ type: 'bVersion/updateState', payload: { modalVisible: false } })
        },
        onOk(value) {
            dispatch({
                type: 'bVersion/onSubmitModal',
                payload: value,
            })
        },
        content,
    }
    const tableProps = {
        loading: loading.effects['bVersion/query'] || loading.effects['bVersion/release'],
        listData: list.map(item => {
            const { status, releaseTime } = item
            return {
                ...item,
                key: item.id,
                tenantCount: item.tenantIds.length,
                releaseTime: status ? releaseTime : null
            }
        }),
        listSupport,
        pagination,
        scroll: true,
        onTableChange(pagi, fil = {}, sort = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) {
                data = { ...data, pagination: pagi }
            }
            dispatch({ type: 'bVersion/changeTable', payload: data })
        },
        Actions: [
            {
                showText: '编辑',
                itemRender(record) {
                    const { id, status } = record
                    return !status ? (
                        <a onClick={() => dispatch({ type: 'bVersion/onEdit', payload: record })}>编辑</a>
                    ) : null
                }
            },
            {
                showText: '发布',
                itemRender(record) {
                    const { id, status } = record
                    return !status ? (
                        <Popconfirm placement="top" title={'确定发布吗？'} onConfirm={() => dispatch({ type: 'bVersion/release', payload: record })} okText="确定" cancelText="取消">
                            <a>发布</a>
                        </Popconfirm>
                    ) : null
                }
            },
            {
                showText: '查看',
                itemRender(record) {
                    const { id, status } = record
                    return status ? (
                        <a onClick={() => dispatch({ type: 'bVersion/onEdit', payload: record })}>查看</a>
                    ) : null
                }
            },
        ],
    }

    return (
        <div className={styles.bVersion}>
            <h1>B端版本</h1>
            <Button onClick={onAddClick}>添加新版本</Button>
            <MagicTable {...tableProps} />
            <MagicFormModal {...modalProps} />
        </div>
    )
}

export default connect(({ bVersion, loading }) => ({ bVersion, loading }))(BVersion)