import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const radarChart = ({ data }) => {
  const intl = useIntl();

  const showdata = data;
  if (!showdata) return <div />;

  const {
    Attention,
    ContinuousAttention,
    Sensitivity,
    ReactionStability,
    Reactivity,
    SelfControl,
  } = showdata || {};

  const _Attention = Math.round(Attention * 100);
  const _ContinuousAttention = Math.round(ContinuousAttention * 100);
  const _Sensitivity = Math.round(Sensitivity * 100);
  const _ReactionStability = Math.round(ReactionStability * 100);
  const _Reactivity = Math.round(Reactivity * 100);
  const _SelfControl = Math.round(SelfControl * 100);

  const option = {
    tooltip: {
      show: false,
    },
    grid: {
      bottom: '0',
      height: '50%',
    },
    legend: {
      data: [
        intl.formatMessage({ id: 'radarChart.percentile' }),
        intl.formatMessage({ id: 'Charts.You' }),
      ],
      top: -5,
    },
    splitNumber: 3,
    radar: {
      silent: true,
      name: {
        textStyle: {
          color: '#000',
          fontSize: 12,
          fontWeight: '600',
          lineHeight: 16,
        },
      },
      indicator: [
        {
          name: intl.formatMessage(
            { id: 'radarChart.focused' },
            { _Attention },
          ),
          max: 100,
        },
        {
          name: intl.formatMessage(
            { id: 'radarChart.mental' },
            { _ContinuousAttention },
          ),
          max: 100,
        },
        {
          name: intl.formatMessage(
            { id: 'radarChart.discriminability' },
            { _Sensitivity },
          ),
          max: 100,
        },
        {
          name: intl.formatMessage(
            { id: 'radarChart.stability' },
            { _ReactionStability },
          ),
          max: 100,
        },
        {
          name: intl.formatMessage({ id: 'radarChart.speed' }, { _Reactivity }),
          max: 100,
        },
        {
          name: intl.formatMessage({ id: 'radarChart.self' }, { _SelfControl }),
          max: 100,
        },
      ],
      splitArea: {
        areaStyle: {
          color: '#fff',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#fff',
        },
      },
      splitLine: {
        lineStyle: {
          color: ['#999', '#fff', '#fff'],
        },
      },
      splitNumber: 3,
      center: ['50%', '53%'],
      axisLabel: {
        align: 'center',
      },
      nameGap: 10,
    },
    series: [
      {
        name: 'Standard vs Your',
        type: 'radar',
        areaStyle: {
          color: '#fff',
        },
        silent: true,
        data: [
          {
            value: [50, 50, 50, 50, 50, 50],
            name: intl.formatMessage({ id: 'radarChart.percentile' }),
            silent: false,
            areaStyle: {
              color: '#EEA9A9',
            },
            lineStyle: {
              color: '#DF6161',
              type: 'dashed',
            },
            itemStyle: {
              color: '#EEA9A9',
            },
            symbolSize: 1,
          },
          {
            value: [
              _Attention,
              _ContinuousAttention,
              _Sensitivity,
              _ReactionStability,
              _Reactivity,
              _SelfControl,
            ],
            name: intl.formatMessage({ id: 'Charts.You' }),
            silent: true,
            areaStyle: {
              color: '#36abd3',
            },
            itemStyle: {
              color: '#36abd3',
            },
            label: {
              show: false,
            },
            symbolSize: 7,
          },
        ],
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{
        height: '430px',
        width: '100%',
      }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default radarChart;
