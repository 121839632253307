
export default {
    namespace: 'showVideo',
    state: {
        id: null,
        url: null
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/showVideo') {
        //             dispatch({ type: 'updateState', payload: location.query })
        //         }
        //     })
        // },
    },
}
