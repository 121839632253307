import getRequest from 'utils/request'
import { examUrl } from 'utils/config'

const PostQuestionListApi = '/api/examination/question/list'
const getQuestionApi = '/api/examination/question'
const getQuestimeClassApi = '/api/examination/questionClassify'
const postStatusApi = '/api/examination/question/status'
const delClassApi = '/api/examination/questionClassify/{id}'
const getCountApi = '/api/examination/question/classifyCount'

export async function GetQuestion(data) {
    return getRequest({ myBaseURL: examUrl, credentials: false })(PostQuestionListApi, {
        method: 'POST',
        data
    })
}

export async function GetCount() {
    return getRequest({ myBaseURL: examUrl, credentials: false })(getCountApi, {
        method: 'GET'
    })
}

export async function PostQuestion(data) {
    return getRequest({ myBaseURL: examUrl, credentials: false })(getQuestionApi, {
        method: 'POST',
        data
    })
}

export async function PutQuestion(data) {
    return getRequest({ myBaseURL: examUrl, credentials: false })(getQuestionApi, {
        method: 'PUT',
        data
    })
}


export async function PutClassName(data) {
    return getRequest({ myBaseURL: examUrl, credentials: false })(getQuestimeClassApi, {
        method: 'PUT',
        data
    })
}

export async function GetQuestionClass() {
    return getRequest({ myBaseURL: examUrl, credentials: false })(getQuestimeClassApi, {
        method: 'GET',
    })
}

export async function PostAddClass(data) {
    return getRequest({ myBaseURL: examUrl, credentials: false })(getQuestimeClassApi, {
        method: 'POST',
        data
    })
}


export async function PostStatus(data) {
    return getRequest({ myBaseURL: examUrl, credentials: false })(postStatusApi, {
        method: 'PUT',
        data
    })
}

export async function DelClass(id) {
    return getRequest({ myBaseURL: examUrl, credentials: false })(delClassApi.replace('{id}', id), {
        method: 'DELETE'
    })
}
