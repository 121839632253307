import { useState, useEffect } from 'react';
import { Card, Button, message, Space, Modal } from 'antd';
import {
  CaretLeftOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import CenterListModal from './CenterListModal';
import { history } from 'umi';

const confirm = Modal.confirm;

import { GetGrantedCenters, DeleteTemplateOfCenter } from 'services/adhd';

const listSupport = {
  tenantName: {
    showText: 'centerName',
    showType: 'Text',
    render: (text, record) => {
      const { tenantId } = record || {};
      return (
        <a
          onClick={(e) => {
            e.stopPropagation();
            history.push({
              pathname: '/system/manage/centerDetail',
              query: {
                id: tenantId,
              },
            });
          }}
        >
          {text}
        </a>
      );
    },
  },
  addTime: {
    showText: 'addTime',
    showType: 'Time',
    addtional: {
      format: 'YYYY-MM-DD HH:mm',
    },
  },
};

const TemplateCenters = ({ location }) => {
  const { id, title } = location.query || {};

  const [dataSource, setDataSource] = useState([]);
  const [modalVisible, setModalVisible] = useState({
    addCentersModal: false,
    sortOrderModal: false,
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    modalLoading: false,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetGrantedCenters({
      TemplateId: id,
      SkipCount: 0,
      MaxResultCount: 20,
    });

    if (res.response.ok) {
      const { items = [] } = res.data || {};
      setDataSource(items);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const tableProps = {
    listData: dataSource,
    listSupport,
    loading: loading.tableLoading,
    Actions: [
      {
        showText: 'Delete',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                confirm({
                  title: 'Are you sure to delete?',
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <span style={{ color: 'red' }}>
                      It won't be available in this center after deletion.
                    </span>
                  ),
                  okText: 'Delete',
                  onOk: async () => {
                    const { tenantId } = record || {};
                    const res = await DeleteTemplateOfCenter({
                      templateId: id,
                      tenantId,
                    });
                    initData();
                    if (res.response.ok) {
                      initData();
                      message.success('Delete successfully');
                    } else {
                      const { error } = res.data;
                      message.error(error.message);
                    }
                  },
                });
              }}
            >
              Delete
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    resourceId: id,
    visible: modalVisible.addCentersModal,
    selectData: dataSource,
    onClose: () => {
      setModalVisible({
        ...modalVisible,
        addCentersModal: false,
      });
    },
    reloadTable: initData,
  };

  return (
    <Card
      title={
        <Space>
          <Button icon={<CaretLeftOutlined />} onClick={() => history.goBack()}>
            Back
          </Button>
          <b style={{ fontSize: '20px' }}>{title}</b>
        </Space>
      }
      bordered={false}
      extra={
        <Button
          type="primary"
          onClick={() => {
            setModalVisible({
              ...modalVisible,
              addCentersModal: true,
            });
          }}
        >
          Add
        </Button>
      }
    >
      <MagicTable {...tableProps} />
      {modalVisible.addCentersModal && <CenterListModal {...modalProps} />}
    </Card>
  );
};

export default TemplateCenters;
