import { GetHfsOrderList, PostDownLoadExcel } from 'services/contract'
import { message } from 'antd'
import download from 'cognitiveleap-core-us/utils/download'
import moment from 'moment'

export default {
  namespace: 'adminhfsOrder',
  state: {
    isOpenUp: false,
    currentTenant: null,
    Filter: '',
    Type: '',
    StartDate: '',
    EndDate: '',
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    orderList: []
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query({ payload }, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user);
      const { currentTenant } = yield select(state => state.adminhfsOrder);
      const { canChangeList } = currentUser;
      if (!currentTenant) {
        yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
      } else if (payload) {
        const { id } = payload;
        if (id) {
          const newCurrentTenant = canChangeList.find(item => item.id == id)
          yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
        } else {
          yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
        }
      }
      yield put({ type: 'getOrderList' })
    },

    *getOrderList(_, { call, put, take, select }) {
      const { pagination, currentTenant, Filter, Type, StartDate, EndDate } = yield select(state => state.adminhfsOrder)
      const { current, pageSize } = pagination
      const params = {
        TenantId: currentTenant.id,
        Filter,
        Type: Type || '',
        StartDate,
        EndDate,
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize
      }
      const res = yield call(GetHfsOrderList, params)
      if (res.response.ok) {
        const { totalCount, items } = res.data
        yield put({
          type: 'updateState',
          payload: {
            pagination: { ...pagination, total: totalCount },
            orderList: items
          }
        })
      }
    },

    *onClear(_, { call, put, take, select }) {
      yield put({
        type: 'updateState',
        payload: {
          Filter: '',
          Type: '',
          StartDate: '',
          EndDate: '',
          pagination: {
            current: 1,
            pageSize: 10,
            total: 0
          }
        }
      })
      yield put({ type: 'getOrderList' })
    },

    *onTableChange({payload}, { call, put, take, select }){
      const { pagination } = yield select(state => state.adminhfsOrder)
      const { pagination: newPagination } = payload
      yield put({ type: 'updateState', payload: { pagination: { ...pagination, ...newPagination } } })
      yield put({ type: 'getOrderList' })
    },

    *downloadExcel({ payload }, { call, put, take, select }) {
      const { Filter, Type, StartDate, EndDate, currentTenant } = yield select(state => state.adminhfsOrder)
      const params = {
        Filter,
        Type: Type || '',
        StartDate,
        EndDate,
        TenantId: currentTenant.id,
      }
      const hide = message.loading('导出列表中,请稍等..', 0);
      const res = yield call(PostDownLoadExcel, params)
      if (res.response.ok) {
        const { response } = res;
        response.blob().then(blob => {
          download(blob, `订单列表+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
        })
      }
      setTimeout(hide, 0)
    }

  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/hfs/order') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
