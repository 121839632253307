import React, { useEffect, useCallback } from 'react';
import { connect, history } from 'umi';
import MusicPlayer from 'components/MusicPlayer'
import styles from './Layout.less'
import { Modal, notification } from 'antd'
import { CloseOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import MusicPlayerDrawer from 'components/MusicPlayerDrawer';
import TestMusicModal from 'components/TestMusicModal'
import NewTrainModuleModal from 'components/NewTrainModuleModal'
import NewTrainModuleModal2 from 'components/NewTrainModuleModal2'
import { IntlManager } from 'utils/helper'
import { showMusicStatusMsg } from 'utils/utils'

const BlankLayout = ({
    children,
    global,
    showGlobalMusicplayer,
    musicPlayerDrawerVisible,
    dispatch,
    user
}) => {
    const intl = IntlManager.MyIntl()

    const { newTrainModuleModalVisible, newTrainModuleModalVisible2 } = user
    const musicPlayerDrawerProps = {
        visible: musicPlayerDrawerVisible,
        onClose: () => {
            dispatch({
                type: 'musicplayer/updateState',
                payload: {
                    musicPlayerDrawerVisible: false,
                    showGlobalMusicplayer: true,
                }
            })
        }
    }

    const NewTrainModuleModalProps = {
        visible: newTrainModuleModalVisible,
        oncancel() {
            dispatch({
                type: 'user/updateState',
                payload: {
                    newTrainModuleModalVisible: false,
                    newTrainModuleModalVisible2: true
                }
            })
        },
        onok() {
            history.push("/system/center/menu/personalCenter")
            dispatch({
                type: 'user/updateState',
                payload: {
                    newTrainModuleModalVisible: false,
                }
            })
        }
    }

    const NewTrainModuleModalProps2 = {
        visible: newTrainModuleModalVisible2,
        oncancel() {
            dispatch({
                type: 'user/updateState',
                payload: {
                    newTrainModuleModalVisible2: false,
                }
            })
        },
        onok() {
            dispatch({
                type: 'user/updateState',
                payload: {
                    newTrainModuleModalVisible2: false,
                }
            })
        }
    }

    // const updateOnline = useCallback(() => {
    //     if (navigator.onLine) {
    //         dispatch({ type: 'musicplayer/changeMusicStatus', payload: { status: true } })
    //     } else {
    //         dispatch({ type: 'musicplayer/changeMusicStatus', payload: { status: false, needSubmitProgress: false, canRequest: false } })
    //         notification.destroy()
    //         showMusicStatusMsg(IntlManager.intl, 'error')
    //     }
    // }, [])

    // useEffect(() => {
    //     if (showGlobalMusicplayer || musicPlayerDrawerVisible) {
    //         // 监听网络状态
    //         window.addEventListener('online', updateOnline);
    //         window.addEventListener('offline', updateOnline);
    //     } else {
    //         window.removeEventListener('online', updateOnline)
    //         window.removeEventListener('offline', updateOnline)
    //     }
    // }, [showGlobalMusicplayer, musicPlayerDrawerVisible])

    return (
        <div style={{ position: 'relative' }}>
            <div>{children}</div>
            <div className={showGlobalMusicplayer ? styles.GlobalMusicPlayer : styles.GlobalMusicPlayerNone}>
                <div className={styles.GlobalMusicPlayerCloseIcon}>
                    <CloseOutlined
                        onClick={() => {
                            Modal.confirm({
                                title: intl('sureCloseMusic'),
                                okText: intl('close'),
                                cancelText: intl('cancel'),
                                onOk: () => {
                                    dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' });
                                },
                                onCancel() {
                                },
                            });
                        }}
                    />
                </div>
                <div className={styles.GlobalMusicPlayerMenuIcon}>
                    <MenuUnfoldOutlined onClick={() => { dispatch({ type: 'musicplayer/updateState', payload: { musicPlayerDrawerVisible: true } }) }} />
                </div>
                <MusicPlayer />
            </div>
            <MusicPlayerDrawer {...musicPlayerDrawerProps} />
            <TestMusicModal />
            {/* 用户被安排了新的培训模块的弹框 */}
            <NewTrainModuleModal {...NewTrainModuleModalProps} />
            <NewTrainModuleModal2 {...NewTrainModuleModalProps2} />
        </div>
    )
}

export default connect(({ loading, global, musicplayer, user }) => ({
    loading: loading.models.global,
    global,
    showGlobalMusicplayer: musicplayer.showGlobalMusicplayer,
    musicPlayerDrawerVisible: musicplayer.musicPlayerDrawerVisible,
    planInfo: musicplayer.planInfo,
    user
}))(BlankLayout)
