import getRequest from 'utils/request'

const AddOrUpdateBApi = '/api/business/webInformation'
const PrepareForEditApi = '/api/business/webInformation/prepareForEdit'
const ReleaseApi = '/api/business/webInformation/release/{webId}'

export async function AddOrUpdateB(data, type) {
    return getRequest()(AddOrUpdateBApi, {
        method: {
            add: 'POST',
            edit: 'PUT'
        }[type],
        data,
    })
}

export async function GetBApi(data) {
    return getRequest()(AddOrUpdateBApi, {
        method: 'GET',
        params: data
    })
}

export async function PrepareForEdit(data) {
    return getRequest()(PrepareForEditApi, {
        method: 'POST',
        data,
    })
}

export async function Release(id){
    return getRequest()(ReleaseApi.replace('{webId}',id), {
        method: 'POST',
        data: { webId: id },
    })
}