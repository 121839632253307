import React from 'react'
import { useIntl, getLocale } from 'umi'
import { Row, Col, Divider, Badge } from 'antd'
import styles from './Report.less'
import LineWithIconChart from 'components/VratChartComponents/LineWithIconChart'
// import RealTimePerformanceLegend from 'components/VratChartComponents/RealTimePerformanceLegend'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const RealTimePerformance = ({ data, showNewReport }) => {
  const Motion = data ? data.Motion : []
  const Behaviour = data ? data.Behaviour : []

  const intl = useIntl()
  const isChinese = getLocale() === 'zh-CN'

  const standard = 52

  //[ Impulsive, Inattentive, Attentive ]

  function inattentiveCount(arr, type) {
    var total = 0
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].Type === type) {
        total += 1
      }
    }
    return total
  }

  const percent = Behaviour && Behaviour.length > 0 ? `${(standard - inattentiveCount(Behaviour, 1))}` : '0'
  const inattentive = inattentiveCount(Behaviour, 1)
  const impulsive = inattentiveCount(Behaviour, 0)

  const LineWithIconChartProps = {
    dataStruct: {
      Motion,
      Behaviour,
    },
  }

  const descData = [
    {
      descE: intl.formatMessage({ id: 'report.realTime.desc1' }),
      descC: '正确次数',
      descNum: percent,
    },
    {
      descE: intl.formatMessage({ id: 'report.realTime.desc2' }),
      descC: '漏击次数 ',
      descNum: inattentive,
    },
    {
      descE: intl.formatMessage({ id: 'report.realTime.desc3' }),
      descC: '错击次数 ',
      descNum: impulsive,
    },
  ]

  const showDynamic = (isCN, time, desc, color) => {
    const showTime = time > 1 ? 'times' : 'time'
    return isCN ? (
      <div>
        <Badge style={{ color: '#333' }} status="default" />
        <span style={{ color: '#333' }}>{desc}</span>
        <span style={{ color: color, fontSize: 25, fontWeight: 700 }}>{time}</span>
      </div>
    ) : (
      <div>
        <Badge style={{ color: '#333' }} status="default" />
        <span style={{ color: color, fontSize: 25, fontWeight: 700 }}>{time} </span>
        <span style={{ color: '#333' }}>{desc.replace('time', showTime)}</span>
      </div>
    )
  }

  const color = {
    green: '#00A854',
    yellow: '#FFCE3D',
    orange: '#F77B28',
  }

  return (
    <div id="realTime" className={styles.page_div}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.realTime.title' })}</p>
      </h2>
      <Row type="flex" justify="center" style={{ marginTop: 60 }}>
        <Col span={23}>
          <LineWithIconChart {...LineWithIconChartProps} />
        </Col>
        <Col span={24} style={{ marginTop: 5, marginBottom: 20 }} className={styles.brainPic}>
          {/* <RealTimePerformanceLegend styles={styles} /> */}
        </Col>
        <Col span={24} style={{ position: 'relative' }}>
          <h2 className={`${styles.brainPic} ${styles.weightFont}`} style={{ marginTop: -10 }}>
            {intl.formatMessage({ id: 'report.realTime.chart.title' })}
          </h2>
        </Col>
        <Col span={23}>
          <Divider style={{ width: 800 }} type="horizontal" className={styles.divider} />
        </Col>
        <Col span={20}>
          <Row type="flex" justify="center">
            <Col span={18}>
              <div>
                <Badge style={{ color: '#333' }} status="default" />
                <span style={{ color: '#333' }}>{descData[0].descE}</span>
                <span style={{ color: color.green, fontSize: 30, fontWeight: 700 }}>
                  {descData[0].descNum}
                </span>
                <span> / 52</span>
              </div>
              {showDynamic(isChinese, descData[1].descNum, descData[1].descE, color.yellow)}
              {showDynamic(isChinese, descData[2].descNum, descData[2].descE, color.orange)}
            </Col>
          </Row>
        </Col>
      </Row>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '3' : '3'}</p>
    </div>
  )
}

export default RealTimePerformance
