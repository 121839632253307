import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderContent from './components/HeaderContent';
import CPTChart from 'components/NewVratChartComponents/CPTChart';
import BottomPageNum from './components/BottomPageNum';
import { useIntl } from 'umi';

const MentalEndurance = ({ data }) => {
  const intl = useIntl();
  const showData = data ? data : [];

  const CPTChartProps = {
    data: showData,
  };

  const headerContentProps = {
    color: '#00C0D8',
    title: intl.formatMessage({ id: 'MentalEndurance.title' }),
    content: (
      <div className={styles.attentionScoresContent}>
        <div>
          {intl.formatMessage({ id: 'MentalEndurance.progression' })}
        </div>
      </div>
    ),
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.mentalEndurance}>
        <HeaderContent {...headerContentProps} />
        <div className={styles.centerContent}>
          <div className={styles.title}>
            {intl.formatMessage({ id: 'MentalEndurance.attention' })}
          </div>
          <CPTChart {...CPTChartProps} />
        </div>
        <BottomPageNum num={10} />
        <BottomDes color="#00C0D8" />
      </div>
    </div>
  );
};

export default MentalEndurance;
