import React from 'react'
import { Button } from 'antd'

export default class Clock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      countDown: 59,
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  tick() {
    if (this.state.countDown > 1) {
      this.setState({
        countDown: this.state.countDown - 1,
      })
    } else {
      const { onComplete } = this.props
      if (onComplete) onComplete()
    }
  }

  render() {
    const {intl} = this.props
    const { countDown } = this.state
    return <Button disabled>{`${countDown}${intl.formatMessage({ id: 'resendAfterSeconds' })}`}</Button>
  }
}
