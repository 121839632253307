import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';
import config from 'utils/config';
const { baseURL, uploadPdtImage } = config;

export const categoryContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'Category',
      FormKey: 'categoryName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    // {
    //     EditorType: 'SingleWithSearch',
    //     Value: null,
    //     FormKey: 'Match',
    //     ShowTitle: 'Matching Food group on plate',
    //     Setting: {
    //         DropdownOptions: [
    //             {
    //                 Value: 'Vegetables',
    //                 Id: 'vegetables',
    //             },
    //             {
    //                 Value: 'Protein',
    //                 Id: 'protein',
    //             },
    //             {
    //                 Value: 'Grains',
    //                 Id: 'grains'
    //             },
    //             {
    //                 Value: 'Fruit',
    //                 Id: 'fruit'
    //             }
    //         ],
    //         Required: true,
    //     },
    //     Description: null,
    // },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        Action: baseURL + uploadPdtImage,
        Header: getAuthHeader(),
        Required: true,
        UploadSetting: {
          isSingle: true,
        },
      },
      ShowTitle: 'Picture',
      FormKey: 'categoryUrl',
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: 'Order',
      FormKey: 'order',
      AdditionalData: null,
      Value: 0,
      Setting: {
        min: 0,
        step: 1,
        Required: true,
      },
      Description: null,
    },
    // {
    //     EditorType: 'Bool',
    //     Value: false,
    //     Setting: {
    //         Required: true,
    //     },
    //     ShowTitle: 'ShowCaffeine',
    //     FormKey: 'showCaffeine',
    //     Description: null,
    // },
  ],
};

export const orderContentMenu = {
  Properties: [
    {
      EditorType: 'NumberInput',
      ShowTitle: 'Order',
      FormKey: 'Order',
      AdditionalData: null,
      Value: 0,
      Setting: {
        min: 0,
        step: 1,
        Required: true,
      },
      Description: null,
    },
  ],
};

export const configContentMenu = {
  Properties: [
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      FormKey: 'measurement',
      ShowTitle: 'Unit',
      Setting: {
        DropdownOptions: [
          {
            Value: 'Fist',
            Id: 'Fist',
          },
          {
            Value: 'Palm',
            Id: 'Palm',
          },
          {
            Value: '1/4 Palm',
            Id: '1/4 Palm',
          },
          {
            Value: 'Cup (8 oz)',
            Id: 'Cup (8 oz)',
          },
        ],
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      FormKey: 'matchingFoodGroupOnPlantName',
      ShowTitle: 'Matching Food group on plate',
      Setting: {
        DropdownOptions: [
          {
            Value: 'Vegetables',
            Id: 'Vegetables',
          },
          {
            Value: 'Protein',
            Id: 'Protein',
          },
          {
            Value: 'Grains',
            Id: 'Grains',
          },
          {
            Value: 'Fruit',
            Id: 'Fruit',
          },
          {
            Value: 'Beverages',
            Id: 'Beverages',
          },
        ],
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Red algorithm',
            Id: 'Red',
          },
          {
            Value: 'Yellow algorithm',
            Id: 'Yellow',
          },
          {
            Value: 'Green algorithm',
            Id: 'Green',
          },
        ],
        Required: true,
      },
      ShowTitle: '1 serving',
      FormKey: 'one',
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Red algorithm',
            Id: 'Red',
          },
          {
            Value: 'Yellow algorithm',
            Id: 'Yellow',
          },
          {
            Value: 'Green algorithm',
            Id: 'Green',
          },
        ],
        Required: true,
      },
      ShowTitle: '2 servings',
      FormKey: 'two',
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Red algorithm',
            Id: 'Red',
          },
          {
            Value: 'Yellow algorithm',
            Id: 'Yellow',
          },
          {
            Value: 'Green algorithm',
            Id: 'Green',
          },
        ],
        Required: true,
      },
      ShowTitle: '3 servings',
      FormKey: 'three',
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Red algorithm',
            Id: 'Red',
          },
          {
            Value: 'Yellow algorithm',
            Id: 'Yellow',
          },
          {
            Value: 'Green algorithm',
            Id: 'Green',
          },
        ],
        Required: true,
      },
      ShowTitle: '4 servings',
      FormKey: 'four',
      Description: null,
    },
    // {
    //     EditorType: 'CheckboxSelector',
    //     Value: null,
    //     Setting: {
    //         DropdownOptions: [
    //             {
    //                 Value: '1',
    //                 Id: 'one'
    //             },
    //             {
    //                 Value: '2',
    //                 Id: 'two'
    //             },
    //             {
    //                 Value: '3',
    //                 Id: 'three'
    //             },
    //             {
    //                 Value: '4',
    //                 Id: 'four'
    //             },
    //         ],
    //         Required: true,
    //     },
    //     ShowTitle: 'Red algorithm',
    //     FormKey: 'redAlgorithm',
    //     Description: null,
    // },
    // {
    //     EditorType: 'CheckboxSelector',
    //     Value: null,
    //     Setting: {
    //         DropdownOptions: [
    //             {
    //                 Value: '1',
    //                 Id: 'one'
    //             },
    //             {
    //                 Value: '2',
    //                 Id: 'two'
    //             },
    //             {
    //                 Value: '3',
    //                 Id: 'three'
    //             },
    //             {
    //                 Value: '4',
    //                 Id: 'four'
    //             },
    //         ],
    //         Required: true,
    //     },
    //     ShowTitle: 'Yellow algorithm',
    //     FormKey: 'yellowAlgorithm',
    //     Description: null,
    // },
    // {
    //     EditorType: 'CheckboxSelector',
    //     Value: null,
    //     Setting: {
    //         DropdownOptions: [
    //             {
    //                 Value: '1',
    //                 Id: 'one'
    //             },
    //             {
    //                 Value: '2',
    //                 Id: 'two'
    //             },
    //             {
    //                 Value: '3',
    //                 Id: 'three'
    //             },
    //             {
    //                 Value: '4',
    //                 Id: 'four'
    //             },
    //         ],
    //         Required: true,
    //     },
    //     ShowTitle: 'Green algorithm',
    //     FormKey: 'greenAlgorithm',
    //     Description: null,
    // },
  ],
};
export const foodContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'Food',
      FormKey: 'Food',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      FormKey: 'Unit',
      ShowTitle: 'Unit',
      Setting: {
        DropdownOptions: [
          {
            Value: 'Fist',
            Id: 'Fist',
          },
          {
            Value: 'Palm',
            Id: 'Palm',
          },
          {
            Value: '1/4 Palm',
            Id: '1/4 Palm',
          },
          {
            Value: 'Cup (8 oz)',
            Id: 'Cup (8 oz)',
          },
        ],
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'CheckboxSelector',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: '1',
            Id: 'one',
          },
          {
            Value: '2',
            Id: 'two',
          },
          {
            Value: '3',
            Id: 'three',
          },
          {
            Value: '4',
            Id: 'fore',
          },
        ],
        Required: true,
      },
      ShowTitle: 'Red algorithm',
      FormKey: 'Red',
      Description: null,
    },
    {
      EditorType: 'CheckboxSelector',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: '1',
            Id: 'one',
          },
          {
            Value: '2',
            Id: 'two',
          },
          {
            Value: '3',
            Id: 'three',
          },
          {
            Value: '4',
            Id: 'fore',
          },
        ],
        Required: true,
      },
      ShowTitle: 'Yellow algorithm',
      FormKey: 'Yellow',
      Description: null,
    },
    {
      EditorType: 'CheckboxSelector',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: '1',
            Id: 'one',
          },
          {
            Value: '2',
            Id: 'two',
          },
          {
            Value: '3',
            Id: 'three',
          },
          {
            Value: '4',
            Id: 'fore',
          },
        ],
        Required: true,
      },
      ShowTitle: 'Green algorithm',
      FormKey: 'Green',
      Description: null,
    },
  ],
};
