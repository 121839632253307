import { PrepareSubject, CreateUserAndSubject, SendRegisterCode, CheckSubject } from 'services/userManager'
import { message, Modal } from 'antd'
import { addOptionsToProperties } from 'cognitiveleap-core-us/utils/utils'
import { history } from 'umi';
import moment from 'moment'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getDvaApp } from 'umi'
import CustomNameInput from 'components/CustomNameInput/CustomNameInput';

const contentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'CustomRender',
        ShowTitle: intl.formatMessage({ id: 'name' }),
        FormKey: 'name',
        AdditionalData: null,
        Value: '',
        Setting: {
          Required: true,
          render: () => <CustomNameInput />
        },
        Description: null,
      },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'birthday' }),
        FormKey: 'birthDay',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          DateType: 'date',
          format: 'MM/DD/YYYY',
          DisabledDate: (current) => {
            return current && current > moment()
          }
        },
        Description: intl.formatMessage({ id: 'pleaseCheckCarefully' }),
      },
      {
        EditorType: 'Dropdown',
        Value: 'Male',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'male' }),
              Id: 'Male',
            },
            {
              Value: intl.formatMessage({ id: 'female' }),
              Id: 'Female',
            },
            // {
            //   Value: intl.formatMessage({ id: 'other' }),
            //   Id: 'Others',
            // },
          ],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'gender' }),
        FormKey: 'gender',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: 'Right',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'left' }),
              Id: 'LeftHand',
            },
            {
              Value: intl.formatMessage({ id: 'right' }),
              Id: 'Right',
            },
          ],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'dominantHand' }),
        FormKey: 'dominantHand',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: 'Mother',
        Setting: {
          DropdownOptions: [],
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'relationship' }),
        FormKey: 'guardianRole',
        Description: <div style={{ marginTop: '16px', color: '#F96B4B' }}>{intl.formatMessage({ id: 'submitTip' })}</div>,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'locatedCity' }),
        FormKey: 'city',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false
        },
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: '',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'kindergarten' }),
              Id: '0',
            },
            {
              Value: '1',
              Id: '1',
            },
            {
              Value: '2',
              Id: '2',
            },
            {
              Value: '3',
              Id: '3',
            },
            {
              Value: '4',
              Id: '4',
            },
            {
              Value: '5',
              Id: '5',
            },
            {
              Value: '6',
              Id: '6',
            },
            {
              Value: '7',
              Id: '7',
            },
            {
              Value: '8',
              Id: '8',
            },
            {
              Value: '9',
              Id: '9',
            },
            {
              Value: '10',
              Id: '10',
            },
            {
              Value: '11',
              Id: '11',
            },
            {
              Value: '12',
              Id: '12',
            },
            {
              Value: intl.formatMessage({ id: 'other' }),
              Id: '13',
            },
          ],
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'grade' }),
        FormKey: 'gradeName',
        Description: null,
      },
      {
        EditorType: 'Dropdown',
        Value: null,
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'public' }),
              Id: 'Public',
            },
            {
              Value: intl.formatMessage({ id: 'private' }),
              Id: 'Private',
            },
            {
              Value: intl.formatMessage({ id: 'international' }),
              Id: 'International',
            },
            {
              Value: intl.formatMessage({ id: 'notAttendingSchool' }),
              Id: 'NotGoingToSchool',
            },
          ],
          // Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'schoolNature' }),
        FormKey: 'schoolNature',
        Description: null,
      }
    ],
  }
}

export default {
  namespace: 'addUser',

  state: {
    canSendCode: true,
    subjectList: [],
    guardianInfo: null,
    coaches: [],
    confirmModalVisible: false,
    content: null,
    modalVisible: false,
    avaliableGuardianRoles: [],
    selectItem: null,
    selectIndex: null,
    countryCode: null,
    isSendCode: false,
    btnDisable: true,  // 验证手机号是否可发送验证码
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *getGuardianRole({ payload }, { take, put, call, select }) {
      const { intl } = payload
      const { currentUser } = yield select(state => state.user)
      const { auth, changedTenant } = currentUser
      const institutionType = changedTenant ? changedTenant.institutionType : 'other'
      const { grantedPolicies } = auth
      const hasAssignCoachPermission = grantedPolicies['Business.SubjectManagement.UserCoach.Assign'] === true
      yield put({ type: 'updateState', payload: { subjectList: [], canSendCode: true, isSendCode: false } })
      const res = yield call(PrepareSubject)
      if (res.response.ok) {
        const { avaliableGuardianRoles, coaches, countryCode } = res.data
        // const coachSelectItem = {
        //   EditorType: 'Dropdown',
        //   Value: null,
        //   Setting: {
        //     DropdownOptions: coaches.map(item => ({
        //       Value: item.name,
        //       Id: item.id,
        //     })),
        //     Required: false,
        //   },
        //   ShowTitle: useIntl().formatMessage({ id: 'assignTrainers' }),
        //   FormKey: 'coachId',
        //   Description: null,
        // }
        const coachSelectItem = {
          EditorType: 'CheckboxSelector',
          Value: null,
          Setting: {
            DropdownOptions: coaches.map(item => {
              return {
                Value: item.name,
                Id: item.id
              }
            }),
            Required: false,
          },
          ShowTitle: intl.formatMessage({ id: 'assignTrainers' }),
          FormKey: 'coachIds',
          Description: null,
        }

        let emptyContent = contentMenu(intl)
        const roleOptions = avaliableGuardianRoles.map(item => ({
          Value: item.displayName,
          Id: item.name,
        }))
        emptyContent = addOptionsToProperties(emptyContent, ['guardianRole'], roleOptions)
        if (hasAssignCoachPermission)
          emptyContent.Properties.push(coachSelectItem)
        if (institutionType === 'Medical') {
          emptyContent.Properties.unshift({
            EditorType: 'Input',
            Value: null,
            AdditionalData: null,
            Setting: {
              Required: false,
            },
            ShowTitle: intl.formatMessage({ id: 'clinicNumber' }),
            FormKey: 'outpatientNumber',
            Description: null,
          })

          emptyContent.Properties.splice(1, 1, {
            EditorType: 'Input',
            ShowTitle: intl.formatMessage({ id: 'name' }),
            FormKey: 'name',
            AdditionalData: null,
            Value: null,
            Setting: {
              Required: true,
            },
            Description: null,
          })
        }
        yield put({
          type: 'updateState',
          payload: { content: emptyContent, coaches, avaliableGuardianRoles, countryCode },
        })
      }
    },

    *sendCode({ payload: { values, intl } }, { take, put, call, select }) {
      const res = yield call(SendRegisterCode, { ...values, registerType: 'Phone' })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
      } else {
        const { error } = res.data
        const { code } = error || {}
        message.error(code === "InvalidPhone" ? intl.formatMessage({ id: 'phoneError' }) : error.message)
        yield put({
          type: 'updateState',
          payload: { canSendCode: true },
        })
      }
    },

    *checkSubject({ payload }, { take, put, call, select }) {
      const res = yield call(CheckSubject, payload)
      const { intl } = payload
      if (res.response.ok) {
        const { hadSame } = res.data
        if (hadSame) {
          Modal.confirm({
            title:<span style={{color: 'red',fontWeight: 500}} >{intl.formatMessage({ id: 'sameNamePrompt' })}</span>,
            content: <span style={{color:'red'}}>{intl.formatMessage({ id: 'sureCenterAddSameChild' })}</span>,
            okText:  intl.formatMessage({ id: 'return' }),
            cancelText: intl.formatMessage({ id: 'Confirm Add' }),
            icon: <ExclamationCircleFilled />,
            okButtonProps: {
              style: { background: '#fff',color:'#5a5a5a', boxShadow:'none', borderRadius: '5px', border:'1px solid #ccc' },
            },
            cancelButtonProps: {
              style: { background: 'red', color: '#fff', borderRadius: '5px', hover: 'none', border:'1px solid red'},
            },
            onCancel() {
              getDvaApp()._store.dispatch({
                type: 'addUser/addOrEditSubject',
                payload
              })
            }
          })
        } else {
          getDvaApp()._store.dispatch({
            type: 'addUser/addOrEditSubject',
            payload
          })
        }
      }
    },

    *addOrEditSubject({ payload }, { take, put, call, select }) {
      const { selectIndex, subjectList } = yield select(state => state.addUser)
      let newSubjectList
      if (selectIndex === null) {
        newSubjectList = subjectList.concat([payload])
      } else {
        newSubjectList = subjectList.map((item, index) => {
          if (index === selectIndex) return payload
          else return item
        })
      }
      yield put({
        type: 'updateState',
        payload: {
          subjectList: newSubjectList,
          modalVisible: false,
          selectIndex: null,
          selectItem: null,
        },
      })
    },

    *onDelete({ payload }, { take, put, call, select }) {
      const { subjectList } = yield select(state => state.addUser)
      const newList = subjectList.map(item => item)
      newList.splice(payload, 1)
      yield put({
        type: 'updateState',
        payload: { subjectList: newList },
      })
    },

    *onSubmit({ payload: { intl } }, { take, put, call, select }) {
      const { subjectList, guardianInfo } = yield select(state => state.addUser)
      const res = yield call(CreateUserAndSubject, { ...guardianInfo, subjects: subjectList })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { confirmModalVisible: false },
        })
        message.success(intl.formatMessage({ id: 'success' }))
        history.replace('/system/center/menu/user/subject')
      } else {
        yield put({
          type: 'updateState',
          payload: { confirmModalVisible: false },
        })
        const { error } = res.data
        message.error(error.message)
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(({ pathname, query }) => {
    //     if (pathname === '/system/center/addUser') {
    //       dispatch({ type: 'getGuardianRole' })
    //     }
    //   })
    // },
  },
}
