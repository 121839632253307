import React, { useEffect, useState } from 'react'
import { connect } from 'umi'
import { Spin } from 'antd'
import PersonalFiles from 'cognitiveleap-core-us/components/Archives/PersonalFiles'
import DiagnosticInfo from 'cognitiveleap-core-us/components/Archives/DiagnosticInfo'
import ChiefComplaint from 'cognitiveleap-core-us/components/Archives/ChiefComplaint'
import ParentInterview from 'cognitiveleap-core-us/components/Archives/ParentInterview'
import VratResult from 'cognitiveleap-core-us/components/Archives/VratResult'
import MFA from 'cognitiveleap-core-us/components/Archives/MFA'
import MFATwo from 'cognitiveleap-core-us/components/Archives/MFATwo'
import ChildrenAssessment from 'cognitiveleap-core-us/components/Archives/ChildrenAssessment'
import Loading from 'components/Loading'
import Question from 'components/Question'
import EditChildInfoModal from 'components/EditChildInfoModal'
import styles from './index.less'

const Archives = ({archives, dispatch, currentUser, location}) => {
    const [visible , setVisible]  = useState(false);
    const [btnVisible , setBtnVisible]  = useState(true);

    useEffect(() => {
        dispatch({ type: 'archives/updateState', payload: location.query })
    }, [])

    const { subjectId, from } = archives
    const roles  = currentUser.current ? currentUser.current.roles : []
    const isEnterAdmin = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'EnterpriseAdmin') : false
    }

    const handleOnclick = (newVisible) => {
        setVisible(newVisible)
    };

    const archivesProps = {
        subjectId,
        Loading,
        Question,
        from,
        isEnterAdmin: isEnterAdmin(),
        callback(){
            dispatch({ type: 'archives/updateState', payload: { subjectId: null } })
        }
    }

    return (
        <div className={styles.archives}>
            <Spin spinning = {subjectId ===null}>
                {
                    subjectId&&<PersonalFiles {...archivesProps} EditChildInfoModal={EditChildInfoModal}/>
                }
                {
                    subjectId&&<DiagnosticInfo {...archivesProps} />
                }
                {
                    subjectId&&<ChiefComplaint {...archivesProps} />}
                {
                    subjectId&&<ParentInterview {...archivesProps} />}
                {
                    subjectId&&<VratResult {...archivesProps} />}
                {
                    subjectId&&<MFA {...archivesProps} handleOnclick={handleOnclick}  visible={visible} />}
                {
                    subjectId&&<MFATwo {...archivesProps} handleOnclick={handleOnclick} visible={visible}  />}
                {
                    subjectId&&<ChildrenAssessment {...archivesProps} />}
            </Spin>
        </div>
    )
}

export default connect(({archives, user})=>({archives, currentUser: user.currentUser}))(Archives)
