import { Spin } from 'antd'

/// 一个页面，数据未加载出来，Spin居中的效果
export default () => {
  return (
    <Spin
      size="large"
      style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, marginTop: 200 }}
    />
  )
}
