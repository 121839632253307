import { message } from 'antd'
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import { PublishStatus, EditSortOrder } from 'services/rocketService/Course'
import config from 'utils/config'
import {
    PostPrepareData,
    GetPrepareList,
    PutPrepareData,
    DeletePrepareData,
    PrepareForEdit,
} from 'services/rocketService/Prepare'

const { baseURL, uploadImagesUrl } = config

let contentMenu = {
    Properties: [
        {
            EditorType: 'Input',
            ShowTitle: 'Title',
            FormKey: 'title',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: false,
            },
            Description: null,
        },
        {
            EditorType: 'Input',
            ShowTitle: 'SubTitle',
            FormKey: 'subTitle',
            AdditionalData: null,
            Value: null,
            Setting: {
                Required: false,
            },
            Description: null,
        },
        {
            EditorType: 'Upload',
            Value: null,
            Setting: {
                UploadSetting: {
                    uploadType: 'image',
                    isSingle: true,
                },
                Action: baseURL + uploadImagesUrl,
                Header: getAuthHeader(),
            },
            ShowTitle: 'ImageUrl',
            FormKey: 'imageUrl',
        },
        {
            EditorType: 'Upload',
            Value: null,
            Setting: {
                UploadSetting: {
                    uploadType: 'image',
                    isSingle: true,
                },
                Action: baseURL + uploadImagesUrl,
                Header: getAuthHeader(),
            },
            ShowTitle: 'headerImageUrl',
            FormKey: 'headerImageUrl',
        },
        {
            EditorType: 'EditableList',
            ShowTitle: 'steps',
            FormKey: 'steps',
            AdditionalData: null,
            Value: null,
            Setting: {
                ListElement: {
                    Properties: [
                        {
                            EditorType: 'Input',
                            ShowTitle: 'title',
                            FormKey: 'title',
                            AdditionalData: null,
                            Value: null,
                            Setting: {
                                Required: false,
                            },
                            Description: null,
                        },
                        {
                            EditorType: 'NumberInput',
                            ShowTitle: 'count',
                            FormKey: 'count',
                            AdditionalData: null,
                            Value: null,
                            Setting: {
                                NumberSetting: {
                                    min: 0,
                                    step: 1,
                                },
                            },
                        },
                    ],
                },
            },
            Description: null,
        },
        {
            EditorType: 'EditableList',
            ShowTitle: 'courseModules',
            FormKey: 'courseModules',
            AdditionalData: null,
            Value: null,
            Setting: {
                ListElement: {
                    Properties: [
                        {
                            EditorType: 'Input',
                            ShowTitle: 'title',
                            FormKey: 'title',
                            AdditionalData: null,
                            Value: null,
                            Setting: {
                                Required: false,
                            },
                            Description: null,
                        },
                        {
                            EditorType: 'RichEditorPro',
                            ShowTitle: 'detail',
                            FormKey: 'detail',
                            AdditionalData: null,
                            Value: null,
                            Setting: {},
                            Description: null,
                        },
                    ],
                },
            },
            Description: null,
        }
    ]
}

export default {
    namespace: 'trainingCourses',
    state: {
        list: [],
        title: '',
        modalVisible: false,
        selectItem: null,
        content: null,
        modalType: 'add',
        sortOrderModal: false,
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        }
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query(_, { call, put, select }) {
            const { pagination, searchInput } = yield select(state => state.trainingCourses)
            const { current, pageSize } = pagination
            let query = {
                skipCount: (current - 1) * pageSize,
                maxResultCount: pageSize,
                type: 'TrainingCourse',
                filter: searchInput
            }
            const res = yield call(GetPrepareList, query, 'course')
            if (res.response.ok) {
                const { items, totalCount } = res.data
                const newPagination = {
                    ...pagination,
                    total: totalCount,
                }
                yield put({
                    type: 'updateState',
                    payload: { pagination: newPagination, list: items },
                })
            }
        },

        *onEdit({ payload }, { call, put, select }) {
            let content = JSON.parse(JSON.stringify(contentMenu))
            const { id } = payload
            const res = yield call(PrepareForEdit, { id }, 'course')
            if (res.response.ok) {
                const { course } = res.data
                const newContent = matchDynamicForm(content, course)
                yield put({
                    type: 'updateState',
                    payload: {
                        content: newContent,
                        modalType: 'edit',
                        modalVisible: true,
                        selectItem: course
                    },
                })
            }
        },

        *onSubmitModal({ payload: data }, { take, put, call, select }) {
            const { modalType, selectItem } = yield select(state => state.trainingCourses)
            let res
            if (modalType === 'edit') {
                res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'course')
            } else {
                res = yield call(PostPrepareData, { ...data, type: 'TrainingCourse' }, 'course')
            }
            if (res.response.ok) {
                yield put({ type: 'query' })
                yield put({ type: 'updateState', payload: { modalVisible: false } })
            } else {
                const { error } = res.data
                const { message: errorInfo } = error || {}
                message.error(errorInfo)
            }
        },

        *onAdd({ payload }, { call, put, select }) {
            yield put({
                type: 'updateState',
                payload: { modalType: 'add', content: contentMenu, modalVisible: true },
            })
        },

        *changeTable({ payload: data }, { call, put, select }) {
            yield put({ type: 'updateState', payload: data })
            yield put({ type: 'query' })
        },

        *changeSortOrder({ payload }, { call, put, take, select }) {
            const { selectItem } = yield select(state => state.trainingCourses)
            const res = yield call(EditSortOrder, selectItem.id, payload)
            if (res.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: { sortOrderModal: false },
                })
                yield put({ type: 'query' })
            }
        },

        *onSearchInput({ payload }, { call, put, take, select }) {
            yield put({
                type: 'updateState',
                payload: {
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    },
                    searchInput: payload
                }
            })
            yield put({ type: 'query' })
        },

        *onClear({ payload }, { call, put, take, select }) {
            yield put({
                type: 'updateState',
                payload: {
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    },
                    searchInput: ''
                }
            })
            yield put({ type: 'query' })
        },

        *onDelete({ payload: id }, { call, put, select }) {
            const res = yield call(DeletePrepareData, id, 'course')
            if (res.response.ok) {
                yield put({ type: 'query' })
            }
        },
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/content/course/trainingCourses') {
        //             dispatch({ type: 'query' })
        //         }
        //     })
        // },
    },
}
