import React, { useEffect } from 'react'
import { Button, Row, Col, Anchor, Spin, Pagination, Divider, Image } from 'antd'
import { connect } from 'umi'
import { history } from 'umi';
import CourseInfo from './CourseInfo'
import TrainingProgramItem from './TrainingProgram'
import PlayBookItemSelectionModal from './PlayBookItemSelectionModal'
import PlaybookDrawer from './PlaybookDrawer'
import { useIntl } from 'umi'
import styles from '../detail.less'
const { Link } = Anchor

const UserOfflineCourseDetail = ({ userOfflineCourseDetail, currentUser, dispatch, location }) => {
  const { userOrClassName, trainPlanName } = location.query || {};

  const intl = useIntl()

  useEffect(() => {
    dispatch({ type: 'userOfflineCourseDetail/pageLoading', payload: location.query })
  }, [])

  const {
    courseInfo,
    pagination,
    total,
    from,
    selectModalVisible,
    drawerVisible,
    playBookList,
    activeNum,
    planType
  } = userOfflineCourseDetail

  const scrollFunc = (e) => {
    if (e.deltaY < 0) {
      checkScroll('up')
    } else {
      checkScroll('down')
    }
  }

  const checkScroll = (direction) => {
    const { planItems = [] } = courseInfo || {}
    if (planItems.length > 0) {
      const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop);
      const offSetHeight = Math.max(...planItems.map(item => document.getElementById(item.id).offsetHeight)); // 元素的offsetHeight
      const offSetTopList = planItems.map(item => document.getElementById(item.id).offsetTop);

      const currentIndex = parseInt((scrollTop - offSetTopList[0]) / offSetHeight);

      const scrollOption = currentIndex * offSetHeight + offSetHeight * 0.4 + offSetTopList[0];

      if (direction === 'down') {
        if (scrollTop > scrollOption) {
          if (currentIndex + 1 < planItems.length) {
            window.scrollTo({
              top: offSetTopList[currentIndex + 1] + 80,
              behavior: "smooth"
            });
          }
        }
      } else {
        if (scrollTop < scrollOption - 200) {
          window.scrollTo({
            top: offSetTopList[currentIndex - 1 || 0] + 80,
            behavior: "smooth"
          });
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('wheel', scrollFunc)
    return () => {
      window.removeEventListener('wheel', scrollFunc)
    }
  }, [courseInfo])

  const { auth, } = currentUser
  const { grantedPolicies } = auth
  const hasOfflinePlanWritePermission = grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true
  if (courseInfo === null)
    return (
      <div style={{ position: 'relative' }} className={styles.spinWrap}>
        <Spin
          size="large"
          style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, marginTop: 200 }}
        />
      </div>
    )
  const { num, iLs = null, userOfflineTrainingPlan = null, planItems, nonePlaybook, isApp } = courseInfo

  const onPageChange = pageIndex => {
    dispatch({
      type: 'userOfflineCourseDetail/changeTable',
      payload: { pagination: { current: pageIndex } },
    })
  }

  const courseInfoProps = {
    num,
    userOfflineTrainingPlan,
    iLs,
    planType,
    isApp,
    userOrClassName: decodeURI(userOrClassName),
    trainPlanName: decodeURI(trainPlanName)
  }

  const drawerProps = {
    list: playBookList,
    visible: drawerVisible,
    onClose() {
      dispatch({
        type: 'userOfflineCourseDetail/updateState',
        payload: { drawerVisible: false },
      })
    },
  }

  const onBack = () => {
    history.goBack()
  }

  const handleClick = (e, link) => {
    e.preventDefault()
    //console.log(link);
  }

  const onSelect = () => {
    dispatch({
      type: 'userOfflineCourseDetail/onSelectPlayBook',
    })
  }

  const onView = () => {
    dispatch({
      type: 'userOfflineCourseDetail/onShowPlayBook',
    })
  }

  const selectModalProps = {
    defaultSelectKeys: nonePlaybook.map(item => item.trainingProgramId),
    allList: playBookList,
    selectModalVisible: selectModalVisible,
    onOk(keys) {
      dispatch({
        type: 'userOfflineCourseDetail/updatePlaybookList',
        payload: { userOfflinePlanId: userOfflineTrainingPlan.id, num, programIds: keys },
      })
    },
    onCancel() {
      dispatch({
        type: 'userOfflineCourseDetail/updateState',
        payload: { selectModalVisible: false },
      })
    },
  }
  return (
    <div>
      <Row
        type="flex"
        justify="start"
        gutter={20}
        className={styles.top}
        style={{ marginBottom: 14 }}
      >
        <Button onClick={onBack}>{intl.formatMessage({ id: 'back' })}</Button>
        {/* <Col>
          <Pagination
            showQuickJumper
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={onPageChange}
          />
        </Col> */}
        <Col>
          {userOfflineTrainingPlan !== null && from == '' ? (
            <h3>{intl.formatMessage({ id: 'totalSessionsOther' }, { num: userOfflineTrainingPlan.totalNum })}</h3>
          ) : null}
        </Col>
      </Row>
      <div className={styles.main}>
        <Row className={styles.courseDetailWrap} gutter={18}>
          <Col span={16}>
            <CourseInfo {...courseInfoProps} />
            {from !== '' && (
              <div style={{ fontSize: 16, color: 'D9000000', fontWeight: 600, marginRight: '10px' }}>
                {(userOrClassName && trainPlanName) && (
                  <div style={{ fontSize: 16, color: 'D9000000', fontWeight: 600 }}>
                    {decodeURI(userOrClassName)} <Divider type="vertical" /> {decodeURI(trainPlanName)}
                    <p />
                  </div>
                )}
                <div>
                  {intl.formatMessage({ id: 'whichSession' }, { num })}
                </div>
              </div>
            )}
            <Divider />
            {planItems.map((item, index) => {
              const { id, trainingProgramDetail } = item
              const props = { ...trainingProgramDetail, index, from, groupCourseItemId: id, ilsVersion: userOfflineTrainingPlan.ilsVersion }
              return (
                <div key={id} id={id}>
                  <TrainingProgramItem {...props} />
                  <Divider />
                </div>
              )
            })}
          </Col>
          <Col span={8}>
            <div className={styles.anchor}>
              <Anchor onClick={handleClick} offsetTop={100} style={{ maxHeight: 'unset' }} showInkInFixed>
                {planItems.map(item => {
                  const { id, trainingProgramDetail } = item
                  return <Link key={id} href={`#${id}`} title={trainingProgramDetail.title} />
                })}
                <div style={{ padding: '20px 0 0 20px' }}>
                  {/* <div>
                    <Button onClick={onView} style={{ marginTop: 24, display: 'block' }} >
                      静态活动
                  </Button>
                  </div>
                  <div>
                    {
                      hasOfflinePlanWritePermission && <Button onClick={onSelect} style={{ marginTop: 24 }} disabled={activeNum != pagination.current}>
                        选择静态活动
                    </Button>
                    }
                  </div> */}
                  {/* <div>
                    {nonePlaybook && Array.isArray(nonePlaybook) && nonePlaybook.length > 0 && <p style={{ marginTop: 24 }}>已选静态活动：</p>}
                    {nonePlaybook.map(item => {
                      const { trainingProgramDetail } = item
                      return <div key={trainingProgramDetail.id} style={{ marginTop: '10px' }}>{trainingProgramDetail.title}</div>
                    })}
                  </div> */}
                  <div>
                    {
                      userOfflineTrainingPlan && userOfflineTrainingPlan.files && Array.isArray(userOfflineTrainingPlan.files) && userOfflineTrainingPlan.files.length > 0 &&
                      userOfflineTrainingPlan.files.map(item => {
                        return item.url && <Image key={item.id} width={170} src={item.url} preview={false} style={{ marginTop: '20px' }} />
                      })
                    }
                  </div>
                </div>
              </Anchor>
            </div>
          </Col>
        </Row>
        <PlayBookItemSelectionModal {...selectModalProps} />
        <PlaybookDrawer {...drawerProps} />
      </div>
    </div>
  )
}

export default connect(({ userOfflineCourseDetail, user, loading }) => ({
  userOfflineCourseDetail,
  currentUser: user.currentUser,
  loading
}))(UserOfflineCourseDetail)
