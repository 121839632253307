import { useState } from 'react';
import { useIntl } from 'umi';
import { deepCopy } from 'cognitiveleap-core-us/utils/utils';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

const AddClassModal = ({
  visible,
  currentItem,
  loading,
  closeModal = () => {},
  onOk = () => {},
}) => {
  const intl = useIntl();

  const { userName, totalTimes, usedTimes } = currentItem || {};

  const ContentMenu = {
    Properties: [
      {
        EditorType: 'NumberInput',
        Value: totalTimes - usedTimes,
        Setting: {
          Disabled: true,
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'Remaining Sessions' }),
        FormKey: 'RemainingSessions',
        Description: '',
      },
      {
        EditorType: 'Input',
        Value: userName,
        Setting: {
          Disabled: true,
          Required: false,
        },
        ShowTitle: intl.formatMessage({ id: 'Coach Name' }),
        FormKey: 'CoachName',
        Description: '',
      },
      {
        EditorType: 'Dropdown',
        Value: 20,
        Setting: {
          DropdownOptions: [
            {
              Value: '20',
              Id: 20,
            },
            {
              Value: '40',
              Id: 40,
            },
            {
              Value: '60',
              Id: 60,
            },
            {
              Value: intl.formatMessage({ id: 'Customize' }),
              Id: 'custom',
            },
          ],
          Required: true,
          onchange: (value) => {
            const tempContent = deepCopy(ContentMenu);
            tempContent.Properties[3].Setting.IsVisible = value === 'custom';
            setModalContent(tempContent);
          },
        },
        ShowTitle: intl.formatMessage({ id: 'Add Sessions' }),
        FormKey: 'changeTimes',
        Description: null,
      },
      {
        EditorType: 'SelectNumberInput',
        Value: null,
        Setting: {
          IsVisible: false,
          NumberSetting: {
            min: 0,
            step: 1,
          },
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'Custom Amount' }),
        FormKey: 'customChangeTimes',
        Description: '',
      },
      {
        EditorType: 'Input',
        Value: null,
        Setting: {
          Required: false,
          Props: {
            placeholder: intl.formatMessage({
              id: 'pleaseEnter',
            }),
          },
        },
        ShowTitle: intl.formatMessage({ id: 'comments' }),
        FormKey: 'remark',
        Description: '',
      },
    ],
  };

  const [modalContent, setModalContent] = useState(deepCopy(ContentMenu));

  const modalProps = {
    title: intl.formatMessage({ id: 'Add Sessions' }),
    visible,
    content: modalContent,
    loading,
    onCancel: closeModal,
    onOk,
  };

  return <MagicFormModal {...modalProps} />;
};

export default AddClassModal;
