import React from 'react'
import { Descriptions, Card } from 'antd'

const CenterInfo = ({ centerInfo }) => {
  return (
    <Card>
      <Descriptions title="中心信息">
        {Object.keys(centerInfo).map(keyItem => {
          if (typeof centerInfo[keyItem] === 'string') {
            return (
              <Descriptions.Item label={keyItem} key={keyItem}>
                {centerInfo[keyItem]}
              </Descriptions.Item>
            )
          }
        })}
      </Descriptions>
    </Card>
  )
}

export default CenterInfo
