import React, { useEffect } from 'react'
import { history } from 'umi';
import { connect } from 'umi'
import { Button, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import StatusModal from 'components/StatusModal'
import SortOrderModal from 'components/SortOrderModal'

const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑问卷测评',
  add: '新建问卷测评',
}

const initialStatusValue = {
  'Unpublished': 'Unpublish',
  'Public': 'Public',
  'Hidden': 'Hide',
  'Expired': 'Expire',
  'Trashed': 'Trash'
}

const Assessment = ({ assessment, dispatch, loading }) => {

  useEffect(() => {
    dispatch({ type: 'assessment/query' })
  }, [])

  const {
    modalType,
    content,
    modalVisible,
    list,
    listSupport,
    pagination,
    statusVisible,
    selectItem,
    sortOrderModal,
    filters
  } = assessment

  const { hasLanguageFallback, status } = selectItem || {}

  const modalTypeToTitle = (modalType, hasLanguageFallback) => {
    const showLangFallback = hasLanguageFallback ? '（尚未翻译）' : ''
    return modalTitleMenu[modalType] + showLangFallback
  }

  const valueToBackendData = value => {
    const { start, end } = value
    return {
      ...value,
      agePeriod: { start, end },
    }
  }

  const modalProps = {
    title: modalTypeToTitle(modalType, hasLanguageFallback),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'assessment/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      const postData = valueToBackendData(value)
      dispatch({ type: 'assessment/onSubmitModal', payload: postData })
    },
    content,
  }

  const statusProps = {
    initialValue: initialStatusValue[status],
    visible: statusVisible,
    onCancel() {
      dispatch({ type: 'assessment/updateState', payload: { statusVisible: false } })
    },
    onOk(data) {
      dispatch({ type: 'assessment/changeStatus', payload: data.status })
    },
  }

  const sortModalProps = {
    visible: sortOrderModal,
    onCancel() {
      dispatch({ type: 'assessment/updateState', payload: { sortOrderModal: false } })
    },
    onOk(data) {
      dispatch({ type: 'assessment/changeSortOrder', payload: data.sortOrder })
    },
    currentValue: selectItem && selectItem.sortOrder,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    loading: loading.effects['assessment/query'],
    filters,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      if (Object.keys(fil).length !== 0) data = { ...data, filters: fil }
      dispatch({ type: 'assessment/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '中心',
        onClick(record) {
          history.push({
            pathname: '/system/manage/authorCenter',
            query: {
              id: record.id,
              type: 'evaluation',
              title: record.title
            },
          })
        }
      },
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'assessment/onEdit', payload: record.id })
        },
      },
      {
        showText: '发布态',
        onClick(record) {
          dispatch({
            type: 'assessment/updateState',
            payload: { statusVisible: true, selectItem: record },
          })
        },
      },
      {
        showText: '排序',
        onClick(record) {
          dispatch({
            type: 'assessment/updateState',
            payload: { sortOrderModal: true, selectItem: record },
          })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'assessment/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'assessment/onAdd' })
  }

  return (
    <div>
      <h1>测评列表</h1>
      <Button onClick={onAddClick}>新建问卷测评</Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
      <StatusModal {...statusProps} />
      <SortOrderModal {...sortModalProps} />
    </div>
  )
}
export default connect(({ assessment, loading }) => ({ assessment, loading }))(Assessment)
