import React, { useEffect } from 'react';
import { Card, Select, Input, Button, Modal } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import TrainWithClassDrawer from 'components/TrainWithClassDrawer';
import { connect, history } from 'umi';
import styles from './index.less';

const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

const UserList = ({ dispatch, loading, staffList }) => {
  const {
    list,
    pagination,
    name,
    drawerVisible,
    currentUserInfo,
    // qualificationList
  } = staffList;

  useEffect(() => {
    dispatch({ type: 'staffList/query' });
  }, []);

  const listSupport = {
    creationTime: {
      showText: '添加时间',
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm',
      },
    },
    name: {
      showText: '姓名',
      showType: 'Text',
      // render(text, record, index) {
      //   const { modelKeys, name } = record || {}
      //   return (
      //     <div className={styles.staffListName}>
      //       <span>{name}</span>
      //       {/* {
      //         modelKeys.map(item => {
      //           const qualificationItem = qualificationList.find(qualificationItem => qualificationItem.name === item)
      //           return (
      //             <img src={qualificationItem && qualificationItem.issuedStyle && qualificationItem.issuedStyle.url} />
      //           )
      //         })
      //       } */}
      //     </div>
      //   )
      // }
    },
    email: {
      showText: '邮箱',
      showType: 'Text',
    },
    showPhoneNumber: {
      showText: '手机号',
      showType: 'Text',
    },
  };

  const onchangeSearchValue = async (value, type) => {
    switch (type) {
      case 'name':
        await dispatch({
          type: 'staffList/updateState',
          payload: { [type]: value.target.value },
        });
        break;
    }
  };

  const toSupervisor = (record) => {
    confirm({
      closable: true,
      title: <b>设为督导师</b>,
      icon: null,
      content:
        '是否确认将该用户设为督导师？确认后，该用户将能被授权督导其他员工。',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        dispatch({ type: 'staffList/toSupervisor', payload: record });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const cancelSupervisor = (record) => {
    confirm({
      closable: true,
      title: <b>取消督导权限</b>,
      icon: null,
      content:
        '是否确认取消该用户督导权限？确认后，该用户下督导列表用户将被取消。',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        dispatch({ type: 'staffList/cancelSupervisor', payload: record });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const tableProps = {
    listData: list.map((item) => {
      let showPhoneNumber = '';
      let { countryCode, phoneNumber } = item;
      if (!countryCode && phoneNumber) {
        countryCode = '';
      } else if (!phoneNumber && countryCode) {
        phoneNumber = '';
      } else if (!phoneNumber && !countryCode) {
        countryCode = '';
        phoneNumber = '';
      }
      showPhoneNumber = countryCode + '-' + phoneNumber;

      return {
        showPhoneNumber,
        ...item,
      };
    }),
    listSupport,
    pagination,
    loading: loading.models.staffList,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination };
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi };
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt };
      dispatch({ type: 'staffList/changeTable', payload: data });
    },
    Actions: [
      {
        showText: '个人设置',
        onClick(record) {
          history.push({
            pathname: '/system/manage/groupDetail',
            query: {
              id: record.id,
            },
          });
        },
      },
      {
        showText: '培训详情',
        onClick(record) {
          history.push({
            pathname: '/system/manage/trainingStaffDetail',
            query: {
              id: record.id,
            },
          });
        },
      },
      {
        showText: '培训带课情况',
        onClick(record) {
          dispatch({
            type: 'staffList/updateState',
            payload: { drawerVisible: true, currentUserInfo: record },
          });
        },
      },
      // {
      //   showText: '设为督导师',
      //   itemRender(record) {
      //     const { hostRoles } = record || {}
      //     const isSupervisor = hostRoles.find(item => item.name === "supervisor")
      //     return (
      //       !isSupervisor ?
      //         <a onClick={() => { toSupervisor(record) }}>设为督导师</a>
      //         :
      //         null
      //     )
      //   }
      // },
      // {
      //   showText: '取消督导权限',
      //   itemRender(record) {
      //     const { hostRoles } = record || {}
      //     const isSupervisor = hostRoles.find(item => item.name === "supervisor")
      //     return (
      //       isSupervisor ?
      //         <a
      //           onClick={() => cancelSupervisor(record)}
      //         >
      //           取消督导权限
      //         </a>
      //         :
      //         null
      //     )
      //   }
      // },
      // {
      //   showText: '督导列表',
      //   itemRender(record) {
      //     const { hostRoles } = record || {}
      //     const isSupervisor = hostRoles.find(item => item.name === "supervisor")
      //     return (
      //       isSupervisor ?
      //         <a
      //           onClick={() => {
      //             history.push(`/system/manage/superviseList?id=${record.id}`)
      //           }}
      //         >
      //           督导列表
      //         </a>
      //         :
      //         null
      //     )
      //   }
      // },
      // {
      //   showText: '查看课表',
      //   itemRender(record) {
      //     return (
      //         <a
      //           onClick={() => {
      //             history.push(`/system/manage/classCardDetail?id=${record.id}`)
      //           }}
      //         >
      //           查看课表
      //         </a>
      //     )
      //   }
      // },
    ],
  };

  const drawerProps = {
    visible: drawerVisible,
    userInfo: currentUserInfo,
    onCloseDraw: () => {
      dispatch({
        type: 'staffList/updateState',
        payload: { drawerVisible: false },
      });
    },
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>员工管理</b>}
      bordered={false}
      extra={
        <Button
          type="primary"
          onClick={() => dispatch({ type: 'staffList/exportExcel' })}
        >
          导出培训带课列表
        </Button>
      }
    >
      <div className={styles.staffListSearch}>
        <Search
          placeholder="搜索姓名/邮箱"
          value={name}
          onChange={(value) => onchangeSearchValue(value, 'name')}
          style={{ width: 200, margin: '0 30px 10px 0' }}
          onSearch={() => dispatch({ type: 'staffList/onsearch' })}
        />
        <Button onClick={() => dispatch({ type: 'staffList/onClear' })}>
          清空
        </Button>
      </div>
      <MagicTable {...tableProps} />
      {drawerVisible && <TrainWithClassDrawer {...drawerProps} />}
    </Card>
  );
};
export default connect(({ staffList, loading }) => ({ staffList, loading }))(
  UserList,
);
