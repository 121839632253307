import { message } from 'antd';
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils';
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';
import {
  PostQualification,
  GetQualification,
  DeleteQualification,
  PutQualification,
} from 'services/trainingSystem';
import config from 'utils/config';
const { baseURL, uploadTrainingMedias } = config;

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'name',
      FormKey: 'name',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: '',
    },
    {
      EditorType: 'Input',
      ShowTitle: '显示名称',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: '',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        Required: true,
        UploadSetting: {
          uploadType: 'image',
          isSingle: true,
        },
        Action: baseURL + uploadTrainingMedias,
        Header: getAuthHeader(),
      },
      ShowTitle: '勋章样式',
      FormKey: 'issuedStyle',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        Required: true,
        UploadSetting: {
          uploadType: 'image',
          isSingle: true,
        },
        Action: baseURL + uploadTrainingMedias,
        Header: getAuthHeader(),
      },
      ShowTitle: '过期勋章样式',
      FormKey: 'unissuedStyle',
    },
    {
      EditorType: 'EditableList',
      Value: null,
      Setting: {
        Required: true,
        IsVisible: true,
        ListElement: {
          Properties: [
            {
              EditorType: 'Input',
              Value: null,
              Setting: {
                Required: false,
              },
              ShowTitle: '名称',
              FormKey: 'name',
              Description: null,
            },
            {
              EditorType: 'Upload',
              ShowTitle: '样式',
              FormKey: 'style',
              Value: null,
              Setting: {
                UploadSetting: {
                  uploadType: 'image',
                  isSingle: true,
                },
                Action: baseURL + uploadTrainingMedias,
                Header: getAuthHeader(),
              },
              Description: null,
            },
          ],
        },
      },
      ShowTitle: '证书样式',
      FormKey: 'certificateStyle',
    },
    {
      EditorType: 'Input',
      ShowTitle: '证书代码',
      FormKey: 'certificateCode',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        IsVisible: true,
      },
      Description: '通常为1-2个字母，用于证书编号中',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '证书有效年限',
      FormKey: 'validityPeriod',
      AdditionalData: null,
      Value: 2,
      Setting: {
        Required: true,
        IsVisible: true,
        NumberSetting: {
          min: 0,
          precision: 0,
        },
      },
      Description: '',
    },
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'remark',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: '',
    },
  ],
};

export default {
  namespace: 'meritMedal',
  state: {
    modalType: 'edit',
    modalVisible: false,
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select((state) => state.meritMedal);
      const { current, pageSize } = pagination;
      const res = yield call(GetQualification, {
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize,
      });
      if (res.response.ok) {
        const { totalCount, items } = res.data;
        yield put({
          type: 'updateState',
          payload: {
            list: items,
            pagination: { ...pagination, total: totalCount },
          },
        });
      }
    },

    *onAdd(_, { take, put, call, select }) {
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: contentMenu, modalVisible: true },
      });
    },

    *onEdit({ payload }, { call, put, select }) {
      const { certificateStyle = [] } = payload;
      const _payload = {
        ...payload,
        certificateStyle: certificateStyle.map(item => {
            const { name, style } = item || {};
            const { id } = style || {};
            return {
                style: id,
                name
            }
        })
       };
      const editUserInfoForm = matchDynamicForm(contentMenu, _payload);
      yield put({
        type: 'updateState',
        payload: {
          content: editUserInfoForm,
          modalType: 'edit',
          modalVisible: true,
          selectItem: _payload,
        },
      });
    },

    *onDelete({ payload }, { call, put, select }) {
      const { id } = payload;
      const res = yield call(DeleteQualification, { id });
      if (res.response.ok) {
        message.success('删除成功');
        yield put({ type: 'query' });
      }
    },

    *onSubmitModal({ payload }, { call, put, select }) {
      const { modalType, selectItem } = yield select(
        (state) => state.meritMedal,
      );
      let params = {};
      let res = null;
      if (modalType === 'add') {
        params = payload;
      } else {
        params = { ...selectItem, ...payload };
      }
      res = yield call(
        modalType === 'add' ? PostQualification : PutQualification,
        params,
      );
      if (res.response.ok) {
        message.success('提交成功');
        yield put({ type: 'updateState', payload: { modalVisible: false } });
        yield put({ type: 'query' });
      } else {
        const {
          data: { error },
        } = res;
        message.error(error.message);
      }
    },

    *changeTable({ payload }, { call, put, select }) {
      const { pagination } = yield select((state) => state.meritMedal);
      const { pagination: newPagination } = payload;
      yield put({
        type: 'updateState',
        payload: { pagination: { ...pagination, ...newPagination } },
      });
      yield put({ type: 'query' });
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //     return history.listen(location => {
    //         if (location.pathname === '/system/manage/content/course/meritMedal') {
    //             dispatch({ type: 'query' })
    //         }
    //     })
    // },
  },
};
