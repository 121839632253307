import {
  GetLoginInfo,
  GetProjectMenu,
  TenantInfo,
  GetUserPermission,
  GetTetanntList,
  PreviewQrcode,
  GetGrant,
  GetReport,
  // GetCoachQrCode,
  // GetParentQrCode,
  GetTrainingApplication,
  GetFeedbackApplication,
  GetNewTrainerTrainingModule
} from 'services/account'
import { GetQRCode } from 'services/tenant'
import { GetTenantQR } from 'services/account'
import userManager from 'utils/auth'
import { logOut, changeTenant, changeUserTenant, changeSelectHost } from 'cognitiveleap-core-us/utils/auth'
import { downloadQr } from 'utils/utils'
import { message, Modal } from 'antd'
import { IntlManager } from 'utils/helper'
import QRCode from 'components/QRCode'
import { history } from 'umi';
import download from 'cognitiveleap-core-us/utils/download'
import * as Sentry from "@sentry/react";

async function getUrl() {
  if (!userManager.userServiceUrl) {
    await userManager.init()
  }
  return userManager.userServiceUrl
}

export default {
  namespace: 'user',

  state: {
    currentUser: null,
    centerQrUrl: null,
    weixinQrUrl: null,
    parentQrUrl: null,
    reportQrUrl: null,
    coachQrUrl: null,
    coachQrModalVisible: false,
    centerQrModalVisible: false,
    parentQrModalVisible: false,
    reportQrModalVisible: false,
    weixinQrModalVisible: false,
    trainingQrModalVisible: false,
    FeedbackQrModalVisible: false,
    newTrainModuleModalVisible: false,
    newTrainModuleModalVisible2: false,
    customSocket: null
  },

  effects: {
    *getUserInfo({ payload }, { call, put, take, select }) {
      // 相当于三种角色
      // 总的Admin端
      // 三种角色管理员
      // Center端
      const userInfoRes = yield call(GetLoginInfo)
      if (userInfoRes.response.ok) {
        const resList = yield [call(TenantInfo), call(GetTetanntList), call(GetNewTrainerTrainingModule)]
        const tenantInfoRes = resList[0]
        const tenantListRes = resList[1]
        const getNewTrainerTrainingModuleRes = resList[2]

        if (tenantInfoRes.response.ok && tenantListRes.response.ok) {
          const getNewTrainerTrainingModuleResData = getNewTrainerTrainingModuleRes.data
          const { trainerTrainingModuleIds } = getNewTrainerTrainingModuleResData || {}
          const { current, userAllTenant, adminRoles = [], changedTenant } = tenantInfoRes.data || {}
          const { id: userId, email, userName, name } = userInfoRes.data || {};

          Sentry.setUser({ id: userId, username: userName || name, email });

          // SelectHost 为 True 则为管理端 False为机构端
          // 只有管理端
          if (adminRoles.length > 0 && userAllTenant.every(item => item.state === "Invalid")) {
            changeSelectHost("Management")
          }
          // 只有机构端
          if (userAllTenant.some(item => item.state === "Effective") && adminRoles.length === 0) {
            changeSelectHost("Institutional")
          }

          // const isClAdmin = adminRoles.some(item => item.name === 'admin')
          // ///如果是中心账号，并且已经被冻结，current为null，则切换到一个可用的中心
          // if (!isClAdmin && current === null) {
          //   const effectiveTenant = userAllTenant.find(item => item.state === 'Effective')
          //   if (effectiveTenant) {
          //     yield put({
          //       type: 'changeUserTenant',
          //       payload: effectiveTenant
          //     })
          //     return
          //   }
          // }

          const { id } = current || {}

          if (id)
            changeUserTenant(id)

          yield put({
            type: 'updateState',
            payload: {
              currentUser: {
                ...userInfoRes.data,
                ...tenantInfoRes.data,
                ...tenantListRes.data,
              },
              newTrainModuleModalVisible: trainerTrainingModuleIds && trainerTrainingModuleIds.length > 0
            },
          })
        }
      }
    },

    *downloadQRPhoto(_, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user)
      const { current, changedTenant } = currentUser
      if (current !== null) {
        const res = yield call(GetTenantQR, current.id)
        const { response } = res
        if (response.ok) {
          response.blob().then(blob => {
            downloadQr({
              type: 'blob',
              data: blob,
              title: '【扫码授权】',
              subTitle: changedTenant.displayName,
              description: `中心码：${current.qrNum}`,
              downloadName: '二维码',
              imgType: 'centerCode'
            })
          })
        }
      }
      else {
        message.error('Can not download QRCode as a administrator')
      }
    },

    *showQRCode(_, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser
      if (current !== null) {
        const res = yield call(GetQRCode, current.id)
        if (res.response.ok) {
          const { picture, url } = res.data
          Modal.info({
            title: 'Center QRCode',
            content: <QRCode codeUrl={url} />
          })
        }
      }
      else {
        message.error('Can not show QRCode as a administrator')
      }
    },

    *returnLoginPage(_, { call, put, take, select }) {
      Sentry.configureScope((scope) => scope.setUser(null));

      const { pathname, search } = window.location
      const returnUrl = pathname + search
      const mgr = userManager.manager ? userManager.manager : yield userManager.init()
      if (mgr) {
        mgr.signinRedirect({ state: { returnUrl: returnUrl } })
      }
    },

    *returnToAccount({ payload }, { call, put }) {
      const url = yield getUrl()
      window.open(url, '_blank')
    },

    *simpleLogout(_, { call, put, take, select }) {
      logOut()
      window.location.reload()
    },

    *changeTenant({ payload: tenantItem }, { call, put }) {
      changeTenant(tenantItem.type === 'Center' ? tenantItem.id : '')
      yield put({
        type: 'updateState',
        payload: {
          currentUser: null,
        },
      })
      history.replace('/')
    },

    *changeAdmin(_, { call, put, take, select }) {
      changeSelectHost('');
      history.replace('/system')
      window.location.reload()
    },

    *changeRoles({ payload }, { call, put }) {
      changeSelectHost(payload)
      yield put({
        type: 'updateState',
        payload: {
          currentUser: null,
        },
      })
      history.replace('/system')
      window.location.reload()
    },

    *changeUserTenant({ payload: tenantItem }, { call, put, select }) {
      const { customSocket } = yield select(state => state.user)
      changeTenant("")
      changeUserTenant(tenantItem.id)
      yield put({
        type: 'updateState',
        payload: {
          currentUser: null,
        },
      })
      history.replace('/')
      customSocket?.disconnect();
    },

    *logout(_, { call, put, take, select }) {
      Sentry.configureScope((scope) => scope.setUser(null));

      const { customSocket } = yield select(state => state.user)
      logOut()
      const mgr = userManager.manager ? userManager.manager : yield userManager.init()
      if (mgr) {
        mgr.signoutRedirect()
      }
      customSocket?.disconnect();
    },

    *showQRAndCode(_, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser
      if (current !== null) {
        const res = yield call(PreviewQrcode, current.id);
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              centerQrUrl: `data:image/png;base64,${res.data}`,
              centerQrModalVisible: true
            }
          })
        }
      } else {
        message.error('Can not show QRCode as a administrator')
      }
    },

    *showWeixinQRAndCode(_, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser
      if (current !== null) {
        const res = yield call(GetGrant, current.id);
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              weixinQrUrl: `data:image/png;base64,${res.data}`,
              weixinQrModalVisible: true
            }
          })
        }
      } else {
        message.error('Can not show QRCode as a administrator')
      }
    },

    // *showParentQRAndCode(_, { call, put, take, select }) {
    //   const { currentUser } = yield select(state => state.user)
    //   const { current } = currentUser
    //   if (current !== null) {
    //     const res = yield call(GetParentQrCode);
    //     if (res.response.ok) {
    //       yield put({
    //         type: 'updateState',
    //         payload: {
    //           parentQrUrl: `data:image/png;base64,${res.data}`,
    //           parentQrModalVisible: true
    //         }
    //       })
    //     }
    //   } else {
    //     message.error('Can not show QRCode as a administrator')
    //   }
    // },

    // *showCoachQRAndCode(_, { call, put, take, select }) {
    //   const { currentUser } = yield select(state => state.user)
    //   const { current } = currentUser
    //   if (current !== null) {
    //     const res = yield call(GetCoachQrCode);
    //     if (res.response.ok) {
    //       yield put({
    //         type: 'updateState',
    //         payload: {
    //           coachQrUrl: `data:image/png;base64,${res.data}`,
    //           coachQrModalVisible: true
    //         }
    //       })
    //     }
    //   } else {
    //     message.error('Can not show QRCode as a administrator')
    //   }
    // },

    *showReportQRAndCode(_, { call, put, take, select }) {
      const { currentUser: { current } } = yield select(state => state.user)
      if (current !== null) {
        const res = yield call(GetReport);
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              reportQrUrl: `data:image/png;base64,${res.data}`,
              reportQrModalVisible: true
            }
          })
        }
      } else {
        message.error('Can not show QRCode as a administrator')
      }
    },

    *showTrainingQRAndCode(_, { call, put, take, select }) {
      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser
      if (current !== null) {
        const res = yield call(GetTrainingApplication);
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              centerQrUrl: `data:image/png;base64,${res.data}`,
              trainingQrModalVisible: true
            }
          })
        }
      } else {
        message.error('Can not show QRCode as a administrator')
      }
    },

    *showFeedbackQRAndCode(_, { call, put, take, select }) {
      const res = yield call(GetFeedbackApplication);
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            centerQrUrl: `data:image/png;base64,${res.data}`,
            FeedbackQrModalVisible: true
          }
        })
      }
    },

    *downloadTrainingQRPhoto(_, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()

      const { centerQrUrl, currentUser } = yield select(state => state.user)
      const { current, changedTenant } = currentUser
      downloadQr({
        type: 'base64',
        data: centerQrUrl,
        title: intl('WechatScan'),
        description: changedTenant.displayName,
        downloadName: intl('QRCode'),
        imgType: 'trainingCode'
      })
    },

    *downloadReportQRPhoto(_, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()

      const { reportQrUrl, currentUser } = yield select(state => state.user)
      const { changedTenant } = currentUser
      downloadQr({
        type: 'base64',
        data: reportQrUrl,
        title: intl('WechatScan'),
        subTitle: intl('viewReport'),
        description: changedTenant.displayName,
        downloadName: intl('QRCodeOfReport'),
        imgType: 'examCode'
      })
    },

    *downloadFeedbackQRPhoto(_, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()

      const { centerQrUrl, currentUser } = yield select(state => state.user)
      downloadQr({
        type: 'base64',
        data: centerQrUrl,
        title: intl('WechatScan'),
        description: '思欣跃儿童优脑',
        descriptionSecend: "COGLEAP",
        downloadName: '培训反馈码',
        imgType: 'feedbackCode'
      })
    },

    *downloadParentQRPhoto(_, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()

      const { parentQrUrl, currentUser } = yield select(state => state.user)
      const { changedTenant } = currentUser
      downloadQr({
        type: 'base64',
        data: parentQrUrl,
        title: intl('WechatScan'),
        subTitle: intl('parentControlConsole'),
        description: changedTenant.displayName,
        downloadName: intl('QRCodeOfReport'),
        imgType: 'examCode'
      })
    },

    *downloadCoachQRPhoto(_, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()

      const { coachQrUrl, currentUser } = yield select(state => state.user)
      const { changedTenant } = currentUser
      downloadQr({
        type: 'base64',
        data: coachQrUrl,
        title: intl('WechatScan'),
        subTitle: intl('coachControlConsole'),
        description: changedTenant.displayName,
        downloadName: intl('QRCodeOfReport'),
        imgType: 'examCode'
      })
    }
  },

  reducers: {
    saveCurrentUser(state, payload) {
      return {
        ...state,
        currentUser: payload,
      }
    },

    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    }
  },

  subscriptions: {

  }
}