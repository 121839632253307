import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import RecommendationsLine from './components/RecommendationsLine';
import ContentLine from './components/ContentLine';
import BottomPageNum from './components/BottomPageNum';

const showList = [
  {
    content:
      'Encourage regular physical activity: Children with hyperactivity may benefit from regular physical exercise, which can help them burn off excess energy and improve their focus and concentration.',
  },
  {
    content:
      'Provide clear and consistent routines: This can help children with hyperactivity manage their time and focus on tasks. Try to create specific times for meals, homework, and other activities, and stick to them as much as possible.',
  },
  {
    content:
      'Create a calm and structured environment: Provide a calm and structured environment for your child, with minimal distractions and a consistent routine.',
  },
  {
    content:
      'Break tasks into smaller steps: Similar to inattention, breaking tasks into smaller steps can help children with hyperactivity stay focused and on-task.',
  },
  {
    content:
      'Use positive reinforcement: Encourage and reward good behavior and accomplishments, particularly when your child demonstrates self-control and focus.',
  },
];

const RecommendationThree = () => {
  const lineProps = {
    title: 'Hyperactivity',
    content:
      'Children with hyperactivity may struggle with excessive activity, fidgeting, and impulsiveness. Here are some tips to help your child:',
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.recommendationThree}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>Recommendations</div>
          <RecommendationsLine {...lineProps} />
          <div style={{ marginBottom: '40px' }}></div>
          {showList.map((item, index) => {
            const { content } = item || {};
            return <ContentLine key={index} content={content} />;
          })}
        </div>
        <BottomPageNum num={3} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default RecommendationThree;
