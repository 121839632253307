import React from 'react'
import style from './index.less'
import { getLocale, useIntl } from 'umi'
import { ExclamationCircleFilled } from '@ant-design/icons'

const RocketProjectTip = () => {
    const intl = useIntl()
    const isZh = getLocale() === 'zh-CN'

    return (
        <div className={isZh ? style.rocketProjectTip : style.rocketProjectTipEn}>
            <ExclamationCircleFilled className={style.rocketProjectTipIcon} />
            <span>{intl.formatMessage({ id: 'unauthorizedStatus' })}</span>
        </div>
    )
}

export default RocketProjectTip;