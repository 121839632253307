  import { PlanUserInfo } from 'services/rocketService/TrainingPlan'
  
  export default {
    namespace: 'adminSubjectSleep',
  
    state: {
      planInfo:null,
      list:[],
      pagination: {
        current: 1,
        pageSize: 6,
        total: 0,
      },
    },
  
    reducers: {
      updateState(state, { payload }) {
        return {
          ...state,
          ...payload,
        }
      },
    },
  
    effects: {
      *loadList({ payload }, { all, call, put, take, select }) {
        const { subjectInfo } = yield select(state => state.adminSubjectDetail)
        const res = yield call(PlanUserInfo, {subjectId: subjectInfo.id, type: 'Sleep'})
        if(res.response.ok){
          yield put({
            type: 'updateState',
            payload: { planInfo: res.data },
          })
        }
    },
    },
  
    subscriptions: {
      setup({ dispatch, history }) {
        // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
        return () => {}
      },
    },
  }
  