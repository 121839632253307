import { Modal, Form, Select, Button } from 'antd'
import { useIntl } from 'umi'
import styles from './index.less'

const FormItem = Form.Item
const { Option } = Select

const CopyToMultiplePlacesModal = ({
  courseNum,
  totalCount,
  visible,
  onCancel,
  onOk
}) => {
  const intl = useIntl()

  const onSubmit = (values) => {
    onOk(values)
  }

  const onQuit = () => {
    onCancel()
  }

  const handleChange = value => {
    // console.log(value)
  }

  const children = []
  for (let i = 0; i < totalCount + 1; i++) {
    if (courseNum !== i) {
      children.push(<Option key={i}>{'第' + (i + 1) + '课'}</Option>)
    }
  }

  return (
    <Modal
      title={'Copy to multiple places'}
      open={visible}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={onQuit}
      footer={null}
    >
      <Form
        layout="horizontal"
        onFinish={onSubmit}
      >
        <FormItem
          label={'Action'}
          name='courseNum'
          initialValue={[]}
          rules={[{ required: true }]}
        >
          <Select mode="tags" placeholder="Please select" onChange={handleChange}>
            {children}
          </Select>
        </FormItem>
        <div className={styles.footer}>
          <Button className={styles.footerLeft} onClick={onQuit}>{intl.formatMessage({ id: 'cancel' })}</Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: 'submit' })}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default CopyToMultiplePlacesModal
