import React from 'react'
import { useIntl } from 'umi'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import SelectModal from './SelectHfsModal'
import { Form, Input, Button, Divider, Table, Tabs, Popconfirm, Pagination, Modal } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons'
import { convertDuration, showContractStatus } from 'utils/utils'
import { toMoney } from 'cognitiveleap-core-us/utils/utils'
import styles from './NewLocation.less'
import moment from 'moment'

const FormItem = Form.Item
const { TabPane } = Tabs

const contractModalTitleMenu = {
    edit: '编辑合同',
    add: '新建合同',
}

const comfirmDelete = (
    <span style={{ color: 'red' }}>确定删除吗？</span>
)

const confirmStart = (
    <span style={{ color: 'red' }}>确定正式开启吗？</span>
)

const confirmContractStop = (
    <div>
        <span>确定停用吗？</span>
        <span style={{ color: 'red' }}>一经停用，中心将不可用此服务</span>
    </div>
)

// Deactivate 已停用
// Expired 已结束
// Active 生效中
const rowCanNotExpandableStatus = ['Expired', 'Deactivate']
const showActionStatus = ['NotActive', 'Active', 'NotEnable']

const HfsContract = ({
    hfsContractLocked,
    canSendCode,
    vratCenterInfo,
    getCode,
    oneToMoreTableData,
    onSendCode,
    hfsModalEditOrAddType,
    contractTableList,
    hfsModalType,
    hfsModalVisible,
    hfsModalContent,
    hfsReminderModalContent,
    newHfs,
    editHfs,
    editHfsReminder,
    deleteHfs,
    stopHfs,
    onHfsCancel,
    onHfsReminderCancel,
    onHfsOk,
    onHfsReminderOk,
    loading,
    contractPagination,
    hfsContractTime,
    expandedRowKeys,
    onExpandedRowsChange,
    onchangeHfsPagination,
    selectHfsModalVisible,
    selectHfsItem,
    onExpand,
    reloadData,
    statisticsHfsData,
    hfsCodeTime,
    startHfs,
    visible,
}) => {
    const { balance, cumulativeConsumption, cumulativeRecharge, notActive, reminderAmount } = statisticsHfsData || {}
    const selectModalPropsData = selectHfsItem && selectHfsItem.selectModalData && selectHfsItem.selectModalData.resource || []
    const { HfsReminderAmount } = vratCenterInfo
    const hfsModalTitle = {
        "contract": contractModalTitleMenu[hfsModalEditOrAddType],
        "recharge": '充值/扣款',
        "rechargeExpired": "扣款",
        "evaluation": '测评',
        "train": '训练',
        "curriculum": '课程',
        "oneToMore": "一对多",
        "remark": "充值/备注"
    }[hfsModalType]

    const intl = useIntl()

    const contractColumns = [
        { title: '最近提交时间', dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
        { title: '有效期', dataIndex: 'validity', key: 'validity', render: text => <span>{text}</span> },
        // { title: '提醒临界金额', dataIndex: 'reminderAmount', key: 'reminderAmount', render: text => <span>${text}</span> },
        { title: '充值/扣款', dataIndex: 'recharge', key: 'recharge', render: text => <span>+${text}</span> },
        { title: '消费', dataIndex: 'consumeAmount', key: 'consumeAmount' },
        { title: '余额', dataIndex: 'amount', key: 'amount', render: text => <span>${text}</span> },
        { title: '备注', dataIndex: 'remark', key: 'remark' },
        { title: '停用时间', dataIndex: 'stopTime', key: 'stopTime', render: text => <span>{text ? moment(text).local().format('YYYY/MM/DD HH:mm') : ''}</span> },
        { title: '开启状态', dataIndex: 'openStatus', key: 'openStatus' },
        { title: '状态', dataIndex: 'showStatus', key: 'showStatus' },
        {
            title: '操作',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (text, record, index) => {
                const { state } = record || {}
                return (
                    <div>
                        {
                            state === 'NotActive' &&
                            <React.Fragment>
                                <a onClick={() => editHfs(record, 'contract')}>编辑</a>
                                <Divider type='vertical' />
                                <Popconfirm placement="topLeft" title={comfirmDelete} trigger="click" onConfirm={() => deleteHfs(record, 'contract')}>
                                    <a>删除</a>
                                </Popconfirm>
                            </React.Fragment>
                        }
                        {
                            state === 'Active' &&
                            <React.Fragment>
                                <Popconfirm placement="topLeft" title={confirmContractStop} trigger="click" onConfirm={() => stopHfs(record, 'contract')}>
                                    <a>停用</a>
                                </Popconfirm>
                            </React.Fragment>
                        }
                        {
                            state === 'NotEnable' &&
                            <React.Fragment>
                                <a onClick={() => editHfs(record, 'contract')}>编辑</a>
                                <Divider type='vertical' />
                                <Popconfirm placement="topLeft" title={comfirmDelete} trigger="click" onConfirm={() => deleteHfs(record, 'contract')}>
                                    <a>删除</a>
                                </Popconfirm>
                                <Popconfirm placement="topLeft" title={confirmStart} trigger="click" onConfirm={() => startHfs(record, 'contract')}>
                                    <Divider type='vertical' />
                                    <a>正式开启</a>
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                )
            },
        }
    ]

    const rechargeColumns = [
        { title: '添加时间', dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
        { title: '充值/扣款', dataIndex: 'money', key: 'money', render: text => <span>{text > 0 ? `+$${text}` : `-$${Math.abs(text)}`}</span> },
        { title: '备注', dataIndex: 'remark', key: 'remark' },
        {
            title: '操作',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (text, record) => {
                const { state } = record
                return showActionStatus.includes(state) && <a onClick={() => editHfs(record, 'remark')}>编辑</a>
            }
        }
    ]

    const evaluationColumns = [
        { title: '添加时间', dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
        { title: '测评名称', dataIndex: 'resourceName', key: 'resourceName' },
        { title: '单价', dataIndex: 'price', key: 'price' },
        {
            title: '操作',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (text, record, index) => {
                const { state } = record
                return (
                    <div>
                        {
                            showActionStatus.includes(state) &&
                            <React.Fragment>
                                <a onClick={() => editHfs(record, 'evaluation')}>编辑</a>
                                <Divider type='vertical' />
                                <Popconfirm placement="topLeft" title={comfirmDelete} trigger="click" onConfirm={() => deleteHfs(record, 'evaluation')}>
                                    <a>删除</a>
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                )
            },
        }
    ]

    const trainColumns = [
        { title: '添加时间', dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
        { title: '训练名称', dataIndex: 'resourceName', key: 'resourceName' },
        { title: '类型', dataIndex: 'type', key: 'type' },
        { title: '单价', dataIndex: 'price', key: 'price' },
        {
            title: '操作',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (text, record, index) => {
                const { state } = record
                return (
                    <div>
                        {
                            showActionStatus.includes(state) &&
                            <React.Fragment>
                                <a onClick={() => editHfs(record, 'train')}>编辑</a>
                                <Divider type='vertical' />
                                <Popconfirm placement="topLeft" title={comfirmDelete} trigger="click" onConfirm={() => deleteHfs(record, 'train')}>
                                    <a>删除</a>
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                )
            },
        }
    ]

    const curriculumColumns = [
        { title: '添加时间', dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
        { title: '课程名称', dataIndex: 'resourceName', key: 'resourceName' },
        { title: '单价', dataIndex: 'price', key: 'price' },
        {
            title: '操作',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (text, record, index) => {
                const { state } = record
                return (
                    <div>
                        {
                            showActionStatus.includes(state) &&
                            <React.Fragment>
                                <a onClick={() => editHfs(record, 'curriculum')}>编辑</a>
                                <Divider type='vertical' />
                                <Popconfirm placement="topLeft" title={comfirmDelete} trigger="click" onConfirm={() => deleteHfs(record, 'curriculum')}>
                                    <a>删除</a>
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                )
            },
        }
    ]

    const onToMoreColumns = [
        { title: '添加时间', dataIndex: 'creationTime', key: 'creationTime', render: text => <span>{moment(text).local().format('YYYY/MM/DD HH:mm')}</span> },
        { title: '名称', dataIndex: 'resourceName', key: 'resourceName' },
        { title: '类型', dataIndex: 'type', key: 'type' },
        {
            title: '线上单价',
            dataIndex: 'appPrice',
            key: 'appPrice',
            render: (text, record) => {
                const { price } = text
                return price
            }
        },
        {
            title: '线下单价',
            dataIndex: 'webPrice',
            key: 'webPrice',
            render: (text, record) => {
                const { price } = text
                return price
            }
        },
        {
            title: '操作',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (text, record, index) => {
                const { state } = record
                return (
                    <div>
                        {
                            showActionStatus.includes(state) &&
                            <React.Fragment>
                                <a onClick={() => editHfs(record, 'oneToMore')}>编辑</a>
                                <Divider type='vertical' />
                                <Popconfirm placement="topLeft" title={comfirmDelete} trigger="click" onConfirm={() => deleteHfs(record, 'onetomore')}>
                                    <a>删除</a>
                                </Popconfirm>
                            </React.Fragment>
                        }
                    </div>
                )
            },
        }
    ]

    const hfsModalProps = {
        visible: hfsModalVisible,
        title: hfsModalTitle,
        onCancel: onHfsCancel,
        onOk: (values) => onHfsOk(values, hfsModalType, hfsModalEditOrAddType),
        content: hfsModalContent,
        loading
    }

    const hfsReminderModalProps = {
        visible,
        title: '修改临界金额',
        onCancel: onHfsReminderCancel,
        onOk: (values) => onHfsReminderOk(values),
        content: hfsReminderModalContent,
    }

    const selectModalProps = {
        loading: loading,
        selectModalVisible: selectHfsModalVisible,
        allList: selectModalPropsData.map(item => {
            return {
                id: item.resourceId,
                title: item.resourceName,
                disabled: item.isInContract
            }
        }),
        onOk: (resourcesIds) => {
            onHfsOk({ resourcesIds }, hfsModalType)
        },
        onCancel: onHfsCancel
    }
    return (
        <div className={styles.hfsContract}>
            {
                hfsContractLocked ?
                    <div className={styles.lock}>
                        <div className={styles.lockTop}>
                            <Button type="primary" onClick={() => { newHfs(null, 'contract') }}><PlusOutlined />合同</Button>
                            <div className={styles.timeDown}>{convertDuration(hfsContractTime)}</div>
                        </div>
                        <div className={styles.hfsContractData}>
                            <span className={balance <= HfsReminderAmount ? styles.hfsContractDataBalance : ''}>余额：￥{toMoney(balance)}</span>
                            <Divider type='vertical' />
                            <span>累计消费：￥{toMoney(cumulativeConsumption)}</span>
                            <Divider type='vertical' />
                            <span>充值：￥{toMoney(cumulativeRecharge)}</span>
                            <span> {notActive > 0 && `（未生效￥${toMoney(notActive)}）`}</span>
                            <Divider type='vertical' />
                            <span>提醒临界金额：￥{toMoney(HfsReminderAmount)} &nbsp;<a onClick={() => editHfsReminder()}>编辑</a></span>
                        </div>
                        <Table
                            columns={contractColumns}
                            dataSource={
                                contractTableList.map(item => {
                                    return {
                                        ...item,
                                        key: item.id,
                                        validity: `${moment(item.startTime).local().format('YYYY/MM/DD')}-${moment(item.endTime).local().format('YYYY/MM/DD')}`,
                                        showStatus: showContractStatus(item.state, intl),
                                        openStatus: item.state === 'NotEnable' ? '未开启' : '已开启',
                                        recharge: item.amount + item.consumeAmount
                                    }
                                })
                            }
                            expandedRowKeys={expandedRowKeys}
                            onExpand={(expanded, record) => onExpand(expanded, record)}
                            onExpandedRowsChange={onExpandedRowsChange}
                            pagination={false}
                            loading={loading}
                            expandable={{
                                expandedRowRender: (record, index) => {
                                    const { state, subData = {} } = record || {};
                                    return (
                                        <div className={styles.tableTabs}>
                                            <Tabs type="card" onChange={(key) => {
                                                reloadData(key, record)
                                            }}>
                                                <TabPane tab="充值" key="recharge">
                                                    <Button onClick={() => { newHfs(record, 'recharge') }} disabled={rowCanNotExpandableStatus.includes(state) && state !== 'Expired'}>{state === 'Expired' ? "扣款" : "充值/扣款"}</Button>
                                                    <Table
                                                        columns={rechargeColumns}
                                                        dataSource={record && subData && subData.records ? subData.records.map((item, index) => { return { ...item, key: index, state: subData.state } }) : []}
                                                        pagination={false}
                                                    />
                                                </TabPane>
                                                <TabPane tab="测评" key="evaluation">
                                                    <Button onClick={() => { newHfs(record, 'evaluation') }} disabled={rowCanNotExpandableStatus.includes(state)}><PlusOutlined />测评</Button>
                                                    <Table
                                                        columns={evaluationColumns}
                                                        dataSource={record && subData && subData.resources ? subData.resources.filter(item => item.type === 'Assessment').map((item, index) => { return { ...item, key: index, state: subData.state } }) : []}
                                                        pagination={false}
                                                    />
                                                </TabPane>
                                                <TabPane tab="训练" key="train">
                                                    <Button onClick={() => { newHfs(record, 'train') }} disabled={rowCanNotExpandableStatus.includes(state)}><PlusOutlined />训练</Button>
                                                    <Table
                                                        columns={trainColumns}
                                                        dataSource={record && subData && subData.resources ? subData.resources.filter(item => item.type === 'OnlinePlanCourse' || item.type === 'OfflinePlanCourse').map((item, index) => { return { ...item, key: index, state: subData.state, type: intl.formatMessage({ id: item.type === 'OnlinePlanCourse' ? 'onlineSession' : 'offlineSession' }) } }) : []}
                                                        pagination={false}
                                                    />
                                                </TabPane>
                                                <TabPane tab="课程" key="curriculum">
                                                    <Button onClick={() => { newHfs(record, 'curriculum') }} disabled={rowCanNotExpandableStatus.includes(state)}><PlusOutlined />课程</Button>
                                                    <Table
                                                        columns={curriculumColumns}
                                                        dataSource={record && subData && subData.resources ? subData.resources.filter(item => item.type === 'Course').map((item, index) => { return { ...item, key: index, state: subData.state } }) : []}
                                                        pagination={false}
                                                    />
                                                </TabPane>
                                                <TabPane tab="一对多" key="oneToMore">
                                                    <Button onClick={() => { newHfs(record, 'oneToMore') }} disabled={rowCanNotExpandableStatus.includes(state)}><PlusOutlined />一对多</Button>
                                                    <Table
                                                        columns={onToMoreColumns}
                                                        dataSource={oneToMoreTableData.map(item => {
                                                            const { prices } = item
                                                            const webPrice = prices.find(data => data.platform === 'Web')
                                                            const appPrice = prices.find(data => data.platform === 'App')
                                                            return {
                                                                ...item,
                                                                webPrice,
                                                                appPrice,
                                                                state: subData.state
                                                            }
                                                        })}
                                                        pagination={false}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                        </div>
                                    )
                                }
                            }}
                        />
                        <div className={styles.hfsPagination}>
                            <Pagination
                                {...contractPagination}
                                onChange={onchangeHfsPagination}
                            />
                        </div>
                    </div>
                    :
                    <div className={styles.unLock}>
                        <div className={styles.unLockLeft}>
                            <Form onFinish={values => onSendCode(values)}>
                                <FormItem name="code" rules={[{ required: true, message: '验证码必填' }]}>
                                    <Input
                                        className={styles.unLockLeftInput}
                                        placeholder='请输入验证码'
                                    />
                                </FormItem>
                                <Button
                                    className={styles.unLockLeftBtn}
                                    type='primary'
                                    htmlType='submit'
                                    loading={loading}
                                >
                                    确定
                                </Button>
                            </Form>
                        </div>
                        <div className={styles.unLockRight}>
                            {
                                canSendCode ?
                                    <Button onClick={getCode} className={styles.unLockRightBtn}>获取验证码</Button> :
                                    <div className={styles.unLockRightCountDownBtn}><Button disabled>{`${hfsCodeTime}${intl.formatMessage({ id: 'resendAfterSeconds' })}`}</Button></div>
                            }
                        </div>
                    </div>
            }
            <MagicFormModal {...hfsModalProps} />
            <MagicFormModal {...hfsReminderModalProps} />
            <SelectModal {...selectModalProps} />
        </div>
    )
}

export default HfsContract