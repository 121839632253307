import ReactEcharts from 'echarts-for-react';

const RoseChart = ({ data }) => {
  let color = ['#36abd3'];
  const option = {
    color,

    angleAxis: {},

    radiusAxis: {
      min: 0,
      max: 12,
      splitNumber: 6,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },

    polar: {
      //圆心在图中心的位置
      center: ['50%', '53%'],
    },
    series: [
      {
        coordinateSystem: 'polar',
        type: 'line',
        showSymbol: false,
        lineStyle: {
          width: 1,
        },
        areaStyle: {},
        data: [],
      },
    ],
  };

  const showData = [];

  data.forEach((item, index) => {
    showData.push([item, index]);
  });

  //需要把最后一个点和第一个点连起来
  showData.push([data[0], 0]);

  option.series[0].data = showData;

  return (
    <ReactEcharts
      option={option}
      style={{ height: '360px', width: '360px', marginTop: '30px' }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default RoseChart;
