import { useState } from 'react';
import { Card, Tabs } from 'antd';
import { useIntl } from 'umi';
import IndicatorsAnalysis from './IndicatorsAnalysis';
import StatisticalData from './StatisticalData';

const { TabPane } = Tabs;

const OneToMoreAnalysis = ({ location }) => {
    const intl = useIntl();

    const { tab = 'Statistical' } = location.query || {};

    const [activeKey, setActiveKey] = useState(tab)

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'VRATAnalysis' })}</b>}
      bordered={false}
    >
      <Tabs activeKey={activeKey} onChange={(value) => setActiveKey(value)}>
        <TabPane tab={intl.formatMessage({ id: 'Statistics' })} key="Statistical">
          <StatisticalData />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'Analysis' })}  key="Indicators">
          <IndicatorsAnalysis />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default OneToMoreAnalysis;
