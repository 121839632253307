import { GetTestRequestList } from 'services/vrat'
import { message } from 'antd'
import { useIntl } from 'umi'

export default {
    namespace: 'adminSubjectVrat',

    state: {
        list: [],
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    effects: {

        *changeTable({ payload: data }, { call, put, select }) {
            const { pagination: currentPagination, } = yield select(
                state => state.adminSubjectVrat
            )
            const { pagination } = data
            yield put({
                type: 'updateState',
                payload: { pagination: { ...currentPagination, ...pagination } },
            })
            yield put({ type: 'loadList', })
        },

        *loadList(_, { call, put, take, select }) {
            const { subjectInfo } = yield select(state => state.adminSubjectDetail)
            const { pagination, } = yield select(state => state.adminSubjectVrat)
            const currentPageIndex = pagination.current
            const currentPageSize = pagination.pageSize
            const query = {
                subjectId: subjectInfo.id,
                skipCount: (currentPageIndex - 1) * currentPageSize,
                maxResultCount: currentPageSize,
            }
            const res = yield call(GetTestRequestList, query)
            if (res.response.ok) {
                const { items, totalCount } = res.data
                const newPagination = {
                    current: currentPageIndex,
                    pageSize: currentPageSize,
                    total: totalCount,
                }
                yield put({
                    type: 'updateState',
                    payload: { pagination: newPagination, list: items, },
                })
            }
        },
    },

    subscriptions: {
        setup({ dispatch, history }) {
            // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
            return () => { }
        },
    },
}
