import React, { Fragment, useEffect } from 'react'
import { connect, useIntl } from 'umi'
import { Card, Tabs, Badge, Spin, Pagination, Tooltip, Tag, Avatar } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { formatNotificationData, getNotificationCount } from 'utils/utils'
import NotificationContent from 'components/NotificationContent'
import ReleaseModal from 'components/ReleaseModal'
import styles from './index.less'

const { TabPane } = Tabs;
const { CheckableTag } = Tag;

const Notification = ({ notificationCenter, notification, dispatch, loading }) => {

    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'notificationCenter/init' })
    }, [])

    const { activeKey, filterTitle, isOpen, data, pagination, modalVisible, releaseInfo } = notificationCenter
    const { unreadUserCount: UserCount, unreadSystemCount: SystemCount } = notification

    const isLoading = loading.effects['notificationCenter/load']

    const tabOption = {
        User: <Badge count={UserCount} offset={[12, 0]}>{intl.formatMessage({ id: 'userMessage' })}</Badge>,
        System: <Badge count={SystemCount} offset={[12, 0]}>{intl.formatMessage({ id: 'systemNotification' })}</Badge>
    }

    const onChangePagination = (pageNumber, pageSize) => {
        dispatch({
            type: 'notificationCenter/changePagination',
            payload: { current: pageNumber, pageSize }
        })
    }

    const onTabClick = (activeKey) => {
        getNotificationCount();
        dispatch({ type: 'notificationCenter/updateState', payload: { data: null } })
        dispatch({
            type: 'notificationCenter/touchTab',
            payload: { activeKey }
        })
    }

    const checkNotEmpty = (activeKey) => {
        if (activeKey === 'User') return UserCount !== 0
        else return SystemCount !== 0
    }

    const signAllToHasRead = () => {
        if (checkNotEmpty(activeKey)) {
            dispatch({ type: 'notification/signAllToHasRead', payload: { key: activeKey } })
        }
    }

    const onchangeCheckTag = (filter) => {
        dispatch({
            type: 'notificationCenter/changeCheckTag',
            payload: { filter }
        })
    }

    const showMoreFilter = () => {
        dispatch({
            type: 'notificationCenter/showMoreFilter'
        })
    }

    const resultData = formatNotificationData(data);

    const notificationConentProps = {
        activeKey,
        datasource: resultData,
        isShowHasRead: true,
        type: 'all',
        showTimeLocation: 'right',
        signToRead: (data) => {
            dispatch({
                type: 'notification/signToRead',
                payload: { data }
            })
        },
        showReleaseModal: (data) => {
            dispatch({
                type: 'notificationCenter/updateState',
                payload: { modalVisible: true, releaseInfo: data }
            })
        }
    }

    const releaseModalProps = {
        visible: modalVisible,
        data: releaseInfo,
        onCancel: () => {
            dispatch({
                type: 'notificationCenter/updateState',
                payload: { modalVisible: false }
            })
        },
        signToRead: (data) => {
            dispatch({
                type: 'notificationCenter/updateState',
                payload: { modalVisible: false }
            })
            dispatch({
                type: 'notification/signToRead',
                payload: { data }
            })
        },
    }

    return (
        <Card
            title={<b className={styles.pageName}>{intl.formatMessage({ id: 'messageCenter' })}</b>}
            bordered={false}
            className={styles.notificationCenter}
        >
            <Spin
                spinning={isLoading}
            >
                <Tabs
                    activeKey={activeKey}
                    onTabClick={key => onTabClick(key)}
                    tabBarExtraContent={
                        <div
                            className={styles.notificationCenterTabsIcon}
                            onClick={() => signAllToHasRead()}
                        >
                            <Tooltip title={intl.formatMessage({ id: 'allMarkedAsRead' })} placement='bottom'><CheckOutlined /></Tooltip>
                        </div>
                    }
                >
                    <TabPane
                        tab={tabOption.User}
                        key="User"
                        className={styles.notificationCenterContent}
                    >
                        <div>
                            {
                                filterTitle.map(item => {
                                    return (
                                        item.isShow &&
                                        <CheckableTag
                                            key={item.name}
                                            checked={item.checked}
                                            className={item.checked ? styles.notificationCenterCheck : styles.notificationCenterChecking}
                                            onChange={() => !item.checked && onchangeCheckTag(item)}
                                        >
                                            {item.name}
                                        </CheckableTag>
                                    )
                                })
                            }
                            {
                                !isOpen &&
                                <a onClick={() => { showMoreFilter() }}>{intl.formatMessage({ id: 'more' })}</a>
                            }
                        </div>
                        <div>
                            <NotificationContent {...notificationConentProps} />
                        </div>
                    </TabPane>
                    <TabPane
                        tab={tabOption.System}
                        key="System"
                        className={styles.notificationCenterContent}
                    >
                        <NotificationContent {...notificationConentProps} />
                    </TabPane>
                </Tabs>
            </Spin>
            <br />
            <Pagination
                showQuickJumper
                current={pagination[activeKey].current}
                total={pagination[activeKey].total}
                pageSize={pagination[activeKey].pageSize}
                onChange={onChangePagination}
            />
            <ReleaseModal {...releaseModalProps} />
        </Card>
    )
}

export default connect(({ notificationCenter, notification, loading }) => ({ notificationCenter, notification, loading }))(Notification)