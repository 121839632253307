
import { PostUserList, PostUser, DelUser, PrepareEditUser, PutUser, PostSendEmail, FreezeUser, postChangeRole } from 'services/userManager'
import { GetAdminStaffList, GetQualification } from 'services/trainingSystem'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import { message } from 'antd'

import { matchDynamicForm, addOptionsToProperties, } from 'cognitiveleap-core-us/utils/utils'

const { uploadUserPicUrl, baseURL } = config

const defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
}

const contentMenu = (intl) => {
  return {
    Properties: [
      // {
      //   EditorType: 'Input',
      //   ShowTitle: intl.formatMessage({ id: 'name' }),
      //   FormKey: 'name',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     Required: true,
      //   },
      //   Description: null,
      // },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'email' }),
        FormKey: 'email',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          Rules: [
            {
              pattern:
                /^(\s+|)([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})(\s+|)?$/,
              message: '请输入有效邮箱',
            },
          ],
        },
        Description: null,
      },
      // {
      //   EditorType: 'Dropdown',
      //   ShowTitle: intl.formatMessage({ id: 'countryCode' }),
      //   FormKey: 'countryCode',
      //   AdditionalData: null,
      //   Value: null,
      //   Setting: {
      //     DropdownOptions: [
      //       {
      //         Value: intl.formatMessage({ id: 'unitedState' }),
      //         Id: '1',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'china' }),
      //         Id: '86',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'mexico' }),
      //         Id: '52',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'israel' }),
      //         Id: '972',
      //       },
      //     ],
      //     Required: false,
      //   },
      //   Description: null,
      // },
      // {
      //   EditorType: 'Input',
      //   ShowTitle: intl.formatMessage({ id: 'phone' }),
      //   FormKey: 'phoneNumber',
      //   AdditionalData: null,
      //   Value: '',
      //   Setting: {
      //     Required: false,
      //   },
      //   Description: null,
      // },
      // {
      //   EditorType: 'Dropdown',
      //   ShowTitle: intl.formatMessage({ id: 'gender' }),
      //   FormKey: 'gender',
      //   AdditionalData: null,
      //   Value: 'Female',
      //   Setting: {
      //     DropdownOptions: [
      //       {
      //         Value: intl.formatMessage({ id: 'male' }),
      //         //Value: intl.formatMessage({ id: 'Invalid' }),
      //         Id: 'Male',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'female' }),
      //         //Value: intl.formatMessage({ id: 'Effective' }),
      //         Id: 'Female',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'other' }),
      //         //Value: intl.formatMessage({ id: 'Invalid' }),
      //         Id: 'Others',
      //       },
      //     ],
      //     Required: false,
      //   },
      //   Description: null,
      // },
      // {
      //   EditorType: 'Upload',
      //   Value: null,
      //   Setting: {
      //     UploadSetting: {
      //       uploadType: 'image',
      //       isSingle: true,
      //     },
      //     Action: baseURL + uploadUserPicUrl,
      //     Header: getAuthHeader(),
      //   },
      //   ShowTitle: intl.formatMessage({ id: 'picture' }),
      //   FormKey: 'picture',
      // },
      // {
      //   EditorType: 'DatePicker',
      //   Value: null,
      //   Setting: {
      //     DateType: 'date'
      //   },
      //   ShowTitle: intl.formatMessage({ id: 'birthday' }),
      //   FormKey: 'birthday',
      // },
      // {
      //   EditorType: 'Dropdown',
      //   ShowTitle: intl.formatMessage({ id: 'status' }),
      //   FormKey: 'state',
      //   AdditionalData: null,
      //   Value: 'Effective',
      //   Setting: {
      //     DropdownOptions: [
      //       {
      //         Value: intl.formatMessage({ id: 'effective' }),
      //         //Value: intl.formatMessage({ id: 'Effective' }),
      //         Id: 'Effective',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'inActive' }),
      //         //Value: intl.formatMessage({ id: 'Invalid' }),
      //         Id: 'Invalid',
      //       },
      //     ],
      //     Required: false,
      //   },
      //   Description: null,
      // },
      {
        EditorType: 'Dropdown',
        ShowTitle: intl.formatMessage({ id: 'roleName' }),
        FormKey: 'roleName',
        AdditionalData: null,
        Value: null,
        Setting: {
          Disabled: true,
          DropdownOptions: [],
          Required: true,
        },
        Description: null,
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'comments' }),
        FormKey: 'remark',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: null,
      }
    ],
  }
}

const changeRoleContentMenu = (intl) => {
  return {
    Properties: [
      {
        EditorType: 'Dropdown',
        ShowTitle: intl.formatMessage({ id: 'roleName' }),
        FormKey: 'roleName',
        AdditionalData: null,
        Value: null,
        Setting: {
          DropdownOptions: [],
          Required: true,
        },
        Description: intl.formatMessage({ id: 'changeDes' })
      }
    ]
  }
}

export default {
  namespace: 'group',
  state: {
    // qualificationList: [],
    list: [],
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
    contentMenu: null,
    selectItem: null,
    modalVisible: false,
    type: '',
    // 选择员工成为中心管理员的弹框
    choiceModalVisible: false,
    // 当前选择的角色名
    choiceRole: '',
    // 当前所有角色
    roleNameOptions: [],
    // 选择的员工列表
    choiceStaff: [],
    // 选择员工id
    choiceStaffId: '',
    isChangeAdmin: false,
    filter: '',
    activeStatus: '',
    filters: {},
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query({ payload }, { call, put, select }) {
      yield put({ type: 'getList', payload })
      yield put({ type: 'getPrepareForEdit' })
      // yield put({ type: 'getQualification' })
    },

    *getPrepareForEdit({ payload }, { call, put, select }) {
      const res = yield call(PrepareEditUser, { type: 'Center' });

      if (res.response.ok) {
        const { roles = [] } = res.data
        let roleNameOptions = roles.map(item => {
          const { name, displayName } = item
          return {
            Value: displayName,
            Id: name,
          }
        })

        yield put({
          type: 'updateState',
          payload: {
            roleNameOptions,
          },
        })
      }
    },

    *getQualification({ payload }, { call, put, select }) {
      const res = yield call(GetQualification, { SkipCount: 0, MaxResultCount: 1000 })
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { qualificationList: res.data.items } })
      }
    },

    *getList({ payload }, { call, put, take, select }) {
      const { pagination, filter, activeStatus, filters } = yield select(state => state.group)
      const { userRoles } = filters || {}
      let query = { skipCount: 0, maxResultCount: 10, }
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
        filter: filter,
        state: activeStatus,
        roles: userRoles || []
      }
      const res = yield call(PostUserList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination, filters = {} } = data || {}

      const isActiveFilter = Object.keys(filters).length > 0
      const currentPagination = isActiveFilter ? defaultPagination : pagination

      if (isActiveFilter) {
        yield put({ type: 'updateState', payload: { filters } })
      }

      yield put({ type: 'updateState', payload: { pagination: currentPagination } })
      yield put({ type: 'getList' })
    },

    *onAddOrEdit({ payload }, { call, put, select }) {
      const { record, type, intl, isActive } = payload
      const { id, roleName } = record || {}
      let formContent = contentMenu(intl)
      let params = {}
      switch (type) {
        case "edit":
        case "changeRole":
          params = { type: 'Center', id };
          break
        case "add":
          params = { type: 'Center' };
          break
      }

      const res = yield call(PrepareEditUser, params)
      if (res.response.ok) {
        const { roles, user } = res.data
        let roleNameOptions = roles.map(item => {
          const { name, displayName, id } = item
          return {
            Value: displayName,
            Id: name,
          }
        })
        let contentMenu
        let content
        switch (type) {
          case "edit":
            const editcontent = { ...formContent }
            // 已经激活的只能修改备注，未激活的可以修改邮箱、备注
            if (isActive) {
              editcontent.Properties[0].Setting.Disabled = true
            }
            editcontent.Properties[1].Setting.Disabled = true
            contentMenu = addOptionsToProperties(editcontent, ['roleName'], roleNameOptions)
            break
          case "add":
            roleNameOptions = roleNameOptions && roleNameOptions.filter(item => item.Id !== "CenterAdmin")
            content = { ...formContent }
            content.Properties[1].Setting.Disabled = false
            contentMenu = addOptionsToProperties(content, ['roleName'], roleNameOptions)
            break
          case "changeRole":
            content = changeRoleContentMenu(intl)
            content.Properties[0].Value = roleName
            contentMenu = addOptionsToProperties(content, ['roleName'], roleNameOptions)
            break
        }
        yield put({
          type: 'updateState',
          payload: {
            modalVisible: true,
            contentMenu,
            selectItem: type === 'add' ? null : res.data,
            type,
            roleNameOptions,
            isChangeAdmin: false
          },
        })
      }
    },

    *onSwitchState({ payload: data }, { call, put, select }) {
      const { state, id } = data
      const newState = state === 'Effective' ? 'Freeze' : 'Unfreeze'
      const res = yield call(FreezeUser, { operate: newState, userId: id })
      if (res.response.ok) {
        yield put({ type: 'getList' })
      }
      else {
        if (res && res.data && res.data.error && res.data.error.message) {
          message.error(res.data.error.message)
        }
      }
    },

    *onSendEmail({ payload: { intl, id } }, { call, put, select }) {
      const res = yield call(PostSendEmail, id)
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'mailSentSuccessfully' }))
        yield put({ type: 'getList' })
      } else {
        message.error(intl.formatMessage({ id: 'failedToSendMail' }))
      }
    },

    *onDelete({ payload: { id, intl } }, { call, put, select }) {
      const res = yield call(DelUser, { userId: id })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({ type: 'getList' })
      } else {
        message.success(intl.formatMessage({ id: 'error' }))
      }
    },

    *onSubmitModal({ payload: { data, intl } }, { take, put, call, select }) {
      const { selectItem, type, choiceRole, choiceStaffId } = yield select(state => state.group)
      const { id } = selectItem && selectItem.user || {}
      let res = null
      switch (type) {
        case "add":
          res = yield call(PostUser, data)
          break
        case "edit":
          res = yield call(PutUser, { ...selectItem.user, ...data })
          break
        case "changeRole":
          if (selectItem.user.roleName === "CenterAdmin" && data.roleName !== "CenterAdmin") {
            yield put({
              type: 'getChoiceStaff',
              payload: ''
            })
            yield put({
              type: 'updateState',
              payload: {
                choiceModalVisible: true,
                modalVisible: false,
                choiceRole: data.roleName
              }
            })
            return
          }
          res = yield call(postChangeRole, { userId: id, roleName: data.roleName })
          break
      }
      if (res && res.response.ok) {
        switch (type) {
          case "add":
            message.success(intl.formatMessage({ id: 'mailSentSuccessfully' }))
            break
          case "edit":
            message.success(intl.formatMessage({ id: 'success' }))
            break
          case "changeRole":
            message.success(intl.formatMessage({ id: 'success' }))
            break
        }
        yield put({ type: 'getList' })
        yield put({ type: 'updateState', payload: { modalVisible: false, choiceModalVisible: false } })
      }
      else {
        if (res && res.data && res.data.error && res.data.error.message) {
          message.error(res.data.error.message)
        }
      }
    },

    *changeAdminRole({ payload }, { take, put, call, select }) {
      const { selectItem, type, choiceRole, choiceStaffId } = yield select(state => state.group)
      const { id } = selectItem && selectItem.user || {}
      const changeAdminRoleRes = yield call(postChangeRole, { userId: id, roleName: choiceRole, nextAdmin: choiceStaffId })
      if (changeAdminRoleRes.response.ok) {
        yield put({
          type: 'user/logout'
        })
      } else {
        if (changeAdminRoleRes && changeAdminRoleRes.data && changeAdminRoleRes.data.error && changeAdminRoleRes.data.error.message) {
          message.error(changeAdminRoleRes.data.error.message)
        }
      }
    },

    *getChoiceStaff({ payload }, { take, put, call, select }) {
      const params = {
        SkipCount: 0,
        MaxResultCount: 1000,
        IsActive: true,
        State: 'Effective'
      }
      const getChoiceStaffRes = yield call(PostUserList, params)
      if (getChoiceStaffRes.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            choiceStaff: getChoiceStaffRes.data.items
          }
        })
      }
    },

    *onClear({ payload }, { take, put, call, select }) {
      yield put({
        type: 'updateState',
        payload: {
          pagination: defaultPagination,
          filter: '',
          activeStatus: '',
          filters: {},
        }
      })
      yield put({ type: 'getList' })
    }

  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/manage/group') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
