import React, { useEffect } from 'react';
import { connect } from 'umi'
import ShowDataAnalysis from 'components/ShowDataAnalysis'
import CardHead from 'components/CardHead';
import { Card, Spin } from 'antd'
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart'
import CommonPieTable from 'cognitiveleap-core-us/components/Charts/Common/CommonPieTable'
import { guardianLegend, guardianKeyIndicators, tableColumns, guardianGengerDataSource } from '../config';
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import { useIntl } from 'umi'
import style from './index.less';
import { getProportionData } from 'cognitiveleap-core-us/utils/utils';

const GuardianAnalysis = ({ adminGuardianAnalysis, loading, dispatch }) => {
    const intl = useIntl()
    useEffect(() => {
        dispatch({ type: 'adminGuardianAnalysis/changeTenant' })
    }, [])

    const {
        currentTag,
        datePickerIsOpen,
        dateMonthPickerData,
        dateYearPickerData,
        analysisData,
        keyIndicators,
        currentTenant
    } = adminGuardianAnalysis;

    if (analysisData == null || keyIndicators == null) {
        return <Spin />
    }
    const { genderChart, trend } = analysisData;

    const isLoadingAnalysis = loading.effects['adminGuardianAnalysis/loadAnalysisData'] || loading.effects['adminGuardianAnalysis/loadKeyIndicators'];

    const chartProps = {
        legendData: guardianLegend(intl),
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        dataSource: trend
    };

    const genderTotalNum = genderChart.length > 0 && genderChart.map(item => item.num).reduce((prev, cur) => {
        return prev + cur
    });

    const showGuardianGengerDataSource = guardianGengerDataSource(intl).map(item=>{
        const data = genderChart.find( genderItem => genderItem.gender == item.gender );
        return {
            ...item,
            number:data!==undefined?data.num:0,
            proportion: data!==undefined?getProportionData(data.num, genderTotalNum): '0%'
        }
    })

    const showGuardianKeyIndicators = guardianKeyIndicators(intl).map(item=>{
        return {
            ...item,
            data: keyIndicators[item.field]
        }
    })

    const genderProps = {
        title: intl.formatMessage({ id: 'genderDistribution' }),
        color: ['#69C0FF', '#FFADD2', '#D9F7BE', '#E8E8E8'],
        totalNum: genderTotalNum,
        columns: tableColumns(intl),
        dataSource: showGuardianGengerDataSource,
        legendData: [intl.formatMessage({ id: 'male' }), intl.formatMessage({ id: 'female' }), intl.formatMessage({ id: 'unknown' }), intl.formatMessage({ id: 'other' })]
    }

    const changeTag = (tag, checked) => {
        dispatch({
            type: 'adminGuardianAnalysis/changeTag',
            payload: {
                key: tag.key
            }
        })
    }

    const handleOpenChange = () => {
        dispatch({
            type: 'adminGuardianAnalysis/updateState',
            payload: {
                datePickerIsOpen: !datePickerIsOpen
            }
        })
    }

    const handlePanelChange = (time) => {
        dispatch({
            type: 'adminGuardianAnalysis/handlePanelChange',
            payload: {
                time
            }
        })
    }

    const analysisChoiceDateProps = {
        currentTag,
        dateMonthPickerData,
        dateYearPickerData,
        datePickerIsOpen,
        changeTag,
        handleOpenChange,
        handlePanelChange
    }
    return (
        <Card
            title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'parentUserAnalysis' })}</b>}
            bordered={false}
            extra={
                <AnalysisTenantSelect
                    showAll={true}
                    currentTenant={currentTenant}
                    onChange={(e) => {
                        dispatch({ type: 'adminGuardianAnalysis/changeTenant', payload: { id: e } })
                    }}
                />
            }
        >
            <div className={style.guardianAnalysis}>

                <div className={style.keyIndicators}>
                    <CardHead  title={intl.formatMessage({ id: 'keyIndicators' })}  />
                    <ShowDataAnalysis keyIndicators={showGuardianKeyIndicators} />
                </div>
                <div className={style.trendAnalysis}>
                    <CardHead title={intl.formatMessage({ id: 'trendAnalysis'})} />
                    <AnalysisChoiceDate {...analysisChoiceDateProps} />
                    <div className={style.trendAnalysisContent}>
                        <Spin
                            spinning={isLoadingAnalysis}
                        >
                            <CommonAnalysisLineChart {...chartProps} />
                        </Spin>
                        <div className={style.trendAnalysisContentChart}><CommonPieTable {...genderProps} /></div>
                    </div>
                </div>

            </div>
        </Card>
    )
}

export default connect(({ adminGuardianAnalysis, loading }) => ({ adminGuardianAnalysis, loading }))(GuardianAnalysis);