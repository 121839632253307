import React from 'react';
import { Row, Col } from 'antd';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import BottomPageNum from './components/BottomPageNum';
import { useIntl } from 'umi';
const getValue = (value) => {
  const data = value * 100;
  if (data > 0 && data < 1) {
    return '<1';
  }
  return Math.round(data);
};

const MentalEnduranceTwo = ({ data }) => {
  const intl = useIntl();
  // 分母
  const standard = 52 / 4;

  const showData = data && data.length > 0 ? data.slice(1) : [];

  const mentalDes = [
    {
      title: intl.formatMessage({ id: 'MentalEnduranceTwo.green' }),
      color: '#00BB73',
      des: intl.formatMessage({ id: 'MentalEnduranceTwo.well' }),
    },
    {
      title: intl.formatMessage({ id: 'MentalEnduranceTwo.yellow' }),
      color: '#FFB500',
      des: intl.formatMessage({ id: 'MentalEnduranceTwo.distracte' }),
    },
    {
      title: intl.formatMessage({ id: 'MentalEnduranceTwo.red' }),
      color: '#FF8682',
      des: intl.formatMessage({ id: 'MentalEnduranceTwo.much' }),
    },
  ];

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.mentalEnduranceTwo}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>{intl.formatMessage({ id: 'MentalEnduranceTwo.centerTitle' })}</div>
          <Row
            gutter={[16, 16]}
            justify="center"
            align="middle"
            style={{ textAlign: 'center' }}
          >
            <Col span={6}></Col>
            <Col span={4}>
              <div className={styles.block}>{intl.formatMessage({ id: 'MentalEnduranceTwo.block1' })}</div>
            </Col>
            <Col span={4}>
              <div className={styles.block}>{intl.formatMessage({ id: 'MentalEnduranceTwo.block2' })}</div>
            </Col>
            <Col span={4}>
              <div className={styles.block}>{intl.formatMessage({ id: 'MentalEnduranceTwo.block3' })}</div>
            </Col>
            <Col span={4}>
              <div className={styles.block}>{intl.formatMessage({ id: 'MentalEnduranceTwo.block4' })}</div>
            </Col>

            <Col span={6}>
              <div className={styles.paidAttention}>{intl.formatMessage({ id: 'MentalEnduranceTwo.paid' })}</div>
            </Col>
            {showData.map((item) => {
              const { CorrectAnswer, Block } = item || {};
              return (
                <Col key={Block} span={4}>
                  <div className={styles.paidAttentionNum}>
                    {getValue(CorrectAnswer / standard)}%
                  </div>
                </Col>
              );
            })}

            <Col span={6}>
              <div className={styles.distracted}>{intl.formatMessage({ id: 'MentalEnduranceTwo.distracted' })}</div>
            </Col>
            {showData.map((item) => {
              const { OmissionError, Block } = item || {};
              return (
                <Col key={Block} span={4}>
                  <div className={styles.distractedNum}>
                    {getValue(OmissionError / standard)}%
                  </div>
                </Col>
              );
            })}

            <Col span={6}>
              <div className={styles.lackSelf}>{intl.formatMessage({ id: 'MentalEnduranceTwo.lacked' })}</div>
            </Col>
            {showData.map((item) => {
              const { CommissionError, Block, CorrectAnswer } = item || {};
              return (
                <Col key={Block} span={4}>
                  <div className={styles.lackSelfNum}>
                    {CorrectAnswer == 0 && CommissionError == 0
                      ? intl.formatMessage({ id: 'TextNA' })
                      : `${getValue(
                          CommissionError / (CorrectAnswer + CommissionError),
                        )}%`}
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className={styles.mentalDes}>
            {mentalDes.map((item) => {
              const { title, color, des } = item || {};
              return (
                <div key={title} className={styles.metalItem}>
                  <div style={{ color }} className={styles.title}>
                    {title}
                  </div>
                  <div>{des}</div>
                </div>
              );
            })}
          </div>
        </div>
        <BottomPageNum num={11} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default MentalEnduranceTwo;
