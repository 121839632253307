import React, { useEffect, useState } from 'react';
import { connect, useIntl, history } from 'umi';
import DocumentTitle from 'react-document-title';
import { Card, Input, message, Space, Modal, Radio, Form } from 'antd';
import moment from 'moment';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';

import styles from './index.less';

const { Search } = Input;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const listSupport = (intl) => {
  return {
    childName: {
      showText: intl.formatMessage({ id: 'subjectName' }),
      showType: 'Text',
    },
    Guardian: {
      showText: intl.formatMessage({ id: 'guardianName' }),
      showType: 'Text',
    },
    PhoneNumber: {
      showText: intl.formatMessage({ id: 'phone' }),
      showType: 'Text',
    },
    Tracked: {
      showText: intl.formatMessage({ id: 'trackedDays' }),
      showType: 'Text',
    },
    ApplicationTime: {
      showText: intl.formatMessage({ id: 'applicationTime' }),
      showType: 'Time',
    },
    ReviewStatus: {
      showText: intl.formatMessage({ id: 'ReviewStatus' }),
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: [
          {
            text: intl.formatMessage({ id: 'Unreviewed' }),
            value: 'Unreviewed',
          },
          {
            text: intl.formatMessage({ id: 'Reviewed' }),
            value: 'Reviewed',
          },
        ],
      },
    },
    Reviewer: {
      showText: intl.formatMessage({ id: 'Reviewer' }),
      showType: 'Text',
    },
    ReviewTime: {
      showText: intl.formatMessage({ id: 'ReviewTime' }),
      showType: 'Time',
    },
  };
};

const PDTCancellation = ({ currentUser }) => {
  const { adminRoles, current, canChangeList } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;

  const intl = useIntl();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listData, setListData] = useState([
    {
      childName: 'listData',
    },
  ]);
  const [filter, setFilter] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: '全部',
  });

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading(true);

    const params = {
      MaxResultCount: pagination.pageSize,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      Filter: keyword,
      TenantId: currentTenant.id,
    };

    console.log(params);

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [keyword, pagination.pageSize, pagination.current, filter, currentTenant]);

  const reviewAction = () => {
    setIsModalOpen(true);
  };

  const TableProps = {
    loading,
    listData: listData,
    listSupport: listSupport(intl),
    pagination,
    filters: {
      status: filter,
    },
    onTableChange(page, filters) {
      if (filters !== undefined) {
        const { status } = filters;
        setFilter(status);
      }
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      isAdmin
        ? null
        : {
            showText: intl.formatMessage({ id: 'Review' }),
            itemRender(record) {
              return (
                <a onClick={reviewAction}>
                  {intl.formatMessage({ id: 'Review' })}
                </a>
              );
            },
          },
    ],
  };

  return (
    <DocumentTitle title="PDTCancellation">
      <Card
        title={<b style={{ fontSize: '20px' }}>PDTCancellation</b>}
        extra={
          isAdmin && (
            <AnalysisTenantSelect
              showAll={true}
              onlyShowCenter={true}
              currentTenant={currentTenant}
              onChange={(e) => {
                if (e) {
                  const newCurrentTenant = canChangeList.find(
                    (item) => item.id == e,
                  );
                  setCurrentTenant(newCurrentTenant);
                } else {
                  setCurrentTenant({
                    id: '',
                    displayName: '全部',
                  });
                }
              }}
            />
          )
        }
        bordered={false}
      >
        <Space>
          <div>{intl.formatMessage({ id: 'keyword' })}: </div>
          <Search
            placeholder={intl.formatMessage({ id: 'subjectName' })}
            onSearch={(value) => {
              setKeyword(value);
            }}
            style={{ height: 32, width: 220 }}
          />
        </Space>
        <p />
        <MagicTable {...TableProps} />
        <Modal
          title="Review the application for FocusEDTx cancellation"
          open={isModalOpen}
          onOk={() => {
            form.submit();
          }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          width={700}
        >
          <div className={styles.formPanel}>
            <div className={styles.formTitle}>
              您对XXX的FocusEDTx注销申请的审核结果是：
            </div>
            <Form
              name="reviewForm"
              disabled={true}
              form={form}
              {...formItemLayout}
              onFinish={(value) => {
                console.log(value);
                setIsModalOpen(false);
              }}
              initialValues={{
                'input-number': 3,
                'checkbox-group': ['A', 'B'],
              }}
            >
              <Form.Item
                name="radio-group"
                rules={[{ required: true, message: 'Please pick an item!' }]}
              >
                <Radio.Group>
                  <Radio.Button value="yes">拒绝注销</Radio.Button>
                  <Radio.Button value="no">允许注销</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="intro">
                <Input.TextArea
                  placeholder="理由及备注"
                  showCount
                  maxLength={100}
                  style={{ height: 120 }}
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </Card>
    </DocumentTitle>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  PDTCancellation,
);
