import React, { useState, useEffect } from 'react';
import { Card, message, Select } from 'antd';

import { useIntl, history, connect } from 'umi';
import ExpandedTable from './ExpandedTable';
import DocumentTitle from 'react-document-title';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

import {
  GetTemplateOfCenters,
  GetTemplateOfWeeks,
  GetTemplate,
} from 'services/adhd';

const parentListSupport = (intl) => {
  return {
    sortOrder: {
      showText: intl.formatMessage({ id: 'Week Order' }),
      showType: 'Text',
      render: (text) => {
        return `${intl.formatMessage(
          { id: 'core-week' },
          { week: parseInt(text) + 1 },
        )} `;
      },
    },
    title: {
      showText: intl.formatMessage({ id: 'Week Topic' }),
      showType: 'Text',
    },
  };
};

const CoreSessionLibrary = ({ currentUser }) => {
  const intl = useIntl();

  const { adminRoles, current } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;

  const { roles = [] } = current || {};
  const isEnterpriseAdmin = roles && roles.some(item => item.name === 'EnterpriseAdmin')
  const isAreaAdmin = roles && roles.some(item => item.name === 'AreaAdmin')
  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectTemplate, setSelectTemplate] = useState({});
  const [listData, setListData] = useState([]);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const getWeekList = async (sessionData) => {
    const { id } = sessionData || {};

    setSelectTemplate(sessionData);

    const weekRes = await GetTemplateOfWeeks({
      TemplateId: id,
      SkipCount: 0,
      MaxResultCount: 20,
    });

    if (weekRes.response.ok) {
      const { items = [] } = weekRes.data || {};
      setListData(items);
    } else {
      const { error } = weekRes.data;
      message.error(error.message);
    }
  };

  const initData = async () => {
    setLoading(true);

    let res;
    const params = {
      SkipCount: 0,
      MaxResultCount: 999,
    };

    if (isAdmin) {
      res = await GetTemplate(params);
    } else {
      res = await GetTemplateOfCenters(params);
    }

    if (res.response.ok) {
      const { items = [] } = res.data || {};

      const coreSessionData = items.filter(
        (item) => item.type === 'CoreSession',
      );

      if (coreSessionData.length > 0) {
        await getWeekList(coreSessionData[0]);
        setSelectOptions(coreSessionData);
      }
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const tableProps = {
    listData,
    listSupport: parentListSupport(intl),
    loading,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: (record) => (
          <ExpandedTable
            allData={record}
            selectTemplate={selectTemplate}
            isAdmin={isAdmin}
            isEnterpriseAdmin={isEnterpriseAdmin}
            isAreaAdmin={isAreaAdmin}
          />
        ),
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
  };

  const onSelectChange = async (value) => {
    setLoading(true);

    const findData = selectOptions.find((item) => item.id === value) || {};
    await getWeekList(findData);

    setLoading(false);
  };

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'Core session Library' })}>
      <Card
        title={
          <b style={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'Core session Library' })}
          </b>
        }
        bordered={false}
        extra={
          selectOptions.length > 1 && (
            <Select
              showSearch
              value={selectTemplate?.id}
              style={{ width: 200 }}
              onChange={onSelectChange}
              options={selectOptions.map((item) => {
                const { displayName, id } = item || {};
                return {
                  value: id,
                  label: displayName,
                };
              })}
            />
          )
        }
      >
        <MagicTable {...tableProps} />
      </Card>
    </DocumentTitle>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  CoreSessionLibrary,
);
