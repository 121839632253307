import React, { useEffect } from 'react'
import { connect } from 'umi'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { Button, Modal, Input } from 'antd'

const confirm = Modal.confirm
const { Search } = Input

const CourseItem = ({ dispatch, courseItem }) => {

  useEffect(() => {
    dispatch({ type: 'courseItem/query' })
  }, [])

  const {
    list,
    listSupport,
    pagination,
    modalType,
    content,
    modalVisible,
    searchInput,
    updateVidVisible,
    updateVidContent,
  } = courseItem

  const validator = value => {
    return true
  }

  const modalTypeToTitle = type => {
    switch (type) {
      case 'add':
        return '新建课程单元'
      case 'edit':
        return '编辑课程单元'
      default:
        return ''
    }
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'courseItem/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      const { resourceList } = value
      const postData = resourceList.length > 0 ? { ...value, resource: '' } : value
      dispatch({ type: 'courseItem/onSubmitModal', payload: postData })
    },
    content,
  }

  const updateVidProps = {
    title: '更新VID',
    visible: updateVidVisible,
    onCancel() {
      dispatch({ type: 'courseItem/updateState', payload: { updateVidVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'courseItem/onsubmitVid', payload: value })
    },
    content: updateVidContent
  }

  const tableProps = {
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'courseItem/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '更新vid',
        itemRender(record) {
          const { contentType } = record || {}
          return (
            contentType === "Video" ?
              <a onClick={()=>dispatch({type: 'courseItem/updateVid', payload: record})}>更新vid</a>
              :
              null
          )
        }
      },
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'courseItem/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'courseItem/onDelete', payload: record.id })
            },
          })
        },
      }
    ],
  }

  const onAddBtnClick = () => {
    dispatch({ type: 'courseItem/onAdd' })
  }

  return (
    <div>
      <h1>课程单元（小节）</h1>
      <Button onClick={onAddBtnClick}>新建课程单元</Button>
      <Search
        placeholder="小节名称"
        onSearch={value =>
          dispatch({ type: 'courseItem/changeTable', payload: { searchInput: value } })
        }
        onChange={e =>
          dispatch({ type: 'courseItem/updateState', payload: { searchInput: e.target.value } })
        }
        style={{ width: 200 }}
        value={searchInput}
      />
      <Button
        onClick={() => dispatch({ type: 'courseItem/changeTable', payload: { searchInput: '' } })}
      >
        Clear
      </Button>
      <br />
      <br />
      <MagicFormModal {...modalProps} />
      <MagicFormModal {...updateVidProps} />
      <MagicTable {...tableProps} />
    </div>
  )
}
export default connect(({ courseItem }) => ({ courseItem }))(CourseItem)
