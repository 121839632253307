import { message } from 'antd'
import { PostChangePassword } from 'services/account'

export default {
    namespace: 'changePassword',
    state: {

    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *changePassword({ payload }, { call, put, select }) {
            const res = yield call(PostChangePassword, payload)
            if (res.response.ok) {
                message.success('密码修改成功')
                yield put({ type: 'user/logout' })
            } else {
                const { error } = res.data
                const { message: info } = error || {}
                info && message.error(info)
            }
        }

    },
    subscriptions: {
        // setupHistory({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/manage/changePassword') {

        //         }
        //     })
        // },
    },
}
