import { useState, useEffect } from 'react';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';
import { message } from 'antd';
import { matchDynamicForm, addOptionsToProperties } from 'cognitiveleap-core-us/utils/utils';
import config from 'utils/config';

import {
  PostDailyTopic,
  GetDailyTopicDetail,
  GetTagList,
  PutDailyTopic,
} from 'services/adhd';

const {
  baseURL,
  uploadDTHeaderImage,
  uploadDTCoverImage,
  uploadDTPdf,
  uploadDTResource,
} = config;

const modalTypeToTitle = {
  edit: 'Edit Daily Topic',
  add: 'Add Daily Topic',
};

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: 'title',
      FormKey: 'title',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: 'Up to 100 characters (including Chinese and English)',
    },
    {
      EditorType: 'Dropdown',
      Value: 'Video',
      Setting: {
        DropdownOptions: [
          {
            Value: 'Video',
            Id: 'Video',
          },
          {
            Value: 'Activity',
            Id: 'Activity',
          },
          {
            Value: 'Text',
            Id: 'Text',
          },
        ],
        Required: true,
      },
      ShowTitle: 'contentType',
      FormKey: 'contentType',
      Description: null,
    },
    {
      EditorType: 'Bool',
      Value: false,
      Setting: {
        Required: true,
      },
      ShowTitle: 'isMark',
      FormKey: 'isMark',
      Description: 'Mark completed',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'url',
      FormKey: 'url',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: 'Activity link',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
          uploadType: 'video',
        },
        Action: baseURL + uploadDTResource,
        Header: getAuthHeader(),
        Required: false,
      },
      ShowTitle: 'resource',
      FormKey: 'resource',
      Description: 'Video',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
          uploadType: 'pdf',
        },
        Action: baseURL + uploadDTPdf,
        Header: getAuthHeader(),
        Required: false,
      },
      ShowTitle: 'PDF',
      FormKey: 'pdf',
      Description: '',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
          uploadType: 'image',
        },
        Action: baseURL + uploadDTCoverImage,
        Header: getAuthHeader(),
        Required: false,
      },
      ShowTitle: 'coverImage',
      FormKey: 'coverImage',
      Description: 'Cover image for the list page.',
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
          uploadType: 'image',
        },
        Action: baseURL + uploadDTHeaderImage,
        Header: getAuthHeader(),
        Required: false,
      },
      ShowTitle: 'headerImage',
      FormKey: 'headerImage',
      Description: 'The header image/video cover image of the detail page.',
    },
    {
      EditorType: 'TimePicker',
      ShowTitle: 'duration',
      FormKey: 'duration',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'ContentSelector',
      ShowTitle: 'keyWords',
      FormKey: 'adhdTagIds',
      AdditionalData: null,
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: 'description',
      FormKey: 'description',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: 'practiceSuggestion',
      FormKey: 'practiceSuggestion',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
  ],
};

// 数据格式转换
const listToOptions = (list, showAttributeArr) => {
  return list.map((item) => {
    const Value = showAttributeArr.map((attribute) => `${item[attribute]} `);
    return { Value, Id: item.id.toString() };
  });
};

const DailyTopicModal = ({
  modalVisible,
  modalType,
  currentItem,
  onClose,
  reloadTable,
}) => {
  const isEdit = modalType === 'edit' && Object.keys(currentItem).length > 0;
  const { id } = currentItem || {};

  const [currentContent, setCurrentContent] = useState(contentMenu);
  const [loading, setLoading] = useState({
    initLoading: false,
    modalLoading: false,
  });

  const initData = async () => {
    let emptyContent = contentMenu;

    const resList = await Promise.all([
      GetTagList({
        SkipCount: 0,
        MaxResultCount: 999,
      }),
      isEdit &&
        GetDailyTopicDetail({
          id,
        }),
    ]);

    const tagRes = resList[0];

    if (tagRes.response.ok) {
      const { items } = tagRes.data || {};
      const tagOptions = listToOptions(items, ['displayName']);

      emptyContent = addOptionsToProperties(
        emptyContent,
        ['adhdTagIds'],
        tagOptions,
      );
    } else {
      const { error } = tagRes.data;
      message.error(error.message);
    }

    let showForm = emptyContent;

    if (isEdit) {
      const editRes = resList[1];

      if (editRes.response.ok) {
        const { adhdTags } = editRes?.data || {};

        showForm = matchDynamicForm(emptyContent, {
          ...editRes?.data,
          adhdTagIds: adhdTags.map((item) => item?.id),
        });
      } else {
        const { error } = editRes.data;
        message.error(error.message);
      }
    }

    setCurrentContent(showForm);
    setLoading({
      ...loading,
      initLoading: true,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const modalProps = {
    loading: loading.modalLoading,
    title: modalTypeToTitle[modalType],
    visible: modalVisible,
    onCancel() {
      onClose();
    },
    onOk: async (value) => {
      setLoading({
        ...loading,
        modalLoading: true,
      });

      const res = isEdit
        ? await PutDailyTopic({
            id,
            ...value,
          })
        : await PostDailyTopic(value);

      if (res.response.ok) {
        onClose();
        reloadTable();
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      setLoading({
        ...loading,
        modalLoading: false,
      });
    },
    content: currentContent,
  };

  return loading.initLoading && <MagicFormModal {...modalProps} />;
};

export default DailyTopicModal;
