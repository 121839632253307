import {
    PostPrepareData,
    GetPrepareList,
    PutPrepareData,
    DeletePrepareData,
    PrepareForEdit,
} from 'services/rocketService/Prepare'

import storageKeys from 'utils/storageKeys'
import localForage from 'localforage'

// 根据contentId获取contentId
function getContentId(id, course) {
    let result = null
    const stepIndex = course.stepIndex
    stepIndex.forEach(item => {
        item.contentId.forEach(contentItem => {
            if (contentItem === id) result = item.contentId
        })
    })
    return result || ''
}


//通过后端return以及cache获取tree的selectkey
function getSelectKey(courseList, courseId, contentId) {
    let newContentId = ''
    //如果courseId为null或者list找不到courseId的话返回第一个有contents的课
    if (courseId === null || !courseList.some(item => item.id === courseId)) {
        const selectCourse = courseList.find(item => item.contents.length > 0)
        newContentId = selectCourse.contents[0].id
        return selectCourse && JSON.stringify({ courseId: selectCourse.id, contentId: newContentId, fatherKey: getContentId(newContentId, selectCourse) })
    }
    else {
        const selectCourse = courseList.find(item => item.id === courseId)
        if (contentId === null || !selectCourse.contents.some(item => item.id === contentId)) {
            if (selectCourse.contents.length > 0) {
                newContentId = selectCourse.contents[0].id
                return JSON.stringify({ courseId: selectCourse.id, contentId: newContentId, fatherKey: getContentId(newContentId, selectCourse) })
            }
            else {
                const firstHasContentCourse = courseList.find(item => item.contents.length > 0)
                newContentId = firstHasContentCourse.contents[0].id
                return firstHasContentCourse && JSON.stringify({ courseId: firstHasContentCourse.id, contentId: newContentId, fatherKey: getContentId(newContentId, firstHasContentCourse) })
            }
        }
        else {
            const selectContent = selectCourse.contents.find(item => item.id === contentId)
            newContentId = selectContent.id
            return JSON.stringify({ courseId: selectCourse.id, contentId: newContentId, fatherKey: getContentId(newContentId, selectCourse) })
        }
    }
}

export default {
    namespace: 'trainingMaterial',
    state: {
        courseList: [],
        selectedKeys: [],
        expandedKeys: [],
        currentPdfInfo: null,
        searchValue: '',
        autoExpandParent: false,
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *pageLoading(_, { call, put, select }) {
            yield put({ type: 'updateState', payload: { courseList: [], selectedKeys: [] } })
            const res = yield call(GetPrepareList, {
                Type: 'TrainingMaterials',
                MaxResultCount: 1000,
                IncludeContent: true
            }, 'course')
            if (res.response.ok) {
                const { items, totalCount } = res.data
                const cache = yield localForage.getItem(storageKeys.trainingMaterialCache,)
                const { courseId = null, contentId = null, fatherKey = null } = cache || {}
                const selectedKeys = getSelectKey(items, courseId, contentId) != null ? [getSelectKey(items, courseId, contentId)] : []
                const expandedKeys = selectedKeys && selectedKeys.length > 0 ?
                    [JSON.stringify({ courseId: JSON.parse(selectedKeys[0]).courseId }), JSON.stringify({ contentId: JSON.parse(selectedKeys[0]).fatherKey })]
                    :
                    []
                yield put({
                    type: 'updateState',
                    payload: {
                        courseList: items,
                        expandedKeys: expandedKeys
                        //transCourseList
                    },
                })
                yield put({
                    type: 'onselectKeys',
                    payload: {
                        // 默认选择当前课程的第一个小节
                        selectedKeys: selectedKeys,
                    }
                })
            }
        },

        *onselectKeys({ payload }, { call, put, select }) {
            const { courseList } = yield select(state => state.trainingMaterial)
            const { selectedKeys } = payload
            const { courseId = null, contentId = null } = selectedKeys.length > 0 ? JSON.parse(selectedKeys[0]) : {}
            const currentCourseContent = courseId && courseList.find(item => item.id === courseId)
            const currentContent = contentId && currentCourseContent.contents.find(item => item.id === contentId)
            if (currentContent && (currentContent.contentType === "Doc")) {
                const { resourceList } = currentContent || {}
                yield put({
                    type: 'updateState',
                    payload: {
                        currentPdfInfo: resourceList && resourceList[0],
                        selectedKeys
                    }
                })
            } else {
                yield put({ type: 'updateState', payload: { selectedKeys } })
            }
        },

        *getCurrentPdfInfo({ payload }, { take, put, call, select }) {
            const { resourceList, id } = payload
            if (!id) {
                yield put({ type: 'updateState', payload: { currentPdfInfo: resourceList && resourceList.length > 0 ? resourceList[0] : null } })
            } else {
                const item = resourceList.find(item => item.id === id)
                yield put({ type: 'updateState', payload: { currentPdfInfo: item } })
            }
        },

        *clear({ payload }, { take, put, call, select }) {
            yield put({ type: 'updateState', payload: { courseList: [], selectedKeys: [], autoExpandParent: false, searchValue: '', expandedKeys: [] } })
        },
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(location => {
        //         if (location.pathname === '/system/center/detail/trainingMaterial') {
        //             dispatch({ type: 'pageLoading', payload: location.query })
        //         }
        //     })
        // },
    },
}
