import React, { useEffect } from 'react'
import { connect, history, useIntl, getLocale } from 'umi'
import { Spin, Form, Input, Button, Radio, Message, DatePicker, Space, message, Select } from 'antd'
import { getMobilePrefix } from 'utils/utils'
import { SHOWTYPE } from './models/activate'
import styles from './index.less'
import ActivateSuccess from './ActivateSuccess'
import CountDown from 'cognitiveleap-core-us/components/CountDown'
import { isEffectualPhoneNumber} from 'cognitiveleap-core-us/utils/utils'
import moment from 'moment'
const formRef = React.createRef()
const registerRef = React.createRef()

const { Option } = Select;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
}

const Activate = ({
  activate,
  loading,
  dispatch,
  location
}) => {
  const intl = useIntl()
  const isZh = getLocale() === 'zh-CN'

  const mobilePrefix = getMobilePrefix(intl)

  useEffect(() => {
    dispatch({ type: 'activate/check', payload: location.query })
  }, [])

  const { showType, isNext, canSendCode, isRegisterNext, btnDisable } = activate

  const onSubmit = values => {
    if (values.password !== values.confirmPassword) {
      message.error(intl.formatMessage({ id: 'passwordMessage' }))
    } else {
      // values.birthday = values.birthday && values.birthday.format()
      dispatch({ type: 'activate/activateUser', payload: { values, intl } })
    }
  }

  const showTypeProps = {
    onComplete() {
      history.replace('/')
    },
    showType,
    intl
  }

  // const goNext = () => {
  //   dispatch({ type: 'activate/updateState', payload: { isNext: true } })
  // }

  // const goPre = () => {
  //   dispatch({ type: 'activate/updateState', payload: { isNext: false } })
  // }

  const sendCode = async () => {
    const values = await formRef.current.getFieldValue();
    const { phoneNumber, countryCode } = values || {}
    if (!phoneNumber || !countryCode) {
      message.error(intl.formatMessage({ id: 'areaCodeMessage' }))
      return
    }
    dispatch({ type: 'activate/sendCode', payload: { countryCode, phoneNumber, intl } })
  }

  const countDownBtnProps = {
    onComplete() {
      dispatch({ type: 'activate/updateState', payload: { canSendCode: true } })
    },
  }

  const onRegisterSubmit = values => {
    if (values.password !== values.confirmPassword) {
      message.error(intl.formatMessage({ id: 'passwordMessage' }))
    } else {
      dispatch({ type: 'activate/registerUser', payload: values })
    }
  }

  const goRegisterNext = () => {
    dispatch({ type: 'activate/updateState', payload: { isRegisterNext: true } })
  }

  const goRegisterPre = () => {
    dispatch({ type: 'activate/updateState', payload: { isRegisterNext: false } })
  }
  //  切换区域radio触发回调只验证手机号~
  const handleChange = () => {
    formRef.current.validateFields(['phoneNumber'])
  }

  const prefixSelector = (
    <Form.Item 
      name='countryCode'
      rules={[{ required: true, message: `${intl.formatMessage({ id: 'countryCode' })} ${intl.formatMessage({ id: 'isRequired' })}` }]} 
      noStyle>
      <Select
        style={{
          width: 170,
        }}
      >
        {
          mobilePrefix.map(item => {
            const { prefix, countryName } = item
            return <Option value={prefix}>{countryName}&nbsp;{`(${prefix})`}</Option>
          })
        }
      </Select>
    </Form.Item>
  );

  const showPage = type => {
    switch (type) {
      case SHOWTYPE.Idle:
        return (
          <div style={{ paddingTop: 100, textAlign: 'center' }}>
            <Spin />
          </div>
        )
      case SHOWTYPE.NewUser:
        return (
          <div className={styles.invitationStaff}>
            <Form
              onFinish={onSubmit}
              {...layout}
              ref={formRef}
              initialValues={{
                countryCode: isZh ? "+86" : '+1',
                phoneNumber: '',
                code: '',
                password: '',
                confirmPassword: ''
              }}
            >
              {/* <div className={isNext ? styles.hideForm : styles.showEditPersonInfo}> */}
                <div className={styles.editTitle}>{intl.formatMessage({ id: 'titleTwo' })}</div>
                <Form.Item
                  label={intl.formatMessage({ id: 'name' })}
                  name='name'
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'name' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                >
                  <Input className={styles.input} />
                </Form.Item>
                {/* <Form.Item
                  label={intl.formatMessage({ id: 'gender' })}
                  name='gender'
                >
                  <Radio.Group>
                    <Radio value="Male">{intl.formatMessage({ id: 'male' })}</Radio>
                    <Radio value="Female">{intl.formatMessage({ id: 'female' })}</Radio>
                    <Radio value="Others">{intl.formatMessage({ id: 'other' })}</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'birthday' })}
                  name='birthday'
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'birthday' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                >
                  <DatePicker
                    placeholder="mm/dd/yyyy"
                    format={'MM/DD/YYYY'}
                    disabledDate={(current) => {
                      return current && current > moment()
                    }}
                  />
                </Form.Item>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                    type="primary"
                    className={styles.btn}
                    onClick={goNext}
                  >
                    {intl.formatMessage({ id: 'next' })}
                  </Button>
                </div> */}
              {/* </div> */}
              {/* <div className={isNext ? styles.showEditPassword : styles.hideForm}> */}
                {/* <div className={styles.editTitle}>{intl.formatMessage({ id: 'titleTwo' })}</div>
                <Form.Item
                  label={intl.formatMessage({ id: 'countryCode' })}
                  name='countryCode'
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'countryCode' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                >
                  <Radio.Group onChange={handleChange}>
                    {
                      mobilePrefix.map(item => {
                        const { prefix, countryName } = item
                        return <Radio value={prefix}>{countryName}</Radio>
                      })
                    }
                  </Radio.Group>
                </Form.Item> */}
                <Form.Item
                  label={intl.formatMessage({ id: 'phone' })}
                  required
                >
                  <Form.Item
                    name="phoneNumber"
                    rules={[{ required: true, message: `${intl.formatMessage({ id: 'phone' })} ${intl.formatMessage({ id: 'isRequired' })}` },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          // 拿到当前的区号
                          const countryCode = getFieldValue('countryCode');
                          // 判断手机号格式是否正确 更新btnDisabled状态 并在表单给出提示
                          if (isEffectualPhoneNumber(countryCode, value)) {
                            dispatch({
                              type: 'activate/updateState',
                              payload: {
                                btnDisable: false,
                              },
                            });
                            return Promise.resolve();
                          } else {
                            dispatch({
                              type: 'activate/updateState',
                              payload: {
                                btnDisable: true,
                              },
                            });
                            return Promise.reject(new Error(intl.formatMessage({id: 'telMessage'})));
                          }
                        },
                      }),
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input addonBefore={prefixSelector} className={styles.input} />
                  </Form.Item>
                </Form.Item>
                {/* 验证码 */}
                <Form.Item
                  label={intl.formatMessage({ id: 'smsCode' })}
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'smsCode' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                  name='code'
                  key="code"
                >
                  <Space>
                    <Input className={styles.input} />
                    <div className={styles.minScreen}>
                      {canSendCode ?
                        <Button onClick={() => sendCode()} disabled={btnDisable}>{intl.formatMessage({ id: 'sendCode' })}</Button>
                        :
                        <CountDown {...countDownBtnProps} />
                      }
                    </div>
                  </Space>
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'password' })}
                  name='password'
                  key="password"
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'password' })} ${intl.formatMessage({ id: 'isRequired' })}` }, {
                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,12}$/,
                    message: intl.formatMessage({ id: 'passwordRule' })
                  }]}
                >
                  <Input.Password className={styles.input} autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'confirmPassword' })}
                  name='confirmPassword'
                  key="confirmPassword"
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'confirmPassword' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                >
                  <Input.Password className={styles.input} autoComplete="new-password" />
                </Form.Item>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {/* <Button
                    className={styles.btn}
                    onClick={goPre}
                    style={{ marginRight: '10px' }}
                  >
                    {intl.formatMessage({ id: 'previous' })}
                  </Button> */}
                  <Button
                    type="primary"
                    className={styles.btn}
                    htmlType="submit"
                  >
                    {intl.formatMessage({ id: 'confirm' })}
                  </Button>
                </div>
              {/* </div> */}
            </Form>
          </div>
        )
      case SHOWTYPE.NeedRegister:
        return (
          <div className={styles.invitationStaff}>
            <Form
              onFinish={onRegisterSubmit}
              {...layout}
              ref={registerRef}
              initialValues={{
                name: '',
                phoneNumber: '',
                password: '',
                confirmPassword: ''
              }}
            >
              <div className={isRegisterNext ? styles.hideForm : styles.showEditPersonInfo}>
                <div className={styles.editTitle}>{intl.formatMessage({ id: 'confirmPersonalInformation' })}</div>
                <Form.Item
                  label={intl.formatMessage({ id: 'name' })}
                  name='name'
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'name' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                >
                  <Input className={styles.input} />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'phone' })}
                  required
                >
                  <Form.Item
                    name="phoneNumber"
                    rules={[{ required: true, message: `${intl.formatMessage({ id: 'phone' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input className={styles.input} />
                  </Form.Item>
                </Form.Item>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                    type="primary"
                    className={styles.btn}
                    onClick={goRegisterNext}
                  >
                    {intl.formatMessage({ id: 'next' })}
                  </Button>
                </div>
              </div>
              <div className={isRegisterNext ? styles.showEditPassword : styles.hideForm}>
                <div className={styles.editTitle}>{intl.formatMessage({ id: 'setPassword' })}</div>
                <Form.Item
                  label={intl.formatMessage({ id: 'password' })}
                  name='password'
                  key="password"
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'password' })} ${intl.formatMessage({ id: 'isRequired' })}` }, {
                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,12}$/,
                    message: intl.formatMessage({ id: 'passwordRule' })
                  }]}
                >
                  <Input.Password className={styles.input} autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'confirmPassword' })}
                  name='confirmPassword'
                  key="confirmPassword"
                  rules={[{ required: true, message: `${intl.formatMessage({ id: 'confirmPassword' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
                >
                  <Input.Password className={styles.input} autoComplete="new-password" />
                </Form.Item>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                    className={styles.btn}
                    onClick={goRegisterPre}
                    style={{ marginRight: '10px' }}
                  >
                    {intl.formatMessage({ id: 'previous' })}
                  </Button>
                  <Button
                    type="primary"
                    className={styles.btn}
                    htmlType="submit"
                  >
                    {intl.formatMessage({ id: 'submit' })}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )
      default:
        return <ActivateSuccess {...showTypeProps} />
    }
  }

  return <div>{showPage(showType)}</div>
}

export default connect(({ activate }) => ({ activate }))(Activate)
