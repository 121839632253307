import React, { useEffect } from 'react'
import { connect, useIntl, history } from 'umi'
import { genderLocale } from 'utils/utils'
import { Modal, Divider, Button, Menu, Dropdown, Select, Space, Input } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import DocumentTitle from 'react-document-title'
import styles from './index.less'
import { matchDynamicForm, } from 'cognitiveleap-core-us/utils/utils'
import MyModal from "components/MyModal"

const { confirm } = Modal
const phoneRegex = /^\d{10,}$/;
const emailRegex = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
const Option = Select.Option

const { Search } = Input;

const title = (intl) => {
  return {
    add: intl.formatMessage({ id: 'addUser' }),
    edit: intl.formatMessage({ id: 'editUser' }),
    changeRole: intl.formatMessage({ id: 'switchRoles' })
  }
}

const Group = ({ group, currentUser, dispatch, loading }) => {

  const intl = useIntl()

  useEffect(() => {
    dispatch({ type: 'group/query' })
  }, [])

  const {
    selectItem,
    contentMenu,
    modalVisible,
    list,
    pagination,
    qualificationList,
    type,
    choiceModalVisible,
    choiceRole,
    roleNameOptions,
    choiceStaff,
    choiceStaffId,
    isChangeAdmin,
    activeStatus,
    filters,
    filter
  } = group
  const { group: isLoading } = loading.models

  const { auth, } = currentUser
  const { grantedPolicies } = auth

  const hasUserWritePermission = grantedPolicies['Business.TenantUserManagement.Update'] === true

  const listSupport = {
    name: {
      showText: intl.formatMessage({ id: 'name' }),
      showType: 'Text',
      render(text, record, index) {
        const { modelKeys, name } = record || {}
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{name}</span>
            {/* {
              modelKeys.map(item => {
                const qualificationItem = qualificationList.find(qualificationItem => qualificationItem.name === item)
                return (
                  <img src={qualificationItem && qualificationItem.issuedStyle && qualificationItem.issuedStyle.url} style={{ width: '70px', marginLeft: '10px' }} />
                )
              })
            } */}
          </div>
        )
      }
    },
    gender: {
      showText: intl.formatMessage({ id: 'gender' }),
      showType: 'Text',
      render(text, record, index) {
        return genderLocale(text, intl)
      }
    },
    userRoles: {
      showText: intl.formatMessage({ id: 'role' }),
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: roleNameOptions.map(item => {
          const { Id, Value } = item || {};
          return {
            text: Value,
            value: Id
          }
        }),
      },
    },
    email: {
      showText: intl.formatMessage({ id: 'email' }),
      showType: 'Text',
    },
    remark: {
      showText: intl.formatMessage({ id: 'comments' }),
      showType: 'Remark',
    },
    isActive: {
      showText: intl.formatMessage({ id: 'isActive' }),
      showType: 'Bool',
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
    }
  }

  const onAddClick = () => {
    dispatch({ type: 'group/onAddOrEdit', payload: { type: 'add', intl } })
  }

  const modalProps = {
    loading: loading.models.group,
    title: title(intl)[type],
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'group/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {

      // if (!emailRegex.test(value.email) && type !== 'changeRole') {
      //   message.error('请输入正确的邮箱！');
      //   return;
      // }

      // if (!phoneRegex.test(value.phoneNumber)) {
      //   message.error('请输入正确的手机号！');
      //   return;
      // }
      dispatch({ type: 'group/onSubmitModal', payload: { data: value, intl } })
    },
    content: selectItem === null || isChangeAdmin ? contentMenu : matchDynamicForm(contentMenu, selectItem.user),
  }

  const choiceModalProps = {
    title: intl.formatMessage({ id: 'selectEmployees' }),
    visible: choiceModalVisible,
    onCancel() {
      const content = { ...contentMenu }
      content.Properties[0].Value = choiceRole
      dispatch({ type: 'group/updateState', payload: { choiceModalVisible: false, contentMenu: content, modalVisible: true, isChangeAdmin: true } })
    },
    onOk(value) {
      dispatch({ type: 'group/changeAdminRole' })
    },
  }

  const showActive = (isActive, isSend) => {
    if (isActive && isSend) return null
    if (isSend) return intl.formatMessage({ id: 'resend' })
    else {
      return intl.formatMessage({ id: 'sendInvitationEmail' })
    }
  }

  const TableProps = {
    loading: loading.models.group,
    listData: list.map(item => {
      const { roles, state } = item
      const roleNames = roles.map(roleItem => roleItem.displayName)
      return {
        ...item,
        userRoles: roleNames.join(' '),
        status: state === 'Effective' ? intl.formatMessage({ id: 'effective' }) : intl.formatMessage({ id: 'invalid' })
      }
    }),
    listSupport,
    pagination,
    filters,
    onTableChange(pagi, filt = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'group/changeTable', payload: data })
    },
    Actions: [
      hasUserWritePermission ? {
        showText: intl.formatMessage({ id: 'switchRoles' }),
        itemRender(record) {
          return (
            <a
              onClick={() => {
                dispatch({ type: 'group/onAddOrEdit', payload: { record, type: 'changeRole', intl } })
              }}>
              {intl.formatMessage({ id: 'switchRoles' })}
            </a>
          )
        }
      } : null,
      {
        showText: intl.formatMessage({ id: 'detail' }),
        itemRender(record) {
          const { isActive } = record
          return isActive ? (
            <a
              onClick={() =>
                history.push({
                  pathname: '/system/center/detail/groupDetail',
                  query: {
                    id: record.id
                  }
                })}
            >
              {intl.formatMessage({ id: 'detail' })}
            </a>
          )
            :
            null
        }
      },
      hasUserWritePermission ? {
        showText: intl.formatMessage({ id: 'freeze' }),
        itemRender(record) {
          const { state, name } = record
          const showWord = state === 'Effective' ? intl.formatMessage({ id: 'freeze' }) : intl.formatMessage({ id: 'activate' })
          const menu = (
            <Menu>
              <Menu.Item>
                <a
                  onClick={() => {
                    confirm({
                      okText: intl.formatMessage({ id: 'yes' }),
                      cancelText: intl.formatMessage({ id: 'cancel' }),
                      title: `${intl.formatMessage({ id: 'areYouSureTo' })} ${showWord} ${name || '(No Name)'}`,
                      onOk() {
                        dispatch({ type: 'group/onSwitchState', payload: record })
                      },
                    });
                  }}
                >
                  {showWord}
                </a>
              </Menu.Item>
            </Menu>
          )

          return (
            <span key="freeze">
              <Dropdown overlay={menu}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  {intl.formatMessage({ id: 'switchStatus' })}
                </a>
              </Dropdown>
              {/* 
              <a
                onClick={() => {
                  confirm({
                    okText: intl.formatMessage({ id: 'ok' }),
                    cancelText: intl.formatMessage({ id: 'cancel' }),
                    title:`${ intl.formatMessage({ id: 'areYouSureTo' })} ${showWord} ${name||'(No Name)'}`,
                    onOk() {
                      dispatch({ type: 'group/onSwitchState', payload: record })
                    },
                  });
                }}
              >
                {showWord}
              </a> */}
            </span>
          )
        },
      } : null,
      hasUserWritePermission ? {
        showText: intl.formatMessage({ id: 'edit' }),
        itemRender(record) {
          // 已激活的也能编辑
          const { isActive } = record

          return <span key="edit">
            <a
              onClick={() => {
                dispatch({ type: 'group/onAddOrEdit', payload: { record, type: 'edit', intl, isActive } })
              }}
            >
              {intl.formatMessage({ id: 'edit' })}
            </a>
          </span>
          // return isActive ? null : (
          //   <span key="edit">
          //     <a
          //       onClick={() => {
          //         dispatch({ type: 'group/onAddOrEdit', payload: { record, type: 'edit', intl } })
          //       }}
          //     >
          //       {intl.formatMessage({ id: 'edit' })}
          //     </a>
          //   </span>
          // )
        },
      } : null,
      hasUserWritePermission ? {
        showText: intl.formatMessage({ id: 'sendInvitationEmail' }),
        itemRender(record) {
          const { isSend, isActive } = record
          const showWord = showActive(isActive, isSend)
          return showWord == null ? null : (
            <span key="sendEmail">
              <a
                onClick={() => {
                  dispatch({ type: 'group/onSendEmail', payload: { id: record.id, intl } })
                }}
              >
                {showWord}
              </a>
            </span>
          )
        },
      } : null,
      hasUserWritePermission ? {
        showText: intl.formatMessage({ id: 'delete' }),
        itemRender(record) {
          const { isActive, id } = record
          return isActive ? null : (
            <span key="delete">
              <a
                onClick={() => {
                  dispatch({ type: 'group/onDelete', payload: { id, intl } })
                }}
              >
                {intl.formatMessage({ id: 'delete' })}
              </a>
            </span>
          )
        },
      } : null,
    ],
  }

  const changeValue = async (type, value) => {
    await dispatch({
        type: 'group/updateState',
        payload: {
          pagination: {
            current: 1,
            pageSize: 12,
            total: 0,
          },
        }
    })

    if (value != undefined) {
      await dispatch({
        type: 'group/updateState',
        payload: {
            [type]: value
        }
      })
    }

    switch (type) {
      default:
        dispatch({ type: 'group/getList' })
    }
  }

  const searchProps = {
    allowClear: true,
    placeholder: `${intl.formatMessage({ id: 'search' })}${intl.formatMessage({id: 'name' })}/${intl.formatMessage({ id: 'email' })}/${intl.formatMessage({ id: 'comments' })}`,
    style: { width: 300 },
    value: filter,
    onChange: (e) => {
      dispatch({
        type: 'group/updateState',
          payload: {
            filter: e.target.value
          }
      })
    },
    onSearch(value) {
      changeValue('filter')
    },
  }

  const onClear = () => {
    dispatch({ type: 'group/onClear' })
  }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'teamManagement' })}>
      <React.Fragment>
        <h1 className={styles.pageName}>{intl.formatMessage({ id: 'teamManagement' })}</h1>
        <Divider />
        <div className={styles.table}>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space>
              <div className={styles.searchItem}>
                <div className={styles.searchItemLeft}>{intl.formatMessage({ id: 'keyword' })}</div>
                <Search {...searchProps} />
              </div>
              <div className={styles.searchItem}>
                <div className={styles.searchItemLeft}>{intl.formatMessage({ id: 'status' })}</div>
                <Select
                  value={activeStatus}
                  style={{ width: 200 }}
                  onChange={value => changeValue('activeStatus', value)}
              >
                  <Option value="">{intl.formatMessage({ id: 'all' })}</Option>
                  <Option value="Effective">{intl.formatMessage({ id: 'effective' })}</Option>
                  <Option value="Invalid ">{intl.formatMessage({ id: 'invalid' })}</Option>
              </Select>
              </div>
              <Button onClick={onClear}>
                {intl.formatMessage({ id: 'clear' })}
              </Button>
            </Space>
            {
              hasUserWritePermission ? <Button type="primary" onClick={onAddClick}>
                {intl.formatMessage({ id: 'addNewEmployee' })}
              </Button> : null
            }
          </Space>
          <p />
          <MagicTable {...TableProps} />
        </div>
        <MagicFormModal {...modalProps} />
        <MyModal {...choiceModalProps}>
          <div style={{ fontSize: '16px' }}>
            {intl.formatMessage({ id: 'switchingAccountRole' }, { value: roleNameOptions.find(item => item.Id === choiceRole)?.Value })}
          </div>
          <Select
            style={{ width: '100%', marginTop: '20px' }}
            showSearch
            onChange={val => dispatch({ type: 'group/updateState', payload: { choiceStaffId: val } })}
            filterOption={
              (input, option) => {
                return Array.isArray(option.props.children) ? option.props.children.find(item => item.includes(input.toLowerCase())) : option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            }
          >
            {
              choiceStaff.map((item, index) => {
                const roleName = item.roles && item.roles[0] && item.roles[0].displayName
                return (
                  <Option key={index} value={item.id}>
                    {`${item.name || item.email} - ${roleName}`}
                  </Option>
                )
              })
            }
          </Select>
        </MyModal>
      </React.Fragment>
    </DocumentTitle>
  )
}

export default connect(({ group, user, loading }) => ({ group, currentUser: user.currentUser, loading }))(Group)
