import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑教师',
  add: '新建教师',
}

const Teacher = ({ teacher, dispatch }) => {

  useEffect(() => {
    dispatch({ type: 'teacher/query' })
  }, [])

  const { modalType, content, modalVisible, list, listSupport, pagination } = teacher

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'teacher/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'teacher/onSubmitModal', payload: value })
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'teacher/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'teacher/onEdit', payload: record.id })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'teacher/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'teacher/onAdd' })
  }

  return (
    <div>
      <h1>教师</h1>
      <Button onClick={onAddClick}>新建教师</Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ teacher }) => ({ teacher }))(Teacher)
