import React, { useEffect } from 'react'
import { connect, history, useIntl, getLocale } from 'umi'
import DocumentTitle from 'react-document-title'
import { Row, Col, Button, Modal, List, Divider, message, Progress, Spin } from 'antd'
import PaginationCom from 'components/Pagination/Pagination'
import CourseCard from 'components/CourseCard'
import FeedbackDrawer from 'components/FeedbackDrawer'
import ClassAdjustmentModal from 'components/ClassAdjustmentModal';
import { isEqualPlan } from '../../../../models/music'
import { GetDownloadPdf } from 'services/puppeteer'
import styles from '../detail.less'

const UserOfflinePlanDetail = ({ userOfflinePlanDetail, dispatch, loading, currentUser, musicPlanInfo, musicIsOpen, location }) => {

  const intl = useIntl()
  const { trainPlanName = '', userName = '' } = location.query

  useEffect(() => {
    dispatch({ type: 'userOfflinePlanDetail/pageLoading', payload: location.query })
  }, [])

  const {
    planItems,
    pagination,
    drawerVisible,
    selectCourseInfo,
    selectGuardianCourseInfo,
    classAdjustmentModalVisible,
    addClassType,
    choicedCClass,
    latelyCourse,
    subjectInfo,
    activeKey,
    isCurrentNum
  } = userOfflinePlanDetail

  const { auth, changedTenant, staff } = currentUser
  const { grantedPolicies } = auth

  const hasOfflinePlanWritePermission = grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true
  const hasOfflinePlanReadPermission = grantedPolicies['RocketSystem.UserOfflinePlan'];
  const rocketProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Rocket')['status'] : true;
  const isLoading = loading.models.userOfflinePlanDetail
  const { isBind, isLogout } = subjectInfo || {}

  /// get first
  var currentNum = 0
  var totalNum = 0

  const latelyCourseIsLoading = loading.effects['userOfflinePlanDetail/getLatelyCourse'];

  let userOfflineTrainingPlan
  let latestItem
  if (planItems.length > 0) {
    latestItem = planItems[0]
    userOfflineTrainingPlan = planItems[0].userOfflineTrainingPlan
    currentNum = userOfflineTrainingPlan != null ? userOfflineTrainingPlan.currentNum : 0
    totalNum = userOfflineTrainingPlan != null ? userOfflineTrainingPlan.totalNum : 0
  }

  const items = latestItem?.planItems??[]
  const notebooks = latestItem?.nonePlaybook??[]
  const numPunch = latestItem?.numPunchOutput

  const latestAllDone = items.every(e=>e.hasDone!==null) && notebooks.every(e=>e.hasDone!==null) && (numPunch?.hasDone!==null)

  const paginationComProps = {
    onPageChange: (page, pageSize) => {
      const _pagination = {
        ...pagination,
        current: page,
        pageSize,
      }
      dispatch({ type: 'userOfflinePlanDetail/changeTable', payload: { pagination: _pagination } })
    },
    ...pagination,
  }

  const onGoToCourseDetail = (userPlanId, num, currentNum, planType) => {
    history.push({
      pathname: '/system/center/detail/userOfflineCourseDetail',
      query: {
        userPlanId,
        num,
        currentNum,
        planType,
        userOrClassName: userName,
        trainPlanName: trainPlanName,
      },
    })
  }

  const sureFinishCourse = (userPlanId, num) => {
    dispatch({ type: 'userOfflinePlanDetail/finishCourse', payload: { userPlanId, num } })
    dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' })
  }

  const onFinishCourse = (userPlan, num) => {
    const { id: userPlanId } = userPlan
    Modal.confirm({
      title: intl.formatMessage({ id: 'reminder' }),
      content: intl.formatMessage({ id: 'report.offlinePlanInfo.cancel.title' }),
      okText: intl.formatMessage({ id: 'ok' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      onOk() {
        if (musicIsOpen) {
          Modal.confirm({
            title: intl.formatMessage({ id: 'musicPlayingProceed' }),
            okText: intl.formatMessage({ id: 'leave' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk: () => {
              sureFinishCourse(userPlanId, num)
            },
          });
        } else {
          sureFinishCourse(userPlanId, num)
        }
      },
    })
  }

  const onStartNextCourse = (userPlanId, num) => {
    dispatch({ type: 'userOfflinePlanDetail/startNextCourse', payload: { userPlanId, num, intl } })
  }

  const onClose = () => {
    dispatch({
      type: 'userOfflinePlanDetail/closeDrawer',
    })
  }
  const drawerProps = {
    isBind,
    isLogout,
    activeKey,
    hasOfflinePlanWritePermission,
    hasOfflinePlanReadPermission,
    visible: drawerVisible,
    allData: selectCourseInfo,
    rocketProjectStatus,
    loading: loading.models.userOfflinePlanDetail,
    guardianCourseInfo: selectGuardianCourseInfo,
    isCurrentNum,
    onClose,
    onSubmit(feedbacks, trainingProgramId, planItemId, hasDone) {
      dispatch({
        type: 'userOfflinePlanDetail/submitFeedback',
        payload: {
          feedbacks,
          trainingProgramId,
          planItemId,
          hasDone
        },
      })
    },
    onChangeCollapse(data, type, hasDone) {
      dispatch({
        type: 'userOfflinePlanDetail/onChangeCollapse',
        payload: { activeKey: data, type, hasDone },
      })
    },
    onGuardianFeedback: (submitData, guardianHasDone) => {
      dispatch({
        type: 'userOfflinePlanDetail/submitGuardianFeedback',
        payload: { submitData, guardianHasDone },
      })
    },
    onNotDo(id) {
      dispatch({
        type: 'userOfflinePlanDetail/onNotDoItem',
        payload: id
      })
    },
    onGuardianNotDo({ id, guardianNum, notFinishAudios }) {
      dispatch({
        type: 'userOfflinePlanDetail/onGuardianNotDoItem',
        payload: {
          offlinePlanId: id,
          num: guardianNum,
          notFinishAudios,
          hasDone: false
        },
      })
    },
    onMusicFeedBack: (data) => {
      dispatch({
        type: 'userOfflinePlanDetail/musicFeedback',
        payload: {
          data
        }
      })
    },
    eidtItem(data, type) {
      dispatch({
        type: 'userOfflinePlanDetail/eidtItem',
        payload: {
          data,
          type
        }
      })
    },
  }

  const updateData = (type, value) => {
    dispatch({
      type: 'userOfflinePlanDetail/updateState',
      payload: {
        [type]: value
      }
    })
  }

  const classAdjustmentModalProps = {
    userOfflineTrainingPlan,
    classAdjustmentModalVisible,
    addClassType,
    latelyCourse,
    choicedCClass,
    latelyCourseIsLoading,
    updateData,
    classAdjustmentOnok: (type, value) => {
      if (value.length == 0) {
        return message.warning(intl.formatMessage({ id: 'pleaseSelectLeast' }));
      }
      updateData('classAdjustmentModalVisible', false);
      dispatch({
        type: 'userOfflinePlanDetail/courseAdjusment',
        payload: {
          courses: type == 'addpreoneclass' || type == 'addpretwoclass' ? value : [],
          cCourse: type == 'addcclass' ? value : []
        }
      })
    }
  }

  const openMusicDrawer = (currentMusicPlanInfo) => {
    dispatch({
      type: 'musicplayer/openMusicPlayerDrawer',
      payload: {
        planInfo: currentMusicPlanInfo,
        subjectInfo,
        from: 'userOfflinePlan',
      }
    })
  }

  const onMusicPlayerDrawer = (currentMusicPlanInfo) => {
    const isSamePlan = isEqualPlan(currentMusicPlanInfo, musicPlanInfo)
    if (musicPlanInfo && isSamePlan && musicIsOpen) {
      dispatch({ type: 'musicplayer/updateState', payload: { musicPlayerDrawerVisible: true } })
    } else {
      if (musicPlanInfo == null || !musicIsOpen) {
        openMusicDrawer(currentMusicPlanInfo)
      } else {
        Modal.confirm({
          title: intl.formatMessage({ id: 'musicPlayingAgain' }),
          okText: intl.formatMessage({ id: 'continue' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          onOk: () => {
            openMusicDrawer(currentMusicPlanInfo)
          },
          onCancel() {
          },
        });
      }
    }
  }

  const sureAdjustmentCourse = () => {
    dispatch({
      type: 'userOfflinePlanDetail/getLatelyCourse'
    })
    dispatch({
      type: 'userOfflinePlanDetail/updateState',
      payload: {
        classAdjustmentModalVisible: true,
        addClassType: 'addcclass',
        choicedCClass: [],
      }
    })
    dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' })
  }

  const showAdjustmentCourse = rocketProjectStatus && hasOfflinePlanWritePermission && isBind && !isLogout && planItems && Array.isArray(planItems) && planItems[0].userOfflineTrainingPlan && !planItems[0].userOfflineTrainingPlan.canNext

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'trainingPlanDetails' })}>
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          align="middle"
          gutter={20}
          className={styles.top}
          style={{ marginBottom: 14 }}
        >
          <Col>
            <Button onClick={() => history.goBack()}>{intl.formatMessage({ id: 'back' })}</Button>
          </Col>
          {
            userName !== '' && trainPlanName !== '' &&
            <Col>
              <div className={styles.title}>
                {intl.formatMessage({ id: 'subjectTrainPlan' }, { userName: decodeURI(userName), trainPlanName: decodeURI(trainPlanName) })}
              </div>
            </Col>
          }
        </Row>
        <Spin spinning={isLoading}>
          <div className={styles.mainOld}>
            <div style={{ backgroundColor: 'white', padding: '16px 0' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className={styles.topTitle}>{`${intl.formatMessage({ id: 'progress' })}：${currentNum}/${totalNum}`}</span>
                {
                  showAdjustmentCourse && 
                  !latestAllDone &&
                  <Button
                    type="link"
                    onClick={() => {
                      if (musicIsOpen) {
                        Modal.confirm({
                          title: intl.formatMessage({ id: 'musicPlayingAgain' }),
                          okText: intl.formatMessage({ id: 'continue' }),
                          cancelText: intl.formatMessage({ id: 'cancel' }),
                          onOk: () => {
                            sureAdjustmentCourse()
                          },
                          onCancel() {
                          },
                        });
                      } else {
                        sureAdjustmentCourse()
                      }

                    }}
                  >
                    {intl.formatMessage({ id: 'adjustSessionTime' })}
                  </Button>
                }
              </div>
              <Divider />
              <div className={styles.planDetailWrap}>
                {
                  planItems.map((item, index) => {
                    const { num, userOfflineTrainingPlan, iLs, endTime, displayName } = item
                    const courseCardProps = {
                      isFixed: true,
                      isShowApp: true,
                      allData: item,
                      hasOfflinePlanWritePermission,
                      hasOfflinePlanReadPermission,
                      rocketProjectStatus,
                      isBind,
                      isLogout,
                      onMusicPlayerDrawer,
                      isCurrent: num === userOfflineTrainingPlan.currentNum, /// TODO 可能要自己算
                      canNext: userOfflineTrainingPlan && userOfflineTrainingPlan.canNext,
                      endTime,
                      from: 'userOfflinePlanDetail',
                      onClickCard: (userPlanId, num) => {
                        onGoToCourseDetail(userPlanId, num, userOfflineTrainingPlan.currentNum, iLs && iLs.audioType)
                      },
                      onFinishCourse: onFinishCourse,
                      onStartNextCourse: onStartNextCourse,
                      onFeedback: async (userPlanId, num) => {
                        await dispatch({ type: 'userOfflinePlanDetail/onGuardianFeedback', payload: { userPlanId, num } })
                        await dispatch({ type: 'userOfflinePlanDetail/onFeedback', payload: { userPlanId, num } })
                      },
                      previewHfsRecord: (userPlanId, num) => {
                        const {ilsVersion} = userOfflineTrainingPlan
                        const pathName = ilsVersion === 'V4'? '/hfsTwoTrainingRecord': '/hfsTrainingRecord'
                        const url = pathName+ `?subjectId=${subjectInfo.id}&userPlanId=${userPlanId}&num=${num}&trainerId=${staff && staff.id}&type=preview&culture=${getLocale()}`
                        window.open(url, '_blank')
                      },
                      onDownloadHfsRecord: async (userPlanId, num) => {
                        // 调试打开页面使用
                        // const url = `/hfsTrainingRecord?subjectId=${subjectInfo.id}&userPlanId=${userPlanId}&num=${num}&trainerId=${staff && staff.id}`
                        // window.open(url, '_blank')
                        const hide = message.loading(intl.formatMessage({ id: 'downloading' }), 0)
                        const fileBaseName = subjectInfo.name + '-'
                        const origin = window.location.origin
                        const CognitiveleapUser = localStorage.getItem('CognitiveleapUser')
                        const userTenantId = localStorage.getItem('userTenantId')
                        const tenantId = localStorage.getItem('tenantId');

                        const {ilsVersion} = userOfflineTrainingPlan
                        const pathName = ilsVersion === 'V4'? '/hfsTwoTrainingRecord': '/hfsTrainingRecord'

                        // 需要打印的页面完整url
                        const url = [origin + pathName + `?subjectId=${subjectInfo.id}&userPlanId=${userPlanId}&num=${num}&trainerId=${staff && staff.id}&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${tenantId}&culture=${getLocale()}`]
                        await GetDownloadPdf({ urlList: { ...url }, needPadding: true }, intl.formatMessage({ id: 'downLoadWhichFeedback' }, { fileBaseName, num }))
                        setTimeout(hide, 0)
                      }
                    }
                    return (
                      <div key={index} className={styles.planDetailWrapItem}>
                        <CourseCard {...courseCardProps} />
                      </div>
                    )
                  })
                }
              </div>
              <div style={{ marginLeft: '16px' }}>
                <PaginationCom {...paginationComProps} />
              </div>
              <FeedbackDrawer {...drawerProps} />
              <ClassAdjustmentModal {...classAdjustmentModalProps} />
            </div>
          </div>
        </Spin>
      </React.Fragment>
    </DocumentTitle>
  )
}

export default connect(({ userOfflinePlanDetail, user, loading, musicplayer }) => ({
  userOfflinePlanDetail,
  currentUser: user.currentUser,
  loading,
  musicPlanInfo: musicplayer.planInfo,
  musicIsOpen: musicplayer.isOpen,
}))(UserOfflinePlanDetail)
