import {
  GetTrainerModuleList,
  GetTrainerModuleDetail,
  GetTrainingApplication,
  PutQuestionnaire,
  StartStudy,
  PostGetTrainingApplication,
  GetQualification,
  GetTrainerDetail,
  GetTrainerSummaryList,
  PostSupervisorSummary,
  PutTrainerSummary,
  GetQuestionnaire,
  GetSupervisorSSummary,
  PostPayOrder,
  GetPayStatus,
  RefreshPayStatus,
  PostTrainerSummary,
  GetTrainCenterList,
  GetCourseList
} from 'services/trainingSystem'
import { getDvaApp, history } from 'umi'
import { PostExamPaper } from 'services/exam'
import { message, Modal, Image } from 'antd'
import weChatpayUrl from 'assets/we_chatpay.png'
import styles from '../index.less'

let currentKey = ''

export default {
  namespace: 'personalCenter',
  state: {
    isEdit: false,
    moduleList: [],
    tabkey: '',
    moduleDetail: null,
    questionDetail: null,
    questionData: null,
    userInfo: null,
    submitData: null,
    drawerVisible: false,
    currentAssmessmentIsFinshed: false,
    showSupervisionVisible: false,
    supervisionTabActiveKey: "TrainerSummary",
    eidtSupervisionVisible: false,
    supervisorQuestion: null,
    supervisorSubmitData: null,
    supervisorIsFinshed: false,
    questionnaireListData: [],
    trainerDetail: null,
    currentTrainerSummaryId: '',
    currentSupervisorId: '',
    supervisionQuestionDetail: null,
    payUrl: '',
    // 订单支付轮询timer
    payTimer: null,
    contentLoading: false,
    // 轮询订单支付结果
    payStatusPollingRes: null,
    // 轮询订单支付结果的次数
    statusPollingTime: 0
  },
  reducers: {
    updateState(state, { payload }) {
      const { tabkey } = payload
      if (tabkey !== undefined) {
        currentKey = tabkey
      }
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user)
      const { staff } = currentUser || {}
      yield put({ type: 'updateState', payload: { userInfo: staff, contentLoading: true, moduleDetail: null, trainerDetail: null, questionDetail: null } })
      yield put({ type: 'getQuestionData' }) // 用于使用finishTime
      yield put({ type: 'getModuleList' })
      // yield put({ type: 'getQualification' })
      yield put({ type: 'getTrainerDetail' })
    },

    // 证书列表
    *getTrainerDetail({ payload }, { call, put, select }) {
      const { userInfo } = yield select(state => state.personalCenter)
      const res = yield call(GetTrainerDetail, { trainerId: userInfo && userInfo.id })
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { trainerDetail: res.data } })
      }
    },

    *getQuestionData({ payload }, { call, put, select }) {
      const res = yield call(GetTrainingApplication)
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { questionDetail: res.data } })
      }
    },

    *getModuleList({ payload }, { call, put, select }) {
      const { tabkey, moduleList, userInfo } = yield select(state => state.personalCenter)
      const { checkedId } = history.location.query
      // if (moduleList.length === 0) {
      // const res = yield call(GetTrainerModuleList)
      const res = yield call(GetTrainCenterList)
      if (res.response.ok) {
        if (res.data && res.data.length > 0) {
          const data = res.data
          const newTabkey = checkedId || tabkey || (data && data[0] && data[0].id || '')
          yield put({ type: 'updateState', payload: { moduleList: data, tabkey: newTabkey } })
          yield put({ type: 'getModuleDetail' })
        }
      }
      // } else {
      //   const newTabkey = checkedId || tabkey
      //   yield put({ type: 'updateState', payload: { tabkey: newTabkey } })
      // }
    },

    *getModuleDetail({ payload }, { call, put, select }) {
      const { tabkey: stateKey, moduleList, trainerDetail } = yield select(state => state.personalCenter)
      // const params = { TrainerTrainingModuleId: tabkey }
      // const res = yield call(GetTrainerModuleDetail, params)

      const tabkey = payload || stateKey
      const currentModule = moduleList.find(item => item.id === tabkey)
      const { trainingModuleId } = currentModule
      const res = yield call(GetCourseList, { TrainingModuleId: trainingModuleId, TrainerTrainingId: tabkey })
      if (res.response.ok && currentKey == tabkey) {
        // 处理一波数据跟原来数据结构保持一致
        // const { trainingModule, issued, startDate, endDate, status, trainer, tenant, trainingOrder } = detail || {}
        // const { courses, id, name: trainingModuleName } = trainingModule || {}
        // trainer 
        // tenant 
        const { id, issued, startDate, endDate, status, trainingOrder, trainingModule, lastUserExaminationPaperId, trainingModuleId } = currentModule
        const { trainerCredentialses = [] } = trainerDetail || {}
        const currentTrainer = trainerCredentialses.filter(item => item.trainingModuleId === trainingModuleId)
        const newData = {
          id,
          issued,
          startDate,
          endDate,
          status,
          trainingOrder,
          trainingModule: {
            ...trainingModule,
            courses: res.data,
          },
          trainer: {
            trainerCredentialses: currentTrainer
          },
          lastUserExaminationPaperId
        }
        yield put({ type: 'updateState', payload: { moduleDetail: newData, contentLoading: false } })
        // yield put({ type: 'updateState', payload: { moduleDetail: res.data } })
      }
    },

    *submit({ payload }, { call, put, select }) {
      const { questionData, questionDetail } = yield select(state => state.personalCenter)
      const { id } = questionData || questionDetail || {}
      const { answerContent, isComplete, finishedPage, intl } = payload
      const params = {
        userQuestionnaireId: id,
        answerContent,
        isComplete,
        finishedPage
      }
      const res = yield call(PutQuestionnaire, params)
      if (res.response.ok) {
        if (isComplete) {
          message.success(intl.formatMessage({ id: 'submitted' }))
          yield put({ type: 'updateState', payload: { submitData: null, drawerVisible: false, isEdit: false } })
          yield put({ type: 'query' })
        }
      }
    },

    *startStudy({ payload: { intl } }, { call, put, select }) {
      const { tabkey } = yield select(state => state.personalCenter)
      const res = yield call(StartStudy, { trainerTrainingId: tabkey })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'hasStarted' }))
        yield put({ type: 'query' })
      } else {
        const { error } = res.data
        const { message: errorInfo } = error || {}
        errorInfo && message.error(errorInfo)
      }
    },

    *onChangeTabs({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { tabkey: payload, payUrl: '', contentLoading: true } })
      // yield put({ type: 'query' })
      yield put({ type: 'clearPayTimer' })
    },

    *postTrainingApplication({ payload }, { call, put, select }) {
      const { userInfo } = yield select(state => state.personalCenter)
      const res = yield call(PostGetTrainingApplication, { userId: userInfo && userInfo.id })
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { questionData: res.data, drawerVisible: true } })
      }
    },

    // 获取问卷内容
    *getQuestionDetail({ payload }, { call, put, select }) {
      const { supervisionTabActiveKey } = yield select(state => state.personalCenter)
      const questionDetailRes = yield call(GetQuestionnaire, { id: payload })
      if (questionDetailRes.response.ok) {
        const currentIdName = supervisionTabActiveKey === "TrainerSummary" ? "currentTrainerSummaryId" : "currentSupervisorId"
        yield put({
          type: "updateState",
          payload: {
            supervisionQuestionDetail: questionDetailRes.data,
            [currentIdName]: payload
          }
        })
      }
    },

    // 切换menuList
    *clickMenuItem({ payload }, { call, put, select }) {
      const { id } = payload || {}
      yield put({
        type: 'getQuestionDetail',
        payload: id
      })
    },

    // 获取训练师小结
    *getTrainerSummary({ payload }, { call, put, select }) {
      const { userInfo, currentTrainerSummaryId } = yield select(state => state.personalCenter)
      const trainerSummaryRes = yield call(GetTrainerSummaryList, { UserId: userInfo.id })
      if (trainerSummaryRes.response.ok) {
        const data = trainerSummaryRes.data
        yield put({
          type: "updateState",
          payload: {
            questionnaireListData: data
          }
        })
        const { items } = data || {}
        const { items: subItems } = items || {}
        const id = currentTrainerSummaryId ? currentTrainerSummaryId : (subItems && subItems.length > 0 ? subItems[0].id : '')
        if (id !== '')
          yield put({
            type: "getQuestionDetail",
            payload: id
          })
      }
    },

    // 获取督导师跟踪表
    *getSupervisor({ payload }, { call, put, select }) {
      const { userInfo, currentSupervisorId } = yield select(state => state.personalCenter)
      const supervisorListRes = yield call(GetSupervisorSSummary, { UserId: userInfo.id })
      if (supervisorListRes.response.ok) {
        const data = supervisorListRes.data
        const { items } = data || {}
        const { items: subItems } = items || {}
        const id = currentSupervisorId ? currentSupervisorId : (subItems && subItems.length > 0 ? subItems[0].id : "")
        yield put({
          type: "updateState",
          payload: {
            questionnaireListData: data
          }
        })
        if (id) {
          yield put({
            type: "getQuestionDetail",
            payload: id
          })
        }
      }
    },

    // 根据当前Tab Type获取不同的数据
    *getDataByDiffType({ payload }, { call, put, select }) {
      const { supervisionTabActiveKey } = yield select(state => state.personalCenter)
      if (supervisionTabActiveKey === "TrainerSummary") {
        yield put({ type: "getTrainerSummary" })
      } else if (supervisionTabActiveKey === "Supervisor") {
        yield put({ type: "getSupervisor" })
      }
    },

    // 展示见习督导记录
    *showSupervision({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          showSupervisionVisible: true,
          currentSupervisorId: '',
          currentTrainerSummaryId: '',
          supervisionQuestionDetail: ''
        }
      })
      yield put({ type: 'getDataByDiffType' })
    },

    // 切换见习督导记录Tab
    *onChangSupervisionTabs({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { supervisionTabActiveKey: payload, questionnaireListData: null, supervisionQuestionDetail: null } })
      yield put({ type: 'getDataByDiffType' })
    },

    // 展示编辑见习督导小结
    *eidtSupervision({ payload }, { call, put, select }) {
      const { userInfo } = yield select(state => state.personalCenter)
      const supervisionRes = yield call(PostTrainerSummary, { userId: userInfo.id })
      if (supervisionRes.response.ok) {
        yield put({ type: "updateState", payload: { supervisorQuestion: supervisionRes.data, eidtSupervisionVisible: true } })
      }
    },

    // 编辑见习督导小结
    *editSupervision({ payload }, { call, put, select }) {
      const { userInfo, currentTrainerSummaryId } = yield select(state => state.personalCenter)
      const supervisionRes = yield call(GetQuestionnaire, { id: currentTrainerSummaryId })
      //const supervisionRes = yield call(PostTrainerSummary, { userId: userInfo.id })
      if (supervisionRes.response.ok) {
        yield put({ type: "updateState", payload: { supervisorQuestion: supervisionRes.data, eidtSupervisionVisible: true } })
      }
    },

    // 关闭编辑见习督导小结
    *onCloseEidtSupervision({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { eidtSupervisionVisible: false, supervisorQuestion: null } })
    },

    // 提交训练小结
    *supervisorSubmit({ payload }, { call, put, select }) {
      const { supervisorQuestion } = yield select(state => state.personalCenter)
      const { id } = supervisorQuestion || {}
      const { answerContent, isComplete, finishedPage, intl } = payload
      const params = {
        updateQuestionnaireDto: {
          userQuestionnaireId: id,
          answerContent,
          isComplete,
          finishedPage
        }
      }
      const res = yield call(PutTrainerSummary, params)
      if (res.response.ok) {
        if (isComplete) {
          message.success(intl.formatMessage({ id: 'submitted' }))
          yield put({ type: 'updateState', payload: { supervisorSubmitData: null, supervisorQuestion: null, eidtSupervisionVisible: false, currentTrainerSummaryId: '' } })
          yield put({ type: 'getDataByDiffType' })
        }
      }
    },

    *goToPay({ payload }, { call, put, select }) {
      const { code, payStyle, showModal = 'noShow', paymentAmount = 0 } = payload || {}
      const params = {
        code,
        bestPayTypeEnum: payStyle
      }
      const goToPayRes = yield call(PostPayOrder, params)
      if (goToPayRes.response?.ok) {
        const { response } = goToPayRes || {};
        response.blob().then(blob => {
          const fr = new FileReader();
          fr.onload = function (e) {
            // 去轮询当前订单的状态
            console.log("开始轮询获取订单状态了")
            const payTimer = setInterval(() => {
              getDvaApp()._store.dispatch({
                type: 'personalCenter/payStatusPolling',
                payload: {
                  code,
                  showModal,
                }
              })
            }, 2000)

            getDvaApp()._store.dispatch({
              type: 'personalCenter/updateState',
              payload: {
                payUrl: e.target.result,
                payTimer
              }
            })

            if (showModal !== 'noShow') {
              Modal.info({
                title: '',
                icon: '',
                content: (
                  <div className={styles.modalPanel}>
                    <div>
                      <Image src={e.target.result} width={100} />
                    </div>
                    <div className={styles.modelRight}>
                      <div>
                        <img src={weChatpayUrl} />
                        <span>微信扫码支付</span>
                      </div>
                      <div>
                        应付金额：<span className={styles.payMoney}>${paymentAmount}</span>
                      </div>
                    </div>
                  </div>
                ),
                onOk: () => {
                  clearInterval(payTimer)
                }
              });
            }
          }
          fr.readAsDataURL(blob);
        })
      } else {
        const { msg } = goToPayRes.data || {}
        msg && message.error(msg)
      }
    },

    *payStatusPolling({ payload }, { call, put, select }) {
      let { statusPollingTime } = yield select(state => state.personalCenter)
      const { code, showModal = 'noShow' } = payload || {}
      const payStatusPollingRes = yield call(GetPayStatus, { code })
      if (payStatusPollingRes.response.ok) {
        const dataRes = payStatusPollingRes.data
        const { data } = dataRes || {}
        const { status } = data || {}
        if (showModal !== 'noShow' && status === 'Paid') {
          history.push({
            pathname: '/system/center/detail/examPanel',
            query: {
              trainerTrainingModuleId: code,
            }
          })
          Modal.destroyAll()
          yield put({ type: 'clearPayTimer' })
        }
        yield put({
          type: 'updateState',
          payload: {
            payStatusPollingRes: payStatusPollingRes.data,
            statusPollingTime: ++statusPollingTime
          }
        })

        if (statusPollingTime === 10) {
          yield put({ type: 'RefreshPayStatus', payload: { code } })
        }

        if (status === "Paid") {
          setTimeout(() => {
            getDvaApp()._store.dispatch({
              type: 'personalCenter/getModuleDetail'
            })
            getDvaApp()._store.dispatch({
              type: 'personalCenter/clearPayTimer'
            })
          }, 2000)
        }
      }
    },

    *RefreshPayStatus({ payload }, { call, put, select }) {
      const { code } = payload || {}
      const params = {
        code
      }
      const RefreshPayStatusRes = yield call(RefreshPayStatus, params)
      yield put({
        type: 'updateState',
        payload: {
          statusPollingTime: 0
        }
      })
    },

    *clearPayTimer({ payload }, { call, put, select }) {
      const { payTimer } = yield select(state => state.personalCenter)
      clearInterval(payTimer)
      yield put({
        type: 'updateState',
        payload: {
          payTimer: null,
          statusPollingTime: 0
        }
      })
    }

  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(async location => {
    //     if (location.pathname === '/system/center/menu/personalCenter') {
    //       await dispatch({
    //         type: 'updateState',
    //         payload: {
    //           payStatusPollingRes: null,
    //           payUrl: ''
    //         }
    //       })
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
