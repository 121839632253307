import React, { useEffect } from 'react'
import { Card, Tabs, Space, Divider, Button, Input, Select, Empty, message, Modal, Tooltip } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import SelectModal from 'components/SelectModal'
import ControllSelectModal from 'components/ControllSelectModal'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { connect, history, useIntl } from 'umi'
import moment from 'moment'
import styles from './index.less'

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const TrainingStaffList = ({ trainingStaff, dispatch, loading, currentUser }) => {
    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'trainingStaff/query' })
    }, [])

    const {
        activeKeyId,
        name,
        learningState,
        qualifications,
        pagination,
        selectModalVisible,
        remarkModalVisible,
        tabsData,
        trainingStaffData,
        list,
        selectedKeys,
        targetKeys,
        content,
        remarkContent,
        isPutAway,
        TrainerFrom,
        currentItem,
        TrainingPayWay,
        learningStatus,
        issuedStatus
    } = trainingStaff
    const currentData = tabsData.find(item => item.trainingModule && item.trainingModule.id === activeKeyId)
    const { numberOfPlaces, usedNumber, validDate, trainingModule, issuedCount, price, usePriceNum, canUsePrice: curCanUsePrice } = currentData || {}
    const { auth, current: { roles = []} } = currentUser || {}
    const { grantedPolicies } = auth || {}
    const hasLookPermission = grantedPolicies['RocketSystem.TenantTrainerManagement'] === true
    const hasArrangePermission = grantedPolicies['RocketSystem.TenantTrainerManagement.Arrange'] === true
    const isEnterAdmin = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'EnterpriseAdmin') : false
    }

    const { canUsePrice } = trainingStaffData || {}

    const listSupport = (intl) => {
        return {
            arrangeTime: {
                showText: intl.formatMessage({ id: 'creationTime' }),
                showType: 'Time',
                addtional: {
                    format: 'YYYY/MM/DD HH:mm',
                },
            },
            name: {
                showText: intl.formatMessage({ id: 'name' }),
                showType: 'Text',
            },
            trainingStep: {
                showText: intl.formatMessage({ id: 'Overall progress' }),
                showType: 'Text',
                render: (text) => intl.formatMessage({ id: text }),
            },
            learningState: {
                showText: intl.formatMessage({ id: 'learnStatus' }),
                showType: 'Text',
                filter: {
                    hasFilter: true,
                    filterOptions: [
                        {
                            text: intl.formatMessage({ id: 'notStart' }),
                            value: 'NotStarted',
                        },
                        {
                            text: intl.formatMessage({ id: 'proceeding' }),
                            value: 'Learning',
                        },
                        {
                            text: intl.formatMessage({ id: 'paused' }),
                            value: 'Stopped',
                        },
                        {
                            text: intl.formatMessage({ id: 'expired' }),
                            value: 'Expired',
                        },
                    ],
                },
            },
            speed: {
                showText: intl.formatMessage({ id: 'Course Progress' }),
                showType: 'Text',
            },
            endDate: {
                showText: intl.formatMessage({ id: 'Course end time' }),
                showType: 'Time',
                addtional: {
                    format: 'YYYY/MM/DD',
                },
            },
            training: {
                showText: intl.formatMessage({ id: 'Number of training sessions' }),
                showType: 'Text',
            },
            supervision: {
                showText: intl.formatMessage({ id: 'Number of supervisions' }),
                showType: 'Text',
            },
            writtenTestPassed: {
                showText: intl.formatMessage({ id: 'WrittenExam' }),
                showType: 'Text',
                render: (text) => {
                    if (text === null) {
                        return '-'
                    }
                    return intl.formatMessage({ id: text ? 'pass' : 'notPass' })
                }
            },
            oralTestPassed: {
                showText: intl.formatMessage({ id: 'OralExam' }),
                showType: 'Text',
                render: (text) => {
                    if (text === null) {
                        return '-'
                    }
                    return intl.formatMessage({ id: text ? 'pass' : 'notPass' })
                }
            },
            handsOnTestPassed: {
                showText: intl.formatMessage({ id: 'PracticalExam' }),
                showType: 'Text',
                render: (text) => {
                    if (text === null) {
                        return '-'
                    }
                    return intl.formatMessage({ id: text ? 'pass' : 'notPass' })
                }
            },
            issueTime: {
                showText: intl.formatMessage({ id: 'Certificate issuance time' }),
                showType: 'Time',
                render: (text, record) => {
                    const { certificateStatus } = record || {};
                    return certificateStatus !== 'NotIssued' && moment(text).format('YYYY-MM-DD')
                }
            },
            lastModificationTime: {
                showText: intl.formatMessage({ id: 'Updated Time' }),
                showType: 'Time',
                addtional: {
                    format: 'YYYY/MM/DD HH:mm',
                },
            },
            remark: {
                showText: intl.formatMessage({ id: 'comments' }),
                showType: 'Text',
                render: (text) => {
                    if (text && text.length >= 4) {
                        return <Tooltip title={text}>
                            <span>{text.slice(0, 4)}...</span>
                        </Tooltip>
                    }
                    return text;
                }
            },
            // from: {
            //     showText: intl.formatMessage({ id: 'from' }),
            //     showType: 'Text',
            // },
            // trainingPayWayText: {
            //     showText: intl.formatMessage({ id: 'paymentMethods' }),
            //     showType: 'Text',
            // },
            // realPrice: {
            //     showText: intl.formatMessage({ id: 'totalPayment' }),
            //     showType: 'Text',
            // },
            // speed: {
            //     showText: intl.formatMessage({ id: 'progress' }),
            //     showType: 'Text',
            // },
            // startDate: {
            //     showText: intl.formatMessage({ id: 'startlearnDate' }),
            //     showType: 'Time',
            //     addtional: {
            //         format: 'YYYY/MM/DD',
            //     },
            // },
            // stopTime: {
            //     showText: intl.formatMessage({ id: 'pausinglearnDate' }),
            //     showType: 'Time',
            //     addtional: {
            //         format: 'YYYY/MM/DD',
            //     },
            // },
            // endDate: {
            //     showText: intl.formatMessage({ id: 'endLearnDate' }),
            //     showType: 'Time',
            //     addtional: {
            //         format: 'YYYY/MM/DD',
            //     },
            // },
            // issuedStatus: {
            //     showText: intl.formatMessage({ id: 'qulification' }),
            //     showType: 'Text',
            //     filter: {
            //         hasFilter: true,
            //         filterOptions: [
            //             {
            //                 text: intl.formatMessage({ id: 'unissued' }),
            //                 value: 'NotIssued',
            //             },
            //             {
            //                 text: intl.formatMessage({ id: 'Effectiveing' }),
            //                 value: 'InEffect',
            //             },
            //             {
            //                 text: intl.formatMessage({ id: 'NotActive' }),
            //                 value: 'Ineffective',
            //             },
            //             {
            //                 text: intl.formatMessage({ id: 'expired' }),
            //                 value: 'Expired',
            //             },
            //             {
            //                 text: intl.formatMessage({ id: 'Cancelled' }),
            //                 value: 'Canceled',
            //             },
            //         ],
            //     },
            // },
            // issueTime: {
            //     showText: intl.formatMessage({ id: 'Date of issue' }),
            //     showType: 'Time',
            //     render: (text, record) => {
            //         const { certificateStatus } = record || {};
            //         return certificateStatus !== 'NotIssued' && moment(text).format('YYYY-MM-DD')
            //     }
            // },
        }
    }
    const tableProps = {
        listData: list.map(item => {
            const { trainerFrom, trainingPayWay, isPay, price, certificateStatus } = item || {}
            return {
                ...item,
                learningState: {
                    "Learning": intl.formatMessage({ id: 'proceeding' }),
                    "NotStarted": intl.formatMessage({ id: 'notStart' }),
                    "Stopped": intl.formatMessage({ id: 'paused' }),
                    "Expired": intl.formatMessage({ id: 'expired' }),
                }[item.status],
                speed: `${item.progress} / ${item.progressCount}`,
                issuedStatus: {
                    "Ineffective": intl.formatMessage({ id: 'NotActive' }),
                    "InEffect": intl.formatMessage({ id: 'Effectiveing' }),
                    "Expired": intl.formatMessage({ id: 'expired' }),
                    "NotIssued": intl.formatMessage({ id: 'unissued' }),
                    "Canceled": intl.formatMessage({ id: 'Cancelled' }),
                }[certificateStatus],
                from: {
                    "Tenant": intl.formatMessage({ id: 'centerArranged' }),
                    "H5": intl.formatMessage({ id: 'weixinAppplied' })
                }[trainerFrom],
                trainingPayWayText: {
                    "Quota": intl.formatMessage({ id: 'centerPayment' }),
                    "Pay": intl.formatMessage({ id: 'privatePayment' }),
                }[trainingPayWay],
                realPrice: trainingPayWay !== "Pay" ? '-' : isPay ? price : intl.formatMessage({ id: 'unpaid' })
            }
        }),
        listSupport: listSupport(intl),
        loading: loading.models.trainingStaff,
        pagination,
        filters: {
            learningState: learningStatus,
            issuedStatus
        },
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'trainingStaff/changeTable', payload: data })
        },
        Actions: [
            hasLookPermission ? {
                showText: intl.formatMessage({ id: 'detail' }),
                onClick(record) {
                    history.push({
                        pathname: '/system/center/detail/trainingStaffDetail',
                        query: {
                            id: record.trainerId,
                        },
                    })
                },
            }
                :
                null,
            !isEnterAdmin() ? {
                showText: intl.formatMessage({ id: 'edit' }),
                onClick(record) {
                    dispatch({ type: 'trainingStaff/editRemark', payload: record })
                },
            } : null
            // {
            //     showText: '重新安排',
            //     itemRender(record) {
            //         const { issued, issueTime, trainerFrom, trainingPayWay, isPay } = record || {}
            //         return !isPay && trainingPayWay === "Pay" ? (
            //             <a onClick={()=>dispatch({ type: 'trainingStaff/arrangeTraining', payload: { type: 'rearrange', record, currentData } })}>重新安排</a>
            //         ) : null
            //     },
            // }
        ]
    }

    const onchangeSearchValue = async (value, type) => {
        const defaultPagination = {
            current: 1,
            pageSize: 10,
            total: 0,
          }
        dispatch({ type: 'trainingStaff/updateState', payload: { pagination: defaultPagination } })
        switch (type) {
            case "name":
                await dispatch({ type: 'trainingStaff/updateState', payload: { [type]: value.target.value } })
                break
            case "learningState":
            case "qualifications":
            case "TrainerFrom":
            case "TrainingPayWay":
                await dispatch({ type: 'trainingStaff/updateState', payload: { [type]: value } })
                dispatch({ type: 'trainingStaff/onsearch' })
                break
        }
    }

    // const selectModalProps = {
    //     title: (
    //         <div>
    //             <span>选择员工安排{trainingModule && trainingModule.displayName}培训</span>
    //             <span style={{ color: 'red' }}>(最多可选{(numberOfPlaces) || 0}项)</span>
    //         </div>
    //     ),
    //     allList: trainingStaffData ? trainingStaffData.trainers.map(item => { return { ...item, title: item.name } }) : [],
    //     selectModalVisible,
    //     selectedKeys,
    //     targetKeys,
    //     onCancel() {
    //         dispatch({ type: 'trainingStaff/updateState', payload: { selectModalVisible: false } })
    //     },
    //     onOk(values) {
    //         if (values.length > (numberOfPlaces)) {
    //             message.error(`最多可选${(numberOfPlaces) || 0}项`)
    //             return
    //         }
    //         dispatch({ type: 'trainingStaff/submitArrange', payload: values })
    //         dispatch({ type: 'trainingStaff/updateState', payload: { selectedKeys: [], targetKeys: [] } })
    //     },
    //     handleSelectChange(value) {
    //         dispatch({ type: 'trainingStaff/updateState', payload: { selectedKeys: value } })
    //     },
    //     handleChange(value) {
    //         dispatch({ type: 'trainingStaff/updateState', payload: { targetKeys: value } })
    //     }
    // }

    const modalProps = {
        title: `${intl.formatMessage({ id: 'arrange' })}${trainingModule && trainingModule.displayName}${intl.formatMessage({ id: 'training' })}`,
        visible: selectModalVisible,
        onCancel() {
            dispatch({ type: 'trainingStaff/updateState', payload: { selectModalVisible: false } })
        },
        onOk(values) {
            const { trainerId, useNum } = values || {}
            let title = intl.formatMessage({ id: 'sureArrangementsSuccessful' })
            if (useNum === '0') {
                if (numberOfPlaces < trainerId.length) {
                    message.error(intl.formatMessage({ id: 'selectedEmployees' }))
                    return
                }
                if (trainerId.length > 1) {
                    title = intl.formatMessage({ id: 'arrangingTraining' }, { name: trainerId.length })
                }
            }
            if (useNum === '1' && trainerId.length > 1) {
                title = intl.formatMessage({ id: 'arrangingTrainingModuleSelfPayment' }, { name: trainerId.length })
            }
            Modal.confirm({
                title: <span style={{ color: 'red' }}>{title}</span>,
                okText: intl.formatMessage({ id: 'submit' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk: () => {
                    dispatch({ type: 'trainingStaff/submitArrange', payload: values })
                },
                onCancel() {
                },
            });
        },
        content,
        otherTopContent: (
            <div style={{ fontSize: '17px', marginBottom: '30px', textAlign: 'center' }}>
                <span>{intl.formatMessage({ id: 'currentlyAvailable' })}：{numberOfPlaces || 0}</span>
                {/* <span>{intl.formatMessage({ id: 'currentlyAvailable' })}：{numberOfPlaces || 0}；</span>
                <span style={{ marginLeft: '5px' }}>{canUsePrice ? `${intl.formatMessage({ id: 'selfPaymentPrices' })}：$${price || 0}` : intl.formatMessage({ id: 'selfPaymentUnavailable' })}</span> */}
                {/* {
                    canUsePrice === false && <span style={{ fontSize: '14px', marginLeft: '10px' }}>如需开启可联系思欣跃工作人员</span>
                } */}
            </div>
        )
    }

    const remarkModalProps = {
        title: `${intl.formatMessage({ id: 'edit' })}${intl.formatMessage({ id: 'comments' })}`,
        loading: loading.models.trainingStaff,
        visible: remarkModalVisible,
        content: remarkContent,
        onCancel: () => {
            dispatch({ type: 'trainingStaff/updateState', payload: { remarkModalVisible: false } })
        },
        onOk: (values) => {
            dispatch({ type: 'trainingStaff/editRemarkSubmit', payload: values })
        },
    }

    const onChangeTabs = (value) => {
        dispatch({ type: 'trainingStaff/onChangeTabs', payload: value })
    }

    return (
        <Card title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'trainManagement' })}</b>} bordered={false}>
            {
                tabsData.length > 0 ?
                    <React.Fragment>
                        <Tabs activeKey={activeKeyId} onChange={onChangeTabs}>
                            {
                                tabsData && tabsData.length > 0 &&
                                tabsData.map(item => {
                                    return <TabPane tab={item.trainingModule && item.trainingModule.displayName} key={item.trainingModule && item.trainingModule.id}></TabPane>
                                })
                            }
                        </Tabs>
                        <div className={styles.trainingStaffContent}>
                            <div className={styles.trainingStaffContentData}>
                                <div className={styles.trainingStaffContentDataTop}>
                                    <div className={styles.trainingStaffContentDataTopItem}>
                                        <Space split={<Divider type="vertical" />}>
                                            <span>{intl.formatMessage({ id: 'availablePlaces' }, { time: numberOfPlaces || 0 })}</span>
                                            <span>{intl.formatMessage({ id: 'usedPlaces' }, { time: usedNumber || 0 })}</span>
                                            <span>{intl.formatMessage({ id: 'totalPlaces' }, { time: (numberOfPlaces + usedNumber) || 0 })}</span>
                                            {/* <span>{intl.formatMessage({ id: 'privatePayment' })} {usePriceNum}</span> */}
                                        </Space>
                                    </div>
                                    {
                                        curCanUsePrice &&
                                        <div className={styles.trainingStaffContentDataTopItem}>
                                            {intl.formatMessage({ id: 'totalPrivatePayment' })}：{price || 0}
                                        </div>
                                    }

                                </div>
                                <div className={styles.trainingStaffContentDataBottom}>
                                    <div className={styles.trainingStaffContentDataBottomItem}>
                                        <Space split={<Divider type="vertical" />}>
                                            <span>{intl.formatMessage({ id: 'qualifiedTrainees' }, { num: issuedCount })}</span>
                                            {/* <span>学习已通过 Y 人</span> */}
                                            <span>{intl.formatMessage({ id: 'availablePeriod' })} {validDate || 0}</span>
                                        </Space>
                                    </div>
                                    {
                                        hasArrangePermission &&
                                        <Button onClick={() => dispatch({ type: 'trainingStaff/arrangeTraining', payload: { type: 'arrange', currentData } })}>{intl.formatMessage({ id: 'arrange' })} {trainingModule && trainingModule.displayName} {intl.formatMessage({ id: 'training' })}</Button>
                                    }
                                </div>
                            </div>
                            <div className={styles.trainingStaffContentSearch}>
                                <div className={styles.trainingStaffContentSearchTop}>
                                    <Search
                                        placeholder={intl.formatMessage({ id: 'searchName' })}
                                        value={name}
                                        onChange={(value) => onchangeSearchValue(value, 'name')}
                                        style={{ width: 200, margin: '0 30px 10px 0' }}
                                        onSearch={() => dispatch({ type: 'trainingStaff/onsearch' })}
                                    />
                                    {/* <div className={styles.trainingStaffContentSearchItem}>
                                        <div className={styles.trainingStaffContentSearchItemLabel}>{intl.formatMessage({ id: 'learnStatus' })}</div>
                                        <Select value={learningState} onChange={(value) => onchangeSearchValue(value, 'learningState')} style={{ width: 200 }}>
                                            <Option key={''}>{intl.formatMessage({ id: 'all' })}</Option>
                                            <Option key={'NotStarted'}>{intl.formatMessage({ id: 'notStart' })}</Option>
                                            <Option key={'Learning'}>{intl.formatMessage({ id: 'proceeding' })}</Option>
                                            <Option key={'Stopped'}>{intl.formatMessage({ id: 'paused' })}</Option>
                                            <Option key={'Expired'}>{intl.formatMessage({ id: 'expired' })}</Option>
                                        </Select>
                                    </div>
                                    <div className={styles.trainingStaffContentSearchItem}>
                                        <div className={styles.trainingStaffContentSearchItemLabel}>{intl.formatMessage({ id: 'qulification' })}</div>
                                        <Select value={qualifications} onChange={(value) => onchangeSearchValue(value, 'qualifications')} style={{ width: 200 }}>
                                            <Option key={''}>{intl.formatMessage({ id: 'all' })}</Option>
                                            <Option key={true}>{intl.formatMessage({ id: 'issued' })}</Option>
                                            <Option key={false}>{intl.formatMessage({ id: 'unissued' })}</Option>
                                        </Select>
                                    </div> */}
                                     <div className={styles.trainingStaffContentSearchBottom}>
                                        <div className={styles.trainingStaffContentSearchItem}>
                                            <div className={styles.trainingStaffContentSearchItemLabel}>{intl.formatMessage({ id: 'from' })}</div>
                                            <Select value={TrainerFrom} onChange={(value) => onchangeSearchValue(value, 'TrainerFrom')} style={{ width: 200 }}>
                                                <Option key={''}>{intl.formatMessage({ id: 'all' })}</Option>
                                                <Option key={'Tenant'}>{intl.formatMessage({ id: 'centerArranged' })}</Option>
                                                <Option key={'H5'}>{intl.formatMessage({ id: 'weixinAppplied' })}</Option>
                                            </Select>
                                        </div>
                                        {/* <div className={styles.trainingStaffContentSearchItem}>
                                            <div className={styles.trainingStaffContentSearchItemLabel}>{intl.formatMessage({ id: 'availabilityOfPlaces' })}</div>
                                            <Select value={TrainingPayWay} onChange={(value) => onchangeSearchValue(value, 'TrainingPayWay')} style={{ width: 200 }}>
                                                <Option key={''}>{intl.formatMessage({ id: 'all' })}</Option>
                                                <Option key={'Quota'}>{intl.formatMessage({ id: 'centerPayment' })}</Option>
                                                <Option key={'Pay'}>{intl.formatMessage({ id: 'privatePayment' })}</Option>
                                            </Select>
                                        </div> */}
                                    </div>
                                    <Button onClick={() => dispatch({ type: 'trainingStaff/onclear' })}>{intl.formatMessage({ id: 'clear' })}</Button>
                                </div>
                            </div>
                        </div>
                        <MagicTable {...tableProps} />
                        {/* 安排员工培训弹框 */}
                        {/* <ControllSelectModal {...selectModalProps} /> */}
                        <MagicFormModal {...modalProps} />
                        <MagicFormModal {...remarkModalProps} />
                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        </Card>
    )
}

export default connect(({ trainingStaff, loading, user }) => ({ trainingStaff, loading, currentUser: user.currentUser }))(TrainingStaffList)