import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { message, Spin, Menu, Dropdown, Button, Card } from 'antd';
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey';
import { getCorrentRateAndResult, changeStorageStatus } from 'cognitiveleap-core-us/utils/utils';
import { GetExamResult, GetOtherExamResult } from 'services/exam';
import { DownOutlined } from '@ant-design/icons';
import passImgUrl from 'assets/pass.png';
import unpassImgUrl from 'assets/unpass.png';
import passEnImgUrl from 'assets/passEn.png'
import unpassEnImgUrl from 'assets/unpassEn.png'
import styles from './index.less';
import { history, useIntl, getLocale } from 'umi';

const ExamResultPanel = (props) => {
  const intl = useIntl()
  const isZh = getLocale() === 'zh-CN' ? true : false;

  // examId 单次查看考试成绩
  // trainerId 查看多个考试成绩
  const { examId, TrainerId, TrainerTrainingModuleId } = props.location.query;
  const { staff, adminRoles, current } = props.user.currentUser;
  const [examInfo, setExamInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState({});
  const [answer, setAnswer] = useState({});
  const [questionArr, setQuestionArr] = useState([]);
  const [allList, setAllList] = useState([]);
  const [url, setUrl] = useState(''); // 客户端登录的头像

  const showSurveyProps = {
    contents: questionArr,
    choice: answer && answer.answerContent ? answer.answerContent : null,
  };

  const getResult = async (choice = 'first') => {
    setLoading(true);
    let params = {};
    if (examId) {
      params = {
        id: examId,
      };
    } else {
      if (choice !== 'first') {
        params = {
          id: choice,
        };
      } else {
        const res = await GetOtherExamResult({
          TrainerTrainingModuleId,
          TrainerId,
        });
        if (res.response.ok) {
          const newList = res.data.items;
          if (newList.length === 0) {
            setLoading(false);
            return;
          }
          setAllList(newList);
          const { id } = newList[newList.length - 1];
          params = {
            id,
          };
        }
      }
    }
    const res = await GetExamResult(params);
    if (res.response.ok) {
      const { examinationPaper, examinationPaperAnswer, status } =
        res.data.data;
      setExamInfo(res.data.data);
      const name = res.data.data.user.name;
      setUrl(
        adminRoles.length > 0
          ? name
          : staff.picture
          ? staff.picture.url
          : staff.name,
      );
      if (status !== 'DOING') {
        if (examinationPaperAnswer.answerContent) {
          const { questionArr } =
            (await getCorrentRateAndResult(
              examinationPaper.jsonString,
              examinationPaperAnswer.answerContent,
              examinationPaper.passingScore,
            )) || {};
          setQuestionArr(questionArr);
        } else {
          let questionArr = [];
          const jsonstring =
            examinationPaper.jsonString &&
            JSON.parse(examinationPaper.jsonString);
          questionArr = jsonstring
            ? jsonstring.pages && jsonstring.pages.map((item) => item.elements)
            : [];
          questionArr = questionArr.flat(Infinity);
          const newData = questionArr.map((item) => {
            return {
              ...item,
              isRequired: false,
              isRight: false,
            };
          });
          setQuestionArr(newData);
        }

        setQuestion(examinationPaper);
        setAnswer(examinationPaperAnswer);
      }
    } else {
      message.error(intl.formatMessage({ id: 'getDataFail' }));
    }
    setLoading(false);
  };

  useEffect(() => {
    getResult();
  }, []);

  const menu = (
    <Menu>
      {allList &&
        allList.length > 0 &&
        allList.map((item) => {
          return (
            <Menu.Item key={item.id}>
              <div
                onClick={() => {
                  getResult(item.id);
                }}
              >
                {moment(item.endTime).format('YYYY.MM.DD HH:mm:ss')}
              </div>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <div className={styles.panel}>
      <Spin tip={intl.formatMessage({ id: 'examPaperReviewed' })} spinning={loading}>
        {JSON.stringify(question) !== '{}' &&
        JSON.stringify(answer) !== '{}' &&
        examInfo.status !== 'DOING' ? (
          <>
            <Card
              type="inner"
              title={
                <div>
                  <Button
                    onClick={() => {
                       changeStorageStatus("_STATUS_ExamList", "flag", true)
                       history.goBack()
                    }}
                  >
                    {intl.formatMessage({ id: 'back' })}
                  </Button>
                  <span className={styles.examName}>{question.name}</span>
                </div>
              }
            >
              <div className={styles.resultTop}>
                <div className={styles.resImg}>
                  {examInfo.status === 'PASSED' ? (
                    <img src={isZh ? passImgUrl : passEnImgUrl} />
                  ) : (
                    <img src={isZh ? unpassImgUrl : unpassEnImgUrl} />
                  )}
                </div>
                <div className={styles.resultCode}>
                  <div className={styles.codeTitle}>{examInfo.score}</div>
                  <div>{intl.formatMessage({ id: 'totalScore' })} {question.fraction}</div>
                </div>
                <div className={styles.userHeader}>
                  {url && url.length > 10 ? (
                    <img src={url} />
                  ) : (
                    <div className={styles.userName}>
                      {url || examInfo.user.name}
                    </div>
                  )}
                  <div className={styles.endTime}>
                    {intl.formatMessage({ id: 'submissionTime' })}:{' '}
                    {moment(examInfo.endTime).format('YYYY.MM.DD HH:mm:ss')}
                  </div>
                </div>
              </div>
              {allList.length > 0 ? (
                <Dropdown overlay={menu}>
                  <div className={styles.timeSelect}>
                    <a>
                      {moment(examInfo.endTime).format('YYYY.MM.DD HH:mm:ss')}{' '}
                      <DownOutlined />
                    </a>
                  </div>
                </Dropdown>
              ) : (
                ''
              )}
              <div className={styles.bodyAnswer}>
                <ShowSurvey {...showSurveyProps} />
              </div>
              {adminRoles.length === 0 &&
                !current.roles.some(
                  (role) =>
                    role.name === 'EnterpriseAdmin',
                ) &&
                examInfo.status !== 'PASSED' && (
                  <div className={styles.footerDes}>
                    *{intl.formatMessage({ id: 'retakeExamAdministrator' })}
                  </div>
                )}
            </Card>
          </>
        ) : (
          <div className={styles.noExam}>{intl.formatMessage({ id: 'noTestResult' })}</div>
        )}
      </Spin>
    </div>
  );
};

export default connect(({ user }) => ({ user }))(ExamResultPanel);
