import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Space,
  message,
  Card,
  Radio,
  Checkbox,
  Popover,
  Input,
} from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import {
  GetQuestion,
  GetQuestionClass,
  PostQuestion,
  PutQuestion,
  PostStatus,
  PostAddClass,
  PutClassName,
  DelClass,
  GetCount,
} from 'services/question';
import { addOptionsToProperties, matchDynamicForm } from 'cognitiveleap-core-us/utils/utils';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { isJson } from 'cognitiveleap-core-us/utils/helper';
import styles from './index.less';

const { Search } = Input;

let contentMenu = {
  Properties: [
    {
      EditorType: 'ContentSelector',
      ShowTitle: '所属分类',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: true,
      },
      Description: null,
      FormKey: 'classifies',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '分数值',
      FormKey: 'mark',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
        Props: {
          min: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'JSON字符串',
      FormKey: 'jsonString',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
  ],
};

let addContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '分类名称',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
  ],
};

let editContentMenu = {
  Properties: [
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: true,
      },
      ShowTitle: '当前分类名称',
      FormKey: 'name',
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '新分类名称',
      FormKey: 'newName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: true,
      },
      Description: null,
    },
  ],
};

let delContentMenu = {
  Properties: [
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        DropdownOptions: [],
        Required: true,
      },
      ShowTitle: '分类名称',
      FormKey: 'name',
      Description: null,
    },
  ],
};

const Question = () => {
  // 列表数据
  const [currentListData, setCurrentListData] = useState([]);
  // 分类数据
  const [classData, setClassData] = useState([]);
  // 当前选择分类
  const [currentClass, setCurrentClass] = useState([]);
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [keyword, setKeyword] = useState('');

  const [radioValue, setRadioValue] = useState(null);

  // 当前预览题目的信息
  const [question, setQuestion] = useState({
    title: '',
    choices: [{ text: '' }],
    correctAnswer: 0,
    showExpression: null
  });

  // 修改题信息的model的状态
  const [content, setContent] = useState({});

  // 判断是否是修改题目
  const [questionID, setQuestionID] = useState('');

  // loading
  const [loading, setLoading] = useState({
    tableLoading: false,
    modelLoading: false,
  });

  // check配置
  const [options, setOptions] = useState([]);

  // 当前选择项
  const [selectItem, setSelectItem] = useState({
    status: 'Activate',
  });

  const [status, setStatus] = useState('');

  // 一些model的控制
  const [modelShow, setModelShow] = useState({
    questionModel: false,
    editModel: false,
    editClassModel: false,
    modelShow: false,
    addModel: false,
    delModel: false,
  });

  // 列表colums
  const listSupport = {
    title: {
      showText: '题目',
      showType: 'Text',
      width: '30%',
    },
    code: {
      showText: 'name',
      showType: 'Text',
      width: '10%',
    },
    mark: {
      showText: '分数',
      showType: 'Text',
      width: '6%',
    },
    classifies: {
      showText: '所属分类',
      showType: 'Text',
      width: '10%',
      render: (text, record, index) => {
        if (text.length === 0) {
          return '-';
        } else if (text.length === 1) {
          return text[0].name;
        } else {
          return (
            <Popover
              title="所属分类"
              key={record.id}
              content={text.map((item) => {
                return item ? (
                  <div key={item.id} className={styles.popover}>
                    {item.name}
                  </div>
                ) : (
                  <div className={styles.popover}>-</div>
                );
              })}
            >
              <span style={{ cursor: 'pointer' }}>
                {text[0].name + ' - (' + text.length + ')'}
              </span>
            </Popover>
          );
        }
      },
    },
    type: {
      showText: '题型',
      showType: 'Text',
      width: '6%',
      render: (text, record, index) => {
        if (text === 'RADIO_GROUP') {
          return '单选题';
        } else {
          return '多选题';
        }
      },
    },
    status: {
      showText: '状态',
      showType: 'Text',
      align: 'center',
      width: '6%',
      render: (text, record) => {
        if (text === 'ACTIVATE') {
          return '发布';
        }
        return '停用';
      },
    },
    updateTime: {
      showText: '最近更新',
      showType: 'Time',
      align: 'center',
      width: '15%',
    },
  };

  const getListData = async () => {
    setLoading({ ...loading, tableLoading: true });
    const questionRes = await GetQuestion({
      filter: keyword,
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      questionClassifyId: currentClass,
      type: radioValue,
    });
    const questionClassRes = await GetQuestionClass();
    const countRes = await GetCount();
    if (
      questionRes.response &&
      questionRes.response.ok &&
      questionClassRes.response &&
      questionClassRes.response.ok &&
      countRes.response &&
      countRes.response.ok
    ) {
      let showClassList = [];
      questionClassRes.data.items.forEach((item) => {
        countRes.data.data.forEach((countName) => {
          if (countName.name === item.name) {
            item = {
              ...item,
              count: countName.count !== undefined ? countName.count : 0,
            };
            showClassList.push(item);
          }
        });
      });

      const options = showClassList.map((item) => {
        const { count = 0, name, id } = item;
        return {
          label: name + '（' + count + '）',
          value: id,
        };
      });

      // options.push({
      //   label: '未分类' + '（' + 0 + '）',
      //   value: 'noClass',
      // });

      setOptions(options);
      setCurrentListData(questionRes.data.items);
      setClassData(showClassList);
      setPagination({
        ...pagination,
        total: questionRes.data.totalCount || 0,
      });
    } else {
      message.error(questionRes.data.msg, 3);
    }
    setLoading({ ...loading, tableLoading: false });
  };

  useEffect(() => {
    getListData();
  }, [
    pagination.current,
    pagination.pageSize,
    currentClass,
    radioValue,
    keyword,
  ]);

  const modalProps = {
    title: '编辑题目',
    content,
    visible: modelShow.editModel,
    loading: loading.modelLoading,
    onCancel() {
      setQuestionID('');
      setLoading({ ...loading, modelLoading: false });
      setModelShow({
        ...modelShow,
        editModel: false,
      });
    },
    async onOk(value) {
      setLoading({ ...loading, modelLoading: true });
      const { classifies, mark, jsonString } = value;
      if (!isJson(jsonString)) {
        // json题库导入格式错误
        message.error('仅支持json格式的题库');
        return;
      }
      const params = {
        questionClassifyIds: classifies,
        mark,
        jsonString,
        id: questionID,
      };

      let res;
      if (questionID !== '') {
        // 编辑
        res = await PutQuestion(params);
      } else {
        // 添加
        res = await PostQuestion(params);
      }
      if (res.response.ok) {
        getListData();
        setModelShow({
          ...modelShow,
          editModel: false,
        });
        setQuestionID('');
      } else {
        message.error(res.data.msg, 3);
      }
      setLoading({ ...loading, modelLoading: false });
    },
  };

  const addModalProps = {
    title: '新建分类',
    content: addContentMenu,
    visible: modelShow.addModel,
    loading: loading.modelLoading,
    onCancel() {
      setLoading({ ...loading, modelLoading: false });
      setModelShow({
        ...modelShow,
        addModel: false,
      });
    },
    async onOk(value) {
      setLoading({ ...loading, modelLoading: true });
      const { name } = value;
      const params = {
        name,
      };
      const res = await PostAddClass(params);
      if (res.response.ok) {
        getListData();
        setModelShow({
          ...modelShow,
          addModel: false,
        });
      } else {
        message.error(res.data.msg, 3);
      }
      setLoading({ ...loading, modelLoading: false });
    },
  };

  const delModalProps = {
    title: '删除分类',
    content: content,
    visible: modelShow.delModel,
    loading: loading.modelLoading,
    onCancel() {
      setLoading({ ...loading, modelLoading: false });
      setModelShow({
        ...modelShow,
        delModel: false,
      });
    },
    async onOk(value) {
      setLoading({ ...loading, modelLoading: true });
      const { name: id } = value;
      const res = await DelClass(id);
      if (res.response.ok) {
        setModelShow({
          ...modelShow,
          delModel: false,
        });
        getListData();
      } else {
        message.error(res.data.msg, 3);
      }
      setLoading({ ...loading, modelLoading: false });
    },
  };

  const editModalProps = {
    title: '分类重命名',
    content: content,
    visible: modelShow.editClassModel,
    loading: loading.modelLoading,
    onCancel() {
      setLoading({ ...loading, modelLoading: false });
      setModelShow({
        ...modelShow,
        editClassModel: false,
      });
    },
    async onOk(value) {
      setLoading({ ...loading, modelLoading: true });
      const { name, newName } = value;
      const params = {
        id: name,
        name: newName,
      };
      const res = await PutClassName(params);
      if (res.response.ok) {
        getListData();
        setModelShow({
          ...modelShow,
          editClassModel: false,
        });
      } else {
        message.error(res.data.msg, 3);
      }
      setLoading({ ...loading, modelLoading: false });
    },
  };

  const TableProps = {
    listData: currentListData,
    listSupport,
    pagination,
    loading: loading.tableLoading,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          editQuestion('edit', record);
          setModelShow({
            ...modelShow,
            editModel: true,
          });
        },
      },
      {
        showText: '预览',
        onClick(record) {
          let showExpression = null
          const { choices, correctAnswer, title, validators } = JSON.parse(
            record.jsonString,
          );
          if(validators){
              ///暂时找到type是expression的那个
              const expressionItem = validators.find(item=>item.type==='expression')
              if(expressionItem) {
                  const expressionText = expressionItem.text
                  showExpression = Object.prototype.toString.call(expressionText) === "[object Object]" ? expressionText['zh-cn'] || '' : expressionText
              }
          }
          setModelShow({
            ...modelShow,
            questionModel: true,
          });
          setQuestion({
            choices,
            correctAnswer,
            title,
            showExpression
          });
        },
      },
      {
        showText: '切换状态',
        onClick(record) {
          setQuestionID(record.id);
          setSelectItem(record);
          setModelShow({ ...modelShow, statusModel: true });
        },
      },
    ],
  };

  // 数据格式转换
  const listToOptions = (list, showAttributeArr) => {
    return list.map((item) => {
      const Value = showAttributeArr.map((attribute) => `${item[attribute]} `);
      return { Value, Id: item.id.toString() };
    });
  };

  // 编辑添加问题
  const editQuestion = (type = 'add', record = []) => {
    const questionOptions = listToOptions(classData, ['name']);
    let emptyContent = contentMenu;
    emptyContent = addOptionsToProperties(
      emptyContent,
      ['classifies'],
      questionOptions,
    );
    let showForm = emptyContent;
    if (type === 'edit') {
      setQuestionID(record.id);
      record = {
        ...record,
        classifies: record.classifies.map((item) => {
          return item.id;
        }),
      };
      showForm = matchDynamicForm(emptyContent, {
        ...record,
      });
    }
    setContent(showForm);
    setModelShow({ ...modelShow, editModel: true });
  };

  // 转换格式获取答案
  const getTypeData = (data, index) => {
    return Object.prototype.toString.call(data) === '[object Object]' ? (
      (
        <div key={index} className={styles.choicesContent}>
          {data['zh-cn']}
        </div>
      ) || ''
    ) : (
      <div key={index} className={styles.choicesContent}>
        {data}
      </div>
    );
  };

  // 切换状态
  const changStatus = async () => {
    const params = {
      id: questionID,
      status,
    };
    const res = await PostStatus(params);
    if (res.response.ok) {
      getListData();
      setStatus('');
    } else {
      message.error('切换失败', 3);
    }
    setQuestionID('');
    setModelShow({ ...modelShow, statusModel: false });
  };

  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };

  // 答案
  const answerShow =
    question.correctAnswer.length > 0 &&
    typeof question.correctAnswer !== 'string' ? (
      question.correctAnswer.map((item) => {
        {
          return question.choices.map((choice, index) => {
            return (
              <div key={index}>
                {choice.value === item && choice.text
                  ? getTypeData(choice.text, index)
                  : ''}
              </div>
            );
          });
        }
      })
    ) : (
      <div>
        <div>
          {question.choices.map((item, index) => {
            return (
              <div key={index}>
                {item.value === question.correctAnswer && item.text
                  ? getTypeData(item.text, index)
                  : ''}
              </div>
            );
          })}
        </div>
      </div>
    );

  const onClassChange = async (list) => {
    setCurrentClass(list);
  };

  const getClassName = (data) => {
    const questionOptions = listToOptions(classData, ['name']);
    let emptyContent = data;
    emptyContent = addOptionsToProperties(
      emptyContent,
      ['name'],
      questionOptions,
    );
    return emptyContent;
  };

  const onTypeChange = ({ target }) => {
    setRadioValue(target.value);
  };

  return (
    <>
      <Card
        type="info"
        title={
          <Space>
            <div
              onClick={() => setRadioValue(null)}
              style={{ cursor: 'pointer' }}
            >
              所有题目
            </div>
            <Radio.Group onChange={onTypeChange} value={radioValue}>
              <Radio value={'RADIO_GROUP'}>单选题</Radio>
              <Radio value={'CHECKBOX'}>多选题</Radio>
            </Radio.Group>
          </Space>
        }
      >
        <Checkbox.Group options={options} onChange={onClassChange} />
        <span className={styles.options}>
          <Space>
            <Button
              onClick={() => setModelShow({ ...modelShow, addModel: true })}
            >
              新增
            </Button>
            <Button
              onClick={() => {
                let showForm = getClassName(editContentMenu);
                setContent(showForm);
                setModelShow({ ...modelShow, editClassModel: true });
              }}
            >
              重命名
            </Button>
            <Button
              onClick={() => {
                let showForm = getClassName(delContentMenu);
                setContent(showForm);
                setModelShow({ ...modelShow, delModel: true });
              }}
            >
              删除
            </Button>
          </Space>
        </span>
      </Card>
      <p />
      <div>
        <Search
          placeholder="搜索题目"
          className={styles.search}
          onSearch={(value) => {
            setPagination({
              ...pagination,
              current: 1,
              pageSize: 10,
            });
            setKeyword(value);
          }}
        />
      </div>
      <Space>
        <Button type="primary" onClick={editQuestion}>
          JSON导入题目
        </Button>
        <Popover content={<div>此功能本版本暂不开放</div>}>
          <Button type="primary" disabled={true}>
            Excel导入题目
          </Button>
        </Popover>
      </Space>
      <p />
      <Modal
        title="题型预览"
        open={modelShow.questionModel}
        footer={null}
        onCancel={() => setModelShow({ ...modelShow, questionModel: false })}
      >
        <div className={styles.modelContent}>
          <div className={styles.title}>
            题目：
            {question.title['default'] ||
              question.title['zh-cn'] ||
              question.title}
          </div>
          <div className={styles.choices}>
            答案选项：
            {question.choices &&
              question.choices.map((item, index) => {
                return item.text ? getTypeData(item.text, index) : '';
              })}
          </div>
          <div className={styles.answer}>
            答案：
            {answerShow}
          </div>
          <div className={styles.answer}>
            解析：
            {question.showExpression || '无'}
          </div>
        </div>
      </Modal>
      <Modal
        title="切换状态"
        open={modelShow.statusModel}
        onCancel={() => {
          setQuestionID('');
          setModelShow({ ...modelShow, statusModel: false });
        }}
        onOk={() => changStatus()}
      >
        <div>
          状态：
          <Radio.Group
            onChange={onStatusChange}
            value={status !== '' ? status : selectItem.status}
          >
            <Radio value={'ACTIVATE'}>发布</Radio>
            <Radio value={'DEACTIVATE'}>停用</Radio>
          </Radio.Group>
        </div>
      </Modal>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
      <MagicFormModal {...addModalProps} />
      <MagicFormModal {...editModalProps} />
      <MagicFormModal {...delModalProps} />
    </>
  );
};

export default Question;
