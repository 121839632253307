import {
  GetRadar,
  GetRose,
  GetPathLengthData,
  GetLineWithIcon,
  GetOverViewData,
  GetCharacteristicData,
  GetContinuousData,
  GetMovementPathData,
  ReportInfo,
  GetParentStaticQr,
  GetReportVersion,
  PostUpdateParentVersion,
  PostUpdateRecommendationVersion,
  GetParentPDFReport,
  GetScreeningMotionFlag,
  GetScreeningCPTFlag
} from 'services/vrat/chart';
import { message } from 'antd';
import { GetTenantInfoNoPermission } from 'services/tenant';
import { SetLanguage } from 'services/language';
import { setLocale, getLocale } from 'umi';

const LangOptions = {
  en: 'en-US',
  'zh-Hans': 'zh-CN',
  es: 'es-ES'
};

export default {
  namespace: 'parentReport',
  state: {
    tenantInfo: null,
    reportVersion: null,
    caseId: null,
    uiVersion: null,
    basicInfo: null,
    overView: null,
    lineWithIcon: null,
    sixDimension: null,
    continuousAttention: null,
    movementPath: null,
    pathLength: null,
    directionTracking: null,
    motionCharacteristics: null,
    screeningCPTFlags: null,
    screeningMotionFlags: null,
    staticQr: null,
    qrCodeStatus: 'success',

    basicLoading: true,
    overViewLoading: true,
    lineWithIconLoading: true,
    sixDimensionLoading: true,
    continuousAttentionLoading: true,
    movementPathLoading: true,
    pathLengthLoading: true,
    directionTrackingLoading: true,
    motionCharacteristicsLoading: true,
    staticQrLoading: true,
    screeningCPTFlagLoading: true,
    screeningMotionFlagLoading: true,
    interpretationQrLoading: true,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: {
    *fetch({ payload: { caseId, code = null, model = 'base', culture, v, reId, content } }, { call, put }) {
      let uiVersion;
      const isRegeneration = reId === caseId;
      const isScreenReport = model === 'screen';

      if (isRegeneration) {
        const versionRes = yield call(GetReportVersion)

        if (versionRes.response.ok) {
          const { parentVcatReportVersion } = versionRes.data || {};

          yield put({
            type: 'updateState',
            payload: {
              parentVcatReportVersion
            },
          });

          uiVersion = parentVcatReportVersion;
        }
      } else {
        yield put({
          type: 'updateState',
          payload: {
            interpretationQrLoading: false
          },
        });
      }

      if (culture && getLocale() !== LangOptions[culture]) {
        const res = yield call(SetLanguage, { culture });
        if (res.response.ok) {
          setLocale(LangOptions[culture]);
        }
      }

      const basicInfoRes = yield call(ReportInfo, caseId, code);
      const basicInfo = basicInfoRes.response.ok ? basicInfoRes.data : null;
      const reportVersion = v || basicInfo.Case.TargetReportVersion;
      let tenantInfoData;

      if (basicInfo && Object.keys(basicInfo).length > 0) {
        const { TestLocation, TestingCenter } = basicInfo
        const res = yield call(GetTenantInfoNoPermission, TestLocation && TestLocation.TenantId || TestingCenter && TestingCenter.Id,)
        tenantInfoData = res.data;
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: { tenantInfo: tenantInfoData }
          })
        }
      }

      const { vcatqrCode } = tenantInfoData || {};

      yield put({
        type: 'updateState',
        payload: {
          caseId: caseId,
          uiVersion,
          reportVersion,
          basicInfo,
          basicLoading: basicInfoRes.response.ok ? false : true,
        },
      });

      const updateVersionProps = {
        caseId,
        uiVersion
      }

      if (content === 'recommendation') {
        const staticQrRes = yield call(GetParentStaticQr);

        if (staticQrRes.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              staticQr: staticQrRes.data,
              staticQrLoading: false,
            },
          });
        }
      } else {
        const resList = yield [
          call(GetOverViewData, caseId, code, reportVersion),
          call(GetLineWithIcon, caseId, code),
          call(GetRadar, caseId, code, reportVersion),
          call(GetContinuousData, caseId, code, reportVersion),
          call(GetMovementPathData, caseId, code, reportVersion),
          call(GetPathLengthData, caseId, code, reportVersion),
          call(GetRose, caseId, code, reportVersion),
          call(GetCharacteristicData, caseId, code, reportVersion),
          isScreenReport && call(GetScreeningCPTFlag, caseId, code, reportVersion),
          isScreenReport && call(GetScreeningMotionFlag, caseId, code, reportVersion),
        ];

        const overViewRes = resList[0];
        // const overViewRes = yield call(GetOverViewData, caseId, code, reportVersion)
        const overView = overViewRes.response.ok ? overViewRes.data : null;
        yield put({
          type: 'updateState',
          payload: {
            overView,
            overViewLoading: overViewRes.response.ok ? false : true,
          },
        });

        const lineWithIconRes = resList[1];
        // const lineWithIconRes = yield call(GetLineWithIcon, caseId, code)
        const lineWithIcon = lineWithIconRes.response.ok
          ? lineWithIconRes.data
          : null;
        yield put({
          type: 'updateState',
          payload: {
            lineWithIcon,
            lineWithIconLoading: lineWithIconRes.response.ok ? false : true,
          },
        });

        const raderRes = resList[2];
        // const raderRes = yield call(GetRadar, caseId, code, reportVersion)
        const sixDimension = raderRes.response.ok ? raderRes.data : null;
        yield put({
          type: 'updateState',
          payload: {
            sixDimension,
            sixDimensionLoading: raderRes.response.ok ? false : true,
          },
        });

        const continuousRes = resList[3];
        // const continuousRes = yield call(GetContinuousData, caseId, code, reportVersion)
        const continuousAttention = continuousRes.response.ok
          ? continuousRes.data
          : null;
        yield put({
          type: 'updateState',
          payload: {
            continuousAttention,
            continuousAttentionLoading: continuousRes.response.ok ? false : true,
          },
        });

        const movementPathRes = resList[4];
        // const movementPathRes = yield call(GetMovementPathData, caseId, code, reportVersion)
        const movementPath = movementPathRes.response.ok
          ? movementPathRes.data
          : null;
        yield put({
          type: 'updateState',
          payload: {
            movementPath,
            movementPathLoading: movementPathRes.response.ok ? false : true,
          },
        });

        const pathLengthRes = resList[5];
        // const pathLengthRes = yield call(GetPathLengthData, caseId, code, reportVersion)
        const pathLength = pathLengthRes.response.ok ? pathLengthRes.data : null;
        yield put({
          type: 'updateState',
          payload: {
            pathLength,
            pathLengthLoading: pathLengthRes.response.ok ? false : true,
          },
        });

        const roseRes = resList[6];
        // const roseRes = yield call(GetRose, caseId, code, reportVersion)
        const directionTracking = roseRes.response.ok ? roseRes.data : null;
        yield put({
          type: 'updateState',
          payload: {
            directionTracking,
            directionTrackingLoading: roseRes.response.ok ? false : true,
          },
        });

        const characteristicRes = resList[7];
        // const characteristicRes = yield call(GetCharacteristicData, caseId, code, reportVersion)
        const motionCharacteristics = characteristicRes.response.ok
          ? characteristicRes.data
          : null;
        yield put({
          type: 'updateState',
          payload: {
            motionCharacteristics,
            motionCharacteristicsLoading: characteristicRes.response.ok
              ? false
              : true,
          },
        });

        if (isScreenReport) {
          const screeningCPTFlagRes = resList[8];
          const screeningMotionFlagRes = resList[9];

          const screeningCPTFlags = screeningCPTFlagRes.response.ok ? screeningCPTFlagRes.data : null;
          const screeningMotionFlags = screeningMotionFlagRes.response.ok ? screeningMotionFlagRes.data : null;

          yield put({
            type: 'updateState',
            payload: {
              screeningCPTFlags,
              screeningMotionFlags,
              screeningCPTFlagLoading: false,
              screeningMotionFlagLoading: false
            },
          });
        }

        if (isRegeneration) {
          if (!vcatqrCode) {
            yield put({
              type: 'updateState',
              payload: {
                interpretationQrLoading: false,
              },
            });
            return;
          }

          if (resList.filter(item => item).every(item => item.response.ok)) {

            const updateVersionResList = yield [
               call(PostUpdateParentVersion, updateVersionProps),
               call(PostUpdateRecommendationVersion, updateVersionProps)
            ];

            yield put({
              type: 'updateState',
              payload: {
                interpretationQrLoading: false,
              },
            });

            if (!updateVersionResList.every(item => item.response.ok)) {
              yield put({
                type: 'updateState',
                payload: {
                  qrCodeStatus: 'failed',
                },
              });
            }
          }
        }
      }

    },

    *downLoadPdt({ payload: { model = 'base' } }, { call, put, select }) {
      const { caseId, reportVersion, uiVersion } = yield select((state) => state.parentReport);

      const hide = message.loading('Downloading...', 0);
      yield call(GetParentPDFReport, caseId, reportVersion, uiVersion, model);
      setTimeout(hide, 0);
    }
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/report') {
    //       dispatch({ type: 'fetch', payload: location.query })
    //     }
    //   })
    // },
  },
};
