import React from 'react'
import { useIntl } from 'umi'
import { Menu } from 'antd'

const TabList = ({ currentTab, tabList, onChangeTab }) => {

  const intl = useIntl()
  const showTabName = {
    RocketAssessment: intl.formatMessage({ id: 'questionnaireEvaluation' }),
    VratTest: intl.formatMessage({ id: 'vartTest' }),
    OfflinePlan: intl.formatMessage({ id: 'exercise' }),
    Sleep: intl.formatMessage({ id: 'sleep' }),
    Nutrition: intl.formatMessage({ id: 'nutrition' }),
    Exercise: intl.formatMessage({ id: 'motion' }),
    Cbt: intl.formatMessage({ id: 'CBTClass' }),
    ExercisePdt: intl.formatMessage({ id: 'Exercise-PDT' }),
    SleepPdt: intl.formatMessage({ id: 'Sleep-PDT' }),
    NutritionPDT: intl.formatMessage({ id: 'Nutrition-PDT' }),
    Emotion: intl.formatMessage({ id: 'Emotion' }),
    ParentLearning: intl.formatMessage({ id: 'ParentLearning' }),
    ParentEmotion: intl.formatMessage({ id: 'ParentEmotion' }),
  }

  return (
    <Menu onClick={onChangeTab} selectedKeys={[currentTab]} mode="horizontal">
      {tabList && Array.isArray(tabList) && tabList.map(item => {
        return <Menu.Item key={item}>
          {showTabName[item]}
        </Menu.Item>
      })}
    </Menu>
  )
}

export default TabList
