import React from 'react';
import CustomMicroApp from 'components/CustomMicroApp';
import ShowCardBorder from './ShowCardBorder';
import styles from './index.less';

const NutritionPdfAnalysis = ({ week, changeWeek }) => {
  const props = {
    url: '/weeksData',
    appProps: {
      week,
      changeWeek,
    },
  };

  return (
    <div className={styles.NutritionEcharts}>
      <ShowCardBorder>
        <CustomMicroApp {...props} />
      </ShowCardBorder>
    </div>
  );
};

export default NutritionPdfAnalysis;
