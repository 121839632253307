import React from 'react'
import { connect } from 'umi'
import { Spin, List, Avatar, Button, Drawer } from 'antd'
import AdminClockInRecord  from 'components/ClockInRecord';
import moment from 'moment';

const Exercise = ({ adminSubjectExercise, adminClockInRecord, loading, dispatch }) => {

  const { planInfo, pagination: exercisePagination } = adminSubjectExercise;
  if (planInfo == null) return <Spin />
  const isRecordLoading = loading.effects['adminClockInRecord/getRecord'];
  const isExerciseLoding = loading.effects['adminSubjectExercise/loadList'];
  const { exerciseRecord, isShowExerciseRecord, pagination: recordPagination, currentExerciseId } = adminClockInRecord;
  const { items, totalCount } = planInfo

  const getRecord = (id) => {
    const currentId = id || currentExerciseId;
    dispatch({
      type: currentId != currentExerciseId ? 'adminClockInRecord/resetGetRecord' : 'adminClockInRecord/getRecord',
      payload: {
        id: currentId,
        type: 'exercise',
        current: ++recordPagination.exercise.current
      }
    })
  }

  const addMore = () => {
      dispatch({
        type: 'adminSubjectExercise/loadList',
        payload: {
          current: ++exercisePagination.current,
          type: 'load'
        }
      })
  }

  const lookExercisePlan = () => {
    dispatch({
      type : 'adminClockInRecord/updateState',
      payload : {
        isShowExerciseRecord: false
      }
    })
  }

  const propsData = {
    dataSource: exerciseRecord,
    loadData:getRecord,
    isLoading: isRecordLoading
  }

  const currentExercisePlan = items.find(item => item.id == currentExerciseId);

  return (
    <div>
      {items.length === 0 ? '暂无开启训练' : 
        <Spin spinning={isExerciseLoding}>
          <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={item => (
              <List.Item
                actions={[item.start && <a onClick={()=>{getRecord(item.id)}}>查看打卡记录</a>]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar 
                      shape="square" 
                      size={50} 
                      src={item && item.image && item.image.url} 
                    />
                  }
                  title={<b>{item.displayName}</b>}
                  description={ item.start ? `${moment(item.start).format('YYYY/MM/DD')} ~ ${moment(item.end).format('YYYY/MM/DD')}` : '未开启'}
                />
              </List.Item>
            )}
          />
          <div style={{textAlign: 'center', color: 'grey'}}>
                  {
                    totalCount > items.length ?
                      <Button onClick={ () => { addMore() } }>加载更多</Button>
                      :
                      <p>我也是有我的底线的</p>
                  }
          </div>
          <Drawer
              open={isShowExerciseRecord}
              onClose={lookExercisePlan}
              width={700}
              title={<b>{`${currentExercisePlan && currentExercisePlan.displayName}  打卡记录`}</b>}
            >
            <AdminClockInRecord { ...propsData } />
          </Drawer>
        </Spin>
      }
    </div>
  )
}

export default connect(({ adminSubjectExercise, adminClockInRecord, loading }) => ({
  adminSubjectExercise,
  adminClockInRecord,
  loading,
}))(Exercise)
