import React, { useState, useEffect, useRef } from 'react';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import {
  categoryContentMenu,
  // orderContentMenu,
  configContentMenu,
} from '../modalConfig';
import { CopyOutlined } from '@ant-design/icons';
import {
  Card,
  Button,
  message,
  Space,
  Table,
  Divider,
  Image,
  Tooltip,
} from 'antd';
import { deepCopy, copyToBoard } from 'cognitiveleap-core-us/utils/utils';

import {
  GetCategoryAll,
  AddCategory,
  PutCategory,
  DelCategory,
  PutColorCalculate,
  PostColorCalculate,
  PutWebHideCategory,
} from 'services/pdt';

const colorList = ['Green', 'Yellow', 'Red'];

// const toValueList = (color) => {
//   const valueList = [];

//   if (color.length > 0) {
//     color.split(',').forEach((item) => {
//       switch (parseInt(item)) {
//         case 1:
//           valueList.push('one');
//           break;
//         case 2:
//           valueList.push('two');
//           break;
//         case 3:
//           valueList.push('three');
//           break;
//         case 4:
//           valueList.push('four');
//           break;
//         default:
//           break;
//       }
//     });
//   }

//   return valueList;
// };

const getRadioValue = (record, type) => {
  let res = null;
  const { configServings } = record;
  const { servingsColors = [] } = configServings || {};
  servingsColors.forEach((item) => {
    const { color, servings } = item;
    if (servings == 1 && type == 'one') {
      res = colorList[color - 1];
    } else if (servings == 2 && type == 'two') {
      res = colorList[color - 1];
    } else if (servings == 3 && type == 'three') {
      res = colorList[color - 1];
    } else if (servings == 4 && type == 'four') {
      res = colorList[color - 1];
    }
  });

  return res;
};

const Category = () => {
  const currentRecord = useRef(null);

  const [categoryContent, setCategoryContent] = useState(categoryContentMenu);
  // const [orderContent, setOrderContent] = useState(orderContentMenu);
  const [configContent, setConfigContent] = useState(configContentMenu);
  const [listData, setListData] = useState([]);
  const [options, setOptions] = useState({
    type: 'add',
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
  });
  const [visible, setVisible] = useState({
    categoryModal: false,
    orderModal: false,
    configModal: false,
  });

  const disposeData = (data) => {
    return data.map((item) => {
      const { configServings } = item;
      const {
        measurement,
        servingsColors = [],
        matchingFoodGroupName,
      } = configServings || {};

      let red = [],
        yellow = [],
        green = [];
      // 1 green, 2 yellow, 3 red
      servingsColors.forEach((item) => {
        const { color, servings } = item;
        switch (parseInt(color)) {
          case 1:
            green.push(servings);
            break;
          case 2:
            yellow.push(servings);
            break;
          case 3:
            red.push(servings);
            break;
          default:
            break;
        }
      });

      return {
        ...item,
        measurement,
        matchingFoodGroupName,
        red: red.join(', '),
        yellow: yellow.join(', '),
        green: green.join(', '),
        // measurementUrl,
      };
    });
  };

  const getCategoryData = async () => {
    setLoading({
      tableLoading: true,
    });
    const res = await GetCategoryAll();
    if (res.response.ok) {
      setListData(disposeData(res.data.data));
    } else {
      const { msg } = res.data;
      message.error(msg);
    }
    setLoading({
      tableLoading: false,
    });
  };

  useEffect(() => {
    getCategoryData();
  }, []);

  const actionList = async (record, type) => {
    let tempContent;
    currentRecord.current = record;
    const {
      categoryName,
      categoryUrl,
      // showCaffeine,
      parentId,
      order,
      measurement,
      configServings,
      // measurementUrl,
      id,
      isHide,
    } = record;
    const { matchingFoodGroupName } = configServings || {};
    switch (type) {
      case 'edit':
        setOptions({
          type: 'edit',
        });
        tempContent = deepCopy(categoryContentMenu);
        tempContent.Properties.unshift({
          EditorType: 'Input',
          ShowTitle: 'Parent Id',
          FormKey: 'parentId',
          AdditionalData: null,
          Value: null,
          Setting: {
            Required: true,
          },
          Description: null,
        });
        tempContent.Properties[0].Value = parentId;
        tempContent.Properties[1].Value = categoryName;
        tempContent.Properties[2].Value = {
          fileName: categoryUrl.includes('/Picture/')
            ? categoryUrl.split('/Picture/')[1]
            : '',
          url: categoryUrl,
        };
        tempContent.Properties[3].Value = order;
        // tempContent.Properties[4].Value = showCaffeine;
        setCategoryContent(tempContent);
        setVisible({
          ...visible,
          categoryModal: true,
        });
        break;
      // case 'order':
      //   tempContent = deepCopy(orderContentMenu);
      //   tempContent.Properties[0].Value = order;
      //   setOrderContent(tempContent);
      //   setVisible({
      //     ...visible,
      //     orderModal: true,
      //   });
      //   break;
      case 'config':
        tempContent = deepCopy(configContentMenu);
        tempContent.Properties[0].Value = measurement;
        tempContent.Properties[1].Value = matchingFoodGroupName;
        tempContent.Properties[2].Value = getRadioValue(record, 'one');
        tempContent.Properties[3].Value = getRadioValue(record, 'two');
        tempContent.Properties[4].Value = getRadioValue(record, 'three');
        tempContent.Properties[5].Value = getRadioValue(record, 'four');

        setConfigContent(tempContent);

        setVisible({
          ...visible,
          configModal: true,
        });
        break;
      case 'delete':
        const res = await DelCategory({ categoryId: id });
        if (res.response.ok) {
          getCategoryData();
        } else {
          const { msg } = res.data;
          message.error(msg);
        }
        break;
      case 'addSub':
        setOptions({
          type: 'add',
        });
        setVisible({
          ...visible,
          categoryModal: true,
        });
        break;
      case 'Hide':
        const hideRes = await PutWebHideCategory({
          categoryId: id,
          isHide: isHide == 0 ? 1 : 0,
        });
        if (hideRes.response.ok) {
          getCategoryData();
        } else {
          const { msg } = hideRes.data;
          message.error(msg);
        }
      default:
        break;
    }
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      fixed: 'left',
      render: (text) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            copyToBoard(text);
          }}
        >
          {text} <CopyOutlined style={{ color: '#1890ff' }} />
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: 'Picture',
      dataIndex: 'categoryUrl',
      key: 'categoryUrl',
      width: 100,
      render: (text) => {
        return <Image width={40} src={text} />;
      },
    },
    {
      title: 'Unit',
      dataIndex: 'measurement',
      width: 120,
      key: 'measurement',
    },
    {
      title: 'Matching Food group on plate',
      dataIndex: 'matchingFoodGroupName',
      width: 120,
      key: 'matchingFoodGroupName',
    },
    // {
    //   title: 'Unit Pic',
    //   dataIndex: 'measurementUrl',
    //   key: 'measurementUrl',
    //   width: 120,
    //   render: (text) => {
    //     return <Image width={40} src={text} preview={false} />;
    //   },
    // },
    {
      title: 'Red algorithm',
      dataIndex: 'red',
      key: 'red',
    },
    {
      title: 'Yellow algorithm',
      dataIndex: 'yellow',
      key: 'yellow',
    },
    {
      title: 'Green algorithm',
      dataIndex: 'green',
      key: 'green',
    },
    { title: 'Order', dataIndex: 'order', key: 'order', width: 100 },
    {
      title: 'Action',
      dataIndex: 'operation',
      fixed: 'right',
      key: 'action',
      width: 280,
      render: (_, record) => {
        const { isHide } = record || {};
        return (
          <Space size={1}>
            <a onClick={() => actionList(record, 'addSub')}>AddSub</a>
            <Divider type="vertical" />
            <a onClick={() => actionList(record, 'edit')}>Edit</a>
            <Divider type="vertical" />
            {/* <a onClick={() => actionList(record, 'order')}>Order</a> */}
            {/* <Divider type="vertical" /> */}
            <a onClick={() => actionList(record, 'config')}>Config</a>
            <Divider type="vertical" />
            <a onClick={() => actionList(record, 'Hide')}>
              {isHide == 0 ? 'Hide' : 'Show'}
            </a>
          </Space>
        );
      },
    },
  ];

  const modalProps = {
    title: `${options.type === 'add' ? 'Add' : 'Edit'} Category`,
    visible: visible.categoryModal,
    onCancel() {
      setVisible({
        ...visible,
        categoryModal: false,
      });
      setCategoryContent(categoryContentMenu);
    },
    onOk: async (value) => {
      let res;
      const { id = 0, parentId = 0 } = currentRecord.current || {};
      if (options.type === 'add') {
        const params = {
          ...value,
          parentId: id,
        };
        res = await AddCategory(params);
      } else {
        const { parentId: inputId } = value;
        if (inputId == id) {
          message.error('禁止此类操作');
          return;
        }
        const params = {
          ...value,
          id,
        };
        res = await PutCategory(params);
      }
      if (res.response.ok) {
        getCategoryData();
      } else {
        const { msg } = res.data;
        message.error(msg);
      }
      setVisible({
        ...visible,
        categoryModal: false,
      });
      setCategoryContent(categoryContentMenu);
    },
    content: categoryContent,
  };

  // const orderModalProps = {
  //   title: 'Change Order',
  //   visible: visible.orderModal,
  //   onCancel() {
  //     setVisible({
  //       ...visible,
  //       orderModal: false,
  //     });
  //     setOrderContent(orderContentMenu);
  //   },
  //   onOk(value) {
  //     console.log(value);
  //     setVisible({
  //       ...visible,
  //       orderModal: false,
  //     });
  //     setOrderContent(orderContentMenu);
  //   },
  //   content: orderContent,
  // };

  const configProps = {
    title: 'Config',
    visible: visible.configModal,
    onCancel() {
      setVisible({
        ...visible,
        configModal: false,
      });
    },
    onOk: async (value) => {
      let res;
      const { configServings, id } = currentRecord.current;

      const redAlgorithm = [],
        yellowAlgorithm = [],
        greenAlgorithm = [];

      const {
        one,
        two,
        three,
        four,
        matchingFoodGroupOnPlantName,
        measurement,
      } = value;

      [one, two, three, four].forEach((item, index) => {
        const tempIndex = index + 1;
        if (item === 'Red') {
          redAlgorithm.push(tempIndex);
        }
        if (item === 'Green') {
          greenAlgorithm.push(tempIndex);
        }
        if (item === 'Yellow') {
          yellowAlgorithm.push(tempIndex);
        }
      });

      const params = {
        categoryId: id,
        redAlgorithm,
        yellowAlgorithm,
        greenAlgorithm,
        measurement,
        matchingFoodGroupOnPlantName,
      };
      if (configServings) {
        // put
        res = await PutColorCalculate(params);
      } else {
        // post
        res = await PostColorCalculate(params);
      }
      if (res.response.ok) {
        getCategoryData();
      } else {
        const { msg } = res.data;
        message.error(msg);
      }
      setVisible({
        ...visible,
        configModal: false,
      });
    },
    content: configContent,
  };

  const getExpandedRowRender = (record) => {
    const { subCategories } = record;
    return (
      <Table
        columns={columns}
        rowKey={'id'}
        pagination={false}
        scroll={{ x: 1200 }}
        expandable={{
          expandedRowRender: getExpandedRowRender,
          rowExpandable: getRowExpandable,
        }}
        dataSource={disposeData(subCategories)}
      />
    );
  };

  const getRowExpandable = (record) => {
    const { subCategories } = record;
    return subCategories !== null ? true : false;
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>Category</b>}
      bordered={false}
      extra={
        <Button
          type="primary"
          onClick={() => {
            setOptions({
              type: 'add',
            });
            setVisible({
              ...visible,
              categoryModal: true,
            });
            currentRecord.current = null;
          }}
        >
          Add Category
        </Button>
      }
    >
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: getExpandedRowRender,
          rowExpandable: getRowExpandable,
        }}
        loading={loading.tableLoading}
        rowKey={'id'}
        scroll={{ x: 1200 }}
        dataSource={listData}
      />
      <MagicFormModal {...modalProps} />
      {/* <MagicFormModal {...orderModalProps} /> */}
      <MagicFormModal {...configProps} />
    </Card>
  );
};

export default Category;
