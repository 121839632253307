import {
  matchDynamicForm,
  addOptionsToProperties
} from 'cognitiveleap-core-us/utils/utils'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import { getDvaApp } from 'umi'

import {
  GetNewTrainingPlanList,
  PostNewTrainingPlanList,
  PutNewTrainingPlanList,
  PostEditNewTrainingPlan,
  GetnewTrainingPlanCategories,
  PutTrainingPlanV2
} from 'services/rocketService/Prepare'

const { baseURL, uploadOfflineTrainingPlanMedias } = config

const uploadUrl = uploadOfflineTrainingPlanMedias

const listToOptions = (list, showAttributeArr) => {
  return list.map(item => {
    const Value = showAttributeArr.map(attribute => `${item[attribute]} `)
    return { Value, Id: item.id.toString() }
  })
}

const contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '名字',
      FormKey: 'name',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        Rules: [{
          pattern: /[a-zA-Z]/,
          message: '仅英文名字'
        }]
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '训练计划名称',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: null,
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        Required: true,
        DropdownOptions: [
          {
            Value: 'PreSchool',
            Id: 'PreSchool',
          },
          {
            Value: 'GradeOrTeenSchool',
            Id: 'GradeOrTeenSchool',
          },
          {
            Value: 'GoldenAge',
            Id: 'GoldenAge',
          },
        ],
        onChange: async (v) => {
          let data = getDvaApp()._store.getState().adminNewOfflinePlan.content
          const res = await GetnewTrainingPlanCategories({
            ageStage: v
          })

          if (res.response.ok) {
            const options = listToOptions(res.data, ['displayName'])

            data = addOptionsToProperties(
              data,
              ['categories', 'trainingCategoryId'],
              options
            )

            getDvaApp()._store.dispatch({
              type: 'adminNewOfflinePlan/updateState',
              payload: { content: data }
            })
          }
        },
      },
      ShowTitle: '年龄段',
      FormKey: 'ageStage',
      Description: "不可二次修改",
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: true,
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
        Required: true,
      },
      ShowTitle: '封面图',
      FormKey: 'coverPicture',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '每节课的音乐数量',
      FormKey: 'videoCount',
      AdditionalData: null,
      Value: 4,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '音乐时长提醒节点',
      FormKey: 'percent',
      AdditionalData: null,
      Value: 75,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
          max: 100
        },
      },
      Description: '单位%',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '课的数量',
      FormKey: 'programCount',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        NumberSetting: {
          min: 0,
          step: 1,
        },
      },
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          isSingle: false,
        },
        Action: baseURL + uploadUrl,
        Header: getAuthHeader(),
      },
      ShowTitle: '静态教具图片',
      FormKey: 'staticFiles',
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '线下单价(课时)',
      FormKey: 'offlinePrice',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 2,
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: '线上单价(课时)',
      FormKey: 'onlinePrice',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 2,
        },
      },
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: '介绍',
      FormKey: 'introduction',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'RichEditorPro',
      ShowTitle: '训练须知',
      FormKey: 'notice',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'SingleWithSearch',
      Value: null,
      Setting: {
        Required: true,
        DropdownOptions: []
      },
      ShowTitle: '关联计划',
      FormKey: 'oneToManyOnlineTypePlanTemplateId',
      Description: "",
    },
    {
      EditorType: 'ContentSelector',
      Value: null,
      Setting: {
        SelectorOptions: [],
        Required: false,
      },
      ShowTitle: '关联证书',
      Description: '关联后，颁发证书才可正常使用。未关联则可直接使用',
      FormKey: 'qualificationMedalIds',
    },
    {
      EditorType: 'EditableList',
      ShowTitle: 'categories',
      FormKey: 'categories',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: 'trainingCategoryId',
              FormKey: 'trainingCategoryId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必须存在',
              FormKey: 'required',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            }
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '反馈（次课）',
      FormKey: 'courseFeedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: 'extra',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '反馈（训练项目）',
      FormKey: 'feedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: '补充备注',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '反馈（小孩）',
      FormKey: 'planUserFeedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: '补充备注',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
  ],
}

const remarkContentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '备注',
      FormKey: 'description',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
  ],
}

export default {
  namespace: 'adminNewOfflinePlan',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    availableTypes: [],
    avaliableFeedbackProperties: [],
    modalVisible: false,
    content: null,
    modalType: 'add',
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.adminNewOfflinePlan)
      let query = { SkipCount: 0, MaxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        SkipCount: (currentPageIndex - 1) * currentPageSize,
        MaxResultCount: currentPageSize,
      }

      const res = yield call(GetNewTrainingPlanList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onAddOrEdit({ payload: id }, { call, put, select }) {
      const isAdd = id === null ? true : false
      const res = yield call(PostEditNewTrainingPlan, { id })
      if (res.response.ok) {
        const {
          availableFeedbackProperties,
          qualificationMedals,
          trainingPlanV2,
          availableOnlinePlans
        } = res.data
        let emptyContent = contentMenu

        const propertyOptions = listToOptions(availableFeedbackProperties, ['displayName', 'name', 'type'])
        const qualificationOptions = listToOptions(qualificationMedals, ['displayName'])
        const oneToManyOnlineOptions = listToOptions(availableOnlinePlans, ['displayName'])

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['feedbackProperties', 'feedbackPropertyId'],
          propertyOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['courseFeedbackProperties', 'feedbackPropertyId'],
          propertyOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['planUserFeedbackProperties', 'feedbackPropertyId'],
          propertyOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['qualificationMedalIds'],
          qualificationOptions
        )

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['oneToManyOnlineTypePlanTemplateId'],
          oneToManyOnlineOptions
        )

        let showForm = emptyContent

        if (!isAdd) {
          const { feedbackProperties, courseFeedbackProperties, planUserFeedbackProperties, categories, ageStage } = trainingPlanV2

          const res = yield call(GetnewTrainingPlanCategories, { ageStage })

          if (res.response.ok) {
            const categoriesOptions = listToOptions(res.data, ['displayName'])

            emptyContent = addOptionsToProperties(
              emptyContent,
              ['categories', 'trainingCategoryId'],
              categoriesOptions
            )
          }

          const feedbacks = feedbackProperties.map(item => {
            const { extraProperties } = item
            const extra = JSON.stringify(extraProperties)
            return {
              ...item,
              feedbackPropertyId: item.feedbackPropertyId,
              extra,
            }
          })

          const courseFeedbacks = courseFeedbackProperties.map(item => {
            const { extraProperties } = item
            const extra = JSON.stringify(extraProperties)
            return {
              ...item,
              feedbackPropertyId: item.feedbackPropertyId,
              extra,
            }
          })

          const planUserFeedbacks = planUserFeedbackProperties.map(item => {
            const { extraProperties } = item
            const extra = JSON.stringify(extraProperties)
            return {
              ...item,
              feedbackPropertyId: item.feedbackPropertyId,
              extra,
            }
          })

          const cate = categories.map(item => {
            return {
              ...item,
              trainingCategoryId: item.trainingCategoryId,
            }
          })

          showForm.Properties[2].Setting = {
            ...showForm.Properties[2].Setting,
            Disabled: true
          }

          showForm = matchDynamicForm(emptyContent, {
            ...trainingPlanV2,
            feedbackProperties: feedbacks,
            courseFeedbackProperties: courseFeedbacks,
            planUserFeedbackProperties: planUserFeedbacks,
            categories: cate
          })
        }

        yield put({
          type: 'updateState',
          payload: {
            content: showForm,
            modalType: isAdd ? 'add' : 'edit',
            modalVisible: true,
            selectItem: isAdd ? null : trainingPlanV2,
            avaliableFeedbackProperties: availableFeedbackProperties,
          },
        })
      }
    },

    *onEditRemark({ payload }, { call, put, select }) {
      const { description } = payload
      const showForm = matchDynamicForm(remarkContentMenu, { description })
      yield put({
        type: 'updateState',
        payload: {
          content: showForm,
          modalType: 'remark',
          modalVisible: true,
          selectItem: payload,
        },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.adminNewOfflinePlan)
      let res
      if (modalType === 'remark') {
        res = yield call(PutTrainingPlanV2, { id: selectItem.id, ...data })
      } else {
        if (modalType === 'edit') {
          res = yield call(PutNewTrainingPlanList, { ...selectItem, ...data }, selectItem.id)
        } else {
          res = yield call(PostNewTrainingPlanList, data)
        }
      }

      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
}
