import React, { useEffect } from 'react'
import { connect, history } from 'umi'
import { Button, Modal, message, Input } from 'antd'
import { checkFeedbackProperty } from 'utils/utils'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑训练计划',
  add: '新建训练计划',
  remark: '编辑备注'
}

const listSupport = {
  displayName: {
    showText: '显示名称',
    showType: 'Text',
  },
  price: {
    showText: '线下单价(课时)',
    showType: 'Text',
  },
  onlinePrice: {
    showText: '线上单价(课时)',
    showType: 'Text',
  },
  // ageStart: {
  //   showText: '月龄(始)',
  //   showType: 'Text',
  // },
  // ageEnd: {
  //   showText: '月龄(末)',
  //   showType: 'Text',
  // },
  description: {
    showText: '备注',
    showType: 'Remark',
  }
}

const OfflinePlan = ({ adminOfflinePlan, loading, dispatch }) => {
  const tableLoading = loading.effects['adminOfflinePlan/query']

  useEffect(() => {
    dispatch({ type: 'adminOfflinePlan/query' })
  }, [])

  const {
    modalType,
    content,
    modalVisible,
    list,
    pagination,
    availableTypes,
    avaliableFeedbackProperties,
  } = adminOfflinePlan

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'adminOfflinePlan/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      if (modalType === 'remark') {
        dispatch({
          type: 'adminOfflinePlan/onSubmitModal',
          payload: value,
        })
      } else {
        const { feedbackProperties, courseFeedbackProperties, ageStart, ageEnd } = value
        let allReady = true
        allReady = allReady && feedbackProperties.every(item => {
          const { extra, feedbackPropertyId } = item
          const feedbackProperty = avaliableFeedbackProperties.find(propertyItem => {
            return (propertyItem.id === feedbackPropertyId)
          })
          const {type, extraProperties} = feedbackProperty
          return checkFeedbackProperty(type, extra, extraProperties)
        })
        if (!allReady) return

        allReady = allReady && courseFeedbackProperties.every(item => {
          const { extra, feedbackPropertyId } = item
          const feedbackProperty = avaliableFeedbackProperties.find(propertyItem => {
            return (propertyItem.id === feedbackPropertyId)
          })
          const {type, extraProperties} = feedbackProperty
          return checkFeedbackProperty(type, extra, extraProperties)
        })

        if (allReady) {
          const feedbacks = feedbackProperties.map(item => {
            const { extra, } = item
            const extraProperties = JSON.parse(extra)
            return {
              ...item,
              extraProperties,
            }
          })
          const courseFeedbacks = courseFeedbackProperties.map(item => {
            const { extra, } = item
            const extraProperties = JSON.parse(extra)
            return {
              ...item,
              extraProperties,
            }
          })
          dispatch({
            type: 'adminOfflinePlan/onSubmitModal',
            payload: {
              ...value,
              feedbackProperties: feedbacks,
              courseFeedbackProperties: courseFeedbacks,
              agePeriod: { start: ageStart, end: ageEnd },
            },
          })
        }
      }
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    loading: tableLoading,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'adminOfflinePlan/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '中心',
        onClick(record) {
          history.push({
            pathname: '/system/manage/authorCenter',
            query: {
              id: record.id,
              type: 'offlinePlan',
              title: record.title
            },
          })
        }
      },
      {
        showText: '详情',
        onClick(record) {
          history.push({
            pathname: '/system/manage/offlinePlanDetail',
            query: {
              templateId: record.id,
            },
          })
        },
      },
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'adminOfflinePlan/onAddOrEdit', payload: record.id })
        },
      },
      {
        showText: '编辑备注',
        onClick(record) {
          dispatch({ type: 'adminOfflinePlan/onEditRemark', payload: record })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'adminOfflinePlan/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'adminOfflinePlan/onAddOrEdit', payload: null })
  }

  return (
    <div>
      <h1>线下训练计划</h1>
      <Button onClick={onAddClick}>新建线下训练计划</Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ adminOfflinePlan, loading }) => ({ adminOfflinePlan, loading }))(OfflinePlan)
