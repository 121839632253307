import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'umi';
import cogleapLogo1 from 'assets/CL_logo.png';
import errImg from 'assets/errImg.png';
import styles from './index.less';

function Wrong() {
  const intl = useIntl();
  const handleClick = () => {
    return window.location.href = '/';
  };
  return (
    <>
      <div className={styles.title}>
        <img src={cogleapLogo1} alt="" onClick={handleClick}/>
      </div>
      <div className={styles.content}>
        <img src={errImg} />
        <div className={styles.wrong}>
          {intl.formatMessage({ id: 'errTips' })}
        </div>
        <Button className={styles.refresh} onClick={handleClick}>
          {intl.formatMessage({ id: 'Home' })}
        </Button>
      </div>
    </>
  );
}

export default Wrong;
