import React, { useState, useEffect } from 'react';
import { Input, Card, message } from 'antd';

import { useIntl, history, connect } from 'umi';
import DocumentTitle from 'react-document-title';
import ExpandedTable from './ExpandedTable';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import moment from 'moment';

import { GetTrainingList } from 'services/pdt';

const { Search } = Input;

const parentListSupport = (intl, isAdmin, auth) => {
  const { grantedPolicies } = auth || {};
  const haveCreatePdtPermission = grantedPolicies['Pdt.SubjectPdt.Create'];
  const PlanUserPermission = grantedPolicies['RocketSystem.PlanUser'];
  const UserManagementPermission = grantedPolicies['RocketSystem.UserManagement'];

  return {
    name: {
      showText: intl.formatMessage({ id: 'subjectName' }),
      showType: 'Text',
      render: (text, record) => {
        const { id } = record || {};
        return UserManagementPermission || PlanUserPermission ? (
          <a
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: isAdmin ? '/system/manage/subjectDetail' : '/system/center/detail/subjectDetail',
                query: { id },
              });
            }}
          >
            {text}
          </a>
        ) : text;
      },
    },
    trackedDays: {
      showText: intl.formatMessage({ id: 'trackedDays' }),
      showType: 'Text',
    },
    appStartTime: {
      showText: intl.formatMessage({ id: 'programStartDate' }),
      showType: 'Time',
      render: (text) => {
        return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-';
      },
    },
    recordStopTime: {
      showText: intl.formatMessage({ id: 'recordStopTime' }),
      showType: 'Time',
      render: (text) => {
        return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-';
      },
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text, render) => {
        const { totalDay } = render || {};
        return intl.formatMessage({
          id: totalDay >= 7 * 12 ? 'hasFinished' : text,
        });
      },
    },
  };
};

const PDTTraining = ({ currentUser }) => {
  const { adminRoles, current, canChangeList, auth } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: '全部',
  });

  // 搜索数据
  const [searchKey, setSearchKey] = useState({
    Filter: null,
  });

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const initData = async () => {
    setLoading(true);
    const res = await GetTrainingList({
      ...searchKey,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
      TenantId: currentTenant.id,
    });

    if (res.response.ok) {
      setListData(res.data.items);
      setPagination({
        ...pagination,
        total: res.data.totalCount || 0,
      });
    } else {
      const { error } = res.data
      message.error(error.message)
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [searchKey, pagination.current, pagination.pageSize, currentTenant]);

  const tableProps = {
    listData: listData.map((item) => {
      const { startRecords, totalDay = 0 } = item || {};
      const { stopTime } =
        startRecords
          .filter((item) => item.stopTime)
          .sort((a, b) => moment(a.stopTime).diff(b.stopTime) < 0)[0] || {};
      const week = Math.ceil(totalDay / 7);
      const day = totalDay % 7;
      return {
        ...item,
        trackedDays: intl.formatMessage({ id: 'whichWeekDay' }, { week, day }),
        recordStopTime: stopTime,
      };
    }),
    listSupport: parentListSupport(intl, isAdmin, auth),
    loading,
    pagination,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: (record) => <ExpandedTable allData={record} />,
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });

      expandedRowKeys.length > 0 && setExpandedRowKeys([]);
    },
  };

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'focusEDTTraining' })}>
      <Card
        title={
          <b style={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'focusEDTTraining' })}
          </b>
        }
        bordered={false}
        extra={
          isAdmin && (
            <AnalysisTenantSelect
              showAll={true}
              currentTenant={currentTenant}
              onChange={(e) => {
                if (e) {
                  const newCurrentTenant = canChangeList.find(
                    (item) => item.id == e,
                  );
                  setCurrentTenant(newCurrentTenant);
                } else {
                  setCurrentTenant({
                    id: '',
                    displayName: '全部',
                  });
                }
              }}
            />
          )
        }
      >
        <Search
            placeholder={intl.formatMessage({ id: 'subjectName' })}
            style={{ height: 32, width: 220 }}
            onSearch={(e) => {
              setPagination({
                ...pagination,
                current: 1,
              });
              setSearchKey({
                Filter: e,
              });
            }}
            allowClear
        />
        <MagicTable {...tableProps} />
      </Card>
    </DocumentTitle>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  PDTTraining,
);
