import { Tag, Table, Divider } from 'antd'
import { useIntl } from 'umi'

const CourseInfo = ({ num, userOfflineTrainingPlan, iLs, planType, isApp, userOrClassName, trainPlanName }) => {
  const intl = useIntl()
  if (iLs === null) {
    return <div></div>
  }
  const currentNum = userOfflineTrainingPlan !== null ? userOfflineTrainingPlan.currentNum : 0

  const { sessionId, tracks, boneConduction, duration, code } = iLs

  const columns = [
    // {
    //   title: '课程曲目编号',
    //   dataIndex: 'sessionId',
    //   render: (text, row, index) => {
    //     const obj = {
    //       children: text,
    //       props: {},
    //     }

    //     if (index === 0) {
    //       obj.props.rowSpan = tracks.length
    //     } else {
    //       obj.props.rowSpan = 0
    //     }
    //     return obj
    //   },
    // },
    {
      title: intl.formatMessage({ id: 'trackNumber' }),
      dataIndex: 'code',
    },
    {
      title: intl.formatMessage({ id: 'trackNumberChant' }),
      dataIndex: 'withoutChantCode',
    },
    {
      title: intl.formatMessage({ id: 'trackDuration' }),
      dataIndex: 'duration',
    },
    {
      title: intl.formatMessage({ id: 'Volume' }),
      dataIndex: 'boneConduction',
      render: (text, row, index) => {
        const obj = {
          children: planType == "BrainAudio" ? '' : text,
          props: {},
        }

        if (index === 0) {
          obj.props.rowSpan = tracks.length
        } else {
          obj.props.rowSpan = 0
        }

        return obj
      },
    },
  ]

  const showData = tracks.map(item => {
    return {
      sessionId,
      boneConduction,
      ...item,
      code: `${item.courseCode}-${item.code}`
    }
  })

  return (
    <div>
      {(userOrClassName && trainPlanName) && (
        <div style={{ fontSize: 16, color: 'D9000000', fontWeight: 600 }}>
          {userOrClassName} <Divider type="vertical" /> {trainPlanName}
          <p />
        </div>
      )}
      <div>
        {num === currentNum ? <Tag color="#1890FF">{intl.formatMessage({ id: 'currentTime' })}</Tag> : null}
        <span
          style={{ fontSize: 16, color: 'D9000000', fontWeight: 600, marginRight: '10px' }}
        >
          {intl.formatMessage({ id: 'whichSession' }, { num })}
        </span>
        <Tag color={isApp ? "#0DC6AA" : "#1890FF"}>{intl.formatMessage({ id: isApp ? 'online' : 'offline' })}</Tag>
      </div>
      <div style={{ margin: '10px 0', color: 'A6000000' }}>{`${intl.formatMessage({ id: 'courseDuration' })}：${duration}`}</div>
      <Table
        columns={columns}
        dataSource={showData}
        rowKey={'code'}
        bordered
        pagination={false}
        title={() => (
          <span style={{ fontSize: 16, fontWeight: 600, color: 'D9000000' }}>{intl.formatMessage({ id: planType == "BrainAudio" ? 'HFSMusic' : 'ILSMusic' })}</span>
        )}
      />
    </div>
  )
}

export default CourseInfo
