
import { GetLocationList, CreateLocation, GetLocation, DeleteLocation, UpdateLocation } from 'services/location'

export default {
  namespace: 'locationList',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 12,
      total: 0,
    },
    selectItem: null,
    modalVisible: false,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { call, put, take, select }) {
      const { pagination } = yield select(state => state.locationList)
      let query = { skipCount: 0, maxResultCount: 10, }
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }
      const res = yield call(GetLocationList, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: currentPagination } = yield select(state => state.locationList)
      const { pagination } = data
      yield put({
        type: 'updateState',
        payload: { pagination: { ...currentPagination, ...pagination } },
      })
      yield put({ type: 'query' })
    },

    *onAddOrEdit({ payload: id }, { call, put, select }) {
      const isAdd = id===undefined
      if(!isAdd){
        const res = yield call(GetLocation, id)
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: {
              modalVisible: true,
              selectItem: res.data,
            },
          })
        }
      }
      else{
        yield put({
          type: 'updateState',
          payload: {
            modalVisible: true,
            selectItem: null,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeleteLocation, id)
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { selectItem } = yield select(state => state.locationList)
      let res
      if (selectItem !== null) {
        res = yield call(UpdateLocation, selectItem.id, { ...selectItem, ...data })
      } else {
        res = yield call(CreateLocation, data)
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },

  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/manage/location') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
