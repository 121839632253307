import React from 'react';
import { connect } from 'dva';
import { Row, Spin, Col, Badge } from 'antd';
import RadarChart from 'components/VratChartComponents/RadarChart';
import headIcon from 'assets/vratReportImg/head_small_icon.png';
import handIcon from 'assets/vratReportImg/hand_small_icon.png';
import vrClassroomImgCN from 'assets/vratReportImg/VR_classroom_bg_cn.png';
import MovementPathMotionType from 'components/VratChartComponents/MovementPathMotionType';
import MovementPathBlocks from 'components/VratChartComponents/MovementPathBlocks';
import RoseChart from 'components/VratChartComponents/RoseChart';
import { useIntl } from 'umi';
import styles from './index.less';

const TwoPage = ({
  sixDimension,
  sixDimensionLoading,
  totalShow,
  pathLength,
  pathLengthLoading,
  motionCharacteristics,
  motionCharacteristicsLoading,
  overViewLoading,
  directionTracking,
  directionTrackingLoading,
  movementPath,
  movementPathLoading,
}) => {
  const intl = useIntl();

  const DescProps = [
    {
      leftTitleE: intl.formatMessage({
        id: 'report.attentionSkill.chart.attention',
      }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.attention' }),
    },
    {
      leftTitleE: intl.formatMessage({
        id: 'report.attentionSkill.chart.self',
      }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.self' }),
    },
    {
      leftTitleE: intl.formatMessage({
        id: 'report.attentionSkill.chart.reactivity',
      }),
      rightDescE: intl.formatMessage({
        id: 'report.attentionSkill.reactivity',
      }),
    },
    {
      leftTitleE: intl.formatMessage({
        id: 'report.attentionSkill.chart.reactSta',
      }),
      rightDescE: intl.formatMessage({ id: 'report.attentionSkill.reactSta' }),
    },
    {
      leftTitleE: intl.formatMessage({
        id: 'report.attentionSkill.chart.sensitivity',
      }),
      rightDescE: intl.formatMessage({
        id: 'report.attentionSkill.sensitivity',
      }),
    },
    {
      leftTitleE: intl.formatMessage({
        id: 'report.attentionSkill.chart.continuous',
      }),
      rightDescE: intl.formatMessage({
        id: 'report.attentionSkill.continuous',
      }),
    },
  ];

  const renderRightDesc = (DescProps) => {
    var arr = [];
    DescProps.map((item, index) => {
      return arr.push(
        <Row
          type="flex"
          justify="space-around"
          style={{ marginBottom: 5 }}
          key={index}
        >
          <Col span={2} push={1}>
            <Badge status="processing" />
          </Col>
          <Col span={4}>
            <div>
              <p style={{ color: '#333', fontWeight: 700 }}>
                {item.leftTitleE}
              </p>
            </div>
          </Col>
          <Col span={18}>
            <div>
              <p style={{ color: '#333' }}>{item.rightDescE}</p>
            </div>
          </Col>
        </Row>,
      );
    });
    return arr;
  };

  // 注意力和动作分维度分析
  const Attention = sixDimension ? sixDimension.Attention : 0;
  const SelfControl = sixDimension ? sixDimension.SelfControl : 0;
  const Reactivity = sixDimension ? sixDimension.Reactivity : 0;
  const ReactionStability = sixDimension ? sixDimension.ReactionStability : 0;
  const Sensitivity = sixDimension ? sixDimension.Sensitivity : 0;
  const ContinuousAttention = sixDimension
    ? sixDimension.ContinuousAttention
    : 0;

  const PathLength = pathLength ? pathLength.PathLength : [];
  const Summaries = pathLength ? pathLength.Summarys : [];

  const RadarChartProps = {
    data: {
      Attention,
      SelfControl,
      Reactivity,
      ReactionStability,
      Sensitivity,
      ContinuousAttention,
    },
  };

  // 头部路径
  const headPath =
    PathLength.length > 0 &&
    PathLength.filter((item) => {
      return !item.IsMedian && item.MotionType === 0;
    });

  // 手部路径
  const handPath =
    PathLength.length > 0 &&
    PathLength.filter((item) => {
      return !item.IsMedian && item.MotionType === 1;
    });

  // 头部路径中位数
  const headMedian =
    PathLength.length > 0 &&
    PathLength.filter((item) => {
      return item.IsMedian && item.MotionType === 0;
    });
  // 手部路径中位数
  const handMedian =
    PathLength.length > 0 &&
    PathLength.filter((item) => {
      return item.IsMedian && item.MotionType === 1;
    });

  const PercentileToString = (percentile) => {
    if (percentile === undefined) return '';
    else return `${(percentile * 100).toFixed(0)}`;
  };
  // type 0->3 ['Head','Hand','Chest','Leg']
  const FindByType = (list, type) => {
    const result = list.find((item) => {
      return item.MotionType === type;
    });
    return result !== undefined ? result : {};
  };

  const percent0 = PercentileToString(
    1 - (FindByType(Summaries, 0).Percentile || 0),
  );
  const percent1 = PercentileToString(
    1 - (FindByType(Summaries, 1).Percentile || 0),
  );

  const hyperactivityIndex = motionCharacteristics
    ? (100 - motionCharacteristics.Hyperactivity * 100).toFixed(0)
    : 0;
  const areaIndex = motionCharacteristics
    ? (100 - motionCharacteristics.Area * 100).toFixed(0)
    : 0;

  const percent = directionTracking
    ? `${(directionTracking.Percentile * 100).toFixed(0)}`
    : '0';

  //type 0->3 blockNum 1-4
  const GetByType = (list, type, blockNum) => {
    const result = list.filter((item) => {
      return item.MotionType === type;
    });

    const res = result.filter((item) => {
      return item.Block === blockNum;
    });
    return res;
  };

  const Points = movementPath ? movementPath.Points : [];

  // Head block 1~4
  const showPointsHeadBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 0, 1),
          type: 'medicalChn',
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 0, 2),
          type: 'medicalChn',
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 0, 3),
          type: 'medicalChn',
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 0, 4),
          type: 'medicalChn',
        },
      },
    },
    motionIcon: headIcon,
    type: 'medicalChn',
    motionType: intl.formatMessage({ id: 'report.movementPath.chart.head' }),
  };
  // Hand block 1~4
  const showPointsHandBlocksProps = {
    styles,
    data: {
      MotionTypeBlock1: {
        data: {
          Position: GetByType(Points, 1, 1),
          type: 'medicalChn',
        },
      },
      MotionTypeBlock2: {
        data: {
          Position: GetByType(Points, 1, 2),
          type: 'medicalChn',
        },
      },
      MotionTypeBlock3: {
        data: {
          Position: GetByType(Points, 1, 3),
          type: 'medicalChn',
        },
      },
      MotionTypeBlock4: {
        data: {
          Position: GetByType(Points, 1, 4),
          type: 'medicalChn',
        },
      },
    },
    motionIcon: handIcon,
    type: 'medicalChn',
    motionType: intl.formatMessage({ id: 'report.movementPath.chart.hand' }),
  };

  const block1Props = {
    styles,
    number: 1,
  };
  const block2Props = {
    styles,
    number: 2,
  };
  const block3Props = {
    styles,
    number: 3,
  };
  const block4Props = {
    styles,
    number: 4,
  };

  const showData = directionTracking ? directionTracking.Time : [];

  const RoseChartProps = {
    data: showData,
    type: 'medicalChn',
  };

  // 五、测评总结
  const attentionPerformance = totalShow
    ? totalShow.AttentionPerformanceIndex * 100
    : 100;
  const motionPerformance = totalShow
    ? totalShow.MotionPerformanceIndex * 100
    : 100;

  // 总结文字
  const getDes = (type, value) => {
    if (type === 'attention') {
      switch (true) {
        case value > 0 && value <= 33:
          return '在测评中容易被干扰，注意力无法集中在任务上，漏击或错击次数较多。';
        case value > 33 && value <= 67:
          return '在测评中较容易被干扰，注意力无法长时间集中在任务上，较容易出现漏击和错击。';
        case value > 67 && value <= 100:
          return '在测评中能够持续专注在任务上，正确击中次数多，反应速度快。';
        default:
          return '';
      }
    } else if (type === 'motion') {
      switch (true) {
        case value > 0 && value <= 33:
          return '动作多，动作范围幅度大。';
        case value > 33 && value <= 67:
          return '偶尔有动作，动作范围较小。';
        case value > 67 && value <= 100:
          return '动作稳定，基本不动。';
        default:
          return '';
      }
    }
  };

  return (
    <div id="basic" className={`${styles.page_div}`}>
      <Spin
        spinning={
          sixDimensionLoading &&
          motionCharacteristicsLoading &&
          pathLengthLoading &&
          directionTrackingLoading
        }
        className={styles.spinnerWrapperText}
      >
        <div className={styles.headerTextFirst}>三、注意力维度分析</div>
        <Row
          type="flex"
          justify="space-around"
          className={styles.RadarChartPanel}
          style={{ marginTop: 10 }}
        >
          <Col span={6}>
            <div className={styles.chart}>
              <RadarChart {...RadarChartProps} />
            </div>
          </Col>
          <Col span={14}>
            <div className={styles.des}>{renderRightDesc(DescProps)}</div>
          </Col>
        </Row>
      </Spin>
      <Spin
        spinning={movementPathLoading}
        className={styles.spinnerWrapperText}
      >
        <div className={styles.headerTextFirst}>四、动作行为表现</div>
        <div className={styles.scale}>
          <div style={{ marginTop: 60, position: 'relative' }}>
            <span className={styles.movementPathText}>
              {intl.formatMessage({ id: 'report.movementPath.chart.yAxis' })}
            </span>
            <MovementPathMotionType {...showPointsHeadBlocksProps} />
            <MovementPathMotionType {...showPointsHandBlocksProps} />
          </div>
          <Row type="flex" justify="center">
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block1Props} />
            </div>
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block2Props} />
            </div>
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block3Props} />
            </div>
            <div style={{ width: '175px' }}>
              <MovementPathBlocks {...block4Props} />
            </div>
          </Row>
          <h3
            style={{ textAlign: 'center', marginTop: -10 }}
            className={styles.weightFont}
          >
            路径图
          </h3>
          <h3 className={`${styles.brainPic} ${styles.weightFont}`}>
            此图显示了在测评过程中儿童头部和手部的运动轨迹变化
          </h3>
        </div>
        <div className={styles.scaleLook}>
          <div className={styles.scaleLookImg}>
            <img src={vrClassroomImgCN} />
          </div>
          <RoseChart {...RoseChartProps} />
          <h2 className={`${styles.brainPic} ${styles.weightFont}`}>
            {intl.formatMessage({ id: 'report.directionTracking.chart.title' })}
          </h2>
          <h2 className={`${styles.brainPic} ${styles.weightFont}`}>
            此图显示了在测评过程中，儿童在虚拟教室中的头部朝向和停留时长
          </h2>
        </div>
      </Spin>
      <Spin spinning={overViewLoading} className={styles.spinnerWrapperText}>
        <div className={styles.headerTextFive}>五、测评总结</div>
        <div className={styles.tableBody}>
          <table border="1" align="center" valign="middle">
            <tbody>
              <tr align="center" className={styles.trStyle}>
                <td className={styles.tdTitle}>维度</td>
                <td className={styles.tdTitle}>人群百分比</td>
                <td className={styles.tdTitle}>测评表现</td>
              </tr>
              <tr align="center" className={styles.trStyle}>
                <td className={styles.tdTitle}>注意力指数</td>
                <td>{attentionPerformance.toFixed(0)}%</td>
                <td className={styles.testDes}>
                  <div>
                    注意力表现超过{attentionPerformance.toFixed(0)}
                    %的同年龄段、同性别群体。
                  </div>
                  <div>{getDes('attention', attentionPerformance)}</div>
                </td>
              </tr>
              <tr align="center" className={styles.trStyle}>
                <td className={styles.tdTitle}>动作指数</td>
                <td>{motionPerformance.toFixed(0)}%</td>
                <td className={styles.testDes}>
                  <div>
                    动作行为表现超过{motionPerformance.toFixed(0)}
                    %的同年龄段、同性别群体。
                  </div>
                  <div>{getDes('motion', motionPerformance)}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.desList}>
          <span>*报告解读说明：</span>
          <span>1.本测评报告仅反映儿童13分钟测评中的表现，不完全等同于平时表现。</span>
          <span>2.报告结果可能会受多种因素的影响：儿童是否理解任务，环境是否吵闹，VR眼镜是否佩戴舒适等。</span>
          <span>3.报告分数为测评儿童与同性别同年龄段儿童的测评表现对比，数据客观专业。</span>
          <span>4.若对儿童的注意力指数和动作指数有疑问，或想进一步确认儿童的注意力情况，建议咨询医疗机构。</span>
        </div>
      </Spin>
      <div className={styles.writePanle}>
        <div>测评人员签名：</div>
        <div>日期：</div>
      </div>
      {/* <span className={styles.page_number}>2</span> */}
    </div>
  );
};

export default connect()(TwoPage);
