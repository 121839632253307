import { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Button, message, Input, Space, Modal } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import TagModal from './components/TagModal';

import { GetTagList, DeleteTag } from 'services/adhd';

const { Search } = Input;
const { confirm } = Modal;

const listSupport = {
  name: {
    showText: 'name',
    showType: 'Text',
  },
  displayName: {
    showText: 'displayName',
    showType: 'Text',
  },
  tagType: {
    showText: 'type',
    showType: 'Text',
  },
  description: {
    showText: 'description',
    showType: 'Text',
  },
};

const Tag = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState({
    tableLoading: false,
    modalLoading: false,
  });
  const [Filter, setFilter] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [currentItem, setCurrentItem] = useState({});
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetTagList({
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
      Filter,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current, Filter]);

  const operate = (type, record) => {
    if (type !== modalType) setModalType(type);
    if (type === 'edit') setCurrentItem(record);
    setModalVisible(true);
  };

  const tableProps = {
    listData: dataSource,
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: 'Edit',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                operate('edit', record);
              }}
            >
              Edit
            </a>
          );
        },
      },
      {
        showText: 'Delete',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                confirm({
                  title: 'Are you sure to delete it?',
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <span style={{ color: 'red' }}>
                      It cannot be recovered after deletion.
                    </span>
                  ),
                  onOk: async () => {
                    const { id } = record || {};
                    const res = await DeleteTag({ id });
                    if (res.response.ok) {
                      initData();
                      message.success('Delete successfully');
                    } else {
                      const { error } = res.data;
                      message.error(error.message);
                    }
                  },
                });
              }}
            >
              Delete
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    modalVisible,
    modalType,
    currentItem,
    onClose: () => {
      setModalVisible(false);
      setCurrentItem({});
    },
    reloadTable: initData,
  };

  return (
    <Card title={<b style={{ fontSize: '20px' }}>Tag</b>} bordered={false}>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <div>Keywords</div>
          <Search
            placeholder="Name"
            allowClear
            onSearch={(value) => setFilter(value)}
            style={{ width: 200 }}
          />
        </Space>
        <Button type="primary" onClick={() => operate('add')}>
          Add Tag
        </Button>
      </Space>

      <p />
      <MagicTable {...tableProps} />
      {modalVisible && <TagModal {...modalProps} />}
    </Card>
  );
};

export default Tag;
