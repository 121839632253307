import React, { useEffect } from 'react'
import { history, connect } from 'umi';
import { Button } from 'antd'
import { IntlManager } from 'utils/helper'
import HfsOrderInfo from 'components/HfsOrderInfo'
import styles from './index.less'


const HfsOrderDetail = ({ hfsOrderDetail, loading }) => {
    const intl = IntlManager.MyIntl()

    useEffect(() => {
        dispatch({ type: 'hfsOrderDetail/query' })
    }, [])

    return (
        <div className={styles.orderDetail}>
            <div className={styles.orderDetailTop}>
                <Button onClick={()=> history.goBack()}>{intl('back')}</Button>
                <span className={styles.orderDetailTopDes}>{intl('orderDetails')}</span>
            </div>
            <HfsOrderInfo />
        </div> 
    )
}

export default connect(({ hfsOrderDetail, loading }) => ({ hfsOrderDetail, loading }))(HfsOrderDetail)