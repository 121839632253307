import { useState, useEffect } from 'react';
import { Card, Button, message, Popconfirm, Input, Space } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import TemplateModal from './components/TemplateModal';
import { history } from 'umi';

import { GetTemplate, PostTemplateStatus } from 'services/adhd';

const { Search } = Input;

const listSupport = {
  name: {
    showText: 'name',
    showType: 'Text',
  },
  displayName: {
    showText: 'displayName',
    showType: 'Text',
  },
  type: {
    showText: 'type',
    showType: 'Text',
    render: (text) => {
      switch (text) {
        case 'CoreSession':
          return 'Core Session';
        default:
          return text;
      }
    },
  },
  disorders: {
    showText: 'disorder',
    showType: 'Text',
    render: (text) => text?.join(', '),
  },
  status: {
    showText: 'status',
    showType: 'Text',
  },
};

const Template = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState({
    tableLoading: false,
    modalLoading: false,
  });
  const [Filter, setFilter] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('add');
  const [currentItem, setCurrentItem] = useState({});
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetTemplate({
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
      Filter,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current, Filter]);

  const changeStatus = async (record, action) => {
    const { id } = record || {};
    const res = await PostTemplateStatus({
      id,
      action,
    });

    if (res.response.ok) {
      message.success('success');
      initData();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }
  };

  const operate = (type, record) => {
    if (type !== modalType) setModalType(type);
    if (type === 'edit') setCurrentItem(record);
    setModalVisible(true);
  };

  const tableProps = {
    listData: dataSource,
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: 'Weeks',
        itemRender(record) {
          const { displayName, id } = record || {};
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: '/system/manage/templateWeeks',
                  query: {
                    title: displayName,
                    id,
                  },
                });
              }}
            >
              Weeks
            </a>
          );
        },
      },
      {
        showText: 'Edit',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                operate('edit', record);
              }}
            >
              Edit
            </a>
          );
        },
      },
      {
        showText: 'Hide',
        itemRender(record) {
          const { status } = record || {};
          const showStatusText = status === 'Public' ? 'Hide' : 'Public';
          return (
            <Popconfirm
              title={`Are you sure to ${showStatusText.toLocaleLowerCase()} it?`}
              onConfirm={() => changeStatus(record, showStatusText)}
            >
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {showStatusText}
              </a>
            </Popconfirm>
          );
        },
      },
      {
        showText: 'c',
        itemRender(record) {
          const { displayName, id } = record || {};
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: '/system/manage/templateCenters',
                  query: {
                    title: displayName,
                    id,
                  },
                });
              }}
            >
              Centers
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    modalVisible,
    modalType,
    currentItem,
    onClose: () => {
      setModalVisible(false);
    },
    reloadTable: initData,
  };

  return (
    <Card title={<b style={{ fontSize: '20px' }}>Template</b>} bordered={false}>
      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <div>Keywords</div>
          <Search
            placeholder="Name"
            allowClear
            onSearch={(value) => setFilter(value)}
            style={{ width: 200 }}
          />
        </Space>
        <Button type="primary" onClick={() => operate('add')}>
          Add Template
        </Button>
      </Space>

      <p />
      <MagicTable {...tableProps} />
      {modalVisible && <TemplateModal {...modalProps} />}
    </Card>
  );
};

export default Template;
