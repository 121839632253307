import React from 'react'
import { useIntl } from 'umi';
import { Row, Col, Card, Divider } from 'antd'
// import styles from './Report.less'
import styles2 from './Report_2.less'
// import CardContent from './CardContent'

const BaseInfoCard = ({ cardName, info, cardColor, icon }) => {
  const intl = useIntl()

  const hideNullContentList = [
    intl.formatMessage({ id: 'report.subjectCode' }),
  ]

  const CardContent = ({ contentLeft, contentRight }) => {
    if (!contentRight && hideNullContentList.includes(contentLeft)) {
      return <></>
    }

    return (
      <div>
        <Row type="flex" justify="space-around">
          <Col span={11} style={{ fontSize: 20, color: '#333', fontWeight: 700 }}>
            {contentLeft}:
          </Col>
          <Col span={13} style={{ fontSize: 20, color: '#333', fontWeight: 500 }}>
            {contentRight}
          </Col>
        </Row>
        <Divider className={styles2.divider} />
      </div>
    )
  }

  const renderCardContent = props => {
    var arr = []
    for (var key in props) {
      arr.push(<CardContent contentLeft={key} contentRight={props[key]} key={key}></CardContent>)
    }
    return arr
  }

  return (
    <Card
      className={styles2.userCard}
      style={{ width: '90%', backgroundColor: cardColor, margin: 'auto' }}
      bodyStyle={{ backgroundColor: 'white' }}
      title={
        <div style={{ backgroundColor: cardColor }}>
          <img src={icon} alt={icon} />
          <span style={{ fontSize: 22, color: '#333', fontWeight: 700, paddingLeft: 10 }}>
            {cardName}:
          </span>
        </div>
      }
    >
      {renderCardContent(info)}
    </Card>
  )
}
export default BaseInfoCard
