import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import RecommendationsLine from './components/RecommendationsLine';
import ContentLine from './components/ContentLine';
import BottomPageNum from './components/BottomPageNum';

const showList = [
  {
    content:
      'Set clear and consistent rules: This can help your child understand boundaries and what behaviors are expected of them. Be firm and consistent in enforcing these rules.',
  },
  {
    content:
      'Practice problem-solving skills: Help your child learn how to stop and think before they act impulsively. Teach them strategies such as taking deep breaths, counting to 10, or walking away from a situation to calm down.',
  },
  {
    content:
      'Provide a consistent and predictable environment: Children with impulsivity may be more reactive to changes in their environment or routine. Try to create a consistent and predictable environment at home and at school.',
  },
  {
    content:
      'Use positive reinforcement: Encourage and reward good behavior, particularly when your child demonstrates restraint or thoughtfulness before acting impulsively.',
  },
];

const RecommendationTwo = () => {
  const lineProps = {
    title: 'Impulsivity',
    content:
      'Children with impulsivity may struggle with impulse control, often acting without thinking about the consequences. Here are some tips to help your child:',
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.recommendationTwo}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>Recommendations</div>
          <RecommendationsLine {...lineProps} />
          <div style={{ marginBottom: '40px' }}></div>
          {showList.map((item, index) => {
            const { content } = item || {};
            return <ContentLine key={index} content={content} />;
          })}
        </div>
        <BottomPageNum num={2} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default RecommendationTwo;
