import React, { useEffect } from 'react'
import { connect } from 'umi'
import styles from './index.less'
import { Table, Button, Badge, DatePicker, Drawer } from 'antd'
import { history } from 'umi';
import moment from 'moment';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { getTimeSlot, newConnectOneweek, getWeekday, move, eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import { LeftOutlined, RightOutlined, CheckOutlined } from '@ant-design/icons';
import iconDetailPng from 'assets/icon_detail.png'
import iconClosedPng from 'assets/icon_closed.png'
import iconTeacherPng from 'assets/icon_teacher.png'
import iconKidPng from 'assets/icon_kid.png'
import iconCenterPng from 'assets/icon_center.png'
import iconTime2Png from 'assets/icon_time2.png'
import iconRepetitionPng from 'assets/icon_repetition.png'
import iconCoursePng from 'assets/icon_course.png'
import iconRepetition3Png from 'assets/icon_repetition3.jpg'


const planTypeEnum = {
	"1": '学前',
	"2": "学龄",
	"3": "青少年",
	"4": "成年"
}

// 当前的时间区间 {startTime: 8:00, endTime: 8:30}
const timeSlot = (selectDate) => getTimeSlot(8, 21, 30, selectDate)

const getRepeatDescription = (periodIntervalUnit, periodIntervalNumber, startTime, endTime) => {
	if (periodIntervalUnit === "Day" && periodIntervalNumber === 1) {
		// return `每天${moment(startTime).format("HH:mm")} - ${moment(endTime).format("HH:mm")}重复`
		return "每天"
	}
	if (periodIntervalUnit === "Day" && periodIntervalNumber === 7) {
		return `每周的周${getWeekday(moment(startTime).day())}重复`
	}
	if (periodIntervalUnit === "Day" && periodIntervalNumber === 14) {
		return `每两周的周${getWeekday(moment(startTime).day())}重复`
	}
	if (periodIntervalUnit === "Month" && periodIntervalNumber === 1) {
		return `每月的${moment(startTime).format("DD")}号重复`
	}
	return ""
}

const oneselfListSupport = {
	displayName: {
		showText: '中心',
		showType: 'Text'
	},
	costNum: {
		showText: '耗课数',
		showType: 'Text'
	}
}

const myTag = (type) => {
	let context = type === "Online" ? "线上" : "线下"
	let backgroundColor = type === "Online" ? "#55B3B7" : "#3C84FF"
	return (
		<div
			className={styles.myTag}
			style={{ backgroundColor }}
		>
			{context}
		</div>
	)
}

let timer = null

/**
 * 8:00~8:30   1
 * 
 */
const ClassCardDetail = ({ dispatch, classCardAdmin, location }) => {

	/**
	 * 给id为classCardHoverInfo添加拖拽效果
	 * 修改当前currentDate
	 */
	useEffect(() => {
		dispatch({ type: 'classCardAdmin/updateState', payload: { content: null, coachIds: location.query.id } })
        dispatch({ type: "classCardAdmin/query" })

		move('classCardHoverInfo')
		timer = setInterval(() => {
			// 刷新data
			dispatch({
				type: 'classCardAdmin/updateStateData',
			})
		}, 30 * 1000)
		return () => clearInterval(timer)
	}, [])

	const addItemToColumnData = (item, columnData) => {
		if (columnData.length > 0) {
			//如果能直接放入原来的数据里就直接放进去
			const isSuccessPush = columnData.some(columnDataItem => {
				//如果新数据跟每一个都可以不重叠，则直接添加进去
				if (columnDataItem.every(calendarItem => {
					return moment(calendarItem.startTime).isSameOrAfter(item.endTime) || moment(calendarItem.endTime).isSameOrBefore(item.startTime)
				})) {
					columnDataItem.push(item)
					return true
				}
			})
			if (!isSuccessPush) {
				columnData.push([])
				columnData[columnData.length - 1].push(item)
			}
		}
		else {
			columnData.push([])
			columnData[0].push(item)
		}
	}

	const getHours = (time) => {
		return moment(time).hour()
	}

	const getMinutes = (time) => {
		return moment(time).minute()
	}

	const filterData = (startTime, endTime, columnList) => {
		const findData = columnList.find(item => {

			const isSameOrBefore = getHours(item.startTime) < getHours(startTime) ||
				(getHours(item.startTime) === getHours(startTime) && getMinutes(item.startTime) <= getMinutes(startTime))

			const isSameOrAfter = getHours(item.endTime) > getHours(endTime) ||
				(getHours(item.endTime) === getHours(endTime) && getMinutes(item.endTime) >= getMinutes(endTime))
			return isSameOrBefore && isSameOrAfter

		})
		if (findData !== undefined) {
			const totalTimeSpan = moment(findData.endTime).diff(moment(findData.startTime), 'minutes')
			const unit = moment(endTime).diff(moment(startTime), 'minutes')
			const beforeTimeSpan = getHours(startTime) * 60 + getMinutes(startTime) - getHours(findData.startTime) * 60 - getMinutes(findData.startTime)

			return {
				...findData,
				cardIndex: beforeTimeSpan / unit,
				total: totalTimeSpan / unit,
			}
		}
	}

	const filterRowData = (startTime, endTime, columnAllData) => {
		let result = {}
		Object.keys(columnAllData).forEach(keyItem => {
			result[keyItem] = columnAllData[keyItem].map(columnItemList => {
				return filterData(startTime, endTime, columnItemList)
			})
		})
		return result
	}

	const {
		currentDate,
		startDate,
		endDate,
		isShowHoverInfo,
		top,
		left,
		hoverDataInfo,
		showDrawer,
		type,
		selfChangeDate,
		centerChangeDate,
		data,
		oneselfCourseNumData,
		currentTrainerArr,
		isShowPreData
	} = classCardAdmin || {}

	const {
		calendarCount,
		calendarCourseType,
		tenantInfo,
		startTime,
		endTime,
		periodIntervalUnit,
		periodIntervalNumber,
		students,
		currentItemStartTime,
		executor,
		isRealItem,
		trainingCourseNum,
		trainingType,
		userTrainingPlanId
	} = hoverDataInfo || {}

	// 本人的总耗课数
	const oneselfCourseNum = oneselfCourseNumData ? oneselfCourseNumData.reduce((result, next) => result + next.costNum, 0) : 0

	const totalCourseNum = oneselfCourseNum

	const oneweekDate = newConnectOneweek(startDate, endDate)

	/**
	 * e.clientY + document.documentElement.scrollTop 这是当前点击位置的高度
	 */
	const showHoverInfo = (e, item, text, row, index, col) => {
		let top = e.clientY + document.documentElement.scrollTop - 100
		let left = e.clientX
		if (left + 360 > document.body.clientWidth) {
			left = document.body.clientWidth - 530
		}
		e.stopPropagation()

		dispatch({
			type: "classCardAdmin/getPlanDetailInfo",
			payload: {
				top: `${top}px`,
				left: `${left}px`,
				dataInfo: { item, text, row, index, col },
				selectCalendar: item
			}
		})
	}

	const getWidthTimes = (item, conflictInfo, index, totalCount) => {
		const { userPlanId, currentIndex, trainingCourseNum, userTrainingPlanId, isRealItem } = item
		const useId = isRealItem ? userTrainingPlanId + trainingCourseNum : userPlanId
		if (conflictInfo.hasOwnProperty(useId + currentIndex)) {
			const info = conflictInfo[useId + currentIndex]
			let times = 1
			let i = index
			while (!info.includes(i + 1) && i < totalCount - 1) {
				times++
				i++
			}
			return times
		}
		else {
			return totalCount - index
		}
	}

	const getShowText = (text, conflictInfo) => {
		const totalCount = text.length
		let result = []
		let times = 0
		let isBeforeData = true
		text.forEach((item, index) => {
			if (item === undefined) {
				if (isBeforeData) {
					result.push(item)
				}
				else {
					if (times > 0) {
						times--
					}
					else {
						result.push(item)
					}
				}
			}
			else {
				const number = getWidthTimes(item, conflictInfo, index, totalCount)
				const width = (type === 'center') && currentTrainerArr && currentTrainerArr.length > 7 ? `${200 * (number / (totalCount || 1)) - 6}px` : `calc(${number / (totalCount || 1) * 100}% - 6px)`
				times = number - 1
				isBeforeData = false
				result.push({
					...item,
					itemWidth: width
				})
			}
		})
		return result
	}

	const render = (text, row, index, col, conflictInfo) => {
		const { startTime, endTime } = row
		const isCurrentDate = moment(col).isSame(currentDate, 'd');
		const isInCurrentCard = isCurrentDate && moment(startTime).isBefore(currentDate) && moment(endTime).isAfter(currentDate)
		const percent = isInCurrentCard && currentDate.diff(moment(startTime)) / moment(endTime).diff(moment(startTime))
		const lineHeight = `${percent * 43}px`
		const initCount = text.length
		const lineWidth = '100%'
		const width = `calc(${1 / (initCount || 1) * 100}% - 6px)`
		const line = percent ? (
			<div style={{ width: lineWidth, height: '1px', background: '#3C84FF', position: 'absolute', top: lineHeight, left: 0, zIndex: 1 }}>
				<div style={{ width: '7px', height: '7px', borderRadius: '50%', background: '#3C84FF', position: 'absolute', left: -3, bottom: -3 }}></div>
			</div>
		) : null

		//先去掉中间的一些undefined
		let showText = getShowText(text, conflictInfo)

		//如果text里包含有数据，则操作一波去掉后面的全部undefined
		if (showText.some(item => item !== undefined)) {
			//判断原数据后面有多少个undefined
			showText.reverse()
			const undefinedCount = showText.findIndex(item => item !== undefined)
			showText = showText.slice(undefinedCount)
			showText.reverse()
		}
		const isPointer = moment(`${col} ${moment(startTime).format("HH:mm")}`).isAfter(currentDate)

		return (
			<div
				style={{ cursor: isPointer ? 'pointer' : '' }}
				className={styles.row}
			>
				{
					initCount > 0 ? showText.map((item, dataIndex) => {
						const { tenantInfo, students, calendarCourseType, startTime, isRealItem, itemWidth, cardIndex, total, trainingCourseNum, trainingType, totalCount } = item || {}
						if (item !== undefined) {
							const endTime = moment(startTime).add(1, 'hours')
							const backgroundColor = isRealItem ? '#F7F7F7' : (moment(endTime).isBefore(currentDate) ? '#F7F7F7' : '#E6F7FF')
							const color = moment(endTime).isBefore(currentDate) ? 'rgba(0, 0, 0, 0.45)' : 'rgba(0, 0, 0, 0.85)'
							return (
								<>
									<div
										key={dataIndex}
										onClick={(e) => showHoverInfo(e, item, text, row, index, col)}
										className={styles.rowItem}
										style={{
											background: backgroundColor,
											width: itemWidth,
											position: 'relative',
											cursor: 'pointer',
											color,
										}}
									>
										<div
											style={{
												background: backgroundColor,
												width: "100%"
											}}
											className={styles.rowItemContent}
										>
											{
												totalCount !== 0 && item.cardIndex === 0 &&
												<img src={iconRepetition3Png} style={{ width: '12px', position: 'absolute', right: '4px', top: '4px' }} />
											}
											{
												item.cardIndex === 0 && students && students.length > 0 &&
												<div className={styles.rowItemContentTop}>
													<div
														className={styles.rowItemContentTopName}
													>
														{students.map(item => item && item.name).join(",")}
													</div>
													<div className={styles.rowItemContentTopCenterName}>
														{tenantInfo && tenantInfo.displayName}
													</div>
												</div>
											}
											{
												item.cardIndex === 1 &&
												<div className={styles.rowItemContentBottom}>
													<div className={styles.rowItemContentBottomRight}>
														{
															calendarCourseType &&
															<div>
																{
																	calendarCourseType === "Online" ?
																		<>{myTag("Online")}</> :
																		<>{myTag("Offline")}</>
																}
															</div>
														}
														{
															isRealItem &&
															<span className={styles.rowItemContentBottomRightDes}>{planTypeEnum[trainingType] || ""}  第{trainingCourseNum}次课</span>
														}
													</div>
												</div>
											}
										</div>
									</div>
								</>
							)
						} else {
							return (
								<div
									style={{ width, position: 'relative', marginRight: '6px' }}
								>
								</div>
							)
						}
					}) : (
						<div
							style={{ width, position: 'relative', marginRight: '6px' }}
						>
						</div>
					)
				}
				{line}
			</div>
		)
	}

	const getColumnKeys = () => {
		return oneweekDate
	}

	const getColumns = (conflictInfo) => {
		let columns = []
		columns = oneweekDate.map((item, index) => {
			const isCurrentDate = moment(currentDate).format('YYYY/MM/DD') === moment(item).format('YYYY/MM/DD')
			return {
				title: (
					<div className={styles.tableHead}>
						<div className={`${styles.tableHeadTitle} ${isCurrentDate && styles.currentTableHeadTitle}`}>
							{`周${getWeekday(moment(item).day())}`}
						</div>
						<div className={`${styles.tableHeadDay} ${isCurrentDate && styles.currentTableHeadDay}`}>
							{moment(item).format('DD')}
						</div>
					</div>
				),
				dataIndex: item,
				key: item,
				width: `${1 / (oneweekDate.length) * 100}%`,
				render: (text, row, index) => render(text, row, index, item, conflictInfo)
			}
		})
		return columns
	}

	const getConflictInfo = (data, keys) => {
		let result = {}
		data.forEach(dataItem => {
			keys.forEach(keyItem => {
				const unitList = dataItem[keyItem].filter(item => item !== undefined)
				if (unitList.length > 1) {
					const allNotUndefined = getListNotUndefinedIndexList(dataItem[keyItem])
					dataItem[keyItem].forEach((item, index) => {
						if (item !== undefined) {
							const { userPlanId, currentIndex, trainingCourseNum, userTrainingPlanId, isRealItem } = item
							let useId = isRealItem ? userTrainingPlanId + trainingCourseNum : userPlanId

							if (result.hasOwnProperty(useId + currentIndex)) {
								result[useId + currentIndex] = [...new Set(result[useId + currentIndex].concat(getNotIncludeData(allNotUndefined, index)))]
							}
							else {
								result[useId + currentIndex] = getNotIncludeData(allNotUndefined, index)
							}
						}
					})
				}
			})
		})
		return result
	}

	const getNotIncludeData = (indexList, notIncludeIndex) => {
		let result = []
		indexList.forEach(item => {
			if (item !== notIncludeIndex) result.push(item)
		})
		return result
	}

	const getListNotUndefinedIndexList = (list) => {
		let result = []
		list.forEach((item, index) => {
			if (item !== undefined) {
				result.push(index)
			}
		})
		return result
	}

	const getDataSource = () => {
		let columnFilteredObj = {}

		const backendData = data.map(item => {
			const { startTime, endTime, isRealItem } = item || {}
			const realEndTime = isRealItem ? moment(startTime).add(1, 'hours').local().format() : moment(endTime).local().format()
			return {
				...item,
				backendStartTime: startTime,
				backendEndTime: endTime,
				startTime: moment(startTime).local().format(),
				endTime: realEndTime
			}
		})


		oneweekDate.forEach(oneweekDateelement => {
			const filteredData = backendData.filter(backendDataitem => moment(backendDataitem.startTime).isSame(oneweekDateelement, 'day'))
			let columnData = []
			if (filteredData.length > 0)
				filteredData.forEach(filteredDataitem => {
					addItemToColumnData(filteredDataitem, columnData)
				})
			columnFilteredObj[oneweekDateelement] = columnData
		})


		const showData = timeSlot(centerChangeDate).map(timeSlotItem => {
			return {
				...timeSlotItem,
				...filterRowData(timeSlotItem.startTime, timeSlotItem.endTime, columnFilteredObj)
			}
		})
		return showData
	}

	const TableProps = {
		listData:
			(
				oneselfCourseNumData ? oneselfCourseNumData.map(item => {
					return {
						...item,
						displayName: item.tenantDto && item.tenantDto.displayName,
						id: item.tenantDto && item.tenantDto.id
					}
				}) : []
			),
		listSupport: oneselfListSupport
	}

	const cardIsEnded = isRealItem ? true : moment(currentItemStartTime).add(1, 'hours').isBefore(currentDate)

	const showData = getDataSource()
	const conflictInfo = getConflictInfo(showData, getColumnKeys())
	const columns = getColumns(conflictInfo)

	return (
		<div
			className={styles.classCard}
			onClick={() => dispatch({ type: 'classCardAdmin/updateState', payload: { isShowHoverInfo: false } })}
		>
			<div className={styles.classCardHeader}>
				<div className={styles.classCardHeaderTop}>
					<div className={styles.classCardHeaderLeft}>
						<Button onClick={() => history.goBack()}>返回</Button>
						<span className={styles.classCardHeaderLeftTitle}>课程表</span>
					</div>
				</div>
			</div>
			{
				<>
					<div className={styles.classCardHks}>
						<div className={styles.classCardHksContent}>
							<div className={styles.classCardHksContentLeft}>总耗课数 {totalCourseNum}</div>
							<div className={styles.classCardHksContentRight} onClick={() => dispatch({ type: 'classCardAdmin/showCourses' })}>详情</div>
						</div>
					</div>
					<div className={styles.classCardContent}>
						<div className={styles.classCardContentTop}>
							<div className={styles.classCardContentTopLeft}>
								<div className={styles.classCardContentTopItem}>
									<Button
										disabled={
											moment(selfChangeDate).format('YYYY/MM/DD') === moment(currentDate).format('YYYY/MM/DD')
										}
										onClick={() => dispatch({ type: 'classCardAdmin/changeDateToToday' })}
									>
										今天
									</Button>
								</div>
								<div className={styles.classCardContentTopItem}>
									<LeftOutlined
										onClick={() => dispatch({ type: "classCardAdmin/transDate", payload: 'subtract' })}
									/>
								</div>
								<div className={styles.classCardContentTopItem}>
									<RightOutlined
										onClick={() => dispatch({ type: "classCardAdmin/transDate", payload: 'add' })}
									/>
								</div>
								<div className={styles.classCardContentTopItem}>
									<DatePicker
										format={'MM/DD/YYYY'}
										placeholder="mm/dd/yyyy"
										value={selfChangeDate}
										onChange={val => dispatch({ type: 'classCardAdmin/changeDate', payload: val })}
										allowClear={false}
									/>
								</div>
								<div className={styles.classCardContentTopItem}>
									{
										isShowPreData ?
											<span style={{ color: '#1890FF' }} onClick={() => dispatch({ type: 'classCardAdmin/showPreData', payload: false })}>
												<span style={{ marginRight: '10px' }}>过去的排课</span>
												<CheckOutlined />
											</span>
											:
											<span onClick={() => dispatch({ type: 'classCardAdmin/showPreData', payload: true })}>过去的排课</span>
									}
								</div>
							</div>
						</div>
						<div className={styles.classCardContentBottom} style={{ marginRight: "0" }}>
							<div className={styles.classCardContentLeft}>
								<div className={styles.classCardContentLeftContent} style={{ top: "84px" }}>
									{
										timeSlot(centerChangeDate).map((item, key) => {
											const { startTime } = item || {}
											return (
												key % 2 === 0 ?
													<div className={styles.classCardContentLeftTimeLine} key={key}>{moment(startTime).format('HH:mm')}</div>
													:
													<div className={styles.classCardContentLeftTimeLine} key={key}></div>
											)
										})
									}
									<div className={styles.classCardContentLeftTimeLine}>21:00</div>
								</div>
							</div>
							<div className={styles.classCardContentRight}>
								<Table
									rowKey={'startTime'}
									columns={columns}
									dataSource={showData}
									pagination={false}
									bordered={true}
									tableLayout={"fixed"}
								/>
							</div>
						</div>
					</div>
				</>
			}
			{/* 展示单个课程的hover信息 */}
			<div
				className={styles.classCardHoverInfo}
				style={{
					top: top,
					left: left,
					display: isShowHoverInfo ? 'inline-block' : 'none',
					background: cardIsEnded ? (isRealItem ? '#F7F7F7' : '#F5F9FF') : '#FFF',
					zIndex: 300
				}}
				id='classCardHoverInfo'
			>
				<div className={styles.classCardHoverInfoTop}>
					<div className={styles.classCardHoverInfoTopLeft}>
						{
							cardIsEnded ?
								(
									isRealItem ?
										<>
											<Badge color="#3C84FF" />
											<span>已结束</span>
										</>
										:
										<>
											<Badge color="#95BBF4" />
											<span>过去的</span>
										</>
								)
								:
								<span></span>
						}
					</div>
					<div className={styles.classCardHoverInfoTopRight}>
						{
							cardIsEnded &&
							<>
								<img
									src={iconDetailPng}
									onClick={() => {
										let url = ""
										if (isRealItem) {
											if (userTrainingPlanId) {
												url = `/system/center/detail/userOfflinePlanDetail?id=${userTrainingPlanId}`
											}
										} else {
											if (students && students.length > 0) {
												url = `/system/center/detail/subjectDetail?id=${students[0].id}`
											}
										}
										if (url)
											dispatch({
												type: 'classCardAdmin/changeRoute',
												payload: {
													url
												}
											})
									}}
								/>
								<img src={iconClosedPng} onClick={() => dispatch({ type: 'classCardAdmin/onClosed' })} />
							</>
						}
					</div>
				</div>
				<div className={styles.classCardHoverInfoBottom}>
					{/* 训练师 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconTeacherPng} />
						<div className={styles.classCardHoverInfoBottomItemName}>
							<div
								className={styles.classCardHoverInfoBottomItemNameItem}
								onClick={() => {
									const url = `/system/center/detail/groupDetail?id=${executor && executor.id}`
									history.push(url)
								}}
							>
								{executor && executor.name}
							</div>
						</div>
					</div>
					{/* 孩子 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconKidPng} />
						<div className={styles.classCardHoverInfoBottomItemName}>
							{students && students.map((item, index) => {
								return (
									<div
										className={styles.classCardHoverInfoBottomItemNameItem}
										onClick={() => {
											const url = `/system/center/detail/subjectDetail?id=${item && item.id}`
											dispatch({
												type: 'classCardAdmin/changeRoute',
												payload: {
													url
												}
											})
										}}
									>
										{item && item.name || ''}
									</div>
								)
							})}
						</div>
					</div>
					{/* 中心 */}
					{
						<div className={styles.classCardHoverInfoBottomItem}>
							<img src={iconCenterPng} />
							<div className={styles.classCardHoverInfoBottomItemContent}>
								{tenantInfo && tenantInfo.displayName}
							</div>
						</div>
					}
					{/* 课程时间区间 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconTime2Png} />
						<div className={styles.classCardHoverInfoBottomItemContent}>
							{moment(startTime).format("YYYY/MM/DD")}({`周${getWeekday(moment(startTime).day())}`})  {moment(startTime).format("HH:mm")}-{moment(moment(startTime).add(1, 'h')).format("HH:mm")}
						</div>
					</div>
					{/* 显示是否重复 */}
					{
						calendarCount > 0 &&
						<div className={styles.classCardHoverInfoBottomItem}>
							<img src={iconRepetitionPng} />
							<div className={styles.classCardHoverInfoBottomItemContent}>
								{getRepeatDescription(periodIntervalUnit, periodIntervalNumber, startTime, endTime)}
							</div>
						</div>
					}
					{/* 显示线上线下课程 */}
					<div className={styles.classCardHoverInfoBottomItem}>
						<img src={iconCoursePng} />
						<div className={styles.classCardHoverInfoBottomItemContent}>
							{
								calendarCourseType === "Online" ?
									<>{myTag("Online")}</> :
									(
										calendarCourseType === "Offline" ?
											<>{myTag("Offline")}</> :
											<span></span>
									)
							}
							{
								isRealItem &&
								<span>{planTypeEnum[trainingType] || ""}  第{trainingCourseNum}次课</span>
							}
						</div>
					</div>
				</div>
			</div>
			<Drawer
				title={<b style={{ fontSize: '18px' }}>耗课数</b>}
				open={showDrawer}
				width={720}
				onClose={() => dispatch({ type: 'classCardAdmin/updateState', payload: { showDrawer: false } })}
			>
				<div style={{ marginBottom: "10px", fontWeight: "600", fontSize: "16px" }}>总耗课数 {totalCourseNum}</div>
				<MagicTable {...TableProps} />
			</Drawer>
		</div>
	)
}

export default connect(({ classCardAdmin }) => ({ classCardAdmin }))(ClassCardDetail)