import { GetProfile, GetTrainerDetail } from 'services/trainingSystem'

export default {
  namespace: 'groupDetail',
  state: {
    userInfo: null,
    trainerDetail: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query({ payload }, { call, put, select }) {
      yield put({ type: 'loadPage', payload })
      yield put({ type: 'getTrainerDetail', payload })
    },

    *loadPage({ payload }, { call, put, select }) {
      const { id } = payload
      const res = yield call(GetProfile, { id })
      yield put({ type: 'updateState', payload: { userInfo: null } })
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { userInfo: res.data } })
      }
    },

    *getTrainerDetail({ payload }, { call, put, select }) {
      const { id } = payload||{}
      const res = yield call(GetTrainerDetail, { trainerId: id })
      if (res.response.ok) {
        yield put({ type: 'updateState', payload: { trainerDetail: res.data } })
      }
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/detail/groupDetail') {
    //       dispatch({ type: 'query', payload: location.query })
    //     }
    //   })
    // },
  },
}
