import React, { Fragment } from 'react'
import { connect } from 'umi'
import { IntlManager } from 'utils/helper'
import { Modal, Button, Progress, Space } from 'antd'
import { PauseOutlined, CaretRightOutlined } from '@ant-design/icons';
import styles from './index.less'

class TestMusicModal extends React.Component {

    state = {
        isOpen: false,
        currentTime: 0,
        duration: 0,
        testmusicplayer: null
    }

    startBrainMusic = (type = 'play') => {
        const { dispatch } = this.props;
        const { isOpen, testmusicplayer } = this.state;
        if(isOpen){
            testmusicplayer.pause();
            testmusicplayer.currentTime = 0;
            this.setState({ isOpen: false })
        }
        dispatch({ type: 'musicplayer/updateState', payload: { testMusicModalVisible: false } })
        if (type === 'play') {
            dispatch({ type: 'musicplayer/changeMusicStatus', payload: { status: true } })
        }
    }

    changeMusicStatus = (status) => {
        const { testmusicplayer } = this.state;
        this.setState({ isOpen: status })
        if (status) {
            testmusicplayer.play().then(() => { }, () => { })
        } else {
            testmusicplayer.pause();
        }
    }

    componentDidMount() {
        const testmusicplayer = document.getElementById('testmusicplayer')
        this.setState({ testmusicplayer })

        // 监听播放进度
        testmusicplayer.ontimeupdate = () => {
            this.setState({
                currentTime: testmusicplayer.currentTime
            })
        }

        testmusicplayer.oncanplay = () => {
            this.setState({
                duration: testmusicplayer.duration
            })
        }

        testmusicplayer.onended = () => {
            this.setState({ isOpen: false })
        }
    }


    render() {
        const intl = IntlManager.MyIntl()

        const { visible } = this.props
        const { isOpen, currentTime, duration } = this.state

        const testMusicUrl = 'https://cl-cdn.oss-cn-shanghai.aliyuncs.com/static/assets/2.mp3'

        return (
            <Fragment>
                <Modal
                    open={visible}
                    title={intl('bluetoothEarphone')}
                    footer={null}
                    onCancel={() => this.startBrainMusic()}
                    maskClosable={false}
                    className={styles.testMusicModal}
                >
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ marginBottom: '10px', fontWeight: 600 }}>{intl('confirmBluetoothIsConnected')}</div>
                        <div style={{ marginBottom: '10px' }}>{intl('adjustVolumeBeforePlaying')}</div>

                        <div style={{ marginBottom: '20px', position:'relative' }}>
                            <Progress
                                type="circle"
                                width={50}
                                strokeWidth={6}
                                showInfo={false}
                                percent={currentTime / duration * 100}
                                strokeColor='#1890FF'
                            >
                            </Progress>
                            <div className={styles.clickText}>{intl('clickToAudition')}</div>
                            {
                                isOpen ?
                                <PauseOutlined onClick={() => { this.changeMusicStatus(false) }} className={styles.testMusicModalIcon} />
                                :
                                <CaretRightOutlined onClick={() => { this.changeMusicStatus(true) }} className={styles.testMusicModalIcon} />
                            }
                        </div>

                        <Space direction="vertical">
                            <Button type='primary' style={{ width: '240px' }} onClick={() => this.startBrainMusic()}>{intl('ConnectedPlayFormalMusic')}</Button>
                            <Button style={{ width: '240px' }} onClick={() => this.startBrainMusic('cancel')}>{intl('NotIntoFormalMusic')}</Button>
                        </Space>
                    </div>

                </Modal>
                <audio id='testmusicplayer' src={testMusicUrl} ></audio>
            </Fragment>
        )
    }
}

export default connect(({ musicplayer }) => ({ visible: musicplayer.testMusicModalVisible }))(TestMusicModal)
