import React from 'react'
import { Modal, Form, Input, Radio, Row, Col, Button } from 'antd'
import { useIntl } from 'umi'
import styles from './index.less'
const FormItem = Form.Item

const QuestionnaireModal = ({
  visible,
  onCancel,
  onOk,
  initData
}) => {
  const intl = useIntl();

  const showTitle = initData ? intl.formatMessage({ id: 'editQuestionnaire' }) : intl.formatMessage({ id: 'newQuestionnaire' })

  const onSubmit = (values) => {
    onOk(values)
  }

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      open={visible}
      title={showTitle}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row gutter={32}>
          <Col span={12}>
            <FormItem
              label={intl.formatMessage({ id: 'name' })}
              name={'Name'}
              initialValue={initData ? initData.Name : null}
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={intl.formatMessage({ id: 'displayName' })}
              name={'DisplayName'}
              initialValue={initData ? initData.DisplayName : null}
              rules={[{ required: true, message: `${intl.formatMessage({ id: 'displayName' })} ${intl.formatMessage({ id: 'isRequired' })}` }]}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <FormItem
              label={intl.formatMessage({ id: 'language' })}
              name={'Language'}
              initialValue={initData ? initData.Language : null}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value='en'>{intl.formatMessage({ id: 'english' })}</Radio>
                <Radio value='zh-Hans'>{intl.formatMessage({ id: 'chinese' })}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={intl.formatMessage({ id: 'type' })}
              name={'Type'}
              initialValue={initData ? initData.Type : null}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value='Guardian'>{intl.formatMessage({ id: 'guardian' })}</Radio>
                <Radio value='Doctor'>{intl.formatMessage({ id: 'doctor' })}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
        <FormItem
          label={intl.formatMessage({ id: 'jsonString' })}
          name={'JsonString'}
          initialValue={initData ? initData.JsonString : null}
          rules={[{ required: true }]}
        >
          <Input />
        </FormItem>
        <div className={styles.footer}>
          <Button className={styles.footerLeft} onClick={onCancel}>取消</Button>
          <Button type="primary" htmlType="submit">
            确定
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default QuestionnaireModal
