import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import LineWithIconChart from 'components/NewVratChartComponents/LineWithIconChart';
import BottomPageNum from './components/BottomPageNum';
import { useIntl } from 'umi';

const getValue = (value) => {
  const data = value * 100;
  if (data > 0 && data < 1) {
    return '<1';
  }
  return Math.round(data);
};

const RealtimePerformance = ({ data }) => {
  const intl = useIntl();
  // 分母
  const standard = 52;

  const { Motion = [], Behaviour = [] } = data || {};

  function inattentiveCount(arr, type) {
    var total = 0;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].Type === type) {
        total += 1;
      }
    }
    return total;
  }

  const correctly = parseInt(
    Behaviour && Behaviour.length > 0
      ? `${standard - inattentiveCount(Behaviour, 1)}`
      : 0,
  );
  const miss = parseInt(inattentiveCount(Behaviour, 1));
  const error = parseInt(inattentiveCount(Behaviour, 0));

  const correctlyPercentile = getValue(correctly / standard);
  const missPercentile = getValue(miss / standard);
  const errorPercentile =
    error === 0 && correctly == 0
      ? intl.formatMessage({ id: 'TextNA' })
      : getValue(error / (error + correctly));

  const LineWithIconChartProps = {
    dataStruct: {
      Motion,
      Behaviour,
    },
  };

  const RealTimeDes = [
    {
      title: intl.formatMessage({ id: 'RealtimePerformance.green' }),
      color: '#00BB73',
      des: intl.formatMessage({ id: 'RealtimePerformance.correct' }),
    },
    {
      title: intl.formatMessage({ id: 'RealtimePerformance.yellow' }),
      color: '#FFB500',
      des: intl.formatMessage({ id: 'RealtimePerformance.omission' }),
    },
    {
      title: intl.formatMessage({ id: 'RealtimePerformance.red' }),
      color: '#FF8682',
      des: intl.formatMessage({ id: 'RealtimePerformance.wrongly' }),
    },
    {
      title: intl.formatMessage({ id: 'RealtimePerformance.blueLine' }),
      color: '#5DB9DD',
      des: intl.formatMessage({ id: 'RealtimePerformance.moving' }),
    },
  ];

  const numDes = [
    {
      value: correctlyPercentile,
      des: intl.formatMessage(
        { id: 'RealtimePerformance.correctly' },
        { correctlyPercentile },
      ),
      color: '#48C969',
    },
    {
      value: missPercentile,
      des: intl.formatMessage(
        { id: 'RealtimePerformance.missPercentile' },
        { missPercentile },
      ),
      color: '#FFB500',
    },
    {
      value: errorPercentile,
      des: intl.formatMessage(
        { id: 'RealtimePerformance.errorPercentile' },
        { errorPercentile },
      ),
      color: '#FF8682',
    },
  ];

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.realtimePerformance}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.title}>
            {intl.formatMessage({ id: 'RealtimePerformance.title' })}
          </div>
          <div className={styles.titleDes}>
            {intl.formatMessage({ id: 'RealtimePerformance.titleDes' })}
          </div>
          <div className={styles.realtimeDes}>
            {RealTimeDes.map((item) => {
              const { title, color, des } = item || {};
              return (
                <div key={title} className={styles.realtimeItem}>
                  <div style={{ color }} className={styles.realtimeTitle}>
                    {title}
                  </div>
                  <div className={styles.realtimeDesContent}>{des}</div>
                </div>
              );
            })}
          </div>
          <LineWithIconChart {...LineWithIconChartProps} />
          <div className={styles.numDes}>
            {numDes.map((item) => {
              const { color, value, des } = item || {};
              return (
                <div key={color} className={styles.numItem}>
                  <div style={{ color }} className={styles.numValue}>
                    {value !== intl.formatMessage({ id: 'TextNA' }) ? `${value}%` : value}
                  </div>
                  <div className={styles.numDesContent}>{des}</div>
                </div>
              );
            })}
          </div>
        </div>
        <BottomPageNum num={12} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default RealtimePerformance;
