import { GetUserOfflinePlanForPunch, GetUserOfflinePlanForNumPunch, GetBrainRecord } from 'services/rocketService/UserOfflinePlan'
import { CenterGetSubjectInfo } from 'services/userManager'
import { GetTrainerDetail } from 'services/trainingSystem'
import { fixedZero } from 'utils/utils';
import { GetListenRecord, GetFeedBack, GetChildFeedback } from 'services/group';
import { SetLanguage } from 'services/language'
import { setLocale, getLocale, history } from 'umi'

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es'
}
export default {
    namespace: 'hfsTrainingRecord',

    state: {
        subjectInfo: null,
        planData: null,
        guardianInfo: null,
        musicInfo: null,
        childInfo: null,
        num: 0,
        trainerInfo: null
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    effects: {
        *fetch({ payload }, { all, call, put, take, select }) {
            const { userPlanId, num, subjectId, trainerId, CognitiveleapUser, userTenantId, tenantId, from = 'oneToone', culture = 'zh-CN' } = payload

            if (getLocale() !== culture) {
                const res = yield call(SetLanguage, { culture: LangOptions[culture] })
                if (res.response.ok) {
                  setLocale(culture)
                }
            }

            if (CognitiveleapUser && userTenantId) {
                localStorage.setItem("CognitiveleapUser", CognitiveleapUser)
                localStorage.setItem("userTenantId", userTenantId)
                if (tenantId) localStorage.setItem("tenantId", tenantId)
            }

            if (from === 'oneToone') {
                yield put({ type: 'oneTooneGetData', payload: payload})
            } else {
                yield put({ type: 'oneTomoreGetData', payload: payload})
            }
        },

        *oneTooneGetData({ payload }, { all, call, put, take, select }) {
            const { userPlanId, num, subjectId, trainerId } = payload

            const res = yield [
                call(GetUserOfflinePlanForPunch, { UserOfflinePlanId: userPlanId, num }),
                call(CenterGetSubjectInfo, subjectId),
                call(GetUserOfflinePlanForNumPunch, { UserOfflinePlanId: userPlanId, Num: num, ForGuardian: true }),
                call(GetBrainRecord, { UserOfflineTrainingPlanId: userPlanId }),
                call(GetTrainerDetail, { trainerId })
            ]
            const planInfoRes = res[0]
            const subjectInfoRes = res[1]
            const guardianInfoRes = res[2]
            const musicInfoRes = res[3]
            const trainerInfoRes = res[4]
            if (res.every(item => item.response.ok)) {
                yield put({
                    type: 'updateState',
                    payload: {
                        planData: planInfoRes.data,
                        subjectInfo: subjectInfoRes.data,
                        guardianInfo: guardianInfoRes.data,
                        musicInfo: musicInfoRes.data,
                        num,
                        trainerInfo: trainerInfoRes.data
                    }
                })
            }
        },

        *oneTomoreGetData({ payload }, { all, call, put, take, select }) {
            const { subjectId, num, groupTrainingPlanId, trainerId } = payload

            const res = yield [
                call(GetFeedBack, { GroupTrainingPlanId: groupTrainingPlanId, CourseNum: num }),
                call(CenterGetSubjectInfo, subjectId),
                call(GetChildFeedback, { GroupTrainingPlanId: groupTrainingPlanId, CourseNum: num, SubjectId: subjectId }),
                call(GetListenRecord, { UserId: subjectId, GroupTrainingPlanId: groupTrainingPlanId, Num: num }),
                call(GetTrainerDetail, { trainerId })
            ]
            if (res.every(item => item.response.ok)) {
                const planInfoRes = res[0]
                const subjectInfoRes = res[1]
                const childInfoRes = res[2]
                const musicInfoRes = res[3]
                const trainerInfoRes = res[4]

                const { groupTrainingPlanCourse } = planInfoRes.data
                const iLs = musicInfoRes.data.map(item => {
                    return item.code + ' ' + fixedZero(item.musicCourseNum)
                })
                const { nonePlaybook, courseItems, coachName, hasDone, feedbacks, endTime } = groupTrainingPlanCourse

                const planData = {
                    nonePlaybook,
                    planItems: courseItems,
                    coachName,
                    num,
                    userOfflineTrainingPlan: {
                        currentNum: num
                    },
                    iLs
                }

                const guardianInfo = {
                    feedbacks,
                    hasDone,
                    endTime
                }

                const musicInfo = {
                    numProgress: musicInfoRes.data
                }

                yield put({
                    type: 'updateState',
                    payload: {
                        planData,
                        subjectInfo: subjectInfoRes.data,
                        guardianInfo,
                        musicInfo,
                        childInfo: childInfoRes.data[0],
                        num,
                        trainerInfo: trainerInfoRes.data
                    }
                })
            }
        },


        *downloadPdf({ payload }, { all, call, put, take, select }) {
            document.getElementById('downlaodButton').style.display = 'none'
            window.print()
            document.getElementById('downlaodButton').style.display = 'block'
        },
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(({ pathname, query }) => {
        //         if (pathname === '/hfsTrainingRecord') {
        //             dispatch({ type: 'fetch', payload: query })
        //         }
        //     })
        // },
    },
}
