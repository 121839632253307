import { Row, Col, Checkbox, Card, Divider, Popconfirm, Space, Modal } from 'antd'
import { useIntl } from 'umi';
import { EnvironmentOutlined, CalendarOutlined, ClockCircleOutlined, QrcodeOutlined, BlockOutlined } from '@ant-design/icons';
import moment from 'moment'

const TestingCenterInfo = ({
  pageType,
  checkBoxState,
  hasQRCodePermission,
  onDownload,
  testingCenterInfo,
  caseInfo,
  onViewReport,
  onMedicalReportView,
  onMedicalChnReportView,
  onParentEditionReport,
  onEducationEditionReport,
  onClinicianEditionReport,
  onChangeTestType,
  onShowQRCode,
  onCopyCode,
  onSendReport,
  onConcentFormCheck,
  isBind,
  isLogout,
  isLoading,
  institutionType,
  getReport,
  isEnterpriseAdmin,
  isAreaAdmin,
}) => {
  const intl = useIntl()
  const { TestingCenter = {}, TestLocation = {}, OrderStatus } = testingCenterInfo
  const { IsConsentFormSigned = false, Status = '', TestDate, TestType, Id, DeviceIP } = caseInfo

  const { Name } = TestLocation || {}
  const { DisplayName, ReportKinds = [] } = TestingCenter || {}

  const displayName = Name || DisplayName

  const showStatus = str => {
    switch (str) {
      case 'UnTested':
        return intl.formatMessage({ id: 'untested' })
      case 'Testing':
        return intl.formatMessage({ id: 'testing' })
      case 'Tested':
        return intl.formatMessage({ id: 'tested' })
      case 'ReportReady':
      case 'ReportAcquired':
        if (pageType === 'Admin') {
          return (
            <span>
              <span>{intl.formatMessage({ id: 'reportReady' })}</span>
              <span style={{ margin: '0 6px' }}>(</span>
              <Space split={<Divider type="vertical" />} size={0.1} >
                <a onClick={() => onViewReport()}>
                  {intl.formatMessage({ id: 'view' })}
                </a>
                <a onClick={() => !isLoading && onDownload()}>
                  {intl.formatMessage({ id: 'download' })}
                </a>
                <a onClick={() => onMedicalChnReportView()}>
                  {intl.formatMessage({ id: 'medicalVratView' })}
                </a>
                {/* <a onClick={() => onClinicianEditionReport()}>{intl.formatMessage({ id: 'Clinician Edition' })}</a>
                <a onClick={() => onEducationEditionReport()}>{intl.formatMessage({ id: 'Educator Edition' })}</a> */}
                <a onClick={() => onParentEditionReport()}>{intl.formatMessage({ id: 'Parent Edition' })}</a>
                <a onClick={() => onParentEditionReport('screen')}>{intl.formatMessage({ id: 'Parent Screen Edition' })}</a>
              </Space>
              <span style={{ marginLeft: 6 }}>)</span>
            </span>
          )
        } else {
          if (str === 'ReportReady') {
            return (
              <a
                onClick={(e) => {
                  if (isEnterpriseAdmin || isAreaAdmin) {
                    Modal.error({
                      content: '企业端用户无法操作所属中心的报告获取',
                    });
                  } else {
                    e.stopPropagation();
                    getReport();
                  }
                }}
              >
                {intl.formatMessage({ id: 'Acquire Report' })}
              </a>
            );
          } else {
            return (
              <Space split={<Divider type="vertical" />} size="0.1">
                {institutionType !== 'Education_CHN' && <>
                  <a onClick={() => onViewReport()}>
                    {intl.formatMessage({ id: 'view' })}
                  </a>
                  <a onClick={() => !isLoading && onDownload()}>
                    {intl.formatMessage({ id: 'download' })}
                  </a>
                  <span>
                    <a onClick={() => onSendReport()}>
                      {intl.formatMessage({ id: 'sendReport' })}
                    </a>
                  </span>
                </>}
                {institutionType === 'Education_CHN' &&
                  <span>
                    <a onClick={() => onMedicalChnReportView()}>
                      {intl.formatMessage({ id: 'medicalVratView' })}
                    </a>
                  </span>
                }
                {/* {ReportKinds.includes('ClinicianEdition') && <a onClick={() => onClinicianEditionReport()}>{intl.formatMessage({ id: 'Clinician Edition' })}</a>}
                {ReportKinds.includes('EducationEdition') && <a onClick={() => onEducationEditionReport()}>{intl.formatMessage({ id: 'Educator Edition' })}</a>} */}
                {ReportKinds.includes('ParentEdition') && <a onClick={() => onParentEditionReport()}>{intl.formatMessage({ id: 'Parent Edition' })}</a>}
                {ReportKinds.includes('ParentScreenEdition') && <a onClick={() => onParentEditionReport('screen')}>{intl.formatMessage({ id: 'Parent Screen Edition' })}</a>}
              </Space>
            );
          }
        }
      case 'DataUploading':
        return <span>{intl.formatMessage({ id: 'dataUploading' })}</span>
      case 'ReportGenerating':
        return <span>{intl.formatMessage({ id: 'reportGenerating' })}</span>
      case 'Failed':
        return <span>{intl.formatMessage({ id: 'testFailed' })}</span>
      case 'TestAbnormal':
        return <span>{intl.formatMessage({ id: 'testAbnormal' })}</span>
      case 'ReportGenerationFailed':
        return <span>{intl.formatMessage({ id: 'reportGenerationFailed' })}</span>
      default:
        return <span></span>
    }
  }

  const showTestDate = moment(TestDate).isValid()
    ? moment(TestDate)
      .local()
      .format('MM/DD/YYYY HH:mm')
    : intl.formatMessage({ id: 'notTestYet' })

  return (
    <Card style={{ marginTop: 18 }}>
      <h3 style={{ fontSize: 18 }}>{intl.formatMessage({ id: 'testInformation' })}</h3>
      <Row style={{ marginTop: 18 }} gutter={16}>
        <Col span={6}>
          <div>
            <span>
              <EnvironmentOutlined />
            </span>
            <span style={{ marginLeft: 6 }}>{intl.formatMessage({ id: 'testLocation' })}</span>
          </div>
          <div style={{ marginTop: 4 }}>
            <span>{displayName}</span>
          </div>
        </Col>
        {
          pageType === 'admin' && (
            <Col span={6}>
              <div>
                <span>
                  <EnvironmentOutlined />
                </span>
                <span style={{ marginLeft: 6 }}>IP地址</span>
              </div>
              <div style={{ marginTop: 4 }}>
                <span>{DeviceIP}</span>
              </div>
            </Col>
          )
        }
        <Col span={6}>
          <div>
            <span>
              <CalendarOutlined />
            </span>
            <span style={{ marginLeft: 6 }}>{intl.formatMessage({ id: 'testDate' })}</span>
          </div>
          <div style={{ marginTop: 4 }}>
            <span>{showTestDate}</span>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <span>
              <ClockCircleOutlined />
            </span>
            <span style={{ marginLeft: 6 }}>{intl.formatMessage({ id: 'testStatus' })}</span>
          </div>
          <div style={{ marginTop: 4 }}>
            <span>{showStatus(Status)}</span>
          </div>
        </Col>
        {pageType === 'admin' && <Col span={6}>
          <div>
            <span>
              <BlockOutlined />
            </span>
            <span style={{ marginLeft: 6 }}>Normative data</span>
          </div>
          <div style={{ marginTop: 4 }}>
            <Checkbox checked={checkBoxState} onChange={onConcentFormCheck}>Internal test</Checkbox>
          </div>
        </Col>}
        {Status === 'UnTested' && pageType === 'center' && hasQRCodePermission && isBind && !isLogout ? (
          <Col span={6}>
            <div>
              <span>
                <QrcodeOutlined />
              </span>
              <span style={{ marginLeft: 6 }}>{intl.formatMessage({ id: 'testCode' })}</span>
            </div>
            <div style={{ marginTop: 4 }}>
              <a
                onClick={() => {
                  onCopyCode()
                }}
              >
                {intl.formatMessage({ id: 'copyTestCode' })}
              </a>
              <Divider type="vertical" />
              <a
                onClick={() => {
                  onShowQRCode()
                }}
              >
                {intl.formatMessage({ id: 'showQRCode' })}
              </a>
            </div>
          </Col>
        ) : null}
      </Row>
    </Card>
  )
}
export default TestingCenterInfo