import { Button, Modal } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { useIntl } from 'umi'
import TemplateModal from './TemplateModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const confirm = Modal.confirm;

const listSupport = {
  name: {
    showText: 'name',
    showType: 'Text',
  },
  displayName: {
    showText: 'displayName',
    showType: 'Text',
  },
  type: {
    showText: 'type',
    showType: 'Text',
    render: (text) => {
      switch (text) {
        case 'CoreSession':
          return 'Core Session';
        default:
          return text;
      }
    },
  },
  disorders: {
    showText: 'disorder',
    showType: 'Text',
    render: (text) => text?.join(', '),
  },
};

const TheraLeap = ({
  centerInfo,
  theraLeapListData,
  deleteTemplete,
  loading,
  templateModalVisible,
  editTemplate,
  reloadTable,
}) => {
  const intl = useIntl()
  const { id: tenantId } = centerInfo || {};

  const tableProps = {
    listData: theraLeapListData,
    listSupport,
    loading,
    Actions: [
      {
        showText: 'Delete',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                confirm({
                  title: 'Are you sure to delete?',
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <span style={{ color: 'red' }}>
                      It won't be available in this center after deletion.
                    </span>
                  ),
                  okText: 'Delete',
                  onOk: async () => {
                    deleteTemplete(record);
                  },
                });
              }}
            >
              Delete
            </a>
          );
        },
      },
    ],
  };

  const modalProps = {
    tenantId,
    visible: templateModalVisible,
    onClose: () => editTemplate('close'),
    selectData: theraLeapListData,
    reloadTable,
  };

  return (
    <div>
      <Button onClick={() => editTemplate('add')}> {intl.formatMessage({ id: 'Edit Authorization' })}</Button>

      <p />
      <MagicTable {...tableProps} />
      {templateModalVisible && <TemplateModal {...modalProps} />}
    </div>
  );
};

export default TheraLeap;
