import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import RecommendationsLine from './components/RecommendationsLine';
import ContentLine from './components/ContentLine';
import BottomPageNum from './components/BottomPageNum';

const showList = [
  {
    content:
      'Establish clear and consistent routines: This is important to help children with inattention stay on track and manage their time effectively. Set specific times for meals, homework, bedtime, and other activities, and stick to them as much as possible.',
  },
  {
    content:
      'Provide visual aids: Use visual aids such as checklists, schedules, and timers to help your child stay organized and on-task. This can help them focus on what they need to do and reduce their stress.',
  },
  {
    content:
      'Create a quiet and distraction-free environment: This can help your child concentrate better and minimize distractions that may interfere with their ability to focus. Consider setting up a designated study area for them.',
  },
  {
    content:
      'Provide positive reinforcement: Encourage and reward good behavior and accomplishments. Use positive reinforcement such as praise, rewards, and privileges to motivate your child and build their confidence.',
  },
  {
    content:
      'Break tasks into smaller steps: This can help your child feel less overwhelmed and more capable of completing tasks. Give clear instructions and check in with them regularly to ensure they understand what is expected of them.',
  },
];

const RecommendationOne = () => {
  const lineProps = {
    title: 'Inattention',
    content:
      'Children with inattention may struggle with paying attention, staying focused, and completing tasks. Here are some tips to help your child:',
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.recommendationOne}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>Recommendations</div>
          <RecommendationsLine {...lineProps} />
          <div style={{ marginBottom: '40px' }}></div>
          {showList.map((item, index) => {
            const { content } = item || {};
            return <ContentLine key={index} content={content} />;
          })}
        </div>
        <BottomPageNum num={1} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default RecommendationOne;
