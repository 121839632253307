import React, {useState} from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { IntlManager } from 'utils/helper';
import { ExclamationCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import styles from '../detail.less';

const ArrangeAssessmentModal = ({ visible, subjectInfo, onOk, onCancel, onExtendClass, planInfo }) => {
    const { name } = subjectInfo;
    const intl = IntlManager.MyIntl();
    const [disable, setDisable] = useState(true)
    const handleChange = () => {
      setDisable(!disable)
    }
    const redStyle = {
      backgroundColor: "#ff00008f",
      border: 'none',
      color: "#fff",
      marginRight: '30px',
      marginLeft: '30px'
    }
    
    const disableStyle = {
      backgroundColor: "red",
      color: "#fff",
      border: 'none',
      marginRight: '30px',
      marginLeft: '30px'
    }
    return (
        <Modal
          title={<span style={{fontSize: '14px'}}><b>{intl('terminateThePlan')}</b></span>}
          open={visible}
          footer={null}
          afterClose={() => {
            setDisable(true)
          }}
          destroyOnClose={true}
          closeIcon={<CloseCircleOutlined />}
          width={800}
          onCancel={onCancel}
        >
          <center style={{height: '330px', paddingTop: '50px'}}>
            <div className={styles.tip}><ExclamationCircleFilled style={{fontSize: '28px', color:'#faad14', lineHeight: '40px' }}/>&nbsp;&nbsp;&nbsp;{intl('sureEndName', { name, trainPlanName: planInfo && planInfo.displayName })}</div>
            <div className={styles.endPlanTip}>
              <ul>
                <li>{intl('afterendPlanNotPossible')}</li>
                <li>{intl('completeAllSessionsEndingPlan')}</li>
              </ul>
            </div>
            <div style={{marginTop: '120px', marginLeft:'280px'}}>
                <Checkbox className={styles.check} onChange={handleChange}>{intl('risk')}</Checkbox>
                <Button disabled={disable} style={disable ? redStyle : disableStyle} onClick={()=>{onOk()}}>{intl('overPlan')}</Button>
                {/* <Button type="primary" ghost style={{marginRight: '30px'}} onClick={()=>{onExtendClass()}}>{intl('Add Sessions Class')}</Button> */}
                <Button onClick={()=>{onCancel()}}>{intl('cancel')}</Button>
            </div>
          </center>
      </Modal>
    )
}

export default ArrangeAssessmentModal