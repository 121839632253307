import React, { useEffect } from 'react';
import { Button, Modal, Image, Popover, Space } from 'antd';
import { connect } from 'umi';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

const modalTypeToTitle = {
  add: '新建证书勋章',
  edit: '编辑证书勋章',
};

const MeritMedal = ({ meritMedal, dispatch, loading }) => {
  useEffect(() => {
    dispatch({ type: 'meritMedal/query' });
  }, []);

  const { pagination, modalType, modalVisible, content, list } = meritMedal;
  const listSupport = {
    name: {
      showText: 'name',
      showType: 'Text',
    },
    displayName: {
      showText: '显示名称',
      showType: 'Text',
    },
    trainingModules: {
      showText: '已绑定模块',
      showType: 'Text',
      render(_, record) {
        const { trainingModules = [] } = record || {};
        return trainingModules.map((item) => item.displayName).join(', ');
      },
    },
    certificateCode: {
      showText: '证书代码',
      showType: 'Text',
    },
    issuedStyles: {
      showText: '勋章样式（生效）',
      render(text, record, index) {
        const { issuedStyle } = record;
        return <Image width={70} src={issuedStyle && issuedStyle.url} />;
      },
    },
    certificateStyle: {
      showText: '证书样式',
      render(_, record) {
        const { certificateStyle = [] } = record;

        const content = (
          <Space direction="vertical" style={{ display: 'flex' }}>
            {certificateStyle.map((item) => {
              const { name, style, id } = item || {};
              const { url } = style || {};
              return (
                <Space key={id}>
                  <div>{name}</div>
                  <Image width={70} src={url} />
                </Space>
              );
            })}
          </Space>
        );

        return Boolean(certificateStyle.length) ? (
          <Popover content={content} title="证书样式">
            <a>{certificateStyle.length}</a>
          </Popover>
        ) : '-';
      },
    },
    remark: {
      showText: '备注',
      showType: 'Remark',
    },
  };

  const tableProps = {
    loading: loading.models.meritMedal,
    listData: list,
    pagination,
    listSupport,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination };
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi };
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt };
      dispatch({ type: 'meritMedal/changeTable', payload: data });
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'meritMedal/onEdit', payload: record });
        },
      },
      {
        showText: '删除',
        onClick(record) {
          Modal.confirm({
            title: <span style={{ color: 'red' }}>确定删除吗？</span>,
            okText: '确定',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'meritMedal/onDelete', payload: record });
            },
          });
        },
      },
    ],
  };

  const modalProps = {
    title: modalTypeToTitle[modalType],
    visible: modalVisible,
    content,
    onCancel() {
      dispatch({
        type: 'meritMedal/updateState',
        payload: { modalVisible: false },
      });
    },
    onOk(value) {
      dispatch({ type: 'meritMedal/onSubmitModal', payload: value });
    },
  };

  const onAdd = () => {
    dispatch({ type: 'meritMedal/onAdd' });
  };
  return (
    <div>
      <div>证书勋章</div>
      <Button onClick={onAdd}>新建证书勋章</Button>
      <MagicTable {...tableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  );
};

export default connect(({ meritMedal, loading }) => ({ meritMedal, loading }))(
  MeritMedal,
);
