import getRequest from 'utils/request';

const GetUserContactEmailDetailApi = '/api/business/userContactEmail/{id}';
const GetUserContactListApi = '/api/business/userContactEmail';
const PostSendToSupportApi = '/api/business/userContactEmail/sendToSupport';
const PostResendToSupportApi =
  '/api/business/userContactEmail/{id}/reSendToSupport';

export async function GetUserContactEmailDetail(data) {
  return getRequest()(GetUserContactEmailDetailApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetUserContactList(data) {
  return getRequest()(GetUserContactListApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostSendToSupport(data) {
  return getRequest()(PostSendToSupportApi, {
    method: 'POST',
    data,
  });
}

export async function PostResendToSupport(data) {
  return getRequest()(PostResendToSupportApi.replace('{id}', data.id), {
    method: 'POST',
    params: data,
  });
}
