import getRequest from 'utils/request'

const Locations = '/api/vrclassroom/TestLocation/Locations'
const locationsPaged = '/api/vrclassroom/TestLocation/LocationsPaged'
const CreateOrUpdateLocation = '/api/vrclassroom/TestLocation/CreateOrUpdateLocation'
const LocationDetail = '/api/vrclassroom/TestLocation/Detail'

export async function GetLocations(bodyData) {
  return getRequest()(Locations, {
    method: 'POST',
    data: bodyData,
  })
}

export async function GetLocationDetail(queryData) {
  return getRequest()(LocationDetail, {
    method: 'GET',
    params: queryData,
  })
}

export async function PostCreateOrUpdateLocation(data) {
  return getRequest()(CreateOrUpdateLocation, {
    method: 'POST',
    data,
  })
}

export async function PostLocationsPaged(queryData, bodyData) {
  return getRequest()(locationsPaged, {
    method: 'POST',
    params: queryData,
    data: bodyData,
  })
}
