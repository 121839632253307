import React from 'react'
import { Row, Col } from 'antd'
import { useIntl } from 'umi'

const PathLengthChartText = ({ motionIcon, data }) => {
  const { percentInfo } = data
  const intl = useIntl()

  return (
    <div>
      <Row
        type="flex"
        justify="center"
        style={{ marginBottom: 10, marginTop: 40, marginLeft: '15%' }}
      >
        <Col span={18}>
          <img
            src={motionIcon}
            alt={motionIcon}
            style={{ width: 35, height: 35, marginRight: 15, verticalAlign: 'top' }}
          />
          <span style={{ color: '#333', fontSize: 16, fontWeight: 700, verticalAlign: 'sub' }}>
            <span>{intl.formatMessage({ id: 'report.pathLength.percentile' })}</span>
            <span style={{ fontSize: 20, color: percentInfo.color }}>{percentInfo.percent}</span>
          </span>
        </Col>
      </Row>
    </div>
  )
}
export default PathLengthChartText
