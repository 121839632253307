import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'umi';

const CharacteristicChart = ({ data, title }) => {
  const intl = useIntl();
  const option = {
    color: ['#eca8a7', '#ffe59d', '#7acb9d', '#36abd3'],
    legend: {
      show: false,
      data: ['Bad', 'Soso', 'Good', 'You'],
    },

    title: {
      text: '',
      left: '9.5%',
      top: '28%',
    },

    grid: {
      // left: '15%',
      top: '48%',
      bottom: '12%',
    },

    xAxis: {
      show: false,
      type: 'value',
    },

    yAxis: {
      show: false,
      name: 'test',
      nameTextStyle: {
        fontWeight: 'bold',
        fontSize: 18,
        padding: [10, 0, 0, 0],
      },
      type: 'category',
      data: ['testName'],
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
        fontWeight: 'bold',
        fontSize: 16,
        verticalAlign: 'middle',
      },
      splitLine: {
        show: false,
      },
    },

    series: [
      {
        name: 'Bad',
        type: 'bar',
        barWidth: '20%',
        stack: 'base',
        data: [33],
        silent: true,
        label: {
          show: true,
          offset: [0, 8],
          position: ['0%', '100%'],
          formatter: '0%',
          color: '#000',
        },
      },
      {
        name: 'Soso',
        type: 'bar',
        barWidth: '20%',
        stack: 'base',
        data: [33],
        silent: true,
        label: {
          show: true,
          offset: [0, 8],
          position: ['0%', '100%'],
          formatter: '33%',
          color: '#000',
        },
      },
      {
        name: 'Good',
        type: 'bar',
        barWidth: '20%',
        stack: 'base',
        data: [34],
        silent: true,
        label: {
          show: true,
          offset: [0, 8],
          position: ['0%', '100%'],
          formatter: '67%',
          color: '#000',
        },
      },
      {
        name: intl.formatMessage({ id: 'report.motionCharacter.you' }),
        type: 'bar',
        barGap: '-300%',
        barWidth: '40%',
        label: {
          show: true,
          position: 'right',
          offset: [-10, 5],
          formatter: 'right',
          fontWeight: 'bold',
          fontSize: 18,
          color: '#2796d3',
        },
        data: [],
        silent: true,
      },
    ],
  };

  option.title.text = title;
  option.series[3].data.push(data);
  option.series[3].label.formatter =
    `${intl.formatMessage({ id: 'report.motionCharacter.you' })} ` + data + '%';

  return (
    <ReactEcharts
      option={option}
      style={{ height: '200px', width: '100%' }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default CharacteristicChart;
