import { message } from 'antd'
import { IntlManager } from 'utils/helper'
import {
  GetContractList,
  GetSubDataById,
} from 'services/contract'

export default {
  namespace: 'hfsCost',
  state: {
    hfsCostData: null,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showQuickJumper: true,
      showTotal: (total) => {
        const intl = IntlManager.MyIntl()
        return intl("totalItem", { num: total })
      }
    },
    expandedRowKeys: []
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { call, put, take, select }) {
      const { pagination, hfsCostData, expandedRowKeys } = yield select(state => state.hfsCost)
      const { current, pageSize } = pagination
      const parmas = {
        SkipCount: (current - 1) * pageSize,
        MaxResultCount: pageSize
      }
      const res = yield call(GetContractList, parmas)
      if (res.response.ok) {
        const { items: { items, totalCount } } = res.data
        const newPagination = { ...pagination, total: totalCount }
        const firstId = items.length > 0 ? items[0].id : ''
        if (firstId) {
          yield put({ type: 'getSubDataById', payload: { id: firstId } })
        }
        items.forEach(item => {
          const findOldItems = hfsCostData && hfsCostData.items && hfsCostData.items.items && hfsCostData.items.items.find(oldItem => oldItem.id === item.id)
          if (findOldItems && findOldItems.subData) {
            item.subData = findOldItems.subData
          }
        })
        yield put({
          type: 'updateState',
          payload: {
            pagination: newPagination,
            expandedRowKeys: firstId ? expandedRowKeys.concat(firstId) : expandedRowKeys,
            hfsCostData: res.data
          }
        })
      }
    },

    *getSubDataById({ payload }, { call, put, take, select }) {
      const { hfsCostData } = yield select(state => state.hfsCost)
      const res = yield call(GetSubDataById, payload)
      if (res.response.ok) {
        const subData = res.data
        if(hfsCostData && hfsCostData.items && hfsCostData.items.items){
          const data = hfsCostData.items.items.map(item=>{
            if(item && item.id === subData.id)
              return {
                ...item,
                subData
              }
              else return item
          })
          const newData = {
            ...hfsCostData,
            items: {
              ...hfsCostData.items,
              items: data
            }
          }
          yield put({ type: 'updateState', payload: { hfsCostData:newData } })
        }
      } else {
        const { error } = res.data || {}
        const { message: errorInfo } = error || {}
        message.error(errorInfo || '获取合同数据失败')
      }
    },

    *changePagination({ payload }, { call, put, take, select }) {
      const { pagination } = yield select(state => state.hfsCost)
      yield put({ type: 'updateState', payload: { pagination: { ...pagination, current: payload } } })
      yield put({ type: 'query' })
    },

    *onExpand({ payload: { expanded, record } }, { call, put, take, select }) {
      const { id } = record || {}
      if (expanded) {
        yield put({ type: 'getSubDataById', payload: { id } })
      }
    }

  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/hfs/costOverview') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
