import React from 'react'
import { useIntl, getLocale } from 'umi'
import styles from './Report.less'
import brainPic from 'assets/vratReportImg/brain2_pic.png'
import brainPicCn from 'assets/vratReportImg/brain2_pic_cn.png'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const AttentionIndex = () => {
  const showImg = getLocale() === 'zh-CN' ? brainPicCn : brainPic

  const intl = useIntl()
  return (
    <div id="attentionIndex" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.attentionIndex.title' })}</p>
      </h2>
      <div className={styles.brainPicStyle}>
        <div className={styles.overviewChart}>
          <img src={showImg} alt="brain" />
        </div>
      </div>
      <h2 className={`${styles.brainPic} ${styles.weightFont}`}>
        {intl.formatMessage({ id: 'report.attentionIndex.chart.title' })}
      </h2>
      <PoweredBy styles = {styles}/>
      <p className={styles.page_number}>4</p>
    </div>
  )
}

export default AttentionIndex
