import { connect, history, useIntl } from 'umi';
import { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import { Button, Input, DatePicker, Table, Row, Col, Space } from 'antd';
import PaginationCom from 'components/Pagination/Pagination';
import { getFormatDate } from 'utils/utils';

const { RangePicker } = DatePicker;

const Search = Input.Search;

const CaseList = ({ cmsCaseList, dispatch }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'cmsCaseList/fetch' });
  }, []);

  const {
    caseList,
    pageIndex,
    pageSize,
    total,
    searchField,
    Status,
    OrderBy,
    StartTime,
    EndTime,
    StartTimeGenerate,
    EndTimeGenerate,
    loading,
    btnLoading,
  } = cmsCaseList;

  const onClickDetail = (record) => {
    history.push({
      pathname: '/system/manage/vratCaseDetail',
      query: {
        id: record.Id,
      },
    });
  };

  const onPageChange = (pageIndex, pageSize) => {
    dispatch({
      type: 'cmsCaseList/filterFetch',
      payload: { pageIndex, pageSize },
    });
  };

  const StatusShow = (status) => {
    switch (status) {
      case 'UnTested':
        return <span>{intl.formatMessage({ id: 'untested' })}</span>;
      case 'Testing':
        return <span>{intl.formatMessage({ id: 'testing' })}</span>;
      // case 'Tested':
      //   return <span>{intl.formatMessage({ id: 'tested' })}</span>;
      case 'ReportReady':
        return <span>{intl.formatMessage({ id: 'reportReady' })}</span>;
      case 'ReportGenerating':
        return (
          <span>{intl.formatMessage({ id: 'reportGenerating' })}</span>
        );
      case 'ReportAcquired':
        return <span>{intl.formatMessage({ id: 'reportAcquired' })}</span>
      case 'Failed':
        return <span>{intl.formatMessage({ id: 'testFailed' })}</span>
      case 'DataUploading':
        return <span>{intl.formatMessage({ id: 'dataUploading' })}</span>
      // case 'Deleted':
      //   return <span>{intl.formatMessage({ id: 'deleted' })}</span>;
      case 'ReportGenerationFailed':
        return <span>{intl.formatMessage({ id: 'reportGenerationFailed' })}</span>;
      case 'TestAbnormal':
        return <span>{intl.formatMessage({ id: 'testAbnormal' })}</span>
      default:
        return <span>{status}</span>;
    }
  };

  const TestTypeShow = (testType) => {
    switch (testType) {
      case 'EngTest':
        return <span>{intl.formatMessage({ id: 'engTest' })}</span>;
      case 'RealTest':
        return <span>{intl.formatMessage({ id: 'realTest' })}</span>;
      default:
        return <span></span>;
    }
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'cms.testList.caseID' }),
      dataIndex: 'caseId',
      key: 'caseId',
    },
    {
      title: intl.formatMessage({ id: 'centerName' }),
      dataIndex: 'centerName',
      key: 'centerName',
    },
    {
      title: intl.formatMessage({ id: 'Case IP' }),
      dataIndex: 'DeviceIP',
      key: 'DeviceIP',
    },
    {
      title: intl.formatMessage({ id: 'IP Location' }),
      dataIndex: 'IPGeoLocation',
      key: 'IPGeoLocation',
    },
    // {
    //   title: intl.formatMessage({ id: 'locationName' }),
    //   dataIndex: 'locationName',
    //   key: 'locationName',
    // },
    {
      title: intl.formatMessage({ id: 'cms.testList.testDate' }),
      dataIndex: 'testDate',
      key: 'testDate',
    },
    {
      title: intl.formatMessage({ id: 'status' }),
      dataIndex: 'Status',
      key: 'Status',
      render: (text) => StatusShow(text),
      filters: [
        {
          text: intl.formatMessage({ id: 'untested' }),
          value: 'UnTested',
        },
        {
          text: intl.formatMessage({ id: 'testing' }),
          value: 'Testing',
        },
        // {
        //   text: intl.formatMessage({ id: 'tested' }),
        //   value: 'Tested',
        // },
        {
          text: intl.formatMessage({ id: 'reportReady' }),
          value: 'ReportReady',
        },
        {
          text: intl.formatMessage({ id: 'dataUploading' }),
          value: 'DataUploading'
        },
        {
          text: intl.formatMessage({ id: 'reportGenerating' }),
          value: 'ReportGenerating',
        },
        {
          text: intl.formatMessage({ id: 'reportAcquired' }),
          value: 'ReportAcquired',
        },
        {
          text: intl.formatMessage({ id: 'testFailed' }),
          value: 'TestFailed',
        },
        {
          text: intl.formatMessage({ id: 'reportGenerationFailed' }),
          value: 'ReportGenerationFailed',
        },
        {
          text: intl.formatMessage({ id: 'testAbnormal' }),
          value: 'TestAbnormal',
        },
      ],
      filteredValue: Status,
    },
    {
      title: intl.formatMessage({ id: 'attentionIndex' }),
      dataIndex: 'AttentionIndex',
    },
    {
      title: intl.formatMessage({ id: 'motionIndex' }),
      dataIndex: 'MotionIndex',
    },
    {
      title: intl.formatMessage({ id: 'reportedDate' }),
      dataIndex: 'lastModificationTime',
    },
    {
      title: intl.formatMessage({ id: 'action' }),
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => (
        <a
          onClick={() => {
            onClickDetail(record);
          }}
        >
          {intl.formatMessage({ id: 'detail' })}
        </a>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch({
      type: 'cmsCaseList/filterFetch',
      payload: { Status: filters.Status },
    });
  };

  const showData = caseList.map((item) => {
    const {
      Case: { TestDate, Id: caseId, TestType, OverView, Reports, TargetReportVersion },
      TestingCenter: { DisplayName: centerDisplayName },
      // TestLocation: { Name: locationName },
      LastModificationTime,
      Status,
    } = item;

    let GenerateTime = '';
    if (Reports && Reports.length > 0) {
      GenerateTime = Reports.find(report => report?.Version === TargetReportVersion)?.GenerateTime
      Reports.forEach((item) => {
        if (moment(item.GenerateTime).isSameOrAfter(GenerateTime)) {
          GenerateTime = item.GenerateTime;
        }
      });
    }

    return {
      testDate: moment(TestDate).isValid()
        ? moment(TestDate).format('YYYY/MM/DD')
        : '',
      centerName: centerDisplayName ? centerDisplayName : '',
      // locationName,
      caseId,
      TestType,
      AttentionIndex: OverView
        ? (OverView.AttentionPerformanceIndex * 100).toFixed(0)
        : '',
      MotionIndex: OverView
        ? (OverView.MotionPerformanceIndex * 100).toFixed(0)
        : '',
      lastModificationTime:
        GenerateTime &&
          Status === 'ReportReady' &&
          moment(GenerateTime).isValid()
          ? moment(GenerateTime).format('YYYY/MM/DD HH:mm')
          : '',
      ...item,
    };
  });

  const searchProps = {
    placeholder: `${intl.formatMessage({
      id: 'search',
    })}: ${intl.formatMessage({
      id: 'caseId',
    })}, ${intl.formatMessage({
      id: 'centerName',
    })}, IP`,
    style: { width: 450 },
    onSearch(value) {
      dispatch({
        type: 'cmsCaseList/filterFetch',
        payload: { searchField: value },
      });
    },
    onChange(value) {
      dispatch({
        type: 'cmsCaseList/save',
        payload: { searchField: value.target.value },
      });
    },
    value: searchField,
  };

  const onClear = () => {
    dispatch({
      type: 'cmsCaseList/filterFetch',
      payload: {
        searchField: null,
        Status: [],
        OrderBy: null,
        StartTime: null,
        EndTime: null,
        StartTimeGenerate: null,
        EndTimeGenerate: null,
      },
    });
  };

  const exportExcel = () => {
    dispatch({
      type: 'cmsCaseList/exportExcel',
    });
  };

  const onTimePicker = (dates) => {
    let StartTime, EndTime;
    if (dates && dates.length > 0) {
      StartTime = getFormatDate(dates[0], true);
      EndTime = getFormatDate(dates[1], false);
    } else {
      StartTime = null;
      EndTime = null;
    }
    dispatch({
      type: 'cmsCaseList/filterFetch',
      payload: { StartTime, EndTime },
    });
  };

  const onReportTimePicker = (dates) => {
    let StartTimeGenerate, EndTimeGenerate;
    if (dates && dates.length > 0) {
      StartTimeGenerate = getFormatDate(dates[0], true);
      EndTimeGenerate = getFormatDate(dates[1], false);
    } else {
      StartTimeGenerate = null;
      EndTimeGenerate = null;
    }
    dispatch({
      type: 'cmsCaseList/filterFetch',
      payload: { StartTimeGenerate, EndTimeGenerate },
    });
  };

  const showRangeTime = [
    moment(StartTime).isValid() ? moment(StartTime) : null,
    moment(EndTime).isValid() ? moment(EndTime) : null,
  ];
  const showReportRangeTime = [
    moment(StartTimeGenerate).isValid() ? moment(StartTimeGenerate) : null,
    moment(EndTimeGenerate).isValid() ? moment(EndTimeGenerate) : null,
  ];

  const paginationComProps = { onPageChange, pageIndex, pageSize, total };

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'testList.title' })}>
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>{intl.formatMessage({ id: 'testList.title' })}</span>
          <Button
            loading={btnLoading}
            onClick={exportExcel}
            disabled={showData.length === 0}
            type="primary"
          >
            {intl.formatMessage({ id: 'exportList' })}
          </Button>
        </div>
        <Row
          type="flex"
          justify="start"
          gutter={10}
          style={{ marginBottom: 20 }}
        >
          <Col>
            <Search {...searchProps} />
          </Col>
          <Col>
            <Space>
              <div>
                测试日期:
              </div>
              <RangePicker onChange={onTimePicker} value={showRangeTime} />
            </Space>
          </Col>
          <Col>
            <Space>
              <div>
                报告生成时间:
              </div>
              <RangePicker onChange={onReportTimePicker} value={showReportRangeTime} />
            </Space>
          </Col>
          <Col>
            <Button onClick={onClear}>
              {intl.formatMessage({ id: 'clear' })}
            </Button>
          </Col>
        </Row>
        <div>
          <Table
            rowKey={(record) => record.Id}
            loading={loading}
            dataSource={showData}
            columns={columns}
            onChange={handleTableChange}
            pagination={false}
          />
          <div style={{ marginTop: 30 }}>
            <PaginationCom {...paginationComProps} />
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default connect(({ cmsCaseList }) => ({ cmsCaseList }))(CaseList);
