import React from 'react';
import { useIntl } from 'umi';
import ShowCardBorder from './ShowCardBorder';
import CustomMicroApp from 'components/CustomMicroApp';

import styles from './index.less';

const ParentLearningOverview = () => {
  const intl = useIntl();

  const props = {
    url: '/parentLearning',
  };

  return (
    <div className={styles.cbtpanel}>
      <div className={styles.cbtContent}>
        <ShowCardBorder footer={intl.formatMessage({ id: 'Week' })}>
          <CustomMicroApp {...props} />
        </ShowCardBorder>
      </div>
    </div>
  );
};

export default ParentLearningOverview;
