import React from 'react'

import { Menu } from 'antd'

const TabList = ({ currentTab, tabList, onChangeTab }) => {
  return (
    <Menu onClick={onChangeTab} selectedKeys={[currentTab]} mode="horizontal">
      {tabList.map(item => {
        return <Menu.Item key={item}>{item}</Menu.Item>
      })}
    </Menu>
  )
}

export default TabList
