
export const vratKeyIndicators =(intl)=> [
    {
        title: intl.formatMessage({ id: 'availableTest' }),
        subTitle: '',
        subTitleField: 'LatestExpirationTime',
        data: 0,
        dataField: 'LastAmount'
    },
    {
        title: intl.formatMessage({ id: 'usedTest' }),
        data: 0,
        field: 'UsedAmount'
    }
]