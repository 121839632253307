import { history } from 'umi';
import { message } from 'antd'
import moment from 'moment'
import { GetPlanList, DownloadExcelApi } from 'services/rocketService/Analysis'
import { CenterGetSubjectInfo } from 'services/userManager'
import { GetSleepRecord } from 'services/rocketService/TrainingPlan';
import { GetBrainRecord, GetMusicRecord } from 'services/rocketService/UserOfflinePlan'
import download from 'cognitiveleap-core-us/utils/download'

export default {
  namespace: 'trainList',
  state: {
    currentTag: 'Month',
    datePickerIsOpen: false,
    dateMonthPickerData: moment(),
    dateYearPickerData: moment(),
    isOpenUp: false,
    planList: [],
    ilsActiveKey: 'chart',
    currentIlsId: null,
    ilsDrawerVisible: false,
    currentonlinePlanId: null,
    shouldGetData: false,
    audioType: '',
    ilstrainerRecord: null,
    ilsparentRecord: null,
    ilsappRecord: null,
    brainRecord: null,
    userId: null,
    currentTenant: null,
    searchOption: {
      userNickName: '',
      planName: '',
      planType: '',
      status: '',
      createTime: {
        startTime: '',
        endTime: ''
      },
      realStartTime: {
        startTime: '',
        endTime: ''
      },
      endTime: {
        startTime: '',
        endTime: ''
      },
      courseTime: {
        startTime: '',
        endTime: ''
      }
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    recordPagination: {
      ilstrainer: {
        current: 0,
        pageSize: 10,
      },
      ilsparent: {
        current: 0,
        pageSize: 10,
      },
      ilsapp: {
        current: 0,
        pageSize: 10,
      }
    },
    currentTablePlan: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *initPage({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user);
      const { currentTenant } = yield select(state => state.trainList);
      const { canChangeList } = currentUser;
      if (!currentTenant) {
        yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
      } else if (payload) {
        const { id } = payload;
        if (id) {
          const newCurrentTenant = canChangeList.find(item => item.id == id)
          yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
        } else {
          yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' } } })
        }
      }
      yield put({ type: 'getPlanList' })
    },

    *onClear({ payload }, { call, put, select }) {
      const searchOption = {
        userNickName: '',
        planName: '',
        planType: '',
        status: '',
        createTime: {
          startTime: '',
          endTime: ''
        },
        realStartTime: {
          startTime: '',
          endTime: ''
        },
        endTime: {
          startTime: '',
          endTime: ''
        },
        courseTime: {
          startTime: '',
          endTime: ''
        }
      }
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 55
      }

      yield put({ type: 'updateState', payload: { searchOption, pagination } })
      yield put({ type: 'getPlanList' })
    },

    *getPlanList({ payload }, { call, put, select }) {
      const { searchOption, pagination, currentTenant } = yield select(state => state.trainList);
      const { current, pageSize } = pagination;
      const { userNickName, planName, planType, status, createTime, realStartTime, endTime, courseTime } = searchOption
      const SkipCount = (current - 1) * pageSize;
      const MaxResultCount = pageSize
      const parmas = {
        UserName: userNickName,
        PlanName: planName,
        AudioType: planType,
        Status: status,
        CreateBeginTime: createTime.startTime,
        CreateTime: createTime.endTime,
        StartBeginTime: realStartTime.startTime,
        StartTime: realStartTime.endTime,
        EndBeginTime: endTime.startTime,
        EndTime: endTime.endTime,
        CourseStartTime: courseTime.startTime,
        CourseEndTime: courseTime.endTime,
        SkipCount,
        MaxResultCount,
        TenantId: currentTenant.id
      }
      const res = yield call(GetPlanList, parmas)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            pagination: { ...pagination, total: res.data.totalCount },
            planList: res.data.items
          }
        })
      } else {
        const { error } = res.data
        message.error(error.message)
      }
    },

    *getRecord({ payload: { id, type, current } }, { all, call, put, take, select }) {
      const { ilstrainerRecord, ilsparentRecord, ilsappRecord, recordPagination, userId } = yield select(state => state.trainList);
      const idFieldName = type == 'ilsapp' ? 'UserTrainingPlanId' : "UserOfflineTrainingPlanId";
      const parmasData = {
        SubjectId: userId,
        [idFieldName]: id,
        SkipCount: (current - 1) * (recordPagination[type].pageSize),
        MaxResultCount: recordPagination[type].pageSize
      };
      type != 'ilsapp' && (parmasData.ForGuardian = (type == 'ilsparent'));
      const res = yield call(GetSleepRecord, parmasData);
      if (res.response.ok) {
        const totalCount = res.data.totalCount;
        const newPagination = Object.assign({}, recordPagination, { [type]: { current, pageSize: recordPagination[type].pageSize } });
        switch (type) {
          case 'ilstrainer':
            yield put({
              type: 'updateState', payload: {
                ilstrainerRecord: ilstrainerRecord && totalCount ? { items: ilstrainerRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
          case 'ilsparent':
            yield put({
              type: 'updateState', payload: {
                ilsparentRecord: ilsparentRecord && totalCount ? { items: ilsparentRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
          case 'ilsapp':
            yield put({
              type: 'updateState', payload: {
                ilsappRecord: ilsappRecord && totalCount ? { items: ilsappRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
        }
      }
    },

    *getBrainRecord({ payload: { id } }, { call, put, select }) {
      const res = yield call(GetBrainRecord, { UserOfflineTrainingPlanId: id });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            brainRecord: res.data,
          },
        })
      }
    },

    *downloadExcel({ payload: { intl } }, { call, put, select }) {
      const { searchOption, currentTenant } = yield select(state => state.trainList);
      const { userNickName, planName, planType, status, createTime, realStartTime, endTime, courseTime } = searchOption
      const parmas = {
        UserName: userNickName,
        PlanName: planName,
        AudioType: planType,
        Status: status,
        CreateBeginTime: createTime.startTime,
        CreateTime: createTime.endTime,
        StartBeginTime: realStartTime.startTime,
        StartTime: realStartTime.endTime,
        EndBeginTime: endTime.startTime,
        EndTime: endTime.endTime,
        CourseStartTime: courseTime.startTime,
        CourseEndTime: courseTime.endTime,
        TenantId: currentTenant.id
      }
      const hide = message.loading(intl.formatMessage({ id: 'exxportListLoading' }), 0);
      const res = yield call(DownloadExcelApi, parmas);
      if (res.response.ok) {
        const { response } = res;
        response.blob().then(blob => {
          download(blob, `${intl.formatMessage({ id: 'brainTrainAnalysis' })}+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
        })
      }
      setTimeout(hide, 0)
    },

    *jumpToRecordDetail({ payload: { record, isAdmin } }, { call, put, select }) {
      const res = yield call(CenterGetSubjectInfo, record.id)
      if (res.response.ok) {
        const { isBind, isLogout } = res.data;
        history.push({
          pathname: isAdmin ? '/system/manage/userOfflinePlanDetail' : '/system/center/detail/userOfflinePlanDetail',
          query: {
            id: record.id,
            isBind,
            isLogout
          }
        })
      }
    },

    *showChartRecord({ payload }, { call, put, select }) {
      const { recordPagination } = yield select(state => state.trainList)
      yield put({
        type: 'updateState',
        payload: {
          ilsDrawerVisible: true,
          currentTablePlan: payload,
          ilsActiveKey: 'chart',
          shouldGetData: true,
          userId: payload.userId,
          ilsparentRecord: null,
          ilstrainerRecord: null,
          ilsappRecord: null,
          brainRecord: null,
          musicRecord: null,
          recordPagination: Object.assign(
            {},
            recordPagination,
            {
              ilstrainer: {
                current: 0,
                pageSize: 10,
              },
              ilsparent: {
                current: 0,
                pageSize: 10,
              },
              ilsapp: {
                current: 0,
                pageSize: 10,
              }
            }
          )
        }
      })
    },

    *getMusicRecord({ payload }, { call, put, select }) {
      const { id } = payload
      const res = yield call(GetMusicRecord, id)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            musicRecord: res.data
          }
        })
      }
    }
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/analysis/iLs') {
    //       dispatch({ type: 'initPage' })
    //     }
    //   })
    // },
  },
}
