import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Modal } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { history } from 'umi';
import SortOrderModal from 'components/SortOrderModal'

const confirm = Modal.confirm

const modalTitleMenu = {
  edit: '编辑分类',
  add: '新建分类',
}

const CourseCategory = ({ courseCategory, dispatch }) => {

  useEffect(() => {
    dispatch({ type: 'courseCategory/query' })
  }, [])

  const {
    modalType,
    content,
    modalVisible,
    list,
    listSupport,
    pagination,
    sortOrderModal,
    selectItem,
  } = courseCategory

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'courseCategory/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'courseCategory/onSubmitModal', payload: value })
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'courseCategory/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '课程',
        onClick(record) {
          history.push({
            pathname: '/system/manage/courseCategoryDetail',
            query: {
              id: record.id,
              type: 'course'
            },
          })
        },
      },
      {
        showText: '信息',
        onClick(record) {
          dispatch({ type: 'courseCategory/onEdit', payload: record.id })
        },
      },
      {
        showText: '排序',
        onClick(record) {
          dispatch({
            type: 'courseCategory/updateState',
            payload: { selectItem: record, sortOrderModal: true },
          })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'courseCategory/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const sortModalProps = {
    visible: sortOrderModal,
    onCancel() {
      dispatch({ type: 'courseCategory/updateState', payload: { sortOrderModal: false } })
    },
    onOk(data) {
      dispatch({ type: 'courseCategory/changeSortOrder', payload: data.sortOrder })
    },
    currentValue: selectItem && selectItem.sortOrder,
  }

  const onAddClick = () => {
    dispatch({ type: 'courseCategory/onAdd' })
  }

  return (
    <div>
      <h1>分类</h1>
      <Button onClick={onAddClick}>新建分类</Button>
      <MagicTable {...TableProps} />
      <SortOrderModal {...sortModalProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ courseCategory }) => ({ courseCategory }))(CourseCategory)
