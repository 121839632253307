import { PostFindUserOfflinePlanList, PostRestartPlanApi } from 'services/rocketService/UserOfflinePlan'
import { message } from 'antd'
  export default {
    namespace: 'adminSubjectOfflinePlan',
  
    state: {
      planInfo: null,
      list:[],
      pagination: {
        currentPageIndex: 1,
        currentPageSize: 10,
      }
    },
  
    reducers: {
      updateState(state, { payload }) {
        return {
          ...state,
          ...payload,
        }
      },
    },
  
    effects: {
      *loadList({ payload }, { all, call, put, take, select }) {
        const { subjectInfo } = yield select(state => state.adminSubjectDetail)
        const { pagination } = yield select(state => state.adminSubjectOfflinePlan)
        const { currentPageIndex, currentPageSize } = pagination;
        const res = yield call(PostFindUserOfflinePlanList, {
          subjectId: subjectInfo.id, 
          skipCount: (currentPageIndex - 1) * currentPageSize,
          maxResultCount: currentPageSize,
        })
        if(res.response.ok){
          yield put({
            type: 'updateState',
            payload: { planInfo: res.data },
          })
        }
      },
      *changePage({ payload: { pagination } }, { all, call, put, take, select }){
        yield put({
          type: 'updateState',
          payload: { pagination },
        })
        yield put({
          type: 'loadList'
        })
      },
      *reStart({ payload: { intl, id }}, { call, put, select }) {
        const res = yield call(PostRestartPlanApi, {
          userOfflineTrainingPlanId: id
        });
        if (res.response.ok) {
          message.success(intl.formatMessage({ id: 'successStart' }));
          yield put({ type: 'loadList' })
        }
      },
    },
    subscriptions: {
      setup({ dispatch, history }) {
        // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
        return () => {}
      },
    },
  }