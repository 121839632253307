import React, { useEffect } from 'react'
import { connect, useIntl, history } from 'umi'
import { Card, Input, Select, Button, DatePicker, Tooltip, message } from 'antd'
import moment from 'moment'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { getFormatDate } from 'utils/utils'
import copy from 'copy-to-clipboard'
import styles from './index.less'

const { Search } = Input
const { Option } = Select
const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'

const copyText = (intl, text) => {
  if (copy(text))
    message.success(intl.formatMessage({ id: 'copyed' }))
}

const listSupport = (intl) => {
  return {
    code: {
      showText: intl.formatMessage({ id: 'orderNumber' }),
      showType: 'Text',
      render: (text, data) => {
        return (
          <React.Fragment>
            <Tooltip title={text} placement="topLeft">
              <span>{text.replace(/(\w{5})(\w{22})(\w{5})/, '$1******$3')}</span>
            </Tooltip>
            <a style={{ marginLeft: '5px' }} onClick={() => copyText(intl, text)}>{intl.formatMessage({ id: 'copy' })}</a>
          </React.Fragment>
        )
      },
    },
    name: {
      showText: intl.formatMessage({ id: 'productsName' }),
      showType: 'Text',
    },
    summary: {
      showText: intl.formatMessage({ id: 'section' }),
      showType: 'Text',
      render: (text, record) => {
        if (text.includes('第') && text.includes('次')) {
          const { name } = record
          const num = text.split('第')[1].split('次')[0]
          return name + ' ' + intl.formatMessage({ id: 'whichSessionOther' }, { num })
        } else {
          return text;
        }
      },
    },
    orderType: {
      showText: intl.formatMessage({ id: 'productTypes' }),
      showType: 'Text',
    },
    userNameOrPhoneNum: {
      showText: intl.formatMessage({ id: 'name_phoneNum' }),
      showType: 'Text',
      render: (text, data) => {
        const { userId, type } = data
        return (
          <a
            onClick={() => {
              if (type.includes('OnlinePlanCourse') || type.includes('OfflinePlanCourse') || type == 'Assessment') {
                history.push({
                  pathname: '/system/center/detail/subjectDetail',
                  query: {
                    id: userId,
                  },
                })
              } else if (type == 'Course') {
                history.push({
                  pathname: '/system/center/detail/userDetail',
                  query: {
                    id: userId,
                  },
                })
              }
            }}
          >
            {text}
          </a>
        )
      },
    },
    // img1: {
    //   showText: '头像',
    //   showType: 'Image',
    // },
    paymentAmount: {
      showText: intl.formatMessage({ id: 'spending' }),
      showType: 'Text',
    },
    status: {
      showText: intl.formatMessage({ id: 'orderStatus' }),
      showType: 'Text',
    },
    dealTime: {
      showText: intl.formatMessage({ id: 'orderDate' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
      },
    },
  }
}

const Order = ({ hfsOrder, loading, dispatch }) => {
  const intl = useIntl()

  useEffect(() => {
    dispatch({ type: 'hfsOrder/query' })
  }, [])

  const { isOpenUp, pagination, orderList, Filter, Type, StartDate, EndDate } = hfsOrder
  const tableProps = {
    loading: loading.models.hfsOrder,

    listData: orderList.map((item) => {
      const { state, type, userName, userPhoneNumber } = item
      // 没有姓名就显示手机号
      const userNameOrPhoneNum = userName || userPhoneNumber

      return {
        ...item,
        status: {
          Created: intl.formatMessage({ id: 'created' }),
          Deal: intl.formatMessage({ id: 'completed' }),
        }[state],
        orderType: {
          Assessment: intl.formatMessage({ id: 'assessment' }),
          Course: intl.formatMessage({ id: 'course' }),
          OnlinePlanCourse: intl.formatMessage({ id: 'onlineTraining' }),
          OfflinePlanCourse: intl.formatMessage({ id: 'offlineTraining' }),
          OneToOneOnlinePlanCourse: intl.formatMessage({ id: 'OneToOneOnlinePlanCourse' }),
          OneToOneOfflinePlanCourse: intl.formatMessage({ id: 'OneToOneOfflinePlanCourse' }),
          OneToTwoOnlinePlanCourse: intl.formatMessage({ id: 'OneToTwoOnlinePlanCourse' }),
          OneToTwoOfflinePlanCourse: intl.formatMessage({ id: 'OneToTwoOfflinePlanCourse' }),
          OneToThreeOnlinePlanCourse: intl.formatMessage({ id: 'OneToThreeOnlinePlanCourse' }),
          OneToThreeOfflinePlanCourse: intl.formatMessage({ id: 'OneToThreeOfflinePlanCourse' }),
          OneToFourOnlinePlanCourse: intl.formatMessage({ id: 'OneToFourOnlinePlanCourse' }),
          OneToFourOfflinePlanCourse: intl.formatMessage({ id: 'OneToFourOfflinePlanCourse' }),
          OneToFiveOnlinePlanCourse: intl.formatMessage({ id: 'OneToFiveOnlinePlanCourse' }),
          OneToFiveOfflinePlanCourse: intl.formatMessage({ id: 'OneToFiveOfflinePlanCourse' }),
          OneToSixOnlinePlanCourse: intl.formatMessage({ id: 'OneToSixOnlinePlanCourse' }),
          OneToSixOfflinePlanCourse: intl.formatMessage({ id: 'OneToSixOfflinePlanCourse' }),
        }[type],
        userNameOrPhoneNum
      }
    }),
    listSupport: listSupport(intl),
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'hfsOrder/onTableChange', payload: data })
    },
    Actions: [
      // {
      //   showText: '详情',
      //   onClick(record) {
      //     history.push({
      //       pathname: '/system/center/detail/hfsOrderDetail',
      //       query: {
      //         id: '001'
      //       }
      //     })
      //   },
      // }
    ],
  }

  const onChange = async (value, type) => {
    switch (type) {
      case 'Filter':
        await dispatch({ type: 'hfsOrder/updateState', payload: { Filter: value } })
        break
      case 'Type':
        await dispatch({
          type: 'hfsOrder/updateState',
          payload: { Type: value, pagination: { ...pagination, current: 1 } },
        })
        onSearch()
        break
      case 'Date':
        const StartDate = value && value[0] ? getFormatDate(value[0], true) : ''
        const EndDate = value && value[1] ? getFormatDate(value[1], false) : ''
        await dispatch({
          type: 'hfsOrder/updateState',
          payload: { StartDate, EndDate, pagination: { ...pagination, current: 1 } },
        })
        onSearch()
        break
    }
  }

  const onSearch = () => dispatch({ type: 'hfsOrder/query' })

  return (
    <Card
      className={styles.order}
      title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'OrderList' })}</b>}
      bordered={false}
    >
      <div className={styles.search}>
        <div className={styles.searchTop}>
          <div className={styles.searchItem}>
            <span className={styles.searchItemLabel}>{intl.formatMessage({ id: 'keyword' })}</span>
            <Search
              placeholder={intl.formatMessage({ id: 'orderNum_products_name' })}
              style={{ height: 32, minWidth: 250 }}
              value={Filter}
              onChange={(e) => onChange(e.target.value, 'Filter')}
              onSearch={onSearch}
            />
          </div>
          <div className={styles.searchItem}>
            <span className={styles.searchItemLabel}>{intl.formatMessage({ id: 'productTypes' })}</span>
            <Select
              placeholder={intl.formatMessage({ id: 'all' })}
              style={{ minWidth: 220 }}
              value={Type}
              onChange={(value) => onChange(value, 'Type')}
            >
              <Option value="">{intl.formatMessage({ id: 'all' })}</Option>
              <Option value="Assessment">{intl.formatMessage({ id: 'assessment' })}</Option>
              <Option value="OnlinePlanCourse">{intl.formatMessage({ id: 'onlineTraining' })}</Option>
              <Option value="OfflinePlanCourse">{intl.formatMessage({ id: 'offlineTraining' })}</Option>
              <Option value="OneToManyOnlinePlanCourse">{intl.formatMessage({ id: 'oneToMoreOnlineCourse' })}</Option>
              <Option value="OneToManyOfflinePlanCourse">{intl.formatMessage({ id: 'oneToMoreOfflineCourse' })}</Option>
              <Option value="Course">{intl.formatMessage({ id: 'course' })}</Option>
            </Select>
          </div>
          <div className={styles.clear}>
            <Button onClick={() => dispatch({ type: 'hfsOrder/onClear' })}>{intl.formatMessage({ id: 'clear' })}</Button>
          </div>
          <div className={styles.openUp}>
            {isOpenUp ? (
              <a
                onClick={() => {
                  dispatch({ type: 'hfsOrder/updateState', payload: { isOpenUp: false } })
                }}
              >
                {intl.formatMessage({ id: 'putItAway' })}
              </a>
            ) : (
              <a
                onClick={() => {
                  dispatch({ type: 'hfsOrder/updateState', payload: { isOpenUp: true } })
                }}
              >
                {intl.formatMessage({ id: 'open' })}
              </a>
            )}
          </div>
        </div>
        {isOpenUp && (
          <div className={styles.searchBottom}>
            <div className={styles.searchItem}>
              <span className={styles.searchItemLabel}>{intl.formatMessage({ id: 'orderDate' })}</span>
              <RangePicker
                placeholder={[intl.formatMessage({ id: 'startDate' }), intl.formatMessage({ id: 'endDate' })]}
                value={[
                  StartDate ? moment(StartDate, dateFormat) : null,
                  EndDate ? moment(EndDate, dateFormat) : null,
                ]}
                onChange={(date) => onChange(date, 'Date')}
              />
            </div>
          </div>
        )}
      </div>
      <Button style={{ marginBottom: '20px' }} onClick={() => dispatch({ type: 'hfsOrder/downloadExcel', payload: { intl } })}>{intl.formatMessage({ id: 'exportList' })}</Button>
      <MagicTable {...tableProps} />
    </Card>
  )
}

export default connect(({ hfsOrder, loading }) => ({ hfsOrder, loading }))(Order)
