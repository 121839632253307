import { useState, useEffect } from 'react'
import { Modal, Button, Radio } from 'antd'
import { PrepareEditUser } from 'services/userManager'
import styles from './index.less'
import { IntlManager } from 'utils/helper'

const BatchApplyModal = ({ visible, selectedRowKeys, onhandleOK, onhandleCancel }) => {
    const intl = IntlManager.MyIntl()

    const [role, setRole] = useState("Coach")
    const [userRoles, setUserRoles] = useState([])

    const getUserRoles = async () => {
        const res = await PrepareEditUser({ type: 'Center' })
        if (res.response.ok) {
            const Roles = res.data.roles.filter(r => r.name !== 'CenterAdmin')
            setUserRoles(Roles)
        } else {
            const data = res.data
            const { error } = data || {}
            error && message.error(error.message)
        }
    }

    useEffect(() => {
        getUserRoles()
    }, [])

    const onOk = () => {
        onhandleOK(role)
    }

    const onCancel = () => {
        onhandleCancel()
    }

    return (
        <Modal
            title={<b style={{ fontSize: '17px' }}>{intl('batchReviewApplications')}</b>}
            open={visible}
            onOk={onOk}
            onCancel={onCancel}
            footer={null}
        >
            <div className={styles.batchApplyModal}>
                <div className={styles.batchApplyModalTitle}>{intl('batchThesePeople', { name: selectedRowKeys && selectedRowKeys.length })}</div>
                <div className={styles.batchApplyModalRole}>
                    <span><span style={{ color: 'red' }}>*</span> {intl('useRole')} </span>
                    <Radio.Group onChange={(e) => setRole(e.target.value)} value={role}>
                        {userRoles.map(item => {
                            return (
                                <Radio key={item.id} value={item.name}>{item.displayName}</Radio>
                            )
                        })}
                    </Radio.Group>
                </div>
                <div className={styles.batchApplyRemark}>{intl('rolesPeopleAreInconsistent')}</div>
                <div className={styles.batchApplyModalBottom}>
                    <Button style={{ width: '100px' }} onClick={() => onCancel()}>{intl("cancel")}</Button>
                    <Button type="primary" style={{ width: '100px', marginLeft: '10px' }} onClick={() => onOk()}>{intl("submitOther")}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default BatchApplyModal