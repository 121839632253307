import React from 'react'
import styles from './Report.less'
import { useIntl, getLocale } from 'umi'
import brainPic from 'assets/vratReportImg/brain4_pic.png'
import brainPicCN from 'assets/vratReportImg/brain4_pic_cn.png'
import kidPic from 'assets/vratReportImg/kid_pic_without_tracker.png'
import PoweredBy from 'components/VratChartComponents/poweredBy'

const MotionIndex = ({ showNewReport }) => {
  const showImg = getLocale() === 'zh-CN' ? brainPicCN : brainPic

  const intl = useIntl()
  return (
    <div id="motionIndex" className={`${styles.page_div}`}>
      <h2 className={styles.page_title}>
        <p className={styles.eTitle}>{intl.formatMessage({ id: 'report.motionIndex.title' })}</p>
      </h2>
      <div className={styles.motionIndexContent}>
        <img className={styles.kidImg} src={kidPic} alt="kid" />
        <div className={styles.pDesc}>
          <p style={{ marginBottom: '10px' }}>{intl.formatMessage({ id: 'report.motionIndex.right.desc1' })}</p>
          <p>{intl.formatMessage({ id: 'report.motionIndex.right.desc2' })}</p>
        </div>
      </div>
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>{showNewReport ? '6' : '6'}</p>
    </div>
  )
}

export default MotionIndex
