import React, { useEffect, useState } from 'react';
import { Input, Space, Button, Card } from 'antd';
import { useIntl, connect } from 'umi';
import moment from 'moment';

import {
  GetTrainerHfsData,
  DownLoadTrainerHfsDataExcel,
} from 'services/rocketService/Analysis';

import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import download from 'cognitiveleap-core-us/utils/download';

import { listSupport } from './config';

const { Search } = Input;

const TrainerAnalysis = ({ currentUser }) => {
  const { canChangeList } = currentUser;

  const intl = useIntl();
  const [dataSource, setDataSource] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
  });
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: '全部',
  });

  const changedTenantList = (id) => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    if (id) {
      const newCurrentTenant = canChangeList.find((item) => item.id == id);
      setCurrentTenant({ ...newCurrentTenant });
    } else {
      setCurrentTenant({ id: '', displayName: '全部' });
    }
  };

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = await GetTrainerHfsData({
      MaxResultCount: pagination.pageSize,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      Filter: keyword,
      TenantId: currentTenant.id,
    });

    if (res.response.ok) {
      const { items, totalCount } = res.data;

      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      showErrorMsg(res, intl);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [keyword, pagination.pageSize, pagination.current, currentTenant.id]);

  const tableProps = {
    listData: dataSource,
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
  };

  const buttonClick = async () => {
    setLoading({
      ...loading,
      buttonLoading: true,
    });

    const res = await DownLoadTrainerHfsDataExcel({
      MaxResultCount: pagination.pageSize,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      Filter: keyword,
      TenantId: currentTenant.id,
    });

    if (res.response.ok) {
      const { data } = res;
      download(data, `训练师分析+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`);
    } else {
      showErrorMsg(res, intl);
    }

    setLoading({
      ...loading,
      buttonLoading: false,
    });
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>训练师分析</b>}
      bordered={false}
      extra={
        <AnalysisTenantSelect
          showAll={true}
          currentTenant={currentTenant}
          onChange={changedTenantList}
        />
      }
    >
      <Space>
        <Search
          placeholder="训练师姓名"
          onSearch={(value) => {
            setPagination({
              current: 1,
              pageSize: 10,
              total: 0,
            });
            setKeyword(value);
          }}
          style={{ width: 200 }}
        />
        <Button loading={loading.buttonLoading} onClick={buttonClick}>
          下载列表
        </Button>
      </Space>
      <p />
      <MagicTable {...tableProps} />
    </Card>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(TrainerAnalysis);
