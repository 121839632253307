import { useState, useEffect } from 'react';
import { message } from 'antd';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils';

import { PostTemplate, GetTemplateDetail, PutTemplate } from 'services/adhd';

const modalTypeToTitle = {
  edit: 'Edit Template',
  add: 'Add Template',
};

let contentMenu = {
  Properties: [
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'Core Session',
            Id: 'CoreSession',
          },
          {
            Value: 'Learning',
            Id: 'Learning',
          },
        ],
        Required: true,
      },
      ShowTitle: 'type',
      FormKey: 'type',
      Description: '',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'name',
      FormKey: 'name',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: 'Up to 100 characters (including Chinese and English)',
    },
    {
      EditorType: 'Input',
      ShowTitle: 'displayName',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: null,
      Setting: {
        Required: true,
      },
      Description: 'Up to 100 characters (including Chinese and English)',
    },
    {
      EditorType: 'CheckboxSelector',
      Value: null,
      Setting: {
        DropdownOptions: [
          {
            Value: 'ADHD',
            Id: 'ADHD',
          },
          {
            Value: 'Anxiety',
            Id: 'Anxiety',
          },
          {
            Value: 'Trauma',
            Id: 'Trauma',
          },
          {
            Value: 'Wellness',
            Id: 'Wellness',
          },
        ],
        Required: true,
      },
      ShowTitle: 'disorder',
      FormKey: 'disorders',
      Description: 'Classification',
    },
    {
      EditorType: 'RichEditor',
      ShowTitle: 'description',
      FormKey: 'description',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
  ],
};

const TemplateModal = ({
  modalVisible,
  modalType,
  currentItem,
  onClose,
  reloadTable,
}) => {
  const isEdit = modalType === 'edit' && Object.keys(currentItem).length > 0;
  const { id } = currentItem || {};

  const [currentContent, setCurrentContent] = useState(contentMenu);
  const [loading, setLoading] = useState({
    initLoading: false,
    modalLoading: false,
  });

  const initData = async () => {
    if (isEdit) {
      let showForm = contentMenu;

      const res = await GetTemplateDetail({ id });

      if (res.response.ok) {
        showForm = matchDynamicForm(contentMenu, {
          ...res?.data,
        });
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      setCurrentContent(showForm);
    }

    setLoading({
      ...loading,
      initLoading: true,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const modalProps = {
    loading: loading.modalLoading,
    title: modalTypeToTitle[modalType],
    visible: modalVisible,
    onCancel() {
      onClose();
    },
    onOk: async (value) => {
      setLoading({
        ...loading,
        modalLoading: true,
      });

      const res = isEdit
        ? await PutTemplate({
            id,
            ...value,
          })
        : await PostTemplate(value);

      if (res.response.ok) {
        onClose();
        reloadTable();
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      setLoading({
        ...loading,
        modalLoading: false,
      });
    },
    content: currentContent,
  };

  return loading.initLoading && <MagicFormModal {...modalProps} />;
};

export default TemplateModal;
