import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Spin } from 'antd';
import { history, connect, useIntl } from 'umi';
import { GetChildListDetail } from 'services/group';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import ChartsDrawer from 'components/ClassGroupModal/ChartsDrawer';

const leftTempSupport = (intl) => {
  return {
    userName: {
      showText: intl.formatMessage({ id: 'subjectName'}),
      showType: 'Text',
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              history.push({
                pathname: '/system/center/detail/subjectDetail',
                query: {
                  id: record.id,
                },
              });
            }}
          >
            {text}
          </a>
        );
      },
    },
    gender: {
      showText: intl.formatMessage({ id: 'gender'}),
      showType: 'Text',
      render: (text, record) => {
        if (text === 'Female') {
          return intl.formatMessage({ id: 'female'});
        } else if (text === 'Male') {
          return intl.formatMessage({ id: 'male'});
        } else {
          return intl.formatMessage({ id: 'unknown'});
        }
      },
    },
    ageStage: {
      showText: intl.formatMessage({ id: 'stage'}),
      showType: 'Text',
      render: (text, record) => {
        if (text === 'PreSchool') {
          return intl.formatMessage({ id: 'preschool'});
        } else if (text === 'GradeSchool') {
          return intl.formatMessage({ id: 'gradeSchool'});
        } else if (text === 'TeenSchool') {
          return intl.formatMessage({ id: 'teenage'});
        } else if (text === 'GradeOrTeenSchool') {
          return intl.formatMessage({ id: 'grade_teenage'});
        }
        return intl.formatMessage({ id: 'other'});
      },
    },
    birthday: {
      showText: intl.formatMessage({ id: 'birthday'}),
      showType: 'Time',
    },
    creationTime: {
      showText: intl.formatMessage({ id: 'participationTime'}),
      showType: 'Time',
    },
  };
}

const rightListSupport = (intl) => {
  return {
    name: {
      showText: intl.formatMessage({ id: 'trainingPlanName'}),
      showType: 'Text',
    },
    currentNum: {
      showText: intl.formatMessage({ id: 'progress'}),
      showType: 'Text',
    },
    status: {
      showText: intl.formatMessage({ id: 'status'}),
      showType: 'Text',
      render: (text, record) => {
        if (text === 'Started') {
          return intl.formatMessage({ id: 'ongoing'});
        } else if (text === 'Created') {
          return intl.formatMessage({ id: 'created'});
        } else {
          return intl.formatMessage({ id: 'finished'});
        }
      },
    },
    startTime: {
      showText: intl.formatMessage({ id: 'startTime'}),
      showType: 'Time',
    },
    endTime: {
      showText: intl.formatMessage({ id: 'endTime'}),
      showType: 'Time',
    },
  };
}

const { TabPane } = Tabs;

const ExpandedTable = ({ allData, currentUser }) => {
  const {
    changedTenant: { institutionType },
  } = currentUser || {};

  const intl = useIntl();

  const [groupLinkUsers, setGroupLinkUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { groupTrainingPlans = [], id } = allData;

  const currentRecord = useRef(null);

  const initData = async () => {
    setLoading(true);
    const res = await GetChildListDetail({
      groupId: id,
    });

    if (res.response.ok) {
      setGroupLinkUsers(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const leftListSupport =
    institutionType === 'Medical'
      ? {
          outpatientNumber: {
            showText: intl.formatMessage({ id: 'clinicNumber'}),
            showType: 'Text',
          },
          ...leftTempSupport(intl),
        }
      : leftTempSupport(intl);

  const leftTableProps = {
    listData: groupLinkUsers.map((item) => {
      const { subjectInfoReturnModel = {} } = item;
      const { gender } = subjectInfoReturnModel;
      return {
        ...item,
        gender,
      };
    }),
    listSupport: leftListSupport,
    Actions: [
      {
        showText: intl.formatMessage({ id: 'detail'}),
        itemRender(record) {
          return (
            <a
              onClick={() => {
                history.push({
                  pathname: '/system/center/detail/subjectDetail',
                  query: {
                    id: record.id,
                  },
                });
              }}
            >
              {intl.formatMessage({ id: 'detail'})}
            </a>
          );
        },
      },
    ],
  };

  const rightTableProps = {
    listData: groupTrainingPlans.map((item) => {
      const { trainingPlanV2 } = item;
      const { displayName: name } = trainingPlanV2;
      return {
        ...item,
        name,
      };
    }),
    listSupport: rightListSupport(intl),
    Actions: [
      {
        showText: intl.formatMessage({ id: 'charts_feedback'}),
        itemRender(record) {
          return (
            <a
              onClick={() => {
                currentRecord.current = record;
                setVisible(true);
              }}
            >
              {intl.formatMessage({ id: 'charts_feedback'})}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'trainingOverview'}),
        itemRender(record) {
          const { groupId, id, name } = record;
          return (
            <a
              onClick={() => {
                history.push({
                  pathname: '/system/center/detail/groupOfflinePlanDetail',
                  query: {
                    id,
                    displayName: encodeURIComponent(name),
                    groupId
                  },
                });
              }}
            >
              {intl.formatMessage({ id: 'trainingOverview'})}
            </a>
          );
        },
      },
    ],
  };

  const chartDrawerProps = {
    visible,
    onClose: () => {
      setVisible(false);
    },
    currentNum: currentRecord?.current?.currentNum,
    currentTablePlan:
      currentRecord.current && currentRecord.current.trainingPlanV2,
    GroupTrainingPlanId: currentRecord.current && currentRecord.current.id,
  };

  return (
    <>
      <Tabs defaultActiveKey="1" type="card" size="small">
        <TabPane tab={intl.formatMessage({ id: 'subject'})} key="1">
          <Spin spinning={loading}>
            <MagicTable {...leftTableProps} />
          </Spin>
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'trainingPlan'})} key="2">
          <MagicTable {...rightTableProps} />
        </TabPane>
      </Tabs>
      {visible && <ChartsDrawer {...chartDrawerProps} />}
    </>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  ExpandedTable,
);
