import { message } from 'antd'
import {
    GetTrainerModuleList,
    GetTrainerModuleDetail,
    GetTrainingApplication,
    GetProfile,
    GetQualification,
    GetQuestionnaire,
    GetTrainerDetail,
    GetTrainCenterList,
    GetCourseList,
    GetTrainingDetail
} from 'services/trainingSystem'
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'

export default {
    namespace: 'trainingStaffDetail',
    state: {
        userId: '',
        userInfo: null,
        drawerVisible: false,
        moduleList: [],
        tabkey: '',
        questionData: null,
        // medalInfo: null,
        moduleDetail: null,
        activeContent: null,
        activeCollapseKey: '',
        activeClassInfo: null,
        showQuestionAnswerData: null,
        showAnswerVisible: false,
        trainerDetail: null
    },
    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },
    effects: {
        *query({ payload }, { call, put, select }) {
            const { id } = payload
            yield put({ type: 'updateState', payload: { userId: id } })
            yield put({ type: 'getUserInfo' })
            yield put({ type: 'getQuestionData' })
            yield put({ type: 'getModuleList' })
            // yield put({ type: 'getQualification' })
            yield put({ type: 'getTrainerDetail' })
        },

        *getTrainerDetail({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.trainingStaffDetail)
            const res = yield call(GetTrainerDetail, { trainerId: userId })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { trainerDetail: res.data } })
            }
        },

        *getUserInfo({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.trainingStaffDetail)
            const res = yield call(GetProfile, { id: userId })
            yield put({ type: 'updateState', payload: { userInfo: null } })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { userInfo: res.data } })
            }
        },

        *getModuleList({ payload }, { call, put, select }) {
            const { tabkey, userId } = yield select(state => state.trainingStaffDetail)
            // const res = yield call(GetTrainerModuleList, { TrainerId: userId })
            const res = yield call(GetTrainCenterList, { TrainerId: userId })
            if (res.response.ok) {
                if (res.data && res.data.length > 0) {
                    const data = res.data
                    const newTabkey = tabkey || (data && data[0] && data[0].id || '')
                    yield put({ type: 'updateState', payload: { moduleList: data, tabkey: newTabkey } })
                    yield put({ type: 'getModuleDetail' })
                }
            }
        },

        *getModuleDetail({ payload }, { call, put, select }) {
            const { tabkey: stateKey, moduleList, trainerDetail, userId } = yield select(state => state.trainingStaffDetail)
            // const params = { TrainerTrainingModuleId: tabkey, TrainerId: userId }
            // const res = yield call(GetTrainerModuleDetail, params)
            const tabkey = payload || stateKey
            const currentModule = moduleList.find(item => item.id === tabkey)
            const { trainingModuleId } = currentModule
            const res = yield call(GetCourseList, { TrainingModuleId: trainingModuleId, TrainerTrainingId: tabkey })
            if (res.response.ok) {
                const { id, issued, startDate, endDate, status, trainingOrder, trainingModule, lastUserExaminationPaperId, trainingModuleId } = currentModule
                const { trainerCredentialses = [] } = trainerDetail || {}
                const currentTrainer = trainerCredentialses.filter(item => item.trainingModuleId === trainingModuleId)
                const newData = {
                    id,
                    issued,
                    startDate,
                    endDate,
                    status,
                    trainerId: userId,
                    trainingOrder,
                    trainingModule: {
                        ...trainingModule,
                        courses: res.data,
                    },
                    trainer: {
                        trainerCredentialses: currentTrainer
                    },
                    lastUserExaminationPaperId
                }
                yield put({ type: 'updateState', payload: { moduleDetail: newData } })
                // yield put({ type: 'updateState', payload: { moduleDetail: res.data } })
            }
        },

        *getQuestionData({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.trainingStaffDetail)
            const res = yield call(GetTrainingApplication, { TrainerId: userId })
            if (res.response.ok) {
                yield put({ type: 'updateState', payload: { questionData: res.data } })
            }
        },

        // *getQualification({ payload }, { call, put, select }) {
        //     const res = yield call(GetQualification, { SkipCount: 0, MaxResultCount: 1000 })
        //     if (res.response.ok) {
        //         yield put({ type: 'updateState', payload: { medalInfo: res.data.items } })
        //     }
        // },

        *onChangeTabs({ payload }, { call, put, select }) {
            const { userId } = yield select(state => state.trainingStaffDetail)
            yield put({ type: 'updateState', payload: { tabkey: payload } })
            // yield put({ type: 'query', payload: { id: userId } })
        },

        *onClickItem({ payload }, { call, put, select }) {
            const { courseId } = payload || {}
            const { moduleDetail } = JSON.parse(JSON.stringify(yield select(state => state.trainingStaffDetail)))
            const { id: moduleId } = moduleDetail || {}
            const res = yield call(GetTrainingDetail, { CourseId: courseId, TrainerTrainingId: moduleId })
            if (res.response.ok) {
                const course = res.data

                let { stepIndex, contents } = course || {}
                let newActiveContent = null
                let currentIndex = 0
                let defaultStep = null
                // 判断返回值是否存在userCourseDetail属性值，如果都不存在，默认打开第一个
                const hasUserCourseDetail = contents.find(item => item.userCourseDetail)
                const hasUserCourseDetailData = contents.filter(item => item.userCourseDetail)
                const hasnotUserCourseDetailData = contents.filter(item => !item.userCourseDetail)
                // 如果存在hasUserCourseDetail，就找isFinish和isPass其中有一个不为true的值就是默认第一个，如果都为true，则第一个没有hasUserCourseDetail就是默认第一个
                if (hasUserCourseDetail) {
                    const eithorData = hasUserCourseDetailData.find(item => item.userCourseDetail.isFinish && !item.userCourseDetail.isPass)
                    if (eithorData) {
                        newActiveContent = eithorData
                    } else {
                        newActiveContent = (hasnotUserCourseDetailData && hasnotUserCourseDetailData[0]) || (hasUserCourseDetailData && hasUserCourseDetailData[hasUserCourseDetailData.length - 1])
                    }
                } else {
                    newActiveContent = contents && contents[0] || null
                }
                currentIndex = contents.findIndex(item => newActiveContent.id === item.id)
                // 标记是否上锁
                contents && contents.forEach((item, index) => {
                    if (index === currentIndex) {
                        item.isLocked = false
                    } else if (!item.userCourseDetail) {
                        item.isLocked = true
                    }
                })
                // step后端没有ID,为了正常切换，我加了index作为切换得id,这样就可以打开当前得章节
                defaultStep = stepIndex.find((item, index) => {
                    item.index = index
                    return item.contentId.includes(newActiveContent && newActiveContent.id)
                })

                yield put({
                    type: 'updateState',
                    payload: {
                        activeContent: newActiveContent,
                        activeCollapseKey: String(defaultStep && defaultStep.index),
                        itemDrawerVisible: true,
                        activeClassInfo: course
                    }
                })
            }
        },

        *createQuestionnaire({ payload }, { take, put, call, select }) {
            const { userCourseDetail } = payload || {}
            const { userQuestionnaireId } = userCourseDetail || {}
            const params = {
                id: userQuestionnaireId
            }
            const res = yield call(GetQuestionnaire, params)
            if (res.response.ok) {
                const data = res.data
                const { questionnaireAndAnswer } = data || {}
                const { answer, questionnaire } = questionnaireAndAnswer || {}
                const { answerContent } = answer || {}
                const { correctRate, jsonString } = questionnaire || {}
                yield put({
                    type: 'updateState',
                    payload: {
                        showQuestionAnswerData: {
                            data,
                            currentContent: payload
                        },
                        correntRateAndResult: getCorrentRateAndResult(jsonString, answerContent, correctRate),
                        showAnswerVisible: true
                    }
                })
            } else {
                const { error } = res.data
                const { message: errorInfo } = error || {}
                errorInfo && message.error(errorInfo)
            }
        },

        *clear({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { userInfo: null } })
        },
    },
    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(async location => {
        //         if (location.pathname === '/system/center/detail/trainingStaffDetail') {
        //             await dispatch({ type: 'updateState', payload: { moduleList: [], tabkey: '' } })
        //             dispatch({ type: 'query', payload: location.query })
        //         }
        //     })
        // },
    },
}
