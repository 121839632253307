import React, { useEffect } from 'react'
import moment from 'moment'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import PersonalCenterCard from 'components/PersonalCenterCard'
import Question from 'components/Question'
import { Button, Card, Drawer, Modal, Spin, Tabs, Dropdown, Menu, message } from 'antd'
import { PlusOutlined, EditOutlined, DownOutlined } from '@ant-design/icons';
import { getCorrentRateAndResult, eventDebounce } from 'cognitiveleap-core-us/utils/utils'
import { connect, history, useIntl } from 'umi'
import { getScore } from 'utils/utils'
import styles from './index.less'

const { TabPane } = Tabs;

const PersonalCenter = ({ personalCenter, dispatch, loading }) => {
    const intl = useIntl()

    const {
        isEdit,
        moduleList,
        tabkey,
        moduleDetail,
        questionData,
        userInfo,
        submitData,
        drawerVisible,
        currentAssmessmentIsFinshed,
        questionDetail,
        trainerDetail,
        showSupervisionVisible,
        supervisionTabActiveKey,
        eidtSupervisionVisible,
        supervisorQuestion,
        supervisorSubmitData,
        supervisorIsFinshed,
        questionnaireListData,
        currentTrainerSummaryId,
        currentSupervisorId,
        supervisionQuestionDetail,
        payUrl,
        payStatusPollingRes,
        contentLoading,
    } = personalCenter

    const { checkedId } = history.location.query

    const newKey = checkedId || tabkey

    useEffect(() => {
        dispatch({
            type: 'personalCenter/updateState',
            payload: {
                payStatusPollingRes: null,
                payUrl: '',
            }
        })
        dispatch({ type: 'personalCenter/query' })
    }, [])

    const { finishTime, questionnaireAndAnswer: applyQuestionnaireAndAnswer } = questionDetail || {}
    const { answer: applyAnswer } = applyQuestionnaireAndAnswer || {};
    const { questionnaireAndAnswer } = questionData || {}
    const { questionnaire, answer } = applyQuestionnaireAndAnswer || questionnaireAndAnswer || {}
    const { jsonString = "{}", correctRate } = questionnaire || {}
    const { answerContent } = submitData || answer || {}

    const { items } = questionnaireListData || {}
    const { items: subItems } = items || {}

    let currentId = ''
    if (supervisionTabActiveKey === "TrainerSummary") {
        currentId = currentTrainerSummaryId
    } else {
        currentId = currentSupervisorId
    }
    const currentQuestion = subItems ? subItems.find(item => item.id === currentId) : null

    // 填写问卷
    const { questionnaireAndAnswer: supervisorQuestionnaireAndAnswer } = supervisorQuestion || {}
    const { questionnaire: supervisorQuestionnaire, answer: supervisorAnswer } = supervisorQuestionnaireAndAnswer || {}
    const { jsonString: supervisorJsonString } = supervisorQuestionnaire || {}
    const { answerContent: supervisorAnswerContent } = supervisorSubmitData || {}

    // 展示问卷
    const { questionnaireAndAnswer: supervisionDetaiQuestionnaireAndAnswerl } = supervisionQuestionDetail || {}
    const { answer: supervisionDetaiAnswer, questionnaire: supervisionDetaiQuestionnaire } = supervisionDetaiQuestionnaireAndAnswerl || {}
    const { jsonString: supervisionDetaiJsonString, correctRate: supervisionDetaiCorrectRate } = supervisionDetaiQuestionnaire || {}
    const { answerContent: supervisionDetaiAnswerContent } = supervisionDetaiAnswer || {}


    let questionArr = [], correntRateAndResult = null
    if (supervisionQuestionDetail) {
        correntRateAndResult = getCorrentRateAndResult(supervisionDetaiJsonString, supervisionDetaiAnswerContent, supervisionDetaiCorrectRate)
        questionArr = correntRateAndResult && correntRateAndResult.questionArr
    } else {
        questionArr = []
    }

    const getAnswerDom = () => {
        // const { answer, questionnaire } = applyQuestionnaireAndAnswer || {}
        // const { answerContent } = answer || {};
        // const { jsonString, correctRate } = questionnaire || {}
        const { questionArr = [], } = getCorrentRateAndResult(jsonString, answerContent, correctRate) || {};
        const showSurveyProps = {
            contents: questionArr,
            choice: answerContent || null,
            showIcon: false
        }
        return <ShowSurvey {...showSurveyProps} />
    }

    const personalCenterCardProps = {
        userInfo,
        tabkey: newKey,
        trainerDetail,
        tabList: moduleList,
        detail: moduleDetail,
        isFininshed: finishTime,
        roleType: 'personal',
        payUrl,
        loading: contentLoading,
        payStatusPollingRes,
        applyDrawerVisible: drawerVisible,
        onClickItem(item) {
            history.push({
                pathname: '/system/center/detail/trainingDetail',
                query: {
                    moduleId: newKey,
                    courseId: item.courseId
                }
            })
        },
        onChangeTabs(key) {
            if (checkedId) {
                history.location.query.checkedId = null
            }
            dispatch({ type: 'personalCenter/onChangeTabs', payload: key })
            // eventDebounce(() => {
            dispatch({ type: 'personalCenter/getModuleDetail', payload: key })
            // }, 800)
        },
        showTrainingApplication(type) {
            if (applyAnswer && type != 'new') {
                dispatch({ type: 'personalCenter/updateState', payload: { drawerVisible: true } })
            } else {
                dispatch({ type: 'personalCenter/postTrainingApplication' })
            }
        },
        startStudy() {
            dispatch({ type: 'personalCenter/startStudy', payload: { intl }})
        },
        showSupervision() {
            dispatch({ type: 'personalCenter/showSupervision' })
        },
        goToPay(value) {
            dispatch({ type: 'personalCenter/goToPay', payload: value })
        }
    }

    const onComplete = ({ answerContent, isComplete, finishedPage }) => {
        if (isComplete) {
            Modal.confirm({
                title: <div style={{ color: 'red' }}>{intl.formatMessage({ id: 'submitDes' })}</div>,
                okText: intl.formatMessage({ id: 'submit' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk: () => {
                    dispatch({ type: 'personalCenter/updateState', payload: { currentAssmessmentIsFinshed: true } })
                    dispatch({ type: 'personalCenter/submit', payload: { answerContent, isComplete, finishedPage, intl } })
                }
            })
            return
        } else {
            dispatch({ type: 'personalCenter/submit', payload: { answerContent, isComplete, finishedPage, intl } })
        }
    }

    const onValueChange = ({ answerContent, isComplete, finishedPage, needSubmit }) => {
        dispatch({ type: 'personalCenter/updateState', payload: { submitData: { answerContent, isComplete, finishedPage } } })
        if (needSubmit) {
            onComplete({ answerContent, isComplete, finishedPage })
        }
    }

    const onClose = () => {
        if (!currentAssmessmentIsFinshed && submitData) {
            onComplete(submitData)
        }
        dispatch({ type: 'personalCenter/updateState', payload: { drawerVisible: false, isEdit: false } })
    }

    const questionProps = {
        content: jsonString ? JSON.stringify(Object.assign({}, JSON.parse(jsonString), { completeText: intl.formatMessage({ id: 'submitOther' }) })) : '',
        answerContent: answerContent || '',
        answer: answer || '',
        onComplete: onComplete,
        onValueChange: onValueChange
    }

    // 见习督导小结
    const supervisorOnComplete = ({ answerContent, isComplete, finishedPage }) => {
        if (isComplete) {
            Modal.confirm({
                title: <div style={{ color: 'red' }}>{intl.formatMessage({ id: 'submitDes' })}</div>,
                okText: intl.formatMessage({ id: 'submit' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk: () => {
                    dispatch({ type: 'personalCenter/updateState', payload: { supervisorIsFinshed: true } })
                    dispatch({ type: 'personalCenter/supervisorSubmit', payload: { answerContent, isComplete, finishedPage, intl } })
                }
            })
            return
        } else {
            dispatch({ type: 'personalCenter/supervisorSubmit', payload: { answerContent, isComplete, finishedPage, intl } })
        }
    }

    const supervisorValueChange = ({ answerContent, isComplete, finishedPage, needSubmit }) => {
        dispatch({ type: 'personalCenter/updateState', payload: { supervisorSubmitData: { answerContent, isComplete, finishedPage } } })
        if (needSubmit) {
            supervisorOnComplete({ answerContent, isComplete, finishedPage })
        }
    }

    const supervisorOnClose = () => {
        if (!supervisorIsFinshed && supervisorSubmitData) {
            supervisorOnComplete(supervisorSubmitData)
        }
        dispatch({ type: 'personalCenter/onCloseEidtSupervision' })
    }

    const supervisorQuestionProps = {
        content: supervisorJsonString ? supervisorJsonString : '',
        answerContent: supervisorAnswerContent || '',
        answer: supervisorAnswer,
        onComplete: supervisorOnComplete,
        onValueChange: supervisorValueChange
    }

    const onChangSupervisionTabs = (value) => {
        dispatch({ type: 'personalCenter/onChangSupervisionTabs', payload: value })
    }

    const showSurveyProps = {
        contents: questionArr,
        choice: supervisionDetaiAnswerContent ? JSON.parse(JSON.stringify(supervisionDetaiAnswerContent)) : {},
        showIcon: false
    }

    const menuList = subItems && subItems.length > 0 ? (
        <Menu>
            {
                subItems.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={() => dispatch({ type: 'personalCenter/clickMenuItem', payload: item })}>{moment(item.finishTime).format("YYYY/MM/DD HH:mm")}</Menu.Item>
                    )
                })
            }
        </Menu>
    ) : null

    return (
        <Card
            bordered={false}
            title={<b className={styles.title}>{intl.formatMessage({ id: 'trainCenter' })}</b>}
        >
            <PersonalCenterCard
                {...personalCenterCardProps}
            />
            <Drawer
                title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'trainingApplicationForm' })}</b>}
                open={drawerVisible}
                width={700}
                onClose={onClose}
            >
                {
                    applyAnswer && !isEdit ?
                    <>
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                type="primary"
                                onClick={() => dispatch({ type: 'personalCenter/updateState', payload: { isEdit: true } })}
                            >
                                {intl.formatMessage({ id: 'edit' })}
                            </Button>
                            <p />
                        </div>
                        {getAnswerDom()}
                    </> :
                    <Question {...questionProps} />
                }
            </Drawer>

            {/* 展示见习督导记录 */}
            <Drawer
                title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'supervisionReport' })}</b>}
                width={700}
                open={showSupervisionVisible}
                onClose={() => dispatch({ type: 'personalCenter/updateState', payload: { showSupervisionVisible: false } })}
            >
                <Spin spinning={loading.models.personalCenter}>
                    <Tabs activeKey={supervisionTabActiveKey} onChange={onChangSupervisionTabs}>
                        <TabPane tab={intl.formatMessage({ id: 'supervisorRecords' })} key="Supervisor"></TabPane>
                        <TabPane tab={intl.formatMessage({ id: 'coachRecords' })} key="TrainerSummary"></TabPane>
                    </Tabs>
                    <div className={styles.supervisionEditBtn}>
                        <div className={styles.supervisionEditBtnLeft}>
                            {
                                supervisionTabActiveKey === "Supervisor" && subItems && subItems.length > 0 &&
                                <span>{intl.formatMessage({ id: 'score' })}{getScore(correntRateAndResult, supervisionDetaiAnswer)}</span>
                            }
                        </div>
                        <div className={styles.supervisionEditBtnRight}>
                            {
                                supervisionTabActiveKey === "TrainerSummary" &&
                                <Button icon={<PlusOutlined />} onClick={() => dispatch({ type: 'personalCenter/eidtSupervision' })}>{intl.formatMessage({ id: 'summaryOfProbationSupervision' })}</Button>
                            }
                            {/* {
                                supervisionTabActiveKey === "TrainerSummary" && currentTrainerSummaryId !== '' &&
                                <div style={{ marginRight: "20px" }}>
                                    <Button icon={<EditOutlined />} onClick={() => dispatch({ type: 'personalCenter/editSupervision' })}>{intl.formatMessage({ id: 'edit' })}</Button>
                                </div>
                            } */}
                            {
                                subItems && subItems.length > 0 &&
                                supervisionTabActiveKey === "SupervisionReview" && subItems && subItems.length == 0 &&
                                <Button icon={<PlusOutlined />} onClick={() => dispatch({ type: 'personalCenter/addSupervision' })}>{intl.formatMessage({ id: 'supervisionReview' })}</Button>
                            }
                            {/* {
                                supervisionTabActiveKey === "SupervisionReview" && currentSupervisionReviewId !== '' &&
                                <div style={{ marginRight: "20px" }}>
                                    <Button icon={<EditOutlined />} onClick={() => dispatch({ type: 'personalCenter/editSupervision' })}>{intl.formatMessage({ id: 'edit' })}</Button>
                                </div>
                            } */}
                            {
                                subItems && subItems.length > 0 && supervisionTabActiveKey !== "SupervisionReview"  &&
                                <div style={{ marginRight: "20px" }}>
                                    <Dropdown overlay={menuList}>
                                        <a>{currentQuestion ? moment(currentQuestion.finishTime).local().format("YYYY/MM/DD HH:mm") : ''}</a>
                                    </Dropdown>
                                    <DownOutlined style={{ marginLeft: "5px" }} />
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: '25px' }}>
                        <ShowSurvey {...showSurveyProps} />
                    </div>
                </Spin>
            </Drawer>

            {/* 填写见习督导小结 */}
            {
                supervisorQuestion && <Drawer
                    title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'summaryOfProbationSupervision' })}</b>}
                    width={700}
                    open={eidtSupervisionVisible}
                    onClose={supervisorOnClose}
                >
                    <Question {...supervisorQuestionProps} />
                </Drawer>
            }

        </Card>
    )
}

export default connect(({ personalCenter, loading }) => ({ personalCenter, loading }))(PersonalCenter)