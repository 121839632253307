import React from 'react'
import { Divider, Badge } from 'antd'
import styles from '../../detail.less'
import VideoPlayer from 'components/VideoReact/VideoPlayer'
import ReportRichText from 'components/ReportRichText/ReportRichText'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

class TrainingProgramContent extends React.Component {
  canShowVideoPlayer() {
    const programInfo = this.props
    let url = ''
    if (programInfo !== null) {
      url = programInfo.videos.length > 0 ? programInfo.videos[0].url : ''
    }
    return url !== ''
  }

  componentDidMount() {
    // instantiate Video.js
    if (this.canShowVideoPlayer()) {
      this.player = videojs(`my-video-${this.props.index}`, {}, function onPlayerReady() {
        // console.log('onPlayerReady', this)
      })
    }
  }

  componentDidUpdate() {
    const programInfo = this.props
    const url = programInfo.videos.length > 0 ? programInfo.videos[0].url : ''
    const posterUrl = programInfo.images.length > 0 ? programInfo.images[0].url : ''
    if (this.canShowVideoPlayer()) {
      if (this.player) {
        this.player.src(url)
        this.player.poster(posterUrl)
      } else {
        this.player = videojs(`my-video-${this.props.index}`, {}, function onPlayerReady() {
          // console.log('onPlayerReady', this)
        })
        /// 这边不手动更新也可以，因为video组件会重新渲染，可以拿到props里面的值
        // this.player.src(url)
        // this.player.poster(posterUrl)
      }
    } else {
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
      this.player = null
    }
  }

  onTouchStart() {
    if (this.player.paused()) {
      this.player.play()
    } else {
      this.player.pause()
    }
  }

  /// type: String Number  Boolean  Duration  Rating
  switchFeedbackType(feedbackProperty) {
    switch (feedbackProperty.type) {
      case 'String':
        return `(${feedbackProperty.extraProperties.minLength}~${feedbackProperty.extraProperties.maxLength})`
      case 'Number':
      case 'Duration':
      case 'Rating':
        return `(${feedbackProperty.extraProperties.minValue}~${feedbackProperty.extraProperties.maxValue})`
      default:
        return ''
    }
  }

  render() {
    const programInfo = this.props
    let videoJsOptions = {
      url: '',
      poster: '',
      onTouchStart: this.onTouchStart,
      videoId: `my-video-${programInfo.index}`,
    }

    if (programInfo != null) {
      if (programInfo.videos.length > 0) {
        videoJsOptions.url = programInfo.videos[0].url
      }
      if (programInfo.images.length > 0) {
        videoJsOptions.poster = programInfo.images[0].url
      }
    }

    const info = programInfo => {
      if (programInfo === null) return null
      return (
        <div>
          {/* 编号 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>编号 : </span>
            <span>{programInfo.code != null ? programInfo.code : ''}</span>
          </div>

          {/* 训练目标 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>训练目标 : </span>
            <span>
              {programInfo.primaryTarget != null ? programInfo.primaryTarget.displayName : ''}
            </span>
          </div>

          {/* 预计训练时长(分钟) */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>预计训练时长(分钟) : </span>
            <span>
              {programInfo.suggestedTimeLength != null ? programInfo.suggestedTimeLength : ''}
            </span>
          </div>

          {/* 适合年龄 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>适合年龄 : </span>
            <span>
              {`${programInfo.agePeriod != null ? programInfo.agePeriod.start : ''}-${
                programInfo.agePeriod != null ? programInfo.agePeriod.end : ''
              }`}
            </span>
          </div>

          {/* 标签 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>标签 : </span>
            {programInfo.tags.map((item, index) => {
              return (
                <span key={index}>{`${item.displayName}${
                  index === programInfo.tags.length - 1 ? '' : ' , '
                }`}</span>
              )
            })}
          </div>

          {/* 教具 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>教具 : </span>
          </div>
          <div className={styles.liItem}>
            {programInfo.materials.map((item, index) => {
              return <div key={index}>{`${item.displayName} ${Boolean(item.requiredCount) ? item.requiredCount + '个' : '若干'}`}</div>
            })}
          </div>

          {/* 反馈 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>反馈 : </span>
          </div>
          <div className={styles.liItem}>
            {programInfo.feedbackProperties.map((item, index) => {
              return (
                <div key={index}>
                  <Badge
                    status="default"
                    color={'rgba(0, 0, 0, 0.65)'}
                    text={`${item.displayName}${this.switchFeedbackType(item)}`}
                  />
                </div>
              )
            })}
          </div>

          {/* 详情说明 */}
          <div className={styles.betweenItem}>
            <Divider type="vertical" className={styles.dividerColor} />
            <span className={styles.infoTitle}>详情说明 : </span>
          </div>
          <div className={styles.richText}>
            <ReportRichText content={programInfo.content} />
          </div>
        </div>
      )
    }

    return (
      <div>
        {this.canShowVideoPlayer() ? (
          <div className={styles.video}>
            <VideoPlayer {...videoJsOptions} />
          </div>
        ) : (
          <div>
            <img alt="img" className={styles.poster} src={videoJsOptions.poster} />
          </div>
        )}

        <h2 className={styles.category}>{programInfo.title}</h2>
        <p style={{ padding: '16px 0 16px 5px' }}>{programInfo.subTitle}</p>
        {info(programInfo)}
      </div>
    )
  }
}

export default TrainingProgramContent
