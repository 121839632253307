import { Spin } from 'antd'

const Callback = () => {
  return (
    <div style={{ paddingTop: 100, textAlign: 'center' }}>
      <Spin size="large" />
    </div>
  )
}

export default Callback
