import React from 'react';
import styles from './index.less';
import BottomDes from './components/BottomDes';
import HeaderLine from './components/HeaderLine';
import RecommendationsLine from './components/RecommendationsLine';
import ContentLine from './components/ContentLine';
import BottomPageNum from './components/BottomPageNum';

const showList = [
  {
    content:
      'Encourage self-expression: Encourage your child to express their feelings and provide a supportive environment where they feel heard.',
  },
  {
    content:
      'Help your child build confidence: Setting realistic expectations for your child and praising them for small achievements can help build their confidence and self-esteem.',
  },
  {
    content:
      'Teach coping skills: Teach your child coping skills, such as deep breathing or counting to ten, to help them manage their emotions.',
  },
  {
    content:
      'Teach relaxation techniques: Teach your child relaxation techniques, such as deep breathing or progressive muscle relaxation, to help them manage their emotions and feel more calm.',
  },
  {
    content:
      'Be consistent with rules and consequences: Consistency is especially important for children who struggle with attention. Establish clear rules and consequences for misbehavior and be consistent in enforcing them.',
  },
  {
    content:
      'Provide structure and routine: A structured routine can help your child feel more in control and reduce anxiety. Try to establish regular times for meals, homework, playtime, and bedtime.',
  },
];

const RecommendationFour = ({ qrCode }) => {
  const blob = new Blob([qrCode], { type : 'image/png' })
  const url = URL.createObjectURL(blob)

  const lineProps = {
    content:
      'Emotional struggles related to attention issues can include low self-esteem, frustration, and anxiety. Here are some tips to help your child:',
  };

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.recommendationFour}>
        <HeaderLine color="#00C0D7" />
        <div className={styles.centerContent}>
          <div className={styles.centerTitle}>Recommendations</div>
          <RecommendationsLine {...lineProps} />
          <div style={{ marginBottom: '40px' }}></div>
          {showList.map((item, index) => {
            const { content } = item || {};
            return <ContentLine key={index} content={content} />;
          })}
          <div className={styles.threeBottomContent}>
            <div className={styles.bottomTitle}>
              For children with attention difficulties, we have prepared
              professional solutions.
              <br />
              Scan the QR code below to learn more about our products.
            </div>
            {qrCode && url && <img src={url} />}
          </div>
        </div>
        <BottomPageNum num={4} />
        <BottomDes color="#00C0D7" />
      </div>
    </div>
  );
};

export default RecommendationFour;
