import React, { useState } from 'react';
import { connect, useIntl } from 'umi';
import { Card, Tabs } from 'antd';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import IndicatorsAnalysis from './IndicatorsAnalysis';
import StatisticalData from './StatisticalData';

const { TabPane } = Tabs;

const OneToOneAnalysis = ({ currentUser }) => {
  let ChildRef = React.createRef();

  const { canChangeList } = currentUser;
  const intl = useIntl();

  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: '全部',
  });

  const changedTenantList = (id) => {
    ChildRef.current.resetPagination();
    if (id) {
      const newCurrentTenant = canChangeList.find((item) => item.id == id);
      setCurrentTenant({ ...newCurrentTenant });
    } else {
      setCurrentTenant({ id: '', displayName: '全部' });
    }
  };

  const indicatorsProps = {
    currentTenantId: currentTenant.id,
    onRef: ChildRef
  };

  const statisticalProps = {
    currentTenantId: currentTenant.id,
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>优脑训练分析</b>}
      bordered={false}
      extra={
        <AnalysisTenantSelect
          showAll={true}
          currentTenant={currentTenant}
          onChange={changedTenantList}
        />
      }
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab={intl.formatMessage({ id: 'Analysis' })} key="1">
          <IndicatorsAnalysis {...indicatorsProps} />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'Statistics' })}  key="2">
          <StatisticalData {...statisticalProps} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(OneToOneAnalysis);
