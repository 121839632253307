import React, { useState } from 'react';
import { Card, Tabs } from 'antd';
import TrainList from './trainList';
import AssessmentList from './assessmentList';
const { TabPane } = Tabs;
import { useIntl } from 'umi';

const getRemainingTimesStyle = (times) => {
  return parseInt(times) <= 5 ? { color: 'red' } : {};
};

const UserConfiguration = () => {
  const intl = useIntl();

  const props = {
    getRemainingTimesStyle,
  };

  return (
    <Card
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({ id: 'User Configuration' })}
        </b>
      }
      bordered={false}
    >
      <Tabs defaultActiveKey="offlinePlan">
        <TabPane tab={intl.formatMessage({ id: 'Training' })} key="offlinePlan">
          <TrainList {...props} />
        </TabPane>
        {/* <TabPane
          tab={intl.formatMessage({ id: 'assessment' })}
          key="assessment"
        >
          <AssessmentList {...props} />
        </TabPane> */}
      </Tabs>
    </Card>
  );
};

export default UserConfiguration;
