import { memo, useState, useEffect } from 'react';
import { useIntl } from 'umi';
import { message } from 'antd';
import { GetCoachConfigRecord } from 'services/coachTrainingConfig';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

const listSupport = (intl) => {
  return {
    changeTimes: {
      showText: intl.formatMessage({ id: 'Training Sessions' }),
      showType: 'Text',
      render: (text, record) => {
        const { recharge } = record || {};
        return recharge ? `+${text}` : `-${Math.abs(text)}`;
      },
    },
    remark: {
      showText: intl.formatMessage({ id: 'comments' }),
      showType: 'Text',
    },
    creationTime: {
      showText: intl.formatMessage({ id: 'LastSubmissionTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY-MM-DD HH:mm',
      },
    },
  };
};

const ExpandedTable = ({ allData, visible }) => {
  const { id } = allData || {};

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const { current, pageSize } = pagination || {}

  const initData = async () => {
    setLoading(true);

    const res = await GetCoachConfigRecord({
      SkipCount: (current - 1) * pageSize,
      MaxResultCount: pageSize,
      CoachTrainingConfigId: id,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount || 0,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [visible, pagination.current, pagination.pageSize]);

  const TableProps = {
    listData,
    loading,
    listSupport: listSupport(intl),
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
  };

  return <MagicTable {...TableProps} />;
};

export default memo(ExpandedTable);
