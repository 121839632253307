import React from 'react'
import { history, connect } from 'umi';
import DocumentTitle from 'react-document-title'
import { Button, Row, Col, Radio, Tree, Empty, Spin, Pagination } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import styles from '../detail.less'
import CategoryContent from './RenderContent/Category'
import CoursewareContent from './RenderContent/Courseware'
import TrainingProgramContent from './RenderContent/TrainingProgram'
import { IntlManager } from 'utils/helper'

const { TreeNode } = Tree
class CoursewareDetail extends React.Component {
  onPageChange = pageIndex => {
    this.props.dispatch({
      type: 'coursewareDetail/changeTable',
      payload: { pagination: { current: pageIndex } },
    })
  }

  onSelect = (selectKeys, info) => {
    if (info.selected) {
      this.props.dispatch({
        type: 'coursewareDetail/updateState',
        payload: { selectKeys },
      })
    }
  }

  onChangeCourseware = e => {
    this.props.dispatch({
      type: 'coursewareDetail/getCoursewareInfo',
      payload: { id: e.target.value },
    })
  }

  render() {
    const intl = IntlManager.MyIntl()
    const { coursewareDetail, loading, location } = this.props
    const showLoading =
      loading.effects['coursewareDetail/getCoursewareInfo'] ||
      loading.effects['coursewareDetail/pageLoading']
    const { list, pagination, trainingCourseInfo, selectKeys, templateInfo } = coursewareDetail
    const { displayName = '' } = templateInfo || {}
    const { categories = [] } = trainingCourseInfo || {}

    dispatch({ type: 'coursewareDetail/pageLoading', payload: location.query })

    const showContent = (selectd, trainingCourseInfo) => {
      const selectKey = selectd[0]
      if (trainingCourseInfo != null) {
        if (selectKey === 'introduce') {
          return <CoursewareContent {...trainingCourseInfo} />
        }
        /// 子级
        else if (selectKey.includes('-')) {
          const keys = selectKey.split('-')
          const { categories } = trainingCourseInfo
          const selectedTrainingProgramIndex = parseInt(keys[1])
          const selectedCategoryIndex = parseInt(keys[0])
          const programInfo =
            categories.length > 0
              ? categories[selectedCategoryIndex].trainingPrograms[selectedTrainingProgramIndex]
              : null
          const props = { ...programInfo, index: 10 }
          return <TrainingProgramContent {...props} />
        }
        /// 父级
        else {
          const { categories } = trainingCourseInfo
          const categoryInfo = categories[parseInt(selectKey[0])]
          return <CategoryContent {...categoryInfo} />
        }
      }
    }

    const onBack = () => {
      history.goBack()
    }

    return (
      <DocumentTitle title={intl('coursewareDetails')}>
        <div style={{ position: 'relative' }}>
          <div className={styles.top} style={{ marginBottom: 14 }}>
            <Button onClick={onBack}>{intl('back')}</Button>
            <span className={styles.topTitle}>{displayName}</span>
          </div>
          <div className={styles.mainOld}>
            <div className={styles.contentBottom}>
              <Row type="flex" justify="start" gutter={20} style={{ marginBottom: 20 }}>
                <Col>
                  <Pagination
                    showQuickJumper
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onChange={this.onPageChange}
                  />
                </Col>
                <Col>
                  <h3>{intl('totalSessionsOther', { num: pagination.total })}</h3>
                </Col>
              </Row>
              <Spin spinning={showLoading} delay={500}>
                {trainingCourseInfo == null ? (
                  <div className={styles.place}>
                    <Empty style={{ paddingTop: 200 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                ) : (
                  <div className={styles.place}>
                    {/* 课件切换 */}
                    <Radio.Group
                      value={trainingCourseInfo && trainingCourseInfo.id}
                      onChange={this.onChangeCourseware}
                    >
                      {list.map(coursewareItem => {
                        const { id, title } = coursewareItem
                        return (
                          <Radio.Button key={id} value={id}>
                            {title}
                          </Radio.Button>
                        )
                      })}
                    </Radio.Group>
                    <Row>
                      <Col span={18}>
                        {/* 左侧内容呈现 */}
                        {showContent(selectKeys, trainingCourseInfo)}
                      </Col>
                      <Col span={6}>
                        {/* 树形控件 */}
                        {categories.length > 0 ? (
                          <Tree
                            selectedKeys={selectKeys}
                            showLine
                            onSelect={this.onSelect}
                            switcherIcon={<DownOutlined />}
                          >
                            <TreeNode title={intl('introduction')} key="introduce" />
                            {categories.map((category, categoryIndex) => {
                              return (
                                <TreeNode
                                  title={category.trainingCategory.name}
                                  key={categoryIndex}
                                >
                                  {category.trainingPrograms.map(
                                    (trainingProgram, trainingProgramIndex) => {
                                      return category != null ? (
                                        <TreeNode
                                          title={trainingProgram.title}
                                          key={categoryIndex + '-' + trainingProgramIndex}
                                          style={{ overflow: 'hidden' }}
                                        />
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  )}
                                </TreeNode>
                              )
                            })}
                          </Tree>
                        ) : (
                          <div></div>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </Spin>
            </div>
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

export default connect(({ coursewareDetail, loading }) => ({ coursewareDetail, loading }))(
  CoursewareDetail
)
