import React, { Fragment, useState, useEffect } from 'react'
import { Redirect, history, useIntl, connect } from 'umi'
import { Menu, Dropdown, Button, Tooltip, Select } from 'antd'
import { DownOutlined, CopyrightOutlined, GlobalOutlined } from '@ant-design/icons';
import styles from './Layout.less'
import FirstSystemNotification from 'components/FirstSystemNotification'
import PageLoading from 'cognitiveleap-core-us/components/PageLoading'
import HeaderCom from 'cognitiveleap-core-us/components/Header'
import Footer from 'cognitiveleap-core-us/components/Footer'
// import {filterMenuList, getDefaultUrl} from 'cognitiveleap-core-us/utils/utils'
import LocaleSelection from 'cognitiveleap-core-us/components/LocaleSelection'
import NoPermissionPage from 'cognitiveleap-core-us/components/ErrorPage/403'
import CenterQr from 'components/CenterQr'
import WeixinQr from 'components/WeixinQr'
import TotalQr from 'components/TotalQr'
import LookReportQr from 'components/LookReportQr'
import TrainingCode from 'components/TrainingCode'
import FeedbackCode from 'components/FeedbackModal'
import ReleaseModal from 'components/ReleaseModal'
import NotificationPopover from 'components/NotificationPopover'
import BasicLayout from 'cognitiveleap-core-us/layout'
import { getDefaultUrl, checkPermission } from './utils'
import downloadAppCodeImg from 'assets/downloadAppCode.png';
import qrcodeImg from 'assets/qrcode.png'
import logoutImg from 'assets/logout.png'
import userChange from 'assets/userChange.png'
import iconTimetableImg from 'assets/icon_Timetable.png'
import moment from "moment"
import SocketClient from 'socket.io-client';

import { getAdminName } from 'utils/utils'
import { getSelectHost, getAuthHeader } from 'cognitiveleap-core-us/utils/auth'

import { baseURL } from 'utils/config'

const { Option } = Select;

const SecurityLayout = ({ children, user, location, dispatch, showFirstSystemNotification, modalVisible, releaseInfo }) => {

  const [isReady, setIsReady] = useState(false)

  const intl = useIntl()

  const { currentUser,
    weixinQrUrl,
    centerQrUrl,
    reportQrUrl,
    parentQrUrl,
    coachQrUrl,
    parentQrModalVisible,
    coachQrModalVisible,
    reportQrModalVisible,
    centerQrModalVisible,
    weixinQrModalVisible,
    trainingQrModalVisible,
    FeedbackQrModalVisible,
    customSocket
  } = user

  const SelectHost = getSelectHost();

  const isManagement = SelectHost == 'Management';
  const isInstitutional = SelectHost == 'Institutional';

  const { current, name, canChangeList, changedTenant, userAllTenant, hostRoles, staff, auth, adminRoles = [] } = currentUser || {}
  const { grantedPolicies = {} } = auth || {}
  const hasCalendarPermission = grantedPolicies["Business.Calendar"]
  const hasProjectCalendarPermission = grantedPolicies["PFE.TopBar.Calendar"]
  const hasCenterAppPermission = grantedPolicies["PFE.TopBar.QRCode.CenterApp"]
  const hasCenterWxPermission = grantedPolicies["PFE.TopBar.QRCode.CenterWx"]
  const hasCenterTrainingAskPermission = grantedPolicies["PFE.TopBar.QRCode.CenterTrainingAsk"]
  const hasAppDownloadPermission = grantedPolicies["PFE.TopBar.QRCode.AppDownload"]
  const hasViewAssessmentReportPermission = grantedPolicies["PFE.TopBar.QRCode.ViewAssessmentReport"]
  const isFreezeAllAccount = current === null && changedTenant === null && adminRoles.length === 0

  const { displayName = '', roles: currentRoles = [] } = current || {}
  const { displayName: changedTenantName = '' } = changedTenant || {}
  const { email } = staff || {}
  const isEnterpriseAgent = currentRoles.some(item => item.name === 'EnterpriseAgent')

  // 代表此员工中心全部被冻结
  const isEmptyTenant = current === null && hostRoles.length === 0 && userAllTenant.every(item => item.state === 'Invalid')
  const divider = name !== null ? '-' : ''

  const initSocketIo = () => {
    const socket = SocketClient(baseURL, {
      transports: ["polling", "websocket"],
      path: '/realtime/socket.io',
      // withCredentials: true,
      randomizationFactor: 15,
      reconnectionAttempts: 6,
      reconnectionDelay: 2500,
      transportOptions: {
        polling: {
          extraHeaders: {
            ...getAuthHeader(),
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        }
      },
    });

    dispatch({
      type: 'user/updateState',
      payload: {
        customSocket: socket
      }
    })

    socket.on('connect', () => {
        // if (getAuthHeader()['t-select'] && getAuthHeader()['t-select'].length > 0) {
        //   socket.emit('unreadMessageCountSystem', {
        //     customHeader: getAuthHeader()
        // });
        // }
    });

    socket.on('unreadMessageCountSystem', msg => {
      dispatch({
          type: 'notification/updateState',
          payload: {
            unreadSystemCount: msg
          },
      })
    });

    socket.on('unreadMessageCountUser', msg => {
      dispatch({
          type: 'notification/updateState',
          payload: {
            unreadUserCount: msg,
          },
      })
    });

    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") {
        console.log('reconnect');
        socket.connect();
      }
    });
  }

  useEffect(() => {
    if (currentUser) {
      if ((adminRoles.length === 0 || isInstitutional) && !isEnterpriseAgent) {
        initSocketIo();
      }
    }
  }, [user.currentUser])

  useEffect(() => {
    setIsReady(true)
    dispatch({
      type: 'user/getUserInfo',
    })

    return () => {
      customSocket?.disconnect();
    }
  }, [])

  if (!currentUser || !isReady) {
    return <PageLoading />
  }

  const getPageTitle = () => {
    return 'BASE SYSTEM'
  }

  const headerName = () => {
    if (adminRoles.some(item => item.name === 'admin')) {
      return 'admin'
    } else {
      if (isManagement) {
        // 显示三个管理员
        return adminRoles.length > 0 && adminRoles.map((item, index) => {
          const des = index === adminRoles.length - 1 ? intl.formatMessage({ id: 'administrator' }) : ", "
          return getAdminName(item.name) + des
        })
      } else {
        // 可切换的Center
        return `${displayName || email}${divider}${name || ''}`
      }
    }
  }

  // const handleChangeTenant = (e, tenantList) => {
  //   const tenantItem = tenantList.find(item => item.id === e.key)
  //   dispatch({
  //     type: 'user/changeTenant',
  //     payload: tenantItem
  //   })
  // }

  // const handleChangeUserTenant = (e, tenantList) => {
  //   const tenantItem = tenantList.find(item => item.id === e.key)
  //   if (tenantItem && tenantItem.state === "Invalid") return
  //   dispatch({
  //     type: 'user/changeUserTenant',
  //     payload: tenantItem
  //   })
  // }

  const menu = (
    <Menu>
      {hasCenterAppPermission && <Menu.Item key={"downloadQRCodeApp"} onClick={() => dispatch({ type: 'user/showQRAndCode' })}>{intl.formatMessage({ id: 'QrCodeOfThisCenterAPP' })}</Menu.Item>}
      {/* <Menu.Item key={"downloadQRCodeParent"} onClick={() => dispatch({ type: 'user/showParentQRAndCode' })}>{intl.formatMessage({ id: 'GuardianCode' })}</Menu.Item>
      <Menu.Item key={"downloadQRCodeCoach"} onClick={() => dispatch({ type: 'user/showCoachQRAndCode' })}>{intl.formatMessage({ id: 'CoachCode' })}</Menu.Item> */}
      {hasCenterWxPermission && <Menu.Item key={"downloadQRCodeWX"} onClick={() => dispatch({ type: 'user/showWeixinQRAndCode' })}>{intl.formatMessage({ id: 'QRCodeofThisCenterWeChat' })}</Menu.Item>}
      {hasCenterTrainingAskPermission && <Menu.Item key={"downloadTrainingQRCode"} onClick={() => dispatch({ type: 'user/showTrainingQRAndCode' })}>{intl.formatMessage({ id: 'trainingApplicationCode' })}</Menu.Item>}
      {hasAppDownloadPermission && <Menu.Item key={"downloadAppQRCode"}><a href={downloadAppCodeImg} download={intl.formatMessage({ id: 'downloadAppQRCode' })} >{intl.formatMessage({ id: 'downloadAppQRCode' })}</a></Menu.Item>}
      {hasViewAssessmentReportPermission && <Menu.Item key={"downloadReportQRCode"} onClick={() => dispatch({ type: 'user/showReportQRAndCode' })}>{intl.formatMessage({ id: 'QRCodeOfReport' })}</Menu.Item>}
    </Menu>
  )

  const hasQrMenuUi = hasCenterAppPermission || hasCenterWxPermission || hasCenterTrainingAskPermission || hasAppDownloadPermission || hasViewAssessmentReportPermission;
  const scancode = (changedTenant && changedTenant.type === "Center" && isInstitutional && hasQrMenuUi) ?
    <Dropdown
      overlay={menu}
      placement="bottom"

     >
      <div style={{ height: '50px', display: "flex", alignItems: "center" }}>
        <img src={qrcodeImg} style={{ width: '18px', height: '18px', cursor: "pointer", margin: "0 0 3px 13px" }} />
      </div>
    </Dropdown>
    :
    null

  const HeaderProps = {
    user: {
      Name: headerName(),
    },
    scancode,
    showFirstSystemNotification: () => {
      return !isFreezeAllAccount && (adminRoles.length === 0 || isInstitutional) && showFirstSystemNotification && <FirstSystemNotification />
    },
    showNotification: () => {
      return !isFreezeAllAccount && (adminRoles.length === 0 || isInstitutional) && <NotificationPopover />
    },
    selectLocaleRender() {
      return (
        <LocaleSelection
          changeLanguage={(key) =>
            dispatch({ type: 'global/changeLang', payload: key })
          }
          // dropDownProps={{
          //   getPopupContainer: () => {
          //     return document.querySelector('Header');
          //   },
          // }}
          renderIcon={() => <GlobalOutlined />}
        />
      );
    },
    selectTenantRender() {
      // const menu =
      //   canChangeList && <Menu onClick={e => handleChangeTenant(e, canChangeList)}>
      //     {
      //       canChangeList.map(menuItem => {
      //         const { displayName, id } = menuItem;
      //         return (
      //           <Menu.Item
      //             key={id}
      //             className={styles.header_menu_item}
      //           >
      //             {displayName}
      //           </Menu.Item>
      //         );
      //       })
      //     }
      //   </Menu>
      // const currentTenant = userAllTenant.find(item => (item&&item.id) === (changedTenant&&changedTenant.id))
      // const roles = currentTenant?currentTenant.roles:[]
      return canChangeList && canChangeList.length > 0 && current != null ? (
        <div style={{ marginLeft: '13px' }}>
          <Select
            defaultValue={changedTenantName}
            showSearch
            onChange={(value) => {
              const tenantItem = canChangeList.find(item => item.id === value)

              dispatch({
                type: 'user/changeTenant',
                payload: tenantItem
              })
            }}
            style={{ minWidth: 200 }}
            filterOption={(input, option) => {
              const { children = '', disabled = false } = option || {};
              return children.toLowerCase().includes(input.toLowerCase())
            }
          }
          >
            {
              canChangeList && canChangeList.map(menuItem => {
                const { displayName, id } = menuItem;

                return <Option key={id}>{displayName}</Option>
              })
            }
          </Select>
          {/* <Dropdown overlay={menu} placement='bottomRight'>
            <Button>
              {changedTenantName}<DownOutlined />
            </Button>
          </Dropdown> */}
        </div >
      )
        :
        null
    },
    selectUserAllTenant() {
      // 没有注销的数量
      let effectiveCount = 0

      if (userAllTenant && Array.isArray(userAllTenant)) {
        userAllTenant.forEach(item => {
          if (item.state === "Effective") effectiveCount++;
        })
      }

      // const menu =
      //   userAllTenant &&
      //   <Menu onClick={e => handleChangeUserTenant(e, userAllTenant)}>
      //     {
      //       userAllTenant.map(menuItem => {
      //         const { displayName, id, roles, state } = menuItem;
      //         const changeDisplayName = `${displayName}${roles && roles.length > 0 ? '-' + roles[0].displayName : ''}`
      //         return (
      //           <Menu.Item
      //             key={id}
      //             className={state === 'Invalid' ? styles.header_menu_item_invalid : styles.header_menu_item}
      //           >
      //             {changeDisplayName}
      //           </Menu.Item>
      //         );
      //       })
      //     }
      //   </Menu>

      return (
        !hostRoles.some(item => item.name === 'admin') ?
          (
            // 代表所有角色被冻结, 或者没有申请通过的中心, 就不显示这里了
            isEmptyTenant ? null :
              <div style={{ marginLeft: '13px' }}>
                {isInstitutional ?
                  effectiveCount > 1 ?
                    <Select
                      showSearch
                      defaultValue={`${current && current.displayName}${currentRoles && currentRoles.length > 0 ? '-' + currentRoles[0].displayName : ''}`}
                      onChange={(value) => {
                        const tenantItem = userAllTenant.find(item => item.id === value)
                        dispatch({
                          type: 'user/changeUserTenant',
                          payload: tenantItem
                        })
                      }}
                      style={{ minWidth: 200 }}
                      filterOption={(input, option) => {
                          const { children = '', disabled = false } = option || {};
                          return children.toLowerCase().includes(input.toLowerCase())
                        }
                      }
                    >
                      {
                        userAllTenant && userAllTenant.map(menuItem => {
                          const { displayName, id, roles, state, tenantState } = menuItem;

                          const disabledState = state === 'Invalid' || tenantState === 'Invalid'
                          const changeDisplayName = `${displayName}${roles && roles.length > 0 ? '-' + roles[0].displayName : ''}`

                          return <Option disabled={disabledState} key={id}>{changeDisplayName}</Option>
                        })
                      }
                    </Select>
                    // <Dropdown overlay={menu} placement='bottomRight'>
                    //   <Button>
                    //     {/* 机构段且有多个效中心时 */}
                    //     {`${current && current.displayName}${currentRoles && currentRoles.length > 0 ? '-' + currentRoles[0].displayName : ''}`} <DownOutlined />
                    //   </Button>
                    // </Dropdown>
                    :
                    <Button>
                      {/* 机构段且只有一个有效中心时 */}
                      {`${current && current.displayName}${currentRoles && currentRoles.length > 0 ? '-' + currentRoles[0].displayName : ''}`}
                    </Button>
                  : isManagement ? <Button>{headerName()}</Button> : null  // 三种管理员端显示
                }
              </div>
          ) : <Button>{headerName()}</Button> // Admin 显示
      )
    },
    showCourseCard() {
      return hasCalendarPermission && isInstitutional ? (
        <Tooltip
          placement="bottom"
          title={<span style={{ padding: '10px 15px' }}>
          {intl.formatMessage({ id: 'timetable' })}
          </span>}
         >
          <img src={iconTimetableImg}
            onClick={() => history.push('/system/center/detail/classCardDetail')}
            style={{ width: '25px', cursor: "pointer", margin: '0 0 6px 10px' }}
          />
        </Tooltip>
      ) : null
    },
    showChangeAdmin() {
      const { pathname } = window.location
      return pathname !== '/system/choseRoles' && adminRoles.length > 0 && userAllTenant.some(item => item.state === "Effective") ? <Tooltip
        placement="bottom"
        title={<span style={{ padding: '10px 15px' }}>{intl.formatMessage({ id: 'switchRoles' })}</span>}>
        <img src={userChange}
        onClick={() => dispatch({ type: 'user/changeAdmin' })}
        style={{ width: '18px', height: '18px', cursor: "pointer", margin: '0 0 6px 10px' }} />
      </Tooltip> : null
    },
    signOut() {
      return (
        <Tooltip
          placement="bottom"
          title={<span style={{ padding: '10px 15px' }}>{intl.formatMessage({ id: 'logout' })}</span>}>
          <img src={logoutImg} onClick={() => dispatch({ type: 'user/logout' })} style={{ width: '18px', height: '18px', cursor: "pointer", margin: '0 0 6px 10px' }} />
        </Tooltip>
      )
    }
  }

  const FooterProps = {
    links: [
      {
        key: 'HomePage',
        title: intl.formatMessage({ id: 'home' }),
        href: '/',
        blankTarget: false,
      },
      {
        key: 'Company',
        title: intl.formatMessage({ id: 'company' }),
        href: 'https://www.cognitiveleap.com/',
        blankTarget: true,
      },
    ],

    copyright: (
      <Fragment>
        Copyright <CopyrightOutlined /> 2017-{moment().format("YYYY")} Cognitive Leap Solutions Inc.
      </Fragment>
    ),
  }

  const LayoutProps = (showHeader = true) => {
    return {
      children,
      pageTitle: getPageTitle(),
      headerRender: showHeader && <HeaderCom {...HeaderProps} />,
      footerRender: <Footer {...FooterProps} />,
    }
  }

  const centerQrProps = {
    url: centerQrUrl,
    changedTenant,
    visible: centerQrModalVisible,
    onCancel: () => {
      dispatch({
        type: 'user/updateState',
        payload: {
          centerQrModalVisible: false
        }
      })
    },
    downloadCenterQr: () => {
      dispatch({
        type: 'user/downloadQRPhoto'
      })
    }
  }

  const weixinQrProps = {
    url: weixinQrUrl,
    changedTenant,
    visible: weixinQrModalVisible,
    onCancel: () => {
      dispatch({
        type: 'user/updateState',
        payload: {
          weixinQrModalVisible: false
        }
      })
    }
  }

  const LookReportCodeProps = {
    url: reportQrUrl,
    changedTenant,
    visible: reportQrModalVisible,
    onCancel: () => {
      dispatch({
        type: 'user/updateState',
        payload: {
          reportQrModalVisible: false
        }
      })
    },
    downloadCenterQr: () => {
      dispatch({
        type: 'user/downloadReportQRPhoto'
      })
    }
  }

  const parentQrProps = {
    type: 'parent',
    url: parentQrUrl,
    changedTenant,
    visible: parentQrModalVisible,
    onCancel: () => {
      dispatch({
        type: 'user/updateState',
        payload: {
          parentQrModalVisible: false
        }
      })
    },
    downloadCenterQr: () => {
      dispatch({
        type: 'user/downloadParentQRPhoto'
      })
    }
  }

  const coachQrProps = {
    type: 'coach',
    url: coachQrUrl,
    changedTenant,
    visible: coachQrModalVisible,
    onCancel: () => {
      dispatch({
        type: 'user/updateState',
        payload: {
          coachQrModalVisible: false
        }
      })
    },
    downloadCenterQr: () => {
      dispatch({
        type: 'user/downloadCoachQRPhoto'
      })
    }
  }

  const TrainingCodeProps = {
    url: centerQrUrl,
    changedTenant,
    visible: trainingQrModalVisible,
    onCancel: () => {
      dispatch({
        type: 'user/updateState',
        payload: {
          trainingQrModalVisible: false
        }
      })
    },
    downloadCenterQr: () => {
      dispatch({
        type: 'user/downloadTrainingQRPhoto'
      })
    }
  }

  const FeedbackCodeProps = {
    url: centerQrUrl,
    changedTenant,
    visible: FeedbackQrModalVisible,
    onCancel: () => {
      dispatch({
        type: 'user/updateState',
        payload: {
          FeedbackQrModalVisible: false
        }
      })
    },
    downloadCenterQr: () => {
      dispatch({
        type: 'user/downloadFeedbackQRPhoto'
      })
    }
  }

  const releaseModalProps = {
    visible: modalVisible,
    data: releaseInfo,
    onCancel: () => {
      dispatch({
        type: 'notification/updateState',
        payload: { modalVisible: false }
      })
    },
    signToRead: (data) => {
      dispatch({
        type: 'notification/updateState',
        payload: { modalVisible: false }
      })
      dispatch({
        type: 'notification/signToRead',
        payload: { data }
      })
    },
  }

  // 是否显示网页顶部内容
  const isShowHeader = (location) => {
    if (location.pathname === '/system/medicalVratReport') return false
    if (location.pathname === '/system/medicalChnVratReport') return false
    if (location.pathname === '/system/compare') return false
    if (location.pathname === '/system/reviewfile') return false
    return true
  }

  if (location.pathname === '/system') {
    // 身份默认登录路由
    const defaultUrl = getDefaultUrl(changedTenant, userAllTenant, adminRoles, auth)
    return <Redirect to={defaultUrl} />
  } else if (!checkPermission(location.pathname, changedTenant, adminRoles, grantedPolicies)) {
    return <NoPermissionPage onClickBack={() => dispatch({ type: 'user/logout' })} />
  } else {
    return (
      <Fragment>
        <BasicLayout {...LayoutProps(isShowHeader(location))} />
        <CenterQr {...centerQrProps} />
        <WeixinQr {...weixinQrProps} />
        {/* <TotalQr {...parentQrProps} />
        <TotalQr {...coachQrProps} /> */}
        <TrainingCode {...TrainingCodeProps} />
        <ReleaseModal {...releaseModalProps} />
        <FeedbackCode {...FeedbackCodeProps} />
        <LookReportQr {...LookReportCodeProps} />
      </Fragment>
    )
  }
}

export default connect(({
  user,
  notification: {
    showFirstSystemNotification,
    modalVisible,
    releaseInfo
  }
}) => ({
  user,
  showFirstSystemNotification,
  modalVisible,
  releaseInfo
}))(SecurityLayout)

// class SecurityLayout extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       isReady: false,
//     }
//   }

//   componentDidMount() {
//     this.setState({
//       isReady: true,
//     })
//     const { dispatch } = this.props
//     if (dispatch) {
//       dispatch({
//         type: 'user/getUserInfo',
//       })
//     }
//   }

//   getPageTitle = () => {
//     return 'BASE SYSTEM'
//   }

//   render() {
//     const { current, name, canChangeList, changedTenant, userAllTenant, hostRoles, staff, auth } = currentUser || {}
//     const { grantedPolicies } = auth || {}
//     const hasCalendarPermission = grantedPolicies["Business.Calendar"]

//     const { displayName = '', roles: currentRoles } = current || {}
//     const { email } = staff || {}
//     // 代表此员工中心全部被冻结
//     const isEmptyTenant = current === null && hostRoles.length === 0 && userAllTenant.every(item => item.state === 'Invalid')
//     const divider = name !== null ? '-' : ''
//     const headerName = hostRoles.some(item => item.name === 'admin') ? 'admin' : `${displayName || email}${divider}${name || ''}`
//     const changedTenantName = changedTenant !== null ? changedTenant.displayName || '' : ''
//     const handleChangeTenant = (e, tenantList) => {
//       const tenantItem = tenantList.find(item => item.id === e.key)
//       dispatch({
//         type: 'user/changeTenant',
//         payload: tenantItem
//       })
//     }

//     const handleChangeUserTenant = (e, tenantList) => {
//       const tenantItem = tenantList.find(item => item.id === e.key)
//       if (tenantItem && tenantItem.state === "Invalid") return
//       dispatch({
//         type: 'user/changeUserTenant',
//         payload: tenantItem
//       })
//     }

//     const menu = (
//       <Menu>
//         <Menu.Item key={"downloadQRCode"} onClick={() => dispatch({ type: 'user/showQRAndCode' })}>{intl.formatMessage({ id: 'downloadQRCode' })}</Menu.Item>
//         <Menu.Item key={"downloadTrainingQRCode"} onClick={() => dispatch({ type: 'user/showTrainingQRAndCode' })}>中心培训申请码</Menu.Item>
//         <Menu.Item key={"downloadAppQRCode"}><a href={downloadAppCodeImg} download={intl.formatMessage({ id: 'downloadAppQRCode' })} >{intl.formatMessage({ id: 'downloadAppQRCode' })}</a></Menu.Item>
//       </Menu>
//     )

//     const scancode = changedTenant && changedTenant.type === "Center" ?
//       <Dropdown overlay={menu} placement="bottom">
//         <div style={{ height: '50px', display: "flex", alignItems: "center" }}>
//           <img src={qrcodeImg} style={{ width: '18px', height: '18px', cursor: "pointer", margin: "0 0 3px 13px" }} />
//         </div>
//       </Dropdown>
//       :
//       null

//     const HeaderProps = {
//       user: {
//         Name: headerName,
//       },
//       scancode,
//       showFirstSystemNotification: () => {
//         return !hostRoles.some(item => item.name === 'admin') && showFirstSystemNotification && <FirstSystemNotification />
//       },
//       showNotification: () => {
//         return !hostRoles.some(item => item.name === 'admin') && <NotificationPopover />
//       },
//       selectLocaleRender() {
//         return (
//           <LocaleSelection
//             changeLanguage={key => dispatch({ type: 'global/changeLang', payload: key })}
//           />
//         )
//       },
//       selectTenantRender() {
//         const menu =
//           canChangeList && <Menu onClick={e => handleChangeTenant(e, canChangeList)}>
//             {
//               canChangeList.map(menuItem => {
//                 const { displayName, id } = menuItem;
//                 return (
//                   <Menu.Item
//                     key={id}
//                     className={styles.header_menu_item}
//                   >
//                     {displayName}
//                   </Menu.Item>
//                 );
//               })
//             }
//           </Menu>
//         // const currentTenant = userAllTenant.find(item => (item&&item.id) === (changedTenant&&changedTenant.id))
//         // const roles = currentTenant?currentTenant.roles:[]
//         return canChangeList && canChangeList.length > 0 && current != null ? (
//           <div style={{ marginLeft: '13px' }}>
//             <Dropdown overlay={menu} placement='bottomRight'>
//               <Button>
//                 {changedTenantName}{/*{`${roles.length>0?'-'+roles[0].displayName:''}`}*/}<DownOutlined />
//               </Button>
//             </Dropdown>
//           </div>
//         )
//           :
//           null
//       },
//       selectUserAllTenant() {
//         // 没有注销的数量
//         let effectiveCount = 0

//         if (userAllTenant && Array.isArray(userAllTenant)) {
//           userAllTenant.forEach(item => {
//             if (item.state === "Effective") effectiveCount++;
//           })
//         }

//         const menu =
//           userAllTenant &&
//           <Menu onClick={e => handleChangeUserTenant(e, userAllTenant)}>
//             {
//               userAllTenant.map(menuItem => {
//                 const { displayName, id, roles, state } = menuItem;
//                 const changeDisplayName = `${displayName}${roles && roles.length > 0 ? '-' + roles[0].displayName : ''}`
//                 return (
//                   <Menu.Item
//                     key={id}
//                     className={state === 'Invalid' ? styles.header_menu_item_invalid : styles.header_menu_item}
//                   >
//                     {changeDisplayName}
//                   </Menu.Item>
//                 );
//               })
//             }
//           </Menu>

//         return (
//           !hostRoles.some(item => item.name === 'admin') ?
//             (
//               // 代表所有角色被冻结
//               isEmptyTenant ?
//                 <div style={{ marginLeft: '13px' }}>
//                   <Button>
//                     {`${email}${currentRoles && currentRoles.length > 0 ? '-' + currentRoles[0].displayName : ''}`}
//                   </Button>
//                 </div>
//                 :
//                 // 当未冻结账号大于等于1并且角色大于1时才显示角色筛选下拉框
//                 effectiveCount >= 1 ?
//                   (
//                     <div style={{ marginLeft: '13px' }}>
//                       {
//                         effectiveCount === 1 ?
//                           <Button>
//                             {`${current && current.displayName}${currentRoles && currentRoles.length > 0 ? '-' + currentRoles[0].displayName : ''}`}
//                           </Button>
//                           :
//                           userAllTenant.length > 1 ?
//                             <Dropdown overlay={menu} placement='bottomRight'>
//                               <Button>
//                                 {`${current && current.displayName}${currentRoles && currentRoles.length > 0 ? '-' + currentRoles[0].displayName : ''}`} <DownOutlined />
//                               </Button>
//                             </Dropdown>
//                             :
//                             null
//                       }
//                     </div>
//                   )
//                   :
//                   <div></div>
//             )
//             :
//             // hostRoles 中含有admin 则为admin，否则为当前身份
//             <Button>{headerName}</Button>
//         )
//       },
//       showCourseCard() {
//         return hasCalendarPermission ? (
//           <Tooltip placement="bottom" title={<span style={{ padding: '10px 15px' }}>课程表</span>}>
//             <img src={iconTimetableImg} onClick={() => history.push('/system/center/detail/classCardDetail')} style={{ width: '25px', cursor: "pointer", margin: '0 0 6px 10px' }} />
//           </Tooltip>
//         ) : null
//       },
//       signOut() {
//         return (
//           <Tooltip placement="bottom" title={<span style={{ padding: '10px 15px' }}>登出</span>}>
//             <img src={logoutImg} onClick={() => dispatch({ type: 'user/logout' })} style={{ width: '18px', height: '18px', cursor: "pointer", margin: '0 0 6px 10px' }} />
//           </Tooltip>
//         )
//       }
//     }

//     const FooterProps = {
//       links: [
//         {
//           key: 'HomePage',
//           title: intl.formatMessage({ id: 'home' }),
//           href: '/',
//           blankTarget: false,
//         },
//         {
//           key: 'Company',
//           title: intl.formatMessage({ id: 'company' }),
//           href: 'https://www.cognitiveleap.com/',
//           blankTarget: true,
//         },
//       ],

//       copyright: (
//         <Fragment>
//           Copyright <CopyrightOutlined /> 2017-{moment().format("YYYY")} Cognitive Leap Solutions Inc.
//         </Fragment>
//       ),
//     }

//     const LayoutProps = {
//       children,
//       pageTitle: this.getPageTitle(),
//       headerRender: <HeaderCom {...HeaderProps} />,
//       footerRender: <Footer {...FooterProps} />,
//     }

//     const centerQrProps = {
//       url: centerQrUrl,
//       changedTenant,
//       visible: centerQrModalVisible,
//       onCancel: () => {
//         dispatch({
//           type: 'user/updateState',
//           payload: {
//             centerQrModalVisible: false
//           }
//         })
//       },
//       downloadCenterQr: () => {
//         dispatch({
//           type: 'user/downloadQRPhoto'
//         })
//       }
//     }

//     const TrainingCodeProps = {
//       url: centerQrUrl,
//       changedTenant,
//       visible: trainingQrModalVisible,
//       onCancel: () => {
//         dispatch({
//           type: 'user/updateState',
//           payload: {
//             trainingQrModalVisible: false
//           }
//         })
//       },
//       downloadCenterQr: () => {
//         dispatch({
//           type: 'user/downloadTrainingQRPhoto'
//         })
//       }
//     }

//     const FeedbackCodeProps = {
//       url: centerQrUrl,
//       changedTenant,
//       visible: FeedbackQrModalVisible,
//       onCancel: () => {
//         dispatch({
//           type: 'user/updateState',
//           payload: {
//             FeedbackQrModalVisible: false
//           }
//         })
//       },
//       downloadCenterQr: () => {
//         dispatch({
//           type: 'user/downloadFeedbackQRPhoto'
//         })
//       }
//     }

//     const releaseModalProps = {
//       visible: modalVisible,
//       data: releaseInfo,
//       onCancel: () => {
//         dispatch({
//           type: 'notification/updateState',
//           payload: { modalVisible: false }
//         })
//       },
//       signToRead: (data) => {
//         dispatch({
//           type: 'notification/updateState',
//           payload: { modalVisible: false }
//         })
//         dispatch({
//           type: 'notification/signToRead',
//           payload: { data }
//         })
//       },
//     }

//     if (location.pathname === '/system') {
//       const defaultUrl = getDefaultUrl(changedTenant, hostRoles)
//       return <Redirect to={defaultUrl} />
//     }
//     else if (!checkPermission(location.pathname, changedTenant, hostRoles)) {
//       return <NoPermissionPage onClickBack={() => dispatch({ type: 'user/logout' })} />
//     } else {
//       return (
//         <Fragment>
//           <BasicLayout {...LayoutProps} />
//           <CenterQr {...centerQrProps} />
//           <TrainingCode {...TrainingCodeProps} />
//           <ReleaseModal {...releaseModalProps} />
//           <FeedbackCode {...FeedbackCodeProps} />
//         </Fragment>
//       )
//     }
//   }
// }

