import { history, connect } from 'umi';
import {useEffect} from 'react'
import { Button, Modal, Spin } from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'
import CategorySelectModal from './CategorySelectModal'
import CategoryCollapse from './CategoryCollapse'
import SortOrderModal from 'components/SortOrderModal'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'

const { confirm } = Modal

const TrainingCourseDetail = ({ dispatch, trainingCourseDetail, location }) => {
    
  useEffect(() => {
    dispatch({ type: 'trainingCourseDetail/loadPage', payload: location.query })
  }, [])

  const { trainingCourseInfo, categorySelectModal, categoryList, modalVisible, content, selectItem, sortModalVisible,
    sortParams,} = trainingCourseDetail

  if (!trainingCourseInfo) return <Spin />

  const { title, categories, agePeriod, code, trainingTarget } = trainingCourseInfo

  const onBack = () => {
    history.goBack()
  }

  const onAddCategory = () => {
    dispatch({ type: 'trainingCourseDetail/addCategory' })
  }


  ///返回null表示前后index一样，不需要动
  const changeSort = (list, current, newIndex)=> {
    if (current === newIndex) return null
    const copylist = list.map(item=>item)
    const currentItem = copylist[current]
    copylist.splice(current, 1)
    copylist.splice(newIndex, 0, currentItem)
    return copylist
  }

  const modalProps = {
    title: selectItem ===null?'添加训练分类':'编辑训练分类',
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'trainingCourseDetail/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'trainingCourseDetail/onSubmit', payload: value })
    },
    content,
  }

  const sortOrderProps = {
    visible: sortModalVisible,
    onCancel() {
      dispatch({ type: 'trainingCourseDetail/updateState', payload: { sortModalVisible: false } })
    },
    onOk(data) {
      const {sortOrder: newSort} = data
      const {categoryIndex, programIndex} = sortParams
      let newList = null
      if(programIndex === null){
        newList = changeSort(categories, categoryIndex, newSort)
      }
      else{
        const newProgramList = changeSort(categories[categoryIndex].trainingProgramIds, programIndex,newSort)
        if(newProgramList!==null){
          newList = categories.map((item,index)=>{
            return index === categoryIndex?{...categories[categoryIndex],trainingProgramIds:newProgramList}:item
          })
        }
      }
      if(newList!==null){
        dispatch({ type: 'trainingCourseDetail/updateCategories', payload: newList })
      }
      else{
        dispatch({ type: 'trainingCourseDetail/updateState', payload: { sortModalVisible: false } })
      }
    },
    currentValue:sortParams&&(sortParams.programIndex||sortParams.categoryIndex),
  }

  const categoryCollapseProps = {
    categories,
    onEdit(index){
      const {trainingCategoryId} = categories[index]
      dispatch({ type: 'trainingCourseDetail/showAddOrEditContent', payload: {categoryId:trainingCategoryId,selectItem:{index, data:categories[index]}} })
    },
    onDelete(index){
      const copyList = categories.map(item=>item)
      copyList.splice(index,1)
      dispatch({ type: 'trainingCourseDetail/updateCategories', payload: copyList })
    },
    onSort(categoryIndex,programIndex=null){
      dispatch({ type: 'trainingCourseDetail/updateState', payload: { sortModalVisible: true, sortParams: {categoryIndex, programIndex} } })
    }
  }

  const categoryModalProps = {
    list: categoryList,
    visible: categorySelectModal,
    onCancel() {
      dispatch({
        type: 'trainingCourseDetail/updateState',
        payload: { categorySelectModal: false, categoryList: [] },
      })
    },
    onOk(data) {
      const {categoryId} = data
      dispatch({ type: 'trainingCourseDetail/showAddOrEditContent', payload: {categoryId,selectItem:null} })
    },
  }

  return (
    <div>
      <h1>{title}</h1>
      <Button icon={<CaretLeftOutlined />} onClick={onBack}>
        Back
      </Button>
      <Button onClick={onAddCategory}>添加训练类别</Button>
      <br/>
      <br/>
      <CategoryCollapse {...categoryCollapseProps}/>
      <CategorySelectModal {...categoryModalProps} />
      <MagicFormModal {...modalProps} />
      <SortOrderModal {...sortOrderProps}/>
    </div>
  )
}

export default connect(({ trainingCourseDetail }) => ({ trainingCourseDetail }))(
  TrainingCourseDetail
)
