import {
  GetRadar,
  GetRose,
  GetPathLengthData,
  GetLineWithIcon,
  GetOverViewData,
  GetCharacteristicData,
  GetContinuousData,
  GetMovementPathData,
  ReportInfo,
} from 'services/vrat/chart';
import { GetTenantInfoNoPermission } from 'services/tenant';
import { SetLanguage } from 'services/language';
import { setLocale, getLocale } from 'umi';

const LangOptions = {
  en: 'en-US',
  'zh-Hans': 'zh-CN',
  es: 'es-ES'
};

export default {
  namespace: 'medicalChnVratReport',
  state: {
    tenantInfo: null,
    reportVersion: null,
    caseId: null,
    basicInfo: null,
    overView: null,
    lineWithIcon: null,
    sixDimension: null,
    continuousAttention: null,
    movementPath: null,
    pathLength: null,
    directionTracking: null,
    motionCharacteristics: null,

    basicLoading: true,
    overViewLoading: true,
    lineWithIconLoading: true,
    sixDimensionLoading: true,
    continuousAttentionLoading: true,
    movementPathLoading: true,
    pathLengthLoading: true,
    directionTrackingLoading: true,
    motionCharacteristicsLoading: true,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: {
    *fetch({ payload: { caseId, code = null, culture, v } }, { call, put }) {
      if (culture && getLocale() !== LangOptions[culture]) {
        const res = yield call(SetLanguage, { culture });
        if (res.response.ok) {
          setLocale(LangOptions[culture]);
        }
      }

      const basicInfoRes = yield call(ReportInfo, caseId, code);
      const basicInfo = basicInfoRes.response.ok ? basicInfoRes.data : null;
      const reportVersion = v || basicInfo.Case.TargetReportVersion;

      if (basicInfo && Object.keys(basicInfo).length > 0) {
        const { TestLocation, TestingCenter } = basicInfo;
        const res = yield call(
          GetTenantInfoNoPermission,
          TestLocation && TestLocation.TenantId || TestingCenter && TestingCenter.Id,
        );
        if (res.response.ok) {
          yield put({
            type: 'updateState',
            payload: { tenantInfo: res.data },
          });
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          caseId: caseId,
          reportVersion,
          basicInfo,
          basicLoading: basicInfoRes.response.ok ? false : true,
        },
      });

      const resList = yield [
        call(GetOverViewData, caseId, code, reportVersion),
        call(GetLineWithIcon, caseId, code),
        call(GetRadar, caseId, code, reportVersion),
        call(GetContinuousData, caseId, code, reportVersion),
        call(GetMovementPathData, caseId, code, reportVersion),
        call(GetPathLengthData, caseId, code, reportVersion),
        call(GetRose, caseId, code, reportVersion),
        call(GetCharacteristicData, caseId, code, reportVersion),
      ];

      const overViewRes = resList[0];
      const overView = overViewRes.response.ok ? overViewRes.data : null;
      yield put({
        type: 'updateState',
        payload: {
          overView,
          overViewLoading: overViewRes.response.ok ? false : true,
        },
      });

      const lineWithIconRes = resList[1];
      const lineWithIcon = lineWithIconRes.response.ok
        ? lineWithIconRes.data
        : null;
      yield put({
        type: 'updateState',
        payload: {
          lineWithIcon,
          lineWithIconLoading: lineWithIconRes.response.ok ? false : true,
        },
      });

      const raderRes = resList[2];
      const sixDimension = raderRes.response.ok ? raderRes.data : null;
      yield put({
        type: 'updateState',
        payload: {
          sixDimension,
          sixDimensionLoading: raderRes.response.ok ? false : true,
        },
      });

      const continuousRes = resList[3];
      const continuousAttention = continuousRes.response.ok
        ? continuousRes.data
        : null;
      yield put({
        type: 'updateState',
        payload: {
          continuousAttention,
          continuousAttentionLoading: continuousRes.response.ok ? false : true,
        },
      });

      const movementPathRes = resList[4];
      const movementPath = movementPathRes.response.ok
        ? movementPathRes.data
        : null;
      yield put({
        type: 'updateState',
        payload: {
          movementPath,
          movementPathLoading: movementPathRes.response.ok ? false : true,
        },
      });

      const pathLengthRes = resList[5];
      const pathLength = pathLengthRes.response.ok ? pathLengthRes.data : null;
      yield put({
        type: 'updateState',
        payload: {
          pathLength,
          pathLengthLoading: pathLengthRes.response.ok ? false : true,
        },
      });

      const roseRes = resList[6];
      const directionTracking = roseRes.response.ok ? roseRes.data : null;
      yield put({
        type: 'updateState',
        payload: {
          directionTracking,
          directionTrackingLoading: roseRes.response.ok ? false : true,
        },
      });

      const characteristicRes = resList[7];
      const motionCharacteristics = characteristicRes.response.ok
        ? characteristicRes.data
        : null;
      yield put({
        type: 'updateState',
        payload: {
          motionCharacteristics,
          motionCharacteristicsLoading: characteristicRes.response.ok
            ? false
            : true,
        },
      });
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // return history.listen(location => {
      //   if (location.pathname === '/medicalChnVratReport') {
      //     dispatch({ type: 'fetch', payload: location.query })
      //   }
      // })
    },
  },
};
