import { getLocale } from 'umi';
import ReactEcharts from 'echarts-for-react';

const RoseChart = ({ categories }) => {
  const isZh = getLocale() === 'zh-CN'

  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c}%',
    },
    legend: {
      icon: 'circle',
      type: 'scroll',
      orient: 'vertical',
      right: isZh ? 160 : 115,
      top: 40,
    },
    series: [
      {
        name: 'Nightingale Chart',
        type: 'pie',
        radius: [20, 140],
        center: isZh ? ['38%', '50%'] : ['28%', '50%'],
        roseType: 'radius',
        itemStyle: {
          borderRadius: 5,
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        data: categories
          .sort((pre, cur) => cur.percentage - pre.percentage)
          .map((item) => {
            const { percentage, category } = item;
            const { displayName } = category;
            return {
              value: parseInt(percentage),
              name: displayName,
            };
          }),
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{
        height: '300px',
        width: '100%',
        margin: 'auto',
      }}
    />
  );
};

export default RoseChart;
