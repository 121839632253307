import React from 'react'
import { message } from 'antd'
import { history, connect, useIntl } from 'umi';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import RocketProjectTip from 'components/RocketProjectTip';
import { addOptionsToProperties, createSuperLabel } from 'cognitiveleap-core-us/utils/utils'
import styles from '../detail.less'
import { encryptAES } from 'utils/utils';
const VratInfo = ({ subjectVrat, loading, dispatch, currentUser }) => {
  const intl = useIntl()
  const { list, pagination } = subjectVrat
  const { auth: { grantedPolicies }, changedTenant: { projectStatus, institutionType, reportKinds = [] } } = currentUser
  const vratProjectStatus = projectStatus.find(item => item.name == 'Project.Vrat')['status'];
  const vratColumns = {
    testDate: {
      showText: intl.formatMessage({ id: 'testDate' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD',
      },
    },
    // centerName: {
    //   showText: 'centerName',
    //   showType: 'Text',
    // },
    // locationName: {
    //   showText: intl.formatMessage({ id: 'testPartName' }),
    //   showType: 'Text',
    // },
    status: {
      showText: intl.formatMessage({ id: 'testStatus' }),
      showType: 'Text',
    },
    attentionIndex: {
      showText: intl.formatMessage({ id: 'attentionNum' }),
      showType: 'Text',
    },
    motionIndex: {
      showText: intl.formatMessage({ id: 'actionNumber' }),
      showType: 'Text',
    },
    // parentalAdviceTime: {
    //   showText: intl.formatMessage({ id: 'parentalAdviceTime' }),
    //   showType: 'Text',
    // }
  }

  const vratActions = [
    {
      showText: intl.formatMessage({ id: 'lookDetail' }),
      itemRender(record) {
        return grantedPolicies['VrClassroom.Center'] ? (
          <span key="view">
            <a
              onClick={() => {
                history.push({
                  pathname: '/system/center/detail/vratTestDetail',
                  query: {
                    id: record.id,
                  },
                })
              }}
            >
              {intl.formatMessage({ id: 'lookDetail' })}
            </a>
          </span>
        ) : null
      }
    },
    {
      showText: intl.formatMessage({ id: 'lookReport' }),
      itemRender(record) {
        const { case: vratCase, status } = record
        return status === intl.formatMessage({ id: 'reportAcquired' }) && institutionType !== 'Education_CHN' ? (
          <span key="view">
            <a
              onClick={() => {
                const url = '/report?caseId=' + encodeURIComponent(encryptAES(vratCase.id, "Secret Passphrase"))  + '&suspension=true'
                createSuperLabel(url, vratCase.id)
                // window.open(url, '_blank')
              }}
            >
              {intl.formatMessage({ id: 'lookReport' })}
            </a>
          </span>
        ) : null
      },
    },
    {
      showText: intl.formatMessage({ id: 'medicalVratView' }),
      itemRender(record) {
        const { case: vratCase, status } = record
        return (status === intl.formatMessage({ id: 'reportAcquired' }) && institutionType === 'Medical') ? (
          <span key="view">
            <a
              onClick={() => {
                const url = '/system/medicalVratReport?caseId=' + vratCase.id
                window.open(url, '_blank')
              }}
            >
              {intl.formatMessage({ id: 'medicalVratView' })}
            </a>
          </span>
        ) : null
      },
    },
    {
      showText: intl.formatMessage({ id: 'medicalVratView' }),
      itemRender(record) {
        const { case: vratCase, status } = record
        return (status === intl.formatMessage({ id: 'reportAcquired' }) && institutionType === 'Education_CHN') ? (
          <span key="view">
            <a
              onClick={() => {
                const url = '/system/medicalChnVratReport?caseId=' + vratCase.id
                window.open(url, '_blank')
              }}
            >
              {intl.formatMessage({ id: 'medicalVratView' })}
            </a>
          </span>
        ) : null
      },
    },
    // {
    //   showText: intl.formatMessage({ id: 'Clinician Edition' }),
    //   itemRender(record) {
    //     const { case: vratCase, status } = record
    //     return (status === intl.formatMessage({ id: 'reportAcquired' }) && reportKinds.includes('ClinicianEdition')) ? (
    //       <span key="view">
    //         <a
    //           onClick={() => {
    //             message.info('暂未完成')
    //             return
    //           }}
    //         >
    //           {intl.formatMessage({ id: 'Clinician Edition' })}
    //         </a>
    //       </span>
    //     ) : null
    //   },
    // },
    // {
    //   showText: intl.formatMessage({ id: 'Educator Edition' }),
    //   itemRender(record) {
    //     const { case: vratCase, status } = record
    //     return (status === intl.formatMessage({ id: 'reportAcquired' }) && reportKinds.includes('EducationEdition')) ? (
    //       <span key="view">
    //         <a
    //           onClick={() => {
    //             message.info('暂未完成')
    //             return
    //           }}
    //         >
    //           {intl.formatMessage({ id: 'Educator Edition' })}
    //         </a>
    //       </span>
    //     ) : null
    //   },
    // },
    {
      showText: intl.formatMessage({ id: 'Parent Edition' }),
      itemRender(record) {
        const { case: vratCase, status } = record
        return (status === intl.formatMessage({ id: 'reportAcquired' }) && reportKinds.includes('ParentEdition')) ? (
          <span key="view">
            <a
              onClick={() => {
                const url = '/parentreport?caseId=' + vratCase.id + '&reId=' + vratCase.id + '&suspension=true'
                window.open(url, '_blank')
              }}
            >
              {intl.formatMessage({ id: 'Parent Edition' })}
            </a>
          </span>
        ) : null
      },
    },
    {
      showText: intl.formatMessage({ id: 'Parent Screen Edition' }),
      itemRender(record) {
        const { case: vratCase, status } = record
        return (status === intl.formatMessage({ id: 'reportAcquired' }) && reportKinds.includes('ParentScreenEdition')) ? (
          <span key="view">
            <a
              onClick={() => {
                const url = '/parentreport?caseId=' + vratCase.id + '&reId=' + vratCase.id + '&suspension=true&model=screen'
                window.open(url, '_blank')
              }}
            >
              {intl.formatMessage({ id: 'Parent Screen Edition' })}
            </a>
          </span>
        ) : null
      },
    },
  ]

  const statusShow = status => {
    switch (status) {
      case 'UnTested':
        return intl.formatMessage({ id: 'untested' })
      case 'Testing':
        return intl.formatMessage({ id: 'testing' })
      case 'DataUploading':
        return intl.formatMessage({ id: 'dataUploading' })
      case 'ReportGenerating':
        return intl.formatMessage({ id: 'reportGenerating' })
      case 'ReportReady':
        return intl.formatMessage({ id: 'reportReady' })
      case 'ReportAcquired':
        return intl.formatMessage({ id: 'reportAcquired' })
      case 'Failed':
        return intl.formatMessage({ id: 'testFailed' })
      case 'TestAbnormal':
        return intl.formatMessage({ id: 'testAbnormal' })
      case 'ReportGenerationFailed':
        return intl.formatMessage({ id: 'reportGenerationFailed' })
      default:
        return <span></span>
    }
  }

  /// test subjectID ff29dbcc-10f3-49ac-a80f-1f52a290a701
  const showList = list.map(item => {
    const {
      case: { testDate, id: caseId, testType, overView, registerTimeForWix },
      testingCenter,
      // testLocation: { name: location },
    } = item

    return {
      testDate,
      centerName: testingCenter && testingCenter.displayName,
      // locationName: location,
      caseId,
      testType,
      attentionIndex: overView ? (overView.attentionPerformanceIndex * 100).toFixed(0) : '',
      motionIndex: overView ? (overView.motionPerformanceIndex * 100).toFixed(0) : '',
      // parentalAdviceTime: registerTimeForWix,
      ...item,
      status: statusShow(item.status),
    }
  })

  const listProps = {
    loading: loading.models.subjectVrat,
    listData: showList,
    listSupport: vratColumns,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'subjectVrat/changeTable', payload: data })
    },
    Actions: vratActions,
  }

  return (
    <div className={styles.tabContent}>
      {
        !vratProjectStatus &&
        <div style={{ marginBottom: '20px' }}>
          <RocketProjectTip />
        </div>
      }
      <MagicTable {...listProps} />
    </div>
  )
}

export default connect(({ subjectVrat, user, loading }) => ({ subjectVrat, loading, currentUser: user.currentUser, }))(VratInfo)
