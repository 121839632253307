import React from 'react';
import { Space, Divider } from 'antd';
import cogleapLogo from 'assets/CL_logo.png';
import { useIntl } from 'umi';
import vratLogo from 'assets/newVratReportImg/vratIcon.png';
import styles from './index.less';

const CoverPage = ({ data, isScreenReport }) => {
  const intl = useIntl();

  const { isShowClLogo = false, isShowCenterLogo = false, logo} = data || {};
  const { url } = logo || {}

  return (
    <div id="basic" className={styles.page_div}>
      <div className={styles.coverPage}>
        <div className={styles.powered}>
          {intl.formatMessage({ id: isScreenReport ? 'ScreeCoverPage.title' : 'CoverPage.powered' })}
        </div>
        <div className={styles.centerContent}>
          <div className={styles.blockList}>
            <div className={styles.firstBlock}></div>
            <div className={styles.twoBlock}></div>
            <div className={styles.threeBlock}></div>
            <div className={styles.fourBlock}></div>
          </div>
          <div className={styles.titleList}>
            <div className={styles.title}>{intl.formatMessage({ id: isScreenReport ? 'ScreeCoverPage.title' : 'CoverPage.powered' })}</div>
            <div className={styles.titleTwo}>{intl.formatMessage({ id: 'CoverPage.two' })}</div>
          </div>
        </div>
        <div className={styles.bottomContent}>
          <Space split={<Divider type="vertical" style={{ height: '45px' }} />} size={5}>
            {isShowClLogo && <img src={cogleapLogo} className={styles.clImg} />}
            {url && isShowCenterLogo && <img src={url} className={styles.clImg} />}
            <img src={vratLogo} className={styles.logoImg} />
          </Space>
        </div>
      </div>
    </div>
  );
};

export default CoverPage;
