import React from 'react'
import { Row, Col, Spin, Button } from 'antd'
import moment from 'moment'
import LineWithIconChart from 'components/VratChartComponents/LineWithIconChart'
import MyProcess from 'components/MyProcess'
import cogleapLogo1 from 'assets/CL_logo.png'
import cogleapLogo2 from 'assets/cogleap2.png'
import triangle from 'assets/vratReportImg/triangle.png'
import square from 'assets/vratReportImg/square.png'
import circular from 'assets/vratReportImg/circular.png'
import { gradeList } from '../../Center/SubjectList/models/subjectList'
import { countAge } from 'utils/utils'
import { useIntl, getLocale } from 'umi'
import styles from './index.less'

const FirstPage = ({ data, tenantInfo, reportVersion, totalShow, basicLoading, overViewLoading, lineWithIconLoading, lineWithIcon, continuousAttentionLoading, continuousAttention, sixDimensionLoading, pathLengthLoading, motionCharacteristicsLoading, directionTrackingLoading }) => {

    // 中心名字
    const { isShowClLogo, isShowCenterLogo, logo } = tenantInfo || {}
    const { url: tenantLogo } = logo || {}

    const locale = getLocale();

    const logoSrc = locale === 'zh-CN' ? cogleapLogo2 : cogleapLogo1

    // 头部数据
    const intl = useIntl()
    const showData = data ? data : {}
    const { Subject = {}, Case = {}, TestLocation = {}, TestingCenter = {}, UnitType } = showData
    const grade = gradeList().find(item => item.key === Case.Grade)
    const { TestDate } = Case || {}

    const { Name } = TestLocation || {}
    const { DisplayName } = TestingCenter || {}

    const displayName = Name || DisplayName

    // 一、总体表现
    const attentionPerformance = totalShow ? (totalShow.AttentionPerformanceIndex * 100) : 100
    const motionPerformance = totalShow ? (totalShow.MotionPerformanceIndex * 100) : 100

    // 二、注意力和头部运动得实时表现
    const Motion = lineWithIcon ? lineWithIcon.Motion : []
    const Behaviour = lineWithIcon ? lineWithIcon.Behaviour : []

    const LineWithIconChartProps = {
        dataStruct: {
            Motion,
            Behaviour,
            type: 'medical'
        },
    }

    const continuousAttentionShowData = continuousAttention ? continuousAttention : []

    const genderToStr = gender => {
        switch (gender) {
            case 'Male':
                return intl.formatMessage({ id: 'male' })
            case 'Female':
                return intl.formatMessage({ id: 'female' })
            default:
                return intl.formatMessage({ id: 'other' })
        }
    }

    const getTotal = (type) => {
        let total = 0
        continuousAttentionShowData && continuousAttentionShowData.slice(1, continuousAttentionShowData.length).map(item => {
            if (type === 'right') {
                return total += parseInt(item.CorrectAnswer)
            } else if (type === 'miss') {
                return total += parseInt(item.OmissionError)
            } else if (type === 'error') {
                return total += parseInt(item.CommissionError)
            }
        })
        return total
    }

    return (
        <div id="basic" className={`${styles.page_div}`}>
            <div className={styles.downlaod}>
                {!(continuousAttentionLoading || lineWithIconLoading || overViewLoading || basicLoading
                    || sixDimensionLoading || pathLengthLoading || motionCharacteristicsLoading
                    || directionTrackingLoading) && <Button type="primary"
                        onClick={() => {
                            document.getElementById('downlaodButton').style.display = 'none'
                            window.print()
                            document.getElementById('downlaodButton').style.display = 'block'
                        }} id='downlaodButton'>打印测试报告</Button>
                }
            </div>
            <div className={styles.headerLogo}>
                {
                isShowClLogo && <img src={logoSrc} alt="logo" className={styles.logo} />
                }
                {
                tenantLogo && isShowCenterLogo &&
                <img src={tenantLogo} alt="logo" className={styles.tenantLogo} />
                }
            </div>
            <div className={styles.firstPageTitle}>VRAT 虚拟现实注意力测评报告</div>
            <Spin
                spinning={basicLoading}
                className={styles.spinnerWrapperText}
            >
                {JSON.stringify(Subject) !== '{}' && <div className={styles.info}>
                    <Row>
                        {<Col span={6}>
                            门诊号：{Subject.OutpatientNumber}
                        </Col>}
                        <Col span={6}>
                            姓名：{(Subject.Name[0] === '*' && Subject.Name[Subject.Name.length - 1] === '*') ? '**' : Subject.Name}
                        </Col>
                        <Col span={6}>
                            性别：{genderToStr(Subject.Gender)}
                        </Col>
                        <Col span={6}>
                            出生日期：{Subject.BirthDay ? moment(Subject.BirthDay).local().format('YYYY/MM/DD') : ''}
                        </Col>
                        <Col span={6}>
                            年级：{grade ? grade.value : Case.Grade}
                        </Col>
                        <Col span={6}>
                            测评年龄：{moment(Subject.BirthDay).isValid()
                                ? countAge(Subject.BirthDay, intl, Case.TestDate)
                                : ''}
                        </Col>
                        <Col span={6}>
                            测评日期：{TestDate
                                ? moment(TestDate).local().format('YYYY/MM/DD')
                                : ''}
                        </Col>
                        <Col span={6}>
                            测评编号：{Case.Id}
                        </Col>
                        {/* <Col span={6}>
                            测试师：{Subject.CoachName}
                        </Col> */}
                        <Col span={6}>
                            测评版本：{UnitType ? Case.AppVersion + ` - ${UnitType}` : Case.AppVersion}
                        </Col>
                        <Col span={6}>
                            报告版本：{`V${reportVersion}`}
                        </Col>
                        <Col span={6}>
                            {/* 测评地点：{TestLocation.Name} */}
                            测评地点：{displayName}
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </div>
                }
            </Spin>
            <Spin
                spinning={overViewLoading}
                className={styles.spinnerWrapperText}
            >
                <div className={styles.headerTextFirst}>一、总体表现</div>
                <div className={styles.headerTextBody}>
                    <table border="1" align="center" valign="middle">
                        <tbody>
                            <tr align="center" className={styles.trStyle}>
                                <td className={styles.tdTitle}>注意力指数</td>
                                <td className={styles.tdNum}>{attentionPerformance.toFixed(0)}</td>
                                <td className={styles.tdProcess}><MyProcess percent={attentionPerformance} /></td>
                            </tr>
                            <tr align="center" className={styles.trStyle}>
                                <td className={styles.tdTitle}>动作指数</td>
                                <td className={styles.tdNum}>{motionPerformance.toFixed(0)}</td>
                                <td className={styles.tdProcess}><MyProcess percent={motionPerformance} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Spin>
            <Spin
                spinning={lineWithIconLoading && continuousAttentionLoading}
                className={styles.spinnerWrapperText}>
                <div className={styles.headerTextFirst}>二、注意力和头部运动的实时表现</div>
                <LineWithIconChart {...LineWithIconChartProps} />
                <div className={styles.description}>注意力和头部运动的实时表现（13分钟）</div>
                <div className={styles.headerTextBody}>
                    <table border="1" align="center" valign="middle">
                        <tbody>
                            <tr align="center" className={styles.attenTrStyle}>
                                <td className={styles.attenTableTitle}>AX-CPT(次)</td>
                                <td className={styles.attenTableContent}>区间1</td>
                                <td className={styles.attenTableContent}>区间2</td>
                                <td className={styles.attenTableContent}>区间3</td>
                                <td className={styles.attenTableContent}>区间4</td>
                                <td className={styles.attenTableContent}>总计</td>
                            </tr>
                            <tr align="center" className={styles.attenTrStyle}>
                                <td className={styles.attenTableTitle}><span>正确击中 <img src={square} /></span></td>
                                {continuousAttentionShowData && continuousAttentionShowData.slice(1, continuousAttentionShowData.length).map((item, index) => {
                                    return (<td key={index}>{item.CorrectAnswer}</td>)
                                })}
                                <td>{getTotal('right')}</td>
                            </tr>
                            <tr align="center" className={styles.attenTrStyle}>
                                <td className={styles.attenTableTitle}><span>&#12288;漏击&#12288; <img src={triangle} /></span></td>
                                {continuousAttentionShowData && continuousAttentionShowData.slice(1, continuousAttentionShowData.length).map((item, index) => {
                                    return (<td key={index}>{item.OmissionError}</td>)
                                })}
                                <td>{getTotal('miss')}</td>
                            </tr>
                            <tr align="center" className={styles.attenTrStyle}>
                                <td className={styles.attenTableTitle}><span>&#12288;错击&#12288; <img src={circular} /></span></td>
                                {continuousAttentionShowData && continuousAttentionShowData.slice(1, continuousAttentionShowData.length).map((item, index) => {
                                    return (<td key={index}>{item.CommissionError}</td>)
                                })}
                                <td>{getTotal('error')}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.tableDescription}>*区间：13分钟测评平均分为4个区间，每个区间的时间为3.25分钟。</div>
            </Spin>
            <span className={styles.page_number}>1</span>
        </div>
    )
}

export default FirstPage
