import React, { useEffect, useState, useRef } from 'react';
import { Card, Button, message, Upload, Space } from 'antd';
import { connect } from 'umi';
import config from 'utils/config';
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { UploadOutlined } from '@ant-design/icons';
import { read, utils } from 'xlsx';

const { baseURL, uploadBAttachment, localesExcelName } = config;

const Online = ({ online, dispatch }) => {
  const [uploadIng, setUploading] = useState(false);
  const [progressText, setProgressText] = useState('');
  const deleteKey = useRef([]);
  const errorValueKey = useRef([]);

  useEffect(() => {
    dispatch({ type: 'online/query' });
  }, []);

  const { currentList, pagination } = online;

  const listSupport = {
    showEmail: {
      showText: '邮箱',
      showType: 'Text',
    },
    ip: {
      showText: 'IP地址',
      showType: 'Text',
    },
  };

  const TableProps = {
    listSupport,
    pagination,
    listData: currentList.map((item) => {
      return {
        ...item,
        showEmail: item.email || 'admin',
      };
    }),
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination };
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi };
      }
      dispatch({ type: 'online/changeTable', payload: data });
    },
  };

  const checkIsValid = (value) => {
    return (
      value == undefined ||
      value == '' ||
      Object.prototype.toString.call(value) === '[object Number]'
    );
  };

  const checkFiles = async (file) => {
    setUploading(true);
    setProgressText('获取远程资源文件中...');
    var serverArray = [];
    var newFileArray = [];

    const serverRes = await fetch(
      `https://rocketsystem.s3.us-west-1.amazonaws.com/Web/Attachment/${localesExcelName}.xlsx`,
      {
        method: 'GET',
        cache: 'no-cache'
      },
    );
    const { status } = serverRes || {};

    const newFile = await file.arrayBuffer();

    const newWb = read(newFile);

    newWb.SheetNames.map((item) => {
      const ws = newWb.Sheets[item];
      newFileArray = newFileArray.concat(
        utils.sheet_to_json(ws, {
          defval: '',
        }),
      );
    });

    newFileArray.forEach((item) => {
      const { key, zhCN, enUS, esES } = item || {};
      if (checkIsValid(zhCN) || checkIsValid(enUS) || checkIsValid(esES)) {
        // Value可能有问题的
        errorValueKey.current = errorValueKey.current.concat(key);
      }
    });

    if (status === 200) {
      const serverFile = await serverRes.arrayBuffer();

      const wb = read(serverFile);

      wb.SheetNames.map((item) => {
        const ws = wb.Sheets[item];
        serverArray = serverArray.concat(
          utils.sheet_to_json(ws, {
            defval: '',
          }),
        );
      });

      serverArray.forEach((item) => {
        const { key } = item || {};
        const findData =
          newFileArray.find((newItem) => newItem.key === key) || {};
        if (!Object.keys(findData).length) {
          // 新文件误删key
          deleteKey.current = deleteKey.current.concat(key);
        }
      });
    }

    setProgressText('');
    setUploading(false);

    return {
      deleteKey: deleteKey.current,
      errorValueKey: errorValueKey.current,
    };
  };

  const props = {
    accept: '.xls, .xlsx',
    action: baseURL + uploadBAttachment + '?replacePreFile=true',
    headers: getAuthHeader(),
    showUploadList: false,
    beforeUpload: async (file) => {
      const { name } = file || {};
      const fileName = name.split('.')[0];
      if (fileName !== localesExcelName) {
        message.error(
          `文件更新失败，文件名不正确，修改规范文件名之后上传重试！提示：${localesExcelName}.xlsx`,
        );
        return false;
      }
      const isWhiteUser = localStorage.getItem('whiteEmail') === 'lulifengl@126.com';
      const { deleteKey, errorValueKey } = await checkFiles(file);
      if (isWhiteUser) {
        // 只做紧急初始化使用
        localStorage.removeItem('whiteEmail');
        return true;
      }
      if (deleteKey.length > 0 || errorValueKey.length > 0) {
        message.error(
          '文件更新失败，修复文件中下方提示有问题的KEY之后上传重试！',
        );
        return false;
      }
    },
    onChange: (info) => {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} 多语言文件上传成功~`);
      }
    },
  };

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>在线人数</b>}
      extra={
        <Upload {...props}>
          <Button
            onClick={() => {
              deleteKey.current = [];
              errorValueKey.current = [];
            }}
            loading={uploadIng}
            icon={<UploadOutlined />}
          >
            更新语言包
          </Button>
        </Upload>
      }
      bordered={false}
    >
      <Space direction="vertical">
        {progressText}
        {Boolean(deleteKey.current.length) && (
          <div style={{ color: 'red' }}>
            检查并补充缺失key：{deleteKey.current.map((item) => item).join(', ')}
          </div>
        )}
        {Boolean(errorValueKey.current.length) && (
          <div style={{ color: 'red' }}>
            检查并修改Value有问题的key：
            {errorValueKey.current.map((item) => item).join(', ')}
          </div>
        )}
        <p />
      </Space>
      <MagicTable {...TableProps} />
    </Card>
  );
};

export default connect(({ online }) => ({ online }))(Online);
