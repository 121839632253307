import React, { useEffect } from 'react'
import { connect } from 'umi'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import StatusModal from 'components/StatusModal'
import SortOrderModal from 'components/SortOrderModal'
import { Button, Modal } from 'antd'

const confirm = Modal.confirm

const initialStatusValue = {
  'Unpublished': 'Unpublish',
  'Public': 'Public',
  'Hidden': 'Hide',
  'Expired': 'Expire',
  'Trashed': 'Trash'
}

const Banner = ({ dispatch, banner }) => {

  useEffect(() => {
    dispatch({ type: 'banner/query' })
  }, [])

  const {
    list,
    listSupport,
    pagination,
    modalType,
    content,
    modalVisible,
    statusVisible,
    sortOrderModal,
    selectItem,
  } = banner

  const validator = value => {
    return true
  }

  const modalTypeToTitle = type => {
    switch (type) {
      case 'add':
        return '新建Banner'
      case 'edit':
        return '编辑Banner'
      default:
        return ''
    }
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'banner/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      switch (modalType) {
        case 'add':
        case 'edit':
          if (!validator(value)) {
            return
          } else {
            dispatch({ type: 'banner/onSubmitModal', payload: value })
          }
          break
        default:
      }
    },
    content,
  }

  const statusProps = {
    visible: statusVisible,
    initialValue: initialStatusValue[selectItem && selectItem.status],
    onCancel() {
      dispatch({ type: 'banner/updateState', payload: { statusVisible: false } })
    },
    onOk(data) {
      dispatch({ type: 'banner/changeStatus', payload: data.status })
    },
  }
  
  const sortModalProps = {
    visible: sortOrderModal,
    onCancel() {
      dispatch({ type: 'banner/updateState', payload: { sortOrderModal: false } })
    },
    onOk(data) {
      dispatch({ type: 'banner/changeSortOrder', payload: data.sortOrder })
    },
    currentValue: selectItem && selectItem.sortOrder,
  }

  const tableProps = {
    listData: list.map(item => {
      return {
        ...item,
        image: item.image && item.image.url
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'banner/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'banner/onEdit', payload: record.id })
        },
      },
      {
        showText: '发布态',
        onClick(record) {
          dispatch({
            type: 'banner/updateState',
            payload: { statusVisible: true, selectItem: record },
          })
        },
      },
      {
        showText: '排序',
        onClick(record) {
          dispatch({
            type: 'banner/updateState',
            payload: { sortOrderModal: true, selectItem: record },
          })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'banner/onDelete', payload: record.id })
            },
          })
        },
      },
    ],
  }

  const onAddBtnClick = () => {
    dispatch({ type: 'banner/onAdd' })
  }

  return (
    <div>
      <h1>Banner</h1>
      <Button onClick={onAddBtnClick}>新建Banner</Button>
      <MagicFormModal {...modalProps} />
      <MagicTable {...tableProps} />
      <StatusModal {...statusProps} />
      <SortOrderModal {...sortModalProps} />
    </div>
  )
}
export default connect(({ banner }) => ({ banner }))(Banner)
