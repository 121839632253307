import React from 'react'
import { connect } from 'umi'
import { Row, Col } from 'antd'
import moment from 'moment'
import BaseInfoCard from 'components/BaseInfoCard/BaseInfoCard'
import cogleapLogo1 from 'assets/CL_logo.png'
import cogleapLogo2 from 'assets/cogleap2.png'
import { useIntl } from 'umi'
import styles from './Report.less'
import textIcon from 'assets/vratReportImg/test_icon.png'
import userIcon from 'assets/vratReportImg/user_icon.png'
import PoweredBy from 'components/VratChartComponents/poweredBy'
import { countAge } from 'utils/utils'
import { gradeList } from '../../Center/SubjectList/models/subjectList'
import { getLocale } from "umi";

const BasicInfo = ({ data, reportVersion, tenantInfo, showNewReport }) => {
  const intl = useIntl()
  const { isShowClLogo, isShowCenterLogo } = tenantInfo || {}
  const showData = data ? data : {}
  const { Subject = {}, Case = {}, TestLocation = {}, TestingCenter = {}, UnitType } = showData
  const { BirthDay } = Subject || {}
  const { TestDate } = Case || {}
  const genderToStr = gender => {
    switch (gender) {
      case 'Male':
        return intl.formatMessage({ id: 'male' })
      case 'Female':
        return intl.formatMessage({ id: 'female' })
      default:
        return intl.formatMessage({ id: 'other' })
    }
  }

  const { Name } = TestLocation || {}
  const { DisplayName } = TestingCenter || {}

  const displayName = Name || DisplayName

  const grade = gradeList(useIntl).find(item => item.key === Case.Grade)
  const userCardProps = {
    cardName: intl.formatMessage({ id: 'userDetail' }),
    info: {
      // [intl.formatMessage({id:'name'})]: user.name,
      // [intl.formatMessage({id:'userId'})]: user.userId,
      [intl.formatMessage({ id: 'gender' })]: genderToStr(Subject.Gender),
      [intl.formatMessage({ id: 'birthday' })]: BirthDay
        ? moment(BirthDay).local().format('YYYY/MM/DD')
        : '',
      [intl.formatMessage({ id: 'grade' })]: grade ? grade.value : Case.Grade,
      [intl.formatMessage({ id: 'testAge' })]: moment(Subject.BirthDay).isValid()
        ? countAge(Subject.BirthDay, intl, Case.TestDate)
        : '',
      [intl.formatMessage({ id: 'report.subjectCode' })]: Subject.OutpatientNumber
    },
    cardColor: '#F5FAFE',
    iconType: 'user',
    icon: userIcon,
  }

  const testDetailProps = {
    cardName: intl.formatMessage({ id: 'testDetail' }),
    info: {
      [intl.formatMessage({ id: 'testDate' })]: TestDate
        ? moment(TestDate).local().format('YYYY/MM/DD')
        : '',
      [intl.formatMessage({ id: 'testId' })]: Case.Id,
      [intl.formatMessage({ id: 'testLocation' })]: displayName,
      [intl.formatMessage({ id: 'testVersion' })]: UnitType ? Case.AppVersion + ` - ${UnitType}` : Case.AppVersion,
      [intl.formatMessage({ id: 'reportVersion' })]: `V${reportVersion}`
    },
    cardColor: '#FEF8F8',
    iconType: 'file-text',
    icon: textIcon,
  }

  const Comments = ({ comments }) => {
    var arr = []
    if (comments.length === 0) {
      return (
        <div>
          <span style={{ fontSize: 20, fontWeight: 700, color: '#333' }}>
            {`${intl.formatMessage({ id: 'comments' })}: (${intl.formatMessage({ id: 'none' })})`}
          </span>
        </div>
      )
    }
    comments.map((item, index) => {
      arr.push(
        <div key={index} style={{ padding: 10, color: '#333' }}>
          {item}
        </div>
      )
    })
    return (
      <div>
        <span style={{ fontSize: 20, fontWeight: 700, color: '#333' }}>Comments: </span>
        {arr}
      </div>
    )
  }

  const tenantLogo = tenantInfo && tenantInfo.logo && tenantInfo.logo.url

  const locale = getLocale();

  const logoSrc = locale === 'zh-CN' ? cogleapLogo2 : cogleapLogo1

  return (
    // <div id="basic" className={`${styles.page_div} ${styles.shadow}`}>
    <div id="basic" className={`${styles.page_div}`}>
      <div className={styles.headerLogo}>
        {
          isShowClLogo && <img src={logoSrc} alt="logo" className={styles.logo} />
        }
        {
          tenantLogo && isShowCenterLogo &&
          <img src={tenantLogo} alt="logo" className={styles.tenantLogo} />
        }
      </div>
      <div className={styles.page_header}>
        <p>{showNewReport ? 'vCAT虚拟教室专注力测评报告' : intl.formatMessage({ id: 'report.basicInfo.title' })}</p>
      </div>
      <h2
        className={`${styles.page_title} ${styles.display_pagetitle}`}
        style={{ marginTop: 40, display: 'none' }}
      >
        <p className={styles.eTitle}>Basic Info</p>
        {/* <p className={styles.cTitle}>基本信息</p> */}
      </h2>
      <Row type="flex" justify="space-around" style={{ marginTop: 60 }}>
        <Col span={11}>
          <BaseInfoCard {...userCardProps} />
        </Col>

        <Col span={11}>
          <BaseInfoCard {...testDetailProps} />
        </Col>
      </Row>

      {/* <Row style={{ marginTop: 40 }}>
          <Col span={9} offset={3}>
            <Comments comments={comments} />
          </Col>
        </Row> */}
      <PoweredBy styles={styles} />
      <p className={styles.page_number}>1</p>
    </div>
  )
}

export default connect()(BasicInfo)
