import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Input, Space, Button, DatePicker, Row, Col } from 'antd';
import moment from 'moment';

import {
  GetOneToOneIndicator,
  DownOneToOneIndicator,
} from 'services/rocketService/Analysis';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import { useIntl } from 'umi';
import { getFormatDate } from 'utils/utils';
import download from 'cognitiveleap-core-us/utils/download';

const { Search } = Input;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const listSupport = {
  displayName: {
    showText: '中心名字',
    showType: 'Text',
    fixed: 'left',
  },
  courseSubjectQuantity: {
    showText: '训练儿童数（总）',
    showType: 'Text',
  },
  courseSubjectQuantity30: {
    showText: '训练儿童数（30天有耗课）',
    showType: 'Text',
  },
  courseSubjectQuantityMonth: {
    showText: '训练儿童数（本月）',
    showType: 'Text',
  },
  courseQuantity: {
    showText: '总耗课数',
    showType: 'Text',
  },
  planQuantity: {
    showText: '总训练计划数',
    showType: 'Text',
  },
  courseQuantityToday: {
    showText: '今日耗课数',
    showType: 'Text',
  },
  planQuantityMonth: {
    showText: '本月新增计划数',
    showType: 'Text',
  },
};

const IndicatorsAnalysis = ({ currentTenantId, onRef }) => {
  const intl = useIntl();
  const [dataSource, setDataSource] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [filterDate, setFilterDate] = useState({
    startTime: '',
    endTime: '',
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
  });
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = await GetOneToOneIndicator({
      MaxResultCount: pagination.pageSize,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      Filter: keyword,
      TenantId: currentTenantId,
      StartTime: filterDate.startTime,
      EndTime: filterDate.endTime,
    });

    if (res.response.ok) {
      const { items, totalCount } = res.data;

      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      showErrorMsg(res, intl);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

   useImperativeHandle(onRef, () => {
    return {
      resetPagination: () => {
        setPagination({
          current: 1,
          pageSize: 10,
          total: 0,
        });
      },
    };
  });

  useEffect(() => {
    initData();
  }, [
    filterDate,
    keyword,
    pagination.pageSize,
    pagination.current,
    currentTenantId
  ]);

  const tableProps = {
    listData: dataSource.map((item) => {
      const {
        tenant: { displayName },
      } = item;
      return {
        displayName,
        ...item,
      };
    }),
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
  };

  const onChangeDate = (value) => {
    const startTime = value && value[0] ? getFormatDate(value[0], true) : '';
    const endTime = value && value[1] ? getFormatDate(value[1], false) : '';
    setPagination({
      current: 1,
      pageSize: 10,
      total: 0,
    });
    setFilterDate({
      startTime,
      endTime,
    });
  };

  const buttonClick = async () => {
    setLoading({
      ...loading,
      buttonLoading: true,
    });

    const res = await DownOneToOneIndicator({
      MaxResultCount: pagination.pageSize,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      Filter: keyword,
      TenantId: currentTenantId,
      StartTime: filterDate.startTime,
      EndTime: filterDate.endTime,
    });

    if (res.response.ok) {
      const { response } = res;
      response.blob().then((blob) => {
        download(
          blob,
          `优脑训练指标分析+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`,
        );
      });
    } else {
      showErrorMsg(res, intl);
    }

    setLoading({
      ...loading,
      buttonLoading: false,
    });
  };

  return (
    <div>
      <Space>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Search
              placeholder="中心名称"
              onSearch={(value) => {
                setPagination({
                  current: 1,
                  pageSize: 10,
                  total: 0,
                });
                setKeyword(value);
              }}
              style={{ width: 200 }}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <RangePicker
              onChange={onChangeDate}
              placeholder={['开始日期', '结束日期']}
              disabledDate={(current) => {
                return current && current > moment();
              }}
              value={[
                filterDate.startTime
                  ? moment(filterDate.startTime, dateFormat)
                  : null,
                filterDate.endTime
                  ? moment(filterDate.endTime, dateFormat)
                  : null,
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Button loading={loading.buttonLoading} onClick={buttonClick}>
              下载列表
            </Button>
          </Col>
        </Row>
      </Space>
      <p />
      <MagicTable {...tableProps} />
    </div>
  );
};

export default IndicatorsAnalysis;
