import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Card, Input, Select, Button, DatePicker, Tooltip } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import { useIntl } from 'umi'
import { getFormatDate } from 'utils/utils'
import moment from 'moment'
import { history } from 'umi';
import styles from './index.less'

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD';
const listSupport = {
  code: {
    showText: '订单号',
    showType: 'Text',
    render: (text, data) => {
      return (
        <Tooltip title={text} placement="topLeft">
          <span>{text.replace(/(\w{5})(\w{22})(\w{5})/, '$1******$3')}</span>
        </Tooltip>
      )
    }
  },
  name: {
    showText: '商品名称',
    showType: 'Text'
  },
  summary: {
    showText: '内容',
    showType: 'Text'
  },
  orderType: {
    showText: '商品类型',
    showType: 'Text'
  },
  userNameOrPhoneNum: {
    showText: '姓名/手机号',
    showType: 'Text',
    render: (text, data) => {
      const { userId, type } = data
      return (
        <a
          onClick={() => {
            if (type.includes('OnlinePlanCourse') || type.includes('OfflinePlanCourse') || type == 'Assessment') {
              history.push({
                pathname: '/system/manage/subjectDetail',
                query: {
                  id: userId
                }
              })
            } else if (type == 'Course') {
              history.push({
                pathname: '/system/manage/userDetail',
                query: {
                  id: userId
                }
              })
            }
          }
          }
        >
          {text}
        </a>
      )
    }
  },
  paymentAmount: {
    showText: '实付金额',
    showType: 'Text'
  },
  status: {
    showText: '订单状态',
    showType: 'Text'
  },
  dealTime: {
    showText: '成交时间',
    showType: 'Time',
    addtional: {
      format: 'YYYY/MM/DD HH:mm',
    },
  }
}

const Order = ({ adminhfsOrder, loading, dispatch }) => {
  const intl = useIntl();
  const { isOpenUp, pagination, orderList, currentTenant, Filter, Type, StartDate, EndDate } = adminhfsOrder

  useEffect(() => {
    dispatch({ type: 'adminhfsOrder/query' })
  }, [])

  const tableProps = {
    loading: loading.models.adminhfsOrder,
    listData: orderList.map(item => {
      const { state, type, userName, userPhoneNumber } = item
      const userNameOrPhoneNum = userName || userPhoneNumber

      return {
        ...item,
        status: {
          "Created": '已创建',
          "Deal": '已成交'
        }[state],
        orderType: {
          "Assessment": '测评',
          "Course": '课程',
          "OnlinePlanCourse": '训练-线上课',
          "OfflinePlanCourse": '训练-线下课',
          "OneToOneOnlinePlanCourse": '一对一-线上课',
          "OneToOneOfflinePlanCourse": '一对一-线下课',
          "OneToTwoOnlinePlanCourse": '一对二-线上课',
          "OneToTwoOfflinePlanCourse": '一对二-线下课',
          "OneToThreeOnlinePlanCourse": '一对三-线上课',
          "OneToThreeOfflinePlanCourse": '一对三-线下课',
          "OneToFourOnlinePlanCourse": '一对四-线上课',
          "OneToFourOfflinePlanCourse": '一对四-线下课',
          "OneToFiveOnlinePlanCourse": '一对五-线上课',
          "OneToFiveOfflinePlanCourse": '一对五-线下课',
          "OneToSixOnlinePlanCourse": '一对六-线上课',
          "OneToSixOfflinePlanCourse": '一对六-线下课',
        }[type],
        userNameOrPhoneNum
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'adminhfsOrder/onTableChange', payload: data })
    },
    Actions: [
      // {
      //   showText: '详情',
      //   onClick(record) {
      //     history.push({
      //       pathname: '/system/manage/hfsOrderDetail',
      //       query: {
      //         id: '001'
      //       }
      //     })
      //   },
      // }
    ]
  }

  const onChange = async (value, type) => {
    switch (type) {
      case 'Filter':
        await dispatch({ type: 'adminhfsOrder/updateState', payload: { Filter: value } })
        break
      case 'Type':
        await dispatch({ type: 'adminhfsOrder/updateState', payload: { Type: value, pagination: { ...pagination, current: 1 } } })
        onSearch()
        break
      case 'Date':
        const StartDate = value && value[0] ? getFormatDate(value[0], true) : ''
        const EndDate = value && value[1] ? getFormatDate(value[1], false) : ''
        await dispatch({ type: 'adminhfsOrder/updateState', payload: { StartDate, EndDate, pagination: { ...pagination, current: 1 } } })
        onSearch()
        break
    }
  }

  const onSearch = () => dispatch({ type: 'adminhfsOrder/getOrderList' })

  return (
    <Card
      className={styles.order}
      title={<b style={{ fontSize: '20px' }}>订单列表</b>}
      bordered={false}
      extra={
        <AnalysisTenantSelect
          showAll={true}
          currentTenant={currentTenant}
          onChange={(e) => {
            dispatch({ type: 'adminhfsOrder/query', payload: { id: e } })
          }}
        />
      }
    >
      <div className={styles.search}>
        <div className={styles.searchTop}>
          <div className={styles.searchItem}>
            <span className={styles.searchItemLabel}>关键字</span>
            <Search
              placeholder={'订单号/商品名称/用户姓名'}
              style={{ height: 32, minWidth: 250 }}
              value={Filter}
              onChange={(e) => onChange(e.target.value, 'Filter')}
              onSearch={onSearch}
            />
          </div>
          <div className={styles.searchItem}>
            <span className={styles.searchItemLabel}>商品名称</span>
            <Select
              placeholder={'全部'}
              style={{ minWidth: 220 }}
              value={Type}
              onChange={(value) => onChange(value, 'Type')}
            >
              <Option value="">全部</Option>
              <Option value="Assessment">测评</Option>
              <Option value="OnlinePlanCourse">训练-线上课</Option>
              <Option value="OfflinePlanCourse">训练-线下课</Option>
              <Option value="OneToManyOnlinePlanCourse">一对多-线上课</Option>
              <Option value="OneToManyOfflinePlanCourse">一对多-线下课</Option>
              <Option value="Course">课程</Option>
            </Select>
          </div>
          <div className={styles.clear}><Button onClick={() => dispatch({ type: 'adminhfsOrder/onClear' })}>清空</Button></div>
          <div className={styles.openUp}>
            {
              isOpenUp ?
                <a onClick={() => { dispatch({ type: 'adminhfsOrder/updateState', payload: { isOpenUp: false } }) }}>{intl.formatMessage({ id: 'putItAway' })}</a>
                :
                <a onClick={() => { dispatch({ type: 'adminhfsOrder/updateState', payload: { isOpenUp: true } }) }}>{intl.formatMessage({ id: 'open' })}</a>
            }
          </div>
        </div>
        {
          isOpenUp &&
          <div className={styles.searchBottom}>
            <div className={styles.searchItem}>
              <span className={styles.searchItemLabel}>成交时间</span>
              <RangePicker
                placeholder={[intl.formatMessage({ id: 'startDate' }), intl.formatMessage({ id: 'endDate' })]}
                value={[
                  StartDate ? moment(StartDate, dateFormat) : null,
                  EndDate ? moment(EndDate, dateFormat) : null
                ]}
                onChange={date => onChange(date, 'Date')}
              />
            </div>
          </div>
        }
      </div>
      <Button style={{ marginBottom: '20px' }} onClick={() => dispatch({ type: 'adminhfsOrder/downloadExcel' })}>
        {intl.formatMessage({ id: 'exportList' })}
      </Button>
      <MagicTable {...tableProps} />
    </Card>
  )
}

export default connect(({ adminhfsOrder, loading }) => ({ adminhfsOrder, loading }))(Order)