import { useIntl } from 'umi';
import { Card } from 'antd';
import StatisticalData from './StatisticalData';

const OneToMoreAnalysis = () => {
  const intl = useIntl();

  return (
    <Card
      title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'classAnalysis' })}</b>}
      bordered={false}
    >
      <StatisticalData />
    </Card>
  );
};

export default OneToMoreAnalysis;
